import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Radio } from '@material-ui/core';
import { useStyles } from 'src/pages/Practitioners/GFEManagement.styles';
import Banner from 'src/components/common/Banner';
import { PENDING_STEPS_FOR_GFE_WARNING } from 'src/constants/onboarding.constants';
import { IUserGroup } from '../../interfaces/IUserGroup';
import {
  GFE_SETTING_MAP,
  GFE_SETTING_NO_PROVIDER_NAME,
  GFE_SETTING_OWN_PROVIDER_NAME,
  GFE_SETTING_PORTRAIT_PROVIDER_NAME,
} from '../../constants/general.constants';
import { dispatch } from '../../rematch';
import compile from '../../utils/toastMessagesCompiler';
import { useUpdateUserGroup } from '../../hooks/queries/useUserGroups';

const GFE_SETTING_TEXT_MAP: Record<string, Record<string, string>> = {
  [GFE_SETTING_PORTRAIT_PROVIDER_NAME]: {
    title: 'Portrait Clearing Services',
    description:
      'All patients will automatically be routed to Portrait for GFE completion at the standard rates. This ' +
      'is your best option to ensure patients receive a timely GFE.',
  },
  [GFE_SETTING_NO_PROVIDER_NAME]: {
    title: 'Do Not Route',
    description: 'You will have to request each GFE manually.',
  },
  [GFE_SETTING_OWN_PROVIDER_NAME]: {
    title: 'Own Clearing Provider',
    description:
      'All GFE requests will be automatically routed to your Clearing Provider. You will still have the' +
      ' ability to request an Urgent GFE from Portrait at the standard fee in the event your Clearing Provider cannot' +
      ' complete a GFE in time.',
  },
};

export const MedspaAdminGfeManagementSetting = ({
  userGroup,
  refetchUserGroup,
  pendingMedicalDirector = false,
}: {
  userGroup: IUserGroup;
  refetchUserGroup: () => void;
  pendingMedicalDirector?: boolean;
}) => {
  const classes = useStyles();
  const [selectedGFESetting, setSelectedGFESetting] = useState<number>();
  const { mutateAsync: updateMedspaUser } = useUpdateUserGroup();

  useEffect(() => {
    if (userGroup) {
      setSelectedGFESetting(GFE_SETTING_MAP[userGroup.gfeSetting] || 0);
    }
  }, [userGroup]);

  const handleUpdateGFESetting = (setting: number) => {
    updateMedspaUser(
      {
        id: userGroup.id,
        gfeSetting: setting,
      },
      {
        onSuccess: refetchUserGroup,
        onError: () => {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: `Something went wrong, failed to update settings.`,
              duration: 5000,
              type: 'error',
            },
          });
        },
      }
    );
    setSelectedGFESetting(setting);
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: `Setting`,
          action: 'saved',
        }),
      },
      duration: 3000,
    });
  };

  return (
    <Box className={classes.gfeManagementPage}>
      <Box className={classes.settingsContainer}>
        <Box className={classes.settingsDescription}>
          <Box className={classes.title}>GFE Auto-Routing to</Box>
          <Box className={classes.subTitle}>
            Choose where do you want to automatically send a patient profile for GFE.
          </Box>
        </Box>
        <Box className={classes.settingsOptionsContainer}>
          {pendingMedicalDirector && (
            <Box style={{ marginBottom: '1.5rem' }}>
              <Banner content={PENDING_STEPS_FOR_GFE_WARNING} type="warning" />
            </Box>
          )}
          {Object.keys(GFE_SETTING_TEXT_MAP).map((key) => {
            const textHash = GFE_SETTING_TEXT_MAP[key];
            const value = GFE_SETTING_MAP[key];
            return (
              <Box className={selectedGFESetting === value ? classes.selectedSettingsOption : classes.settingsOption}>
                <FormControlLabel
                  value={value}
                  disabled={pendingMedicalDirector}
                  control={
                    <Radio
                      className={classes.radioButton}
                      color="primary"
                      checked={selectedGFESetting === +value}
                      onClick={() => handleUpdateGFESetting(+value)}
                    />
                  }
                  label={
                    <>
                      <Box>{textHash.title}</Box>
                      <Box className={classes.subTitle}>{textHash.description}</Box>
                    </>
                  }
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
