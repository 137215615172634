// eslint-disable-next-line import/no-unresolved
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const CustomizeMessage = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
    },
    rootExpanded: {
      width: '100%',
      flexGrow: 1,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      textAlign: 'left',
      letterSpacing: '-0.05px',
      color: '#3A3B3F',
      opacity: 1,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    flex: {
      flexGrow: 1,
    },
    formControl: {
      width: '100%',
      marginTop: '10px',
      paddingBottom: '10px',
    },
    formControlLabel: {
      backgroundColor: 'white',
      paddingLeft: '5px',
      paddingRight: '5px',
      color: '#12574d !important',
    },
  })
);

export default CustomizeMessage;
