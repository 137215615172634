import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    width: '440px',
    padding: '4rem 2rem',
    background: 'white',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
  },
  modalTitle: {
    width: '70%',
    fontSize: '18px',
    fontFamily: 'Messina Sans SemiBold',
    margin: '1rem auto 2rem',
  },
  confirmButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '12px',
    padding: '1rem 2rem',
    borderRadius: '8px',
    margin: '0 0.5rem',
    width: '150px',
    textAlign: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#104e45',
    },
    '&:disabled': {
      color: 'white',
    },
  },
  doneButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '12px',
    padding: '1rem 2rem',
    borderRadius: '8px',
    margin: '2rem 0.5rem 0',
    width: '100%',
    textAlign: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#104e45',
    },
  },
  cancelButton: {
    textAlign: 'center',
    color: '#12574D',
    border: '1px solid #12574D',
    borderRadius: '8px',
    margin: '0 0.5rem',
    width: '150px',
    textTransform: 'none',
  },
  bold: {
    fontFamily: 'Messina Sans Bold',
  },
  tipsContainer: {
    background: '#F4F9F8',
    borderRadius: '4px',
    padding: '1rem',
  },
  customTipsContainer: {
    background: '#F4F9F8',
    borderRadius: '4px',
    padding: '1rem 1rem 2rem',
  },
  tipDefaultOptions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.tipDefaultOptions > .tipButton': {
    width: '30%',
  },
  tipButton: {
    marginBottom: '1rem',
    borderRadius: '4px',
    width: '100%',
    height: '50px',
    border: 'none',
    color: '#12574D',
    fontSize: '18px',
    cursor: 'pointer',
    background: '#DAE7E5',
    '&:hover': {
      border: '1px solid #12574D',
    },
  },
  third: {
    width: '30%',
  },
  active: {
    background: '#12574D',
    color: 'white',
  },
  tipOption: {
    fontWeight: 'bold',
  },
  tipOptionTotal: {
    fontSize: '12px',
  },
  customTipButtonsContainer: {
    marginTop: '2rem',
  },
}));
