import React from 'react';
import {
  Box,
  Chip,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  Collapse,
} from '@material-ui/core';
import { Menu as MenuIcon, Note as NoteIcon } from '@material-ui/icons';
import { dispatch } from '../../../../rematch';
import { useStyles } from './inventoryProductsTable.styles';
import { IInventoryProductsTable } from '../../../../interfaces/reconciliation.interfaces';
import { getPluralWord, showQuantityUnits } from '../../../../utils/inventory.utils';
import { formatCurrency } from '../../../../utils/formatNumber';

export const InventoryProductsTable = ({ header, body, iconCallback, showDeductions }: IInventoryProductsTable) => {
  const classes = useStyles();

  const openNote = (id: number) => {
    dispatch({
      type: 'inventoryPractitioner/toggleNote',
      payload: id,
    });
  };

  return (
    <Box padding="10px">
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {header.map((name: string) => (
                <TableCell key={name} className={classes.headerItem}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {body.map((item: any) => (
              <React.Fragment key={item.id}>
                <TableRow>
                  <TableCell className={classes.tableBodyItem}>{item.product}</TableCell>
                  <TableCell className={classes.tableBodyItem}>
                    {showQuantityUnits({ ...item, quantity: item.expected, units: item.expectedUnits })}
                  </TableCell>
                  <TableCell className={classes.tableBodyItem}>
                    {showQuantityUnits({ ...item, quantity: item.counted, units: item.countedUnits })}
                  </TableCell>
                  <TableCell className={classes.tableBodyItem}>
                    {showQuantityUnits({
                      ...item,
                      quantity: item.markedAsEmpty,
                      units: item.markedAsEmptyUnits,
                      showRedText: true,
                    })}
                  </TableCell>
                  <TableCell>
                    {showQuantityUnits({
                      ...item,
                      quantity: item.expired,
                      units: item.expiredUnits,
                      showRedText: true,
                    })}
                  </TableCell>
                  <TableCell className={classes.tableBodyItem} style={{ color: !item.missing ? '#393B3F' : '#FF5252' }}>
                    {showQuantityUnits({ ...item, quantity: item.missing, units: item.missingUnits })}
                  </TableCell>
                  <TableCell className={classes.tableBodyItem}>
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      onClick={() => {
                        iconCallback(item.id);
                      }}
                    >
                      <MenuIcon className={classes.menuIcon} />
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableBodyItem}>
                    {item.practitionerNote && (
                      <NoteIcon
                        onClick={() => {
                          openNote(item.id);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.tableBodyItem}>
                    {item.waived}
                    <span> </span>
                    {item.allowPartialSale && getPluralWord('Unit', item.waived === 0 || item.waived > 1)}
                  </TableCell>
                  <TableCell
                    className={classes.tableBodyItem}
                    style={{ color: !(item.missingUnits - item.waived) ? '#393B3F' : '#FF5252' }}
                  >
                    {item.missingUnits - item.waived}
                    <span> </span>
                    {item.allowPartialSale &&
                      getPluralWord(
                        'Unit',
                        item.missingUnits - item.waived === 0 || item.missingUnits - item.waived > 1
                      )}
                  </TableCell>
                  {showDeductions && (
                    <TableCell>
                      <Chip label={formatCurrency(item.cost || 0)} />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell className={classes.collapseTableCell} colSpan={9}>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                      <div className={classes.noteSection}>
                        <div className={classes.noteLabel}>Practitioner Note:</div>
                        <div className={classes.practitionerNoteExpanded}>{item.practitionerNote}</div>
                      </div>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
