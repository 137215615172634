import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  medspaHeaderSearchContainer: {
    width: '296px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  medspaHeaderProductTypeContainer: {
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  medspaHeaderStatusContainer: {
    width: '150px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  medspaInfoCard: {
    marginBottom: '2rem',
    padding: '1.25rem',
    borderRadius: '0.5rem',
    border: '1px solid #E7E7E7',
    background: 'white',
    boxShadow: 'none',
  },
  medspaCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
    alignItems: 'center',
  },
  medspaCardDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F8F8F8',
    padding: '1.25rem',
    borderRadius: '8px',
    border: '1px solid rgba(239, 239, 239, 0.8)',
    height: '3.5rem',
    marginBottom: '2rem',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '700px',
    height: '500px',
    margin: 'auto',
    borderRadius: '8px',
  },
  modalContent: {
    backgroundColor: 'white',
    width: '100%',
    textAlign: 'center',
    padding: '1rem 0',
  },
  modalBody: {
    margin: 'auto',
    marginBottom: '2rem',
    width: '70%',
  },
  warningIcon: {
    marginTop: '2rem',
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  cardDetailButton: {
    cursor: 'pointer',
    marginRight: '1rem',
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    boxSizing: 'border-box',
    display: 'block',
  },
  medspaLink: {
    color: '#2172BB',
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.25rem',
  },
  subText: {
    color: 'gray',
  },
  fullNameContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    gridGap: '1rem',
  },
  actionColumn: {
    padding: '1rem',
    minWidth: '20%',
    maxWidth: '20%',
    display: 'flex',
    flexDirection: 'row',
    gridGap: '1rem',
  },
  infiScrollContainer: {
    overflow: 'auto',
    height: '84.7vh',
    width: '100%',
    marginTop: '10px',
  },
  activeUser: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#C9F2CB',
    borderRadius: '8px',
    color: '#26802F',
    width: '80px',
    height: '1.85rem',
  },
  inactiveUser: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#D9D9D9',
    borderRadius: '8px',
    color: '#686868',
    width: '80px',
    height: '1.85rem',
  },
  medspaUserRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avatar: {
    width: '32px',
    height: '32px',
  },
  medspaUsersTable: {
    '& tr > td': {
      border: 'none',
    },
  },
}));
