/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import classnames from 'classnames';
import moment, { ISO_8601 } from 'moment';
import { useStyles } from './styles';
import { INPUT_REGEX } from './schemas';

interface TimeBlockProps {
  name: string;
  hours?: string[];
  disabled?: boolean;
  onChangeDay: (e: any, name: string, i: number) => void;
  onRemove: (name: string, index: number) => void;
}
const hourMFormat = 'hh:mma';
const hourFormat = 'HH:mm';
export const AvailabilityTimeBlock = ({ name, hours, disabled, onChangeDay, onRemove }: TimeBlockProps) => {
  const classes = useStyles();

  const [dates, setDates] = useState<any>([]);

  useEffect(() => {
    const newDates = hours?.map((hour) => {
      const hourStart = moment(hour?.split('-')[0], [ISO_8601, hourMFormat]).format(hourFormat).split(' ')[0];
      const hourEnd = moment(hour?.split('-')[1], [ISO_8601, hourMFormat]).format(hourFormat).split(' ')[0];

      return {
        hourStart: hourStart?.length < 5 ? `0${hourStart}` : hourStart,
        hourEnd: hourEnd?.length < 5 ? `0${hourEnd}` : hourEnd,
      };
    });
    setDates(newDates);
  }, [hours]);

  const onChangeForm = (value: any, key: string, index: number) => {
    const newDates = dates;
    newDates[index] = { ...newDates[index], [key]: value };
    setDates(newDates);

    const newValue = `${moment(newDates[index].hourStart, [moment.ISO_8601, hourFormat]).format(hourMFormat)}-${moment(
      newDates[index].hourEnd,
      [moment.ISO_8601, hourFormat]
    ).format(hourMFormat)}`;

    const valid = newValue.match(INPUT_REGEX);
    if (valid) {
      onChangeDay(newValue, name, index);
    }
  };

  return (
    <>
      {hours?.map((item: string, index: number) => (
        <Box key={`${item}-${index}`} className={classes.formItem} display="flex" textAlign="right">
          <TextField
            style={{ paddingTop: 0 }}
            disabled={disabled}
            type="time"
            value={dates[index]?.hourStart}
            onChange={(event: any) => onChangeForm(event?.target?.value, 'hourStart', index)}
            size="small"
            label="Hours"
            variant="outlined"
          />
          <div className={classes.timeText}>-</div>
          <TextField
            disabled={disabled}
            type="time"
            value={dates[index]?.hourEnd}
            onChange={(event: any) => onChangeForm(event?.target?.value, 'hourEnd', index)}
            size="small"
            label="Hours"
            variant="outlined"
          />
          <DeleteIcon
            className={classnames(classes.actionButton, classes.actionButtonTable)}
            onClick={() => onRemove(name, index)}
          />
        </Box>
      ))}
    </>
  );
};
