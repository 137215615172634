import React from 'react';
import moment from 'moment';
import { withStyles, TableCell, TableRow } from '@material-ui/core';
import { IActivityLog } from '../../types/Patient';

const TableRowStriped = withStyles(() => ({
  root: {
    fontFamily: 'Messina Sans Regular',
    height: '56px',
  },
}))(TableRow);

interface IActivityRowItem {
  activity: IActivityLog;
  striped: number;
}

const RowItem = ({ activity, striped }: IActivityRowItem) => (
  <>
    <TableRowStriped className="activity-log-row" style={{ backgroundColor: striped % 2 ? '#f2f5f5' : '#fff' }}>
      <TableCell>
        <div>{activity.activityType}</div>
      </TableCell>
      <TableCell>
        <div>{`${activity.description !== null ? activity.description : ''}`}</div>
      </TableCell>
      <TableCell>
        <div>{moment(activity.createdAt).format('MM/DD/YYYY')}</div>
      </TableCell>
    </TableRowStriped>
  </>
);

export default RowItem;
