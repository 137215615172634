import { createModel } from '@rematch/core';
import { LoyaltyProgramsAccountState, IGaldermaUser } from '../types/LoyaltyProgramsAccount';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import { handleGaldermaUserError } from '../utils/galderma.utils';

const loyaltyProgramsAccountInitialState = {
  user: {},
  aspireAccountLinked: false,
} as LoyaltyProgramsAccountState;

export const loyaltyProgramsAccount = createModel<RootModel>()({
  state: loyaltyProgramsAccountInitialState,
  reducers: {
    setUser(state: LoyaltyProgramsAccountState, payload: { user: IGaldermaUser }) {
      return { ...state, user: payload.user };
    },
    setIsAccountLinked(state: LoyaltyProgramsAccountState, payload: boolean) {
      return { ...state, aspireAccountLinked: payload };
    },
    setResetProgramsAccount(): LoyaltyProgramsAccountState {
      return loyaltyProgramsAccountInitialState;
    },
  },
  effects: (dispatch: any) => ({
    async findUserByEmail(payload: { email: string }) {
      try {
        const { email } = payload;
        const request = await axiosInstance.get(`/galderma/find_user_by_email?email=${email}`);

        if (request.data.success) {
          dispatch.loyaltyProgramsAccount.setIsAccountLinked(true);
        }
      } catch (error) {
        dispatch.loyaltyProgramsAccount.setIsAccountLinked(false);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            type: 'error',
            message: error.message,
          },
        });
      }
    },
    async linkGaldermaUser(payload: { email: string; customerId: number; successCallback: () => void }) {
      try {
        const { email, customerId, successCallback } = payload;
        const request = await axiosInstance.post('/galderma/link_user', { customerId, email });

        if (!request.data.success) {
          handleGaldermaUserError(request.data);
        } else {
          const requestUser = await axiosInstance.get(`/galderma/find_user_by_email?email=${email}`);

          dispatch.loyaltyProgramsAccount.setUser(requestUser.data.user);
          successCallback();
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: error.message,
            type: 'error',
          },
        });
      }
    },
    async createUser(payload: { customerId: number; email?: string; successCallback?: () => void }) {
      try {
        const { customerId, email, successCallback } = payload;
        const request = await axiosInstance.post('/galderma/create_user', { customerId, email });

        if (!request.data.success) {
          handleGaldermaUserError(request.data);
        } else {
          dispatch.loyaltyProgramsAccount.setUser(request.data);
          if (successCallback) {
            successCallback();
          }
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: error.message,
            type: 'error',
            duration: 2000,
          },
        });
      }
    },
  }),
});
