/* IMPORTANT:
 * Each permission declared in this file, should be present in all_permissions in backend
 * Related file: scripts/identities/new_roles_and_permissions.rb
 * Steps:
 * 1. Check permissions in doc:
 *  https://docs.google.com/spreadsheets/d/1J5U33xpFpzMJPN2hA0fqG-2xrjIbPuV6o7q-xolr8f8/edit#gid=0
 * 2. Add permission in new_roles_and_permissions.rb (API).
 * 3. Define the permissions with the exact name than the database.
 */
export const READ = 'read';
export const CREATE = 'create';
export const UPDATE = 'update';
export const DESTROY = 'destroy';
export const CLAIM = 'claim';
export const FILTER = 'filter';
export const CUSTOM = 'custom';

export const INVENTORY_ORDER_PERIOD = 'inventory_order_period';
export const INVENTORY_ORDER = 'inventory_order';
export const PURCHASE_ORDER = 'purchase_order';
export const SUPPLY_RECEIPT = 'supply_receipt';
export const ASSET = 'asset';
export const RECONCILIATION_REPORT = 'reconciliation_report';
export const RECONCILIATION_REPORT_DEDUCTIONS = 'reconciliation_report_deductions';
export const PRACTITIONER_SALES_DASHBOARD = 'practitioner_sales_dashboard';
export const PHOTOS_REQUEST = 'photo_request';
export const PATIENT_CONTACT_INFO = 'patient_contact_info';
export const MEDICAL_PROFILE = 'medical_profile';
export const STICKY_NOTE = 'sticky_note';
export const LOYALTY_PROGRAM = 'loyalty_program';
export const REFERRAL = 'referral';
export const PATIENT_PHOTO = 'patient_photo';
export const ADMIN_USER = 'admin_user';
export const PRACTITIONER_LOGIN = 'practitioner_login';
export const CUSTOMER = 'customer';
export const PRACTITIONER = 'practitioner';
export const PHYSICIAN = 'physician';
export const CUSTOMER_CHANGE_PRACTITIONER = 'customer_change_practitioner';
export const ADMIN_NOTE = 'admin_note';
export const DOCTOR_ADMIN_NOTE = 'doctor_admin_note';
export const LOCATION = 'location';
export const SERVICE = 'service';
export const EHR = 'ehr';
export const PRACTITIONER_REGISTRATION = 'practitioner_registration';
export const CLIENT_LEAD = 'client_lead';
export const MEDICAL_DIRECTOR_NOTE = 'medical_director_note';
export const USER_GROUP = 'user_group';
export const MARK_CUSTOMER_IMPORTANT = 'mark_customer_important';
export const AD_CAMPAIGN = 'ad_campaign';
export const ORDERING_BUDGET = 'ordering_budget';

export const READ_INVENTORY_ORDER_PERIOD = `${INVENTORY_ORDER_PERIOD}:${READ}`;
export const UPDATE_INVENTORY_ORDER_PERIOD = `${INVENTORY_ORDER_PERIOD}:${UPDATE}`;
export const READ_INVENTORY_ORDER = `${INVENTORY_ORDER}:${READ}`;
export const DESTROY_INVENTORY_ORDER = `${INVENTORY_ORDER}:${DESTROY}`;
export const READ_PURCHASE_ORDER = `${PURCHASE_ORDER}:${READ}`;
export const CREATE_PURCHASE_ORDER = `${PURCHASE_ORDER}:${CREATE}`;
export const READ_SUPPLY_RECEIPT = `${SUPPLY_RECEIPT}:${READ}`;
export const CREATE_SUPPLY_RECEIPT = `${SUPPLY_RECEIPT}:${CREATE}`;
export const READ_RECONCILIATION_REPORT = `${RECONCILIATION_REPORT}:${READ}`;
export const UPDATE_RECONCILIATION_REPORT = `${RECONCILIATION_REPORT}:${UPDATE}`;
export const READ_RECONCILIATION_REPORT_DEDUCTIONS = `${RECONCILIATION_REPORT_DEDUCTIONS}:${READ}`;
export const READ_ASSET = `${ASSET}:${READ}`;
export const READ_PRACTITIONER_SALES_DASHBOARD = `${PRACTITIONER_SALES_DASHBOARD}:${READ}`;
export const CREATE_PHOTOS_REQUEST = `${PHOTOS_REQUEST}:${CREATE}`;
export const UPDATE_PPHOTOS_REQUEST = `${PHOTOS_REQUEST}:${UPDATE}`;
export const READ_PATIENT_MEDICAL_PROFILE = `${MEDICAL_PROFILE}:${READ}`;
export const UPDATE_PATIENT_MEDICAL_PROFILE = `${MEDICAL_PROFILE}:${UPDATE}`;
export const CREATE_PATIENT_MEDICAL_PROFILE = `${MEDICAL_PROFILE}:${CREATE}`;
export const CREATE_LOYALTY_PROGRAM = `${LOYALTY_PROGRAM}:${CREATE}`;
export const CREATE_REFERRAL = `${REFERRAL}:${CREATE}`;
export const CREATE_PATIENT_PHOTO = `${PATIENT_PHOTO}:${CREATE}`;
export const UPDATE_PATIENT_PHOTO = `${PATIENT_PHOTO}:${UPDATE}`;
export const CREATE_ADMIN_USER = `${ADMIN_USER}:${CREATE}`;
export const READ_ADMIN_USER = `${ADMIN_USER}:${READ}`;
export const READ_PRACTITIONER_LOGIN = `${PRACTITIONER_LOGIN}:${READ}`;
export const CREATE_CUSTOMER = `${CUSTOMER}:${CREATE}`;
export const READ_CUSTOMER = `${CUSTOMER}:${READ}`;
export const READ_PRACTITIONER = `${PRACTITIONER}:${READ}`;
export const CREATE_PRACTITIONER = `${PRACTITIONER}:${CREATE}`;
export const READ_CLIENT_LEAD = `${CLIENT_LEAD}:${READ}`;
export const CREATE_USER_GROUP = `${USER_GROUP}:${CREATE}`;
export const READ_USER_GROUP = `${USER_GROUP}:${READ}`;
export const UPDATE_USER_GROUP = `${USER_GROUP}:${UPDATE}`;
export const MARK_CUSTOMER_IMPORTANT_UPDATE = `${MARK_CUSTOMER_IMPORTANT}:${UPDATE}`;
export const CREATE_AD_CAMPAIGN = `${AD_CAMPAIGN}:${CREATE}`;
export const READ_AD_CAMPAIGN = `${AD_CAMPAIGN}:${READ}`;
export const UPDATE_AD_CAMPAIGN = `${AD_CAMPAIGN}:${UPDATE}`;
export const UPDATE_ORDERING_BUDGET = `${ORDERING_BUDGET}:${UPDATE}`;

export const READ_PATIENT_CONTACT_INFO = `${PATIENT_CONTACT_INFO}:${READ}`;
export const UPDATE_PATIENT_CONTACT_INFO = `${PATIENT_CONTACT_INFO}:${UPDATE}`;
export const CREATE_PATIENT_CONTACT_INFO = `${PATIENT_CONTACT_INFO}:${CREATE}`;

export const UPDATE_CUSTOMER_CHANGE_PRACTITIONER = `${CUSTOMER_CHANGE_PRACTITIONER}:${UPDATE}`;
export const READ_ADMIN_NOTE = `${ADMIN_NOTE}:${READ}`;
export const CREATE_ADMIN_NOTE = `${ADMIN_NOTE}:${CREATE}`;
export const DESTROY_ADMIN_NOTE = `${ADMIN_NOTE}:${DESTROY}`;
export const READ_DOCTOR_ADMIN_NOTE = `${DOCTOR_ADMIN_NOTE}:${READ}`;

export const READ_PHYSICIAN = `${PHYSICIAN}:${READ}`;
export const CREATE_PHYSICIAN = `${PHYSICIAN}:${CREATE}`;
export const READ_LOCATION = `${LOCATION}:${READ}`;
export const CREATE_LOCATION = `${LOCATION}:${CREATE}`;
export const UPDATE_LOCATION = `${LOCATION}:${UPDATE}`;
export const CREATE_SERVICE = `${SERVICE}:${CREATE}`;

// New Portrait Flex permissions
export const GOOD_FAITH_EXAM = 'good_faith_exam';
export const BEAUTIBANK = 'beauty_bank';
export const MARKETING = 'marketing';
export const DIAGNOSIS = 'diagnosis';
export const TODO_LIST = 'todo_list';
export const PRACTITIONER_SERVICE_PRICE_OVERRIDE = 'practitioner_service_price_override';
export const FEEDBACK = 'feedback';
export const REFER_PROVIDER = 'refer_provider';
export const NOTIFICATION_BELL = 'notification_bell';
export const CIRCLE = 'circle';
export const GUIDELINES = 'guidelines';
export const PRODUCT_BOARD = 'product_board';
export const PORTRAIT_RESOURCES = 'portrait_resources';
export const CALENDAR = 'calendar';
export const MESSAGE_THREAD = 'message_thread';
export const INVENTORY = 'inventory';
export const SERVICE_VISIT_DISCOUNT = 'service_visit_discount';
export const EARNING_DASHBOARD = 'earning_dashboard';
export const JOINT_INVESTMENT = 'joint_investment';
export const FLOOR_PRICE = 'floor_price';
export const ALL_SERVICES_AND_GROUPS = 'all_services_and_groups';
export const SERVICE_VISIT_PHOTO_CONSENT = 'service_visit_photo_consent';
export const SERVICE_VISIT_OPTIONAL_AFTER_PHOTOS = 'service_visit_optional_after_photos';
export const CONSENT = 'consent';
export const STANDING_ORDER = 'standing_order';
export const PRACTITIONER_BOOK_APPOINTMENT = 'practitioner_book_appointment';
export const POLICIES = 'policies';
export const SERVICES_AND_PRODUCTS_MANAGEMENT = 'services_and_products_management';
export const INVENTORY_SCAN = 'inventory_scan';
export const DOCUMENT = 'document';
export const GFE_MANAGEMENT_SETTING = 'gfe_management_setting_configuration';

export const GFE_REQUEST = `${GOOD_FAITH_EXAM}:${UPDATE}`;
export const READ_MARKETING = `${MARKETING}:${READ}`;
export const READ_BEAUTIBANK = `${BEAUTIBANK}:${READ}`;
export const READ_REFERRAL = `${REFERRAL}:${READ}`;
export const READ_DIAGNOSIS = `${DIAGNOSIS}:${READ}`;
export const READ_TODO_LIST = `${TODO_LIST}:${READ}`;
export const UPDATE_PRACTITIONER_SERVICE_PRICE_OVERRIDE = `${PRACTITIONER_SERVICE_PRICE_OVERRIDE}:${UPDATE}`;
export const READ_FEEDBACK = `${FEEDBACK}:${READ}`;
export const READ_REFER_PROVIDER = `${REFER_PROVIDER}:${READ}`;
export const READ_NOTIFICATION_BELL = `${NOTIFICATION_BELL}:${READ}`;
export const READ_PORTRAIT_RESOURCES = `${PORTRAIT_RESOURCES}:${READ}`;
export const READ_CALENDAR_FILTER = `${CALENDAR}:${FILTER}`;
export const READ_MESSAGE_THREAD = `${MESSAGE_THREAD}:${READ}`;
export const READ_INVENTORY = `${INVENTORY}:${READ}`;
export const READ_SERVICE_VISIT_DISCOUNT = `${SERVICE_VISIT_DISCOUNT}:${READ}`;
export const READ_EARNING_DASHBOARD = `${EARNING_DASHBOARD}:${READ}`;
export const READ_JOINT_INVESTMENT = `${JOINT_INVESTMENT}:${READ}`;
export const READ_FLOOR_PRICE = `${FLOOR_PRICE}:${READ}`;
export const READ_SERVICE_VISIT_PHOTO_CONSENT = `${SERVICE_VISIT_PHOTO_CONSENT}:${READ}`;
export const READ_SERVICE_VISIT_OPTIONAL_AFTER_PHOTOS = `${SERVICE_VISIT_OPTIONAL_AFTER_PHOTOS}:${READ}`;
export const CONSENT_READ = `${CONSENT}:${READ}`;
export const CONSENT_UPDATE = `${CONSENT}:${UPDATE}`;
export const STANDING_ORDER_READ = `${STANDING_ORDER}:${READ}`;
export const STANDING_ORDER_UPDATE = `${STANDING_ORDER}:${UPDATE}`;
export const PRACTITIONER_BOOK_APPOINTMENT_UPDATE = `${PRACTITIONER_BOOK_APPOINTMENT}:${UPDATE}`;
export const READ_POLICIES = `${POLICIES}:${READ}`;
export const READ_SERVICES_AND_PRODUCTS_MANAGEMENT = `${SERVICES_AND_PRODUCTS_MANAGEMENT}:${READ}`;
export const USE_EHR = `${EHR}:${READ}`;
export const SERVICE_VISIT_INVENTORY_SCAN = `${INVENTORY_SCAN}:${UPDATE}`;
export const CUSTOM_SERVICES = `${SERVICE}:${CUSTOM}`;
export const MEDICAL_DIRECTOR_NOTE_UPDATE = `${MEDICAL_DIRECTOR_NOTE}:${UPDATE}`;
export const DOCUMENT_READ = `${DOCUMENT}:${READ}`;
export const DOCUMENT_CREATE = `${DOCUMENT}:${CREATE}`;
export const UPDATE_GFE_MANAGEMENT_SETTING = `${GFE_MANAGEMENT_SETTING}:${UPDATE}`;

// support permissions

export const IS_SUPPORT = 'is_support';
