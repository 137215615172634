import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationOnIcon,
  Note as NoteIcon,
  Star as StarIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { BLOCK_OFF_HOURS } from 'src/constants/acuity/acuity.constants';
import { useCancelAppointmentMutation } from 'src/hooks/acuity/useAppointment';
import { useBlockOffHoursDeleteMutation } from 'src/hooks/acuity/useAcuityAccount';
import { USER_TYPES } from 'src/constants/general.constants';
import ButtonAhref from '../ButtonAhref';
import { useCardStyles } from './Appointments.styles';
import { hasAccessTo } from '../../../utils/auth.utils';
import { PRACTITIONER_BOOK_APPOINTMENT_UPDATE } from '../../../constants/actions.constants';
import { dispatch } from '../../../rematch';

interface Props {
  appointmentData: any;
  shouldRefetch: () => void;
  isAppointmentEdit: () => void;
  refetch: boolean;
}

const formatDateHourA = 'YYYY-MM-DD hh:mmA';

const AppointmentsCard = (props: Props) => {
  const cardRef: any = useRef();
  const { userType } = useSelector(({ auth }: any) => auth);
  const { appointmentData, shouldRefetch, isAppointmentEdit, refetch } = props;

  const { userId, permissions } = useSelector(({ auth }: any) => auth);
  const { currentAppointment } = useSelector(({ schedulers }: any) => schedulers);
  const [isCancelling, setIsCancelling] = useState(false);

  const classes = useCardStyles(appointmentData);
  const startDateStr = moment(appointmentData.startDate).format('dddd, MMMM DD, YYYY / hh:mm a');
  const endDateStr = moment(appointmentData.endDate).format('hh:mm a');

  const [openCancelBlockOffModal, setOpenCancelBlockOffModal] = useState(false);

  const cancelAppointmentMutation = useCancelAppointmentMutation();
  const deleteBlockOffHoursMutation = useBlockOffHoursDeleteMutation(() => {
    setOpenCancelBlockOffModal(false);
  });

  const showPhoneNumber = ['incomplete', 'cleared'].includes(appointmentData.statusId) && appointmentData.phone;

  const getPractitionerLink = () => {
    if (appointmentData.customerId) {
      return `/patient/${appointmentData.customerId}`;
    }
    return '';
  };

  useEffect(() => {
    if (JSON.stringify(appointmentData || {}) !== JSON.stringify(currentAppointment || {})) {
      dispatch.schedulers.setCurrentAppointment(appointmentData);
    }
  }, []);

  useEffect(() => {
    if (refetch) {
      // Close modal if is refetching...
      cardRef?.current?.parentNode?.remove?.();
    }
  }, [refetch]);

  const handleCancelAppointment = async () => {
    try {
      setIsCancelling(true);
      cardRef?.current?.parentNode?.remove?.();
      await cancelAppointmentMutation.mutateAsync({
        appointmentId: appointmentData?.id,
        practitionerId: userId,
      });
      shouldRefetch();
      setIsCancelling(false);
    } catch (e) {
      setIsCancelling(false);
    }
  };

  const canCancel = moment(appointmentData.startDate) > moment();

  const isBlockOff = appointmentData.type === BLOCK_OFF_HOURS;

  const cardTitle =
    userType === USER_TYPES.ADMINISTRATOR
      ? `[${appointmentData.practitionerName}] ${appointmentData.patient || ''}`
      : appointmentData.patient;
  return (
    <div className={classes.cardContainer} ref={cardRef}>
      <div className={classes.cardContent}>
        <ButtonAhref
          buttonStyle="notStyle"
          className={classes.cardTitle}
          text={cardTitle}
          href={appointmentData.customerId ? `/patient/${appointmentData.customerId}` : null}
        />

        {hasAccessTo(PRACTITIONER_BOOK_APPOINTMENT_UPDATE, permissions) && appointmentData.isFirstAppointment ? (
          <StarIcon style={{ width: '15px' }} />
        ) : null}

        <Typography className={classes.cardSubtitle}>{appointmentData.type}</Typography>

        <p role="presentation" onClick={getPractitionerLink} className={classes.cardDate} data-testid="date-range">
          {startDateStr} - {endDateStr}
        </p>

        {appointmentData?.location && (
          <Typography className={classes.cardLocation}>
            <LocationOnIcon className={classes.cardContactIcon} style={{ background: 'transparent' }} />
            {appointmentData.location}
          </Typography>
        )}

        {appointmentData?.notes && (
          <div className={classes.cardContact}>
            <NoteIcon className={classes.cardContactIcon} />
            Note
            <Typography className={classes.noteText}>{appointmentData.notes}</Typography>
          </div>
        )}

        {appointmentData.email && (
          <Typography className={classes.cardContact}>
            <EmailIcon className={classes.cardContactIcon} />
            {appointmentData.email}
          </Typography>
        )}

        {showPhoneNumber && (
          <Typography className={classes.cardContact}>
            <PhoneIcon className={classes.cardContactIcon} />
            {appointmentData.phone}
          </Typography>
        )}

        {!isBlockOff && hasAccessTo(PRACTITIONER_BOOK_APPOINTMENT_UPDATE, permissions) && (
          <>
            <Button
              data-cy="submitBtn"
              variant="contained"
              disableElevation
              color="primary"
              size="medium"
              style={{ margin: '10px 0 0 0' }}
              disabled={isCancelling || !canCancel}
              onClick={() => isAppointmentEdit()}
            >
              Reschedule appointment
            </Button>
            <Button
              data-cy="submitBtn"
              variant="outlined"
              disableElevation
              color="primary"
              size="medium"
              style={{ margin: '10px 0 10px 0' }}
              onClick={handleCancelAppointment}
              disabled={isCancelling || !canCancel}
            >
              {isCancelling ? (
                <>
                  <CircularProgress style={{ color: 'black', marginRight: '5px' }} size={20} />
                  <Typography variant="body2">Processing</Typography>
                </>
              ) : (
                <Typography variant="body2">Cancel appointment</Typography>
              )}
            </Button>
          </>
        )}

        {isBlockOff && hasAccessTo(PRACTITIONER_BOOK_APPOINTMENT_UPDATE, permissions) && (
          <Button
            data-cy="submitBtn"
            variant="contained"
            disableElevation
            color="primary"
            size="medium"
            style={{ margin: '10px 0 0 0' }}
            onClick={() => setOpenCancelBlockOffModal(true)}
          >
            Delete
          </Button>
        )}
      </div>

      <Dialog
        open={openCancelBlockOffModal}
        onClose={() => setOpenCancelBlockOffModal(false)}
        data-cy="confirmationModal"
      >
        <DialogTitle>Delete Block Off Time</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-content-text">
            Are you sure you want to delete the block off from{' '}
            {moment(appointmentData.startDate).format(formatDateHourA)} to{' '}
            {moment(appointmentData.endDate).format(formatDateHourA)} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-cy="closeModal" onClick={() => setOpenCancelBlockOffModal(false)}>
            Cancel
          </Button>
          <Button
            disabled={deleteBlockOffHoursMutation.isLoading}
            data-cy="confirmModal"
            onClick={() => {
              deleteBlockOffHoursMutation.mutate(appointmentData.id);
              cardRef?.current?.parentNode?.remove?.();
            }}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AppointmentsCard;
