import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Box, Chip, Divider, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';
import { WizardFooter } from './WizardFooter';
import { useStyles } from './finalizeReconciliation.styles';
import { RootState, dispatch } from '../../../rematch';
import { WizardHeader } from './WizardHeader';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import { formatCurrency } from '../../../utils/formatNumber';
import { PRACTITIONER_RECONCILIATION_PATH } from '../../../routes/practitionerRoutes';

const FinalizeReconciliation = () => {
  let signCanvasRef: any;
  const history = useHistory();
  const classes = useStyles();
  const { remainingAssets, productList } = useSelector((state: RootState) => state.inventoryPractitioner);
  const { userId } = useSelector(({ auth }: RootState) => auth);

  const [signed, setSigned] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const { register, control, handleSubmit, errors, watch } = useForm({
    defaultValues: { note: '', valueFilledEmergencyKit: '' },
  });

  useEffect(() => {
    if (!productList.length && userId) {
      dispatch({
        type: 'inventoryPractitioner/fetchDraftReport',
        payload: { practitionerId: userId },
      });
    }
  }, [userId]);

  const back = () => {
    history.replace('/inventory/product-summary');
  };

  const submit = ({ note, valueFilledEmergencyKit }: any) => {
    setIsSubmitLoading(true);
    const filledEmergencyKit: boolean = valueFilledEmergencyKit === 'yes';
    const reconciliationReport = remainingAssets.map((asset: IAsset) => ({
      assetId: asset.id,
      practitionerNote: note[asset.serviceId] || '',
    }));

    dispatch({
      type: 'inventoryPractitioner/submitReconciliationReport',
      payload: {
        reconciliationReport,
        filledEmergencyKit,
        signature,
        practitionerId: userId,
        successCallback: () => {
          history.push(PRACTITIONER_RECONCILIATION_PATH);
        },
        finallyCallback: () => {
          setIsSubmitLoading(false);
        },
      },
    });
  };

  const onEndStroke = () => {
    const signatureEnd = signCanvasRef.getTrimmedCanvas().toDataURL('image/png');
    setSigned(true);
    setSignature(signatureEnd);
  };

  return (
    <article>
      <div className={classes.content}>
        <WizardHeader title="Finalize and Submit" />
        <Divider />
        <Box className={classes.header}>
          <Box className={classes.subTitle}>Missing Products</Box>
          <Box className={classes.subTitle}>Costs</Box>
          <Box className={classes.subTitle}>Notes</Box>
        </Box>
        <form style={{ margin: '10px', border: '1px solid #E4E7EB', fontFamily: 'Messina Sans Regular' }}>
          {productList.map(
            (
              { name, quantity, scanned, serviceId, allowPartialSale, units, scannedUnits, cost }: any,
              index: number
            ) => (
              <React.Fragment key={name}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '7px' }}>
                  <div style={{ width: '160px', marginLeft: '13px' }}>{name}</div>
                  <div>
                    <Chip label={formatCurrency(cost < 0 ? 0 : cost)} />
                  </div>
                  <TextField
                    name={`note[${serviceId}]`}
                    defaultValue=""
                    placeholder="Add a note for your admin."
                    variant="outlined"
                    multiline
                    style={{ width: '368px' }}
                    inputRef={register({
                      required: allowPartialSale ? !!(units - scannedUnits) : !!(quantity - scanned),
                    })}
                    error={!!(errors as any).note?.[serviceId]}
                    helperText={(errors as any).note?.[serviceId] ? 'Note is required' : ''}
                  />
                </div>
                {index !== productList.length - 1 && <Divider />}
              </React.Fragment>
            )
          )}
          <div style={{ alignItems: 'center', padding: '7px', borderTop: '1px solid rgb(228, 231, 235)' }}>
            <div>
              <div className={classes.subTitleRadio}> I acknowledge that my emergency kit is full.</div>
              <Controller
                name="valueFilledEmergencyKit"
                control={control}
                rules={{ required: true }}
                as={
                  <RadioGroup name="valueFilledEmergencyKit">
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <div style={{ display: 'flex' }}>
                      <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                      {watch('valueFilledEmergencyKit') === 'no' && (
                        <span
                          style={{
                            fontSize: '1rem',
                            alignSelf: 'center',
                            fontFamily: 'Messina Sans SemiBold',
                            lineHeight: '1.5',
                          }}
                        >
                          - Please email <a href="mailto:inventory@portraitcare.com">inventory@portraitcare.com</a>
                        </span>
                      )}
                    </div>
                  </RadioGroup>
                }
                style={{ margin: '0 15px' }}
              />
            </div>
            <p style={{ color: '#f44336', marginLeft: '13px' }}>
              {(errors as any).valueFilledEmergencyKit?.type === 'required' && 'This field is required'}
            </p>
          </div>
        </form>
        <div style={{ margin: '42px', fontFamily: 'Messina Sans Regular' }}>
          <p style={{ marginBottom: '0' }}>
            I acknowledge that I have scanned only inventory that I know to be in my possession and any notes above are
            truthful and accurate.
          </p>
          <p className={classes.signatureLabel}>Practitioner Signature</p>
          <SignatureCanvas
            canvasProps={{ className: classes.signature }}
            ref={(ref) => {
              signCanvasRef = ref;
            }}
            onEnd={onEndStroke}
            clearOnResize={false}
          />
        </div>
      </div>
      <WizardFooter
        primaryButtonText="SUBMIT"
        secondaryButtonText="BACK"
        step="3/3"
        primaryCallback={handleSubmit(submit)}
        secondaryCallback={back}
        disablePrimary={!signed}
        loadingPrimary={isSubmitLoading}
      />
    </article>
  );
};

export default FinalizeReconciliation;
