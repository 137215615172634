import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Grid, Box, TextField, Typography, TextareaAutosize, makeStyles, CardContent } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { dispatch } from '../../rematch';
import HistoryImageList from '../common/HistoryImageList';
import ImageEditor from '../common/ImageEdit';
import { Card, SmallTitle } from '../common/card';

import { ActiveStepWrapper } from './ActiveStepWrapper';
import { getSelectedAnnotatableServiceIds } from '../../utils/newServiceVisit.util';
import FullChartingImageModal from './FullChartingImageModal';
import { Button } from '../common/Button';
import { PHOTO_TYPE } from '../../constants/newServiceVisit.constants';
import { CustomerInternalNotes } from './CustomerInternalNotes';
import { replaceVariables } from '../../utils/replaceVariables';

const useStyles = makeStyles(() => ({
  card: {
    margin: '0',
  },
  cardContent: {
    padding: '11px 20px 9px !important',
  },
  smallTitle: {
    margin: '0',
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
  button: {
    backgroundColor: '#12574d',
    color: 'white',
    width: '128px',
    height: '47px',
    marginRight: 0,
  },
  contentCheckout: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentTotal: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const setButtonServiceStyles = (color: string) => ({
  margin: 0,
  color: '#fff',
  fontSize: '15px',
  marginRight: '8px',
  marginBottom: '8px',
  backgroundColor: color || '#e3e3e3',
});

const TextAreaStyles = {
  width: '100%',
  minHeight: '75px',
  maxHeight: '98px',
  borderRadius: '3px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: '#fff',
  padding: '13px 14px',
  fontFamily: 'Messina Sans Regular',
  fontSize: '15px',
};

const imageInitialState = { photo_id: '', url: '', photo_services: [] };

const MedicalChartingImages = () => {
  const params: any = useParams();
  const classes = useStyles();
  const { photos: patientPhotos, firstName, lastName } = useSelector(({ patient }: any) => patient);
  const { handleSubmit, errors } = useForm();
  const {
    serviceVisit,
    services,
    selectedServices: allSelectedServices,
    totalServicesUnits,
    currentService,
  } = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const selectedServices: number[] = getSelectedAnnotatableServiceIds(allSelectedServices, services);

  const { id, photos, annotatedPhotos, opened } = serviceVisit;
  const { serviceVisits } = useSelector(({ patient }: any) => patient);
  const [image, setImage] = useState(imageInitialState);
  const [photoList, setPhotoList] = useState<any>([]);
  const [listAnnotationItems, setListAnnotationItems] = useState<any>([]);
  const [color, setColor] = useState('blue');
  const [openModal, setOpenModal] = useState(false);
  const [onUndo, setOnUndo] = useState(false);
  const [performingUndo, setPerformingUndo] = useState(false);

  useEffect(() => {
    const imageExists = photoList.some((item: any) => item.photo_id === image.photo_id);
    if (image.photo_id && !imageExists) {
      setPhotoList((prevState: any) => [...prevState, { ...image }]);
    }
  }, [image]);

  useEffect(() => {
    const q = serviceVisits
      .filter((sV: any) => sV.id === +params.serviceVisitId) // just use annotation of the current service
      .map((sv: any) =>
        sv.annotatedPhotos.map((item: any) => ({
          photo_id: item.photo.id,
          url: item.photo.largeUrl,
          photo_services: item.photoServices,
        }))
      )
      .flatMap((item: any) => item);

    setPhotoList(q);
  }, [serviceVisits]);

  useEffect(() => {
    const photoServices = photoList.filter((pList: any) => pList.photo_id === image.photo_id);
    setListAnnotationItems(photoServices.map((pS: any) => pS.photo_services) || []);
  }, [photoList, image]);

  useEffect(() => {
    if (serviceVisit.id) {
      const defaultPhoto: any = getPhotos()[0];
      if (defaultPhoto && !image.photo_id) {
        setImage({ photo_id: defaultPhoto.id, url: defaultPhoto.largeUrl, photo_services: [] });
      }
    }
  }, [photos]);

  const getServices = () => {
    let filteredServices = [];
    const unitValues = Object.entries(totalServicesUnits).map((unit) => ({ id: unit[0], units: unit[1] }));
    if (unitValues.length > 0) {
      filteredServices = services
        .filter((service: any) => selectedServices.includes(service.id))
        .map((item: any, index: any) => ({ ...item, units: unitValues[index].units }));
    }
    return filteredServices;
  };

  const handleReplaceVariables = (service: any) =>
    replaceVariables(
      service.internalNote,
      { Patient_Name: `${firstName} ${lastName}`, Service_Name: service.name },
      service.description
    );

  const getPhotos = () => {
    const newPhotos: any[] = [];
    const photosIds = photos.map(({ photoId }: any) => photoId);

    photosIds.forEach((photoId: number) => {
      let annotatedPhoto = serviceVisit.annotatedPhotos.find((annotation: any) => annotation.photo.id === photoId);
      if (!annotatedPhoto && patientPhotos) {
        const resultPatient = patientPhotos.find(
          (patientPhoto: any) => patientPhoto.id === photoId && patientPhoto.photoType === PHOTO_TYPE.BEFORE
        );
        if (resultPatient) {
          annotatedPhoto = {
            photo: resultPatient,
          };
        }
      }

      if (annotatedPhoto) {
        newPhotos.push(annotatedPhoto.photo);
      }
    });

    return newPhotos.concat(patientPhotos.filter(({ photoType }: any) => !photoType));
  };

  const onDrawPath = async (paths: any) => {
    const userService = await dispatch.newServiceVisit.getCurrentService();

    if (userService.id) {
      setPhotoList((prevState: any) => {
        const photoIndex = photoList.findIndex((item: any) => item.photo_id === image.photo_id);
        const prevImages = JSON.parse(JSON.stringify(prevState));

        const item = prevImages.find((prevImage: any) => prevImage.photo_id === image.photo_id);
        item.photo_services.forEach((_: any, index: number) => {
          Object.assign(prevImages[photoIndex].photo_services[index], { canvasData: paths });
        });

        return [...prevImages];
      });
    }
  };

  const isSameService = (photoService: any, serviceId: string | number) =>
    photoService.service?.id === serviceId || photoService.service_id === serviceId;

  const onServiceSelect = (service: any) => {
    if (service) {
      dispatch({ type: 'newServiceVisit/updateCurrentService', payload: { service } });
      setColor(service.color);

      setPhotoList((prevState: any) => {
        const photoIndex = photoList.findIndex((photo: any) => photo.photo_id === image.photo_id);
        const prevImages = [...prevState];
        const prevPhotoServices = prevImages[photoIndex]?.photo_services || [];
        const exists = prevPhotoServices.some(
          (item: any) => (get(item, 'service.id') || get(item, 'service_id')) === service.id
        );

        photoList.map((item: any) =>
          item.photo_services.map((i: any) =>
            i.service_id === service.service_id ? Object.assign(i, { note: i.note }) : item
          )
        );

        if (!exists) {
          let noteService;
          prevImages.forEach((prevImage) => {
            prevImage.photo_services.forEach((photoService: any) => {
              if (isSameService(photoService, service?.id)) {
                noteService = photoService.note;
              }
            });
          });

          const newServiceNote = {
            ...service,
            note: noteService || handleReplaceVariables(service),
            service_id: service.id,
          };

          prevImages[photoIndex] = {
            ...prevImages[photoIndex],
            photo_services: prevPhotoServices ? [...prevPhotoServices, newServiceNote] : [newServiceNote],
          };
        }
        return [...prevImages];
      });
    }
  };

  const onEditService = (event: any, service: any) => {
    event.persist();
    setPhotoList((prevState: any) => {
      const photoIndex = photoList.findIndex((item: any) => item.photo_id === image.photo_id);
      const prevImages = [...prevState];
      const serviceIndex = photoList[photoIndex].photo_services.findIndex((item: any) => item.id === service.id);
      const units = event.target.name === 'units' && { units: event.target.value };
      const prevPhotoService = prevImages[photoIndex].photo_services[serviceIndex];

      Object.assign(prevPhotoService, {
        note: handleReplaceVariables(service),
        [event.target.name]: event.target.value,
        ...units,
      });

      prevImages.map((prevImage: any) =>
        prevImage.photo_services.map((item: any) => {
          if (isSameService(item, service.service_id || service?.service?.id)) {
            return Object.assign(item, { note: event.target.value });
          }
          return item;
        })
      );

      return [...prevImages];
    });
  };

  const saveServiceVisit = (serviceVisitId: any) => {
    let callback = () => {};
    const charges = photoList
      .map((photo: any) => photo.photo_services)
      .flatMap((item: any) => item)
      .map((item: any) => ({
        units: totalServicesUnits[get(item, 'service.id') || get(item, 'service_id')],
        note: get(item, 'service.note') || get(item, 'note'),
        service_id: get(item, 'service.id') || get(item, 'service_id'),
      }))
      .filter((_charge: any) => _charge.service_id);

    const bodyPhotoList = photoList.map((pL: any) => ({
      ...pL,
      photo_services: pL?.photo_services.filter(
        (pS: any) => (pS.service || pS.service_id) && (pS.canvasData || pS.canvas_data)
      ),
    }));

    if (onUndo) {
      setPerformingUndo(true);
      callback = () => {
        setPerformingUndo(false);
      };
    }

    dispatch({
      type: 'newServiceVisit/saveNewServiceVisit',
      payload: { serviceVisitId, body: bodyPhotoList, charges, callback },
    });

    if (onUndo) {
      setOnUndo(false);
    }
  };

  const onSubmit = () => {
    if (!onUndo) {
      saveServiceVisit(params.serviceVisitId);
    }
  };

  // const handleOpen = () => {
  //   setOpenModal(true);
  // };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onSaveAnnotations = async () => {
    const hasCanvasFilter = photoList.find((item: any) => {
      const result = item.photo_services.find(
        (photoService: any) =>
          photoService &&
          (get(photoService, 'service.id') || get(photoService, 'service_id')) === currentService.id &&
          !!(photoService.canvasData || photoService.canvas_data)
      );

      return !!result;
    });

    if (id && currentService.id && !!hasCanvasFilter) {
      saveServiceVisit(params.serviceVisitId);
    }
  };

  useEffect(() => {
    onSaveAnnotations();
  }, [photoList]);

  const deletedAnnotated = () => {
    const annotatedPhoto = annotatedPhotos.find(({ photo }: any) => photo.id === image.photo_id);

    if (annotatedPhoto) {
      dispatch({
        type: 'newServiceVisit/deleteAnnotated',
        payload: { service_annotated_photo_id: annotatedPhoto.id, photoId: image.photo_id },
      });

      setPhotoList((prevState: any) => {
        const photoIndex = photoList.findIndex((item: any) => item.photo_id === image.photo_id);
        const prevImages = [...prevState];
        prevImages[photoIndex].photo_services = [];
        return [...prevImages];
      });
    }
  };

  const updateSelectedStep = () => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 10 });
  };

  const undo = () => {
    setOnUndo(true);
  };

  const selectPhoto = (paramImage: any) => {
    setImage(paramImage);
    dispatch.newServiceVisit.updateOldAnnotations();
  };

  // const onSaveOrEdit = useCallback(
  //   debounce(() => saveServiceVisit(params.serviceVisitId), 2000),
  //   []
  // );

  return (
    <ActiveStepWrapper step={10} onClick={updateSelectedStep}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box display="flex" alignItems="start">
            <Box mr="auto">
              <SmallTitle title="Medical charting" className={classes.smallTitle} />
              <p style={{ color: 'gray', fontFamily: 'Messina Sans Regular', fontSize: '14px', marginTop: '6px' }}>
                If you charged{' '}
                <b>
                  {lastName}, {firstName}
                </b>{' '}
                for this visit, you may chart later via your to-do list
              </p>
            </Box>
            <div>
              {/* TODO: We'll need this later */}
              {/* <IconButton onClick={handleOpen}>
                <ZoomOutMapIcon style={{ fontSize: 48, color: 'black' }} />
              </IconButton> */}
            </div>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={2}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Box style={{ height: '445px' }}>
                    {photoList && photoList[0] && (
                      <div key={image.photo_id}>
                        <ImageEditor
                          image={image}
                          imageUrl={image.url}
                          brushColor={color}
                          getPaths={onDrawPath}
                          photoServicesList={listAnnotationItems}
                          onUndo={undo}
                          deletedAnnotated={deletedAnnotated}
                          performingUndo={performingUndo}
                        />
                      </div>
                    )}
                  </Box>
                  <Box mt={2} display="block">
                    {getServices().map((service: any) => (
                      <Button
                        title={service.name}
                        key={service.id}
                        onClick={() => onServiceSelect(service)}
                        disabled={!serviceVisit.opened || image.photo_id === ''}
                        style={{
                          ...setButtonServiceStyles(service.color),
                          cursor: !image.photo_id ? 'no-drop' : 'copy',
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={4}>
                    <HistoryImageList handlerSelectPhoto={selectPhoto} image={image} photos={getPhotos()} />
                  </Box>
                  <Box>
                    {photoList.map((photo: any) =>
                      photo.photo_services.map((service: any) => (
                        <Box
                          key={service.id}
                          mb={2.5}
                          style={{
                            paddingLeft: '10px',
                            display: image.photo_id === photo.photo_id ? 'block' : 'none',
                          }}
                        >
                          <Box mb={2.25} display="flex" justifyItems="center">
                            <FiberManualRecord
                              htmlColor={`${service.service ? service.service.color : service.color}`}
                            />
                            <Typography style={{ fontSize: '15px', marginLeft: '8px', color: '#000' }}>
                              {service.service ? service.service.name : service.name}
                            </Typography>
                          </Box>
                          <Box display="flex" mb={2}>
                            <Box width="50" mr={1} display="flex" alignItems="center">
                              <Box mr={2}>
                                <TextField
                                  value={totalServicesUnits[service.service_id || get(service, 'service.id')]}
                                  name="units"
                                  placeholder={totalServicesUnits[service.service_id || get(service, 'service.id')]}
                                  variant="outlined"
                                  type="number"
                                  size="medium"
                                  disabled={!opened}
                                />
                              </Box>
                              {errors.units && errors.units.type === 'required' && 'Number must be between 1 and 10'}
                            </Box>
                          </Box>
                          <Box>
                            <TextareaAutosize
                              name="note"
                              onChange={(event) => onEditService(event, service)}
                              onBlur={() => saveServiceVisit(params.serviceVisitId)}
                              placeholder="Notes"
                              defaultValue={handleReplaceVariables(service)}
                              value={service.note}
                              style={TextAreaStyles}
                              disabled={!opened}
                            />
                          </Box>
                        </Box>
                      ))
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <CustomerInternalNotes />
          </form>
        </CardContent>
      </Card>
      <FullChartingImageModal
        open={openModal}
        handleClose={handleClose}
        image={image}
        color={color}
        photoList={photoList}
        currentService={currentService.Id}
        onDrawPath={onDrawPath}
        services={getServices()}
        onServiceSelect={onServiceSelect}
        photos={getPhotos()}
        setImage={setImage}
        saveServiceVisit={saveServiceVisit}
        photoServicesList={listAnnotationItems}
        performingUndo={performingUndo}
        deletedAnnotated={deletedAnnotated}
        undo={undo}
      />
    </ActiveStepWrapper>
  );
};

export default MedicalChartingImages;
