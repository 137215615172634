import { makeStyles, TextField, withStyles } from '@material-ui/core';

export const CustomTextFiled = withStyles(() => ({
  root: {
    color: '#000000',
    textAlign: 'center',
    borderColor: '#979797',
    fontFamily: 'Messina Sans Regular',
  },
  disabled: {
    color: '#979797',
    textAlign: 'center',
    borderColor: '#979797',
    fontFamily: 'Messina Sans Regular',
  },
}))(TextField);

export const useStyles = makeStyles((theme: any) => ({
  checkbox: {
    color: '#12574d',
  },
  title: {
    fontSize: '18px',
    fontFamily: 'Messina Sans SemiBold',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  formControl: {
    width: '100%',
  },
  formInput: {
    color: '#000000',
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
  },
  formInputLoading: {
    color: '#000000',
    opacity: '0.70',
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
  },
  quantity: {
    width: '100%',
  },
  add: {
    width: '100%',
    height: '55px',
  },
  yourOrder: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '14px',
    color: '#393B3F',
    marginLeft: theme.spacing(2),
  },
  tableContainer: {
    fontFamily: 'Messina Sans Regular',
    overflowX: 'auto',
  },
  table: {
    tableLayout: 'fixed',
    height: 'auto',
    width: '100%',
    border: '1px solid #E7E7E7',
  },
  rowHeight: {
    height: '56px',
  },
  inputProps: {
    color: '#000000',
    textAlign: 'center',
    borderColor: '#979797',
    fontFamily: 'Messina Sans Regular',
  },
  disabledInputProps: {
    color: '#979797',
    textAlign: 'center',
    borderColor: '#979797',
    fontFamily: 'Messina Sans Regular',
  },
  orderBanner: {
    height: '53px',
    lineHeight: '53px',
  },
  button: {
    height: '47px',
  },
  downloadButton: {
    width: '200px',
    marginRight: '20px',
    color: '#12574d',
    float: 'right',
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  dollarSign: {
    '& > p': {
      color: '#000000',
      marginTop: 0,
      position: 'relative',
      left: '8px',
    },
  },
  disabledDollarSign: {
    '& > p': {
      color: '#979797',
      marginTop: 0,
      position: 'relative',
      left: '8px',
    },
  },
  hideMenuItem: {
    display: 'none',
  },
  formInputSelect: {
    color: '#000000',
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    top: '22px',
    position: 'absolute',
  },
  errorText: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    marginLeft: '14px',
    marginRight: '14px',
  },
  clipboardLink: {
    marginTop: '8px',
    color: '#2172BB',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    textDecoration: 'underline',
  },
}));
