import { createModel } from '@rematch/core';
import moment from 'moment';
import { chat } from '../mock/Chat';
import { RootModel } from './rootModel';

export const chatModel = createModel<RootModel>()({
  state: {
    messages: [],
  },
  reducers: {
    updateState(state: any, payload: any) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch: any) => ({
    loadChat(_payload: any) {
      const response = chat;
      dispatch.chatModel.updateState({ messages: response });
    },
    addFileMessage(payload: any[], rootState: any) {
      const newMessages = payload.map((file: any) => ({
        type: 'IMAGE',
        data_url: file.data_url,
        isBot: false,
        createdAt: moment().format('MM/DD/YYYY hh:mm'),
      }));
      rootState.chatModel.messages.push(...newMessages);
    },
    addMessage(payload: string, rootState: any) {
      const newMessage = payload;
      const messages = rootState.chatModel.messages.push({
        type: 'TEXT',
        createdAt: moment().format('MM/DD/YYYY hh:mm'),
        isBot: false,
        message: newMessage,
      });
      dispatch.chatModel.updateState({ messages });
    },
  }),
});
