import React from 'react';

// Components
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TabDataObject, TabFrame } from '../../components/DashboardPractitioner/Tabs/TabFrame';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import ServiceGroupsList from '../../components/PractitionerConfiguration/ServiceGroupsList';
import { CONSENTS_TITLE, STANDING_ORDERS_TITLE } from '../../constants/configuration.constants';

// Hooks
import { usePractitionerConsents } from '../../hooks/queries/useConsents';
import { usePractitionerStandingOrders } from '../../hooks/queries/useStandingOrders';

// Routes
import { PRACTITIONER_CONFIGURATION_PATH, PRACTITIONER_HOME_PATH } from '../../routes/practitionerRoutes';
import { hasAccessTo } from '../../utils/auth.utils';

// Constants
import {
  CONSENT_READ,
  CONSENT_UPDATE,
  STANDING_ORDER_READ,
  STANDING_ORDER_UPDATE,
} from '../../constants/actions.constants';

const Configuration = () => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const { data: practitionerConsents, isLoading: consentsLoading } = usePractitionerConsents();
  const { data: practitionerStandingOrders, isLoading: standingOrdersLoading } = usePractitionerStandingOrders();

  const history = useHistory();

  const navigateBack = () => {
    history.push(PRACTITIONER_HOME_PATH);
  };

  const tabs: TabDataObject = {
    consents: {
      label: 'Consents',
      order: 0,
      target: (
        <ServiceGroupsList
          title={CONSENTS_TITLE}
          practitionerDocument={practitionerConsents || []}
          isLoading={consentsLoading}
        />
      ),
    },
    standingOrders: {
      label: 'Standing Orders',
      order: 1,
      target: (
        <ServiceGroupsList
          title={STANDING_ORDERS_TITLE}
          practitionerDocument={practitionerStandingOrders || []}
          isLoading={standingOrdersLoading}
        />
      ),
    },
  };

  if (
    !hasAccessTo(CONSENT_READ, permissions) &&
    !hasAccessTo(CONSENT_UPDATE, permissions) &&
    !hasAccessTo(STANDING_ORDER_READ, permissions) &&
    !hasAccessTo(STANDING_ORDER_UPDATE, permissions)
  ) {
    navigateBack();
  }

  return (
    <main>
      <NavigationBar title="Configuration" onBackButtonClick={navigateBack} />
      <TabFrame tabs={tabs} path={PRACTITIONER_CONFIGURATION_PATH} />
    </main>
  );
};

export default Configuration;
