import { isNil, omitBy } from 'lodash';
import { months } from 'src/constants/general.constants';
import Api from './Api';
import { ITransaction } from '../interfaces/ITransaction.interfaces';
import { IEarningCalculator } from '../interfaces/IEarningCalcultator';
import { PossibleTransactionResponse } from '../hooks/queries/usePractitionersDashboard';

export type IResponse = {
  transactions: ITransaction[];
  meta: {
    currentPage: number;
    totalPages: number;
    totalCount: number;
    hasMorePages: boolean;
  };
};

export type IGraphType = {
  xAxisLabels: string[];
  series: {
    name: string;
    data: any[];
  }[];
};

export type IPractitionerDashboardGraphs = {
  revenue: IGraphType;
  earnings: IGraphType;
  appointments: IGraphType;
  appointmentsAverage: IGraphType;
};

export type IEarningResponse = {
  firstReport: Date | string;
  earningsTiers: {
    retail: IEarningCalculator[];
    services: IEarningCalculator[];
    tips: IEarningCalculator[];
    tier5Percent: IEarningCalculator[];
    tier10Percent: IEarningCalculator[];
  };
  totals: {
    retail: number;
    services: number;
    tier5Percent: number;
    tier10Percent: number;
    tips: number;
    totalEarnings: number;
    amountBelowPriceFloor: number;
    bbDiscountedWholesaleTotal: number;
  };
  baseCalculations: {
    totalServicesWithDiscount: number;
  };
};

export const getTransactionsPractitioners = async (query: any): Promise<IResponse> => {
  const { month } = query;
  const paramsList = [];

  paramsList.push(`month=${month}`);

  const { transactions = [], meta, error } = await Api.get(`sumarize_transactions?${paramsList.join('&')}`);

  if (error) {
    throw error;
  }

  return { transactions, meta };
};

export const getPossibleTransactionsPractitioners = async (query: any): Promise<PossibleTransactionResponse> => {
  const params = omitBy(query, isNil);
  return Api.get(`/v3/checkouts/flex_earnings`, params);
};

export const getEarningsPractitioners = async (month: string): Promise<IEarningResponse> => {
  const { earningsTiers, totals, baseCalculations, firstReport } = await Api.get(
    `/v3/earnings_calculations?month=${month}`
  );
  return { earningsTiers, totals, baseCalculations, firstReport };
};

export const getGraphDataPractitioners = async (period: string): Promise<IPractitionerDashboardGraphs> => {
  const { revenue, earnings, appointments, appointmentsAverage } = await Api.get(
    `/v3/year_statistics?period=${period}`
  );

  const revenueDataParsed = parseDataForGraph(revenue);
  const earningsDataParsed = parseDataForGraph(earnings);
  const appointmentsDataParsed = parseDataForGraph(appointments);
  const appointmentsAverageDataParsed = parseDataForGraph(appointmentsAverage);

  return {
    revenue: {
      ...revenueDataParsed,
    },
    earnings: {
      ...earningsDataParsed,
    },
    appointments: {
      ...appointmentsDataParsed,
    },
    appointmentsAverage: {
      ...appointmentsAverageDataParsed,
    },
  };
};

export const parseDataForGraph = (serie: any, period?: string): IGraphType => {
  const { name, data = [] } = serie;
  const result = [];
  let obj = {};

  if (period) {
    const [monthString, yearString] = period.split('-');
    const month = parseInt(monthString, 10);
    const year = parseInt(yearString, 10);
    const date = new Date(year, month - 1, 1);
    const lowerLimit = date.getMonth() - 5;
    const upperLimit = date.getMonth() + 6;
    date.setMonth(lowerLimit);

    for (let i = 0; i < upperLimit; i++) {
      // Build the keys to match how they come from API
      const monthName = months[date.getMonth()].toLowerCase();
      const fullYear = String(date.getFullYear()).slice(2);
      result.push(`${monthName} '${fullYear}`);
      date.setMonth(date.getMonth() + 1);
    }
  }
  result.forEach((date) => {
    obj = {
      ...obj,
      [date]: data[date] ?? 0,
    };
    return obj;
  });

  const xAxisLabels = Object.keys(period ? obj : data);
  const seriesData = Object.values(period ? obj : data);

  return {
    xAxisLabels,
    series: [
      {
        name,
        data: seriesData,
      },
    ],
  };
};
