import React, { useEffect, useState } from 'react';
import { Controller, UseFormMethods, useFieldArray } from 'react-hook-form';
import { Box, Grid, InputLabel, FormControl, Select, MenuItem, FormHelperText } from '@material-ui/core';
import IServices from 'src/interfaces/IServices';
import { EHRButton } from 'src/components/ui/v1';
import OrderingTooltip from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderingTooltip';
import { NewTextField, useStyles } from './specialsModal.styles';
import { SpecialOfferFormData } from './NewSpecialModal';

interface DiscountSectionProps {
  filteredProducts: IServices[];
  isLoadingProducts: boolean;
  methods: UseFormMethods<SpecialOfferFormData>;
}

const OfferDiscountSection = ({ filteredProducts, isLoadingProducts, methods }: DiscountSectionProps) => {
  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'discounts',
  });

  const onAdd = () => append({ productId: '', quantity: 1, discount: 0 });
  const onDelete = () => remove(fields.length - 1);

  return (
    <>
      {fields.map((field, index) => (
        <OfferDiscount
          key={field.id}
          sectionIndex={index}
          methods={methods}
          filteredProducts={filteredProducts}
          isLoadingProducts={isLoadingProducts}
        />
      ))}
      <Box padding="12px" marginTop="1rem">
        <EHRButton
          dataCy="add-product"
          style={{ width: '100%', padding: '18px' }}
          color="text"
          variant="outlined"
          onClick={onAdd}
          text="Add new product"
        />
      </Box>

      {fields.length > 1 && (
        <Box padding="12px">
          <EHRButton
            dataCy="delete-product"
            style={{
              width: '100%',
              padding: '18px',
              color: '#FD4A4A',
              border: '1px solid #FFA9A9',
              backgroundColor: '#FFF7F7',
            }}
            color="text"
            variant="outlined"
            onClick={onDelete}
            text="Delete Product"
          />
        </Box>
      )}
    </>
  );
};

interface OfferDiscountProps {
  filteredProducts: IServices[];
  isLoadingProducts: boolean;
  sectionIndex: number;
  methods: UseFormMethods<SpecialOfferFormData>;
}

const OfferDiscount = ({ sectionIndex, filteredProducts, isLoadingProducts, methods }: OfferDiscountProps) => {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState<IServices | null>(null);

  const discountFieldName = `discounts.${sectionIndex}`;

  const {
    watch,
    control,
    setValue,
    register,
    setError,
    formState: { errors },
    trigger,
  } = methods;

  const watchProductId = watch(`${discountFieldName}.orderProductId`);
  const watchDiscount = watch(`${discountFieldName}.discount`);
  const marketPrice = selectedProduct?.listPrice;
  const finalPrice = Number(marketPrice) - Number(watchDiscount);

  useEffect(() => {
    setSelectedProduct(filteredProducts.find((product) => product.id === Number(watchProductId)) || null);
  }, [watchProductId, filteredProducts]);

  useEffect(() => {
    // remove orderProductId if the product is not in the filteredProducts
    if (filteredProducts.length > 0 && !filteredProducts.find((product) => product.id === Number(watchProductId))) {
      setValue(`${discountFieldName}.orderProductId`, '');
    }
  }, [filteredProducts]);

  return (
    <>
      <Box display="flex" padding="12px" style={{ gap: '1rem' }}>
        <Grid item xs={8}>
          <InputLabel color="secondary" focused={false} htmlFor="product-select" className={classes.label}>
            Products
          </InputLabel>
          <FormControl fullWidth variant="outlined" className={classes.editGroupSelect}>
            <Controller
              name={`${discountFieldName}.orderProductId`}
              control={control}
              error={!!errors?.discounts?.[sectionIndex]?.orderProductId}
              as={
                <Select id="product-select" fullWidth>
                  {filteredProducts?.map(({ id, name }) => (
                    <MenuItem data-cy="product" key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              }
              disabled={isLoadingProducts}
            />
            {errors?.discounts?.[sectionIndex]?.orderProductId && (
              <FormHelperText error>{errors?.discounts?.[sectionIndex]?.orderProductId?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <InputLabel color="secondary" focused={false} className={classes.label}>
            Qty
          </InputLabel>
          <NewTextField
            name={`${discountFieldName}.quantity`}
            type="number"
            variant="outlined"
            error={!!errors?.discounts?.[sectionIndex]?.quantity}
            helperText={errors?.discounts?.[sectionIndex]?.quantity?.message || ''}
            onChange={(e) => setValue(`${discountFieldName}.quantity`, Number(e.target.value))}
            inputRef={register}
          />
        </Grid>
      </Box>

      <Box display="flex" padding="12px" style={{ gap: '1rem' }}>
        <Grid item xs={4}>
          <InputLabel color="secondary" focused={false} className={classes.label}>
            Market Price
            <OrderingTooltip title="The price of the product outside of Portrait in the market." />
          </InputLabel>
          <NewTextField
            placeholder="$"
            name={`${discountFieldName}.marketPrice`}
            variant="outlined"
            type="number"
            value={marketPrice}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel color="secondary" focused={false} className={classes.label}>
            Discount
            <OrderingTooltip title="The total discount applied to this special, calculated over the total price." />
          </InputLabel>
          <NewTextField
            placeholder="$"
            type="number"
            name={`${discountFieldName}.discount`}
            variant="outlined"
            error={!!errors?.discounts?.[sectionIndex]?.discount}
            helperText={errors?.discounts?.[sectionIndex]?.discount?.message || ''}
            onChange={(e) => {
              const newDiscount = Number(e.target.value);
              if (newDiscount > Number(marketPrice)) {
                setError(`${discountFieldName}.discount`, {
                  type: 'custom',
                  message: 'Discount should be less than Market Price',
                });
              } else {
                setValue(`${discountFieldName}.discount`, newDiscount);
                trigger(`${discountFieldName}.discount`);
              }
            }}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel color="secondary" focused={false} className={classes.label}>
            Final Price
            <OrderingTooltip title="The final cost of the special that users will pay." />
          </InputLabel>
          <NewTextField
            placeholder="$"
            name={`${discountFieldName}.finalPrice`}
            variant="outlined"
            type="number"
            value={finalPrice}
            disabled
          />
        </Grid>
      </Box>
    </>
  );
};

export default OfferDiscountSection;
