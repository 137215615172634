import React, { useState } from 'react';

import { partition } from 'lodash';
import { useSelector } from 'react-redux';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';

import { useMedspaCustomCareMessages } from 'src/hooks/queries/useMedspaCareMessages';
import { useMedspaConsents } from 'src/hooks/queries/useMedspaConsents';
import { useMedspaStandingOrders } from 'src/hooks/queries/useMedspaStandingOrders';

import {
  CARE_MESSAGES_DOCUMENT_TYPE,
  CONSENTS_DOCUMENT_TYPE,
  OTHER_DOCUMENT_TYPE,
  STANDING_ORDERS_DOCUMENT_TYPE,
} from 'src/constants/medspa/documentsManagement.constants';
import Page from 'src/components/common/Page';
import { useStyles } from './documentsManagement.styles';
import DocumentTable from './Components/DocumentTable';
import { OtherDocuments } from './Components/OtherDocuments';

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const DocumentsManagement = () => {
  const classes = useStyles();
  const {
    user: { userGroupId },
  } = useSelector(({ auth }: any) => auth);
  const [tab, setTab] = useState(0);

  const { data: consents = [], isLoading: isLoadingConsents } = useMedspaConsents(userGroupId);
  const { data: standingOrders = [], isLoading: isLoadingStandingOrders } = useMedspaStandingOrders(userGroupId);
  const { data: careMessages = [], isLoading: isLoadingCustomCareMessages } = useMedspaCustomCareMessages(
    userGroupId,
    !!userGroupId
  );

  const handleTabChange = (event: any, newTab: number) => {
    setTab(newTab);
  };

  const careMessagesWithContent = careMessages?.map((careMessage) => ({
    ...careMessage,
    content:
      careMessage.preCare.introductoryMessage +
      careMessage.preCare.mandatoryMessage +
      careMessage.preCare.endingMessage +
      careMessage.postCare.introductoryMessage +
      careMessage.postCare.mandatoryMessage +
      careMessage.postCare.endingMessage,
  }));

  const [customConsents, masterLibraryConsents] = partition(consents, (o) => o.userGroupId);
  const [customStandingOrders, masterLibraryStandingOrders] = partition(standingOrders, (o) => o.userGroupId);
  const [customCareMessages, masterLibraryCustomCareMessages] = partition(
    careMessagesWithContent,
    (o) => o.userGroupId
  );

  const componentLayout = ({ name, customData, isLoadingCustomData, masterLibraryData }: any) => (
    <>
      <Box className={classes.layoutContainer}>
        <Typography className={classes.sidesPadding} component="h1">
          {name}
        </Typography>
        <p className={[classes.customParagraph, classes.sidesPadding].join(' ')}>
          Your custom {name} you created during new service group...
        </p>
        <DocumentTable documentType={name} documents={customData} isLoadingDocuments={isLoadingCustomData} />
      </Box>

      {!!masterLibraryData && (
        <Box className={classes.layoutContainer}>
          <Typography className={classes.sidesPadding} component="h1">
            Master Library
          </Typography>
          <p
            className={[classes.customParagraph, classes.sidesPadding].join(' ')}
            style={{ fontWeight: 400, fontSize: '13px' }}
          >
            You can’t edit Master Library items...{' '}
          </p>
          <DocumentTable
            documentType={name}
            isFromMasterLibrary
            documents={masterLibraryData}
            isLoadingDocuments={isLoadingCustomData}
          />
        </Box>
      )}
    </>
  );

  return (
    <Page title="Documents">
      <Box width="100%" padding="10px">
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label={CONSENTS_DOCUMENT_TYPE} />
          <Tab label={STANDING_ORDERS_DOCUMENT_TYPE} />
          <Tab label={CARE_MESSAGES_DOCUMENT_TYPE} />
          <Tab label={OTHER_DOCUMENT_TYPE} />
        </Tabs>

        <TabPanel value={tab} index={0}>
          {componentLayout({
            name: CONSENTS_DOCUMENT_TYPE,
            customData: customConsents,
            isLoadingCustomData: isLoadingConsents,
            masterLibraryData: masterLibraryConsents,
          })}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {componentLayout({
            name: STANDING_ORDERS_DOCUMENT_TYPE,
            customData: customStandingOrders,
            isLoadingCustomData: isLoadingStandingOrders,
            masterLibraryData: masterLibraryStandingOrders,
          })}
        </TabPanel>
        <TabPanel value={tab} index={2}>
          {componentLayout({
            name: CARE_MESSAGES_DOCUMENT_TYPE,
            customData: customCareMessages,
            isLoadingCustomData: isLoadingCustomCareMessages,
            masterLibraryData: masterLibraryCustomCareMessages,
          })}
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <OtherDocuments />
        </TabPanel>
      </Box>
    </Page>
  );
};

export default DocumentsManagement;
