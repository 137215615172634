import { useQuery, UseQueryResult } from 'react-query';
import { MonthlyBlockers } from '../../services/MonthlyBlockers';
import { IMonthlyBlocker } from '../../interfaces/IMonthlyBlocker';
import { MONTHLY_BLOCKERS } from '../../constants/reactQuery.keys';

const useMonthlyBlockers = (practitionerId: string | null | undefined): UseQueryResult<IMonthlyBlocker[]> =>
  useQuery<IMonthlyBlocker[]>({
    queryKey: [MONTHLY_BLOCKERS, practitionerId],
    queryFn: (): Promise<IMonthlyBlocker[]> => MonthlyBlockers.getBlockers(practitionerId),
  });

export { useMonthlyBlockers, useMonthlyBlockers as default };
