import { createModel } from '@rematch/core';
import { OverviewState } from '../types/OverviewState';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import compile from '../utils/toastMessagesCompiler';

const overviewInitialState = {
  headerServices: [],
  warehouses: [],
} as OverviewState;

export const overview = createModel<RootModel>()({
  state: overviewInitialState,
  reducers: {
    setTotalProducts(state: OverviewState, payload: any) {
      return { ...state, headerServices: payload };
    },
    setWarehouses(state: OverviewState, payload: any) {
      return { ...state, warehouses: payload };
    },
    setReset() {
      return overviewInitialState;
    },
  },
  effects: (dispatch: any) => ({
    async getTotalProducts(_payload: any, _rootState: any) {
      try {
        const request = await axiosInstance.get('/assets/overview');
        dispatch.overview.setTotalProducts(request.data.totalProducts);
        dispatch.overview.setWarehouses(request.data.warehouses);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'loading',
              element: 'the overview',
            }),
            type: 'error',
          },
        });
      }
    },
  }),
});
