import React from 'react';
import { Modal } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useStyles } from './MembershipConsentAgreementModal.styles';

export const MembershipCosentAgreementModal = ({ consentName, consentContent, open, onClose }: any) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.photoConsentAgreementModal}>
      <div style={{ backgroundColor: '#e7eeed', height: '100%', overflowY: 'scroll' }}>
        <div>
          <div>
            <CloseIcon className={classes.closeIcon} onClick={onClose} />
            <div className={classes.consentTitle}> {consentName}</div>
          </div>
          <hr style={{ border: '0.5px solid lightgray', marginTop: '25px', marginBottom: '20px' }} />
          <div
            className={classes.consentContent}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: consentContent as string }}
          />
          <hr style={{ border: '0.5px solid lightgray', marginTop: '40px', marginBottom: '20px' }} />
        </div>
      </div>
    </Modal>
  );
};
