import React, { useEffect, useState } from 'react';
import { FormControlLabel, Radio, Collapse, RadioGroup, makeStyles, Box } from '@material-ui/core';
import { EHRTypography } from '../ui/v1';

interface RadioButtonDetails {
  label: string;
  component: React.ReactNode;
  cta?: () => void;
}

interface ExpandableRadioGroupProps {
  radioButtonDetails: RadioButtonDetails[];
  defaultSelection?: number;
  onOptionChange: (index: number) => void;
  disabled?: boolean;
}

const useStyles = makeStyles({
  buttonContainer: {
    margin: '10px 0px',
    display: 'flex',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '20px 10px',
    border: '1px solid lightgray',
  },
  borderHelper: {
    border: '1px solid #12574d',
  },
});

const ExpandableRadioGroup = ({
  radioButtonDetails,
  defaultSelection,
  onOptionChange,
  disabled = false,
}: ExpandableRadioGroupProps) => {
  const [value, setValue] = useState<number | undefined>();
  const classes = useStyles();

  const handleValue = (radioValue: number) => {
    setValue(radioValue);
  };

  const getClasses = (index: number): string => {
    const { buttonContainer, borderHelper } = classes;
    return `${buttonContainer} ${value === index ? borderHelper : ''}`;
  };

  const handleClick = (index: number, details: RadioButtonDetails) => {
    setValue(index);
    if (value !== index) {
      onOptionChange(index);
      details.cta?.();
    }
  };

  useEffect(() => {
    setValue(defaultSelection);
  }, [defaultSelection]);

  return (
    <RadioGroup value={value ?? ''} onChange={(_, val) => handleValue(parseInt(val, 10))}>
      {radioButtonDetails.map((button, index) => (
        <>
          <FormControlLabel
            key={button.label}
            value={index}
            control={<Radio color="primary" disabled={disabled} />}
            disabled={disabled}
            label={
              <EHRTypography dataCy="radio-group-label" variant="body2">
                {button.label}
              </EHRTypography>
            }
            className={getClasses(index)}
            onClick={() => (disabled ? null : handleClick(index, button))}
          />
          <Collapse in={value === index} timeout="auto" unmountOnExit>
            <Box margin="20px 0">{button.component}</Box>
          </Collapse>
        </>
      ))}
    </RadioGroup>
  );
};

export default ExpandableRadioGroup;
