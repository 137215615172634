import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  annotationPhoto: {
    minWidth: '318px',
    '@media (max-width: 767px)': {
      minWidth: '90%',
    },
  },
  annotationForm: {
    padding: '10px',
    '@media (max-width: 767px)': {
      minWidth: '90%',
    },
  },
  card: {
    margin: '0',
    borderRadius: '9px 9px 0 0',
  },
  cardContent: {
    padding: '11px 20px 9px !important',
  },
  contentMedicalCharting: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    backgroundColor: '#12574d',
    color: 'white !important',
    width: 'auto',
    height: '47px',
    marginRight: 0,
  },
});
