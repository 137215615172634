import React from 'react';
import Photos from '../PatientProfile/Photos';
import {
  MAX_ITEMS_PHOTOS_LIMIT_MOBILE,
  ITEMS_PHOTOS_LIMIT_DESKTOP,
  WIDTH_MAX_DESKTOP,
} from '../../constants/general.constants';
import useWindowDimensions from '../../utils/useWindowDimensions';

const cardContentStyle = {
  padding: '11px 20px 20px 20px',
};

export const HistoricalPhotosStep = ({ photos }: { photos: any }) => {
  const { width } = useWindowDimensions();

  return (
    <Photos
      inputFileId="historicalPhoto"
      cardStyles={{ marginBottom: '0' }}
      cardContentStyle={cardContentStyle}
      title="All photos"
      photos={photos || []}
      isLoading={false}
      showUploadPhotoButton={false}
      itemsPhotos={width > WIDTH_MAX_DESKTOP ? ITEMS_PHOTOS_LIMIT_DESKTOP : MAX_ITEMS_PHOTOS_LIMIT_MOBILE}
    />
  );
};
