import { Grid, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DragAndDropComponent from 'src/components/DragAndDrop';
import FilesList from 'src/components/DragAndDrop/FilesList';
import { EHRTypography } from 'src/components/ui/v1';
import { FileDetails } from 'src/interfaces/onboarding.interface';

interface DocumentFormProps {
  fileHasErrors: boolean;
  handleFileInput: (files: File[]) => void;
  file?: FileDetails;
  handleRemoveFile?: () => void;
  isFormDisabled?: boolean;
}

const DocumentForm = ({
  fileHasErrors,
  handleFileInput,
  file,
  handleRemoveFile,
  isFormDisabled = false,
}: DocumentFormProps) => {
  const [fileName, setFileName] = useState<string | null>();

  const handleRemove = () => {
    setFileName(null);
    handleRemoveFile?.();
  };

  useEffect(() => {
    setFileName(file?.fileName);
  }, [file?.fileName, file?.url]);

  return (
    <Grid container>
      <Box marginTop="20px">
        <EHRTypography variant="body2" dataCy="" style={{ color: 'grey' }}>
          Please provide one of the following documents with your business name (DBA):
          <ol>
            <li>
              <strong>Signed lease</strong> with business name and shipping address, signed by both the Lessee and
              Lessor.
            </li>
            <li>
              <strong>Utility bill </strong>with business name and shipping address.
            </li>
            <li>
              <strong>Photo of the building exterior</strong> showing business name and shipping address.
            </li>
            <li>
              <strong>Invoice</strong> with business name and shipping address (e.g., purchase order, moving invoice,
              business taxes).
            </li>
          </ol>
        </EHRTypography>
      </Box>
      <Box width="100%">
        {!fileName ? (
          <DragAndDropComponent
            acceptedFileType="image/png,image/jpg,image/jpeg,application/pdf"
            onFileChange={(files) => {
              const { name } = files?.[0];
              setFileName(name);
              handleFileInput(files);
            }}
            error={fileHasErrors}
            hideRemoveFile
          />
        ) : (
          <FilesList
            files={[{ name: fileName || '', url: file?.url as string }]}
            onRemoveDocument={() => handleRemove()}
            hideRemoveFile={isFormDisabled}
          />
        )}
      </Box>
    </Grid>
  );
};

export default DocumentForm;
