export const NOT_CLEARED_STATUS = 'to_clear';
export const TO_RECLEAR = 'to_reclear';
export const CLEARED_STATUS = 'cleared';
export const INCOMPLETE_STATUS = 'incomplete';
export const PENDING_RECLEAR = 'pending_reclear';
export const FLEX = 'flex';
export const UNKNOWN_STATUS = 'Unknown' || undefined || '';

export const PATIENT_SCHEDULER_STATUSES = [
  { id: '', text: 'All', color: '#969696', background: '#FFFFFF', border: '#969696' },
  { id: FLEX, text: 'All', color: '#12574D', background: '#FFFFFF', border: '#969696' },
  { id: CLEARED_STATUS, text: 'Cleared', color: '#FFFFFF', background: '#38AFBC' },
  { id: NOT_CLEARED_STATUS, text: 'Not Cleared', color: '#FFFFFF', background: '#FF5252' },
  { id: INCOMPLETE_STATUS, text: 'Incomplete', color: '#FFFFFF', background: '#919498' },
  { id: PENDING_RECLEAR, text: 'Requires Update', color: '#FFFFFF', background: '#EF8B39' },
  { id: TO_RECLEAR, text: 'To Reclear', color: '#FFFFFF', background: '#EF8B39' },
  { id: UNKNOWN_STATUS, text: 'Unknown', color: '#FFFFFF', background: '#FF5252' },
];

export const DROPDOWN_STATUSES: any = {
  all: 'All',
  to_clear: 'Not Cleared',
  incomplete: 'Incomplete',
  cleared: 'Cleared',
  pending_reclear: 'Requires Update',
  '': 'Unknown',
};

export const DROPDOWN_APPOINTMENT_STATUSES: any = {
  all: 'All appointments',
  cancelled: 'Cancelled',
  no_show_appointment: 'No show appointments',
  show_all: 'Show all',
  '': 'Unknown',
};

export const ILIKE_AVAILABILITY_INDICATOR_APP_TYPE = "30 Min Availability Indicator";
