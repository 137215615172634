import { useQuery, UseQueryResult, useMutation } from 'react-query';
import { ILeadUpdateParams, ILeadContactDetails } from '../../interfaces/ILeadLandingPage';
import { CLIENT_LEAD_CONTACT_DETAILS } from '../../constants/reactQuery.keys';
import { ClientLeads } from '../../services/ClientLeads';

export const useContactDetails = (adCampaignKey: string): UseQueryResult<ILeadContactDetails | null> =>
  useQuery<ILeadContactDetails | null>({
    queryKey: [CLIENT_LEAD_CONTACT_DETAILS, adCampaignKey],
    queryFn: (): Promise<ILeadContactDetails | null> => ClientLeads.getContactDetails(adCampaignKey),
  });

export const useUpdateExternalClientLead = () => useMutation<void, unknown, ILeadUpdateParams, unknown>({
  mutationFn: async (params: ILeadUpdateParams): Promise<void> => {
    await ClientLeads.updateExternalClientLead(params);
  },
});
