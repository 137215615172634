import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import formatDate from '../../../utils/formatDate';
import { useStyles } from './signatures.styles';

const PRACTITIONER = 'practitioner';
const COSIGNER = 'cosigner';

type SignatureType = 'practitioner' | 'cosigner';

export default function Signatures({
  closedAt,
  cosigned,
  cosignedBy,
  cosignedAt,
  cosignatureImg,
  signedBy,
  signatureImg,
}: any) {
  const classes = useStyles();

  const [showPractitionerSignature, setShowPractitionerSignature] = useState(true);
  const [showCosignerSignature, setShowCosignerSignature] = useState(true);

  const handleOnErrorLoadSignature = (type: SignatureType) => {
    if (type === PRACTITIONER) {
      setShowPractitionerSignature(false);
    } else {
      setShowCosignerSignature(false);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        Service visit submitted by: {signedBy} - {formatDate(closedAt, 'LLLL')}
      </Grid>
      {cosigned && (
        <>
          <Grid item xs={12}>
            Cosigned by:{' '}
            <>
              {cosignedBy} - {formatDate(cosignedAt, 'LLLL')}
            </>
          </Grid>
        </>
      )}

      <Grid item sm={6} className={classes.signBox}>
        {showPractitionerSignature && (
          <>
            <img
              src={signatureImg}
              alt="Provider sign"
              width={356}
              height={136}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              onError={() => handleOnErrorLoadSignature(PRACTITIONER)}
            />
            <div style={{ alignContent: 'center' }}>{signedBy}</div>
          </>
        )}
      </Grid>

      {cosigned && (
        <Grid item sm={6} className={classes.signBox}>
          {showCosignerSignature && (
            <>
              <img
                src={cosignatureImg}
                alt="Physician sign"
                width={356}
                height={136}
                onError={() => handleOnErrorLoadSignature(COSIGNER)}
              />
              <div>{cosignedBy}</div>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
}
