import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { CardContent, makeStyles } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
// eslint-disable-next-line max-len
import { useCustomIncomingPatientHistoryAcknowledgements } from 'src/hooks/queries/useCustomPatientMedicalHistoryAcknowledgements';
import Patients from '../../services/Patients';
import { Card, SmallTitle, Title } from '../common/card';
import { Button } from '../common/Button';
import { Pill } from '../common/Pill';
import { GENDER } from '../../constants/medicalProfile.constants';
import { calculateAge } from '../../utils/patient.util';
import { RootState, dispatch } from '../../rematch';
import { INCOMING_MEDICAL_HISTORY_ACK } from '../../constants/reactQuery.keys';
import { AddNote } from './AddNote';
import DiscardModal from './Discard';
import StickyCard from '../common/StickyCard';
import compile from '../../utils/toastMessagesCompiler';
import { useStyles as useStickyStyles } from '../common/StickyCard/StickyCard.styles';
import { ShortMultipleSkeleton } from '../common/LoadingSkeleton';

const useStyles = makeStyles(() => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
  },
  subtitle: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '10px 0',
  },
  detail: {
    fontFamily: 'Messina Sans Regular',
  },
  card: {
    position: 'initial',
  },
  medicalHistoryAckCard: {
    position: 'absolute',
    zIndex: 11,
    width: '40%',
    margin: '-46px 6px',
  },
}));

const styles = {
  color: '#000000',
  backgroundColor: '#e7eeed',
  margin: '0 3px',
  paddingRight: '9px',
  paddingLeft: '9px',
};

const activeStyles = {
  color: '#ffffff',
  backgroundColor: '#12574d',
  margin: '0 3px',
  paddingRight: '9px',
  paddingLeft: '9px',
};
const STICKY_CARD_OFFSET_LIMIT = 369;

export const PatientHeader = ({ serviceVisitId, currentPatientId }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    firstName,
    lastName,
    createdAt,
    medicalProfile,
    serviceVisits,
    id,
    phone = 'None',
  } = useSelector(({ patient }: RootState) => patient);
  const { sex, dateOfBirth, isPregnant } = medicalProfile;
  const [lastServiceVisit, setLastServiceVisit] = useState<any>(null);
  const [showAddNote, setShowAddNote] = useState(false);
  const [cardOffset, setCardOffset] = useState<number>(0);
  const [openMedicalAcknowledgement, setOpenMedicalAcknowledgement] = useState<boolean>(false);
  const { data: patientHistoryAcknowledgement } = useCustomIncomingPatientHistoryAcknowledgements(currentPatientId);
  const stickyClasses = useStickyStyles();
  const { isProgressQueueWorking } = useSelector((state: any) => state.newServiceVisit);

  const queryClient = useQueryClient();
  const patientMHAMutation = useMutation(
    async () => {
      if (patientHistoryAcknowledgement) {
        await Patients.markMedicalHistoryAcknowledgementAsViewed(patientHistoryAcknowledgement.id, serviceVisitId);
      }
    },
    {
      onSuccess: () => {
        setOpenMedicalAcknowledgement(false);
        queryClient.invalidateQueries(INCOMING_MEDICAL_HISTORY_ACK);
      },
      onError: () => {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'updating',
              element: 'acknowledgement',
            }),
            type: 'error',
          },
        });
      },
    }
  );

  useEffect(() => {
    if (patientHistoryAcknowledgement?.id) {
      setOpenMedicalAcknowledgement(true);
    }
  }, [patientHistoryAcknowledgement]);

  useEffect(() => {
    setLastServiceVisit(serviceVisits?.filter(({ opened }: any) => !opened).pop() || null);
  }, [serviceVisits]);

  const navigateToFullServiceHistory = () => {
    history.push(`/history/${id}`, { from: history.location.pathname });
  };

  const getPatientData = () => {
    let result = '';
    if (sex) {
      result += `${GENDER[sex]}, `;
    }
    result += calculateAge(dateOfBirth) || '';
    if (dateOfBirth) {
      result += ' - ';
    }
    return `${result} Created ${moment(createdAt).format('MM/DD/YYYY')} - Phone ${phone}`;
  };

  const medicalHistoryAcknowledgementConfirmation = () =>
    patientHistoryAcknowledgement && openMedicalAcknowledgement ? (
      <div
        className={classes.medicalHistoryAckCard}
        style={{
          left:
            cardOffset + STICKY_CARD_OFFSET_LIMIT > window.innerWidth
              ? window.innerWidth - STICKY_CARD_OFFSET_LIMIT
              : cardOffset,
        }}
      >
        <StickyCard
          colorText="#FFFFFF"
          backgroundColorNote="#173F61"
          minWidth="330px"
          title={patientHistoryAcknowledgement.physicianName}
          footer={
            <>
              <div className={stickyClasses.leftFooterText}>
                {moment(patientHistoryAcknowledgement.createdAt).format('MM/DD/YYYY - hh:mm A')}
              </div>
              <Button
                className={stickyClasses.primaryButton}
                title="Acknowledge"
                onClick={() => patientMHAMutation.mutate()}
              />
            </>
          }
        >
          {patientHistoryAcknowledgement.text}
        </StickyCard>
      </div>
    ) : null;

  return (
    <Card className={classes.card}>
      <CardContent style={{ padding: '12px 15px' }}>
        <Button
          style={{ ...styles, marginLeft: '0' }}
          title="FULL SERVICE HISTORY"
          onClick={navigateToFullServiceHistory}
          disabled={isProgressQueueWorking}
        />
        <Button
          style={showAddNote ? activeStyles : styles}
          title="ADD SERVICE NOTE"
          onClick={() => {
            setShowAddNote(true);
          }}
          disabled={isProgressQueueWorking}
        />
        <DiscardModal serviceVisitId={serviceVisitId} currentPatientId={currentPatientId} />
        <div style={{ display: 'initial' }} ref={(el) => el && setCardOffset(el.offsetLeft)}>
          {medicalHistoryAcknowledgementConfirmation()}
        </div>
      </CardContent>
      {showAddNote ? (
        <AddNote
          cancelCallback={() => {
            setShowAddNote(false);
          }}
        />
      ) : (
        <>
          <CardContent style={{ padding: '12px 15px 5px 15px' }}>
            <div className={classes.nameContainer}>
              <Title
                title={`${firstName} ${lastName}`}
                style={{ marginTop: '0', marginBottom: '0', marginRight: '10px' }}
              />
              {isPregnant === 'yes' && <Pill title="Pregnancy" color="white" />}
            </div>
            {createdAt ? (
              <div>
                <h5 className={classes.subtitle}>
                  {getPatientData()} - MRN: <strong>{currentPatientId}</strong>
                </h5>
              </div>
            ) : (
              <ShortMultipleSkeleton length={2} />
            )}
          </CardContent>
          {lastServiceVisit && (
            <CardContent style={{ padding: '15px 15px', borderTop: '1px solid #e3e3e3' }}>
              <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '10px' }}>
                <SmallTitle title="Latest visit:" style={{ margin: '0', paddingRight: '10px' }} />
                <span className={classes.detail}>
                  {moment(lastServiceVisit.originDate || lastServiceVisit.createdAt).format('MM/DD/YYYY')}
                  {` - ${moment(lastServiceVisit.originDate || lastServiceVisit.createdAt).fromNow()}`}
                </span>
              </div>
              {lastServiceVisit.charges && (
                <span className={classes.detail}>
                  {lastServiceVisit.charges
                    .map(({ charge, service }: any) => {
                      const units = Math.trunc(charge.units) * Number(service.multiplier);
                      const unitsRounded = Math.round(units * 10) / 10;
                      const unitLabel = unitsRounded > 1 ? `${service.unitLabel}s` : service.unitLabel;
                      return `${service.name} (${unitsRounded} ${unitLabel})`;
                    })
                    .join(' - ')}
                </span>
              )}
            </CardContent>
          )}
        </>
      )}
    </Card>
  );
};
