import API_ROUTES from '../constants/apiRoutes.constants';
import Api from './Api';

const CheckoutTransaction = {
  async getTransactions(paramsQuery: any): Promise<any> {
    const { results } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS, paramsQuery);
    return results;
  },
  async getTransactionsCount(paramsQuery: any): Promise<number> {
    const { count } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_COUNT, paramsQuery);
    return count;
  },
  async getTransactionsLineItems(paramsQuery: any): Promise<any> {
    const { results } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_LINE_ITEMS, paramsQuery);
    return results;
  },
  async getTransactionsLineItemsCount(paramsQuery: any): Promise<number> {
    const { count } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_LINE_ITEMS_COUNT, paramsQuery);
    return count;
  },
  async getTransactionsDiscount(paramsQuery: any): Promise<any> {
    const { results } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_DISCOUNTS, paramsQuery);
    return results;
  },
  async getTransactionsDiscountCount(paramsQuery: any): Promise<number> {
    const { count } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_DISCOUNT_COUNT, paramsQuery);
    return count;
  },
  async getTransactionsTenders(paramsQuery: any): Promise<number> {
    const { results } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_TENDERS, paramsQuery);
    return results;
  },
  async getTransactionsTenderCount(paramsQuery: any): Promise<number> {
    const { count } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_TENDER_COUNT, paramsQuery);
    return count;
  },
  async getTransactionsAssetsSold(paramsQuery: any): Promise<number> {
    const { results } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_ASSETS_SOLD, paramsQuery);
    return results;
  },
  async getTransactionsAssetsSoldCount(paramsQuery: any): Promise<number> {
    const { count } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_ASSETS_SOLD_COUNT, paramsQuery);
    return count;
  },
  async getCheckoutTransactionsDetails(id: number): Promise<any> {
    const { transactions } = await Api.get(API_ROUTES.CHECKOUT_TRANSACTIONS_DETAILS, { checkoutId: id });
    return transactions;
  },
};

export default CheckoutTransaction;
