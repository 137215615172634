/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Select,
  Grid,
  TextField,
  CircularProgress,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Button,
  MenuItem,
} from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import SideModal from 'src/components/common/NewModal';
import { useStyles } from './styles';
import { appointmentTypeDefaultValues, appointmentTypeSchema } from './schemas';
import { TextAreaStyles } from '../../../components/FullServiceHistory/FullServiceHistoryOpen/CustomerItem.styles';
import { IAppointmentType } from '../../../interfaces/acuity/acuity.interface';
import {
  useCareMessagesSuggestion,
  useCreateAppointmentTypeMutation,
  useUpdateAppointmentTypeMutation,
} from '../../../hooks/acuity/useAcuityAccount';
import MultipleSelector from '../../../components/common/Custom/MultipleSelector';
import { useServiceGroups, useServices } from '../../../hooks/queries/services/useServices';
import { Pill } from '../../../components/common/Pill';
import { copyToClipboard } from '../../../utils/clipboard.utils';
import ConfirmModal from '../../../components/common/ConfirmModal';

interface AppointmentTypeProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentType?: IAppointmentType | null;
}

const AppointmentTypeForm = ({ openDialog, appointmentType, setOpenDialog }: AppointmentTypeProps) => {
  const useQuery = () => new URLSearchParams(window.location.search);
  const query = useQuery();
  const practitionerId = query.get('practitionerId') || undefined;
  const classes = useStyles();
  const [showSuggestion, setShowSuggestion] = useState<string>('');
  const { data: serviceGroups, isFetching: isFetchingServiceGroups } = useServiceGroups();
  const { data: services, isFetching: isFetchingServices } = useServices();
  const updateMutation = useUpdateAppointmentTypeMutation();
  const createMutation = useCreateAppointmentTypeMutation();
  const loading = updateMutation.isLoading || createMutation.isLoading || isFetchingServiceGroups || isFetchingServices;
  const disabled = appointmentType?.default || loading;

  const { register, handleSubmit, errors, setValue, control, watch } = useForm<FormData>({
    resolver: yupResolver(appointmentTypeSchema),
    defaultValues: appointmentTypeDefaultValues,
  });
  const watchEnablePreCareMessage = watch('enablePreCareMessage');
  const watchEnablePostCareMessage = watch('enablePostCareMessage');
  const watchPostCareMessage = watch('postCareMessage');
  const watchPreCareMessage = watch('preCareMessage');
  const watchServiceGroups = watch('serviceGroups');
  const watchServices = watch('services');
  const { data: careMessageSuggestions, isFetching: isFetchingSuggestions } = useCareMessagesSuggestion({
    serviceGroupIds: watchEnablePreCareMessage ? watchServiceGroups : [],
  });

  const handleDialogCloseCancel = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (!loading) {
      setValue('name', appointmentType?.name);
      setValue('duration', appointmentType?.duration);
      setValue('description', appointmentType?.description);
      setValue('enablePreCareMessage', !!appointmentType?.preCareMessage);
      setValue('enablePostCareMessage', !!appointmentType?.postCareMessage);
      setValue('preCareMessage', appointmentType?.preCareMessage || '');
      setValue('postCareMessage', appointmentType?.postCareMessage || '');
      setValue('serviceGroups', appointmentType?.serviceGroups || []);
      setValue('services', appointmentType?.services || []);
    }
  }, [appointmentType, loading]);

  const onSubmit = async (data: any) => {
    if (appointmentType?.id) {
      await updateMutation.mutateAsync({ ...data, id: appointmentType?.id, practitionerId });
    } else {
      await createMutation.mutateAsync({ ...data, practitionerId });
    }
    setOpenDialog(false);
  };

  return (
    <SideModal openModal={openDialog} onClose={handleDialogCloseCancel}>
      <SideModal.Header onClose={handleDialogCloseCancel}>
        <SideModal.Title>{appointmentType?.id ? 'Edit' : 'New'} Appointment Type</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Name</div>
            <TextField
              data-cy="nameInput"
              id="nameInput"
              fullWidth
              name="name"
              error={!!errors.name}
              helperText={errors.name?.message || ''}
              disabled={disabled}
              onChange={(event) => setValue('name', event.target.value)}
              inputRef={register}
              variant="outlined"
              labelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Duration (minutes)</div>
            <TextField
              id="durationInput"
              data-cy="durationInput"
              fullWidth
              name="duration"
              error={!!errors.duration}
              helperText={errors.duration?.message || ''}
              disabled={disabled}
              onChange={(event) => setValue('duration', event.target.value)}
              inputRef={register}
              type="number"
              variant="outlined"
              labelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Service Groups</div>
            <MultipleSelector
              disabled={disabled}
              name="serviceGroups"
              control={control}
              value={watchServiceGroups}
              onChange={(newVal: any) => setValue('serviceGroups', newVal)}
              errors={errors}
              options={serviceGroups.map(({ name, id }) => ({ name, value: id }))}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Services</div>
            <MultipleSelector
              name="services"
              control={control}
              value={watchServices}
              disabled={disabled}
              onChange={(newVal: any) => setValue('services', newVal)}
              errors={errors}
              options={services
                .filter(({ serviceGroupId }) => watchServiceGroups.includes(serviceGroupId))
                .map(({ name, id }) => ({ name, value: id }))}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Description</div>
            <TextareaAutosize
              id="descriptionInput"
              placeholder="Description"
              disabled={disabled}
              defaultValue={appointmentType?.description}
              onChange={(event) => setValue('description', event.target.value)}
              name="description"
              style={TextAreaStyles}
            />
            {!!errors?.description && <FormHelperText error>{errors.description?.message}</FormHelperText>}
          </Grid>
          <Grid item className={classes.formItem}>
            <FormControlLabel
              style={{
                width: '100%',
              }}
              control={
                <Controller
                  control={control}
                  inputRef={register}
                  name="enablePreCareMessage"
                  render={({ onChange, value }: any) => (
                    <Checkbox
                      disabled={disabled}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        setValue('preCareMessage', '');
                      }}
                      ref={register}
                      checked={value}
                      color="default"
                    />
                  )}
                />
              }
              label="Pre-care instructions"
            />
            {isFetchingSuggestions && <CircularProgress size={20} />}
            {watchEnablePreCareMessage && !!careMessageSuggestions?.preCareMessages?.length && <Box>Suggestions:</Box>}
            {watchEnablePreCareMessage &&
              careMessageSuggestions?.preCareMessages?.map((message: string) => (
                <Box className={classes.careMessageSuggestion} style={{ cursor: 'pointer' }}>
                  <VisibilityIcon onClick={() => setShowSuggestion(message)} />
                  <Box onClick={() => copyToClipboard(message)} style={{ marginLeft: '5px' }}>
                    <Pill title="Copy to clipboard" color="gray" style={{ cursor: 'pointer' }} />
                  </Box>
                </Box>
              ))}
            <ConfirmModal
              title="Care message suggestion"
              contentText={<div dangerouslySetInnerHTML={{ __html: showSuggestion }} />}
              openDialog={!!showSuggestion}
              setOpenDialog={() => setShowSuggestion('')}
              confirmText="Copy to clipboard"
              onClickConfirm={() => {
                copyToClipboard(showSuggestion);
                setShowSuggestion('');
              }}
            />
            <TextareaAutosize
              placeholder="Pre care message"
              disabled={!watchEnablePreCareMessage || disabled}
              value={watchPreCareMessage}
              onChange={(event) => setValue('preCareMessage', event.target.value)}
              name="preCareMessage"
              style={TextAreaStyles}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <FormControlLabel
              style={{
                width: '100%',
              }}
              control={
                <Controller
                  control={control}
                  inputRef={register}
                  name="enablePostCareMessage"
                  render={({ onChange, value }: any) => (
                    <Checkbox
                      disabled={disabled}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        setValue('postCareMessage', '');
                      }}
                      ref={register}
                      checked={value}
                      color="default"
                    />
                  )}
                />
              }
              label="Post-care instructions"
            />
            {isFetchingSuggestions && <CircularProgress size={20} />}
            {watchEnablePreCareMessage && !!careMessageSuggestions?.postCareMessages?.length && <Box>Suggestions:</Box>}
            {watchEnablePostCareMessage &&
              careMessageSuggestions?.postCareMessages?.map((message: string) => (
                <Box className={classes.careMessageSuggestion} style={{ cursor: 'pointer' }}>
                  <VisibilityIcon onClick={() => setShowSuggestion(message)} />
                  <Box onClick={() => copyToClipboard(message)} style={{ marginLeft: '5px' }}>
                    <Pill title="Copy to clipboard" color="gray" style={{ cursor: 'pointer' }} />
                  </Box>
                </Box>
              ))}
            <ConfirmModal
              title="Post Care message suggestion"
              contentText={<div dangerouslySetInnerHTML={{ __html: showSuggestion }} />}
              openDialog={!!showSuggestion}
              setOpenDialog={() => setShowSuggestion('')}
              confirmText="Copy to clipboard"
              onClickConfirm={() => {
                copyToClipboard(showSuggestion);
                setShowSuggestion('');
              }}
            />
            <TextareaAutosize
              placeholder="Post care message"
              disabled={!watchEnablePostCareMessage || disabled}
              value={watchPostCareMessage}
              onChange={(event) => setValue('postCareMessage', event.target.value)}
              name="postCareMessage"
              style={TextAreaStyles}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Status</div>
            <Controller
              control={control}
              name="private"
              error={!!errors.private}
              fullWidth
              render={() => (
                <Select
                  id="statusInput"
                  fullWidth
                  variant="outlined"
                  onChange={(event) => setValue('private', !event.target.value)}
                  defaultValue={appointmentType?.private ? 0 : 1}
                  disabled={loading}
                >
                  {/* THIS FIELD IS NOT DISABLED FOR EDITION */}
                  <MenuItem data-cy="member" value={1}>
                    Active
                  </MenuItem>
                  <MenuItem data-cy="member" value={0}>
                    Disabled
                  </MenuItem>
                </Select>
              )}
            />
            {errors.private && <FormHelperText error>{errors.private?.message}</FormHelperText>}
          </Grid>
        </Box>
      </SideModal.Body>
      <SideModal.Footer>
        <Button
          data-cy="confirmModal"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Save Changes
          {loading && <CircularProgress size={25} />}
        </Button>
      </SideModal.Footer>
    </SideModal>
  );
};

export default AppointmentTypeForm;
