import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
} from '@material-ui/core';
import { FiberManualRecord as FiberManualRecordIcon, MoreHoriz as MoreHorizIcon } from '@material-ui/icons';
import { BarChartDataMock } from '../../../mock/BarChart';
import BarChart from '../../BarChart';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paperPadding: {
    padding: '12px 10px',
  },
  boxColor: {
    backgroundColor: '#afcbc7',
  },
  boxSummaryCounter: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: 3,
  },
  avatar: {
    width: '32px',
    height: '32px',
  },
  boxTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dotReference1: {
    fill: '#199f90',
  },
  dotReference2: {
    fill: '#c0e0dc',
  },
  dotReference3: {
    fill: '#dadada',
  },
}));

const Work = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(BarChartDataMock);
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Box p={2}>
                <Typography color="primary">Administrator dashboard</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={2}>
                <Typography>Select date range</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box px={2} mb={4}>
              <Paper className={classes.paperPadding}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography className={classes.boxTitle}>Daily revenue</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Box mr={2} display="flex" alignItems="center" justifyContent="space-between">
                          <FiberManualRecordIcon className={classes.dotReference1} />
                          <Typography variant="body2">Reference</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box mr={2} display="flex" alignItems="center" justifyContent="space-between">
                          <FiberManualRecordIcon className={classes.dotReference2} />
                          <Typography variant="body2">Reference</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box mr={2} display="flex" alignItems="center" justifyContent="space-between">
                          <FiberManualRecordIcon className={classes.dotReference3} />
                          <Typography variant="body2">Reference</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Box textAlign="right">
                      <MoreHorizIcon />
                    </Box>
                  </Grid>
                </Grid>
                <Grid>
                  <BarChart height={250} data={data} />
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={6}>
          <Box px={2}>
            <Paper className={classes.paperPadding}>
              <Typography variant="subtitle1" className={classes.boxTitle}>
                Top nurses
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="1. Francis Wright" />
                  <ListItemSecondaryAction>
                    <Box display="flex">
                      <Typography color="primary" variant="subtitle1" className={classes.boxSummaryCounter}>
                        125
                      </Typography>
                      <Typography variant="subtitle1">services performed</Typography>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary="2. Nettie Greene" />
                  <ListItemSecondaryAction>
                    <Box display="flex">
                      <Typography color="primary" variant="body2" className={classes.boxSummaryCounter}>
                        102
                      </Typography>
                      <Typography variant="body2">services performed</Typography>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary="3. Sara Campbell" />
                  <ListItemSecondaryAction>
                    <Box display="flex">
                      <Typography color="primary" variant="body2" className={classes.boxSummaryCounter}>
                        98
                      </Typography>
                      <Typography variant="body2">services performed</Typography>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Paper>
            <Box
              borderRadius={8}
              mt={4}
              py={2}
              px={2}
              display="flex"
              justifyContent="space-between"
              className={classes.boxColor}
            >
              <Typography variant="subtitle2">Inventory reports</Typography>
              <Typography variant="subtitle2">23</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} lg={6}>
          <Box px={2}>
            <Paper className={classes.paperPadding}>
              <Typography variant="subtitle1" className={classes.boxTitle}>
                Top Services
              </Typography>
              <List>
                <ListItem>
                  <ListItemText className={classes.boxTitle} primary="1. Service name" />
                  <ListItemSecondaryAction>
                    <Box display="flex">
                      <Typography color="primary" variant="subtitle1" className={classes.boxSummaryCounter}>
                        450
                      </Typography>
                      <Typography variant="subtitle1">times performed</Typography>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary="2. Service name" />
                  <ListItemSecondaryAction>
                    <Box display="flex">
                      <Typography color="primary" variant="body2" className={classes.boxSummaryCounter}>
                        390
                      </Typography>
                      <Typography variant="body2">times performed</Typography>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary="3. Service name" />
                  <ListItemSecondaryAction>
                    <Box display="flex">
                      <Typography color="primary" variant="body2" className={classes.boxSummaryCounter}>
                        280
                      </Typography>
                      <Typography variant="body2">times performed</Typography>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Paper>
            <Box
              borderRadius={8}
              mt={4}
              py={2}
              px={2}
              display="flex"
              justifyContent="space-between"
              className={classes.boxColor}
            >
              <Typography variant="subtitle2">Open messages</Typography>
              <Typography variant="subtitle2">47</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Work;
