import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  divLabel: {
    paddingBottom: '5px',
    color: 'gray',
  },
  formBack: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  menuContainer: {
    borderLeft: 'solid 1px #c8c8c8',
    paddingLeft: '10px',
  },
  menuItem: {
    padding: '10px',
    margin: '10px',
    borderRadius: '30px',
    cursor: 'pointer',
  },
  menuItemTop: {
    padding: '10px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  menuItemBottom: {
    border: '1px solid #b7b7b7',
  },
  menuItemBottomActive: {
    border: `2px solid ${theme.palette.primary.main}`,
    position: 'relative',
    top: '-1px',
  },
  tableTitle: {
    color: 'gray',
  },
  disabled: {
    color: 'gray !important',
  },
  removeIcon: {
    color: '#bf3939 !important',
  },
  menuActive: {
    backgroundColor: '#E7EEED',
    color: '#12574D',
  },
  menuActiveTop: {
    fontWeight: 'bold',
    color: '#12574D',
  },
  container: {
    padding: '20px',
  },
  form: {
    border: 'solid 1px #acacac',
    borderRadius: '10px',
    padding: '20px',
    margin: '20px 20px 0 0',
  },
  formItem: {
    padding: '10px',
  },
  formItemLegend: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '12px',
    paddingTop: '18px',
    '@media (max-width: 1279px)': {
      paddingTop: '0px',
    },
    margin: 'auto',
  },
  formItemTitle: {
    fontSize: '110%',
    paddingBottom: '20px',
  },
  inputTable: {
    width: '160px',
  },
  selectForm: {
    width: '100%',
  },
  actionButton: {
    color: theme.palette.primary.main,
    margin: '0 5px',
    cursor: 'pointer',
  },
  actionButtonTable: {
    position: 'relative',
    top: '5px',
  },
  inputLabel: {
    background: 'white',
    top: '-15px',
    width: '83px',
  },
  // TOOLBAR
  toolbar: {
    background: theme.palette.primary.main,
    display: 'flex',
  },
  currentDate: {
    fontSize: '20px',
    letterSpacing: '-1.36px',
    color: '#FFFFFF',
    lineHeight: '50px',
    marginLeft: '17px',
    width: '170px',
  },
  navigationButtons: {
    display: 'flex',
    lineHeight: '50px',
    color: 'white',
  },
  navigationButton: {
    width: '36px',
    border: '1px solid #5F8E87',
    height: '36px',
    marginTop: '8px',
    textAlign: 'center',
    lineHeight: '48px',
    borderRadius: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  todayNavigation: {
    border: '1px solid #5F8E87',
    borderRadius: '3px',
    height: '36px',
    width: '72px',
    lineHeight: '32px',
    textAlign: 'center',
    margin: '8px 8px 0px 8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  viewsSelectors: {
    width: '214px',
    height: '36px',
    background: '#2b6960',
    borderRadius: '9px',
    display: 'flex',
    margin: '7px',
    marginInlineStart: 'auto',
  },
  viewSelector: {
    height: '32px',
    padding: '7px 18px',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  viewSelectorActive: {
    background: '#5F8E87',
    borderRadius: '11px',
    marginTop: '2px',
    lineHeight: '17px',
  },
  timeText: {
    display: 'inline',
    position: 'relative',
    top: '7px',
  },
  workingText: {
    color: '#5F8E87',
  },
  formMinutesBeforeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formMinuteBeforeSetting: {
    width: '200px',
  },
  center: {
    textAlign: 'center',
  },
  loginButton: {
    border: 'none',
    background: '#12574D',
    borderRadius: '4px',
    padding: '8px 16px',
    color: 'white',
  },
  careMessageSuggestion: {
    width: '100%',
    overflow: 'hidden',
    paddingBottom: '5px',
    display: 'flex',
  },
  appointmentTypeFormInputSelect: {
    height: '100%',
    marginBottom: '0.2rem',
    '& .MuiSelect-select': {
      minHeight: '2.7rem',
      borderRadius: '4px',
      height: 'auto',
    },
  },
}));
