import { round } from 'lodash';
import moment from 'moment';
import { SKIN_CONCERN_KINDS } from '../constants/medicalProfile.constants';
import { IFormatSkinProfileInfo } from '../interfaces/IFormatSkinProfileInfo';

function getSkinConcerns(skinConcerns: string[]): string {
  return Object.entries(SKIN_CONCERN_KINDS)
    .filter(([key]) => skinConcerns.includes(key))
    .map(([, { label }]) => label)
    .join(', ');
}

function formatMedication(medications: any): string {
  const usedMedications = medications.map(({ name, frequency, potency, vehicle }: any) => {
    const medicationName = name;
    const medicationFrequency = frequency ? `\n - Frequency: ${frequency}` : '';
    const medicationPotency = potency ? `\n - Potency: ${potency}` : '';
    const medicationVehicle = vehicle ? `\n - Vehicle: ${vehicle}` : '';
    return `${medicationName}${medicationFrequency}${medicationPotency}${medicationVehicle}`;
  });

  return usedMedications.join('\n');
}

export const formatAddress = ({ addressLine1, addressLine2, city, state, zipCode }: any): string =>
  !addressLine1 && !addressLine2 && !city && !state && !zipCode
    ? 'None'
    : `${addressLine1 || ''} ${addressLine2 || ''}\n${city || ''}${city ? ', ' : ''} ${state || ''} ${zipCode || ''}`;

export const formatContactInfo = (data: any): object => {
  const { physician, practitioner, email, phone, medicalProfile, shippingAddress } = data;

  return {
    physician: physician ? `${physician.firstName} ${physician.lastName}` : '',
    practitioner: practitioner ? `${practitioner.firstName} ${practitioner.lastName}` : '',
    email: email || 'None',
    phone: phone || 'None',
    address: formatAddress(data),
    shippingAddress: formatAddress(shippingAddress),
    dateOfBirth: medicalProfile.dateOfBirth ? moment(medicalProfile.dateOfBirth).format('MM/DD/YYYY') : '',
  };
};

export const formatConditionsLabel = (conditions: any): string => {
  // Note: the second if depends of the first one, please do not join it.
  const hasConditions = conditions?.noCondition ? false : !!(conditions && Object.values(conditions).includes(true));

  if (!hasConditions) {
    return 'No';
  }

  let label = '';
  if (conditions?.autoimmuneDisease) {
    label += 'Autoimmune disease';
    if (conditions?.autoimmuneDiseaseControlled === 'yes') {
      label += ' (stable/controlled)';
    }
    label += '. ';
  }
  if (conditions?.diabetes) {
    label += 'Diabetes';
    if (conditions?.diabetesControlled === 'yes') {
      label += ' (stable/controlled)';
    }
    label += '. ';
  }
  if (conditions?.neurologicOrNeuromuscular) {
    label += 'Neurologic or neuromuscular';
    if (conditions?.neurologicOrNeuromuscularControlled === 'yes') {
      label += ' (stable/controlled)';
    }
    label += '. ';
  }
  if (conditions?.bleedingOrClotting) {
    label += 'Bleeding or clotting';
    if (conditions?.bleedingComments) {
      label += ` (${conditions?.bleedingComments})`;
    }
    label += '. ';
  }
  if (conditions?.immuneDeficiency) {
    label += 'Immune deficiency';
    if (conditions?.immuneComments) {
      label += ` (${conditions?.immuneComments})`;
    }
    label += '. ';
  }
  if (conditions?.cancer) {
    label += 'Cancer';
    if (conditions?.cancerType) {
      label += ` (${conditions?.cancerType})`;
    }
    if (conditions?.cancerCurrentlyReceivingTreatment === 'yes') {
      label += ` (receiving treatment)`;
    }
    label += '. ';
  }
  if (conditions?.heartDisease) {
    label += 'Heart disease';
    if (conditions?.heartDiseaseComments) {
      label += ` (${conditions?.heartDiseaseComments})`;
    }
    label += '. ';
  }
  if (conditions?.anaphylaxisOrAngioedema) {
    label += 'Anaphylaxis or angioedema. \n';
  }
  if (conditions?.coldSores) {
    label += 'Cold sores. \n';
  }
  if (conditions?.keloidsOrSarcoidosis) {
    label += 'Keloids or sarcoidosis. \n';
  }
  if (conditions?.melasmaOrVitiligo) {
    // eslint-disable-next-line no-restricted-syntax
    for (const condition of conditions?.melasmaOrVitiligoConditions) {
      label += `${condition}. \n`;
    }
  }
  if (conditions?.allergiesConditions) {
    // eslint-disable-next-line no-restricted-syntax
    for (const allergy of conditions?.allergiesConditions) {
      label += `Allergy ${allergy}. \n`;
    }
  }

  return label;
};

export const convertCmToFtInch = (
  heightInCms: number
): {
  ft: number;
  inch: number;
} => {
  const heightInInches = heightInCms / 2.54;
  const feet = Math.trunc(heightInInches / 12);
  const inches = heightInInches - feet * 12;

  return {
    ft: feet,
    inch: inches,
  };
};

export const convertFtInchToCm = (feet: number, inches: number) => round(feet * 30.48 + inches * 2.54, 2);

export const formatMedicalProfileInfo = (data: any): object => {
  const {
    knownAllergies,
    medicationAllergies,
    isPregnant,
    intendingPregnant,
    isBreastFeeding,
    oralMedications,
    topicalMedications,
    otherMedications,
    userActiveMedicalConditions,
    procedures,
    conditions,
    userSkinExtraDetails,
    weightInLbs,
    heightInCms,
    interestedInWeightLoss,
  } = data;
  const { ft, inch } = convertCmToFtInch(heightInCms);
  const currentOralMedication = oralMedications ? oralMedications.filter(({ using }: any) => using) : [];
  const historicalOralMedication = oralMedications ? oralMedications.filter(({ using }: any) => !using) : [];
  const currentTopicalMedication = topicalMedications ? topicalMedications.filter(({ using }: any) => using) : [];
  const historicalTopicalMedication = topicalMedications ? topicalMedications.filter(({ using }: any) => !using) : [];
  const currentOtherMedications = otherMedications ? otherMedications.filter(({ using }: any) => using) : [];
  const historicalOtherMedications = otherMedications ? otherMedications.filter(({ using }: any) => !using) : [];

  return {
    knownAllergies: knownAllergies || 'None',
    medicationAllergies: medicationAllergies && medicationAllergies.length ? medicationAllergies.join(', ') : 'None',
    pregnant: isPregnant === 'yes' ? 'Yes' : 'No',
    intendingPregnant: intendingPregnant === 'yes' ? 'Yes' : 'No',
    breastFeeding: isBreastFeeding === 'yes' ? 'Yes' : 'No',
    currentOralMedication: currentOralMedication.length ? formatMedication(currentOralMedication) : 'None reported',
    historicalOralMedication: historicalOralMedication.length
      ? formatMedication(historicalOralMedication)
      : 'None reported',
    currentTopicalMedication: currentTopicalMedication.length
      ? formatMedication(currentTopicalMedication)
      : 'None reported',
    historicalTopicalMedication: historicalTopicalMedication.length
      ? formatMedication(historicalTopicalMedication)
      : 'None reported',
    currentOtherMedications: currentOtherMedications.length
      ? formatMedication(currentOtherMedications)
      : 'None reported',
    historicalOtherMedications: historicalOtherMedications.length
      ? formatMedication(historicalOtherMedications)
      : 'None reported',
    medicalConditions: userActiveMedicalConditions || 'None reported',
    hasProcedures: formatProceduresLabel(procedures),
    hasConditions: formatConditionsLabel(conditions),
    extraDetails: userSkinExtraDetails || 'No',
    weightLoss: interestedInWeightLoss === 'yes' ? `Yes (${ft}ft${inch}in/${weightInLbs}lbs)` : 'No',
  };
};

export const formatProceduresLabel = (procedures: any): string => {
  // Note: the second if depends of the first one, please do not join it.
  const hasProcedures = procedures?.noProcedures ? false : !!(procedures && Object.values(procedures).includes(true));

  if (!hasProcedures) {
    return 'No';
  }

  let label = '';
  if (procedures?.neurotoxins) {
    label += 'Neurotoxins. ';
  }
  if (procedures?.fillers) {
    label += 'Fillers. ';
  }
  if (procedures?.threads) {
    label += 'Threads. ';
  }
  if (procedures?.sculptra) {
    label += 'Sculptra. ';
  }
  if (procedures?.kybella) {
    label += 'Kybella. ';
  }
  if (procedures?.prpPrf) {
    label += 'PRP/PRF. ';
  }
  if (procedures?.laserTreatment) {
    label += 'Laser treatment. ';
  }
  if (procedures?.cosmeticSurgery) {
    label += 'Cosmetic surgery. ';
  }
  if (procedures?.facialImplants) {
    label += 'Facial implants. ';
  }
  if (procedures?.adverseReactions && procedures?.adverseReactions !== 'true') {
    label += `Adverse Reactions: ${procedures?.adverseReactions}`;
  }

  return label;
};

export const formatSkinProfileInfo = (data: any): IFormatSkinProfileInfo => {
  const { skinConcerns, serviceImprovements, skinType, sunscreenFrequency, usingRetinol, usingPeels, goalsAndNotes } =
    data;

  return {
    skinConcerns: skinConcerns && skinConcerns.length ? getSkinConcerns(skinConcerns) : 'None',
    serviceImprovements: serviceImprovements && serviceImprovements.length ? serviceImprovements.join(', ') : 'None',
    skinType,
    sunscreenFrequency,
    retinalRetinA: usingRetinol === 'yes' ? 'Yes' : 'No',
    usingPeels: usingPeels === 'yes' ? 'Yes' : 'No',
    goalsNotes: goalsAndNotes || 'None',
  };
};

export const calculateAge = (dateOfBirth: string): number => {
  const a = moment();
  const b = moment(dateOfBirth, 'YYYY-MM-DD');
  const age = moment.duration(a.diff(b));

  return age.years();
};

export const getUserTypePropertyId = (userType: string): string => {
  switch (userType) {
    case 'Administrator':
      return 'administratorId';
    case 'Physician':
      return 'physicianId';
    case 'Practitioner':
      return 'practitionerId';
    default:
      return '';
  }
};
