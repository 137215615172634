import { makeStyles } from '@material-ui/core/styles';
import {
  BEFORE_PHOTOS,
  AFTER_PHOTOS,
  HERO_IMAGE,
  CUSTOM_ICONS,
  LOGO_IMAGE,
} from '../../constants/leadLandingPage.constants';

export const landingPageStyles = makeStyles(() => ({
  rootContainer: {
    overflowX: 'hidden',
  },
  hero: {
    display: 'flex',
    height: '87vh',
    width: '100%',
    backgroundImage: `url('${HERO_IMAGE}')`,
    backgroundPositionY: '-160px',
    '&[data-mobile="true"]': {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    '&[data-xl="true"]': {
      backgroundSize: 'cover',
    },
  },
  heroLogo: {
    height: '35px',
    position: 'absolute',
    top: '40px',
    left: '10%',
    width: '215px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('${LOGO_IMAGE}')`,
    '&[data-mobile="true"]': {
      backgroundPositionX: '0px',
    },
  },
  heroContent: {
    position: 'relative',
    top: '-75px',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 25px 35px 25px',
    width: '635px',
    minWidth: '500px',
    margin: 'auto auto auto 10%',
    '&[data-mobile="true"]': {
      backgroundColor: '#fffa',
      width: '95vw',
      minWidth: 'unset',
      marginTop: '6rem',
      top: '0px',
    },
  },
  heroTitle: {
    fontSize: '4rem',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '590px',
    '&[data-mobile="true"]': {
      fontSize: '3.2rem',
    },
  },
  heroText: {
    marginTop: '10px',
    maxWidth: '590px',
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  leadForm: {
    position: 'absolute',
    top: '80%',
    left: '25%',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    transitionDuration: '1s',
    minHeight: '512px',
    margin: '0 auto',
    backgroundColor: 'rgb(244,239,238)',
    padding: '28px 18px',
    '&[data-mobile="true"]': {
      left: '1%',
      width: '98%',
      minWidth: '375px',
    },
    '&[data-submitted="true"]': {
      minHeight: '0px',
    },
    '&[data-highlight="true"] input:invalid': {
      outline: '1px solid #f00',
    },
    '& input': {
      margin: '10px 5px',
      borderRadius: '4px',
      backgroundColor: '#fff',
      height: '35px',
      padding: '4px 10px',
    },
  },
  phoneInput: {
    '&[data-highlight="true"] input': {
      outline: '1px solid #f00',
    },
  },
  leadFormTitle: {
    margin: '18px auto 35px auto',
  },
  nameInputs: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  nameInput: {
    width: '50%',
  },
  submitLeadButton: {
    backgroundColor: 'rgb(9,24,76)',
    color: '#fff',
    borderRadius: '2px',
    margin: '25px auto 50px auto',
    height: '55px',
    width: '130px',
    '&:hover': {
      color: 'rgb(9,24,76)',
    },
    '&:disabled': {
      color: '#ccc',
    },
  },
  securityNote: {
    margin: 'auto auto 18px auto',
    textAlign: 'center',
  },
  formSectionTag: {
    margin: '10px auto',
    textAlign: 'center',
    '&:first-of-type': {
      fontSize: '3rem',
      marginTop: '500px',
    },
  },
  formSectionReview: {
    margin: '10px auto',
  },
  leadSectionReview: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    margin: '20px auto 20px auto',
    backgroundColor: 'rgb(244,239,238)',
    padding: '18px 60px',
    textAlign: 'center',
    '&[data-mobile="true"]': {
      left: '1%',
      width: '98%',
      minWidth: '375px',
    },
  },
  starIcon: {
    color: 'rgb(236,102,57)',
  },
  reviewIcon: {
    width: '40px',
    height: '40px',
    margin: '8px auto',
    backgroundImage: `url('${CUSTOM_ICONS[0]}')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    '&[data-icon="yelp"]': {
      backgroundImage: `url('${CUSTOM_ICONS[1]}')`,
    },
  },
  boutiqueSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    backgroundColor: 'rgb(26,43,67)',
    margin: '80px 0 0 0',
    padding: '40px',
    color: '#fff',
  },
  starReview: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto',
    '&[data-align="left"]': {
      marginLeft: '0',
      marginTop: '10px',
    },
    '&[data-align="right"]': {
      marginRight: '0',
    },
  },
  boutiqueText: {
    fontSize: '2.6rem',
    margin: '0 auto',
    maxWidth: '590px',
    color: '#fff',
    '&[data-mobile="true"]': {
      fontSize: '2rem',
    },
  },
  baSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    backgroundColor: 'rgb(244,239,238)',
    margin: '0',
    padding: '40px',
    '&[data-mobile="true"]': {
      flexDirection: 'column',
    },
  },

  baDescription: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    margin: 'auto 0 auto auto',
    '&[data-mobile="true"]': {
      margin: 'auto',
      marginBottom: '10px',
    },
  },
  baFeature: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto auto auto 0',
    '&[data-mobile="true"]': {
      margin: '15px auto auto auto',
    },
    '& > div': {
      width: '400px',
      height: '289px',
      margin: '5px auto',
      marginLeft: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      '&[data-mobile="true"]': {
        width: '325px',
        marginLeft: 'auto',
        margin: '10px auto 0 auto',
      },
    },
  },
  baText: {
    margin: '10px auto',
    '&:first-of-type': {
      fontSize: '3rem',
      textAlign: 'center',
      marginRight: '25px',
      '&[data-mobile="true"]': {
        width: '325px',
        margin: '10px auto',
        textAlign: 'center',
      },
    },
  },
  beforeImage: {
    backgroundImage: `url('${BEFORE_PHOTOS[0]}')`,
    '&[data-service="relaxer"]': {
      backgroundImage: `url('${BEFORE_PHOTOS[1]}')`,
    },
    '&[data-service="volumizer"]': {
      backgroundImage: `url('${BEFORE_PHOTOS[2]}')`,
    },
    '&[data-service="contour"]': {
      backgroundImage: `url('${BEFORE_PHOTOS[3]}')`,
    },
    '&[data-service="sculpting"]': {
      backgroundImage: `url('${BEFORE_PHOTOS[4]}')`,
    },
  },
  afterImage: {
    backgroundImage: `url('${AFTER_PHOTOS[0]}')`,
    '&[data-service="relaxer"]': {
      backgroundImage: `url('${AFTER_PHOTOS[1]}')`,
    },
    '&[data-service="volumizer"]': {
      backgroundImage: `url('${AFTER_PHOTOS[2]}')`,
    },
    '&[data-service="contour"]': {
      backgroundImage: `url('${AFTER_PHOTOS[3]}')`,
    },
    '&[data-service="sculpting"]': {
      backgroundImage: `url('${AFTER_PHOTOS[4]}')`,
    },
  },
  expectSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    margin: '0',
    padding: '80px 15% 40px 15%',
    textAlign: 'center',
  },
  expectText: {
    margin: '0 auto 15px auto',
    maxWidth: '650px',
    '&:first-of-type': {
      fontSize: '2.5rem',
    },
  },
  iconPanelGroup: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '&[data-mobile="true"]': {
      flexWrap: 'wrap',
    },
  },
  iconPanel: {
    padding: '30px 30px',
    width: '380px',
    height: '200px',
    margin: '10px auto 10px 15px',
    backgroundColor: 'rgb(244,239,238)',
    borderRadius: '22px',
    '&:nth-child(1)': {
      margin: '10px 15px 10px auto',
    },
    '&[data-order="1"]': {
      borderBottomRightRadius: '0px',
    },
    '&[data-order="2"]': {
      borderBottomLeftRadius: '0px',
    },
    '&[data-order="3"]': {
      borderTopRightRadius: '0px',
    },
    '&[data-order="4"]': {
      borderTopLeftRadius: '0px',
    },
    '&[data-mobile="true"]': {
      margin: '10px auto',
      height: 'auto',
    },
    '& > svg': {
      fontSize: '45px',
    },
  },
  iconPanelText: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '12px',
  },
  iconPanelTitle: {
    marginTop: '15px',
    marginBottom: '8px',
  },
  regular: {
    fontFamily: 'Messina Sans Regular',
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    backgroundColor: 'rgb(26,43,67)',
    margin: '80px 0 0 0',
    padding: '40px',
    paddingBottom: '200px',
    color: '#fff',
  },
  contactBar: {
    display: 'flex',
    flexDirection: 'row',
    margin: '40px auto',
    width: '70%',
    borderTop: '1px solid rgb(122,132,144)',
    borderBottom: '1px solid rgb(122,132,144)',
    padding: '10px',
    '&[data-mobile="true"]': {
      width: '100%',
      borderBottom: 'none',
    },
  },
  contactBarPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    width: '30%',
    margin: 'auto',
    textAlign: 'center',
    maxHeight: '100px',
    '&[data-mobile="true"]': {
      height: '120px',
      maxHeight: 'unset',
    },
    '& > svg': {
      fontSize: '18px',
      color: 'rgb(220,109,70)',
      margin: '0 auto 10px auto',
    },
  },
  contactPanelText: {
    fontSize: '12px',
    color: '#fff',
    margin: '0 auto',
    '&[data-mobile="true"]': {
      fontSize: '10px',
    },
  },
  footerTag: {
    margin: '10px auto',
  },
  footerDisclaimer: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '12px',
    margin: '10px auto 0 auto',
    width: '70%',
    textAlign: 'center',
    '&[data-mobile="true"]': {
      width: '100%',
    },
  },
  formLoader: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '250px',
  },
  formLoaderProgress: {
    margin: '15px 25px auto 25px',
  },
  successIcon: {
    color: '#009900',
    borderRadius: '50%',
    border: '1px solid',
    fontSize: '110px',
    padding: '25px',
    margin: '35px auto 5px auto',
  },
  allSet: {
    color: '#009900',
    margin: '5px auto auto auto',
  },
  justAMoment: {
    margin: '60px auto 0 auto',
  },
}));
