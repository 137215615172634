import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CardContent, Typography, Table, TableBody, TableRow } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import moment from 'moment';

import { useMutation } from 'react-query';
import { ActiveStepWrapper } from '../ActiveStepWrapper';
import Scanner from '../../common/Scanner';
import { Card } from '../../common/card';
import { dispatch } from '../../../rematch';
import { SQUARE_STATUS } from '../../../constants/checkout.constants';
import { RemainingUnitsModal } from '../../common/RemainingUnitsModal/RemainingUnitsModal';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import { ManualForm } from './ManualForm';
import {
  getHumanReadableInternalSerialNumber,
  getPluralWord,
  getTotal,
  isAssetExpired,
} from '../../../utils/inventory.utils';
import { useStyles, TableCellCustom } from './ServiceVisitScanner.styles';

import compile from '../../../utils/toastMessagesCompiler';
import useLog from '../../../hooks/mutations/useLog';
import RescanAsset from './RescanAsset';
import { handleAssetSelected } from '../../../utils/handleAssetsSelected';
import ServiceVisit from '../../../services/ServiceVisit';
import { getServiceVisitProgress } from '../../../constants/reselect/serviceVisitProgress';

const defaultModalData = { name: '', internalSerialNumber: '', currentNumberOfUnits: 0, toDraw: 0 };

export const ServiceVisitScanner = ({ pendingServices, patientId, serviceVisitId, display = '' }: any) => {
  const classes = useStyles();
  const scannerInputRef = useRef<HTMLInputElement>(null);
  const newServiceVisit = useSelector((state: any) => state.newServiceVisit);
  const patient = useSelector((state: any) => state.patient);
  const { services, productsSelected, selectedServices, checkout, totalServicesUnits, servicesUnits, serviceVisit } =
    newServiceVisit;
  const [disabledScan, setDisabledScan] = useState(false);
  const [lastSerialNumberAdded, setLastSerialNumberAdded] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(defaultModalData);
  const [showInputs, setShowInputs] = useState<any>({});
  const { transactionStatus } = checkout;
  const serviceVisitProgress = useSelector(getServiceVisitProgress);

  const { mutateAsync: log } = useLog();

  const rescanAsset = useMutation(
    (data: { serviceVisitId: number; assetId: number; newAssetId: number }) => ServiceVisit.rescanAsset(data),
    { onSuccess: () => {} }
  );

  useEffect(() => {
    const { paymentStatus, opened } = serviceVisit;
    const isDisabled = (paymentStatus === 'paid' && transactionStatus === SQUARE_STATUS.OK) || !opened;

    setDisabledScan(isDisabled);
  }, [serviceVisit, serviceVisitId, transactionStatus]);

  const isAssetAlreadySelected = (code: string) =>
    productsSelected.some((value: any) =>
      value.assets.some(({ internalSerialNumber }: any) => internalSerialNumber === code)
    );

  const alreadyReachTheLimitOfUnitsScannedForTheAssetType = ({ serviceId }: IAsset, selected: any) => {
    const { assets: alreadyScannedAssets } = selected || { assets: [] };

    const maxNumberOfUnitsAllowedToScan = Number(totalServicesUnits[serviceId]);
    return alreadyScannedAssets.length >= maxNumberOfUnitsAllowedToScan;
  };

  const hasAssetsOnlyForFutureUse = ({ serviceId }: IAsset) => {
    const numberOfCurrentUnits = Number(totalServicesUnits[serviceId]);
    return numberOfCurrentUnits === 0;
  };

  const calculateUnitsToSuggest = (asset: IAsset, selected: any) => {
    const maxNumberOfUnitsAllowedToScan = Number(totalServicesUnits[asset.serviceId]);

    if (!selected) {
      return asset.currentNumberOfUnits < maxNumberOfUnitsAllowedToScan
        ? asset.currentNumberOfUnits
        : maxNumberOfUnitsAllowedToScan;
    }

    const sumOfRequestedUnits = selected.assets.reduce(
      (total: number, { requestedUnits = 0 }: any) => total + Number(requestedUnits),
      0
    );

    if (asset.currentNumberOfUnits > maxNumberOfUnitsAllowedToScan) {
      return maxNumberOfUnitsAllowedToScan - sumOfRequestedUnits;
    }

    if (asset.currentNumberOfUnits > maxNumberOfUnitsAllowedToScan - sumOfRequestedUnits) {
      return maxNumberOfUnitsAllowedToScan - sumOfRequestedUnits;
    }

    return asset.currentNumberOfUnits > 0 ? asset.currentNumberOfUnits : 1;
  };

  const isServiceSelected = (serviceId: number) => selectedServices.includes(serviceId);

  const confirmUnits = async (asset: IAsset, requestedUnits: number) => {
    if (modalData.toDraw) {
      Object.assign(asset, { requestedUnits });

      const newProductsSelected = handleAssetSelected({ asset, services, productsSelected });

      await ServiceVisit.fetchSaveProgress({
        body: { ...serviceVisitProgress, ...{ productsSelected: newProductsSelected } },
        patientId,
        serviceVisitId,
      });

      dispatch({ type: 'newServiceVisit/setProducts', payload: newProductsSelected });

      log({
        feature: 'Service Visit - Scanner',
        step: 'Add product - step: 8',
        logId: null,
        params: {
          patientId,
          serviceVisitId,
          asset,
          services,
          productsSelected,
        },
      });

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.product_added'),
        },
      });
    }
    setModalData(defaultModalData);
    setIsModalOpen(false);
  };

  const handleAssetChecked = async (asset: IAsset) => {
    const FUTURE_USE_ERROR_MESSAGE = compile('new_service_visit.asset_for_future_use');

    const ASSET_LIMIT_REACHED = compile('new_service_visit.reached_asset_limit', {
      serviceName: asset.name,
    });

    if (!isServiceSelected(asset.serviceId)) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.asset_not_in_service_selected'),
          type: 'error',
        },
      });
      return;
    }

    if (isAssetAlreadySelected(asset.internalSerialNumber)) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.product_already_added'),
          type: 'error',
        },
      });
      return;
    }

    const selected = productsSelected.find(({ id }: any) => id === asset.serviceId);

    const MESSAGE_TO_SHOW = hasAssetsOnlyForFutureUse(asset) ? FUTURE_USE_ERROR_MESSAGE : ASSET_LIMIT_REACHED;

    if (asset.allowPartialSale) {
      const unitsToSugget = calculateUnitsToSuggest(asset, selected);

      if (unitsToSugget === 0) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: MESSAGE_TO_SHOW,
            type: 'warning',
          },
        });
        return;
      }

      setModalData({ ...asset, toDraw: unitsToSugget });
      setIsModalOpen(true);
      return;
    }

    // check if the amount of scanned products is less of units in step 2
    if (alreadyReachTheLimitOfUnitsScannedForTheAssetType(asset, selected)) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: MESSAGE_TO_SHOW,
          type: 'warning',
        },
      });
      return;
    }
    const newProductsSelected = handleAssetSelected({ asset, services, productsSelected });

    await ServiceVisit.fetchSaveProgress({
      body: { ...serviceVisitProgress, ...{ productsSelected: newProductsSelected } },
      patientId,
      serviceVisitId,
    });

    dispatch({ type: 'newServiceVisit/setProducts', payload: newProductsSelected });

    log({
      feature: 'Service Visit - Scanner',
      step: 'Add product - step: 8',
      logId: null,
      params: {
        patientId,
        serviceVisitId,
        asset,
        services,
        productsSelected,
      },
    });

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.product_added'),
      },
    });
  };

  const checkAssetInternalSerialNumber = (scannedCode: string, prefix?: string) => {
    // Deprecated (process performed by Scanner component)
    if (disabledScan) {
      return;
    }

    if (!isAssetAlreadySelected(scannedCode)) {
      dispatch({
        type: 'scanner/checkAssetByInternalSerialNumber',
        payload: { prefix, internalSerialNumber: scannedCode },
      });
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.product_already_added'),
          type: 'error',
          duration: 2000,
        },
      });
    }
  };

  const onAddProduct = (text: string, service?: any) => {
    // Deprecated (process performed by Scanner component)
    checkAssetInternalSerialNumber(text, service?.prefix);

    setLastSerialNumberAdded(text);
    setTimeout(() => setLastSerialNumberAdded(''), 800);
  };

  const hasAnyManualInputOpened = () => Object.keys(showInputs).some((key: string) => showInputs[key]);

  const updateSelectedStep = () => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 8 });
    log({
      feature: 'Service Visit',
      step: 8,
      logId: null,
      params: {
        newServiceVisit,
        patient,
      },
    });

    if (scannerInputRef && scannerInputRef.current && !hasAnyManualInputOpened()) {
      scannerInputRef.current.focus();
    }
  };

  const handleUndo = (assetId: number) => {
    if (disabledScan) {
      return false;
    }

    const newProductsSelected = productsSelected.map((service: any) => ({
      ...service,
      assets: service.assets.filter(({ id }: any) => id !== assetId),
    }));

    return dispatch({ type: 'newServiceVisit/setProducts', payload: newProductsSelected });
  };

  const rescanCallback = async (assetIdToRemove: number, unitsRequested: number, asset: IAsset) => {
    const { success } = await rescanAsset.mutateAsync({
      serviceVisitId,
      assetId: assetIdToRemove,
      newAssetId: asset.id,
    });

    if (!success) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'updating',
            element: 'scanned assets',
          }),
          type: 'error',
        },
      });
      return;
    }

    let newProductsSelected = handleAssetSelected({ asset, services, productsSelected });

    // Remove old asset and Add units requested for rescaned asset:
    newProductsSelected = newProductsSelected.map((productSelected: any) => ({
      ...productSelected,
      assets: productSelected.assets
        .filter(({ id }: any) => id !== assetIdToRemove)
        .map((ast: IAsset) => ({
          ...ast,
          requestedUnits: ast.id === asset.id ? unitsRequested : ast.requestedUnits,
        })),
    }));

    dispatch({ type: 'newServiceVisit/setProducts', payload: newProductsSelected });

    log({
      feature: 'Service Visit - ReScanner',
      step: 'Add product - step: 8',
      logId: null,
      params: {
        patientId,
        serviceVisitId,
        asset,
        services,
        productsSelected,
      },
    });

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.product_rescanned'),
      },
    });
  };

  const scanPendingAssets: any = [];

  const toggleInputs = (key: string) => {
    const newShowInputs = { ...showInputs };
    newShowInputs[key] = !newShowInputs[key];
    setShowInputs(newShowInputs);
  };

  const onScan = (oldAsset: IAsset, newAsset: IAsset, unitsRequested: number) => {
    if (newAsset.internalSerialNumber === oldAsset.internalSerialNumber) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.rescan_same_serial_number'),
          type: 'error',
        },
      });
    } else if (newAsset.serviceId !== oldAsset.serviceId) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.product_not_same_service'),
          type: 'error',
        },
      });
    } else if (isAssetExpired(newAsset)) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.product_expired'),
          type: 'error',
        },
      });
    } else {
      rescanCallback(oldAsset.id, unitsRequested, newAsset);
    }
  };

  const getPrimaryTextForModal = (): string =>
    `This bottle is expected to have ${modalData.currentNumberOfUnits} units. <br> Will you draw <b>${
      modalData.toDraw
    } ${getPluralWord('unit', modalData.toDraw !== 1)}</b> from this vial?`;

  pendingServices.forEach((service: any) => {
    for (let i = 0; i < service.pending; i++) {
      const id = `${service.id}-${i}`;

      if (totalServicesUnits[service.id] > 0) {
        scanPendingAssets.push(
          <ManualForm
            key={id}
            service={service}
            showInputs={showInputs[id]}
            units={service.allowPartialSale ? totalServicesUnits[service.id] : 1}
            toggleInputs={toggleInputs}
            id={id}
            saveCallback={(text: string) => onAddProduct(text, service)}
            disabled={disabledScan}
          />
        );
      }
    }
  });

  const ProductList = (
    <>
      {scanPendingAssets}
      <Table>
        <TableBody>
          {productsSelected.map((service: any) => (
            <Fragment key={service.id}>
              {service.assets.map((asset: any) => (
                <TableRow
                  key={`${service.id}-${asset.id}`}
                  className={
                    asset.internalSerialNumber === lastSerialNumberAdded
                      ? classes.productScannedTransition
                      : classes.productScanned
                  }
                >
                  <TableCellCustom
                    style={{ fontSize: '12px', border: 'none', fontWeight: 'bold', maxWidth: '150px' }}
                    colSpan={2}
                  >
                    {service.name}
                  </TableCellCustom>
                  <TableCellCustom style={{ fontSize: '12px', border: 'none', minWidth: '67px' }}>
                    {asset.requestedUnits && <>Qty: {asset.requestedUnits}</>}
                  </TableCellCustom>
                  <TableCellCustom style={{ fontSize: '12px', border: 'none' }}>
                    Lot: {asset.lot?.toUpperCase()}
                  </TableCellCustom>
                  <TableCellCustom style={{ fontSize: '12px', border: 'none' }}>
                    Exp: {moment(asset.expireAt).format('MM/DD/YYYY')}
                  </TableCellCustom>
                  <TableCellCustom style={{ fontSize: '12px', border: 'none' }}>
                    {!!asset.internalSerialNumber && getHumanReadableInternalSerialNumber(asset.internalSerialNumber)}
                  </TableCellCustom>
                  <TableCellCustom style={{ fontSize: '12px', border: 'none', paddingTop: '8px' }} align="right">
                    {disabledScan ? (
                      <div style={{ height: '25px' }}>
                        <RescanAsset
                          onScan={(newAsset: IAsset) =>
                            onScan(asset, newAsset, asset.requestedUnits || servicesUnits[asset.serviceName])
                          }
                          asset={asset}
                          unitsRequested={asset.requestedUnits || servicesUnits[asset.serviceName]}
                        />
                      </div>
                    ) : (
                      <DeleteForever onClick={() => handleUndo(asset.id)} className={classes.undoIcon} />
                    )}
                  </TableCellCustom>
                </TableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </>
  );

  const getMaxForNeurotoxinInput = () => {
    const selectedAssets = productsSelected.find(({ id }: any) => id === modalData.serviceId)?.assets;
    const total = getTotal(selectedAssets, 'requestedUnits');
    return (totalServicesUnits[modalData.serviceId] || 0) - total;
  };

  return (
    <ActiveStepWrapper step={9} onClick={updateSelectedStep} display={display}>
      <Card style={{ marginBottom: '0px' }}>
        <CardContent className={classes.cardContent}>
          <div className={classes.contentCheckout}>
            <Typography>Inventory update</Typography>
          </div>
          {serviceVisit.opened && !disabledScan && (
            <div className={classes.contentButton}>
              <Scanner successCallback={handleAssetChecked} scannerInputRef={scannerInputRef} disabled={disabledScan} />
            </div>
          )}
          <div className={classes.contentTotal}>{ProductList}</div>
        </CardContent>
      </Card>
      <RemainingUnitsModal
        open={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        confirmUnits={confirmUnits}
        asset={modalData}
        primaryText={getPrimaryTextForModal()}
        secondaryText="How many will you draw?"
        secondaryButtonLabel="No, I'll draw fewer or more"
        max={getMaxForNeurotoxinInput()}
      />
    </ActiveStepWrapper>
  );
};
