/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Box, InputAdornment, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Moment } from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { CALENDAR_DATETIME_FORMAT } from 'src/constants/inventory.constants';

const useStyles = makeStyles(() => ({
  fromDateBox: {
    width: '49%',
    paddingRight: '1%',
    minWidth: '130px',
  },
  toDateBox: {
    width: '50%',
    paddingLeft: '1%',
    minWidth: '130px',
  },
}));

interface Props {
  startEndOfDate?: boolean; // Implement start and end of date in date range
  disabled?: boolean;
  onChangeFromDate: (value: string | null) => void;
  onChangeToDate: (value: string | null) => void;
}

const CloseAdornment = ({ onClick }: { onClick: () => void }) => (
  <InputAdornment position="end" style={{ position: 'relative', top: '-30px', right: '7px', justifyContent: 'right' }}>
    <IconButton data-cy="searchBtn" style={{ padding: 0 }} onClick={onClick}>
      <CloseIcon />
    </IconButton>
  </InputAdornment>
);
const CustomFromDateTextField = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TextField {...props} name="fromDate" style={{ marginBottom: '10px' }} fullWidth />
);

const CustomEndDateTextField = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TextField {...props} name="endDate" style={{ marginBottom: '10px' }} fullWidth />
);

const CustomDateRangeSelector = ({ startEndOfDate, disabled, onChangeFromDate, onChangeToDate }: Props) => {
  const classes = useStyles();
  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);

  const handleChangeFromDate = (date: any) => {
    const value = startEndOfDate
      ? date.startOf('day').format(CALENDAR_DATETIME_FORMAT)
      : date.format(CALENDAR_DATETIME_FORMAT);
    setFromDate(value);
    onChangeFromDate(value);
  };

  const handleChangeToDate = (date: any) => {
    const value = startEndOfDate
      ? date.endOf('day').format(CALENDAR_DATETIME_FORMAT)
      : date.format(CALENDAR_DATETIME_FORMAT);
    setToDate(value);
    onChangeToDate(value);
  };

  return (
    <Box display="flex" paddingTop="10px">
      <Box className={classes.fromDateBox}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            size="small"
            variant="dialog"
            inputVariant="outlined"
            label="From date"
            format="MM/DD/yyyy"
            autoOk
            disableToolbar
            value={fromDate || null}
            onChange={handleChangeFromDate}
            TextFieldComponent={CustomFromDateTextField}
            disabled={disabled}
            maxDate={toDate || undefined}
          />
          {!!fromDate && (
            <CloseAdornment
              onClick={() => {
                setFromDate(null);
                onChangeFromDate('');
              }}
            />
          )}
        </MuiPickersUtilsProvider>
      </Box>
      <Box className={classes.toDateBox}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            size="small"
            variant="dialog"
            inputVariant="outlined"
            label="To date"
            format="MM/DD/yyyy"
            autoOk
            disableToolbar
            value={toDate || null}
            onChange={handleChangeToDate}
            TextFieldComponent={CustomEndDateTextField}
            disabled={disabled}
            minDate={fromDate || undefined}
          />
          {!!toDate && (
            <CloseAdornment
              onClick={() => {
                setToDate(null);
                onChangeToDate('');
              }}
            />
          )}
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
};

export default CustomDateRangeSelector;
