import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography, TextareaAutosize } from '@material-ui/core';
import { dispatch } from '../../../rematch';
import { ADMIN_ROLE } from '../../../constants/general.constants';
import { ButtonSaveNote } from './CustomerItem.styles';

const TextAreaStyles = {
  width: '100%',
  minHeight: '75px',
  maxHeight: '98px',
  marginTop: '0',
  borderRadius: '3px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: '#fff',
  padding: '13px 14px',
  fontFamily: 'Messina Sans Regular',
  fontSize: '15px',
  '&:focus': {
    border: '2px solid #12574d',
    outline: 'none !important',
  },
};

const ServiceVisitNotes = ({ visit }: any) => {
  const { patientId } = useParams<any>();
  const { serviceVisit } = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const { id, customerNote, internalNote } = serviceVisit;
  const [note, setNotes] = useState<any>({
    customerNote: '',
    internalNote: '',
  });

  const userType = useSelector(({ auth }: any) => auth.userType);

  useEffect(() => {
    setNotes({ customerNote: visit.customerNote, internalNote: visit.internalNote });
  }, [id]);

  const onChangeNote = (event: any) => {
    event.persist();
    setNotes((prevState: any) => ({ ...prevState, [event.target.name]: event.target.value }));

    const payload = { internalNote, customerNote };
    dispatch({
      type: 'newServiceVisit/updateNotes',
      payload: {
        ...payload,
        [event.target.name]: event.target.value,
      },
    });
  };

  const saveNotes = () => {
    dispatch({
      type: 'newServiceVisit/saveNotesForServiceVisit',
      payload: {
        serviceVisitId: visit.id,
        body: {
          customerNote: note.customerNote,
          internalNote: note.internalNote,
        },
        callback: () => dispatch.fullServiceHistory.getFullServiceHistory({ patientId }),
      },
    });
  };

  return (
    <>
      <Box style={{ marginTop: '32px' }}>
        <Box mb={1} mt={2}>
          <Typography>Internal note</Typography>
        </Box>
        <Box>
          {userType !== ADMIN_ROLE ? (
            <TextareaAutosize
              onChange={onChangeNote}
              name="internalNote"
              value={note.internalNote}
              placeholder="Internal note"
              style={TextAreaStyles}
            />
          ) : (
            <span>{note.internalNote || 'There are no notes yet'}</span>
          )}
        </Box>
        <Box mb={1} mt={2}>
          <Typography className="mt-2">Customer note</Typography>
        </Box>
        <Box>
          {userType !== ADMIN_ROLE ? (
            <TextareaAutosize
              onChange={onChangeNote}
              name="customerNote"
              value={note.customerNote}
              placeholder="Customer note"
              style={TextAreaStyles}
            />
          ) : (
            <span>{note.customerNote || 'There are no notes yet'}</span>
          )}
        </Box>
      </Box>
      {userType !== ADMIN_ROLE && (
        <Box display="flex">
          <Box ml="auto">
            <Button onClick={saveNotes} style={ButtonSaveNote} variant="contained" disableElevation>
              Save
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ServiceVisitNotes;
