import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import ButtonAhref from '../../common/ButtonAhref';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_ADMIN_USER } from '../../../constants/actions.constants';

const AdministratorsHeader: FC = () => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  return (
    <Box padding={2}>
      <Box display="flex" justifyContent="flex-end">
        <Box>
          {hasAccessTo(CREATE_ADMIN_USER, permissions) && (
            <ButtonAhref buttonStyle="big" text="NEW ADMIN USER" href="/administrator/new-user" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AdministratorsHeader;
