// eslint-disable-next-line import/prefer-default-export
export const replaceVariables = (string: string, obj: any, fallback?: any) => {
  let stringLocal = string;
  // eslint-disable-next-line
  for (const prop in obj) {
    stringLocal = stringLocal
      ? stringLocal.replace(new RegExp(`%${prop}%`, 'g'), obj[prop])
      : fallback || '';
  }
  return stringLocal;
};
