import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  pill: {
    fontFamily: 'Roboto, Messina Sans SemiBold',
    fontSize: '14px',
    lineHeight: 1.43,
    height: '28px',
    minWidth: '68px',
    borderRadius: '4px',
    textAlign: 'center',
  },
  teal: {
    backgroundColor: '#BDEBF1',
    color: '#00000',
    border: 'none',
  },
  red: {
    color: '#FFFFFF',
    backgroundColor: '#ff5252',
    border: '1px solid #ff5252',
  },
  white: {
    color: '#ff5252',
    backgroundColor: '#FFFFFF',
    border: '1px solid #ff5252',
  },
  green: {
    color: '#3a3b3f',
    backgroundColor: '#d1f1bd',
    border: '1px solid #d1f1bd',
  },
  orange: {
    color: '#3a3b3f',
    backgroundColor: '#fac980',
    border: '1px solid #fac980',
  },
  gray: {
    color: '#3a3b3f',
    backgroundColor: '#d6d6d6',
    border: '1px solid #d6d6d6',
  },
  lightRed: {
    color: '#3a3b3f',
    backgroundColor: '#FFBABA',
    border: '1px solid #FFBABA',
  },
}));

export type PillColors = 'teal' | 'white' | 'red' | 'orange' | 'green' | 'gray' | 'lightRed' | 'lightGreen';

type Props = {
  title: React.ReactNode;
  color?: PillColors;
  style?: object;
};

export const Pill = ({ title, color, style }: Props) => {
  const classes = useStyles();
  return <Chip label={title} className={`${classes.pill} ${classes[color || 'red']}`} style={style} />;
};
