import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, CircularProgress, debounce } from '@material-ui/core';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import { EHRTextField } from 'src/components/ui/v1';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { useCreatePositions } from 'src/hooks/queries/useAccountSettings';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';

interface PositionsProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus, value?: any) => void;
  position?: string;
  progressPercentage?: number;
  disabled?: boolean;
}

const Positions = ({ stepId, onStepUpdate, position, progressPercentage, disabled = false }: PositionsProps) => {
  const [positionInput, setPositionInput] = useState<string>('');
  const { mutateAsync: updatePosition, isError, isLoading, isSuccess } = useCreatePositions();
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();
  const prevState = useRef(position);

  const handleUpdateCheckoutNote = async (pos: string) => {
    await updatePosition({ position: pos });
  };

  const updateNote = useCallback(debounce(handleUpdateCheckoutNote, 1500), []);

  const handleTextInput = (value?: string) => {
    setPositionInput(value || '');
    if (value) {
      updateNote(value);
    } else {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  };

  useEffect(() => {
    setPositionInput(position || '');
    onStepUpdate(stepId, position ? STEP_COMPLETED : STEP_EMPTY);
  }, [position, isSuccess]);

  useEffect(() => {
    if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isError) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [isLoading, isError]);

  useEffect(() => {
    if (prevState.current === null && position) {
      updateProgress({
        stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
        percentage: progressPercentage as number,
      });
    }

    prevState.current = position;
  }, [position]);

  return (
    <Box>
      <EHRTextField
        error={isError}
        helperText={isError ? 'Error updating position' : ''}
        label="Positions"
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
          handleTextInput(event?.target?.value)
        }
        value={positionInput}
        dataCy="positions-input"
        disabled={disabled}
        startAdornment={
          isLoading ? (
            <Box width="40px" textAlign="center">
              {' '}
              <CircularProgress size={20} />
            </Box>
          ) : null
        }
      />
    </Box>
  );
};

export default Positions;
