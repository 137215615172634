import React from 'react';
import { useParams } from 'react-router-dom';
import Photos from '../PatientProfile/Photos';
import useWindowDimensions from '../../utils/useWindowDimensions';

import {
  MIN_ITEMS_PHOTOS_LIMIT_MOBILE,
  ITEMS_PHOTOS_LIMIT_DESKTOP,
  WIDTH_MAX_DESKTOP,
} from '../../constants/general.constants';
import { IServiceVisitPhoto } from '../../interfaces/IServiceVisits';
import { preparePhotoParams } from '../../utils/serviceVisit/photo.utils';
import { useSaveServiceVisitPhotoMutation } from '../../hooks/serviceVisit/useServiceVisitPhotos';
import { BEFORE_PHOTO_TYPE } from '../../constants/serviceVisits/serviceVisit.constants';

const cardContentStyle = {
  padding: '11px 20px 20px 20px',
};

export type Props = {
  isLoading: boolean;
  photos: IServiceVisitPhoto[];
  opened: boolean;
  isPhotoRequired: boolean;
};

export const BeforePhotosStep = ({ isLoading, photos, opened, isPhotoRequired }: Props) => {
  const { serviceVisitId }: any = useParams();
  const { width } = useWindowDimensions();
  const ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE =
    width > WIDTH_MAX_DESKTOP ? ITEMS_PHOTOS_LIMIT_DESKTOP : MIN_ITEMS_PHOTOS_LIMIT_MOBILE;

  const savePhotoMutation = useSaveServiceVisitPhotoMutation();

  const uploadBeforePhoto = async (files: File[]) => {
    const formData = await preparePhotoParams(files);
    formData.append('photo_type', BEFORE_PHOTO_TYPE);
    formData.append('service_visit_id', serviceVisitId);
    savePhotoMutation.mutate({ serviceVisitId, formData });
  };

  return (
    <Photos
      required={isPhotoRequired && !photos.length}
      inputFileId="beforePhoto"
      cardStyles={{ marginBottom: '0' }}
      cardContentStyle={cardContentStyle}
      title="Before photos"
      photos={photos}
      isUploadingPhoto={savePhotoMutation.isLoading}
      isLoading={isLoading}
      uploadPhotosCallback={uploadBeforePhoto}
      showUploadPhotoButton={opened}
      itemsPhotos={ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE}
    />
  );
};
