// eslint-disable-next-line import/no-unresolved
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
  buttonLeft: {
    width: '123px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#e7eeed',
  },
  headerTitle: {
    fontSize: '24px',
    letterSpacing: '-0.05px',
    color: '#12574D',
    opacity: 1,
    fontFamily: 'Messina Sans Regular',
    textAlign: 'center',
  },
  headerSubTitle: {
    fontSize: 14,
    fontWeight: 'normal',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 40px',
  },
  cellContainer: {
    border: 'none',
  },
  buttonRight: {
    width: '187px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#fbdada',
  },
  title: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '24px !important',
    fontWeight: 600,
    paddingLeft: '35px',
    paddingTop: '25px',
  },
  content: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    fontHeight: 1.38,
    paddingLeft: '15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '60px',
  },
  containerDialog: {
    width: '350px',
    minHeight: '251px',
  },
  containerDialogReset: {
    width: '450px',
    marginTop: '30px',
    minHeight: '251px',
  },
});
