import Annotation from '../interfaces/drawer.interfaces';

export const OLD_DATA_PLACEHOLDER = {
  canvasData: '',
  note: '',
  relevant: null,
  signed: false,
  svgData: '',
  svgDataInverse: '',
};

export const SELECTED_PHOTO_INITIAL_STATE = {
  id: 0,
  serviceVisitId: 0,
  photoType: '',
  seenByDoctor: false,
  thumbnailUrl: '',
  smallUrl: '',
  mediumUrl: '',
  largeUrl: '',
  createdAt: '',
  uploadedAt: '',
};

export const SELECTED_SERVICE_INITIAL_STATE = {
  id: 0,
  name: '',
  description: '',
  price: 0,
  defaultPrice: 0,
  serviceGroupId: 0,
  multiplier: '',
  color: '',
  createdAt: '',
  updatedAt: '',
  unitLabel: '',
  assetLabel: '',
  untracked: false,
  allowPartialSale: false,
  showInEmr: false,
  annotatable: false,
  galdermaSku: '',
  internalNote: '',
  serviceType: undefined,
  variants: [],
  isVariantItem: false,
  currentCost: 0,
  daysToExpireAfterOpened: 0,
  externalNote: '',
  hasStandingOrder: false,
  maxPrice: '',
  minPrice: '',
  startingUnits: 0,
};

export const ANNOTATION_LINE_INITIAL_STATE: Annotation.Line[] = [
  {
    color: '',
    id: 0,
    points: [],
    strokeWidth: 0,
    tool: 'pen',
  },
];

export const DEFAULT_STROKE = 2;
export const DEFAULT_MIN_STROKE = 2;
