import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  beautyBankBadge: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '14px',
    lineHeight: 1.43,
    height: '28px',
    minWidth: '68px',
    borderRadius: '4px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#3a3b3f',
    backgroundColor: '#FFDFDF',
    border: '1px solid #FFDFDF',
    paddingLeft: '12px',
    paddingRight: '12px',
    margin: 'auto 5px',
  },
  diamond: {
    width: '12.93px',
    height: '10.43px',
    alignSelf: 'center',
    textAlign: 'center',
    marginRight: '5px',
    fontSize: '20px',
  },
  diamondTop: {
    width: '12.93px',
    height: '2.71px',
    backgroundColor: '#FF94B5',
    clipPath: 'polygon(0% 100%, 10% 0%, 90% 0%, 100% 100%, 95% 100%, 5% 100%)',
  },
  diamondBottom: {
    width: '12.93px',
    height: '7.72px',
    borderleft: '6.465px solid transparent',
    borderRight: '6.465px solid transparent',
    borderStyle: 'solid',
    borderWidth: '7.72px 6.47px 0 6.47px',
    borderColor: '#ED547C transparent transparent transparent',
  },
  textLabel: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    alignSelf: 'center',
  },
  errorAlert: {
    marginLeft: '5px',
    color: 'red',
  },
  circularProgress: {
    color: 'black',
    marginLeft: '20px',
  },
}));
