import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import { PractitionerChartReviews } from '../../services/PractitionerChartReviews';
import { ChartReview, IUpdateChartNote } from '../../interfaces/IPractitionerChartReview';
import { PRACTITIONER_CHART_REVIEWS } from '../../constants/reactQuery.keys';

const useChartReviews = ({
  showSaved,
  showUnread,
  page,
  enabled = true,
}: IUpdateChartNote & { enabled: boolean }): UseQueryResult<ChartReview[]> =>
  useQuery<ChartReview[]>({
    queryKey: [PRACTITIONER_CHART_REVIEWS, showSaved, showUnread, page],
    queryFn: (): Promise<ChartReview[]> =>
      PractitionerChartReviews.getCharts({ showSaved, showUnread, page } as IUpdateChartNote),
    enabled,
  });

const useUpdateChartReviews = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ acknowledged, serviceVisitId }: IUpdateChartNote): Promise<void> => {
      PractitionerChartReviews.updateChart({
        serviceVisitId,
        acknowledged,
      } as IUpdateChartNote);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [PRACTITIONER_CHART_REVIEWS] });
    },
  });
};

export { useUpdateChartReviews, useChartReviews, useChartReviews as default };
