import { GET_RECONSTITUTION_EXPIRY } from '../constants/apiRoutes.constants'
import { ExpirationDays } from '../interfaces/ISectionProducts'
import Api from './Api';

export const ReconstitutionExpiry = {
  async getReconstitutionExpiry(): Promise<ExpirationDays> {
    const { success, data }: { success: boolean, data: ExpirationDays } = await Api.get(
      GET_RECONSTITUTION_EXPIRY
    )
    return success ? data : null
  },

  async updateReconstitutionExpiry(days: number): Promise<void> {
    await Api.post(
      GET_RECONSTITUTION_EXPIRY,
      { days }
    )
  }
}