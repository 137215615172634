import { useQuery } from 'react-query';
import { USA_COORDS } from 'src/constants/google';
import { CITIES, COORDINATES, INFO_FROM_COORDINATES, STATES } from '../../constants/reactQuery.keys';
import { getCities, getCoordinates, getInfoFromCoords, getStates } from '../../services/States';

export function useStates(enabled: boolean = true) {
  return useQuery([STATES], () => getStates(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled,
  });
}

export function useCities(state?: string) {
  return useQuery([CITIES, state], () => getCities(state), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !!state,
  });
}

export function useCoordinates(params: any, enabled: boolean) {
  return useQuery([COORDINATES, JSON.stringify(params)], () => getCoordinates(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: USA_COORDS,
    enabled,
  });
}

export function useInfoFromCoords(params: any, enabled: boolean) {
  return useQuery([INFO_FROM_COORDINATES, JSON.stringify(params)], () => getInfoFromCoords(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: {},
    enabled,
  });
}
