import React, { useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Modal,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

interface PhysicianFormTypes {
  isModalOpen: boolean;
  handleCloseCallback: () => void;
  submitCallback: (prop: any) => Promise<void>;
}

const buttonStyles = {
  color: 'white',
  backgroundColor: '#12574d',
  margin: '0 3px',
  fontSize: '12px',
  paddingRight: '8px',
  paddingLeft: '8px',
};

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    width: '100%',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
}));

export const schemaForm = yup.object().shape({
  firstName: yup.string().required('First name is a required field.'),
  lastName: yup.string().required('Last name is a required field.'),
  professionalName: yup.string(),
  medicalMemberType: yup.string().required('Medical Member Type is a required field.'),
  notes: yup.string(),
});

const PhysicianForm = ({ isModalOpen, handleCloseCallback, submitCallback }: PhysicianFormTypes) => {
  const [isLoading, setsIsLoading] = useState(false);
  const classes = useStyles();
  const { handleSubmit, errors, reset, control, getValues } = useForm({
    resolver: yupResolver(schemaForm),
    defaultValues: {
      firstName: '',
      lastName: '',
      professionalName: '',
      medicalMemberType: '',
      notes: '',
    },
  });

  const onSubmit = () => {
    setsIsLoading(true);
    submitCallback(getValues());
    reset();
    setsIsLoading(false);
  };

  return (
    <Modal open={isModalOpen} onClose={() => {}} className={classes.modal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent className={classes.cardContent}>
            <h3>Please fill out the following form to complete GFE clearance</h3>
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <Controller
                control={control}
                name="firstName"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="firstName"
                    error={!!errors?.firstName}
                    fullWidth
                    helperText={errors?.firstName?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="First Name"
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <Controller
                control={control}
                name="lastName"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="lastName"
                    error={!!errors?.lastName}
                    fullWidth
                    helperText={errors?.lastName?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Last Name"
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <Controller
                control={control}
                name="professionalName"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="professionalName"
                    error={!!errors?.professionalName}
                    fullWidth
                    helperText={errors?.professionalName?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Professional Name"
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <Controller
                control={control}
                name="medicalMemberType"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="medicalMemberType"
                    error={!!errors?.medicalMemberType}
                    fullWidth
                    helperText={errors?.medicalMemberType?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Medical Member Type"
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <p style={{ marginBottom: 0 }}>Notes</p>
              <Controller
                control={control}
                name="notes"
                render={({ onChange, value }) => (
                  <TextareaAutosize
                    onChange={(event) => onChange(event.target.value)}
                    name="Notes"
                    value={value || ''}
                    style={{ width: '100%', height: '100px', resize: 'none', margin: '20px 0' }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button style={buttonStyles} type="submit" title="Complete GFE">
                {!isLoading ? (
                  'Complete GFE'
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <CircularProgress size={20} color="secondary" />
                    Processing
                  </div>
                )}
              </Button>
              <Button title="Close" onClick={handleCloseCallback}>
                Close
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Modal>
  );
};

export default PhysicianForm;
