import React, { FC, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { Box, makeStyles, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Page from 'src/components/common/Page';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import InputSearch from '../../common/InputSearch';
import ReconciliationReportsRow from './ReconciliationReportsRow';
import { dispatch } from '../../../rematch';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';

const useStyles = makeStyles(() => ({
  inputSearch: {
    fontFamily: 'Messina Sans Regular',
    height: '47px',
  },
}));

export interface ReconciliationReportsPageProps {}

const ReconciliationReportsPage: FC<ReconciliationReportsPageProps> = React.memo(() => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const { reconciliationReports, orderByDate, isLoading } = useSelector((state: any) => state.reconciliationReport);

  useEffect(() => {
    dispatch.reconciliationReport.getReconciliationReports();
  }, []);

  useEffect(() => {
    if (!search.trim().length) {
      dispatch.reconciliationReport.getReconciliationReports();
    }
  }, [search]);

  const doSearch = useMemo(
    () =>
      debounce((query: string) => dispatch.reconciliationReport.filterReconciliationReports({ value: query }), 1000),
    []
  );

  const onChange = (event: React.ChangeEvent<any>) => {
    setSearch(event.target.value);
    doSearch(event.target.value);
  };

  const handleOrder = () => {
    if (orderByDate === 'desc') {
      dispatch.reconciliationReport.setSort('asc');
    }
    if (orderByDate === 'asc') {
      dispatch.reconciliationReport.setSort('desc');
    }
    dispatch.reconciliationReport.sortByDate(orderByDate);
  };

  const MyBreadcrumb = (
    <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BreadcrumbsContainer>
          <BreadcrumbText text="Inventory" />
          <BreadcrumbText text="Reconciliation" isActive linkTo="/administrator/reconciliation-reports" />
        </BreadcrumbsContainer>
      </Box>
    </Box>
  );

  const Header = (
    <Box padding={2}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="row">
          <Box mr="21px">
            <InputSearch
              onChangeValue={onChange}
              className={classes.inputSearch}
              value={search}
              placeholder="Search by nurse"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const MyTable = isLoading ? (
    <MultipleSkeleton />
  ) : (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader>Nurse name</TableCellHeader>
              <TableCellHeader onClick={() => handleOrder()}>Date</TableCellHeader>
              <TableCellHeader>Status</TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {reconciliationReports.map((reconciliationReport: any) => (
              <ReconciliationReportsRow
                key={reconciliationReport.id}
                id={reconciliationReport.id}
                practitionerName={reconciliationReport.practitionerName}
                date={moment(reconciliationReport.createdAt).format('MM/DD/YYYY')}
                status={reconciliationReport.status}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Page title="Reconciliation Reports">
      <Box width="100%">
        {MyBreadcrumb}
        {Header}
        {MyTable}
      </Box>
    </Page>
  );
});

export default ReconciliationReportsPage;
