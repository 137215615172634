/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Checkout from './Checkout';
import CheckoutWithCard from './CheckoutWithCard';
import EditContactInfo from './EditContactInfo';
import MedicalProfile from './MedicalProfile';
import Messaging from './Messaging';
import ServiceVisitContainer from './ServiceVisit/ServiceVisitContainer';
import NewServiceVisit from './NewServiceVisit';
import PatientConsents from './PatientConsents';
import ProgressNote from '../components/ProgressNote';
import PractitionerAdminPatientProfile from './PractitionerAdminPatientProfile';
import PrivateRoute from '../utils/PrivateRoute';
import SkinProfile from './SkinProfile';
import PatientChargeCard from './PatientChargeCard';
import PatientCreditCards from './PatientCreditCards';

import { getDraft } from '../services/ReconciliationReport';
import { dispatch } from '../rematch';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import {
  READ_PATIENT_MEDICAL_PROFILE,
  READ_PATIENT_CONTACT_INFO,
  UPDATE_PATIENT_CONTACT_INFO,
  READ_MESSAGE_THREAD,
  READ_BEAUTIBANK,
  READ_INVENTORY,
} from '../constants/actions.constants';
import { useOpenedServiceVisit } from '../hooks/queries/useOpenedServiceVisit';
import ServiceVisitCheckoutContainer from './ServiceVisitCheckout/ServiceVisitCheckoutContainer';
// eslint-disable-next-line max-len
import PatientManageMembership from './PatientManageMembership';
import { hasAccessTo } from '../utils/auth.utils';

export const ReconciliationReportGuard = (props: any) => {
  const { userId, permissions } = useSelector(({ auth }: any) => auth);

  const {
    component: Component,
    computedMatch: {
      params: { patientId },
    },
    ...otherProps
  } = props;

  const { data: openedServiceVisitId } = useOpenedServiceVisit({ patientId });

  const [loading, setLoading] = useState(true);
  const [hasOpenReconciliationReport, setHasOpenReconciliationReport] = useState(false);

  useEffect(() => {
    if (hasAccessTo(READ_INVENTORY, permissions)) {
      const checkRoconciliationReport = async () => {
        try {
          await getDraft(userId);
          setHasOpenReconciliationReport(true);
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: 'You have a drafted reconciliation report. You must submit or discard it before continuing.',
              type: 'warning',
              duration: 5000,
            },
          });
        } catch (e) {
          setHasOpenReconciliationReport(false);
        } finally {
          setLoading(false);
        }
      };
      if (userId && openedServiceVisitId) {
        checkRoconciliationReport();
      }
    }
  }, [userId, openedServiceVisitId, permissions]);

  return (
    <Route
      {...otherProps}
      render={() => {
        if (!hasOpenReconciliationReport) {
          /* eslint-disable react/destructuring-assignment */
          return <Component match={props.computedMatch} {...props} />;
        }

        return loading ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MultipleSkeleton />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              /* eslint-disable react/destructuring-assignment */
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

const PractitionerPatientRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute path={`${path}/:patientId/edit`} permission={READ_PATIENT_CONTACT_INFO}>
        <Route component={EditContactInfo} />
      </PrivateRoute>
      <Route path={`${path}/:patientId/consents`} component={PatientConsents} />
      <PrivateRoute path={`${path}/:patientId/medicalProfile`} permission={READ_PATIENT_MEDICAL_PROFILE}>
        <Route component={MedicalProfile} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/messaging`} permission={READ_MESSAGE_THREAD}>
        <Route component={Messaging} />
      </PrivateRoute>
      <Route path={`${path}/:patientId/skinProfile`} component={SkinProfile} />
      <ReconciliationReportGuard
        path={`${path}/:patientId/serviceVisit/:serviceVisitId/checkout`}
        component={Checkout}
      />
      <ReconciliationReportGuard
        path={`${path}/:patientId/serviceVisit/:serviceVisitId/checkoutWithCard`}
        component={CheckoutWithCard}
      />
      <Route path={`${path}/:patientId/progressNote`} component={ProgressNote} />
      <PrivateRoute path={`${path}/:patientId/retailCheckout`} permission={UPDATE_PATIENT_CONTACT_INFO}>
        <Route component={PatientChargeCard} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/creditCards`} permission={UPDATE_PATIENT_CONTACT_INFO}>
        <Route component={PatientCreditCards} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/membership`} permission={READ_BEAUTIBANK}>
        <Route component={PatientManageMembership} />
      </PrivateRoute>
      <ReconciliationReportGuard path={`${path}/:patientId/serviceVisit/:serviceVisitId`} component={NewServiceVisit} />
      <ReconciliationReportGuard
        path={`${path}/:patientId/newServiceVisit/:serviceVisitId/checkout`}
        component={ServiceVisitCheckoutContainer}
      />
      <ReconciliationReportGuard
        path={`${path}/:patientId/newServiceVisit/:serviceVisitId`}
        component={ServiceVisitContainer}
      />
      <Route path={`${path}/:patientId`} component={PractitionerAdminPatientProfile} />
    </Switch>
  );
};

export default PractitionerPatientRoutes;
