import React, { useState } from 'react';
import {
  IconButton,
  makeStyles,
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { orderBy } from 'lodash';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import IconCross from '../../common/IconCross';
import { showQuantityUnits } from '../../../utils/inventory.utils';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function InventoryRow(props: any) {
  const { date, status, dataCollapse, iconCallback, reportId, showExpand } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const handleClick = (serviceId: number, counted: number) => {
    if (counted) {
      iconCallback(reportId, serviceId);
    }
  };

  return (
    <>
      <TableRow className={classes.root} style={{ backgroundColor: open ? '#e5efed' : 'white' }}>
        <TableCell width="50">
          {showExpand && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              <IconCross open={open} />
            </IconButton>
          )}
        </TableCell>
        <TableCell width="200">
          <Box component="span" fontWeight="bold">
            {date}
          </Box>
        </TableCell>
        <TableCell>{status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHeader>Product</TableCellHeader>
                    <TableCellHeader>Expected</TableCellHeader>
                    <TableCellHeader>Counted</TableCellHeader>
                    <TableCellHeader>Marked As Empty</TableCellHeader>
                    <TableCellHeader>Missing</TableCellHeader>
                    <TableCellHeader>Product IDs</TableCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderBy(dataCollapse, ['product'], ['asc']).map((data: any) => (
                    <TableRow key={`${data.serviceId}_`} style={{ height: '56px' }}>
                      <TableCellCustom>{data.product}</TableCellCustom>
                      <TableCellCustom>
                        {showQuantityUnits({ ...data, quantity: data.expected, units: data.expectedUnits })}
                      </TableCellCustom>
                      <TableCellCustom>
                        {showQuantityUnits({ ...data, quantity: data.counted, units: data.countedUnits })}
                      </TableCellCustom>
                      <TableCellCustom>
                        {showQuantityUnits({
                          ...data,
                          quantity: data.markedAsEmpty,
                          units: data.markedAsEmptyUnits,
                          showRedText: true,
                        })}
                      </TableCellCustom>
                      <TableCellCustom>
                        {showQuantityUnits({
                          ...data,
                          quantity: data.expected - data.counted,
                          units: data.expectedUnits - data.countedUnits,
                          showRedText: true,
                        })}
                      </TableCellCustom>
                      <TableCellCustom>
                        <Box
                          style={{ display: 'inline-flex', border: '1px solid #e5efed' }}
                          onClick={() => {
                            handleClick(data.serviceId, data.counted);
                          }}
                        >
                          <MenuIcon style={{ color: !data.counted ? '#E4E7EB' : undefined }} />
                        </Box>
                      </TableCellCustom>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default InventoryRow;
