import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';

import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DialogModal from 'src/components/common/DialogModal';
import { ALL_SERVICE_GROUPS } from '../../../../constants/reactQuery.keys';

import Services from '../../../../services/Services';
import {
  ASSET_LABEL,
  SERVICE_FORM_DEFAULT_VALUES,
  SUPPLIER_GALDERMA_ID,
} from '../../../../constants/general.constants';
import { ADMIN_SERVICES_LIST_PATH } from '../../../../routes/administratorRoutes';
import { UNITS } from '../../../../constants/medicalProfile.constants';
import { ServiceFormData } from '../../../../interfaces/administrator.interface';
import BreadcrumbsContainer from '../../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../../common/Breadcrumb/BreadcrumbText';
import useSuppliers from '../../../../hooks/queries/useSuppliers';
import { useService } from '../../../../hooks/queries/services/useServices';

const Header = withStyles((theme) => ({
  root: {
    fontSize: '18px',
    fontFamily: 'Messina Sans SemiBold',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}))(Typography);

const handleKeyDownOnlyNumbers = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const reg = /\D/;
  if (reg.test(event.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(event.key)) {
    event.preventDefault();
  }
};

export const ServiceForm: FC = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<ServiceFormData>({ defaultValues: SERVICE_FORM_DEFAULT_VALUES });

  const { serviceId }: { serviceId: string } = useParams();
  const editing: boolean = typeof serviceId !== 'undefined';
  const watchSupplierChange = watch('supplierId');

  const [galdermaSkuEnabled, setGaldermaSkuEnabled] = React.useState(false);
  const { data: service, isFetched, isFetching: isFetchingService } = useService(+serviceId);

  const { data: serviceGroups } = useQuery([ALL_SERVICE_GROUPS], () => Services.getServiceGroups(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { data: suppliers } = useSuppliers();

  const { data: galdermaSkus = [] } = useQuery(['galdermaSkus'], () => Services.getGaldermaSkus(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { mutateAsync: createService, isLoading } = useMutation((serviceParam: ServiceFormData) =>
    Services.createService(serviceParam)
  );

  const { mutateAsync: updateService, isLoading: isUpdating } = useMutation((serviceParam: ServiceFormData) =>
    Services.updateService(+serviceId, serviceParam)
  );
  const loading = isFetchingService || isLoading || isUpdating;

  useEffect(() => {
    if (editing && isFetched) {
      const {
        name,
        serviceGroupId,
        supplierId,
        unitLabel,
        assetLabel,
        startingUnits,
        price,
        daysToExpireAfterOpened,
        currentCost,
        description,
        internalNote,
        externalNote,
        prefix,
        followUpIntervalDays,
        color,
        showInEmr,
        untracked,
        annotatable,
        allowPartialSale,
        currentCostWithGuaranteedRebates,
        currentCostWithAllRebates,
        legacyPrice,
        legacyPlusPrice,
        luxePrice,
        flexPrice,
        litePrice,
        listPrice,
      } = service;

      setValue('name', name);
      setValue('serviceGroupId', serviceGroupId);
      setValue('supplierId', supplierId);
      setValue('unitLabel', unitLabel);
      setValue('assetLabel', assetLabel);
      setValue('startingUnits', startingUnits);
      setValue('price', price);
      setValue('daysToExpireAfterOpened', daysToExpireAfterOpened);
      setValue('currentCost', currentCost);
      setValue('description', description);
      setValue('internalNote', internalNote);
      setValue('externalNote', externalNote);
      setValue('prefix', prefix);
      setValue('followUpIntervalDays', followUpIntervalDays);
      setValue('color', color);
      setValue('showInEmr', showInEmr);
      setValue('isTracked', !untracked);
      setValue('annotatable', annotatable);
      setValue('allowPartialSale', allowPartialSale);
      setValue('currentCostWithGuaranteedRebates', currentCostWithGuaranteedRebates);
      setValue('currentCostWithAllRebates', currentCostWithAllRebates);
      setValue('legacyPrice', legacyPrice);
      setValue('legacyPlusPrice', legacyPlusPrice);
      setValue('luxePrice', luxePrice);
      setValue('flexPrice', flexPrice);
      setValue('litePrice', litePrice);
      setValue('listPrice', listPrice);
    }
  }, [service]);

  useEffect(() => {
    setGaldermaSku(service.galdermaSku);
  }, [service, galdermaSkus]);

  // if the supplier selected is not galderma disable and clean galderma SKU field
  useEffect(() => {
    const isGaldermaSelected = watchSupplierChange === SUPPLIER_GALDERMA_ID;

    setGaldermaSkuEnabled(isGaldermaSelected);
    if (!isGaldermaSelected) {
      setValue('galdermaSku', []);
    }
  }, [watchSupplierChange]);

  const setGaldermaSku = (value: string | undefined) => {
    if (!value) {
      return;
    }
    const galdermaSkusValue = JSON.parse(value);

    const matchedGaldermaSkus = Object.keys(galdermaSkusValue)
      .filter((location) => location in galdermaSkusValue)
      .map((location) => galdermaSkusValue[location])
      .flatMap((skuValue) => galdermaSkus.filter((skuObj) => skuObj.sku === skuValue));

    setValue('galdermaSku', matchedGaldermaSkus);
  };

  const onSubmit = handleSubmit(async (formData) => {
    setDialogOpen(false);

    try {
      let response: any;

      if (!editing) {
        response = await createService(formData);
      } else {
        response = await updateService(formData);
      }

      if (!response.success) {
        toast.error('There was an error creating the service');
        return;
      }
      const action = editing ? 'updated' : 'created';
      toast.success(`Service ${action} successfully.`);

      history.push(ADMIN_SERVICES_LIST_PATH);
      reset();
    } catch (error) {
      toast.error('There was an error, please try again');
    }
  });

  return (
    <>
      <Box paddingRight={5} style={{ backgroundColor: '#f2f5f5' }} width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <BreadcrumbsContainer>
            <BreadcrumbText text="Services" linkTo="/administrator/services" />
            <BreadcrumbText text={editing ? 'Edit Service' : 'New Service'} isActive />
          </BreadcrumbsContainer>
        </Box>
      </Box>
      <Box m={2}>
        <Box mb={4}>
          <Header>{editing ? 'Edit Service' : 'New service'}</Header>
        </Box>
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="name"
                rules={{ required: 'Name is required' }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="name"
                    error={!!errors?.name}
                    fullWidth
                    helperText={errors?.name?.message ?? ' '}
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                    label="Service name"
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="serviceGroupId"
                rules={{ required: 'Service group is required' }}
                render={({ onChange, value }) => (
                  <FormControl data-cy="serviceGroup" variant="outlined" fullWidth error={!!errors?.serviceGroupId}>
                    <InputLabel shrink>Service group</InputLabel>
                    <Select disabled={loading} fullWidth value={value} onChange={onChange} label="Service group">
                      {serviceGroups?.map(({ name, id }) => (
                        <MenuItem data-cy="serviceItem" value={id}>
                          {name}
                        </MenuItem>
                      )) ?? []}
                    </Select>
                    {errors?.serviceGroupId ? (
                      <FormHelperText>{errors.serviceGroupId.message}</FormHelperText>
                    ) : (
                      <Box height="19px" />
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                rules={{ required: 'Supplier is required' }}
                name="supplierId"
                render={({ onChange, value }) => (
                  <FormControl data-cy="supplier" variant="outlined" fullWidth error={!!errors?.supplierId}>
                    <InputLabel shrink>Supplier</InputLabel>
                    <Select
                      disabled={loading}
                      fullWidth
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      label="Supplier"
                    >
                      <MenuItem value="">None</MenuItem>
                      {suppliers?.map(({ name, id }) => (
                        <MenuItem data-cy="supplierItem" value={id}>
                          {name}
                        </MenuItem>
                      )) ?? []}
                    </Select>
                    {errors?.supplierId ? (
                      <FormHelperText>{errors.supplierId.message}</FormHelperText>
                    ) : (
                      <Box height="19px" />
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="unitLabel"
                rules={{ required: 'Unit label is required' }}
                render={({ onChange, value }) => (
                  <FormControl data-cy="unitLabel" variant="outlined" fullWidth error={!!errors?.unitLabel}>
                    <InputLabel shrink>Unit Label</InputLabel>
                    <Select disabled={loading} value={value} onChange={onChange} label="Unit label">
                      {Object.entries(UNITS).map(([key, unitLabel]) => (
                        <MenuItem data-cy="unitItem" value={key} key={key}>
                          {unitLabel}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors?.unitLabel ? (
                      <FormHelperText>{errors.unitLabel.message}</FormHelperText>
                    ) : (
                      <Box height="19px" />
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="assetLabel"
                rules={{ required: 'Asset label is required' }}
                render={({ onChange, value }) => (
                  <FormControl data-cy="assetLabel" variant="outlined" fullWidth error={!!errors?.assetLabel}>
                    <InputLabel shrink>Asset Label</InputLabel>
                    <Select disabled={loading} value={value} onChange={onChange} label="Asset Label">
                      {Object.entries(ASSET_LABEL).map(([key, assetName]) => (
                        <MenuItem data-cy="assetItem" value={key} key={key}>
                          {assetName}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors?.assetLabel ? (
                      <FormHelperText>{errors.assetLabel.message}</FormHelperText>
                    ) : (
                      <Box height="19px" />
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="startingUnits"
                rules={{
                  required: 'Starting units is required',
                  min: { value: 0.1, message: 'The min. allowed value is 0.1' },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="startingUnits"
                    error={!!errors?.startingUnits}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.startingUnits?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Starting units"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                control={control}
                defaultValue=""
                name="price"
                rules={{
                  required: 'Floor Price is required',
                  min: { value: 0, message: `Floor Price can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="price"
                    error={!!errors?.price}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.price?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Floor Price"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                control={control}
                defaultValue=""
                name="daysToExpireAfterOpened"
                rules={{
                  required: 'Expire days is required',
                  min: { value: 0, message: `Can't be negative` },
                  pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="expireDays"
                    error={!!errors?.daysToExpireAfterOpened}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.daysToExpireAfterOpened?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Days to expire after opened"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                    id="expireDays"
                    // Necessary this validation due for form-hook "e" is considered a number
                    onKeyDown={handleKeyDownOnlyNumbers}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="description"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="description"
                    fullWidth
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                    label="Description"
                    multiline
                    onChange={onChange}
                    rows={4}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="internalNote"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="internalNote"
                    fullWidth
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                    label="Internal note"
                    multiline
                    onChange={onChange}
                    rows={4}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="externalNote"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="externalNote"
                    fullWidth
                    disabled={loading}
                    InputLabelProps={{ shrink: true }}
                    label="External note"
                    multiline
                    onChange={onChange}
                    rows={4}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="prefix"
                rules={{
                  maxLength: { value: 3, message: 'Prefix should have 3 characters' },
                  minLength: { value: 3, message: 'Prefix should have 3 characters' },
                  required: 'Prefix is required',
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="prefix"
                    error={!!errors?.prefix}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.prefix?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Prefix"
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                rules={{
                  min: { value: 0, message: `Can't be negative` },
                }}
                name="followUpIntervalDays"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="followUpDays"
                    fullWidth
                    disabled={loading}
                    error={!!errors?.followUpIntervalDays}
                    helperText={errors?.followUpIntervalDays?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Follow up interval days"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="galdermaSku"
                render={({ value }) => (
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      onChange={(e, selectedOptions) => {
                        setValue('galdermaSku', selectedOptions);
                      }}
                      multiple
                      disabled={!galdermaSkuEnabled || loading}
                      disableCloseOnSelect
                      filterSelectedOptions
                      options={galdermaSkus}
                      value={value || []}
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => (
                        <TextField
                          data-cy="galdermaSKU"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          label="Galderma SKU"
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} lg="auto" />

            <Grid item xs={6} md={3} lg={2} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <Controller
                control={control}
                defaultValue=""
                name="color"
                rules={{ required: 'Color is required' }}
                render={({ onChange, value }) => (
                  <Box data-cy="color" style={{ display: 'flex' }}>
                    <Box style={{ marginRight: 10 }}>
                      <Typography>Color</Typography>
                    </Box>
                    <input
                      disabled={loading}
                      data-cy="colorPicker"
                      type="color"
                      name="body"
                      value={value}
                      onChange={onChange}
                    />
                    {!!errors.color && (
                      <Box ml={2}>
                        <Typography style={{ color: 'red' }}>{errors.color.message}</Typography>
                      </Box>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <Controller
                control={control}
                defaultValue={false}
                name="showInEmr"
                render={({ onChange, value }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-cy="showInEmr"
                        checked={value}
                        disabled={loading}
                        onChange={(e) => onChange(e.target.checked)}
                        style={{ color: '#12574d' }}
                      />
                    }
                    label="Show in EMR"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <Controller
                control={control}
                defaultValue={false}
                name="isTracked"
                render={({ onChange, value }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-cy="isTracked"
                        checked={value}
                        disabled={loading}
                        onChange={(e) => onChange(e.target.checked)}
                        style={{ color: '#12574d' }}
                      />
                    }
                    label="Is tracked"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <Controller
                control={control}
                defaultValue={false}
                name="annotatable"
                render={({ onChange, value }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-cy="annotatable"
                        checked={value}
                        disabled={loading}
                        onChange={(e) => onChange(e.target.checked)}
                        style={{ color: '#12574d' }}
                      />
                    }
                    label="Is annotatable"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <Controller
                control={control}
                defaultValue={false}
                name="allowPartialSale"
                render={({ onChange, value }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-cy="allowPartialSale"
                        checked={value}
                        disabled={loading}
                        onChange={(e) => onChange(e.target.checked)}
                        style={{ color: '#12574d' }}
                      />
                    }
                    label="Allow partial sale"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography>Service Pricing Details</Typography>
            </Grid>
            <Grid item xs={6} md={12} lg={12}>
              <Controller
                control={control}
                defaultValue=""
                name="listPrice"
                rules={{
                  required: 'List Price is required',
                  min: { value: 0, message: `List Price can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="listPrice"
                    error={!!errors?.listPrice}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.listPrice?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="List Price"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="currentCost"
                rules={{
                  required: 'COGS Invoice is required',
                  min: { value: 0, message: `COGS Invoice can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="currentCost"
                    error={!!errors?.currentCost}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.currentCost?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="COGS Invoice"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="currentCostWithGuaranteedRebates"
                rules={{
                  required: 'COGS w/ Guaranteed Rebates is required',
                  min: { value: 0, message: `COGS w/ Guaranteed Rebates can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="currentCostWithGuaranteedRebates"
                    error={!!errors?.currentCostWithGuaranteedRebates}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.currentCostWithGuaranteedRebates?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="COGS w/ Guaranteed Rebates"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={4}>
              <Controller
                control={control}
                defaultValue=""
                name="currentCostWithAllRebates"
                rules={{
                  required: 'COGS w/ All Rebates is required',
                  min: { value: 0, message: `COGS w/ All Rebates can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="currentCostWithAllRebates"
                    error={!!errors?.currentCostWithAllRebates}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.currentCostWithAllRebates?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="COGS w/ All Rebates"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Controller
                control={control}
                defaultValue=""
                name="legacyPrice"
                rules={{
                  required: 'Legacy Price is required',
                  min: { value: 0, message: `Legacy Price can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="legacyPrice"
                    error={!!errors?.legacyPrice}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.legacyPrice?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Legacy Price"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Controller
                control={control}
                defaultValue=""
                name="legacyPlusPrice"
                rules={{
                  required: 'LegacyPlus Price is required',
                  min: { value: 0, message: `LegacyPlus Price can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="legacyPlusPrice"
                    error={!!errors?.legacyPlusPrice}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.legacyPlusPrice?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="LegacyPlus Price"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Controller
                control={control}
                defaultValue=""
                name="luxePrice"
                rules={{
                  required: 'Luxe Price is required',
                  min: { value: 0, message: `Luxe Price can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="luxePrice"
                    error={!!errors?.luxePrice}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.luxePrice?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Luxe Price"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Controller
                control={control}
                defaultValue=""
                name="flexPrice"
                rules={{
                  required: 'Flex Price is required',
                  min: { value: 0, message: `Flex Price can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="flexPrice"
                    error={!!errors?.flexPrice}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.flexPrice?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Flex Price"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Controller
                control={control}
                defaultValue=""
                name="litePrice"
                rules={{
                  required: 'Lite Price is required',
                  min: { value: 0, message: `Lite Price can't be negative` },
                }}
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="litePrice"
                    error={!!errors?.litePrice}
                    fullWidth
                    disabled={loading}
                    helperText={errors?.litePrice?.message ?? ' '}
                    InputLabelProps={{ shrink: true }}
                    label="Lite Price"
                    onChange={onChange}
                    type="number"
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box style={{ marginTop: '50px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              data-cy="submitBtn"
              style={{ height: '55px', width: '400px' }}
              size="large"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              {isLoading || isUpdating ? 'Submitting' : 'Submit'}
            </Button>
          </Box>
          <DialogModal
            confirmationText="Are you sure?"
            open={dialogOpen}
            closeModal={() => {
              setDialogOpen(false);
            }}
            confirm={onSubmit}
            children={null /* eslint-disable-line react/no-children-prop */}
          />
        </form>
      </Box>
    </>
  );
};
