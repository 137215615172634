import { withStyles, Button, Typography, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
  },
  serviceTitle: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    marginRight: '10px',
    display: 'inline',
  },
  serviceSubtitles: {
    color: theme.palette.primary.main,
    fontSize: '0.9rem',
  },
  buttonPadding: {
    paddingRight: '5px',
  },
  buttonBackItems: {
    backgroundColor: '#f2f5f5',
    paddingLeft: '30px',
    paddingRight: '30px',
    borderColor: '#f2f5f5',
  },
  jar: {
    width: '15px',
    height: '41px',
    display: 'flex',
    background: '#E4E7EB 0% 0% no-repeat padding-box',
    opacity: 1,
  },
  jarCover: {
    width: '19px',
    height: '5px',
    position: 'relative',
    left: '-2px',
  },
  jarContent: {
    top: '363px',
    left: '279px',
    width: '15px',
    marginTop: '15px',
    height: '26px',
    background: '#12574D 0% 0% no-repeat padding-box',
    opacity: '1',
  },
  jarMissingContent: {
    background: '#FBDADA !important',
  },
  detailBox: {
    marginLeft: '11px',
    marginRight: '-2px',
  },
  detailBoxPrice: {
    marginRight: -11,
    backgroundColor: 'rgb(240,242,244)',
    borderBottomLeftRadius: 2,
    padding: '9px 10px 10px 11px',
    marginTop: 2,
    '&[data-extended="true"]': {
      marginTop: 21,
    },
  },
  actionButtonContainer: {
    height: '100%',
  },
  actionButtonDiv: {
    paddingLeft: '2.5px',
    paddingRight: '2.5px',
    height: '25px',
  },
  button: {
    minWidth: 'auto',
    padding: '0',
  },
  actionButton: {
    fontSize: '20px',
    width: '100%',
    margin: -2,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  productId: {
    border: '1px dashed #E4E7EB',
    '&:hover': {
      cursor: 'pointer',
      background: '#f6f6f6',
      borderRadius: '2px',
    },
  },
}));

export const Title = withStyles(() => ({
  root: {
    fontSize: '16px',
    color: '#393B3F',
    lineHeight: '18.75px',
    opacity: 0.7,
    fontFamily: 'Messina Sans Regular',
  },
}))(Typography);

export const ButtonFooter = withStyles((theme) => ({
  root: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '14px',
    textTransform: 'uppercase',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '47px',
    },
  },
}))(Button);
