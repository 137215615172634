import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TableCell, FormControl, TextField, InputAdornment } from '@material-ui/core';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  QueryBuilder as QueryBuilderIcon,
  Undo as UndoIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import MedspaAdmins from 'src/services/MedspaAdmins';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import { useMedspaRole } from 'src/hooks/queries/useMedspaAdmins';
import IServices from '../../../../interfaces/IServices';
import Services from '../../../../services/Services';
import Variants from '../../../../services/Variants';

import { TableRowStriped, useStyles } from './practitionerPricing.styles';
import { RootState, dispatch } from '../../../../rematch';
import compile from '../../../../utils/toastMessagesCompiler';
import { SERVICES } from '../../../../constants/reactQuery.keys';
import { IVariantItem } from '../../../../interfaces/IVariants';
import { ReactComponent as Alert } from '../../../../assets/images/alert.svg';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { warnLineItemBelowPriceFloor } from '../../../../utils/checkout.utils';
import { READ_FLOOR_PRICE, UPDATE_PRACTITIONER_SERVICE_PRICE_OVERRIDE } from '../../../../constants/actions.constants';

interface Props {
  service: IServices | IVariantItem;
  showHistory: (value: IServices | IVariantItem) => void;
  showReset: (value: any) => void;
  isFetching: boolean;
  isVariantItem?: boolean;
}
const ServiceRow = ({ service, isFetching, showHistory, showReset, isVariantItem }: Props) => {
  const classes = useStyles();
  const { roleName } = useSelector(({ auth }: any) => auth);
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const isMedspaActive = process.env.REACT_APP_MEDSPA_FIELDS;
  const { data: medspaRoleName } = useMedspaRole(isMedspaAdmin);
  const isLegacyMedspa = medspaRoleName === PORTRAIT_LEGACY || medspaRoleName === PORTRAIT_LEGACY_PLUS;

  const [editing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<number | string | undefined>(service.price || '');
  const permissions = useSelector(({ auth }: RootState) => auth.permissions);
  const queryClient = useQueryClient();
  const serviceMutation = useMutation(
    (newPrice: { serviceId: number; price: number }) =>
      Services.updateServicePricing(newPrice.serviceId, newPrice.price),
    {
      onSuccess: (_, { price }) => {
        queryClient.invalidateQueries(SERVICES);
        setValue(price);
        setEditing(false);
      },
    }
  );
  const variantItemMutation = useMutation(
    (newPrice: { variantItemId: number; price: number }) =>
      Variants.updateVariantItemPricing(newPrice.variantItemId, newPrice.price),
    {
      onSuccess: (_, { price }) => {
        queryClient.invalidateQueries(SERVICES);
        setValue(price);
        setEditing(false);
      },
    }
  );

  const medspaServiceMutation = useMutation(
    (newPrice: { serviceId: number; price: number }) =>
      MedspaAdmins.updateMedspaServicePricing({ serviceId: newPrice.serviceId, priceOverride: newPrice.price }),
    {
      onSuccess: (_, { price }) => {
        queryClient.invalidateQueries(SERVICES);
        setValue(price);
        setEditing(false);
      },
    }
  );

  const medspaVariantItemMutation = useMutation(
    (newPrice: { variantItemId: number; price: number }) =>
      MedspaAdmins.updateMedspaServicePricing({ variantItemId: newPrice.variantItemId, priceOverride: newPrice.price }),
    {
      onSuccess: (_, { price }) => {
        queryClient.invalidateQueries(SERVICES);
        setValue(price);
        setEditing(false);
      },
    }
  );

  const setShowPriceFloor = () =>
    isMedspaActive && isMedspaAdmin
      ? isLegacyMedspa && hasAccessTo(READ_FLOOR_PRICE, permissions)
      : hasAccessTo(READ_FLOOR_PRICE, permissions);

  const disabledPriceFloor = !setShowPriceFloor();
  const canSetPriceBelowFloor = hasAccessTo(UPDATE_PRACTITIONER_SERVICE_PRICE_OVERRIDE, permissions);

  useEffect(() => {
    if (!isFetching) {
      setValue(service.price);
      if (typeof service.price !== 'number' && disabledPriceFloor) {
        setValue(service.defaultPrice);
      }
    }
    if (isVariantItem) {
      /* eslint-disable no-param-reassign */
      service.isVariantItem = isVariantItem;
    }
  }, [service.price, isFetching, isVariantItem]);

  const onSavePrice = async () => {
    if (value && +value >= 0) {
      if (value === service.price) {
        return;
      }
      if (service?.id && (+value >= service.defaultPrice || (canSetPriceBelowFloor && +value >= 0))) {
        if (isVariantItem) {
          if (isMedspaAdmin) {
            medspaVariantItemMutation.mutateAsync({ variantItemId: service.id, price: +value });
          } else {
            variantItemMutation.mutate({ variantItemId: service.id, price: +value });
          }
        } else if (isMedspaAdmin) {
          medspaServiceMutation.mutateAsync({ serviceId: service.id, price: +value });
        } else {
          serviceMutation.mutate({ serviceId: service.id, price: +value });
        }
      } else if (service?.id && +value < service.defaultPrice && !canSetPriceBelowFloor) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('dashboard_practitioner.pricing.new_value_error'),
            type: 'error',
          },
        });
      } else {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('dashboard_practitioner.pricing.negative_value_error'),
            type: 'error',
          },
        });
      }
    }
  };

  const onReset = () => {
    if (service.defaultPrice === service.price) {
      return;
    }
    showReset(service);
  };

  const onCloseEdition = () => {
    setEditing(false);
    setValue(service.price);
  };

  const renderEditInput = () => {
    if (!editing) {
      if (typeof value === 'number') {
        if (disabledPriceFloor) {
          return <div>${value.toFixed(2)}</div>;
        }
        return value === service.defaultPrice ? (
          '-'
        ) : (
          <div>
            <div>${value.toFixed(2)}</div>
            {warnLineItemBelowPriceFloor({
              totalDefaultPrice: service.defaultPrice,
              totalWithDiscount: service.price,
              service,
              noCommission: false,
            }) && (
              <>
                <Alert className={classes.warning} /> below suggested retail price
              </>
            )}
          </div>
        );
      }
    }
    return (
      <FormControl size="small" variant="outlined">
        <TextField
          name="price"
          variant="outlined"
          size="small"
          style={{ width: '120px' }}
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value || '')}
          label="Price"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CloseIcon
                  data-cy="cancelEditPrice"
                  fontSize="small"
                  className={classes.actionButtonClose}
                  onClick={onCloseEdition}
                />
              </InputAdornment>
            ),
            type: 'number',
          }}
          data-cy="priceEditInput"
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
    );
  };

  const editIconComponent = () => {
    if (isMedspaActive && isMedspaAdmin && !isLegacyMedspa) {
      return null;
    }
    return (
      <EditIcon
        data-cy="pricingEditIcon"
        fontSize="small"
        className={classes.actionButton}
        onClick={() => setEditing(true)}
      />
    );
  };

  const undoIconComponent = () => {
    if (isMedspaActive && isMedspaAdmin && !isLegacyMedspa) {
      return null;
    }
    return (
      <UndoIcon
        data-cy="pricingResetIcon"
        fontSize="small"
        className={classnames(classes.actionButton, {
          [classes.buttonDisabled]: service.defaultPrice === service.price,
        })}
        onClick={onReset}
      />
    );
  };

  return (
    <TableRowStriped data-cy="serviceRow" className={classes.itemTab} hover key={service.id}>
      <TableCell data-cy="serviceRowName">{service.name}</TableCell>
      {!disabledPriceFloor && (
        <TableCell data-cy="serviceRowNormalPrice" className={classes.centeredColumn}>
          ${service.defaultPrice?.toFixed(2)}
        </TableCell>
      )}
      <TableCell data-cy="serviceRowOverridePrice" className={classes.centeredColumn} style={{ padding: '0px' }}>
        {renderEditInput()}
      </TableCell>
      <TableCell className={classes.centeredColumn} style={{ minWidth: '125px' }}>
        {editing ? (
          <div
            className={classnames(classes.saveButton, {
              [classes.buttonDisabled]:
                (service?.id && value && +value < service.defaultPrice && !canSetPriceBelowFloor) ||
                (service?.id && value && +value < 0 && canSetPriceBelowFloor) ||
                value === service?.price,
            })}
            onClick={onSavePrice}
            role="presentation"
          >
            <SaveIcon data-cy="editPriceSaveIcon" fontSize="small" />
            <span className={classes.saveButtonText}>Save</span>
          </div>
        ) : (
          <>
            {editIconComponent()}
            <QueryBuilderIcon
              data-cy="pricingHistoryIcon"
              fontSize="small"
              className={classes.actionButton}
              onClick={() => showHistory(service)}
            />
            {undoIconComponent()}
          </>
        )}
      </TableCell>
    </TableRowStriped>
  );
};

export default ServiceRow;
