import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dispatch } from '../rematch';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import MedicalProfileForm from '../components/PatientProfile/MedicalProfileForm/MedicalProfileForm';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import { ROUTES } from '../constants/routes.constants';

const MedicalProfile = ({
  match: {
    params: { patientId },
  },
}: any) => {
  const history = useHistory();
  const patient = useSelector(({ patient: storedPatient }: any) => storedPatient);

  useEffect(() => {
    dispatch({ type: 'patient/fetchPatientData', payload: +patientId });
  }, [patientId]);

  const navigateBack = () => {
    history.push(ROUTES.PATIENT_ID(patientId));
  };

  return (
    <main>
      <NavigationBar title="Medical profile" onBackButtonClick={navigateBack} />
      <article>{patient.isLoading ? <MultipleSkeleton /> : <MedicalProfileForm />}</article>
    </main>
  );
};

export default MedicalProfile;
