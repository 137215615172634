import { makeStyles } from '@material-ui/core';

export const useRowStyles = makeStyles({
  root: {},
  buttonStatus: {
    pending: {
      backgroundColor: '#e7e7e7',
    },
  },
  tabsContainer: {
    padding: '1rem',
  },
});

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
    height: '47px',
    lineHeight: '47px',
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '600px',
  },
  link: {
    textDecoration: 'none',
  },
}));
