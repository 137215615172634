export const STATUS_SUPPLY_RECEIPTS = [
  {
    text: 'Pending',
    value: 'pending',
  },
  {
    text: 'Saved Draft',
    value: 'draft',
  },
  {
    text: 'Closed',
    value: 'closed',
  },
];
