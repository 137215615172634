import { IAsset } from 'src/interfaces/reconciliation.interfaces';
import API_ROUTES from '../constants/apiRoutes.constants';
import { IServiceVisitAssetViewParams } from '../interfaces/IServiceVisitAsset.interface';
import Api from './Api';

const Asset = {
  async getServiceVisitAssets(paramsQuery: IServiceVisitAssetViewParams): Promise<any> {
    const { results } = await Api.get(API_ROUTES.SERVICE_VISIT_ASSETS, paramsQuery);
    return results;
  },
  async getServiceVisitAssetsCount(paramsQuery: any): Promise<number> {
    const { count } = await Api.get(API_ROUTES.SERVICE_VISIT_ASSET_COUNT, paramsQuery);
    return count;
  },
  async recoverAsset(id: number): Promise<boolean> {
    if (!id) {
      return false;
    }

    const { success } = await Api.post(API_ROUTES.ASSET_RECOVER(id), {});
    return success;
  },
  async byPurchaseOrderItem(purchaseOrderItemId: number): Promise<IAsset[]> {
    const { success, data } = await Api.get(API_ROUTES.ASSETS_BY_PO_ITEM(purchaseOrderItemId));
    return success ? data : [];
  },
};

export default Asset;
