import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      '& > div#root': {
        height: '100%',
        '& > div:not(.Toastify)': {
          height: '100%',
        },
      },
    },
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#E3E3E3',
  },
  navigationContainer: {
    flex: '1',
    maxHeight: '64px',
  },
  card: {
    margin: '15px',
    paddingBottom: '24px !important',
    padding: '24px 30px',
    background: '#F5F5F5',
    border: '5px solid transparent',
    borderRadius: '15px',
    marginBottom: '5px',
  },
  activeTab: {
    color: 'white',
    background: '#12574D',
    textTransform: 'none',
    borderRadius: '25px',
    padding: '4px 25px',
    '&:hover': {
      background: '#12574D',
    },
  },
  inactiveTab: {
    color: '#12574D',
    border: '1px solid #12574D',
    background: '#E7EEED',
    textTransform: 'none',
    borderRadius: '25px',
    padding: '4px 25px',
    '&:hover': {
      background: '#E7EEED',
    },
  },
  mr10: {
    marginRight: '10px',
  },
  cardName: {
    display: 'flex',
    alignItems: 'center',
  },
  cardIcon: {
    position: 'relative',
    top: '8px',
    marginLeft: '6px',
  },
  selectCardsContainer: {
    marginTop: '20px',
  },
  checkoutButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '12px',
    width: '236px',
    padding: '1rem 2rem',
    borderRadius: '8px',
    margin: '1rem 0',
    textAlign: 'center',
    textTransform: 'none',
    '&:disabled': {
      color: 'white',
      opacity: '50%',
    },
    '&:hover': {
      background: '#12574D',
    },
  },
  processing: {
    color: 'white',
    marginLeft: theme.spacing(2),
    position: 'relative',
    top: '4px',
  },
  acknowledgeConsent: {
    maxWidth: '550px',
  },
  tabsContainer: {
    marginBottom: '2rem',
  },
}));
