import axiosInstance from '../utils/axios';
import { toSnakeCase } from '../utils/global';

const Api = {
  async get(endpoint: string, params: any = null): Promise<any> {
    if (params) {
      // eslint-disable-next-line no-param-reassign
      params = new URLSearchParams(toSnakeCase(params));
    }
    const response = await axiosInstance.get(endpoint, { params });
    return response.data;
  },

  async post(endpoint: string, body: any): Promise<any> {
    const response = await axiosInstance.post(endpoint, body);
    return response.data;
  },

  async put(endpoint: string, body: any): Promise<any> {
    const response = await axiosInstance.put(endpoint, body);
    return response.data;
  },
  async delete(endpoint: string): Promise<any> {
    const response = await axiosInstance.delete(endpoint);
    return response.data;
  },
};

export default Api;
