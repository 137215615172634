/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@material-ui/icons';
import { DROPDOWN_APPOINTMENT_STATUSES } from '../../../constants/scheduler.constants';

const useStyles = makeStyles((theme) => ({
  dropdownSelector: {
    lineHeight: '28px',
    color: 'white',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropdownOptions: {
    top: '38px',
    left: '188px',
    zIndex: 3,
    width: '180px',
    background: '0% 0% no-repeat padding-box padding-box rgb(231, 238, 237)',
    boxShadow: 'rgb(0 0 0 / 44%) 0px 3px 8px',
    color: 'black',
    position: 'absolute',
    borderRadius: '2px',
  },
  dropdownOption: {
    height: '25px',
    lineHeight: '26px',
    paddingLeft: '13px',
    color: theme.palette.primary.main,
    '&:hover': {
      background: '#DBDBDB',
    },
  },
  dropdownActiveOption: {
    background: '#DBDBDB',
  },
  arrow: {
    position: 'relative',
    top: '4px',
  },
  dropdownLabelText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const AppointmentStatusDropdown = ({ status, handleStatus }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleOption = (option: string) => {
    handleStatus(option);
    setOpen(false);
  };

  return (
    <div className={classes.dropdownSelector} data-cy="appointmentStatusFilter">
      Filter by Appointment Status:
      <div onClick={() => setOpen(!open)} style={{ display: 'flex', margin: 'auto' }}>
        <div className={classes.dropdownLabelText}>{DROPDOWN_APPOINTMENT_STATUSES[status]}</div>
        {open ? <ArrowDropUpIcon className={classes.arrow} /> : <ArrowDropDownIcon className={classes.arrow} />}
      </div>
      <div className={classes.dropdownOptions} style={{ display: open ? 'initial' : 'none' }}>
        <div className={classes.dropdownOption} onClick={() => handleOption('all')}>
          {DROPDOWN_APPOINTMENT_STATUSES.all}
        </div>
        <div className={classes.dropdownOption} onClick={() => handleOption('show_all')}>
          {DROPDOWN_APPOINTMENT_STATUSES.show_all}
        </div>
        <div className={classes.dropdownOption} onClick={() => handleOption('cancelled')}>
          {DROPDOWN_APPOINTMENT_STATUSES.cancelled}
        </div>
        <div className={classes.dropdownOption} onClick={() => handleOption('no_show_appointment')}>
          {DROPDOWN_APPOINTMENT_STATUSES.no_show_appointment}
        </div>
      </div>
    </div>
  );
};
export default AppointmentStatusDropdown;
