import React, { useState, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { debounce } from 'lodash';
import InputSearch from '../../components/common/InputSearch';
import TablePaginator from '../../components/common/Table/TablePaginator';
import { useServiceVisitAssets, useServiceVisitAssetsCount } from '../../hooks/queries/assets/useServiceVisitAssets';
import { SERVICE_VISIT_ASSETS_COLUMNS } from '../../constants/patients/columns.constants';
// eslint-disable-next-line max-len
import AdminServiceVisitAssetBreadcrumb from '../../components/DashboardAdministrator/ServiceVisitAssetView/AdminServiceVisitAssetBreadcrumb';
import { useStyles } from '../../components/DashboardAdministrator/ServiceVisitAssetView/ServiceVisitAsset.styles';
import { IServiceVisitAssetViewParams } from '../../interfaces/IServiceVisitAsset.interface';

// TODO: This file seems is not used any more

const INITIAL_ORDER_BY = 'id';
const INITIAL_DIRECTION = 'DESC';

const initialParams = {
  page: 1,
  sort: INITIAL_ORDER_BY,
  sortDirection: INITIAL_DIRECTION,
};

const ServiceVisitAssetsPage = () => {
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState('');
  const [queryParams, setQueryParams] = useState<IServiceVisitAssetViewParams>(initialParams);
  const { data: count = 0 } = useServiceVisitAssetsCount(queryParams);
  const {
    isLoading,
    isFetching,
    data: { assets },
    fetchNextPage,
    isError,
  } = useServiceVisitAssets(queryParams, count);

  const onChangeSortBy = (column: string, direction: string) => {
    setQueryParams({ ...queryParams, sort: column, sortDirection: direction });
  };

  const debounceCall = (query: string, params: any) => {
    setQueryParams({
      ...params,
      search: query,
    });
  };

  const doSearch = useMemo(
    () => debounce((query: string, params: IServiceVisitAssetViewParams) => debounceCall(query, params), 1000),
    []
  );

  const onInputChange = (event: any) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    doSearch(newSearchQuery, queryParams);
  };

  return (
    <Box width="100%">
      <AdminServiceVisitAssetBreadcrumb />
      <Box padding={2}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>Service visit assets</Typography>
          </Box>
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Box data-cy="internalSerialNumber">
            <InputSearch
              placeholder="Search..."
              onChangeValue={onInputChange}
              value={searchQuery}
              isLoading={isFetching}
            />
          </Box>
        </Box>
        <Box>
          <TablePaginator
            isLoading={isLoading}
            isFetching={isFetching}
            isError={isError}
            data={assets}
            columns={SERVICE_VISIT_ASSETS_COLUMNS}
            onChangeSortBy={onChangeSortBy}
            total={count}
            fetchNextPage={fetchNextPage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceVisitAssetsPage;
