import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IPractitionerCertificate, PatientPointCertificatesState } from '../../../types/PatientPointCertificatesState';
import { CertificatesList, CertificatesListItem } from './CertificatesList';
import { useStyles } from './modalPoints.styles';
import { ShortMultipleSkeleton } from '../../../components/common/LoadingSkeleton';
import { filterByUniqueKey } from '../../../utils/global';

interface PractitionerCertificatesListProps {
  practitionerCertificatesSelected: number[];
  // eslint-disable-next-line no-unused-vars
  onChange: (value: number) => void;
}

const PractitionerCertificatesList = (props: PractitionerCertificatesListProps) => {
  const { practitionerCertificatesSelected, onChange } = props;
  const { practitionerCertificates, isLoadingToggleCertificate, isLoadingPractitionerCertificates } = useSelector(
    ({ patientPointCertificates }: any) => patientPointCertificates as PatientPointCertificatesState
  );
  const classes = useStyles();
  const hcpCertificates = filterByUniqueKey(practitionerCertificates, 'categoryCode');

  return (
    <div>
      {!hcpCertificates.length && !isLoadingPractitionerCertificates && <div>You have no certificates.</div>}
      {isLoadingPractitionerCertificates && <ShortMultipleSkeleton length={3} />}

      <CertificatesList>
        {!isLoadingPractitionerCertificates &&
          hcpCertificates.map((item: IPractitionerCertificate) => (
            <CertificatesListItem
              onChange={onChange}
              key={item.id}
              value={item.id}
              disabled={isLoadingToggleCertificate}
              checked={practitionerCertificatesSelected.includes(item.id)}
            >
              <Box>
                <Box my={0} component="p" className={classes.listItemTitle}>
                  {item.description}
                </Box>
                <Box component="small" className={classes.listItemSubtitle}>
                  {item.categoryCode} {item.amount} (
                  {practitionerCertificates.filter((cert) => cert.categoryCode === item.categoryCode).length}
                  {' remaining)'}
                </Box>
              </Box>
            </CertificatesListItem>
          ))}
      </CertificatesList>
    </div>
  );
};

export default PractitionerCertificatesList;
