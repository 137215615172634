import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import BreadcrumbsContainer from 'src/components/common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from 'src/components/common/Breadcrumb/BreadcrumbText';
import { ADMIN_BASE_PATH } from 'src/routes/administratorRoutes';
import { ClassNameMap } from 'src/types/Dom';

export const Breadcrumb: React.FC<ClassNameMap & { prependHeading?: string }> = ({ classes, prependHeading }) => {
  const paths = window.location.pathname.split('/');
  const basePath = ADMIN_BASE_PATH.split('/')[0];
  const baseIndex = paths.indexOf(basePath);

  const displayPaths = baseIndex > -1 ? paths.slice(baseIndex + 1) : paths;
  const activePath = displayPaths.slice(-1)[0];

  const linkTo = (path: string): string => `${paths.slice(0, paths.indexOf(path) + 1).join('/')}`;

  return (
    <Box className={classes?.breadcrumbSection}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BreadcrumbsContainer>
          {prependHeading && <BreadcrumbText text={prependHeading} />}
          {displayPaths.map((path: string) => (
            <BreadcrumbText text={path} isActive={path === activePath} linkTo={linkTo(path)} />
          ))}
        </BreadcrumbsContainer>
      </Box>
    </Box>
  );
};

export default withStyles({
  breadcrumbSection: {
    marginBottom: 17,
    paddingRight: 5,
    backgroundColor: '#f2f5f5',
  },
})(Breadcrumb);
