import {
  Box,
  Button,
  Container,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { IPaymentCard } from 'src/interfaces/IMedspaAdminList';
import useGetCards from 'src/hooks/queries/medspaAdmins/useMedspaPaymentMethods';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { Skeleton } from '@material-ui/lab';
import CreditCardIcon from 'src/components/PatientProfile/CreditCards/CreditCardIcon';
import { useUpdatePaymentSource } from 'src/hooks/queries/useInventoryOrders';
import { capitalize } from 'lodash';
import AddCreditCard from './AddCreditCard';
import { OrderContext } from '..';

const SelectCreditCard = ({ classes }: { classes?: { [key: string]: string } }): React.ReactElement => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    user: { userGroupId },
  } = useSelector(({ auth }: RootState) => auth);

  const { orderData, blockProgress, isFetching = false } = useContext(OrderContext) || {};

  const { data: creditCards, isLoading: isLoadingCards, refetch } = useGetCards(userGroupId ?? 0);
  const { mutateAsync: updatePaymentMethod } = useUpdatePaymentSource(orderData?.id ?? 0);

  const isUpdating = isLoading || isFetching;

  const handleUpdatePaymentMethod = async (value: string): Promise<void> => {
    setIsLoading(true);
    await updatePaymentMethod(value);
    setIsLoading(false);
  };

  useEffect(() => {
    blockProgress?.(isUpdating);
  }, [blockProgress, isUpdating]);

  return (
    <Container data-testid="orderCards" data-loading={isLoadingCards || isLoading} classes={classes}>
      {isLoadingCards ? (
        <>
          <Skeleton variant="rect" width="100%" height={55} />
          <Skeleton variant="rect" width="100%" height={55} />
          <Skeleton variant="rect" width="100%" height={55} />
        </>
      ) : (
        <>
          <RadioGroup
            className={classes?.radioGroup}
            value={orderData?.paymentSourceId}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUpdatePaymentMethod(event.target.value)}
          >
            {creditCards?.map(
              ({ cardBrand, last4, id }: IPaymentCard): React.ReactElement => (
                <FormControlLabel
                  key={last4}
                  className={classes?.radioItem}
                  value={id}
                  disabled={isUpdating}
                  control={<Radio color="primary" />}
                  label={
                    <Box
                      className={classes?.cardRow}
                      data-testid="orderCards"
                      width="100%"
                      display="flex"
                      flexDirection="row"
                    >
                      <Typography className={classes?.cardName}>
                        {capitalize(cardBrand?.split('_').join(' '))} card <span>ending in {last4}</span>
                      </Typography>
                      <CreditCardIcon cardBrand={cardBrand} noFormat />
                    </Box>
                  }
                />
              )
            )}
          </RadioGroup>

          <Button
            variant="outlined"
            data-testid="orderPayment.addNewCard"
            onClick={() => setDialogOpen(true)}
            disabled={isUpdating}
            className={classes?.addCardButton}
          >
            Add New
          </Button>
        </>
      )}
      <Dialog open={dialogOpen}>
        <AddCreditCard onSave={refetch} closeDialog={() => setDialogOpen(false)} />
      </Dialog>
    </Container>
  );
};

export default withStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
    minHeight: 90,
    '& svg': {
      width: 45,
      marginLeft: 8,
    },
    '&[data-loading="true"]': {
      opacity: 0.3,
    },
  },
  radioGroup: {
    paddingLeft: 3,
  },
  radioItem: {
    '& > span:first-child': {
      width: 30,
      marginRight: 5,
    },
  },
  cardName: {
    fontWeight: 600,
    textTransform: 'capitalize',
    '& > span': {
      fontWeight: 100,
      textTransform: 'lowercase',
    },
  },
  cardRow: {
    '& > p': {
      margin: 'auto',
    },
  },
  addCardButton: {
    width: '30%',
    margin: 'auto auto 0 0',
  },
})(SelectCreditCard);
