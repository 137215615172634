import React from 'react';
import { useSelector } from 'react-redux';
import ProductIdentifiersComponent from '../components/common/ProductIdentifiers/ProductIdentifiers';

const ProductIdentifiers = () => {
  const {
    productName,
    vials,
    units,
    identifiers,
    hasScannedProducts,
    aggregatedField
  } = useSelector((state: any) => state.productIdentifiers);

  const service = {
    name: productName,
    vials,
    units,
    assets: identifiers
  };

  return (
    <ProductIdentifiersComponent
      service={service}
      editionDisabled
      aggregatedField={aggregatedField}
      hasScannedProducts={hasScannedProducts}
    />
  );
};

export default ProductIdentifiers;
