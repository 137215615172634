import React from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import HeaderNav from './HeaderNav';
import { dispatch } from '../../rematch';
import Sidebar from '../Sidebar';
import IWrapper from '../../interfaces/IWrapper';
import SidebarStyles from '../Sidebar/Sidebar.styles';
import PhysicianSidebar from '../Sidebar/PhysicianSidebar';
import { Practitioners } from '../../services/Practitioners';

const mapState = (state: any) => ({
  isLoading: state.loading.effects.auth.login,
  isAuthenticate: state.auth.isAuthenticate,
  isSuccess: state.auth.isSuccess,
  messageError: state.auth.messageError,
  userType: state.auth.userType,
  name: state.auth.name,
  roleName: state.auth.roleName,
});

const Wrapper = (props: IWrapper) => {
  const { name, userType, roleName, children, scrollMainRef } = props;
  const isMedspaProvider = Practitioners.isMedspaProvider();

  const classes = SidebarStyles();

  const logout = () => {
    dispatch.auth.logout();
  };

  return (
    <div className={classes.root}>
      {userType === 'Administrator' && <Sidebar />}
      {isMedspaProvider && <Sidebar />}
      {userType === 'Physician' && <PhysicianSidebar />}
      <main ref={scrollMainRef} className={classes.content}>
        <Grid container style={{ height: '100%', flexDirection: 'column', flexWrap: 'unset' }}>
          <HeaderNav
            userType={userType}
            roleName={roleName}
            username={name}
            logoutFn={logout}
            useStyles={SidebarStyles}
            avatarUrl="/static/images/avatar/1.jpg"
          />
          {children}
        </Grid>
      </main>
    </div>
  );
};

export default connect(mapState, {})(Wrapper);
