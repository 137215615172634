import { withStyles, createStyles, Select } from '@material-ui/core';

export const SelectCustom = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: 0,
      color: 'rgba(0, 0, 0, .54)',
      fontSize: '15px',
      fontFamily: 'Messina Sans Regular',
      width: '150px',
    },
    select: {
      color: '#000',
    },
  })
)(Select);
