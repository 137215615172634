import React, { useEffect, useState } from 'react';

// Material-UI Imports
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// Validation and Form Resolvers
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

// React Hook Form Imports
import { Controller, useForm } from 'react-hook-form';

// React Query Imports
import { useMutation, useQueryClient } from 'react-query';

// Services and Constants
import { useSelector } from 'react-redux';
import moment from 'moment';
import { hasAccessTo } from 'src/utils/auth.utils';
import { PRACTITIONER_BOOK_APPOINTMENT_UPDATE } from 'src/constants/actions.constants';
import { RootState } from 'src/rematch';
import { useCustomerSelector } from 'src/hooks/queries/customers/customers';
import { EHRAutocompleteSelect, IAutocompleteSelectOption } from 'src/components/ui/v1';
import { Appointment } from '../../../services/Appointment';
import { APPOINTMENT_BOOKING_SLOTS, APPOINTMENT_TYPES, CALENDAR_IDS } from '../../../constants/reactQuery.keys';
import { SNACKBAR_ERROR, USER_TYPES } from '../../../constants/general.constants';
import { showSnackbar } from '../../../utils/global';
import compile from '../../../utils/toastMessagesCompiler';

// Custom Hooks and Redux Imports
import {
  useBookingSlot,
  useLocations,
  useProviderAppointmentTypes,
  useRescheduleMutation,
} from '../../../hooks/practitioner/useBooking';
import formatDate from '../../../utils/formatDate';

// Date and Time Libraries

export const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  dialog: {
    padding: '34px 0 34px 0 !important',
  },
  autocompleteForm: {
    width: '100% !important',
  },
}));

interface AppointmentBookProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickConfirm: () => void;
  editMode?: boolean;
  shouldRefetch: () => void;
}

export const schemaForm = yup.object().shape({
  email: yup.string().required().email(),
  appointmentTypeId: yup.string().required('Appointment type is a required field'),
  datetime: yup.string().required('Time is a required field'),
  notes: yup.string().required('Notes is a required field'),
  calendarId: yup.string().required(),
});

const defaultValues = {
  email: '',
  appointmentTypeId: '',
  datetime: '',
  notes: '',
  calendarId: '',
};

const dateFormat = 'YYYY-MM-DD';

const AppointmentBook = ({ open, setOpen, onClickConfirm, shouldRefetch, editMode = false }: AppointmentBookProps) => {
  const classes = useStyles();
  const { userType } = useSelector(({ auth }: any) => auth);
  const { permissions } = useSelector(({ auth }: RootState) => auth);
  const { currentAppointment } = useSelector(({ schedulers }: any) => schedulers);
  const {
    startDate: appointmentDateTime,
    email: appointmentEmail,
    appointmentTypeId: currentAppointmentTypeId,
    calendarId: currentCalendarId,
    notes: currentAppointmentNotes,
  } = currentAppointment;

  const [searchInput, setSearchInput] = useState('');
  const { control, errors, setValue, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(schemaForm),
    defaultValues,
  });
  // const watchEmail = watch('email');
  const appointmentTypeId = watch('appointmentTypeId');

  const { userId } = useSelector(({ auth }: any) => auth);
  const [selectedDate, setSelectedDate] = useState(moment().format(dateFormat));

  const [practitionerId, setPractitionerId] = useState<null | number>(null);
  const [calendarId, setCalendarId] = useState(currentCalendarId);

  const queryClient = useQueryClient();
  const bookAppointmentMutation = useMutation(Appointment.create);
  const rescheduleAppointmentMutation = useRescheduleMutation();

  const isMedspaAdmin =
    userType === USER_TYPES.ADMINISTRATOR && hasAccessTo(PRACTITIONER_BOOK_APPOINTMENT_UPDATE, permissions);

  const { emailOptions, isFetching: isFetchingCustomers } = useCustomerSelector({
    search: searchInput,
    practitionerId: practitionerId || '',
  });

  const { data: appointmentTypes, isFetching: isFetchingAppointmentTypes } = useProviderAppointmentTypes(
    isMedspaAdmin ? practitionerId : (userId as string),
    calendarId
  );
  const { data: calendars, isFetching: isFetchingCalendars } = useLocations();

  const { data: dataSlots, isFetching: isFetchingSlots } = useBookingSlot({
    date: selectedDate,
    practitionerId: isMedspaAdmin ? practitionerId : userId,
    days: 1,
    appointmentTypeId,
    calendarId,
  });

  const [selectedEmailOption, setSelectedEmailOption] = useState<IAutocompleteSelectOption | undefined>(undefined);

  useEffect(() => {
    queryClient.invalidateQueries([CALENDAR_IDS]);
  }, [userId]);

  useEffect(() => {
    queryClient.invalidateQueries([APPOINTMENT_TYPES]);
  }, [calendarId, userId]);

  useEffect(() => {
    if (appointmentTypeId) {
      queryClient.invalidateQueries([APPOINTMENT_BOOKING_SLOTS]);
    }
  }, [appointmentTypeId, selectedDate, calendarId]);

  const handleDialogCloseCancel = () => {
    setOpen(false);
  };

  const [isLoadingScheduling, SetIsLoadingScheduling] = useState(false);

  const onSubmit = async (data: any) => {
    try {
      SetIsLoadingScheduling(true);
      if (editMode) {
        await rescheduleAppointmentMutation.mutateAsync({
          appointmentId: currentAppointment?.id,
          ...data,
          practitionerId,
        });
      } else {
        if (!emailOptions.map(({ value }) => value).includes(data.email)) {
          SetIsLoadingScheduling(false);
          showSnackbar(
            compile('generic.invalid_field', {
              element: 'Email',
            })
          );
          return;
        }

        await bookAppointmentMutation.mutateAsync({ ...data, practitionerId });
      }

      onClickConfirm();
      setOpen(false);
      reset(defaultValues);
      shouldRefetch();
      SetIsLoadingScheduling(false);
    } catch (e) {
      SetIsLoadingScheduling(false);
      setOpen(false);
      reset(defaultValues);
      showSnackbar(compile('appointment_booking.error'), SNACKBAR_ERROR);
    }
  };

  const handleAppointmentTypeChange = (event: any) => {
    const selectedValue = event.target.value;
    setValue('appointmentTypeId', selectedValue);
  };

  const handleCalendarIdChange = (event: any) => {
    const selectedValue = event.target.value;
    setValue('calendarId', selectedValue);
    setCalendarId(selectedValue);
    const selectedPractitionerId =
      calendars.find((location: { calendarId: number }) => location.calendarId === +selectedValue)?.practitionerId ||
      null;

    setPractitionerId(selectedPractitionerId);
    setValue('appointmentTypeId', '');
    setValue('email', '');
    setSearchInput('');
  };

  const CustomFromDateTextField = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...props} name="fromDate" style={{ marginBottom: '10px' }} fullWidth />
  );

  const onChangeFromDate = (date: any) => {
    const formattedDate = date.format(dateFormat);
    setSelectedDate(formattedDate);
  };

  const getTime = (slot: string) => {
    const date = new Date(slot);
    const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return timeString;
  };

  useEffect(() => {
    if (editMode) {
      setValue('appointmentTypeId', currentAppointmentTypeId);
      setPractitionerId(currentAppointment.practitionerId);
      setValue('calendarId', currentCalendarId);
      setValue('email', appointmentEmail);
      setValue('datetime', appointmentDateTime);
      setValue('notes', currentAppointmentNotes);

      setCalendarId(currentCalendarId);
      setSelectedDate(formatDate(appointmentDateTime, dateFormat));

      queryClient.invalidateQueries([APPOINTMENT_BOOKING_SLOTS]);
    }
    return () => {
      reset(defaultValues);
      setSearchInput('');
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogCloseCancel}
      data-cy="confirmationModal"
      classes={{ paper: classes.dialog }}
    >
      <DialogContent>
        <DialogContentText id="dialog-content-text">
          <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingX={2}>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography style={{ marginBottom: '10px', color: 'black' }} variant="body1">
                  Provider calendar{' '}
                  {isFetchingCalendars && <CircularProgress size={20} style={{ position: 'relative', top: '4px' }} />}
                </Typography>
                <FormControl data-cy="calendarIds" size="medium" variant="outlined" className={classes.formControl}>
                  <Controller
                    control={control}
                    name="calendarId"
                    error={!!errors.calendarId}
                    render={() => (
                      <Select
                        id="calendarId-select"
                        onChange={handleCalendarIdChange}
                        defaultValue={currentCalendarId}
                        disabled={editMode}
                      >
                        {calendars?.map((calendar: any) => (
                          <MenuItem data-cy="member" key={calendar.id} value={calendar.calendarId}>
                            {userType === USER_TYPES.ADMINISTRATOR && <>[{calendar.practitionerName}]</>}{' '}
                            {calendar.name || calendar.practitionerName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.calendarId && <FormHelperText error>{errors.calendarId?.message}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{ marginBottom: '10px', color: 'black' }} variant="body1">
                  Appointment type
                </Typography>
                <FormControl
                  data-cy="appointmentTypeId"
                  size="medium"
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Controller
                    control={control}
                    name="appointmentTypeId"
                    error={!!errors.appointmentTypeId}
                    render={() => (
                      <Select
                        disabled={editMode || isFetchingAppointmentTypes}
                        id="appointmentType-select"
                        onChange={handleAppointmentTypeChange}
                        value={appointmentTypeId}
                      >
                        {appointmentTypes
                          .filter(({ hidden }) => !hidden)
                          .map((appointmentType) => (
                            <MenuItem
                              data-cy="member"
                              key={appointmentType.id}
                              value={appointmentType.appointmentTypeId}
                            >
                              {appointmentType.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                  {errors.appointmentTypeId && (
                    <FormHelperText error>{errors.appointmentTypeId.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{ marginBottom: '10px', color: 'black' }} variant="body1">
                  Date & Time
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        variant="dialog"
                        inputVariant="outlined"
                        label="Date"
                        format={dateFormat}
                        autoOk
                        disableToolbar
                        value={selectedDate}
                        onChange={onChangeFromDate}
                        TextFieldComponent={CustomFromDateTextField}
                      />
                    </MuiPickersUtilsProvider>
                    <Typography variant="body2">
                      {isFetchingSlots ? 'Fetching Slots' : !dataSlots?.length && 'This date has no available slots.'}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      data-cy="select-start-time"
                      size="medium"
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="datetime">Time</InputLabel>
                      <Controller
                        control={control}
                        name="datetime"
                        id="datetime"
                        error={!!errors.datetime}
                        render={({ onChange }: any) => (
                          <Select
                            id="select-start-time"
                            labelWidth={40}
                            onChange={(event: any) => onChange(event.target.value)}
                            disabled={!dataSlots?.length || isFetchingSlots}
                          >
                            {dataSlots?.map((slot: string) => (
                              <MenuItem data-cy="start-time" key={slot} value={slot}>
                                {getTime(slot)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {errors.datetime && <FormHelperText error>{errors.datetime.message}</FormHelperText>}
                    {editMode && (
                      <Typography variant="body2">Scheduled time: {getTime(appointmentDateTime)}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {!editMode ? (
                      <Controller
                        control={control}
                        name="email"
                        id="email-id"
                        error={errors?.email?.message}
                        render={() => (
                          <EHRAutocompleteSelect
                            placeholder="Type to search..."
                            dataCy="email-search"
                            label="Search patient"
                            disabled={isLoadingScheduling || isFetchingCustomers}
                            isFetching={isFetchingCustomers}
                            error={!!errors.email}
                            helperText={
                              errors.email ? 'The email should match an email belonging to an existing patient' : ''
                            }
                            options={emailOptions.map((e) => ({
                              name: e.name,
                              value: e.value,
                            }))}
                            onChangeDebounceSearch={(search: string) => setSearchInput(search)}
                            onChange={(option: IAutocompleteSelectOption | null) => {
                              if (!option || !option.value) {
                                setSelectedEmailOption(undefined);
                                setValue('email', undefined);
                                return;
                              }
                              const emailOptionIndex = emailOptions.findIndex((op) => op.value === option.value);
                              if (emailOptionIndex < 0) {
                                return;
                              }
                              const emailOption = emailOptions[emailOptionIndex];
                              setSelectedEmailOption(emailOption);
                              setValue('email', String(option?.value));
                            }}
                            selectedOption={selectedEmailOption}
                          />
                        )}
                      />
                    ) : (
                      <Typography variant="body2">{appointmentEmail}</Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputLabel style={{ color: 'black', marginTop: '20px' }} htmlFor="notes">
                      Notes
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      data-cy="select-start-time"
                      size="medium"
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Controller
                        id="notes"
                        control={control}
                        name="notes"
                        error={!!errors.notes?.message}
                        render={({ onChange, value }: any) => (
                          <TextareaAutosize
                            onChange={(event) => onChange(event.target.value)}
                            name="Notes"
                            value={value || ''}
                            style={{ width: '100%', height: '200px', resize: 'none', margin: '20px 0' }}
                          />
                        )}
                      />
                      {errors.notes && <FormHelperText error>{errors.notes.message}</FormHelperText>}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <Button disabled={isLoadingScheduling} data-cy="closeModal" onClick={handleDialogCloseCancel}>
                Cancel
              </Button>
              <Button
                data-cy="submitBtn"
                variant="contained"
                disableElevation
                color="primary"
                size="large"
                type="submit"
                disabled={isLoadingScheduling}
              >
                {isLoadingScheduling ? (
                  <>
                    <CircularProgress style={{ color: 'black', marginRight: '5px' }} size={20} />
                    <Typography variant="body2">Processing</Typography>
                  </>
                ) : (
                  <>{editMode ? `Reschedule appointment` : `Schedule appointment`}</>
                )}
              </Button>
            </DialogActions>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentBook;
