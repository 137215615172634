import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { dispatch } from 'src/rematch';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    width: '100%',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
}));
interface PageProps {
  title?: string;
  titleComponent?: React.ReactElement;
  children?: React.ReactNode;
}

const Page = ({ title, titleComponent, children }: PageProps) => {
  const classes = useStyles();

  useEffect(() => {
    if (titleComponent) {
      dispatch.page.setTitleComponent(titleComponent);
    } else if (title) {
      dispatch.page.setTitle(title);
    }

    return () => {
      dispatch.page.setTitle(null);
    };
  }, []);

  return (
    <>
      <Box className={classes.pageContent}>{children}</Box>
    </>
  );
};

export default Page;
