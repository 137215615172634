import { createModel } from '@rematch/core';
import { orderBy } from 'lodash';
import axiosInstance from '../utils/axios';
import { ReconciliationReportState } from '../types/ReconciliationReportState';
import { TypeSort } from '../utils/types';
import { RootModel } from './rootModel';
import { addProductListToReconciliationReport } from '../utils/inventory.utils';
import { IReconciliationReport } from '../interfaces/reconciliation.interfaces';
import compile from '../utils/toastMessagesCompiler';

export const reconciliationReport = createModel<RootModel>()({
  state: {
    orderByDate: 'asc',
    reconciliationReports: [],
    reconciliationReport: { reconciliationReportItems: [] },
    productsSelected: [],
    isLoading: false,
  } as ReconciliationReportState,
  reducers: {
    setReconciliationReports(state: any, payload: IReconciliationReport[]) {
      const reconciliationReports = addProductListToReconciliationReport(payload);
      return { ...state, reconciliationReports };
    },
    setReconciliationReport(state: any, payload: IReconciliationReport[]) {
      return { ...state, reconciliationReport: payload };
    },
    sortByDate(state: ReconciliationReportState, payload: TypeSort) {
      const reconciliationReports = orderBy(
        state.reconciliationReports,
        [(object: any) => new Date(object.date)],
        [payload]
      );
      return { ...state, reconciliationReports };
    },
    setSort(state: ReconciliationReportState, payload: TypeSort) {
      return { ...state, orderByDate: payload };
    },
    setFilterReconciliationReports(state: ReconciliationReportState, payload: { value: string }) {
      const { value } = payload;
      if (value === '') {
        return { ...state };
      }

      const reconciliationReports = state.reconciliationReports.filter((item: any) =>
        item.practitionerName.trim().toLowerCase().match(value.trim().toLowerCase())
      );

      return { ...state, reconciliationReports };
    },
    setLoading(state: any, payload: boolean) {
      return { ...state, isLoading: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getReconciliationReports() {
      try {
        dispatch.reconciliationReport.setLoading();
        const response = await axiosInstance.get('/reconciliation-reports-for-administrator');
        if (response.status !== 200) {
          throw new Error(response.data.message);
        }
        if (response.data.error) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: response.data.error,
              type: 'error',
            },
          });
          return;
        }
        dispatch.reconciliationReport.setReconciliationReports(response.data.reconciliationReports);
      } finally {
        dispatch.reconciliationReport.setLoading(false);
      }
    },
    async filterReconciliationReports(payload: { value: string }) {
      dispatch.reconciliationReport.setFilterReconciliationReports(payload);
    },
    async getReconciliationReport(id: number) {
      try {
        /* eslint-disable @typescript-eslint/no-shadow */
        const {
          data: { reconciliationReport },
        } = await axiosInstance.get(`/reconciliation-reports/${id}`);

        dispatch.reconciliationReport.setReconciliationReport(reconciliationReport);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.element_not_found', {
              element: 'Reconciliaton Report',
            }),
            type: 'error',
          },
        });
      }
    },
    async removeReconciliationAssets({ assetsIds, reconciliationReportId }: any) {
      try {
        await axiosInstance.post(`/reconciliation-reports/${reconciliationReportId}`, { assetsIds });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.element_not_found', {
              element: 'Reconciliaton Report',
            }),
            type: 'error',
          },
        });
      }
    },
  }),
});
