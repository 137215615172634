export const getServiceUnits = (unit: String) => {
  switch (unit) {
    case 'region': {
      return 'Region';
    }
    case 'mL': {
      return 'mL';
    }
    default: {
      return 'Units';
    }
  }
};
