import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  consentTitle: {
    paddingTop: '29px',
    paddingRight: '60px',
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Messina Sans Regular',
  },
  consentContent: {
    fontSize: '11px',
    display: 'block',
    height: 'auto',
    overflowY: 'hidden',
    backgroundColor: 'white',
    padding: '16px',
    textAlign: 'left',
    margin: '20px',
    '& h2': {
      fontSize: '16px',
    },
    '& h3': {
      fontSize: '14px',
    },
  },
  closeIcon: {
    float: 'left',
    marginTop: '25px',
    marginLeft: '22px',
    width: '31px',
    height: '31px',
    cursor: 'pointer',
  },
  photoConsentAgreementModal: {
    zIndex: 3001,
  },
}));
