import React, { useState, useEffect } from 'react';
import { Box, Dialog, TableCell, TableRow } from '@material-ui/core';
import { Comment } from '@material-ui/icons';
import { formatCurrency } from 'src/utils/formatNumber';
import TrashCan from 'src/components/common/TrashCan';
import { useStyle } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { InventoryOrderItem } from 'src/interfaces/IInventoryOrder';
import {
  useDestroyInventoryOrderCustomItem,
  useRemoveInventoryOrderItem,
  useUpdateInventoryOrderCustomItem,
  useUpdateInventoryOrderItem,
} from '../../../../../hooks/queries/useInventoryOrders';
import { ProviderNotesDialog } from '../OrderApprovalRowAux';
import { EditableOrderingValue } from './EditableOrderingValue';
import OrderApprovalSpecialsRow from './OrderApprovalSpecialsRow';

type Props = Omit<InventoryOrderItem, 'supplierName' | 'minimumOrderQuantity' | 'marketPrice'> & {
  approved: boolean;
  rejected: boolean;
};

export const OrderApprovalProductRow = ({
  id,
  inventoryOrderId,
  orderProductId,
  specialOfferId,
  name,
  quantity,
  notes,
  subtotal,
  approved,
  rejected,
  price,
}: Props) => {
  const classes = useStyle();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<number | undefined>();
  const [customPrice, setCustomPrice] = useState<number | undefined>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [removing, setRemoving] = useState<boolean>(false);

  const { mutateAsync: updateProduct } = useUpdateInventoryOrderItem({ orderProductId, inventoryOrderId });
  const { mutateAsync: removeProduct } = useRemoveInventoryOrderItem(orderProductId ? id : 0);

  const { mutateAsync: updateItem } = useUpdateInventoryOrderCustomItem(!orderProductId ? id : 0);
  const { mutateAsync: removeItem } = useDestroyInventoryOrderCustomItem(!orderProductId ? id : 0);

  const isLoading = removing || (!editMode && !!userInput && !!quantity && quantity !== userInput);
  const pending = !approved && !rejected;

  const handleRemove = async () => {
    setRemoving(true);
    try {
      if (orderProductId) {
        await removeProduct();
      } else {
        await removeItem();
      }
    } finally {
      setRemoving(false);
    }
  };

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(+event.currentTarget.value);
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomPrice(+event.currentTarget.value);
  };

  const handleSave = async () => {
    if (!!userInput || userInput === 0) {
      setEditMode(false);
      if (orderProductId) {
        await updateProduct({ quantity: userInput });
      } else {
        await updateItem({ price: customPrice, quantity: userInput });
      }
    }
  };

  useEffect(() => {
    setUserInput(quantity);
  }, [quantity]);

  useEffect(() => {
    setCustomPrice(price);
  }, [price]);

  if (specialOfferId) {
    return (
      <OrderApprovalSpecialsRow
        disabled={!pending || !editMode}
        isLoading={isLoading}
        testId={`order item ${inventoryOrderId} ${specialOfferId}`}
        onChange={handleChangeQuantity}
        name={name}
        quantity={userInput ?? 0}
        subtotal={subtotal}
      />
    );
  }
  return (
    <TableRow
      className={classes.pulseOnly}
      data-loading={isLoading}
      data-testid={`order item ${inventoryOrderId} ${orderProductId} row`}
    >
      <TableCell
        className={classes.approvalRowName}
        data-testid={`order item ${inventoryOrderId} ${orderProductId} name`}
      >
        {name}
      </TableCell>
      <TableCell>{formatCurrency(subtotal)}</TableCell>
      <TableCell>
        <EditableOrderingValue
          testId={`order custom product ${inventoryOrderId} ${orderProductId}`}
          value={userInput}
          onChange={handleChangeQuantity}
          onSave={handleSave}
          pending={pending}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        {!editMode && !!notes && (
          <Comment
            className={classes.approveEdit}
            viewBox="0 0 22 22"
            onClick={() => setDialogOpen(!dialogOpen)}
            style={{ marginLeft: '5px' }}
            data-testid={`order item ${orderProductId} note button`}
          />
        )}
        <Dialog open={dialogOpen} maxWidth="lg">
          <ProviderNotesDialog dismiss={() => setDialogOpen(!dialogOpen)} text={notes} />
        </Dialog>
      </TableCell>
      <TableCell className={classes.smallText}>
        {orderProductId ? null : (
          <>
            <span style={{ display: 'inline-block', margin: '7px 0' }}>$</span>
            <EditableOrderingValue
              testId={`order custom item ${inventoryOrderId} ${id}`}
              value={formatCurrency(customPrice || 0).slice(1)}
              onChange={handleChangePrice}
              onSave={handleSave}
              type="number"
              pending={pending}
              editMode={editMode}
              setEditMode={setEditMode}
              step={0.01}
            />
          </>
        )}
      </TableCell>
      <TableCell className={classes.removeItem}>
        {pending && (
          <Box style={{ cursor: 'pointer' }} data-testid={`order item ${orderProductId} remove`} onClick={handleRemove}>
            <TrashCan />
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};
