import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  subtitle: {
    margin: '0.8rem 0 2rem',
  },
  mt2: {
    marginTop: '2rem',
  },
}));
