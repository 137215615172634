import React, { useMemo, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Row,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtual } from 'react-virtual';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { debounce } from 'lodash';
import { useStyles } from './patientDataTable.styles';
import { FileType } from './BulkUpload';

export interface IPatientData {
  firstName?: string;
  lastName?: string;
  email: string;
  phone?: string;
  physician?: string;
  practitioner?: string;
  address?: string;
  birthdate?: string;
  biologicalSex?: string;
  knownAllergies?: string;
  medicationAllergies?: string;
  currentOralMedication?: string;
  historicalOralMedication?: string;
  currentTopicalMedication?: string;
  historicalTopicalMedication?: string;
  medicalCondition?: string;
  previousProcedures?: string;
  previousConditions?: string;
  extraDetails?: string;
  patientPhoto?: string;
  skinConcerns?: string;
  serviceImprovements?: string;
  skinType?: string;
  sunscreenFrequency?: string;
  retinalRetinA?: string;
  usingPeels?: string;
  goalsNotes?: string;
  interestedInWeightLoss?: string;
  weightInLbs?: number;
  heightInCms?: number;
}
declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: TData | unknown) => void;
  }
}

const defaultColumn: Partial<ColumnDef<IPatientData>> = {
  cell: ({ getValue, row: { index }, column: { id }, table }) => {
    const initialValue = getValue();

    const [value, setValue] = React.useState<string | unknown>(initialValue);
    const [previousValue, setPreviousValue] = useState<string | unknown>(initialValue);

    const updateValue = useMemo(
      () =>
        debounce((newSearch: string) => {
          table.options.meta?.updateData(index, id, newSearch);
        }, 500),
      []
    );
    const onBlur = () => {
      if (value !== previousValue) {
        table.options.meta?.updateData(index, id, value);
        setPreviousValue(value);
      }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      updateValue(e.target.value);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        style={{
          minWidth: '170px',
          width: '100%',
          padding: '8px 14px',
          margin: '0',
          boxSizing: 'border-box',
          border: '0px',
        }}
        value={value as string}
        onChange={(e) => onChange(e)}
        onBlur={onBlur}
      />
    );
  },
};

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}
interface IPatientDataTableProps {
  data: IPatientData[];
  setData: React.Dispatch<React.SetStateAction<IPatientData[]>>;
  selectedFileType: FileType;
}
const PatientDataTable = (props: IPatientDataTableProps) => {
  const { data, setData, selectedFileType } = props;
  const classes = useStyles();

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const columns = React.useMemo<ColumnDef<IPatientData>[]>(
    () =>
      selectedFileType.fields.map((field) => ({
        accessorKey: field.displayName || field.name,
        header: field.displayName,
        footer: (footerProps) => footerProps.column.id,
        size: 100,
      })),
    []
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        skipAutoResetPageIndex();
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
    debugTable: true,
  });

  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  return (
    <div className="p-2">
      <div className="h-2" />
      <div
        ref={tableContainerRef}
        style={{ overflow: 'auto', margin: 'auto', border: '1px solid #12574d', maxHeight: '360px' }}
      >
        <table
          style={{
            margin: 'auto',
            borderCollapse: 'collapse',
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      border: '1px solid #12574d',
                      textAlign: 'start',
                      color: '#12574d',
                      backgroundColor: '#E7EEED',
                      padding: '5px',
                      position: 'sticky',
                      width: '100%',
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<IPatientData>;
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        border: '1px solid #12574d',
                        borderSpacing: '0px',
                        textAlign: 'start',
                        padding: '0px',
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="container" style={{ width: '100%' }}>
        <div className="h-2" />
        <div className="flex items-center gap-2">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <button
                type="button"
                className={`${classes.arrowIcon} + ${!table.getCanPreviousPage() && classes.arrowIconDisabled}`}
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronLeft style={{ color: '#E7EEED', margin: 'auto' }} />
              </button>

              <div style={{ display: 'flex', width: '140px', justifyContent: 'center' }}>
                {Array.from({ length: 3 }).map((_, i) => {
                  const pageIndex = table.getState().pagination.pageIndex + i - 1;
                  if (pageIndex >= 0 && pageIndex < table.getPageCount()) {
                    return (
                      <button
                        type="button"
                        key={pageIndex}
                        className={`${classes.paginationButton}  ${
                          table.getState().pagination.pageIndex === pageIndex && classes.paginationButtonActive
                        }`}
                        onClick={() => table.setPageIndex(pageIndex)}
                      >
                        {pageIndex + 1}
                      </button>
                    );
                  }
                  return null;
                })}
              </div>

              <button
                type="button"
                className={`${classes.arrowIcon} + ${!table.getCanNextPage() && classes.arrowIconDisabled}`}
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <ChevronRight style={{ color: '#E7EEED', margin: 'auto' }} />
              </button>
            </div>
            <span className="flex items-center gap-1">
              Go to page:{' '}
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                max={table.getPageCount()}
                min={1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                style={{ width: '100px', padding: ' 8px 14px' }}
                className="border p-1 rounded w-16"
              />
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
              style={{ height: '35.5px' }}
            >
              {[10, 20, 30, 40, 50, table.getCoreRowModel().rows.length].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <div style={{ alignSelf: 'center' }}>
              {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
              {Math.min(
                (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                table.getCoreRowModel().rows.length
              )}{' '}
              of {table.getCoreRowModel().rows.length} Rows
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDataTable;
