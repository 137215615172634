import { IPhysician, IPhysicianPost } from '../interfaces/IPhysician';
import Api from './Api';
import { ClearanceParams } from '../hooks/queries/useClearanceHistory';
import { ClearanceHistoryCustomerResponse } from '../interfaces/IClearanceHistory';

const BASE_PATH = '/v3/physicians';

export const Physicians = {
  async getPhysicians(filter = {}): Promise<IPhysician[]> {
    const { physicians } = await Api.get(BASE_PATH, filter);
    return physicians;
  },
  async createPhysician(data: IPhysicianPost): Promise<IPhysicianPost> {
    const { results, error } = await Api.post(`${BASE_PATH}`, data);
    if (error) {
      throw error;
    }
    return results;
  },
  async getPhysicianInfo(id: number): Promise<IPhysician> {
    const { physician, error } = await Api.get(`${BASE_PATH}/${id}`);
    if (error) {
      throw error;
    }
    return physician;
  },
  async editPhysician(data: IPhysicianPost): Promise<IPhysicianPost> {
    const { id } = data;
    const { results, error } = await Api.put(`${BASE_PATH}/${id}`, data);
    if (error) {
      throw error;
    }
    return results;
  },
  async getClearedPatients(pageParam: number, queryParams: ClearanceParams): Promise<ClearanceHistoryCustomerResponse> {
    const { search, sortBy, sortDirection, practitionerId, startDate, endDate } = queryParams;

    let baseUrl = '/v3/clearance_histories?';
    if (pageParam) {
      baseUrl += `page=${pageParam}&`;
    }
    if (search) {
      baseUrl += `search=${search}&`;
    }
    if (practitionerId) {
      baseUrl += `practitioner_id=${practitionerId}&`;
    }
    if (startDate) {
      baseUrl += `start_date=${startDate}&`;
    }
    if (endDate) {
      baseUrl += `end_date=${endDate}&`;
    }
    if (sortDirection) {
      baseUrl += `sort_direction=${sortDirection}&`;
    }
    if (sortBy) {
      baseUrl += `sort=${sortBy}`;
    }
    return Api.get(baseUrl);
  },
  async getClearedPatientStatistics(year: number): Promise<Record<number, number>> {
    return Api.get(`/v3/clearance_histories/year_statistics?year=${year}`);
  },
};
