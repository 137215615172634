import { CommercialShippingAddress } from 'src/interfaces/onboarding.interface';

export const hasActiveShippingAddress = (addresses: CommercialShippingAddress[] = []) =>
  // If there is only one address use hasCommercialShippingAddress to determine if it is active
  // If there are multiple addresses they are all considered active
  addresses?.length === 1 ? addresses[0].hasCommercialShippingAddress : Boolean(addresses?.length);

export const formatAddress = (address: string, city: string, state: string, zip?: string | number) => {
  if (!address) {
    return '';
  }
  return zip ? `${address}, ${city}, ${state}, ${zip}` : `${address}, ${city}, ${state}`;
};
