import React from 'react';

export const LogoAlt = () => (
  <div style={{ width: '90px', height: '40px', display: 'inline-block' }}>
    {/* eslint-disable max-len */}
    <svg width="133" height="23" viewBox="0 0 133 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_260_14438"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="133"
        height="22"
      >
        <path d="M133 0H0V21.7556H133V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_260_14438)">
        <path
          d="M99.6116 0.29834L90.8535 21.3083H91.9893L99.1394 3.94173L103.602 14.798H96.7042V15.6028H103.933L106.278 21.3083H109.243L100.378 0.29834H99.6116Z"
          fill="#0F0F0F"
        />
        <path
          d="M10.4445 1.13242C9.36187 0.675652 8.08374 0.447266 6.60877 0.447266H0V21.3081H2.62542V1.22243H6.07677C7.61159 1.22243 8.80992 1.64427 9.67575 2.48929C10.5416 3.33431 10.9738 4.4413 10.9738 5.8116C10.9738 6.72512 10.7823 7.52447 10.3979 8.21096C10.0149 8.89611 9.44832 9.43349 8.70086 9.8204C7.9534 10.2073 7.07826 10.4021 6.07544 10.4021H4.82391V11.1773H6.60611C8.08108 11.1773 9.35921 10.9543 10.4418 10.5069C11.5231 10.0595 12.349 9.42946 12.9196 8.61399C13.4902 7.79987 13.7748 6.86618 13.7748 5.81295C13.7748 4.75969 13.4889 3.83138 12.9196 3.02666C12.349 2.22195 11.5231 1.59187 10.4418 1.13376L10.4445 1.13242Z"
          fill="#0F0F0F"
        />
        <path
          d="M31.422 1.41598C29.8088 0.471545 27.98 0 25.9345 0C23.8889 0 22.0296 0.471545 20.4176 1.41598C18.8043 2.36041 17.5462 3.65683 16.6418 5.30522C15.7374 6.95496 15.2852 8.81159 15.2852 10.8778C15.2852 12.944 15.7374 14.802 16.6418 16.4504C17.5462 18.1001 18.8057 19.3965 20.4176 20.3396C22.0296 21.284 23.869 21.7556 25.9345 21.7556C28 21.7556 29.8088 21.284 31.422 20.3396C33.034 19.3965 34.2935 18.1001 35.1979 16.4504C36.1023 14.802 36.5545 12.944 36.5545 10.8778C36.5545 8.81159 36.1023 6.95362 35.1979 5.30522C34.2935 3.65683 33.034 2.36041 31.422 1.41598ZM32.647 16.3174C31.9687 17.7978 31.0483 18.9209 29.8886 19.6853C28.7275 20.4511 27.4108 20.8326 25.9345 20.8326C24.4582 20.8326 23.1415 20.4498 21.9804 19.6853C20.8193 18.9209 19.9003 17.7978 19.222 16.3174C18.5437 14.8369 18.2045 13.0246 18.2045 10.8791C18.2045 8.73367 18.5437 6.92003 19.222 5.44091C19.9003 3.96179 20.8193 2.83868 21.9804 2.07292C23.1401 1.30851 24.4582 0.925626 25.9345 0.925626C27.4108 0.925626 28.7275 1.30851 29.8886 2.07292C31.0483 2.83733 31.9687 3.96044 32.647 5.44091C33.3253 6.92137 33.6644 8.73367 33.6644 10.8791C33.6644 13.0246 33.3253 14.8382 32.647 16.3174Z"
          fill="#0F0F0F"
        />
        <path d="M114.049 0.447266H111.424V21.3081H114.049V0.447266Z" fill="#0F0F0F" />
        <path
          d="M49.9426 10.1029C51.0145 9.68509 51.8405 9.09935 52.4203 8.34434C53.0002 7.58933 53.2902 6.67579 53.2902 5.60239C53.2902 4.58944 53.0042 3.69068 52.435 2.90477C51.8644 2.12021 51.0478 1.51432 49.9864 1.08711C48.9238 0.659896 47.6656 0.446289 46.2106 0.446289H39.5127V21.3085H42.1381V1.22279H45.7078C47.2028 1.22279 48.3732 1.60567 49.219 2.37009C50.0649 3.1345 50.4879 4.23209 50.4879 5.66284C50.4879 6.63683 50.2963 7.45633 49.912 8.12133C49.5289 8.78767 48.977 9.29415 48.2601 9.64075C47.5419 9.9887 46.6907 10.162 45.7078 10.162H44.3366V10.7289L52.2275 21.3085H55.5525L47.3943 10.6698C48.3399 10.5825 49.1911 10.3944 49.9399 10.1029H49.9426Z"
          fill="#0F0F0F"
        />
        <path
          d="M84.1213 10.1029C85.1932 9.68509 86.0192 9.09935 86.5991 8.34434C87.1789 7.58933 87.4689 6.67579 87.4689 5.60239C87.4689 4.58944 87.1829 3.69068 86.6137 2.90477C86.0431 2.12021 85.2265 1.51432 84.1652 1.08711C83.1025 0.659896 81.8443 0.446289 80.3893 0.446289H73.6914V21.3085H76.3168V1.22279H79.8865C81.3815 1.22279 82.5519 1.60567 83.3977 2.37009C84.2436 3.1345 84.6666 4.23209 84.6666 5.66284C84.6666 6.63683 84.475 7.45633 84.0907 8.12133C83.7076 8.78767 83.1557 9.29415 82.4388 9.64075C81.7206 9.9887 80.8694 10.162 79.8865 10.162H78.5153V10.7289L86.4062 21.3085H89.7312L81.573 10.6698C82.5199 10.5825 83.3698 10.3944 84.1186 10.1029H84.1213Z"
          fill="#0F0F0F"
        />
        <path d="M54.6306 1.25199H61.5333V21.3081H64.1601V1.25199H71.0336V0.447266H54.6306V1.25199Z" fill="#0F0F0F" />
        <path d="M116.597 0.447266V1.25199H123.501V21.3081H126.126V1.25199H133.001V0.447266H116.597Z" fill="#0F0F0F" />
      </g>
    </svg>

    {/* eslint-enable max-len */}
  </div>
);
