import React from 'react';
import { Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import IServices from '../../../interfaces/IServices';

interface ServicesButtonsProps {
  services: IServices[];
  setSelectedServiceId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedServiceId: number | null;
  isLoading: boolean;
}

export const ServicesButtons = ({
  services,
  setSelectedServiceId,
  selectedServiceId,
  isLoading,
}: ServicesButtonsProps) => (
  <div style={{ marginTop: 20, display: 'flex' }}>
    {services.map((service) =>
      isLoading ? (
        <Skeleton
          key={service.id}
          height={40}
          width={100}
          variant="rect"
          style={{ marginRight: 5, marginBottom: 3 }}
        />
      ) : (
        <Button
          data-cy="serviceBtn"
          key={service.id}
          type="button"
          style={{
            backgroundColor: service.color || 'black',
            color: 'white',
            marginRight: 5,
            marginBottom: 3,
            opacity: service.id === selectedServiceId ? 1 : 0.35,
          }}
          onClick={() => setSelectedServiceId(service.id)}
        >
          {service.name}
        </Button>
      )
    )}
  </div>
);
