import React from 'react';
import { ButtonBase, Divider, Grid, makeStyles } from '@material-ui/core';
import { EHRModal, EHRTypography } from 'src/components/ui/v1';
import { ShippingAddress } from 'src/pages/Onboarding/AccountSettings/StepsCards';
import { Close as CloseIcon } from '@material-ui/icons';
import { useQueryClient } from 'react-query';
import { CURRENT_USER_GROUP } from 'src/constants/reactQuery.keys';
import { CommercialShippingAddress } from 'src/interfaces/onboarding.interface';

const useStyles = makeStyles({
  formContainer: {
    width: '100%',
    minHeight: '450px',
    maxHeight: '80vh',
    height: '100%',
    maxWidth: '600px',
    padding: '2rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    overflowY: 'auto',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 0',
  },
});

interface ShippingAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  editMode?: boolean;
  commercialShippingAddress?: CommercialShippingAddress;
}

const ShippingAddressModal = (props: ShippingAddressModalProps) => {
  const queryClient = useQueryClient();
  const { isOpen, onClose, editMode, commercialShippingAddress } = props;
  const classes = useStyles();

  const handleSuccess = () => {
    queryClient.invalidateQueries([CURRENT_USER_GROUP]);
    onClose();
  };

  return (
    <EHRModal open={isOpen} onClose={onClose} dataCy="">
      <Grid container className={classes.formContainer}>
        <Grid item xs={12} className={classes.headerContainer}>
          <EHRTypography variant="h6" dataCy="header-content">
            {editMode ? 'Update Commercial Shipping Address' : 'Add Commercial Shipping Address'}
          </EHRTypography>
          <ButtonBase onClick={onClose}>
            <CloseIcon />
          </ButtonBase>
        </Grid>
        {editMode && (
          <Grid item xs={12} style={{ margin: '0 0 10px 0' }}>
            <EHRTypography variant="body2" dataCy="header-content">
              Any updates made to the commercial shipping address will be saved once the Real Estate Business Management
              agreement is signed.
            </EHRTypography>
          </Grid>
        )}
        <Divider />
        <Grid item xs={12}>
          <ShippingAddress
            onSuccess={handleSuccess}
            commercialShippingAddress={editMode ? commercialShippingAddress : undefined}
            editMode
          />
        </Grid>
      </Grid>
    </EHRModal>
  );
};

export default ShippingAddressModal;
