/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  AccountCircle,
  QuestionAnswer,
  PhoneInTalk,
  CreditCard,
  PhoneForwarded,
  Close,
  Done,
} from '@material-ui/icons';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Paper,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ROUTES } from '../../../../constants/routes.constants';
import { TableCellCustom } from '../TodoListTabs/rowItem.styles';
import {
  IClientLeadRow,
  IClientLeadParams,
  IClientLeadStatus,
  IClientLeadStatusParams,
  ILeadDialogParams,
} from '../../../../interfaces/IClientLead';
import { SelectList as ReportList } from '../../../common/SelectList';
import { useStyles } from './reports.styles';
import { useUpdateClientLeadNotes, useUpdateClientLeadStatus } from '../../../../hooks/queries/useClientLeads';
import {
  DATE_FORMAT_COMPACT,
  DATE_FORMAT_FULL,
  FULLSCREEN,
  NEW_WINDOW,
  TABLE_LIST,
  EMPTY_CELL,
  EMPTY_HREF,
  BOOKED,
  CONVERTED,
  NONE_CELL,
} from '../../../../constants/clientLead.constants';

const callTime = (date: Date | moment.Moment | null | undefined, ago: boolean | undefined): string =>
  String(!date ? EMPTY_CELL : moment(date).format(DATE_FORMAT_COMPACT)) +
  String(!date || !ago ? '' : ` (${moment(date).fromNow()})`);

const openInNewWindow = (uri: string): Window | null => window.open(uri, NEW_WINDOW);

const FocusButtons: React.FC<IClientLeadRow> = ({ row }: IClientLeadRow): React.ReactElement => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [userNote, setUserNote] = useState<string>('');
  const matches = useMediaQuery(FULLSCREEN);
  const classes = useStyles();

  const { mutateAsync: updateNotes } = useUpdateClientLeadNotes(row.id);

  const handleSaveNotes = async (): Promise<void> => {
    setIsSaving(true);
    await updateNotes(userNote);
    setIsSaving(false);
  };

  const requiresSave = (row.notes ?? '') !== userNote;

  useEffect(() => {
    row.notes && setUserNote(row.notes);
  }, []);

  return (
    <Box className={classes.focusMenu}>
      <Button
        variant="outlined"
        disabled={!row.customerId}
        className={classes.focusButton}
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          // eslint-disable-next-line no-unused-expressions
          row.customerId && openInNewWindow(ROUTES.PATIENT_ID(row.customerId));
        }}
        data-testid="lead focus customer button"
      >
        {' '}
        View Customer
      </Button>
      <Button
        variant="outlined"
        className={classes.focusButton}
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          openInNewWindow(row.openphoneConversationLink);
        }}
        disabled={!row.openphoneConversationLink}
        data-testid="lead focus op button"
      >
        {' '}
        Contact History
      </Button>
      <Paper className={classes.comStat}>
        <Box>
          <Typography>Total Outbound: {row.outboundSmsTotal}</Typography>
          <Typography>Last Inbound: {callTime(row.lastInboundSmsTime, matches)}</Typography>
          <Typography>Last Outbound: {callTime(row.lastOutboundSmsTime, matches)}</Typography>
        </Box>
        <QuestionAnswer className={classes.talkIcon} />
      </Paper>
      <Divider className={classes.divider} />
      <Paper className={classes.comStat}>
        <Box>
          <Typography>Total Outbound: {row.outboundCallTotal}</Typography>
          <Typography>Last Inbound: {callTime(row.lastInboundCallTime, matches)}</Typography>
          <Typography>Last Outbound: {callTime(row.lastOutboundCallTime, matches)}</Typography>
        </Box>
        <PhoneInTalk className={classes.talkIcon} />
      </Paper>
      <Box
        maxHeight={125}
        margin="auto 0 0 auto"
        width="calc(75% - 8px)"
        position="relative"
        className={classes.detailGroup}
      >
        <textarea
          placeholder="Enter notes here..."
          defaultValue={userNote}
          onChange={(event: React.ChangeEvent): void =>
            setUserNote((event.currentTarget as HTMLInputElement).value || '')
          }
        />
        <IconButton onClick={handleSaveNotes} data-editing={requiresSave} data-testid={`save note ${row.id}`}>
          {isSaving ? <CircularProgress size={22} /> : <Done />}
        </IconButton>
      </Box>
    </Box>
  );
};

const LeadStatus: React.FC<IClientLeadStatusParams> = ({
  status,
  additionalClasses,
}: IClientLeadStatusParams): React.ReactElement => {
  const classes = useStyles();

  return (
    <Box
      className={[classes.leadStatus].concat(additionalClasses || []).join(' ')}
      data-status={status}
      data-align="right"
    >
      <Typography variant="caption" className={classes.leadStatusText}>
        {TABLE_LIST[status]}
      </Typography>
    </Box>
  );
};

const FocusDialog: React.FC<ILeadDialogParams> = ({ row, setDialogOpen }: ILeadDialogParams): React.ReactElement => {
  const { mutateAsync } = useUpdateClientLeadStatus(row.id);

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    setDialogOpen(false);
  };

  const handleSelect = async (status: string | null): Promise<void> => {
    if (status) {
      await mutateAsync(status as IClientLeadStatus);
    }
    setDialogOpen(false);
  };

  const ignoreSpendData = ([BOOKED, CONVERTED] as IClientLeadStatus[]).indexOf(row.status) < 0;

  return (
    <Box data-testid="lead focus dialog" className={classes.focusDialog}>
      <Box className={classes.focusDialogHeader}>
        <IconButton
          size="small"
          onClick={handleClick}
          className={classes.closeDialog}
          data-testid="lead focus dialog close"
        >
          <Close />
        </IconButton>
        <AccountCircle className={classes.userIcon} />
        <Typography variant="h4">{row.fullName}</Typography>
        {row.cardOnFile ? <CreditCard className={classes.ccIcon} /> : null}
        <LeadStatus status={row.status} />
      </Box>
      <Box className={classes.sideBy}>
        <Box className={classes.focusDialogContent}>
          <Box className={classes.detailGroup}>
            <Box data-testid="focus status select" className={classes.focusDialogLine}>
              <Typography>Lead Status:</Typography>
              <ReportList
                title=""
                className={classes.leadStatusSelectFlex}
                listItems={TABLE_LIST}
                selected={row.status}
                setSelected={handleSelect}
              />
            </Box>
            <Box className={classes.focusDialogLine}>
              <Typography>Email:</Typography>
              <Typography>{row.email}</Typography>
            </Box>
            <Box className={classes.focusDialogLine}>
              <Typography>Phone Number:</Typography>
              <Typography className={classes.phonePill}>
                <Link
                  href={EMPTY_HREF}
                  data-testid="lead focus op link"
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    // eslint-disable-next-line no-unused-expressions
                    row.openphoneConversationLink && openInNewWindow(row.openphoneConversationLink);
                  }}
                >
                  {row.phone}
                </Link>
              </Typography>
            </Box>
            <Box className={classes.focusDialogLine}>
              <Typography>First Contact:</Typography>
              <Typography>
                {!row.firstContactTime ? EMPTY_CELL : moment(row.firstContactTime).format(DATE_FORMAT_FULL)}
              </Typography>
            </Box>
            <Box className={classes.focusDialogLine}>
              <Typography>Next Appointment:</Typography>
              <Typography>
                {!row.nextAppointment ? EMPTY_CELL : moment(row.nextAppointment).format(DATE_FORMAT_FULL)}
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.detailGroup}>
            <Box className={classes.focusDialogLine}>
              <Typography>Original Lead Source:</Typography>
              <Typography>{row.originalSource || EMPTY_CELL}</Typography>
            </Box>
            <Box className={classes.focusDialogLine}>
              <Typography>Latest Lead Source:</Typography>
              <Typography>{row.latestSource || EMPTY_CELL}</Typography>
            </Box>
            <Box className={classes.focusDialogLine}>
              <Typography>Original Campaign:</Typography>
              <Typography>{row.originalCampaign || EMPTY_CELL}</Typography>
            </Box>
            <Box className={classes.focusDialogLine}>
              <Typography>Latest Campaign:</Typography>
              <Typography>{row.latestCampaign || EMPTY_CELL}</Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.detailGroup}>
            <Box className={classes.focusDialogLine} data-disabled={ignoreSpendData}>
              <Typography>Appointment Total:</Typography>
              <Typography>{row.visitCount || 0}</Typography>
            </Box>
            <Box className={classes.focusDialogLine} data-disabled={ignoreSpendData}>
              <Typography>Lifetime Spend:</Typography>
              <Typography>${row.totalSpend || 0}</Typography>
            </Box>
          </Box>
        </Box>
        <FocusButtons row={row} />
      </Box>
    </Box>
  );
};

const RowItem: React.FC<IClientLeadParams> = ({
  row,
  setDialogOpen,
  setFocusDialog,
  practitionerView,
}: IClientLeadParams): React.ReactElement => {
  const { mutateAsync } = useUpdateClientLeadStatus(row.id);

  const classes = useStyles();

  const handleLink = (event: React.MouseEvent): void => {
    event.preventDefault();
    setDialogOpen(true);
    setFocusDialog(<FocusDialog row={row} setDialogOpen={setDialogOpen} />);
  };

  const handleSelect = async (status: string | null): Promise<void> => {
    if (status) {
      await mutateAsync(status as IClientLeadStatus);
    }
  };

  return (
    <>
      <TableRow className={classes.centerRow}>
        <TableCellCustom>
          <IconButton
            size="small"
            onClick={handleLink}
            className={classes.rowIcon}
            data-testid={`lead focus dialog button ${row.id}`}
          >
            <AccountCircle />
          </IconButton>
          <IconButton
            size="small"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              openInNewWindow(row.openphoneConversationLink);
            }}
            className={classes.rowIcon}
            disabled={!row.openphoneConversationLink}
            data-testid={`lead row op link ${row.id}`}
          >
            <PhoneForwarded />
          </IconButton>
        </TableCellCustom>
        <TableCellCustom className={classes.nameRow}>
          <Link href={EMPTY_HREF} onClick={handleLink} className={classes.cellLink}>
            {' '}
            {row.fullName}
            <Typography className={classes.phoneCell}>{row.phone}</Typography>
          </Link>
        </TableCellCustom>
        <TableCellCustom className={classes.centerRow}>
          <ReportList
            title=""
            className={classes.leadStatusSelect}
            listItems={TABLE_LIST}
            selected={row.status}
            setSelected={handleSelect}
          />
        </TableCellCustom>
        {!practitionerView && <TableCellCustom className={classes.centerSmall}>{row.practitionerName}</TableCellCustom>}
        <TableCellCustom className={classes.centerSmall}>{row.latestSource || row.originalSource}</TableCellCustom>
        <TableCellCustom className={classes.campaignName}>{row.latestCampaign || row.originalCampaign}</TableCellCustom>
        <TableCellCustom className={classes.leadRowNotes}>
          <Typography>{row.notes}</Typography>
        </TableCellCustom>
        <TableCellCustom className={classes.centerSmall}>
          {row.nextAppointment ? moment(row.nextAppointment).format(DATE_FORMAT_COMPACT) : NONE_CELL}
        </TableCellCustom>
        <TableCellCustom className={classes.centerSmall}>
          {row.firstContactTime ? moment(row.firstContactTime).fromNow(true) : EMPTY_CELL}
        </TableCellCustom>
        <TableCellCustom className={classes.centerSmall}>
          {row.lastInboundSmsTime ? moment(row.lastInboundSmsTime).fromNow(true) : EMPTY_CELL}
        </TableCellCustom>
        <TableCellCustom className={classes.centerSmall}>
          {row.lastOutboundSmsTime ? moment(row.lastOutboundSmsTime).fromNow(true) : EMPTY_CELL}
        </TableCellCustom>
        <TableCellCustom className={classes.centerSmall}>
          {row.lastInboundCallTime ? moment(row.lastInboundCallTime).fromNow(true) : EMPTY_CELL}
        </TableCellCustom>
        <TableCellCustom className={classes.centerSmall}>
          {row.lastOutboundCallTime ? moment(row.lastOutboundCallTime).fromNow(true) : EMPTY_CELL}
        </TableCellCustom>
        <TableCellCustom className={classes.centerSmall}>
          {row.createdAt ? moment(row.createdAt).format(DATE_FORMAT_COMPACT) : EMPTY_CELL}
        </TableCellCustom>
      </TableRow>
    </>
  );
};

export { RowItem, RowItem as default };
