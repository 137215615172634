import React from 'react';
import { getFeatures } from 'src/services/Features';
import { useQuery } from 'react-query';
import { Box, Table, TableBody, TableRow, TableCell, TableContainer } from '@material-ui/core';
import TableHeader from 'src/components/common/Table/TableHeader';
import { IFeature } from 'src/interfaces/IFeature';
import Page from 'src/components/common/Page';

const TABLE_COLUMNS = [
  { id: 'id', title: 'ID' },
  { id: 'key', title: 'Key' },
  { id: 'name', title: 'Name' },
  { id: 'description', title: 'Description' },
];

const FeatureFlagsPage = () => {
  const { data: featureFlags, isLoading: isLoadingFlags } = useQuery(['FEATURE_FLAGS', 1], () => getFeatures());

  const TableComponent = (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHeader columns={TABLE_COLUMNS} />
          <TableBody>
            {isLoadingFlags ? (
              <h1>Loading</h1>
            ) : (
              (featureFlags || []).map((featureFlag: IFeature) => (
                <TableRow key={featureFlag.id} data-cy="row">
                  <TableCell>{featureFlag.id}</TableCell>
                  <TableCell data-cy="key">{featureFlag.key}</TableCell>
                  <TableCell data-cy="name">{featureFlag.name}</TableCell>
                  <TableCell data-cy="description">{featureFlag.description}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Page title="Feature Flags">
      <Box width="100%">
        <Box>{TableComponent}</Box>
      </Box>
    </Page>
  );
};

export default FeatureFlagsPage;
