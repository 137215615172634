import { withStyles, Tab } from '@material-ui/core';

export const TabCustom = withStyles(() => ({
  root: {
    height: 'auto',
    padding: '1.4rem 1rem !important',
    marginLeft: 0,
    minWidth: 'max-content',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '22%',
    fontFamily: 'Messina Sans SemiBold !important',
    fontSize: '13px !important',
    fontWeight: 300,
    opacity: 0.5,
  },
}))(Tab);
