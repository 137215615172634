import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Props } from './Button';

const MaterialButton = withStyles(() => ({
  root: {
    borderRadius: 3,
    margin: '0 5px',
    padding: '15px',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    textAlign: 'center',
    maxHeight: '46px',
  },
  disabled: {
    opacity: '0.5',
  },
}))(Button);

export const IconButton = ({ title, style, onClick = () => {}, Icon, disabled, startIcon, className }: Props) => (
  <MaterialButton
    data-cy="iconButton"
    className={className}
    endIcon={Icon}
    style={style}
    onClick={onClick}
    disabled={disabled}
    startIcon={startIcon}
  >
    {title}
  </MaterialButton>
);
