import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'src/rematch';
import { BEAUTY_BANK_MEMBER, BEAUTY_BANK_SUBSCRIPTION_STATUS } from '../../../constants/beautyBank.constants';
import ISubscription from '../../../interfaces/ISubscription';
import { useStyles } from './beautyBankBadge.styles';
import { ReactComponent as TriangleAlert } from '../../../assets/images/triangle-alert.svg';

export const BeautyBankBadge = ({ patientId }: { patientId: string }) => {
  const classes = useStyles();

  const [customerSubscription, setCustomerSubscription] = useState<ISubscription | null>(null);

  const { basicInfo: basicData } = useSelector(({ patient }: any) => patient);
  const { isLoadingBasicInfo } = basicData;

  useEffect(() => {
    if (basicData?.subscription) {
      setCustomerSubscription(basicData.subscription);
    }
  }, [basicData]);

  useEffect(() => {
    dispatch.patient.fetchBasicInfo({ patientId: +patientId });
  }, []);

  if (isLoadingBasicInfo) {
    return <CircularProgress className={classes.circularProgress} size={20} />;
  }

  if (customerSubscription && customerSubscription.status === BEAUTY_BANK_SUBSCRIPTION_STATUS.ACTIVE) {
    return (
      <div className={classes.beautyBankBadge}>
        <div className={classes.diamond}>
          <div className={classes.diamondTop} />
          <div className={classes.diamondBottom} />
        </div>
        <span className={classes.textLabel}>{BEAUTY_BANK_MEMBER}</span>
      </div>
    );
  }

  if (customerSubscription && customerSubscription.status === BEAUTY_BANK_SUBSCRIPTION_STATUS.PAYMENT_OVERDUE) {
    return (
      <>
        <div className={classes.beautyBankBadge}>
          <TriangleAlert />
          <span className={`${classes.textLabel} ${classes.errorAlert}`}>BeautiBank Payment Failed</span>
        </div>
      </>
    );
  }
  return null;
};
