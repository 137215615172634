import { uniq } from 'lodash';
import { useSelector } from 'react-redux';
import IServiceGroup from 'src/interfaces/IServiceGroup';
import { SERVICE_VISIT_STEPS, TRANSACTION_STATUS_PAID } from '../../constants/serviceVisits/serviceVisit.constants';
import { ILineItem, IServiceVisitPhoto, IMedicalCharting } from '../../interfaces/IServiceVisits';
import IServices from '../../interfaces/IServices';
import { ICheckout } from '../../interfaces/checkout.interfaces';
import { RETAIL_SERVICE_GROUP_ID } from '../../constants/retail.constants';
import { CUSTOM_TREATMENT_SERVICE_NAME } from '../../constants/checkout.constants';
import { hasAccessTo } from '../auth.utils';
import { READ_INVENTORY } from '../../constants/actions.constants';

export const getServiceVisitValidSteps = ({
  services,
  lineItems,
  pendingServices,
  beforePhotos,
  afterPhotos,
  isBeforePhotoRequired,
  isAfterPhotoRequired,
  checkout,
  customerConsents,
  serviceGroupPendingForConsents,
  signedStandingOrders,
  servicesWithStandingOrders,
  medicalCharting,
  saveMedicalCharting,
}: {
  services: IServices[];
  lineItems: ILineItem[];
  pendingServices: IServices[];
  beforePhotos: IServiceVisitPhoto[];
  afterPhotos: IServiceVisitPhoto[];
  isBeforePhotoRequired: boolean;
  isAfterPhotoRequired: boolean;
  checkout: ICheckout;
  customerConsents: any;
  serviceGroupPendingForConsents: IServiceGroup[];
  signedStandingOrders: any;
  servicesWithStandingOrders: any;
  medicalCharting: IMedicalCharting;
  saveMedicalCharting: boolean;
}) => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const unitsToServiceCount: Record<string, number> = lineItems.reduce(
    (obj: any, lineItem: ILineItem) =>
      Object.assign(obj, {
        [lineItem.serviceId]: (obj[lineItem.serviceId] || 0) + lineItem.currentUseQuantity + lineItem.futureUseQuantity,
      }),
    {}
  );

  const isValidServiceUnits = !Object.values(unitsToServiceCount).some((count: number) => count === 0);
  const isValidServiceVisitScannerStep = pendingServices.length === 0 || !hasAccessTo(READ_INVENTORY, permissions);
  const isValidBeforePhoto = isBeforePhotoRequired ? beforePhotos.length !== 0 : true;
  const isValidCheckout = checkout?.transactionStatus === TRANSACTION_STATUS_PAID;
  const isValidAfterPhoto = isAfterPhotoRequired ? afterPhotos.length !== 0 : true;
  const isValidServiceGroupConsent = serviceGroupPendingForConsents
    .filter(({ consentRequiredForServiceVisit }) => consentRequiredForServiceVisit)
    .every(({ id }: any) => customerConsents.some(({ serviceGroupId }: any) => serviceGroupId === id));

  const signedServiceGroupIdsForStandingOrders = uniq(
    signedStandingOrders.map(({ serviceGroupId }: { serviceGroupId: number }) => serviceGroupId)
  );
  const isValidStandingOrders = servicesWithStandingOrders.every(({ serviceGroupId }: any) =>
    signedServiceGroupIdsForStandingOrders.includes(serviceGroupId)
  );

  const annotatableServices = lineItems
    .filter(({ currentUseQuantity }) => currentUseQuantity > 0)
    .filter((lineItem) => services.some((service) => service.annotatable && service.id === lineItem.serviceId))
    .map(({ serviceId }) => serviceId);

  const isValidMedicalCharting =
    !saveMedicalCharting &&
    annotatableServices.every(
      (id) =>
        services.find((service) => service.id === id && service.name === CUSTOM_TREATMENT_SERVICE_NAME) ||
        medicalCharting.annotatedPhotos.some((annotatedPhoto) =>
          annotatedPhoto.photoServices.some(
            (photoService) =>
              photoService.service.id === id || photoService.service.serviceGroupId === RETAIL_SERVICE_GROUP_ID
          )
        )
    );

  const isValidServiceVisit =
    isValidServiceVisitScannerStep &&
    isValidServiceUnits &&
    isValidBeforePhoto &&
    isValidCheckout &&
    isValidAfterPhoto &&
    isValidStandingOrders &&
    isValidMedicalCharting;

  return {
    isValidServiceVisit,
    isValidServiceUnits,
    isValidServiceVisitScannerStep,
    isValidBeforePhoto,
    isValidAfterPhoto,
    isValidCheckout,
    isValidStandingOrders,
    isValidServiceGroupConsent,
    isValidMedicalCharting,
  };
};

interface IInvalidStepId {
  isValidServiceUnits: boolean;
  isValidServiceVisitScannerStep: boolean;
  isValidBeforePhoto: boolean;
  isValidAfterPhoto: boolean;
  isValidCheckout: boolean;
  isValidStandingOrders: boolean;
  isValidServiceGroupConsent: boolean;
  isValidMedicalCharting: boolean;
}
export const getInvalidStepId = ({
  isValidServiceUnits,
  isValidServiceVisitScannerStep,
  isValidBeforePhoto,
  isValidAfterPhoto,
  isValidCheckout,
  isValidStandingOrders,
  isValidServiceGroupConsent,
  isValidMedicalCharting,
}: IInvalidStepId) => {
  let step: string = '';
  if (!isValidServiceUnits) {
    step = SERVICE_VISIT_STEPS.serviceUnits;
  } else if (!isValidBeforePhoto) {
    step = SERVICE_VISIT_STEPS.beforePhotos;
  } else if (!isValidServiceGroupConsent) {
    step = SERVICE_VISIT_STEPS.consents;
  } else if (!isValidStandingOrders) {
    step = SERVICE_VISIT_STEPS.standingOrders;
  } else if (!isValidAfterPhoto) {
    step = SERVICE_VISIT_STEPS.afterPhotos;
  } else if (!isValidServiceVisitScannerStep) {
    step = SERVICE_VISIT_STEPS.scanner;
  } else if (!isValidCheckout) {
    step = SERVICE_VISIT_STEPS.checkout;
  } else if (!isValidMedicalCharting) {
    step = SERVICE_VISIT_STEPS.medicalCharting;
  }
  return step;
};

export const getServiceGroupsPendingForConsents = (
  selectedServiceGroups: any,
  services: IServices[],
  lineItems: ILineItem[] // All service groups with current units even the not required for SV
) =>
  selectedServiceGroups.filter(
    ({ consent, id }: any) =>
      !!consent &&
      services.filter(
        ({ serviceGroupId, id: serviceId }) =>
          serviceGroupId === id &&
          lineItems
            .filter((item) => item.serviceId === serviceId)
            .map((item) => item.currentUseQuantity)
            .reduce((a, b) => a + b) > 0
      ).length > 0
  );
