import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, MenuItem } from '@material-ui/core';
import { EHRSelect } from 'src/components/ui/v1';
import { MEDICAL_LICENSES } from 'src/pages/AccountSetting/MoreDetails';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import { useCreateLicenses } from 'src/hooks/queries/useAccountSettings';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';

interface LicensesProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus) => void;
  license?: string;
  progressPercentage?: number;
  disabled?: boolean;
}

const Licenses = ({ stepId, onStepUpdate, license, progressPercentage, disabled = false }: LicensesProps) => {
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();
  const [value, setValue] = useState<string>('');
  const { mutateAsync: createLicenses, isLoading, isError, isSuccess } = useCreateLicenses();
  const prevState = useRef(license);

  const handleLicenses = (licenses: string) => {
    setValue(licenses);
    createLicenses({ licenses });
  };

  useEffect(() => {
    if (license) {
      setValue(license);
      onStepUpdate(stepId, STEP_COMPLETED);
    }
  }, [license, isSuccess]);

  useEffect(() => {
    if (prevState.current === null && license) {
      updateProgress({
        stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
        percentage: progressPercentage as number,
      });
    }

    prevState.current = license;
  }, [license]);

  useEffect(() => {
    if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isError) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [isLoading, isError]);

  return (
    <Box>
      <EHRSelect
        dataCy="select-licenses"
        name="licenses"
        value={value}
        onChange={(e: string | number) => handleLicenses(e.toString())}
        label="Licenses"
        fullWidth
        error={isError}
        helperText={isError ? 'Error updating licenses' : ''}
        disabled={disabled}
        startAdornment={
          isLoading ? (
            <Box width="40px" textAlign="center">
              <CircularProgress size={20} />
            </Box>
          ) : null
        }
      >
        {[...MEDICAL_LICENSES, 'Other'].map((licenseType) => (
          <MenuItem value={licenseType} key={licenseType}>
            {licenseType}
          </MenuItem>
        ))}
      </EHRSelect>
    </Box>
  );
};

export default Licenses;
