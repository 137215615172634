import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import SkinProfileForm from '../components/PatientProfile/SkinProfileForm/SkinProfileForm';
import { RootState, dispatch } from '../rematch';
import { ROUTES } from '../constants/routes.constants';

const MedicalProfile = ({
  match: {
    params: { patientId },
  },
}: any) => {
  const history = useHistory();
  const { isLoading } = useSelector(({ patient }: RootState) => patient);

  useEffect(() => {
    dispatch({ type: 'patient/fetchPatientData', payload: +patientId });
  }, [patientId]);

  const navigateBack = () => {
    history.push(ROUTES.PATIENT_ID(patientId));
  };

  return (
    <main>
      <NavigationBar title="Skin profile" onBackButtonClick={navigateBack} />
      {isLoading ? (
        <MultipleSkeleton />
      ) : (
        <article>
          <SkinProfileForm patientId={patientId} />
        </article>
      )}
    </main>
  );
};

export default MedicalProfile;
