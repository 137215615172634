import React from 'react';
import { Box, Typography } from '@material-ui/core';

export const Banner = () => (
  <Box
    style={{
      border: '1px solid #F0F0F0',
      borderRadius: '8px',
      padding: '16px',
      marginTop: '1rem',
      marginBottom: '1rem',
    }}
  >
    <Box>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#F7930D" />
        <path
          // eslint-disable-next-line max-len
          d="M11.3338 5.81526L11.1431 11.6578H9.75615L9.55967 5.81526H11.3338ZM9.51343 13.4667C9.51343 13.2201 9.59819 13.0159 9.76771 12.8541C9.94108 12.6884 10.1684 12.6056 10.4496 12.6056C10.7347 12.6056 10.962 12.6884 11.1316 12.8541C11.3011 13.0159 11.3858 13.2201 11.3858 13.4667C11.3858 13.7055 11.3011 13.9078 11.1316 14.0735C10.962 14.2391 10.7347 14.322 10.4496 14.322C10.1684 14.322 9.94108 14.2391 9.76771 14.0735C9.59819 13.9078 9.51343 13.7055 9.51343 13.4667Z"
          fill="white"
        />
      </svg>
    </Box>

    <Typography
      style={{ fontWeight: 'normal', fontSize: '13px', color: '#000000', opacity: '0.48', marginLeft: '12px' }}
    >
      Please submit a ticket with an order request once you are ready to get started ordering through Portrait. The
      compliance team will help guide you efficiently through the steps required to get your business and your Medical
      Director accounts verified and compliant with Portrait and its supplier partners.
    </Typography>
  </Box>
);
