import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      '& > div#root': {
        height: '100%',
      },
    },
  },
  signupContainer: {
    height: '100%',
    display: 'flex',
  },
  sidebarContainer: {
    width: '300px',
    background: '#EDE6E4',
    height: '100%',
    padding: '2rem',
  },
  signupStepsContainer: {
    margin: '2rem 0 0 1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  signupStep: {
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    fontSize: '18px',
    marginBottom: '1rem',
    '& > svg': {
      marginRight: '10px',
    },
  },
  cleared: {
    opacity: '50%',
  },
  signupStepCompleted: {
    color: '#F16C00',
    '& > svg > circle': {
      stroke: '#F16C00',
    },
    '& > svg > path': {
      stroke: '#F16C00',
    },
  },
  signupStepIncomplete: {
    color: '#75665A',
  },
  signupDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  signupComplete: {
    maxWidth: '500px',
  },
  signupCompleteHeader: {
    maxWidth: '400px',
  },
  signupForm: {
    width: '400px',
  },
  signupNextStepContainer: {
    width: '600px',
  },
  loginLink: {
    textAlign: 'center',
  },
  signupButton: {
    background: '#F16C00',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    width: '100%',
    padding: '1rem',
    cursor: 'pointer',
    marginTop: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:disabled': {
      backgroundColor: '#979797',
      color: 'white',
    },
  },
  signupField: {
    marginBottom: '2rem',
  },
  bold: {
    fontFamily: 'Messina Sans Bold',
  },
  subSectionContainer: {
    background: '#F6F3F2',
    borderRadius: '4px',
    padding: '1rem',
  },
  subSectionButton: {
    background: '#12574d',
    color: 'white',
    fontFamily: 'Messina Sans Bold',
    borderRadius: '8px',
    padding: '8px 16px',
    border: 'none',
    cursor: 'pointer',
  },
}));
