import React, { useContext, useState } from 'react';
import { Box, Dialog, Button, Typography } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { InventoryOrderService } from 'src/interfaces/IInventoryOrder';
import { groupBy, sortBy, sumBy } from 'lodash';
import { MultipleSkeleton } from 'src/components/common/LoadingSkeleton';
import { PINNED_PRODUCTS_AND_SUPPLIERS } from 'src/constants/inventory.constants';
import { useStyle } from '../inventoryOrder.styles';
import { OrderContext } from '../index';
import ServiceSelectionSupplier from './ServiceSelectionSupplier';

const ServiceSelection = () => {
  const classes = useStyle();

  const [showAllSuppliers, setShowAllSuppliers] = useState<boolean>(false);
  const [budgetDialogueOpen, setBudgetDialogueOpen] = useState<boolean>(false);

  const { orderData, isLoading } = useContext(OrderContext) || {};

  const servicesBySupplier: { [supplierName: string]: InventoryOrderService[] } = groupBy(
    (orderData?.services ?? []) as InventoryOrderService[],
    'supplierName'
  );

  const listOrder = Object.keys(PINNED_PRODUCTS_AND_SUPPLIERS);
  const sortedSuppliers = sortBy(Object.keys(servicesBySupplier), (supplierName) => {
    const supplierIndex = listOrder.indexOf(supplierName);

    return supplierIndex < 0 ? supplierName.toLowerCase().charCodeAt(0) + (listOrder.length + 1) * 2 : supplierIndex;
  });

  const getSupplierUsage = (supplierName: string) =>
    sumBy(servicesBySupplier[supplierName], (item) => item.recommendation + item.currentTotal + item.quantity);

  return isLoading ? (
    <Box className={classes.skelWrap}>
      <MultipleSkeleton />
    </Box>
  ) : (
    <>
      {sortedSuppliers.map(
        (supplier: string) =>
          (showAllSuppliers || listOrder.indexOf(supplier) > -1 || getSupplierUsage(supplier) > 0) && (
            <ServiceSelectionSupplier
              key={supplier}
              supplier={supplier}
              items={servicesBySupplier[supplier]}
              RowProps={{ setBudgetDialogueOpen }}
            />
          )
      )}

      <Box className={classes.viewMore}>
        {!showAllSuppliers && (
          <Button
            data-cy="view"
            onClick={() => setShowAllSuppliers(true)}
            variant="outlined"
            data-showmore
            className={classes.actionButton}
          >
            Show More Suppliers
          </Button>
        )}
      </Box>

      <Dialog open={budgetDialogueOpen} maxWidth="lg">
        <Box className={classes.confirmBox} data-testid="order confirm dialog">
          <ErrorIcon className={classes.successIcon} viewBox="0 0 22 22" />
          <Typography variant="h4">Budget Exceeded</Typography>
          <Typography data-testid="confirm delivery" component="p" variant="subtitle2">
            Your order is in excess of your available budget. Some items may be excluded.
          </Typography>
          <Box className={classes.divider} />
          <Box className={classes.modalButtons}>
            <Button
              data-cy="view"
              variant="outlined"
              className={classes.modalButton}
              onClick={() => setBudgetDialogueOpen(false)}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ServiceSelection;
