import React, { useState } from 'react';
import Page from 'src/components/common/Page';
import Breadcrumb from 'src/components/DashboardAdministrator/common/Breadcrumb';
import { EHRButton } from 'src/components/ui/v1';
import { Box, Container, withStyles } from '@material-ui/core';
import { IOrderProductAdmin } from 'src/services/Products';
import { ClassNameMap } from 'src/types/Dom';
import CreateOrEditProduct from './CreateOrEditProduct';
import ProductsTable from './ProductsTable';
import ImportProducts from './ImportProducts';

export const OrderProducts: React.FC<ClassNameMap> = ({ classes }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const [focusProduct, setFocusProduct] = useState<IOrderProductAdmin | undefined>(undefined);

  const editProduct = (product: IOrderProductAdmin) => {
    setFocusProduct(product);
    setDialogOpen(true);
  };

  const createProduct = () => {
    setFocusProduct(undefined);
    setDialogOpen(true);
  };

  return (
    <Page title="Products">
      <Container classes={classes}>
        <Breadcrumb prependHeading="Inventory" />
        <Box display="flex" margin={0} paddingY="8px">
          <EHRButton
            text="Import Products"
            color="primary"
            dataCy="importProductsButton"
            className={classes?.button}
            onClick={() => setImportDialogOpen(true)}
          />
          <EHRButton
            text="New Product"
            color="primary"
            dataCy="newProductButton"
            className={classes?.button}
            onClick={createProduct}
          />
        </Box>
        <ProductsTable editProduct={editProduct} />
        <CreateOrEditProduct open={dialogOpen} dismiss={() => setDialogOpen(false)} row={focusProduct} />
        <ImportProducts open={importDialogOpen} dismiss={() => setImportDialogOpen(false)} />
      </Container>
    </Page>
  );
};

export default withStyles({
  maxWidthLg: {
    maxWidth: 'unset',
  },
  button: {
    margin: 'auto',
    marginRight: '0',
    width: 135,
    '&:last-child': {
      marginLeft: 10,
    },
  },
})(OrderProducts);
