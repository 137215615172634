import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { IPaymentCard } from 'src/interfaces/IMedspaAdminList';
import CreditCardIcon from '../PatientProfile/CreditCards/CreditCardIcon';

export const cardOnFileStyles = makeStyles(() => ({
  paymentMethodsListItem: {
    borderRadius: '0.375rem',
    border: '1px solid #DFDFDF',
    padding: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ccBrand: {
    width: '3.5rem',
    height: '2.5rem',
    borderRadius: '0.375rem',
    border: '1px solid #DFDFDF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentMethodInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
}));

interface CreditCardProps {
  cardOnFile: IPaymentCard;
  onDelete: () => void;
  hideRemove?: boolean;
}

const CreditCardDetails: React.FC<CreditCardProps> = ({ cardOnFile, onDelete, hideRemove }) => {
  const classes = cardOnFileStyles();
  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.paymentMethodsListItem}>
        <Box className={classes.paymentMethodInfo}>
          <Box className={classes.ccBrand}>
            <CreditCardIcon cardBrand={cardOnFile?.cardBrand} noFormat />
          </Box>
          <Box>
            <Box>
              {capitalize((cardOnFile?.cardBrand || '').split('_').join(' '))} ending in {cardOnFile?.last4}
            </Box>
            <Box>
              <Typography variant="caption" style={{ color: 'rgba(0,0,0,0.5)' }}>
                Expires in {`${cardOnFile?.expMonth}/${cardOnFile?.expYear}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        {!hideRemove && (
          <Box>
            <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
              <Delete fontSize="small" onClick={onDelete} />
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CreditCardDetails;
