import { createModel } from '@rematch/core';
import { IAnnotationsState } from '../interfaces/annotation.interfaces';
import { getCharges, getPhotosData } from '../utils/annotations.utils';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import compile from '../utils/toastMessagesCompiler';

export const annotations = createModel<RootModel>()({
  state: {
    annotations: {},
    serviceNotes: {},
  } as IAnnotationsState,
  reducers: {
    setInitialLinesAndNotes(state, { annotatedPhotos, charges }) {
      /* eslint-disable @typescript-eslint/no-shadow */
      const annotations: any = {};
      const serviceNotes: any = {};
      annotatedPhotos.forEach((annotatedPhoto: any) => {
        annotations[annotatedPhoto.photo.id] = annotatedPhoto.annotationLines;
      });

      charges.forEach(({ charge }: any) => {
        serviceNotes[charge.serviceId] = charge.note;
      });

      return { ...state, annotations, serviceNotes };
    },
    updateLines(state, payload) {
      return { ...state, annotations: payload };
    },
    setNote(state, { note, serviceId }) {
      const serviceNotes = { ...state.serviceNotes };
      serviceNotes[serviceId] = note;
      return { ...state, serviceNotes };
    },
    setLines(state, { photoId, lines }) {
      const newState = { ...state };
      newState.annotations[photoId] = lines;
      return { ...newState };
    },
  },
  effects: (dispatch) => ({
    async saveAnnotations({ serviceVisitId, photos, charges }) {
      const body = { photos, charges };

      try {
        await axiosInstance.post(`/service-visits/${serviceVisitId}/photo-services-handler-new`, body);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'saving',
              element: 'annotations',
            }),
            type: 'error',
          },
        });
      }
    },
    saveNotes({ serviceVisitId }, { annotations: annotationsModel, common, newServiceVisit }) {
      const charges = getCharges(
        annotationsModel.annotations,
        newServiceVisit.totalServicesUnits,
        annotationsModel.serviceNotes
      );
      const photos = getPhotosData(annotationsModel.annotations, common.services, newServiceVisit.totalServicesUnits);

      dispatch({
        type: 'annotations/saveAnnotations',
        payload: { serviceVisitId, charges, photos },
      });
    },
    removeAnnotationsRequest(
      { serviceId, serviceVisitId },
      { annotations: annotationsModel, common, newServiceVisit }
    ) {
      try {
        const newAnnotations: any = {};

        Object.entries(annotationsModel.annotations).forEach(([photoId, lines]: any) => {
          newAnnotations[photoId] = lines.filter((line: any) => line.id !== serviceId);
        });

        const charges = getCharges(newAnnotations, newServiceVisit.totalServicesUnits, annotationsModel.serviceNotes);
        const photos = getPhotosData(newAnnotations, common.services, newServiceVisit.totalServicesUnits);

        dispatch({ type: 'annotations/updateLines', payload: newAnnotations });

        dispatch({
          type: 'annotations/saveAnnotations',
          payload: { serviceVisitId, charges, photos },
        });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'saving',
              element: 'annotations',
            }),
            type: 'error',
          },
        });
      }
    },
  }),
});
