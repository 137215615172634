import Api from './Api';
import { IPhoto } from '../interfaces/global.interfaces';

const ProgressNote = {
  async getProgressNotes({ patientId }: { patientId: number }) {
    const { progressNotes, error } = await Api.get(`/progress_notes/${patientId}`);
    if (error) {
      throw error;
    }
    return progressNotes;
  },

  async createProgressNote({
    patientId,
    text,
    photosIds,
  }: {
    patientId: number;
    text: string;
    photosIds: IPhoto['id'][];
  }) {
    const { progressNote, error } = await Api.post(`/progress_notes/${patientId}`, { text, photosIds });
    if (error) {
      throw error;
    }
    return progressNote;
  },
  async uploadProgressNotePhoto({ patientId, formData }: { patientId: number; formData: FormData }) {
    const { photos, error } = await Api.post(`/progress_notes/${patientId}/progress_notes_photos/create`, formData);
    if (error) {
      throw error;
    }
    return photos;
  },
};

export default ProgressNote;
