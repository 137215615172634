import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { ShortMultipleSkeleton } from '../../../components/common/LoadingSkeleton';
import { PatientCertificate, PatientPointCertificatesState } from '../../../types/PatientPointCertificatesState';
import { CertificatesList, CertificatesListItem } from './CertificatesList';
import { useStyles } from './modalPoints.styles';

interface PatientCertificatesListProps {
  patientCertificatesSelected: number[];
  // eslint-disable-next-line no-unused-vars
  onChange: (value: number) => void;
}

const PatientCertificatesList = (props: PatientCertificatesListProps) => {
  const { patientCertificatesSelected, onChange } = props;
  const { patientCertificates, isLoadingToggleCertificate, isLoadingPatientCertificates } = useSelector(
    ({ patientPointCertificates }: any) => patientPointCertificates as PatientPointCertificatesState
  );
  const classes = useStyles();

  return (
    <div>
      {!patientCertificates.length && !isLoadingPatientCertificates && <div>There are no certificates to apply.</div>}
      {isLoadingPatientCertificates && <ShortMultipleSkeleton length={3} />}

      <CertificatesList>
        {!isLoadingPatientCertificates &&
          patientCertificates.map((item: PatientCertificate) => (
            <CertificatesListItem
              onChange={onChange}
              value={item.id}
              disabled={isLoadingToggleCertificate}
              checked={patientCertificatesSelected.includes(item.id)}
            >
              <Box my={0} component="p">
                {item.description}
              </Box>
              <Box component="small" className={classes.listItemSubtitle}>
                {item.amount} | {item.code} | Expiration Date: {moment(item.expirationDate).format('MM/DD/YYYY')}
              </Box>
            </CertificatesListItem>
          ))}
      </CertificatesList>
    </div>
  );
};

export default PatientCertificatesList;
