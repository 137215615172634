import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  breadCrumbsFont: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '0.875rem !important',
    color: '#000',
  },
  noLink: {
    color: '#545151',
    textDecoration: 'none !important',
    cursor: 'default',
  },
  isActive: {
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  buttonNewSupplyReceipt: {
    backgroundColor: '#e7eeed',
    height: '47px',
    paddingLeft: '16px',
    paddingRight: '16px',
    fontSize: '14px',
  },
  inputSearch: {
    fontFamily: 'Messina Sans Regular',
    height: '47px',
  },
  buttonDeleteItems: {
    backgroundColor: '#fbdada',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontSize: '14px',
  },
}));
