import ROUTES from '../constants/apiRoutes.constants';
import Api from './Api';
import { IAdminMedspaUserLitePost, IAdminUser, IAdminUserPost } from '../interfaces/IAdminUser';

const BASE_PATH = '/admins';

export interface EditParams {
  userAdminInfo: IAdminUser;
}

export const AdminUser = {
  async getAdminUsers(): Promise<IAdminUser[]> {
    const { administrators } = await Api.get(`${BASE_PATH}`);
    return administrators;
  },
  async createAdminUser({
    firstName,
    lastName,
    email,
    password,
    roleId,
    userGroupId,
  }: IAdminUserPost): Promise<IAdminUserPost> {
    try {
      const { results } = await Api.post(`${BASE_PATH}`, {
        firstName,
        lastName,
        email,
        password,
        roleId,
        userGroupId,
      });
      return results;
    } catch (error) {
      const {
        response: { data },
      } = error;
      throw new Error(data.message);
    }
  },
  async createMedspaAdminUser(data: IAdminMedspaUserLitePost): Promise<any> {
    return Api.post(ROUTES.CREATE_MEDSPA_ADMIN_REGISTRATION, data);
  },
  async getAdminUserInfo(id: number): Promise<IAdminUser> {
    const { administrator, error } = await Api.get(`${BASE_PATH}/${id}`);
    if (error) {
      throw error;
    }
    return administrator;
  },
  async editAdminUser({ userAdminInfo }: EditParams): Promise<IAdminUser> {
    const { id, firstName, lastName, email, active, roleId, userGroupId } = userAdminInfo;
    const { results, error } = await Api.put(`${BASE_PATH}/${id}`, {
      id,
      firstName,
      lastName,
      email,
      active,
      roleId,
      userGroupId,
    });
    if (error) {
      throw error;
    }
    return results;
  },
};
