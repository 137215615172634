import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      '& > div#root': {
        height: '100%',
      },
    },
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#E3E3E3',
  },
  navigationContainer: {
    flex: '1',
    maxHeight: '64px',
  },
  stepsSection: {
    padding: '0 7px 0 23px',
    backgroundColor: '#e3e3e3',
  },
  stepper: {
    backgroundColor: 'transparent',
    padding: '0',
  },
  stepContainer: {
    marginTop: '30px',
  },
  stepRoot: {
    paddingTop: '17px',
    '& svg': {
      fontSize: '33px',
    },
  },
  stepContent: {
    paddingRight: '0',
    marginLeft: '16px',
    marginTop: '14px',
    borderLeft: '1px solid #979797',
  },
  card: {
    margin: '30px 15px',
    paddingBottom: '24px !important',
    padding: '24px 30px',
    background: '#F5F5F5',
    marginTop: '-68px',
    marginLeft: '10px',
    border: '5px solid transparent',
    borderRadius: '15px',
    marginBottom: '5px',
  },
}));
