import React, { useContext, useState } from 'react';
import { TableContainer, Table, TableBody, withStyles, TableHead, TableRow, Box, Button } from '@material-ui/core';
import { OrderContext } from 'src/components/DashboardPractitioner/Tabs/OrderingTab';
import useProducts from 'src/hooks/queries/useProducts';
import {
  InventoryOrderFull,
  InventoryOrderItem,
  InventoryOrderSpecial,
  ProductOrderDetails,
} from 'src/interfaces/IInventoryOrder';
import { ClassNameMap } from 'src/types/Dom';
import {
  MARKET_PRICE_TOOLTIP,
  PINNED_SUPPLIERS,
  PRICE_TOOLTIP,
  YOU_SAVE_TOOLTIP,
} from 'src/constants/inventory.constants';
import { groupBy, sortBy, sumBy } from 'lodash';
import OrderLoading from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderLoading';
import { useSpecialOffers } from 'src/hooks/queries/useSpecialOffers';
import { ISpecialOffer } from 'src/services/SpecialOffers';
import { IOrderProduct } from 'src/services/Products';
import useSuppliers from 'src/hooks/queries/useSuppliers';
import ProductSelectionSupplier from './ProductSelectionSupplier';
import { TableCellHeader, useStyle } from '../inventoryOrder.styles';
import InventorySpecialOfferRow from '../InventorySpecialOfferRow';
import OrderingTooltip from '../OrderingTooltip';

type SpecialOfferProps = {
  orderData: InventoryOrderFull | undefined;
  specials: ISpecialOffer[];
  inventoryOrderId: number;
  edit?: boolean;
};

function filterActiveSpecials(specials: InventoryOrderSpecial[]) {
  return Object.fromEntries(
    specials.map((item) => [
      item.specialOfferId,
      {
        quantity: item.quantity,
      },
    ]) ?? []
  );
}

export const SpecialOfferContainer = ({ orderData, specials, inventoryOrderId, edit }: SpecialOfferProps) => {
  const classes = useStyle();

  const orderSpecials = filterActiveSpecials(orderData?.specials ?? []);

  const specialsColumn = [
    {
      className: '',
      content: 'Product Name',
    },
    {
      className: classes?.smaller,
      content: (
        <div className={classes.toolTipContainer}>
          Market price
          <OrderingTooltip title={MARKET_PRICE_TOOLTIP} />
        </div>
      ),
    },
    {
      className: classes?.smaller,
      content: (
        <div className={classes.toolTipContainer}>
          You Save
          <OrderingTooltip title={YOU_SAVE_TOOLTIP} />
        </div>
      ),
    },
    {
      className: classes?.centerCell,
      content: (
        <div className={classes.toolTipContainer}>
          You Pay
          <OrderingTooltip title={PRICE_TOOLTIP} />
        </div>
      ),
    },
    {
      className: classes?.centerCell,
      content: 'Offer Expires on',
    },
  ];

  return (
    <TableContainer data-testid="special offer" className={classes.tableContainer}>
      <Table className={classes.innerTable}>
        <TableHead>
          <TableRow>
            <TableCellHeader className={classes.innerTableCellHeader}>
              Special Offers
              <Box fontSize={13} marginLeft={5} component="span" className={classes.chip}>
                Limited Time
              </Box>
            </TableCellHeader>
          </TableRow>
          <TableRow>
            <>
              {specialsColumn.map((cell) => (
                <TableCellHeader data-large="true" className={cell.className} style={{ textTransform: 'capitalize' }}>
                  {cell.content}
                </TableCellHeader>
              ))}
            </>
          </TableRow>
        </TableHead>

        <TableBody>
          {inventoryOrderId
            ? specials.map((special) => (
              <InventorySpecialOfferRow
                key={special.id}
                specialId={special.id}
                inventoryOrderId={inventoryOrderId}
                productNameLabel={special.productNameLabel as string}
                marketPriceLabel={special.marketPriceLabel as number}
                youSaveLabel={special.youSaveLabel as number}
                youPayLabel={special.youPayLabel as number}
                expirationDate={special.expirationDate as string}
                item={orderSpecials[special.id]}
                edit={edit}
              />
            ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ProductSelection = ({ classes }: ClassNameMap) => {
  const [showAllSuppliers, setShowAllSuppliers] = useState<boolean>(false);

  const { orderData, isLoading: isLoadingOrder } = useContext(OrderContext) || {};

  const { data: { data: products } = {}, isLoading: isLoadingProducts } = useProducts({ limit: 1000 });
  const { data: suppliers = [], isLoading: isLoadingSuppliers } = useSuppliers();
  const { data: specials, isLoading: isLoadingSpecial } = useSpecialOffers(true);

  const isLoading = isLoadingOrder || isLoadingProducts || isLoadingSpecial || isLoadingSuppliers;

  const productsBySupplier: { [supplierName: string]: IOrderProduct[] } = groupBy(products, 'supplierName');
  const filteredSuppliers = suppliers.filter((supplier) => supplier.name in productsBySupplier);

  const sortedSuppliers = sortBy(filteredSuppliers, ({ name }) => {
    const supplierIndex = PINNED_SUPPLIERS.indexOf(name);
    return supplierIndex < 0 ? name.toLowerCase().charCodeAt(0) + (PINNED_SUPPLIERS.length + 1) * 2 : supplierIndex;
  });

  const orderItems: ProductOrderDetails = Object.fromEntries(
    orderData?.services?.map((item: InventoryOrderItem) => [
      item.orderProductId,
      {
        quantity: item.quantity,
        notes: item.notes,
      },
    ]) ?? []
  );

  const inventoryOrderId = orderData?.id ? orderData.id : undefined;

  const getSupplierUsage = (supplierName: string) =>
    sumBy(productsBySupplier[supplierName], (item) => orderItems[item.id]?.quantity ?? 0);

  // TEMPORARILY HIDE SPECIALS UNTIL WE CORRECT THE ROLE-BASED PRICING
  const showSpecials = false;

  return isLoading ? (
    <OrderLoading />
  ) : (
    <>
      {showSpecials && inventoryOrderId && (
        <SpecialOfferContainer specials={specials} orderData={orderData} inventoryOrderId={inventoryOrderId} />
      )}
      {!inventoryOrderId
        ? null
        : !!products &&
          sortedSuppliers.map(
            (supplier) =>
              (showAllSuppliers ||
                PINNED_SUPPLIERS.indexOf(supplier.name) > -1 ||
                getSupplierUsage(supplier.name) > 0) && (
                <ProductSelectionSupplier
                  key={supplier.id}
                  classes={classes}
                  inventoryOrderId={inventoryOrderId}
                  supplier={supplier}
                  items={productsBySupplier[supplier.name]}
                  customItems={orderData?.customItems}
                  RowProps={{ inventoryOrderId, orderItems }}
                />
              )
          )}
      <Box className={classes?.viewMore}>
        {!!products && !showAllSuppliers && (
          <Button
            data-cy="view"
            onClick={() => setShowAllSuppliers(true)}
            variant="outlined"
            data-showmore
            className={classes?.showMoreSuppliers}
          >
            Show More Suppliers
          </Button>
        )}
      </Box>
      <Box height={100} />
    </>
  );
};

export default withStyles({
  root: {
    border: '1px solid #E1E1E1',
    backgroundColor: '#fff',
    borderRadius: '10px',
    marginBottom: '12px',
  },
  viewMore: {
    display: 'flex',
    width: '100%',
    marginBottom: '100px',
    minHeight: '121px',
  },
  showMoreSuppliers: {
    textTransform: 'none',
    fontSize: 14,
    minWidth: 132,
    height: 'auto',
    margin: 'auto',
    marginTop: '25px',
    marginBottom: '50px',
    width: '400px',
    color: '#222',
    borderColor: '#eee',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#333',
      borderColor: '#dadada',
      backgroundColor: '#f8f8f8',
    },
  },
})(ProductSelection);
