import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { BLOCK_OFF_HOURS } from 'src/constants/acuity/acuity.constants';
import { USER_TYPES } from 'src/constants/general.constants';
import { getPatientStatusData } from '../../../utils/scheduler.utils';
import { hasAccessTo } from '../../../utils/auth.utils';
import { GFE_REQUEST } from '../../../constants/actions.constants';
import { FLEX } from '../../../constants/scheduler.constants';
import { MULTIPLE_SELECTOR_OPTION_COLORS_RESOURCE } from '../Custom/MultipleSelector';

const useStyles = makeStyles(() => ({
  boxContainer: {
    height: '100%',
  },
  boxItem: {
    height: '100%',
    borderRadius: '3px',
    background: (data: any) => `${data.background}`,
    color: (data: any) => data.color,
    border: (data: any) => `solid 1px ${data?.border || data?.background}`,
    textTransform: 'capitalize',
  },
  boxItemTimeOff: {
    height: '100%',
    borderRadius: '3px',
    background: `rgb(139 139 139) !important`,
    color: '#ffff !important',
    textTransform: 'capitalize',
  },
}));

interface Props {
  data: any;
}

const AppointmentsBox = ({ data }: Props) => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const { userType } = useSelector(({ auth }: any) => auth);
  const flexStatus = data.canceled ? '' : FLEX;

  const getResourceData = () => {
    if (data.practitionerColor) {
      // Colors for Medspa Admin
      return MULTIPLE_SELECTOR_OPTION_COLORS_RESOURCE[data.practitionerColor];
    }
    return getPatientStatusData(hasAccessTo(GFE_REQUEST, permissions) ? data.statusId : flexStatus);
  };
  const resourceData = getResourceData();

  const classes = useStyles(resourceData);

  if (data.type?.includes(BLOCK_OFF_HOURS)) {
    return (
      <div className={classes.boxContainer}>
        <div className={classes.boxItemTimeOff} data-cy="appointmentCard">
          {userType === USER_TYPES.ADMINISTRATOR && <>[{data.practitionerName}] </>}
          {data.type}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.boxContainer}>
      <div className={classes.boxItem} data-cy="appointmentCard">
        {userType === USER_TYPES.ADMINISTRATOR && <>[{data.practitionerName}] </>}
        {data.patient}, ({data.type})
      </div>
    </div>
  );
};

export default AppointmentsBox;
