import Konva from 'konva';
import Annotation from '../interfaces/drawer.interfaces';

/* eslint-disable import/prefer-default-export */
const getAspectRatio = (containerSize: Annotation.Size, image: HTMLImageElement | null) => {
  const { width: ws, height: hs } = containerSize;

  if (!image) {
    return { width: ws, height: hs };
  }

  const wi = image.width;
  const hi = image.height;
  const ri = wi / hi;
  const rs = ws / hs;

  return {
    width: rs > ri ? (wi * hs) / hi : ws,
    height: rs > ri ? hs : (hi * ws) / wi,
  };
};

const getRelativePointerPosition = (node: Konva.Node) => {
  // the function will return pointer position relative to the passed node
  const transform = node.getAbsoluteTransform().copy();
  // to detect relative position we need to invert transform
  transform.invert();
  // get pointer (say mouse or touch) position
  const pos = node?.getStage()?.getPointerPosition();
  // now we find relative point
  return transform.point(pos as Konva.Vector2d);
};

const getOrientation = () => {
  if (window.innerWidth > window.innerHeight) {
    return 'landscape';
  }
  return 'portrait';
};

export { getAspectRatio, getRelativePointerPosition, getOrientation };
