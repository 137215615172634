import { useMutation } from 'react-query';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { PAYMENT_METHODS } from 'src/constants/reactQuery.keys';
import { queryClient } from 'src/initializers/queryClient';
import { MedspaAdminPaymentMethods } from 'src/services/medspaAdmins/medspaAdmin';
import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import compile from 'src/utils/toastMessagesCompiler';

export const useMedspaAdminSetDefaultCC = (userGroupId: number | undefined | null, successCallback?: () => void) =>
  useMutation((cardId: string) => MedspaAdminPaymentMethods.setDefaultCC(userGroupId, cardId), {
    onSuccess: (appointment) => {
      if (!appointment) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          element: 'Default cc',
          action: 'created',
        }),
        SNACKBAR_SUCCESS
      );

      queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf(PAYMENT_METHODS) > -1 });
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });
