import { useQuery } from 'react-query';
import { SERVICE_ACKNOWLEDGMENTS } from '../../constants/reactQuery.keys';
import ServiceAcknowledgments from '../../services/ServicesAcknowledgments';

export function useServiceAcknowledgments() {
  return useQuery([SERVICE_ACKNOWLEDGMENTS], () => ServiceAcknowledgments.getServiceAcknowledgments(), {
    refetchOnWindowFocus: false,
    staleTime: 60000,
  });
}
