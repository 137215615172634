import React from 'react';
import { useSelector } from 'react-redux';
import { TableCell, TableRow } from '@material-ui/core';
import { RootState } from 'src/rematch';
import { formatCurrency } from 'src/utils/formatNumber';
import PercentChip from 'src/components/common/PercentChip';
import { ServiceRowProps } from 'src/interfaces/IServices';
import { useRowStyles } from './Service.styles';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import ButtonAhref from '../../common/ButtonAhref';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_SERVICE } from '../../../constants/actions.constants';
import { EDIT_SERVICE } from '../../../routes/administratorRoutes';

const ServiceRow: React.FC<ServiceRowProps> = (props) => {
  const {
    id: serviceId,
    name,
    price,
    serviceGroupName,
    supplierServiceName,
    currentCost,
    currentCostWithGuaranteedRebates,
    currentCostWithAllRebates,
    legacyPrice,
    legacyPlusPrice,
    luxePrice,
    flexPrice,
    litePrice,
    listPrice,
  } = props;
  const classes = useRowStyles();
  const permissions = useSelector(({ auth }: RootState) => auth.permissions);

  const displayPrice = (value?: number): string | 0 | undefined =>
    value || value === 0 ? formatCurrency(value) : '--';

  return (
    <TableRow data-cy="row" className={classes.root}>
      <TableCellCustom>{serviceId}</TableCellCustom>
      <TableCellCustom data-cy="name">{name}</TableCellCustom>
      <TableCellCustom data-cy="serviceGroupName">{serviceGroupName}</TableCellCustom>
      <TableCellCustom data-cy="supplierServiceName">{supplierServiceName}</TableCellCustom>
      <TableCellCustom data-cy="listPrice">{displayPrice(listPrice)}</TableCellCustom>
      <TableCellCustom data-cy="currentCost">{displayPrice(currentCost)}</TableCellCustom>
      <TableCellCustom data-cy="currentCostWithGuaranteedRebates">
        {displayPrice(currentCostWithGuaranteedRebates)}
      </TableCellCustom>
      <TableCellCustom data-cy="currentCostWithAllRebates">{displayPrice(currentCostWithAllRebates)}</TableCellCustom>
      <TableCellCustom data-cy="price">{displayPrice(price)}</TableCellCustom>
      <TableCellCustom data-cy="legacyPrice">
        {displayPrice(legacyPrice)}
        <PercentChip dividend={legacyPrice} divisor={listPrice} />
      </TableCellCustom>
      <TableCellCustom data-cy="legacyPlusPrice">
        {displayPrice(legacyPlusPrice)}
        <PercentChip dividend={legacyPlusPrice} divisor={listPrice} />
      </TableCellCustom>
      <TableCellCustom data-cy="luxePrice">
        {displayPrice(luxePrice)}
        <PercentChip dividend={luxePrice} divisor={listPrice} />
      </TableCellCustom>
      <TableCellCustom data-cy="flexPrice">
        {displayPrice(flexPrice)}
        <PercentChip dividend={flexPrice} divisor={listPrice} />
      </TableCellCustom>
      <TableCellCustom data-cy="litePrice">
        {displayPrice(litePrice)}
        <PercentChip dividend={litePrice} divisor={listPrice} />
      </TableCellCustom>
      <TableCell>
        {hasAccessTo(CREATE_SERVICE, permissions) && <ButtonAhref text="EDIT" href={EDIT_SERVICE(String(serviceId))} />}
      </TableCell>
    </TableRow>
  );
};

export default ServiceRow;
