import * as yup from 'yup';
import { VALID_EMAIL, PHONE, VALID_PHONE, FEE_AMOUNT, LICENSE } from '../../../constants/schemaForm.constants';

const VALID_PHONE_REGEX = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const schemaForm = yup.object().shape({
  email: yup.string().email(VALID_EMAIL),
  phone: yup.string().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
  signatureImage: yup.string(),
  profileImage: yup.string(),
  medicalDirectorId: yup.string(),
  licenses: yup.string().required(LICENSE),
  cancelationFee: yup.string().test('validation', FEE_AMOUNT, function validateCancellationFee(value) {
    const { requestCreditCard } = this.parent;
    return !(requestCreditCard && !value);
  }),
  depositFee: yup.string().test('validation', FEE_AMOUNT, function validateDepositFee(value) {
    const { requestDeposit } = this.parent;
    return !(requestDeposit && !value);
  }),
  requestCreditCard: yup.boolean(),
  requestDeposit: yup.boolean(),
  squareLocationId: yup.string(),
});
