import { createModel } from '@rematch/core';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import compile from '../utils/toastMessagesCompiler';

const INITIAL_STATE = {
  serviceVisits: [],
};

export const fullServiceHistory = createModel<RootModel>()({
  state: INITIAL_STATE,
  reducers: {
    setServiceVisits(state: any, payload: any) {
      return { ...state, serviceVisits: payload };
    },
    setResetFullServiceVisits() {
      return { ...INITIAL_STATE };
    },
  },
  effects: (dispatch: any) => ({
    async getFullServiceHistory(payload: { patientId: number }) {
      try {
        const { patientId } = payload;
        const request = await axiosInstance.get(
          `/service-visits/${patientId}/full-service-history?visit_type=in_person`
        );
        const {
          data: { serviceVisits },
        } = request;
        dispatch.fullServiceHistory.setServiceVisits(serviceVisits);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'the full service history',
            }),
            type: 'error',
          },
        });
      }
    },
  }),
});
