import { CREATE_MEDSPA_ADMIN_INVITATION, VALID_MEDSPA_ADMIN_INVITATION } from 'src/constants/apiRoutes.constants';
import Api from '../Api';

export const MedspaAdminInvitations = {
  async createMedspaAdminInvitation(email: string) {
    return Api.post(CREATE_MEDSPA_ADMIN_INVITATION, { email });
  },
  async validMedspaAdminInvitation(code: string) {
    return Api.get(VALID_MEDSPA_ADMIN_INVITATION, { token: code });
  },
};
