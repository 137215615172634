import { makeStyles, createStyles, Select, withStyles, TableRow } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    padding: '1rem',
  },
  area: {
    margin: '3rem 0',
  },
  flexArea: {
    margin: '1rem 0 3rem',
  },
  title: {
    color: '#12574d',
    padding: '1rem 0',
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '18px',
  },
  collapseContainer: {
    padding: '1rem',
    backgroundColor: 'rgb(231, 238, 237)',
    borderRadius: '8px',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexTransactions: {
    marginTop: '1rem',
  },
  statusSelection: {
    height: '36px',
  },
  formControl: {
    width: '180px',
    marginRight: '1rem',
  },
  flexTransactionsDate: {
    background: 'white',
    '&> div > input': {
      padding: '0.7rem',
    },
    '&> label': {
      transform: 'translate(14px, 13px) scale(1)',
    },
  },
  processed: {
    color: '#005A4E',
    background: '#E6F9DA',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
  },
  unprocessed: {
    color: '#704822',
    background: '#F8DCC1',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
  },
  transactionRow: {
    cursor: 'pointer',
  },
}));

export const SelectCustom = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white !important',
      borderRadius: 0,
      color: '#000',
      fontSize: '15px',
      fontFamily: 'Messina Sans Regular',
    },
    select: {
      color: '#000',
    },
  })
)(Select);

export const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      border: '0 !important',
    },
  },
  bold: {
    fontWeight: 600,
  },
  menuIcon: {
    border: '1px solid #E4E7EB',
    borderRadius: '2px',
  },
  backgroundRow: {
    backgroundColor: '#e7eeed',
    '&>td': {
      fontWeight: 600,
    },
  },
  rowStyle: {
    color: 'red',
  },
  dummyCols: {
    [theme.breakpoints.down('md')]: {
      width: '10%',
    },
    [theme.breakpoints.up('md')]: {
      width: '15%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '20%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '35%',
    },
  },
  belowPriceFloorInfo: {
    position: 'relative',
    top: '7px',
    width: '20px',
    cursor: 'pointer',
    color: 'gray',
  },
  warning: {
    position: 'relative',
    top: '7px',
  },
}));

export const TableRowStriped = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f5f5',
    },
  },
}))(TableRow);

export const tableStyles = makeStyles(() => ({
  headercell: {
    fontSize: '18px',
    maxWidth: '130px',
    backgroundColor: '#b0e0e68a',
    '&[data-highlighted="true"]': {
      border: '1px solid',
      borderTop: '6px solid',
      backgroundColor: '#03728f22',
      borderBottom: '0px solid',
      borderLeftColor: '#03728f',
      borderRightColor: '#03728f',
      borderTopColor: '#03728f',
    },
  },
  rowcell: {
    fontSize: '15px',
    maxWidth: '160px',
    backgroundColor: '#b0e0e62d',
    fontVariantNumeric: 'diagonal-fractions',
  },
  bodycell: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '8px',
    paddingRight: '8px',
    height: '19px',
    fontSize: '15px',
    maxWidth: '130px',
    '&:nth-child(1)': {
      fontSize: '15px',
    },
    '&[data-highlighted="true"]': {
      border: '1px solid #03728f',
      borderTop: 'none',
      borderBottomColor: '#03728f33',
      backgroundColor: '#03728f22',
    },
  },
  mainTable: {
    margin: '25px 0px',
  },
  mainBox: {
    padding: '15px',
  },
  description: {
    fontSize: 14,
  },
  footnote: {
    fontSize: 12,
    margin: '15px 0',
  },
  descriptionContainer: {
    padding: 8,
    marginBottom: '12px',
  },
  blockerPaper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    width: '100%',
    minHeight: '191px',
    padding: '13px 8px 10px 8px',
  },
  blockerBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0',
    width: '100%',
    padding: '15px 0',
  },
  blockerHead: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    paddingTop: '13px',
    marginTop: '10px',
    width: '100%',
    borderTop: '1px solid #0002',
  },
  blockerTitle: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    marginBottom: '6px',
    fontSize: '1.6rem',
  },
  blockerSubtitle: {
    fontSize: '0.875rem',
  },
  blockerItem: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '32%',
    '& *': {
      margin: 'auto',
      textAlign: 'center',
    },
    '&:nth-child(1)': {
      borderRight: '1px solid #0002',
    },
    '&:nth-child(2)': {
      borderRight: '1px solid #0002',
    },
    '&:nth-child(4)': {
      borderLeft: '1px solid #0002',
    },
    '& > h6': {
      fontSize: '1rem',
    },
  },
  skeletonItem: {
    '& > *': {
      margin: 'auto',
      width: '60%',
      textAlign: 'center',
    },
  },
  completeIcon: {
    color: '#009900',
    borderRadius: '50%',
    border: '1px solid',
    fontSize: '31px',
    padding: '6px',
    margin: '10px auto',
  },
  blockedIcon: {
    color: '#cc0000',
    borderRadius: '50%',
    border: 'none',
    fontSize: '31px',
    margin: '10px auto',
  },
  blockerProgress: {
    margin: 'auto -10px -10px -10px',
  },
  skeletonBox: {
    margin: 'auto',
  },
  bigBar: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    paddingTop: '18px',
    marginTop: '10px',
    width: '100%',
    borderTop: '1px solid #0002',
    '& span': {
      width: '75%',
      margin: 'auto',
    },
  },
  subscript: {
    fontVariantNumeric: 'diagonal-fractions',
  },
}));
