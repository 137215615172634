import { Box, Button } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useStyles } from './FutureCredits.styles';
import { IAppliedServiceCredit, IServiceCredit } from '../../types/ServiceCredits';
import { FutureCreditModal, ModalData } from '../../pages/Checkout/FutureCredits/FutureCreditModal';
import { useApplyServiceVisitCredits } from '../../hooks/serviceVisit/useServiceVisitCredits';
import IServices from '../../interfaces/IServices';
import { formatNumber } from '../../utils/checkout.utils';

const INITIAL_MODEL_DATA = {
  id: 0,
  serviceName: '',
  units: 0,
  serviceId: 0,
  variantItemId: 0,
  maxUnits: 1,
  balance: 0,
};

const FutureCredits = ({
  servicesIdMap,
  serviceCredits = [],
  appliedServiceCredits = [],
  serviceVisitId,
  serviceToOverriddenPriceMap,
  serviceCounts,
  isCheckoutPaid,
  refetchAppliedServiceCredits,
  resetCredits,
  setResetCredits,
}: {
  serviceCredits: IServiceCredit[];
  appliedServiceCredits: IAppliedServiceCredit[];
  serviceVisitId: number;
  servicesIdMap: Record<number, IServices>;
  serviceCounts: Record<number, number>;
  isCheckoutPaid: boolean;
  serviceToOverriddenPriceMap: Record<number, number>;
  refetchAppliedServiceCredits: () => void;
  resetCredits: boolean;
  setResetCredits: (v: boolean) => void;
}) => {
  const classes = useStyles();
  const [usedServiceCredits, setUsedServiceCredits] = useState<IAppliedServiceCredit[]>(appliedServiceCredits);
  const [openFutureUseModal, setOpenFutureUseModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalData>({ ...INITIAL_MODEL_DATA });

  const { mutateAsync: applyServiceCredit } = useApplyServiceVisitCredits(+serviceVisitId);

  useEffect(() => {
    if (resetCredits) {
      setUsedServiceCredits([]);
      setResetCredits(false);
    }
  }, [resetCredits]);

  useEffect(() => {
    if (appliedServiceCredits) {
      setUsedServiceCredits(appliedServiceCredits);
    }
  }, [appliedServiceCredits]);

  const openModal = (serviceName: string, serviceId: number, id: number, balance: number): void => {
    const units = appliedServiceCreditMap[id] ? appliedServiceCreditMap[id]?.value : 1;
    const maxUnits = serviceCounts[serviceId] < balance ? serviceCounts[serviceId] : balance;
    setModalData({ serviceName, units, id, maxUnits, balance, serviceId });
    setOpenFutureUseModal(true);
  };

  const appliedServiceCreditMap: Record<number, IAppliedServiceCredit> = useMemo(
    () =>
      usedServiceCredits.reduce(
        (obj, appliedServiceCredit) =>
          Object.assign(obj, {
            [appliedServiceCredit.serviceCreditId]: appliedServiceCredit,
          }),
        {}
      ) || {},
    [usedServiceCredits]
  );

  const getAppliedCreditsLabel = (serviceCreditId: number, serviceId: number) => {
    const appliedServiceCredit = appliedServiceCreditMap[serviceCreditId];
    const service = servicesIdMap[serviceId];
    if (appliedServiceCredit && service) {
      const used = appliedServiceCreditMap[serviceCreditId].value;
      const price = serviceToOverriddenPriceMap[serviceId] || service.price;
      const total = formatNumber(used * price);
      return `(Applied ${used}) - $${total}`;
    }
    return '';
  };

  const handleApplyServiceCredits = async (units: number) => {
    await applyServiceCredit({
      serviceCreditId: modalData.id,
      value: units,
    });
    await refetchAppliedServiceCredits();
  };

  const handleRemoveServiceCredits = async () => {
    await applyServiceCredit({
      serviceCreditId: modalData.id,
      value: 0,
    });
    await refetchAppliedServiceCredits();
  };

  return (
    <>
      <div className={classes.mainSection}>
        <div className={classes.sectionTitle}>Future Use Credits</div>
        <div className={classes.contentSection}>
          {serviceCredits.map(({ serviceName, id, balance, serviceId }: IServiceCredit) => (
            <Box key={id} display="flex" alignItems="center" mb={2} flexDirection="row">
              <Box width="23%" className={classes.service}>
                {serviceName}
              </Box>
              <Box width="17%" className={classes.balance}>
                {appliedServiceCreditMap[id] && !isCheckoutPaid ? balance - appliedServiceCreditMap[id].value : balance}
              </Box>
              <Box width="25%">
                <Button
                  disableElevation
                  className={classes.buttonApply}
                  onClick={() => {
                    openModal(serviceName, serviceId, id, balance);
                  }}
                >
                  {appliedServiceCreditMap[id] ? 'Edit credits' : 'Apply credits'}
                </Button>
              </Box>
              <Box width="35%" textAlign="right">
                {getAppliedCreditsLabel(id, serviceId)}
              </Box>
            </Box>
          ))}
        </div>
      </div>
      <FutureCreditModal
        open={openFutureUseModal}
        data={modalData}
        showRemoveButton={Boolean(appliedServiceCreditMap[modalData.id])}
        handleClose={() => {
          setOpenFutureUseModal(false);
        }}
        handleConfirm={handleApplyServiceCredits}
        removeCredit={handleRemoveServiceCredits}
      />
    </>
  );
};

export default FutureCredits;
