/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '20px',
    color: '#000000',
    fontWeight: 'bold',
  },
  textModal: {
    fontSize: '14px',
    color: '#3a3b3f',
    lineHeight: '20px',
  },
  dialog: {
    borderRadius: '10px',
  },
  skipButton: {
    color: '#000000',
    border: 'solid 1px #C0C0C0',
    height: '36px',
    padding: '5px 20px',
    margin: '0 auto',
    marginLeft: '0',
  },
  modalContainer: {
    display: 'flex',
    backgroundColor: '#ffffff',
    flexDirection: 'column',
    width: '100%',
  },
  confirmButton: {
    color: '#ffffff',
    backgroundColor: '#12574d',
    height: '36px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#104e45',
    },
    padding: '5px 20px',
    margin: '0 auto',
    marginRight: '0',
  },
  container: {
    width: '540px',
    padding: '30px',
  },
  footerButtons: {
    display: 'flex',
    width: '100%',
    bottom: '20px',
  },
  circularProgress: {
    margin: '0 0 10px 0',
  },
}));

interface Props {
  openModal: boolean;
  closeModal?: () => void;
  confirmModal?: () => void;
  confirmTextModal?: string;
  skipModal?: boolean;
  isLoading?: boolean;
  titleModal?: string;
  modalTexts: string[];
  style?: React.CSSProperties;
  className?: string;
}

const DialogModal = ({
  openModal,
  closeModal,
  confirmModal,
  confirmTextModal,
  skipModal,
  isLoading,
  titleModal,
  modalTexts,
  style,
  className,
}: Props) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(0);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const isLastPage = currentPage === modalTexts.length - 1;

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      className={className}
      classes={{ paper: classes.dialog }}
      style={style}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        className={classes.container}
      >
        <Box>
          <div className={classes.modalContainer}>
            <Box display="flex" flexDirection="column" width="100%" margin="0 0 10px 0">
              {isLoading && <CircularProgress className={classes.circularProgress} size={25} />}
              <Box className={classes.title}>{titleModal}</Box>
              {modalTexts.length > 0 && (
                <Box key={modalTexts[currentPage]} className={classes.textModal}>
                  {modalTexts[currentPage]}
                </Box>
              )}
            </Box>
            <Box className={classes.footerButtons}>
              {skipModal && (
                <Button className={classes.skipButton} onClick={closeModal}>
                  Skip
                </Button>
              )}
              {modalTexts.length > 1 && (
                <Box>
                  <span
                    style={{
                      alignSelf: 'center',
                      margin: '5px 15px',
                      fontSize: '14px',
                    }}
                  >
                    {`${currentPage + 1}/${modalTexts.length}`}
                  </span>
                  <Button className={classes.confirmButton} onClick={isLastPage ? confirmModal : handleNextPage}>
                    {isLastPage ? confirmTextModal : 'Next'}
                  </Button>
                </Box>
              )}
              {!isLoading && modalTexts.length <= 1 && (
                <Button className={classes.confirmButton} onClick={confirmModal}>
                  {confirmTextModal}
                </Button>
              )}
            </Box>
          </div>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogModal;
