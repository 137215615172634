import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, TextField } from '@material-ui/core';
import { useStyles } from './modalPoints.styles';
import { dispatch } from '../../../rematch';
import { ShortMultipleSkeleton } from '../../../components/common/LoadingSkeleton';
import compile from '../../../utils/toastMessagesCompiler';

interface Props {
  reloadPointsData: () => void;
  configureTreatment: (showError?: boolean) => void;
}

const CertificateConfirmationCode = (props: Props) => {
  const { reloadPointsData, configureTreatment } = props;
  const classes = useStyles();
  const [sixDigitCode, setSixDigitCode] = useState('');
  const [isLoadingRedeemPoints, setIsLoadingRedeemPoints] = useState(false);
  const {
    patientPointCertificates: { patientPointsToRedeem, patientSMSCodeSent },
    patient: { id: patientId, firstName, lastName },
  } = useSelector(({ patient, patientPointCertificates }: any) => ({ patient, patientPointCertificates }));

  const handleSendCode = () => {
    dispatch({ type: 'patientPointCertificates/sendPatientSmsPointsCode', payload: patientId });
  };

  const successPatientPointsRedeemed = () => {
    setSixDigitCode('');
    reloadPointsData();
    configureTreatment(true);
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('checkout.galderma_points_redeemed'),
      },
    });
    setIsLoadingRedeemPoints(false);
  };

  const onConfirm = () => {
    setIsLoadingRedeemPoints(true);
    dispatch({
      type: 'patientPointCertificates/redeemPatientPointsToCertificates',
      payload: {
        patientId,
        sixDigitCode,
        points: patientPointsToRedeem,
        callback: successPatientPointsRedeemed,
        errorCallback: () => setIsLoadingRedeemPoints(false),
      },
    });
  };

  const handleOnChangeSixDigitCode = (event: React.ChangeEvent<{ value: string }>) => {
    setSixDigitCode(event.target.value);
  };

  return (
    <Box>
      {isLoadingRedeemPoints && <ShortMultipleSkeleton length={2} />}
      {patientPointsToRedeem > 0 && (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
            <Box width="30%" className={classes.confirmationCodeText}>
              Confirmation code
            </Box>
            <Box width="35%">
              <TextField
                value={sixDigitCode}
                variant="outlined"
                placeholder="Six-digit code"
                onChange={handleOnChangeSixDigitCode}
              />
            </Box>
            <Box width="25%">
              {!patientSMSCodeSent && (
                <Button className={classes.codeButton} onClick={handleSendCode}>
                  Send code
                </Button>
              )}
              {patientSMSCodeSent && !isLoadingRedeemPoints && (
                <Button className={classes.codeButton} onClick={onConfirm}>
                  Redeem
                </Button>
              )}
            </Box>
          </Box>
          <Box mt={1}>
            <i>
              By entering the confirmation code, {firstName} {lastName} will redeem {patientPointsToRedeem} points.
            </i>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CertificateConfirmationCode;
