/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import { createModel } from '@rematch/core';
import {
  NewSupplyReceiptState,
  SypplyReceiptParams,
  RemoveDraftPayload,
  RemoveSupplyReceiptAssetPayload,
} from '../types/NewSupplyReceiptState';
import { RootModel } from './rootModel';
import axiosInstance from '../utils/axios';
import { handleAssetSelected } from '../utils/handleAssetsSelected';
import compile from '../utils/toastMessagesCompiler';

const handleError = (dispatch: any, msg: string) => {
  dispatch({
    type: 'snackbar/enqueueSnackBar',
    payload: {
      message: msg,
      type: 'error',
    },
  });
};

export const newSupplyReceipt = createModel<RootModel>()({
  state: {
    services: [],
    assets: [],
    productsSelected: [],
    warehouses: [],
    supplyReceipt: {
      status: 'draft',
      fromWarehouseId: '',
      toWarehouseId: '',
      internalNote: '',
      practitionerId: '',
      forceSignature: true,
    },
    isLoading: false,
    serverError: '',
    isLoadingUpdateSupplyReceipt: false,
  } as NewSupplyReceiptState,
  reducers: {
    setForceSignature(state: NewSupplyReceiptState) {
      return {
        ...state,
        supplyReceipt: { ...state.supplyReceipt, forceSignature: !state.supplyReceipt.forceSignature },
      };
    },
    setWarehouses(state: NewSupplyReceiptState, payload: object[]) {
      return { ...state, warehouses: payload };
    },
    setServices(state: NewSupplyReceiptState, payload: object[]) {
      return { ...state, services: payload };
    },
    setAssets(state: NewSupplyReceiptState, payload: object[]) {
      return { ...state, assets: payload };
    },
    setFromWarehouse(state: NewSupplyReceiptState, payload: number) {
      return { ...state, supplyReceipt: { ...state.supplyReceipt, fromWarehouseId: payload } };
    },
    setToWarehouse(state: NewSupplyReceiptState, payload: any) {
      return {
        ...state,
        supplyReceipt: { ...payload },
      };
    },
    setSupplyReceipt(state: NewSupplyReceiptState, payload: object) {
      const supplyReceipt: any = payload || state.supplyReceipt;
      const warehouse: any = state.warehouses.find(({ id }: any) => id == supplyReceipt.toWarehouseId) || {};

      return { ...state, supplyReceipt };
    },
    setServerError(state: any, payload: string) {
      return { ...state, serverError: payload };
    },
    setProducts(state: any, payload: object) {
      return { ...state, productsSelected: payload };
    },
    setIsLoadingUpdateSupplyReceipt(state: any, payload: boolean) {
      return { ...state, isLoadingUpdateSupplyReceipt: payload };
    },
    setIsLoading(state: any, payload: boolean) {
      return { ...state, isLoading: payload };
    },
  },
  effects: (dispatch) => ({
    async updateSupplyReceipt(query: SypplyReceiptParams) {
      try {
        const isDraft = !query.setStatus;

        if (!isDraft && (!query.supplyReceipt.fromWarehouseId || !query.supplyReceipt.toWarehouseId)) {
          const message = compile('new_supply_receipt.invalid_form_create');
          handleError(dispatch, message);
          return;
        }

        const params = {
          supplyReceipt: query.supplyReceipt,
          assets: query.assets,
          isDraft: isDraft && query.supplyReceipt.status === 'draft',
        };

        let supplyReceiptForm = query.supplyReceipt;
        dispatch.newSupplyReceipt.setIsLoadingUpdateSupplyReceipt(true);

        if (query.supplyReceipt.id) {
          const { data } = await axiosInstance.put(`supply-receipts/${query.supplyReceipt.id}`, params);
          supplyReceiptForm = data.supplyReceipt;
          if (data.error && !isDraft) {
            throw new Error();
          }
        } else {
          const { data } = await axiosInstance.post('supply-receipts', params);
          supplyReceiptForm = data.supplyReceipt;
        }

        dispatch.newSupplyReceipt.setSupplyReceipt(supplyReceiptForm);

        // @ts-ignore
        if (query.serviceId) {
          query.onSaveCallback(query.serviceId, query.supplyReceipt.id);
        } else if (query.onSaveCallback) {
          query.onSaveCallback();
        }
      } catch (error) {
        const message = compile('generic.error_message', {
          action: 'updating',
          element: 'the supply receipt',
        });
        handleError(dispatch, message);
      } finally {
        dispatch.newSupplyReceipt.setIsLoadingUpdateSupplyReceipt(false);
      }
    },
    async removeSupplyReceiptAssets(query: RemoveSupplyReceiptAssetPayload) {
      try {
        const params = { assets: query.assets };
        await axiosInstance.put(`supply-receipts/${query.supplyReceiptId}/remove-assets`, params);

        if (query.onSuccess) {
          query.onSuccess();
        } else {
          dispatch({ type: 'newSupplyReceipt/getSupplyReceipt', payload: query.supplyReceiptId });
        }
      } catch (error) {
        const message = compile('generic.error_message', {
          action: 'updating',
          element: 'the supply receipt',
        });
        handleError(dispatch, message);
      }
    },
    async getSupplyReceipt(supplyReceiptId: string) {
      try {
        dispatch.newSupplyReceipt.setIsLoading(true);

        const {
          data: { warehouses },
        } = await axiosInstance.get('warehouses');
        dispatch.newSupplyReceipt.setWarehouses(warehouses);

        const {
          data: { services },
        } = await axiosInstance.get('services');
        dispatch.newSupplyReceipt.setServices(services);

        if (supplyReceiptId) {
          const {
            data: { supplyReceipt, assetsSelected },
          } = await axiosInstance.get(`supply-receipts/${supplyReceiptId}`);

          dispatch.newSupplyReceipt.setSupplyReceipt(supplyReceipt);

          let productsSelected: any = [];
          assetsSelected.forEach((asset: any) => {
            productsSelected = handleAssetSelected({ asset, services, productsSelected });
          });

          dispatch.newSupplyReceipt.setProducts(productsSelected);
        } else {
          dispatch.newSupplyReceipt.setSupplyReceipt(newSupplyReceipt.state.supplyReceipt);
          dispatch.newSupplyReceipt.setProducts([]);
        }

        dispatch.newSupplyReceipt.setIsLoading(false);
      } catch (error) {
        const message = compile('generic.server_error');
        handleError(dispatch, message);
      }
    },
    async removeDraft(payload: RemoveDraftPayload) {
      try {
        await axiosInstance.delete(`supply-receipts/${payload.supplyReceiptId}`);
        payload.onSuccess();
      } catch (error) {
        const message = compile('generic.error_message', {
          action: 'deleting',
          element: 'the draft',
        });
        handleError(dispatch, message);
      }
    },
  }),
});
