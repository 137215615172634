/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient, useMutation } from 'react-query';
import { Box } from '@material-ui/core';
import ButtonAhref from '../../common/ButtonAhref';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_LOCATION } from '../../../constants/actions.constants';
import { Locations } from '../../../services/Locations';
import { LOCATIONS } from '../../../constants/reactQuery.keys';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';

const LocationHeader: FC = () => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const queryClient = useQueryClient();
  const mutationCreate = useMutation(Locations.populateLocations);
  const loading = mutationCreate.isLoading;

  const populateLocations = async () => {
    const { newRecords, errors } = await mutationCreate.mutateAsync();
    queryClient.invalidateQueries([LOCATIONS]);

    const sucessMessage =
      newRecords > 0
        ? compile('locations.new_locations_imported', { count: newRecords })
        : compile('locations.no_new_locations_imported');

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: sucessMessage,
        type: 'success',
        duration: 3000,
      },
    });

    if (errors.length > 0) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: (
            <div>
              {errors.map((e: String) => (
                <div>{e}</div>
              ))}
            </div>
          ),
          type: 'warning',
          duration: 3000,
        },
      });
    }
  };

  return (
    <Box padding={2}>
      <Box display="flex" justifyContent="flex-end">
        <Box>
          {hasAccessTo(CREATE_LOCATION, permissions) && (
            <ButtonAhref
              buttonStyle="big"
              text="IMPORT NEW LOCATIONS FROM ACUITY"
              onClick={() => populateLocations()}
              marginBtn="0 0 0 16px"
              loading={loading}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LocationHeader;
