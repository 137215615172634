import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  cardContentPhoto: {
    paddingBottom: '5px',
  },
  photo: {
    maxHeight: navigator.userAgent.match('CriOS') ? '75vh' : '80vh',
    maxWidth: '80vw',
  },
  cardContentButtons: {
    paddingTop: '0',
    paddingBottom: '10px',
  },
  closeButton: {
    color: '#12574d',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
  },
  ViewOriginalButton: {
    color: '#ffffff',
    backgroundColor: '#12574d',
  },
}));
