/* eslint-disable no-restricted-syntax */
import { useHistory, useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  const updateQueryParams = (newQueryParams: object) => {
    const searchParams = new URLSearchParams(location.search);

    Object.entries(newQueryParams).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    const newSearch = searchParams.toString();

    history.replace({
      pathname: location.pathname,
      search: newSearch ? `?${newSearch}` : '',
    });
  };

  const getAllParamsStartingWith = (predicate: string): { [key: string]: string } => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamsKeys = [...new Set(searchParams.keys())];
    const searchParamsKeysStartingWith = searchParamsKeys.filter((key) => key.startsWith(predicate));

    return searchParamsKeysStartingWith.reduce((acc: any, key) => {
      acc[key] = searchParams.getAll(key).join(',');
      return acc;
    }, {});
  };

  return {
    updateQueryParams,
    getAllParamsStartingWith,
    queryParams: new URLSearchParams(location.search),
  };
};

export default useQueryParams;
