import React, { useMemo } from 'react';
import { sortBy } from 'lodash';
import { Grid } from '@material-ui/core';
import IServiceGroup from '../../interfaces/IServiceGroup';
import ServiceGroupButton from './ServiceGroupButton';
import IServices from '../../interfaces/IServices';

interface ServiceGroupsListProps {
  serviceGroups: IServiceGroup[];
  services: IServices[];
  onServiceGroupSelect: (serviceGroupId: number) => void;
  selectedServiceGroup: number;
  selectedServices: number[];
  hideZeroServices?: boolean;
}

export default function ServiceGroupsList({
  serviceGroups,
  services,
  onServiceGroupSelect,
  selectedServiceGroup,
  selectedServices,
  hideZeroServices = true,
}: ServiceGroupsListProps) {
  const handleServiceGroupClick = (serviceGroupId: number) => {
    onServiceGroupSelect(serviceGroupId);
  };

  const groupsWithServices = useMemo(() => services.reduce((obj: number[], service) => {
    if (!obj.includes(service.serviceGroupId)) {
      obj.push(service.serviceGroupId);
    }
    return obj;
  }, []), [services]);

  return (
    <Grid container spacing={2}>
      {sortBy(serviceGroups, ['name'])
        .filter(({ useDynamicPricing, id }: IServiceGroup) => {
          if (hideZeroServices) {
            return !useDynamicPricing && groupsWithServices.includes(id);
          }
          return !useDynamicPricing;
        })
        .map(({ id, name }: IServiceGroup) => {
          const amountOfSelectedServices = services.filter(
            ({ id: serviceId, serviceGroupId }: IServices) =>
              serviceGroupId === id && selectedServices.includes(serviceId)
          ).length;

          return (
            <Grid item xs={12} key={id}>
              <ServiceGroupButton
                id={id}
                name={name}
                selected={selectedServiceGroup === id}
                amount={amountOfSelectedServices}
                onClick={handleServiceGroupClick}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}
