import React, { useState } from 'react';
import { Button, CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { ORDER_STATUS_DELIVERED, ORDER_STATUS_REQUESTED } from 'src/constants/inventory.constants';
import { assetsByPurchaseOrderItem } from 'src/utils/inventory.utils';
import { orderHasShipped } from 'src/utils/inventoryOrdering';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/rematch';
import { IAsset, IDashboardItem } from 'src/interfaces/reconciliation.interfaces';
import { downloadAssetPdf, getAssetsPages } from 'src/utils/datamatrix';
import { GetApp } from '@material-ui/icons';
import { formatCurrency } from 'src/utils/formatNumber';
import { USER_TYPES } from 'src/constants/general.constants';
import Asset from 'src/services/Asset';
import compile from 'src/utils/toastMessagesCompiler';
import { ServiceSubrowParams } from '../../../../../interfaces/IInventoryOrder';
import { useStyle } from '../inventoryOrder.styles';

const OrderHistorySubRow: React.FC<ServiceSubrowParams> = ({
  name,
  quantity,
  status,
  deliveredAt,
  inventoryOrderId,
  serviceId,
  supplierName,
  purchaseOrderItemId,
  confirmed,
  currentTotal,
  orderMultiplier,
  unitPrice,
  pricePerBox,
  startingUnits,
  allowPartialSale,
  setDialogParams,
  showDeliveryConfirmation,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { userType } = useSelector(({ auth }: RootState) => ({ ...auth }));
  const { dashboardList, isLoadingInventory } = useSelector(({ inventoryDefault }: RootState) => ({
    ...inventoryDefault,
  }));

  const isAdmin = userType === USER_TYPES.ADMINISTRATOR;
  const itemStatus = deliveredAt ? ORDER_STATUS_DELIVERED : status ?? ORDER_STATUS_REQUESTED;

  const handleConfirm = () => {
    inventoryOrderId &&
      setDialogParams?.({
        quantity,
        supplierName,
        name,
        startingUnits,
        unitPrice,
        orderMultiplier,
        purchaseOrderItemId: purchaseOrderItemId ?? 0,
      });
  };

  const fetchAssets = async () => {
    if (purchaseOrderItemId) {
      try {
        return !isAdmin
          ? assetsByPurchaseOrderItem(dashboardList as IDashboardItem[], purchaseOrderItemId)
          : await Asset.byPurchaseOrderItem(purchaseOrderItemId);
      } catch {
        throw new Error('fetching');
      }
    } else {
      throw new Error('purchase order item not found fetching');
    }
  };

  const createAssetPages = (assets: IAsset[]) => {
    try {
      return getAssetsPages(assets);
    } catch {
      throw new Error('creating');
    }
  };

  const downloadLabels = async () => {
    if (purchaseOrderItemId) {
      setIsLoading(true);

      try {
        const assets = await fetchAssets();
        if (!assets?.length) {
          throw new Error('no assets found fetching');
        }
        const assetPages = createAssetPages(assets);
        if (!assetPages?.length) {
          throw new Error('no assets found creating');
        }
        try {
          await downloadAssetPdf(assetPages, assets[0].lot, () => {
            setIsLoading(false);
          });
        } catch {
          throw new Error('downloading');
        }
      } catch (error: unknown) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: (error as Error)?.message || 'processing',
              element: 'asset labels',
            }),
            type: 'error',
          },
        });

        setIsLoading(false);
      }
    }
  };

  const classes = useStyle();

  const labelsButtonContent =
    isLoading || isLoadingInventory ? (
      <CircularProgress
        data-testid={`orderHistory.${inventoryOrderId}.${serviceId}.labels.load`}
        className={classes.cprogress}
        size={20}
      />
    ) : (
      'Labels'
    );

  const button = confirmed ? (
    <Button
      startIcon={!isLoading ? <GetApp /> : null}
      className={classes.getLabelsButton}
      onClick={downloadLabels}
      disabled={isLoading || isLoadingInventory}
      data-testid={`orderHistory.${inventoryOrderId}.${serviceId}.labels`}
    >
      {labelsButtonContent}
    </Button>
  ) : (
    <Button
      className={classes.confirmOrderButton}
      onClick={handleConfirm}
      data-testid={`orderHistory.${inventoryOrderId}.${serviceId}.confirm`}
    >
      Confirm
    </Button>
  );

  return (
    <TableRow data-testid={`orderHistory.${inventoryOrderId}.${serviceId}`}>
      <TableCell className={classes.approvalRowName} data-testid={`orderHistory.${inventoryOrderId}.${serviceId}.name`}>
        {name}
      </TableCell>
      <TableCell>{currentTotal}</TableCell>
      <TableCell>{orderMultiplier}</TableCell>
      <TableCell>{Math.floor(quantity / (orderMultiplier || 1))}</TableCell>
      <TableCell>{pricePerBox ? formatCurrency(pricePerBox) : '--'}</TableCell>
      <TableCell data-testid={`orderHistory.${inventoryOrderId}.${serviceId}.quantity`}>{quantity}</TableCell>
      <TableCell width={175}>
        {formatCurrency((allowPartialSale ? startingUnits || 1 : 1) * (unitPrice ?? 1) * quantity)}
      </TableCell>
      {showDeliveryConfirmation && orderHasShipped(itemStatus) && purchaseOrderItemId ? (
        <TableCell>{button}</TableCell>
      ) : null}
    </TableRow>
  );
};

export { OrderHistorySubRow, OrderHistorySubRow as default };
