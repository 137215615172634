import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    paddingTop: '29px',
    paddingRight: '60px',
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Messina Sans Regular',
  },
  cancelButton: {
    width: '193px',
    height: '47px',
    borderRadius: '3px',
    backgroundColor: '#ffffff',
    fontSize: '14px',
    margin: '15px 60px',
  },
  saveButton: {
    width: '193px',
    height: '47px',
    borderRadius: '3px',
    float: 'right',
    backgroundColor: '#12574d',
    color: '#ffffff',
    fontSize: '14px',
    margin: '15px 60px',
  },
  closeIcon: {
    float: 'left',
    marginTop: '25px',
    marginLeft: '22px',
    width: '31px',
    height: '31px',
    cursor: 'pointer',
  },
}));
