import { IAddon } from 'src/services/medspaAdmins/MedspaCalendar';
import ROUTES from '../constants/apiRoutes.constants';
import { IAppointmentType, IBlockOff, INewAppointmentType } from '../interfaces/acuity/acuity.interface';
import Api from './Api';
import {
  IUpsertAppointmentConfigData,
  IUpsertPractitionerAppointmentConfigData,
} from '../hooks/mutations/useUpsertAppointmentReminderConfiguration';
import { IAppointmentReminderConfiguration } from '../interfaces/IAppointmentReminderConfiguration';
import IServiceGroup from '../interfaces/IServiceGroup';
import IServices from '../interfaces/IServices';

interface IAppointmentFullContextHistory {
  id: number;
  name: string | null;
  datetime: string;
  status: string;
  notes: string;
}
interface IAppointmentFullContext {
  addOns: IAddon[];
  appointment:
  | {
    id: number;
    datetime: string;
    duration: number;
    name: string | null;
    notes: string;
    canceled: boolean;
    rescheduled: boolean;
  }
  | undefined;
  customer:
  | {
    id: number;
    fullName: string;
    email: string;
    phone: string;
    gfeStatus: string;
  }
  | undefined;
  practitioner:
  | {
    id: number;
    fullName: string;
    email: string;
  }
  | undefined;
  appointmentType:
  | {
    id: number;
    name: number;
    duration: number;
    description: string;
  }
  | undefined;
  history: IAppointmentFullContextHistory[];
  serviceGroups: IServiceGroup[];
  services: IServices[];
}

export const Appointment = {
  async create(data: any): Promise<any> {
    const { results, error } = await Api.post(ROUTES.APPOINTMENT_BOOK, data);
    if (error) {
      throw error;
    }
    return results;
  },
  async createMedspaAppointment(data: any): Promise<any> {
    const { appointment, error } = await Api.post(ROUTES.MEDSPA_CALENDAR_APPOINTMENTS, data);
    if (error) {
      throw error;
    }
    return appointment;
  },
  async rescheduleMedspaAppointment(id: string | number | undefined, data: any): Promise<any> {
    const { success, error } = await Api.put(ROUTES.MEDSPA_CALENDAR_APPOINTMENTS_RESCHEDULE(id), data);
    if (error) {
      throw error;
    }
    return success;
  },
  async getMedspaAppointmentFullContext(appointmentId: number | null): Promise<IAppointmentFullContext> {
    if (!appointmentId) {
      return {
        appointment: undefined,
        appointmentType: undefined,
        customer: undefined,
        practitioner: undefined,
        history: [],
        addOns: [],
        serviceGroups: [],
        services: [],
      };
    }
    return Api.get(ROUTES.MEDSPA_CALENDAR_APPOINTMENTS_FULL_CONTEXT(appointmentId));
  },
  async reschedule(data: any): Promise<any> {
    return Api.put(ROUTES.APPOINTMENT_RESCHEDULE(data.appointmentId), data);
  },
  async getAccount(params: { practitionerId?: number | string; locationId?: number }): Promise<any> {
    return Api.get(ROUTES.APPOINTMENT_ACCOUNT, params);
  },
  async updateAccount(data: any): Promise<any> {
    return Api.put(ROUTES.APPOINTMENT_ACCOUNT, data);
  },
  async getAppointmentType(id: number): Promise<any> {
    return Api.get(`${ROUTES.APPOINTMENT_TYPES}/${id}`);
  },
  async createAppointmentType(data: IAppointmentType): Promise<any> {
    return Api.post(ROUTES.APPOINTMENT_TYPES, data);
  },
  async updateAppointmentType(id: number, data: IAppointmentType): Promise<any> {
    return Api.put(`${ROUTES.APPOINTMENT_TYPES}/${id}`, data);
  },
  async deleteAppointmentType(id: number, practitionerId?: number | string): Promise<any> {
    let url = `${ROUTES.APPOINTMENT_TYPES}/${id}`;
    if (practitionerId) {
      url = `${url}?practitioner_id=${practitionerId}`;
    }
    return Api.delete(url);
  },
  async getCalendarAvailability(params: any): Promise<any> {
    return Api.get(ROUTES.APPOINTMENT_CALENDAR_AVAILABILITY, params);
  },
  async updateCalendarAvailability(data: any): Promise<any> {
    return Api.put(ROUTES.APPOINTMENT_CALENDAR_AVAILABILITY, data);
  },
  async getAvailabilityOverride(data: any): Promise<any> {
    return Api.get(ROUTES.APPOINTMENT_CALENDAR_AVAILABILITY_OVERRIDE, data);
  },
  async updateAvailabilityOverride(data: any): Promise<any> {
    return Api.put(ROUTES.APPOINTMENT_CALENDAR_AVAILABILITY_OVERRIDE, data);
  },
  async getBlockOffHours(data: any): Promise<{ blockOffs: IBlockOff[] }> {
    return Api.get(ROUTES.APPOINTMENT_CALENDAR_BLOCK_OFF_HOURS, data);
  },
  async updateBlockOffHours(data: any): Promise<any> {
    return Api.post(ROUTES.APPOINTMENT_CALENDAR_BLOCK_OFF_HOURS, data);
  },
  async deleteBlockOffHours(id: number): Promise<any> {
    return Api.delete(`${ROUTES.APPOINTMENT_CALENDAR_BLOCK_OFF_HOURS}/${id}`);
  },
  async bookingSlots(data: any): Promise<any> {
    if (!data.calendarId || !data.practitionerId || !data.appointmentTypeId) {
      return [];
    }
    const { slots, error } = await Api.get(ROUTES.APPOINTMENT_BOOKING_SLOT, data);
    if (error) {
      throw error;
    }

    if (new Date(slots[0].date).getTime() === new Date(data.date).getTime()) {
      return slots[0].slots;
    }
    return [];
  },
  async appointmentTypes(data: any): Promise<any> {
    const { appointmentTypes, error } = await Api.get(ROUTES.APPOINTMENT_TYPES, data);
    if (error) {
      throw error;
    }
    return appointmentTypes;
  },
  async providerAppointmentTypes(data: any): Promise<INewAppointmentType[]> {
    const { appointmentTypes } = await Api.get(ROUTES.APPOINTMENT_TYPES_FOR_PROVIDER, data);
    return appointmentTypes;
  },
  async createAppointmentTypes(data: any): Promise<any> {
    return Api.post(ROUTES.APPOINTMENT_TYPES, data);
  },
  async calendarIDs(data: any): Promise<any> {
    const { calendars, error } = await Api.get(ROUTES.PRACTITIONER_CALENDAR_IDS, data);
    if (error) {
      throw error;
    }
    return calendars;
  },
  async locations(): Promise<any> {
    const { calendars } = await Api.get(ROUTES.PRACTITIONER_LOCATIONS);
    return calendars;
  },
  async cancelAppointment(params: any): Promise<any> {
    return Api.put(ROUTES.APPOINTMENT_CANCELLATION, params);
  },
  async cancelMedspaAppointment(appointmentId: number): Promise<any> {
    return Api.put(ROUTES.MEDSPA_CALENDAR_APPOINTMENTS_CANCEL(appointmentId));
  },
  async fetchAppointmentReminderConfiguration(practitionerId: number): Promise<IAppointmentReminderConfiguration[]> {
    const { appointmentReminderConfigurations } = await Api.get(
      ROUTES.APPOINTMENT_REMINDER_CONFIGURATION(practitionerId)
    );
    return appointmentReminderConfigurations;
  },
  async upsertMedspaAppointmentReminderConfiguration(id: number, data: IUpsertAppointmentConfigData): Promise<any> {
    return Api.put(`/v4/practitioners/appointment_reminder_configurations/${id}`, data);
  },
  async upsertAppointmentReminderConfiguration(data: IUpsertPractitionerAppointmentConfigData): Promise<any> {
    return Api.put(ROUTES.APPOINTMENT_REMINDER_CONFIGURATION(data.practitionerId), data);
  },
  async getCareMessagesSuggestion(params: any): Promise<any> {
    return Api.get(ROUTES.APPOINTMENT_TYPE_CARE_MESSAGES_SUGGESTION, params);
  },
  medspa: {
    // @ts-ignore
    async appointmentTypes(id: string | number | null, params: any = {}): Promise<any> {
      if (!id) {
        return [];
      }
      return Api.get(ROUTES.MEDSPA_APPOINTMENT_TYPES(id), params);
    },
    async createAppointmentType(id: string | number, data: any = {}): Promise<any> {
      return Api.post(`${ROUTES.MEDSPA_APPOINTMENT_TYPES(id)}`, data);
    },
    async updateAppointmentType(id: number, data: IAppointmentType): Promise<any> {
      return Api.put(`${ROUTES.MEDSPA_APPOINTMENT_TYPES(id)}/${data.id}`, data);
    },
  },
};
