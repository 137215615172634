import { makeStyles } from '@material-ui/core';

export const useRowStyles = makeStyles({
  root: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    '& > *': {
      border: '0 !important',
    },
  },
  email: {
    textTransform: 'lowercase',
  },
  menuIcon: {
    border: '1px solid #E4E7EB',
    borderRadius: '2px',
  },
});
