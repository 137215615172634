import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  modalContent: {
    width: '100%',
    height: '100%',
    background: 'white',
    overflow: 'scroll',
  },
  summaryContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  summary: {
    border: 'solid 2px #D1D8D8',
    borderRadius: '4px',
    padding: '1rem 2rem',
    marginTop: '1rem',
    minWidth: '600px',
    marginBottom: '3rem',
  },
  summaryLineBreak: {
    borderTop: '#E0E0E0',
    marginTop: '1rem',
  },
  summaryLineItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  tipsContainer: {
    marginBottom: '1rem',
  },
  optional: {
    color: '#999999',
    fontSize: '16px',
  },
  tipOptionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedTipOption: {
    background: '#12574D',
    color: 'white',
    borderRadius: '4px',
    padding: '1rem 2rem',
    border: 'none',
    cursor: 'pointer',
  },
  tipOption: {
    border: '1px solid #12574D',
    background: 'white',
    color: '#12574D',
    borderRadius: '4px',
    padding: '1rem 2rem',
    cursor: 'pointer',
  },
  cardName: {
    display: 'flex',
    alignItems: 'center',
  },
  cardIcon: {
    position: 'relative',
    top: '8px',
    marginLeft: '6px',
  },
  payWithContainer: {
    background: '#e8ebeb',
    borderRadius: '4px',
    padding: '0.25rem 1rem',
  },
  payWithCashContainer: {
    background: '#e8ebeb',
    borderRadius: '4px',
    padding: '1rem',
  },
  mt0: {
    marginTop: 0,
  },
  payWithLineBreak: {
    borderTop: '0',
    borderColor: 'gray',
    marginTop: '1rem',
  },
  summaryBold: {
    fontSize: '20px',
    fontFamily: 'Messina Sans Bold',
  },
  payButton: {
    width: '100%',
    textAlign: 'center',
    background: '#12574D',
    color: 'white',
    padding: '1rem',
    fontFamily: 'Messina Sans Bold',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '1rem',
    fontSize: '16px',
    '&:disabled': {
      backgroundColor: '#979797',
      color: 'white',
      cursor: 'default',
    },
  },
  ml05: {
    marginLeft: '0.5rem',
  },
  summaryLineItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  summaryLineItemHeader: {
    color: 'gray',
    borderBottom: 'none',
    fontSize: '0.875rem',
    fontFamily: 'inherit',
    padding: 0,
  },
  summaryLineItemBody: {
    paddingTop: '0.675rem',
    borderBottom: 'none',
    padding: 0,
  },
  summaryLineItemRight: {
    paddingLeft: '1rem',
    textAlign: 'right',
  },
  signatureContainer: {
    height: '250px',
  },
  signatureBox: {
    border: '1px solid #e8ebeb',
    width: '600px',
    height: 'calc(100% - 40px)',
    position: 'relative',
  },
  clearSignatureButton: {
    position: 'relative',
    right: '0',
  },
  signatureImageContainer: {
    textAlign: 'center',
    width: '600px',
    border: '1px solid #e8ebeb',
  },
  signatureImage: {
    maxHeight: '250px',
    maxWidth: '100%',
    padding: '2rem',
  },
  signCheckoutContainer: {
    position: 'relative',
  },
  clearSignatureContainer: {
    textAlign: 'right',
  },
}));
