/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => ({
  containerDialog: {
    width: '450px',
  },
  title: {
    textAlign: 'center',
  },
  dialogActions: {
    display: 'block',
    textAlign: 'center',
  },
  buttonRight: {
    background: theme.palette.primary.main,
    color: 'white',
  },
  buttonLeft: {
    background: '#FBDADA',
  },
}));

const ModalMarkAsEmptyItem = ({ handleSaveChanges, open, units, handleClose, isMarkedAsEmpty }: any) => {
  const classes = useStyles();

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <div data-cy="modal" className={classes.containerDialog}>
          <DialogTitle className={classes.title}>
            {isMarkedAsEmpty ? (
              <>
                <div style={{ color: '#F16C00' }}>Undo mark as empty</div>
                <div style={{ color: '#969696', fontSize: '70%' }}>Undo mark as empty restoring asset to stock.</div>
                <div>Are you sure you want to remove the empty mark from this element?</div>
              </>
            ) : (
              <>
                <div style={{ color: '#F16C00' }}>WARNING</div>
                <div style={{ color: '#969696', fontSize: '70%' }}>
                  Marking this vial as empty will result in a recorded loss of {units} units on your next reconciliation
                  report.
                </div>
                <div>Are you sure you want to mark this item as empty?</div>
              </>
            )}
          </DialogTitle>
          <DialogActions className={classes.dialogActions}>
            <Button data-cy="noBtn" onClick={handleClose} className={classes.buttonLeft}>
              NO
            </Button>
            <Button data-cy="yesBtn" onClick={handleSaveChanges} className={classes.buttonRight}>
              YES
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
};

export default ModalMarkAsEmptyItem;
