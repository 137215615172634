import React, { FC } from 'react';
import { Box, Table, TableBody, TableContainer } from '@material-ui/core';
import _ from 'lodash';
import { IRoles } from 'src/interfaces/IRoles.interfaces';
import { useCustomRoles } from 'src/hooks/queries/useCustomRoles';
import Page from 'src/components/common/Page';
import AdministratorsHeader from './AdministratorsHeader';
import { dispatch } from '../../../rematch';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { useCustomAdminUsers } from '../../../hooks/queries/useCustomAdminUsers';
import compile from '../../../utils/toastMessagesCompiler';
import AdminUserRow from './AdminUserRow';
import { IAdminUser } from '../../../interfaces/IAdminUser';
import TableHeader, { ColumnType } from '../../common/Table/TableHeader';

const ERROR_GETTING_ADMIN_USERS = compile('generic.error_message', {
  action: 'getting',
  element: 'admin users',
});

const TABLE_COLUMNS: ColumnType[] = [
  { id: 'id', title: 'ID' },
  { id: 'fullname', title: 'Fullname' },
  { id: 'email', title: 'Email' },
  { id: 'Role', title: 'Role' },
  { id: 'status', title: 'Status' },
];

export interface IRolesMap {
  [key: number]: IRoles;
}

const AdministratorsTable: FC = () => {
  const { adminUsers, isLoading, isError } = useCustomAdminUsers();
  const STATUS_INACTIVE = false;
  const adminUsersOrder = _.orderBy(adminUsers, ['id'], ['asc']);
  const { data: roles = [], isLoading: isLoadingRoles } = useCustomRoles(null);

  if (isLoadingRoles) {
    return <Page title="Admin Users" />;
  }

  const rolesMap: IRolesMap = roles.reduce((acc: any, role: IRoles) => {
    acc[role.id] = role;
    return acc;
  }, {});

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: ERROR_GETTING_ADMIN_USERS,
        type: 'error',
        duration: 3000,
      },
    });
  }

  const TableComponent = (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHeader columns={TABLE_COLUMNS} />
          <TableBody>
            {isLoading || isLoadingRoles ? (
              <MultipleSkeleton />
            ) : (
              adminUsersOrder.map((admin: IAdminUser) => (
                <AdminUserRow
                  key={admin.id}
                  id={admin.id}
                  fullname={`${admin.firstName} ${admin.lastName}`}
                  email={admin.email}
                  roleId={admin.roleId}
                  active={admin.active || STATUS_INACTIVE}
                  rolesMap={rolesMap}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Page title="Admin Users">
      <Box width="100%">
        <AdministratorsHeader />
        {TableComponent}
      </Box>
    </Page>
  );
};

export default AdministratorsTable;
