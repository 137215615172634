import React from 'react';

import { Box, Grid, ButtonBase, CircularProgress } from '@material-ui/core';
import { CheckRounded, AccessTime } from '@material-ui/icons';
import { Step, StepStatus, StepsTrackerProps } from 'src/interfaces/onboarding.interface';
import { startCase } from 'lodash';
import { EHRTypography } from '../ui/v1';
import useStyles, { COMPLETED_STEP_COLOR, PENDING_STEP_FONT_COLOR } from './StepsTracker.styles';

export const STEP_COMPLETED = 'completed';
export const STEP_EMPTY = 'empty';
export const STEP_PENDING = 'pending';
export const STEP_LOADING = 'loading';

const StepsTracker = ({ steps, onStepClick, isLoading = false }: StepsTrackerProps) => {
  const classes = useStyles();
  const handleStepClick = (step: Step) => {
    if (onStepClick) {
      onStepClick(step);
    }
  };

  const completedStepIcon = (
    <Box margin="10px 24px" className={classes.filledGreenCircle}>
      <CheckRounded className={classes.roundedCheck} />
    </Box>
  );

  const pendingStepIcon = (
    <Box margin="10px 24px" className={classes.filledYellowCircle}>
      <AccessTime className={classes.roundedCheck} />
    </Box>
  );

  const loadingStepIcon = (
    <Box margin="10px 24px" className={classes.circularProgressContainer}>
      <CircularProgress size={20} />
    </Box>
  );

  const emptyStepIcon = <div className={classes.emptyCircle} />;

  const renderStatusIcon = (status: StepStatus) => {
    switch (status) {
      case STEP_COMPLETED:
        return completedStepIcon;
      case STEP_PENDING:
        return pendingStepIcon;
      case STEP_LOADING:
        return loadingStepIcon;
      default:
        return emptyStepIcon;
    }
  };

  const setTypographyColor = (status: StepStatus) => {
    switch (status) {
      case STEP_COMPLETED:
        return COMPLETED_STEP_COLOR;
      case STEP_PENDING:
        return PENDING_STEP_FONT_COLOR;
      default:
        return 'black';
    }
  };

  return (
    <Grid container direction="column" justify="space-evenly" className={classes.container}>
      {steps.map((step, index) => {
        const { name, status } = step;
        return (
          <ButtonBase
            onClick={() => handleStepClick(step)}
            key={`onboarding-step-${step.name}`}
            className={classes.stepComponent}
            disabled={status === STEP_LOADING || isLoading}
          >
            {!isLoading ? (
              <Box className={index === steps.length - 1 ? '' : classes.circleContainer}>
                {renderStatusIcon(status)}
              </Box>
            ) : (
              loadingStepIcon
            )}

            <Box width="100%" display="flex" justifyContent="left">
              <EHRTypography
                style={{ color: setTypographyColor(status) }}
                variant="body2"
                dataCy={`onboarding-step-${index}`}
              >
                {startCase(name)}
              </EHRTypography>
            </Box>
          </ButtonBase>
        );
      })}
    </Grid>
  );
};

export default StepsTracker;
