import { useMutation, useQueryClient } from 'react-query';
import {
  MEDSPA_ADMINS,
  MEDSPA_LOCATION_PRACTITIONERS,
  PRACTITIONERS,
  USER_GROUPS_INFINITY,
} from '../../constants/reactQuery.keys';
import { showSnackbar } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import { SNACKBAR_INFO, SNACKBAR_SUCCESS } from '../../constants/general.constants';
import { Practitioners } from '../../services/Practitioners';

export const useEditMedspaProviderMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      params,
      userGroupId,
      userId,
    }: {
      params: FormData;
      userGroupId: number;
      userId: number;
    }): Promise<void> => {
      await Practitioners.updateMedspaProvider(userGroupId, userId, params);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [PRACTITIONERS] });
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMINS] });
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY] });
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_LOCATION_PRACTITIONERS] });
      showSnackbar(compile('provider.updated'), SNACKBAR_SUCCESS);
    },
    onError: async (error: any) => {
      showSnackbar(`Failed to update provider: ${error.message}`, SNACKBAR_INFO);
    },
  });

  return mutation;
};
