import Api from './Api';
import { GET_IDEMPOTENCY_KEY } from '../constants/apiRoutes.constants'

export const IdempotencyKeys = {

  async getIdempotencyKey(eventId?: string): Promise<string | null> {
    const { success, data }: { success: boolean, data: { eventId: string } } = await Api.post(
      GET_IDEMPOTENCY_KEY,
      { eventId }
    )

    return success ? data.eventId : null
  }
}