import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useStyles } from './tenderSection.styles';

interface Props {
  xperienceVoucher: string;
  setXperienceVoucher: (value: string) => void;
  xperienceValue: string;
  setXperienceValue: (value: string) => void;
  isTotalTenderValueValid: (value: NumberFormatValues, tenderValue: string) => boolean;
}

export const XperienceTender = (props: Props) => {
  const { xperienceVoucher, setXperienceVoucher, xperienceValue, setXperienceValue, isTotalTenderValueValid } = props;
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.title}>Xperience</div>
      <input
        type="text"
        value={xperienceVoucher}
        onChange={(e) => setXperienceVoucher(e.target.value)}
        placeholder="Code/Note"
        className={classes.input}
        style={{ marginRight: '20px' }}
      />
      <NumberFormat
        decimalScale={2}
        fixedDecimalScale
        autoComplete="off"
        placeholder="Amount"
        value={xperienceValue}
        onChange={(e) => {
          setXperienceValue(e.target.value);
        }}
        isAllowed={(inputObj) => isTotalTenderValueValid(inputObj, xperienceValue)}
        className={classes.input}
        type="tel"
      />
    </div>
  );
};
