import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  signBox: {
    marginTop: '.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > img': {
      border: '1px solid #ccc',
      minWidth: 356,
      minHeight: 136,
    },
  },
});
