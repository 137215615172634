import { useMutation } from 'react-query';
import { Appointment } from 'src/services/Appointment';
import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import compile from '../../utils/toastMessagesCompiler';

export const useCancelAppointmentMutation = () =>
  useMutation((params: { appointmentId: number; practitionerId: number }) => Appointment.cancelAppointment(params), {
    onSuccess: ({ success, message }: { success: boolean; message: string }) => {
      if (!success) {
        if (message) {
          showSnackbar(message);
        } else {
          showSnackbar(compile('appointment_cancelling.error'));
        }
        return;
      }
      showSnackbar(compile('appointment_cancelling.success'), SNACKBAR_SUCCESS);
    },
    onError: useReactQueryOnErrorHandler,
  });
