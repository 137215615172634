import API_ROUTES from 'src/constants/apiRoutes.constants';
import Api from './Api';

export type OrderConfirmationNumber = {
  id: number;
  inventoryOrderId: number;
  supplierId: number;
  supplierName: string;
  confirmationNumber: string;
  notes: string | null;
};
export default {
  async create(params: Partial<OrderConfirmationNumber>): Promise<OrderConfirmationNumber> {
    const { data }: { data: OrderConfirmationNumber } = await Api.post(API_ROUTES.CONFIRMATION_NUMBERS(), params);
    return data;
  },

  async destroy(id: number): Promise<OrderConfirmationNumber> {
    const { data }: { data: OrderConfirmationNumber } = await Api.delete(API_ROUTES.CONFIRMATION_NUMBERS(id));
    return data;
  },
};
