import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CardButton } from './Appointments.styles';
import { PATIENT_SCHEDULER_STATUSES } from '../../../constants/scheduler.constants';
import { ROUTES } from '../../../constants/routes.constants';
import { hasAccessTo } from '../../../utils/auth.utils';
import { GFE_REQUEST } from '../../../constants/actions.constants';

interface Props {
  appointmentData: any;
}

export const AppointmentsCardHeader = ({ appointmentData }: Props) => {
  const history = useHistory();
  const { statusId, customerId } = appointmentData;
  const data = PATIENT_SCHEDULER_STATUSES.find(({ id }: any) => id === statusId);
  const status = data?.text || '';
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const gotToPractitioner = () => {
    if (customerId) {
      history.push(ROUTES.PATIENT_ID(customerId), { from: history.location.pathname });
    }
  };
  if (!hasAccessTo(GFE_REQUEST, permissions)) {
    return null;
  }

  return (
    <CardButton
      style={{
        background: data?.background || '#3D766E',
        color: data?.color || '#ffff',
        width: 'auto',
        padding: '10px',
        border: `solid 1px ${data?.border || data?.background || '#3D766E'}`,
      }}
      disabled={status === '' || status === 'All'}
      onClick={gotToPractitioner}
    >
      {status === '' || status === 'All' ? 'Unknown' : status}
    </CardButton>
  );
};

export default AppointmentsCardHeader;
