/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';
import { Grid, FormControlLabel, Switch, Collapse, TextField, debounce } from '@material-ui/core';
import { useStyles } from './medicalProfile.styles';

interface ConditionsFieldsProps {
  disabled?: boolean;
  isChecked: (name: string) => boolean;
  onToggleSwitch: (name: string) => void;
  setText?: (name: string, text: string) => void;
  name: string;
  label: string;
  collapsedField?: string;
  collapsedLabel?: string;
  collapsedFieldName?: string;
  collapsedFieldValue?: string;
  extraForm?: any;
}
const ConditionalField = (props: ConditionsFieldsProps) => {
  const {
    disabled,
    isChecked,
    onToggleSwitch,
    name,
    label,
    collapsedField,
    collapsedLabel,
    collapsedFieldName,
    collapsedFieldValue,
    setText,
    extraForm,
  } = props;
  const classes = useStyles();

  const onChangeText = useCallback(
    debounce((key: string, text: string, onChange: any) => {
      if (onChange) {
        onChange(key, text);
      }
    }, 2000),
    []
  );

  return (
    <>
      <Grid item xs={12} style={{ padding: '2px' }}>
        <div className={classes.field}>
          {label}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              label={isChecked(name) ? 'Yes' : 'No'}
              control={<Switch checked={isChecked(name)} onChange={() => onToggleSwitch(name)} disabled={disabled} />}
            />
          </div>
        </div>
      </Grid>
      <Collapse in={isChecked(name)} timeout="auto" className={classes.collapseRow}>
        <Grid item xs={12}>
          {collapsedField === 'checkbox' && name !== 'autoimmuneDisease' && (
            <div className={classes.subField}>
              {collapsedLabel}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  label={isChecked(collapsedFieldName || '') ? 'Yes' : 'No'}
                  control={
                    <Switch
                      checked={collapsedFieldValue === 'yes'}
                      onChange={() =>
                        setText && setText(collapsedFieldName || '', collapsedFieldValue === 'yes' ? 'no' : 'yes')
                      }
                    />
                  }
                />
              </div>
            </div>
          )}
          {collapsedField === 'text' && (
            <div className={classes.subField}>
              <div className={classes.textField}>
                <TextField
                  disabled={disabled}
                  defaultValue={collapsedFieldValue}
                  name={collapsedFieldName}
                  fullWidth
                  placeholder="Please explain here."
                  variant="outlined"
                  onChange={(e) => onChangeText(collapsedFieldName || '', e.currentTarget.value, setText)}
                  multiline
                />
                {extraForm}
              </div>
            </div>
          )}

          {name === 'autoimmuneDisease' && (
            <>
              <div className={classes.subField}>
                <div className={classes.textField}>{extraForm}</div>
              </div>
              <div className={classes.subField}>
                {collapsedLabel}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    label={isChecked(collapsedFieldName || '') ? 'Yes' : 'No'}
                    control={
                      <Switch
                        checked={collapsedFieldValue === 'yes'}
                        onChange={() =>
                          setText && setText(collapsedFieldName || '', collapsedFieldValue === 'yes' ? 'no' : 'yes')
                        }
                      />
                    }
                  />
                </div>
              </div>
            </>
          )}
        </Grid>
      </Collapse>
    </>
  );
};
export default ConditionalField;
