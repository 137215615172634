import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

export const useStyles = makeStyles({
  title: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: '#000000DE',
    marginTop: '18px',
    marginBottom: '9px',
    marginLeft: '18px',
  },
  cardContent: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  advocateForm: {
    position: 'relative',
    top: '-40px',
  },
  advocateReasonLabel: {
    color: '#747474',
  },
  makeAdvocateText: {
    color: '#747474',
    fontSize: '15px',
  },
  advocateButton: {
    background: '#e7eeed',
    padding: '15px',
    color: 'black',
  },
  referralContainer: {
    marginTop: '11px',
  },
  referralByText: {
    fontFamily: 'Messina Sans Regular',
    whiteSpace: 'nowrap',
  },
  referralsCreditContainer: {
    padding: '16px 20px',
  },
  referredByContainerMargin: {
    padding: '16px 0',
    margin: '10px',
  },
  referralByLabelButton: {
    color: '#393B3F',
    background: '#E7EEED',
    width: '146px',
    height: '56px',
    lineHeight: '56px',
    textAlign: 'center',
    borderRadius: '4px',
  },
  input: {
    minWidth: '378px',
  },
  hasReferredText: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    color: '#000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chip: {
    backgroundColor: '#E7EEED',
    width: '100px',
  },
});

export const SwitchSimple = withStyles((theme) =>
  createStyles({
    root: {
      width: 49,
      height: 24,
      padding: 0,
      display: 'flex',
      margin: '0 16px',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      marginTop: '3px',
      '&$checked': {
        transform: 'translateX(32px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 0.7,
          backgroundColor: '#62bb58',
          borderColor: '#62bb58',
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 36 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);
