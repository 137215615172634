import { TableRow } from '@material-ui/core';
import React from 'react';
import { TableCellCustom } from 'src/components/common/TableStyles/TableCellCustom';
import { useRowStyles } from 'src/components/DashboardAdministrator/Services/Service.styles';
import { formatCurrency } from 'src/utils/formatNumber';
import PercentChip from 'src/components/common/PercentChip';
import { ServiceRowProps } from 'src/interfaces/IServices';

const ServiceRow: React.FC<ServiceRowProps> = ({
  name,
  serviceGroupName,
  supplierServiceName,
  rolePrice,
  listPrice,
}: ServiceRowProps): React.ReactElement => {
  const classes = useRowStyles();

  const displayPrice = (value?: number): string | 0 | undefined =>
    value || value === 0 ? formatCurrency(value) : '--';

  return (
    <TableRow data-cy="row" className={classes.root}>
      <TableCellCustom data-cy="name">{name}</TableCellCustom>
      <TableCellCustom data-cy="serviceGroupName">{serviceGroupName}</TableCellCustom>
      <TableCellCustom data-cy="supplierServiceName">{supplierServiceName}</TableCellCustom>
      <TableCellCustom data-cy="listPrice">{displayPrice(listPrice)}</TableCellCustom>
      <TableCellCustom data-cy="rolePrice">
        {displayPrice(rolePrice)}
        <PercentChip dividend={rolePrice} divisor={listPrice} />
      </TableCellCustom>
    </TableRow>
  );
};

export default ServiceRow;
