import { Grow, Table, TableBody, TableRow } from '@material-ui/core';
import moment from 'moment';
import { DeleteForever } from '@material-ui/icons';
import React from 'react';
import compile from '../../utils/toastMessagesCompiler';
import { TableCellCustom, useStyles } from '../NewServiceVisit/scanner/ServiceVisitScanner.styles';
import { getHumanReadableInternalSerialNumber, isAssetExpired } from '../../utils/inventory.utils';
import RescanAsset from '../NewServiceVisit/scanner/RescanAsset';
import { IAsset } from '../../interfaces/reconciliation.interfaces';
import { IServiceVisitAssetScanned } from '../../interfaces/IServiceVisits';
import { dispatch } from '../../rematch';
import {
  useDeleteServiceVisitAssetMutation,
  useRescanServiceVisitAssetMutation,
} from '../../hooks/serviceVisit/useServiceVisitAssets';

const ServiceVisitScannedProductList = ({
  serviceVisitAssets,
  disabled,
  serviceVisitId,
}: {
  serviceVisitAssets: IServiceVisitAssetScanned[];
  disabled: boolean;
  serviceVisitId: number;
}) => {
  const classes = useStyles();

  const deleteServiceVisitAsset = useDeleteServiceVisitAssetMutation();
  const rescanAsset = useRescanServiceVisitAssetMutation();

  const handleDelete = (assetId: number) => {
    if (disabled) {
      return false;
    }

    deleteServiceVisitAsset.mutate({
      assetId,
      serviceVisitId,
    });

    return true;
  };

  const rescanCallback = async (assetIdToRemove: number, unitsRequested: number, asset: IAsset) => {
    const success = await rescanAsset.mutateAsync({
      serviceVisitId,
      assetId: assetIdToRemove,
      newAssetId: asset.id,
    });

    if (success) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.product_rescanned'),
        },
      });
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'updating',
            element: 'scanned assets',
          }),
          type: 'error',
        },
      });
    }
  };

  const onScan = (oldAsset: IServiceVisitAssetScanned, newAsset: IAsset, unitsRequested: number) => {
    if (newAsset.internalSerialNumber === oldAsset.internalSerialNumber) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.rescan_same_serial_number'),
          type: 'error',
        },
      });
    } else if (newAsset.serviceId !== oldAsset.serviceId) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.product_not_same_service'),
          type: 'error',
        },
      });
    } else if (isAssetExpired(newAsset)) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.product_expired'),
          type: 'error',
        },
      });
    } else {
      rescanCallback(oldAsset.assetId, unitsRequested, newAsset);
    }
  };

  return (
    <>
      <Table>
        <TableBody>
          {serviceVisitAssets.map((serviceVisitAsset) => (
            <Grow
              key={`${serviceVisitAsset.id}-${serviceVisitAsset.serviceId}`}
              in
              timeout={1000}
              style={{ transformOrigin: '0 0 0' }}
            >
              <TableRow className={classes.productScanned}>
                <TableCellCustom
                  style={{ fontSize: '12px', border: 'none', fontWeight: 'bold', maxWidth: '150px' }}
                  colSpan={2}
                >
                  {serviceVisitAsset.name}
                </TableCellCustom>
                <TableCellCustom style={{ fontSize: '12px', border: 'none', minWidth: '67px' }}>
                  Qty: {serviceVisitAsset.units}
                </TableCellCustom>
                <TableCellCustom style={{ fontSize: '12px', border: 'none' }}>
                  Lot: {serviceVisitAsset.lot.toUpperCase()}
                </TableCellCustom>
                <TableCellCustom style={{ fontSize: '12px', border: 'none' }}>
                  Exp: {moment(serviceVisitAsset.expireAt).format('MM/DD/YYYY')}
                </TableCellCustom>
                <TableCellCustom style={{ fontSize: '12px', border: 'none' }}>
                  {getHumanReadableInternalSerialNumber(serviceVisitAsset.internalSerialNumber)}
                </TableCellCustom>
                <TableCellCustom style={{ fontSize: '12px', border: 'none', paddingTop: '8px' }} align="right">
                  {disabled ? (
                    <div style={{ height: '25px' }}>
                      <RescanAsset
                        onScan={(newAsset: IAsset) => onScan(serviceVisitAsset, newAsset, serviceVisitAsset.units)}
                        asset={serviceVisitAsset}
                        unitsRequested={serviceVisitAsset.units}
                      />
                    </div>
                  ) : (
                    <DeleteForever
                      onClick={() => handleDelete(serviceVisitAsset.assetId)}
                      className={classes.undoIcon}
                    />
                  )}
                </TableCellCustom>
              </TableRow>
            </Grow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ServiceVisitScannedProductList;
