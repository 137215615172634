/* eslint-disable curly */
import React, { useState } from 'react';
import { Grid, CardContent } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useStyles } from '../NewServiceVisit/consents/consents.styles';
import { ConsentModal } from '../NewServiceVisit/consents/ConsentModal';
import { Card, SmallTitle } from '../common/card';
import IServiceGroup from '../../interfaces/IServiceGroup';
import { IPractitioner } from '../../interfaces/IPractitioner';
import { useSignServiceVisitConsent } from '../../hooks/serviceVisit/useConsents';
import { IPatientView } from '../../types/Patient';

interface Props {
  serviceGroupPendingForConsents: IServiceGroup[];
  patient: IPatientView;
  practitioner: IPractitioner;
  customerConsents: any;
  opened: boolean;
  viewOnly?: boolean;
}

export const MedicalConsentStep = ({
  serviceGroupPendingForConsents,
  patient,
  practitioner,
  customerConsents,
  opened,
  viewOnly,
}: Props) => {
  const classes = useStyles();
  const { serviceVisitId, patientId }: { patientId: string; serviceVisitId: string } = useParams();
  const { businessEntity, medicalGroup } = practitioner;

  const [isOpened, setOpened] = useState(false);
  const [consentContent, setConsentContent] = useState('');
  const [consentServiceGroupId, setConsentServiceGroupId] = useState(0);
  const [consentName, setConsentName] = useState('');
  const [patientName, setPatientName] = useState('');
  const [isSigning, setIsSigning] = useState(false);
  const signConsentMutation = useSignServiceVisitConsent(+serviceVisitId);

  const handleOpen = (serviceGroupId: number, name: string, consent: any) => {
    if (!opened) return;
    if (isSignedConsent(serviceGroupId)) {
      const consentObject = customerConsents.find((consentObj: any) => consentObj.serviceGroupId === serviceGroupId);
      window.open(consentObject.consentPdfUrl, '_blank');
    } else if (!viewOnly) {
      const consentInfo = consent.content
        .replace('<%= business_entity %>', businessEntity)
        .replace('<%= medical_group %>', medicalGroup ? ` and ${medicalGroup}` : '');
      setOpened(true);
      setPatientName(`${patient.firstName} ${patient.lastName}`);
      setConsentName(name);
      setConsentServiceGroupId(serviceGroupId);
      setConsentContent(consentInfo);
    }
  };

  const handleClose = () => {
    setOpened(false);
    setPatientName('');
  };

  const isSignedConsent = (serviceGroupId: number) =>
    customerConsents.some((consent: any) => consent.serviceGroupId === serviceGroupId);

  const signConsent = async (signatureImage: any, patientNameConsent: string) => {
    setIsSigning(true);
    await signConsentMutation.mutateAsync({
      patientId,
      serviceGroupId: consentServiceGroupId,
      patientName: patientNameConsent,
      serviceVisitId,
      signatureImage,
    });
    setOpened(false);
    setIsSigning(false);
  };

  const getRequiredStyle = (idService: number) => {
    if (!isSignedConsent(idService)) {
      const requiredForServiceVisit = serviceGroupPendingForConsents.find(
        ({ id }) => id === idService
      )?.consentRequiredForServiceVisit;

      return requiredForServiceVisit ? classes.notSigned : classes.notSignedAndNotRequired;
    }
    return classes.optional;
  };

  return (
    <Card style={{ marginBottom: '0' }}>
      <CardContent>
        <div className={classes.title}>
          <SmallTitle title="Patient medical consents" style={{ marginTop: '0', marginBottom: '18px' }} />
        </div>
        <Grid container spacing={1}>
          {!serviceGroupPendingForConsents.length && <div>No consents pending to sign</div>}
          {serviceGroupPendingForConsents.map(
            ({ id: idService, consent, name }: any) =>
              consent !== null && (
                <div key={idService}>
                  <Grid item md={3}>
                    <div
                      data-cy="consentBtn"
                      className={[classes.consent, getRequiredStyle(idService)].join(' ')}
                      onClick={() => handleOpen(idService, name, consent)}
                      role="none"
                    >
                      {name}
                    </div>
                  </Grid>
                </div>
              )
          )}
        </Grid>
        <ConsentModal
          isOpen={isOpened}
          isSigning={isSigning}
          handleCloseCallback={handleClose}
          consentName={consentName}
          consentContent={consentContent}
          patientNameProp={patientName}
          signConsentCallback={signConsent}
        />
      </CardContent>
    </Card>
  );
};
