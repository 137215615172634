import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import { useUpdateAvailabilityOverrideMutation } from '../../../hooks/acuity/useAcuityAccount';

interface Props {
  appointmentData: any;
  availability: any;
}

const useCardStyles = makeStyles(() => ({
  cardContainer: {
    top: '394px',
    left: '165px',
    width: '455px',
    background: '#E7EEED 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 8px #00000071',
  },
  cardContent: {
    padding: '24px 69px 19px 19px',
    color: '#173F61',
  },
}));

const formatDate = 'YYYY-MM-DD';
// const formatHourA = 'h:mmA';
const formatDateHourA = 'YYYY-MM-DD hh:mmA';

const AvailabilityCard = (props: Props) => {
  const { appointmentData: timeOffData, availability } = props;
  const useQuery = () => new URLSearchParams(window.location.search);
  const query = useQuery();
  const practitionerId = query.get('practitionerId') || undefined;

  const classes = useCardStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const updateAvailabilityOverrideMutation = useUpdateAvailabilityOverrideMutation(() => {
    setOpenDialog(false);
  });

  const onDelete = () => {
    const data = {
      start: moment(timeOffData.startDate).format(formatDate),
      end: moment(timeOffData.endDate).format(formatDate),
      days: {
        sunday: availability.sunday,
        monday: availability.monday,
        tuesday: availability.tuesday,
        wednesday: availability.wednesday,
        thursday: availability.thursday,
        friday: availability.friday,
        saturday: availability.saturday,
      },
    };
    updateAvailabilityOverrideMutation.mutate({ ...data, practitionerId });
  };

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardContent}>
        {timeOffData.title} from {moment(timeOffData.startDate).format(formatDateHourA)} to{' '}
        {moment(timeOffData.endDate).format(formatDateHourA)}
        <div>
          <Button
            data-cy="submitBtn"
            variant="contained"
            disableElevation
            color="primary"
            size="medium"
            style={{ margin: '10px 0 0 0' }}
            onClick={() => setOpenDialog(true)}
          >
            Delete
          </Button>
        </div>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} data-cy="confirmationModal">
        <DialogTitle>Reset Time</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-content-text">
            {/* eslint-disable-next-line max-len */}
            Are you sure you want to reset the time from {moment(timeOffData.startDate).format(formatDateHourA)} to{' '}
            {moment(timeOffData.endDate).format(formatDateHourA)} as a default configured time in availability?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-cy="closeModal" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button
            disabled={updateAvailabilityOverrideMutation.isLoading}
            data-cy="confirmModal"
            onClick={onDelete}
            color="primary"
            autoFocus
          >
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AvailabilityCard;
