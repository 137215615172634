import Id from '../types/Id';

export const REC_REPORT_TYPE = 'REC_REPORT';
export const GRAL_MESSAGE_TYPE = 'GRAL_MESSAGE';

export type NotificationType = typeof REC_REPORT_TYPE | typeof GRAL_MESSAGE_TYPE;

export default interface IGlobalNotification {
  id: Id;
  createdAt: Date;
  done: boolean;
  message: string;
  notificationType: NotificationType;
  read: boolean;
  updatedAt?: Date;
}
