import React, { useEffect, useState } from 'react';
import {
  TableRow,
  TableCell,
  Tooltip,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';
import moment from 'moment';
import {
  FlipCameraIosOutlined,
  InsertPhotoOutlined,
  MessageOutlined,
  Search as SearchIcon,
  Update,
} from '@material-ui/icons';
import classnames from 'classnames';
import { useCustomPatients } from '../../hooks/queries/useCustomPatients';
import { PHYSICIAN_DASHBOARD_KEY } from '../../constants/general.constants';
import { PortraitTable } from '../../components/common/TableNew/Table';
import compile from '../../utils/toastMessagesCompiler';
import { CLEARED_STATUS } from '../../constants/scheduler.constants';
import { useStyles } from './ProviderPatientsList.styles';
import { SelectCustom } from '../../components/DashboardAdministrator/Inventory/supplyReceipt.styles';
import { usePhysicianNavigate } from '../../hooks/customer/usePhysicianNavigate';

const TABLE_COLUMNS = [
  { id: 'name', title: 'Name' },
  { id: 'id', title: 'ID' },
  { id: 'next_appointment', title: 'Appt. Date', sort: true },
  { id: 'updates', title: 'Updates' },
  { id: 'status', title: 'GFE Status' },
];

export const NewMessages = ({ count }: { count: number }) => (
  <Tooltip title={`${count} new messages`}>
    <MessageOutlined />
  </Tooltip>
);

export const UnseenPhotos = ({ count }: { count: number }) => (
  <Tooltip title={`${count} new photos`}>
    <InsertPhotoOutlined />
  </Tooltip>
);

export const MedicalProfileUpdated = () => (
  <Tooltip title="medical profile updated since last view">
    <Update />
  </Tooltip>
);
export const PhotoRequested = () => (
  <Tooltip title="new photos were requested">
    <FlipCameraIosOutlined />
  </Tooltip>
);

export const nextAppointment = (appointment: string | null) => {
  if (!appointment) {
    return '';
  }

  const appointmentDate = moment(appointment);
  const lapse = appointmentDate.from(moment());
  return (
    <>
      {appointmentDate.format('MMMM  DD HH:mm')} <small>({lapse})</small>
    </>
  );
};

const PatientRow = ({ patient }: { patient: any }) => {
  const classes = useStyles();
  const navigateToPatient = usePhysicianNavigate(patient.id);

  return (
    <TableRow>
      <TableCell onClick={navigateToPatient} className={classes.patientRow}>
        {patient.firstName} {patient.lastName}
      </TableCell>
      <TableCell onClick={navigateToPatient} className={classes.patientRow}>
        {patient.id}
      </TableCell>
      <TableCell onClick={navigateToPatient} className={classes.patientRow}>
        {nextAppointment(patient.nextAppointment)}
      </TableCell>
      <TableCell onClick={navigateToPatient} className={classes.patientRow}>
        {patient.unreadMessagesCount > 0 && <NewMessages count={patient.unreadMessagesCount} />}
        {patient.unseenPhotosCount > 0 && <UnseenPhotos count={patient.unseenPhotosCount} />}
        {patient.needsDoctorReview && <MedicalProfileUpdated />}
        {patient.hasRequestedPhotos && <PhotoRequested />}
      </TableCell>
      <TableCell onClick={navigateToPatient} className={classes.patientRow}>
        {patient.status === CLEARED_STATUS ? (
          <span className={classes.completed}>Completed</span>
        ) : (
          <span className={classes.pending}>Pending</span>
        )}
      </TableCell>
    </TableRow>
  );
};

export const ProviderPatientsList = ({ practitionerId }: { practitionerId: string | number }) => {
  const initialFilters = {
    group: 'allPatients',
    view: 'all',
    filter: 'all',
    license: '',
    search: '',
    sortBy: '',
    sortDirection: '',
    practitionerId,
    gfeStatus: '',
    state: '',
    excludedStatus: '',
  };
  const [filters, setFilters] = useState<any>(initialFilters);
  const [search, setSearch] = useState('');
  const [gfeStatus, setGfeStatus] = useState<string>('');
  const classes = useStyles();

  const { results, isLoading, isError, fetchNextPage, hasNextPage, isFetching } = useCustomPatients(
    PHYSICIAN_DASHBOARD_KEY,
    filters
  );

  useEffect(() => {
    setFilters({ ...filters, ...{ search, group: gfeStatus || 'allPatients' } });
  }, [search, gfeStatus]);

  const patientsData = results?.pages.map(({ customers = [] }) => customers).flat() || [];

  const doSortBy = (column: string, direction: string) => {
    setFilters({
      ...filters,
      sortBy: column,
      sortDirection: direction,
    });
  };

  return (
    <div className={classes.providerPatientList} data-testid="physician-patient-list-main">
      <h3 className={classes.heading}>Patients ({isLoading ? '...' : results?.pages?.[0]?.meta?.totalCount})</h3>
      <div className={`${classes.flexRow} ${classes.filterContainer}`}>
        <FormControl size="small" variant="outlined">
          <InputLabel htmlFor="search" color="secondary" className={classes.search}>
            Search
          </InputLabel>
          <OutlinedInput
            data-cy="providerSearchInput"
            id="search"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton style={{ padding: 0 }}>
                  {isFetching ? (
                    <CircularProgress style={{ color: 'black' }} size={20} />
                  ) : (
                    <SearchIcon style={{ color: 'black' }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        <div className={classes.flexRow}>
          <FormControl size="small" className={classes.formControl} variant="outlined">
            <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>GFE Status</InputLabel>
            <SelectCustom
              data-cy="from"
              name="gfeStatus"
              value={gfeStatus}
              onChange={(e) => setGfeStatus(e.target.value as string)}
              label="GFE Status"
            >
              <MenuItem value={undefined}>
                <em>All</em>
              </MenuItem>
              <MenuItem value="notCleared">Pending</MenuItem>
              <MenuItem value="cleared">Completed</MenuItem>
            </SelectCustom>
          </FormControl>
        </div>
      </div>
      <PortraitTable
        columns={TABLE_COLUMNS}
        sortBy={filters.sortBy}
        sortDirection={filters.sortDirection}
        onSortChange={doSortBy}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        data={patientsData}
        rowComponent={(row) => <PatientRow patient={row} />}
        errorMessage={compile('generic.error_message', { action: 'fetching', element: 'patients' })}
        infiScroll
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
      />
    </div>
  );
};
