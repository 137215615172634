import React from 'react';
import { Box, Button, CircularProgress, Dialog, Typography, withStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from 'src/constants/inventory.constants';
import { formatCurrency } from 'src/utils/formatNumber';
import { ClassNameMap } from 'src/types/Dom';
import OrderConfirmDialogStyles from './OrderConfirmDialog.styles';

type Props = ClassNameMap & {
  open: boolean;
  closeDialog?: () => void;
  confirm: () => void | Promise<void>;
  isLegacy?: boolean;
  finalizing?: boolean;
  validating?: boolean;
  isLoading?: boolean;
  desiredDeliveryDate?: string | null;
  total?: number;
};

export const OrderConfirmDialog: React.FC<Props> = ({
  open = true,
  total = 0,
  closeDialog,
  confirm,
  classes,
  isLegacy,
  finalizing,
  validating,
  isLoading,
  desiredDeliveryDate,
}) => (
  <Dialog open={open} PaperProps={{ className: classes?.paper }} onBackdropClick={closeDialog}>
    <Box data-testid="order confirm dialog">
      <CheckCircle className={classes?.successIcon} viewBox="0 0 24 22" />
      {isLegacy ? (
        <>
          <Typography variant="h6">All set! Please click CONFIRM to submit your order.</Typography>
          <Typography component="p" data-testid="confirm delivery" variant="subtitle2">
            Your estimated date of delivery is {moment.utc(desiredDeliveryDate).format(DISPLAY_DATE_FORMAT)}.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h6">Total: {formatCurrency(total)}</Typography>
          <Typography component="p" data-testid="confirm delivery" variant="subtitle2">
            Please click CONFIRM to submit your order. You will also receive an email confirmation.
          </Typography>
        </>
      )}
      <Box height={6} margin="25px 0px 0px 6px" borderBottom="1px solid eee" />
      <Box className={classes?.modalButtons}>
        <Button
          data-cy="view"
          variant="outlined"
          className={classes?.modalButton}
          onClick={confirm}
          disabled={isLoading || finalizing || validating}
        >
          {validating && <CircularProgress size={20} />}
          {finalizing && 'Finalizing...'}
          {!finalizing && !validating && 'Confirm'}
        </Button>
      </Box>
    </Box>
  </Dialog>
);

export default withStyles(OrderConfirmDialogStyles)(OrderConfirmDialog);
