import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  warning: {
    position: 'relative',
    top: '6px',
    '& > path': {
      fill: 'red',
    },
    marginLeft: '3px',
  },
  actionButton: {
    position: 'relative',
    margin: '0 5px',
    fontSize: '16px',
  },
  unitPriceContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  undoButton: {
    cursor: 'pointer',
    fontSize: '20px',
    margin: '0 3px',
  },
  undoButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    fontSize: '16px',
  },
  editButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#12574D',
    fontSize: '16px',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
  },
  saveButton: {
    background: 'none',
    border: 'none',
    fontSize: '16px',
    color: '#12574D',
    cursor: 'pointer',
    marginLeft: '10px',
  },
}));
