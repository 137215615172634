import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  my0: {
    marginTop: 0,
    marginBottom: 0,
  },
  cardContent: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  beautyBankSection: {
    padding: '13px',
  },
  title: {
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
  },
  documentLink: {
    color: '#12574D',
    fontFamily: 'Messina Sans Regular',
    textDecoration: 'underline',
  },
}));
