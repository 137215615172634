import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  totalCost: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '15px 0',
    padding: 10,
    '& div': {
      margin: 'auto 15px auto auto'
    },
  },
  textCenter: {
    textAlign: 'center',
    '& th, td': {
      textAlign: 'center',
    },
    '& th:first-child, td:first-child': {
      textAlign: 'left',
    }
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 550px)',
    '&[data-fullheight="true"]': {
      maxHeight: 'calc(100vh - 350px)'
    }
  }
}))

export { useStyles, useStyles as default }