/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core';
import { CreateRounded } from '@material-ui/icons';
import { IPhoto } from '../../../interfaces/global.interfaces';
import { Lines } from '../../../interfaces/annotation.interfaces';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: 'fit-content',
    overflow: 'auto',
  },
  photoContainer: {
    width: 80,
    height: 100,
    marginBottom: '20px',
    marginRight: 10,
    padding: '5px 6px 8.9px',
    borderRadius: '5px',
    border: 'solid 2px #12574d',
    backgroundColor: '#e7eeed',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  photo: {
    width: 64,
    height: 64,
    objectFit: 'cover',
    marginBottom: 4,
  },
  iconContainer: {
    position: 'absolute',
    right: 1,
    borderRadius: '50%',
    backgroundColor: '#45ce89',
    width: '25px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 1,
  },
  icon: {
    color: 'white',
    fontSize: '16px',
  },
  photoSliders: {
    width: '100%',
    paddingLeft: 0,
  },
  containerDisplaySlide: {
    display: 'flex',
    verticalAlign: 'top',
  },
}));

interface Props {
  photos: IPhoto[];
  setSelectedPhoto: (photo: IPhoto) => void;
  selectedPhoto: IPhoto;
  lines: Lines;
  onlyShowAnnotated: boolean;
  itemsPhotos?: number;
}

export const PhotoSlider = (props: Props) => {
  const { photos, setSelectedPhoto, selectedPhoto, lines, onlyShowAnnotated, itemsPhotos } = props;
  const classes = useStyles();

  const parseDateToString = (photo: IPhoto): string => {
    const { createdAt } = photo;
    return moment(createdAt).format('MM/DD/YYYY');
  };

  const Photo = ({ photo }: { photo: IPhoto }) => (
    <div
      style={{ borderColor: selectedPhoto?.id === photo.id ? '#12574d' : 'transparent' }}
      className={classes.photoContainer}
      role="presentation"
      onClick={() => {
        setSelectedPhoto(photo);
      }}
    >
      {!!lines[photo.id]?.length && (
        <div className={classes.iconContainer}>
          <CreateRounded className={classes.icon} />
        </div>
      )}
      <img src={photo.thumbnailUrl} alt="Patient" className={classes.photo} />
      <small>{parseDateToString(photo)}</small>
    </div>
  );

  const settingsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: itemsPhotos,
    slidesToScroll: itemsPhotos,
  };

  const photosSlider = photos
    .filter(({ id }) => (onlyShowAnnotated ? lines[id]?.length : true))
    .map((photo) => <Photo key={photo.id} photo={photo} />);

  return (
    <>
      <div className={classes.photoSliders}>
        {itemsPhotos && itemsPhotos < photosSlider.length ? (
          <Slider {...settingsSlider}>{photosSlider}</Slider>
        ) : (
          <div className={classes.containerDisplaySlide}> {photosSlider}</div>
        )}
      </div>
    </>
  );
};

PhotoSlider.defaultProps = {
  itemsPhotos: 0,
};
