import { MEDSPA_V4_PATH } from 'src/constants/apiRoutes.constants';
import { showSnackbar } from 'src/utils/global';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import {
  Care,
  IMedspaCareMessage,
  IMedspaCareMessagesAPI,
  IMedspaCustomCareMessage,
} from 'src/interfaces/IMedspaCareMessages';
import Api from './Api';
import { FollowUp } from './IMedspaCustomCareMessage';

const MedspaCareMessages = {
  async getMedspaCareMessages({ userGroupId }: { userGroupId: number }): Promise<IMedspaCareMessage[]> {
    const { success, careMessages }: IMedspaCareMessagesAPI = await Api.get(
      `${MEDSPA_V4_PATH}/${userGroupId}/care_messages`
    );
    if (!success) {
      showSnackbar('There was an error fetching care messages.', SNACKBAR_ERROR);
      return [];
    }
    return careMessages;
  },
  async getMedspaCustomCareMessages({ userGroupId }: { userGroupId: number }): Promise<IMedspaCustomCareMessage[]> {
    try {
      const { careMessages }: { careMessages: IMedspaCustomCareMessage[] } = await Api.get(
        `${MEDSPA_V4_PATH}/${userGroupId}/care_messages/custom_messages`
      );
      return careMessages;
    } catch {
      showSnackbar('There was an error fetching custom care messages.', SNACKBAR_ERROR);
      return [];
    }
  },
  async updateMedspaCareMessages({
    userGroupId,
    documentId,
    params,
  }: {
    userGroupId: number;
    documentId: number;
    params: { preCare: Care; postCare: Care; followUp: FollowUp; name: string };
  }): Promise<IMedspaCustomCareMessage[]> {
    try {
      const { careMessages }: { careMessages: IMedspaCustomCareMessage[] } = await Api.put(
        `${MEDSPA_V4_PATH}/${userGroupId}/care_messages/${documentId}`,
        params
      );
      showSnackbar('Care message was updated.', SNACKBAR_SUCCESS);
      return careMessages;
    } catch {
      showSnackbar('There was an error updating custom care messages.', SNACKBAR_ERROR);
      return [];
    }
  },

  async createMedspaCareMessages({
    userGroupId,
    params,
  }: {
    userGroupId: number;
    params: { preCare: Care; postCare: Care; followUp: FollowUp; name: string };
  }): Promise<IMedspaCustomCareMessage[]> {
    try {
      const { careMessages }: { careMessages: IMedspaCustomCareMessage[] } = await Api.post(
        `${MEDSPA_V4_PATH}/${userGroupId}/care_messages`,
        params
      );
      showSnackbar('Care message was created.', SNACKBAR_SUCCESS);
      return careMessages;
    } catch {
      showSnackbar('There was an error creating custom care messages.', SNACKBAR_ERROR);
      return [];
    }
  },
};

export default MedspaCareMessages;
