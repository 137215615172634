import React from 'react';
import { GroupingPanel } from '@devexpress/dx-react-scheduler-material-ui';
import { Avatar, Box, styled, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { Edit as EditIcon } from '@material-ui/icons';
import { EHRTypography } from 'src/components/ui/v1';
import { IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';
import moment from 'moment';
import { groupingStyles } from './groupingStyles';
import { classes } from './classes';

// @ts-expect-error
const StyledGroupingPanelCell = styled(GroupingPanel.Cell)(groupingStyles);

const GroupingPanelCell = ({
  group,
  provider,
  onOverrideHoursClick,
  workingHours,
  ...restProps
}: GroupingPanel.CellProps & {
  provider: IPractitioner;
  onOverrideHoursClick: () => void;
  workingHours: IWorkingHour[];
}) => {
  const newGroup = { ...group, text: '' };
  const { firstName, lastName } = provider;
  const providerFullName = [firstName, lastName].join(' ');
  const { groupOrientation } = restProps;
  const orientation = groupOrientation === 'Vertical' ? groupOrientation : 'Horizontal';
  return (
    <StyledGroupingPanelCell
      className={classNames({
        [classes.providerHeader]: orientation === 'Horizontal',
        [classes.providerHeaderVertical]: orientation === 'Vertical',
      })}
      group={newGroup}
      {...restProps}
    >
      <Box height="100%">
        <Tooltip title={providerFullName} arrow>
          <Box
            display="flex"
            flexDirection="column"
            gridGap="1rem"
            alignItems="center"
            textAlign="center"
            height="100%"
            justifyContent="center"
          >
            <Box className={classes.providerNameContainer}>
              <Box>
                <Avatar src={provider.profileImageUrl} />
              </Box>
              <Box width="100%">
                <Box className={classNames(classes.providerName)}>{providerFullName}</Box>
                <Box>
                  <EHRTypography dataCy="label-practitioner-working-hours" variant="label">
                    {workingHours?.map((workingHour) => (
                      <Box>
                        {moment(workingHour.startDate).format('hh:mm A')}
                        &nbsp;&mdash;&nbsp;
                        {moment(workingHour.endDate).format('hh:mm A')}
                      </Box>
                    ))}
                  </EHRTypography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Tooltip>
        <Box
          position="absolute"
          top="0.25rem"
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="2rem"
          height="1.5rem"
          style={{ cursor: 'pointer', backgroundColor: '#fff' }}
          onClick={onOverrideHoursClick}
        >
          <EditIcon />
        </Box>
      </Box>
    </StyledGroupingPanelCell>
  );
};

export { GroupingPanelCell };
