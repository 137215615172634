import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { ReactComponent as UploadProfileIcon } from 'src/assets/images/upload-profile-image.svg';
import { Box, Button, Chip, FormHelperText, Grid, InputLabel, MenuItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { snakeCase } from 'lodash';
import { IPractitioner } from '../../../interfaces/IPractitioner';
import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE,
  PROFILE_IMAGE,
  SIGNATURE_IMAGE,
  VALID_EMAIL,
  VALID_PHONE,
  VALID_PHONE_REGEX,
} from '../../../constants/schemaForm.constants';
import { useStyles } from '../../DashboardAdministrator/Medspas/MedspaModal.styles';
import { EHRButton, EHRCheckbox, EHRCRUDModal, EHRSelect, EHRTextField, EHRTypography } from '../../ui/v1';
import DragAndDropComponent from '../../DragAndDrop';
import { MEDICAL_LICENSES } from '../../../pages/AccountSetting/MoreDetails';
import { useCreateMedspaProvider } from '../../../hooks/practitioner/usePractitioner';
import { useEditMedspaProviderMutation } from '../../../hooks/mutations/useEditMedspaProvider';
import { useMedspaAdminsByUserGroup } from '../../../hooks/queries/useMedspaAdmins';
import { IMedspaAdminListEntry } from '../../../interfaces/IMedspaAdminList';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { useMedspaLocations } from '../../../hooks/queries/medspaAdmins/useMedspaLocations';
import { useMedspaLocationPractitioners } from '../../../hooks/queries/medspaAdmins/useMedspaLocationPractitioners';
import { IMedspaLocation } from '../../../interfaces/ILocation';
import { IMedspaLocationPractitioner } from '../../../interfaces/IMedspaLocationPractitioner';
import MultipleSelector from '../../common/Custom/MultipleSelector';

const ProviderSchema = yup.object().shape(
  {
    firstName: yup.string().required(FIRST_NAME),
    lastName: yup.string().required(LAST_NAME),
    email: yup.string().required(EMAIL).email(VALID_EMAIL),
    phone: yup.string().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
    licenses: yup.string(),
    position: yup.string(),
    profileImage: yup.string().required(PROFILE_IMAGE),
    signatureImage: yup.string().required(SIGNATURE_IMAGE),
    isMedspaAdmin: yup.boolean(),
    medspaLocationIds: yup.array(),
    provisionMedspaAdminAccount: yup
      .boolean()
      .when(['isMedspaAdmin', 'linkedMedspaAdminId'], (isMedspAdmin: boolean, linkedMedspaAdminId: string) => {
        if (isMedspAdmin) {
          if (linkedMedspaAdminId) {
            return yup.boolean();
          }
          return yup
            .boolean()
            .required('You must select an existing MedSpa admin or select you want to provision a new admin account.');
        }
        return yup.boolean();
      }),
    linkedMedspaAdminId: yup
      .boolean()
      .when(
        ['isMedspaAdmin', 'provisionMedspaAdminAccount'],
        (isMedspAdmin: boolean, provisionMedspaAdminAccount: boolean) => {
          if (isMedspAdmin) {
            if (provisionMedspaAdminAccount) {
              return yup.string();
            }
            return yup
              .string()
              .required(
                'You must select an existing MedSpa admin or select you want to provision a new admin account.'
              );
          }
          return yup.string();
        }
      ),
    // @ts-ignore
  },
  ['provisionMedspaAdminAccount', 'linkedMedspaAdminId']
);

export const UpsertMedspaProviderModal = ({
  open,
  onClose,
  practitioner,
  onSuccessCallback,
}: {
  open: boolean;
  onClose: () => void;
  practitioner?: IPractitioner;
  onSuccessCallback?: () => void;
}) => {
  const { userGroupId } = useSelector(({ auth }: any) => auth);
  const classes = useStyles();
  const [userActive, setUserActive] = useState<boolean>(true);
  const [profileImageFile, setProfileImageFile] = useState<File>();
  const [signatureImageFile, setSignatureImageFile] = useState<File>();
  const [signaturePreview, setSignaturePreview] = useState<string | null>(null);
  const [profilePreview, setProfilePreview] = useState<string | null>(null);
  const { mutateAsync: createMedspaProvider, isLoading: isCreatingMedspaProvider } =
    useCreateMedspaProvider(userGroupId);
  const { mutateAsync: updateMedspaProvider, isLoading: isUpdatingProvider } = useEditMedspaProviderMutation();
  const {
    data: medspaAdmins = [],
    isLoading: isLoadingMedspaAdmins,
    isFetching: isFetchingMedspaAdmins,
  } = useMedspaAdminsByUserGroup(userGroupId);
  const [isMedspaAdmin, setIsMedspaAdmin] = useState<boolean>(false);
  const [linkedMedspaAdmin, setLinkedMedspaAdmin] = useState<IMedspaAdminListEntry>();
  const [provisionAdminAccount, setProvisionAdminAccount] = useState<boolean>();
  const {
    data: { medspaLocations = [] },
    isFetching: isFetchingmedspaLocations,
    isLoading: isLoadingMedspaLocations,
  } = useMedspaLocations();
  const {
    data: { medspaLocationPractitioners = [] },
    isLoading: isLoadingLocations,
  } = useMedspaLocationPractitioners(practitioner?.id, !!practitioner?.id && medspaLocations.length > 0);

  const isLoading = isCreatingMedspaProvider || isUpdatingProvider || isLoadingMedspaLocations || isLoadingLocations;

  const availableMedspaAdmins =
    medspaAdmins?.filter((medspaAdmin: IMedspaAdminListEntry) => !medspaAdmin.linkedPractitionerId) || [];

  const { control, handleSubmit, errors, setValue, getValues, reset } = useForm({
    resolver: yupResolver(ProviderSchema),
    defaultValues: {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      position: '',
      licenses: '',
      activeForScheduling: false,
      profileImage: '',
      signatureImage: '',
      isMedspaAdmin: false,
      linkedMedspaAdminId: '',
      medspaLocationIds: [],
      provisionMedspaAdminAccount: false,
    },
  });

  useEffect(() => {
    if (practitioner && medspaAdmins && medspaAdmins.length > 0) {
      const matchingAdmin = medspaAdmins.find(
        (medspaAdmin: IMedspaAdminListEntry) => medspaAdmin.linkedPractitionerId === practitioner.id
      );
      setLinkedMedspaAdmin(matchingAdmin);
      setValue('linkedMedspaAdminId', matchingAdmin?.id?.toString() || '');
      if (matchingAdmin) {
        setIsMedspaAdmin(true);
      } else {
        setIsMedspaAdmin(false);
        setValue('provisionMedspaAdminAccount', false);
      }
    }
  }, [practitioner, medspaAdmins]);

  useEffect(() => {
    if (practitioner && practitioner.id !== getValues('id')) {
      setValue(
        'medspaLocationIds',
        medspaLocationPractitioners.map(
          (medspaLocationPractitioner: IMedspaLocationPractitioner) => medspaLocationPractitioner.medspaLocationId
        )
      );
    }
  }, [practitioner, medspaLocationPractitioners]);

  useEffect(() => {
    if (practitioner && practitioner.id !== getValues('id')) {
      setValue('firstName', practitioner.firstName);
      setValue('lastName', practitioner.lastName);
      setValue('email', practitioner.email);
      setValue('phone', practitioner.phone);
      setValue('licenses', practitioner.licenses);
      setValue('position', practitioner.practitionerInformation?.position);
      setValue('signatureImage', practitioner.signatureImageUrl || '');
      setValue('profileImage', practitioner.profileImageUrl || '');
      setValue('active', !practitioner.inactive);
      setSignaturePreview(practitioner.signatureImageUrl || '');
      setProfilePreview(practitioner.profileImageUrl || '');
      setUserActive(!practitioner.inactive);
    }
  }, [practitioner]);

  const handleProfilePicChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('profileImage', file, { shouldValidate: true });
        setProfileImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('profileImage', '', { shouldValidate: true });
    }
  };

  const locationListOptions = useMemo(
    () =>
      medspaLocations?.map((medspaLocation: IMedspaLocation) => ({
        name: `${medspaLocation.name}, ${medspaLocation.address}, ${medspaLocation.city}, ${medspaLocation.state},
         ${medspaLocation.zipCode}`,
        value: medspaLocation.id,
      })) || [],
    [medspaLocations]
  );

  const renderTags = (tagValue: any, getTagProps: any) =>
    tagValue.map((option: any, index: number) => {
      if (
        medspaLocationPractitioners.find(
          (medspaLocationPractitioner: IMedspaLocationPractitioner) =>
            medspaLocationPractitioner.medspaLocationId === option.value
        )
      ) {
        return <Chip label={option.name} {...getTagProps({ index })} disabled />;
      }
      return <Chip label={option.name} {...getTagProps({ index })} />;
    });

  const handleSignatureChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignaturePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('signatureImage', file, { shouldValidate: true });
        setSignatureImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('signatureImage', '', { shouldValidate: true });
    }
  };

  const handleClose = () => {
    setIsMedspaAdmin(false);
    setProvisionAdminAccount(undefined);
    setValue('isMedspaAdmin', false);
    setValue('provisionMedspaAdminAccount', false);
    setValue('linkedMedspaAdminId', '');
    if (!practitioner) {
      setProfilePreview(null);
      setSignaturePreview(null);
      reset();
    }
    onClose();
  };

  const buttonText = practitioner ? 'Edit' : 'Add';

  const onSubmit = async (formData: any) => {
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      position: formData.position,
      licenses: formData.licenses,
      inactive: practitioner ? !userActive : false,
      medspaLocationIds: formData.medspaLocationIds,
      linkedMedspaAdminId: getValues('linkedMedspaAdminId') || '',
      provisionMedspaAdminAccount: getValues('provisionMedspaAdminAccount') || false,
    };

    const userFormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      userFormData.append(snakeCase(key), value);
    });

    if (signatureImageFile) {
      userFormData.append('signature_image', signatureImageFile);
    }
    if (profileImageFile) {
      userFormData.append('profile_image', profileImageFile);
    }

    if (practitioner?.id) {
      await updateMedspaProvider({
        params: userFormData,
        userId: practitioner.id,
        userGroupId,
      });
    } else {
      await createMedspaProvider(userFormData);
      reset();
    }
    onSuccessCallback?.();
    handleClose();
  };

  return (
    <EHRCRUDModal dataCy="modal-new-medspa-user" open={open} onClose={handleClose}>
      <EHRCRUDModal.Title
        dataCy="modal-new-medspa-user-modal-title"
        title={practitioner ? 'Edit Provider' : 'New Provider'}
        handleClose={handleClose}
      />

      <EHRCRUDModal.Body dataCy="modal-new-medspa-modal-body">
        <Box display="flex" justifyContent="space-between" flexDirection="column" gridGap="0.25rem" width="100%">
          <Box
            className={classNames(classes.headShotContainer, errors?.profileImage ? 'ehrUploadContainerError' : null)}
          >
            <EHRTypography
              dataCy="label-upload-headsot"
              variant="label"
              error={!!errors?.profileImage}
              style={{ marginBottom: '0.5rem' }}
            >
              Upload Headshot
            </EHRTypography>
            <input
              accept="image/*"
              id="profile-photo-upload"
              type="file"
              name="profilePicture"
              style={{ display: 'none' }}
            />
            <label htmlFor="profile-photo-upload" className={classes.uploadProfileImageContainer}>
              {profilePreview ? (
                <Box display="flex" alignItems="center">
                  <img src={profilePreview} alt="Profile preview" className={classes.profileImage} />
                </Box>
              ) : (
                <UploadProfileIcon className={classes.profileImage} />
              )}
              <DragAndDropComponent
                hideDocumentIcon
                height="80px"
                hideRemoveFile
                acceptedFileType="image/png,image/jpg,image/jpeg"
                onFileChange={handleProfilePicChange}
                error={!!errors?.profileImage}
              />
            </label>
            <FormHelperText error>{errors?.profileImage?.message || ' '}</FormHelperText>
          </Box>

          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="firstName"
                rules={{ required: 'First name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-firstName"
                    data-cy="firstName"
                    name="firstName"
                    onChange={onChange}
                    value={value}
                    label="First Name"
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message || ' '}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name="lastName"
                rules={{ required: 'Last name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-lastName"
                    data-cy="lastName"
                    name="lastName"
                    onChange={onChange}
                    value={value}
                    label="Last Name"
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message || ' '}
                  />
                )}
              />
            </Box>
          </Box>

          <Box>
            <Controller
              control={control}
              name="email"
              rules={{ required: 'Email is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-email"
                  data-cy="email"
                  name="email"
                  onChange={onChange}
                  value={value}
                  label="Email"
                  error={!!errors?.email}
                  helperText={errors?.email?.message || ' '}
                />
              )}
            />
          </Box>

          <Box>
            <Controller
              control={control}
              name="phone"
              rules={{ required: 'Phone number is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-phone"
                  data-cy="phone"
                  name="phone"
                  onChange={onChange}
                  value={value}
                  label="Phone Number"
                  error={!!errors?.phone}
                  helperText={errors?.phone?.message || ' '}
                />
              )}
            />
          </Box>
          {!!practitioner && (
            <Box className={classes.medspaInfoInput}>
              <EHRTypography dataCy="label-upload-signature" variant="label">
                Status
              </EHRTypography>
              <Box className={classes.medspaActiveStatusContainer}>
                <Button
                  className={userActive ? classes.medspaActiveButton : classes.medspaInactiveButton}
                  type="button"
                  onClick={() => setUserActive(true)}
                >
                  Active
                </Button>
                <Button
                  className={userActive ? classes.medspaInactiveButton : classes.medspaActiveButton}
                  type="button"
                  onClick={() => setUserActive(false)}
                >
                  Inactive
                </Button>
              </Box>
            </Box>
          )}
          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="licenses"
                render={({ onChange, value }) => (
                  <EHRSelect
                    dataCy="select-licenses"
                    name="licenses"
                    value={value}
                    onChange={onChange}
                    label="Licenses"
                    fullWidth
                    error={!!errors?.licenses}
                    helperText={errors?.licenses?.message || ' '}
                  >
                    {MEDICAL_LICENSES.map((licenseType) => (
                      <MenuItem value={licenseType} key={licenseType}>
                        {licenseType}
                      </MenuItem>
                    ))}
                  </EHRSelect>
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name="position"
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-position"
                    name="position"
                    onChange={onChange}
                    value={value}
                    label="Position"
                    error={!!errors?.position}
                    helperText={errors?.position?.message || ' '}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="medspaLocationIds"
                render={({ onChange, value }) => (
                  <Grid item xs={12}>
                    <InputLabel color="secondary" focused={false}>
                      Locations
                    </InputLabel>
                    <MultipleSelector
                      autocomplete
                      disabled={isFetchingmedspaLocations}
                      name="medspaLocationIds"
                      control={control}
                      value={value}
                      onChange={onChange}
                      options={locationListOptions}
                      renderTags={renderTags}
                      disableClearable
                    />
                  </Grid>
                )}
              />
            </Box>
          </Box>

          <Box
            className={classNames(
              classes.signatureImageContainer,
              errors?.signatureImage ? 'ehrUploadContainerError' : null
            )}
          >
            <Box className={classes.signatureImageHeader}>
              <EHRTypography
                dataCy="label-upload-signature"
                variant="label"
                error={!!errors?.signatureImage}
                style={{ marginBottom: '0.5rem' }}
              >
                Upload Signature
              </EHRTypography>

              {signaturePreview && (
                <Button
                  type="button"
                  onClick={() => {
                    setSignaturePreview(null);
                    setValue('signatureImage', '', { shouldValidate: true });
                  }}
                  className={classes.signatureUpdate}
                >
                  Update
                </Button>
              )}
            </Box>

            {signaturePreview ? (
              <img src={signaturePreview} alt="Signature preview" className={classes.signatureImage} />
            ) : (
              <DragAndDropComponent
                hideDocumentIcon
                height="80px"
                hideRemoveFile
                acceptedFileType="image/png,image/jpg,image/jpeg"
                onFileChange={handleSignatureChange}
                error={!!errors?.signatureImage}
              />
            )}
            <FormHelperText error>{errors?.signatureImage?.message || ' '}</FormHelperText>
          </Box>
        </Box>
        {
          // eslint-disable-next-line no-nested-ternary
          isFetchingMedspaAdmins || isLoadingMedspaAdmins ? (
            <ShortMultipleSkeleton length={1} />
          ) : linkedMedspaAdmin ? (
            <Box>
              Linked Admin: {linkedMedspaAdmin?.firstName} {linkedMedspaAdmin?.lastName}
            </Box>
          ) : (
            <Box>
              <Box className={classes.operatingProviderContainer}>
                <Controller
                  name="isMedspaAdmin"
                  control={control}
                  defaultValue={false}
                  render={() => (
                    <EHRCheckbox
                      dataCy="label-is-medspa-admin"
                      label="MedSpa Admin"
                      onChange={(e) => {
                        setValue('isMedspaAdmin', e.target.checked);
                        setIsMedspaAdmin(e.target.checked);
                      }}
                      checked={isMedspaAdmin}
                      className={classes.operatingProviderCheckbox}
                      disabled={!!linkedMedspaAdmin}
                    >
                      <Typography variant="body2" className={classes.operatingProviderSubtext}>
                        If this is checked, you can select to link this provider to an existing Medspa Admin or have
                        this provider be created as a Medspa Admin with a operating provider.
                      </Typography>
                    </EHRCheckbox>
                  )}
                />
              </Box>
              {isMedspaAdmin && (
                <Box className={classes.medspaInfoInput}>
                  <EHRTypography dataCy="label-upload-signature" variant="label">
                    Link to existing MedSpa admin without an operating provider?
                  </EHRTypography>
                  <Box className={classes.medspaActiveStatusContainer}>
                    <Button
                      className={
                        provisionAdminAccount === false ? classes.medspaActiveButton : classes.medspaInactiveButton
                      }
                      type="button"
                      onClick={() => {
                        setValue('provisionMedspaAdminAccount', false);
                        setValue('linkedMedspaAdminId', '');
                        setProvisionAdminAccount(false);
                      }}
                      disabled={!availableMedspaAdmins.length}
                      style={{ textTransform: 'none' }}
                    >
                      Yes, link existing MedSpa admin
                    </Button>
                    <Button
                      className={
                        provisionAdminAccount === true ? classes.medspaActiveButton : classes.medspaInactiveButton
                      }
                      type="button"
                      onClick={() => {
                        setProvisionAdminAccount(true);
                        setValue('provisionMedspaAdminAccount', true);
                      }}
                      style={{ textTransform: 'none' }}
                    >
                      No, provision new MedSpa admin
                    </Button>
                  </Box>
                </Box>
              )}
              {isMedspaAdmin && provisionAdminAccount === false && (
                <Box>
                  <Controller
                    control={control}
                    name="linkedMedspaAdminId"
                    render={({ onChange, value }) => (
                      <EHRSelect
                        dataCy="select-linkedMedspaAdminId"
                        name="linkedMedspaAdminId"
                        value={value}
                        onChange={onChange}
                        label="Select Medspa Admin"
                        fullWidth
                        error={!!errors?.linkedMedspaAdminId}
                      >
                        {availableMedspaAdmins.map((medspaAdmin) => (
                          <MenuItem value={medspaAdmin.id} key={medspaAdmin.id}>
                            {medspaAdmin.firstName} {medspaAdmin.lastName}
                          </MenuItem>
                        ))}
                      </EHRSelect>
                    )}
                  />
                </Box>
              )}
              <FormHelperText error>
                {errors?.provisionMedspaAdminAccount?.message || errors?.linkedMedspaAdminId?.message || ''}
              </FormHelperText>
            </Box>
          )
        }
      </EHRCRUDModal.Body>

      <EHRCRUDModal.Footer dataCy="modal-footer-new-medspa">
        <Box display="flex" width="100%">
          <EHRButton
            dataCy="btn-add-modal-new-medspa-user"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            color="primary"
            text={isLoading ? 'Processing...' : buttonText}
            fullWidth
          />
        </Box>
      </EHRCRUDModal.Footer>
    </EHRCRUDModal>
  );
};
