import IConversationThread from '../interfaces/IConversationThread';
import Id from '../types/Id';
import Api from './Api';

const BASE_PATH = '/customers';

type createParamsType = {
  patientId: Id;
  message: string;
};

export const getPatientMessages = async ({ patientId }: { patientId: Id }) => {
  const endpoint = `/patient-profile/${patientId}/messages-threads`;

  const { messagesThreads, error } = await Api.get(endpoint);

  if (error) {
    throw error;
  }

  return messagesThreads.messagesThreads;
};

export const createThread = async ({ patientId }: { patientId: number }): Promise<IConversationThread> => {
  const endpoint = `${BASE_PATH}/${patientId}/messages/new_thread`;

  const { thread, success, error } = await Api.post(endpoint, {});

  if (error || !success) {
    throw error || 'thread already open';
  }

  return thread;
};

export const closeThread = async ({
  patientId,
  threadId,
}: {
  patientId: number;
  threadId: number;
}): Promise<boolean> => {
  const endpoint = `${BASE_PATH}/${patientId}/messages/close-thread/${threadId}`;

  const { success, error } = await Api.post(endpoint, {});

  if (error || !success) {
    throw error || 'cant close the conversation';
  }

  return success;
};

export const createMessage = async ({ patientId, message }: createParamsType) => {
  const endpoint = `${BASE_PATH}/${patientId}/messages`;

  const { message: createdMessage, error } = await Api.post(endpoint, {
    message,
  });

  if (error) {
    throw error;
  }

  return createdMessage;
};

export const markMessagesAsRead = async ({ patientId }: { patientId: number }) => {
  const endpoint = `${BASE_PATH}/${patientId}/messages/mark-as-read`;

  const { result, error } = await Api.post(endpoint, {});

  if (error) {
    throw error;
  }

  return result;
};
