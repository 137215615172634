import { useQuery } from 'react-query';
import { CUSTOM_CARE_MESSAGE } from '../constants/reactQuery.keys';
import Messaging from '../services/CareMessage';

const { getCustomCareMessages } = Messaging;

export function useCustomCareMessages() {
  return useQuery([CUSTOM_CARE_MESSAGE], getCustomCareMessages);
}

export { CUSTOM_CARE_MESSAGE } from '../constants/reactQuery.keys';
