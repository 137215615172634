import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: '#fafafa',
  },
  tab: {
    padding: '0 65px',
  },
  selectedTab: {
    backgroundColor: '#f16c00',
  },
  gridItem: {
    width: '33.33%',
    marginLeft: '0px',
  },
  serviceGroups: {
    padding: '20px',
  },
  serviceItem: {
    border: '1px solid purple',
  },
  serviceTitle: {
    opacity: '0.71',
    fontFamily: 'Messina Sans Regular',
    lineHeight: 'normal',
    color: '#12574d',
  },
  checkboxLabel: {
    fontSize: '.85rem',
    fontFamily: 'Messina Sans Regular !important',
  },
  checkbox: {
    color: '#12574d',
  },
  accordion: {
    boxShadow: 'none',
  },
  accordionSummary: {
    border: '0',
    boxShadow: 'none',
    borderRadius: 'inherit',
    padding: '0px 8px',
    minHeight: '45px !important',
    display: 'inline-block !important',
  },
  accordionDetails: {
    border: '0',
    width: '100%',
    textAlign: 'left',
    display: 'inline-block',
    padding: '0 8px 8px 8px',
  },
  root: {
    flexGrow: 1,
    margin: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%',
    background: '#FAFAFA',
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
}));
