import API_ROUTES, { MEDSPA_V4_PATH } from '../constants/apiRoutes.constants';
import { ICustomerViewParams } from '../interfaces/CustomerParams';
import { IPatientView } from '../types/Patient';
import Api from './Api';

const PatientView = {
  async getPatients(paramsQuery: ICustomerViewParams): Promise<IPatientView> {
    const { isMedspaProvider, practitionerId } = paramsQuery;

    if (isMedspaProvider && practitionerId) {
      const { customers } = await Api.get(`${API_ROUTES.PRACTITIONERS_V4_PATH}/${practitionerId}/customers`, {
        ...paramsQuery,
        status: paramsQuery?.status === 'all_patients' ? '' : paramsQuery.status,
      });
      return customers;
    }

    const { results } = await Api.get(API_ROUTES.CUSTOMER_VIEW, paramsQuery);

    return results;
  },
  async getPatientsCount(paramsQuery: ICustomerViewParams): Promise<number> {
    const { count } = await Api.get(API_ROUTES.CUSTOMER_VIEW_COUNT, paramsQuery);
    return count;
  },
  async getMedspaPatients(userGroupId: string, paramsQuery: any) {
    const response = await Api.get(`${MEDSPA_V4_PATH}/${userGroupId}/customers`, paramsQuery);
    return response;
  },
  async getMedspaProviderPatients(practitionerId: string | number, paramsQuery: any) {
    const response = await Api.get(`v4/practitioners/${practitionerId}/customers`, paramsQuery);
    return response;
  },
  async getPatient(id: number): Promise<IPatientView> {
    const { customer } = await Api.get(API_ROUTES.CUSTOMER_VIEW_SHOW(id));
    return customer;
  },
  async getPatientServiceRequests(id: number): Promise<any> {
    const { serviceRequests } = await Api.get(API_ROUTES.CUSTOMER_SERVICE_REQUESTS(id));
    return serviceRequests.serviceRequests;
  },
};

export default PatientView;
