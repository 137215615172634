import { UseMutationResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { CARE_MESSAGES, CARE_MESSAGE_TEMPLATES } from '../../constants/reactQuery.keys';
import CareMessages from '../../services/CareMessages';
import compile from '../../utils/toastMessagesCompiler';

export function useCareMessages() {
  const response = useQuery([CARE_MESSAGES], () => CareMessages.getCareMessages());
  return {
    ...response,
    data: response.data,
  };
}

export function useCareMessageTemplates() {
  const response = useQuery([CARE_MESSAGE_TEMPLATES], () => CareMessages.getCareMessageTemplates());
  return {
    ...response,
    data: response.data,
  };
}

export const useUpdateCareMessageMutation = (successCallback?: () => void): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation((params: any) => CareMessages.updatePractitionerCareMessage(params), {
    onSuccess: (success: boolean) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }
      queryClient.invalidateQueries([CARE_MESSAGES]);
      showSnackbar(
        compile('generic.success_message', { element: 'Care Message', action: 'updated' }),
        SNACKBAR_SUCCESS
      );
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useCreateCareMessageMutation = (successCallback?: (data: any) => void): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation((params: any) => CareMessages.createPractitionerCareMessage(params), {
    onSuccess: ({ success, careMessages }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }
      queryClient.invalidateQueries([CARE_MESSAGES]);
      showSnackbar(
        compile('generic.success_message', { element: 'Care Message', action: 'created' }),
        SNACKBAR_SUCCESS
      );
      successCallback?.({ careMessages });
    },
    onError: useReactQueryOnErrorHandler,
  });
};
