import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  label: {
    fontSize: '15px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: '#000000',
    whiteSpace: 'pre-line',
  },
  incompleteLabel: {
    color: '#ff5252',
  },
  value: {
    fontFamily: 'Messina Sans Regular',
    fontWeight: 'normal',
  },
}));

interface Detail {
  label: string;
  value: string;
  hide?: boolean;
  double?: boolean;
}

type Props = {
  details: Detail[];
};

export const Details = ({ details }: Props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      {details.map((detail: Detail) => {
        const className = !detail.value ? `${classes.label} ${classes.incompleteLabel}` : classes.label;

        return !detail.hide ? (
          <React.Fragment key={detail.label}>
            <Grid item md={4} xs={4}>
              <label className={className} htmlFor="label-contact">
                {detail.label}
              </label>
            </Grid>
            <Grid item md={8} xs={8}>
              <label data-cy="label" className={`${classes.label} ${classes.value}`} htmlFor="label-contact">
                {detail.value}
              </label>
            </Grid>
          </React.Fragment>
        ) : null;
      })}
    </Grid>
  );
};
