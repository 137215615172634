import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button , makeStyles } from '@material-ui/core';

import { ReferralCreditModal } from '../ReferralCreditModal';
import { formatNumber } from '../../../utils/checkout.utils';
import { TOTAL_AMOUNT_REDEEMABLE, AMOUNT_FOR_REFERRER } from '../../../constants/general.constants';
import { SQUARE_STATUS } from '../../../constants/checkout.constants';

const useStyles = makeStyles(() => ({
  mainSection: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0 5px 0'
  },
  referralCreditTitle: {
    width: '30%',
    marginRight: '15px'
  },
  availableCreditSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: '15px'
  },
  availableLeft: {
    display: 'flex',
    alignItems: 'center'
  },
  availableRight: {
    alignSelf: 'center'
  },
  availableLabel: {
    marginRight: '19px'
  },
  plusButton: {
    padding: '5px',
    backgroundColor: '#E7EEED',
    minWidth: 0,
    fontSize: '30px',
    height: '24px',
    width: '24px',
    paddingTop: 0
  }
}));

interface Props {
  getReferrals: (referredIds: number[], selectedIndexes: number[]) => void;
}

export const ReferralCredit = ({ getReferrals }: Props) => {
  const classes = useStyles();
  const { notRedeemed, redeemed } = useSelector((store: any) => store.referrals);
  const { hasReferred, isAdvocate } = useSelector(({ patient }: any) => patient);
  const { referralsChunkIndex, transactionStatus } = useSelector((store: any) => store.newServiceVisit.checkout);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

  useEffect(() => {
    setSelectedIndexes(referralsChunkIndex);
  }, [referralsChunkIndex]);

  const getAvailableAmount = (): number => {
    /* if is advocate can redeem 250 (5 referral)
     * if is not advocate but has referred can redeem by 50 (1 referred)
     */
    if (!isAdvocate && hasReferred) {
      return getAvailableCount() * AMOUNT_FOR_REFERRER;
    }
    return getAvailableCount() * TOTAL_AMOUNT_REDEEMABLE;
  }

  const getAvailableCount = (): number => {
    if (!isAdvocate && hasReferred) {
      return transactionStatus === SQUARE_STATUS.OK ? notRedeemed.length : notRedeemed.length - selectedIndexes.length;
    }
    if (transactionStatus === SQUARE_STATUS.OK) {
      return Math.trunc(notRedeemed.length / 5);
    }
    return notRedeemed.length >= 5
      ? Math.trunc((notRedeemed.length - (selectedIndexes.length * 5)) / 5)
      : 0;
  }

  const getAvailableReferralsLabel = (): string => `${getAvailableCount()} Available ($${getAvailableAmount()})`;

  const getRedeemedReferralsLabel = (): string => {
    const amount = !isAdvocate && hasReferred
      ? selectedIndexes.length * AMOUNT_FOR_REFERRER
      : selectedIndexes.length * TOTAL_AMOUNT_REDEEMABLE;
    const redeemedValue = formatNumber(amount);
    return selectedIndexes.length ? `$${redeemedValue}` : '';
  };

  const closeCallback = (referredIds: number[], selectedIndexArray: number[]): void => {
    setSelectedIndexes(selectedIndexArray);
    getReferrals(referredIds, selectedIndexArray);
    setOpenModal(false);
  };

  return (
    <>
      <div className={classes.mainSection}>
        <div className={classes.referralCreditTitle}>
          Referral Credit
        </div>
        <div className={classes.availableCreditSection}>
          <div className={classes.availableLeft}>
            <div className={classes.availableLabel}>
              {getAvailableReferralsLabel()}
            </div>
            <Button
              className={classes.plusButton}
              onClick={() => { setOpenModal(true); }}
            >
              +
            </Button>
          </div>
          <div className={classes.availableRight}>
            {getRedeemedReferralsLabel()}
          </div>
        </div>
      </div>
      <ReferralCreditModal
        open={openModal}
        closeCallback={closeCallback}
        redeemed={redeemed}
        notRedeemed={notRedeemed}
        availableAmount={getAvailableAmount()}
        referralsChunkIndex={referralsChunkIndex}
      />
    </>
  );
};
