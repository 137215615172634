import { useMutation } from 'react-query';
import { IPhoto } from '../../interfaces/global.interfaces';
import ProgressNote from '../../services/ProgressNote';

interface ICreateProgressNote {
  patientId: number;
  text: string;
  photosIds: IPhoto['id'][];
}
export const useCreateProgressNote = () =>
  useMutation(({ patientId, text, photosIds }: ICreateProgressNote) =>
    ProgressNote.createProgressNote({ patientId, text, photosIds })
  );
