import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  parentGrid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  avatar: {
    width: '48px',
    height: '48px',
    marginRight: '8px',
  },
  submitButton: {
    backgroundColor: '#12574D',
    color: '#FFFFFF',
    border: 'solid 1px',
    borderRadius: '6px',
  },
  buttonGrid: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  adminList: {
    maxWidth: '100%',
    padding: '1.25rem 0',
  },
  title: {
    marginLeft: '16px',
    margin: '16px 0',
    fontSize: '20px',
  },
  chipActive: {
    marginLeft: 8,
    background: '#E7F9DA',
    color: '#386A18',
  },
  chipInactive: {
    marginLeft: 8,
    background: '#E2E2E2',
    color: '#6B6B6B',
  },
  shadowedLabel: {
    fontSize: '14px',
    marginLeft: '42px',
    marginRight: '42px',
    opacity: 0.4,
  },
  statusText: {
    marginTop: '10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  modalBox: {
    marginRight: '25px',
    height: '96vh',
    width: '550px',
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '15px',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    padding: '24px',
  },
  modalTitle: {
    marginTop: '5px',
  },
  modalSubmitButton: {
    backgroundColor: '#12574D',
    color: '#FFFFFF',
    border: 'solid 1px',
    borderRadius: '6px',
    width: '100%',
    margin: '0',
  },
  modalActiveButton: {
    backgroundColor: '#12574D',
    color: '#FFFFFF',
    border: 'solid 1px',
    borderRadius: '6px',
    width: '100%',
    margin: '0 !important',
  },
  modalInactiveButton: {
    backgroundColor: '#FFFFFF',
    color: '#12574D',
    border: 'solid 1px #CFCFCF',
    borderRadius: '6px',
    width: '100%',
    margin: '0 !important',
  },
  modalTextField: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  modalCloseButton: {
    cursor: 'pointer',
    color: '#6B6B6B',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalCheckboxGrid: {
    flexDirection: 'column',
    border: 'solid 1px #CFCFCF',
    borderRadius: '6px',
    marginTop: '24px',
    padding: '16px',
  },
  modalCheckbox: {
    margin: '0',
  },
  modalContentGrid: {
    height: '90%',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  modalSubmitButtonGrid: {
    height: '10%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  editAdmin: {},
});

export default useStyles;
