/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField, Box } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

import { useStyles } from './transactions.styles';

const CheckoutTransactionsHeader = ({ queryParams, setQueryParams }: any) => {
  const classes = useStyles();

  const onChangeFromDate = (fromDate: any) => {
    setQueryParams({ ...queryParams, fromDate });
  };

  const onChangeToDate = (toDate: any) => {
    setQueryParams({ ...queryParams, toDate });
  };

  const CustomFromDateTextField = (props: any) => (
    <TextField {...props} name="fromDate" style={{ marginBottom: '10px' }} fullWidth />
  );

  const CustomEndDateTextField = (props: any) => (
    <TextField {...props} name="endDate" style={{ marginBottom: '10px' }} fullWidth />
  );

  return (
    <Box padding={2}>
      <Box display="flex" justifyContent="flex-end">
        <Box className={classes.filters}>
          <Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                variant="dialog"
                inputVariant="outlined"
                label="From date"
                format="MM/DD/yyyy"
                autoOk
                disableToolbar
                value={queryParams.fromDate}
                onChange={onChangeFromDate}
                TextFieldComponent={CustomFromDateTextField}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                variant="dialog"
                inputVariant="outlined"
                label="To date"
                format="MM/DD/yyyy"
                autoOk
                disableToolbar
                value={queryParams.toDate}
                onChange={onChangeToDate}
                TextFieldComponent={CustomEndDateTextField}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutTransactionsHeader;
