import React from 'react';
import Slider from 'react-input-slider';
import { DEFAULT_MIN_STROKE, DEFAULT_STROKE } from '../../../constants/annotations.constants';

type Props = {
  onChange?: (y: number) => void;
};

const RangeSlider = ({ onChange }: Props) => {
  const [state, setState] = React.useState<{ x: number; y: number }>({
    x: 0,
    y: DEFAULT_STROKE,
  });

  const handleChange = (newState: any) => {
    setState(newState);
    onChange?.(newState.y);
  };

  return (
    <Slider
      styles={{
        track: {
          // backgroundColor: 'transparent',
        },
        active: {
          backgroundColor: '#00efae',
        },
        thumb: {
          width: state.y < 12 ? 12 : state.y + 5,
          height: state.y < 12 ? 12 : state.y + 5,
          opacity: 0.7,
          backgroundColor: '#000',
        },
        disabled: {
          opacity: 0.5,
        },
      }}
      axis="y"
      x={state.x}
      y={state.y}
      ymin={DEFAULT_MIN_STROKE}
      ymax={30}
      yreverse
      onChange={handleChange}
    />
  );
};

RangeSlider.defaultProps = {
  onChange: (_e: number) => {},
};

export default RangeSlider;
