import React, { useCallback } from 'react';
import { Grid, TextField, debounce } from '@material-ui/core';
import { useStyles } from './medicalProfile.styles';
import ConditionalField from './ConditionalFields';

interface Props {
  question: string;
  value: any;
  save: (data: any) => void;
}

const Procedures = (props: Props) => {
  const { question, value: procedures, save } = props;
  const classes = useStyles();

  const onToggleSwitch = (key: string) => {
    if (key === 'noProcedures' && !procedures[key]) {
      const newValues = procedures;
      Object.keys(procedures).forEach((objKey) => {
        if (typeof newValues[objKey] === 'boolean') {
          newValues[objKey] = false;
        } else if (typeof newValues[objKey] === 'string') {
          newValues[objKey] = '';
        } else {
          newValues[objKey] = [];
        }
      });
      save({ procedures: { ...newValues, [key]: true } });
    } else {
      save({ procedures: { ...procedures, [key]: !procedures[key], noProcedures: false } });
    }
  };

  const debounceCall = useCallback(
    debounce((key: string, text: string) => {
      save({ procedures: { ...procedures, [key]: text } });
    }, 1000),
    [procedures]
  );

  const setText = (key: string, text: string) => {
    if (key === 'adverseReactions') {
      debounceCall(key, text);
    }
  };

  const isChecked = (key: string): boolean => procedures[key] || false;

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.title}>{question}</div>
      </Grid>

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="neurotoxins"
        label="Neurotoxin (Dysport, Xeomin, Botox)"
      />
      <ConditionalField isChecked={isChecked} onToggleSwitch={onToggleSwitch} name="fillers" label="Fillers" />
      <ConditionalField isChecked={isChecked} onToggleSwitch={onToggleSwitch} name="threads" label="Threads" />
      <ConditionalField isChecked={isChecked} onToggleSwitch={onToggleSwitch} name="sculptra" label="Sculptra" />
      <ConditionalField isChecked={isChecked} onToggleSwitch={onToggleSwitch} name="kybella" label="Kybella" />
      <ConditionalField isChecked={isChecked} onToggleSwitch={onToggleSwitch} name="prpPrf" label="PRP/PRF" />
      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="laserTreatment"
        label="Laser treatment"
      />
      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="cosmeticSurgery"
        label="Cosmetic surgery"
      />
      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="facialImplants"
        label="Facial implants"
      />
      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="noProcedures"
        label="No, I've never  had any of the above procedures"
      />

      <Grid item xs={10} md={10} style={{ padding: '2px' }}>
        <div className={classes.field}>Did you ever have any adverse reactions to any of these procedures?</div>
        <div className={classes.textField}>
          <TextField
            defaultValue={procedures.adverseReactions}
            name="adverseReactions"
            fullWidth
            placeholder="Adverse reactions"
            variant="outlined"
            onChange={(e) => setText('adverseReactions', e.currentTarget.value)}
            multiline
          />
        </div>
      </Grid>
    </>
  );
};

export default Procedures;
