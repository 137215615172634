import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    width: '100%',
  },
  tableRow: {
    width: '100%',
  },
  actionsContainer: {
    textAlign: 'right',
  },
  actionButton: {
    cursor: 'pointer',
  },
  loadingStateContainer: {
    display: 'flex',
    margin: '50px',
    alignItems: 'center',
  },
  layoutContainer: {
    padding: '24px 0',
  },
  customEditor: {
    width: '100%',
    marginTop: 'unset',
    '& .ck-editor__editable_inline': {
      height: '100%',
    },
  },
  root: {
    display: 'block',
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    textAlign: 'left',
    letterSpacing: '-0.05px',
    color: '#3A3B3F',
    opacity: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  arrowBackContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '10px',
  },
  gridContainer: {
    margin: 'auto',
    width: '95%',
  },
  customParagraph: {
    fontWeight: 400,
    fontSize: '13px',
  },
  sidesPadding: {
    padding: '0 16px',
  },
  tableHeadCell: {
    fontSize: '13px',
    fontWeight: 200,
    color: 'grey',
  },
  formControl: {
    width: '100%',
    paddingBottom: '10px',
  },
  formControlLabel: {
    backgroundColor: 'white',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: '#12574d !important',
  },
}));
