import React from 'react';
import { Modal } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/common/Button';
import { useStyles } from './CreditCardModal.styles';

export const SubscriptionCardWarningModal = ({
  open,
  handleCloseCallback,
  patientId,
}: {
  open: boolean;
  handleCloseCallback: () => void;
  patientId: string;
}) => {
  const classes = useStyles();
  const history = useHistory();

  const navigateToManageMembership = () => {
    history.push(`/patient/${patientId}/membership`);
  };

  return (
    <Modal className={classes.modal} open={open} onClose={handleCloseCallback}>
      <div className={classes.modalContent}>
        <div className={classes.modalTitle}>Card cannot be removed</div>
        <div className={classes.modalBody}>
          This card cannot be removed as it’s being used in an active subscription. To remove, first tap Change Card to
          choose another card for the subscription.
        </div>
        <div>
          <Button className={classes.cancelButton} onClick={handleCloseCallback} title="Close" />
          <Button className={classes.deleteButton} title="Change Card" onClick={navigateToManageMembership} />
        </div>
      </div>
    </Modal>
  );
};
