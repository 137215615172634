import * as yup from 'yup';
import { NAME, SUPPLIER } from '../../../constants/schemaForm.constants';

export const schemaForm = yup.object().shape({
  name: yup.string().required(NAME),
  description: yup.string().default(''),
  listPrice: yup.number().default(0),
  minimumOrderQuantity: yup.number().default(1),
  itemCount: yup.number().default(1),
  unitsPerItem: yup.number().default(1),
  supplierId: yup.number().required(SUPPLIER),
  currentCost: yup.number().default(0),
  currentCostWithGuaranteedRebates: yup.number().default(0),
  currentCostWithAllRebates: yup.number().default(0),
  legacyPrice: yup.number().default(0),
  legacyPlusPrice: yup.number().default(0),
  flexPrice: yup.number().default(0),
  luxePrice: yup.number().default(0),
  litePrice: yup.number().default(0),
  active: yup.boolean().default(true),
});
