import { snakeCase } from 'lodash';
import {
  ALL_PATIENTS_STATE,
  CLEARED_STATE,
  INCOMPLETE_STATE,
  LEAD_STATE,
  PENDING_RECLEAR_STATE,
  TO_CLEAR_STATE,
  TO_RECLEAR_STATE,
} from '../general.constants';

export const PATIENT_STATUS_OPTIONS = {
  [snakeCase(ALL_PATIENTS_STATE)]: 'All',
  [snakeCase(LEAD_STATE)]: 'Lead',
  [snakeCase(INCOMPLETE_STATE)]: 'Incomplete',
  [snakeCase(TO_CLEAR_STATE)]: 'To clear',
  [snakeCase(TO_RECLEAR_STATE)]: 'To reclear',
  [snakeCase(CLEARED_STATE)]: 'Cleared',
  [snakeCase(PENDING_RECLEAR_STATE)]: 'Pending reclear',
};
