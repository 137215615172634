/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { get } from 'lodash';
import { useStyles, ButtonFooter } from './productIdentifiers.styles';
import { NavigationBar } from '../../PatientProfile/NavigationBar';
import { getTotal, twoDecimalPlacesIfCents } from '../../../utils/inventory.utils';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';

interface IService {
  assets: IAsset[];
  name: string;
  units?: number;
  vials?: number;
}

interface Props {
  service: IService;
  aggregatedField: string;
  hidePractitionerBar?: boolean;
  hideServiceBackButtom?: boolean;
  goBack?: () => void;
  handleRemove?: (assetsSelected: any, shouldUseCallback?: boolean) => void;
}

const ProductIdentifierHeader = (props: Props) => {
  const classes = useStyles();
  const [units, setUnits] = useState<number>(0);
  const [vials, setVials] = useState<number>(0);
  const [openVials, setOpenVials] = useState<number>(0);
  const { service, aggregatedField, hidePractitionerBar, goBack } = props;
  const { userType } = useSelector(({ auth }: any) => auth);
  const showUnitDetails = get(service.assets, '[0].allowPartialSale', false);

  useEffect(() => {
    const assetsInStock = service.assets.filter(({ markedAsEmpty }: IAsset) => !markedAsEmpty);
    const opened = assetsInStock.filter((asset: IAsset) => asset.currentNumberOfUnits !== asset.initialNumberOfUnits);

    setUnits(getTotal(assetsInStock, aggregatedField));
    if (aggregatedField === 'counted') {
      setVials(assetsInStock.filter((asset: IAsset) => asset.counted && asset.counted > 0).length);
    } else {
      setVials(assetsInStock.length);
    }
    setOpenVials(opened.length);
  }, [service]);

  const TotalCount = showUnitDetails ? (
    <span className={classes.serviceSubtitles}>
      <span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{vials}</span>
      <span style={{ marginLeft: '2px' }}>Total Vials</span>
      <br />
      <span style={{ color: '#969696', fontWeight: 'bold' }}>
        {openVials} Open vials
        <span style={{ padding: '0px 5px' }}> | </span>
        {twoDecimalPlacesIfCents(units)} Units Total
      </span>
    </span>
  ) : (
    <span className={classes.serviceSubtitles}>
      {aggregatedField === 'counted' ? twoDecimalPlacesIfCents(units) : vials}
    </span>
  );

  const ServiceTitle = (
    <Box mr={8}>
      <Typography className={classes.serviceTitle}>{service.name}</Typography>
      {TotalCount}
    </Box>
  );

  if (userType === 'Practitioner') {
    return (
      <>
        {!hidePractitionerBar && (
          <NavigationBar title={`${service.name} Product Identifiers`} onBackButtonClick={goBack} />
        )}
        <Box display="flex" justifyContent="space-between" style={{ paddingTop: '15px', paddingLeft: '15px' }}>
          <Box
            style={{ fontFamily: 'Messina Sans Regular' }}
            color="#393B3F"
            paddingX={2.5}
            mb={2.5}
            display="flex"
            alignItems="center"
          >
            {ServiceTitle}
          </Box>
        </Box>
      </>
    );
  }

  return (
    // Admin Header
    <Box padding={2}>
      <Box display="flex" justifyContent="space-between">
        <Box
          data-cy="serviceTitle"
          style={{ fontFamily: 'Messina Sans Regular' }}
          color="#393B3F"
          paddingX={2.5}
          mb={2.5}
          display="flex"
          alignItems="center"
        >
          {ServiceTitle}
        </Box>
        <Box display="flex" flexDirection="row">
          <Box className={classes.buttonPadding} onClick={goBack}>
            <ButtonFooter data-cy="backBtn" className={classes.buttonBackItems}>
              BACK
            </ButtonFooter>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductIdentifierHeader;
