import { Slider, withStyles } from '@material-ui/core';

export const MySlider = withStyles(() => ({
  rail: {
    backgroundColor: '#eaeaea',
    width: '4px !important',
    opacity: 1,
  },
  track: {
    backgroundColor: '#eaeaea',
    width: '4px !important',
  },
  thumb: {
    backgroundColor: '#eaeaea',
    height: '16px',
    width: '16px',
    marginLeft: '-6px !important',
  },
  vertical: {
    width: '10px',
  },
}))(Slider);
