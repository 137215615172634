import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  makeStyles,
  Checkbox,
  TextField,
  Typography,
  Tooltip,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Close as CloseIcon, Help as HelpIcon } from '@material-ui/icons';
import IServiceGroup from 'src/interfaces/IServiceGroup';
import ISuppliers from 'src/interfaces/ISuppliers';
import classNames from 'classnames';
import { PriceField } from 'src/components/common/Custom/PriceField';
import { useSelector } from 'react-redux';
import TitleComponent from './TitleComponent';
import { UNITS } from '../../../constants/medicalProfile.constants';
import { ASSET_LABEL } from '../../../constants/general.constants';
import IServices from '../../../interfaces/IServices';
import { Pill } from '../../../components/common/Pill';
import { useCreateService, useDeleteService, useUpdateService } from '../../../hooks/queries/services/useServices';
import {
  useCreateMedspaService,
  useUpdateMedspaService,
  useDeleteMedspaService,
} from '../../../hooks/mutations/useMedspaServices';
import { dispatch } from '../../../rematch';

export const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
  },
  fields: {
    width: '100%',
    margin: '0 0 1.5rem 0',
  },
  title: {
    margin: '0 15px 0 0',
  },
  paddingCotainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  separator: {
    margin: '0',
  },
  numberInputField: {
    width: '30.5%',
    margin: '10px 0',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      alignSelf: 'center',
      fontSize: '12px',
    },
    '& .MuiCheckbox-root': {
      padding: '0 0.5rem 0 0',
    },
  },
  titleField: {
    fontSize: '12px',
    color: '#706F6F',
    margin: '5px 0',
  },
  submitButton: {
    alignSelf: 'center',
    minWidth: '110px',
  },
  enableButton: {
    border: '1px solid #545454',
    cursor: 'pointer',
    width: '46%',
    alignSelf: 'center',
    color: '#545454',
  },
  deleteButton: {
    border: '1px solid #B64848',
    cursor: 'pointer',
    alignSelf: 'center',
    color: '#B64848',
    minWidth: '110px',
  },
  helpIcon: {
    fontSize: '1rem',
    margin: '0 0.3125rem',
    alignSelf: 'center',
    fill: '#000',
    '&.disabled': {
      fill: '#8F8D8B',
    },
  },
  formControlLabel: {
    marginRight: 0,
    '& .MuiTypography-body1': {
      fontSize: '0.75rem',
    },
  },
}));

interface ServiceModalProps {
  open: boolean;
  onClose: () => void;
  serviceId: string | null;
  serviceGroupId: string | null;
  suppliers?: ISuppliers[];
  serviceGroups: IServiceGroup[];
  services: IServices[];
  isMedspaAdmin?: boolean;
}

const ServiceModal = ({
  open,
  onClose,
  serviceId,
  services,
  suppliers,
  serviceGroups,
  serviceGroupId,
  isMedspaAdmin = false,
}: ServiceModalProps) => {
  const { userGroupId } = useSelector(({ auth }: any) => auth);
  const classes = useStyles();

  const nameRef = useRef<HTMLInputElement>(null);
  const serviceGroupRef = useRef<HTMLSelectElement>(null);
  const supplierRef = useRef<HTMLSelectElement>(null);
  const unitLabelRef = useRef<HTMLSelectElement>(null);
  const assetLabelRef = useRef<HTMLSelectElement>(null);
  const startingUnitsRef = useRef<HTMLInputElement>(null);
  const defaultCostRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const daysToExpireAfterOpenedRef = useRef<HTMLInputElement>(null);
  const prefixRef = useRef<HTMLInputElement>(null);
  const annotatableRef = useRef<HTMLInputElement>(null);
  const allowPartialSaleRef = useRef<HTMLInputElement>(null);
  const colorRef = useRef<HTMLInputElement>(null);
  const showInEhrRef = useRef<HTMLInputElement>(null);

  const service: IServices | undefined =
    serviceId && services && services.length
      ? services.find((_service: IServices) => _service.id === +serviceId)
      : undefined;

  const getShowInEhrLabel = (checked: boolean) => {
    if (checked) {
      return 'Set inactive';
    }
    return 'Activate';
  };
  const showInEhrDefaultChecked: boolean = Boolean(service?.showInEmr || serviceId === 'new');

  const [showInEhrLabel, setShowInEhrLabel] = useState<string>(getShowInEhrLabel(showInEhrDefaultChecked));

  const canEditService: boolean = Boolean(service?.practitionerId) || serviceId === 'new';

  const { mutateAsync: createService } = isMedspaAdmin ? useCreateMedspaService(userGroupId) : useCreateService();
  const { mutateAsync: updateService } = isMedspaAdmin ? useUpdateMedspaService(userGroupId) : useUpdateService();
  const { mutateAsync: deleteService } = isMedspaAdmin ? useDeleteMedspaService(userGroupId) : useDeleteService();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const serviceData = {
      name: nameRef.current?.value || '',
      serviceGroupId: serviceGroupRef.current?.value || '',
      supplierId: supplierRef.current?.value || '',
      unitLabel: unitLabelRef.current?.value || '',
      assetLabel: assetLabelRef.current?.value || '',
      startingUnits: startingUnitsRef.current?.value || '',
      currentCost: defaultCostRef.current?.value || '',
      price: priceRef.current?.value || '',
      description: descriptionRef.current?.value || '',
      daysToExpireAfterOpened: daysToExpireAfterOpenedRef.current?.value || '',
      prefix: prefixRef.current?.value || '',
      annotatable: annotatableRef.current?.checked,
      allowPartialSale: allowPartialSaleRef.current?.checked,
      color: colorRef.current?.value || '',
      showInEmr: showInEhrRef.current?.checked,
    };

    try {
      let response;
      if (serviceId === 'new') {
        response = await createService(serviceData);
      } else {
        response = await updateService({ serviceId, serviceData });
      }
      if (response.success) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: 'Service saved successfully',
            duration: 5000,
          },
        });
        onClose();
      } else {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: response.message,
            type: 'error',
            duration: 5000,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: 'There was an error, please try again',
          type: 'error',
          duration: 5000,
        },
      });
    }
  };

  const handleDelete = async () => {
    if (serviceId == null) {
      return;
    }
    try {
      const response = await deleteService(+serviceId);
      if (response.success) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: 'Service deleted successfully',
            duration: 5000,
          },
        });
        onClose();
      }
    } catch (error: any) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: error.message,
          type: 'error',
          duration: 5000,
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        m={2}
        style={{
          width: '38.2%',
          margin: '10px 10px auto auto',
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: '24px',
          minWidth: '550px',
          maxWidth: '550px',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
          className={classes.paddingCotainer}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom className={classes.title}>
              {serviceId === 'new' ? 'New Service' : 'Service'}
            </Typography>
            {serviceId !== 'new' && (
              <Pill
                title={service?.showInEmr ? 'Active' : 'Inactive'}
                color={service?.showInEmr ? 'green' : 'lightRed'}
              />
            )}
          </Box>
          <Button onClick={onClose} style={{ minWidth: 'auto', border: '1px solid #CFCFCF' }}>
            <CloseIcon />
          </Button>
        </Box>
        <hr className={classes.separator} />
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p={3}
            className={classes.paddingCotainer}
          >
            <Box className={classes.fields}>
              <TitleComponent title="Service Name" />
              <TextField
                required
                fullWidth
                inputRef={nameRef}
                defaultValue={service?.name}
                variant="outlined"
                size="small"
                disabled={!canEditService}
              />
            </Box>

            <Box className={classes.fields}>
              <FormControl
                fullWidth
                variant="outlined"
                required
                margin="normal"
                size="small"
                className={classes.formControl}
              >
                <TitleComponent title="Service Group" />
                <Select
                  disabled={!!serviceGroupId || !canEditService}
                  inputRef={serviceGroupRef}
                  name="serviceGroups"
                  id="serviceGroups"
                  defaultValue={serviceGroupId || service?.serviceGroupId}
                  placeholder="Select Service Group"
                >
                  <MenuItem value="">None</MenuItem>
                  {serviceGroups?.map((serviceGroup: IServiceGroup) => (
                    <MenuItem value={serviceGroup.id}>{serviceGroup.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box className={classes.fields}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
                className={classes.formControl}
                disabled={!canEditService}
              >
                <TitleComponent title="Supplier" />
                <Select
                  inputRef={supplierRef}
                  name="suppliers"
                  id="suppliers"
                  defaultValue={String(service?.supplierId)}
                >
                  <MenuItem value="">None</MenuItem>
                  {suppliers?.map((supplier: ISuppliers) => (
                    <MenuItem value={String(supplier.id)}>{supplier.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="1.5rem">
              <FormControl variant="outlined" required margin="normal" size="small" className={classes.fields}>
                <TitleComponent title="Unit Label" />
                <Select
                  inputRef={unitLabelRef}
                  name="unitLabel"
                  id="unitLabel"
                  defaultValue={service?.unitLabel}
                  disabled={!canEditService}
                >
                  <MenuItem value="">None</MenuItem>
                  {Object.keys(UNITS).map((unit: any) => (
                    <MenuItem value={unit}>{UNITS[unit]}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" required margin="normal" size="small" className={classes.fields}>
                <TitleComponent title="Asset Label" />
                <Select
                  inputRef={assetLabelRef}
                  name="assetLabel"
                  id="assetLabel"
                  defaultValue={service?.assetLabel}
                  placeholder="PlacAsset Labeleholder"
                  disabled={!canEditService}
                >
                  <MenuItem value="">None</MenuItem>
                  {Object.keys(ASSET_LABEL).map((unit: any) => (
                    <MenuItem value={unit}>{ASSET_LABEL[unit]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box className={classes.numberInputField}>
                <Box display="flex" flexDirection="row">
                  <TitleComponent
                    title="Starting Units"
                    tooltipComponent={
                      <Tooltip
                        // eslint-disable-next-line max-len
                        title="The minimum quantity or number of units that a seller requires a buyer to purchase when placing an order for a specific product. This minimum quantity is set by the seller."
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                      >
                        <HelpIcon
                          className={classNames(classes.helpIcon, canEditService ? undefined : 'disabled')}
                          fontSize="small"
                        />
                      </Tooltip>
                    }
                  />
                </Box>
                <TextField
                  required
                  inputRef={startingUnitsRef}
                  type="number"
                  defaultValue={service?.startingUnits}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  disabled={!canEditService}
                />
              </Box>
              <Box className={classes.numberInputField}>
                <Box display="flex" flexDirection="row">
                  <TitleComponent
                    title="Default Cost"
                    tooltipComponent={
                      <Tooltip
                        // eslint-disable-next-line max-len
                        title="The standard or predetermined cost associated with a product or service. This is the cost of Service or Product you pay for to the supplier."
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                      >
                        <HelpIcon
                          className={classNames(classes.helpIcon, canEditService ? undefined : 'disabled')}
                          fontSize="small"
                        />
                      </Tooltip>
                    }
                  />
                </Box>
                <PriceField
                  ref={defaultCostRef}
                  onChange={(value: number) => {
                    defaultCostRef.current = { value };
                  }}
                  defaultValue={service?.currentCost}
                  disabled={!canEditService}
                />
              </Box>
              <Box className={classes.numberInputField}>
                <TitleComponent title="Price" />
                <PriceField
                  onChange={(value: number) => {
                    priceRef.current = { value };
                  }}
                  ref={priceRef}
                  defaultValue={service?.price}
                />
              </Box>
            </Box>
            <Box className={classes.fields}>
              <TitleComponent title="Description" optional />
              <TextField
                fullWidth
                multiline
                rows={4}
                inputRef={descriptionRef}
                defaultValue={service?.description}
                variant="outlined"
                size="small"
                disabled={!canEditService}
              />
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="1.5rem">
              <Box className={classes.fields}>
                <TitleComponent
                  title="Days to Expire After Opened"
                  tooltipComponent={
                    <Tooltip
                      title="This is the expiration of the Service once it is opened."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                    >
                      <HelpIcon
                        className={classNames(classes.helpIcon, canEditService ? undefined : 'disabled')}
                        fontSize="small"
                      />
                    </Tooltip>
                  }
                />
                <TextField
                  fullWidth
                  inputRef={daysToExpireAfterOpenedRef}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  defaultValue={service?.daysToExpireAfterOpened}
                  variant="outlined"
                  size="small"
                  disabled={!canEditService}
                />
              </Box>

              <Box className={classes.fields}>
                <TitleComponent title="Prefix" />
                <TextField
                  fullWidth
                  inputRef={prefixRef}
                  type="text"
                  defaultValue={service?.prefix}
                  variant="outlined"
                  size="small"
                  disabled={!canEditService}
                />
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="1.5rem">
              <Box className={classes.fields}>
                <TitleComponent title="Color" />
                <input
                  type="color"
                  name="body"
                  defaultValue={service?.color || '#000000'}
                  ref={colorRef}
                  style={{ height: '40px', width: '80px' }}
                  disabled={!canEditService}
                />
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="1.5rem">
              <FormControl className={classes.fields}>
                <Box className={classes.checkbox}>
                  <Checkbox
                    inputRef={annotatableRef}
                    color="primary"
                    defaultChecked={Boolean(service?.annotatable)}
                    disabled={!canEditService}
                  />
                  <Typography>Is annotatable</Typography>
                  <Tooltip
                    // eslint-disable-next-line max-len
                    title="This Service required charting and you will not be allowed to close the Service visit preformed with this service without charting completion."
                    arrow
                    placement="top"
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                  >
                    <HelpIcon
                      className={classNames(classes.helpIcon, canEditService ? undefined : 'disabled')}
                      fontSize="small"
                    />
                  </Tooltip>
                </Box>
              </FormControl>

              <FormControl className={classes.fields}>
                <Box display="flex">
                  <FormControlLabel
                    className={classes.formControlLabel}
                    control={
                      <Switch
                        inputRef={showInEhrRef}
                        color="primary"
                        onChange={(e) => setShowInEhrLabel(getShowInEhrLabel(e.target.checked))}
                        defaultChecked={showInEhrDefaultChecked}
                        name="showInEhr"
                      />
                    }
                    label={showInEhrLabel}
                  />
                  <Tooltip
                    // eslint-disable-next-line max-len
                    title="When selecting this as Active, these services will appear in your Service Visit flow; on the contrary, they will be stored in your services listing but not visible and available in Service Visits."
                    arrow
                    placement="top"
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                  >
                    <HelpIcon
                      className={classNames(classes.helpIcon, canEditService ? undefined : 'disabled')}
                      fontSize="small"
                    />
                  </Tooltip>
                </Box>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="1.5rem">
              <FormControl className={classes.fields}>
                <Box className={classes.checkbox}>
                  <Checkbox
                    inputRef={allowPartialSaleRef}
                    color="primary"
                    defaultChecked={Boolean(service?.allowPartialSale)}
                    disabled={!canEditService}
                  />
                  <Typography>Allow Partial Sale</Typography>
                </Box>
              </FormControl>
            </Box>
          </Box>

          <hr className={classes.separator} />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            className={classes.paddingCotainer}
          >
            <Box style={{ width: `${serviceId === 'new' ? '100%' : 'auto'}` }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submitButton}
                style={{ width: `${serviceId === 'new' ? '100%' : 'auto'}` }}
              >
                {serviceId === 'new' ? 'Create' : 'Save'}
              </Button>
            </Box>
            {serviceId !== 'new' && (
              <Box display="flex" justifyContent="space-between">
                <Button className={classes.deleteButton} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            )}
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ServiceModal;
