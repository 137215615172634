import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography, withStyles } from '@material-ui/core';
import {
  modalStyles,
  // eslint-disable-next-line max-len
} from 'src/components/DashboardAdministrator/OrderApproval/OrderApprovalRow/OrderApprovalRow.styles';
import { useCreateInventoryOrderCustomItem } from 'src/hooks/queries/useInventoryOrders';
import classNames from 'classnames';
import { EHRTextField } from 'src/components/ui/v1';
import ISuppliers from 'src/interfaces/ISuppliers';
import { CUSTOM_ITEM_TEXT } from 'src/constants/inventory.constants';
import { OrderSupplierProductsStyles } from './inventoryOrder.styles';

type Props = {
  supplier: ISuppliers;
  inventoryOrderId: number;
  dismiss?: () => void;
};

export const AddCustomSupplierItem = ({ supplier: { name, id }, inventoryOrderId, dismiss }: Props) => {
  const classes = modalStyles();

  const [itemName, setItemName] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { mutateAsync: createItem } = useCreateInventoryOrderCustomItem({ supplierId: id, inventoryOrderId });

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSaving(true);
    try {
      await createItem({ name: itemName });
      dismiss?.();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box className={classes.confirmDialog} data-testid="addCustomItem">
      <form onSubmit={onSubmit} className={classes.trackingDialogForm}>
        <Box className={classes.confirmContent}>
          <Typography variant="h5">Add a new {name} custom item</Typography>
        </Box>
        <Box className={classes.confirmContent}>
          <Typography className={classes.subheading}>{CUSTOM_ITEM_TEXT}</Typography>
        </Box>
        <Box className={classes.confirmContent}>
          <EHRTextField
            label="Item Name"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setItemName(event.target.value)}
            value={itemName}
            dataCy="addCustomItem.companyName"
            data-testid="addCustomItem.companyName"
            required
          />
        </Box>
        <Box className={classNames(classes.confirmButtons, classes.smallButtons)}>
          {!!dismiss && (
            <Button
              data-testid="addCustomItem.cancel"
              data-cy="addCustomItem.cancel"
              onClick={dismiss}
              variant="outlined"
              className={classes.actionButton}
            >
              Cancel
            </Button>
          )}
          <Button
            type="submit"
            data-testid="addCustomItem.save"
            data-cy="addCustomItem.save"
            variant="outlined"
            className={classes.actionButton}
          >
            {isSaving ? <CircularProgress style={{ color: 'white' }} size={30} /> : 'Add'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default withStyles(OrderSupplierProductsStyles)(AddCustomSupplierItem);
