import Id from '../types/Id';

/** system routes */
export const LOGIN_PAGE = `/login`;
export const PATIENT_BASE = `/patient`;

export const ROUTES = {
  PATIENT_BASE,
  PATIENT_ID: (id: Id) => `/patient/${id}`,
  SERVICE_VISIT: ({ mrn, serviceVisitId }: { mrn: Id; serviceVisitId: Id }): string =>
    `/patient/${mrn}/serviceVisit/${serviceVisitId}`,
  NEW_SERVICE_VISIT_VERSION: (patientId: number, id: number) => `/patient/${patientId}/newServiceVisit/${id}`,
  NEW_SERVICE_VISIT_CHECKOUT_VERSION: (patientId: number, id: number) =>
    `/patient/${patientId}/newServiceVisit/${id}/checkout`,
  ORDER_HISTORY: '/practitioner/inventory/orders',
  NEW_ORDER: '/practitioner/inventory/new-order',
  FLEX_ORDER_HISTORY: '/practitioner/ordering/orders',
  FLEX_ORDER: '/practitioner/ordering/new-order',
};

export const SIGN_UP_ROUTES = {
  booking: `${process.env.REACT_APP_SIGN_UP_LINK}/booking`,
  userGroupBooking: `${process.env.REACT_APP_SIGN_UP_LINK}/booking/medspa-group`,
  medspaLocationBooking: `${process.env.REACT_APP_SIGN_UP_LINK}/booking/medspa-group/medspa-location`,
  registration: `${process.env.REACT_APP_SIGN_UP_LINK}/auth/register`,
};

export const SUPPORT_LINK = 'https://portraitcare.com/support';
