import React, { useRef, useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import Page from 'src/components/common/Page';
import InfiScroller from 'react-infi-scroller';
import Message from 'src/components/common/Message/Message';
import { useSuppliers } from 'src/hooks/queries/useSuppliers';
import { useInventoryOrdersInfinite } from '../../../hooks/queries/useInventoryOrders';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { OrderApprovalRow } from './OrderApprovalRow';
import { BannerDates } from './BannerDates';
import BlackoutDates from './BlackoutDates';
import Breadcrumb from '../common/Breadcrumb';
import { modalStyles } from './OrderApprovalRow/OrderApprovalRow.styles';

const OrderApproval = () => {
  const refComponent = useRef<HTMLDivElement | null>(null);
  const classes = modalStyles();
  const [scrollPosition, setScrollPosition] = useState(0);

  const { data: suppliers = [], isLoading: isLoadingSuppliers } = useSuppliers();
  const { results, isLoading: isLoadingOrders, hasNextPage, fetchNextPage } = useInventoryOrdersInfinite();

  const isLoading = isLoadingSuppliers || isLoadingOrders;
  const orders = results?.pages?.flatMap((page: any) => page?.data) || [];

  const handleLoadMore = () => {
    setScrollPosition(refComponent.current?.scrollTop || 0);
    fetchNextPage();
  };

  useEffect(() => {
    const scrollRef = refComponent?.current;
    if (scrollRef) {
      if (scrollRef.scroll) {
        scrollRef.scroll({ top: scrollPosition, behavior: 'smooth' });
      } else {
        scrollRef.scrollTop = scrollPosition;
      }
    }
  }, [scrollPosition]);

  const ResultsComponent =
    orders.length > 0 ? (
      <>
        {orders.map((order) => !!order && <OrderApprovalRow {...{ ...order, suppliers }} key={order.id} />)}
        {hasNextPage && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </>
    ) : (
      <Message text="No data to show" colSize={12} />
    );

  return (
    <Page title="Order Approval">
      <Box width="100%" data-testid="order approval">
        <div ref={refComponent} className={classes.infiScrollContainer}>
          <Breadcrumb prependHeading="Inventory" />
          <BlackoutDates />
          <BannerDates />
          <InfiScroller hasMore={hasNextPage} scrollTarget={refComponent.current} onLoadMore={handleLoadMore}>
            {isLoading ? <ShortMultipleSkeleton key={0} /> : ResultsComponent}
          </InfiScroller>
        </div>
      </Box>
    </Page>
  );
};

export default OrderApproval;
