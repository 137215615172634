export const ROWS_PER_PAGE = 10;
export const DEFAULT_SORT_BY = 'service_name';
export const DEFAULT_SORT_DIR = 'ASC';

export const TABLE_COLUMNS = [
  { id: 'service_name', title: 'Product Name', sort: true },
  { id: 'portrait_price', title: 'List Price per Container' },
  { id: 'portrait_price', title: 'Portrait Price per Container' },
  { id: 'portrait_unit_price', title: 'Cost per Unit' },
  { id: 'weeks_on_hand', title: 'Weeks On-Hand' },
  { id: 'current_units', title: 'Units On-Hand', sort: true },
  { id: 'value_on_hand', title: 'Amount On-Hand ($)', sort: true },
  { id: 'avg_units', title: 'Weekly Avg. Units', sort: true },
  { id: 'avg_value', title: 'Weekly Avg. ($)', sort: true },
];
