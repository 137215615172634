import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab/';
import Photos from '../PatientProfile/Photos';
import { dispatch } from '../../rematch';
import { ActiveStepWrapper } from './ActiveStepWrapper';
import { PHOTO_TYPE } from '../../constants/newServiceVisit.constants';
import useWindowDimensions from '../../utils/useWindowDimensions';

import {
  MIN_ITEMS_PHOTOS_LIMIT_MOBILE,
  ITEMS_PHOTOS_LIMIT_DESKTOP,
  WIDTH_MAX_DESKTOP,
} from '../../constants/general.constants';
import useLog from '../../hooks/mutations/useLog';
import { isBeforePhotoRequired } from '../../utils/newServiceVisit.util';

const cardContentStyle = {
  padding: '11px 20px 20px 20px',
};

export type Props = {
  fromHistory?: boolean;
};

export const BeforePhotos = ({ fromHistory }: Props) => {
  const newServiceVisit = useSelector(({ newServiceVisit: serviceVisit }: any) => serviceVisit);
  const { isUploadingBeforePhoto, serviceVisit, isLoadingGetServiceVisitFromPatient } = useSelector(
    (store: any) => store.newServiceVisit
  );
  const { id, photos, opened } = serviceVisit;
  const { photos: patientPhotos, serviceGroups } = useSelector(({ patient }: any) => patient);
  const { width } = useWindowDimensions();
  const ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE =
    width > WIDTH_MAX_DESKTOP ? ITEMS_PHOTOS_LIMIT_DESKTOP : MIN_ITEMS_PHOTOS_LIMIT_MOBILE;

  const { mutateAsync: log } = useLog();

  const uploadBeforePhoto = (files: File[]) => {
    dispatch({
      type: 'newServiceVisit/uploadPhotosRequest',
      payload: { files, type: PHOTO_TYPE.BEFORE, serviceVisitId: id },
    });
  };

  const patientPhotosMemo = useMemo(() => {
    const photosIds = photos.map(({ photoId }: any) => photoId);
    return patientPhotos.filter(
      ({ id: idPhoto, photoType }: any) => photosIds.includes(idPhoto) && photoType === PHOTO_TYPE.BEFORE
    );
  }, [photos, patientPhotos]);

  const setCurrentStep = () => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 4 });
    log({
      feature: 'Service Visit',
      step: 4,
      logId: null,
      params: {
        newServiceVisit,
      },
    });
  };

  const { selectedServiceGroups } = newServiceVisit;

  const isBeforePhotoRequiredMemo = useMemo(
    () => isBeforePhotoRequired(selectedServiceGroups, serviceGroups, Boolean(patientPhotosMemo.length)),
    [selectedServiceGroups, serviceGroups, patientPhotosMemo]
  );

  return (
    <ActiveStepWrapper step={4} onClick={setCurrentStep} fromHistory={fromHistory}>
      {isLoadingGetServiceVisitFromPatient ? (
        <Skeleton height={150} />
      ) : (
        <Photos
          required={isBeforePhotoRequiredMemo}
          inputFileId="beforePhoto"
          cardStyles={{ marginBottom: '0' }}
          cardContentStyle={cardContentStyle}
          title="Before photos"
          photos={patientPhotosMemo}
          isUploadingPhoto={isUploadingBeforePhoto}
          isLoading={false}
          uploadPhotosCallback={uploadBeforePhoto}
          showUploadPhotoButton={opened}
          itemsPhotos={ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE}
          fromHistory={fromHistory}
        />
      )}
    </ActiveStepWrapper>
  );
};
