import { uniqBy } from 'lodash';
/* Function that allow to convert the json to format:
 * { ...service, assets }
 */
export const handleAssetSelected = ({ asset, services, productsSelected }: any) => {
  if (!asset.requestedUnits && !asset.allowPartialSale) {
    // eslint-disable-next-line no-param-reassign
    asset.requestedUnits = asset.currentNumberOfUnits;
  }
  const product = services.find(({ id }: any) => id === asset.serviceId);
  const productIndex = productsSelected.findIndex(({ id }: any) => id === product.id);

  if (productIndex !== -1) {
    // eslint-disable-next-line no-param-reassign
    productsSelected[productIndex] = {
      ...productsSelected[productIndex],
      assets: uniqBy([asset, ...productsSelected[productIndex].assets], 'id'),
    };
  } else {
    productsSelected.unshift({ ...product, assets: [asset] });
  }
  return productsSelected;
};
