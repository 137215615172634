import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { chunk } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { Close as CloseIcon } from '@material-ui/icons';
import { useStyles } from './referralCreditModal.styles';
import { AdvocateReferralCredit } from '../../interfaces/checkout.interfaces';

interface Props {
  open: boolean;
  closeCallback: (referredIds: number[], selectedIndexes: number[]) => void;
  availableAmount: number;
  notRedeemed: AdvocateReferralCredit[];
  redeemed: AdvocateReferralCredit[];
  referralsChunkIndex: number[];
}

export const ReferralCreditModal = (props: Props) => {
  const { open, closeCallback, availableAmount, notRedeemed, redeemed, referralsChunkIndex } = props;
  const classes = useStyles();
  const { hasReferred, isAdvocate } = useSelector(({ patient }: any) => patient);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

  useEffect(() => {
    setSelectedIndexes(referralsChunkIndex);
  }, [referralsChunkIndex]);

  const applyReferrals = (index: number, apply: boolean): void => {
    if (apply) {
      setSelectedIndexes([...selectedIndexes, index]);
    } else {
      const filteredIndexes = selectedIndexes.filter((selectedIndex) => selectedIndex !== index);
      setSelectedIndexes(filteredIndexes);
    }
  };

  const handleClose = (): void => {
    if (!isAdvocate && hasReferred) {
      closeCallback(
        notRedeemed.filter((obj, index) => selectedIndexes.includes(index)).map(({ referredId }) => referredId),
        selectedIndexes
      );
      return;
    }
    let referredIds: number[] = [];
    selectedIndexes.forEach((index) => {
      referredIds = referredIds.concat(chunk(notRedeemed, 5)[index].map(({ referredId }) => referredId));
    });

    closeCallback(referredIds, selectedIndexes);
  };
  const getAvailableCreditLabel = (): string => `$${availableAmount} In redeemable credit`;

  const advocateAvailableReferralOptions = chunk(notRedeemed, 5).map((referrals, index: number) => (
    // eslint-disable-next-line react/no-array-index-key
    <div className={classes.referralsAvailableSection} key={`advocate-${index}`}>
      <div className={classes.buttonSection}>
        {selectedIndexes.includes(index) ? (
          <Button
            className={classes.removeButton}
            onClick={() => {
              applyReferrals(index, false);
            }}
          >
            Remove Credit
          </Button>
        ) : (
          <Button
            className={classes.applyButton}
            onClick={() => {
              applyReferrals(index, true);
            }}
            disabled={referrals.length < 5}
          >
            Apply $250
          </Button>
        )}
      </div>
      {referrals.map(({ value, fullName }) => (
        <div className={classes.referralsAvailable}>
          <div className={classes.referralsAvailableAmount}>$ {value}</div>
          <div className={classes.referralsAvailableName}>{fullName}</div>
        </div>
      ))}
    </div>
  ));

  const availableReferralsOptions = notRedeemed.map((referrals, index: number) => (
    <div className={classes.referralsAvailableSection} key={`referal-${referrals.id}`}>
      <div className={classes.referralsAvailable}>
        <Checkbox
          color="primary"
          checked={selectedIndexes.includes(index)}
          onChange={() => {
            applyReferrals(index, !selectedIndexes.includes(index));
          }}
        />
        <div className={classes.referralsAvailableAmount}>$ {referrals.value}</div>
        <div className={classes.referralsAvailableName}>{referrals.fullName}</div>
      </div>
    </div>
  ));

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ className: classes.dialog }}>
      <div className={classes.header}>
        <span className={classes.title}>Apply Referral Credit</span>
        <div role="button" tabIndex={0} onKeyDown={() => {}} onClick={handleClose}>
          <CloseIcon />
        </div>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.stripe}>
          <div className={classes.stripeTitle}>Available</div>
          <div className={classes.stripeTitleRight}>{getAvailableCreditLabel()}</div>
        </div>
        {!isAdvocate && hasReferred ? availableReferralsOptions : advocateAvailableReferralOptions}
        <div className={classes.stripe}>
          <div className={classes.stripeTitle}>Redeemed</div>
        </div>
        <div className={classes.redeemedSection}>
          {redeemed.map(({ fullName }) => (
            <div className={classes.redeemedNames}>{fullName}</div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
