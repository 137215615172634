import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import { debounce } from 'lodash';
import { useMedspaSummarizeTransactions } from 'src/hooks/queries/useMedspaAdmins';
import { useSelector } from 'react-redux';
import { MEDSPA_ADMIN_ROLE } from 'src/constants/general.constants';
import compile from '../../../../utils/toastMessagesCompiler';
import { useTransactionsDashboard } from '../../../../hooks/queries/usePractitionersDashboard';

import InputSearch from '../../../common/InputSearch';
import TransactionsDashboardTable from './TransactionsDashboardTable';

import { useStyles } from './practitionerDashboard.styles';

const ERROR_GETTING_TRANSACTIONS = compile('generic.error_message', {
  action: 'getting',
  element: 'practitioners transactions',
});

const Transactions = ({
  selectedDate,
  formatToShow,
  formatToQuery,
  totalServicesWithDiscount,
  practitionerId,
}: any) => {
  const classes = useStyles();
  const {
    roleName,
    user: { userGroupId },
  } = useSelector(({ auth }: any) => auth);
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const isMedspaEnabled = process.env.REACT_APP_MEDSPA_FIELDS === 'true';
  const [searchText, setSearchText] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState({ search: '', month: selectedDate.format(formatToQuery) });
  const summedMedspaPractTransactions = useMedspaSummarizeTransactions(
    practitionerId,
    searchQuery,
    userGroupId,
    isMedspaAdmin
  );
  const practitionerTransactions = useTransactionsDashboard(searchQuery, !isMedspaAdmin);

  const {
    data: transactions,
    isLoading,
    isFetching,
    isError,
    hasNextPage,
    fetchNextPage,
  } = isMedspaAdmin && isMedspaEnabled ? summedMedspaPractTransactions : practitionerTransactions;

  useEffect(() => {
    setSearchQuery({ ...searchQuery, month: selectedDate.format(formatToQuery) });
  }, [selectedDate, formatToQuery]);

  const doSearch = useCallback(
    debounce(
      (query: any) =>
        setSearchQuery({
          ...searchQuery,
          search: query,
        }),
      2000
    ),
    [searchQuery]
  );

  const onChange = (event: any) => {
    const newSearchQuery = event.target.value;
    setSearchText(newSearchQuery);
    doSearch(newSearchQuery);
  };

  return (
    <div className={classes.area}>
      <Typography variant="h6" className={classes.title}>
        {selectedDate.format(formatToShow)} Transactions
      </Typography>
      <Box width="100%">
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box style={{ marginBottom: '1rem' }}>
              <InputSearch
                placeholder="Patient Name"
                onChangeValue={onChange}
                value={searchText}
                isLoading={isLoading}
              />
            </Box>
          </Box>
          <TransactionsDashboardTable
            data={transactions as any}
            totalServicesWithDiscount={totalServicesWithDiscount}
            errorMessage={ERROR_GETTING_TRANSACTIONS}
            fetchNextPage={fetchNextPage}
            filterText={searchQuery.search}
            hasNextPage={hasNextPage}
            isError={isError}
            isLoading={isLoading || isFetching}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Transactions;
