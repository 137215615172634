import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  progressNoteTitle: {
    letterSpacing: '-0.05px',
    fontSize: '20px',
    fontFamily: 'Messina Sans Regular',
    color: 'rgba(0, 0, 0, .87)',
    marginBottom: '10px',
  },
  serviceName: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    lineHeight: 1.67,
    letterSpacing: '-0.05px',
    color: '#3a3b3f',
  },
  progressNoteDetails: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '15px !important',
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#3a3b3f',
  },
}));
