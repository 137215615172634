import { IFeatureSetting } from '../interfaces/IFeatureSetting';

export const hasAccessTo = (action: string, permissions: string[]) =>
  permissions?.length > 0 ? permissions.includes(action) : false;

export const isSessionExpired = () => {
  const timeInSec = new Date().getTime() / 1000;
  const tokenExpiry = localStorage.getItem('identity.token.expiry');
  const tokenExpiryInSec = tokenExpiry ? parseInt(tokenExpiry, 10) : 0;
  return timeInSec > tokenExpiryInSec;
};

export const dispatchNewAuth = (dispatch: any, data: any) => {
  const permissions = data.permissions || data.identity.permissions || [];

  dispatch.auth.updateState({
    isAuthenticate: true,
    isSuccess: false,
    userType: data.identity.userType,
    email: data.identity.email,
    user: {
      id: data.identity.id,
      firstName: data.identity.firstName,
      lastName: data.identity.lastName,
      professionalName: data.professionalName,
      userGroupId: data.userGroupId || data.identity.userGroupId,
      userGroupName: data.userGroupName || data.identity.userGroupName,
    },
    permissions,
    featureList: data.featureList || data.identity.featureList,
    featureSettings: data.featureSettings || data.identity.featureSettings,
    userId: data.identity.userId,
    userGroupId: data.identity.userGroupId,
    userGroupName: data.identity.userGroupName,
    name: `${data.identity.firstName} ${data.identity.lastName}`,
    id: data.identity.id,
    practitionerInformation: {
      dashboardLink: data.practitionerInformation?.dashboardLink,
    },
    roleId: data.identity.roleId,
    roleName: data.identity.roleName,
    linkedAccounts: data.identity.linkedAccounts,
    canOnlyAccessMedspaAdminOnboarding: data.identity.canOnlyAccessMedspaAdminOnboarding,
    onboardingEnabled: data.identity.onboardingEnabled,
  });
};

export const hasFeatureEnabled = (feature: string, features: string[] = []): boolean => features.includes(feature);

export const hasUserGroupFeatureEnabled = (feature: string, features: IFeatureSetting[]) =>
  features?.some((feat) => feat.key === feature);

export function getFeatureSettings<S>(feature: string, featureSettings: IFeatureSetting[] = []): S {
  const featureSetting = featureSettings.find((setting) => setting.key === feature);
  return featureSetting ? (featureSetting.settings as S) : ({} as S);
}
