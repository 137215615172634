import { Box, Button, makeStyles, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DocMenu from 'src/components/common/DocMenu/Dropdown';
import CustomCKEditor from 'src/components/common/Custom/CustomCKEditor';

export const useStyles = makeStyles(() => ({
  modalForm: {
    backgroundColor: 'white',
  },
  submitButton: {
    alignSelf: 'center',
    minWidth: '110px',
  },
  buttons: {
    alignSelf: 'center',
  },
  myEditor: {
    width: '100%',
    marginTop: 'unset',
    '& .ck-editor__editable_inline': {
      height: '100%',
    },
  },
}));

interface Props {
  title: string;
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
  onDelete?: () => void;
}
const DocumentForm = ({ title, value, disabled, onChange, onDelete }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    if (value !== newValue) {
      setNewValue(value);
    }
  }, [value]);

  const onEditStandingOrder = () => {
    setOpen(true);
  };

  const onSave = () => {
    onChange(newValue);
    setOpen(false);
  };
  const onClickDelete = () => {
    onDelete?.();
    setOpen(false);
  };

  return (
    <>
      <DocMenu title={title} value={value} disabled={disabled} onEdit={onEditStandingOrder} onDelete={onClickDelete} />
      <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box className={classes.modalForm}>
            <CustomCKEditor onChange={setNewValue} value={value} disabled={disabled} className={classes.myEditor} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" onClick={() => setOpen(false)}>
            {disabled ? 'Close' : 'Cancel'}
          </Button>
          {!disabled && (
            <Button variant="contained" color="primary" type="submit" className={classes.submitButton} onClick={onSave}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocumentForm;
