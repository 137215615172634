import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  FormControlLabel,
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Page from 'src/components/common/Page';
import { ServiceCell } from '../components/OverviewPage/ServiceCell';
import { PractitionerCell } from '../components/OverviewPage/PractitionerCell';
import { SwitchCustom } from '../components/DashboardAdministrator/Inventory/supplyReceipt.styles';
import { dispatch } from '../rematch';
import BreadcrumbsContainer from '../components/common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../components/common/Breadcrumb/BreadcrumbText';
import { IAssetsOverview, IHeaderService, IPractitionerOverview } from '../types/OverviewState';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
  },
  practitionerTitle: {
    fontSize: '15px',
    fontFamily: 'Messina Sans Bold',
  },
}));

const OverviewPage = () => {
  const classes = useStyles();
  const [rowsHidden, setRowsHidden] = useState<number[]>([]);
  const [showAllRows, setShowAllRows] = useState<boolean>(false);
  const [columnsHidden, seColumnsHidden] = useState<number[]>([]);
  const [showAllColumns, setShowAllColumns] = useState<boolean>(false);
  const {
    headerServices,
    warehouses,
    effects: {
      overview: { getTotalProducts: isLoadingGetTotalProducts },
    },
  } = useSelector((state: any) => ({ ...state.overview, ...state.loading }));

  useEffect(() => {
    dispatch.overview.getTotalProducts();
  }, []);

  const handleHideColumn = (columnIndex: number) => {
    const columns = [...columnsHidden];
    columns.push(columnIndex);
    seColumnsHidden(columns);
  };

  const handleHideRow = (rowIndex: number): void => {
    const rows = [...rowsHidden];
    rows.push(rowIndex);
    setRowsHidden(rows);
  };

  const handleShowRow = (rowIndex: number): void => {
    const rows = rowsHidden.filter((item: any) => item !== rowIndex);
    setRowsHidden(rows);
  };

  const isRowHide = (rowIndex: number): boolean => rowsHidden.includes(rowIndex);

  const isColumnHide = (rowIndex: number): boolean => columnsHidden.includes(rowIndex);

  const handleShowColumn = (rowIndex: number): void => {
    const rows = columnsHidden.filter((item: any) => item !== rowIndex);
    seColumnsHidden(rows);
  };

  const handleRowVisibility = (rowIndex: number, handleShowAllRows: boolean = false): string =>
    isRowHide(rowIndex) && !handleShowAllRows ? 'none' : 'table-row';

  const RenderCell = ({ assets }: IPractitionerOverview, { serviceId }: IHeaderService, index: number) => {
    const asset = assets.find((assetItem: IAssetsOverview) => assetItem.serviceId === serviceId);
    return asset ? (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <ServiceCell index={index} isColumnHide={isColumnHide(index)} showAllColumns={showAllColumns} {...asset} />
    ) : (
      <TableCell style={{ display: isColumnHide(index) && !showAllColumns ? 'none' : '' }} />
    );
  };

  const Breadcrumb = (
    <Box mb="17px">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Inventory" />
        <BreadcrumbText text="Assets overview" isActive />
      </BreadcrumbsContainer>
    </Box>
  );

  const TitleButton = (
    <Box padding={2}>
      <Box display="flex" alignItems="start" justifyContent="space-between">
        <Box>
          <Typography data-cy="title" className={classes.title}>
            Assets Overview
          </Typography>
        </Box>
        <Box>
          <FormControlLabel
            data-cy="switchLabel"
            control={
              <SwitchCustom
                data-cy="showHiddenToggle"
                checked={showAllRows}
                disabled={false}
                onChange={() => {
                  setShowAllRows((prevState: any) => !prevState);
                  setShowAllColumns((prevState: any) => !prevState);
                }}
                name="rowsHidden"
              />
            }
            name="rowsHidden"
            labelPlacement="start"
            label="Show hidden"
          />
        </Box>
      </Box>
    </Box>
  );

  const MyTable = (
    <Box p={2}>
      <TableContainer>
        <Table className="overview-page-table-sticky">
          <TableHead data-cy="thead">
            <TableRow>
              <TableCell align="center" className={classes.practitionerTitle}>
                Warehouse
              </TableCell>
              {headerServices.map((item: IHeaderService, index: number) => (
                <ServiceCell
                  data-cy="serviceCell"
                  index={index}
                  isColumnHide={isColumnHide(index)}
                  handleHideColumn={() => handleHideColumn(index)}
                  handleShowColumn={() => handleShowColumn(index)}
                  showAllColumns={showAllColumns}
                  key={item.serviceId}
                  isHeader
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...item}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-cy="tbody">
            {warehouses.map((item: IPractitionerOverview) => (
              <TableRow data-cy="row" key={item.id} style={{ display: handleRowVisibility(item.id, showAllRows) }}>
                <PractitionerCell
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...item}
                  handleHideRow={() => handleHideRow(item.id)}
                  handleShowRow={() => handleShowRow(item.id)}
                  isHide={isRowHide(item.id)}
                />
                {headerServices.map((service: IHeaderService, index: number) => RenderCell(item, service, index))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  if (isLoadingGetTotalProducts) {
    return (
      <Page title="Assets overview">
        <Box width="100%" position="relative">
          <MultipleSkeleton />
        </Box>
      </Page>
    );
  }

  return (
    <Page title="Assets overview">
      <Box width="100%">
        {Breadcrumb}
        {TitleButton}
        {MyTable}
      </Box>
    </Page>
  );
};

export default OverviewPage;
