import React from 'react';
import { useDocuments } from 'src/hooks/queries/useDocuments';
import { Box, Typography } from '@material-ui/core';
import { OTHER_DOCUMENT_TYPE } from 'src/constants/medspa/documentsManagement.constants';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import DocumentTable from './DocumentTable';
import { useStyles } from '../documentsManagement.styles';

export const OnboardingDocuments = () => {
  const classes = useStyles();
  const { userGroupData, isLoading } = useMedspaAdminOnboarding();
  const { commercialShippingAddress, medicalDirector } = userGroupData ?? {};

  return (
    <Box className={classes.layoutContainer}>
      <Typography className={classes.sidesPadding} component="h1">
        Onboarding Documents
      </Typography>
      <p className={[classes.customParagraph, classes.sidesPadding].join(' ')}>Your uploaded account documents</p>
      <Typography className={classes.sidesPadding} component="h1">
        Commercial Shipping Address Documents
      </Typography>
      {(commercialShippingAddress || []).map((address) => (
        <>
          <p className={[classes.customParagraph, classes.sidesPadding].join(' ')}>
            {`${address.address} ${address.city}, ${address.state}, ${address.zip}`}
          </p>
          <DocumentTable
            documentType={OTHER_DOCUMENT_TYPE}
            documents={
              [
                {
                  name: 'Shipping Address Amendment',
                  documentPdfUrl: address?.shippingAddressAmendmentPdf,
                },
                {
                  name: 'Proof of Business Address',
                  documentPdfUrl: address.proofOfBusinessAddress?.fileName ? address.proofOfBusinessAddress?.url : null,
                },
              ].filter((document) => !!document?.documentPdfUrl) ?? []
            }
            isLoadingDocuments={isLoading}
            isFromMasterLibrary
          />
        </>
      ))}
      <Typography className={classes.sidesPadding} component="h1" style={{ marginTop: '20px' }}>
        Medical Director Documents
      </Typography>
      <DocumentTable
        documentType={OTHER_DOCUMENT_TYPE}
        documents={
          [
            {
              name: 'Medical Director Agreement',
              documentPdfUrl: medicalDirector?.ownMedicalDirector?.ownMedicalDirectorAgreementPdf,
            },
          ].filter((document) => !!document?.documentPdfUrl) ?? []
        }
        isLoadingDocuments={isLoading}
        isFromMasterLibrary
      />
    </Box>
  );
};

export const AssignedDocuments = () => {
  const classes = useStyles();
  const { data: documents = [], isLoading } = useDocuments({});

  return (
    <Box className={classes.layoutContainer}>
      <Typography className={classes.sidesPadding} component="h1">
        Assigned Document
      </Typography>
      <p className={[classes.customParagraph, classes.sidesPadding].join(' ')}>Your assigned documents from Portrait</p>
      <DocumentTable
        documentType={OTHER_DOCUMENT_TYPE}
        documents={documents ?? []}
        isLoadingDocuments={isLoading}
        isFromMasterLibrary
      />
    </Box>
  );
};

export const OtherDocuments = () => (
  <>
    <AssignedDocuments />
    <OnboardingDocuments />
  </>
);
