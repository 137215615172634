import { useState, useEffect } from 'react';

export default function useOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);

  function handleOnline() {
    setOnlineStatus(true);
  }

  function handleOffline() {
    setOnlineStatus(false);
  }

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { onlineStatus };
}
