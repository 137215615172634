import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControlLabel, Chip, Button } from '@material-ui/core';
import { SwitchSimple, useStyles } from './referral.styles';
import ConfirmAdvocate from './ConfirmAdvocate';
import { dispatch } from '../../../rematch';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_REFERRAL } from '../../../constants/actions.constants';

interface Props {
  disabled?: boolean;
  patientId: number | null;
  isAdvocate: boolean | null;
  advocateReason: string | null;
  isLoadingToggleIsAdvocate: boolean;
}

const AdvocateForm = ({ patientId, isAdvocate, advocateReason, isLoadingToggleIsAdvocate, disabled }: Props) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const toggleIsAdvocate = (advocateExplanation: string): void => {
    dispatch({
      type: 'patient/toggleIsAdvocate',
      payload: { patientId, advocateExplanation, onSuccess: () => toggleModal() },
    });
  };

  return (
    <Box display="flex" justifyContent="flex-end" className={classes.advocateForm}>
      <ConfirmAdvocate
        open={openModal}
        closeModal={toggleModal}
        onConfirm={toggleIsAdvocate}
        isLoadingToggleIsAdvocate={isLoadingToggleIsAdvocate}
      />
      <Box display="flex" alignItems="center">
        {isAdvocate ? (
          <>
            {!!advocateReason && (
              <Box textAlign="center" padding="0 20px">
                <Box className={classes.advocateReasonLabel}>This patient is advocate because:</Box>
                <Box style={{ fontSize: '120%' }}>{advocateReason}</Box>
              </Box>
            )}
            <Chip label="Advocate" className={classes.chip} />
          </>
        ) : (
          <>
            {hasAccessTo(CREATE_REFERRAL, permissions) && (
              <>
                <Box className={classes.makeAdvocateText}>
                  {disabled ? (
                    'This is an active patient who is not eligible for the industry discount anymore'
                  ) : (
                    <Button
                      className={classes.advocateButton}
                      onClick={toggleModal}
                      disabled={isLoadingToggleIsAdvocate}
                    >
                      Make an Advocate
                    </Button>
                  )}
                </Box>
                {!disabled && isAdvocate && (
                  <Box>
                    <FormControlLabel
                      style={{ margin: 0 }}
                      control={<SwitchSimple checked={isAdvocate} />}
                      label={null}
                    />
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdvocateForm;
