import React from 'react';
import { withStyles, Box } from '@material-ui/core';
import moment from 'moment';

const ImageContainer = withStyles((theme) => ({
  root: {
    borderRadius: '5px',
    backgroundColor: '#e7eeed',
    textAlign: 'center',
    padding: '5px',
    color: theme.palette.common.black,
    cursor: 'pointer',
    marginRight: '10px',
    height: '97px',
    alignContent: 'center',
    border: '3px solid transparent',
  },
}))(Box);

const selected = { borderColor: '#12574d', borderWidth: '3px', borderStyle: 'solid' };

const ImageItem = ({ id, thumbnailUrl, largeUrl, date, handlerSelectPhoto, image }: any) => {
  const selectPhotoHandler = (url: string) => {
    handlerSelectPhoto({ photo_id: id, url, photo_services: [] });
  };

  return (
    <ImageContainer
      style={id === image.photo_id ? selected : {}}
      onClick={() => selectPhotoHandler(largeUrl)}
      position="relative"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          background: 'white',
          border: '6px solid #45ce89',
          borderRadius: '100%',
          width: '25px',
          height: '25px',
          fontSize: '6px',
          color: 'white',
          position: 'absolute',
          top: 8,
          right: 5,
        }}
      />
      <img src={thumbnailUrl} alt={date} width="64" height="63" style={{ display: 'block' }} />
      <div style={{ display: 'flex', paddingLeft: 3.5, paddingTop: 3, lineHeight: '14px' }}>
        <small>{moment(date || undefined).format('MM/DD/YYYY')}</small>
      </div>
    </ImageContainer>
  );
};

export default ImageItem;
