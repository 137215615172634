import React from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import { tableStyles } from './practitionerDashboard.styles';
import { EdgeTier, EdgeBenefit, EdgeTierBenefit, EdgeTierBenefits } from '../../../../interfaces/IEdgeTier';
import { useEdgeTiers } from '../../../../hooks/queries/useEdgeTiers';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import { PLACEHOLDER_CELL, HEADER_TITLE, FOOTNOTE, DISCLAIMER } from '../../../../constants/jointInvestments.constants';

const PortraitEdgeChart: React.FC = (): React.ReactElement | null => {
  const { data, isLoading } = useEdgeTiers();

  const { currentTierId, tiers, benefits } = data || {};

  const classes = tableStyles();

  const formatTiers = () =>
    tiers?.map((tier: EdgeTier) => ({
      ...tier,
      benefits: Object.fromEntries(
        Object.values(tier.benefits).map((benefit: EdgeTierBenefit) => [benefit.label, benefit])
      ) as EdgeTierBenefits,
    }));

  if (isLoading) {
    return <MultipleSkeleton />;
  }

  return tiers ? (
    <Box data-testid="PortraitEdgeChart" className={classes.mainBox}>
      <TableContainer className={classes.mainTable}>
        <Table data-testid="PortraitEdgeChartTable">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headercell}>{HEADER_TITLE}</TableCell>
              {tiers.map(
                (tier: EdgeTier): React.ReactElement => (
                  <TableCell
                    key={tier.label}
                    className={classes.headercell}
                    data-highlighted={tier.id === currentTierId}
                    data-testid={`PortraitEdgeChartTableHeader:${tier.id}`}
                  >
                    {tier.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {benefits?.map(
              (benefit: EdgeBenefit): React.ReactElement => (
                <TableRow key={benefit.label}>
                  <TableCell className={classes.rowcell}>{benefit.label}</TableCell>
                  {formatTiers()?.map(
                    (tier: EdgeTier): React.ReactElement => (
                      <TableCell
                        key={benefit.label + tier.label}
                        className={classes.bodycell}
                        data-highlighted={tier.id === currentTierId}
                        data-testid={`PortraitEdgeChartTableBody:${tier.id}:${benefit.id}`}
                      >
                        {tier.benefits[benefit.label]?.description || PLACEHOLDER_CELL}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Typography data-testid="PortraitEdgeFootnote" className={classes.footnote}>
          <span className={classes.subscript}>1</span> {FOOTNOTE}
        </Typography>
        <Typography data-testid="PortraitEdgeDisclaimer" className={classes.footnote}>
          {DISCLAIMER}
        </Typography>
      </Box>
    </Box>
  ) : null;
};

export { PortraitEdgeChart, PortraitEdgeChart as default };
