import { UseQueryResult, useQuery } from 'react-query';
import { IPatientBasicInfo } from 'src/types/Patient';
import Patients from '../../services/Patients';

interface IUseCustomPatientProfile {
  data?: IPatientBasicInfo;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  refetch: UseQueryResult<IPatientBasicInfo>['refetch'];
}

export function useCustomPatientProfile(id: string): IUseCustomPatientProfile {
  const { data, isLoading, isFetching, isError, refetch } = useQuery(['patient', id], () => Patients.getBasicInfo(id), {
    refetchOnWindowFocus: true, // For multi-tab purposes
  });

  return {
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
  };
}
