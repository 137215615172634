import React, { useState } from 'react';
import { Paper, Box, Typography, Avatar, Button, MenuItem, Menu, Radio, Divider, IconButton } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, Event as EventIcon } from '@material-ui/icons';
import { MEDSPA_PROVIDER_ACCOUNT_SETTINGS, MEDSPA_PROVIDER_PATIENTS } from 'src/routes/medspaPractitionerRoutes';
import { useHistory } from 'react-router-dom';
import { useMedspaRole } from 'src/hooks/queries/useMedspaAdmins';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  ADMIN_ROLE,
  MEDSPA_ADMIN_ROLE,
  PORTRAIT_LITE,
  PORTRAIT_SUPPORT_CENTER,
  PRACTITIONER_ROLE,
} from '../../constants/general.constants';
import {
  MEDSPA_ADMIN_BASE_PATH,
  MEDSPA_ADMIN_SETTINGS,
  MEDSPA_DOCUMENTS_MANAGEMENT,
} from '../../routes/medspaAdminRoutes';
import SidebarStyles from '../Sidebar/Sidebar.styles';
import { RootState, dispatch } from '../../rematch';
import { ADMIN_BASE_PATH } from '../../routes/administratorRoutes';
import { Practitioners } from '../../services/Practitioners';

interface HeaderNavProps {
  username: string;
  useStyles: any;
  userType: string;
  roleName?: string | null;
  avatarUrl: string;
  logoutFn: any;
}

function setHeaderNavTitle(userType: string, isMedspaProvider: boolean) {
  switch (userType) {
    case 'Practitioner':
      if (isMedspaProvider) {
        return null;
      }
      return <Typography variant="h6">Practitioner Dashboard</Typography>;
    default:
      return null;
  }
}

const RenderSwitchAccountOption = ({
  name,
  id,
  currentProfile,
  userType,
  roleName,
}: {
  name: string;
  id: number | string;
  userType: string;
  currentProfile: boolean;
  roleName: string;
}) => {
  const classes = SidebarStyles();
  const history = useHistory();
  const switchAccount = () => {
    if (currentProfile) {
      return;
    }
    let callback;
    if (userType === ADMIN_ROLE) {
      if (roleName === MEDSPA_ADMIN_ROLE) {
        callback = () => history.push(MEDSPA_ADMIN_BASE_PATH);
      } else {
        callback = () => history.push(ADMIN_BASE_PATH);
      }
    } else {
      callback = () => history.push(MEDSPA_PROVIDER_PATIENTS);
    }
    dispatch.masquerade.switchAccount({
      id,
      callback,
    });
  };

  let accountType;
  switch (userType) {
    case PRACTITIONER_ROLE:
      accountType = 'Provider Dashboard';
      break;
    case ADMIN_ROLE:
      if (roleName === MEDSPA_ADMIN_ROLE) {
        accountType = 'MedSpa Admin';
      } else {
        accountType = 'Administrator';
      }
      break;
    default:
      return null;
  }

  return (
    <MenuItem className={classes.switchAccountOption} onClick={switchAccount}>
      <Box>
        <Box>{name}</Box>
        <Box className={classes.switchAccountType}>{accountType}</Box>
      </Box>
      <Radio color="primary" checked={currentProfile} />
    </MenuItem>
  );
};

export const SwitchAccounts = ({ classes, username, userId, userType, roleName, linkedAccounts }: any) => (
  <>
    <Box>
      <p className={classes.switchAccountHeader}>SWITCH ACCOUNT</p>
      <RenderSwitchAccountOption name={username} id={userId} userType={userType} roleName={roleName} currentProfile />
      {linkedAccounts.map((accountDetail: Record<string, string>) => (
        <RenderSwitchAccountOption
          name={`${accountDetail.firstName} ${accountDetail.lastName}`}
          id={accountDetail.id}
          userType={accountDetail.userType}
          roleName={accountDetail.roleName}
          currentProfile={false}
        />
      ))}
    </Box>
    <hr className={classes.menuLine} />
  </>
);

const HeaderNav = ({ useStyles, userType, roleName, username, logoutFn, avatarUrl }: HeaderNavProps) => {
  const classes = useStyles(useStyles);
  const history = useHistory();
  const isMedspaProvider = Practitioners.isMedspaProvider();
  const bgColorByUserType = userType === 'Practitioner' ? '#e7eeed' : '#fafafa';
  const { linkedAccounts, userId } = useSelector(({ auth }: RootState) => auth);
  const { title, titleComponent } = useSelector(({ page }: RootState) => page);
  const { data: medspaRoleName } = useMedspaRole();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleAccountSettings = () => history.push(MEDSPA_ADMIN_SETTINGS);
  const handleDocumentsManagement = () => history.push(MEDSPA_DOCUMENTS_MANAGEMENT);

  const supportCenterLink = 'https://support.joinportrait.com/knowledge/announcements';
  const submitTicketLink = 'https://support.joinportrait.com/knowledge/kb-tickets/new';
  const scheduleCallLink = PORTRAIT_SUPPORT_CENTER;

  const showScheduleCallLink = roleName === MEDSPA_ADMIN_ROLE && medspaRoleName === PORTRAIT_LITE;

  return (
    <Box>
      <Paper style={{ boxShadow: isMedspaProvider ? 'none' : 'initial' }}>
        <Box
          display="flex"
          gridGap="1rem"
          alignItems="center"
          className={classes.headerNavContainer}
          style={{
            backgroundColor: isMedspaProvider ? 'white' : bgColorByUserType,
            paddingTop: '12px',
            paddingBottom: '12px',
            alignItems: 'center',
          }}
        >
          <Box flexGrow={1} alignContent="center">
            <Typography className={classes.headerNavTitle}>
              {setHeaderNavTitle(userType, isMedspaProvider) || titleComponent || title}
            </Typography>
          </Box>

          {showScheduleCallLink && (
            <Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                gridGap="1rem"
                justifyContent="flex-end"
                className={classes.hideOnXS}
              >
                <span style={{ flex: 1 }}>Have questions?</span>
                <Button data-cy="scheduleCallLinkBtn" style={{ width: 'max-content' }} variant="outlined">
                  <a href={scheduleCallLink} target="_blank" rel="noreferrer" className={classes.btn}>
                    Submit a ticket
                  </a>
                </Button>
              </Box>
              <Box className={classes.showOnXS}>
                <IconButton href={scheduleCallLink} color="primary" style={{ backgroundColor: '#fafafa' }}>
                  <EventIcon style={{ fontSize: '1.8rem' }} />
                </IconButton>
              </Box>
            </Box>
          )}

          <Box display="flex" alignItems="center">
            <Box className={classes.avatarContainer}>
              <Avatar className={classes.avatar} alt={username} src={avatarUrl} />
            </Box>
            <Box display="flex" alignItems="start" flexDirection="column" className={classes.hideOnXS}>
              <Box>
                <Typography>{username}</Typography>
              </Box>
              <Box mr={2}>{roleName}</Box>
            </Box>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.btnLog}>
              <ExpandMoreIcon data-cy="expandMoreIcon" />
            </Button>

            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem className={classNames(classes.headerNavMenuTitle, classes.showOnXS)}>
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography variant="h6">{username}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" style={{ color: '#969696' }}>
                      {roleName}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
              <Divider className={classes.showOnXS} />
              {isMedspaProvider && (
                <MenuItem onClick={() => history.push(MEDSPA_PROVIDER_ACCOUNT_SETTINGS)}>Account Settings</MenuItem>
              )}
              {isMedspaProvider && (
                <>
                  <Divider />
                  {linkedAccounts?.length > 0 ? (
                    <SwitchAccounts
                      userType={userType}
                      username={username}
                      userId={userId}
                      roleName={roleName}
                      classes={classes}
                      linkedAccounts={linkedAccounts}
                    />
                  ) : null}
                </>
              )}
              {roleName === MEDSPA_ADMIN_ROLE && (
                <>
                  <MenuItem
                    data-cy="medspaAccountSettings"
                    onClick={handleAccountSettings}
                    className={classes.linkMenu}
                  >
                    Account Settings
                  </MenuItem>
                  <MenuItem data-cy="medspaDocuments" onClick={handleDocumentsManagement} className={classes.linkMenu}>
                    Documents
                  </MenuItem>
                  <MenuItem data-cy="medspaSupportCenter">
                    <a href={supportCenterLink} target="_blank" className={classes.linkMenu} rel="noreferrer">
                      Support Center
                    </a>
                  </MenuItem>
                  <MenuItem data-cy="medspaSubmitTicket">
                    <a href={submitTicketLink} target="_blank" className={classes.linkMenu} rel="noreferrer">
                      Submit a Ticket
                    </a>
                  </MenuItem>
                  <Divider />
                  {linkedAccounts?.length > 0 ? (
                    <SwitchAccounts
                      userType={userType}
                      username={username}
                      userId={userId}
                      roleName={roleName}
                      classes={classes}
                      linkedAccounts={linkedAccounts}
                    />
                  ) : null}
                </>
              )}

              <MenuItem data-cy="medspaLogout" onClick={logoutFn} className={classes.linkMenu}>
                Sign Out
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default HeaderNav;
