/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
  Button as MaterialButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { ACUITY_AVAILABILITY_HOURS } from '../../../constants/schemaForm.constants';
import { availabilityOverrideDefaultValues, availabilityOverrideSchema } from './schemas';
import { useUpdateAvailabilityOverrideMutation } from '../../../hooks/acuity/useAcuityAccount';
import { useStyles } from './styles';

const dateFormat = 'YYYY-MM-DD';
const formatAcuityHours = 'hh:mma';
const formatHours = 'HH:mm';
const CustomFromDateTextField = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TextField {...props} name="fromDate" style={{ marginBottom: '10px' }} fullWidth />
);

interface ConfirmModalProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const AvailabilityOverrideModal = ({ openDialog, setOpenDialog }: ConfirmModalProps) => {
  const classes = useStyles();
  const useQuery = () => new URLSearchParams(window.location.search);
  const query = useQuery();
  const practitionerId = query.get('practitionerId') || undefined;
  const updateAvailabilityOverrideMutation = useUpdateAvailabilityOverrideMutation(() => setOpenDialog(false));
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(availabilityOverrideSchema),
    defaultValues: availabilityOverrideDefaultValues,
  });
  const [isTimeOff, setIsTimeOff] = useState(false);
  const watchStart = watch('start');
  const watchEnd = watch('end');

  useEffect(() => {
    setValue('start', moment().format(dateFormat));
    setValue('end', moment().format(dateFormat));
  }, []);

  const handleDialogCloseCancel = () => {
    setOpenDialog(false);
  };

  const onSubmit = (data: any) => {
    const hours = isTimeOff
      ? []
      : [
        `${moment(data.hourStart, [moment.ISO_8601, formatHours]).format(formatAcuityHours)}-${moment(data.hourEnd, [
          moment.ISO_8601,
          formatHours,
        ]).format(formatAcuityHours)}`,
      ];

    data.days = {
      sunday: hours,
      monday: hours,
      tuesday: hours,
      wednesday: hours,
      thursday: hours,
      friday: hours,
      saturday: hours,
    };
    updateAvailabilityOverrideMutation.mutate({ ...data, practitionerId });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={openDialog} onClose={handleDialogCloseCancel} data-cy="confirmationModal">
        <DialogTitle>Availability Override hours</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-content-text">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                disabled={updateAvailabilityOverrideMutation.isLoading}
                variant="dialog"
                inputVariant="outlined"
                label="Start date"
                format={dateFormat}
                autoOk
                disableToolbar
                value={watchStart}
                error={!!errors?.start}
                helperText={errors?.start?.message}
                onChange={(date: any) => setValue('start', date.format(dateFormat))}
                TextFieldComponent={CustomFromDateTextField}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                disabled={updateAvailabilityOverrideMutation.isLoading}
                variant="dialog"
                inputVariant="outlined"
                label="End date"
                format={dateFormat}
                autoOk
                disableToolbar
                value={watchEnd}
                error={!!errors?.end}
                helperText={errors?.end?.message}
                onChange={(date: any) => setValue('end', date.format(dateFormat))}
                TextFieldComponent={CustomFromDateTextField}
              />
            </MuiPickersUtilsProvider>
            <Box paddingBottom={isTimeOff ? '27px' : '10px'}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={updateAvailabilityOverrideMutation.isLoading}
                    color="primary"
                    onChange={() => setIsTimeOff(!isTimeOff)}
                    checked={!isTimeOff}
                    name="Working hours"
                  />
                }
                label={isTimeOff ? 'Day off' : 'Working hours'}
              />
            </Box>

            {isTimeOff ? (
              <Box className={classes.timeText} style={{ position: 'relative', top: '-10px', paddingLeft: '7px' }}>
                All day
              </Box>
            ) : (
              <>
                <Box className={classes.timeText} paddingLeft="5px">
                  {'From '}
                </Box>
                <TextField
                  disabled={isTimeOff || updateAvailabilityOverrideMutation.isLoading}
                  error={!!errors?.hourStart}
                  inputRef={register}
                  helperText={errors?.hourStart ? ACUITY_AVAILABILITY_HOURS : ''}
                  type="time"
                  defaultValue="7:00"
                  name="hourStart"
                  size="small"
                  label="Hour"
                  variant="outlined"
                />
                <div className={classes.timeText}>{' To '}</div>
                <TextField
                  error={!!errors?.hourEnd}
                  inputRef={register}
                  disabled={isTimeOff || updateAvailabilityOverrideMutation.isLoading}
                  helperText={errors?.hourEnd ? ACUITY_AVAILABILITY_HOURS : ''}
                  defaultValue="7:00"
                  type="time"
                  name="hourEnd"
                  size="small"
                  label="Hour"
                  variant="outlined"
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MaterialButton
            disabled={updateAvailabilityOverrideMutation.isLoading}
            data-cy="closeModal"
            onClick={handleDialogCloseCancel}
          >
            Cancel
          </MaterialButton>
          <MaterialButton
            disabled={updateAvailabilityOverrideMutation.isLoading}
            data-cy="confirmModal"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            autoFocus
          >
            Save
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AvailabilityOverrideModal;
