import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Button, Grid, Link } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import useStyles from './footer.styles';

interface FooterProps {
  isSaving: boolean;
  currentStep: number;
  handleSubmit: any;
  skipLink?: string;
  backLink?: string;
  saveTitle?: string;
  disabled?: boolean;
}

const Footer: React.FC<FooterProps> = ({
  isSaving,
  currentStep,
  handleSubmit,
  skipLink,
  backLink,
  saveTitle,
  disabled,
}: FooterProps) => {
  const classes = useStyles();
  const history = useHistory();
  const showSaveButton = !window.location.href.includes('ViewPrimaryServicesLibrary');
  return (
    <Grid container spacing={2} className={classes.footer}>
      <Grid item className={classes.buttonsContainer}>
        <Grid container spacing={2}>
          {showSaveButton && (
            <Grid item>
              <Button
                variant="contained"
                className={classes.saveAndContinue}
                color="primary"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={isSaving || disabled}
              >
                {isSaving ? <CircularProgress size={25} /> : saveTitle ?? 'Save & Continue'}
              </Button>
            </Grid>
          )}
          {currentStep > 1 && skipLink && (
            <Grid item>
              <Button
                variant="contained"
                className={classes.secondaryButton}
                type="submit"
                onClick={() => {
                  history.push(skipLink || '');
                }}
              >
                Skip for now
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        {currentStep > 1 && (
          <Link href={backLink}>
            <Box component="span">{'<'} Back</Box>
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

export default Footer;
