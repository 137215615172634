/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Page from 'src/components/common/Page';
import { ACUITY_TIMEZONE_OPTIONS } from 'src/constants/acuity/acuity.constants';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import { useStyles } from '../PurchaseOrders/purchaseOrder.styles';
import { Locations } from '../../../services/Locations';
import compile from '../../../utils/toastMessagesCompiler';
import { dispatch } from '../../../rematch';
import { useLocationInfo, LOCATIONS } from '../../../hooks/queries/useLocations';
import { ILocationPost } from '../../../interfaces/ILocation';
import { schemaForm, schemaFormEdit } from './schemaForm';
import { ADMIN_LOCATION_LIST_PATH } from '../../../routes/administratorRoutes';

const SUCCESS_LOCATION = compile('generic.success_message', {
  action: 'added',
  element: 'Location',
  duration: 3000,
});
const SUCCESS_LOCATION_EDIT = compile('generic.success_message', {
  action: 'edited',
  element: 'Location',
  duration: 3000,
});
const ERROR_LOCATION = compile('generic.error_message', {
  action: 'customizing',
  element: 'location',
});
const ERROR_LOCATION_EDIT = compile('generic.error_message', {
  action: 'editing',
  element: 'location',
});
const ERROR_GETTING_LOCATION = compile('generic.error_message', {
  action: 'getting',
  element: 'location info',
});

interface FormData {
  customAddress: string;
  name: string;
  acuityLocation: string;
  city: string;
  state: string;
  practitionerId: number;
  latitude: number;
  longitude: number;
  acuityProviderId: number;
  active: boolean;
  timezone: string;
}

const NewLocation: FC = () => {
  const { locationId }: any = useParams();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const mutationCreate = useMutation(Locations.createLocation);
  const mutationEdit = useMutation(Locations.editLocation);
  const loadingCreate = mutationCreate.isLoading;
  const loadingEdit = mutationEdit.isLoading;
  const editing: boolean = typeof locationId !== 'undefined';
  const { location, isFetched, isError } = useLocationInfo(locationId);
  const buttonTitle = editing ? 'SAVE' : 'ADD';

  const { register, control, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(editing ? schemaFormEdit : schemaForm),
    defaultValues: {
      acuityLocation: '',
      customAddress: '',
      name: '',
      city: '',
      state: '',
      practitionerId: 0,
      latitude: 0,
      longitude: 0,
      acuityProviderId: 0,
      active: true,
      timezone: '',
    },
  });

  if (editing && isError && !isFetched) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: ERROR_GETTING_LOCATION,
        type: 'error',
        duration: 2500,
      },
    });
  }

  useEffect(() => {
    if (editing && isFetched) {
      const {
        acuityLocation,
        customAddress,
        name,
        city,
        state,
        practitionerId,
        latitude,
        longitude,
        acuityProviderId,
        active,
        timezone,
      } = location;
      setValue('acuityLocation', acuityLocation);
      setValue('customAddress', customAddress);
      setValue('name', name);
      setValue('city', city);
      setValue('state', state);
      setValue('practitionerId', practitionerId);
      setValue('latitude', latitude);
      setValue('longitude', longitude);
      setValue('acuityProviderId', acuityProviderId);
      setValue('active', active);
      setValue('timezone', timezone || '');
    }
  }, [location]);

  const createLocationAction = async (dataForm: FormData) => {
    const dataToCreate = {
      acuityLocation: dataForm.acuityLocation.trim(),
      customAddress: dataForm.customAddress.trim(),
      name: dataForm.name.trim(),
      city: dataForm.city.trim(),
      state: dataForm.state.trim(),
      practitionerId: dataForm.practitionerId,
      latitude: dataForm.latitude,
      longitude: dataForm.longitude,
      acuityProviderId: dataForm.acuityProviderId,
      timezone: dataForm.timezone,
    };

    try {
      await mutationCreate.mutateAsync(dataToCreate);
      queryClient.invalidateQueries([LOCATIONS, locationId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_LOCATION,
          type: 'success',
          duration: 2500,
        },
      });
      window.location.href = ADMIN_LOCATION_LIST_PATH;
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_LOCATION,
          type: 'error',
          duration: 2500,
        },
      });
    }
  };

  const editLocationAction = async (dataForm: FormData) => {
    const dataToEdit = {
      customAddress: dataForm.customAddress.trim(),
      name: dataForm.name.trim(),
      city: dataForm.city.trim(),
      state: dataForm.state.trim(),
      latitude: dataForm.latitude,
      longitude: dataForm.longitude,
      active: dataForm.active,
      timezone: dataForm.timezone,
    };

    const data: ILocationPost = {
      ...dataToEdit,
      id: location.id,
    };

    try {
      await mutationEdit.mutateAsync(data);
      queryClient.invalidateQueries([LOCATIONS, locationId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_LOCATION_EDIT,
          type: 'success',
          duration: 2500,
        },
      });
      window.location.href = ADMIN_LOCATION_LIST_PATH;
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_LOCATION_EDIT,
          type: 'error',
          duration: 2500,
        },
      });
    }
  };

  const onSubmit = (data: any) => {
    if (editing) {
      editLocationAction(data);
    } else {
      createLocationAction(data);
    }
  };

  const timezone = watch('timezone', '');
  const acuityLocation = watch('acuityLocation', '');

  const MyBreadcrumb = (
    <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BreadcrumbsContainer>
          <BreadcrumbText text="Locations" linkTo={ADMIN_LOCATION_LIST_PATH} />
          <BreadcrumbText text={editing ? 'Edit location' : 'New location'} isActive />
        </BreadcrumbsContainer>
      </Box>
    </Box>
  );

  return (
    <Page titleComponent={MyBreadcrumb}>
      <Box width="100%" display="flex" height="100%" flexDirection="column">
        <Box paddingX={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Box>
              <Typography className={classes.title}>{editing ? 'Edit' : 'New'} location</Typography>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs>
              <TextField
                data-cy="acuityId"
                fullWidth
                name="acuityProviderId"
                onChange={(event) => setValue('acuityProviderId', event.target.value)}
                error={!editing ? !!errors.acuityProviderId : false}
                helperText={!editing ? errors.acuityProviderId?.message : ' '}
                label="Acuity Calendar ID"
                variant="outlined"
                inputRef={register}
                disabled={editing}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                data-cy="practId"
                fullWidth
                name="practitionerId"
                error={!!errors.practitionerId}
                helperText={errors.practitionerId?.message || ' '}
                onChange={(event) => setValue('practitionerId', event.target.value)}
                inputRef={register}
                label="Provider ID"
                type="number"
                variant="outlined"
                disabled={editing}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                data-cy="name"
                fullWidth
                name="name"
                error={!!errors.name}
                helperText={errors.name?.message || ' '}
                onChange={(event) => setValue('name', event.target.value)}
                inputRef={register}
                label="Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs>
              <TextField
                data-cy="city"
                fullWidth
                name="city"
                error={!!errors.city}
                helperText={errors.city?.message || ' '}
                onChange={(event) => setValue('city', event.target.value)}
                label="City"
                type="text"
                inputRef={register}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                data-cy="state"
                fullWidth
                name="state"
                error={!!errors.state}
                helperText={errors.state?.message || ' '}
                onChange={(event) => setValue('state', event.target.value)}
                inputRef={register}
                label="State"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                data-cy="address"
                fullWidth
                name="customAddress"
                error={!!errors.customAddress}
                helperText={errors.customAddress?.message || ' '}
                onChange={(event) => setValue('customAddress', event.target.value)}
                inputRef={register}
                label="Custom address"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs>
              <TextField
                data-cy="latitude"
                fullWidth
                name="latitude"
                error={!!errors.latitude}
                helperText={errors.latitude?.message || ' '}
                onChange={(event) => setValue('latitude', event.target.value)}
                inputRef={register}
                label="Latitude"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs alignItems="center" alignContent="center" style={{ display: 'flex' }}>
              <TextField
                data-cy="longitude"
                fullWidth
                name="longitude"
                onChange={(event) => setValue('longitude', event.target.value)}
                error={!editing ? !!errors.longitude : false}
                helperText={!editing ? errors.longitude?.message : ' '}
                label="Longitude"
                variant="outlined"
                inputRef={register}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs alignItems="center" alignContent="center" style={{ display: 'flex' }}>
              <TextField
                select
                id="timezone"
                data-cy="input-timezone"
                variant="outlined"
                onChange={(event) => {
                  setValue('timezone', event.target.value);
                  if (acuityLocation === null || acuityLocation === undefined || acuityLocation === '') {
                    setValue('acuityLocation', event.target.value);
                  }
                }}
                error={!!errors.timezone}
                helperText={errors.timezone?.message || ' '}
                label="Time Zone"
                value={timezone}
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select a timezone
                </MenuItem>
                {ACUITY_TIMEZONE_OPTIONS.map((timezoneOption: string) => (
                  <MenuItem key={timezoneOption} value={timezoneOption}>
                    {timezoneOption.replace('_', ' ')}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs alignItems="center" alignContent="center" style={{ display: 'flex' }}>
              <FormControlLabel
                style={{
                  width: '100%',
                  padding: '10px 0',
                }}
                control={
                  <Controller
                    control={control}
                    inputRef={register}
                    name="active"
                    render={({ onChange, value }: any) => (
                      <Checkbox
                        disabled={loadingCreate || loadingEdit}
                        onChange={(e) => onChange(e.target.checked)}
                        ref={register}
                        checked={value}
                        className={classes.checkbox}
                        color="default"
                      />
                    )}
                  />
                }
                label="Active Location"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="flex-end">
            <Grid item sm={4}>
              <Button
                data-cy="saveBtn"
                disabled={loadingCreate || loadingEdit}
                variant="contained"
                className={classes.add}
                color="primary"
                type="submit"
              >
                {loadingCreate || loadingEdit ? <CircularProgress size={25} /> : buttonTitle}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default NewLocation;
