/* eslint-disable import/no-unresolved */
import React from 'react';
import MaterialCard from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme) => ({
  card: {
    border: 'none',
    borderRadius: '9px',
    marginBottom: '10px',
    position: 'relative',
  },
}));

type Props = {
  children?: any;
  style?: object;
  className?: string;
};

export const Card = ({ children, className, style }: Props) => {
  const classes = useStyles();
  return (
    <MaterialCard data-cy="card" variant="outlined" className={`${classes.card} ${className}`} style={style}>
      {children}
    </MaterialCard>
  );
};
