import React from 'react';
import {
  // FormGroup,
  // FormControlLabel,
  // Checkbox,
  // Box,
  // Typography,
  Grid,
  // FormControl,
  // InputLabel,
  // OutlinedInput,
  // InputAdornment,
  // IconButton
} from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  historySearchInputContainer: {},
  filter: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '14px',
    color: '#12574d',
  },
  searchFont: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    opacity: 0.44,
    lineHeight: '14px',
  },
}));

const HistorySearchInput = () => {
  // const [search, setSearch] = useState('');
  // const [stateCheckbox, setStateCheckbox] = useState({
  //   treatments: true,
  //   customFormulations: false
  // });

  const classes = useStyles();
  // const handleCheckbox = (event: any) =>
  //   setStateCheckbox({ ...stateCheckbox, [event.target.name]: event.target.checked });

  // const handleInput = (event: any) => setSearch(event.target.value);

  return (
    <div style={{ padding: '0 20px' }}>
      <form>
        <Grid container direction="row" alignItems="center" className={classes.historySearchInputContainer}>
          {/* WILL BE IMPLEMENTED IN THE FUTURE */}
          {/* <Grid container item alignItems='center' xs={7}>
            <Box mr='15px'>
              <Typography className= {classes.filter} variant='body2' component='span'>Filter</Typography>
            </Box>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateCheckbox.treatments}
                    onChange={handleCheckbox}
                    name='treatments'
                    color='primary'
                  />
                }
                label={
                  <Typography style={{ fontFamily: 'Messina Sans Regular', fontSize: '15px' }}>
                    Treatments
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateCheckbox.customFormulations}
                    onChange={handleCheckbox}
                    name='customFormulations' color='primary'
                  />
                }
                label={
                  <Typography style={{ fontFamily: 'Messina Sans Regular', fontSize: '15px' }}>
                    Custom formulations
                  </Typography>
                }
              />
            </FormGroup>
          </Grid> */}
          {/* <Grid container item alignItems='center' xs={5}>
            <FormControl variant='outlined' size="small" >
              <InputLabel htmlFor='searchHistory' className={classes.searchFont}>Search</InputLabel>
              <OutlinedInput
                id='searchHistory'
                value={search}
                onChange={handleInput}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton aria-label='search history'edge='end'>
                      <SearchIcon style={{color: 'black'}}/>
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default HistorySearchInput;
