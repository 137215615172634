import { useQuery } from 'react-query';
import { ROLES } from '../../constants/reactQuery.keys';
import { IRoles, IRolesParams } from '../../interfaces/IRoles.interfaces';
import Roles from '../../services/Roles';

export function useCustomRoles(params: IRolesParams | null) {
  const {
    data = [],
    isLoading,
    isFetching,
    isError,
  } = useQuery([ROLES, params], () => Roles.getRoles(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    data: data as IRoles[],
    isLoading,
    isFetching,
    isError,
  };
}
