/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Table, TableBody, TableContainer, TableHead, TableRow, Typography, Tabs, Tab } from '@material-ui/core';

import AssetsSoldRow from './AssetsSoldRow';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import DiscountsRow from './DiscountsRow';
import LineItemsRow from './LineItemsRow';
import TendersRow from './TendersRow';
import TransactionsHeader from './TransactionsHeader';
import TransactionsRow from './TransactionsRow';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: any) => ({
  id: `transactions-tab-${index}`,
  'aria-controls': `transactions-tabpanel-${index}`,
});

const Transactions = () => {
  const [tab, setTab] = useState(0);

  const { transactions, lineItems, discounts, tenders, assetsSold, isLoading } = useSelector(
    (state: any) => state.transaction
  );

  const handleTabChange = (event: any, newTab: number) => {
    setTab(newTab);
  };

  const CustomHeader = ({ children, width }: any) => (
    <TableCellHeader>
      <div style={{ whiteSpace: 'nowrap', width }}>{children}</div>
    </TableCellHeader>
  );

  const TransactionsTable = isLoading ? (
    <MultipleSkeleton addPosition={false} />
  ) : (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomHeader>Checkout</CustomHeader>
              <CustomHeader>Service visit id</CustomHeader>
              <CustomHeader>Patient MRN</CustomHeader>
              <CustomHeader>Date</CustomHeader>
              <CustomHeader>Time</CustomHeader>
              <CustomHeader width="150px">Practitioner</CustomHeader>
              <CustomHeader>Event type</CustomHeader>
              <CustomHeader>Total</CustomHeader>
              <CustomHeader>Discounts</CustomHeader>
              <CustomHeader>New sales</CustomHeader>
              <CustomHeader>Other tender totals</CustomHeader>
              <CustomHeader>Square transaction amount</CustomHeader>
              <CustomHeader>Square transaction id</CustomHeader>
              <CustomHeader width="300px">Note</CustomHeader>
              <CustomHeader>Open Square</CustomHeader>
              <CustomHeader>Open EHR</CustomHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction: any) => (
              <TransactionsRow key={transaction.id} transaction={transaction} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const LineItemsTable = isLoading ? (
    <MultipleSkeleton addPosition={false} />
  ) : (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomHeader>Checkout</CustomHeader>
              <CustomHeader>Service visit id</CustomHeader>
              <CustomHeader>Checkout line item id</CustomHeader>
              <CustomHeader>Patient MRN</CustomHeader>
              <CustomHeader width="150px">Practitioner</CustomHeader>
              <CustomHeader>Service id</CustomHeader>
              <CustomHeader>Service visit created at</CustomHeader>
              <CustomHeader>Service name</CustomHeader>
              <CustomHeader>Quantity</CustomHeader>
              <CustomHeader>Unit</CustomHeader>
              <CustomHeader>Total without discount</CustomHeader>
              <CustomHeader>Total with discount</CustomHeader>
              <CustomHeader>Number of real assets involved</CustomHeader>
              <CustomHeader>Sum of all costs</CustomHeader>
              <CustomHeader>All Costs</CustomHeader>
              <CustomHeader>Open EHR</CustomHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((lineItem: any) => (
              <LineItemsRow key={lineItem.id} lineItem={lineItem} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const DiscountsTable = isLoading ? (
    <MultipleSkeleton addPosition={false} />
  ) : (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomHeader>Checkout</CustomHeader>
              <CustomHeader>Service visit id</CustomHeader>
              <CustomHeader>Checkout line item id</CustomHeader>
              <CustomHeader>Patient MRN</CustomHeader>
              <CustomHeader width="150px">Practitioner</CustomHeader>
              <CustomHeader>Date</CustomHeader>
              <CustomHeader>Value</CustomHeader>
              <CustomHeader>Discount type</CustomHeader>
              <CustomHeader width="250px">Description</CustomHeader>
              <CustomHeader width="250px">Discount name</CustomHeader>
              <CustomHeader>Discount Id</CustomHeader>
              <CustomHeader>Service Price</CustomHeader>
              <CustomHeader>Dollar amount discounted</CustomHeader>
              <CustomHeader>Open EHR</CustomHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {discounts.map((discount: any) => (
              <DiscountsRow key={discount.id} discount={discount} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const TendersTable = isLoading ? (
    <MultipleSkeleton addPosition={false} />
  ) : (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomHeader>Checkout</CustomHeader>
              <CustomHeader>Service visit id</CustomHeader>
              <CustomHeader>Checkout tender id</CustomHeader>
              <CustomHeader>Transaction date</CustomHeader>
              <CustomHeader>Transaction time</CustomHeader>
              <CustomHeader>Patient MRN</CustomHeader>
              <CustomHeader width="150px">Practitioner</CustomHeader>
              <CustomHeader>Voucher</CustomHeader>
              <CustomHeader>Value</CustomHeader>
              <CustomHeader>Tender type</CustomHeader>
              <CustomHeader>Open EHR</CustomHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenders.map((tender: any) => (
              <TendersRow key={tender.id} tender={tender} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const AssetsSoldTable = isLoading ? (
    <MultipleSkeleton addPosition={false} />
  ) : (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomHeader>Checkout</CustomHeader>
              <CustomHeader>Service visit id</CustomHeader>
              <CustomHeader>Checkout line item id</CustomHeader>
              <CustomHeader>Service id</CustomHeader>
              <CustomHeader>Service</CustomHeader>
              <CustomHeader>Quantity in line item</CustomHeader>
              <CustomHeader>Number of Assets involved</CustomHeader>
              <CustomHeader>Cost of Assets involved</CustomHeader>
              <CustomHeader>Sum of all costs</CustomHeader>
              <CustomHeader>Sales price</CustomHeader>
              <CustomHeader width="300px">Margin</CustomHeader>
              <CustomHeader>Internal serial numbers</CustomHeader>
              <CustomHeader width="150px">Warehouse</CustomHeader>
              <CustomHeader>PurchaseOrderIds</CustomHeader>
              <CustomHeader>Open EHR</CustomHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {assetsSold.map((assetSold: any) => (
              <AssetsSoldRow key={assetSold.id} assetSold={assetSold} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Box width="100%">
      <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <BreadcrumbsContainer>
            <BreadcrumbText text="Dashboards" />
            <BreadcrumbText text="Transactions" isActive linkTo="/administrator/transactions" />
          </BreadcrumbsContainer>
        </Box>
      </Box>

      <TransactionsHeader />

      <Box p={2}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label={`Transactions (${transactions.length})`} {...a11yProps('transactions')} />
          <Tab label={`Line items (${lineItems.length})`} {...a11yProps('line-items')} />
          <Tab label={`Discounts (${discounts.length})`} {...a11yProps('discounts')} />
          <Tab label={`Tenders (${tenders.length})`} {...a11yProps('tenders')} />
          <Tab label={`Assets sold (${assetsSold.length})`} {...a11yProps('assets-sold')} />
        </Tabs>

        <TabPanel value={tab} index={0}>
          {TransactionsTable}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {LineItemsTable}
        </TabPanel>
        <TabPanel value={tab} index={2}>
          {DiscountsTable}
        </TabPanel>
        <TabPanel value={tab} index={3}>
          {TendersTable}
        </TabPanel>
        <TabPanel value={tab} index={4}>
          {AssetsSoldTable}
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Transactions;
