import React, { useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStyles } from './AccountCredit.styles';
import { Button } from '../../../components/common/Button';
import { dispatch } from '../../../rematch';
import { ACCOUNT_CREDIT_CHARGE_TYPE } from '../../../constants/checkout.constants';
import IServices from '../../../interfaces/IServices';

const AccountCredit = ({ patientId }: { patientId: string }) => {
  const classes = useStyles();
  const history = useHistory();
  const [creditAmount, setCreditAmount] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const { isProgressQueueWorking } = useSelector(({ newServiceVisit }: any) => newServiceVisit);

  const createAndNavigateToCheckout = async () => {
    try {
      setProcessing(true);
      dispatch({
        type: 'patient/prepareCheckoutForFee',
        payload: {
          patientId,
          chargeAmount: creditAmount,
          chargeType: ACCOUNT_CREDIT_CHARGE_TYPE,
          successCallback: (serviceVisit: IServices) => {
            setProcessing(false);
            history.push(`/patient/${patientId}/newServiceVisit/${serviceVisit.id}/checkout`);
          },
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  const validCreditAmount = () => creditAmount && parseFloat(creditAmount) > 0;

  return (
    <div className={classes.container}>
      This amount will be added to the client&apos;s account as an Account Credit. It does not expire.
      <div className={classes.creditAmountContainer}>
        <NumberFormat
          className={classes.creditAmountInput}
          decimalScale={2}
          fixedDecimalScale
          customInput={TextField}
          variant="outlined"
          label="$"
          // @ts-ignore
          value={creditAmount}
          autoComplete="off"
          name="credit_amount"
          onChange={(e) => {
            setCreditAmount(e.target.value);
          }}
          disabled={processing}
          error={creditAmount && !validCreditAmount()}
          helperText={creditAmount && !validCreditAmount() && 'Credit must be greater than 0'}
        />
      </div>
      <p>
        By processing this payment I confirm the patient has provided verbal or written consent to charge the credit
        card on file for the amount charged.
      </p>
      <Button
        disabled={!validCreditAmount() || processing || isProgressQueueWorking}
        className={classes.button}
        // @ts-ignore
        title={
          // eslint-disable-next-line no-nested-ternary
          processing ? (
            <>
              Processing
              <CircularProgress size={20} className={classes.processing} />
            </>
          ) : validCreditAmount() ? (
            `Checkout: $${creditAmount}`
          ) : (
            'Checkout'
          )
        }
        onClick={createAndNavigateToCheckout}
      />
    </div>
  );
};

export default AccountCredit;
