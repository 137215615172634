import React, { useMemo } from 'react';
import { WeekView, WeekViewProps } from '@devexpress/dx-react-scheduler-material-ui';
import { Box, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { WeekViewTimeTableCell } from './WeekViewTimeTableCell'; // For MedspaCalendar V2
import { WeekViewDayScaleCell } from './WeekViewDayScaleCell';
import { AppointmentCard } from './MedspaCalendarAppointments';
import { VIEW_NAME_WEEK } from '../indexV2';

const useStyles = makeStyles({
  ehrWeekCell: {
    border: '1px solid',
    display: 'contents',
  },
  ehrWeekCellBox: {
    overflowY: 'scroll',
    maxHeight: '14rem',
    paddingBottom: '1.5rem',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
});
interface CustomTimeTableCellProps extends WeekView.TimeTableCellProps {
  data: any;
}
const CustomTableCellComponent = ({ groupingInfo, ...restProps }: CustomTimeTableCellProps) => {
  const classes = useStyles();
  const boxStartDate = moment(String(restProps?.startDate));
  const boxEndDate = moment(String(restProps?.endDate));
  const rowPractitionerId = groupingInfo[0].id;

  const items = useMemo(
    () =>
      restProps.data
        ?.filter(
          ({ practitionerId, startDate }) =>
            practitionerId === rowPractitionerId &&
            moment(startDate).isSameOrAfter(boxStartDate) &&
            moment(startDate).isSameOrBefore(boxEndDate)
        )
        ?.sort((a: any, b: any) => new Date(a.startDate) - new Date(b.startDate)),
    [restProps.data, boxStartDate]
  );

  const handleWeekClick = (appointment: any) => {
    try {
      // Hack to get original node with triggered event for modal for: MedspaCalendarAppointments.tsx
      const originalAppointment = document.getElementById(`${appointment?.bookingType}-${appointment?.id}`)
        ?.childNodes[0];
      originalAppointment?.click?.();
      // TODO: check if is possible to set the appointment from here:
      // restProps.onDoubleClick()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <Box className={classes.ehrWeekCell}>
      <WeekViewTimeTableCell groupingInfo={groupingInfo} {...restProps}>
        <Box
          className={classes.ehrWeekCellBox}
          id={`${rowPractitionerId}-${boxStartDate.startOf('day').format('l LT')}`}
        >
          {items.map((item) => (
            <AppointmentCard onClick={() => handleWeekClick(item)} view={VIEW_NAME_WEEK} appointmentData={item} />
          ))}
        </Box>
      </WeekViewTimeTableCell>
    </Box>
  );
};

interface CustomWeekViewProps extends WeekViewProps {
  data: any;
}
const CustomWeekView = ({ ...restProps }: CustomWeekViewProps) => (
  <WeekView
    {...restProps}
    startDayHour={0}
    endDayHour={24}
    cellDuration={1440}
    dayScaleCellComponent={WeekViewDayScaleCell}
    timeTableCellComponent={(props: WeekView.TimeTableCellProps) => (
      <CustomTableCellComponent data={restProps.data} {...props} />
    )}
    timeScaleLayoutComponent={() => <></>}
    timeScaleLabelComponent={() => <></>}
  />
);

export { CustomWeekView };
