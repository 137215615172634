import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  SERVICE_SELECTED_ITEMS,
  SERVICE_VISIT_ANNOTATED_PHOTOS,
  SERVICE_VISIT_ASSET_SCANNED,
  SERVICE_VISIT_CHECKOUT,
  SERVICE_VISIT_CONSENTS,
  SERVICE_VISIT_CUSTOMER_STANDING_ORDERS,
} from '../../constants/reactQuery.keys';
import { ISaveSelectedServicesParams, IUpdateLineItemParams } from '../../interfaces/params/IServiceVisitParams';
import ServiceVisit from '../../services/ServiceVisit';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import { SNACKBAR_INFO } from '../../constants/general.constants';
import compile from '../../utils/toastMessagesCompiler';

/* List of all Service selected (Line Items)
 * Params: id (service visit id)
 * keepPreviousData false to avoid cache issues beetwen tabs
 */
export const useSelectedServices = (id: number) => {
  const response = useQuery([SERVICE_SELECTED_ITEMS, id], () => ServiceVisit.getSelectedServices(id), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: false,
    onError: () =>
      useReactQueryOnErrorHandler(
        compile('generic.error_message', { action: 'fetching', element: 'selected services' })
      ),
  });

  return {
    ...response,
    data: response.data?.lineItems || [],
    selectedServiceIds: response.data?.lineItems?.map(({ serviceId }) => serviceId) || [],
    selectedServiceIdsWithCurrentUnits:
      response.data?.lineItems
        ?.filter(({ currentUseQuantity }) => currentUseQuantity > 0)
        .map(({ serviceId }) => serviceId) || [],
  };
};

export const useSaveSelectedServiceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ISaveSelectedServicesParams) => ServiceVisit.saveServiceSelected(+params.serviceVisitId, params),
    {
      onSuccess: ({ success, messages = [] }: { success: boolean; messages: string[] }, params) => {
        if (!success) {
          showSnackbar(compile('generic.server_error'));
          return;
        }

        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, +params.serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_SELECTED_ITEMS, +params.serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_VISIT_ASSET_SCANNED, +params.serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_VISIT_CONSENTS, +params.serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_VISIT_CUSTOMER_STANDING_ORDERS, +params.serviceVisitId]);

        messages.forEach((message) => showSnackbar(message, SNACKBAR_INFO));
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};

export const useUpdateCheckoutLineItemMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: IUpdateLineItemParams) =>
      ServiceVisit.updateLineItem(+params.serviceVisitId, +params.checkoutLineItemId, params),
    {
      onSuccess: ({ success, messages = [] }: { success: boolean; messages: string[] }, params) => {
        if (!success) {
          showSnackbar(compile('generic.server_error'));
          return;
        }

        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, +params.serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_SELECTED_ITEMS, +params.serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_VISIT_ASSET_SCANNED, +params.serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_VISIT_CONSENTS, +params.serviceVisitId]);

        messages.forEach((message) => showSnackbar(message, SNACKBAR_INFO));
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};

export const useRemoveSelectedServiceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ISaveSelectedServicesParams) => ServiceVisit.removeServiceSelected(params.serviceVisitId, params),
    {
      onSuccess: ({ success, messages = [] }: { success: boolean; messages: string[] }, params) => {
        if (!success) {
          showSnackbar(compile('generic.server_error'));
          return;
        }

        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, params.serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_SELECTED_ITEMS]);
        queryClient.invalidateQueries([SERVICE_VISIT_ASSET_SCANNED]);
        queryClient.invalidateQueries([SERVICE_VISIT_ANNOTATED_PHOTOS, params.serviceVisitId]);
        messages.forEach((message) => showSnackbar(message, SNACKBAR_INFO));
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};
