import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    left: '-8px',
    width: '110%',
  },
  circleBefore: {
    width: '9px',
    height: '9px !important',
    background: '#FF5252',
    borderRadius: '9px',
    zIndex: 1,
  },
  indicator: {
    width: '100%',
    height: '2px !important',
    background: '#FF5252',
    position: 'relative',
    left: '-3px',
    margin: '0px',
    zIndex: 1,
  },
}));

const AppointmentsTimeIndicator = () => {
  const classes = useStyles();

  return (
    <div className={classes.container} id="timer-indicator">
      {/* <div className={classes.circleBefore} /> */}
      <div className={classes.indicator} />
    </div>
  );
};

export default AppointmentsTimeIndicator;
