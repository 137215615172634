import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper, ClickAwayListener, Popper, Button, Grow, MenuItem, MenuList, Box } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { useStyles } from './modalPoints.styles';
import { getGaldermaServicesSelected } from '../../../utils/galderma.utils';
import IServices from '../../../interfaces/IServices';
import { dispatch } from '../../../rematch';
import { PatientPointCertificatesState } from '../../../types/PatientPointCertificatesState';

const ProductLocation = () => {
  const { services } = useSelector((store: any) => store.common);
  const { totalServicesUnits } = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const { galdermaProducts, skusSelected, galdermaApplicableProducts, isLoadingPatientCertificates } = useSelector(
    ({ patientPointCertificates }: any) => patientPointCertificates as PatientPointCertificatesState
  );
  const galdermaServicesSelected = getGaldermaServicesSelected(galdermaProducts, totalServicesUnits, services);

  const classes = useStyles();
  const [open, setOpen] = useState<any>({});

  const handleToggle = (serviceId: number) => {
    setOpen({ ...open, [serviceId]: !open[serviceId] });
  };

  const handleClose = (serviceId: number, galdermaSku: string | null) => {
    setOpen({ ...open, [serviceId]: false });

    const newSkusSelected = { ...skusSelected, [serviceId]: galdermaSku };

    dispatch.patientPointCertificates.setSkusSelected(newSkusSelected);
  };

  const applicableSkus = galdermaApplicableProducts.map(({ sku }) => sku);
  return (
    <Box mb="22px">
      {galdermaServicesSelected.map(({ id, galdermaSku, name }: IServices) => {
        const galdermaSkus = JSON.parse(galdermaSku || '{}');
        const isApplicable = Object.values(galdermaSkus).some((sku) => applicableSkus.includes(sku as string));

        return (
          <Box mb="22px" display="flex">
            <Box width="40%" marginLeft="8%">
              <div className={classes.locationLabel} style={{ color: isApplicable ? 'black' : 'grey' }}>
                {name}
              </div>
            </Box>
            <Box width="40%" marginLeft="4%">
              <Button
                className={classes.locationButton}
                aria-controls={open[id] ? `product-list-grow-2-${id}` : undefined}
                aria-haspopup="true"
                disabled={Object.keys(galdermaSkus).length < 2 || isLoadingPatientCertificates}
                onClick={() => handleToggle(id)}
                endIcon={<ArrowDropDownIcon />}
              >
                {Object.keys(galdermaSkus).find((key) => galdermaSkus[key] === skusSelected[id]) || 'Face'}
              </Button>
              <Popper
                placement="bottom-end"
                open={open[id]}
                role={undefined}
                transition
                disablePortal
                style={{ width: '191px', zIndex: 1, top: '-6.5% !important', left: 'inherit' }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => setOpen({ ...open, [id]: false })}>
                        <MenuList autoFocusItem={open[id]} id={`product-list-grow-2-${id}`}>
                          {Object.keys(galdermaSkus).map((key: string) => (
                            <MenuItem
                              key={galdermaSkus[key]}
                              onClick={() => handleClose(id, galdermaSkus[key])}
                              className={classes.locationSelectItem}
                            >
                              {key}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ProductLocation;
