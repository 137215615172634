// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  iconColor: {
    fill: 'RGB (0,87,77) !important',
  },
  buttonTab: {
    margin: 0,
    padding: 1,
  },
  tab: {
    width: '125px',
  },
  typographyClass: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '30px',
    fontWeight: 600,
    color: '#949494',
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '80%',
    height: '30%',
    margin: 'auto',
  },
  pendingNotification: {
    background: '#FAC980',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    marginLeft: '2px',
    marginTop: '6px',
  },
  redPendingNotification: {
    background: '#FF6262',
  },
  headTableCell: {
    fontWeight: 700,
    fontSize: '15px',
    color: '#3a3b3f',
  },
  newReportButton: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'center',
    color: '#000000',
    borderRadius: '3px',
    border: 'none',
    padding: '16px 24px',
    backgroundColor: '#e7eeed',
    marginRight: '1rem',
  },
  signButton: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(57, 59, 63, 0.12)',
    color: '#12574D !important',
    borderRadius: '4px',
    width: '124px',
    height: '36px',
  },
  inventoryTab: {
    display: 'flex',
  },
  helpCenterButton: {
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    alignItems: 'center',
  },
  helpCenterButtonContainer: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& button': {
      margin: 'auto 3px',
    },
  },
});
