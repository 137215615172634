import React, { useRef } from 'react';
import { Box, Table, TableBody, TableContainer, makeStyles } from '@material-ui/core';
import InfiScroller from 'react-infi-scroller';
import classnames from 'classnames';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import TableHeader from '../../../common/Table/TableHeader';
import UpcomingPatientsRow from './UpcomingPatientsRow';
import SortDirection, { ASCENDING } from '../../../../types/SortDirection';
import { DEFAULT_LIMIT } from '../../../../constants/general.constants';

interface Props {
  isLoading: boolean;
  data: any;
  isError: boolean;
  errorMessage: string;
  state?: string;
  sortBy: string;
  sortDirection?: SortDirection;
  page: number;
  total: number;
  onChangeSortBy: (by: string, direction: SortDirection) => void;
  fetchNextPage: () => void;
}

type TableResultsProps = {
  data: any | undefined;
  isError: boolean;
  errorMessage: string;
  state: string | undefined;
};

const useStyles = makeStyles(() => ({
  tableGeneral: {
    position: 'relative',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'hidden',
  },
  name: {
    paddingLeft: 20,
  },
  bodyContainer: {
    position: 'relative',
  },
  infiScrollContainer: {
    overflow: 'auto',
    height: '84.7vh',
    width: '104%',
    marginTop: '10px',
  },
}));

const ShowMessage = ({ text }: any) => (
  <tr>
    <td colSpan={7}>
      <Box textAlign="center">
        <p>{text}</p>
      </Box>
    </td>
  </tr>
);

const TABLE_COLUMNS = [
  { id: 'name', title: 'Name' },
  { id: 'id', title: 'Patient MRN' },
  { id: 'appointment', title: 'Appointment ID' },
  { id: 'practitioner', title: 'Provider' },
  { id: 'email', title: 'Email' },
  { id: 'updates', title: 'Updates' },
  { id: 'datetime', title: 'Next appointment', sort: true },
  { id: 'visits', title: 'Visits', sort: true },
];

const TableResults = ({ data, isError, errorMessage = '', state }: TableResultsProps) => {
  if (isError) {
    return <ShowMessage text={errorMessage} />;
  }
  if (!data) {
    return <></>;
  }

  return data.length > 0 ? (
    <>
      {data.map((row: any) => (
        <UpcomingPatientsRow row={{ ...row }} state={state} key={row.id} />
      ))}
    </>
  ) : (
    <ShowMessage text="No data to show" />
  );
};
const UpcomingPatientsTable = ({
  isLoading,
  isError,
  errorMessage,
  data,
  onChangeSortBy,
  sortBy,
  sortDirection = ASCENDING,
  state,
  page,
  total,
  fetchNextPage,
}: Props) => {
  const classes = useStyles();
  const refComponent = useRef(null);

  return (
    <div className={classnames(classes.tableGeneral, classes.infiScrollContainer)} ref={refComponent}>
      <InfiScroller
        hasMore={page * DEFAULT_LIMIT <= total}
        scrollTarget={refComponent?.current}
        onLoadMore={fetchNextPage}
      >
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHeader
              columns={TABLE_COLUMNS}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={onChangeSortBy}
            />
            <TableBody>
              {isLoading ? (
                <MultipleSkeleton width="90%" key={0} />
              ) : (
                <TableResults data={data} isError={isError} errorMessage={errorMessage} state={state} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiScroller>
    </div>
  );
};

export default UpcomingPatientsTable;
