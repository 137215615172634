import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  typographySize: {
    fontSize: '138%',
  },
  warningProgressiveSaving: {
    right: '15px',
    bottom: '0px',
    height: '32px',
    zIndex: 10,
    position: 'fixed',
    background: '#ffd94c',
    textAlign: 'center',
    borderRadius: '10px',
    padding: '2px 5px',
    margin: '10px',
    cursor: 'pointer',
  },
}));
