import React, { memo } from 'react';
import { FormControl, MenuItem, Select, SelectProps, withStyles } from '@material-ui/core';
import { isEqual, range } from 'lodash';
import { KeyboardArrowDown } from '@material-ui/icons';
import { BootstrapInput } from './base/BootstrapInput';

type Props = Omit<SelectProps, 'onChange'> & {
  dataCy: string;
  start?: number;
  end?: number;
  onChange: (value: number) => void;
  rangeFilter?: (value: number) => boolean;
};

const EHRSelectIcon = () => (
  <KeyboardArrowDown
    className="MuiSelect-icon"
    viewBox="6 6 12 12"
    style={{
      width: '0.7rem',
      height: '0.8rem',
      marginRight: '1rem',
      transform: 'translateY(50%)',
    }}
  />
);

export const EHRNumberSelect = memo(
  withStyles({
    formControl: {
      maxWidth: 80,
    },
    select: {
      width: '100%',
    },
    input: {
      width: '100%',
    },
    outlined: {
      borderColor: '#ddd',
    },
  })(({ classes, dataCy, start = 0, end = 11, value, error, onChange, inputProps, rangeFilter }: Props) => {
    const fullRange = range(start, end);
    const displayRange = !rangeFilter ? fullRange : fullRange.filter(rangeFilter);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) =>
      onChange(+((event.target.value || '0') as string));

    return (
      <FormControl variant="outlined" classes={classes}>
        <Select
          data-cy={dataCy}
          onChange={handleChange}
          value={value ?? String(start)}
          inputProps={inputProps}
          IconComponent={EHRSelectIcon}
          error={error}
          input={<BootstrapInput classes={classes} error={error} />}
          autoWidth
        >
          {displayRange.map((numberValue: number) => (
            <MenuItem classes={classes} key={numberValue} value={numberValue}>
              {numberValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }),
  (oldProps, newProps) => isEqual(oldProps, newProps)
);

export default EHRNumberSelect;
