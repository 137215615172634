import React, { useState } from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, Box, Typography, Dialog } from '@material-ui/core';
import { AT_COST_SUPPLIERS, SUPPLIERS_HELPER_TEXT } from 'src/constants/inventory.constants';
import { EHRButton } from 'src/components/ui/v1';
import { Error as ErrorIcon } from '@material-ui/icons';
import { ClassNameMap } from 'src/types/Dom';
import ISuppliers from 'src/interfaces/ISuppliers';
import { TableCellHeader } from './inventoryOrder.styles';
import TableHeaderCells from './OrderHeaderCells';
import AddCustomItem from './AddCustomItem';

type Props = ClassNameMap & {
  supplier: ISuppliers | string;
  inventoryOrderId?: number;
  edit?: boolean;
  isLegacy?: boolean;
  children?: React.ReactElement | null;
  testid?: string;
  showMore: boolean;
  setExpanded: () => void;
};

export default ({
  classes,
  inventoryOrderId,
  supplier,
  edit = false,
  isLegacy = false,
  children,
  testid,
  setExpanded,
  showMore,
}: Props) => {
  const [addCustomItemDialogOpen, setAddCustomItemDialogOpen] = useState<boolean>(false);

  const hasFullSupplierDetails = typeof supplier !== 'string';
  const supplierName = hasFullSupplierDetails ? supplier.name : supplier;

  return (
    <TableContainer data-testid={testid} className={classes?.tableContainer}>
      {SUPPLIERS_HELPER_TEXT[supplierName] && (
        <Box display="flex" flexDirection="row" className={classes?.suppliersHelperText}>
          <ErrorIcon />
          <Typography component="p" style={{ margin: 'auto 8px' }}>
            {SUPPLIERS_HELPER_TEXT[supplierName]}
          </Typography>
        </Box>
      )}
      <Table className={classes?.innerTable}>
        <TableHead>
          <TableRow>
            <TableCellHeader className={classes?.innerTableCellHeader}>{supplierName}</TableCellHeader>
            {hasFullSupplierDetails && (
              <TableCellHeader colSpan={6} className={classes?.innerTableCellHeader}>
                <Box width="100%" display="flex">
                  <Box maxWidth={155} margin="0 0 0 auto">
                    <EHRButton
                      dataCy={`order.supplier.${supplierName}.addCustomItem`}
                      color="default"
                      text="Add a custom item"
                      onClick={() => setAddCustomItemDialogOpen(true)}
                    />
                  </Box>
                </Box>
              </TableCellHeader>
            )}
          </TableRow>
          <TableRow>
            <TableHeaderCells
              editMode={edit}
              isLegacy={isLegacy}
              noSavings={AT_COST_SUPPLIERS.indexOf(supplierName) > -1}
            />
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      {showMore && (
        <Box display="flex" flexDirection="row" width="100%" padding="10px 0">
          <EHRButton
            onClick={setExpanded}
            text="Show More Products"
            color="primary"
            dataCy={`order.supplier.${supplierName}.showMore`}
            className={classes?.showMoreButton}
          />
        </Box>
      )}
      {hasFullSupplierDetails && !!inventoryOrderId && (
        <Dialog open={addCustomItemDialogOpen} onBackdropClick={() => setAddCustomItemDialogOpen(false)}>
          <AddCustomItem
            supplier={supplier}
            inventoryOrderId={inventoryOrderId}
            dismiss={() => setAddCustomItemDialogOpen(false)}
          />
        </Dialog>
      )}
    </TableContainer>
  );
};
