import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// Components
import { Button, Grid } from '@material-ui/core';
import { APPOINTMENT_TYPE_COLUMNS } from 'src/constants/acuity/columns.constants';
import TablePaginator from 'src/components/common/Table/TablePaginator';
import { useStyles } from './styles';
import AppointmentTypeForm from './AppointmentTypeForm';
import { useAppointmentTypes } from '../../../hooks/practitioner/useBooking';
import { IAppointmentType } from '../../../interfaces/acuity/acuity.interface';
import {
  useDeleteAppointmentTypeMutation,
  useUpdateAppointmentTypeMutation,
} from '../../../hooks/acuity/useAcuityAccount';
import ConfirmModal from '../../../components/common/ConfirmModal';
import {
  ARE_YOU_SURE_DELETE_APPOINTMENT_TYPE,
  DELETE_APPOINTMENT_TYPE,
} from '../../../constants/acuity/acuity.constants';

const AppointmentTypes = () => {
  const useQuery = () => new URLSearchParams(window.location.search);
  const query = useQuery();
  const practitionerId = query.get('practitionerId') || undefined;
  const classes = useStyles();
  const [appointmentType, setAppointmentType] = useState<IAppointmentType | null>();
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteAppType, setDeleteAppType] = useState<IAppointmentType | null>(null);
  const { userId } = useSelector(({ auth }: any) => auth);
  const { data, isLoading, isFetching } = useAppointmentTypes(practitionerId || (userId as string), false);
  const updateMutation = useUpdateAppointmentTypeMutation();
  const deleteMutation = useDeleteAppointmentTypeMutation(practitionerId);
  const loading = isLoading || isFetching || updateMutation.isLoading || deleteMutation.isLoading;

  const handleOpen = (appType: any = null) => {
    setOpenDialog(true);
    setAppointmentType(appType);
  };

  const dataToShow = useMemo(
    () =>
      data.map((appType) => ({
        ...appType,
        onEdit: () => {
          handleOpen(appType);
        },
        onChangeStatus: () => {
          updateMutation.mutateAsync({
            ...appType,
            private: !appType.private,
            practitionerId: practitionerId || userId,
          });
        },
        onDelete: () => {
          if (!appType.default) {
            setDeleteAppType(appType);
          }
        },
      })) || [],
    [data]
  );

  return (
    <Grid item xs={12}>
      <Grid item>
        <h2>Appointment Types</h2>
      </Grid>
      <Grid item xs={12} justify="flex-end" className={classes.formItem} style={{ display: 'flex' }}>
        <AppointmentTypeForm openDialog={openDialog} setOpenDialog={setOpenDialog} appointmentType={appointmentType} />

        <Button
          data-cy="submitBtn"
          disabled={loading}
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleOpen}
        >
          Create
        </Button>
      </Grid>
      <TablePaginator
        isLoading={isLoading}
        isFetching={loading}
        backgroundAlternated={false}
        data={dataToShow}
        columns={APPOINTMENT_TYPE_COLUMNS}
        total={data.length}
      />
      <ConfirmModal
        title={DELETE_APPOINTMENT_TYPE}
        contentText={ARE_YOU_SURE_DELETE_APPOINTMENT_TYPE}
        openDialog={!!deleteAppType?.id}
        setOpenDialog={(val) => {
          if (!val) {
            setDeleteAppType(null);
          }
        }}
        onClickConfirm={() => {
          if (deleteAppType?.id) {
            deleteMutation.mutate(deleteAppType.id);
          }
        }}
      />
    </Grid>
  );
};

export default AppointmentTypes;
