import { makeStyles } from '@material-ui/core';

export const useRowStyles = makeStyles({
  root: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    '& > *': {
      border: '0 !important',
    },
  },
  email: {
    textTransform: 'lowercase',
  },
  menuIcon: {
    border: '1px solid #E4E7EB',
    borderRadius: '2px',
  },
});

export const usePractitionerBadgeStyles = makeStyles({
  flex: {
    color: '#005C83',
    background: '#BDEBFF',
    padding: '4px',
    borderRadius: '4px',
    marginLeft: '8px',
    fontSize: '14px',
  },
  legacy: {
    color: '#005A4E',
    background: '#E6F9DA',
    padding: '4px',
    borderRadius: '4px',
    marginLeft: '8px',
    fontSize: '14px',
  },
  luxe: {
    color: '#84563C',
    background: '#FFEEAA',
    padding: '4px',
    borderRadius: '4px',
    marginLeft: '8px',
    fontSize: '14px',
  },
  lite: {
    color: '#866AE6',
    background: '#B6ABDB',
    padding: '4px',
    borderRadius: '4px',
    marginLeft: '8px',
    fontSize: '14px',
  },
});

export const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: '100%',
  },
  add: {
    width: '100%',
    height: '55px',
  },
  clipboardLink: {
    color: '#2172BB',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    textDecoration: 'underline',
    border: 'none',
    background: 'transparent',
    marginLeft: '20px',
  },
  selectedPractitioner: {
    fontFamily: 'Messina Sans Regular',
    borderRadius: '4px',
    backgroundColor: '#e7eeed',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.93,
    letterSpacing: 'normal',
    color: '#000000',
    padding: '11px 6px 11px 11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '46px',
  },
  dialogActions: {
    display: 'block',
    textAlign: 'center',
  },
  message: {
    height: '40px',
    background: '#F2D1AB',
    lineHeight: '40px',
    padding: '0px 5px',
    marginTop: '10px',
  },
  formDropdown: {
    width: '255px',
  },
  formInputSelect: {
    top: '-4px',
    color: '#000',
  },
  checkbox: {
    color: '#12574d',
    marginLeft: '20px',
  },
  containerDialog: {
    width: '450px',
  },
  buttonRight: {
    background: theme.palette.primary.main,
    color: 'white',
  },
  buttonLeft: {
    background: '#FBDADA',
  },
  stepsContainer: {
    display: 'flex',
    marginBottom: '2rem',
    alignItems: 'end',
    padding: '0 8px',
  },
  stepContainer: {
    width: '264px',
    marginRight: '1rem',
  },
  stepTitle: {
    color: '#12574D',
    fontSize: '18px',
  },
  stepSubtitle: {
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  stepBarActive: {
    width: '100%',
    background: '#12574D',
    border: 'none',
    height: '8px',
  },
  stepBarInactive: {
    width: '100%',
    border: 'none',
    height: '8px',
    background: '#12574D',
    opacity: '30%',
  },
  mt2: {
    marginTop: '2rem',
  },
  ml2: {
    marginLeft: '2rem',
  },
  actionButton: {
    color: 'white',
    background: '#12574D',
    borderRadius: '8px',
    padding: '10px 8px',
    border: 'none',
    fontSize: '16px',
    width: '168px',
    cursor: 'pointer',
    '&:disabled': {
      color: 'white',
      opacity: '50%',
    },
  },
  backButton: {
    border: 'none',
    background: 'none',
    color: '#12574D',
    fontSize: '16px',
    cursor: 'pointer',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '12px',
  },
  uploadSignature: {
    display: 'flex',
    alignItems: 'center',
  },
  practitionerHeaderOuter: {
    display: 'flex',
    flexDirection: 'row',
    padding: '25px 15px 15px 15px',
    '& > div': {
      margin: '0',
    },
  },
  profileImage: {
    width: 120,
    height: 120,
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  practitionerHeader: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& > h5': {
      margin: 'auto auto 0 10px',
    },
    '& > p': {
      margin: '0 auto auto 10px',
      fontSize: '0.7rem',
      backgroundColor: 'rgb(233,248,221)',
      padding: '3px 12px',
      borderRadius: '6px',
      fontFamily: 'Messina Sans Regular',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '700px',
    height: '500px',
    margin: 'auto',
    borderRadius: '8px',
  },
  modalContent: {
    width: '440px',
    padding: '4rem 2rem',
    background: 'white',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
  },
  cancelButton: {
    color: '#12574d',
    borderRadius: '8px',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    width: '168px',
    marginRight: '20px',
    cursor: 'pointer',
    padding: '10px 8px',
    fontSize: '16px',
  },
  generateLinkButtonsContainer: {
    marginBottom: '2rem',
  },
  generateLinkButton: {
    background: '#E7EEED',
    color: '3a3b3f',
    border: 'none',
    height: '47px',
    padding: '6px 16px',
    marginLeft: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  createLinkButton: {
    color: 'white',
    background: '#12574D',
    borderRadius: '8px',
    padding: '10px 8px',
    border: 'none',
    fontSize: '16px',
    width: '208px',
    cursor: 'pointer',
    '&:disabled': {
      color: 'white',
      opacity: '50%',
      cursor: 'default',
    },
  },
  generateLinkOption: {
    border: 'none',
    color: '#12574d',
    padding: '8px 16px',
    borderRadius: '8px',
    margin: '1rem',
    fontSize: '18px',
    background: '#E7EEED',
    cursor: 'pointer',
  },
  activeLinkOption: {
    border: '1px solid #12574d',
  },
  btnNewProvider: {
    minWidth: '7.5rem',
  },
}));

export const usePractitionersHeaderStyles = makeStyles((theme: any) => ({
  practHeaderFormControlContainer: {
    [theme.breakpoints.down('xs')]: {
      flex: '100%',
    },
  },
  practHeaderInputSearch: {
    minWidth: '296px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  practHeaderShowInactive: {
    minWidth: '205px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  practHeaderRolesDropdown: {
    width: '255px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  practHeaderActionsContainer: {
    minWidth: '246px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
}));
