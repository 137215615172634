import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  Badge,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Fade,
  List,
  makeStyles,
  Menu,
  Theme,
} from '@material-ui/core';
import { NotificationsActive, NotificationsOutlined, Error, ThumbUp } from '@material-ui/icons';
import { useGlobalsNotifications, GLOBAL_NOTIFICATIONS } from '../../hooks/queries/useNotifications';
import { markAsRead } from '../../services/Notifications';
import NotificationItem, { SimpleItem } from './NotificationItem';
import IGlobalNotification from '../../interfaces/IGlobalNotification';
import compile from '../../utils/toastMessagesCompiler';
import { dispatch } from '../../rematch';
import Id from '../../types/Id';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      maxWidth: '90vh',
      minWidth: '300px',
      backgroundColor: theme.palette.background.paper,
      overflow: 'none',
      maxHeight: '90vh',
      textAlign: 'center',
    },
    unreadItem: {
      backgroundColor: 'aliceblue',
    },
  })
);

const NotificationIcon = ({ isLoading, hasNewNotifications }: any) => {
  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  return (
    <Badge color="error" variant="dot" invisible={!hasNewNotifications}>
      {hasNewNotifications ? <NotificationsActive /> : <NotificationsOutlined />}
    </Badge>
  );
};
const NO_NOTIFICATIONS_MESSAGE = compile('notifications.no_notifications');
const ERROR_GETTING_MESSAGES = compile('generic.error_message', {
  action: 'fetching',
  element: 'the notifications',
});
const ERROR_MARKING_AS_READ = compile('generic.error_message', {
  action: 'marking as read',
  element: 'the notification',
});

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentNotificationId, setCurrentNotificationId] = useState<Id | null>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const queryClient = useQueryClient();
  const markAsReadMutation = useMutation(markAsRead);

  const { data: notifications = [], isLoading, isError } = useGlobalsNotifications();

  const showNotifications = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const hideNotifications = () => {
    setAnchorEl(null);
  };

  const handleMarkAsRead = async (id: Id) => {
    try {
      setCurrentNotificationId(id);
      await markAsReadMutation.mutateAsync(id);
      queryClient.invalidateQueries(GLOBAL_NOTIFICATIONS);
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_MARKING_AS_READ,
          type: 'warning',
        },
      });
    }
  };

  const hasNewNotifications = notifications.some(({ read }) => !read);

  const NotificationsList = ({ data }: { data: IGlobalNotification[] }) => {
    if (isLoading) {
      return (
        <List component="nav" className={classes.list}>
          <CircularProgress />
        </List>
      );
    }
    if (isError) {
      return (
        <List component="nav" className={classes.list}>
          <SimpleItem icon={<Error color="error" />} messageText={ERROR_GETTING_MESSAGES} />
        </List>
      );
    }
    if (data.length === 0) {
      return (
        <List component="nav" className={classes.list}>
          <SimpleItem icon={<ThumbUp />} messageText={NO_NOTIFICATIONS_MESSAGE} />
        </List>
      );
    }
    return (
      <List component="nav" className={classes.list}>
        {notifications.map(({ id, notificationType, read, message, createdAt }) => (
          <div key={`notification_${id}_${createdAt}`} className={read ? '' : classes.unreadItem}>
            <NotificationItem
              loading={markAsReadMutation.isLoading && currentNotificationId === id}
              read={read}
              type={notificationType}
              messageText={message}
              createdAt={createdAt}
              showAction={!read}
              onActionClick={() => handleMarkAsRead(id)}
            />
            <Divider />
          </div>
        ))}
      </List>
    );
  };

  return (
    <div>
      <Button size="small" color="primary" onClick={showNotifications}>
        <NotificationIcon isLoading={isLoading} hasNewNotifications={hasNewNotifications} />
      </Button>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={open}
        onClose={hideNotifications}
        TransitionComponent={Fade}
      >
        <NotificationsList data={notifications} />
      </Menu>
    </div>
  );
};
export default Notifications;
