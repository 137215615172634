import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Box, CircularProgress, withStyles } from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
  CloudDone as CloudDoneIcon,
  CloudOff as CloudOffIcon,
} from '@material-ui/icons';
import { EHRButton } from 'src/components/ui/v1';
import LoadingModal from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderLoading';
import { ClassNameMap } from 'src/types/Dom';
import classNames from 'classnames';
import { camelCase, identity, snakeCase } from 'lodash';
import { dispatch } from 'src/rematch';
import compile from 'src/utils/toastMessagesCompiler';

const MAX_FILENAME_LEN = 15;

export type ImportCsvProps<T> = ClassNameMap & {
  numericalValues?: (keyof T)[];
  requiredKeys?: (keyof T)[];
  children?: ReactElement;
  variant?: 'button' | 'box';
  showLoadingModal?: boolean;
  autoSubmit?: boolean;
  onSave: (data: T[]) => Promise<void>;
};

const CsvUploadBox = withStyles({
  csvUploadBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    border: '1px dashed #999',
    borderRadius: 8,
    height: 250,
    maxWidth: 350,
    width: '100%',
    padding: 25,
    '& > span': {
      display: 'block',
      margin: 'auto',
    },
  },
})(({ classes, mainIcon, children }: ClassNameMap & { children: ReactElement; mainIcon: ReactElement }) => (
  <Box component="span" className={classes?.csvUploadBox} data-testid="importProducts.boxVariant">
    <Box component="span" fontSize={90}>
      {mainIcon}
    </Box>
    <Box component="span" width="100%">
      {children}
    </Box>
  </Box>
));

export default <T extends {}>({
  onSave,
  classes,
  children,
  autoSubmit = false,
  showLoadingModal = false,
  variant = 'button',
  numericalValues = [],
  requiredKeys = [],
}: ImportCsvProps<T>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string | undefined>();
  const [rows, setRows] = useState<T[]>([]);

  const handleSave = async (newRows?: T[]) => {
    if (!isDone && !isError && (newRows ?? rows).length > 1) {
      setIsLoading(true);
      try {
        await onSave(newRows ?? rows);
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
        setIsDone(true);
      }
    }
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files?.[0];

    if (file) {
      setIsDone(false);
      setIsError(false);
      setFileName(file.name.length > MAX_FILENAME_LEN ? `${file.name.slice(0, MAX_FILENAME_LEN)}...csv` : file.name);
      try {
        const text = await readFileAsText(file);
        const newRows = parseCSV(text);

        if (autoSubmit) {
          await handleSave(newRows);
        } else {
          setIsLoading(false);
        }
      } catch (error: unknown /* Error */) {
        setIsError(true);
        setIsLoading(false);
        !!(error as Error)?.message &&
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile((error as Error).message),
              type: 'error',
            },
          });
      }
    } else {
      setIsLoading(false);
    }
  };

  const readFileAsText = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(Error('csv.file.error.generic'));
      reader.readAsText(file);
    });

  const parseCSV = (text: string) => {
    const firstNewLine = text.indexOf('\n');
    if (!text || firstNewLine < 0 || text.indexOf(',') < 0) {
      throw Error('csv.file.error.format');
    }

    const lines = text.split(text[firstNewLine - 1] === '\r' ? '\r\n' : '\n').filter(identity);
    if (lines.length < 2) {
      throw Error('csv.file.error.size');
    }

    const headers = lines[0]
      .split(',')
      .filter(identity)
      .map((header: string) => camelCase(header.trim().toLowerCase()));

    const missingKeys = requiredKeys.filter((key: keyof T) => headers.indexOf(key as string) < 0);
    if (missingKeys.length > 0) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('csv.file.error.columns', {
            columns: missingKeys.map((key: keyof T) => snakeCase(key as string)).join(', '),
          }),
          type: 'error',
        },
      });
      throw Error('');
    }

    const newRows = lines.slice(1).map(
      (row: string) =>
        Object.fromEntries(
          row
            .split(',')
            .slice(0, headers.length)
            .map((value, index) => {
              const header = headers[index] as keyof T;
              return [header, parseValue<T[typeof header]>(value.trim(), header)];
            })
        ) as T
    );
    setRows(newRows);
    return newRows;
  };

  const parseValue = <TypeTranslation,>(value: string, header: keyof T): TypeTranslation | undefined => {
    switch (value) {
      case '':
        return undefined as TypeTranslation;

      case 'true':
        return true as TypeTranslation;

      case 'false':
        return false as TypeTranslation;

      default:
        return (numericalValues.indexOf(header) > -1 ? Number(value) : value) as TypeTranslation;
    }
  };

  const buttonOnly = variant === 'button';
  const iconSize = buttonOnly ? 'default' : 'inherit';
  const mainText = !buttonOnly && !showLoadingModal && isLoading && !isError ? '' : fileName || 'Choose a CSV';

  const importStatusIcon = isError ? (
    <CloudOffIcon color="error" fontSize={iconSize} />
  ) : (
    <CloudDoneIcon htmlColor="green" fontSize={iconSize} />
  );

  const mainIcon = isDone ? importStatusIcon : <CloudUploadIcon fontSize={iconSize} />;
  const buttonIconBase = buttonOnly ? mainIcon : null;
  const buttonIcon =
    !showLoadingModal && isLoading && !isError ? (
      <CircularProgress className={classes?.progress} color="primary" size={20} />
    ) : (
      buttonIconBase
    );

  const uploadButton = (
    <EHRButton
      dataCy="importProducts.upload"
      data-testid="importProducts.upload"
      className={classNames(classes?.button, classes?.uploadButton)}
      variant="contained"
      color={fileName ? 'default' : 'primary'}
      startIcon={buttonIcon}
      text={mainText}
      component="span"
      fullWidth
    />
  );

  return (
    <Box className={classes?.csvUploadContainer} data-testid="importProducts.container">
      <input
        accept=".csv"
        style={{ display: 'none' }}
        id="upload-products-csv"
        data-testid="importProducts.input"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="upload-products-csv">
        {buttonOnly ? (
          uploadButton
        ) : (
          <CsvUploadBox classes={classes} mainIcon={mainIcon}>
            {uploadButton}
          </CsvUploadBox>
        )}
      </label>
      {!autoSubmit && (
        <Box display="flex" flexDirection="column" margin={0} paddingTop={5} className={classes?.saveButtonContainer}>
          <EHRButton
            dataCy="importProducts.save"
            data-testid="importProducts.save"
            className={classNames(classes?.button, classes?.saveButton)}
            text={isDone && !isError ? 'Imported successfully' : 'Import'}
            color="primary"
            disabled={rows.length < 1 || isError || isLoading || isDone}
            onClick={() => handleSave()}
          />
        </Box>
      )}
      {children}
      {showLoadingModal && isLoading && !isError && <LoadingModal />}
    </Box>
  );
};
