import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import ServiceTable from 'src/components/DashboardPractitioner/Tabs/PractitionerPricingTab/ServiceTable';
import InputSearch from 'src/components/common/InputSearch';
import { SERVICES } from 'src/constants/reactQuery.keys';
import IServices from 'src/interfaces/IServices';
import Page from 'src/components/common/Page';
import Services from 'src/services/Services';

const MedspaAdminPricing = () => {
  const [search, setSearch] = useState<string>('');

  const onChange = (event: any) => {
    setSearch(event.target.value);
  };

  const { data: services, isLoading } = useQuery([SERVICES], () => Services.getServices(null));

  return (
    <Page title="Pricing">
      <Box width="100%">
        <Box marginBottom="1rem">
          <InputSearch
            data-cy="pricesSearchInput"
            placeholder="Search"
            onChangeValue={onChange}
            value={search}
            isLoading={isLoading}
          />
        </Box>
        <ServiceTable
          isFetching={isLoading}
          data={
            services?.filter(({ name }: IServices) => name.toLowerCase().includes(search?.toLowerCase())) || services
          }
        />
      </Box>
    </Page>
  );
};

export default MedspaAdminPricing;
