import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Table, TableHead, TableRow, TableBody, Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';
import { Menu as MenuIcon } from '@material-ui/icons';

import { useQuery } from 'react-query';
import { dispatch } from '../rematch';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import { TableCellCustom } from '../components/common/TableStyles/TableCellCustom';
import { TableCellHeader } from '../components/common/TableStyles/TableCellHeader';
import { getProductIdentifiersData, showQuantityUnits } from '../utils/inventory.utils';
import SupplyReceipts from '../services/SupplyReceipts';
import { SUPPLY_RECEIPT } from '../constants/reactQuery.keys';
import { PRACTITIONER_INVENTORY_SUPPLY_PATH } from '../routes/practitionerRoutes';

export const useStyles = makeStyles(() => ({
  consentSignature: {
    border: 'solid 1px #969696',
    display: 'block',
    height: '158px',
    backgroundColor: 'white',
    borderRadius: '4px',
    marginBottom: '10px',
    position: 'relative',
  },
  signatureTitle: {
    fontFamily: 'Messina Sans SemiBold',
    color: '#a6a6a6',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
  },
  consentSignatureCanvas: {
    width: '100%',
    height: '100%',
  },
  consentSignatureContainer: {
    textAlign: 'center',
    border: 'solid 1px #969696',
    height: '158px',
  },
  consentSignatureImg: {
    height: '158px',
  },
  cancelButton: {
    border: 'solid 1px rgba(57, 59, 63, 0.12)',
    color: '#12574d',
    height: '47px',
    width: '150px',
  },
  submitButton: {
    height: '47px',
    width: '180px',
  },
}));

const SupplyReceiptConfirmationPage = () => {
  let signCanvas: any;
  const { id: supplyReceiptId }: any = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [isSigned, setIsSigned] = useState(false);
  const { userId } = useSelector(({ auth }: any) => auth);

  const { data: supplyReceipt, isLoading: isLoadingSupplyReceipt } = useQuery([SUPPLY_RECEIPT, supplyReceiptId], () =>
    SupplyReceipts.getSupplyReceipt(supplyReceiptId)
  );

  const isDisabled = supplyReceipt?.status === 'closed';

  const navigate = () => {
    history.push(PRACTITIONER_INVENTORY_SUPPLY_PATH);
  };

  const navigateToProductIdentifiers = (assets: any) => {
    dispatch({
      type: 'productIdentifiers/setProductIdentifiersData',
      payload: { ...getProductIdentifiersData(assets), aggregatedField: 'initialNumberOfUnits' },
    });

    history.push(`/product-identifiers`);
  };

  const onBeginStroke = (): void => {
    setIsSigned(true);
  };

  const handleSubmit = () => {
    const signature = signCanvas.getTrimmedCanvas().toDataURL('image/png');

    dispatch({
      type: 'inventorySupplyReceipt/submitSupplyReceipt',
      payload: {
        practitionerId: userId,
        supplyReceiptId,
        signature,
        callback: navigate,
      },
    });
  };

  return (
    <main>
      <NavigationBar
        title="Supply Receipt"
        onBackButtonClick={navigate}
        hasCancelButton
        onCancelButtonClick={navigate}
      />
      <article style={{ padding: '20px', width: 'inherit', backgroundColor: 'white' }}>
        <Box component="p" color="#393b3f" fontSize="16px" style={{ opacity: 0.7 }} marginLeft={2}>
          Supply Receipt Confirmation
        </Box>
        <Table style={{ border: '1px solid #e4e7eb' }}>
          <TableHead>
            <TableRow style={{ height: '56px' }}>
              <TableCellHeader>Product</TableCellHeader>
              <TableCellHeader>Supplied</TableCellHeader>
              <TableCellHeader>Product IDs</TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplyReceipt?.productsList?.map((data: any) => (
              <TableRow key={`${data.productName}`} style={{ height: '56px' }}>
                <TableCellCustom>{data.productName}</TableCellCustom>
                <TableCellCustom>
                  {showQuantityUnits({
                    ...data,
                    quantity: data.assetsCount,
                    units: data.units,
                  })}
                </TableCellCustom>
                <TableCellCustom>
                  <Box
                    style={{ display: 'inline-flex', border: '1px solid #e5efed' }}
                    onClick={() => navigateToProductIdentifiers(data.assets)}
                  >
                    <MenuIcon />
                  </Box>
                </TableCellCustom>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box marginTop={4} marginLeft={4} marginRight={4}>
          <Box component="p" fontFamily="Messina Sans Regular" fontSize="14px" lineHeight="23px" color="#000000">
            I acknowledge I have received in my possession all of the products listed in this supply receipt.
          </Box>
          <Box>
            {isDisabled ? (
              <div>
                {supplyReceipt?.signature && (
                  <div className={classes.consentSignatureContainer}>
                    <img
                      className={classes.consentSignatureImg}
                      src={supplyReceipt?.signature}
                      alt="Signature"
                      onError={(e: any) => {
                        e.target.style.display = 'none';
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div data-cy="signatureCanvas" className={classes.consentSignature}>
                <div style={{ display: isSigned ? 'none' : 'block' }} className={classes.signatureTitle}>
                  Signature By Practitioner
                </div>
                <SignatureCanvas
                  canvasProps={{ className: classes.consentSignatureCanvas }}
                  ref={(ref) => {
                    signCanvas = ref;
                  }}
                  onBegin={onBeginStroke}
                  clearOnResize={false}
                />
              </div>
            )}
          </Box>
          <Box
            component="p"
            marginTop={2}
            fontFamily="Messina Sans Regular"
            fontSize="13px"
            lineHeight="23px"
            color="#000000"
          >
            I understand that I am financially responsible for the Value of any lost or misplaced product. The value of
            any lost product will be calculated at 50% of the retail price. The Value shall be deducted from any
            payments owed to me. If the Value exceeds any payment due to me, I shall be required to pay the difference.
          </Box>
        </Box>
      </article>
      <Box
        borderTop="1px solid #e4e7eb"
        px={4}
        py={1.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button data-cy="cancelBtn" variant="outlined" className={classes.cancelButton} onClick={navigate}>
          Cancel
        </Button>
        {!isDisabled && (
          <Button
            data-cy="submitBtn"
            variant="contained"
            className={classes.submitButton}
            disableElevation
            color="primary"
            disabled={!isSigned || isLoadingSupplyReceipt}
            onClick={handleSubmit}
          >
            {isLoadingSupplyReceipt ? <CircularProgress size={25} color="secondary" /> : 'Submit'}
          </Button>
        )}
      </Box>
    </main>
  );
};

export default SupplyReceiptConfirmationPage;
