import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  AttachFileContainer: {
    alignSelf: 'center',
  },
  cardContent: {
    padding: '0 !important',
  },
  AttachFilesInput: {
    display: 'none',
  },
  AttachFilesButton: {
    color: '#12574d',
  },
}));
