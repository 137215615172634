import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, TextField, MenuItem, Button, IconButton, Typography, Modal, CircularProgress } from '@material-ui/core';
/* eslint-disable */
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ApartmentIcon from '@material-ui/icons/Apartment';
import CloseIcon from '@material-ui/icons/Close';
import Papa from 'papaparse';
import { usePatientMedspaStyles } from './PatientMedspaStyles';
import { MEDSPA_ADMIN_ROLE } from '../../../constants/general.constants';
import useCurrentUserGroup from '../../../hooks/queries/useUserGroups';
import { useMedspaPractitioners } from '../../../hooks/queries/useMedspaAdmins';
import Patients from '../../../services/Patients';

interface Props {
  uploadType: string;
  showBulkModal: boolean;
  setShowBulkModal: (show: boolean) => void;
  onSuccessCallback?: () => void;
}

const BulkUpload: FC<Props> = ({ uploadType, showBulkModal, setShowBulkModal, onSuccessCallback }) => {
  const classes = usePatientMedspaStyles();

  const { roleName, userGroupId } = useSelector(({ auth }: any) => auth);
  const isSpaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const { data: assignedPractitioners = [] } = useMedspaPractitioners(isSpaAdmin);
  const { data: userGroupData } = useCurrentUserGroup(roleName === MEDSPA_ADMIN_ROLE);

  const [providerSelectError, setProviderSelectError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadErrors, setUploadErrors] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const selectedProvider = assignedPractitioners.find((provider) => provider.id === selectedProviderId);
  const [currentScreen, setCurrentScreen] = useState('upload'); // 'upload', 'success', 'error'

  const requiredFields = [
    'First Name',
    'Last Name',
    'Email',
    'Phone',
    'City',
    'State',
    'Address',
    'Zip Code',
    'Birth Date',
  ];

  const handleCsvUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const file = files[0];

      if (uploadType === 'provider' && !selectedProviderId) {
        setProviderSelectError('Selecting a provider is required.');
        return;
      }

      setUploading(true);
      setProviderSelectError('');
      setUploadErrors('');

      Papa.parse(file, {
        complete: async (results: any) => {
          const missingFields = requiredFields.filter((field) => !results.meta.fields.includes(field));

          if (missingFields.length > 0) {
            setUploading(false);
            setUploadErrors(`Missing required fields: ${missingFields.join(', ')}`);
          } else {
            try {
              const response = await Patients.bulkMedspaPatientProfilesUpload(
                userGroupId,
                selectedProviderId,
                results.data
              );

              if (response.success && response.errors.length === 0) {
                setCurrentScreen('success');
                onSuccessCallback?.();
              } else {
                setValidationErrors(response.errors || []);
                setCurrentScreen('error');
              }
            } catch (error) {
              setUploadErrors('Server error failed to upload.');
              setCurrentScreen('error');
            } finally {
              setUploading(false);
            }
          }
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  const getContent = () => {
    switch (currentScreen) {
      case 'success':
        return renderSuccess();
      case 'error':
        return renderErrors();
      case 'upload':
      default:
        return renderUpload();
    }
  };

  const handleClose = () => {
    setUploadErrors('');
    setValidationErrors([]);
    setProviderSelectError('');
    setShowBulkModal(false);
    setCurrentScreen('upload');
  };

  const renderUpload = () => (
    <>
      <Box className={classes.associateBox}>
        {uploadType === 'medspa' ? (
          <Box className={classes.infoBox}>
            <ApartmentIcon fontSize="large" />
            <Box className={classes.textBox}>
              <Typography className={classes.subtitle}>Associate to Medspa</Typography>
              <Typography className={classes.bodyText} variant="body2">
                Uploaded patients will be assigned to {userGroupData?.name}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box className={classes.infoBox} style={{ justifyContent: 'space-between' }}>
            <AccountCircleIcon fontSize="large" />
            <Box className={classes.textBox} style={{ width: '250px' }}>
              <Typography className={classes.subtitle}>Associate to Provider</Typography>
              <Typography className={classes.bodyText} variant="body2">
                Uploaded patients will be assigned to selected provider
              </Typography>
            </Box>
            <TextField
              select
              value={selectedProviderId}
              onChange={(e) => setSelectedProviderId(e.target.value)}
              error={!!providerSelectError}
              helperText={providerSelectError}
              variant="outlined"
              size="small"
              style={{ width: 296 }}
            >
              {assignedPractitioners.map((provider) => (
                <MenuItem key={provider.id} value={provider.id}>
                  {`${provider.firstName} ${provider.lastName}`}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
      </Box>

      <Typography variant="body2">
        In order to upload the proper CSV file you can{' '}
        <a
          style={{ color: 'darkgreen' }}
          href={`${process.env.PUBLIC_URL}/csv/patient-bulk-template.csv`}
          download="Template.csv"
        >
          Download CSV Template
        </a>
      </Typography>
      <Typography variant="body2">
        Please make sure that your Patient Profile CSV table contains the following information:
      </Typography>
      <ul className={classes.csvFieldList}>
        {requiredFields.map((field) => (
          <li key={field}>{field}</li>
        ))}
      </ul>
      <Typography style={{ color: 'red' }} variant="body2">
        {uploadErrors}
      </Typography>

      <Box className={classes.buttonContainer} mt="50px" display="flex" justifyContent="end">
        <Button onClick={handleClose} color="primary" className={classes.button}>
          Cancel
        </Button>
        <Box>
          <input
            type="file"
            accept=".csv"
            onChange={handleCsvUpload}
            style={{ display: 'none' }}
            id="csv-upload-input"
          />
          <label htmlFor="csv-upload-input">
            <Button variant="contained" color="primary" component="span" disabled={uploading}>
              {uploading ? <CircularProgress size={20} /> : 'Upload CSV'}
            </Button>
          </label>
        </Box>
      </Box>
    </>
  );

  const renderSuccess = () => (
    <Box>
      <Box className={classes.uploadStatusBox}>
        <CheckCircleIcon style={{ color: '#00FF00', fontSize: '64px' }} />
        <Typography variant="h6">All Good!</Typography>
      </Box>
      <Box style={{ justifyContent: 'center', textAlign: 'center' }}>
        <Typography>Your CSV file has been successfully uploaded.</Typography>
        {uploadType === 'provider' && (
          <Typography>
            Patients have been assigned to {`${selectedProvider.firstName} ${selectedProvider.lastName}`}
          </Typography>
        )}
      </Box>
      <Box className={classes.buttonContainer} mt="50px" display="flex" justifyContent="end">
        <Button onClick={handleClose} variant="contained" color="primary" component="span">
          Done
        </Button>
      </Box>
    </Box>
  );

  const renderErrors = () => (
    <Box>
      <Box className={classes.uploadStatusBox}>
        <ErrorIcon style={{ color: '#FF6347', fontSize: '64px' }} />
        <Typography variant="subtitle1" style={{ marginBottom: 20 }}>
          We detected {validationErrors.length} Patients on the list. Some of them could not be uploaded!
        </Typography>
      </Box>
      <Box className={classes.associateBox} style={{ display: 'block', overflow: 'scroll', height: '166px' }}>
        <Typography>The following patients are missing important information:</Typography>
        <ul>
          {validationErrors &&
            validationErrors.map((error, index) => (
              <li key={index}>{`${error['firstName']} ${error['lastName']} - ${error['errorMessages']}`}</li>
            ))}
        </ul>
      </Box>
      <Typography style={{ color: 'red' }} variant="body2">
        {uploadErrors}
      </Typography>
      <Box className={classes.buttonContainer} mt="50px" display="flex" justifyContent="end">
        <Button onClick={handleClose} color="primary" className={classes.button}>
          Cancel
        </Button>
        <Box>
          <input
            type="file"
            accept=".csv"
            onChange={handleCsvUpload}
            style={{ display: 'none' }}
            id="csv-upload-input"
          />
          <label htmlFor="csv-upload-input">
            <Button variant="contained" color="primary" component="span" disabled={uploading}>
              {uploading ? <CircularProgress size={20} /> : 'Try Again'}
            </Button>
          </label>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Modal
        disableBackdropClick
        open={showBulkModal}
        onClose={handleClose}
        className={classes.centerModal}
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box className={classes.centerModalContent}>
          <Box className={classes.modalTitleBar}>
            <Typography variant="h6">Bulk Upload</Typography>
            <IconButton onClick={handleClose} className={classes.closeModalButton}>
              <CloseIcon />
            </IconButton>
          </Box>
          {getContent()}
        </Box>
      </Modal>
    </Box>
  );
};

export default BulkUpload;
