import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { AddAPhoto as AddAPhotoIcon } from '@material-ui/icons';

import { useStyles } from '../PatientProfile/photos.styles';

interface Props {
  isUploadingPhoto?: boolean;
  uploadPhotos: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addNewPhoto: () => void;
  inputFileId: string;
  required?: boolean;
}

const PhotoUpload = ({ isUploadingPhoto = false, addNewPhoto, uploadPhotos, inputFileId, required = false }: Props) => {
  const classes = useStyles();

  return (
    <Grid item>
      <label
        htmlFor={inputFileId}
        className={classes.addNewPhoto}
        style={required ? { borderColor: '#ff0000' } : {}}
        onClick={addNewPhoto}
        aria-hidden="true"
      >
        {!isUploadingPhoto ? (
          <>
            <AddAPhotoIcon className="icon" />
            <div className="label">ADD NEW PHOTOS</div>
          </>
        ) : (
          <CircularProgress />
        )}
      </label>
      <input
        id={inputFileId}
        style={{ display: 'none' }}
        type="file"
        accept="image/png, image/jpeg"
        multiple
        onChange={uploadPhotos}
        disabled={isUploadingPhoto}
      />
    </Grid>
  );
};

export default PhotoUpload;
