import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(() => ({
  orderPayment: {
    padding: '15px 0',
    backgroundColor: '#fff',
    width: '50%',
    minWidth: 460,
    borderRadius: '8px',
    border: '2px solid #dedede',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 100px 20px',
    '& *': {
      fontSize: 12,
    },
    '& > div': {
      display: 'flex',
      margin: 'auto 0',
      width: '100%',
      padding: 18,
    },
  },
  heading: {
    fontSize: 16,
    width: '100%',
    borderBottom: '1px solid #eee',
    textAlign: 'left',
    paddingBottom: 8,
    marginLeft: 18,
  },
  orderPaymentRow: {
    flexDirection: 'row',
    '&> *': {
      margin: '0 auto 0 0',
    },
    '&> *:last-child': {
      margin: '0 0 0 auto',
      textAlign: 'right',
    },
    '&[data-size="lg"] > *': {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  bold: {
    fontWeight: 600,
  },
  boldGray: {
    '&> *:first-child': {
      fontWeight: 600,
    },
    '&> *:last-child': {
      color: '#555',
    },
  },
  savePayment: {
    height: '55px',
    width: '100%',
    backgroundColor: 'rgb(18,87,77)',
    color: '#fff',
    margin: 25,
    marginTop: 'auto',
    '&:disabled': {
      color: '#aaa',
    },
    '&:hover': {
      backgroundColor: 'rgb(49,108,97)',
    },
  },
  deliveryNotice: {
    margin: 0,
    padding: 15,
    backgroundColor: 'rgb(254,242,226)',
    color: 'rgb(133,76,0)',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '8px',
    border: '1px solid rgb(237,225,209)',
    '& p': {
      margin: 'auto auto auto 15px',
      fontSize: '0.6rem',
      fontFamily: 'Messina Sans Regular',
    },
    '& p > span': {
      fontFamily: 'Roboto',
    },
    '& svg': {
      fontSize: 22,
      color: 'rgb(247,147,13)',
    },
  },
  orderPaymentOptions: {
    flexDirection: 'column',
    padding: '10px 18px 0 18px !important',
    '& button': {
      marginTop: 8,
      borderRadius: 16,
      backgroundColor: 'rgb(225,234,232)',
      border: '1px solid rgb(18,87,77)',
      '&:first-child': {
        marginRight: 10,
      },
      '&:hover': {
        '&:disabled': {
          backgroundColor: 'rgb(18,87,77)',
          color: '#fff',
        },
      },
      '&:disabled': {
        backgroundColor: 'rgb(18,87,77)',
        color: '#fff',
      },
    },
  },
  deliveryDate: {
    width: '100%',
    margin: '10px 0',
    paddingRight: 15,
    backgroundColor: '#fff',
    borderRadius: 6,
    border: '1px solid #ddd',
    '& input': {
      width: '100%',
      borderRadius: 7,
      height: 30,
      padding: 5,
      margin: 0,
      backgroundColor: '#fff',
    },
    '& svg': {
      fontSize: 22,
    },
  },
  marginBottom: {
    marginBottom: 8,
  },
}));
