import * as Sentry from '@sentry/react';
import axios from 'axios';
/* eslint-disable import/no-unresolved */
import camelcaseKeys from 'camelcase-keys';
import apiRoutesConstants from '../constants/apiRoutes.constants';
import { isSessionExpired } from './auth.utils';
import compile from './toastMessagesCompiler';
import { dispatch } from '../rematch';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_RAILS_URL,
  headers: {
    'content-type': 'application/json',
  },
  transformResponse: [
    function transformResponse(data) {
      let parsedData = {};
      try {
        parsedData = JSON.parse(data);
      } catch (error) {
        Sentry.captureMessage(`${error}`, 'debug' as Sentry.Severity);
      }
      return camelcaseKeys(parsedData, { deep: true });
    },
  ],
});

const clearSessionStorage = () => {
  const ehrLoggedOut = sessionStorage.getItem('ehr_loggedOut');
  sessionStorage.clear();
  if (ehrLoggedOut) {
    sessionStorage.setItem('ehr_loggedOut', ehrLoggedOut);
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    if (!navigator.onLine) {
      if (!config.url?.includes('/v3/log')) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.no_connection_message', {
              duration: 3000,
            }),
            type: 'error',
          },
        });
      }
      return undefined;
    }

    if (!apiRoutesConstants.LOGIN_REDIRECT_EXCEPTIONS.includes(config.url || '') && isSessionExpired()) {
      localStorage.clear();
      clearSessionStorage();
      return Promise.reject(new Error('Session expired'));
    }

    const newConfig = config;
    newConfig.headers['access-token'] = localStorage.getItem('identity.token.access-token');
    newConfig.headers['token-type'] = localStorage.getItem('identity.token.token-type');
    newConfig.headers.client = localStorage.getItem('identity.token.client');
    newConfig.headers.uid = localStorage.getItem('identity.token.uid');
    newConfig.headers.expiry = localStorage.getItem('identity.token.expiry');
    if (sessionStorage.getItem('identity.token.uid')) {
      newConfig.headers['uid-session'] = sessionStorage.getItem('identity.token.uid');
    }
    return newConfig;
  },
  (error) => {
    Sentry.captureMessage(`${error}`, 'debug' as Sentry.Severity);
    return Promise.reject(error);
  }
);

const isInactiveUser = (text: string | undefined = '') => {
  if (typeof text === 'string') {
    const lowercaseText = text.toLowerCase();
    if (lowercaseText.includes('inactive user')) {
      localStorage.clear();
      clearSessionStorage();
      window.location.href = '/login';
    }
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    isInactiveUser(response.error);
    return response;
  },
  (error) => {
    isInactiveUser(error.response?.data?.error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
