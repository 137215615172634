import React from 'react';

import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { ReactComponent as DocumentIcon } from 'src/assets/images/document.svg';
import { cutLongName } from 'src/utils/documents.utils';
import { useStyles } from './DragAndDrop.styles';
import { linkWrapper } from '../common/SignDocumentButton';

type PartialFile = {
  name: string;
  url?: string;
};

const FilesList = ({
  files,
  onRemoveDocument,
  hideRemoveFile,
}: {
  files: File[] | PartialFile[];
  onRemoveDocument: (e?: any) => void;
  hideRemoveFile?: boolean;
}) => {
  const classes = useStyles();

  const handleRemoveDocument = (index: number) => {
    const filesAux = [...files];
    filesAux.splice(index, 1);
    onRemoveDocument?.(filesAux);
  };

  return (
    <ul style={{ listStyle: 'none', margin: '10px auto 10px auto', padding: 0 }}>
      {files.map((file, index) => (
        <li className={classes.selectedDocumentContainer}>
          <Box className={classes.selectedDocumentNameContainer}>
            <DocumentIcon />
            <Tooltip title={file.name}>
              <p>{linkWrapper(cutLongName(file.name), 'url' in file ? file.url : null)}</p>
            </Tooltip>
          </Box>
          {!hideRemoveFile ? (
            <Box>
              <Tooltip title="Remove this document">
                <IconButton onClick={() => handleRemoveDocument(index)} className={classes.selectedDeleteButton}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default FilesList;
