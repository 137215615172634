import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  section: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '15px',
  },
  title: {
    width: '65%',
    marginRight: '15px',
  },
  input: {
    height: '46px',
    width: '100%',
    border: '1px solid #979797',
    borderRadius: '4px',
    fontSize: '15px',
    color: '#3A3B3F',
    paddingLeft: '10px',
    '&::placeholder': {
      color: '#C0C0C0',
    },
    '&:disabled': {
      border: '1px solid #EEEEEE',
    },
  },
  noPointsAvailableText: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    color: '#3A3B3F',
  },
}));
