import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, CircularProgress, Modal, Card, CardContent, Typography, Grid } from '@material-ui/core/';
import { Check as CheckIcon, Info as InfoIcon } from '@material-ui/icons/';

import { dispatch } from '../../rematch';
import { useStyles } from './NewPhotoRequest.styles';
import {
  REQUEST_STATUS_OPTIONS,
  PhotoRequestTypes,
  photoRequestButtonNote,
} from '../../constants/newPhotoRequest.constants';
import Id from '../../types/Id';
import { formatLocalDateTime } from '../../utils/formatDate';
import { useActivityLogs } from '../../hooks/queries/useActivityLogs';

type NewPhotoRequestProps = {
  patientId: Id;
};

const NewPhotoRequest = ({ patientId }: NewPhotoRequestProps) => {
  const { isLoading: isLoadingPhotoRequest, requestStatus } = useSelector(
    ({ newPhotoRequest }: any) => newPhotoRequest
  );
  const isFromSameDay = new Date().getTime() - new Date(requestStatus?.createdAt).getTime() < 24 * 3600 * 1000;

  const [isAccepted, setIsAccepted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTextareaEmpty, setIsTextareaEmpty] = useState(false);
  const [requestDateTime, setRequestDateTime] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { PHOTO_SUBMITTED, REQUESTED, APPROVED, REJECTED } = REQUEST_STATUS_OPTIONS;
  const classes = useStyles();

  const { data: activityLogs, isLoading } = useActivityLogs(patientId as string);

  const getPhotoRequestStatus = () => {
    dispatch({
      type: 'newPhotoRequest/getPhotoRequestStatus',
      payload: patientId,
    });
  };

  useEffect(() => {
    if (!isLoading) {
      getPhotoRequestStatus();
    }
  }, [activityLogs?.pages[0], isLoading]);

  useEffect(() => {
    getPhotoRequestStatus();
  }, []);

  useEffect(() => {
    if (requestStatus) {
      const { time, month, day, year } = formatLocalDateTime(requestStatus.createdAt);
      setRequestDateTime(`New Photo request sent at ${time} on ${month} ${day}, ${year}`);
    }
  }, [requestStatus]);

  const handleNewPhotoRequest = (): void => {
    setIsButtonDisabled(true);

    dispatch({
      type: 'newPhotoRequest/updatePhotoRequestStatus',
      payload: {
        patientId,
        photoRequestStatus: REQUESTED,
      },
    }).then(() => {
      setIsButtonDisabled(false);
    });
  };

  const handleApprovePhoto = () => {
    setIsButtonDisabled(true);
    setIsAccepted(true);
    dispatch({
      type: 'newPhotoRequest/updatePhotoRequestStatus',
      payload: {
        patientId,
        photoRequestStatus: APPROVED,
      },
    }).then(() => {
      setIsButtonDisabled(false);
    });
  };

  const handleRejectPhoto = () => {
    if (textAreaRef.current?.value === '') {
      setIsTextareaEmpty(true);
    } else {
      setIsButtonDisabled(true);
      setIsModalOpen(false);
      setIsTextareaEmpty(false);
      dispatch({
        type: 'newPhotoRequest/updatePhotoRequestStatus',
        payload: {
          patientId,
          photoRequestStatus: REJECTED,
          message: textAreaRef.current?.value,
        },
      }).then(() => {
        setIsButtonDisabled(false);
      });
    }
  };

  const SuccessMessage = ({ message }: { message: string }) => (
    <Grid container className={classes.message}>
      <CheckIcon className={classes.checkIcon} />
      <Typography className={classes.typography}>{message}</Typography>
    </Grid>
  );

  const CircularProgressButton = ({
    classname,
    content,
    callToAction,
    disabled = false,
  }: {
    classname: string;
    content: string;
    callToAction: () => void;
    disabled?: boolean | undefined;
  }) => (
    <Button onClick={callToAction} className={classname} disabled={disabled} classes={{ disabled: classes.disabled }}>
      {isLoadingPhotoRequest ? (
        <>
          <CircularProgress style={{ color: 'white', alignItems: 'center', margin: 'auto 20px' }} size={30} />
          <p>Processing</p>
        </>
      ) : (
        <p>{content}</p>
      )}
    </Button>
  );

  const Note = ({ content }: { content: string }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InfoIcon className={classes.infoIcon} />
      <p style={{ color: 'grey' }}>{content}</p>
    </div>
  );

  const ResendPhotoButtonComponent = () => (
    <>
      <CircularProgressButton
        callToAction={handleNewPhotoRequest}
        content="Resend Photo Request"
        classname={classes.applyButton}
        disabled={isFromSameDay || isButtonDisabled}
      />
      {isFromSameDay && Note({ content: photoRequestButtonNote })}
      <SuccessMessage message={requestDateTime} />
    </>
  );

  const RequestNewPhotoButtonComponent = () => (
    <>
      <CircularProgressButton
        callToAction={handleNewPhotoRequest}
        content="Request New Photo"
        classname={classes.applyButton}
        disabled={isFromSameDay || isButtonDisabled}
      />
      {isFromSameDay && Note({ content: photoRequestButtonNote })}
      {isAccepted ? <SuccessMessage message="Photo approved" /> : null}
    </>
  );

  const AcceptOrRejectButtonsComponent = () => (
    <Grid container className={classes.buttonsCol}>
      <CircularProgressButton
        callToAction={handleApprovePhoto}
        content="Accept photo"
        classname={classes.applyButtonSmall}
      />
      <Button onClick={() => setIsModalOpen(true)} className={classes.secondaryButton}>
        Reject photo
      </Button>
    </Grid>
  );

  const renderButton = () => {
    const buttonOptions = {
      [REQUESTED]: <ResendPhotoButtonComponent />,
      [APPROVED]: <RequestNewPhotoButtonComponent />,
      [REJECTED]: <ResendPhotoButtonComponent />,
      [PHOTO_SUBMITTED]: <AcceptOrRejectButtonsComponent />,
    };
    const buttonElement = buttonOptions[requestStatus?.activityType as PhotoRequestTypes];

    return (
      buttonElement || (
        <section className={classes.buttonContainer}>
          <RequestNewPhotoButtonComponent />
        </section>
      )
    );
  };

  const rejectModal = () => (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsTextareaEmpty(false);
      }}
      className={classes.modal}
    >
      <Card style={{ width: 'fit-content' }}>
        <CardContent className={classes.cardContent}>
          <p>
            Please explain what was wrong with the photo below. <b>This will be sent to the patient.</b>
          </p>
          <textarea
            ref={textAreaRef}
            className={classes.textArea}
            name="photoRejectTextarea"
            id="photoRejectTextarea"
          />
          {isTextareaEmpty ? <p>Message must not be empty</p> : null}
          <Grid container className={classes.buttons}>
            <Button
              onClick={handleRejectPhoto}
              className={classes.applyButtonSmall}
              disabled={isFromSameDay && requestStatus?.activityType === REJECTED}
            >
              Send photo request
            </Button>
            <Button onClick={() => setIsModalOpen(false)} className={classes.secondaryButton}>
              Cancel
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );

  return (
    <>
      {renderButton()}
      {rejectModal()}
    </>
  );
};

export default NewPhotoRequest;
