import React from 'react';
import { Switch } from 'react-router-dom';
import InventoryWizardRoutes from '../components/DashboardPractitioner/InventoryWizard/InventoryWizarRoutes';
import { PRACTITIONER_ROLE } from '../constants/general.constants';
import CompletedInventoryReportPage from '../pages/CompletedInventoryReportPage';
import PrivateRoute from '../utils/PrivateRoute';

const InventoryRoutes: React.FC = () => (
  <Switch>
    <PrivateRoute path="/inventory/completed-inventory-report/:reportId" role={PRACTITIONER_ROLE}>
      <CompletedInventoryReportPage />
    </PrivateRoute>
    <PrivateRoute path="/inventory" role={PRACTITIONER_ROLE}>
      <InventoryWizardRoutes />
    </PrivateRoute>
  </Switch>
);

export default InventoryRoutes;
