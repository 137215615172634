import React, { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, List, ListItem, ListItemIcon } from '@material-ui/core';
import Page from 'src/components/common/Page';
import { ONBOARDING_ACCOUNT_SETTINGS } from 'src/routes/medspaOnboardingRoutes';
import {
  MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG,
  MEDSPA_ADMIN_CALENDAR_CONFIGURATION,
  MEDSPA_ADMIN_INVENTORY,
  MEDSPA_ADMIN_LOCATIONS,
  MEDSPA_ADMIN_PATIENTS,
  MEDSPA_ADMIN_PROVIDERS,
  MEDSPA_ADMIN_SERVICES_MANAGEMENT,
} from 'src/routes/medspaAdminRoutes';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { useLocation } from 'react-router-dom';
import { useMedspaAdminOnboardingProgress } from '../../../hooks/queries/useMedspaAdmins';
import { useStepsListStyles, useStyles } from './index.styles';
import { Step } from './components/Steps';
import { OnboardingSuccessModal } from './components/OnboardingSuccessModal';

const ToDoOnboarding = () => {
  const classes = useStyles();
  const stepsStyles = useStepsListStyles();

  const location = useLocation();

  const [openEhrOnboardingSuccessModal, setOpenEhrOnboardingSuccessModal] = useState(false);

  const { data: progress, isLoading } = useMedspaAdminOnboardingProgress();
  const { onboardingCompleted, isLoadingMedspaAdminInfo, pendingCommercialShippingAddress, pendingMedicalDirector } =
    useMedspaAdminOnboarding();

  const STEPS = useMemo(() => {
    if (progress?.stepsCompleted) {
      return [
        {
          step: 'Step 1',
          title: 'Account Settings',
          completed: Boolean((progress?.stepsCompleted?.accountSettings ?? 0) >= 100),
          completedPercentage: progress?.stepsCompleted?.accountSettings ?? 0,
          disabled: false,
          redirect: ONBOARDING_ACCOUNT_SETTINGS,
          isRequired: true,
          pending: Boolean(onboardingCompleted && (pendingCommercialShippingAddress || pendingMedicalDirector)),
        },
        {
          step: 'Step 2',
          title: 'Make your first order',
          completed: Boolean((progress?.stepsCompleted?.makeYourFirstOrder ?? 0) >= 100),
          completedPercentage: progress?.stepsCompleted?.makeYourFirstOrder ?? 0,
          disabled: !onboardingCompleted,
          redirect: MEDSPA_ADMIN_INVENTORY,
          pending: false,
        },
        {
          step: 'Step 3',
          title: 'Add Providers to MedSpa',
          completed: Boolean((progress?.stepsCompleted?.providersManagement ?? 0) >= 100),
          completedPercentage: progress?.stepsCompleted?.providersManagement ?? 0,
          disabled: !onboardingCompleted,
          redirect: MEDSPA_ADMIN_PROVIDERS,
          pending: false,
        },
        {
          step: 'Step 4',
          title: 'Create Locations',
          completed: Boolean((progress?.stepsCompleted?.locationsManagement ?? 0) >= 100),
          completedPercentage: progress?.stepsCompleted?.locationsManagement ?? 0,
          disabled: !onboardingCompleted,
          redirect: MEDSPA_ADMIN_LOCATIONS,
          pending: false,
        },
        {
          step: 'Step 5',
          title: 'Add your Services',
          completed: Boolean((progress?.stepsCompleted?.servicesManagement ?? 0) >= 100),
          completedPercentage: progress?.stepsCompleted?.servicesManagement ?? 0,
          disabled: !onboardingCompleted,
          redirect: MEDSPA_ADMIN_SERVICES_MANAGEMENT,
          pending: false,
        },
        {
          step: 'Step 6',
          title: 'Add your Patients',
          completed: Boolean((progress?.stepsCompleted?.patientsManagement ?? 0) >= 100),
          completedPercentage: progress?.stepsCompleted?.patientsManagement ?? 0,
          disabled: !onboardingCompleted,
          redirect: MEDSPA_ADMIN_PATIENTS,
          pending: false,
        },
        {
          step: 'Step 7',
          title: 'Appointment Types',
          completed: Boolean((progress?.stepsCompleted?.appointmentTypes ?? 0) >= 100),
          completedPercentage: progress?.stepsCompleted?.appointmentTypes ?? 0,
          disabled: !onboardingCompleted,
          redirect: MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG,
          pending: false,
        },
        {
          step: 'Step 8',
          title: 'Set up your first Calendar',
          completed: Boolean((progress?.stepsCompleted?.calendarSetUp ?? 0) >= 100),
          completedPercentage: progress?.stepsCompleted?.calendarSetUp ?? 0,
          disabled: !onboardingCompleted,
          redirect: MEDSPA_ADMIN_CALENDAR_CONFIGURATION,
          pending: false,
        },
      ];
    }
    return [];
  }, [progress, onboardingCompleted, pendingCommercialShippingAddress, pendingMedicalDirector]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');

    setOpenEhrOnboardingSuccessModal(success === 'true');
  }, []);

  if (isLoadingMedspaAdminInfo || isLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Page title="Home">
      <Box width="90%" marginBottom="2rem">
        <h1 className={classes.title}>Welcome to Your EHR Onboarding!</h1>
        <h6 className={classes.subTitle}>
          We&apos;re thrilled to have you join our platform. To help you make the most of our exciting features and
          elevate your business to the next level, please complete this simple onboarding checklist. This process will
          guide you through configuring your account.
        </h6>
        <div className={classes.stepsContainer}>
          <div className={classes.verticalLine} />
          <List>
            {STEPS.map((step) => (
              <ListItem className={stepsStyles.listItem} key={step.step}>
                <ListItemIcon className={stepsStyles.listItemIcon}>
                  <Step
                    text={step.step}
                    title={step.title}
                    completed={step.completed}
                    pending={step.pending}
                    disabled={step.disabled}
                    isRequired={step.isRequired ?? false}
                    redirect={step.redirect}
                    completedPercentage={step.completedPercentage}
                  />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </div>
      </Box>

      <OnboardingSuccessModal open={openEhrOnboardingSuccessModal} setOpen={setOpenEhrOnboardingSuccessModal} />
    </Page>
  );
};

export default ToDoOnboarding;
