import React from 'react';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';

const container = {
  borderRadius: '3px',
  border: '1px solid rgba(0, 0, 0, .56)',
  display: 'flex',
};

const icon = {
  color: '#000000',
  width: '22px',
  height: '22px',
};

const IconCross = ({ open }: any) => (
  <div style={container}>{open ? <RemoveIcon style={icon} /> : <AddIcon style={icon} />}</div>
);

export default IconCross;
