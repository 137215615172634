// eslint-disable-next-line no-shadow
import { IN_PERSON_VISIT_TYPE } from '../services/ServiceVisit';

export enum PHOTO_TYPE {
  // eslint-disable-next-line no-unused-vars
  BEFORE = 'before',
  // eslint-disable-next-line no-unused-vars
  AFTER = 'after',
}

export const AFTER_PHOTOS_INITIAL_STATE = {
  trainingEducationConsent: false,
  socialMediaConsent: false,
  deidentifiedConsent: false,
  trainingMarketingPhotoConsent: false,
  generalPhotoConsent: false,
  patientName: '',
  signature: null,
  createdAt: null,
  serviceVisitId: null,
};

export const SERVICE_VISIT_INITIAL_STATE = {
  serviceVisit: {
    id: null,
    createdAt: '',
    updatedAt: '',
    appointmentKind: null,
    partyToken: null,
    customerNote: null,
    paymentStatus: '',
    status: '',
    expectedResults1: '',
    expectedResults2: '',
    internalNote: null,
    discount: null,
    tips: null,
    totalCharged: null,
    submittedAt: null,
    squareOrderId: null,
    charges: [],
    fromSquare: false,
    originDate: null,
    notes: [],
    annotatedPhotos: [],
    photos: [],
    opened: false,
    closedAt: '',
    cosigned: null,
    cosignedBy: null,
    cosignedAt: null,
    cosignatureImg: null,
    signedBy: null,
    signatureImg: null,
    visitType: IN_PERSON_VISIT_TYPE,
  },
  isLoading: false,
  isLoadingProgressingSaving: false,
  isLoadingServices: false,
  services: [],
  selectedServices: [],
  servicesUnits: {},
  creditServicesUnits: {},
  variantsUnits: {},
  creditVariantsUnits: {},
  totalServicesUnits: {},
  totalCreditServicesUnits: {},
  isUploadingBeforePhoto: false,
  isUploadingAfterPhoto: false,
  currentStep: 0,
  selectedServiceGroups: [],
  signedConsents: {},
  signedStandingOrders: [],
  afterPhotos: { ...AFTER_PHOTOS_INITIAL_STATE },
  customerPhotoConsent: null,
  checkout: {
    servicesDiscounts: {},
    variantsDiscounts: {},
    taxes: 0.0,
    isLoading: false,
    transactionStatus: 'pending',
    squareErrorCode: null,
    tips: 0,
    referralsChunkIndex: [],
    redeemableIds: [],
    galdermaApplied: false,
    discounts: 0,
    squareTransactionId: null,
    otherTenderDiscounts: 0,
    toPay: 0,
    total: 0,
    subtotal: 0,
    note: null,
    processedInSquare: false,
    lastCheckoutTransactionCreatedAt: null,
  },
  currentService: {},
  oldAnnotations: [],
  serviceVisitDirty: false,
  discounts: [],
  productsSelected: [],
  serviceVisitSessionId: null,
  futureCreditUsed: [],
  futureVariantCreditUsed: [],
  isLoadingFetchCheckout: false,
  isLoadingGetServiceVisitFromPatient: false,
  processingCheckout: false,
  isProgressQueueWorking: false,
  visitType: IN_PERSON_VISIT_TYPE,
  allowSaveProgress: false,
  synchronizingServiceVisit: false,
};

export const PATIENT_CLEARED_STATUS = 'cleared';

export const NEW_SERVICE_VISIT_TITLE = 'New service visit confirmation';

export enum SERVICE_TYPE {
  RETAIL = 'retail',
  PRESCRIPTION_RETAIL = 'prescription_retail',
  PRESCRIPTION = 'prescription',
  FOLLOW_UP = 'follow_up',
}

export enum SERVICE_GROUPS_THAT_DONT_REQUIRE_BEFORE_PHOTO {
  MISC = 'Misc',
  RETAIL = 'Retail',
  B12 = 'B12',
  AESTHETICIAN_SERVICES = 'Aesthetician Services',
}

export const MINIMUM_TIME_TO_UPDATE_THE_SERVICE_VISIT = 30000;
