import { useMutation } from 'react-query';
import Patients from '../../services/Patients';

interface ICreateCustomerMembershipConsent {
  patientId: string;
  planId: number;
  signature: string;
  patientName: string;
}
export const useCreateCustomerMembershipConsent = () =>
  useMutation(({ patientId, planId, signature, patientName }: ICreateCustomerMembershipConsent) =>
    Patients.createCustomerMembershipConsent({ customerId: +patientId, planId, signature, patientName })
  );
