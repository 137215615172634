import React from 'react';
import { Box } from '@material-ui/core';
import { orderBy } from 'lodash';
import ImageItem from './ImageItem';

const HistoryImageList = ({ photos, handlerSelectPhoto, image }: any) => (
  <Box mb={2}>
    <Box display="flex" flexDirection="row" flexWrap="nowrap" style={{ overflowX: 'scroll' }}>
      {orderBy(photos, ['uploadedAt'], ['desc']).map(({ id, thumbnailUrl, largeUrl, uploadedAt }) => (
        <ImageItem
          handlerSelectPhoto={handlerSelectPhoto}
          key={id}
          id={id}
          image={image}
          thumbnailUrl={thumbnailUrl}
          largeUrl={largeUrl}
          date={uploadedAt}
        />
      ))}
    </Box>
  </Box>
);

export default HistoryImageList;
