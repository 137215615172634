import React from 'react';
import { useHistory } from 'react-router-dom';
import { MEDSPA_CARE_MESSAGES_CUSTOMIZE_PATH, MEDSPA_MESSAGING_CUSTOMIZE_PATH } from 'src/routes/medspaAdminRoutes';
import { CircularProgress, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { TableCellCustom } from 'src/components/common/TableStyles/TableCellCustom';
import { downloadHTMLStringAsPdf } from 'src/utils/printPdf';
import { Edit as EditIcon, GetApp as GetAppIcon, Visibility as VisibilityIcon } from '@material-ui/icons';
import { CARE_MESSAGES_DOCUMENT_TYPE, OTHER_DOCUMENT_TYPE } from 'src/constants/medspa/documentsManagement.constants';
import { TableCellHeader } from 'src/components/common/TableStyles/TableCellHeader';
import { UPDATE, VIEW } from 'src/constants/medspa/servicesManagement.constants';
import { NEW_WINDOW } from 'src/constants/clientLead.constants';
import { useStyles } from '../documentsManagement.styles';

interface DocumentTableProps {
  documents: any[];
  isLoadingDocuments: boolean;
  documentType?: string;
  isFromMasterLibrary?: boolean;
}

const DocumentTable = ({
  documentType,
  documents,
  isLoadingDocuments,
  isFromMasterLibrary = false,
}: DocumentTableProps) => {
  const classes = useStyles();
  const history = useHistory();

  const isTypeOther = documentType === OTHER_DOCUMENT_TYPE;

  const handleView = (document: any) => {
    history.push({
      pathname:
        documentType === CARE_MESSAGES_DOCUMENT_TYPE
          ? MEDSPA_CARE_MESSAGES_CUSTOMIZE_PATH
          : MEDSPA_MESSAGING_CUSTOMIZE_PATH,
      state: {
        document: document || {},
        backUrl: window.location.pathname,
        documentType,
        isFromMasterLibrary,
        action: VIEW,
      },
    });
  };

  const handleEdit = (document: any) => {
    history.push({
      pathname:
        documentType === CARE_MESSAGES_DOCUMENT_TYPE
          ? MEDSPA_CARE_MESSAGES_CUSTOMIZE_PATH
          : MEDSPA_MESSAGING_CUSTOMIZE_PATH,
      state: {
        document: document || {},
        editable: true,
        documentType,
        isFromMasterLibrary,
        backUrl: window.location.pathname,
        action: UPDATE,
      },
    });
  };

  const handleDownload = (document: any) => downloadHTMLStringAsPdf({ content: document.content, name: document.name });

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableCellHeader className={classes.tableHeadCell}>{isTypeOther ? 'Name' : 'Service Group'}</TableCellHeader>
          <TableCellHeader />
        </TableHead>
        <TableBody data-cy="tableBody">
          {isLoadingDocuments ? (
            <div className={classes.loadingStateContainer}>
              <CircularProgress size={30} style={{ marginRight: '10px' }} />
              <p>Loading documents...</p>
            </div>
          ) : (
            <>
              {!documents.length && (
                <p className={[classes.sidesPadding, classes.customParagraph].join(' ')}>There are no documents.</p>
              )}
              {(documents as any[])
                ?.sort((i, j) => (i.name > j.name ? 1 : -1))
                .map((document) => (
                  <TableRow className={classes.tableRow} key={document.id}>
                    <TableCellCustom style={{ width: '50%', height: '50px' }}>{document.name}</TableCellCustom>
                    <TableCellCustom style={{ width: '100%', height: '50px' }} className={classes.actionsContainer}>
                      <>
                        {!isFromMasterLibrary && (
                          <EditIcon
                            className={classes.actionButton}
                            data-cy="documentEditIcon"
                            fontSize="small"
                            onClick={() => handleEdit(document)}
                          />
                        )}
                        {!isTypeOther && (
                          <VisibilityIcon
                            data-cy="documentVisibilityIcon"
                            fontSize="small"
                            className={classes.actionButton}
                            onClick={() => handleView(document)}
                          />
                        )}
                        {isTypeOther ? (
                          <a
                            href={document?.documentPdfUrl}
                            target={NEW_WINDOW}
                            rel="noreferrer"
                            style={{ color: 'black' }}
                          >
                            <GetAppIcon
                              data-cy="documentDownloadIcon"
                              fontSize="small"
                              className={classes.actionButton}
                            />
                          </a>
                        ) : (
                          <GetAppIcon
                            data-cy="documentDownloadIcon"
                            fontSize="small"
                            className={classes.actionButton}
                            onClick={() => handleDownload(document)}
                          />
                        )}
                      </>
                    </TableCellCustom>
                  </TableRow>
                ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentTable;
