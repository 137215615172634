// TODO check if replace by import * as Sentry from '@sentry/browser';
import * as Sentry from '@sentry/react';
import Cohere from 'cohere-js';
import CohereSentry from 'cohere-sentry';
import { Integrations } from '@sentry/tracing';

export const initializeSentry = (integrations: any) => {
  Sentry.init({
    release: process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? process.env.REACT_APP_COMMIT_REF : undefined,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations,
    tracesSampleRate: 1.0,
    beforeSend(event) {
      if (process.env.REACT_APP_SENTRY_FEEDBACK_ENABLED) {
        if (event.exception) {
          let user = {};
          const email = localStorage.getItem('identity.token.uid');
          if (email) {
            user = {
              email,
            };
          }
          Sentry.showReportDialog({ eventId: event.event_id, user });
        }
      }
      return event;
    },
  });
};

export const initializeSentryWithCohere = () => {
  try {
    if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
      try {
        Cohere.init(process.env.REACT_APP_COHERE_API_KEY as string);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    initializeSentry([new CohereSentry(), new Integrations.BrowserTracing()]);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default Sentry;
