import { withStyles, Button } from "@material-ui/core";

export const ButtonStatus = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontFamily: 'Messina Sans Regular',
    color: '#393b3f',
    textTransform: 'capitalize',
    padding: '5px 16px',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '29px'
    },
    display: 'inline-block'
  }
}))(Button);