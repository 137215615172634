/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { useSelector } from 'react-redux';
import { CardContent, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import IServiceGroup from 'src/interfaces/IServiceGroup';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, USER_TYPES } from '../../constants/general.constants';
import IServiceRequest from '../../interfaces/IServiceRequest';
import { Card } from '../common/card';
import { useStyles } from './services.style';
import { useCustomRoles } from '../../hooks/queries/useCustomRoles';
import { usePractitionerInfo } from '../../hooks/queries/usePractitioners';

interface Props {
  by: string;
  serviceRequests: IServiceRequest[];
  serviceGroups: IServiceGroup[];
}

/* This component only shows the service requested approvals from other users
 * that is not the logged in. (Practitioner assigned, physicians, etc)
 * READ ONLY component. To update the service requested use src/components/PatientProfile/Services.tsx
 */
const OtherServiceRequests = ({ by, serviceRequests, serviceGroups }: Props) => {
  const classes = useStyles();

  const { userId } = useSelector(({ auth }: any) => auth);
  const { data: roles = [] } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });
  const { practitionerInfo } = usePractitionerInfo(userId);

  const roleName = roles.find((role) => +role.id === practitionerInfo?.roleId)?.name;
  const isNonLegacyPractitioner = roleName !== PORTRAIT_LEGACY && roleName !== PORTRAIT_LEGACY_PLUS;

  function isChecked(serviceId: number): boolean {
    const service = serviceRequests.find(({ serviceGroupId }) => serviceGroupId === serviceId);
    return service ? service.approved : false;
  }

  function isRequested(serviceId: number): string {
    const service = serviceRequests.find(({ serviceGroupId }: any) => serviceGroupId === serviceId);
    return service && service.requested ? 'black' : 'white';
  }

  function isYellowFlagHighlighted(serviceId: number): boolean {
    const service = serviceRequests.find(({ serviceGroupId }: any) => serviceGroupId === serviceId);
    return !isNonLegacyPractitioner && !!(service && service.yellowFlagHighlighted);
  }

  function isRedFlagHighlighted(serviceId: number): boolean {
    const service = serviceRequests.find(({ serviceGroupId }: any) => serviceGroupId === serviceId);
    return !isNonLegacyPractitioner && !!(service && service.redFlagHighlighted);
  }

  return (
    <Card>
      <CardContent>
        <h5 className={classes.serviceTitle}>Other Indicated treatments ({by})</h5>
        <Grid container spacing={0}>
          {serviceGroups.map(({ name, id: serviceGroupId }: any) => (
            <Grid key={`serviceGroup_${serviceGroupId}`} item xs={6} md={4} lg={3}>
              <li className={classes.listItem} style={{ color: isRequested(serviceGroupId) }}>
                <FormControlLabel
                  label={name}
                  classes={{
                    root: `${isYellowFlagHighlighted(serviceGroupId) ? classes.listItemYellow : ''}
                            ${isRedFlagHighlighted(serviceGroupId) ? classes.listItemRed : ''}`,
                    label: `${isRedFlagHighlighted(serviceGroupId) ? classes.label : ''}`,
                  }}
                  control={
                    <Checkbox
                      color="default"
                      className={classes.checkbox}
                      checked={isChecked(serviceGroupId)}
                      name={name}
                      disabled
                      style={{ color: `${isRedFlagHighlighted(serviceGroupId) ? '#fff' : ''}` }}
                    />
                  }
                />
              </li>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OtherServiceRequests;
