import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { formatCurrency } from 'src/utils/formatNumber';
import { Error as ErrorIcon } from '@material-ui/icons';
import { CARD_FEE_PCT } from 'src/constants/inventory.constants';
import classNames from 'classnames';
import { useStyle } from './orderPayment.styles';
import { OrderContext } from '..';
import SelectCreditCard from './SelectCreditCard';
import OrderConfirmDialog from '../OrderConfirmDialog';

const OrderPayment: React.FC = () => {
  const [isUsingBankTransfer, setIsUsingBankTransfer] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [buttonTooltipOpen, setButtonTooltipOpen] = useState<boolean>(false);
  const { orderData: { savingsTotal = 0, subtotal = 0, paymentSourceId = null, desiredDeliveryDate = null } = {} } =
    useContext(OrderContext) || {};

  const transactionFee = isUsingBankTransfer ? 0 : subtotal * (CARD_FEE_PCT / 100);
  const total = subtotal + transactionFee;

  const handleSubmit = async (): Promise<void> => {
    setDialogOpen(true);
  };

  const classes = useStyle();

  return (
    <Box className={classes.orderPayment} data-testid="orderPayment">
      <Typography className={classes.heading}>Summary</Typography>
      {savingsTotal > 0 && (
        <Box className={classNames(classes.orderPaymentRow, classes.boldGray)}>
          <Typography>Saved</Typography>
          <Typography>{formatCurrency(savingsTotal)}</Typography>
        </Box>
      )}
      <Box className={classNames(classes.orderPaymentRow, classes.boldGray)}>
        <Typography>Subtotal</Typography>
        <Typography>{formatCurrency(subtotal)}</Typography>
      </Box>
      <Box className={classNames(classes.orderPaymentRow, classes.boldGray)}>
        <Typography>Delivery and Sales Tax</Typography>
        <Typography>Subject to additional charges</Typography>
      </Box>
      <Box>
        <Box className={classes.deliveryNotice}>
          <ErrorIcon />
          <Typography component="p">
            Shipping is waived in most circumstances. The only situation where shipping charges are required is when you
            are below the order minimums that were specified. Sales tax depends on location and is typically only
            applied on non-Rx products.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.orderPaymentOptions}>
        <Typography className={classes.bold}>Payment Options</Typography>
        <Box>
          <Button
            variant="outlined"
            disabled={!isUsingBankTransfer}
            onClick={() => setIsUsingBankTransfer(false)}
            data-testid="orderPayment.cardOnFile"
          >
            Card on File
          </Button>
          {/* <Button
            variant="outlined"
            disabled={isUsingBankTransfer}
            onClick={() => setIsUsingBankTransfer(true)}
            data-testid="orderPayment.bankTransfer"
          >
            {' '}
            Bank Transfer (no fee){' '}
          </Button> */}
        </Box>
      </Box>
      {isUsingBankTransfer ? <Box minHeight={155} /> : <SelectCreditCard />}
      <Box borderTop="1px solid #eee" className={classes.orderPaymentRow}>
        <Typography>{isUsingBankTransfer ? 'Transaction Fee (no fee)' : `Card Fee (${CARD_FEE_PCT}%)`}</Typography>
        <Typography>{formatCurrency(transactionFee)}</Typography>
      </Box>
      <Box className={classes.orderPaymentRow} data-size="lg">
        <Typography>Total</Typography>
        <Typography>{formatCurrency(total)}</Typography>
      </Box>
      <Tooltip
        title="Please select a payment method and delivery date in order to proceed"
        open={(!paymentSourceId || !desiredDeliveryDate) && buttonTooltipOpen}
        placement="top"
        arrow
      >
        <Box
          width="100%"
          onMouseEnter={() => setButtonTooltipOpen(true)}
          onMouseLeave={() => setButtonTooltipOpen(false)}
        >
          <Button
            onClick={handleSubmit}
            variant="outlined"
            className={classes.savePayment}
            data-testid="orderPayment.savePayment"
            disabled={!paymentSourceId || !desiredDeliveryDate}
          >
            {' '}
            Place Order{' '}
          </Button>
        </Box>
      </Tooltip>
      <OrderConfirmDialog open={dialogOpen} closeDialog={() => setDialogOpen(false)} />
    </Box>
  );
};

export { OrderPayment, OrderPayment as default };
