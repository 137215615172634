import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DOCUMENTS } from 'src/constants/reactQuery.keys';
import { DocumentFilters } from 'src/interfaces/documents.interface';
import Documents from '../../services/Documents';

export function useDocuments(filters: DocumentFilters) {
  return useQuery([DOCUMENTS, filters], () => Documents.getDocuments(filters), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });
}

export function useCreateDocument() {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { practitionerId?: string; userGroupAdminId?: string; documentFile: File; documentName: string }) =>
      Documents.createDocument(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([DOCUMENTS]);
      },
    }
  );
}

export function useUpdateDocument() {
  const queryClient = useQueryClient();
  return useMutation((params: { documentName: string; documentId: string }) => Documents.updateDocument(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENTS]);
    },
  });
}

export function useRemoveDocument() {
  const queryClient = useQueryClient();
  return useMutation((params: { documentId: string }) => Documents.removeDocument(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENTS]);
    },
  });
}
