import formatNumber from './formatNumber';

export function getChartsDataFormater(num: number, prefix: string = ''): string {
  let value = num;
  const prefixwithspacing = prefix.length ? `${prefix} ` : '';

  if (typeof num === 'undefined') {
    return '';
  }

  if (value >= 1000000000000) {
    value /= 1000000000000;
    return `${prefixwithspacing}${value.toFixed(value ? 2 : 0)}T`;
  }

  if (value >= 1000000000) {
    value /= 1000000000;
    return `${prefixwithspacing}${value.toFixed(value ? 2 : 0)}B`;
  }

  if (value >= 1000000) {
    value /= 1000000;
    return `${prefixwithspacing}${value.toFixed(value ? 2 : 0)}M`;
  }

  const formattedValue = formatNumber(value);
  return `${prefixwithspacing}${formattedValue}`;
}
