import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CardContent } from '@material-ui/core';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { useStyles } from './index.styles';
import { PATIENT_INFO } from '../../constants/reactQuery.keys';
import Patients from '../../services/Patients';
import { dispatch } from '../../rematch';
import { ADMIN_ROLE } from '../../constants/general.constants';
import PatientDetailHeader from '../../components/PatientProfile/PatientDetailHeader';
import { ManageCards } from './components/ManageCards';
import { PRACTITIONER_PATIENT_ALL_PATH, PRACTITIONER_PATIENT_CLEARED_PATH } from '../../routes/practitionerRoutes';
import { ADMIN_PATIENTS_LIST_PATH } from '../../routes/administratorRoutes';
import { hasAccessTo } from '../../utils/auth.utils';
import { GFE_REQUEST } from '../../constants/actions.constants';

const PatientCreditCards = () => {
  const classes = useStyles();
  const history = useHistory();
  const { patientId }: { patientId: string } = useParams();
  const { userType, permissions } = useSelector(({ auth }: any) => auth);

  const {
    data: patient,
    error,
    refetch,
  } = useQuery([PATIENT_INFO, patientId], () => Patients.getPatientInfo(patientId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (error) {
      const { message } = error as Error;

      /* eslint-disable no-nested-ternary */
      const goTo =
        userType === ADMIN_ROLE
          ? ADMIN_PATIENTS_LIST_PATH
          : hasAccessTo(GFE_REQUEST, permissions)
            ? PRACTITIONER_PATIENT_CLEARED_PATH
            : PRACTITIONER_PATIENT_ALL_PATH;
      history.push(goTo);
      const sentryMessage = `${message} for patient ${patientId}`;
      Sentry.captureMessage(sentryMessage, 'debug' as Sentry.Severity);
    }

    if (patient?.basicInfo.customerStatus === 'incomplete') {
      refetch();
      dispatch.patient.fetchBasicInfo({ patientId: parseInt(patientId, 10) });
    }
  }, [patient, error]);

  const navigateBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.navigationContainer}>
        <NavigationBar title="Manage Card" onBackButtonClick={navigateBack} />
      </div>
      <PatientDetailHeader patient={patient} />
      <CardContent className={classes.card}>
        <ManageCards patientId={patientId} />
      </CardContent>
    </div>
  );
};

export default PatientCreditCards;
