import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box } from '@material-ui/core';
import Patients from '../../../services/Patients';
import { useStyles } from './contactInfoForm.styles';
import { Button } from '../../common/Button';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import PhoneNumberCustom from '../../common/InputPhoneNumber';
import { STATES } from '../../../constants/medicalProfile.constants';
import { ICurrentAddress } from '../../../interfaces/ICurrentAddress';
import { ISearchAddress } from '../../../interfaces/ISearchAddress';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import { ROUTES } from '../../../constants/routes.constants';
import { ERROR_EMAIL_EXISTS, EMAIL_EXISTS_MESSAGE } from '../../../constants/clientLead.constants';

const schema = yup.object().shape({
  firstName: yup.string().required('First name is a required field'),
  lastName: yup.string().required('Last name is a required field'),
  birthdate: yup.date(),
  email: yup.string().required('Email is a required field').email('Must be a valid email'),
  phone: yup
    .string()
    .required('Phone is a required field')
    .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, 'Must be a valid phone number'),
  addressLine1: yup.string().required('Address is a required field'),
  addressLine2: yup.string(),
  city: yup.string().required('City is a required field'),
  state: yup.string().required('State is a required field'),
  zipCode: yup
    .string()
    .required('Zip code is a required field')
    .matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code'),
});

type Props = {
  data: any;
  isLoading: boolean;
};

const ContactInfoForm: React.FC<Props> = ({ data, isLoading }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { id, email, state, phone, city, addressLine1, addressLine2, zipCode, firstName, lastName, dateOfBirth } = data;
  const { register, handleSubmit, errors, setValue, control } = useForm({ resolver: yupResolver(schema) });
  const [birthdate, setBirthdate] = useState(new Date());
  const [address, setSearchAddress] = useState<ISearchAddress>({
    search: '',
    value: '',
  });
  const [currentAddress, setCurrentAddress] = useState<ICurrentAddress>({
    city: '',
    zipCode: '',
    state: '',
  });
  const [phoneApiError, setPhoneApiError] = useState<string | undefined>(undefined);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');

  const queryClient = useQueryClient();

  const { mutate, isLoading: isSavingPatient } = useMutation(
    async (params: any) => {
      const response = await Patients.savePatient(params.id, params.formData);
      if (response && response.error) {
        throw new Error(response.error);
      }

      queryClient.invalidateQueries(['contactInfo', params.id]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Contact info',
            action: 'updated',
          }),
        },
      });
    },
    {
      onSuccess: async () => {
        navigate();
      },
      onError: async (error: Error) => {
        // Will handle phone error send from the API
        if (error.message === 'Validation failed: Phone has already been taken, Identity user is invalid') {
          setPhoneApiError('Phone has already been taken');
        }
        if (error?.message === ERROR_EMAIL_EXISTS) {
          setEmailErrorMsg(EMAIL_EXISTS_MESSAGE);
        }
      },
    }
  );

  useEffect(() => {
    setBirthdate(dateOfBirth);
  }, [dateOfBirth]);

  useEffect(() => {
    setSearchAddress((prevState: ISearchAddress) => ({ ...prevState, value: addressLine1 }));
    setCurrentAddress({ city, state, zipCode });
  }, [addressLine1, city, state, zipCode]);

  const CustomTextField = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...props} name="dateOfBirth" inputRef={register} style={{ marginBottom: '10px' }} fullWidth />
  );

  const handleAddressSuggest = (suggest: ReactGooglePlacesSuggest.GeocodedPrediction) => {
    const { address_components: addressComponents } = suggest;
    const find = (type: string) => {
      const component = addressComponents.find((_) => _.types.includes(type));
      return component ? component.long_name : '';
    };

    const foundAddress = {
      city: find('locality'),
      state: find('administrative_area_level_1'),
      zipCode: find('postal_code'),
    };

    setSearchAddress({ search: '', value: `${find('street_number')} ${find('route')}` });
    setCurrentAddress(foundAddress);
    setValue('state', foundAddress.state);
  };

  const handleInputChange = (event: React.ChangeEvent<{ value: string }>) => {
    setSearchAddress({ search: event.target.value, value: event.target.value });
  };

  const navigate = () => {
    history.push(ROUTES.PATIENT_ID(id));
  };

  const isNotOfAge = (date: any) => moment().diff(moment(date), 'years') < 18;

  const onSubmit = useCallback(async (formData: any) => {
    if (!formData.dateOfBirth.length || isNotOfAge(formData.dateOfBirth)) {
      return;
    }

    mutate({ id, formData });
  }, []);

  const handleDayPicker = (newDate: any) => {
    setBirthdate(newDate);
  };

  if (isLoading || isEmpty(data)) {
    return <MultipleSkeleton />;
  }

  return (
    <article style={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <TextField
              name="firstName"
              defaultValue={firstName}
              error={!!errors.firstName}
              helperText={errors.firstName?.message || ' '}
              inputRef={register}
              className={classes.textField}
              InputLabelProps={{ className: classes.textFieldLabel }}
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name="lastName"
              defaultValue={lastName}
              error={!!errors.lastName}
              helperText={errors.lastName?.message || ' '}
              inputRef={register}
              className={classes.textField}
              InputLabelProps={{ className: classes.textFieldLabel }}
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name="email"
              defaultValue={email}
              error={!!errors.email || !!emailErrorMsg}
              helperText={errors.email?.message || emailErrorMsg}
              inputRef={register}
              className={classes.textField}
              InputLabelProps={{ className: classes.textFieldLabel }}
              label="Email"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                variant="dialog"
                inputVariant="outlined"
                error={!!errors.birthdate || !birthdate || isNotOfAge(birthdate)}
                helperText={isNotOfAge(birthdate) ? 'Is not of age' : ' '}
                label="Date of Birth"
                format="MM/DD/yyyy"
                autoOk
                value={moment(birthdate).utc().format('MM/DD/YYYY')}
                onChange={handleDayPicker}
                TextFieldComponent={CustomTextField}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={8}>
            <PhoneNumberCustom
              name="phone"
              defaultValue={phone}
              error={!!errors.phone || phoneApiError}
              helperText={phoneApiError || errors.phone?.message || ' '}
              inputRef={register}
              style={{ width: '100%' }}
              InputLabelProps={{ className: classes.textFieldLabel }}
              label="Phone"
              variant="outlined"
              onChange={() => setPhoneApiError('')}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box mb={2.8}>
              <ReactGoogleMapLoader
                params={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string, libraries: 'places' }}
                render={(googleMaps) =>
                  googleMaps && (
                    <ReactGooglePlacesSuggest
                      googleMaps={googleMaps}
                      autocompletionRequest={{ input: address.search }}
                      onSelectSuggest={handleAddressSuggest}
                      customRender={(prediction) => (
                        <Box padding={1.5}>{prediction ? prediction.description : <p>No results</p>}</Box>
                      )}
                    >
                      <TextField
                        name="addressLine1"
                        fullWidth
                        inputRef={register}
                        error={!!errors.addressLine1}
                        helperText={errors.addressLine1?.message}
                        label="Address"
                        variant="outlined"
                        type="text"
                        value={address.value}
                        placeholder="Address"
                        onChange={handleInputChange}
                      />
                    </ReactGooglePlacesSuggest>
                  )
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name="addressLine2"
              defaultValue={addressLine2}
              helperText={' '}
              inputRef={register}
              style={{ width: '100%' }}
              InputLabelProps={{ className: classes.textFieldLabel }}
              label="Address line 2"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name="city"
              defaultValue={currentAddress.city || city || ''}
              error={!!errors.city}
              helperText={errors.city?.message || ' '}
              inputRef={register}
              style={{ width: '100%' }}
              InputLabelProps={{ className: classes.textFieldLabel }}
              label="City"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box mb={2.8}>
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel htmlFor="state" className={classes.textFieldLabel} error={!!errors.state}>
                  State
                </InputLabel>
                <Controller
                  control={control}
                  name="state"
                  error={!!errors.state}
                  defaultValue={currentAddress.state || state}
                  as={
                    <Select id="state" labelWidth={40}>
                      {STATES.map((s: string) => (
                        <MenuItem key={s} value={s}>
                          {s}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {errors.state && <FormHelperText error>{errors.state.message}</FormHelperText>}
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name="zipCode"
              defaultValue={currentAddress.zipCode || zipCode || ''}
              error={!!errors.zipCode}
              helperText={errors.zipCode?.message || ' '}
              inputRef={register}
              style={{ width: '100%' }}
              InputLabelProps={{ className: classes.textFieldLabel }}
              label="Zip code"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8}>
            <div className={classes.buttons}>
              <Button
                title="Cancel"
                style={{ color: '#12574d', backgroundColor: 'none', border: 'none' }}
                onClick={navigate}
              />
              <Button
                title={isSavingPatient ? 'Updating Contact Info' : 'Update Contact Info'}
                disabled={isSavingPatient}
                className={classes.submitButton}
                type="submit"
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </article>
  );
};

export default ContactInfoForm;
