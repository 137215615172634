import { useQuery } from 'react-query';
import { PRACTITIONERS } from '../../constants/reactQuery.keys';
import { Practitioners } from '../../services/Practitioners';

export function usePractitionersDropdown(filters = {}, enabled: boolean = true) {
  if (Object.keys(filters).length) {
    return useQuery([PRACTITIONERS, filters], () => Practitioners.getDropdown(filters), {
      refetchOnWindowFocus: false,
      enabled,
    });
  }

  return useQuery([PRACTITIONERS], () => Practitioners.getAllDropdown(), {
    refetchOnWindowFocus: false,
    enabled,
  });
}

export const DEFAULT_CANCELLATION_FEE = 150;

export const defaultPractitionerValues = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  servicesCommission: '',
  practitionerInformation: {
    id: 0,
    identityId: '',
    endpoint: '',
    instagramLink: null,
    facebookLink: null,
    twitterLink: null,
    profileImageUrl: null,
    isExpert: false,
    licenses: '',
    position: '',
    locations: '',
    credentials: '',
    createdAt: '',
    updatedAt: '',
    profileImageFileName: null,
    profileImageContentType: null,
    profileImageFileSize: null,
    socialMediaReviewsInfo: null,
  },
  slug: '',
  signatureImageUrl: '',
  profileImageUrl: '',
  inactive: false,
  activeForScheduling: false,
  squareLocationId: '',
  medicalDirectorId: null,
  requestCreditCard: false,
  requestDeposit: false,
  entityName: '',
  medicalGroup: '',
  cancelationFee: DEFAULT_CANCELLATION_FEE,
  roleId: null,
  acuityUserId: null,
  depositFee: null,
  cancellationFee: null,
  isEhrReady: false,
  userGroupId: null,
  doingBusinessAs: '',
  enableAcuityEnterprise: false,
  gfeSetting: '',
  requestReclearOnProfileChange: false,
};

export { PRACTITIONERS } from '../../constants/reactQuery.keys';
