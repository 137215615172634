/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { get, orderBy } from 'lodash';
import { ProductBox } from '../ProductBox';
import { Title } from './productIdentifiers.styles';
import ProductIdentifierDetails from './ProductIdentifierDetails';
import ProductIdentifierHeader from './ProductIdentifierHeader';

const useQuery = () => new URLSearchParams(useLocation().search);

interface service {
  assets: any[];
  name: string;
  units?: number;
  vials?: number;
}

interface Props {
  service: service;
  editionDisabled: boolean;
  hasScannedProducts?: boolean;
  showPrice?: boolean;
  aggregatedField: string; // used by header component
  hidePractitionerBar?: boolean; // used by header component
  hideServiceBackButtom?: boolean; // used by header component
  canMarkAsEmpty?: boolean; // used by detail actions component
  hideDetailColors?: boolean; // show colors of stock, expiration, etc
  goBack?: () => void; // used by header component
  handleRemove?: (assetsSelected: any, shouldUseCallback?: boolean) => void;
}

const ProductIdentifiers = (props: Props) => {
  const history = useHistory();
  const {
    service,
    editionDisabled,
    hasScannedProducts,
    canMarkAsEmpty,
    hideDetailColors,
    showPrice,
    goBack,
    handleRemove,
  } = props;

  const query = useQuery();
  const [isEditionDisabled, setIsEditionDisabled] = useState(editionDisabled);
  const showUnitDetails = get(service.assets, '[0].allowPartialSale', false);
  const defaultGoBack = () => (goBack ? goBack() : history.goBack());

  useEffect(() => {
    if (query.get('readonly') === 'true') {
      setIsEditionDisabled(true);
    }
  });

  return (
    <Box width="100%">
      <ProductIdentifierHeader {...props} goBack={defaultGoBack} />
      <Box width="100%">
        <Box m="10px">
          {hasScannedProducts && (
            <Box mb={2.5} style={{ padding: '20px' }}>
              <Title>{service.name} Scanned</Title>
            </Box>
          )}
          <Grid container direction="row">
            {orderBy(service.assets, ['counted'], ['desc']).map((item: any) => (
              <Grid key={item.internalSerialNumber} item style={{ paddingLeft: '23px' }}>
                <ProductBox style={{ backgroundColor: item.currentNumberOfUnits > 0 ? '#fff' : '#F0F2F4' }}>
                  <ProductIdentifierDetails
                    showUnitDetails={showUnitDetails}
                    hideDetailColors={hideDetailColors}
                    asset={item}
                    canMarkAsEmpty={canMarkAsEmpty}
                    showPrice={showPrice}
                    goBack={defaultGoBack}
                    handleRemove={!isEditionDisabled ? handleRemove : undefined}
                  />
                </ProductBox>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductIdentifiers;
