import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import useStyles from './index.styles';

const Legal = () => {
  const classes = useStyles();

  const inventoryPolicyLink = process.env.REACT_APP_INVENTORY_POLICY_LINK || '';
  const paymentPolicyLink = process.env.REACT_APP_PAYMENT_POLICY_LINK || '';
  const partnerDirectoryLink = process.env.REACT_APP_PARTNER_DIRECTORY_LINK || '';

  return (
    <Grid container direction="row" className={classes.parentGrid}>
      <Grid item xs={6}>
        <Typography variant="h6">Legal</Typography>
      </Grid>
      <Grid item xs={6} container direction="row" justify="space-between">
        <Grid item xs={4}>
          <Link href={inventoryPolicyLink} target="_blank" rel="noreferrer">
            <Typography className={classes.links}>Inventory Policy</Typography>
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Link href={paymentPolicyLink} target="_blank" rel="noreferrer">
            <Typography className={classes.links}>Payment Policy</Typography>
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Link href={partnerDirectoryLink} target="_blank" rel="noreferrer">
            <Typography className={classes.links}>Preferred Partner Directory</Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Legal;
