import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { logSentryMessageError } from 'src/services/Sentry';
import { logDatadogException } from 'src/services/Datadog';
import { IPractitioner, IPractitionerPost } from '../../interfaces/IPractitioner';
import { Practitioners } from '../../services/Practitioners';
import { dispatch } from '../../rematch';
import {
  PRACTITIONERS,
  PRACTITIONER_INFO,
  PHYSICIAN_ASSIGNED_PROVIDERS,
  PRACTITIONER_PRIMARY_SERVICES,
  USER_GROUPS_INFINITY,
  MEDSPA_LOCATION_PRACTITIONERS,
} from '../../constants/reactQuery.keys';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import { SNACKBAR_INFO, SNACKBAR_SUCCESS } from '../../constants/general.constants';

export function usePractitioner(id: number | undefined, enabled: boolean) {
  // TODO: create v3 endpoit
  const response = useQuery([PRACTITIONER_INFO], () => Practitioners.getPractitionerInfo(id), {
    enabled: !!id && enabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'provider' })),
  });

  return {
    ...response,
    data: response.data || ({} as IPractitioner),
  };
}

export interface PhysicianProviderQueryParam {
  search?: string;
  gfeStatus?: string;
  license?: string;
  practitionerIds?: string[] | number[];
}

export const usePractitionerForPhysicians = (queryParams: PhysicianProviderQueryParam) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries([PHYSICIAN_ASSIGNED_PROVIDERS, queryParams]);
  }, [JSON.stringify(queryParams)]);

  return useQuery(
    [PHYSICIAN_ASSIGNED_PROVIDERS, queryParams],
    async () => Practitioners.getPractitionersForPhysician(queryParams),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: () =>
        useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'provider' })),
    }
  );
};

export const useCreateMedspaProvider = (
  userGroupId: string | number,
  successCallback?: () => void,
  showSuccessMessage = true
) => {
  const queryClient = useQueryClient();
  return useMutation((providerData: FormData) => Practitioners.createMedspaProvider(userGroupId, providerData), {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY] });
      await queryClient.invalidateQueries({ queryKey: [PRACTITIONERS] });
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_LOCATION_PRACTITIONERS] });
      if (showSuccessMessage) {
        showSnackbar(compile('provider.created'), SNACKBAR_SUCCESS);
      }

      successCallback?.();
    },
    onError: (error: any) => {
      showSnackbar(`Failed to create provider: ${error.message}`, SNACKBAR_INFO);
    },
  });
};

export const useCreatePractitioner = (successCallback?: () => void, showSuccessMessage = true) => {
  const queryClient = useQueryClient();

  return useMutation((params: IPractitionerPost) => Practitioners.createPractitioner(params), {
    onSuccess: ({
      messages = [],
      practitioner,
    }: {
      success: boolean;
      messages: string[];
      practitioner: IPractitioner;
    }) => {
      messages.forEach((message) => showSnackbar(message, SNACKBAR_INFO));

      queryClient.invalidateQueries([PRACTITIONERS, practitioner.id]);

      if (showSuccessMessage) {
        showSnackbar(
          compile('practitioner.created', {
            squareId: practitioner.squareLocationId,
          }),
          SNACKBAR_SUCCESS
        );
      }

      successCallback?.();
    },
  });
};

interface EditPractitionerParams {
  providerId: number;
  userGroupId: string;
  formData: FormData;
}

export const useEditMedspaProvider = (successCallback?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: EditPractitionerParams) =>
      Practitioners.editMedspaProvider(params.providerId, params.userGroupId, params.formData),
    {
      onSuccess: (data) => {
        const { success, messages, practitioner } = data;
        if (success) {
          queryClient.invalidateQueries(['PRACTITIONERS', practitioner.id]);
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile('generic.success_message', {
                action: 'edited',
                element: 'Provider',
              }),
              type: 'success',
              duration: 2500,
            },
          });
          successCallback?.();
        } else {
          messages.forEach((message: string) => showSnackbar(message, 'info'));
        }
      },
      onError: (err, params) => {
        const error = err as Error;
        logSentryMessageError('Error updating Provider', { raw: error, params });
        logDatadogException(error, { params });
        showSnackbar('Failed to update Provider', 'error');
      },
    }
  );
};

export const usePractitionerPrimaryServices = () => {
  const response = useQuery([PRACTITIONER_PRIMARY_SERVICES], () => Practitioners.practitionerPrimaryServices());

  return {
    ...response,
    data: response.data || {
      selectedServicesFromPrimaryLibrary: [],
      selectedServiceGroupsFromPrimaryLibrary: [],
    },
  };
};
