import { makeStyles } from '@material-ui/core';

export const styles: object = {
  root: {
    '& td': {
      fontSize: 11,
      borderBottom: 'none',
      padding: '8px 16px',
    },
    backgroundColor: 'inherit',
    '&[data-edit="true"]': {
      transitionDuration: '0.1s',
      backgroundColor: '#f1f2f488',
    },
    '&[data-loading="true"]': {
      animation: '$pulse 2s ease-in-out infinite',
    },
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '30%': {
      opacity: 0.26,
    },
    '100%': {
      opacity: 1,
    },
  },
  inputCell: {
    width: 'min-content',
    margin: '0 25px 0 auto',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  iconButton: {
    borderRadius: '6px',
    width: '32px',
    height: '32px',
    backgroundColor: '#E8EEED',
    color: '#1D584D',
    margin: 'auto',
  },
  nameCol: {
    width: '25%',
    minWidth: '145px',
    overflow: 'hidden',
    transitionDuration: '0.1s',
    '& svg': {
      fontSize: 15,
      verticalAlign: 'middle',
      marginLeft: 5,
      color: 'rgb(246,147,12)',
    },
  },
  description: {
    maxWidth: '95px',
    minWidth: '95px',
    '&::-webkit-scrollbar': {
      width: '8px',
      border: '1px solid #d3d3d369',
      borderRadius: '6px',
    },
  },
  centerCell: {
    maxWidth: '95px',
    minWidth: '95px',
    textAlign: 'center',
  },
  percentChip: {
    minWidth: 50,
  },
  formControl: {
    maxWidth: '99px',
    maxHeight: '45px',
    textAlign: 'center',
    fontSize: 16,
    '& *': {
      textAlign: 'center',
      maxHeight: '45px',
      fontSize: 12,
    },
    '& input': {
      padding: '10px 14px',
      minWidth: 20,
    },
    '& input[type="number"]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
};

export const useStyle = makeStyles(styles);
export default styles;
