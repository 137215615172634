import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
    height: '47px',
    lineHeight: '47px',
  },
  modal: {
    width: '550px',
    height: '100%',
    marginRight: 0,
    marginLeft: 'auto',
    borderRadius: '8px',
    backgroundColor: 'white',
  },
  modalContent: {
    width: '440px',
    padding: '4rem 2rem',
    background: 'white',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
  },
  primaryButton: {
    backgroundColor: '#12574d',
    color: 'white',
    width: '100%',
    marginBottom: 0,
    height: '56px',
    marginTop: 'auto',
  },
  primaryButtonDisabled: {
    backgroundColor: 'lightgrey',
    color: 'black',
    width: '100%',
    marginBottom: 0,
    height: '56px',
    marginTop: 'auto',
  },
  divider: {
    border: '1px solid #E4E7EB',
    marginBottom: '10px',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '80%',
    height: '80%',
    margin: '30px auto 10px auto',
    gap: '50px',
  },
  modalHeader: {
    height: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  closeButton: {
    cursor: 'pointer',
    color: theme.palette.grey[600],
  },
  documentContainer: {
    background: theme.palette.grey[200],
    borderRadius: '8px',
    marginBottom: '10px',
    width: '100%',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
  },
  deleteButton: {
    marginLeft: '10px',
    borderRadius: '50%',
    padding: '8px',
    color: 'black',
  },
  documentNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  tabBar: {
    width: '100%',
    display: 'flex',
    '& button': {
      display: 'block',
      backgroundColor: '#fff',
      border: '1px solid',
      borderBottom: 'none',
      borderColor: '#aaa',
      color: '#000',
      borderRadius: 8,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      width: 130,
      height: 55,
      margin: 'auto 10px 2px 5px',
      fontSize: 12,
    },
    '& button:first-child': {
      marginLeft: 1,
    },
    '& button[data-active="true"]': {
      borderColor: '#ddd',
      color: '#666',
      backgroundColor: '#eee',
    },
  },
}));
