import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  APPLIED_SERVICE_VISIT_REFERRAL_CREDITS,
  PATIENT_REFERRALS,
  SERVICE_VISIT_CHECKOUT,
} from '../../constants/reactQuery.keys';
import Patients from '../../services/Patients';
import ServiceVisit from '../../services/ServiceVisit';
import { useReactQueryOnErrorHandler } from '../../utils/global';

const useReferrals = (patientId: number, enabled: boolean) =>
  useQuery([PATIENT_REFERRALS, patientId], () => Patients.getReferrals(patientId), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: true,
    enabled,
  });

export interface ApplyServiceReferralCreditParams {
  referralIds: number[];
}

export const useApplyReferralCredits = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ApplyServiceReferralCreditParams) => ServiceVisit.applyServiceVisitReferralCredits(serviceVisitId, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([APPLIED_SERVICE_VISIT_REFERRAL_CREDITS, serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};

export const useFetchAppliedReferralCredits = (serviceVisitId: number, enabled: boolean) =>
  useQuery(
    [APPLIED_SERVICE_VISIT_REFERRAL_CREDITS, serviceVisitId],
    () => ServiceVisit.getAppliedServiceVisitReferralCredits(serviceVisitId),
    {
      refetchOnWindowFocus: true, // For multi-tab purposes
      keepPreviousData: true,
      enabled,
    }
  );

export default useReferrals;
