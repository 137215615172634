import Api from './Api';
import IPrice from '../interfaces/IPrice';

const Variants = {
  async getVariantItemPricings(variantItemId: number): Promise<IPrice[]> {
    const { history } = await Api.get(`/practitioners/practitioner_pricings`, {
      variantItemId,
    });
    return history;
  },
  async updateVariantItemPricing(variantItemId: number, priceOverride?: number): Promise<void> {
    await Api.put('/practitioners/update_practitioner_pricing', {
      variantItemId,
      priceOverride,
    });
  },
};
export default Variants;
