import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { useEffect, useState } from 'react';
import { ChartReview, IUpdateChartNote } from 'src/interfaces/IPractitionerChartReview';
import { MedicalDirectorInfo, MedicalLicenseDetails } from 'src/interfaces/onboarding.interface';
import { MedspaAdmins } from '../../services/MedspaAdmins';
import { IMedspaAdminInfo, IMedspaAdminList, MedspaAdminProps } from '../../interfaces/IMedspaAdminList';
import {
  ASSIGNABLE_PRACTITIONERS,
  CURRENT_USER_GROUP,
  MEDSPA_ADMIN_INFO,
  MEDSPA_ADMIN_ONBOARDING_PROGRESS,
  MEDSPA_ADMINS,
  MEDSPA_CHART_REVIEWS,
  MEDSPA_EARNINGS_CALCULATIONS,
  MEDSPA_FLEX_TRANSACTIONS,
  MEDSPA_OPEN_SERVICE_VISITS,
  MEDSPA_PRACTITIONERS,
  MEDSPA_PRACTITIONERS_YEAR_STATS,
  MEDSPA_ROLE_NAME,
  MEDSPA_SUMMARIZE_TRANSACTIONS,
  PRACTITIONERS,
  USER_GROUPS_INFINITY,
} from '../../constants/reactQuery.keys';
import { PossibleTransactionParams, PossibleTransactionResponse } from './usePractitionersDashboard';
import { showSnackbar } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import { SNACKBAR_INFO, SNACKBAR_SUCCESS } from '../../constants/general.constants';

const useMedspaAdmins = (enabled: boolean = true, filters = {}) =>
  useQuery({
    queryKey: [MEDSPA_ADMINS, JSON.stringify(filters)],
    queryFn: (): Promise<IMedspaAdminList> => MedspaAdmins.getMedspaAdmins(filters),
    refetchOnWindowFocus: false,
    enabled,
  });

const useMedspaAdminInfo = () =>
  useQuery({
    queryKey: [MEDSPA_ADMIN_INFO],
    queryFn: (): Promise<IMedspaAdminInfo> => MedspaAdmins.getMedspaAdminInfo(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 2,
  });

const useMedspaAdminsByUserGroup = (userGroupId: string, enabled: boolean = true) =>
  useQuery({
    queryKey: [MEDSPA_ADMINS, userGroupId],
    queryFn: (): Promise<IMedspaAdminList | null> => MedspaAdmins.getMedspaAdminsByUserGroup(userGroupId),
    enabled,
  });

const useAssignablePractitioners = () =>
  useQuery({
    queryKey: [ASSIGNABLE_PRACTITIONERS],
    queryFn: (): Promise<IMedspaAdminList | null> => MedspaAdmins.getAssignablePractitioners(),
  });

const useMedspaPractitioners = (enabled: boolean = true) =>
  useQuery({
    queryKey: [MEDSPA_PRACTITIONERS],
    queryFn: (): Promise<IMedspaAdminList | null> => MedspaAdmins.getMedspaPractitioners(),
    enabled,
    refetchOnWindowFocus: false,
  });

const useMedspaPractitionerYearStatistics = (
  userGroupId: string,
  practitionerId: string,
  period: string,
  enabled: boolean
) =>
  useQuery({
    queryKey: [MEDSPA_PRACTITIONERS_YEAR_STATS, period, practitionerId],
    queryFn: () => MedspaAdmins.getMedspaYearStatistics(userGroupId, period, practitionerId),
    enabled,
    keepPreviousData: false,
  });

const useMedspaSummarizeTransactions = (
  practitionerId: string,
  query: any,
  userGroupId: string,
  enabled: boolean = true
) =>
  useInfiniteQuery(
    [MEDSPA_SUMMARIZE_TRANSACTIONS, query, practitionerId],
    ({ pageParam = 1 }) =>
      MedspaAdmins.getMedspaSummarizedTransactions(userGroupId, practitionerId, { ...query, page: pageParam }),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      getNextPageParam: (lastPage: any) => {
        if (lastPage?.meta?.currentPage < lastPage?.meta?.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
        return false;
      },
      enabled,
    }
  );

const useMedspaEarningsCalculations = (
  month: string,
  userGroupId: string,
  practitionerId: string,
  enabled: boolean = true
) =>
  useQuery({
    queryKey: [MEDSPA_EARNINGS_CALCULATIONS, month, practitionerId],
    queryFn: () => MedspaAdmins.getPractitionersMedspaEarningsCalculations(userGroupId, practitionerId, month),
    enabled,
    keepPreviousData: false,
  });

const useMedspaRole = (enabled: boolean = true) =>
  useQuery({
    queryKey: [MEDSPA_ROLE_NAME],
    queryFn: () => MedspaAdmins.getMedspaRole(),
    enabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

const useMedspaFlexTransactions = (
  userGroupId: string,
  practitionerId: string,
  query: PossibleTransactionParams,
  enabled: boolean = true
) => {
  const queryClient = useQueryClient();
  const [results, setResults] = useState<InfiniteData<PossibleTransactionResponse | null>>();

  useEffect(() => {
    queryClient.invalidateQueries([MEDSPA_FLEX_TRANSACTIONS, query]);
  }, [JSON.stringify(query)]);

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, isError, refetch } = useInfiniteQuery(
    [MEDSPA_FLEX_TRANSACTIONS, JSON.stringify(query), practitionerId],
    ({ pageParam = 1 }) => MedspaAdmins.getFlexTransactions(userGroupId, practitionerId, { ...query, page: pageParam }),
    {
      staleTime: 60000,
      getNextPageParam: (lastPage: PossibleTransactionResponse | null) => {
        if (lastPage && lastPage?.meta?.currentPage < lastPage?.meta?.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
        return false;
      },
      enabled,
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data);
    }
  }, [data, isLoading, query]);

  return {
    refetch,
    results,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
  };
};

const useOpenServiceVisits = (practitionerId: string, userGroupId: string) =>
  useQuery({
    queryKey: [MEDSPA_OPEN_SERVICE_VISITS, practitionerId, userGroupId],
    queryFn: () => MedspaAdmins.getOpenServiceVisits({ practitionerId, userGroupId }),
    enabled: !!userGroupId,
    refetchOnWindowFocus: false,
  });

type MedspaAdminsChartReviews = {
  userGroupId: string;
  practitionerId: string;
  enabled: boolean;
};
const useMedspaChartReviews = ({
  showSaved,
  showUnread,
  page,
  userGroupId,
  practitionerId,
  enabled = true,
}: IUpdateChartNote & MedspaAdminsChartReviews): UseQueryResult<ChartReview[]> =>
  useQuery<ChartReview[]>({
    queryKey: [MEDSPA_CHART_REVIEWS, showSaved, showUnread, page, practitionerId],
    queryFn: (): Promise<ChartReview[]> =>
      MedspaAdmins.getChartReviews({ showSaved, showUnread, page, userGroupId, practitionerId }),
    enabled,
  });

const useUpdateMedspaChartReviews = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      acknowledged,
      serviceVisitId,
      userGroupId,
    }: {
      acknowledged: boolean;
      serviceVisitId: number;
      userGroupId: number;
    }): Promise<void> => {
      MedspaAdmins.updateMedspaChart(
        {
          serviceVisitId,
          acknowledged,
        } as IUpdateChartNote,
        userGroupId
      );
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_CHART_REVIEWS] });
    },
  });
};

const useCreateMedspaAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ params, userGroupId }: { params: MedspaAdminProps; userGroupId: number }): Promise<void> => {
      await MedspaAdmins.createMedspaAdmin(params, userGroupId);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMINS] });
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY] });
      await queryClient.invalidateQueries({ queryKey: [CURRENT_USER_GROUP] });
      showSnackbar(compile('medspa_admin.created'), SNACKBAR_SUCCESS);
    },
    onError: (error: any) => {
      showSnackbar(`Failed to create Medspa admin: ${error.message}`, SNACKBAR_INFO);
    },
  });
};

const useUpdateMedspaAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      params,
      userGroupId,
      userId,
    }: {
      params: FormData;
      userGroupId: number;
      userId: number;
    }): Promise<void> => {
      await MedspaAdmins.updateMedspaAdmin(params, userGroupId, userId);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [PRACTITIONERS] });
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMINS] });
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY] });
      await queryClient.invalidateQueries({ queryKey: [CURRENT_USER_GROUP] });
      showSnackbar(compile('medspa_admin.updated'), SNACKBAR_SUCCESS);
    },
    onError: (error: any) => {
      showSnackbar(`Failed to update MedSpa Admin: ${error.message}`, SNACKBAR_INFO);
    },
  });
};

function useMedspaAdminOnboardingProgress() {
  return useQuery([MEDSPA_ADMIN_ONBOARDING_PROGRESS], () => MedspaAdmins.getMedspaAdminOnboardingProgress(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 5, // 5 seconds
  });
}

const useUpdateMedspaAdminOnboardingProgress = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ stepName, percentage, override = false }: { stepName: string; percentage: number; override?: boolean }) =>
      MedspaAdmins.updateMedspaAdminOnboardingProgress({ stepName, percentage, override }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MEDSPA_ADMIN_ONBOARDING_PROGRESS]);
      },
    }
  );
};

const useUpdateMedspaMedicalDirector = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      medicalDirectorInfo,
      medicalLicenseDetails,
    }: {
      medicalDirectorInfo: MedicalDirectorInfo;
      medicalLicenseDetails?: MedicalLicenseDetails;
    }) => MedspaAdmins.updateMedspaMedicalDirector({ medicalDirector: medicalDirectorInfo, medicalLicenseDetails }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries({ queryKey: [CURRENT_USER_GROUP] });
      },
    }
  );
};

export {
  useMedspaPractitioners,
  useAssignablePractitioners,
  useMedspaAdmins,
  useMedspaAdminInfo,
  useMedspaPractitionerYearStatistics,
  useMedspaSummarizeTransactions,
  useMedspaEarningsCalculations,
  useMedspaRole,
  useMedspaFlexTransactions,
  useOpenServiceVisits,
  useMedspaChartReviews,
  useUpdateMedspaChartReviews,
  useMedspaAdminsByUserGroup,
  useUpdateMedspaAdmin,
  useMedspaAdminOnboardingProgress,
  useUpdateMedspaAdminOnboardingProgress,
  useUpdateMedspaMedicalDirector,
  useCreateMedspaAdmin as default,
};
