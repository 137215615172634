import React from 'react';
import { Box, Button, Modal, Typography, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

export const useStyles = makeStyles(() => ({
  title: {
    margin: '0 1rem 0 0',
  },
  formModal: {
    width: '38.2%',
    margin: '0.625rem 0.625rem auto auto',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '24px',
    minWidth: '550px',
    maxWidth: '550px',
  },
  formModalContent: {
    height: '98vh',
    width: '50%',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '24px',
    minWidth: '550px',
  },
  separator: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  formContainer: {
    overflowY: 'scroll',
    height: 'calc(100vh - 215px)',
  },
  footer: {
    position: 'absolute',
    bottom: '8px',
    left: '0px',
    width: '100%',
  },
}));

interface SideModalTitleProps {
  children: React.ReactNode;
}
export const SideModalTitle = ({ children }: SideModalTitleProps) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h6" gutterBottom className={classes.title}>
        {children}
      </Typography>
    </Box>
  );
};

interface SideModalHeaderProps {
  children: React.ReactNode;
  onClose?: () => void;
}
export const SideModalHeader = ({ onClose: propsOnClose, children }: SideModalHeaderProps) => {
  const classes = useStyles();
  const onClose = () => {
    propsOnClose?.();
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" p="1.5rem">
        {/* SideModalTitle */}
        {children}

        <Button onClick={onClose} style={{ minWidth: 'auto', border: '1px solid #CFCFCF' }}>
          <CloseIcon />
        </Button>
      </Box>
      <hr className={classes.separator} />
    </>
  );
};

interface SideModalFooterProps {
  children: React.ReactNode;
}
export const SideModalFooter = ({ children }: SideModalFooterProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <hr className={classes.separator} />
      <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <Box style={{ width: '100%' }}>{children}</Box>
      </Box>
    </Box>
  );
};

interface SideModalBodyProps {
  children: React.ReactNode;
}
export const SideModalBody = ({ children }: SideModalBodyProps) => {
  const classes = useStyles();
  return (
    <Box alignItems="center" p="1.5rem" className={classes.formContainer}>
      {children}
    </Box>
  );
};

interface SideModalProps {
  openModal: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}
const SideModal = ({ openModal, onClose: propsOnClose, children }: SideModalProps) => {
  const classes = useStyles();

  const onClose = () => {
    propsOnClose?.();
  };

  return (
    <Modal open={openModal} onClose={onClose} className={classes.formModal}>
      <Box className={classes.formModalContent}>
        {/* SideModalHeader -> SideModalTitle */}
        {/* SideModalBody */}
        {children}
        {/* SideModalFooter */}
      </Box>
    </Modal>
  );
};

/* SideModal Usage
  <SideModal openModal={openDialog} onClose={handleDialogCloseCancel}>
    <SideModal.Header onClose={handleDialogCloseCancel}>
      <SideModal.Title>
        Modal Title
      </SideModal.Title>
    </SideModal.Header>
    <SideModal.Body>
      Modal Body
    </SideModal.Body>
    <SideModal.Footer>
      Modal Footer
    </SideModal.Footer>
  </SideModal>
*/
SideModal.Body = SideModalBody;
SideModal.Header = SideModalHeader;
SideModal.Title = SideModalTitle;
SideModal.Body = SideModalBody;
SideModal.Footer = SideModalFooter;
export default SideModal;
