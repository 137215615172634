import React, { FC } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const CustomTypography = withStyles(() => ({
  root: {
    padding: '20px'
  }
}))(Typography);

interface Props {
  id: string | undefined,
  open: boolean,
  anchorEl: HTMLSpanElement | null,
  label: string,
  onClose: () => void
}

export const CustomPopover: FC<Props> = ({ id, open, anchorEl, label, onClose }) => (
  <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <CustomTypography>
      {label}
    </CustomTypography>
  </Popover>
);
