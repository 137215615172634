import React, { useEffect, useState } from 'react';
import { Grid, Divider, List, Container, Typography, TextField, Fab, Button } from '@material-ui/core';
import { Send, Create } from '@material-ui/icons';
import { orderBy } from 'lodash';

import formatDate from '../../utils/formatDate';
import compile from '../../utils/toastMessagesCompiler';

import IConversationThread from '../../interfaces/IConversationThread';
import IAvatarProps from '../../interfaces/IAvatarProps';

import DialogModal from '../../components/common/DialogModal';
import Message from '../../components/common/Threads/Message';
import { ThreadList, Thread, ThreadsHeader } from '../../components/common/Threads';
import useStyles from './ConversationsWrapper.style';

type propsType = {
  user1: IAvatarProps;
  user2: IAvatarProps;
  threads: IConversationThread[];
  selectedThread: IConversationThread | undefined;
  hideUnreadMeessages: boolean;
  isLoading: boolean;
  isError: boolean;
  readOnly: boolean;
  onSelectThread?: (thread: IConversationThread) => void;
  onCreateThread?: () => void;
  onCloseThread?: (thread: IConversationThread) => void;
  onSendMessage?: (text: string) => void;
};

const ConversationsWrapper = ({
  user1,
  user2,
  threads,
  selectedThread,
  hideUnreadMeessages = false,
  isLoading,
  isError,
  readOnly,
  onSelectThread = () => {
    /* empty fn to not crash when call over undefined */
  },
  onCreateThread = () => {
    /* empty fn to not crash when call over undefined */
  },
  onCloseThread = () => {
    /* empty fn to not crash when call over undefined */
  },
  onSendMessage = () => {
    /* empty fn to not crash when call over undefined */
  },
}: propsType) => {
  const classes = useStyles();

  const [selectedThreadIsClosed, setSelectedThreadIsClosed] = useState(true);
  const [messageText, setMessageText] = useState('');
  const [noThreads, setNoThreads] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setSelectedThreadIsClosed(Boolean(!selectedThread?.open));
  }, [selectedThread]);

  useEffect(() => {
    if (!threads.length) {
      setNoThreads(true);
    } else {
      setNoThreads(false);
    }
  }, [threads]);

  const handleSelectThread = async (thread: IConversationThread) => {
    onSelectThread(thread);
  };

  const handleCloseThread = async () => {
    setShowConfirmation(false);

    return selectedThread && onCloseThread(selectedThread);
  };

  const handleSendMessage = async () => {
    if (messageText.length === 0) {
      return;
    }
    setMessageText('');
    onSendMessage(messageText);
  };

  /* RENDERS FUNCTIONS */
  const editable = selectedThread && selectedThread.open && !readOnly;

  const messagesList = () => {
    if (isLoading) {
      return null;
    }
    if (!selectedThread) {
      return (
        <List className={`${classes.messageArea}`}>
          <Container style={{ marginTop: '5rem' }}>
            <Typography variant="h5" align="center">
              Select a conversation
            </Typography>
          </Container>
        </List>
      );
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {editable && (
          <Button
            variant="contained"
            color="default"
            aria-label="close thread"
            className={`${classes.roundedButton} ${classes.closeButton}`}
            onClick={() => setShowConfirmation(true)}
          >
            Close
          </Button>
        )}
        <List className={`${classes.messageArea} ${selectedThreadIsClosed ? classes.messageAreaClosed : ''}`}>
          {selectedThread.messages.length > 0 ? (
            orderBy(selectedThread.messages, ({ createdAt }) => new Date(createdAt), ['asc']).map(
              ({ content, createdAt, fromCustomer }, idx) => (
                <Message
                  key={createdAt.toString()}
                  author={fromCustomer ? user2.userName : user1.userName}
                  createdAt={createdAt}
                  content={content}
                  autoFocus={selectedThread.messages.length - 1 === idx}
                  position={fromCustomer ? 'right' : 'left'}
                />
              )
            )
          ) : (
            <Container style={{ display: 'block', marginTop: '5rem' }}>
              <Typography variant="h5" align="center">
                Nothing to show
              </Typography>
            </Container>
          )}
        </List>
        {editable && (
          <Container>
            <Divider />
            <Container style={{ marginTop: '5px', display: 'flex', paddingRight: '5px' }}>
              <TextField
                size="small"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                disabled={selectedThreadIsClosed}
                id="outlined-basic-email"
                label={selectedThreadIsClosed ? 'The conversation is closed' : 'Type your message'}
                fullWidth
                style={{ marginRight: '5px' }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
              />
              <Fab
                disabled={selectedThreadIsClosed}
                size="small"
                color="primary"
                aria-label="add"
                onClick={handleSendMessage}
              >
                <Send />
              </Fab>
            </Container>
          </Container>
        )}
      </div>
    );
  };

  return (
    <main>
      <Grid container className={classes.chatSection}>
        <Grid
          item
          xs={6}
          md={4}
          lg={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(80vh)',
          }}
          className="MuiPaper-elevation3"
        >
          <ThreadsHeader
            user1={user1}
            user2={user2}
            conversationsCount={threads.length}
            showCreateButton={!noThreads && !readOnly}
            buttonClasses={classes.roundedButton}
            onCreate={onCreateThread}
          />
          <div className={classes.threadsArea}>
            <ThreadList
              isLoading={isLoading}
              isError={isError}
              errorMessage={
                <Typography variant="h6">
                  {compile('generic.error_message', { action: 'retrieving', element: 'the messages' })}
                </Typography>
              }
              noItems={noThreads}
              noItemsMessage={
                <Container style={{ marginTop: '1rem', textAlign: 'center' }}>
                  <Typography variant="subtitle1">Nothing to show</Typography>
                  {!readOnly && (
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<Create />}
                      className={classes.roundedButton}
                      onClick={onCreateThread}
                    >
                      New conversation
                    </Button>
                  )}
                </Container>
              }
            >
              {threads.map((thread) => (
                <Thread
                  key={thread.id}
                  title={<>Conversation {formatDate(thread.createdAt, 'L')}</>}
                  unreadCount={hideUnreadMeessages ? 0 : thread.unreadMessagesCount}
                  subtitle={
                    <>
                      {thread.open ? 'Last activity:' : 'Closed at:'} {formatDate(thread.updatedAt, 'LLL')}
                    </>
                  }
                  status={thread.open ? 'open' : 'closed'}
                  selected={selectedThread?.id === thread.id}
                  onClick={() => handleSelectThread(thread)}
                />
              ))}
            </ThreadList>
          </div>
        </Grid>
        <Grid item xs={6} md={8} lg={9}>
          {messagesList()}
        </Grid>
      </Grid>
      <DialogModal open={showConfirmation} closeModal={() => setShowConfirmation(false)} confirm={handleCloseThread}>
        Are you sure you want to close the current conversation
      </DialogModal>
    </main>
  );
};

export default ConversationsWrapper;
