import React, { ReactElement, useRef } from 'react';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import InfiScroller from 'react-infi-scroller';
import SortDirection from '../../../types/SortDirection';
import { MultipleSkeleton } from '../LoadingSkeleton';
import Message from '../Message/Message';
import TableHeader from '../Table/TableHeader';
import { useStyles } from './Table.styles';

export const HEIGHT_BREAKPOINT = 800;
export const GUTTER = {
  S: 100,
  L: 200,
};

type ColumnType = {
  id: string;
  title: string | React.ReactNode;
  sort?: boolean;
  align?: 'start' | 'center' | 'end';
  className?: string;
};

export const PortraitTable = ({
  columns,
  sortBy,
  sortDirection,
  onSortChange,
  infiScroll,
  hasNextPage,
  fetchNextPage,
  isLoading,
  isFetching,
  isError,
  data,
  rowComponent,
  errorMessage,
  tableStyles,
}: {
  columns: ColumnType[];
  sortBy?: string;
  sortDirection?: SortDirection;
  onSortChange?: (by: string, direction: SortDirection) => void;
  infiScroll: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isLoading: boolean;
  isFetching?: boolean;
  isError?: boolean;
  data: any[];
  rowComponent: (row: any) => ReactElement;
  errorMessage: string;
  tableStyles?: string;
}) => {
  const classes = useStyles();
  const scrollerMain = useRef();
  const isLargeScreen = window.innerHeight > HEIGHT_BREAKPOINT;
  const getGutterValue = () => (isLargeScreen ? GUTTER.L : GUTTER.S);

  const ResultsComponent =
    data.length > 0 ? (
      <>{data.map((row: any) => rowComponent(row))}</>
    ) : (
      <Message text="No data to show" colSize={12} />
    );

  return (
    // @ts-ignore
    <div className={tableStyles || classes.tableContainer} ref={scrollerMain}>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHeader columns={columns} sortBy={sortBy} sortDirection={sortDirection} onSortChange={onSortChange} />
          <TableBody>
            {infiScroll ? (
              <InfiScroller
                active={hasNextPage}
                scrollTarget={scrollerMain?.current}
                hasMore={hasNextPage}
                gutter={getGutterValue()}
                shouldLoadMore={(scrollTargetHeight: any, scrollYOffset: any, gutter: any, scrollHeight: any) => {
                  const shouldLoadMore: Boolean = scrollTargetHeight + scrollYOffset + gutter >= scrollHeight;
                  return shouldLoadMore;
                }}
                onLoadMore={() => {
                  fetchNextPage?.();
                }}
              >
                {isLoading ? (
                  <MultipleSkeleton />
                ) : (
                  <>
                    {isError && <Message text={errorMessage} colSize={12} />}
                    {ResultsComponent}
                    {isFetching && <MultipleSkeleton />}
                  </>
                )}
              </InfiScroller>
            ) : (
              <>
                {isError && <Message text={errorMessage} colSize={12} />}
                {ResultsComponent}
                {isFetching && <MultipleSkeleton />}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
