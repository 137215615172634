import { Tab, withStyles } from '@material-ui/core';

const MainTab = withStyles(() => ({
  root: {
    fontSize: '13px',
    minWidth: 'auto',
    padding: '10px',
  },

  textColorInherit: {
    opacity: '0.5',
    color: '#12574d',
  },
}))(Tab);

export default MainTab;
