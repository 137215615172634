/* eslint-disable */
import React from 'react';
import { useStyles } from './AppliedDiscount.styles';
import { Collapse, TableCell, TableRow, Button } from '@material-ui/core';
import { DISCOUNT_TYPES } from '../../constants/checkout.constants';
import { calculateDiscount, formatNumber } from '../../utils/checkout.utils';
import { ILineItemDiscount } from '../../interfaces/IServiceVisits';
import { IDiscount } from '../../interfaces/checkout.interfaces';

interface IAppliedDiscounts {
  discounts: Array<IDiscount | ILineItemDiscount>;
  openDiscountsModal: (name: string, id: number, quantity: number, units: number, isVariantItem: boolean) => void;
  service: any;
  units: any;
  isVariantItem?: any;
  totalPrice: number;
}

const AppliedDiscounts = ({
  discounts = [],
  openDiscountsModal,
  service,
  units,
  isVariantItem,
  totalPrice,
}: IAppliedDiscounts) => {
  const classes = useStyles();

  const getAmountElement = (amount: string | number, type: string) => (
    <span>
      {DISCOUNT_TYPES.PERCENTAGE !== type ? '$' : ''}
      {(+amount).toFixed(2)}
      {DISCOUNT_TYPES.PERCENTAGE === type ? '%' : ''}
    </span>
  );

  const AppliedDiscountLine = ({ reason, amount, amountElement, discountName }: any) => (
    <div className={classes.discountLineItem}>
      <div style={{ width: '85%' }}>
        {!!discountName && <span>{discountName}: </span>}
        {amountElement}
        {!!reason && (
          <>
            <span> off - Custom Discount - </span>
            <span>{reason}</span>
          </>
        )}
      </div>
      <div>{amount}</div>
    </div>
  );

  return (
    <TableRow>
      <TableCell className={classes.tableCell} colSpan={6}>
        <Collapse in={!!discounts.length} timeout="auto" unmountOnExit>
          <div className={classes.appliedDiscountContainer}>
            <div className={classes.appliedDiscountSection}>
              <Button
                className={classes.editButton}
                onClick={() => {
                  openDiscountsModal(service.name, service.id, service.price, units, isVariantItem);
                }}
              >
                edit
              </Button>
              <div style={{ width: '100%' }}>
                {discounts.map((discount: any) => {
                  if ((discount as IDiscount).lineItemPriceValue) {
                    const { id, reason, discountType, name, discountByPercentage, lineItemPriceValue } =
                      discount as IDiscount;
                    const amountValue =
                      discountType === DISCOUNT_TYPES.PERCENTAGE ? discountByPercentage : lineItemPriceValue;
                    return (
                      <AppliedDiscountLine
                        key={`discount-${id}`}
                        amountElement={getAmountElement(amountValue || 0, discountType)}
                        reason={reason}
                        discountName={name}
                        amount={`- $ ${formatNumber(lineItemPriceValue)}`}
                      />
                    );
                  } else {
                    const { id, discountType, description, discountTypeValue } = discount as ILineItemDiscount;
                    return (
                      <AppliedDiscountLine
                        key={`discount-${id}`}
                        amountElement={getAmountElement(discountTypeValue || 0, discountType)}
                        reason={description}
                        amount={`- $ ${formatNumber(calculateDiscount(discountTypeValue, discountType, totalPrice))}`}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default AppliedDiscounts;
