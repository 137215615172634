import { withStyles, TableCell } from '@material-ui/core';

export const TableCellCustom = withStyles(() => ({
  root: {
    fontSize: '14px',
    fontFamily: 'Messina Sans Regular',
    color: '#393b3f',
    letterSpacing: '-0.05px',
    height: '36px',
    padding: '3px 16px',
    textTransform: 'capitalize',
  },
}))(TableCell);
