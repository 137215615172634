import { MEDSPA_V4_PATH } from 'src/constants/apiRoutes.constants';
import { showSnackbar } from 'src/utils/global';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { IMedspaStandingOrders, IMedspaStandingOrdersAPI } from 'src/interfaces/IMedspaStandingOrders';
import IStandingOrder from 'src/interfaces/IStandingOrder';
import Api from './Api';

const MedspaStandingOrders = {
  async getMedspaStandingOrders({ userGroupId }: { userGroupId: number }): Promise<IMedspaStandingOrders[]> {
    const { success, standingOrders }: IMedspaStandingOrdersAPI = await Api.get(
      `${MEDSPA_V4_PATH}/${userGroupId}/standing_orders`
    );
    if (!success) {
      showSnackbar('There was an error fetching standing orders.', SNACKBAR_ERROR);
      return [];
    }
    return standingOrders;
  },
  async updateMedspaStandingOrders({
    userGroupId,
    standingOrderId,
    content,
  }: {
    userGroupId: number;
    standingOrderId: number;
    content: string;
  }) {
    const { success } = await Api.put(`${MEDSPA_V4_PATH}/${userGroupId}/standing_orders/${standingOrderId}`, {
      content,
    });
    if (!success) {
      showSnackbar('There was an error updating standing order.', SNACKBAR_ERROR);
      return [];
    }
    showSnackbar('Standing order updated successfully.', SNACKBAR_SUCCESS);

    return success;
  },
  async createMedspaStandingOrder({
    userGroupId,
    name,
    content,
  }: {
    userGroupId: number;
    name: string;
    content: string;
  }): Promise<{ standingOrder: IStandingOrder | null }> {
    const { success, standingOrder } = await Api.post(`${MEDSPA_V4_PATH}/${userGroupId}/standing_orders`, {
      name,
      content,
    });
    if (!success) {
      showSnackbar('There was an error creating standing order.', SNACKBAR_ERROR);
      return { standingOrder: null };
    }
    showSnackbar('Standing order updated successfully.', SNACKBAR_SUCCESS);
    return standingOrder;
  },
};

export default MedspaStandingOrders;
