export const USER_INTERACTION_EVENT = 'user_interaction';
export const USER_INTERACTION_PATIENT_REVIEW = 'patient_review';
export const USER_INTERACTION_GOOGLE_REVIEW = 'google_review';
export const USER_INTERACTION_FACEBOOK_REVIEW = 'facebook_review';
export const USER_INTERACTION_YELP_REVIEW = 'yelp_review';

export const userInteractionProps = {
  interaction_type: 'click',
  category: 'EHR',
  component: USER_INTERACTION_PATIENT_REVIEW,
  url: '',
  initiator: 'provider',
  event_details: {},
};

const ACTION_PAGE_LOAD = 'page_load';
const ACTION_CLICK = 'click';
const RESULT_SUCCESS = 'success';
const RESULT_FAIL = 'fail';
const RESULT_EXCEPTION = 'exception';

const trackEventSelfSignUpLite = 'self_sign_up_lite';
export const TRACK_EVENT_SELF_SIGN_UP_LITE = {
  PAGE_LOAD: `${trackEventSelfSignUpLite}_${ACTION_PAGE_LOAD}`,
  CLICK_REGISTER: `${trackEventSelfSignUpLite}_${ACTION_CLICK}_register`,
  REGISTER_SUCCESS: `${trackEventSelfSignUpLite}_register_${RESULT_SUCCESS}`,
  REGISTER_FAIL: `${trackEventSelfSignUpLite}_register_${RESULT_FAIL}`,
  REGISTER_EXCEPTION: `${trackEventSelfSignUpLite}_register_${RESULT_EXCEPTION}`,
};
