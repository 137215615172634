import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  cardContent: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  appointmentSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '13px',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: '11px',
    },
  },
  icon: {
    opacity: '0.5',
  },
  title: {
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
  },
}));
