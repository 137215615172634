import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Typography, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import classnames from 'classnames';

import { ReactComponent as QRCode } from '../../../assets/images/qr-code.svg';
import { dispatch } from '../../../rematch';
import { useStyles, SelectCustom } from './supplyReceipt.styles';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import SupplyReceiptFooter from './SupplyReceiptFooter';
import ProductList from './ProductIdentifiersList/ProductList';
import ProductScanner from './ProductIdentifiersList/ProductScanner';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import compile from '../../../utils/toastMessagesCompiler';
import { CREATE_SUPPLY_RECEIPT } from '../../../constants/actions.constants';
import { hasAccessTo } from '../../../utils/auth.utils';

const NewSupplyReceiptsPageStep1 = () => {
  const scannerInputRef = useRef<HTMLInputElement>(null);
  const { supplyReceiptId }: any = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [hasPractitioner, setHasPractitioner] = useState<boolean>(true);
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const { warehouses, productsSelected, supplyReceipt, isLoading } = useSelector(
    (state: any) => state.newSupplyReceipt
  );
  const [internalNote, setInternalNote] = useState<string>(supplyReceipt.internalNote);
  const editionDisabled = supplyReceipt.status === 'closed';

  useEffect(() => {
    dispatch({ type: 'newSupplyReceipt/getSupplyReceipt', payload: supplyReceiptId || null });
  }, []);

  const focusScan = () => {
    setTimeout(() => {
      if (scannerInputRef && scannerInputRef.current) {
        scannerInputRef.current.focus();
      }
    }, 100);
  };

  useEffect(() => {
    if (supplyReceipt.internalNote !== internalNote) {
      setInternalNote(supplyReceipt.internalNote);
    }
  }, [supplyReceipt]);

  useEffect(() => {
    focusScan();
  }, [productsSelected, scannerInputRef]);

  // eslint-disable-next-line
  const onSaveCallback = (serviceId?: any, supplyReceiptId?: number) => {
    if (serviceId && supplyReceiptId) {
      history.push(`/administrator/new-supply-receipt-step-2/${supplyReceiptId}/${serviceId}`);
    } else {
      history.push('/administrator/supply-receipts');
    }
  };

  const onSaveChanges = (setStatus: boolean = true, serviceId?: number) => {
    let assetsIdsSelected: number[] = [];
    productsSelected.forEach(({ assets }: any) => {
      assetsIdsSelected = [...assetsIdsSelected, ...assets.map(({ id }: any) => id)];
    });

    dispatch({
      type: 'newSupplyReceipt/updateSupplyReceipt',
      payload: {
        supplyReceipt,
        assets: assetsIdsSelected,
        serviceId,
        setStatus,
        onSaveCallback,
      },
    });
  };

  // eslint-disable-next-line
  const autoSaveForm = ({ supplyReceipt, productsSelected, showMsg }: any) => {
    let assetsIdsSelected: number[] = [];
    productsSelected.forEach(({ assets }: any) => {
      assetsIdsSelected = [...assetsIdsSelected, ...assets.map(({ id }: any) => id)];
    });

    const params: any = {
      supplyReceipt,
      assets: assetsIdsSelected,
      setStatus: supplyReceipt.status !== 'draft',
    };

    if (showMsg) {
      params.onSaveCallback = () =>
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('supply_receipts.saved'),
          },
        });
    }

    dispatch({ type: 'newSupplyReceipt/updateSupplyReceipt', payload: params });
  };

  const updateForm = (payload: any) => {
    dispatch({ type: 'newSupplyReceipt/setSupplyReceipt', payload });
    autoSaveForm({ supplyReceipt: payload, productsSelected, showMsg: false });
  };

  const handleSignature = (forceSignature: boolean) => {
    if (supplyReceipt.status === 'pending') {
      dispatch({
        type: 'newSupplyReceipt/setSupplyReceipt',
        payload: { ...supplyReceipt, forceSignature },
      });
    } else {
      updateForm({ ...supplyReceipt, forceSignature });
    }
  };

  const onChangeToWarehouse = (event: React.ChangeEvent<{ value: number | unknown }>) => {
    const itHasPractitioner = !!warehouses.find(({ id }: any) => id === event.target.value)?.practitionerId;
    setHasPractitioner(itHasPractitioner);

    dispatch({
      type: 'newSupplyReceipt/setToWarehouse',
      payload: {
        ...supplyReceipt,
        toWarehouseId: event.target.value,
        forceSignature: itHasPractitioner ? supplyReceipt.forceSignature : false,
      },
    });

    if (supplyReceipt.forceSignature && itHasPractitioner) {
      autoSaveForm({
        supplyReceipt: {
          ...supplyReceipt,
          toWarehouseId: event.target.value,
          forceSignature: itHasPractitioner ? supplyReceipt.forceSignature : false,
        },
        productsSelected,
        showMsg: false,
      });
    }
    focusScan();
  };

  const saveInternalNote = useCallback(
    debounce((payload: any) => updateForm(payload), 2000),
    []
  );

  const onChangeInternalNote = (event: any) => {
    setInternalNote(event.target.value);
    saveInternalNote({ ...supplyReceipt, internalNote: event.target.value });
  };

  const onChangeFromWarehouse = (event: React.ChangeEvent<{ value: number | unknown }>) => {
    updateForm({ ...supplyReceipt, fromWarehouseId: event.target.value });
    focusScan();
  };

  const Breadcrumb = (
    <Box mb="17px">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Inventory" />
        <BreadcrumbText text="Supply receipts" linkTo="/administrator/supply-receipts" />
        <BreadcrumbText text="New supply receipts" isActive />
      </BreadcrumbsContainer>
    </Box>
  );

  const TitleButton = (
    <Box padding={2}>
      <Box display="flex" alignItems="start" justifyContent="space-between">
        <Box>
          <Typography className={classes.title}>Create a Supply Receipt</Typography>
        </Box>
      </Box>
    </Box>
  );

  const TableData = isLoading ? (
    <MultipleSkeleton addPosition={false} />
  ) : (
    <Box paddingX={2}>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Box mb={3}>
            <form>
              <Box mb={1}>
                <FormControl size="small" className={classes.formControl} variant="outlined">
                  <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>From Warehouse</InputLabel>
                  <SelectCustom
                    data-cy="from"
                    name="from_warehouse"
                    value={supplyReceipt.fromWarehouseId}
                    disabled={editionDisabled || productsSelected.length}
                    onChange={onChangeFromWarehouse}
                    label="From Warehouse"
                  >
                    <MenuItem value={undefined}>
                      <em>None</em>
                    </MenuItem>
                    {warehouses.map(({ id, providerLabeling, active }: any) => (
                      <MenuItem data-cy="fromWarehouse" key={id} value={id}>
                        <Typography className={classnames({ [classes.inactive]: !active })}>
                          {providerLabeling}
                        </Typography>
                      </MenuItem>
                    ))}
                  </SelectCustom>
                </FormControl>
              </Box>
              <Box mb={1}>
                <FormControl size="small" className={classes.formControl} variant="outlined">
                  <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>To Warehouse</InputLabel>
                  <SelectCustom
                    data-cy="to"
                    name="to_warehouse"
                    value={supplyReceipt.toWarehouseId}
                    disabled={editionDisabled || productsSelected.length}
                    onChange={onChangeToWarehouse}
                    label="To Warehouse"
                  >
                    <MenuItem value={undefined}>
                      <em>None</em>
                    </MenuItem>
                    {warehouses
                      .filter(({ active }: any) => active)
                      .map(({ id, providerLabeling }: any) => (
                        <MenuItem data-cy="toWarehouse" key={id} value={id}>
                          {providerLabeling}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                </FormControl>
              </Box>
              <Box mb={1}>
                <FormControl size="small" className={classes.formControl} variant="outlined">
                  <TextField
                    data-cy="internalNote"
                    name="internalNote"
                    variant="outlined"
                    size="small"
                    type="text"
                    value={internalNote}
                    disabled={editionDisabled}
                    onChange={onChangeInternalNote}
                    label="Internal Note"
                  />
                </FormControl>
              </Box>
            </form>
          </Box>
          <ProductList onSaveChanges={onSaveChanges} />
        </Grid>
        <Grid item xs={5}>
          <Box>
            <QRCode />
          </Box>
          <Box
            component="p"
            fontFamily="Messina Sans SemiBold"
            fontSize="16px"
            color="rgba(0, 0, 0, 0.87)"
            style={{ opacity: '0.70' }}
          >
            Scan Instructions
          </Box>
          <Box component="p" fontSize="14px" color="#000" lineHeight="23px" fontFamily="Messina Sans Regular">
            Scan every product you are going to supply to a practitioner with your scanner.
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box width="100%">
      {Breadcrumb}
      {TitleButton}
      {TableData}
      <Box paddingX={2} style={{ padding: '12px 21px 11px 16px' }}>
        {!editionDisabled && !isLoading && hasAccessTo(CREATE_SUPPLY_RECEIPT, permissions) && (
          <ProductScanner scannerInputRef={scannerInputRef} />
        )}
      </Box>
      {!isLoading && hasAccessTo(CREATE_SUPPLY_RECEIPT, permissions) && (
        <SupplyReceiptFooter
          hasPractitioner={hasPractitioner}
          editionDisabled={editionDisabled}
          handleSignature={handleSignature}
          onSaveChanges={onSaveChanges}
          autoSaveForm={autoSaveForm}
        />
      )}
    </Box>
  );
};

export default NewSupplyReceiptsPageStep1;
