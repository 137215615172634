export const downloadHTMLStringAsPdf = ({ content }: any) => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
  if (iframeDocument) {
    iframeDocument.open();
    iframeDocument.write(content);
    iframeDocument.close();
    iframe.contentWindow?.print();
  }
};
