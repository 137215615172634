import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#e3e3e3',
    padding: '12px',
    paddingBottom: '2px',
  },
  stepsSection: {
    padding: '0 7px 0 23px',
    backgroundColor: '#e3e3e3',
  },
  stepper: {
    backgroundColor: 'transparent',
    padding: '0',
  },
  stepRoot: {
    paddingTop: '17px',
    '& svg': {
      fontSize: '33px',
    },
  },
  stepIncompleteWithAlert: {
    '& ::before': {
      content: '"!"',
      borderRadius: '50%',
      backgroundColor: 'red',
      width: '17px',
      height: '18px',
      textAlign: 'center',
      color: 'white',
      fontWeight: 'bold',
      position: 'relative',
      zIndex: '1',
      top: '-5px',
      right: '1px',
    },
    '& svg': {
      color: 'white !important',
      borderRadius: '50%',
      position: 'relative',
      left: '-15px',
      '& text': {
        fill: '#12574d',
      },
    },
  },
  stepActive: {
    '& svg': {
      color: 'white !important',
      border: '2px solid #12574d',
      borderRadius: '50%',
      '& text': {
        fill: '#12574d',
        color: 'white',
      },
    },
  },
  stepContent: {
    paddingRight: '0',
    marginLeft: '16px',
    marginTop: '14px',
    borderLeft: '1px solid #979797',
  },
  lastStep: {
    borderLeft: '1px solid transparent',
  },
  stepFinish: {
    '& svg': {
      color: 'white !important',
      border: '2px solid #12574d',
      borderRadius: '50%',
      position: 'relative',
      top: '25px',
      '& text': {
        fill: '#12574d',
        color: 'white',
      },
    },
  },
  signaturesBox: {
    marginLeft: '26px',
    paddingLeft: '20px',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  cardContent: {
    width: '100%',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
}));
