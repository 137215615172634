import React from 'react';
import { IconButton, Modal, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import ImageEditor from '../common/ImageEdit';
import HistoryImageList from '../common/HistoryImageList';
import { Button } from '../common/Button';

const setButtonServiceStyles = (color: string) => ({
  height: '44px',
  color: '#fff',
  fontSize: '15px',
  padding: '1.5rem',
  marginRight: '8px',
  marginBottom: '8px',
  backgroundColor: color || '#e3e3e3',
});

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    height: '100%',
  },
  innerContainer: {
    width: '483px',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    height: '81px',
    paddingLeft: '38px',
    paddingRight: '38px',
    marginBottom: '14px',
  },
  fullName: {
    margin: '0 auto',
    paddingRight: '50px',
    fontFamily: 'Messina Sans Regular',
    fontSize: '20px',
  },
  imageContainer: {
    marginBottom: '6px',
  },
  servicesContainer: {
    marginBottom: '18px',
  },
  button: {
    backgroundColor: '#12574d',
    color: 'white',
    width: '150px',
    height: '47px',
    marginRight: 0,
  },
}));

const FullChartingImageModal = (props: any) => {
  const { open, handleClose, image, color, photoList, onDrawPath, services, onServiceSelect, photos } = props;
  const { setImage, deletedAnnotated, listAnnotationItems, undo, performingUndo } = props;
  const classes = useStyles();
  const { firstName, lastName } = useSelector(({ patient }: any) => patient);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.container}>
          <div className={classes.header}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <h1 className={classes.fullName}>
              {firstName} {lastName}
            </h1>
          </div>
          <div className={classes.innerContainer}>
            <div className={classes.imageContainer}>
              {photoList.map((item: any) => (
                <div key={item.photo_id} style={{ display: image.photo_id === item.photo_id ? 'block' : 'none' }}>
                  <ImageEditor
                    canvasHeight={650}
                    canvasWidth={483}
                    image={image}
                    imageUrl={image.url}
                    brushColor={color}
                    getPaths={onDrawPath}
                    photoServicesList={listAnnotationItems}
                    onUndo={undo}
                    deletedAnnotated={deletedAnnotated}
                    performingUndo={performingUndo}
                  />
                </div>
              ))}
            </div>
            <div className={classes.servicesContainer}>
              {services.map((service: any, index: number) => (
                <Button
                  title={service.name}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onClick={() => onServiceSelect(service)}
                  disabled={image.photo_id === ''}
                  style={{
                    ...setButtonServiceStyles(service.color),
                    cursor: !image.photo_id ? 'no-drop' : 'copy',
                  }}
                />
              ))}
            </div>
            <HistoryImageList handlerSelectPhoto={setImage} image={image} photos={photos} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FullChartingImageModal;
