import { Box, Button, InputAdornment, InputLabel, TextField, Typography } from '@material-ui/core';
import React, { ChangeEvent, MouseEvent, ReactElement, useState } from 'react';
import { useUpdateInventoryBudget } from 'src/hooks/queries/useInventoryBudget';
import { round } from 'lodash';
import { useStyle } from './productUtilization.styles';

interface EditOrderingBudgetParams {
  practitionerId: number;
  orderingBudget: number;
  toggleDialog?: () => void;
}

const EditOrderingBudget: React.FC<EditOrderingBudgetParams> = ({
  practitionerId,
  orderingBudget,
  toggleDialog,
}: EditOrderingBudgetParams): ReactElement => {
  const [userInput, setUserInput] = useState<number | undefined>();
  const { mutateAsync } = useUpdateInventoryBudget(practitionerId);

  const classes = useStyle();

  const displayUserInput = (): string => `${round(!userInput && userInput !== 0 ? orderingBudget : userInput, 2)}`;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => setUserInput(+event.currentTarget?.value);

  const handleInputSave = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    if (userInput) {
      await mutateAsync(+userInput);
    }
    toggleDialog?.();
  };

  return (
    <Box className={classes.editOrderingBudget} data-testid="editOrderingBudget">
      <InputLabel htmlFor="orderingBudget">Ordering Budget</InputLabel>
      <TextField
        data-testid="editOrderingBudget.input"
        name="orderingBudget"
        id="orderingBudget"
        value={displayUserInput()}
        onChange={handleInputChange}
        type="number"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography>$</Typography>
            </InputAdornment>
          ),
        }}
      />
      <Box>
        <Button data-testid="editOrderingBudget.cancel" onClick={toggleDialog}>
          Cancel
        </Button>
        <Button
          data-testid="editOrderingBudget.save"
          disabled={!userInput && userInput !== 0}
          onClick={handleInputSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditOrderingBudget;
