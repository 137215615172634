/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import { ViewState, SchedulerDateTime } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  Appointments,
  Resources,
  WeekView,
  MonthView,
  Toolbar,
  AppointmentTooltip,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useAvailabilityOverride } from '../../../hooks/acuity/useAcuityAccount';
import { useStyles } from './styles';
import { PATIENT_SCHEDULER_STATUSES } from '../../../constants/scheduler.constants';
import { getViewStringType } from '../../../utils/scheduler.utils';
import { dispatch } from '../../../rematch';
import AvailabilityToolbar from './AvailabilityToolbar';
import AvailabilityOverrideBox from './AvailabilityOverrideBox';
import AvailabilityCard from './AvailabilityCard';

const resources = [
  {
    fieldName: 'statusId',
    title: 'Status',
    instances: PATIENT_SCHEDULER_STATUSES,
  },
];

const schedulerHeaderHeight = 28; // static value
const schedulerHeight = 750; // value can be calculated aftrer first render

const TimeTableCell = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MonthView.TimeTableCell {...props} style={{ height: `${(schedulerHeight - schedulerHeaderHeight) / 6}px` }} />
);

const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

interface Props {
  availability: any;
  locationId?: number;
}
const AvailabilityOverride = ({ availability, locationId }: Props) => {
  const useQuery = () => new URLSearchParams(window.location.search);
  const query = useQuery();
  const practitionerId = query.get('practitionerId') || undefined;
  const classes = useStyles();
  const { isFetching, data } = useAvailabilityOverride({ practitionerId, locationId });

  const [schedulerData, setSchedulerData] = useState([]);
  const [view, setView] = useState('Month');
  const [currentDate, setCurrentDate] = React.useState<SchedulerDateTime>(moment().format(dateFormat));
  const handleChangeView = (value: string) => {
    setView(value);
  };

  const handleNavigatorChange = (action: string) => {
    dispatch.schedulers.setIsLoading(true);
    const date = moment(currentDate);
    const momentView = getViewStringType(view);

    switch (action) {
      case 'back':
        setCurrentDate(date.subtract(1, momentView).format(dateFormat));
        break;
      case 'today':
        setCurrentDate(moment().format(dateFormat));
        break;
      case 'forward':
        setCurrentDate(date.add(1, momentView).format(dateFormat));
        break;
      default:
        break;
    }
    dispatch.schedulers.setIsLoading(false);
  };

  useEffect(() => {
    const days: any = [];

    if (!data?.data?.length) {
      return;
    }

    data.data.forEach((item: any) => {
      let startDate = moment(item.start);
      const endDate = moment(item.end);
      const diffInDays = endDate.diff(startDate, 'days', false);

      for (let index = 0; index <= diffInDays; index++) {
        let hours: string[] = [];
        Object.keys(item.hours).forEach((key) => {
          hours = hours.concat(item.hours[key]);
        });
        hours = [...new Set(hours)];
        if (hours.length === 0) {
          days.push({
            startDate: startDate.startOf('day').format(dateTimeFormat),
            endDate: startDate.endOf('day').format(dateTimeFormat), // Start date for box purposes
            title: 'Time off',
          });
        }

        hours.forEach((hourRange: string) => {
          const hourStart = hourRange.split('-')[0].split('a').join(' a').split('p').join(' p');
          const hourEnd = hourRange.split('-')[1]?.split('a').join(' a').split('p').join(' p');

          if (hourRange && hourEnd) {
            days.push({
              startDate: moment(`${startDate.format(dateFormat)} ${hourStart}`).format(dateTimeFormat),
              // Start date for box purposes:
              endDate: moment(`${startDate.format(dateFormat)} ${hourEnd}`).format(dateTimeFormat),
              title: 'Working day',
            });
          }
        });

        startDate = startDate.add(1, 'day');
      }
    });
    setSchedulerData(days);
  }, [data]);

  return (
    <Grid item xs>
      <Grid item>
        <h2>Override hours for specific days</h2>
      </Grid>
      <Box paddingX={2} className={classes.form}>
        <h3>Set hours of availability</h3>

        <Grid item className={classes.formItem}>
          <div id="calendar">
            <Scheduler data={schedulerData}>
              <ViewState currentDate={currentDate} currentViewName={view} />
              <WeekView startDayHour={3} endDayHour={24} />
              <DayView startDayHour={3} endDayHour={24} />
              <MonthView timeTableCellComponent={TimeTableCell} />
              <Toolbar
                rootComponent={() =>
                  AvailabilityToolbar({
                    isLoading: isFetching,
                    currentDate,
                    view,
                    handleChangeView,
                    handleNavigatorChange,
                  })
                }
              />
              <Appointments appointmentContentComponent={AvailabilityOverrideBox} />
              <Resources data={resources} />
              {!isFetching && (
                <AppointmentTooltip
                  showOpenButton
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  contentComponent={(props: any) => <AvailabilityCard {...props} availability={availability} />}
                  headerComponent={() => <></>}
                />
              )}
            </Scheduler>
          </div>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AvailabilityOverride;
