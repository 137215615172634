import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useStyles } from './tenderSection.styles';

interface Props {
  careCreditVoucher: string;
  setCareCreditVoucher: (value: string) => void;
  careCreditValue: string;
  setCareCreditValue: (value: string) => void;
  isTotalTenderValueValid: (value: NumberFormatValues, tenderValue: string) => boolean;
  enableCareCreditDiscount: boolean;
}

export const CareCreditTender = (props: Props) => {
  const {
    careCreditVoucher,
    setCareCreditVoucher,
    careCreditValue,
    setCareCreditValue,
    isTotalTenderValueValid,
    enableCareCreditDiscount,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.title}>CareCredit</div>
      <input
        type="text"
        value={careCreditVoucher}
        onChange={(e) => setCareCreditVoucher(e.target.value)}
        placeholder="Code/Note"
        className={classes.input}
        style={{ marginRight: '20px' }}
        disabled={!enableCareCreditDiscount}
      />
      <NumberFormat
        decimalScale={2}
        fixedDecimalScale
        autoComplete="off"
        placeholder="Amount"
        value={careCreditValue}
        onChange={(e) => {
          setCareCreditValue(e.target.value);
        }}
        isAllowed={(inputObj) => isTotalTenderValueValid(inputObj, careCreditValue)}
        className={classes.input}
        type="tel"
        disabled={!enableCareCreditDiscount}
      />
    </div>
  );
};
