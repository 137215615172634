import React from 'react';
import { Box, ButtonBase, Modal, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { EHRTypography } from '../ui/v1';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#e7eeed',
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '100px',
    padding: '10px',
    borderBottom: '1px solid #e0e0e0',
    position: 'relative',
    textAlign: 'center',
  },
  headerContainer: {
    width: '50%',
    margin: 'auto',
  },
  closeIcon: {
    transform: 'scale(1.5)',
  },
  closeIconContainer: {
    position: 'absolute',
    left: '10px',
    width: '10%',
  },
});

interface FullPageModalProps {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  headerText?: string;
}

const FullPageModal = ({ isOpen, handleClose, children, headerText }: FullPageModalProps) => {
  const classes = useStyles();

  return (
    <Modal open={isOpen}>
      <Box className={classes.container}>
        <Box className={classes.modalHeader}>
          <Box className={classes.closeIconContainer}>
            <ButtonBase onClick={handleClose}>
              <CloseIcon className={classes.closeIcon} />
            </ButtonBase>
          </Box>
          <Box className={classes.headerContainer}>
            <EHRTypography variant="h6" dataCy="full-page-modal-header">
              {headerText}
            </EHRTypography>
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Modal>
  );
};

export default FullPageModal;
