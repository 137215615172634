import React, { FC, useEffect, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Page from 'src/components/common/Page';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import UploadImage from '../../common/UploadImage';
import { useStyles } from './Physician.styles';
import { Physicians } from '../../../services/Physicians';
import compile from '../../../utils/toastMessagesCompiler';
import { dispatch } from '../../../rematch';
import { usePhysicianInfo, PHYSICIANS } from '../../../hooks/queries/usePhysicians';
import { IPhysicianPost } from '../../../interfaces/IPhysician';
import { schemaForm, schemaFormEdit } from './schemaForm';
import { useMedicalMembers } from '../../../hooks/queries/useMedicalMembers';
import { useStates } from '../../../hooks/queries/useStates';
import { ADMIN_PHYSICIANS_LIST_PATH } from '../../../routes/administratorRoutes';
import { usePractitioners } from '../../../hooks/queries/usePractitioners';

const SUCCESS_PHYSICIAN = compile('generic.success_message', {
  action: 'added',
  element: 'Physician',
  duration: 3000,
});
const SUCCESS_PHYSICIAN_EDIT = compile('generic.success_message', {
  action: 'edited',
  element: 'Physician',
  duration: 3000,
});
const ERROR_PHYSICIAN = compile('generic.error_message', {
  action: 'customizing',
  element: 'physician',
});

const ERROR_GETTING_PHYSICIAN = compile('generic.error_message', {
  action: 'getting',
  element: 'physician info',
});

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  workingStates: string[];
  professionalName: string;
  medicalMemberId: number | string;
  password?: string;
  signatureImage?: string | ArrayBuffer | null;
  inactive: boolean;
  canViewAllCustomers: boolean;
  assignedProviderIds: string[];
}

const NewPhysician: FC = () => {
  const history = useHistory();
  const { physicianId }: any = useParams();
  const queryClient = useQueryClient();
  const classes = useStyles();

  const mutationCreate = useMutation(Physicians.createPhysician);
  const mutationEdit = useMutation(Physicians.editPhysician);
  const loadingCreate = mutationCreate.isLoading;
  const loadingEdit = mutationEdit.isLoading;
  const { data: practitioners = [], isLoading } = usePractitioners();

  const editing: boolean = typeof physicianId !== 'undefined';

  const buttonTitle = editing ? 'SAVE' : 'ADD';
  const [advancedPractitioner, setAdvancedPractitioner] = useState<boolean>(false);
  const { physicianInfo, isFetched, isError } = usePhysicianInfo(physicianId);
  const { data: medicalMembers } = useMedicalMembers();
  const { data: states = [] } = useStates();

  const { register, control, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(editing ? schemaFormEdit : schemaForm),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      workingStates: [],
      password: '',
      professionalName: '',
      medicalMemberId: '',
      signatureImage: null,
      inactive: false,
      canViewAllCustomers: false,
      assignedProviderIds: [],
    },
  });

  const watchWorkingStates = watch(['workingStates']);
  const watchAssignedProviderIds = watch(['assignedProviderIds']);

  if (editing && isError && !isFetched) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: ERROR_GETTING_PHYSICIAN,
        type: 'error',
        duration: 2500,
      },
    });
  }

  useEffect(() => {
    if (editing && isFetched) {
      // eslint-disable-next-line max-len
      const {
        identity,
        workingStates,
        professionalName,
        medicalMemberId,
        isAdvancedPractitioner,
        inactive,
        canViewAllCustomers,
        assignedProviderIds,
      } = physicianInfo;
      setValue('firstName', identity.firstName);
      setValue('lastName', identity.lastName);
      setValue('email', identity.email);
      setValue('workingStates', !workingStates ? [] : workingStates);
      setValue('professionalName', professionalName);
      setValue('medicalMemberId', medicalMemberId);
      setAdvancedPractitioner(isAdvancedPractitioner);
      setValue('inactive', inactive);
      setValue('canViewAllCustomers', canViewAllCustomers);
      setValue(
        'assignedProviderIds',
        assignedProviderIds.map((id: number) => id.toString())
      );
    }
  }, [physicianInfo]);

  const createAction = async (dataForm: FormData) => {
    const dataToCreate = {
      firstName: dataForm.firstName.trim(),
      lastName: dataForm.lastName.trim(),
      email: dataForm.email.trim(),
      workingStates: dataForm.workingStates.join(','),
      professionalName: dataForm.professionalName,
      password: dataForm.password?.trim(),
      medicalMemberId: dataForm.medicalMemberId,
      signatureImage: dataForm.signatureImage,
      canViewAllCustomers: dataForm.canViewAllCustomers,
      assignedProviderIds: dataForm.assignedProviderIds,
    };
    try {
      await mutationCreate.mutateAsync(dataToCreate);
      queryClient.invalidateQueries([PHYSICIANS, physicianId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_PHYSICIAN,
          type: 'success',
          duration: 2500,
        },
      });
      history.push(ADMIN_PHYSICIANS_LIST_PATH);
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_PHYSICIAN,
          type: 'error',
          duration: 2500,
        },
      });
    }
  };

  const editAction = async (dataForm: FormData) => {
    const dataToEdit = {
      id: physicianId,
      firstName: dataForm.firstName.trim(),
      lastName: dataForm.lastName.trim(),
      email: dataForm.email.trim(),
      workingStates: dataForm.workingStates.join(','),
      professionalName: dataForm.professionalName,
      medicalMemberId: dataForm.medicalMemberId,
      signatureImage: dataForm.signatureImage,
      inactive: dataForm.inactive,
      canViewAllCustomers: dataForm.canViewAllCustomers,
      assignedProviderIds: dataForm.assignedProviderIds,
    };

    const data: IPhysicianPost = {
      ...dataToEdit,
    };

    try {
      await mutationEdit.mutateAsync(data);
      queryClient.invalidateQueries([PHYSICIANS, physicianId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_PHYSICIAN_EDIT,
          type: 'success',
          duration: 2500,
        },
      });
      history.push(ADMIN_PHYSICIANS_LIST_PATH);
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'editing',
            element: `physician - ${error}`,
          }),
          type: 'error',
          duration: 2500,
        },
      });
    }
  };

  const onSubmit = (data: any) => {
    if (!data.workingStates || data.workingStates.length === 0) {
      // eslint-disable-next-line no-param-reassign
      data.workingStates = states.map((state) => state.name);
    }
    if (editing) {
      editAction(data);
    } else {
      createAction(data);
    }
  };

  const handleAdvancedPractitioner = () => {
    setAdvancedPractitioner(!advancedPractitioner);
    setValue('medicalMemberId', '');
    setValue('canViewAllCustomers', advancedPractitioner);
  };

  const handleInactivePhysician = (e: any) => {
    setValue('inactive', e.target.checked);
  };

  useEffect(() => {
    if (!advancedPractitioner) {
      setValue('canViewAllCustomers', true);
    }
  }, [advancedPractitioner, setValue]);

  const MyBreadcrumb = (
    <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BreadcrumbsContainer>
          <BreadcrumbText text="Physicians" linkTo="/administrator/physicians" />
          <BreadcrumbText text="New physician" isActive />
        </BreadcrumbsContainer>
      </Box>
    </Box>
  );

  return (
    <Page titleComponent={MyBreadcrumb}>
      <Box width="100%" display="flex" height="100%" flexDirection="column">
        <Box paddingX={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Box>
              <Typography data-cy="title" className={classes.title}>
                {editing ? 'Edit' : 'New'} physician
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={4}>
              <TextField
                data-cy="firstNameInput"
                fullWidth
                name="firstName"
                error={!!errors.firstName}
                helperText={errors.firstName?.message || ''}
                onChange={(event) => setValue('firstName', event.target.value)}
                inputRef={register}
                label="First name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                data-cy="lastNameInput"
                fullWidth
                name="lastName"
                error={!!errors.lastName}
                helperText={errors.lastName?.message || ''}
                onChange={(event) => setValue('lastName', event.target.value)}
                inputRef={register}
                label="Last name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                data-cy="emailInput"
                fullWidth
                name="email"
                error={!!errors.email}
                helperText={errors.email?.message || ''}
                onChange={(event) => setValue('email', event.target.value)}
                label="Email"
                type="email"
                inputRef={register}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={editing ? 6 : 4}>
              <TextField
                data-cy="professionalNameInput"
                fullWidth
                name="professionalName"
                inputRef={register}
                error={!!errors.professionalName}
                helperText={errors.professionalName?.message || ''}
                onChange={(event) => setValue('professionalName', event.target.value)}
                label="Professional Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={editing ? 6 : 4}>
              <Autocomplete
                data-cy="states"
                onChange={(e, selectedOptions) => setValue('workingStates', selectedOptions)}
                limitTags={editing ? 2 : 1}
                multiple
                disableCloseOnSelect
                filterSelectedOptions
                options={states.map((o) => o.name)}
                getOptionLabel={(option) => option}
                value={watchWorkingStates.workingStates}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    label="Working States"
                    placeholder="California, Texas"
                  />
                )}
              />
            </Grid>
            {!isLoading && (
              <Grid item xs={editing ? 6 : 4}>
                <Autocomplete
                  data-cy="assignedProviderIds"
                  onChange={(e, selectedOptions) => setValue('assignedProviderIds', selectedOptions)}
                  limitTags={editing ? 2 : 1}
                  multiple
                  disableCloseOnSelect
                  filterSelectedOptions
                  options={practitioners.map((practitioner) => practitioner.id.toString())}
                  getOptionLabel={(option) => {
                    const provider = practitioners.find((practitioner) => practitioner.id.toString() === option);
                    return `${provider?.firstName} ${provider?.lastName}`;
                  }}
                  value={watchAssignedProviderIds.assignedProviderIds}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      variant="outlined"
                      label="Assigned Providers"
                      placeholder=""
                    />
                  )}
                />
              </Grid>
            )}
            {!editing && (
              <Grid item xs={editing ? 6 : 4}>
                <TextField
                  data-cy="passwordInput"
                  fullWidth
                  name="password"
                  autoComplete="new-password"
                  onChange={(event) => setValue('password', event.target.value)}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  label="Password"
                  type="password"
                  variant="outlined"
                  inputRef={register}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}
            <Grid data-cy="signatureImage" item xs={4}>
              <UploadImage
                imageUrl={physicianInfo?.signatureImageUrl}
                valueToSet="signatureImage"
                label="signature"
                setValue={setValue}
                isFetched={editing ? isFetched : true}
                errors={errors}
              />
            </Grid>
            <Grid item xs className={classes.medicalMembers}>
              <FormControlLabel
                label="Advanced Practitioner"
                control={
                  <Checkbox
                    data-cy="advancedPract"
                    color="default"
                    checked={advancedPractitioner}
                    className={classes.checkbox}
                    onChange={handleAdvancedPractitioner}
                  />
                }
              />
              <FormControl data-cy="medicalMember" size="medium" variant="outlined" className={classes.formControl}>
                <InputLabel
                  htmlFor="medical-member-select"
                  error={!!errors.medicalMemberId}
                  className={classes.formInput}
                >
                  Medical member type
                </InputLabel>
                <Controller
                  control={control}
                  name="medicalMemberId"
                  error={!!errors.medicalMemberId}
                  as={
                    <Select id="medical-member-select" labelWidth={150}>
                      {medicalMembers &&
                        medicalMembers.map(
                          ({ id, name, isAdvancedPractitioner }) =>
                            isAdvancedPractitioner === advancedPractitioner && (
                              <MenuItem data-cy="member" key={id} value={id}>
                                {name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  }
                />
                {errors.medicalMemberId && <FormHelperText error>{errors.medicalMemberId.message}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4} style={{ alignSelf: 'end' }}>
              <FormControlLabel
                label="Can view all patients"
                control={
                  <Controller
                    control={control}
                    inputRef={register}
                    name="canViewAllCustomers"
                    render={({ value }: any) => (
                      <Checkbox
                        data-cy="canViewAllCustomers"
                        color="default"
                        className={classes.checkbox}
                        checked={value}
                        onChange={(event) => setValue('canViewAllCustomers', event.target.checked)}
                      />
                    )}
                  />
                }
              />
              <FormControlLabel
                label="Inactive"
                control={
                  <Controller
                    control={control}
                    inputRef={register}
                    name="inactive"
                    render={({ value }: any) => (
                      <Checkbox
                        data-cy="inactivePhysician"
                        color="default"
                        className={classes.checkbox}
                        checked={value}
                        onChange={handleInactivePhysician}
                      />
                    )}
                  />
                }
              />
              <Button
                data-cy="submitBtn"
                disabled={loadingCreate || loadingEdit}
                variant="contained"
                className={classes.add}
                color="primary"
                type="submit"
              >
                {loadingCreate || loadingEdit ? <CircularProgress size={25} /> : buttonTitle}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default NewPhysician;
