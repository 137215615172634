import { withStyles, Button } from '@material-ui/core';

const ButtonBrush = withStyles({
  root: {
    color: '#000',
    padding: 0,
    height: '28px',
    backgroundColor: '#fff',
    fontSize: '13px',
    borderRadius: '21px',
    fontFamily: 'Roboto',
    marginRight: '18px',
    pointerEvents: 'auto',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
})(Button);

export default ButtonBrush;
