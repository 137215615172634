import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import Banner from 'src/components/common/Banner';
import { EHRButton, EHRTextField, EHRTypography } from 'src/components/ui/v1';
import * as yup from 'yup';
import { ExternalMedicalDirector } from 'src/interfaces/onboarding.interface';

export interface MedicalDirectorFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}
const BANNER_CONTENT = `Our Internal Operations team will follow up 
directly with your Medical Director.
Once the agreement is executed, we will let you know.`;
interface MedicalDirectorFormProps {
  onComplete?: (data: ExternalMedicalDirector) => void;
  formData?: ExternalMedicalDirector;
  disabled?: boolean;
  hideBanner?: boolean;
  hideHeader?: boolean;
}

const mdFormSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup
    .string()
    .matches(/^\+?1?[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, 'Phone number must be a valid format')
    .required('Phone number is required'),
  medicalDirectorAgreementPdf: yup.string().nullable(),
});

const MedicalDirectorForm = ({
  onComplete,
  formData,
  disabled = false,
  hideBanner = false,
  hideHeader = false,
}: MedicalDirectorFormProps) => {
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };
  const { control, handleSubmit, errors, setValue } = useForm<ExternalMedicalDirector>({
    resolver: yupResolver(mdFormSchema),
    defaultValues,
  });

  const onSubmit = (data: ExternalMedicalDirector) => {
    onComplete?.(data);
  };

  useEffect(() => {
    if (formData) {
      const fields = Object.keys(defaultValues) as (keyof ExternalMedicalDirector)[];
      fields.forEach((field) => {
        setValue(field, formData[field]);
      });
    }
  }, [formData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {!hideHeader && (
          <Grid item xs={12}>
            <EHRTypography dataCy="" variant="body2">
              Please add your Medical Director&apos;s information
            </EHRTypography>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="firstName"
            render={({ onChange, value }) => (
              <EHRTextField
                error={!!errors?.firstName?.message}
                helperText={errors?.firstName?.message}
                label="First Name"
                fullWidth
                name="firstName"
                value={value}
                onChange={onChange}
                dataCy=""
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="lastName"
            render={({ onChange, value }) => (
              <EHRTextField
                error={!!errors?.lastName?.message}
                helperText={errors?.lastName?.message}
                label="Last Name"
                fullWidth
                name="lastName"
                value={value}
                onChange={onChange}
                dataCy=""
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="email"
            render={({ onChange, value }) => (
              <EHRTextField
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
                label="Email"
                fullWidth
                name="email"
                value={value}
                onChange={onChange}
                dataCy=""
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="phone"
            render={({ onChange, value }) => (
              <EHRTextField
                label="Phone Number"
                fullWidth
                error={!!errors?.phone?.message}
                helperText={errors?.phone?.message}
                name="phone"
                value={value}
                onChange={onChange}
                dataCy=""
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <EHRButton dataCy="" text="Submit" color="primary" type="submit" disabled={disabled} fullWidth>
            Submit
          </EHRButton>
        </Grid>
        {!hideBanner && (
          <Grid item xs={12}>
            <Banner type="warning" content={BANNER_CONTENT} />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default MedicalDirectorForm;
