import React, { ChangeEventHandler } from 'react';
import { useStyle } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { TableCell, TableRow, TextField } from '@material-ui/core';
import { formatCurrency } from 'src/utils/formatNumber';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  testId: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  name: string;
  quantity: number;
  subtotal: number;
};

export const OrderApprovalSpecialsRow = ({
  disabled,
  isLoading,
  testId,
  onChange,
  name,
  quantity,
  subtotal,
}: Props) => {
  const classes = useStyle();
  return (
    <TableRow className={classes.pulseOnly} data-loading={isLoading} data-testid={`${testId}.row`}>
      <TableCell className={classes.approvalRowName} data-testid={`${testId}.name`}>
        {name}
      </TableCell>
      <TableCell>{formatCurrency(subtotal)}</TableCell>
      <TableCell>
        <TextField
          value={quantity}
          onChange={onChange}
          className={classes.qtyInput}
          disabled={disabled}
          data-testid={`${testId}.input`}
          type="number"
          inputProps={{ disableUnderline: disabled }}
        />
      </TableCell>

      <TableCell colSpan={2} />
    </TableRow>
  );
};

export default OrderApprovalSpecialsRow;
