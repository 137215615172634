import { Link, withStyles } from '@material-ui/core';

export const CustomLink = withStyles(() => ({
  root: {
    color: '#12574d',
    '&:hover': {
      color: '#12574d',
    },
  },
}))(Link);
