import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  listPhotos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'auto',
  },
  photoItem: {
    width: '80px',
    height: '80px',
    marginRight: '10px',
    cursor: 'pointer',
    objectFit: 'cover',
  },
  ListItemText: {
    whiteSpace: 'pre-line',
    display: 'block',
    overflowWrap: 'anywhere',
  },
}));
