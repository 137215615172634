import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '15px'
  },
  logoContainer: {
    width: '25%'
  },
  title: {
    width: '344px',
    marginRight: '15px'
  },
  sectionContainer: {
    width: '100%',
    height: '46px',
    fontSize: '15px'
  },
  pointsText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: '15px',
  },
  textRight: {
    textAlign: 'right',
    lineHeight: '47px'
  },
  loadingPoints: {
    color: 'black',
    marginLeft: '4px',
  },
  addIcon: {
    height: '26px',
    width: '26px',
    marginTop: '10px',
    paddingRight: '32px'
  },
  available: {
    fontSize: '95%',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
}));
