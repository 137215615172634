import React from 'react';
import { IconButton, Drawer, List } from '@material-ui/core';
import { Menu as MenuIcon, ArrowBackIos } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/rematch';
import { ReactComponent as PortraitLogo } from '../../assets/images/portrait-logo.svg';
import { MainListItems } from '../../pages/listItems';
import SidebarStyles from './Sidebar.styles';

const Sidebar = () => {
  const classes = SidebarStyles();
  const { sidebarOpened } = useSelector(({ page }: RootState) => page);

  const handleDrawerOpen = () => {
    dispatch.page.setSidebarOpened(true);
  };

  const handleDrawerClose = () => {
    dispatch.page.setSidebarOpened(false);
  };

  const classnamePaper = sidebarOpened ? classes.drawerPaper : classes.drawerPaperClose;
  const classnameButton = sidebarOpened ? classes.menuButtonHidden : classes.menuButton;

  return (
    <Drawer variant="permanent" className={classes.drawer} classes={{ paper: classnamePaper }} open={sidebarOpened}>
      <div>
        <div className={classes.toolbarIcon}>
          {sidebarOpened ? (
            <div className={classes.logoContainer}>
              <PortraitLogo className={classes.logo} />
            </div>
          ) : (
            <div className={classes.expandButtonContainer}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={classnameButton}
              >
                <MenuIcon className={classes.iconColor} />
              </IconButton>
            </div>
          )}
        </div>
        <List className={sidebarOpened ? classes.sidebarList : classes.sidebarListClosed}>
          <MainListItems />
        </List>
      </div>
      {sidebarOpened && (
        <div className={classes.closeNuttonContainer}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            className={classes.menuButton}
          >
            <ArrowBackIos className={classes.iconColor} style={{ transform: 'translateX(0.35rem)' }} />
          </IconButton>
        </div>
      )}
    </Drawer>
  );
};

export default Sidebar;
