import React from 'react';
import { useRowStyles, TableRowStriped } from './practitionerDashboard.styles';
import { TableCellCustom } from '../../../common/TableStyles/TableCellCustom';
import formatNumber, { formatCurrency } from '../../../../utils/formatNumber';

export interface DashboardTableProps {
  category: string;
  rate?: number;
  baseAmount?: number;
  amount?: number | React.ElementType;
  isTotal?: boolean;
  bold?: boolean;
  italicized?: boolean;
}

const EarningsDashboardTableRow = ({
  category,
  rate,
  baseAmount,
  amount,
  isTotal,
  bold,
  italicized,
}: DashboardTableProps) => {
  const classes = useRowStyles();

  return (
    <TableRowStriped className={`${classes.root} ${isTotal ? classes.backgroundRow : ''}`}>
      <TableCellCustom className={classes.dummyCols} />
      <TableCellCustom style={{ fontWeight: bold ? 'bold' : 'normal', fontStyle: italicized ? 'italic' : 'normal' }}>
        {category}
      </TableCellCustom>
      <TableCellCustom>{typeof rate === 'number' ? `${formatNumber(rate * 100)}%` : ''}</TableCellCustom>
      <TableCellCustom align="right">
        {typeof baseAmount === 'number' ? formatCurrency(baseAmount, { maximumFractionDigits: 2 }) : ''}
      </TableCellCustom>
      <TableCellCustom align="right">
        {typeof amount === 'number' ? formatCurrency(amount, { maximumFractionDigits: 2 }) : amount}
      </TableCellCustom>
      <TableCellCustom className={classes.dummyCols} />
    </TableRowStriped>
  );
};

export default EarningsDashboardTableRow;
