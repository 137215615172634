import { ICustomCareMessage } from '../interfaces/ICustomCareMessage';
import IServiceGroup from '../interfaces/IServiceGroup';
import Api from './Api';

const BASE_PATH = '/v3/care_messages';

const getServicesGroupsList = async (): Promise<IServiceGroup[]> => {
  const { serviceGroups } = await Api.get(`${BASE_PATH}/service_groups`);
  return serviceGroups;
};
const getCustomCareMessages = async (): Promise<ICustomCareMessage[]> => {
  const { careMessages, error } = await Api.get(`${BASE_PATH}/custom_messages`);
  if (error) {
    throw error;
  }

  return careMessages;
};

interface SaveParams {
  customCareMessage: ICustomCareMessage;
  copyTo: any[];
}

const saveCustomCareMessages = async ({
  customCareMessage,
  copyTo,
}: SaveParams): Promise<ICustomCareMessage | undefined> => {
  const { customMessageId, serviceGroupId, preCare, postCare, followUp } = customCareMessage;

  const method = customMessageId ? 'put' : 'post';

  const { editedMessage, error } = await Api[method](BASE_PATH, {
    customMessageId,
    serviceGroupId,
    preCare,
    postCare,
    followUp,
    copyTo,
  });

  if (error) {
    throw error;
  }

  return editedMessage;
};

const removeCustomCareMessages = async ({ id }: any): Promise<number | undefined> => {
  if (id === undefined || id === null) {
    throw new Error("Something has gone wrong we're missing the message id");
  }
  const { careMessage, error } = await Api.delete(`${BASE_PATH}/${id}`);
  if (error) {
    throw error;
  }
  return careMessage && careMessage.customMessageId;
};

export default {
  getServicesGroupsList,
  getCustomCareMessages,
  saveCustomCareMessages,
  removeCustomCareMessages,
};
