import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  note: {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.34)',
    marginBottom: '1rem',
    borderRadius: '10px !important',
  },
  noteInside: {
    border: '1px solid #bbb',
    boxShadow: 'none',
    marginBottom: 0,
    height: '100%',
  },
  title: {
    fontWeight: 'bold',
    width: '100%',
    display: 'inline-block',
    marginBottom: '15px',
  },
  content: {
    fontWeight: 300,
  },
  footer: {
    width: '100%',
    textAlign: 'right',
    marginTop: '10px',
  },
  primaryButton: {
    background: '#DFEAE2',
    padding: '1%',
    textTransform: 'capitalize',
    fontSize: '80%',
    '&:hover': {
      background: '#ACD2CC',
    },
  },
  secondaryButton: {
    background: '#FFFFFF',
    padding: '1%',
    fontSize: '80%',
    '&:hover': {
      background: '#F3F3F3',
    },
  },
  leftFooterText: {
    textAlign: 'left',
    display: 'flex',
    position: 'absolute',
  },
}));
