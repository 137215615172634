import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  operatingProviderCheckbox: {
    display: 'flex',
    height: '100% !important',
    padding: '0rem 0.75rem 0.75rem !important',
    '& .MuiFormControlLabel-label': {
      color: '#000 !important',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontWeight: 400,
    },
  },
  modalProductSelectModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  modalProductSelectContainer: {
    width: '440px',
    background: 'white',
    borderRadius: '8px',
  },
  modalProductSelectBody: {
    padding: '2rem',
  },
  productOption: {
    width: '100%',
    background: '#FBFBFB',
    borderRadius: '8px',
    border: '1px solid #E5E5E5',
    padding: '1rem',
    textTransform: 'none',
  },
  buttonContainer: {
    padding: '1rem 2rem 1rem',
    textAlign: 'right',
  },
  subTitle: {
    color: '#706F6F',
    marginBottom: '2rem',
    fontSize: '14px',
  },
  medspaInfoInput: {
    marginBottom: '1.375rem',
  },
  medspaActiveButton: {
    width: '47%',
    background: '#12574D',
    borderRadius: '4px',
    color: 'white',
    cursor: 'pointer',
    border: 'none',
    height: '48px',
    '&:hover': {
      background: '#12574D',
      opacity: '90%',
    },
  },
  medspaInactiveButton: {
    width: '47%',
    background: 'white',
    borderRadius: '4px',
    color: '#CFCFCF',
    cursor: 'pointer',
    border: '1px solid #CFCFCF',
    height: '48px',
  },
  medspaActiveStatusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  uploadProfileImageContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profileImage: {
    width: '75px',
    height: '75px',
    borderRadius: '50%',
    marginRight: '1rem',
  },
  operatingProviderContainer: {
    marginBottom: '1.375rem',
  },
  operatingProviderSubtext: {
    fontSize: '14px',
    opacity: 0.4,
    marginLeft: '32px',
    textWrap: 'pretty',
  },
  headShotContainer: {
    width: '100%',
    '&.ehrUploadContainerError': {
      borderColor: '#f44336',
      color: '#f44336',
    },
  },
  signatureImageContainer: {
    width: '100%',
    '&.ehrUploadContainerError': {
      borderColor: '#f44336',
      color: '#f44336',
    },
  },
  signatureImage: {
    height: '80px',
    width: '100%',
  },
  signatureImageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  signatureUpdate: {
    color: '#12574D',
    cursor: 'pointer',
    border: 'none',
    background: 'inherit',
    textTransform: 'none',
    '&:hover': {
      background: 'inherit',
    },
  },
}));
