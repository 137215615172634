import { TableHeaderSet } from '../interfaces/IClientLead';

export const DEFAULT_SORT_KEY = 'lastName';
export const DEFAULT_SEARCH_TERMS = '_';
export const MIN_SEARCH_CHARS = 2;
export const DEFAULT_ROW_COUNT = 10;

export const TABLE_LIST: TableHeaderSet = {
  client_summary: 'Client Overview',
  beauty_bank: 'Beauty Bank Members',
  advocates: 'Client Advocates',
  client_birthdays: 'Upcoming Client Birthdays',
  no_filler: 'Botox But No Filler',
  dropoff_3_months: '3 Month Dropoff',
  dropoff_6_months: '6 Month Dropoff',
};

export const TEST_COLUMNS = {
  mrn: 'MRN',
  firstName: 'First Name',
  lastName: 'Last Name',
  visitCount: 'Visits',
  lastVisit: 'Last Seen',
  lifetimeSpend: 'Lifetime Spend',
  avgSpend: 'Avg Spend',
  avgTox: 'Avg Tox Units',
  upcomingAppt: 'Next Appt',
  phone: 'Phone',
};

export const TEST_ROW = {
  count: 2,
  mrn: 1,
  firstName: 'Test',
  lastName: 'Test',
  visitCount: 1,
  lastVisit: '2023-01-02 15:40:15',
  lifetimeSpend: 100,
  avgSpend: 100,
  avgTox: 40,
  upcomingAppt: null,
  phone: '+15555555555',
  email: 'example@example.com',
  smsMarketingOptedIn: true,
};
