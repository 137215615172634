import { TableRow, TableCell, withStyles, makeStyles } from '@material-ui/core';

export const TableRowStriped = withStyles(() => ({
  root: {
    '&:nth-child(4n+1)': {
      backgroundColor: '#f2f5f5',
      maxHeight: '56px',
    },
  },
}))(TableRow);

export const TableCellCustom = withStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    height: '56px',
  },
}))(TableCell);

export const useStyle = makeStyles(() => ({
  ItemTable: {
    borderBottom: 'solid 2px #f2f5f5',
    borderTop: 'solid 2px #f2f5f5',
  },
  iconCross: {
    width: '50px !important',
    paddingRight: 0,
  },
  patientId: {
    width: '90px',
    padding: '5px',
  },
  patientName: {
    textTransform: 'capitalize',
  },
  expandedText: {
    paddingLeft: '50px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
}));
