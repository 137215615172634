import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { EHRButton } from 'src/components/ui/v1';
import { RootState } from 'src/rematch';
import { useSelector } from 'react-redux';
import { greenBackground } from '../index.styles';

export const useFooterStyles = makeStyles(() => ({
  container: {
    backgroundColor: greenBackground,
    display: 'flex',
    flex: 0.2,
    padding: '0 2rem',
    maxHeight: '100px',
    position: 'fixed',
    bottom: 0,

    // mobile
    '@media (max-width: 768px)': {
      maxHeight: '120px',
    },
  },
  grey: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
}));

type Props = {
  currentStep: string;
  nextStep: string;
  urlToNextStep: string;
};

export const OnboardingFooter = ({ currentStep, nextStep, urlToNextStep }: Props) => {
  const history = useHistory();
  const classes = useFooterStyles();
  const { sidebarOpened } = useSelector(({ page }: RootState) => page);

  return (
    <Box className={classes.container} width={sidebarOpened ? '82%' : '97%'}>
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <Box color="#000000">
          <p className={classes.grey}>Next Step</p>
          <p>
            You can add {currentStep.toLowerCase()} later and move to {nextStep.toLowerCase()} step from here.
          </p>
        </Box>
        <Box>
          <EHRButton
            dataCy={`go-to-step-${nextStep.toLowerCase()}`}
            text={nextStep}
            color="primary"
            onClick={() => history.push(urlToNextStep)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const OnboardingDoneFooter = () => {
  const history = useHistory();
  const classes = useFooterStyles();
  const { sidebarOpened } = useSelector(({ page }: RootState) => page);

  return (
    <Box className={classes.container} width={sidebarOpened ? '82%' : '97%'}>
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" padding="0.75rem 0">
        <Box color="#000000">
          <p>Congratulations! This is the final step. You are done!</p>
        </Box>
        <Box>
          <EHRButton
            dataCy="finish-onboarding-btn"
            text="Done"
            color="primary"
            onClick={() => history.push('/medspa-admin/onboarding?success=true')}
          />
        </Box>
      </Box>
    </Box>
  );
};
