import React from 'react';

import {
  Button,
  ListItemSecondaryAction,
  Tooltip,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { MultipleSkeleton } from '../common/LoadingSkeleton';
import {
  PRACTITIONER_CONFIGURATION_CUSTOMIZE_PATH,
  PRACTITIONER_CONFIGURATION_VIEW_PATH,
} from '../../routes/practitionerRoutes';
import { IPractitionerConsent } from '../../services/Consents';
import { IPractitionerStandingOrder } from '../../services/StandingOrders';

interface ServiceGroupsListProps {
  title: string;
  practitionerDocument: IPractitionerConsent[] | IPractitionerStandingOrder[];
  isLoading: boolean;
}

const ServiceGroupsList = ({ title, practitionerDocument, isLoading }: ServiceGroupsListProps) => {
  const history = useHistory();

  const goTo = (url: string, id?: string, name?: string) => {
    history.push({
      pathname: url,
      state: {
        id,
        name,
        configuration: title,
      },
    });
  };

  return (
    <div>
      {isLoading ? (
        <MultipleSkeleton width="90%" />
      ) : (
        <Card variant="outlined">
          <CardContent style={{ padding: '12px 15px' }}>
            <List>
              <ListItem key="labelId_title" dense>
                <ListItemText
                  id="labelId_title"
                  primary={
                    <>
                      <Typography variant="h5" component="span">
                        {title}
                      </Typography>
                      {isLoading && <CircularProgress size={20} />}
                      <Button
                        variant="contained"
                        style={{ float: 'inline-end' }}
                        onClick={() => goTo(PRACTITIONER_CONFIGURATION_CUSTOMIZE_PATH)}
                      >
                        New
                      </Button>
                    </>
                  }
                />
              </ListItem>

              <Divider />
              {practitionerDocument?.map(({ id, name, practitionerId }) => (
                <ListItem key={id} role={undefined} dense>
                  <ListItemText id={id.toString()} primary={<h3>{name}</h3>} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Customize">
                      <Button
                        color="primary"
                        disabled={isLoading || !practitionerId}
                        onClick={() => goTo(PRACTITIONER_CONFIGURATION_CUSTOMIZE_PATH, id, name)}
                      >
                        <Edit />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Preview">
                      <Button
                        color="primary"
                        disabled={isLoading}
                        onClick={() => goTo(PRACTITIONER_CONFIGURATION_VIEW_PATH, id, name)}
                      >
                        <Visibility />
                      </Button>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ServiceGroupsList;
