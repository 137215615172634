import React from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useStyles } from './proccessingPaymentInSquareModal.styles';

interface IPaymentStatusModal {
  proccessingPaymentInSquare: boolean;
}

const ProccessingPaymentInSquareModal = ({ proccessingPaymentInSquare }: IPaymentStatusModal) => {
  const classes = useStyles();
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Dialog
      open={proccessingPaymentInSquare}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper }}
      BackdropProps={{ className: classes.backdrop }}
      className={classes.modal}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        Processing payment in Square
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Customer already paid?
          <Button onClick={reloadPage} color="primary" autoFocus className={classes.clickHere}>
            Click here
          </Button>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ProccessingPaymentInSquareModal;
