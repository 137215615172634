import React, { useEffect, useState } from 'react';
import { ListItem, Drawer, Typography, List, ListItemIcon, IconButton, ListItemText } from '@material-ui/core';
import {
  Menu as MenuIcon,
  PeopleAlt as PeopleAltIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Poll as ReportIcon,
  Description as GFEIcon,
  ArrowBackIos,
} from '@material-ui/icons';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as PortraitLogo } from '../../assets/images/portrait-logo.svg';
import SidebarStyles from './Sidebar.styles';
import { useStyles } from '../../pages/listItems';
import {
  PHYSICIAN_GFE_REPORT_PATH,
  PHYSICIAN_NEW_PATIENTS_PATH,
  PHYSICIAN_PROVIDERS_PATH,
  PHYSICIAN_PENDING_GFE_PATH,
} from '../../routes/physicianRoutes';

const PhysicianSidebar = () => {
  const classes = SidebarStyles();
  const history = useHistory();
  const location = useLocation();
  const sideItemClasses = useStyles();
  const { user } = useSelector(({ auth }: any) => auth);
  const [open, setOpen] = React.useState(window.innerWidth >= 880);
  const [activePatientTab, setActivePatientTab] = useState<string>(PHYSICIAN_NEW_PATIENTS_PATH);

  useEffect(() => {
    setActivePatientTab(location.pathname);
  }, [location.pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const classnamePaper = open ? classes.drawerPaper : classes.drawerPaperClose;
  const classnameButton = open ? classes.menuButtonHidden : classes.menuButton;

  return (
    <Drawer variant="permanent" className={classes.drawer} classes={{ paper: classnamePaper }} open={open}>
      <div>
        <div className={classes.toolbarIcon}>
          {open ? (
            <div className={classes.logoContainer}>
              <PortraitLogo className={classes.logo} />
            </div>
          ) : (
            <div className={classes.expandButtonContainer}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={classnameButton}
              >
                <MenuIcon className={classes.iconColor} />
              </IconButton>
            </div>
          )}
        </div>
        <List className={classes.sidebarList}>
          <ListItem
            button
            onClick={() => history.push(PHYSICIAN_PROVIDERS_PATH)}
            className={classNames(sideItemClasses.listItem, sideItemClasses[0], {
              [sideItemClasses[`selected-1`]]: activePatientTab.includes(PHYSICIAN_PROVIDERS_PATH),
            })}
          >
            <ListItemIcon className={sideItemClasses.iconList}>
              <PeopleAltIcon
                className={classNames(sideItemClasses.iconColor, {
                  [sideItemClasses.iconActive]: activePatientTab.includes(PHYSICIAN_PROVIDERS_PATH),
                })}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  activePatientTab.includes(PHYSICIAN_PROVIDERS_PATH)
                    ? sideItemClasses.textActive
                    : sideItemClasses.text
                }
              >
                Providers
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => history.push(PHYSICIAN_PENDING_GFE_PATH)}
            className={classNames(sideItemClasses.listItem, sideItemClasses[0], {
              [sideItemClasses[`selected-1`]]: activePatientTab.includes(PHYSICIAN_PENDING_GFE_PATH),
            })}
          >
            <ListItemIcon className={sideItemClasses.iconList}>
              <GFEIcon
                className={classNames(sideItemClasses.iconColor, {
                  [sideItemClasses.iconActive]: activePatientTab.includes(PHYSICIAN_PENDING_GFE_PATH),
                })}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  activePatientTab.includes(PHYSICIAN_PENDING_GFE_PATH)
                    ? sideItemClasses.textActive
                    : sideItemClasses.text
                }
              >
                Pending GFES
              </Typography>
            </ListItemText>
          </ListItem>
          <List style={{ padding: 0 }}>
            <ListItem
              button
              onClick={() => history.push(PHYSICIAN_NEW_PATIENTS_PATH)}
              className={classNames(sideItemClasses.listItem, sideItemClasses[0], {
                [sideItemClasses[`selected-0`]]: activePatientTab.includes(PHYSICIAN_NEW_PATIENTS_PATH),
              })}
            >
              <ListItemIcon className={sideItemClasses.iconList}>
                <SupervisorAccountIcon
                  className={classNames(sideItemClasses.iconColor, {
                    [sideItemClasses.iconActive]: activePatientTab.includes(PHYSICIAN_NEW_PATIENTS_PATH),
                  })}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    activePatientTab.includes(PHYSICIAN_NEW_PATIENTS_PATH)
                      ? sideItemClasses.textActive
                      : sideItemClasses.text
                  }
                >
                  All Patients
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          {user.isAdvancedPractitioner && (
            <ListItem
              button
              onClick={() => history.push(PHYSICIAN_GFE_REPORT_PATH)}
              className={classNames(sideItemClasses.listItem, sideItemClasses[0], {
                [sideItemClasses[`selected-1`]]: activePatientTab.includes(PHYSICIAN_GFE_REPORT_PATH),
              })}
            >
              <ListItemIcon className={sideItemClasses.iconList}>
                <ReportIcon
                  className={classNames(sideItemClasses.iconColor, {
                    [sideItemClasses.iconActive]: activePatientTab.includes(PHYSICIAN_GFE_REPORT_PATH),
                  })}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    activePatientTab.includes(PHYSICIAN_GFE_REPORT_PATH)
                      ? sideItemClasses.textActive
                      : sideItemClasses.text
                  }
                >
                  GFE Report
                </Typography>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </div>
      {open && (
        <div className={classes.closeNuttonContainer}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            className={classes.menuButton}
          >
            <ArrowBackIos className={classes.iconColor} style={{ transform: 'translateX(0.35rem)' }} />
          </IconButton>
        </div>
      )}
    </Drawer>
  );
};

export default PhysicianSidebar;
