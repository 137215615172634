import { Box } from '@material-ui/core';
import React from 'react';
import { EHRButton, EHRModal } from 'src/components/ui/v1';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const OnboardingSuccessModal = ({ open, setOpen }: Props) => (
  <EHRModal open={open} dataCy="modal-ehr-onboarding-success">
    <EHRModal.Body dataCy="modal-cancel-body">
      <Box display="flex" gridGap="1rem" flexDirection="column">
        <Box display="flex" width="100%" justifyContent="center" fontSize="3rem">
          🎉
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="80%"
          gridGap="2rem"
          margin="0 auto"
          style={{
            fontSize: '1.5rem',
            lineHeight: '1.5rem',
          }}
        >
          Congratulation! Your EHR is set up and now fully functioning.
        </Box>
      </Box>
    </EHRModal.Body>

    <EHRModal.Footer dataCy="modal-ehr-onboarding-success-footer">
      <Box display="flex" width="100%" justifyContent="flex-end" gridGap="1rem">
        <EHRButton
          text="Done"
          dataCy="modal-ehr-onboarding-success-footer-done"
          color="primary"
          onClick={() => setOpen(false)}
        />
      </Box>
    </EHRModal.Footer>
  </EHRModal>
);
