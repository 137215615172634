// eslint-disable-next-line import/no-unresolved
import kebabcase from 'kebabcase-keys';
import * as Sentry from '@sentry/react';
import { queryClient } from 'src/initializers/queryClient';
import { PATIENT_BASIC_INFO } from 'src/constants/reactQuery.keys';
import isCacheExpired from 'src/utils/isCacheExpired';
import axios from '../utils/axios';
// eslint-disable-next-line import/no-useless-path-segments
import { dispatch, store } from '../rematch';
import { compressImage } from '../utils/image.utils';
import Customers from '../services/Customers';
import compile from '../utils/toastMessagesCompiler';
import ICustomerDiagnosis, { ISaveCustomerDiagnosesProps } from '../interfaces/ICustomerDiagnosis';
import Patients from '../services/Patients';
import IServices from '../interfaces/IServices';
import { ILineItem, IServiceVisitAssetScanned } from '../interfaces/IServiceVisits';
import { showSnackbar } from '../utils/global';
import { SNACKBAR_ERROR, SNACKBAR_INFO } from '../constants/general.constants';

export const fetchPatientData = async (patientId: number) => {
  dispatch({ type: 'patient/getPatient' });
  try {
    const {
      data: { customer },
    } = await axios.get(`/customers/${patientId}`);
    dispatch({ type: 'patient/getPatientSuccess', payload: customer });
  } catch (error) {
    dispatch({ type: 'patient/getPatientFail' });
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's data",
        }),
        type: 'error',
      },
    });
  }
};

export const fetchBasicInfo = async ({ patientId, useCache = true }: { patientId: number; useCache?: boolean }) => {
  dispatch({ type: 'patient/setBasicInfoError', payload: undefined });
  const {
    patient: { basicInfo: basicInfoState },
  } = store.getState();

  try {
    if (basicInfoState.id === patientId && !isCacheExpired(basicInfoState.basicInfoLoadedAt) && useCache) {
      const basicInfoPayload = { id: patientId, basicInfo: basicInfoState };
      dispatch({ type: 'patient/getPatientSuccess', payload: basicInfoPayload });
      return;
    }
    dispatch({ type: 'patient/setIsLoadingBasicInfo', payload: true });
    const { basic, message } = await queryClient.fetchQuery([PATIENT_BASIC_INFO, patientId], async () => {
      const { data } = await axios.get(`/patient-profile/${patientId}/basic`);
      return data;
    });
    const basicInfoPayload = { id: patientId, basicInfo: { ...basic, basicInfoLoadedAt: new Date() } };
    dispatch({ type: 'patient/getPatientSuccess', payload: basicInfoPayload });
    if (message) {
      showSnackbar(message, SNACKBAR_INFO);
    }
    // dispatch({ type: 'patient/setIsLoadingBasicInfo', payload: false });
  } catch (error) {
    dispatch({ type: 'patient/setBasicInfoError', payload: error });
    showSnackbar(
      compile('generic.error_message', {
        action: 'fetching',
        element: "the patient's  data",
      }),
      SNACKBAR_ERROR
    );
  } finally {
    dispatch({ type: 'patient/setIsLoadingBasicInfo', payload: false });
  }
};

export const fetchContactInfo = async (patientId: number) => {
  dispatch({ type: 'patient/setIsLoadingContactInfo', payload: true });
  try {
    const { data } = await axios.get(`/patient-profile/${patientId}/contact-info`);
    dispatch({
      type: 'patient/getPatientSuccess',
      payload: {
        id: patientId,
        contactInfo: { ...data.contactInfo, contactInfoLoadedAt: new Date() },
      },
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's  data",
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingContactInfo', payload: false });
  }
};

export const fetchMedicalProfile = async (patientId: number) => {
  dispatch({ type: 'patient/setIsLoadingMedicalProfile', payload: true });
  try {
    const {
      data: {
        medicalProfile: { medicalProfile },
      },
    } = await axios.get(`/patient-profile/${patientId}/medical-profile`);
    dispatch({ type: 'patient/getPatientSuccess', payload: { id: patientId, medicalProfile } });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's  data",
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingMedicalProfile', payload: false });
  }
};

export const fetchCustomerConsents = async (patientId: number) => {
  dispatch({ type: 'patient/setIsLoadingCustomerConsents', payload: true });
  try {
    const response = await axios.get(`/patient-profile/${patientId}/customer-consents`);
    dispatch({ type: 'patient/getPatientSuccess', payload: response.data.customerConsents });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's  consents",
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingCustomerConsents', payload: false });
  }
};

export const fetchCustomerStandingOrders = async ({
  patientId,
  serviceVisitId,
}: {
  patientId: number;
  serviceVisitId: number;
}) => {
  dispatch({ type: 'patient/setIsLoadingCustomerStandingOrders', payload: true });
  try {
    const response = await axios.get(`/patient-profile/${patientId}/customer-standing-orders/${serviceVisitId}`);
    dispatch({ type: 'patient/getPatientSuccess', payload: response.data.customerStandingOrders });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's standing orders",
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingCustomerStandingOrders', payload: false });
  }
};

export const fetchPhotos = async (patientId: number) => {
  dispatch({ type: 'patient/setIsLoadingPhotos', payload: true });
  try {
    const response = await axios.get(`/patient-profile/${patientId}/photos`);
    dispatch({ type: 'patient/getPatientSuccess', payload: response.data.photos });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's  photos",
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingPhotos', payload: false });
  }
};

export const fetchServiceRequests = async (patientId: number) => {
  dispatch({ type: 'patient/setIsLoadingServiceRequests', payload: true });
  try {
    const response = await axios.get(`/patient-profile/${patientId}/service-requests`);
    dispatch({
      type: 'patient/getPatientSuccess',
      payload: {
        id: patientId,
        serviceRequests: response.data.serviceRequests.serviceRequests || [],
        otherServiceRequests: response.data.otherServiceRequests,
      },
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's service requests",
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingServiceRequests', payload: false });
  }
};

export const fetchMessagesThreads = async (patientId: number) => {
  dispatch({ type: 'patient/setIsLoadingMessagesThreads', payload: true });
  try {
    const response = await axios.get(`/patient-profile/${patientId}/messages-threads`);
    dispatch({ type: 'patient/getPatientSuccess', payload: response.data.messagesThreads });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's message threads",
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingMessagesThreads', payload: false });
  }
};

export const fetchServiceGroups = async (patientId?: number) => {
  try {
    let url = '/service-groups';
    if (patientId) {
      url += `?customer_id=${patientId}`;
    }
    const response = await axios.get(url);
    dispatch({ type: 'patient/getServiceGroups', payload: response.data.serviceGroups });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'the service groups',
        }),
        type: 'error',
      },
    });
  }
};

export const fetchPatientsNotes = async (patientId: number) => {
  try {
    const response = await axios.get(`/customers/${patientId}/notes`);
    const notes = response.data.notes || response.data;
    const updatedNotes = notes.map((note: any) => ({ ...note, isOpen: false }));

    dispatch({ type: 'patient/getPatientNotes', payload: updatedNotes });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's notes",
        }),
        type: 'error',
      },
    });
  }
};

export const fetchPractitioners = async () => {
  dispatch({ type: 'patient/setIsLoadingPractitioners', payload: true });
  try {
    const response = await axios.get('practitioners');
    dispatch({ type: 'patient/getPractitioners', payload: response.data.practitioners });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'the practitioners',
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingPractitioners', payload: false });
  }
};

export const fetchServiceVisits = async (patientId: number) => {
  try {
    const request = await axios.get(`/service-visits/${patientId}/full-service-history`);
    dispatch({
      type: 'patient/getPatientSuccess',
      payload: {
        id: patientId,
        serviceVisits: request.data.serviceVisits,
      },
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'the service visits',
        }),
        type: 'error',
      },
    });
  }
};

export const discardServiceVisit = async (
  { serviceVisitId, callback }: { serviceVisitId: number; callback: () => void },
  { patient: { serviceVisits } }: any
) => {
  try {
    dispatch({ type: 'patient/setIsLoadingDiscardServiceVisit', payload: true });

    await axios.post(`service-visits/${serviceVisitId}/delete`);
    callback();
    dispatch({
      type: 'patient/updateServiceVisits',
      payload: serviceVisits.filter(({ id }: any) => id !== serviceVisitId),
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'discarding',
          element: 'the service visit',
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingDiscardServiceVisit', payload: false });
  }
};

export const toggleIsAdvocate = async ({
  patientId,
  advocateReason,
  onSuccess = () => {},
}: {
  patientId: number;
  advocateReason: string;
  onSuccess?: () => void;
}) => {
  try {
    dispatch({ type: 'patient/setIsLoadingToggleIsAdvocate', payload: true });
    const response = await axios.put(`/patient-profile/${patientId}/set-advocate`, kebabcase({ advocateReason }));
    dispatch({ type: 'patient/getPatientSuccess', payload: response.data.basic });
    onSuccess();
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: error,
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingToggleIsAdvocate', payload: false });
  }
};

export const uploadPhotosRequest = async ({
  files,
  id,
  callback = () => {
    /* This is intentional */
  },
}: {
  files: File[] | Blob[];
  id: string;
  callback: () => void;
}) => {
  dispatch({ type: 'patient/uploadPhotosLoading', payload: true });

  for (let index = 0; index < files.length; index++) {
    compressImage({
      file: files[index],
      onSuccess: async (result) => {
        const formData = new FormData();
        formData.append('images[]', result);
        try {
          const { photos, error } = await Customers.uploadPhoto(id, formData);
          if (error) {
            dispatch({
              type: 'snackbar/enqueueSnackBar',
              payload: {
                message: error,
                type: 'error',
              },
            });
          } else {
            dispatch({ type: 'patient/uploadPhotosSuccess', payload: photos });
            callback();
          }
        } catch (error) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile('generic.error_message', {
                action: 'uploading',
                element: 'your photos',
              }),
              type: 'error',
            },
          });
        } finally {
          dispatch({ type: 'patient/uploadPhotosLoading', payload: false });
        }
      },
      onError: (error: any) => {
        Sentry.captureMessage(error.message, 'debug' as Sentry.Severity);
        dispatch({ type: 'patient/uploadPhotosLoading', payload: false });
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'uploading',
              element: 'your photos',
            }),
            type: 'error',
          },
        });
      },
    });
  }
};

export const addNoteRequest = async ({ message, id, callback }: any) => {
  try {
    const response = await axios.post(`/customers/${id}/notes`, { message });
    dispatch({ type: 'patient/addNoteSuccess', payload: response.data });
    callback();
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'adding',
          element: 'the note',
        }),
        type: 'error',
      },
    });
  }
};

export const deletePhotoRequest = async ({ patientId, photoId, deletePhotoReason, deletePhotoCallback }: any) => {
  try {
    await Customers.deletePhoto(patientId, photoId, deletePhotoReason);
    dispatch({ type: 'patient/deletePhotoSuccess', payload: { patientId, photoId } });
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: 'Photo',
          action: 'deleted',
        }),
      },
    });
    deletePhotoCallback();
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'deleting',
          element: 'this photo',
        }),
        type: 'error',
      },
    });
  }
};

export const deleteNoteRequest = async ({ noteId }: any) => {
  try {
    await axios.delete(`/customers/notes/${noteId}`);
    dispatch({ type: 'patient/deleteNoteSuccess', payload: { noteId } });
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: 'Note',
          action: 'deleted',
        }),
      },
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'deleting',
          element: 'the note',
        }),
        type: 'error',
      },
    });
  }
};

export const completeProfile = async ({ id }: any) => {
  try {
    const response = await axios.post(`/customers/${id}/mark_as_completed`);

    if (response.data.success) {
      dispatch({ type: 'patient/completeProfileSuccess' });
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Profile',
            action: 'completed',
          }),
        },
      });
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'completing',
            element: 'your profile',
          }),
          type: 'error',
        },
      });
    }
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'completing',
          element: 'your profile',
        }),
        type: 'error',
      },
    });
  }
};

export const changePractitionerRequest = async ({ practitioner_id: practitionerId, patientId, callback }: any) => {
  try {
    await axios.post(`/customers/${patientId}/change-practitioner`, { practitioner_id: practitionerId });
    dispatch({ type: 'patient/updatePractitionerSuccess', payload: { practitioner_id: practitionerId } });
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: 'Practitioner',
          action: 'updated',
        }),
      },
    });
    callback();
  } catch (error) {
    const message =
      error?.response?.data?.error ||
      compile('generic.error_message', {
        action: 'updating',
        element: 'the practitioner',
      });

    dispatch({ type: 'patient/updatePractitionerFail' });
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message,
        type: 'error',
      },
    });
  }
};

export const approveServiceRequest = async ({ patientId }: any, { patient }: any) => {
  const { serviceRequests, standingOrderDraft } = patient;

  const body = serviceRequests.map(({ approved, checkedByPhysician, requested, serviceGroupId }: any) => ({
    approved,
    checkedByPhysician,
    requested,
    serviceGroupId,
  }));

  try {
    await axios.post(`/customers/${patientId}/update-requested-services`, { 'requested-services': kebabcase(body) });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'updating',
          element: 'the requested services',
        }),
        type: 'error',
      },
    });
  }

  try {
    axios.post(`/customers/${patientId}/create-customer-standing-order-pdfs`, {
      requested_services: body,
      standing_order_draft: standingOrderDraft,
    });
  } catch (error) {
    Sentry.captureMessage(error.message, 'debug' as Sentry.Severity);
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'creating',
          element: 'customer standing order',
        }),
        type: 'error',
      },
    });
  }
};

export const requestPhoto = async ({ patientId, userTypePropertyId, isReSend }: any) => {
  dispatch({ type: 'patient/setPhotoRequestPreResponse', payload: userTypePropertyId });

  if (isReSend) {
    dispatch({ type: 'patient/updatePhotoRequestReSent' });
  }

  try {
    await axios.post(`/customers/${patientId}/request-new-images`);
    // const response = await axios.get(`/customers/${patientId}`);
    // dispatch({ type: 'patient/setPhotoRequest', payload: response.data.customer });
    dispatch({ type: 'patient/setPhotoRequest', payload: {} });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_unexpected'),
        type: 'error',
      },
    });
  }
};

export const clearPhotoRequest = async ({ patientId, threadId }: any) => {
  dispatch({ type: 'patient/clearPhoto', payload: threadId });

  try {
    await axios.post(`/customers/${patientId}/messages/close-thread/${threadId}`);
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_unexpected'),
        type: 'error',
      },
    });
  }
};

export const updateMedicalProfileRequest = async (
  { objectKey, flagName, flag, callback, value }: any,
  { patient }: any
) => {
  const { medicalProfile, id } = patient;

  const newMedialProfile = { ...medicalProfile };
  newMedialProfile[objectKey] = value;
  if (flagName) {
    newMedialProfile[flagName] = flag;
  }

  try {
    const response = await axios.post(
      `customers/${id}/update-medical-profile`,
      kebabcase({ medicalProfile: newMedialProfile }, { deep: true })
    );
    if (callback) {
      callback(response.data.medicalProfile[objectKey] || []);
    }

    dispatch({
      type: 'patient/getPatientSuccess',
      payload: {
        ...patient,
        medicalProfile: { ...patient.medicalProfile, ...response.data.medicalProfile },
      },
    });

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: 'Medical Profile',
          action: 'updated',
        }),
      },
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'updating',
          element: 'the medical profile',
        }),
        type: 'error',
      },
    });
  }
};

export const updateNewMedicalProfile = async ({ data, callback }: any, { patient }: any) => {
  const { medicalProfile, id } = patient;
  const newMedialProfile = { ...medicalProfile, ...data };

  try {
    const response = await Patients.updateMedicalProfile({
      ...patient,
      medicalProfile: newMedialProfile,
      customerId: id,
    });
    if (callback) {
      callback();
    }

    dispatch({
      type: 'patient/getPatientSuccess',
      payload: {
        ...patient,
        medicalProfile: { ...patient.medicalProfile, ...response.medicalProfile },
      },
    });

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: 'Medical Profile',
          action: 'updated',
        }),
      },
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'updating',
          element: 'the medical profile',
        }),
        type: 'error',
      },
    });
  }
};

export const signConsentRequest = async ({
  patientId,
  serviceGroupId,
  signatureImage,
  patientName,
  serviceVisitId = null,
  finallyCallback = () => {},
  successCallback = () => {},
}: any) => {
  const postBody = {
    signatureImage,
    patientName,
    serviceVisitId,
  };

  try {
    const response: any = await axios.post(
      `/customers/${patientId}/add-customer-signature/${serviceGroupId}`,
      postBody
    );
    dispatch({ type: 'patient/updatePatientConsent', payload: response.data.customerConsent });

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: 'Consent',
          action: 'signed',
        }),
      },
    });
    successCallback();
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'signing',
          element: 'the consent',
        }),
        type: 'error',
      },
    });
  } finally {
    finallyCallback();
  }
};

export const fetchCustomerDiagnoses = async (patientId: number) => {
  dispatch({ type: 'patient/setIsLoadingServiceRequests', payload: true });
  try {
    const response = await axios.get(`/customers/${patientId}/diagnoses/customer-diagnoses`);
    const { customerDiagnoses = {}, error } = response?.data;
    if (error) {
      throw error;
    }

    dispatch({
      type: 'patient/getPatientSuccess',
      payload: {
        id: patientId,
        customerDiagnoses: customerDiagnoses.map((el: ICustomerDiagnosis) => ({ ...el, isSelected: true })),
      },
    });
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: "the patient's diagnoses",
        }),
        type: 'error',
      },
    });
  } finally {
    dispatch({ type: 'patient/setIsLoadingServiceRequests', payload: false });
  }
};

export const saveCustomerDiagnoses = async (
  { patientId, successCallback, clearedByExternalPhysician }: ISaveCustomerDiagnosesProps,
  { patient }: any
) => {
  try {
    // const queryClient = useQueryClient();
    const { customerDiagnoses } = patient;

    const diagnoses = customerDiagnoses
      .filter((el: ICustomerDiagnosis) => el.isSelected)
      .map(({ defaultDiagnosisId, treatmentPlan }: ICustomerDiagnosis) => ({ defaultDiagnosisId, treatmentPlan }));

    const { data } = await axios.post(`/customers/${patientId}/diagnoses/customer-diagnoses`, { data: diagnoses });

    const { error } = data;

    if (error) {
      throw Error(error);
    }

    dispatch.patient.fetchCustomerDiagnoses(patientId as number);

    if (!clearedByExternalPhysician) {
      const response = await axios.post(`/customers/${patientId}/clear`);
      if (!response.data.success) {
        throw Error(error);
      }
    }

    dispatch({ type: 'patient/fetchServiceRequests', payload: patientId });
    // queryClient.invalidateQueries([CUSTOMER_SERVICE_REQUESTS, +patientId]);
    successCallback();
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'saving',
          element: 'the diagnoses',
        }),
        type: 'error',
      },
    });
  }
};

export const addCustomerCreditCard = async ({
  patientId,
  squareCreditCardNonce,
  successCallback,
  errorCallback,
}: {
  patientId: string;
  squareCreditCardNonce: string;
  successCallback: (data?: any) => void;
  errorCallback: () => void;
}) => {
  try {
    const { data } = await axios.post(`/v3/customer/${patientId}/save_credit_card`, {
      squareCreditCardNonce,
    });
    const { error } = data;

    if (error) {
      throw Error(error);
    }

    successCallback(data);
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'saving',
          element: 'the credit card',
        }),
        type: 'error',
      },
    });
    errorCallback();
  }
};

export const deleteCustomerCreditCard = async ({
  patientId,
  creditCardId,
  successCallback,
}: {
  patientId: string;
  creditCardId: string;
  successCallback: () => void;
}) => {
  try {
    const { data } = await axios.delete(
      `/v3/customers/${patientId}/remove_square_credit_card?credit_card_id=${creditCardId}`
    );
    const { error } = data;

    if (error) {
      throw Error(error);
    }

    successCallback();
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'removing',
          element: 'the credit card',
        }),
        type: 'error',
      },
    });
  }
};

export const setCustomerDefaultCreditCard = async ({
  creditCardId,
  patientId,
  successCallback,
}: {
  patientId: string;
  creditCardId: string;
  successCallback: () => void;
}) => {
  try {
    const { data } = await axios.post(`customers/${patientId}/update`, { square_default_credit_card_id: creditCardId });
    const { error } = data;

    if (error) {
      throw Error(error);
    }

    successCallback();
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'updating',
          element: 'the default credit card',
        }),
        type: 'error',
      },
    });
  }
};

export const chargeCustomerCreditCard = async ({
  patientId,
  chargeAmount,
  chargeType,
  successCallBack,
  failCallBack,
  creditCardId,
  chargeNote,
}: {
  patientId: string;
  chargeAmount: string;
  chargeType: string;
  creditCardId: string;
  successCallBack?: () => void;
  failCallBack?: (error: Error) => void;
  chargeNote?: string;
}) => {
  try {
    const { data } = await axios.post(`v3/customers/${patientId}/charge_card_for_fee`, {
      chargeAmount: parseFloat(chargeAmount),
      creditCardId,
      chargeType,
      chargeNote,
    });

    const { error } = data;

    if (error) {
      throw Error(error);
    }

    successCallBack?.();
  } catch (error) {
    failCallBack?.(error);
  }
};

export const purchaseServicesAndProducts = async ({
  patientId,
  lineItems,
  serviceVisitAssets,
  successCallback,
}: {
  patientId: string;
  lineItems: ILineItem[];
  serviceVisitAssets: IServiceVisitAssetScanned[];
  successCallback?: (serviceVisit: IServices) => void;
}) => {
  try {
    const { data: serviceVisit } = await axios.post(`v3/customers/${patientId}/purchase_services_and_products`, {
      lineItems,
      serviceVisitAssets,
    });
    const { error } = serviceVisit;

    if (error) {
      throw Error(error);
    }

    successCallback?.(serviceVisit as IServices);
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'purchasing',
          element: 'the selected services',
        }),
        type: 'error',
      },
    });
  }
};

export const prepareCheckoutForFee = async ({
  patientId,
  chargeAmount,
  chargeType,
  chargeNote,
  successCallback,
}: {
  patientId: string;
  chargeAmount: number;
  chargeType: string;
  chargeNote?: string;
  successCallback?: (serviceVisit: IServices) => void;
}) => {
  try {
    const { data: serviceVisit } = await axios.post(`v3/customers/${patientId}/prepare_charge_for_fee`, {
      charge_amount: chargeAmount,
      charge_type: chargeType,
      charge_note: chargeNote ?? '',
    });
    const { error } = serviceVisit;

    if (error) {
      throw Error(error);
    }

    successCallback?.(serviceVisit as IServices);
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'checking out',
          element: 'charge',
        }),
        type: 'error',
      },
    });
  }
};
