import React, { useEffect, useState } from 'react';
import { Card, CardContent, Checkbox, FormControlLabel } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ICustomerPhotoConsent, IPatientView } from '../../types/Patient';
import { SmallTitle } from '../common/card';
import { Button } from '../common/Button';
import { useStyles } from '../NewServiceVisit/photosConsent/PhotosConsent.styles';
import { PhotosConsentModal } from '../NewServiceVisit/photosConsent/PhotosConsentModal';
import { PhotoConsentAgreementModal } from '../NewServiceVisit/photosConsent/PhotoConsentAgreementModal';
import {
  useCreateCustomerPhotoConsent,
  useUpdateCustomerPhotoConsent,
} from '../../hooks/serviceVisit/useCustomerPhotoConsent';
import { IPractitioner } from '../../interfaces/IPractitioner';
import IServiceGroup from '../../interfaces/IServiceGroup';

const DEIDENTIFIED_CONSENT = 'deidentifiedConsent';
const GENERAL_PHOTO_CONSENT = 'generalPhotoConsent';

export const PhotoConsentStep = ({
  serviceVisitOpen,
  patientId,
  serviceVisitId,
  customerPhotoConsent,
  patient,
  refetchCustomerPhotoConsent,
  loading,
  practitioner,
  serviceGroups,
}: {
  patientId: number;
  serviceVisitId: number;
  patient: IPatientView;
  serviceVisitOpen: boolean;
  customerPhotoConsent: ICustomerPhotoConsent | undefined;
  refetchCustomerPhotoConsent: () => void;
  loading: boolean;
  practitioner: IPractitioner;
  serviceGroups: IServiceGroup[];
}) => {
  const classes = useStyles();
  const [consentModalOpen, setConsentModalOpen] = useState<boolean>(false);
  const [openAgreementModal, setOpenAgreementModal] = useState(false);
  const [deidentifiedConsent, setDeidentifiedConsent] = useState<boolean>(
    customerPhotoConsent?.deidentifiedConsent || false
  );
  const [generalPhotoConsent, setGeneralPhotoConsent] = useState<boolean>(
    customerPhotoConsent?.generalPhotoConsent || false
  );
  const { mutateAsync: createCustomerPhotoConsent } = useCreateCustomerPhotoConsent(patientId, serviceVisitId);
  const { mutateAsync: updateCustomerPhotoConsent } = useUpdateCustomerPhotoConsent(patientId, serviceVisitId);
  useEffect(() => {
    if (customerPhotoConsent) {
      setDeidentifiedConsent(customerPhotoConsent.deidentifiedConsent);
      setGeneralPhotoConsent(customerPhotoConsent.generalPhotoConsent);
    }
  }, [customerPhotoConsent]);

  const handleOnSign = async (signatureImage: string, patientName: string) => {
    await createCustomerPhotoConsent({
      signatureImage,
      patientId,
      patientName,
      serviceVisitId,
      deidentifiedConsent,
      generalPhotoConsent: true,
    });
    await refetchCustomerPhotoConsent();
  };

  const onChangeConsent = async (consentType: string) => {
    if (consentType === DEIDENTIFIED_CONSENT) {
      setDeidentifiedConsent(!deidentifiedConsent);
      if (customerPhotoConsent) {
        await updateCustomerPhotoConsent({
          patientId,
          id: customerPhotoConsent.id,
          deidentifiedConsent: !deidentifiedConsent,
        });
      }
    } else {
      setGeneralPhotoConsent(!generalPhotoConsent);
      if (customerPhotoConsent) {
        await updateCustomerPhotoConsent({
          patientId,
          id: customerPhotoConsent.id,
          generalPhotoConsent: !generalPhotoConsent,
        });
      }
    }
  };

  const openSignedPhotoConsent = () => {
    window.open(customerPhotoConsent?.photoConsentPdfUrl, '_blank');
  };

  if (loading) {
    return <Skeleton height={150} />;
  }

  return (
    <Card style={{ marginBottom: '0', minHeight: '148px' }}>
      {customerPhotoConsent ? (
        <CardContent style={{ padding: '11px 20px 20px 20px' }}>
          <SmallTitle title="Photos Consent (optional)" style={{ marginTop: '0', marginBottom: '15px' }} />
          <div>
            <FormControlLabel
              label="My photos can be used for marketing, training and education"
              control={
                <Checkbox
                  disabled={!serviceVisitOpen}
                  color="primary"
                  checked={generalPhotoConsent ?? false}
                  onChange={() => onChangeConsent(GENERAL_PHOTO_CONSENT)}
                />
              }
            />
          </div>
          <div>
            <FormControlLabel
              label="Please use de-identified versions of my image only"
              control={
                <Checkbox
                  disabled={!serviceVisitOpen}
                  color="primary"
                  checked={deidentifiedConsent ?? false}
                  onChange={() => onChangeConsent(DEIDENTIFIED_CONSENT)}
                />
              }
            />
          </div>
          <div>
            <div className={classes.editSignatureContainer}>
              <img
                className={classes.signatureImage}
                src={customerPhotoConsent.signatureImageUrl}
                alt="Patient's Signature"
              />
              {serviceVisitOpen && (
                <Button
                  title="Edit"
                  className={classes.editSignatureButton}
                  onClick={() => setConsentModalOpen(true)}
                />
              )}
            </div>
            <div>
              <Button
                className={classes.button}
                title="VIEW PHOTO CONSENT AGREEMENT"
                onClick={openSignedPhotoConsent}
              />
            </div>
          </div>
        </CardContent>
      ) : (
        <CardContent style={{ padding: '11px 20px 20px 20px' }}>
          <SmallTitle title="Photos Consent (optional)" style={{ marginTop: '0', marginBottom: '15px' }} />
          <Button
            className={classes.button}
            title="SIGN CONSENT"
            disabled={!serviceVisitOpen}
            onClick={() => setConsentModalOpen(true)}
          />
        </CardContent>
      )}
      <PhotosConsentModal
        isOpen={consentModalOpen}
        onClose={() => setConsentModalOpen(false)}
        onSign={handleOnSign}
        signature={customerPhotoConsent}
        patientNameProp={`${patient?.firstName} ${patient?.lastName}`}
        deidentifiedConsent={deidentifiedConsent}
        onChangeConsent={onChangeConsent}
        handleOpenAgreementModal={() => setOpenAgreementModal(true)}
      />
      <PhotoConsentAgreementModal
        open={openAgreementModal}
        serviceGroups={serviceGroups}
        practitioner={practitioner}
        onClose={() => setOpenAgreementModal(false)}
      />
    </Card>
  );
};
