import { useQuery } from 'react-query';
import { LOCATIONS, LOCATION_INFO } from '../../constants/reactQuery.keys';
import { Locations } from '../../services/Locations';

export function useLocations() {
  return useQuery([LOCATIONS], () => Locations.getAllLocations());
}

export function useLocationInfo(id: number) {
  const {
    data: location = {
      acuityLocation: '',
      customAddress: '',
      name: '',
      city: '',
      state: '',
      practitionerId: 0,
      latitude: 0,
      longitude: 0,
      acuityProviderId: 0,
      active: true,
      timezone: '',
    },
    isLoading,
    isError,
    isFetched,
  } = useQuery([LOCATION_INFO], () => Locations.getLocationInfo(id));

  return {
    location,
    isLoading,
    isError,
    isFetched,
  };
}

export function useLocationByPractitioner(practitionerId: number, enabled: boolean = true) {
  const response = useQuery([LOCATION_INFO], () => Locations.getPractitionerLocation(practitionerId), { enabled });

  return {
    ...response,
    data: response.data,
  };
}

export { LOCATIONS } from '../../constants/reactQuery.keys';
