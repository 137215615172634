/* eslint-disable import/no-unresolved */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Dialog, TextField } from '@material-ui/core';
import { Button } from '../Button';
import { useStyles } from './remainingUnitsModal.styles';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import { getHumanReadableInternalSerialNumber } from '../../../utils/inventory.utils';
import { dispatch } from '../../../rematch';

interface Props {
  open: boolean;
  closeModal: () => void;
  confirmUnits: (asset: IAsset, remainingUnits: number) => void;
  asset: IAsset;
  primaryText: string;
  secondaryText: string;
  isReconciliation?: boolean;
  secondaryButtonLabel: string;
  max?: number;
}

export const RemainingUnitsModal = (props: Props) => {
  const { open, asset, primaryText, secondaryText, isReconciliation, secondaryButtonLabel, max } = props;
  // const { open, asset, primaryText, secondaryText, isReconciliation, secondaryButtonLabel, max, rescan } = props;
  const { closeModal, confirmUnits } = props;
  const { name, internalSerialNumber, toDraw, currentNumberOfUnits } = asset;
  const classes = useStyles();
  const [showInput, setShowInput] = useState<boolean>(false);
  const { register, handleSubmit, errors, setValue, reset } = useForm({ defaultValues: { remainingUnits: 1 } });

  useEffect(
    () => () => {
      setShowInput(false);
      setValue('remainingUnits', 1);
      dispatch({ type: 'scanner/setAsset', payload: undefined });
      reset();
    },
    []
  );

  const submit = ({ remainingUnits }: any) => {
    setShowInput(false);
    confirmUnits(asset, +remainingUnits);
    reset();
  };

  const close = () => {
    closeModal();
    setShowInput(false);
    reset();
  };

  const confirmDefaultUnits = (): void => {
    const units = isReconciliation ? currentNumberOfUnits : toDraw || 0;
    confirmUnits(asset, units);
  };

  return (
    <Dialog open={open} onClose={close} classes={{ paper: classes.dialog }}>
      <Box
        data-cy="vialModal"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        className={classes.container}
      >
        <Box display="flex" justifyContent="center" alignContent="center" mb="12px">
          <Box>
            <Box mb={1}>
              <span className={classes.title}>PRODUCT: </span>
              <span className={classes.value}>{name}</span>
            </Box>
            <div>
              <span className={classes.title}>ID: </span>
              <span className={classes.value}>{getHumanReadableInternalSerialNumber(internalSerialNumber)}</span>
            </div>
          </Box>
        </Box>
        <Box>
          {showInput ? (
            <form onSubmit={handleSubmit(submit)} className={classes.form}>
              <Box mb={2} fontSize="16px" fontStyle="normal" fontFamily="Messina Sans Regular">
                {secondaryText}
              </Box>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  name="remainingUnits"
                  variant="outlined"
                  inputRef={(ref) => {
                    ref?.focus();
                    register(ref, { required: true, min: 1, max: isReconciliation ? asset.initialNumberOfUnits : max });
                  }}
                  type="number"
                  error={!!errors.remainingUnits}
                  size="small"
                  style={{ width: '70px', color: '#12574D' }}
                  inputProps={{
                    min: 1,
                    max: isReconciliation ? asset.initialNumberOfUnits : max,
                    style: { textAlign: 'center' },
                  }}
                />
                <Button title="Confirm" type="submit" className={classes.applyButton} />
              </div>
            </form>
          ) : (
            <div className={classes.questionContainer}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignContent="center"
                width="100%"
                textAlign="center"
              >
                <Box mb={1}>
                  <span
                    style={{ fontFamily: 'Messina Sans Regular' }}
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: primaryText }}
                  />
                </Box>
                <Box>
                  <Button
                    title={secondaryButtonLabel}
                    className={classes.secondaryButton}
                    onClick={() => {
                      setShowInput(true);
                    }}
                  />
                  <Button title="Yes" onClick={confirmDefaultUnits} className={classes.applyButton} />
                </Box>
              </Box>
            </div>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
