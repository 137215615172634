import React from 'react';
import { CardContent, Modal } from '@material-ui/core';
import { Launch as LaunchIcon } from '@material-ui/icons';
import { Card } from '../common/card';
import { Button } from '../common/Button';
import { IconButton } from '../common/IconButton';
import { useStyles } from '../PatientProfile/photos.styles';

type Props = {
  isOpenModal: boolean;
  closeModal: () => void;
  image: any;
};

const PhotoModal = ({ isOpenModal, closeModal, image }: Props) => {
  const classes = useStyles();

  const viewOriginal = () => {
    window.open(image.largeUrl);
  };

  const goToServiceVisit = (linkToServiceVisit: string) => {
    window.location.href = linkToServiceVisit;
  };

  return (
    <Modal open={isOpenModal} onClose={closeModal} className={classes.modal}>
      <>
        <Card style={{ width: 'fit-content' }}>
          <CardContent style={{ paddingBottom: '5px' }}>
            <img
              src={image.largeUrl}
              style={{ maxHeight: navigator.userAgent.match('CriOS') ? '75vh' : '80vh', maxWidth: '80vw' }}
              alt="item"
            />
          </CardContent>
          <CardContent style={{ paddingTop: 0, paddingBottom: '10px' }}>
            <div className={classes.buttons}>
              <Button
                title="Close"
                style={{
                  color: '#12574d',
                  backgroundColor: 'rgba(98, 2, 238, 0)',
                  border: 'solid 1px rgba(0, 0, 0, 0.12)',
                }}
                onClick={closeModal}
              />
              {image.linkToServiceVisit && (
                <Button
                  title="Go to service visit"
                  style={{
                    color: '#12574d',
                    backgroundColor: 'rgba(98, 2, 238, 0)',
                    border: 'solid 1px rgba(0, 0, 0, 0.12)',
                  }}
                  onClick={() => goToServiceVisit(image.linkToServiceVisit)}
                />
              )}
              <IconButton
                title="View Original"
                onClick={viewOriginal}
                style={{ color: '#ffffff', backgroundColor: '#12574d' }}
                Icon={<LaunchIcon />}
              />
            </div>
          </CardContent>
        </Card>
      </>
    </Modal>
  );
};
export default PhotoModal;
