export const chat = [
  {
    createdAt: '2020-10-23 18:30:34',
    isBot: true,
    message: 'Hello, welcome to Bubble. How may I assist you today?',
  },
  {
    createdAt: '2020-10-23 18:45:23',
    isBot: false,
    message: ' Hi, I need the new plan.',
  },
  {
    createdAt: '2020-10-23 18:50:54',
    isBot: true,
    message: 'Want to give us your email address? That way we can follow up in case we get disconnected.',
  },
];
