import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SERVICE_VISIT_CHECKOUT, SERVICE_VISIT_DISCOUNTS } from '../../constants/reactQuery.keys';
import ServiceVisit from '../../services/ServiceVisit';
import { IDiscount } from '../../interfaces/checkout.interfaces';
import Checkout from '../../services/Checkout';
import { useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';

export interface CheckoutLineItemDiscount {
  checkoutLineItem: number;
  discounts: IDiscount[];
}

const useDiscounts = (serviceVisitId: number, enabled: boolean) => {
  const response = useQuery([SERVICE_VISIT_DISCOUNTS], () => ServiceVisit.getServiceVisitDiscounts(serviceVisitId), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: true,
    enabled,
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'discounts' })),
  });

  return {
    ...response,
    data: response.data || ([] as CheckoutLineItemDiscount[]),
  };
};

export interface CustomDiscountData {
  /* eslint-disable camelcase */
  discount_type: 'percentage' | 'amount';
  value: number | string;
  description: string;
}

export const useApplyDiscounts = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      checkoutLineItemId,
      discountId,
      customData,
    }: {
      checkoutLineItemId: number;
      discountId?: number;
      customData?: CustomDiscountData;
    }) => {
      let data = { checkout_line_item_id: checkoutLineItemId };
      if (discountId) {
        data = Object.assign(data, { discount_id: discountId });
      } else {
        data = Object.assign(data, customData);
      }
      return Checkout.applyDiscount(serviceVisitId, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};

export const useRemoveDiscounts = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ checkoutLineItemId, discountId }: { checkoutLineItemId: number; discountId: number }) =>
      Checkout.removeDiscount(serviceVisitId, checkoutLineItemId, discountId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};

export default useDiscounts;
