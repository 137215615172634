import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Page from '../Page';

export const useStyles = makeStyles(() => ({
  root: { '& p': { margin: 0 } },
  emptyState: {
    marginTop: '-5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',

    '& h1': { fontSize: '18px', fontWeight: 500, color: '#000000' },
    '& p': { margin: 0, fontSize: '15px', color: '#000000', lineHeight: '24px' },
  },
}));

interface PageProps {
  title: string;
  children?: React.ReactNode;
}

const EmptyPage = ({ title, children }: PageProps) => {
  const classes = useStyles();

  return (
    <>
      <Page title={title}>
        <Box className={classes.emptyState}>{children}</Box>
      </Page>
    </>
  );
};

export default EmptyPage;
