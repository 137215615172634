/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { PractitionerSignupSidebar } from './PractitionerSignupSidebar';
import { PASSWORD_STEP } from './constants';
import { useStyles } from './index.styles';
import useQueryParams from '../../hooks/useQueryParams';
import {
  useResendResetPassword,
  useSetPasswordPractitionerRegistration,
} from '../../hooks/mutations/useSetPasswordPractitionerRegistration';
import { dispatch } from '../../rematch';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH } from '../../routes/practitionerRoutes';

const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*0-9]).{7,}$/;

export const SetPassword = () => {
  const classes = useStyles();
  const { queryParams } = useQueryParams();
  const history = useHistory();
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showResetPasswordLink, setShowResetPasswordLink] = useState<boolean>(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const { mutateAsync: saveNewPassword } = useSetPasswordPractitionerRegistration();
  const { mutateAsync: sendResetPassword } = useResendResetPassword();

  const token = queryParams.get('token');

  if (!token) {
    history.push('/login');
    return null;
  }

  const handleResendVerificationEmail = async () => {
    await sendResetPassword(email);
    toast.success('If there is an account with that email address, a verification link is on its way!');
  };

  const handleSubmit = async () => {
    try {
      if (password !== passwordConfirmation) {
        toast.error('Passwords do not match');
      } else if (!password.match(PASSWORD_REGEX)) {
        toast.error('Password does not meet criteria listed above.');
      } else {
        await saveNewPassword({
          password,
          passwordConfirmation,
          token,
        });
        history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('more-details'));
      }
    } catch (error) {
      if (error.response?.data?.message === 'Invalid token') {
        setShowResetPasswordLink(true);
      } else {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: 'There was an error, please try again later.',
            type: 'error',
            duration: 5000,
          },
        });
      }
    }
  };

  return (
    <div className={classes.signupContainer}>
      <PractitionerSignupSidebar step={PASSWORD_STEP} />
      <div className={classes.signupDetails}>
        {showResetPasswordLink ? (
          <div>
            <h2>Your password verification link has expired.</h2>
            <p>Please enter your email to request a new email verification link:</p>
            <br />
            <TextField
              className={classes.signupField}
              data-cy="emailInput"
              fullWidth
              name="email"
              onChange={(event) => setEmail(event.target.value)}
              label="Email*"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <button
              type="button"
              className={classes.signupButton}
              onClick={handleResendVerificationEmail}
              disabled={!email}
            >
              Resend Verification Email
            </button>
          </div>
        ) : (
          <div>
            <h2>Choose password</h2>
            <li>A minimum of 7 characters</li>
            <li>A lowercase letter</li>
            <li>An uppercase letter</li>
            <li>A number or symbol</li>
            <br />
            <TextField
              className={classes.signupField}
              data-cy="passwordInput"
              fullWidth
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              label="Password*"
              variant="outlined"
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className={classes.signupField}
              data-cy="passwordConfirmationInput"
              fullWidth
              name="passwordConfirmation"
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              label="Confirm Password*"
              type="password"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <button
              type="submit"
              className={classes.signupButton}
              onClick={handleSubmit}
              disabled={!password || !passwordConfirmation}
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
