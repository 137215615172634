import { createModel } from '@rematch/core';
import { uniq } from 'lodash';
import { InventorySupplyReceiptReceiptState } from '../types/InventorySupplyReceipts';
import { RootModel } from './rootModel';
import axiosInstance from '../utils/axios';
import { getTotal } from '../utils/inventory.utils';

export const inventorySupplyReceipt = createModel<RootModel>()({
  state: {
    supplyReceipts: [],
    supplyReceipt: {},
    productsForConfirmation: [],
    isLoading: false,
    isLoadingSubmitSupplyReceipt: false,
  } as InventorySupplyReceiptReceiptState,
  reducers: {
    setInventorySupplyReceipts(state: any, payload: any) {
      payload.forEach((receipt: any) => {
        const { products } = receipt;
        const serviceIds: number[] = uniq(products.map(({ serviceId }: any) => serviceId));
        // eslint-disable-next-line no-param-reassign
        receipt.productsList = serviceIds.map((id: number) => {
          const filteredProducts = products.filter(({ serviceId }: any) => serviceId === id);

          const assets = filteredProducts.map((product: any) => ({
            productName: product.productName,
            internalSerialNumber: product.internalSerialNumber,
            currentNumberOfUnits: product.assetsCount,
            initialNumberOfUnits: product.initialNumberOfUnits,
            allowPartialSale: product.allowPartialSale,
            reconstitutedAt: product.reconstitutedAt,
            expireAt: product.expireAt,
            lot: product.lot,
          }));

          return {
            serviceId: id,
            productName: filteredProducts[0].productName,
            assetsCount: filteredProducts.length,
            units: getTotal(filteredProducts, 'assetsCount'),
            assets,
            allowPartialSale: filteredProducts[0].allowPartialSale,
          };
        });
      });

      return { ...state, supplyReceipts: payload };
    },
    getProductsForConfirmation(state: InventorySupplyReceiptReceiptState, payload: { id: number }) {
      const { id } = payload;
      const { supplyReceipts } = state;
      const supplyReceipt: any = supplyReceipts.find((item: any) => item.id === id);
      return { ...state, productsForConfirmation: supplyReceipt?.productsList || [], supplyReceipt };
    },
    setIsLoadingSubmitSupplyReceipt(state: InventorySupplyReceiptReceiptState, payload: boolean) {
      return { ...state, isLoadingSubmitSupplyReceipt: payload };
    },
    setLoading(state: any, payload: boolean) {
      return { ...state, isLoading: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getSupplyReceipts(practitionerId: number) {
      dispatch.inventorySupplyReceipt.setLoading(true);
      try {
        const response = await axiosInstance.get(`/supply-receipts-by-practitioner/${practitionerId}`);
        if (response.status !== 200) {
          throw new Error(response.data.message);
        }
        if (response.data.error) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: response.data.error,
              type: 'error',
            },
          });
          return;
        }
        dispatch.inventorySupplyReceipt.setInventorySupplyReceipts(response.data.supplyReceipts);
      } finally {
        dispatch.inventorySupplyReceipt.setLoading(false);
      }
    },
    async submitSupplyReceipt({ practitionerId, supplyReceiptId, signature, callback }: any) {
      try {
        dispatch.inventorySupplyReceipt.setIsLoadingSubmitSupplyReceipt(true);
        const response = await axiosInstance.post(
          `/supply-receipts-by-practitioner/${practitionerId}/practitioner-save`,
          { signature, supplyReceiptId }
        );
        if (response.status !== 200) {
          throw new Error(response.data.message);
        }

        if (response.data.error) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              type: 'error',
              message: response.data.error,
            },
          });
          return;
        }
        callback();
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: error,
            type: 'error',
          },
        });
      } finally {
        dispatch.inventorySupplyReceipt.setIsLoadingSubmitSupplyReceipt(false);
      }
    },
  }),
});
