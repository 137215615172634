/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { LinkProps, makeStyles, Link, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { NEW_WINDOW } from 'src/constants/clientLead.constants';

const useStyles = makeStyles({
  button: {
    height: '36px',
    fontFamily: 'Messina Sans SemiBold',
    backgroundColor: '#fff',
    color: '#12574d',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'inline-flex',
    padding: '5px 16px',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(58, 59, 63, 0.04)',
    },
  },
  buttonBig: {
    border: 0,
    cursor: 'pointer',
    margin: 0,
    display: 'inline-flex',
    outline: 0,
    position: 'relative',
    alignItems: 'center',
    userSelect: 'none',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    color: '#3a3b3f',
    fontSize: '0.875rem',
    minWidth: '64px',
    transition:
      // eslint-disable-next-line max-len
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: 'Messina Sans SemiBold',
    fontWeight: 500,
    lineHeight: '1.75',
    borderRadius: '4px',
    textTransform: 'uppercase',
    padding: '6px 16px',
    width: 'auto',
    height: '47px',
    backgroundColor: '#E7EEED',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(58, 59, 63, 0.04)',
    },
  },
});

interface ButtonAhrefInterface {
  text?: string;
  href?: string | any;
  linkProps?: LinkProps;
  className?: any;
  buttonStyle?: 'big' | 'default' | 'notStyle';
  children?: any;
  colorLink?: string;
  onClick?: any;
  marginBtn?: string;
  loading?: boolean;
  styles?: any;
  newWindow?: boolean;
}

function ButtonAhref(props: ButtonAhrefInterface) {
  const {
    styles,
    text,
    href,
    linkProps,
    className,
    buttonStyle,
    children,
    colorLink,
    onClick,
    marginBtn,
    loading,
    newWindow,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const openInNewWindow = (uri: string): Window | null => window.open(uri, NEW_WINDOW);

  const goTo = (hrefParam: any) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (!hrefParam) {
      return;
    }

    if (newWindow) {
      openInNewWindow(hrefParam);
    } else {
      history.push(hrefParam);
    }
  };

  const handleType = (type: string | undefined) => {
    switch (type) {
      case 'big':
        return classes.buttonBig;
      case 'default':
        return classes.button;
      case 'notStyle':
      default:
        return {};
    }
  };

  return (
    <Link
      data-cy="btnAhref"
      className={className || handleType(buttonStyle)}
      underline="none"
      href={href}
      color="primary"
      onClick={onClick || goTo(href)}
      style={{ color: colorLink, margin: marginBtn || '', ...styles }}
      {...linkProps}
    >
      {loading && <CircularProgress size={18} />}
      <span style={{ marginLeft: loading ? '10px' : '' }}>{text || children}</span>
    </Link>
  );
}

ButtonAhref.defaultProps = {
  buttonStyle: 'default',
};

export default ButtonAhref;
