import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  checkbox: {
    color: '#12574d',
  },
  checkboxSection: {
    marginBottom: '9px',
    display: 'flex',
    flexDirection: 'column',
  },
  photoSliders: {
    width: '100%',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  },
  photoSlidersSmall: {
    width: '80%',
    textAlign: 'left',
    marginRight: '.2rem',
  },
  photoContainer: {
    fontFamily: 'Messina Sans Regular',
    padding: '7px',
    cursor: 'pointer',
    borderRadius: '5px',
    backgroundColor: '#e7eeed',
    display: 'block',
    textDecoration: 'none',
    width: '85px',
    '& img': {
      height: '70px',
      width: '70px',
      objectFit: 'cover',
    },
    '& .date': {
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.17,
      letterSpacing: 'normal',
      color: '#000000',
      marginTop: '8px',
    },
  },
  addNewPhoto: {
    fontFamily: 'Messina Sans SemiBold',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '6px',
    height: '105px',
    width: '100px',
    borderRadius: '4px',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    '& .icon': {
      color: '#12574d',
    },
    '& .label': {
      fontSize: '12px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: '1.25px',
      textAlign: 'center',
      color: '#12574d',
    },
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  containerDialog: {
    width: '400px',

    '& p': {
      fontSize: '1rem',
      marginTop: '0',
    },
  },
  title: {
    textAlign: 'center',
  },
  dialogActions: {
    display: 'block',
    textAlign: 'center',
  },
  applyButton: {
    color: '#ffffff !important',
    backgroundColor: '#12574d',
    height: '36px',
    '&:hover': {
      backgroundColor: '#104e45',
    },
    minWidth: '104px',
    margin: '5px',
  },
  secondaryButton: {
    color: '#393B3F',
    border: 'solid 1px #FBDADA',
    backgroundColor: '#FBDADA',
    height: '36px',
    minWidth: '104px',
    margin: '5px',
  },
  actionButton: {
    fontSize: '20px',
    width: '85px',
    marginTop: '4px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    padding: '0 11px 11px 11px',
  },
}));
