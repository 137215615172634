import { AllDayPanel } from '@devexpress/dx-react-scheduler-material-ui';
import { Button, styled } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';
import moment from 'moment';
import { groupingStyles } from './groupingStyles';
import { VIEW_NAME_DAY, VIEW_NAME_WEEK } from '../indexV2';

const disabledBg =
  'repeating-linear-gradient(-60deg, rgb(229, 229, 229) 1px, rgb(240, 240, 240) 4px, rgb(240, 240, 240) 9px)';
// @ts-expect-error
const StyledAllDayPanelCell = styled(AllDayPanel.Cell)(groupingStyles);
interface AllDayCellProps extends AllDayPanel.CellProps {
  view: string;
  workingHoursMap: { [key: number]: IWorkingHour[] };
  onChangePractitionerSelected: (id: number | string) => void;
  onCurrentViewNameChange: (view: string) => void;
  onCurrentDateChange: (date: Date) => void;
}
const AllDayCell = ({
  groupingInfo,
  view,
  workingHoursMap,
  onChangePractitionerSelected,
  onCurrentViewNameChange,
  onCurrentDateChange,
  ...restProps
}: AllDayCellProps) => {
  const groupId = groupingInfo?.[0].id;
  const workingHours = useMemo(
    () =>
      workingHoursMap[groupId]?.filter(
        (hour: IWorkingHour) =>
          moment(hour.startDate).isSameOrAfter(moment(restProps.startDate)) &&
          moment(hour.startDate).isSameOrBefore(moment(restProps.endDate))
      ) || [],
    [restProps.startDate, restProps.endDate, groupId]
  );
  const hasOpenWorkingHours = workingHours.length > 0;

  if (view !== VIEW_NAME_WEEK) {
    return <></>;
  }

  const handleBook = () => {
    onChangePractitionerSelected(groupId || '');
    onCurrentViewNameChange(VIEW_NAME_DAY);
    onCurrentDateChange(restProps.startDate);
  };

  return (
    <StyledAllDayPanelCell
      className={classNames(`group-${groupId}`)}
      groupingInfo={groupingInfo}
      {...restProps}
      style={{ height: '1.6rem', border: 'none', textAlign: 'center' }}
    >
      {/* TODO use EHRButton (styles break with this implementation) */}
      {hasOpenWorkingHours ? (
        <Button
          style={{ background: '#12574d', color: 'white', width: '98%', height: '1.5rem', fontSize: '0.6rem' }}
          data-cy="bookButton"
          onClick={handleBook}
        >
          Book
        </Button>
      ) : (
        <Button
          disabled
          style={{
            background: disabledBg,
            color: 'black',
            width: '98%',
            height: '1.5rem',
            fontSize: '0.6rem',
          }}
          data-cy="bookButton"
        >
          Not Available
        </Button>
      )}
    </StyledAllDayPanelCell>
  );
};

export { AllDayCell };
