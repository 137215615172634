import { uniq } from 'lodash';
import { IMetaPagination } from '../interfaces/global.interfaces';
import { ISupplyReceipts, ISupplyReceipt } from '../interfaces/ISupplyReceipt';
import axiosInstance from '../utils/axios';
import { toSnakeCase } from '../utils/global';
import { getTotal } from '../utils/inventory.utils';
import Api from './Api';

const getProductList = (products: any[]) => {
  const serviceIds: number[] = uniq(products.map(({ serviceId }: any) => serviceId));

  return serviceIds.map((id: number) => {
    const filteredProducts = products.filter(({ serviceId }: any) => serviceId === id);

    return {
      serviceId: id,
      productName: filteredProducts[0].productName,
      assetsCount: filteredProducts.length,
      assets: filteredProducts,
      units: getTotal(filteredProducts, 'initialNumberOfUnits'),
      allowPartialSale: filteredProducts[0].allowPartialSale,
    };
  });
};

const SupplyReceipts = {
  async getSupplyReceipts(
    params: { practitionerId?: number; status?: string; page?: number } = {}
  ): Promise<ISupplyReceipts> {
    const { data } = await axiosInstance.get<ISupplyReceipts>('supply-receipts', { params: toSnakeCase(params) });
    return data;
  },
  async getSupplyReceipt(supplyReceiptId: string) {
    const { supplyReceipt } = await Api.get(`supply-receipts-confirmation/${supplyReceiptId}`);

    const { products } = supplyReceipt;
    supplyReceipt.productsList = getProductList(products);

    return supplyReceipt;
  },
  async practitionerSupplyReceipts(
    params: { practitionerId?: number; page?: number; perPage?: number } = {}
  ): Promise<{ meta?: IMetaPagination; supplyReceipts?: ISupplyReceipt[] }> {
    if (!params.practitionerId) {
      return {};
    }

    const { data } = await axiosInstance.get(`/supply-receipts-by-practitioner/${params.practitionerId}`, {
      params: toSnakeCase(params),
    });

    if (data?.supplyReceipts?.length) {
      data.supplyReceipts.forEach((receipt: any) => {
        const { products } = receipt;
        // eslint-disable-next-line no-param-reassign
        receipt.productsList = getProductList(products);
      });
    }

    return data;
  },
};

export default SupplyReceipts;
