import React, { useEffect } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { MapMarkerComponent } from 'src/components/common/GoogleMap/MapComponent';
import { Box } from '@material-ui/core';
import { USA_COORDS } from 'src/constants/google';
import { useInfoFromCoords } from 'src/hooks/queries/useStates';

const legendStyles = {
  fontSize: 'small',
  fontStyle: 'italic',
  fontFamily: 'Messina Sans Regular',
  color: 'gray',
};

interface Props {
  lat: number;
  lng: number;
  loading?: boolean;
  onChangeMarker: (data: any) => void;
  defaultMarker?: { lat: number; lng: number };
  legend?: string;
  getExtraInfo?: boolean;
}
export const MedspaLocationMap = ({
  lat,
  lng,
  loading,
  defaultMarker,
  legend,
  getExtraInfo,
  onChangeMarker,
}: Props) => {
  const position = { lat: lat || USA_COORDS.latitude, lng: lng || USA_COORDS.longitude };
  const [infoFromCoordsParams, setInfoFromCoordsParams] = React.useState<any>({});

  // Get info after marker:
  const { data: addressInfo = {}, isFetching: isFetchingAddressInfo } = useInfoFromCoords(
    infoFromCoordsParams,
    !!infoFromCoordsParams?.latitude && !!infoFromCoordsParams?.longitude
  );

  // Get extra info from marker data
  const handleChangeMarker = (data: { lat: number; lng: number }) => {
    if (getExtraInfo) {
      if (infoFromCoordsParams.latitude !== data.lat && infoFromCoordsParams.longitude !== data.lng) {
        setInfoFromCoordsParams({
          latitude: data.lat,
          longitude: data.lng,
        });
      }
    } else {
      onChangeMarker?.(data);
    }
  };

  useEffect(() => {
    if (getExtraInfo && !isFetchingAddressInfo && infoFromCoordsParams.latitude && infoFromCoordsParams.longitude) {
      const data = {
        lat: infoFromCoordsParams.latitude,
        lng: infoFromCoordsParams.longitude,
        ...addressInfo,
      };
      onChangeMarker?.(data);
    }
  }, [addressInfo, isFetchingAddressInfo, infoFromCoordsParams]);

  const isLoading = loading || isFetchingAddressInfo;
  return (
    <Box>
      <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
        <MapMarkerComponent
          center={position}
          zoom={10}
          onChangeMarker={handleChangeMarker}
          defaultMarker={defaultMarker}
        />
      </Wrapper>
      <Box style={legendStyles}>
        {!!isLoading && <span>Loading...</span>}
        {!isLoading && (!lat || !lng) && <span>Position not found by zip code</span>}
        {!isLoading && legend && <span>{legend}</span>}
      </Box>
    </Box>
  );
};
