import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableBody,
  withStyles,
  makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import { useMedspaRole } from 'src/hooks/queries/useMedspaAdmins';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import ModalPricingHistory from './ModalPricingHistory';
import ModalPricingReset from './ModalPricingReset';
import IServices from '../../../../interfaces/IServices';
import ServiceRow from './ServiceRow';
import Message from '../../../common/Message/Message';
import IVariant, { IVariantItem } from '../../../../interfaces/IVariants';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { READ_FLOOR_PRICE } from '../../../../constants/actions.constants';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: theme.palette.primary.main,
    margin: '0 5px',
    cursor: 'pointer',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  name: {
    paddingLeft: 20,
  },
  centeredColumn: {
    textAlign: 'center',
  },
  itemTab: {
    border: 'solid 2px #f2f5f5',
  },
}));

const TableCellHeader = withStyles(() => ({
  root: {
    fontSize: '15px',
  },
}))(TableCell);

interface Props {
  data?: IServices[];
  isFetching: boolean;
  height?: string;
}

const ServiceTable = ({ data, isFetching, height = '500px' }: Props) => {
  const { roleName } = useSelector(({ auth }: any) => auth);
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const { data: medspaRoleName } = useMedspaRole(isMedspaAdmin);
  const isLegacyMedspa = medspaRoleName === PORTRAIT_LEGACY || medspaRoleName === PORTRAIT_LEGACY_PLUS;
  const isMedspaActive = process.env.REACT_APP_MEDSPA_FIELDS === 'true';
  const classes = useStyles();
  const [openModalHistory, setOpenModalHistory] = useState<boolean>(false);
  const [openModalReset, setOpenModalReset] = useState<boolean>(false);
  const [currentPrice, setCurrentPrice] = useState<IServices | IVariantItem>();
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const showHistory = (item: IServices | IVariantItem) => {
    setCurrentPrice(item);
    setOpenModalHistory(true);
  };

  const showReset = (item: IServices | IVariantItem) => {
    setCurrentPrice(item);
    setOpenModalReset(true);
  };

  const setShowPriceFloor = () =>
    isMedspaActive && isMedspaAdmin
      ? isLegacyMedspa && hasAccessTo(READ_FLOOR_PRICE, permissions)
      : hasAccessTo(READ_FLOOR_PRICE, permissions);

  const showPriceFloor = setShowPriceFloor();

  return (
    <div style={{ height }}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableCellHeader className={classes.name} data-cy="serviceColumnTitle">
              Service
            </TableCellHeader>
            {showPriceFloor && (
              <TableCellHeader className={classes.centeredColumn} data-cy="normalPriceColumnTitle">
                Suggested Retail Price
              </TableCellHeader>
            )}
            <TableCellHeader className={classes.centeredColumn} width="200px" data-cy="overridePriceColumnTitle">
              {showPriceFloor ? 'Override Price' : 'Current Price'}
            </TableCellHeader>
            <TableCellHeader className={classes.centeredColumn} data-cy="actionsColumnTitle">
              Actions
            </TableCellHeader>
          </TableHead>
          <TableBody>
            {data
              ?.sort((i, j) => (i.name > j.name ? 1 : -1))
              .map((service: IServices) => (
                <>
                  <ServiceRow
                    isFetching={isFetching}
                    service={service}
                    showHistory={showHistory}
                    showReset={showReset}
                  />
                  {service.variants &&
                    service.variants.map((variant: IVariant) =>
                      variant.items.map((item: IVariantItem) => (
                        <ServiceRow
                          isFetching={isFetching}
                          service={item}
                          showHistory={showHistory}
                          showReset={showReset}
                          isVariantItem
                        />
                      ))
                    )}
                </>
              ))}
            {isFetching && <MultipleSkeleton />}
            {!isFetching && !data?.length && <Message text="No data to show" />}
          </TableBody>
        </Table>
      </TableContainer>
      {openModalHistory && currentPrice && (
        <ModalPricingHistory
          open={openModalHistory}
          setOpenModalHistory={() => setOpenModalHistory(false)}
          data={currentPrice}
        />
      )}
      {openModalReset && (
        <ModalPricingReset open={openModalReset} setOpenModalReset={setOpenModalReset} data={currentPrice} />
      )}
    </div>
  );
};

export default ServiceTable;
