import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../../common/Button';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px'
  },
  primaryButton: {
    color: '#ffffff !important',
    margin: 0,
    backgroundColor: '#12574d',
    border: '1px solid #12574d',
    minWidth: '190px'
  },
  secondaryButton: {
    color: '#12574d',
    margin: 0,
    backgroundColor: '#ffffff',
    border: '1px solid #E4E7EB',
    minWidth: '190px'
  },
  divider: {
    border: '1px solid #E4E7EB',
    marginBottom: '10px'
  },
  step: {
    fontFamily: "Messina Sans Regular",
    fontSize: '20px',
    lineHeight: '23px',
    color: '#000000',
    opacity: 0.4
  },
  loadingContainer: {
    width: '190px',
    display: 'flex',
    justifyContent: 'center'
  }
}));

interface WizardFooterProps {
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryCallback: () => void;
  secondaryCallback: () => void;
  step?: string;
  disablePrimary?: boolean;
  loadingPrimary?: boolean;
}

export const WizardFooter = (props: WizardFooterProps) => {
  const {
    primaryButtonText,
    secondaryButtonText,
    primaryCallback,
    secondaryCallback,
    disablePrimary,
    step,
    loadingPrimary
  } = props;
  const classes = useStyles();

  return (
    <>
      <hr className={classes.divider} />
      <div className={classes.container}>
        <Button
          className={classes.secondaryButton}
          title={secondaryButtonText}
          onClick={secondaryCallback}
        />
        {!!step && <span className={classes.step}>{step}</span>}
        {loadingPrimary ?
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div> :
          <Button
            className={classes.primaryButton}
            title={primaryButtonText}
            onClick={primaryCallback}
            disabled={disablePrimary}
          />
        }
      </div>
    </>
  );
};
