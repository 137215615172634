import { makeStyles } from '@material-ui/core';

export const drawerWidth = `18.03vw`;
export const drawerMinWidth = `260px`;
export const drawerClosedWidth = '3.5rem';

const SidebarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: '111px !important',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: '-5px',
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    borderRadius: '0.35rem',
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Messina Sans Regular',
    fontSize: '18px',
  },
  drawer: {
    backgroundColor: 'black',
    '& [class*="MuiDrawer-paper"]': {
      position: 'relative !important',
    },
  },
  drawerPaper: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    color: '#000 !important',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    minWidth: drawerMinWidth,
    scrollbarColor: 'rgba(255,255,255,1) rgba(0,0,0,0) !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    overflowX: 'hidden',
    backgroundColor: theme.palette.primary.main,
    scrollbarColor: 'rgba(255,255,255,1) rgba(0,0,0,0) !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerClosedWidth,
    [theme.breakpoints.up('sm')]: {
      width: drawerClosedWidth,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    width: '100%',
    overflowX: 'hidden',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  iconColor: {
    fill: '#fff',
  },
  paperPadding: {
    padding: '12px 10px',
  },
  boxColor: {
    backgroundColor: '#afcbc7',
  },
  boxSummaryCounter: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: 3,
  },
  avatarContainer: {
    marginRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  avatar: {
    width: '32px',
    height: '32px',
  },
  boxTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dotReference1: {
    fill: '#199f90',
  },
  dotReference2: {
    fill: '#c0e0dc',
  },
  dotReference3: {
    fill: '#dadada',
  },
  menuLine: {
    backgroundColor: '#C8C8C8',
    height: '1px',
    border: 0,
  },
  menu: {
    marginTop: '50px',
  },
  linkMenu: {
    color: '#000',
    textDecoration: 'none',
  },
  btn: {
    color: '#000',
    textDecoration: 'none',
    textTransform: 'none',
  },
  switchAccountHeader: {
    color: '#b8b9b9',
    margin: '16px 10px 0',
  },
  switchAccountOption: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  switchAccountType: {
    color: '#b8b9b9',
  },
  btnLog: {
    minWidth: '18px',
  },
  logoContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: '1.75rem',
    paddingTop: '2rem',
  },
  logo: {
    fill: '#fff !important',
    maxWidth: '126px',
  },
  expandButtonContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    transform: 'translateX(50%) translateY(50%)',
    right: '30%',
  },
  closeNuttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    paddingBottom: '1.25rem',
  },
  headerNavContainer: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    minHeight: '5.5625rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  headerNavTitle: {
    fontWeight: 500,
    fontSize: '1.75rem',
    lineHeight: '1.75rem',
    color: '#000',
  },
  sidebarList: {
    padding: '0 1rem',
  },
  sidebarListClosed: {
    padding: '0 0.25rem !important',
  },
  headerNavMenuTitle: {
    transform: 'translateY(-0.5rem)',
  },
  hideOnXS: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  showOnXS: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

export default SidebarStyles;
