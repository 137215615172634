import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import { Edit as EditIcon, Replay as ReplayIcon } from '@material-ui/icons';
import { useStyles } from './skinConditions.styles';
import { SKIN_CONCERN_KINDS } from '../../../constants/medicalProfile.constants';
import { RootState, dispatch } from '../../../rematch';
import { Button } from '../../common/Button';
import compile from '../../../utils/toastMessagesCompiler';

export const SkinConditions = () => {
  const classes = useStyles();
  const { sex, skinConcerns } = useSelector(({ patient }: RootState) => patient.medicalProfile);
  const [editMode, setEditMode] = useState(false);
  const [selected, setSelected] = useState<any>([]);
  const [finishExit, setFinishExit] = useState(true);

  useEffect(() => {
    setSelected(skinConcerns || []);
  }, [skinConcerns]);

  const toggleEdit = () => {
    if (editMode) {
      setSelected(skinConcerns);
    }

    setFinishExit(false);
    setEditMode(!editMode);
  };

  const onSelectCondition = (key: string) => {
    if (selected.includes(key)) {
      const newSelectedArray = selected.filter((name: string) => name !== key);
      setSelected(newSelectedArray);
      return;
    }
    if (selected.length < 5) {
      const values = [...selected];
      values.push(key);
      setSelected(values);
      return;
    }

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('patient_profile.skin_profile_form.skin_conditions'),
        type: 'error',
      },
    });
  };

  const isButtonDisabled = (): boolean => {
    if (skinConcerns) {
      return !selected.length || JSON.stringify(selected.sort()) === JSON.stringify(skinConcerns.sort());
    }
    return !selected.length;
  };

  const callback = () => {
    setEditMode(false);
  };

  const save = () => {
    dispatch({
      type: 'patient/updateMedicalProfileRequest',
      payload: { value: selected, objectKey: 'skinConcerns', callback },
    });
  };

  const exited = () => {
    setFinishExit(true);
  };

  return (
    <div>
      <div className={classes.editSection}>
        <div>Top Skin Concerns</div>
        {editMode ? <ReplayIcon onClick={toggleEdit} /> : <EditIcon onClick={toggleEdit} />}
      </div>
      {!editMode && finishExit && (
        <div className={classes.selectedSection}>
          {skinConcerns &&
            Object.entries(SKIN_CONCERN_KINDS)
              .filter(([key]) => skinConcerns.includes(key))
              .map(([key, value]: [string, any]) => (
                <Grid key={key} item xs={3} md={3}>
                  <div style={{ maxWidth: '120px' }}>
                    <img src={value[sex || 'male'].active} alt={value.label} />
                    <div style={{ textAlign: 'center' }}>{value.label}</div>
                  </div>
                </Grid>
              ))}
        </div>
      )}
      <Collapse in={editMode} timeout="auto" disableStrictModeCompat onExited={exited}>
        <div className={classes.selectSection}>
          <h1>Choose Your Top Skin Concerns</h1>
          <h3>
            To ensure your personalized formulation is developed to address your top skin concerns. You can choose up to
            5.
          </h3>
          <Grid container spacing={1} justify="center">
            {Object.entries(SKIN_CONCERN_KINDS).map(([key, value]: [string, any]) => (
              <Grid key={key} item xs={3} md={3}>
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className={`${classes.condition} ${selected.includes(key) ? 'selected' : ''}`}
                  onClick={() => {
                    onSelectCondition(key);
                  }}
                >
                  <img
                    src={selected.includes(key) ? value[sex || 'male'].active : value[sex || 'male'].normal}
                    alt={value.label}
                  />
                  <div>{value.label}</div>
                </div>
              </Grid>
            ))}
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              title="Save"
              style={{ color: '#ffffff', backgroundColor: '#12574d', marginRight: '0' }}
              onClick={save}
              disabled={isButtonDisabled()}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
};
