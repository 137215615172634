import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Paper, ClickAwayListener, Popper, Button, Grow, MenuItem, MenuList } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { orderBy } from 'lodash';
import { useStyles } from './modalPoints.styles';
import { CertificationPoints } from '../../../types/PatientPointCertificatesState';

interface Props {
  handleClick: (item: CertificationPoints) => void;
}

const ConvertCertificate = (props: Props) => {
  const { handleClick } = props;

  const { patientPointsToRedeem, patientSMSCodeSent, certificateOptions } = useSelector(
    ({ patientPointCertificates }: any) => patientPointCertificates
  );

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    if (patientSMSCodeSent) {
      return;
    }

    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (item: CertificationPoints | null) => (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    if (item) {
      handleClick(item);
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button
        className={classes.convertCertificateButton}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow-2' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<ArrowDropDownIcon />}
      >
        REDEEM ({patientPointsToRedeem})
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ width: '151px', zIndex: 1, maxHeight: '400px', overflow: 'scroll' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose(null)}>
                <MenuList autoFocusItem={open} id="menu-list-grow-2" onKeyDown={handleListKeyDown}>
                  {orderBy(certificateOptions, ['points'], ['desc']).map((certificate: CertificationPoints) => (
                    <MenuItem
                      key={certificate.id}
                      onClick={handleClose(certificate)}
                      className={classes.convertCertificationItem}
                    >
                      {certificate.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ConvertCertificate;
