import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { MEDSPA_CONSENTS } from 'src/constants/reactQuery.keys';
import { IMedspaConsent } from 'src/interfaces/IMedspaConsents';
import MedspaConsents from 'src/services/MedspaConsents';

export const useMedspaConsents = (userGroupId: number, enabled: boolean = true): UseQueryResult<IMedspaConsent[]> =>
  useQuery<IMedspaConsent[]>({
    queryKey: [MEDSPA_CONSENTS],
    queryFn: (): Promise<IMedspaConsent[]> => MedspaConsents.getMedspaConsents({ userGroupId }),
    enabled,
  });

export const useUpdateMedspaConsents = () => {
  const queryClient = useQueryClient();
  return useMutation(MedspaConsents.updateMedspaConsent, {
    onSuccess: () => {
      queryClient.invalidateQueries([MEDSPA_CONSENTS]);
    },
  });
};

export const useCreateMedspaConsent = () => {
  const queryClient = useQueryClient();
  return useMutation(MedspaConsents.createMedspaConsent, {
    onSuccess: () => {
      queryClient.invalidateQueries([MEDSPA_CONSENTS]);
    },
  });
};
