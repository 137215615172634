import React from 'react';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import { EHRSelect, EHRTextField } from 'src/components/ui/v1';
import IState from 'src/interfaces/IState';
import { AddressDetails } from '.';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const AddressForm = ({
  formControl,
  states,
  isFormDisabled = false,
}: {
  formControl: Control<AddressDetails>;
  states: IState[];
  isFormDisabled: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Controller
          name="streetAddress"
          control={formControl}
          render={({ onChange, value }) => (
            <EHRTextField
              value={value}
              onChange={onChange}
              label="Street Address"
              data-testid="street-address"
              dataCy="shipping-address-street"
              disabled={isFormDisabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="city"
          control={formControl}
          render={({ onChange, value }) => (
            <EHRTextField
              data-testid="input-medspa-city"
              dataCy="input-medspa-city"
              onChange={onChange}
              value={value}
              fullWidth
              label="City"
              disabled={isFormDisabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="state"
          control={formControl}
          render={({ onChange, value }) => (
            <EHRSelect
              data-testid="select-medspa-state"
              dataCy="select-medspa-state"
              displayEmpty
              onChange={onChange}
              value={value}
              fullWidth
              label="State"
              disabled={isFormDisabled}
            >
              {(states || []).map((state) => (
                <MenuItem key={state.code} value={state.code}>
                  {state.name}
                </MenuItem>
              ))}
            </EHRSelect>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="zip"
          control={formControl}
          render={({ onChange, value }) => (
            <EHRTextField
              data-testid="input-medspa-zip"
              inputProps={{ min: 0 }}
              value={value}
              onChange={onChange}
              label="Zip"
              dataCy="shipping-address-zip"
              type="number"
              disabled={isFormDisabled}
              classes={classes}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AddressForm;
