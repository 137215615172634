import React, { useState } from 'react';
import moment from 'moment';
import { groupBy, sortBy } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  Box,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@material-ui/core';
import OrderStatus from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderStatus';
import { DISPLAY_DATE_FORMAT_VARIANT } from 'src/constants/inventory.constants';
import IconCross from 'src/components/common/IconCross';
import { assignOrderStatus } from 'src/utils/inventoryOrdering';
// eslint-disable-next-line max-len
import { DeliveryDetailsAdmin } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderHistory/DeliveryDetails';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import OrderTrackingNumbers from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderTrackingNumbers';
import ISuppliers from 'src/interfaces/ISuppliers';
import OrderConfirmationNumbers from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderConfirmationNumbers';
import {
  IconLeftAccordionSummary,
  useStyle,
} from '../../../DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import {
  InventoryOrderPartial,
  InventoryOrderService,
  InventoryOrderWithId,
} from '../../../../interfaces/IInventoryOrder';
import { useApproveOrder, useRejectOrder, useAdminNote } from '../../../../hooks/queries/useInventoryOrders';
import { OrderApprovalSubRow, TableHeader } from './OrderApprovalRowAux';
import { ButtonsFooter } from './ButtonsFooter';
import { PractitionerBadge } from '../../Practitioners/PractitionerRow';
import { OrderApprovalModal, OrderRejectModal, AdminNoteModal } from './OrderApprovalModal';
import { OrderCharges } from '../../../DashboardPractitioner/Tabs/OrderingTab/OrderCharges';
import { OrderApprovalProductRow } from './OrderApprovalProductRow';

export const OrderApprovalRow = ({
  id,
  purchaseOrderTotal,
  delivered,
  services = [],
  customItems = [],
  suppliers = [],
  specials = [],
  createdAt,
  finalizedAt,
  warehouse,
  approved,
  desiredDeliveryDate,
  rejected = false,
  canceled,
  cancelable,
  deliveryLocation,
  total,
  subtotal,
  transactionFeePct,
  roleName,
  charges,
  paymentStatus,
  referenceNumber,
  shipmentTrackingNumbers = [],
  orderConfirmationNumbers = [],
  adminNote: initialAdminNote,
}: InventoryOrderWithId & { suppliers?: ISuppliers[] }) => {
  const classes = useStyle();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [approving, setApproving] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogAdminNoteOpen, setDialogAdminNoteOpen] = useState<boolean>(false);
  const [dialogRejectOpen, setDialogRejectOpen] = useState<boolean>(false);
  const [adminNote, setAdminNote] = useState<string | null>(initialAdminNote);

  const isLegacy = roleName === PORTRAIT_LEGACY || roleName === PORTRAIT_LEGACY_PLUS;
  const showTrackingNumbers = approved || !!purchaseOrderTotal || !!orderConfirmationNumbers.length;

  const { mutateAsync: approveOrder } = useApproveOrder(id, () => {
    setApproving(false);
  });
  const { mutateAsync: rejectOrder } = useRejectOrder(id, () => {
    setApproving(false);
  });
  const { mutateAsync: AddNote } = useAdminNote(id);

  const statusDetails: InventoryOrderPartial = {
    approved,
    delivered,
    purchaseOrderTotal,
    rejected,
    canceled,
    paymentStatus,
    shipmentTrackingNumbers,
    orderConfirmationNumbers,
    services,
  };

  const status = assignOrderStatus(statusDetails);

  const productsBySupplier = groupBy(services, 'supplierName');
  const customItemsBySupplier = groupBy(customItems, 'supplierName');

  const orderSuppliers = Object.keys({ ...productsBySupplier, ...customItemsBySupplier });
  const filteredSuppliers = sortBy(
    suppliers.filter((supplier) => orderSuppliers.indexOf(supplier.name) > -1),
    'name'
  );

  const formattedSpecials = specials.map((s) => ({ ...s, name: s.specialOffer.name }));

  const approve = async () => {
    setDialogOpen(false);
    setApproving(true);
    try {
      await approveOrder();
    } catch {
      setApproving(false);
    }
  };

  const handleAdminNote = async () => {
    if (!adminNote || adminNote === '') {
      return;
    }
    setDialogAdminNoteOpen(false);
    await AddNote(adminNote);
  };

  const handleRejectOrder = async () => {
    if (!adminNote || adminNote === '') {
      return;
    }
    setDialogRejectOpen(false);
    setApproving(true);
    try {
      await rejectOrder(adminNote);
    } catch {
      setApproving(false);
    }
  };

  return (
    <Accordion expanded={expanded} className={classes.pulseOnly} data-loading={approving && !approved && !rejected}>
      <IconLeftAccordionSummary
        expandIcon={
          <IconButton size="small" onClick={() => setExpanded(!expanded)} data-testid={`order expand ${id}`}>
            <IconCross open={expanded} />
          </IconButton>
        }
      >
        <Box className={classes.accordionSummary} data-testid="order history">
          <Box className={classes.orderHeader}>
            <Box className={classes.orderSummary}>
              <Typography className={classes.orderTitle} component="span">
                {warehouse}
              </Typography>
              <PractitionerBadge role={roleName ?? ''} />
            </Box>
          </Box>
          <Box>
            <Typography className={classes.orderDate}>
              {moment(finalizedAt ?? createdAt).format(DISPLAY_DATE_FORMAT_VARIANT)}
            </Typography>
            <OrderStatus status={status} />
          </Box>
        </Box>
      </IconLeftAccordionSummary>

      <AccordionDetails style={{ flexDirection: 'column' }}>
        <DeliveryDetailsAdmin
          desiredDeliveryDate={
            desiredDeliveryDate ? moment.utc(desiredDeliveryDate).format(DISPLAY_DATE_FORMAT_VARIANT) : '--'
          }
          adminNote={adminNote ?? ''}
          totalCost={total ?? 0}
          order={referenceNumber}
          deliveryLocation={deliveryLocation}
        />
        <TableContainer data-testid={`order details ${id}`}>
          {isLegacy ? (
            <Table>
              <TableHeader status={status} isLegacy />
              <TableBody>
                {[...sortBy(services, 'name'), ...formattedSpecials].map((e) => (
                  <OrderApprovalSubRow
                    {...(e as InventoryOrderService)}
                    finalizedAt={finalizedAt}
                    status={status}
                    inventoryOrderId={id}
                    key={e.id}
                    isLegacy={isLegacy}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <>
              <Table>
                <TableBody>
                  {formattedSpecials.map((special) => (
                    <OrderApprovalProductRow {...special} approved={approved} rejected={rejected} key={special.id} />
                  ))}
                </TableBody>
              </Table>

              {filteredSuppliers.map((supplier) => (
                <>
                  <Box width="100%" display="flex" padding="1rem">
                    <Typography variant="h6">{supplier.name}</Typography>
                  </Box>
                  <Table>
                    <TableHeader status={status} hasCustomItems={!!customItemsBySupplier[supplier.name]} />
                    <TableBody>
                      {customItemsBySupplier[supplier.name]?.map((product) => (
                        <OrderApprovalProductRow
                          {...{ ...product, notes: product.notes ?? '' }}
                          approved={approved}
                          rejected={rejected}
                          key={product.id}
                        />
                      ))}
                      {productsBySupplier[supplier.name]?.map((product) => (
                        <OrderApprovalProductRow
                          {...product}
                          approved={approved}
                          rejected={rejected}
                          key={product.id}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </>
              ))}
            </>
          )}
        </TableContainer>
        <OrderCharges charges={charges} status={status} subtotal={subtotal} transactionFeePct={transactionFeePct} />
        <OrderConfirmationNumbers confirmationNumbers={orderConfirmationNumbers} />
        {showTrackingNumbers && <OrderTrackingNumbers trackingNumbers={shipmentTrackingNumbers} />}
        <Box height="1rem" />
        <ButtonsFooter
          id={id}
          approve={() => setDialogOpen(true)}
          handleAdminNote={() => setDialogAdminNoteOpen(true)}
          handleRejectOrder={() => setDialogRejectOpen(true)}
          disabled={!cancelable}
          approved={approved}
          isLegacy={isLegacy}
          statusDetails={statusDetails}
        />
      </AccordionDetails>

      <Dialog open={dialogOpen} maxWidth="lg">
        <OrderApprovalModal
          dismiss={() => setDialogOpen(false)}
          action={approve}
          orderReferenceNumber={referenceNumber}
          total={total ?? 0}
        />
      </Dialog>
      <Dialog open={dialogAdminNoteOpen} maxWidth="lg">
        <AdminNoteModal
          id={id}
          dismiss={() => setDialogAdminNoteOpen(false)}
          action={handleAdminNote}
          adminNote={adminNote ?? ''}
          setAdminNote={setAdminNote}
          status={status}
        />
      </Dialog>
      <Dialog open={dialogRejectOpen} maxWidth="lg">
        <OrderRejectModal
          id={id}
          dismiss={() => setDialogRejectOpen(false)}
          action={handleRejectOrder}
          orderReferenceNumber={referenceNumber}
          adminNote={adminNote ?? ''}
          setAdminNote={setAdminNote}
          status={status}
        />
      </Dialog>
    </Accordion>
  );
};

export default OrderApprovalRow;
