import React from 'react';
import { useSelector } from 'react-redux';

const cardStyles = {
  marginTop: '-68px',
  marginLeft: '10px',
  border: '5px solid transparent',
  borderRadius: '15px',
  marginBottom: '5px',
};

type Props = {
  children: any;
  step: any;
  onClick: () => void;
  fromHistory?: boolean;
  display?: string;
};

export const ActiveStepWrapper = ({ children, step, onClick, fromHistory, display }: Props) => {
  const currentStep = useSelector(({ newServiceVisit }: any) => newServiceVisit.currentStep);

  return (
    <div
      style={{
        ...cardStyles,
        display,
        borderColor: currentStep === step ? '#afcbc7' : 'transparent',
        marginTop: fromHistory ? '0px !important' : '-68px',
      }}
      onClick={onClick}
      role="none"
    >
      {children}
    </div>
  );
};
