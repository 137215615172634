import IServiceGroup from 'src/interfaces/IServiceGroup';
import Api from './Api';

const ServiceGroup = {
  async getServiceGroups(params: any): Promise<IServiceGroup[]> {
    const { success, serviceGroups } = await Api.get(`/v3/service_groups`, params);

    return success ? serviceGroups : [];
  },
  async createServiceGroup(params: any): Promise<IServiceGroup> {
    const { success, serviceGroup } = await Api.post(`/v3/service_groups`, params);

    return success ? serviceGroup : {};
  },
  async updateServiceGroup(id: number, params: any): Promise<IServiceGroup> {
    return Api.put(`/v3/service_groups/${id}`, params);
  },
  async deleteServiceGroup(id: number): Promise<boolean> {
    return Api.delete(`/v3/service_groups/${id}`);
  },
};

export default ServiceGroup;
