import Api from './Api';
import { DocumentFilters, IDocument } from '../interfaces/documents.interface';
import { showSnackbar } from '../utils/global';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../constants/general.constants';
import compile from '../utils/toastMessagesCompiler';

const BASE_URL = 'v3/documents';

const Documents = {
  async getDocuments(filters: DocumentFilters): Promise<IDocument[] | null> {
    const urlParams = createDocumentFilters(filters);
    const url = `${BASE_URL}?${urlParams.join('&')}`;
    try {
      const { success, documents } = await Api.get(url);
      return success ? documents : [];
    } catch (e) {
      showSnackbar('There was an error fetching this document.', SNACKBAR_ERROR);
      return [];
    }
  },

  async createDocument(documentParams: {
    practitionerId?: string;
    userGroupAdminId?: string;
    documentFile: File;
    documentName: string;
  }): Promise<IDocument | null> {
    const { practitionerId, userGroupAdminId, documentName, documentFile } = documentParams;
    const formData = new FormData();
    if (practitionerId) {
      formData.append('practitioner_id', practitionerId);
    } else {
      !!userGroupAdminId && formData.append('user_group_admin_id', userGroupAdminId);
    }

    formData.append('name', documentName);
    formData.append('document_pdf', documentFile);

    try {
      const { success, document } = await Api.post(BASE_URL, formData);
      if (success) {
        showSnackbar(
          compile('generic.success_message', {
            action: 'created',
            element: 'Document',
            duration: 3000,
          }),
          SNACKBAR_SUCCESS
        );
      }
      return success ? document : null;
    } catch (e) {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'documents',
        }),
        SNACKBAR_ERROR
      );
      return null;
    }
  },

  async updateDocument(documentParams: { documentName: string; documentId: string }): Promise<IDocument | null> {
    const { documentName, documentId } = documentParams;
    try {
      const { success, document } = await Api.put(`${BASE_URL}/update`, {
        name: documentName,
        documentId,
      });
      if (success) {
        showSnackbar(
          compile('generic.success_message', {
            action: 'updated',
            element: 'Document',
            duration: 3000,
          }),
          SNACKBAR_SUCCESS
        );
      }
      return success ? document : null;
    } catch (e) {
      showSnackbar(
        compile('generic.error_message', {
          action: 'updating',
          element: 'documents',
        }),
        SNACKBAR_ERROR
      );
      return null;
    }
  },
  async removeDocument(documentParams: { documentId: string }): Promise<boolean | null> {
    const { documentId } = documentParams;
    try {
      const { success } = await Api.put(`${BASE_URL}/remove`, {
        documentId,
      });
      if (success) {
        showSnackbar(
          compile('generic.success_message', {
            action: 'removed',
            element: 'Document',
            duration: 3000,
          }),
          SNACKBAR_SUCCESS
        );
      }
      return success;
    } catch (e) {
      showSnackbar(
        compile('generic.error_message', {
          action: 'removing',
          element: 'documents',
        }),
        SNACKBAR_ERROR
      );
      return false;
    }
  },
};

const createDocumentFilters = ({ month, showAll, practitionerId, documentName, sort }: DocumentFilters): any[] => {
  const filters = [];
  if (typeof month !== 'undefined' && month >= 0) {
    filters.push(`month=${month + 1}`);
  }

  if (showAll) {
    filters.push(`show_all=${showAll}`);
  }

  if (practitionerId && !Number.isNaN(Number(practitionerId))) {
    filters.push(`practitioner_id=${practitionerId}`);
  }

  if (documentName) {
    filters.push(`document_name=${documentName}`);
  }

  if (sort) {
    filters.push(`sort=${sort}`);
  }

  return filters;
};

export default Documents;
