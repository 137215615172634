import { IOrderingAllowed, InventoryOrderStatus } from 'src/interfaces/IInventoryOrder';

export const INVENTORY_COLORS = {
  JAR_COVERED: '#393B3F',
  JAR_UNCOVERED: 'transparent',
  ACTION_DEFAULT_BG: '#F0F2F4',
  ACTION_ZERO_STOCK_BG: '#BEBFC0',
  ACTION_MISSING_BG: '#F7D3A9',
  BOX_DEFAULT_BG: 'white',
  BOX_ZERO_STOCK_BG: '#F0F2F4',
  BOX_ABOUT_TO_EXPIRE_BG: '#F8E6D1',
  BOX_EXPIRED_BG: '#FFF1F1',
};
export const IN_STOCK_STATUS = 'in_stock';
export const WASTE_STATUS = 'waste';
export const EMPTY_CELL = '--';

export const RECONCILIATION_HEADERS = [
  'Product',
  'Expected',
  'Scanned',
  'Marked As Empty',
  'Expired',
  'Remaining',
  'Product IDs',
];
export const RECONCILIATION_HEADERS_WITH_DEDUCTIONS = [
  'Product',
  'Expected',
  'Scanned',
  'Marked As Empty',
  'Expired',
  'Remaining',
  'Cost',
  'Product IDs',
];
export const COMPLETED_RECONCILIATION_HEADERS = [
  'Product',
  'Expected',
  'Counted',
  'Marked as Empty',
  'Expired',
  'Missing',
  'IDs',
  'Notes',
  'Waived',
  'Final Missing',
];
export const COMPLETED_RECONCILIATION_HEADERS_WITH_DEDUCTIONS = COMPLETED_RECONCILIATION_HEADERS.concat(['Cost']);
export const SERVICE_GROUP_NEUROTOXINS = 'Neurotoxins';

export const RUSH_DELIVERY_COST = 40;

export const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
export const CALENDAR_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DISPLAY_DATE_FORMAT = 'MMM DD YYYY';
export const DISPLAY_DATE_FORMAT_VARIANT = 'MMM DD, YYYY';
export const SERIAL_DATE_FORMAT = 'YYYYMD';

export const HEADER_TEXT_DEFAULT =
  'Please take a moment to verify the accuracy of your stock records before proceeding.';
export const RECOMMENDATION_HEADER =
  'Note: recommendations below are expressed in vials or syringes (not units or boxes)';

export const DATEPICKER_FORMAT = 'MM/DD/yyyy';
export const FULLSCREEN = '(min-width:900px)';
export const ORDERING_DISABLED: IOrderingAllowed = { active: false, disabled: true };
export const SHORT_DATE = 'MMM DD';
export const DEFAULT_TIMEOUT = 100;
export const PORTRAIT_PRICING_TEMP_URI_LITE =
  'https://s3.amazonaws.com/ehr-prod-east/documents/f7ca6240755a88236df6ffffc9d8d05337c53238.pdf?1715040142';
export const PORTRAIT_PRICING_TEMP_URI_FLEX =
  'https://s3.amazonaws.com/ehr-prod-east/documents/fa10f815e6061e463c0684052244b742a835cefd.pdf?1715040175';
export const PORTRAIT_PRICING_TEMP_URI_FLEX_LUXE_LITE =
  'https://s3.amazonaws.com/ehr-prod-east/documents/5e2bb26f72a09515fdd12a9e27714bbc7dc16bd6.pdf?1718199622';
export const ORDER_STATUS_REQUESTED: InventoryOrderStatus = 'order requested';
export const ORDER_STATUS_APPROVED: InventoryOrderStatus = 'order confirmed';
export const ORDER_STATUS_PENDING_PAYMENT: InventoryOrderStatus = 'awaiting payment';
export const ORDER_STATUS_PLACED: InventoryOrderStatus = 'order placed';
export const ORDER_STATUS_PAID: InventoryOrderStatus = 'paid';
export const ORDER_STATUS_ORDERED: InventoryOrderStatus = 'shipped';
export const ORDER_STATUS_PATRIALLY_SHIPPED: InventoryOrderStatus = 'partially shipped';
export const ORDER_STATUS_DELIVERED: InventoryOrderStatus = 'delivered';
export const ORDER_STATUS_PARTIALLY_DELIVERED: InventoryOrderStatus = 'partially delivered';
export const ORDER_STATUS_CANCELED: InventoryOrderStatus = 'canceled';
export const ORDER_STATUS_REJECTED: InventoryOrderStatus = 'rejected';
export const ORDER_STATUS_FAILED: InventoryOrderStatus = 'failed';
export const PAYMENT_STATUS_APPROVED = 'approved';
export const PAYMENT_STATUS_PENDING = 'pending';
export const PAYMENT_STATUS_COMPLETED = 'completed';
export const SHIPPED_ORDER_STATUSES: InventoryOrderStatus[] = [
  ORDER_STATUS_PATRIALLY_SHIPPED,
  ORDER_STATUS_ORDERED,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_PARTIALLY_DELIVERED,
];
export const CARD_FEE_PCT = 2.9;
export const PORTRAIT_ORDERING_SQUARE_LOCATION_ID = 'LPZKG9CW76C0S';
export const AT_COST_SUPPLIERS = ['Alastin', 'ZO Skin Health'];
export const SUPPLIERS_HELPER_TEXT: { [key: string]: string } = {
  Galderma:
    // eslint-disable-next-line max-len
    'Restylane orders must contain a minimum of 6 boxes of any SKU. Sculptra orders must contain a minimum of 3 boxes and Dysport orders must contain a minimum of 2 vials.',
  Allergan:
    // eslint-disable-next-line max-len
    'Botox orders of less than 3 vials and/or Juvederm orders of less than 3 boxes each incur a standard $25 shipping charge. Latisse must be ordered in quantities of 6.',
  'Revance Therapeutics':
    'RHA orders must contain a minimum of 5 boxes. DAXXIFY orders must contain a minimum of 10 vials.',
  Prollenium: 'Versa orders must contain a minimum of 5 boxes.',
  Merz: 'There are no minimum order requirements for Merz orders.',
  Alastin:
    // eslint-disable-next-line max-len
    'Sales tax will be applied based on location. Your first order must be a minimum of $1,500. After your first order, order minimums are reduced to $500. All items must be ordered in quantities of 6. All orders incur a $10 standard shipping charge.',
  Benev: 'All orders incur a $60 overnight shipping fee.',
  CosmoFrance: 'Sales tax will be applied based on location. All orders incur a two-day shipping charge of $25.',
  'Dermaplane Pro': 'Sales tax will be applied based on location. All orders incur a standard shipping charge of $20.',
  Eurothreads: 'All orders incur a $30 standard shipping charge.',
  Evolus: 'Jeuveau orders of 2 or less vials incur a $30 shipping charge.',
  'Heal MD':
    'Orders must contain a minimum of 10 vials of each SKU purchased. All orders incur a shipping charge of $100.',
  Mint: 'All orders incur a $30 standard shipping charge.',
  Miracu: 'All orders incur a $30 standard shipping charge.',
  Novathreads: 'All orders incur a $30 standard shipping charge.',
  Olympia: 'All orders incur a $50 overnight shipping charge.',
  Skinmedica: 'Sales tax will be applied based on location. Each SKU must be purchased in quantities of 6.',
  'Vitality Institute':
    // eslint-disable-next-line max-len
    'Sales tax will be applied based on location. Your first order must be a minimum of $1,500. After your first order, order minimums are reduced to $500. All orders incur a $25 standard shipping charge.',
  WelcareRx: 'All orders incur a $35 standard shipping charge.',
  'ZO Skin Health':
    // eslint-disable-next-line max-len
    'Sales tax will be applied based on location. Your first order must be a minimum $3,000. Each SKU must be purchased in quantities of 3.',
};

export const PRICE_TOOLTIP =
  // eslint-disable-next-line max-len
  "This is the final price you pay and includes the cost of the product and Portrait's comprehensive platform access fee.";
export const CUSTOM_ITEM_TEXT =
  // eslint-disable-next-line max-len
  'If you couldn’t find this item within our list you can place a custom order for this product. This order will be processed separately. Our team will reach you out with the price and availability.';
export const YOU_SAVE_TOOLTIP = "This is how much you save by operating on Portrait's platform.";
export const MARKET_PRICE_TOOLTIP = 'This is the estimated list price for independent practices.';

export const PINNED_PRODUCTS_AND_SUPPLIERS: { [key: string]: string[] } = {
  Allergan: [
    'Botox',
    'Botox 100u',
    'Botox 50u',
    'Juvederm Voluma',
    'Juvederm Voluma XC',
    'Juvederm Ultra',
    'Juvederm Ultra .55',
    'Juvederm Ultra XC',
    'Juvederm Voluma XC (2 Per Box)',
    'Juvederm Ultra XC (2 Per Box)',
    'Juvederm Ultra XC .55 (2 Per Box)',
  ],
  Galderma: ['Dysport', 'Dysport 300u', 'Restylane Contour', 'Restylane Defyne', 'Restylane Kysse', 'Restylane Lyft'],
  Merz: ['Xeomin', 'Xeomin 100u', 'Xeomin 50u', 'Radiesse', 'Belotero'],
  'Revance Therapeutics': [],
  Prollenium: [],
  Evolus: [],
  Alastin: [
    'HA IMMERSE SerumTM',
    'Alastin - HA Immerse',
    'HydraTint Pro Mineral Sunscreen SPF 36',
    'Alastin - HydraTint Sunscreen',
    'INhance Post-Injection Serum with TriHex Technology®',
    'Alastin - Inhance Post-Injection',
    'Regenerating Skin Nectar with TriHex Technology®',
    'Alastin - Regenerating Nectar',
    'Gentle Cleanser',
    'Alastin - Gentle Cleanser',
  ],
  Skinmedica: [
    'AHA/BHA Exfoliating Cleanser - 6oz',
    'Skinmedica - AHA/BHA Exfol. Cleanser',
    'AHA/BHA Cream - 2oz',
    'Skinmedica - AHA/BHA Cream',
    'Dermal Repair Cream - 1.7oz',
    'Skinmedica - Dermal Repair Cream',
    'HA5® Rejuvenating Hydrator - 2oz',
    'Skinmedica-HA5 Rejuvenating Hydrator',
    'Skinmedica-HA5 Lip System',
    'HA5® Smooth & Plump Lip System - 0.1oz',
  ],
  'ZO Skin Health': [
    '10% Vitamin C Self-Activating - 20mL',
    '10% Vitamin C Self-Activating - 50mL (min 3)',
    'Ablative Healing Post-Procedure Bundle',
    'Acne Control 10% Benzoyl Peroxide - 60mL (min 3)',
    'Aggressive Anti-Aging Program (min 3)',
  ],
};

export const PINNED_SUPPLIERS = Object.keys(PINNED_PRODUCTS_AND_SUPPLIERS);
export const PAYMENT_STATUS_AUTHORIZED = 'authorized';
export const PAYMENT_STATUS_AUTHORIZING = 'authorizing';
export const MAX_PAYMENT_AUTH_ATTEMPTS = 20;
export const PAYMENT_AUTH_TIMEOUT = 500;
export const MEDSPA_ONBOARDING_ORDERING_URI_PARAMS = '?openEhrOnboardingModal=true';
export const SNACKBAR = 'snackbar/enqueueSnackBar';

export const ERR_PAYMENT_TIMEOUT = 'Timeout occurred';
export const ERR_PAYMENT_FAILED = 'Unable to initiate payment';
export const ERR_SUBMIT_FAILED = 'could not be submitted';
export const ERROR_PREFIX = 'An error was encountered ';
