import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import IState from 'src/interfaces/IState';
import { yupResolver } from '@hookform/resolvers';
import { useStates } from 'src/hooks/queries/useStates';
import { useCreatePatient } from '../../hooks/queries/usePatients';
import { Physicians } from '../../services/Physicians';
import { PHYSICIANS } from '../../constants/reactQuery.keys';
import compile from '../../utils/toastMessagesCompiler';
import { ADMIN_ROLE, PRACTITIONER_ROLE } from '../../constants/general.constants';
import { usePractitionersForNewPatient } from '../../hooks/queries/usePractitioners';
import { dispatch } from '../../rematch';
import { patientSchemaFormForPractitioner, schemaForm } from './schemaForm';

export const useStyles = makeStyles(() => ({
  title: {
    margin: '0 1rem 0 0',
  },
  formModal: {
    width: '38.2%',
    margin: '0.625rem 0.625rem auto auto',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '24px',
    minWidth: '550px',
    maxWidth: '550px',
  },
  formModalContent: {
    height: '98vh',
    width: '50%',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '24px',
    minWidth: '550px',
  },
  submitButton: {
    alignSelf: 'center',
    minWidth: '110px',
  },
  separator: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  footer: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '100%',
  },
  label: {
    marginBottom: '0.5rem',
  },
}));

const LOAD_PHYSICIAN_ERROR = compile('generic.error_message', {
  action: 'loading',
  element: 'physicians',
});

interface Props {
  openModal: boolean;
  onClose: () => void;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  physicianId: string;
  practitionerId: string;
}

const DEFAULT_VALUE_FORM = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  city: '',
  addressLine1: '',
  zipCode: '',
  state: '',
  physicianId: '',
  practitionerId: '',
};

const NewPatientModal = ({ openModal, onClose }: Props) => {
  const classes = useStyles();
  const { userType, userId } = useSelector(({ auth }: any) => auth);

  const { handleSubmit, errors, register, watch, control, setValue } = useForm<FormData>({
    resolver: yupResolver(userType === ADMIN_ROLE ? schemaForm : patientSchemaFormForPractitioner),
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: DEFAULT_VALUE_FORM,
  });

  const city = watch('city', '');
  const state = watch('state', '');
  const mutationCreate = useCreatePatient();
  const loadingCreate = mutationCreate.isLoading;
  const { data: states = [], isFetching: isFetchingStates } = useStates(openModal);
  const {
    data: practitioners,
    isLoading: isLoadingPractitioners,
    isError: isErrorPractitioners,
  } = usePractitionersForNewPatient(openModal);

  const {
    isError,
    data: physicians,
    isFetching: isFetchingPhysicians,
  } = useQuery([PHYSICIANS], Physicians.getPhysicians, {
    enabled: openModal,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: LOAD_PHYSICIAN_ERROR,
        type: 'error',
        duration: 2500,
      },
    });
  }

  if (isErrorPractitioners) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'loading',
          element: 'practitioners',
        }),
        type: 'error',
        duration: 2500,
      },
    });
  }
  const formatPhone = (val: string): string => val.replace(/[^a-zA-Z0-9]/g, '');

  const createAction = async (dataForm: FormData) => {
    const dataToCreate = {
      firstName: dataForm.firstName.trim(),
      lastName: dataForm.lastName.trim(),
      email: dataForm.email.trim(),
      phone: formatPhone(String(dataForm.phone)).trim(),
      addressLine1: dataForm.addressLine1.trim(),
      addressLine2: '',
      city: dataForm.city.trim(),
      state: dataForm.state.trim(),
      zipCode: dataForm.zipCode.trim(),
      physicianId: dataForm.physicianId,
      practitionerId: userType === PRACTITIONER_ROLE ? userId : dataForm.practitionerId,
    };
    await mutationCreate.mutateAsync(dataToCreate);
  };

  return (
    <Modal open={openModal} onClose={onClose} className={classes.formModal}>
      <Box className={classes.formModalContent}>
        {/* Modal Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center" p="1.5rem">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom className={classes.title}>
              New Patient
            </Typography>
          </Box>
          <Button onClick={onClose} style={{ minWidth: 'auto', border: '1px solid #CFCFCF' }}>
            <CloseIcon />
          </Button>
        </Box>
        <hr className={classes.separator} />
        {/* Modal Body */}
        <Box component="form" onSubmit={handleSubmit(createAction)} alignItems="center" p="1.5rem">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                First Name
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Patient's First Name"
                name="firstName"
                variant="outlined"
                error={!!errors.firstName}
                helperText={errors.firstName?.message || ''}
                onChange={(e) => setValue('firstName', e.target.value)}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Last Name
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Patient's Last Name"
                name="lastName"
                variant="outlined"
                error={!!errors.lastName}
                helperText={errors.lastName?.message || ''}
                onChange={(e) => setValue('lastName', e.target.value)}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Email
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Patient's Email"
                name="email"
                type="email"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message || ''}
                onChange={(e) => setValue('email', e.target.value)}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Phone
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Patient's Phone"
                name="phone"
                type="tel"
                variant="outlined"
                error={!!errors.phone}
                helperText={errors.phone?.message || ''}
                onChange={(e) => setValue('phone', e.target.value)}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                State
              </InputLabel>
              <Autocomplete
                inputValue={state}
                options={states}
                groupBy={(option) => option.name[0]}
                getOptionLabel={(usState) => usState.name}
                style={{ minWidth: '10rem' }}
                disabled={isFetchingStates}
                helperText={errors.state?.message || ''}
                onChange={(ev, value: IState) => {
                  setValue('state', value?.name || '');
                  setValue('city', '');
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    error={!!errors.state}
                    autocomplete="off"
                    variant="outlined"
                    placeholder="Select a State"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                City
              </InputLabel>
              <TextField
                value={city}
                onChange={(ev) => setValue('city', ev.target.value)}
                error={!!errors.city}
                helperText={errors.city?.message || ''}
                variant="outlined"
                placeholder="Enter a city"
              />
            </Grid>
            <Grid item xs={8}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Address Line 1
              </InputLabel>
              <TextField
                fullWidth
                name="addressLine1"
                placeholder="Patient's Address"
                variant="outlined"
                error={!!errors.addressLine1}
                helperText={errors.addressLine1?.message || ''}
                onChange={(e) => setValue('addressLine1', e.target.value)}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                ZIP Code
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Patient's Zip Code"
                name="zipCode"
                variant="outlined"
                error={!!errors.zipCode}
                helperText={errors.zipCode?.message || ''}
                onChange={(e) => setValue('zipCode', e.target.value)}
                inputRef={register}
              />
            </Grid>
            {userType === ADMIN_ROLE && (
              <>
                <Grid item xs={12}>
                  <InputLabel
                    color="secondary"
                    focused={false}
                    htmlFor="physician-select"
                    error={!!errors.physicianId}
                    className={classes.label}
                  >
                    Physician
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="physicianId"
                      control={control}
                      error={!!errors.physicianId}
                      as={
                        <Select id="physician-select" fullWidth label="physician">
                          {physicians?.map(({ identity, id }) => {
                            const { firstName = 'Unknown', lastName = 'Physician' } = identity || {};
                            return (
                              <MenuItem data-cy="physician" key={id} value={id}>
                                {firstName} {lastName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      }
                      disabled={isFetchingPhysicians}
                    />
                    {errors.physicianId && <FormHelperText error>{errors.physicianId.message}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel
                    color="secondary"
                    focused={false}
                    htmlFor="practitioner-select"
                    error={!!errors.practitionerId}
                    className={classes.label}
                  >
                    Providers
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="practitionerId"
                      control={control}
                      error={!!errors.practitionerId}
                      as={
                        <Select id="practitioner-select" fullWidth label="practitioner">
                          {practitioners?.map(({ firstName, lastName, id }) => (
                            <MenuItem data-cy="practitioner" key={id} value={id}>
                              {firstName} {lastName}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      disabled={isLoadingPractitioners}
                    />
                    {errors.practitionerId && <FormHelperText error>{errors.practitionerId?.message}</FormHelperText>}
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          <Box className={classes.footer}>
            <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Box style={{ width: '100%' }}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={loadingCreate}
                  className={classes.submitButton}
                >
                  {loadingCreate ? <CircularProgress size={25} /> : 'Add'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewPatientModal;
