import { MEDSPA_V4_PATH } from 'src/constants/apiRoutes.constants';
import {
  CommercialShippingAddress,
  IAccountSettings,
  MedicalDirectorInfo,
  MedicalLicenseDetails,
} from 'src/interfaces/onboarding.interface';
import Api from '../Api';

const ACCOUNT_SETTINGS_PATH = `${MEDSPA_V4_PATH}/account-settings`;

type AccountSettingsData = {
  profileImage?: string;
  governmentIssuePhotoId?: string;
  licenses?: string;
  position?: string;
  entityName?: string;
  medicalDirector?: MedicalDirectorInfo;
  medicalLicenseDetails?: MedicalLicenseDetails;
  signatureImage?: string;
  commercialShippingAddress?: CommercialShippingAddress;
  hasAcknowledgedInventoryDisclaimer?: boolean;
};

const AccountSettings = {
  async getAccountSettings(): Promise<IAccountSettings> {
    const { success, data } = await Api.get(ACCOUNT_SETTINGS_PATH);

    return success ? data : {};
  },
  createAccountSettings({
    profileImage,
    governmentIssuePhotoId,
    licenses,
    position,
    entityName,
    medicalDirector,
    medicalLicenseDetails,
    signatureImage,
    commercialShippingAddress,
    hasAcknowledgedInventoryDisclaimer,
  }: AccountSettingsData) {
    return Api.post(ACCOUNT_SETTINGS_PATH, {
      profileImage,
      governmentIssuePhotoId,
      licenses,
      position,
      entityName,
      medicalDirector,
      medicalLicenseDetails,
      signatureImage,
      commercialShippingAddress,
      hasAcknowledgedInventoryDisclaimer,
    });
  },
  upsertCommercialShippingAddress(
    commercialShippingAddressId?: number,
    commercialShippingAddress?: CommercialShippingAddress
  ) {
    return Api.post(`${MEDSPA_V4_PATH}/commercial_shipping_address`, {
      commercialShippingAddressId,
      commercialShippingAddress,
    });
  },
  deleteCommercialShippingAddress(commercialShippingAddressId?: number) {
    return Api.delete(`${MEDSPA_V4_PATH}/commercial_shipping_address/${commercialShippingAddressId}`);
  },
};

export default AccountSettings;
