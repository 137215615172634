import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  arrowIcon: {
    display: 'flex',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    padding: '0',
    margin: 'auto 5px',
    border: '0',
    borderRadius: '50%',
    backgroundColor: '#12574d',
  },

  arrowIconDisabled: {
    backgroundColor: '#0000001F',
    color: '#12574d',
  },
  paginationButton: {
    width: '35px',
    height: '35px',
    border: '1px solid #ccc',
    margin: 'auto 5px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#E7EEED',
    color: '#12574d',
  },
  paginationButtonActive: {
    backgroundColor: '#12574d',
    color: '#E7EEED',
    width: '40px',
    height: '40px',
  },
}));
