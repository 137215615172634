import MessinaSansLightWoff2 from '../assets/fonts/MessinaSans-Light.woff2';
import MessinaSansRegularWoff2 from '../assets/fonts/MessinaSans-Regular.woff2';
import MessinaSansBoldWoff2 from '../assets/fonts/MessinaSans-Bold.woff2';
import MessinaSansSemiBoldWoff2 from '../assets/fonts/MessinaSans-SemiBold.woff2';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';

export const messinaLight = {
  fontFamily: 'Messina Sans Light',
  fontStyle: 'normal',
  fontWeight: '300',
  src: `
    url(${MessinaSansLightWoff2}) format('woff2')
  `,
  unicodeRange:
    /* eslint-disable-next-line max-len */
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const messinaRegular = {
  fontFamily: 'Messina Sans Regular',
  fontStyle: 'normal',
  fontWeight: '400',
  src: `
    url(${MessinaSansRegularWoff2}) format('woff2')
  `,
  unicodeRange:
    /* eslint-disable-next-line max-len */
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const messinaBold = {
  fontFamily: 'Messina Sans Bold',
  fontStyle: 'normal',
  fontWeight: '700',
  src: `
    url(${MessinaSansBoldWoff2}) format('woff2')
  `,
  unicodeRange:
    /* eslint-disable-next-line max-len */
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const messinaSemiBold = {
  fontFamily: 'Messina Sans SemiBold',
  fontStyle: 'normal',
  src: `
    url(${MessinaSansSemiBoldWoff2}) format('woff2')
  `,
  unicodeRange:
    /* eslint-disable-next-line max-len */
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const robotoMedium = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  src: `
    local('Roboto'),
    local('Roboto-Medium.ttf'),
    url(${RobotoMedium}) format('ttf')
  `,
  unicodeRange:
    /* eslint-disable-next-line max-len */
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
// eslint-disable-next-line no-undef
const robotoMediumFaceDescriptors: FontFaceDescriptors = {
  style: robotoMedium.fontStyle,
  unicodeRange: robotoMedium.unicodeRange,
};
if (process.env.NODE_ENV !== 'test') {
  const roboto2 = new FontFace(
    robotoMedium.fontFamily,
    robotoMedium.src.split(/\s+/).find((srcPart) => srcPart.startsWith('url')),
    robotoMediumFaceDescriptors
  );
  roboto2.load().then((newFont) => document.fonts.add(newFont));
}
