import React from 'react';
import moment from 'moment';
import { CardContent } from '@material-ui/core';
import { InsertInvitation as InsertInvitationIcon } from '@material-ui/icons';
import { Card, Title } from '../common/card';
import { ShortMultipleSkeleton } from '../common/LoadingSkeleton';
import { useStyles } from './nextAppointment.styles';
import { useNextAppointment } from '../../hooks/queries/useNextAppointment';

type NextAppointmentProps = {
  patientId: string;
};

const NextAppointment: React.FC<NextAppointmentProps> = ({ patientId }: NextAppointmentProps) => {
  const classes = useStyles();
  const { data: nextAppointment, isLoading } = useNextAppointment(Number(patientId));

  return (
    <Card>
      {isLoading ? (
        <CardContent style={{ padding: '12px 15px' }}>
          <ShortMultipleSkeleton length={2} />
        </CardContent>
      ) : (
        <CardContent className={classes.cardContent}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title title="Next Appointment" style={{ marginTop: '0', marginBottom: '0' }} />
          </div>
          {nextAppointment ? (
            <div className={classes.appointmentSection}>
              <div className={classes.titleSection}>
                <InsertInvitationIcon className={classes.icon} />
                <span className={classes.title}>{nextAppointment.name}</span>
              </div>
              <span className={classes.title}>
                {moment(nextAppointment.datetime).format('dddd, MMMM D, YYYY - hh:mm A')}
              </span>
            </div>
          ) : (
            <div className={classes.appointmentSection}>
              <div className={classes.titleSection}>
                <InsertInvitationIcon className={classes.icon} />
                <span className={classes.title}>No Appointments scheduled yet.</span>
              </div>
            </div>
          )}
        </CardContent>
      )}
    </Card>
  );
};

function areEqual(prevProps: { patientId: string }, nextProps: { patientId: string }) {
  return prevProps.patientId === nextProps.patientId;
}

export default React.memo(NextAppointment, areEqual);
