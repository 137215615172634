import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useStyles } from './ModalDeleteDraft.styles';
import { ButtonFooter, useStyles as supplyReceiptStyles } from '../supplyReceipt.styles';
import { dispatch } from '../../../../rematch';

const ModalDeleteDraft = ({ onSuccess }: any) => {
  const classes = useStyles();
  const classesName = supplyReceiptStyles();
  const [open, setOpen] = useState(false);
  const [discardInProgress, setDiscardInProgress] = useState(false);
  const { supplyReceiptId }: any = useParams();

  const discard = () => {
    setDiscardInProgress(true);
    dispatch.newSupplyReceipt.removeDraft({ supplyReceiptId, onSuccess });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <ButtonFooter
        data-cy="deleteBtn"
        onClick={handleClickOpen}
        variant="outlined"
        className={classesName.buttonDeleteItems}
      >
        DELETE
      </ButtonFooter>
      <Dialog open={open} onClose={handleClose}>
        <div data-cy="deleteModal" className={classes.containerDialog}>
          <DialogTitle className={classes.title}>Discard supply receipt draft?</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.content}>
              By discarding the current supply receipt you will lose your progress.
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button data-cy="noBtn" onClick={handleClose} className={classes.buttonLeft}>
              NO
            </Button>
            <Button data-cy="yesBtn" onClick={discard} className={classes.buttonRight} disabled={discardInProgress}>
              {discardInProgress ? <CircularProgress /> : `YES, DISCARD`}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
};

export default ModalDeleteDraft;
