import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { dispatch } from '../../../rematch';
import { PRACTITIONER_BASE_PATH } from '../../../routes/practitionerRoutes';
import { ADMIN_MEDSPA, ADMIN_PRACTITIONER_LIST_PATH } from '../../../routes/administratorRoutes';
import { MEDSPA_ADMIN_BASE_PATH, MEDSPA_ADMIN_PRACTITIONERS } from '../../../routes/medspaAdminRoutes';
import { ADMIN_ROLE, MEDSPA_ADMIN_ROLE } from '../../../constants/general.constants';
import { MEDSPA_PROVIDER_BASE_PATH } from '../../../routes/medspaPractitionerRoutes';

const MasqueradeBar = () => {
  const history = useHistory();
  const location = useLocation();
  const [hideNavBar, setHideNavBar] = useState(
    !history.location.pathname.includes(PRACTITIONER_BASE_PATH) &&
      !history.location.pathname.includes(MEDSPA_PROVIDER_BASE_PATH) &&
      !history.location.pathname.includes(MEDSPA_ADMIN_BASE_PATH)
  );

  const { isLoading } = useSelector(({ auth }: any) => auth);
  const isMedspaActive = process.env.REACT_APP_MEDSPA_FIELDS === 'true';

  useEffect(() => {
    setHideNavBar(
      !history.location.pathname.includes(PRACTITIONER_BASE_PATH) &&
        !history.location.pathname.includes(MEDSPA_PROVIDER_BASE_PATH) &&
        !history.location.pathname.includes(MEDSPA_ADMIN_BASE_PATH)
    );
  }, [location]);

  const logOut = (event: any) => {
    event.preventDefault();
    dispatch.masquerade.masqueradeBack({
      callback: (masqueradeIdentity: any) => {
        const isMedspaRole =
          masqueradeIdentity.userType === ADMIN_ROLE && masqueradeIdentity.roleName === MEDSPA_ADMIN_ROLE;
        const showMedspa = isMedspaActive && isMedspaRole;
        const adminPath =
          process.env.REACT_APP_HIDE_OLD_PRACTITIONERS_UI !== 'true' ? ADMIN_PRACTITIONER_LIST_PATH : ADMIN_MEDSPA;
        const redirectPath = showMedspa ? MEDSPA_ADMIN_PRACTITIONERS : adminPath;

        history.push(redirectPath);
      },
    });
  };

  // @ts-ignore
  const practitioner: any = JSON.parse(localStorage.getItem('masqueradeIdentity'));

  if (hideNavBar || !practitioner) {
    return null;
  }

  return (
    <Box paddingY={0.5} paddingX={2} display="flex" alignItems="center" bgcolor="#F5FFC0">
      <Box mr={2}>
        <p>
          You&apos;re logged in as {practitioner.firstName} {practitioner.lastName}
        </p>
      </Box>
      <Box>
        <Button data-cy="logOutBtn" onClick={logOut} disabled={isLoading} variant="outlined" size="small">
          LOGOUT {isLoading && <CircularProgress style={{ marginLeft: '10px' }} size={18} />}
        </Button>
      </Box>
    </Box>
  );
};

export default MasqueradeBar;
