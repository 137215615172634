import * as Sentry from '@sentry/react';
import { ICustomerStandingOrder } from 'src/interfaces/IStandingOrder';
import { isNil, omitBy } from 'lodash';
import Api from './Api';
import ROUTES from '../constants/apiRoutes.constants';
import {
  ISaveIMedicalChartingsParams,
  IDeleteServiceVisitAssetParams,
  ISaveSelectedServicesParams,
  ISaveServiceVisitAssetParams,
  ProgressSeriesPayload,
  IUpdateLineItemParams,
} from '../interfaces/params/IServiceVisitParams';
import IServiceVisit, { ILineItem, IServiceVisitPhoto, IMedicalCharting } from '../interfaces/IServiceVisits';
import { ApplyServiceCreditParams } from '../hooks/serviceVisit/useServiceVisitCredits';
import { ApplyServiceReferralCreditParams } from '../hooks/serviceVisit/useReferrals';
import { IPhysicianServiceVisitResponse } from '../interfaces/ICustomer';
import { PhysicianServiceVisitQueryParams } from '../hooks/serviceVisit/useServiceVisit';

export const IN_PERSON_VISIT_TYPE = 'in_person';
export const ACCOUNT_CHARGE_VISIT_TYPE = 'account_charge';

export const ServiceVisit = {
  async getServiceVisitSessionId({ patientId, serviceVisitId }: { patientId: number; serviceVisitId: number }) {
    const { serviceVisitProgress, error } = await Api.get(
      `/customers/${patientId}/service-visit-progress/${serviceVisitId}`
    );

    if (error) {
      throw error;
    }
    return serviceVisitProgress.serviceVisitSessionId;
  },

  async updateServiceVisitSessionId({
    patientId,
    serviceVisitId,
    serviceVisitSessionId,
    successCallback,
  }: {
    patientId: number;
    serviceVisitId: number;
    serviceVisitSessionId: string;
    successCallback?: () => void;
  }) {
    const { success, error } = await Api.post(
      `/customers/${patientId}/update-service-visit-session-id/${serviceVisitId}`,
      {
        serviceVisitSessionId,
      }
    );
    if (error) {
      const messageError = (error as string).toString();
      // @ts-ignore
      Sentry.captureMessage(messageError, 'debug' as Sentry.Severity);

      throw error;
    }
    successCallback?.();
    return success;
  },

  async fetchSaveProgress({ body, patientId, serviceVisitId }: ProgressSeriesPayload) {
    const { success, error } = await Api.post(
      `/customers/${patientId}/update-service-visit-progress/${serviceVisitId}`,
      { payload: JSON.stringify(body) }
    );

    if (error) {
      throw error;
    }

    return success;
  },
  async rescanAsset({
    serviceVisitId,
    assetId,
    newAssetId,
  }: {
    serviceVisitId: number;
    assetId: number;
    newAssetId: number;
  }) {
    const { success, error } = await Api.post(`/service_visits/${serviceVisitId}/rescan_asset`, {
      assetId,
      newAssetId,
    });

    if (error) {
      throw error;
    }

    return success;
  },
  /* Given a service visit id return the list of selected services */
  async createServiceVisitVersion(params: {
    customerId: number;
  }): Promise<{ success: boolean; serviceVisit: IServiceVisit }> {
    return Api.post(ROUTES.SERVICE_VISIT_CREATE_VERSION, params);
  },
  /* Given a service visit id return the list of selected services */
  async getSelectedServices(id: number): Promise<{ success: boolean; lineItems: ILineItem[] }> {
    return Api.get(ROUTES.SERVICE_VISIT_SELECTED_SERVICES(id));
  },
  /* Save new selected service in model */
  async saveServiceSelected(id: number, params: ISaveSelectedServicesParams) {
    return Api.post(ROUTES.SERVICE_VISIT_SELECTED_SERVICES(id), params);
  },
  /* Remove selected service in model checkout line item */
  async removeServiceSelected(id: number, params: ISaveSelectedServicesParams) {
    return Api.delete(`${ROUTES.SERVICE_VISIT_SELECTED_SERVICES_REMOVE(id)}?service_id=${params.serviceId}`);
  },
  /* Return all service visit history from patient */
  async getFullServiceHistory(id: number, params: { lastClosed?: boolean; visitType?: string } = {}) {
    const { serviceVisits } = await Api.get(ROUTES.SERVICE_VISIT_FULL_HISTORY(id), params);
    return serviceVisits;
  },
  async getServiceVisitAssetsScanned(id: number) {
    const { serviceVisitAssets } = await Api.get(ROUTES.SERVICE_VISIT_ASSETS_SCANNED(id));
    return serviceVisitAssets;
  },
  async saveServiceVisitAssetsScanned(id: number, params: ISaveServiceVisitAssetParams) {
    const { serviceVisitAssets } = await Api.post(ROUTES.SERVICE_VISIT_ASSETS_SCANNED(id), params);
    return serviceVisitAssets;
  },
  async deleteServiceVisitAsset(id: number, params: IDeleteServiceVisitAssetParams) {
    const { serviceVisitAssets } = await Api.post(ROUTES.SERVICE_VISIT_ASSETS_SCANNED_DESTROY(id), params);
    return serviceVisitAssets;
  },
  async getServiceVisit(id: number): Promise<IServiceVisit> {
    const { serviceVisit } = await Api.get(ROUTES.SERVICE_VISIT(id));
    return serviceVisit;
  },
  async getServiceVisitDiscounts(id: number) {
    const { discountsPerLineItem } = await Api.get(ROUTES.SERVICE_VISIT_DISCOUNTS(id));
    return discountsPerLineItem;
  },
  async getCheckoutTenders(id: number) {
    const { checkoutTenders } = await Api.get(ROUTES.SERVICE_VISIT_CHECKOUT_TENDERS(id));
    return checkoutTenders;
  },
  async addCheckoutTender(id: number, params: any) {
    const response = await Api.post(ROUTES.SERVICE_VISIT_CHECKOUT_TENDERS(id), params);

    if (!response?.success) {
      throw new Error(response.message);
    }

    return response.success;
  },
  async getServiceVisitCredits(id: number) {
    const { credits } = await Api.get(ROUTES.SERVICE_VISIT_CREDITS(id));
    return credits;
  },
  async getAppliedServiceVisitCredits(id: number) {
    const { creditsApplied } = await Api.get(ROUTES.APPLIED_SERVICE_VISIT_CREDITS(id));
    return creditsApplied;
  },
  async getAppliedServiceVisitReferralCredits(id: number) {
    const { referralCredits } = await Api.get(ROUTES.APPLIED_SERVICE_VISIT_REFERRAL_CREDITS(id));
    return referralCredits;
  },
  async getMedicalChartings(id: number): Promise<IMedicalCharting> {
    const { medicalCharting } = await Api.get(ROUTES.SERVICE_VISIT_ANNOTATED_PHOTOS(id));
    return medicalCharting;
  },
  async saveMedicalCharting(id: number, params: ISaveIMedicalChartingsParams) {
    const { success, message } = await Api.post(ROUTES.SERVICE_VISIT_ANNOTATED_PHOTOS(id), params);
    if (!success) {
      throw new Error(message);
    }

    return success;
  },
  async applyServiceVisitCredits(id: number, params: ApplyServiceCreditParams) {
    const { success, message } = await Api.post(ROUTES.SERVICE_VISIT_CREDITS(id), params);
    if (!success) {
      throw new Error(message);
    }

    return success;
  },
  async applyServiceVisitReferralCredits(id: number, params: ApplyServiceReferralCreditParams) {
    const { success, message } = await Api.put(ROUTES.SERVICE_VISIT_REFERRAL_CREDITS(id), params);
    if (!success) {
      throw new Error(message);
    }

    return success;
  },
  async fetchAspireCertificates(id: number, galdermaLocations: string[]) {
    return Api.get(ROUTES.SERVICE_VISIT_ASPIRE_CERTIFICATES(id), { galdermaLocations });
  },
  async fetchAspireCertificatesUsed(id: number) {
    const response = await Api.get(ROUTES.SERVICE_VISIT_ASPIRE_CERTIFICATES_USED(id));

    if (!response.success) {
      throw new Error(response.message);
    }

    return response;
  },
  async validateAspireCertificates(id: number, params: any) {
    const response = await Api.put(ROUTES.SERVICE_VISIT_ASPIRE_VALIDATE_CERTIFICATES(id), params);

    if (!response.success) {
      throw new Error(response.message);
    }

    return response;
  },
  async sendAspireCode(params: any) {
    const response = await Api.post(ROUTES.SERVICE_VISIT_ASPIRE_SEND_CODE, params);

    if (!response?.success) {
      throw new Error(response?.message);
    }

    return response;
  },
  async redeemAspirePoints(params: any) {
    const response = await Api.post(ROUTES.SERVICE_VISIT_ASPIRE_REDEEM_CODE, params);

    if (!response?.success) {
      throw new Error(response?.message);
    }

    return response;
  },
  async customerStandingOrder(id: number): Promise<ICustomerStandingOrder[]> {
    return Api.get(ROUTES.SERVICE_VISIT_CUSTOMER_STANDING_ORDER(id));
  },
  async serviceVisitStandingOrder(id: number): Promise<any> {
    return Api.get(ROUTES.SERVICE_VISIT_STANDING_ORDERS(id));
  },
  async signServiceVisitStandingOrder(id: number, params: any): Promise<any> {
    return Api.post(ROUTES.SERVICE_VISIT_STANDING_ORDERS(id), params);
  },
  async serviceVisitPhotos(id: number): Promise<IServiceVisitPhoto[]> {
    const { photos } = await Api.get(ROUTES.SERVICE_VISIT_PHOTOS(id));
    return photos;
  },
  async saveServiceVisitPhotos(id: number, formData: FormData): Promise<{ success: boolean }> {
    return Api.post(ROUTES.SERVICE_VISIT_PHOTOS(id), formData);
  },
  async serviceVisitConsents(id: number): Promise<any> {
    const response = await Api.get(ROUTES.SERVICE_VISIT_CONSENTS(id));
    return response;
  },
  async signServiceVisitConsent(id: number, params: any): Promise<any> {
    return Api.post(ROUTES.SIGN_SERVICE_VISIT_CONSENTS(params.patientId, params.serviceGroupId), params);
  },
  async payStagingServiceVisit(id: number): Promise<{ success: boolean }> {
    return Api.post(ROUTES.SERVICE_VISIT_PAY_STAGING(id), {});
  },
  async payZeroBalanceServiceVisit(id: number): Promise<{ success: boolean }> {
    return Api.post(ROUTES.SERVICE_VISIT_POST_MOVEMENTS(id), {});
  },
  async payForServiceVisit({
    id,
    cardId,
    cashAmount,
    patientId,
    tipAmount,
    isFeeCharge = false,
  }: {
    id: number;
    cardId?: string;
    cashAmount?: number;
    patientId: number;
    tipAmount: number;
    isFeeCharge?: boolean;
  }): Promise<{ success: boolean }> {
    return isFeeCharge
      ? Api.post(ROUTES.SERVICE_VISIT_FEE_PAY_WITH_CARD_OR_CASH(patientId), {
        cash_amount: cashAmount,
        credit_card_id: cardId,
        service_visit_id: id,
      })
      : Api.post(ROUTES.SERVICE_VISIT_PAY_WITH_CARD_OR_CASH(patientId), {
        cash_amount: cashAmount,
        credit_card_id: cardId,
        service_visit_id: id,
        tip_amount: tipAmount,
      });
  },
  async refundStagingServiceVisit(id: number): Promise<{ success: boolean }> {
    return Api.post(ROUTES.SERVICE_VISIT_REFUND_STAGING(id), {});
  },
  async refundServiceVisit(id: number, params: any): Promise<{ success: boolean }> {
    return Api.post(ROUTES.SERVICE_VISIT_REFUND(id), params);
  },
  async addServiceVisitNote(id: number, params: any): Promise<{ success: boolean }> {
    return Api.post(ROUTES.SERVICE_VISIT_ADD_NOTE(id), params);
  },
  async addMedicalDirectorNote(id: number, params: any): Promise<{ success: boolean }> {
    return Api.post(ROUTES.SERVICE_VISIT_ADD_MEDICAL_DIRECTOR_NOTE(id), params);
  },
  async submitServiceVisit(id: number): Promise<{ success: boolean }> {
    return Api.post(ROUTES.SERVICE_VISIT_SUBMIT(id), {});
  },
  async setGFEDiagnosisAcceptedServiceVisit(id: number): Promise<{ success: boolean }> {
    return Api.post(ROUTES.SERVICE_VISIT_GFE_DIAGNOSIS_ACCEPTED(id), {});
  },
  async deleteServiceVisit(id: number): Promise<{ success: boolean }> {
    return Api.delete(ROUTES.SERVICE_VISIT_VERSION(id));
  },
  async updateLineItem(id: number, checkoutLineItemId: number, params: IUpdateLineItemParams) {
    return Api.put(ROUTES.SERVICE_VISIT_UPDATE_LINE_ITEM(id, checkoutLineItemId), params);
  },
  async toggleSave(id: number): Promise<void> {
    return Api.put(ROUTES.SERVICE_VISIT_TOGGLE_SAVE, { id });
  },
  async getPhysicianServiceVisitsByProvider(
    paramsQuery: PhysicianServiceVisitQueryParams
  ): Promise<IPhysicianServiceVisitResponse> {
    const queryString = new URLSearchParams(omitBy(paramsQuery, isNil)).toString();
    return Api.get(`${ROUTES.PHYSICIAN_PROVIDER_CLOSED_SERVICE_VISITS}?${queryString}`);
  },
};

export default ServiceVisit;
