import React, { useState, useEffect, useContext, createContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Box, Button, Typography, Slide, useMediaQuery } from '@material-ui/core';
import { DISPLAY_DATE_FORMAT, FULLSCREEN, DEFAULT_TIMEOUT } from 'src/constants/inventory.constants';
import { ROUTES } from 'src/constants/routes.constants';
import { IOrderingAllowed } from '../../../../interfaces/IInventoryOrder';
import { useStyle as bannerStyle } from './inventoryOrder.styles';

const OrderingStatusContext = createContext<IOrderingAllowed | undefined>(undefined);

const OrderBanner: React.FC = (): React.ReactElement | null => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [dismissed, setDismissed] = useState<boolean>(false);

  const orderingStatus = useContext(OrderingStatusContext);

  const history = useHistory();
  const location = useLocation();

  const matches = useMediaQuery(FULLSCREEN);
  const classes = bannerStyle();

  const dismiss = (redirect: boolean = false): number => {
    setCollapsed(true);
    return +setTimeout((): void => {
      setDismissed(true);
      redirect && history.push(ROUTES.NEW_ORDER);
    }, DEFAULT_TIMEOUT);
  };

  const alreadyThere = location.pathname === ROUTES.NEW_ORDER;

  useEffect((): (() => void) => {
    let timeoutId: number | false = false;

    if (orderingStatus?.active) {
      if (collapsed && !dismissed && !alreadyThere) {
        timeoutId = +setTimeout((): void => setCollapsed(false), DEFAULT_TIMEOUT);
      } else {
        timeoutId = alreadyThere && !dismissed && dismiss();
      }
    }

    return (): void => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [alreadyThere, collapsed, dismissed, orderingStatus]);

  return orderingStatus?.active && !dismissed ? (
    <Slide direction="down" enter exit in={!collapsed} timeout={{ enter: 500, exit: DEFAULT_TIMEOUT }}>
      <Box className={classes.orderingBanner} data-mobile={!matches} data-testid="order banner">
        <Typography className={classes.orderBannerLabel}>
          Submit your inventory orders before {moment(orderingStatus?.endDate).format(DISPLAY_DATE_FORMAT)}
        </Typography>
        <Button data-testid="order banner dismiss" variant="outlined" onClick={() => dismiss(true)}>
          Place Order
        </Button>
        <Button data-testid="order banner skip" variant="outlined" onClick={() => dismiss()}>
          Skip
        </Button>
      </Box>
    </Slide>
  ) : null;
};

export { OrderingStatusContext, OrderBanner, OrderBanner as default };
