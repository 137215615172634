import { withStyles, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SquareButton = withStyles(() => ({
  root: {
    borderRadius: '0px',
    width: '100%',
    textAlign: 'left',
    paddingLeft: 0,
    justifyContent: 'flex-start'
  },
}))(IconButton);

const feedbackStyles = makeStyles(() => ({
  dismissed: {
    opacity: 0.2
  },
  sel: {
    marginTop: '18px'
  },
  navbar: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '10px'
  },
  pager: {
    margin: 'auto 15px auto 0px',
    minWidth: '230px'
  },
  pager2: {
    margin: 'auto 15px auto auto',
    minWidth: '250px'
  },
  searchbox: {
    margin: 'auto 15px auto 15px'
  },
  search: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '14px',
  },
  header: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '18px',
    fontWeight: 600,
    color: '#949494',
    paddingLeft: 5,
    margin: 'auto auto auto 15px'
  },
  topdownload: {
    margin: 'auto 0 '
  },
  download: {
    margin: 'auto 0px auto auto',
  },
  download2: {
    margin: 'auto 0px auto 15px',
  },
  csvicon: {
    fontSize: '22px',
    overflow: 'visible'
  },
  theader: {
    cursor: 'pointer',
    borderRadius: '2px',
    borderTop: '1px dotted #fff0',
    borderleft: '1px solid #eeeeee00',
    borderRight: '1px solid #eeeeee00',
    paddingBottom: 0
  },
  tsort: {
    cursor: 'pointer',
    borderRadius: '2px',
    borderTop: '1px dotted',
    borderLeft: '1px solid #eeeeee44',
    borderRight: '1px solid #eeeeee66',
    backgroundColor: '#eeeeee33',
    transitionDuration: '0.1s'
  },
  selectform: {
    minWidth: '250px',
    margin: 'auto auto auto 20px'
  },
  chevron: {
    display: 'block',
    marginBottom: '5px'
  },
  readun: {
    fontSize: '12px',
    paddingLeft: '8px'
  },
  feedbackRow: {
    minHeight: '100px',
    height: '100px'
  },
  highlightCell: {
    borderLeft: '1px solid #0002'
  }
}))

const rowStyle = makeStyles(() => ({
  actionButton: {
    fontSize: '16px',
    fontFamily: 'Messina Sans SemiBold',
    width: '98%',
    height: '36px',
    borderColor: '#ccc',
    color: '#12574d',
  }
}))

export { SquareButton, rowStyle, feedbackStyles, feedbackStyles as default }