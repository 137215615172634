import { useQuery } from 'react-query';
import { ADMINS_LIST, ADMIN_USER_INFO } from '../../constants/reactQuery.keys';
import { AdminUser } from '../../services/AdminUser';

export function useCustomAdminUsers() {
  const {
    data: adminUsers = [],
    isLoading,
    isFetching,
    isError,
  } = useQuery([ADMINS_LIST], () => AdminUser.getAdminUsers());

  return {
    adminUsers,
    isLoading,
    isFetching,
    isError,
  };
}

export function useCustomAdminUserInfo(id: number) {
  const {
    data: adminUserInfo = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      active: true,
      roleId: 0,
      userGroupId: undefined,
    },
    isLoading,
    isError,
    isFetched,
  } = useQuery([ADMIN_USER_INFO], () => AdminUser.getAdminUserInfo(id));

  return {
    adminUserInfo,
    isLoading,
    isError,
    isFetched,
    ADMINS_LIST,
  };
}

export function useCustomEmptyAdminUserInfo() {
  return {
    adminUserInfo: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      active: true,
      roleId: 0,
      userGroupId: undefined,
    },
    isLoading: false,
    isError: false,
    isFetched: false,
    ADMINS_LIST: '',
  };
}
