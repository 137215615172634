import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { dispatch } from '../rematch';
import { downloadAssetPdf, getAssetsPages } from '../utils/datamatrix';

const useStyles = makeStyles(() => ({
  progress: {
    color: 'black',
    margin: 'auto',
    display: 'block',
    marginTop: '21px',
    marginLeft: '417px',
  },
}));

const PdfDatamatrixGeneration = () => {
  const { id }: any = useParams();
  const classes = useStyles();
  const { purchaseOrder } = useSelector((state: any) => state.purchaseOrders);
  const isLoading = useSelector((state: any) => state.loading.effects.purchaseOrders.getPurchaseOrder);

  let assets: any[] = [];
  purchaseOrder.purchaseOrderItems?.forEach((item: any) => {
    assets = assets.concat(item.assets);
  });

  useEffect(() => {
    if (isLoading === false && purchaseOrder.id) {
      const pagesAssetsList = getAssetsPages(assets);

      if (!pagesAssetsList.length) {
        return;
      }

      const { id: purchaseOrderId, supplierName, referenceNumber, createdAt } = purchaseOrder;
      const fileConcatName = `${moment(createdAt).format(
        'YYYY-MM-DD'
      )}_${purchaseOrderId}_${supplierName}-${referenceNumber}`;

      downloadAssetPdf(pagesAssetsList, fileConcatName, () => {
        setTimeout(() => window.close(), 100);
      });
    }
  }, [isLoading, purchaseOrder]);

  useEffect(() => {
    dispatch({
      type: 'purchaseOrders/getPurchaseOrder',
      payload: { purchaseOrderId: id },
    });
  }, [id]);

  return (
    <>
      <Box width="100%">
        <div className={classes.progress}>
          <CircularProgress size={30} />
        </div>
      </Box>
    </>
  );
};

export default PdfDatamatrixGeneration;
