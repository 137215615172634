import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { EHRTypography } from 'src/components/ui/v1';

const useStyles = makeStyles((theme) => ({
  thirdBox: {
    width: '20%',
    textAlign: 'end',
    [theme.breakpoints.down('sm')]: {
      width: '135px',
    },
  },
  labelContainer: {
    width: '40%',
    paddingRight: '1rem',
  },
  dataLabelContainer: {
    width: '40%',
  },
  emptyContainer: {
    width: '20%',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0.75rem 0',
  },
}));

interface UserInfoRowProps {
  label: string;
  userData?: string;
  description?: string;
  content?: React.ReactNode;
  third?: React.ReactNode;
}

export const UserInfoRow: React.FC<UserInfoRowProps> = ({ label, userData, description, content, third }) => {
  const classes = useStyles();
  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.labelContainer}>
        <EHRTypography variant="readonly" dataCy={`user-info-row-${label}`}>
          {label}
        </EHRTypography>
        {description && (
          <EHRTypography variant="description" dataCy={`admin-list-${description}`}>
            {description}
          </EHRTypography>
        )}
      </Box>
      <Box className={classes.dataLabelContainer}>
        {!content ? (
          <EHRTypography variant="readonly" dataCy={`user-info-row-${userData}`}>
            {userData}
          </EHRTypography>
        ) : (
          content
        )}
      </Box>

      {third ? <Box className={classes.thirdBox}>{third}</Box> : <Box className={classes.emptyContainer} />}
    </Box>
  );
};
