import React from 'react';
import { useSelector } from 'react-redux';
import Photos from '../PatientProfile/Photos';
import { dispatch } from '../../rematch';
import { ActiveStepWrapper } from './ActiveStepWrapper';
import {
  MAX_ITEMS_PHOTOS_LIMIT_MOBILE,
  ITEMS_PHOTOS_LIMIT_DESKTOP,
  WIDTH_MAX_DESKTOP,
} from '../../constants/general.constants';
import useWindowDimensions from '../../utils/useWindowDimensions';
import useLog from '../../hooks/mutations/useLog';

const cardContentStyle = {
  padding: '11px 20px 20px 20px',
};

export const HistoricalPhotos = () => {
  const patientPhotos = useSelector(({ patient }: any) => patient.photos);
  const { width } = useWindowDimensions();
  const getPhotos = () => patientPhotos;

  const { mutateAsync: log } = useLog();

  const setCurrentStep = () => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 3 });
    log({
      feature: 'Service Visit',
      step: 3,
      logId: null,
      params: {
        patientPhotos,
      },
    });
  };

  return (
    <ActiveStepWrapper step={3} onClick={setCurrentStep}>
      <Photos
        inputFileId="historicalPhoto"
        cardStyles={{ marginBottom: '0' }}
        cardContentStyle={cardContentStyle}
        title="All photos"
        photos={getPhotos()}
        isLoading={false}
        showUploadPhotoButton={false}
        itemsPhotos={width > WIDTH_MAX_DESKTOP ? ITEMS_PHOTOS_LIMIT_DESKTOP : MAX_ITEMS_PHOTOS_LIMIT_MOBILE}
      />
    </ActiveStepWrapper>
  );
};
