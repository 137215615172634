import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      '& > div#root': {
        height: '100%',
      },
    },
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#E3E3E3',
  },
  navigationContainer: {
    flex: '1',
    maxHeight: '64px',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  headerTitle: {
    marginTop: '0',
    marginBottom: '0',
    marginRight: '10px',
    fontWeight: 'bold',
  },
  subtitle: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '10px',
    marginBottom: '0px',
  },
  card: {
    margin: '15px',
    paddingBottom: '24px !important',
    padding: '24px 30px',
    background: '#F5F5F5',
    borderRadius: '9px',
    marginBottom: '10px',
    border: 'none',
  },
  headerCard: {
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: '18px',
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  cardsContainer: {
    display: 'flex',
    gap: '15px',
    flexWrap: 'wrap',
    marginBottom: '2rem',
  },
  emptyCreditCards: {
    margin: '16px 0 30px',
    display: 'flex',
    alignItems: 'center',
    color: '#12574D',
    border: '1px solid gray',
    borderRadius: '9px',
    textAlign: 'center',
    width: '308px',
    height: '170px',
    padding: '24px 12px',
  },
}));
