import { createModel } from '@rematch/core';
import { logSentryException } from 'src/services/Sentry';
import { logDatadogException } from 'src/services/Datadog';
import { CoverImageState } from '../types/CoverImageState';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import compile from '../utils/toastMessagesCompiler';

export const coverImage = createModel<RootModel>()({
  state: {
    servicesToAdd: [],
    services: [],
    loading: false,
  } as CoverImageState,
  reducers: {
    updateState(state: any, payload: any) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch: any) => ({
    async loadServices(_payload: any, _rootState: any) {
      try {
        const response = await axiosInstance.get(`/services`);

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        dispatch.coverImage.updateState({ services: response.data.services });
      } catch (err) {
        const error = err as Error;
        logSentryException(error);
        logDatadogException(error);
      }
    },
    async addAnnotatedPhotos(payload: { image: any; forAnnotatedPhotos: any; visit: any }, _rootState: any) {
      const { updateState } = dispatch.coverImage;
      try {
        const { image, forAnnotatedPhotos, visit } = payload;

        updateState({ loading: true });
        const data = [
          {
            photo_id: image.id,
            photo_services: forAnnotatedPhotos,
          },
        ];

        await axiosInstance.post(`/service-visits/${visit.id}/photo-services-handler-new`, {
          photos: data,
          charges: forAnnotatedPhotos,
        });

        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.added'),
          },
        });
      } catch (err) {
        const error = err as Error;
        logSentryException(error);
        logDatadogException(error);
      }
      updateState({ loading: false });
    },
    async updateServiceNote(
      payload: {
        // eslint-disable-next-line camelcase
        photo_service_id: number;
        note: string;
        units: string;
        // eslint-disable-next-line camelcase
        service_visit_id: number;
      },
      _rootState: any
    ) {
      try {
        await axiosInstance.put(`/service-visits/${payload.service_visit_id}/photo-services`, { ...payload });

        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Service',
              action: 'updated',
            }),
            duration: 2000,
          },
        });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: error,
            type: 'error',
          },
        });
      }
    },
  }),
});
