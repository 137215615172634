import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AddAPhoto } from '@material-ui/icons';
import { orderBy } from 'lodash';
import { dispatch } from '../../rematch';
import { useStyles } from './photoRequest.styles';
import { Card } from '../common/card';
import { Button } from '../common/Button';
import { getUserTypePropertyId } from '../../utils/patient.util';
import { PHOTO_REQUEST_MESSAGE } from '../../constants/medicalProfile.constants';
import IConversationThread, { IMessage } from '../../interfaces/IConversationThread';

const buttonStyles = { color: '#000000', backgroundColor: '#e9e9e9', height: '37px' };
const altButtonStyles = {
  color: '#12574d',
  backgroundColor: '#f0f0f0',
  height: '37px',
  border: 'solid 1px rgba(0, 0, 0, 0.12)',
};

const PhotoRequest = () => {
  const classes = useStyles();
  const { userType } = useSelector(({ auth }: any) => auth);
  const { id, messagesThreads, isLoading, photoRequestReSent } = useSelector(({ patient }: any) => patient);

  const resendPhotoRequest = () => {
    dispatch({
      type: 'patient/requestPhoto',
      payload: { patientId: id, isReSend: true, userTypePropertyId: getUserTypePropertyId(userType) },
    });
  };

  const clearPhotoRequest = (threadId: number) => {
    dispatch({ type: 'patient/clearPhotoRequest', payload: { patientId: id, threadId } });
  };

  const setBackgroundColor = ({ administratorId, physicianId }: any) => {
    if (administratorId) {
      return '#F2D1AB';
    }
    if (physicianId) {
      return '#f5ffc0';
    }

    return '#ffffff';
  };

  const setUserRoleName = ({ administratorId, physicianId }: any) => {
    if (administratorId) {
      return 'Administrator';
    }
    if (physicianId) {
      return 'Physician';
    }

    return 'Practitioner';
  };

  const isAThreadOfCurrentUser = (thread: any) => !!thread[getUserTypePropertyId(userType)];

  if (isLoading) {
    return null;
  }

  return messagesThreads
    .filter(({ open }: IConversationThread) => open)
    .map((thread: any) => {
      const lastMessage = orderBy(thread.messages, ({ id: messageId }: IMessage) => messageId, ['asc']).pop();

      if (lastMessage?.content !== PHOTO_REQUEST_MESSAGE) {
        return null;
      }

      const style = thread.administratorId ? altButtonStyles : buttonStyles;

      return (
        <Card style={{ backgroundColor: setBackgroundColor(thread) }}>
          <div className={classes.appointmentSection}>
            <div className={classes.titleSection}>
              <AddAPhoto className={classes.icon} />
              <span className={classes.title}>{`${setUserRoleName(thread)} Photos Requested`}</span>
            </div>
            {isAThreadOfCurrentUser(thread) && (
              <div>
                <Button
                  title={photoRequestReSent ? 'REQUEST SENT' : 'RESEND REQUEST'}
                  style={style}
                  onClick={resendPhotoRequest}
                  disabled={photoRequestReSent}
                />
                <Button
                  title="CLEAR"
                  style={style}
                  onClick={() => {
                    clearPhotoRequest(thread.id);
                  }}
                />
              </div>
            )}
            <span className={classes.title}>{moment(lastMessage.createdAt).format('dddd, MMMM D, YYYY')}</span>
          </div>
        </Card>
      );
    });
};

export default PhotoRequest;
