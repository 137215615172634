import Api from './Api';
import ROUTES from '../constants/apiRoutes.constants';

export interface IPractitionerRegistrationData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  token: string;
}

export interface IPractitionerRegistrationPasswordParams {
  password: string;
  passwordConfirmation: string;
  token: string;
}

export interface IPractitionerRegistration {
  id: number;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  fullName: string;
  lastName: string;
  email: string;
  phone: string;
  signedMsaAgreement: boolean;
  paidDeposit: boolean;
  squareLocationId: string;
  status: string;
  sentMsaAgreement: string;
}

export const PractitionerRegistrations = {
  async sendResetPassword(email: string): Promise<null> {
    return Api.post(ROUTES.RESET_PASSWORD_PRACTITIONER_REGISTRATIONS, { email });
  },
  async createPractitionerRegistration(data: IPractitionerRegistrationData): Promise<IPractitionerRegistration> {
    return Api.post(ROUTES.CREATE_PRACTITIONER_REGISTRATION, data);
  },
  async setPassword(data: IPractitionerRegistrationPasswordParams): Promise<any> {
    return Api.put(ROUTES.SET_PASSWORD_PRACTITIONER_REGISTRATION, data);
  },
  async createPractitionerInvitation(roleId: number): Promise<any> {
    return Api.post(ROUTES.CREATE_PRACTITIONER_INVITATION, { roleId });
  },
};
