import { createModel } from '@rematch/core';
import { ScannerState } from '../types/ScannerState';
import { RootModel } from './rootModel';
import axiosInstance from '../utils/axios';
import { toSnakeCase } from '../utils/global';
import compile from '../utils/toastMessagesCompiler';

export const scanner = createModel<RootModel>()({
  state: {
    code: '',
    asset: undefined,
    isSearchingAsset: false,
  } as ScannerState,
  reducers: {
    setCode(state: ScannerState, payload: string) {
      return { ...state, code: payload };
    },
    setAsset(state: any, payload: object[] | undefined) {
      return { ...state, asset: payload };
    },
    setIsSeachingAsset(state: any, payload: boolean) {
      return { ...state, isSearchingAsset: payload };
    },
  },
  effects: (dispatch) => ({
    async checkAssetByInternalSerialNumber(payload: {
      internalSerialNumber: string;
      prefix?: string;
      isInReceipt?: boolean;
      isAssetRecover?: boolean;
      toWarehouseId?: string;
      identityId: string;
      callback?: (obj: any) => {};
    }) {
      try {
        dispatch.scanner.setIsSeachingAsset(true);
        const { internalSerialNumber, prefix, isInReceipt, isAssetRecover, toWarehouseId, identityId, callback } =
          payload;
        const params = {
          internalSerialNumber,
          prefix,
          isInReceipt,
          isAssetRecover,
          identityId,
          ...(toWarehouseId && { toWarehouseId }),
        };
        const { data } = await axiosInstance.get(`assets/${internalSerialNumber}/check`, {
          params: toSnakeCase(params),
        });

        if (data.success === false && data.message) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: data.message,
              type: 'error',
            },
          });

          return;
        }

        dispatch.scanner.setAsset(data.asset);
        if (callback) {
          callback(data.asset);
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'checking',
              element: 'the serial number',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.scanner.setIsSeachingAsset(false);
      }
    },
  }),
});
