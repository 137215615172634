import { createModel } from '@rematch/core';
import { orderBy } from 'lodash';
import { IReconciliationReport } from '../interfaces/reconciliation.interfaces';
import axiosInstance from '../utils/axios';
import { addProductListToReconciliationReport } from '../utils/inventory.utils';
import { TypeSort } from '../utils/types';
import { RootModel } from './rootModel';

export const inventoryReconciliation = createModel<RootModel>()({
  state: {
    orderByDate: 'asc',
    inventory: [],
    isLoading: false,
  },
  reducers: {
    getInventoryItems(state: any, payload: IReconciliationReport[]) {
      const inventory = addProductListToReconciliationReport(payload);
      return { ...state, inventory };
    },
    sortByDate(state: any, payload: TypeSort) {
      const inventory = orderBy(state.inventory, [(object: any) => new Date(object.date)], [payload]);
      return { ...state, inventory };
    },
    setSort(state: any, payload: TypeSort) {
      return { ...state, orderByDate: payload };
    },
    closeReport(state: any, payload: any) {
      const report = state.inventory.find(({ id }: any) => id === payload);
      report.status = 'closed';
      return { ...state };
    },
    setLoading(state: any, payload: boolean) {
      return { ...state, isLoading: payload };
    },
    removeDraft(state: any) {
      return {
        ...state,
        inventory: state.inventory.filter((report: any) => report.status !== 'draft'),
      };
    },
  },
  effects: (dispatch: any) => ({
    async getInventory({ practitionerId }: any) {
      try {
        dispatch.inventoryReconciliation.setLoading(true);
        const response = await axiosInstance.get(`/reconciliation-reports-by-practitioner/${practitionerId}`);
        if (response.status !== 200) {
          throw new Error(response.data.message);
        }
        if (response.data.error) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: response.data.error,
              type: 'error',
            },
          });
          return;
        }
        dispatch.inventoryReconciliation.getInventoryItems(response.data.reconciliationReports);
      } finally {
        dispatch.inventoryReconciliation.setLoading(false);
      }
    },
  }),
});
