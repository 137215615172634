/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import { TextField } from '@material-ui/core';
import { debounce } from 'lodash';
import { useStyles } from '../NewServiceVisit/servicesUnits.styles';
import { ILineItem } from '../../interfaces/IServiceVisits';

const DEBOUNCE_INPUT_TIME = 1000;

interface Props {
  id: number;
  lineItem: ILineItem;
  label: string;
  onChangeUnit: (payload: any) => void;
  disabled: boolean;
  inputName?: 'currentUseQuantity' | 'futureUseQuantity';
  variantItemId?: number;
  minAmount?: number;
}

export const ServiceUnitsInput = ({
  lineItem,
  id,
  label,
  disabled,
  onChangeUnit,
  inputName = 'currentUseQuantity',
  variantItemId,
  minAmount,
}: Props) => {
  const classes = useStyles();
  // State needed for debounce effect
  const [value, setValue] = useState(lineItem[inputName].toString());
  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (event.key === '.' || event.key === ',') {
      event.preventDefault();
    }
  };

  const saveLineItemUnits = useCallback(
    debounce((payload: any) => {
      onChangeUnit(payload);
    }, DEBOUNCE_INPUT_TIME),
    []
  );

  const lineItemUnits = {
    currentUseQuantity: lineItem.currentUseQuantity,
    futureUseQuantity: lineItem.futureUseQuantity,
    serviceId: id,
    variantItemId,
  };
  // is the same for now, until new features
  const maxValueAllowed = minAmount || 500;

  const isValidVariantUnit = (units: number) =>
    !(minAmount && units !== 0 && (units < minAmount || units > maxValueAllowed));

  const onChange = (e: any) => {
    const newValue = +e.target.value;
    if (minAmount && !isValidVariantUnit(newValue)) {
      // Prevent invalid variant units
      return;
    }

    if (newValue <= maxValueAllowed) {
      setValue(newValue.toString());
      saveLineItemUnits({
        ...lineItemUnits,
        [inputName]: newValue,
      });
    }
  };

  return (
    <TextField
      inputProps={{
        min: '0',
        max: maxValueAllowed,
        step: minAmount || 1,
        onKeyDown: (e) => {
          onKeyDown(e);
        },
        onClick: (e) => e.currentTarget.select(),
      }}
      label={label}
      value={value.toString()}
      type="number"
      variant="outlined"
      onChange={onChange}
      className={classes.textField}
      error={minAmount ? !isValidVariantUnit(lineItem[inputName]) : lineItem[inputName] < 0}
      helperText={
        minAmount
          ? !isValidVariantUnit(lineItem[inputName])
            ? `Minimum ${minAmount}`
            : ' '
          : lineItem[inputName] < 0
            ? '0 or more'
            : ' '
      }
      disabled={disabled}
      fullWidth
      style={{ marginRight: '10px' }}
    />
  );
};
