import React from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../../rematch';
import Scanner from '../../../common/Scanner';
import { handleAssetSelected } from '../../../../utils/handleAssetsSelected';
import { IAsset } from '../../../../interfaces/reconciliation.interfaces';
import compile from '../../../../utils/toastMessagesCompiler';

const ProductScanner = ({ scannerInputRef }: any) => {
  const { services, productsSelected, supplyReceipt, warehouses } = useSelector((state: any) => state.newSupplyReceipt);

  const autoSaveForm = ({ newProductsSelected }: any) => {
    let assetsIdsSelected: number[] = [];

    newProductsSelected.forEach(({ assets }: any) => {
      assetsIdsSelected = [...assetsIdsSelected, ...assets.map(({ id }: any) => id)];
    });

    dispatch({
      type: 'newSupplyReceipt/updateSupplyReceipt',
      payload: {
        supplyReceipt,
        assets: assetsIdsSelected,
      },
    });
  };

  const addProduct = ({ asset }: any) => {
    try {
      const newProductsSelected = handleAssetSelected({ asset, services, productsSelected });
      dispatch({ type: 'newSupplyReceipt/setProducts', payload: newProductsSelected });
      autoSaveForm({ newProductsSelected });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.element_not_found', {
            element: 'Product',
          }),
          type: 'error',
        },
      });
    }
  };

  const handleAssetChecked = (asset: IAsset) => {
    if (asset.warehouseId === supplyReceipt.fromWarehouseId) {
      addProduct({ asset });
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.product_added'),
        },
      });
    } else {
      const name = warehouses.find(({ id }: any) => id === supplyReceipt.fromWarehouseId)?.name;
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: name
            ? compile('admin_dashboard.item_not_in_warehouse', { name })
            : compile('admin_dashboard.select_from_warehouse'),
          type: 'error',
        },
      });
    }
  };

  return <Scanner successCallback={handleAssetChecked} scannerInputRef={scannerInputRef} isInReceipt />;
};

export default ProductScanner;
