import Api from './Api';

const BASE_PATH = '/reconciliation-reports';

const createDraft = async (): Promise<any> => {
  const { reconciliationReport, error } = await Api.post(`${BASE_PATH}/create_draft`, {});

  if (error) {
    throw error;
  }

  return reconciliationReport;
};

export const getDraft = async (practitionerId: number): Promise<any> => {
  const { reconciliationReport, error } = await Api.get(`${BASE_PATH}/${practitionerId}/draft_report`);
  if (error) {
    throw error;
  }
  return reconciliationReport;
};
const addItem = async (reportId: number, data: any): Promise<any> => {
  const { reconciliationReport, error } = await Api.post(`${BASE_PATH}/${reportId}/create_item`, data);

  if (error) {
    throw error;
  }

  return reconciliationReport;
};

const submitReport = async (practitionerId: number, body: any): Promise<any> => {
  const { reconciliationReport, error } = await Api.post(`${BASE_PATH}/${practitionerId}/practitioner-save`, body);
  if (error) {
    throw error;
  }

  return reconciliationReport;
};

export const removeDraft = async (): Promise<any> => {
  const { reconciliationReport, error } = await Api.delete(`${BASE_PATH}/remove_draft`);
  if (error) {
    throw error;
  }
  return reconciliationReport;
};

export default {
  createDraft,
  getDraft,
  addItem,
  removeDraft,
  submitReport,
};
