import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { EHRSelect, EHRTextField, EHRTypography } from 'src/components/ui/v1';
import IState from 'src/interfaces/IState';
import { Close as CloseIcon, Check as CheckIcon } from '@material-ui/icons';

import { Box, makeStyles } from '@material-ui/core';
import { LicenseDetails } from 'src/interfaces/onboarding.interface';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  removeButton: {
    position: 'absolute',
    bottom: '20px',
    right: '-20px',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    color: 'grey',
  },
});

interface LicenseNumberDetailsProps {
  states: IState[];
  onUpdate: (license: LicenseDetails[]) => void;
  errors?: string;
}

const LicenseNumberDetails = ({ states, onUpdate, errors = '' }: LicenseNumberDetailsProps) => {
  const classes = useStyles();
  const [licenseNumber, setLicenseNumber] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');
  const [licenses, setLicenses] = useState<LicenseDetails[]>([]);

  const resetFields = () => {
    setLicenseNumber('');
    setSelectedState('');
  };

  const handleAddLicense = () => {
    if (!!licenseNumber && !!selectedState) {
      const newLicenses = [...licenses, { licenseNumber, state: selectedState }];
      setLicenses(newLicenses);
      resetFields();
      onUpdate(newLicenses);
    }
  };

  const handleRemoveLicense = (index: number) => {
    const newLicenses = licenses.filter((_, i) => i !== index);
    setLicenses(newLicenses);
    onUpdate(newLicenses);
  };

  return (
    <Box>
      <Box margin="10px 0">
        <EHRTypography variant="body2" dataCy="" style={{ color: 'grey' }}>
          * Please enter any relevant medical license numbers.
        </EHRTypography>
      </Box>
      <Box margin="10px 0">
        <EHRTypography variant="body2" dataCy="" style={{ color: 'grey' }}>
          Once you enter both your license number and state, click the check icon to add it to the list.
        </EHRTypography>
      </Box>
      {licenses.map((license, index) => (
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={6}>
            <EHRTextField dataCy="" value={license.licenseNumber} disabled />
          </Grid>
          <Grid item xs={6}>
            <EHRTextField dataCy="" value={license.state} disabled />
          </Grid>
          <Box className={classes.removeButton} onClick={() => handleRemoveLicense(index)}>
            <CloseIcon />
          </Box>
        </Grid>
      ))}
      <Grid container spacing={2} className={classes.container} style={{ marginTop: '20px' }}>
        <Grid item xs={6}>
          <EHRTextField
            dataCy=""
            label="Medical Director License Number"
            helperText=""
            tooltip=""
            value={licenseNumber}
            onChange={(event) => setLicenseNumber(event.target.value || '')}
            error={!!errors}
          />
        </Grid>
        <Grid item xs={6}>
          <EHRSelect
            data-testid="select-medspa-state"
            dataCy="select-medspa-state"
            displayEmpty
            onChange={(value: string | number) => setSelectedState(value.toString() || '')}
            value={selectedState}
            fullWidth
            label="State"
            error={!!errors}
          >
            {(states || []).map((state: IState) => (
              <MenuItem key={state.name} value={state.name}>
                {state.name}
              </MenuItem>
            ))}
          </EHRSelect>
        </Grid>
        <Box
          className={classes.removeButton}
          onClick={() => handleAddLicense()}
          style={!!licenseNumber && !!selectedState ? { color: 'green' } : { color: 'grey' }}
        >
          <CheckIcon />
        </Box>
      </Grid>
      <Box>
        <EHRTypography variant="body2" dataCy="license-number-details-error" style={{ color: 'red' }}>
          {!!errors && errors}
        </EHRTypography>
      </Box>
    </Box>
  );
};

export default LicenseNumberDetails;
