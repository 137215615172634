import React, { useState } from 'react';
import { Box, MenuItem, Tooltip, makeStyles, withStyles } from '@material-ui/core';
import { Edit as EditIcon, Check as CheckIcon, KeyboardArrowDown } from '@material-ui/icons';
import moment from 'moment';
import classnames from 'classnames';
import { EHRTextFieldProps } from './EHRTextField';
import { EHRSelect as Select } from './EHRSelect';

export const EHRSelectIcon = () => (
  <KeyboardArrowDown
    className="MuiSelect-icon"
    viewBox="6 6 12 12"
    style={{
      width: '1.35rem',
      height: '0.9rem',
      transform: 'translateY(50%)',
    }}
  />
);

const EHRSelect = withStyles(() => ({
  root: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem !important',
    width: '2rem',
  },
}))(Select);

const HOUR_OPTIONS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const getMinutes = () => {
  const options = [];
  for (let i = 0; i < 60; i++) {
    options.push(i < 10 ? `0${i}` : i);
  }
  return options;
};
const MINUTE_OPTIONS = getMinutes();

const useStyles = makeStyles(() => ({
  containerSelected: {
    color: '#545252',
    border: 'solid 1.5px #c8c8c8',
    height: '2.5rem',
    lineHeight: '2.5rem',
    borderRadius: '5px',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    display: 'flex',
    margin: 'auto',
    width: '5.7rem',
    justifyContent: 'center',
    fontFamily: 'Messina Sans Regular',
    fontSize: '94%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '68px',
  },
  disabled: {
    background: '#eeeeee',
  },
  ehrTimePicker: {
    width: '46px',
    height: '40px',
  },
  amPmPicker: {
    width: '3.1rem',
    paddingLeft: '1px',
    height: '40px',
  },
  timeSeparator: {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  iconButton: {
    cursor: 'pointer',
    fontSize: '120%',
    paddingLeft: '0.2rem',
    paddingTop: '0.1rem',
  },
}));

interface EHRTimePickerProps extends EHRTextFieldProps {
  onEditInput: (value: boolean) => void;
  edit: boolean;
}

export default function EHRTimePickerSelector(props: EHRTimePickerProps) {
  const classes = useStyles();
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [amPm, setAmPm] = useState('AM');

  const { dataCy, value, onEditInput, edit, onChange, disabled, ...restProps } = props;

  const onChangeHour = (newValue: string) => {
    setHour(newValue);
  };
  const onChangeMinutes = (newValue: string) => {
    setMinute(newValue);
  };
  const onChangeAmPm = (newValue: string) => {
    setAmPm(newValue);
  };

  React.useEffect(() => {
    const componentValue = moment(`${hour}:${minute} ${amPm}`, 'hh:mm A').format('LT');

    if (value && value !== componentValue) {
      const newValue = moment(value, 'hh:mm A');
      setHour(newValue.format('hh'));
      setMinute(newValue.format('mm'));
      setAmPm(newValue.format('A'));
    }
  }, [value]);

  const onCheck = () => {
    const newValue = moment(`${hour}:${minute} ${amPm}`, 'hh:mm A').format('LT');
    onChange?.({ target: { value: newValue } } as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>);
    onEditInput(false);
  };

  if (!edit) {
    return (
      <Box className={classnames(classes.containerSelected, { [classes.disabled]: disabled })}>
        {hour || '--'}:{minute || '--'}
        {amPm}
        {!disabled && (
          <Box className={classes.iconButton} onClick={() => onEditInput(true)}>
            <EditIcon fontSize="inherit" />
          </Box>
        )}
      </Box>
    );
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.ehrTimePicker}>
        <EHRSelect
          {...restProps}
          dataCy={dataCy}
          fullWidth
          value={hour}
          onChange={onChangeHour}
          IconComponent={EHRSelectIcon}
        >
          {HOUR_OPTIONS.map((option) => (
            <MenuItem value={option}>{option}</MenuItem>
          ))}
        </EHRSelect>
      </Box>
      <Box className={classes.timeSeparator}>:</Box>
      <Box className={classes.ehrTimePicker}>
        <EHRSelect
          {...restProps}
          dataCy={dataCy}
          fullWidth
          value={minute}
          onChange={onChangeMinutes}
          IconComponent={EHRSelectIcon}
        >
          {MINUTE_OPTIONS.map((min) => (
            <MenuItem value={min}>{min}</MenuItem>
          ))}
        </EHRSelect>
      </Box>
      <Box className={classes.amPmPicker}>
        <EHRSelect
          {...restProps}
          dataCy={dataCy}
          fullWidth
          value={amPm}
          onChange={onChangeAmPm}
          IconComponent={EHRSelectIcon}
        >
          {['AM', 'PM'].map((type) => (
            <MenuItem value={type}>{type}</MenuItem>
          ))}
        </EHRSelect>
      </Box>
      <Box className={classes.iconButton} onClick={onCheck} style={{ marginTop: '0.4rem' }}>
        <Tooltip title="Confirm">
          <CheckIcon color="primary" style={{ background: '#eeeeee', borderRadius: '5px' }} />
        </Tooltip>
      </Box>
    </Box>
  );
}
