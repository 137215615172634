import { createModel } from '@rematch/core';
import { filter } from 'lodash';
import { RootModel } from './rootModel';
import axiosInstance from '../utils/axios';
import { ReferralsState } from '../types/Referrals';
import compile from '../utils/toastMessagesCompiler';
import { hasAccessTo } from '../utils/auth.utils';
import { READ_REFERRAL } from '../constants/actions.constants';

const REFERRALS_INITIAL_STATE: {
  referrals: any[] | null;
  notRedeemed: any[] | null;
  redeemed: any[] | null;
  isLoadingReferrals: boolean | null;
  isLoadingRedeemedCustomers: boolean | null;
} = {
  referrals: [],
  notRedeemed: [],
  redeemed: [],
  isLoadingReferrals: false,
  isLoadingRedeemedCustomers: false,
};

export const referrals = createModel<RootModel>()({
  state: REFERRALS_INITIAL_STATE as ReferralsState,
  reducers: {
    setReferrals(state: any, payload: object[]) {
      return { ...state, referrals: payload };
    },
    setNotRedeemed(state: any, payload: object[]) {
      return { ...state, notRedeemed: payload };
    },
    setRedeemed(state: any, payload: object[]) {
      return { ...state, redeemed: payload };
    },
    setLoadingReferrals(state: any, payload: boolean) {
      return { ...state, isLoadingReferrals: payload };
    },
    setIsLoadingRedeemedCustomers(state: any, payload: boolean) {
      return { ...state, isLoadingRedeemedCustomers: payload };
    },
  },
  effects: (dispatch) => ({
    async getReferrals(payload: any) {
      try {
        dispatch.referrals.setLoadingReferrals(true);
        const { params } = payload;
        const request = await axiosInstance.get('/referrals', { params });
        dispatch.referrals.setReferrals(request.data.referrals);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'referrals',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.referrals.setLoadingReferrals(false);
      }
    },
    async setReferredBy(payload: { customerId: number; referredById: number; onSuccess: () => void }) {
      try {
        const { customerId, referredById, onSuccess } = payload;
        const request = await axiosInstance.post('/referrals/create', { customerId, referredById });
        dispatch({ type: 'patient/getPatientSuccess', payload: { basicInfo: request.data.basic } });
        onSuccess();
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'setting',
              element: 'the referral',
            }),
            type: 'error',
          },
        });
      }
    },
    async getRedeemedCustomers(payload: { customerId: number }, rootState: any) {
      try {
        const { permissions } = rootState.auth;
        if (!hasAccessTo(READ_REFERRAL, permissions)) {
          return;
        }
        dispatch.referrals.setIsLoadingRedeemedCustomers(true);
        const { customerId } = payload;
        const {
          data: { referralCredits },
        } = await axiosInstance.get(`/referrals/customers/${customerId}`);

        dispatch.referrals.setNotRedeemed(filter(referralCredits, ({ redeemed }: any) => !redeemed));
        dispatch.referrals.setRedeemed(filter(referralCredits, ({ redeemed }: any) => redeemed));
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'redeemed',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.referrals.setIsLoadingRedeemedCustomers(false);
      }
    },
  }),
});
