import React, { useEffect, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { dispatch } from '../../../rematch';
import { formatCurrency } from '../../../utils/formatNumber';

import compile from '../../../utils/toastMessagesCompiler';

import { useStyles } from './AccountCredit.styles';

interface Props {
  accountCreditUsed: number | string;
  onChange: (val: number | string) => void;
  currentBalance: number;
  toPay: number;
  showCurrentBalance: boolean;
  saveOnBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AccountCredit = ({
  accountCreditUsed,
  toPay,
  currentBalance,
  showCurrentBalance,
  onChange,
  saveOnBlur,
}: Props) => {
  const classes = useStyles();

  const hasAccountCredit = currentBalance > 0;

  const [currentBalanceAfterUseCredit, setCurrentBalanceAfterUseCredit] = useState<number>(0);

  useEffect(() => {
    setCurrentBalanceAfterUseCredit(accountCreditUsed ? currentBalance - Number(accountCreditUsed) : currentBalance);
  }, [currentBalance, accountCreditUsed]);

  const validateAmount = ({ floatValue: newAmount = 0 }: NumberFormatValues) => {
    const exceedCurrentBalance = newAmount > currentBalance;
    const exceedToPay = newAmount - Number(accountCreditUsed) > toPay;

    if (exceedCurrentBalance) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.account_credit_exceed'),
          type: 'info',
        },
      });
      onChange('');
      return false;
    }
    if (exceedToPay) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.to_pay_exceed'),
          type: 'info',
        },
      });
      onChange('');
      return false;
    }

    return true;
  };

  return (
    <div className={classes.mainSection}>
      <div className={classes.ghostTitle}>Other Tenders</div>
      <div className={classes.tendersSection}>
        <div className={classes.section}>
          <div className={classes.title}>Account Credit</div>

          <div className={classes.creditsAvailable}>
            {showCurrentBalance && <>Total Credit Available: {formatCurrency(+currentBalanceAfterUseCredit)}</>}
          </div>
          <NumberFormat
            decimalScale={2}
            autoComplete="off"
            placeholder="Amount"
            disabled={!hasAccountCredit}
            value={accountCreditUsed || ''}
            onChange={(e) => {
              e.preventDefault();
              onChange(e.target.value);
            }}
            onBlur={saveOnBlur}
            isAllowed={validateAmount}
            className={classes.input}
            type="tel"
          />
        </div>
      </div>
    </div>
  );
};
