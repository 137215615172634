import React from 'react';
import { Box } from '@material-ui/core';
// import { SwapHoriz as SwapHorizIcon } from '@material-ui/icons';
import AspireLogo from '../../assets/aspire-logo.jpg';
import { useStyles } from './points.styles';

interface Props {
  onSwap: () => void;
}

export const AspireTenderLogo = (props: Props) => {
  const { onSwap } = props;
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <Box className={classes.title} display="flex" alignItems="center">
        <Box mr={1}>ASPIRE</Box> <img src={AspireLogo} alt="Aspire Galderma Rewards" width="30" height="30" />
        <Box
          component="span"
          ml={1}
          width="23px"
          height="23"
          bgcolor="#e7eeed"
          display="inline-flex"
          alignItems="center"
          onClick={() => onSwap()}
        >
          {/* <SwapHorizIcon /> */}
        </Box>
      </Box>
    </div>
  );
};
