import Api from './Api';

const BASE_PATH = '/v3/medical_profile';

export const markAsSeen = async ({ patientId }: any): Promise<number | undefined> => {
  const { medicalProfile, error } = await Api.post(`${BASE_PATH}/${patientId}/mark_as_seen`, {});
  if (error) {
    throw error;
  }
  return medicalProfile;
};

export const BulkMedicalProfilesUpload = async (data: any): Promise<any> => {
  const res = await Api.post(`${BASE_PATH}/bulk_medical_profiles_upload`, data);
  if (res.error) {
    throw res.error;
  }

  return res;
};

export const BulkSkinProfilesUpload = async (data: any): Promise<any> => {
  const res = await Api.post(`${BASE_PATH}/bulk_skin_profiles_upload`, data);
  if (res.error) {
    throw res.error;
  }

  return res;
};
