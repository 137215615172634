import { TextField, TextFieldProps } from '@material-ui/core';
import React, { useState } from 'react';
import { useStyle } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { Done, Edit } from '@material-ui/icons';

type InputProps = TextFieldProps & {
  testId: string;
  pending: boolean;
  editMode: boolean;
  setEditMode: (newValue: boolean) => void;
  onSave: () => void;
  step?: number;
};

export const EditableOrderingValue = ({
  testId,
  value,
  onSave,
  onChange,
  pending,
  editMode,
  setEditMode,
  type = 'text',
  step,
}: InputProps) => {
  const classes = useStyle();
  const [editing, setEditing] = useState<boolean>(false);

  const disabled = !pending || !editMode || !editing;

  const toggleEdit = () => {
    setEditMode(!editMode);
    setEditing(!editing);
  };

  const handleSave = () => {
    setEditing(false);
    onSave();
  };

  const buttons = !editing ? (
    <Edit
      className={classes.approveEdit}
      viewBox="0 0 22 22"
      onClick={toggleEdit}
      data-testid={`${testId}.edit`}
      data-cy={`${testId}.edit`}
    />
  ) : (
    <Done
      className={classes.approvalSave}
      viewBox="0 0 22 22"
      onClick={handleSave}
      data-testid={`${testId}.save`}
      data-cy={`${testId}.save`}
    />
  );

  return (
    <>
      <TextField
        value={value}
        onChange={onChange}
        className={classes.qtyInput}
        inputProps={{
          ...(!!step && type === 'number' ? { step } : {}),
          disableUnderline: disabled,
        }}
        disabled={disabled}
        type={type}
        data-testid={`${testId}.input`}
        data-cy={`${testId}.input`}
      />
      {!!pending && buttons}
    </>
  );
};
