import React, { FC, useEffect, useState } from 'react';
import papa from 'papaparse';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useStyles } from './index.styles';
import Header from '../Header';
import Footer from '../Footer';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH, PRACTITIONER_HOME_PATH } from '../../../routes/practitionerRoutes';
import PatientDataTable, { IPatientData } from '../../BulkUpload/PatientDataTable';
import { useBulkUpload } from '../../../hooks/mutations/useBulkUpload';
import { PATIENT_TYPE, BULK_UPLOAD_TYPES } from '../../../constants/bulkUpload.constants';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import { useUpdateProfileProgress } from '../../../hooks/mutations/useUpdateProfileProgress';
import { usePractitionerInfo } from '../../../hooks/queries/usePractitioners';

const PatientBulkUpload: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: identityId, userId, user } = useSelector(({ auth }: any) => auth);
  const { practitionerInfo } = usePractitionerInfo(userId, identityId);
  const [data, setData] = useState<IPatientData[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const buklUploadMutatition = useBulkUpload();
  const [openMappingDialog, setOpenMappingDialog] = useState(false);
  const [selectedMappings, setSelectedMappings] = useState<{ csvField: string; ehrField: string }[]>([]);
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [csvValues, setCsvValues] = useState<IPatientData[]>([]);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [uploadedFileSize, setUploadedFileSize] = useState<string>('');
  const updateProfileProgressMutation = useUpdateProfileProgress();
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (practitionerInfo?.isEhrReady) {
      history.replace(PRACTITIONER_HOME_PATH);
    }
  }, [practitionerInfo]);

  const downloadCSVTemplate = () => {
    const csvHeaders = PATIENT_TYPE.fields.map((field) => field.displayName);
    const csvExampleValues = PATIENT_TYPE.fields.map((field) => `"${PATIENT_TYPE.example[field.name]}"`);
    const csvData = `${csvHeaders.join(',')}\n${csvExampleValues.join(',')}\n`;
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = csvUrl;
    link.setAttribute('download', `${PATIENT_TYPE.name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatFileSize = (sizeInBytes: any) => {
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];

    if (sizeInBytes === 0) {
      return '0 Byte';
    }
    const i = parseInt(String(Math.floor(Math.log(sizeInBytes) / Math.log(1024))), 10);

    return `${Math.round(10 * (sizeInBytes / 1024 ** i)) / 10} ${sizes[i]}`;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const file = files[0];

      setUploadedFileName(file.name);
      setUploadedFileSize(formatFileSize(file.size));
      papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results: any) => {
          setCsvFields(Object.keys(results.data[0]));
          setCsvValues(results.data);
          setOpenMappingDialog(true);
        },
      });
    }
  };

  const uploadData = async () => {
    try {
      setIsSaving(true);
      const res = await buklUploadMutatition.mutateAsync({
        dataType: PATIENT_TYPE?.type,
        data,
        practitionerId: user.id,
      });

      await updateProfileProgressMutation.mutateAsync({ stepName: 'patient-bulk-upload' });

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: `Patients`,
            action: 'uploaded',
          }),
        },
      });
      setData([]);
      if (res.errors && res.errors.length > 0) {
        setShowErrorModal(true);
      }
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'uploading',
            element: `the ${PATIENT_TYPE?.name}`,
          }),
          type: 'error',
        },
      });
    } finally {
      setIsSaving(false);
    }
  };

  const saveMappingsHandler = () => {
    const allMappingsSelected = PATIENT_TYPE.fields.every((field) =>
      selectedMappings.some((mapping) => mapping.csvField === field.displayName)
    );

    if (allMappingsSelected) {
      const cleanedData = csvValues.filter(
        (entry: any) =>
          !PATIENT_TYPE.fields.every((field) => {
            const value = entry[field.name] || entry[field.displayName];
            return value === '' || value === null;
          })
      );

      const processedData = cleanedData.map((entry: any) => {
        const processedEntry: any = {};
        selectedMappings.forEach((mapping) => {
          processedEntry[mapping.ehrField] = entry[mapping.csvField];
        });

        if (PATIENT_TYPE.type === BULK_UPLOAD_TYPES.PATIENT_PROFILES) {
          processedEntry.Practitioner = user.email;
        }

        return processedEntry;
      });

      setData(processedData);

      setSelectedMappings([]);
      setOpenMappingDialog(false);
    }
  };

  const handleSubmit = () => {
    history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('calendar-configuration'));
  };

  return (
    <>
      <Header currentStepName="Patient bulk upload" currentStep={3} />
      <Box
        width="100%"
        display="flex"
        height="100%"
        justifyContent="center"
        flexDirection="column"
        minHeight="500px"
        alignItems="center"
      >
        <Box paddingX={2}>
          <Box>
            {data && data.length > 0 ? (
              <div>
                <PatientDataTable data={data} setData={setData} selectedFileType={PATIENT_TYPE} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Button variant="contained" color="primary" component="span" onClick={() => setData([])}>
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => uploadData()}
                    disabled={isSaving}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5" className={classes.title}>
                  Welcome to the Patients List Bulk Upload feature!
                </Typography>
                <div className={classes.textContainer}>
                  <p className={classes.text}>
                    If you have a pre-existing list of patients that you&apos;d like to efficiently import into your EHR
                    dashboard and seamlessly generate their Patient Profiles, follow these simple steps:
                  </p>
                  <ol>
                    <li>
                      <strong>Download the CSV Template:</strong> Start by downloading the provided CSV template.
                    </li>
                    <li>
                      <strong>Fill in the Information:</strong> Complete the template with the necessary details for
                      each patient. If you don&apos;t have all the information at the moment, no worries! You can skip
                      those fields and return to fill them in later through the Account Configurations tab. Just simply
                      select &ldquo;Skip for now&ldquo;.
                    </li>
                    <li>
                      <strong>Initiate the Upload:</strong> Once the CSV file is filled out, you&apos;re ready to begin
                      the upload process.
                    </li>
                  </ol>
                  <p className={classes.text}>
                    This streamlined process ensures that your patient data is quickly integrated into your EHR
                    dashboard, saving you time and effort.
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    height: '100%',
                    margin: 'auto',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <label key={PATIENT_TYPE.type} htmlFor={`file-input-${PATIENT_TYPE.type}`}>
                      <input
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        id={`file-input-${PATIENT_TYPE.type}`}
                        onChange={(event) => handleFileUpload(event)}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ width: '200px', margin: '10px 0' }}
                      >
                        Upload CSV
                      </Button>
                    </label>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      component="span"
                      onClick={() => downloadCSVTemplate()}
                      style={{ margin: '10px' }}
                      className={classes.button}
                    >
                      Download Template
                    </Button>
                  </div>
                </div>
                <p style={{ textAlign: 'center', fontSize: '12px', color: 'gray' }}>Only .csv format </p>
              </div>
            )}
          </Box>
        </Box>
        <Dialog className={classes.mappingDialog} open={openMappingDialog} onClose={() => setOpenMappingDialog(false)}>
          <DialogTitle>File Upload</DialogTitle>
          <DialogActions className={classes.mappingDialogActions}>
            <Box>
              <Typography variant="subtitle1">{uploadedFileName}</Typography>
              <p style={{ marginTop: '0px' }}>{uploadedFileSize}</p>
            </Box>
            <Button
              onClick={() => saveMappingsHandler()}
              variant="contained"
              color="primary"
              style={{ width: '80px', margin: '0px' }}
            >
              Upload
            </Button>
          </DialogActions>
          <DialogContent>
            <Typography variant="subtitle1">Field Mapping</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {csvFields.map((csvField) => (
                    <TableRow key={csvField}>
                      <TableCell style={{ width: '160px', background: '#F2F2F2' }}>{csvField}</TableCell>
                      <TableCell style={{ width: '460px' }}>
                        <Select
                          value={selectedMappings.find((mapping) => mapping.csvField === csvField)?.ehrField || ''}
                          style={{ display: 'flex', justifyContent: 'end' }}
                          fullWidth
                          label="Select"
                          onChange={(e) => {
                            const selectedField = e.target.value as string;
                            setSelectedMappings((prevMappings) => [
                              ...prevMappings.filter((mapping) => mapping.csvField !== csvField),
                              { csvField, ehrField: selectedField },
                            ]);
                          }}
                        >
                          <MenuItem value="">None</MenuItem>
                          {PATIENT_TYPE.fields.map((field) => (
                            <MenuItem key={field.displayName} value={field.displayName}>
                              {field.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
        <Dialog open={showErrorModal} onClose={() => setShowErrorModal(false)}>
          <DialogTitle className={classes.errorTitle}>Error Encountered</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Some patients information couldn&apos;t be saved due to an error.
              <br />
              An email with details about the affected patients and their errors has been sent.
              <br />
              Please review the information, make corrections, and try uploading again.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowErrorModal(false)} variant="contained" color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Footer
        saveTitle="Continue"
        isSaving={false}
        disabled={isSaving || data.length > 0}
        currentStep={3}
        handleSubmit={handleSubmit}
        backLink={PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management')}
      />
    </>
  );
};

export default PatientBulkUpload;
