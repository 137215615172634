import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { InventoryOrderService } from 'src/interfaces/IInventoryOrder';
import { PINNED_PRODUCTS_AND_SUPPLIERS } from 'src/constants/inventory.constants';
import { sortBy } from 'lodash';
import { ClassNameMap } from 'src/types/Dom';
import { OrderSupplierProductsStyles } from '../inventoryOrder.styles';
import InventoryRecommendationRow from './ServiceSelectionRow';
import OrderSupplierTable from '../OrderSupplierTable';

export default withStyles(OrderSupplierProductsStyles)(
  ({
    classes,
    showAll,
    supplier,
    items,
    RowProps = {},
  }: ClassNameMap & {
    showAll?: boolean;
    supplier: string;
    items: InventoryOrderService[];
    RowProps?: { setBudgetDialogueOpen?: (open: boolean) => void };
  }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const listOrder = PINNED_PRODUCTS_AND_SUPPLIERS[supplier] ?? [];
    const activeProducts = listOrder;
    const sortedProducts = sortBy(items, (item) => {
      if (item.recommendation) {
        activeProducts.push(item.name);
        return item.recommendation * -1 - items.length - 1;
      }
      if (item.currentTotal) {
        activeProducts.push(item.name);
        return item.currentTotal * -1;
      }
      const itemIndex = listOrder.indexOf(item.name);
      return itemIndex < 0 ? item.name.toLowerCase().charCodeAt(0) + (listOrder.length + 1) * 2 : itemIndex;
    });

    const displayProducts =
      expanded || !listOrder.length
        ? sortedProducts
        : sortedProducts.filter((item) => item.quantity > 0 || activeProducts.indexOf(item.name) > -1);

    useEffect(() => {
      showAll && setExpanded(true);
    }, []);

    return (
      <OrderSupplierTable
        classes={classes}
        supplier={supplier}
        showMore={!expanded && !!displayProducts.length && displayProducts.length < sortedProducts.length}
        setExpanded={() => setExpanded(true)}
        testid="order recommend"
        isLegacy
      >
        <>
          {(displayProducts.length ? displayProducts : sortedProducts).map((row: InventoryOrderService) => (
            <InventoryRecommendationRow key={row.name} {...row} {...RowProps} />
          ))}
        </>
      </OrderSupplierTable>
    );
  }
);
