import IState from '../interfaces/IState';
import Api from './Api';

export const getStates = async (): Promise<IState[]> => {
  const { states } = await Api.get('/v3/states');

  return states;
};

export const getCities = async (state?: string): Promise<string[]> => {
  const { cities } = await Api.get(`/v3/cities?state=${state}`);

  return cities || [];
};

export const getCoordinates = async (
  params: any
): Promise<{ latitude: number; longitude: number; formattedAddress?: string }> => {
  const { coords } = await Api.get('/v3/coordinates', params);

  return coords;
};

export const getInfoFromCoords = async (params: any): Promise<any> => {
  const { info } = await Api.get('/v3/info_from_coords', params);

  return info;
};
