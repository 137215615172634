import { EdgeTierChartParams, EdgeTierApi } from '../interfaces/IEdgeTier'
import Api from './Api';

const EdgeTiers = {
  async getTiers(): Promise<EdgeTierChartParams[]> {
    const { success, data }: EdgeTierApi = await Api.get(
      '/portrait_edge_tiers/all'
    )
    return success ? data : {}
  }
}

export { EdgeTiers, EdgeTiers as default }