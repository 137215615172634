import { useQuery, useMutation, useQueryClient, UseQueryResult } from 'react-query';
import { PRACTITIONER_SMS_TEMPLATES } from '../../constants/reactQuery.keys';
import { IPractitionerSMSTemplate, IUpdateSMSTemplate } from '../../interfaces/IPractitionerSMSTemplate';
import { PractitionerSMSTemplates } from '../../services/PractitionerSMSTemplates';

/* practitioner id optional if is PRACTITIONER_TYPE, if is ADMINISTRATOR_TYPE is required */
const usePractitionerSMSTemplates = (
  params?: { practitionerId?: number },
  enabled: boolean = true
): UseQueryResult<IPractitionerSMSTemplate[] | null> =>
  useQuery<IPractitionerSMSTemplate[] | null>({
    queryKey: [PRACTITIONER_SMS_TEMPLATES, JSON.stringify(params)],
    queryFn: (): Promise<IPractitionerSMSTemplate[] | null> => PractitionerSMSTemplates.getTemplates(params),
    enabled,
  });

const useUpdateSMSTemplates = (templateId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ body, active, practitionerId }: IUpdateSMSTemplate): Promise<void> => {
      PractitionerSMSTemplates.updateTemplate({
        templateId,
        body,
        active,
        practitionerId,
      } as IUpdateSMSTemplate);
    },
    onSuccess: (): void => {
      queryClient.invalidateQueries({ queryKey: [PRACTITIONER_SMS_TEMPLATES] });
    },
  });
};

export { useUpdateSMSTemplates, usePractitionerSMSTemplates, usePractitionerSMSTemplates as default };
