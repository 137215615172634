import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    margin: '1rem 2rem 2rem',
    padding: '2rem 8rem 2rem 2rem',
    borderRadius: '8px',
    border: 'solid 1px #C8C8C8',
    minWidth: '800px',
    width: '100%',
  },
  avatar: {
    width: '50px',
    height: '50px',
    marginRight: '16px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  subTitle: {
    color: '#999999',
  },
  providerDetails: {
    marginLeft: '66px',
    display: 'flex',
    alignItems: 'initial',
    justifyContent: 'space-between',
  },
  navigateBack: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 2rem',
    cursor: 'pointer',
  },
  backTitle: {
    marginLeft: '0.5rem',
    fontFamily: 'Messina Sans SemiBold',
  },
}));
