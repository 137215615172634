import { dispatch } from '../rematch';
import toastMessagesCompiler from './toastMessagesCompiler';

export const copyToClipboard = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: { message: toastMessagesCompiler('generic.copy_to_clipboard') },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const copyToClipboardWithCallbacks = (text: string, onSuccess?: () => void, onError?: () => void) => {
  try {
    navigator.clipboard.writeText(text);
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: toastMessagesCompiler('generic.copy_to_clipboard'),
      },
    });
    onSuccess?.();
  } catch (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: toastMessagesCompiler('generic.error_message', {
          action: 'copying',
          element: 'clipboard',
        }),
        type: 'error',
      },
    });
    onError?.();
  }
};
