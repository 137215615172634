import React from 'react';
import classnames from 'classnames';
import { Box, Button, Grid } from '@material-ui/core';
import { SALES_ROUND_ROBIN } from 'src/constants/general.constants';
import { NEW_WINDOW } from 'src/constants/clientLead.constants';
import { LogoAlt } from 'src/components/LogoAlt';
import { useStyles } from './index.styles';
import { PortraitMockUp } from './PortraitMockUp';

export const SignUpWait = () => {
  const classes = useStyles();

  const bookAConsult = SALES_ROUND_ROBIN;

  const openInNewTab = () => window.open(bookAConsult, NEW_WINDOW);

  return (
    <div className={classes.signupContainer}>
      <div className={classes.left}>
        <div className={classes.leftInside}>
          <PortraitMockUp />
        </div>
      </div>
      <div className={classes.right}>
        <div className={classnames(classes.signupDetails, classes.signUpDetailsInBeige)}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={4}>
              <Box className={classes.mobileOnly}>
                <LogoAlt />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box justifyContent="end">
                <div className={classes.btnContainer}>
                  <p>Have a question?</p>
                  <Button onClick={openInNewTab} className={classes.btn}>
                    Book a consult
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.waitingText}>
            Great! Your account is now under review. You will receive an email activation within 24 hours once your
            account is live.
          </Box>
        </div>
      </div>
    </div>
  );
};
