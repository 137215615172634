/* eslint-disable consistent-return */
import {
  Box,
  Button,
  Modal,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Close as CloseIcon, Help as HelpIcon, Warning as WarningIcon } from '@material-ui/icons';
import { RadioButtons } from 'src/ui/RadioButtons/RadioButtons';
import classNames from 'classnames';
import DocumentForm from 'src/components/common/DocMenu/DocumentForm';
import { useConsentTemplates, useUpdatePractitionerConsents } from 'src/hooks/queries/useConsents';
import { showSnackbar } from 'src/utils/global';
import { MEDSPA_ADMIN_ROLE, SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import compile from 'src/utils/toastMessagesCompiler';
import { useCareMessageTemplates, useUpdateCareMessageMutation } from 'src/hooks/queries/useCareMessages';
import { useStandingOrderTemplates, useUpdatePractitionerStandingOrders } from 'src/hooks/queries/useStandingOrders';
import { CONSENTS_TITLE, STANDING_ORDERS_TITLE } from 'src/constants/configuration.constants';
import { useHistory, useLocation } from 'react-router-dom';
import {
  PRACTITIONER_CONFIGURATION_CUSTOMIZE_PATH,
  PRACTITIONER_MESSAGING_CUSTOMIZE_PATH,
} from 'src/routes/practitionerRoutes';
import IConsent from 'src/interfaces/IConsent';
import IStandingOrder from 'src/interfaces/IStandingOrder';
import { ICustomCareMessage } from 'src/interfaces/ICareMessages';
import { useSelector } from 'react-redux';
import {
  useMedspaCareMessageTemplates,
  useMedspaConsentTemplates,
  useMedspaCreateServiceGroupMutation,
  useMedspaDeleteServiceGroupMutation,
  useMedspaStandingOrderTemplates,
  useMedspaUpdateServiceGroupMutation,
} from 'src/hooks/mutations/useMedspaServiceGroups';
import { useMutation } from 'react-query';
import MedspaCareMessages from 'src/services/MedspaCareMessages';
import {
  MEDSPA_ADMIN_SERVICES_CUSTOMIZE,
  MEDSPA_ADMIN_SERVICES_CUSTOMIZE_MESSAGING,
} from 'src/routes/medspaAdminRoutes';
import { useUpdateMedspaConsents } from 'src/hooks/queries/useMedspaConsents';
import { useUpdateMedspaStandingOrders } from 'src/hooks/queries/useMedspaStandingOrders';
import {
  CARE_MESSAGES_DOCUMENT_TYPE,
  CONSENTS_DOCUMENT_TYPE,
  STANDING_ORDERS_DOCUMENT_TYPE,
} from 'src/constants/medspa/documentsManagement.constants';
import { CREATE } from 'src/constants/medspa/servicesManagement.constants';
import TitleComponent from './TitleComponent';
import IServiceGroup from '../../../interfaces/IServiceGroup';
import {
  useCreateServiceGroupMutation,
  useUpdateServiceGroupMutation,
  useDeleteServiceGroupMutation,
} from '../../../hooks/queries/serviceGroups/useServiceGroup';
import { Pill } from '../../../components/common/Pill';

export const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
  },
  fields: {
    width: '100%',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gridGap: '0.5rem',
  },
  title: {
    margin: '0 1rem 0 0',
  },
  paddingContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    maxHeight: '80vh',
    overflowY: 'auto',
    display: 'flex',
    gridGap: '1.5rem',
  },
  separator: {
    margin: '0',
  },
  submitButton: {
    alignSelf: 'center',
    minWidth: '110px',
  },
  enableButton: {
    border: '1px solid #545454',
    cursor: 'pointer',
    width: '46%',
    alignSelf: 'center',
    color: '#545454',
  },
  deleteButton: {
    border: '1px solid #B64848',
    cursor: 'pointer',
    alignSelf: 'center',
    color: '#B64848',
    minWidth: '110px',
  },
  container: {
    height: '100px',
    overflowY: 'scroll',
    padding: '10px',
    '&::-webkit-scrollbar': {
      width: '8px',
      border: '1px solid #d3d3d369',
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgrey',
      borderRadius: '6px',
    },
  },
  helpIcon: {
    fontSize: '1rem',
    margin: '0 0.3125rem',
    alignSelf: 'center',
    fill: '#000',
    '&.disabled': {
      fill: '#8F8D8B',
    },
  },
  serviceGroupModal: {
    width: '38.2%',
    margin: '0.625rem 0.625rem auto auto',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '24px',
    minWidth: '550px',
    maxWidth: '550px',
  },
}));

interface IServiceGroupModal {
  open: boolean;
  onClose: () => void;
  serviceGroupId: string;
  userServiceGroups: IServiceGroup[];
  defaultServiceGroups: IServiceGroup[];
}
const ServiceGroupModal = ({
  open,
  onClose,
  serviceGroupId,
  userServiceGroups,
  defaultServiceGroups,
}: IServiceGroupModal) => {
  const { roleName, userGroupId } = useSelector(({ auth }: any) => auth);
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const classes = useStyles();
  const history = useHistory();
  const isFirstTime = window.location.href.includes('services-management');
  const { state } = useLocation<{ form: any }>() || {};
  const form = state?.form;

  const { mutateAsync: createServiceGroup } = isMedspaAdmin
    ? useMedspaCreateServiceGroupMutation(userGroupId)
    : useCreateServiceGroupMutation();
  const { mutateAsync: updateServiceGroup } = isMedspaAdmin
    ? useMedspaUpdateServiceGroupMutation(userGroupId)
    : useUpdateServiceGroupMutation();
  const { mutateAsync: deleteServiceGroup } = isMedspaAdmin
    ? useMedspaDeleteServiceGroupMutation(userGroupId)
    : useDeleteServiceGroupMutation();

  const { data: careMessageSuggestions } = isMedspaAdmin
    ? useMedspaCareMessageTemplates(userGroupId)
    : useCareMessageTemplates();
  const { data: consentTemplates } = isMedspaAdmin ? useMedspaConsentTemplates(userGroupId) : useConsentTemplates();
  const { data: standingOrderTemplates } = isMedspaAdmin
    ? useMedspaStandingOrderTemplates(userGroupId)
    : useStandingOrderTemplates();

  const standingOrderOptions = standingOrderTemplates?.map(({ name, id, practitionerId }) => ({
    name: `${name} ${practitionerId ? '(editable)' : ''}`,
    value: id,
  }));
  const careMessageOptions = careMessageSuggestions?.map(({ id, name, practitionerId }) => ({
    name: `${name} ${practitionerId ? '(editable)' : ''}`,
    value: id,
  }));
  const consentOptions = consentTemplates?.map(({ id, name, practitionerId }) => ({
    name: `${name} ${practitionerId ? '(editable)' : ''}`,
    value: id,
  }));

  const serviceGroupIdRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState('');
  const [showDescription, setShowDescription] = useState(true);
  const [description, setDescription] = useState('');
  const [afterPhotoRequired, setAfterPhotoRequired] = useState<boolean | undefined>();
  const [consentsRequired, setConsentsRequired] = useState<boolean | undefined>();
  const [standingOrdersRequired, setStandingOrdersRequired] = useState<boolean | undefined>();
  const [consentSelected, setConsentSelected] = useState<IConsent | undefined | null>();
  const [standingOrderSelected, setStandingOrderSelected] = useState<IStandingOrder | undefined | null>();
  const [careMessage, setCareMessage] = useState<ICustomCareMessage | undefined | null>();

  const [canEditServiceGroup, setCanEditServiceGroup] = useState(false);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState<IServiceGroup | undefined>();

  const { mutateAsync: updateMedspaConsentMutation } = useUpdateMedspaConsents();
  const { mutateAsync: updateMedspaStandingOrderMutation } = useUpdateMedspaStandingOrders();
  const { mutateAsync: updateMedspaCareMessages } = useMutation(MedspaCareMessages.updateMedspaCareMessages);

  const updateConsentMutation = useUpdatePractitionerConsents();
  const updateStandingOrderMutation = useUpdatePractitionerStandingOrders();
  const updateCareMessageMutation = useUpdateCareMessageMutation();

  const handleMasterLibraryServiceGroupChange = (selectedServiceGroupId: any) => {
    const retrievedServiceGroup = defaultServiceGroups.find(({ id }) => id === selectedServiceGroupId);

    if (retrievedServiceGroup) {
      setName(retrievedServiceGroup.name || '');
      setDescription(retrievedServiceGroup.description || '');
      setAfterPhotoRequired(retrievedServiceGroup.afterPhotoRequired);
      setConsentsRequired(retrievedServiceGroup.consentRequiredForServiceVisit);
      setStandingOrdersRequired(retrievedServiceGroup.standingOrderRequiredForServiceVisit);
      setConsentSelected(retrievedServiceGroup.consent);
      setStandingOrderSelected(retrievedServiceGroup.standingOrder);
      setCareMessage(retrievedServiceGroup.careMessages);
    }
  };

  const selectStandingOrderTemplate = (ev: any) => {
    const value = ev.target?.value;
    if (value === 'new') {
      return createNewStandingOrder();
    }
    const template = standingOrderTemplates?.find(({ id }) => id === +value);
    setStandingOrderSelected(template);
    showSnackbar(compile('form.populated_from_template', { element: 'Standing order' }), SNACKBAR_SUCCESS);
  };

  const selectCareTemplate = (ev: any) => {
    const value = ev.target?.value;
    if (value === 'new') {
      return createNewCareMessage();
    }
    const template = careMessageSuggestions?.find(({ id }) => id === +value);
    setCareMessage(template);
    showSnackbar(compile('form.populated_from_template', { element: 'Care messages' }), SNACKBAR_SUCCESS);
  };

  const selectConsentTemplate = (ev: any) => {
    const value = ev.target?.value;
    if (value === 'new') {
      return createNewConsent();
    }
    const template = consentTemplates?.find(({ id }) => id === +value);
    setConsentSelected(template);
    showSnackbar(compile('form.populated_from_template', { element: 'Consent' }), SNACKBAR_SUCCESS);
  };

  useEffect(() => {
    let serviceGroup = null;
    if (form) {
      serviceGroup = form;
    }

    const serviceGroupObject =
      serviceGroupId && userServiceGroups && userServiceGroups.length
        ? userServiceGroups.find((_serviceGroup) => _serviceGroup.id === +serviceGroupId)
        : undefined;

    if (!serviceGroup) {
      serviceGroup = serviceGroupObject;
    } else {
      serviceGroup.practitionerId = serviceGroupObject?.practitionerId;
    }

    setName(serviceGroup?.name || '');
    setDescription(serviceGroup?.description || '');
    setAfterPhotoRequired(Boolean(serviceGroup?.afterPhotoRequired));
    setConsentsRequired(Boolean(serviceGroup?.consentRequiredForServiceVisit));
    setStandingOrdersRequired(Boolean(serviceGroup?.standingOrderRequiredForServiceVisit));
    setConsentSelected(serviceGroup?.consent);
    setStandingOrderSelected(serviceGroup?.standingOrder);
    setCareMessage(serviceGroup?.careMessages);
    setCanEditServiceGroup(
      serviceGroupId === 'new' || Boolean(serviceGroup?.practitionerId) || Boolean(serviceGroup?.userGroupId)
    );
    setSelectedServiceGroup(serviceGroup);
    const showDescriptionForm = serviceGroup?.id ? !!serviceGroup?.practitionerId : true;
    setShowDescription(showDescriptionForm);
  }, [serviceGroupId]);

  const getFormData = (isSubmit = false) => {
    const data: any = {
      name: name || '',
      description: description || '',
      afterPhotoRequired: afterPhotoRequired || '',
      consentRequiredForServiceVisit: consentsRequired || '',
      standingOrderRequiredForServiceVisit: standingOrdersRequired || '',
      consentId: consentSelected?.id,
      standingOrderId: standingOrderSelected?.id,
      careMessageId: careMessage?.id,
      // Extra fields if care message is not created yet and selected from master template
      preMandatoryMessage: careMessage?.preMandatoryMessage, // required for isSubmit or not
      postMandatoryMessage: careMessage?.postMandatoryMessage, // required for isSubmit or not
    };

    if (isSubmit) {
      data.consentId = consentSelected?.id;
      data.standingOrderId = standingOrderSelected?.id;
      data.careMessageId = careMessage?.id;
    } else {
      data.consent = consentSelected;
      data.standingOrder = standingOrderSelected;
      data.careMessages = careMessage;
    }

    return data;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const serviceGroupData = getFormData(true);

    if (serviceGroupId === 'new') {
      await createServiceGroup(serviceGroupData);
    } else {
      await updateServiceGroup({ ...serviceGroupData, id: serviceGroupId });
    }
    onClose();
  };

  const handleDelete = async () => {
    const success = await deleteServiceGroup(+serviceGroupId);
    if (success) {
      onClose();
    }
  };

  const createNewConsent = () => {
    if (isMedspaAdmin) {
      history.push({
        pathname: MEDSPA_ADMIN_SERVICES_CUSTOMIZE,
        state: {
          document: {
            name: '',
            content: '',
          },
          action: CREATE,
          documentType: CONSENTS_DOCUMENT_TYPE,
          backUrl: window.location.pathname,
          form: getFormData(),
          searchUrl: window.location.search,
        },
      });
    } else {
      history.push({
        pathname: PRACTITIONER_CONFIGURATION_CUSTOMIZE_PATH,
        state: {
          configuration: CONSENTS_TITLE,
          backUrl: window.location.pathname,
          searchUrl: window.location.search,
          form: getFormData(),
        },
      });
    }
  };

  const createNewCareMessage = () => {
    if (isMedspaAdmin) {
      history.push({
        pathname: MEDSPA_ADMIN_SERVICES_CUSTOMIZE_MESSAGING,
        state: {
          document: {
            name: '',
            content: '',
          },
          action: CREATE,
          documentType: CARE_MESSAGES_DOCUMENT_TYPE,
          backUrl: window.location.pathname,
          form: getFormData(),
          searchUrl: window.location.search,
        },
      });
    } else {
      history.push({
        pathname: PRACTITIONER_MESSAGING_CUSTOMIZE_PATH,
        state: {
          customCareMessages: {},
          readOnly: false,
          backUrl: window.location.pathname,
          searchUrl: window.location.search,
          form: getFormData(),
        },
      });
    }
  };

  const handleChangeConsent = (newValue?: string) => {
    const params = {
      id: consentSelected?.id,
      name: consentSelected?.name,
      content: newValue,
    };

    if (isMedspaAdmin) {
      updateMedspaConsentMutation({
        ...params,
        consentId: params.id,
      });
    } else {
      updateConsentMutation.mutateAsync(params);
    }
    setConsentSelected({ ...consentSelected, content: newValue } as IConsent);
  };

  const handleChangeStandingOrder = (newValue?: string) => {
    const params = {
      id: standingOrderSelected?.id,
      name: standingOrderSelected?.name,
      content: newValue,
    };
    if (isMedspaAdmin) {
      updateMedspaStandingOrderMutation({
        ...params,
        standingOrderId: params.id,
      });
    } else {
      updateStandingOrderMutation.mutateAsync(params);
    }
    setStandingOrderSelected({ ...standingOrderSelected, content: newValue } as IStandingOrder);
  };

  const handleChangeCareMessage = (newValue: string, key: string) => {
    const data = {
      id: careMessage?.id,
      name: careMessage?.name,
      postCare: {
        endingMessage: careMessage?.postEndMessage,
        introductoryMessage: careMessage?.postIntroductoryMessage,
        mandatoryMessage: careMessage?.postMandatoryMessage,
      },
      preCare: {
        endingMessage: careMessage?.preEndMessage,
        introductoryMessage: careMessage?.preIntroductoryMessage,
        mandatoryMessage: careMessage?.preMandatoryMessage,
      },
      followUp: {
        message: careMessage?.followUpMessage,
        periodNumber: careMessage?.followUpPeriodNumber,
        periodFrequency: careMessage?.followUpPeriodFrequency,
      },
    };
    switch (key) {
      case 'preIntroductoryMessage':
        data.preCare.introductoryMessage = newValue;
        break;
      case 'preMandatoryMessage':
        data.preCare.mandatoryMessage = newValue;
        break;
      case 'preEndMessage':
        data.preCare.endingMessage = newValue;
        break;
      case 'postIntroductoryMessage':
        data.postCare.introductoryMessage = newValue;
        break;
      case 'postMandatoryMessage':
        data.postCare.mandatoryMessage = newValue;
        break;
      case 'postEndMessage':
        data.postCare.endingMessage = newValue;
        break;
      default:
        break;
    }
    if (isMedspaAdmin) {
      updateMedspaCareMessages({
        ...data,
        documentId: data.id,
      });
    } else {
      updateCareMessageMutation.mutateAsync(data);
    }

    setCareMessage({
      ...careMessage,
      preIntroductoryMessage: data.preCare.introductoryMessage,
      preMandatoryMessage: data.preCare.mandatoryMessage,
      preEndMessage: data.preCare.endingMessage,
      postIntroductoryMessage: data.postCare.introductoryMessage,
      postMandatoryMessage: data.postCare.mandatoryMessage,
      postEndMessage: data.postCare.endingMessage,
    } as ICustomCareMessage);
  };

  const createNewStandingOrder = () => {
    if (isMedspaAdmin) {
      history.push({
        pathname: MEDSPA_ADMIN_SERVICES_CUSTOMIZE,
        state: {
          document: {
            name: '',
            content: '',
          },
          documentType: STANDING_ORDERS_DOCUMENT_TYPE,
          backUrl: window.location.pathname,
          action: CREATE,
          form: getFormData(),
          searchUrl: window.location.search,
        },
      });
    } else {
      history.push({
        pathname: PRACTITIONER_CONFIGURATION_CUSTOMIZE_PATH,
        state: {
          configuration: STANDING_ORDERS_TITLE,
          backUrl: window.location.pathname,
          searchUrl: window.location.search,
          form: getFormData(),
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.serviceGroupModal}>
      <Box
        style={{
          width: '50%',
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: '24px',
          minWidth: '550px',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" p="1.5rem">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom className={classes.title}>
              {serviceGroupId === 'new' ? 'New Service Group' : 'Service Group'}
            </Typography>
            {serviceGroupId !== 'new' && <Pill title="Active" color="green" />}
          </Box>
          <Button onClick={onClose} style={{ minWidth: 'auto', border: '1px solid #CFCFCF' }}>
            <CloseIcon />
          </Button>
        </Box>
        <hr className={classes.separator} />
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p={3}
            className={classes.paddingContainer}
          >
            {serviceGroupId === 'new' && (
              <Box className={classes.fields}>
                <TitleComponent title="Primary Service Library Templates" />
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  margin="normal"
                  className={classes.formControl}
                  disabled={!canEditServiceGroup}
                >
                  <Select
                    inputRef={serviceGroupIdRef}
                    name="serviceGroupId"
                    id="serviceGroupId"
                    onChange={(event) => handleMasterLibraryServiceGroupChange(event.target.value)}
                  >
                    <MenuItem value="">None</MenuItem>
                    {defaultServiceGroups?.map((defaultServiceGroup) => (
                      <MenuItem value={defaultServiceGroup.id}>{defaultServiceGroup.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            <Box className={classes.fields}>
              <TitleComponent title="Service Group Name" />
              <TextField
                data-testid="service-group-name"
                required
                fullWidth
                defaultValue={selectedServiceGroup?.name}
                variant="outlined"
                size="small"
                value={name}
                onChange={(event) => setName(event.target.value)}
                disabled={!canEditServiceGroup}
              />
            </Box>

            {showDescription && (
              <Box className={classes.fields}>
                <TitleComponent title="Description" optional />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  defaultValue={selectedServiceGroup?.description}
                  variant="outlined"
                  size="small"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  disabled={!canEditServiceGroup}
                />
              </Box>
            )}

            <Box className={classes.fields}>
              <Box display="flex" flexDirection="row">
                <TitleComponent
                  title="After Photo Required"
                  tooltipComponent={
                    <Tooltip
                      // eslint-disable-next-line max-len
                      title="If required, all Services within this Service Group will require an after photo to complete the service visit."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                    >
                      <HelpIcon
                        className={classNames(classes.helpIcon, canEditServiceGroup ? undefined : 'disabled')}
                        fontSize="small"
                      />
                    </Tooltip>
                  }
                />
              </Box>
              <Box>
                <FormControl disabled={!canEditServiceGroup} fullWidth>
                  <RadioButtons
                    selectedOption={String(afterPhotoRequired)}
                    onChange={(value: string) => setAfterPhotoRequired(value === 'true')}
                    options={[
                      { value: 'true', label: 'Required' },
                      { value: 'false', label: 'Not Required' },
                    ]}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box className={classes.fields}>
              <TitleComponent
                title="Consents"
                tooltipComponent={
                  canEditServiceGroup ? undefined : (
                    <Tooltip
                      // eslint-disable-next-line max-len
                      title="Services from master library cannot have their consents edited."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                    >
                      <WarningIcon
                        className={classNames(classes.helpIcon, canEditServiceGroup ? undefined : 'disabled')}
                        fontSize="small"
                      />
                    </Tooltip>
                  )
                }
              />

              {canEditServiceGroup && (
                <Box>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="normal"
                    className={classes.formControl}
                    disabled={!canEditServiceGroup}
                  >
                    <Select name="consents" value={consentSelected?.id || ''} onChange={selectConsentTemplate}>
                      {(!isFirstTime || isMedspaAdmin) && <MenuItem value="new">New</MenuItem>}
                      {consentOptions?.map((option) => (
                        <MenuItem key={`consent-${option.value}`} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              <Box>
                <DocumentForm
                  title={`${consentSelected?.name || ''} Consent Template`}
                  value={consentSelected?.content || ''}
                  onChange={(value: string) => handleChangeConsent(value)}
                  onDelete={() => handleChangeConsent()}
                  disabled={!canEditServiceGroup || !consentSelected?.id || !consentSelected?.practitionerId}
                />
              </Box>

              <Box>
                <FormControl disabled={!canEditServiceGroup} fullWidth>
                  <RadioButtons
                    selectedOption={String(consentsRequired)}
                    onChange={(value: string) => setConsentsRequired(value === 'true')}
                    options={[
                      { value: 'true', label: 'Required' },
                      { value: 'false', label: 'Not Required' },
                    ]}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box className={classes.fields}>
              <TitleComponent
                title="Standing Orders"
                tooltipComponent={
                  canEditServiceGroup ? undefined : (
                    <Tooltip
                      // eslint-disable-next-line max-len
                      title="Services from master library cannot have their standing orders edited."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                    >
                      <WarningIcon
                        className={classNames(classes.helpIcon, canEditServiceGroup ? undefined : 'disabled')}
                        fontSize="small"
                      />
                    </Tooltip>
                  )
                }
              />

              {canEditServiceGroup && (
                <Box>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="normal"
                    className={classes.formControl}
                    disabled={!canEditServiceGroup}
                  >
                    <Select
                      name="standingOrders"
                      value={standingOrderSelected?.id || ''}
                      onChange={selectStandingOrderTemplate}
                    >
                      {(!isFirstTime || isMedspaAdmin) && <MenuItem value="new">New</MenuItem>}
                      {standingOrderOptions?.map((option) => (
                        <MenuItem value={option.value}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              <Box>
                <DocumentForm
                  title={`${standingOrderSelected?.name || ''} Standing order`}
                  value={standingOrderSelected?.content || ''}
                  onChange={(value: string) => handleChangeStandingOrder(value)}
                  onDelete={() => handleChangeStandingOrder()}
                  disabled={
                    !canEditServiceGroup || !standingOrderSelected?.id || !standingOrderSelected?.practitionerId
                  }
                />
              </Box>

              <Box>
                <FormControl disabled={!canEditServiceGroup} fullWidth>
                  <RadioButtons
                    selectedOption={String(standingOrdersRequired)}
                    onChange={(value: string) => setStandingOrdersRequired(value === 'true')}
                    options={[
                      { value: 'true', label: 'Required' },
                      { value: 'false', label: 'Not Required' },
                    ]}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box className={classes.fields}>
              <TitleComponent title="Care Instructions" optional />
              {canEditServiceGroup && (
                <Box>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="normal"
                    className={classes.formControl}
                    disabled={!canEditServiceGroup}
                  >
                    <Select name="preCareInstructions" value={careMessage?.id || ''} onChange={selectCareTemplate}>
                      {(!isFirstTime || isMedspaAdmin) && <MenuItem value="new">New</MenuItem>}
                      {careMessageOptions?.map((option) => (
                        <MenuItem value={option.value}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              <Box>
                <DocumentForm
                  title={`${careMessage?.name || ''} Pre-care introductory instructions`}
                  value={careMessage?.preIntroductoryMessage || ''}
                  onChange={(value: string) => handleChangeCareMessage(value, 'preIntroductoryMessage')}
                  onDelete={() => handleChangeCareMessage('', 'preIntroductoryMessage')}
                  disabled={!canEditServiceGroup || !careMessage?.id || !careMessage?.practitionerId}
                />
              </Box>
              <Box>
                <DocumentForm
                  title={`${careMessage?.name || ''} Pre-care mandatory instructions`}
                  value={careMessage?.preMandatoryMessage || ''}
                  onChange={(value: string) => handleChangeCareMessage(value, 'preMandatoryMessage')}
                  onDelete={() => handleChangeCareMessage('', 'preMandatoryMessage')}
                  disabled={!canEditServiceGroup || !careMessage?.id || !careMessage?.practitionerId}
                />
              </Box>
              <Box>
                <DocumentForm
                  title={`${careMessage?.name || ''} Pre-care end instructions`}
                  value={careMessage?.preEndMessage || ''}
                  onChange={(value: string) => handleChangeCareMessage(value, 'preEndMessage')}
                  onDelete={() => handleChangeCareMessage('', 'preEndMessage')}
                  disabled={!canEditServiceGroup || !careMessage?.id || !careMessage?.practitionerId}
                />
              </Box>
              <Box>
                <DocumentForm
                  title={`${careMessage?.name || ''} Post-care introductory instructions`}
                  value={careMessage?.postIntroductoryMessage || ''}
                  onChange={(value: string) => handleChangeCareMessage(value, 'postIntroductoryMessage')}
                  onDelete={() => handleChangeCareMessage('', 'postIntroductoryMessage')}
                  disabled={!canEditServiceGroup || !careMessage?.id || !careMessage?.practitionerId}
                />
              </Box>
              <Box>
                <DocumentForm
                  title={`${careMessage?.name || ''} Post-care mandatory instructions`}
                  value={careMessage?.postMandatoryMessage || ''}
                  onChange={(value: string) => handleChangeCareMessage(value, 'postMandatoryMessage')}
                  onDelete={() => handleChangeCareMessage('', 'postMandatoryMessage')}
                  disabled={!canEditServiceGroup || !careMessage?.id || !careMessage?.practitionerId}
                />
              </Box>
              <Box>
                <DocumentForm
                  title={`${careMessage?.name || ''} Post-care end instructions`}
                  value={careMessage?.postEndMessage || ''}
                  onChange={(value: string) => handleChangeCareMessage(value, 'postEndMessage')}
                  onDelete={() => handleChangeCareMessage('', 'postEndMessage')}
                  disabled={!canEditServiceGroup || !careMessage?.id || !careMessage?.practitionerId}
                />
              </Box>
            </Box>
          </Box>

          <hr className={classes.separator} />

          <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <Box style={{ width: `${serviceGroupId === 'new' ? '100%' : 'auto'}` }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submitButton}
                style={{ width: `${serviceGroupId === 'new' ? '100%' : 'auto'}` }}
                disabled={!canEditServiceGroup}
              >
                {serviceGroupId === 'new' ? 'Create' : 'Save'}
              </Button>
            </Box>
            {serviceGroupId !== 'new' && (
              <Box display="flex" justifyContent="space-between">
                <Button className={classes.deleteButton} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            )}
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ServiceGroupModal;
