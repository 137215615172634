import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { formatCurrency } from 'src/utils/formatNumber';
import { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import { EMPTY_CELL } from 'src/constants/inventory.constants';
import { InventoryOrderItem, InventoryOrderSpecial } from '../../../interfaces/IInventoryOrder';
import { useStyle } from './inventory.styles';

export const OrderHistorySubRow = ({
  name,
  quantity,
  inventoryOrderId,
  orderProductId,
  price,
  marketPrice,
  subtotal,
}: InventoryOrderItem) => {
  const classes = useStyle();

  return (
    <TableRow data-testid={`orderHistory.${inventoryOrderId}.${orderProductId}`}>
      <TableCell
        className={classes.approvalRowName}
        data-testid={`orderHistory.${inventoryOrderId}.${orderProductId}.name`}
      >
        {name}
      </TableCell>
      <TableCell>{formatCurrency(marketPrice)}</TableCell>
      <TableCell>{marketPrice > price ? formatCurrency(marketPrice - price) : '--'}</TableCell>
      <TableCell>{formatCurrency(price)}</TableCell>
      <TableCell data-testid={`orderHistory.${inventoryOrderId}.${orderProductId}.quantity`}>{quantity}</TableCell>
      <TableCell width={175}>{subtotal > 0 ? formatCurrency(subtotal) : 'awaiting approval'}</TableCell>
    </TableRow>
  );
};

export const OrderHistorySubRowCustom = ({ name, quantity, id, price, subtotal }: InventoryOrderCustomItem) => {
  const classes = useStyle();

  return (
    <TableRow data-testid={`orderHistory.custom.${id}`}>
      <TableCell className={classes.approvalRowName} data-testid={`orderHistory.custom.${id}.name`}>
        {name}
      </TableCell>
      <TableCell>{EMPTY_CELL}</TableCell>
      <TableCell>{EMPTY_CELL}</TableCell>
      <TableCell>{formatCurrency(price)}</TableCell>
      <TableCell data-testid={`orderHistory.custom.${id}.quantity`}>{quantity}</TableCell>
      <TableCell width={175}>{formatCurrency(subtotal)}</TableCell>
    </TableRow>
  );
};

type Props = {
  special: InventoryOrderSpecial;
};

export const OrderHistorySpecialSubRow = ({ special }: Props) => {
  const classes = useStyle();
  const { specialOffer, quantity, inventoryOrderId, specialOfferId, price, subtotal } = special;
  const { name, marketPriceLabel, youSaveLabel } = specialOffer;

  const marketPrice = marketPriceLabel as number;

  return (
    <TableRow data-testid={`orderHistory.${inventoryOrderId}.${specialOfferId}`}>
      <TableCell
        className={classes.approvalRowName}
        data-testid={`orderHistory.${inventoryOrderId}.${specialOfferId}.name`}
      >
        {name}
      </TableCell>
      <TableCell>{formatCurrency(marketPrice)}</TableCell>
      <TableCell>{formatCurrency(youSaveLabel ?? 0)}</TableCell>
      <TableCell>{formatCurrency(price)}</TableCell>
      <TableCell data-testid={`orderHistory.${inventoryOrderId}.${specialOfferId}.quantity`}>{quantity}</TableCell>
      <TableCell width={175}>{formatCurrency(subtotal)}</TableCell>
    </TableRow>
  );
};
