import { TextField, makeStyles, withStyles } from '@material-ui/core';

export const NewTextField = withStyles(() => ({
  root: {
    height: '45px',
    fontSize: 14,
  },

  '@global': {
    '.MuiInputBase-root': {
      minHeight: '45px',
      fontSize: '14px',
    },
    '.MuiFormHelperText-contained': {
      marginLeft: '0',
      marginRight: '0',
    },
  },
}))(TextField);

export const useStyles = makeStyles(() => ({
  title: {
    margin: '0 1rem 0 0',
  },
  formModal: {
    width: '38.2%',
    margin: '0.625rem 0.625rem auto auto',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '24px',
    minWidth: '550px',
    maxWidth: '550px',
  },
  formModalContent: {
    height: '98vh',
    width: '50%',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '24px',
    minWidth: '550px',
    overflowY: 'auto',
  },
  formContainer: {
    flexDirection: 'column',
  },
  closeBtn: {
    minWidth: 'auto',
    border: '1px solid #CFCFCF',
  },
  submitButton: {
    alignSelf: 'center',
    minWidth: '110px',
  },
  separator: {
    margin: '1rem 0',
  },
  footer: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '100%',
  },
  label: {
    marginBottom: '0.5rem',
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '15px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  createGroupField: {
    margin: '10px 0',
    '&:first-of-type': {
      '& *': {
        width: '100%',
      },
    },
  },
  editGroupField: {
    margin: '10px 0',
    '& *': {
      width: '100%',
    },
  },
  createGroupNameLabel: {
    fontSize: 12,
  },
  editGroupSelect: {
    width: '100%',
    height: '45px',
  },
}));
