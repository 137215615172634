import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { Error as ErrorIcon, CheckCircle as CheckCircleIcon, Info as InfoIcon } from '@material-ui/icons';
import classNames from 'classnames';
import { EHRTypography } from '../ui/v1';

const useStyles = makeStyles({
  container: { padding: '1rem', borderRadius: '8px', display: 'flex', gap: '10px' },
  success: { backgroundColor: '#CEF1D7', color: '#24AF49', border: 'solid 1px #24AF49' },
  error: { backgroundColor: '#FFDBDB', color: '#C65858', border: 'solid 1px #C65858' },
  warning: { backgroundColor: '#FFF9F1', color: '#DB8108', border: 'solid 1px #DB8108' },
  info: { backgroundColor: '#BAEEFF', color: '#266A80', border: 'solid 1px #266A80' },
});

interface BannerProps {
  content: string | React.ReactNode;
  type: 'success' | 'error' | 'warning' | 'info';
}

const Banner = ({ content, type }: BannerProps) => {
  const classes = useStyles();
  const handleIcon = {
    success: <CheckCircleIcon />,
    error: <ErrorIcon />,
    warning: <ErrorIcon />,
    info: <InfoIcon />,
  };

  return (
    <Box className={classNames(classes.container, classes[type])}>
      {handleIcon[type]}
      <EHRTypography variant="body2" dataCy="banner-text">
        {content}
      </EHRTypography>
    </Box>
  );
};

export default Banner;
