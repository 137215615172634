import { useMutation, useQueryClient } from 'react-query';
import { IClaimPatientParams } from '../../types/Patient';
import { ClaimPatient } from '../../services/Patients';
import { PATIENT_INFO } from '../../constants/reactQuery.keys';

export const useClaimPatient = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ customerId, claim }: IClaimPatientParams) =>
      ClaimPatient({
        customerId,
        claim,
      }),
    {
      onMutate: async (data) => {
        await queryClient.cancelQueries([PATIENT_INFO, data.customerId]);

        // Snapshot the previous value
        const previousState = queryClient.getQueryData([PATIENT_INFO, data.customerId]);

        // Optimistically update to the new value
        queryClient.setQueryData(
          [PATIENT_INFO, data.customerId],
          ({ basicInfo, contactInfo, medicalProfile, serviceVisits }) => ({
            basicInfo: {
              ...basicInfo,
              claimed: data.claim,
              claimedBy: data.identityId,
              claimedAt: null,
            },
            contactInfo,
            medicalProfile,
            serviceVisits,
          })
        );

        // Return a context object with the snapshotted value
        return { previousState };
      },
      onError: (err, { customerId }, context) => {
        queryClient.setQueryData([PATIENT_INFO, customerId], context.previousState);
      },
      onSettled: (patientData, error, variables) => {
        queryClient.invalidateQueries([PATIENT_INFO, variables.customerId]);
      },
    }
  );
};
