import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import { useStyles } from './Breadcrumb.styles';

export interface BreadcrumbTextProps {
  text: string | React.ReactNode;
  isActive?: boolean;
  linkTo?: any;
}

const BreadcrumbText: React.FC<BreadcrumbTextProps> = ({ text, isActive, linkTo }) => {
  const classes = useStyles();
  const classIsActive = isActive ? classes.isActive : undefined;
  const haveLink = linkTo === undefined ? classes.noLink : '';

  return (
    <Typography color="textPrimary">
      <Link
        data-cy="breadcrumbText"
        component={RouterLink}
        to={linkTo}
        className={classnames(classes.breadCrumbsFont, haveLink, classIsActive)}
      >
        {text}
      </Link>
    </Typography>
  );
};

export default BreadcrumbText;
