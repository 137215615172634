import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText, TextField, InputAdornment, CircularProgress, Box } from '@material-ui/core';
import { Autocomplete, AutocompleteInputChangeReason, AutocompleteProps } from '@material-ui/lab';
import { debounce } from 'lodash';
import { EHRTypography } from './EHRTypography';

const TIME_DO_SEARCH = 1500;

const useStyles = makeStyles((theme) => ({
  ehrAutocompleteSelectContainer: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(1),
    },
    '& .MuiAutocomplete-inputRoot': {
      marginTop: theme.spacing(1),
      borderRadius: '0.375rem',
      fontSize: '0.875rem',
      color: '#6F6F6F',
      height: '2.5rem',
      '& .MuiAutocomplete-input': {
        height: '1.185rem',
        padding: 0,
      },
    },
  },
  ehrAutocompleteSelectSelectedItem: {
    backgroundColor: '#12574d',
    color: 'white',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25rem',
    height: '100%',
    alignItems: 'center',
    overflow: 'auto',
  },
  chip: {
    height: '1rem',
    fontSize: '0.65rem',
  },
}));

type IValue = string | number | undefined | null;
export interface IAutocompleteSelectOption {
  name: string;
  value: IValue;
}

type _EHRAutocompleteSelectProps = AutocompleteProps<IAutocompleteSelectOption, undefined, undefined, undefined>;

export type EHRAutocompleteSelectProps = Omit<_EHRAutocompleteSelectProps, 'onChange' | 'renderInput'> & {
  dataCy: string;
  label?: string;
  options?: IAutocompleteSelectOption[];
  onChange?: (option: IAutocompleteSelectOption | null) => void;
  onChangeDebounceSearch?: (search: string) => void;
  onInputChange?: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  isFetching?: boolean;
  labelStyles?: React.CSSProperties;
  noOptionsText?: string;
  selectedOption?: IAutocompleteSelectOption;
  renderInput?: _EHRAutocompleteSelectProps['renderInput'];
};

const EHRAutocompleteSelect = (props: EHRAutocompleteSelectProps) => {
  const classes = useStyles();
  const {
    dataCy,
    options = [],
    onChange,
    onChangeDebounceSearch,
    placeholder,
    label,
    disabled,
    error,
    helperText,
    isFetching,
    labelStyles = {},
    noOptionsText,
    selectedOption,
  } = props;
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!selectedOption) {
      setSearchInput(undefined);
    }
  }, [selectedOption]);

  const doSearch = useCallback(
    debounce((payload: any) => {
      onChangeDebounceSearch?.(payload || '');
    }, TIME_DO_SEARCH),
    []
  );

  const handleChangeSearch = (ev: any) => {
    const text = ev?.target?.value;
    if (typeof text === 'string' && !isFetching && searchInput !== text) {
      setSearchInput(text);
      doSearch(text);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.ehrAutocompleteSelectContainer}>
        <EHRTypography dataCy="label-profile-photo" variant="label" error={error} style={labelStyles}>
          {label}
        </EHRTypography>
        <Autocomplete
          {...props}
          data-cy={dataCy}
          noOptionsText={noOptionsText}
          options={options}
          getOptionLabel={(option) => option.name}
          disabled={disabled}
          onChange={(ev, option: IAutocompleteSelectOption | null) => {
            setSearchInput(option?.name || undefined);
            onChange?.(option);
          }}
          onInputChange={handleChangeSearch}
          filterOptions={onChangeDebounceSearch ? (filterOptions) => filterOptions : undefined}
          fullWidth
          value={selectedOption}
          inputValue={searchInput}
          defaultValue={selectedOption}
          renderInput={(params: any) => (
            <TextField
              {...params}
              placeholder={placeholder || 'Type to search'}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: isFetching ? (
                  <InputAdornment position="end">
                    <CircularProgress style={{ color: 'black' }} size={20} />
                  </InputAdornment>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
              error={error}
              autocomplete="off"
              variant="outlined"
            />
          )}
        />
        {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </Box>
    </Box>
  );
};

export { EHRAutocompleteSelect };
