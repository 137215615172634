import React, { FC } from 'react';
import { useQuery } from 'react-query';
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from '@material-ui/core';

import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Page from 'src/components/common/Page';
import BreadcrumbsContainer from '../../components/common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../components/common/Breadcrumb/BreadcrumbText';
import { useStyles } from './newPatient.styles';
import compile from '../../utils/toastMessagesCompiler';
import { dispatch } from '../../rematch';
import { patientSchemaFormForPractitioner, schemaForm } from './schemaForm';
import { Physicians } from '../../services/Physicians';
import { PHYSICIANS } from '../../constants/reactQuery.keys';
import { ADMIN_ROLE, PRACTITIONER_ROLE } from '../../constants/general.constants';
import { usePractitioners } from '../../hooks/queries/usePractitioners';
import { useCreatePatient } from '../../hooks/queries/usePatients';
import { STATES } from '../../constants/medicalProfile.constants';
import { Practitioners } from '../../services/Practitioners';

const LOAD_PHYSICIAN_ERROR = compile('generic.error_message', {
  action: 'loading',
  element: 'physicians',
});

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  physicianId: string;
  practitionerId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}

const NewPatient: FC = () => {
  const classes = useStyles();
  const mutationCreate = useCreatePatient();
  const loadingCreate = mutationCreate.isLoading;
  const { userType, userId } = useSelector(({ auth }: any) => auth);
  const { data: practitioners, isLoading: isLoadingPractitioners, isError: isErrorPractitioners } = usePractitioners();
  const isMedspaProvider = Practitioners.isMedspaProvider();

  const { handleSubmit, errors, register, control, setValue } = useForm<FormData>({
    resolver: yupResolver(userType === ADMIN_ROLE ? schemaForm : patientSchemaFormForPractitioner),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      physicianId: '',
      practitionerId: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
    },
  });

  const {
    isError,
    data: physicians,
    isFetching: isFetchingPhysicians,
  } = useQuery([PHYSICIANS], Physicians.getPhysicians, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: LOAD_PHYSICIAN_ERROR,
        type: 'error',
        duration: 2500,
      },
    });
  }

  if (isErrorPractitioners) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'loading',
          element: 'practitioners',
        }),
        type: 'error',
        duration: 2500,
      },
    });
  }

  const formatPhone = (val: string): string => val.replace(/[^a-zA-Z0-9]/g, '');

  const createAction = async (dataForm: FormData) => {
    const dataToCreate = {
      firstName: dataForm.firstName.trim(),
      lastName: dataForm.lastName.trim(),
      email: dataForm.email.trim(),
      phone: formatPhone(String(dataForm.phone)).trim(),
      physicianId: dataForm.physicianId,
      practitionerId: userType === PRACTITIONER_ROLE ? userId : dataForm.practitionerId,
      addressLine1: dataForm.addressLine1.trim(),
      addressLine2: dataForm.addressLine2.trim(),
      city: dataForm.city.trim(),
      state: dataForm.state.trim(),
      zipCode: dataForm.zipCode.trim(),
    };
    await mutationCreate.mutateAsync(dataToCreate);
  };

  const MyBreadcrumb = () => (
    <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BreadcrumbsContainer>
          <BreadcrumbText
            text="Patients"
            linkTo={`/${userType !== PRACTITIONER_ROLE ? 'administrator' : 'practitioner'}/patients`}
          />
          <BreadcrumbText text="New patient" isActive />
        </BreadcrumbsContainer>
      </Box>
    </Box>
  );

  return (
    <Page title="New Patient">
      <Box width="100%" display="flex" height="100%" flexDirection="column">
        {!isMedspaProvider && <MyBreadcrumb />}
        <Box paddingX={2} component="form" onSubmit={handleSubmit(createAction)}>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Box>
              <Typography className={classes.title}>New patient</Typography>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                data-cy="firstNameInput"
                fullWidth
                name="firstName"
                error={!!errors.firstName}
                helperText={errors.firstName?.message || ''}
                onChange={(event) => setValue('firstName', event.target.value)}
                inputRef={register}
                label="First name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                data-cy="lastNameInput"
                fullWidth
                name="lastName"
                error={!!errors.lastName}
                helperText={errors.lastName?.message || ''}
                onChange={(event) => setValue('lastName', event.target.value)}
                inputRef={register}
                label="Last name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                data-cy="emailInput"
                fullWidth
                name="email"
                autoComplete="off"
                error={!!errors.email}
                helperText={errors.email?.message || ''}
                label="Email"
                type="email"
                inputRef={register}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                data-cy="phoneInput"
                fullWidth
                name="phone"
                error={!!errors.phone}
                helperText={errors.phone?.message || ''}
                onChange={(event) => setValue('phone', event.target.value)}
                inputRef={register}
                label="Phone"
                type="tel"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                data-cy="addressLine1"
                fullWidth
                name="addressLine1"
                error={!!errors.addressLine1}
                helperText={errors.addressLine1?.message || ''}
                onChange={(event) => setValue('addressLine1', event.target.value)}
                inputRef={register}
                label="Address"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                data-cy="addressLine2"
                fullWidth
                name="addressLine2"
                error={!!errors.addressLine2}
                helperText={errors.addressLine2?.message || ''}
                onChange={(event) => setValue('addressLine2', event.target.value)}
                inputRef={register}
                label="Address line 2"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                data-cy="city"
                fullWidth
                name="city"
                error={!!errors.city}
                helperText={errors.city?.message || ''}
                onChange={(event) => setValue('city', event.target.value)}
                inputRef={register}
                label="City"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel htmlFor="state" error={!!errors.state}>
                  State
                </InputLabel>
                <Controller
                  control={control}
                  name="state"
                  error={!!errors.state}
                  as={
                    <Select id="state" labelWidth={40}>
                      {STATES.map((state: string) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {errors.state && <FormHelperText error>{errors.state.message}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                data-cy="zipCode"
                fullWidth
                name="zipCode"
                error={!!errors.zipCode}
                helperText={errors.zipCode?.message || ''}
                onChange={(event) => setValue('zipCode', event.target.value)}
                inputRef={register}
                label="Zip code"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {userType === ADMIN_ROLE && (
              <>
                <Grid item xs={4}>
                  <FormControl data-cy="select" size="medium" variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.formInput} htmlFor="physician-select" error={!!errors.physicianId}>
                      Physician
                    </InputLabel>
                    <Controller
                      name="physicianId"
                      control={control}
                      error={!!errors.physicianId}
                      as={
                        <Select id="physician-select" fullWidth label="physician">
                          {physicians?.map(({ identity: { firstName, lastName }, id }) => (
                            <MenuItem data-cy="physician" key={id} value={id}>
                              {firstName} {lastName}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      disabled={isFetchingPhysicians}
                    />
                    {errors.physicianId && <FormHelperText error>{errors.physicianId.message}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl data-cy="select" size="medium" variant="outlined" className={classes.formControl}>
                    <InputLabel
                      className={classes.formInput}
                      htmlFor="practitioner-select"
                      error={!!errors.practitionerId}
                    >
                      Practitioner
                    </InputLabel>
                    <Controller
                      name="practitionerId"
                      control={control}
                      error={!!errors.practitionerId}
                      as={
                        <Select id="practitioner-select" fullWidth label="practitioner">
                          {practitioners?.map(({ firstName, lastName, id }) => (
                            <MenuItem data-cy="practitioner" key={id} value={id}>
                              {firstName} {lastName}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      disabled={isLoadingPractitioners}
                    />
                    {errors.practitionerId && <FormHelperText error>{errors.practitionerId?.message}</FormHelperText>}
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <Button
                data-cy="addPatientBtn"
                disabled={loadingCreate}
                variant="contained"
                className={classes.add}
                color="primary"
                type="submit"
              >
                {loadingCreate ? <CircularProgress size={25} /> : 'ADD'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default NewPatient;
