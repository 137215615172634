import { createModel } from '@rematch/core';
import { RootModel } from './rootModel';
import { NewPurchaseOrderState } from '../types/NewPurchaseOrder';
/* eslint-disable camelcase */
import axiosInstance from '../utils/axios';
import compile from '../utils/toastMessagesCompiler';

const initialState = {
  products: [],
  productsSelected: [],
  warehouses: [],
  suppliers: [],
  supplierId: undefined,
  warehouseId: undefined,
  referenceNumber: '',
  internalNote: '',
  orderType: '',
  purchase: {},
};

interface IAddProductToSelect {
  serviceId: number | undefined;
  quantity: number;
  units: number;
  warehouseId: number;
  supplierId: number;
}

export const newPurchaseOrder = createModel<RootModel>()({
  state: { ...initialState } as NewPurchaseOrderState,
  reducers: {
    setProducts(state: NewPurchaseOrderState, payload: any) {
      return { ...state, products: payload };
    },
    setPurchase(state: NewPurchaseOrderState, payload: any) {
      return { ...state, purchase: payload };
    },
    setProductsSelected(state: NewPurchaseOrderState, payload: any) {
      return { ...state, productsSelected: payload };
    },
    setRemoveProductSelected(state: NewPurchaseOrderState, payload: { id: number }) {
      const products = state.productsSelected.filter((item: any) => !(item.id === payload.id));
      return { ...state, productsSelected: products, products: state.products.concat(payload) };
    },
    setAddProductToSelected(
      state: NewPurchaseOrderState,
      payload: { serviceId: number; units: number; quantity: number; warehouseId: number; supplierId: number }
    ) {
      const { serviceId, quantity, warehouseId, supplierId, units } = payload;
      const productsCopy = [...state.products];
      const product: any = productsCopy.find((item: any) => item.id === serviceId);
      const newProduct = {
        id: Date.now(),
        serviceId: product.id,
        quantity: +quantity,
        cost: product.currentCost || 0,
        expireAt: undefined,
        warehouseId,
        supplierId,
        units: +units,
        name: product.name,
        isLocal: true,
      };

      return {
        ...state,
        products: productsCopy,
        productsSelected: [newProduct, ...state.productsSelected],
      };
    },
    setEditProduct(state: NewPurchaseOrderState, payload: { id: number; name: string; value: string | number }) {
      const { id, name, value } = payload;
      const selected = state.productsSelected.map((item: any) =>
        item.id === id ? Object.assign(item, { units: item.units || 1, [name]: value }) : item
      );
      return { ...state, productsSelected: selected };
    },
    setWarehouses(state: NewPurchaseOrderState, payload: any) {
      return { ...state, warehouses: payload };
    },
    setSuppliers(state: NewPurchaseOrderState, payload: any) {
      return { ...state, suppliers: payload };
    },
    setSupplierId(state: NewPurchaseOrderState, payload: number) {
      return { ...state, supplierId: payload };
    },
    setWarehouseId(state: NewPurchaseOrderState, payload: number) {
      return { ...state, warehouseId: payload };
    },
    setReferenceNumber(state: NewPurchaseOrderState, payload: string) {
      return { ...state, referenceNumber: payload };
    },
    setInternalNote(state: NewPurchaseOrderState, payload) {
      return { ...state, internalNote: payload };
    },
    setOrderType(state: NewPurchaseOrderState, payload: any) {
      return { ...state, orderType: payload };
    },
    setResetState() {
      return { ...initialState };
    },
  },
  effects: (dispatch: any) => ({
    async getProductsBySupplierById({ supplierId }: { supplierId: number | undefined }) {
      try {
        const request = await axiosInstance.get(`/suppliers/${supplierId}/services`);
        dispatch.newPurchaseOrder.setProducts(request.data.services);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'loading',
              element: 'products',
            }),
            type: 'error',
          },
        });
      }
    },
    async getProductsSelected() {
      dispatch.newPurchaseOrder.setProductsSelected([]);
    },
    async addProductToSelect({ serviceId, quantity, units, warehouseId, supplierId }: IAddProductToSelect) {
      dispatch.newPurchaseOrder.setAddProductToSelected({ serviceId, quantity, units, warehouseId, supplierId });
    },
    async saveDraft(
      payload: {
        purchaseId: number | undefined;
        referenceNumber: string;
        internalNote: string;
        callback: any;
        orderType: string;
      },
      rootState: any
    ) {
      try {
        const { purchaseId, referenceNumber, internalNote, callback, orderType } = payload;
        const { productsSelected, supplierId, warehouseId } = rootState.newPurchaseOrder as NewPurchaseOrderState;

        const body = {
          supplierId: +supplierId,
          warehouseId: +warehouseId,
          referenceNumber,
          internalNote,
          productsSelected,
          orderType,
        };

        if (purchaseId) {
          const request = await axiosInstance.put(`/purchase-order/${+purchaseId}`, body);

          if (request.status === 200) {
            dispatch.newPurchaseOrder.setProductsSelected(request.data.purchaseOrder.purchaseOrderItems);

            dispatch({
              type: 'snackbar/enqueueSnackBar',
              payload: {
                message: compile('generic.success_message', {
                  element: 'Purchase order',
                  action: 'updated',
                }),
              },
            });
          }

          return;
        }
        const params = {
          supplierId: +supplierId,
          warehouseId: +warehouseId,
          productsSelected,
          referenceNumber,
          internalNote,
          orderType,
          status: 'draft',
        };
        const request = await axiosInstance.post('/purchase-order', params);

        callback(request.data.purchaseOrderId);

        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Draft',
              action: 'saved',
            }),
          },
        });
      } catch (error) {
        const { callback } = payload;
        // @ts-ignore
        callback(error.response.data.purchaseOrderId);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'saving',
              element: 'the draft',
            }),
            type: 'error',
          },
        });
      }
    },
    async getWarehouses() {
      try {
        const request = await axiosInstance.get('/warehouses');
        dispatch.newPurchaseOrder.setWarehouses(request.data.warehouses);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'loading',
              element: 'the Warehouses',
            }),
            type: 'error',
          },
        });
      }
    },
    async getInternalWarehouses() {
      try {
        const request = await axiosInstance.get('/warehouses-internal');
        dispatch.newPurchaseOrder.setWarehouses(request.data.warehouses);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'loading',
              element: 'the Warehouses',
            }),
            type: 'error',
          },
        });
      }
    },
    async getSuppliers() {
      try {
        const request = await axiosInstance.get('/suppliers');
        dispatch.newPurchaseOrder.setSuppliers(request.data.suppliers);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'loading',
              element: 'the Suppliers',
            }),
            type: 'error',
          },
        });
      }
    },
    async loadDraft(payload: { id: number; callback?: any }) {
      try {
        if (!payload.id) {
          return;
        }

        const request = await axiosInstance.get(`/purchase-order/${+payload.id}`);

        if (request.status !== 200) {
          throw new Error(request.statusText);
        }

        if (!request.data.purchaseOrder) {
          payload.callback();
          return;
        }

        dispatch.newPurchaseOrder.setPurchase(request.data.purchaseOrder);
        dispatch.newPurchaseOrder.setSupplierId(request.data.purchaseOrder.supplierId);
        dispatch.newPurchaseOrder.setWarehouseId(request.data.purchaseOrder.warehouseId);
        dispatch.newPurchaseOrder.setOrderType(request.data.purchaseOrder.orderType);
        dispatch.newPurchaseOrder.setReferenceNumber(request.data.purchaseOrder.referenceNumber);
        dispatch.newPurchaseOrder.setInternalNote(request.data.purchaseOrder.internalNote);

        dispatch.newPurchaseOrder.setProductsSelected(request.data.purchaseOrder.purchaseOrderItems);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'loading',
              element: 'the draft',
            }),
            type: 'error',
          },
        });
      }
    },
    async deleteDraft(payload: { id: number; callback: any }) {
      try {
        if (!payload.id) {
          return;
        }

        const { id, callback } = payload;
        const request = await axiosInstance.delete(`/purchase-order/${id}`);

        if (request.status !== 200) {
          throw new Error(request.statusText);
        }

        if (request.data.success) {
          callback();
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile('generic.success_message', {
                element: 'Purchase order',
                action: 'deleted',
              }),
            },
          });
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'deleting',
              element: 'the draft',
            }),
            type: 'error',
          },
        });
      }
    },
    async updateStatus(payload: { id: number; status: string; callback: any }) {
      try {
        const { id, status, callback } = payload;
        const request = await axiosInstance.put(`/purchase-order/${+id}/status`, { status });

        if (request.status !== 200) {
          throw new Error(request.statusText);
        }

        if (request.data.success) {
          callback();
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile('generic.success_message', {
                element: 'status',
                action: 'changed',
              }),
            },
          });
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'changing',
              element: 'the state',
            }),
            type: 'error',
          },
        });
      }
    },
    async deleteOrderItem({ id }: { id: number }) {
      try {
        const request = await axiosInstance.delete(`/purchase-order-item/${id}`);

        if (request.status !== 200) {
          throw new Error(request.statusText);
        }

        if (request.data.success) {
          dispatch.newPurchaseOrder.setRemoveProductSelected({ id });

          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile('generic.success_message', {
                element: 'Order item',
                action: 'deleted',
              }),
            },
          });
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'deleting',
              element: 'the item',
            }),
            type: 'error',
          },
        });
      }
    },
    async updatePurchaseComplete(payload: { purchase_id: number; status: string; callback: any }) {
      try {
        const { purchase_id, status, callback } = payload;
        const request = await axiosInstance.put(`/purchase-order/${purchase_id}/complete`, { status });

        if (request.status !== 200) {
          throw new Error(request.statusText);
        }
        if (request.data.purchaseOrder.id) {
          dispatch.newPurchaseOrder.setPurchase(request.data.purchaseOrder);
          callback();

          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile('generic.success_message', {
                element: 'Status',
                action: 'updated',
              }),
            },
          });
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'updating',
              element: 'the purchase order',
            }),
            type: 'error',
          },
        });
      }
    },
  }),
});
