import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { filter as _filter } from 'lodash';
import IWarningsList from '../../../../interfaces/IWarningsList';
import Warning from './Warning';
import { LIMIT_TO_SHOW_HYALURONIDASE_WARNING } from '../../../../constants/general.constants';
import { PRACTITIONER_PRODUCTS_ABOUT_PATH } from '../../../../routes/practitionerRoutes';

const WarningsList: React.FC<IWarningsList> = ({ showProductsIds }) => {
  const history = useHistory();
  const { dashboardList, productAtRiskCount } = useSelector(({ inventoryDefault }: any) => ({ ...inventoryDefault }));
  const dashboardFillersList = _filter(dashboardList, { title: 'HA Fillers' })[0];
  const hylenex = _filter(dashboardFillersList?.products, { productName: 'Hylenex' })[0];
  const hyaluronidase = _filter(dashboardFillersList?.products, { productName: 'Hyaluronidase' })[0];
  const hylenexQuantity = (hylenex && hylenex.quantity) || 0;
  const hyaluronidaseQuantity = (hyaluronidase && hyaluronidase.quantity) || 0;
  const hyaluronidaseTotal = hylenexQuantity + hyaluronidaseQuantity;

  const navigateToProductsToExpire = () => {
    history.push(PRACTITIONER_PRODUCTS_ABOUT_PATH);
  };

  return (
    <>
      {productAtRiskCount === 0 ? null : (
        <Warning
          message="Vials are at risk of expiration."
          productAtRiskCount={productAtRiskCount}
          onClick={navigateToProductsToExpire}
          color="#f2d1ab"
        />
      )}
      {hyaluronidaseTotal < LIMIT_TO_SHOW_HYALURONIDASE_WARNING && (hyaluronidase || hylenex) ? (
        <Warning
          message="You have less than 8 vials of Hyaluronidase."
          onClick={() => showProductsIds(hyaluronidase || hylenex)}
          color="#FBDADA"
        />
      ) : null}
    </>
  );
};

export default WarningsList;
