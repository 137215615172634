import * as yup from 'yup';
import {
  FIRST_NAME,
  FIRST_NAME_MAX,
  LAST_NAME,
  LAST_NAME_MAX,
  EMAIL,
  VALID_EMAIL,
  PHONE,
  SIGNATURE_IMAGE,
  PROFILE_IMAGE,
  SERVICE_COMMISSION,
  ROLE,
  VALID_PHONE,
  VALID_PHONE_REGEX,
} from '../../../constants/schemaForm.constants';

export const schemaForm = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME).max(64, FIRST_NAME_MAX),
  lastName: yup.string().required(LAST_NAME).max(64, LAST_NAME_MAX),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  phone: yup.string().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
  signatureImage: yup.string().required(SIGNATURE_IMAGE),
  profileImage: yup.string().required(PROFILE_IMAGE),
  servicesCommission: yup.string().required(SERVICE_COMMISSION),
  googleReview: yup.string(),
  squareLocationId: yup.string(),
  yelpReview: yup.string(),
  medicalGroup: yup.string().nullable(),
  roleId: yup.string().required(ROLE),
  acuityUserId: yup.string(),
  enableAcuityEnterprise: yup.boolean(),
  userGroupId: yup.number().nullable(),
});

export const schemaFormEdit = yup.object().shape({
  firstName: yup.string().trim().required(FIRST_NAME),
  lastName: yup.string().trim().required(LAST_NAME),
  phone: yup.string().trim().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
  email: yup.string().trim().required(EMAIL).email(VALID_EMAIL),
  servicesCommission: yup.string().nullable(),
  googleReview: yup.string(),
  yelpReview: yup.string(),
  medicalGroup: yup.string().nullable(),
  roleId: yup.string(),
  acuityUserId: yup.string(),
  userGroupId: yup.number().nullable(),
});
