import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { Box, Button, CardContent, FormControl, MenuItem, Select } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { uniq } from 'lodash';
import { SmallTitle, Card } from '../../common/card';
import { useStyles } from './index.style';

import { RootState, dispatch } from '../../../rematch';
import { IPractitioner } from '../../../interfaces/IPractitioner';
import IServiceVisit from '../../../interfaces/IServiceVisits';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { hasAccessTo } from '../../../utils/auth.utils';
import { READ_USER_GROUP, UPDATE_CUSTOMER_CHANGE_PRACTITIONER } from '../../../constants/actions.constants';
import { CONTACT_INFO } from '../../../hooks/queries/useCustomContactInfo';
import MultipleSelector from '../../common/Custom/MultipleSelector';
import { useUserGroups } from '../../../hooks/queries/useUserGroups';
import { usePractitionersDropdown } from '../../../hooks/queries/usePractitionersDropdown';
import {
  useCustomerPractitionersAssigned,
  useUpdateCustomerPractitionersAssignedMutation,
} from '../../../hooks/queries/customers/customerPractitioners';

const buttonStyle = {
  color: '#000000',
  backgroundColor: '#e7eeed',
  border: 'solid 1px rgba(0, 0, 0, 0.12)',
  height: '34px',
  marginRight: 0,
};

const customerPractitionersSchema = yup.object().shape({
  customerPractitioners: yup.array(),
  medspaAdmins: yup.array(),
});

const AssignedPractitioner = () => {
  const classes = useStyles();
  const { patientId }: any = useParams();
  const queryClient = useQueryClient();

  const permissions = useSelector(({ auth }: RootState) => auth.permissions);
  const canReadUserGroups = hasAccessTo(READ_USER_GROUP, permissions);
  const {
    practitioners,
    contactInfo: { id, practitioner, contactInfoLoadedAt },
    serviceVisits,
    isLoadingContactInfo,
    isLoadingPractitioners,
  } = useSelector(({ patient }: RootState) => patient);

  const { data: medspas, isLoading: isLoadingMedspaAdmins } = useUserGroups({
    disabled: !canReadUserGroups,
  });

  const { data: practitionerList } = usePractitionersDropdown();

  const {
    data: practitionersAssigned,
    isLoading: isLoadingPractitionersAssigned,
    isFetching: isFetchingPractitionersAssigned,
  } = useCustomerPractitionersAssigned(+patientId);
  const updatePractitionersAssignedMutation = useUpdateCustomerPractitionersAssignedMutation(+patientId);
  const sharePractitionerLoaded = !isLoadingMedspaAdmins && !isLoadingPractitionersAssigned;

  const practitionerOptions = useMemo(
    () =>
      practitionerList?.map(({ userGroupName, firstName, lastName, id: practitionerId }) => ({
        name: `${firstName} ${lastName}${userGroupName ? ` - ${userGroupName}` : ''}`,
        value: practitionerId,
      })) || [],
    [medspas, practitionerList]
  );

  const { control } = useForm<FormData>({
    resolver: yupResolver(customerPractitionersSchema),
    defaultValues: { customerPractitioners: [], medspaAdmins: [] },
  });
  const [changePractitioner, setChangePractitioner] = useState(false);

  const handleChangePractitioner = () => {
    if (serviceVisits !== null && serviceVisits.some(({ opened }: IServiceVisit) => opened)) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: 'There is a service visit currently open',
          type: 'error',
        },
      });
      return;
    }
    setChangePractitioner(!changePractitioner);
  };

  const setNewPractitioner = (event: any) => {
    dispatch({
      type: 'patient/changePractitionerRequest',
      payload: {
        practitioner_id: event.target.value,
        patientId: id,
        callback: () => {
          setChangePractitioner(false);
          // invalidate the contact to refresh the practitioner and physician
          id !== null && queryClient.invalidateQueries([CONTACT_INFO, id.toString()]);
        },
      },
    });
  };

  const onChangeAdmins = (adminIds: number[]) => {
    const practitionersInAdmin =
      practitionerList
        ?.filter(({ userGroupId }) =>
          userGroupId !== undefined && userGroupId !== null ? adminIds.includes(userGroupId) : false
        )
        .map(({ id: practitionerId }) => practitionerId) || [];

    onChangePractitioners(uniq([...practitionersAssigned, ...practitionersInAdmin]));
  };

  const onChangePractitioners = (newVal: any) => {
    updatePractitionersAssignedMutation.mutate({
      practitionerIds: newVal,
    });
  };

  const fullyLoaded =
    !isLoadingContactInfo && !isLoadingPractitioners && !isLoadingPractitionersAssigned && contactInfoLoadedAt;

  return (
    <Card>
      {!fullyLoaded ? (
        <Card>
          <CardContent style={{ padding: '12px 15px' }}>
            <ShortMultipleSkeleton length={2} />
          </CardContent>
        </Card>
      ) : (
        <CardContent>
          <SmallTitle title="Assigned practitioner" style={{ marginTop: '0', marginBottom: '18px' }} />
          <div className={classes.selectedPractitioner}>
            Default Practitioner: {practitioner ? `${practitioner.providerLabeling}` : 'None'}
            {hasAccessTo(UPDATE_CUSTOMER_CHANGE_PRACTITIONER, permissions) && (
              <Button style={buttonStyle} onClick={() => handleChangePractitioner()}>
                {changePractitioner ? 'Cancel' : 'Change'}
              </Button>
            )}
          </div>
          {changePractitioner && (
            <FormControl variant="outlined" className={classes.practitionerDropdown}>
              <Select
                className={classes.dropdown}
                value={practitioner?.id}
                onChange={(item) => {
                  setNewPractitioner(item);
                }}
              >
                {(practitioners || []).map(({ providerLabeling, id: practitionerId }: IPractitioner) => (
                  <MenuItem key={practitionerId} value={practitionerId}>
                    {providerLabeling}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {hasAccessTo(UPDATE_CUSTOMER_CHANGE_PRACTITIONER, permissions) &&
            canReadUserGroups &&
            sharePractitionerLoaded && (
            <>
              <Box paddingTop="10px">
                <MultipleSelector
                  autocomplete
                  disabled={updatePractitionersAssignedMutation.isLoading || isFetchingPractitionersAssigned}
                  label="Add providers by Medspa"
                  name="medspaAdmins"
                  control={control}
                  value={[]}
                  onChange={onChangeAdmins}
                  errors={null}
                  options={
                    medspas?.userGroups?.map(({ name, id: userGroupId }) => ({
                      name: `Add providers of ${name}`,
                      value: userGroupId,
                    })) || []
                  }
                />
              </Box>
              <Box paddingTop="10px">
                {updatePractitionersAssignedMutation.isLoading || isLoadingPractitionersAssigned ? (
                  <ShortMultipleSkeleton length={2} />
                ) : (
                  <MultipleSelector
                    autocomplete
                    disabled={updatePractitionersAssignedMutation.isLoading || isFetchingPractitionersAssigned}
                    label="Practitioners"
                    name="customerPractitioners"
                    control={control}
                    value={practitionersAssigned}
                    onChange={onChangePractitioners}
                    errors={null}
                    options={practitionerOptions}
                  />
                )}
              </Box>
            </>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default AssignedPractitioner;
