import React from 'react';
import moment from 'moment';

import { TableRow } from '@material-ui/core';
import { ITender } from '../../../interfaces/ITransaction.interfaces';
import { Pill } from '../../common/Pill';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { useRowStyles } from './transactions.styles';

interface TendersRowProps {
  tender: ITender;
}

const TendersRow: React.FC<TendersRowProps> = ({ tender }: { tender: ITender }) => {
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCellCustom>{tender.checkoutId}</TableCellCustom>
        <TableCellCustom>{tender.serviceVisitId}</TableCellCustom>
        <TableCellCustom>{tender.checkoutTenderId}</TableCellCustom>
        <TableCellCustom>{moment(tender.transactionDateCreatedAt).format('MM/DD/YYYY')}</TableCellCustom>
        <TableCellCustom>{tender.transactionTimeCreatedAt} </TableCellCustom>
        <TableCellCustom>{tender.customerId}</TableCellCustom>
        <TableCellCustom>{tender.practitioner}</TableCellCustom>
        <TableCellCustom>{tender.voucher}</TableCellCustom>
        <TableCellCustom>{tender.value}</TableCellCustom>
        <TableCellCustom>{tender.tenderType}</TableCellCustom>
        <TableCellCustom>
          <a href={tender.ehrUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
            <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
          </a>
        </TableCellCustom>
      </TableRow>
    </>
  );
};

export default TendersRow;
