import React, { useState } from 'react';

import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import TabItem from 'src/components/common/TabItem';
import { PortraitEdgeChart } from 'src/components/DashboardPractitioner/Tabs/PractitionerDashboardTab/JointInvestments';
import { useSelector } from 'react-redux';
import { READ_JOINT_INVESTMENT } from 'src/constants/actions.constants';
import { hasAccessTo } from 'src/utils/auth.utils';
import Page from 'src/components/common/Page';
import MedspaAdminEarningsCalculator from './EarningsTab';

const MedspaAdminEarnings = () => {
  const { permissions } = useSelector(({ auth }: any) => auth);
  const [tab, setTab] = useState(0);

  const handleTabChange = (event: any, newTab: number) => {
    setTab(newTab);
  };

  const a11yProps = (index: any) => ({
    id: `earnings-tab-${index}`,
    'aria-controls': `transactions-tabpanel-${index}`,
  });

  return (
    <Page title="Earnings">
      <Grid container>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: 'black' } }}
          value={tab}
          onChange={handleTabChange}
          style={{ borderBottom: '1px solid lightgrey', width: '100%' }}
        >
          <Tab label="Earnings Calculator" {...a11yProps('calculator')} />
          {hasAccessTo(READ_JOINT_INVESTMENT, permissions) && (
            <Tab label="Joint Investments" {...a11yProps('joint-investments')} />
          )}
        </Tabs>

        <TabItem value={tab} index={0}>
          <Box>
            <MedspaAdminEarningsCalculator />
          </Box>
        </TabItem>
        {hasAccessTo(READ_JOINT_INVESTMENT, permissions) && (
          <TabItem value={tab} index={1}>
            <Box>
              <PortraitEdgeChart />
            </Box>
          </TabItem>
        )}
      </Grid>
    </Page>
  );
};

export default MedspaAdminEarnings;
