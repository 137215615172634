import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  cardContentPhoto: {
    paddingBottom: '5px',
  },
  photo: {
    maxHeight: navigator.userAgent.match('CriOS') ? '75vh' : '80vh',
    maxWidth: '80vw',
  },
  cardContentButtons: {
    paddingTop: '0',
    paddingBottom: '10px',
    color: '#12574d',
  },
  closeButton: {
    color: '#12574d',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
  },
  ViewOriginalButton: {
    color: '#ffffff',
    backgroundColor: '#12574d',
  },
  profileImage: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '100%',
    height: '100px',
    cursor: 'pointer',
    objectFit: 'cover',
    border: '1px solid rgb(228, 231, 235)',
  },
  changeImageButton: {
    top: '0',
    right: '0',
    color: '#12574d',
    margin: 'auto',
    padding: '5px 0',
    minWidth: '100%',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
    '& span': {
      margin: 0,
    },
  },
  photoContainer: {
    marginTop: '1rem',
    display: 'flex',
    maxWidth: '500px',
    flexDirection: 'column',
  },
  changePhotoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  changePhotoButton: {
    border: 'none',
    background: 'none',
    color: '#12574d',
    cursor: 'pointer',
    fontSize: '16px',
  },
  uploadIcon: {
    width: '140px',
    cursor: 'pointer',
  },
  button: {
    width: '140px',
    height: '30px',
    backgroundColor: '#E7EEED',
    fontSize: '12px',
    textTransform: 'none',
    color: '#12574d',
    '&:hover': {
      backgroundColor: 'rgba(58, 59, 63, 0.04)',
    },
  },
}));
