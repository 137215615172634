import { createModel } from '@rematch/core';
import { SERVICE_VISIT_INITIAL_STATE } from '../constants/newServiceVisit.constants';
import { addLoadingProp } from '../utils/newServiceVisit.util';
import {
  createNewServiceVisitRequest,
  addServiceNoteRequest,
  getServiceVisitFromPatient,
  fetchServices,
  uploadPhotosRequest,
  saveProgressStep1,
  discardNewServiceVisit,
  saveNewServiceVisit,
  saveNotesForServiceVisit,
  checkout,
  refundUndo,
  fetchCheckout,
  squareResponse,
  deleteAnnotated,
  submitReviewRequest,
  fetchDiscounts,
  payStaging,
  checkoutServiceVisitWithCard,
} from './newServiceVisit.actions';
import { RootModel } from './rootModel';

const newServiceVisit = createModel<RootModel>()({
  state: { ...SERVICE_VISIT_INITIAL_STATE },
  reducers: {
    getServiceVisit(state: any, { serviceVisit, savedData, oldAnnotations }: any) {
      // data that should not be erased by the progressive saving restore
      const stateCheckout = {
        squareErrorCode: state.checkout.squareErrorCode,
        squareTransactionId: state.checkout.squareTransactionId,
        transactionStatus: state.checkout.transactionStatus,
        tips: state.checkout.tips,
        galdermaApplied: state.checkout.galdermaApplied,
        discounts: state.checkout.discounts,
      };

      const newCheckout = { ...state.checkout, ...savedData.checkout, ...stateCheckout };
      return { ...state, serviceVisit, ...savedData, checkout: newCheckout, currentStep: 0, oldAnnotations };
    },
    setIsLoadingGetServiceVisitFromPatient(state: any, payload: boolean) {
      return { ...state, isLoadingGetServiceVisitFromPatient: payload };
    },
    setIsLoadingProgressingSaving(state: any, payload: boolean) {
      return { ...state, isLoadingProgressingSaving: payload };
    },
    createNewServiceVisit() {
      return { ...SERVICE_VISIT_INITIAL_STATE, isLoading: true };
    },
    createNewServiceVisitSuccess(state: any, payload: any) {
      return { ...SERVICE_VISIT_INITIAL_STATE, serviceVisit: payload, isLoading: false };
    },
    createNewServiceVisitFail(state: any) {
      return { ...state, isLoading: false };
    },
    addServiceNoteSuccess(state: any, { serviceVisit }: any) {
      return { ...state, serviceVisit };
    },
    getServices(state: any, { services }: any) {
      return { ...state, services };
    },
    setIsLoadingServices(state: any, payload: boolean) {
      return { ...state, isLoadingServices: payload };
    },
    selectService(state: any, payload: any) {
      return { ...state, ...payload };
    },
    updateServicesUnits(state: any, { servicesUnits }: any) {
      return { ...state, servicesUnits };
    },
    updateCreditServicesUnits(state: any, { creditServicesUnits }: any) {
      return { ...state, creditServicesUnits };
    },
    updateVariantsUnits(state: any, { variantsUnits }: any) {
      return { ...state, variantsUnits };
    },
    updateCreditVariantsUnits(state: any, { creditVariantsUnits }: any) {
      return { ...state, creditVariantsUnits };
    },
    updateTotalUnits(state: any, payload: any) {
      return { ...state, ...payload };
    },
    uploadPhotosLoading(state: any, { type, loading }: any) {
      let newState = { ...state };
      newState = addLoadingProp(newState, type, loading);
      return { ...newState };
    },
    uploadPhotosSuccess(state: any, { newPhotos, type }: any) {
      const updatedPhotos = newPhotos.map((item: any) => ({ ...item, photoId: item.id }));
      const photos = updatedPhotos.concat(state.serviceVisit.photos);
      const newState = addLoadingProp(state, type, false);
      newState.serviceVisit.photos = photos;
      return newState;
    },
    updateCurrentStep(state: any, payload: any) {
      return { ...state, currentStep: payload };
    },
    signConsent(state: any, payload: number) {
      const newSignedConsent = state.signedConsents;
      newSignedConsent[payload] = true;

      return { ...state, signedConsents: { ...state.signedConsents, ...newSignedConsent } };
    },
    populateForSelectedServiceGroups(state: any) {
      const selectedServiceIds = [...state.selectedServices];
      const selectedServices = state.services.filter((service: any) => selectedServiceIds.includes(service.id));
      const selectedServiceGroupIds = selectedServices.map((service: any) => service.serviceGroupId);

      return { ...state, selectedServiceGroups: selectedServiceGroupIds };
    },
    showConsentsAlert(state: any, payload: boolean) {
      return { ...state, showConsentsAlert: payload };
    },
    updateStandingOrders(state: any, { signedStandingOrders }: any) {
      return { ...state, signedStandingOrders };
    },
    saveProgressSuccess(state: any, payload) {
      return { ...state, ...payload };
    },
    cleanServiceVisitData() {
      return SERVICE_VISIT_INITIAL_STATE;
    },
    updateNotes(state: any, payload) {
      const newState = { ...state };
      Object.assign(newState.serviceVisit, { ...payload });
      return { ...newState };
    },
    clearDiscounts(state: any) {
      const { servicesDiscounts, variantsDiscounts } = state.checkout;
      const removedDiscountsIds = Object.entries(state.checkout.servicesDiscounts)
        .filter(([, value]: any) => value.some(({ id }: any) => id))
        .map(([key]: any) => +key);

      removedDiscountsIds.forEach((serviceId: number) => {
        delete servicesDiscounts[serviceId];
      });
      const removedVariantsDiscountsIds = Object.entries(state.checkout.variantsDiscounts)
        .filter(([, value]: any) => value.some(({ id }: any) => id))
        .map(([key]: any) => +key);

      removedVariantsDiscountsIds.forEach((variantItemId: number) => {
        delete variantsDiscounts[variantItemId];
      });

      return { ...state, checkout: { ...state.checkout, servicesDiscounts, variantsDiscounts } };
    },
    clearDiscountByServiceId(state: any, serviceId: number) {
      const { servicesDiscounts } = state.checkout;
      delete servicesDiscounts[serviceId];
      return { ...state, checkout: { ...state.checkout, servicesDiscounts } };
    },
    clearDiscountByvariantItemId(state: any, variantItemId: number) {
      const { variantsDiscounts } = state.checkout;
      delete variantsDiscounts[variantItemId];
      return { ...state, checkout: { ...state.checkout, variantsDiscounts } };
    },
    applyServicesDiscounts(state: any, payload: any) {
      return { ...state, checkout: { ...state.checkout, servicesDiscounts: payload } };
    },
    applyVariantsDiscounts(state: any, payload: any) {
      return { ...state, checkout: { ...state.checkout, variantsDiscounts: payload } };
    },
    applyExtraCheckoutData(state: any, payload: any) {
      return { ...state, checkout: { ...state.checkout, ...payload } };
    },
    processCheckout(state: any) {
      return { ...state, isLoading: true };
    },
    processCheckoutSuccess(state: any, payload: any) {
      return { ...state, checkout: { ...state.checkout, ...payload.checkout }, isLoading: false };
    },
    processCheckoutFail(state: any) {
      return { ...state, isLoading: false };
    },
    processingCheckout(state: any, processingCheckout: boolean) {
      return { ...state, processingCheckout };
    },
    updateCurrentService(state: any, payload: any) {
      const { service } = payload;
      return { ...state, currentService: service };
    },
    saveNewServiceVisitSuccess(state: any, payload: any) {
      return { ...state, serviceVisit: { ...state.serviceVisit, ...payload } };
    },
    deleteAnnotatedSuccess(state: any, payload: any) {
      const annotatedPhoto = state.serviceVisit.annotatedPhotos.find(({ photo }: any) => photo.id === payload);
      if (annotatedPhoto) {
        annotatedPhoto.photoServices = [];
      }

      const oldAnnotations = JSON.parse(JSON.stringify(state.serviceVisit.annotatedPhotos));
      return { ...state, oldAnnotations };
    },
    updateOldAnnotations(state: any) {
      const oldAnnotations = JSON.parse(JSON.stringify(state.serviceVisit.annotatedPhotos));
      return { ...state, oldAnnotations };
    },
    serviceVisitDirty(state: any) {
      return { ...state, serviceVisitDirty: true };
    },
    getDiscountsSuccess(state: any, payload: any) {
      return { ...state, discounts: payload };
    },
    setProducts(state: any, payload: object[]) {
      return { ...state, productsSelected: [...payload] };
    },
    updateCustomerPhotoConsent(state: any, { customerPhotoConsent }: any) {
      return { ...state, customerPhotoConsent };
    },
    updateAfterPhotosConsent(state: any, payload: object) {
      return { ...state, afterPhotos: { ...state.afterPhotos, ...payload } };
    },
    updateServiceVisitSessionId(state: any, { serviceVisitSessionId }: any) {
      return { ...state, serviceVisitSessionId };
    },
    removeServiceDiscount(state: any, payload: number) {
      const checkoutState = { ...state.checkout };
      delete checkoutState.servicesDiscounts[payload];
      return { ...state, checkout: checkoutState };
    },
    removeVariantDiscount(state: any, payload: number) {
      const checkoutState = { ...state.checkout };
      delete checkoutState.variantsDiscounts[payload];
      return { ...state, checkout: checkoutState };
    },
    applyCredit(state: any, payload: any) {
      let futureCreditUsed = state.futureCreditUsed.filter(({ serviceId }: any) => serviceId !== payload.serviceId);
      futureCreditUsed = [...futureCreditUsed, payload];
      return { ...state, futureCreditUsed };
    },
    removeCredit(state: any, payload: number) {
      const futureCreditUsed = state.futureCreditUsed.filter(({ serviceId }: any) => serviceId !== payload);
      return { ...state, futureCreditUsed };
    },
    applyVariantCredit(state: any, payload: any) {
      let futureVariantCreditUsed = state.futureVariantCreditUsed.filter(
        ({ variantItemId }: any) => variantItemId !== payload.variantItemId
      );
      futureVariantCreditUsed = [...futureVariantCreditUsed, payload];
      return { ...state, futureVariantCreditUsed };
    },
    removeVariantCredit(state: any, payload: number) {
      const futureVariantCreditUsed = state.futureVariantCreditUsed.filter(
        ({ variantItemId }: any) => variantItemId !== payload
      );
      return { ...state, futureVariantCreditUsed };
    },
    setIsLoadingFetchCheckout(state: any, payload: boolean) {
      return { ...state, isLoadingFetchCheckout: payload };
    },
    setIsProgressQueueWorking(state: any, payload: boolean) {
      return { ...state, isProgressQueueWorking: payload };
    },
    setAllowSaveProgress(state: any, payload: boolean) {
      return { ...state, allowSaveProgress: payload };
    },
    setSynchronizingServiceVisit(state: any, payload: boolean) {
      return { ...state, synchronizingServiceVisit: payload };
    },
  },
  effects: {
    discardNewServiceVisit,
    createNewServiceVisitRequest,
    addServiceNoteRequest,
    getServiceVisitFromPatient,
    fetchServices,
    uploadPhotosRequest,
    saveProgressStep1,
    saveNewServiceVisit,
    saveNotesForServiceVisit,
    checkout,
    refundUndo,
    fetchCheckout,
    squareResponse,
    deleteAnnotated,
    submitReviewRequest,
    fetchDiscounts,
    payStaging,
    checkoutServiceVisitWithCard,
    async getCurrentService(_, globalState: any) {
      return globalState?.newServiceVisit?.currentService;
    },
  },
});

export default newServiceVisit;
