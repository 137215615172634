/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { DROPDOWN_STATUSES } from '../../../constants/scheduler.constants';

const useStyles = makeStyles((theme) => ({
  dropdownSelector: {
    lineHeight: '28px',
    color: 'white',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropdownOptions: {
    top: '38px',
    left: '188px',
    zIndex: 3,
    width: '121px',
    background: '0% 0% no-repeat padding-box padding-box rgb(231, 238, 237)',
    boxShadow: 'rgb(0 0 0 / 44%) 0px 3px 8px',
    color: 'black',
    position: 'absolute',
    borderRadius: '2px',
  },
  dropdownOption: {
    height: '25px',
    lineHeight: '26px',
    paddingLeft: '13px',
    color: theme.palette.primary.main,
    '&:hover': {
      background: '#DBDBDB',
    },
  },
  dropdownActiveOption: {
    background: '#DBDBDB',
  },
  arrow: {
    position: 'relative',
    top: '7px',
  },
}));

const AppointmentsDropdown = ({ status, handleStatus }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleOption = (option: string) => {
    handleStatus(option);
    setOpen(false);
  };

  return (
    <div className={classes.dropdownSelector} data-cy="calendarPatientFilter">
      Filter by Patient Clearance status:
      <div onClick={() => setOpen(!open)}>
        {DROPDOWN_STATUSES[status]}
        {open ? <ArrowDropUpIcon className={classes.arrow} /> : <ArrowDropDownIcon className={classes.arrow} />}
      </div>
      <div className={classes.dropdownOptions} style={{ display: open ? 'initial' : 'none' }}>
        <div className={classes.dropdownOption} onClick={() => handleOption('all')}>
          All
        </div>
        <div className={classes.dropdownOption} onClick={() => handleOption('to_clear')}>
          Not cleared
        </div>
        <div className={classes.dropdownOption} onClick={() => handleOption('incomplete')}>
          Incomplete
        </div>
        <div className={classes.dropdownOption} onClick={() => handleOption('cleared')}>
          Cleared
        </div>
      </div>
    </div>
  );
};
export default AppointmentsDropdown;
