import React, { useState } from 'react';
import {
  Avatar,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Page from 'src/components/common/Page';
import { usePractitionerForPhysicians } from '../../hooks/practitioner/usePractitioner';
import { IPractitionerForPhysician } from '../../interfaces/IPractitioner';
import { useStyles } from './PhysicianAssignedProvidersList.styles';
import { MultipleSkeleton } from '../../components/common/LoadingSkeleton';
import { SelectCustom } from '../../components/DashboardAdministrator/Inventory/supplyReceipt.styles';
import { MEDICAL_LICENSES } from '../AccountSetting/MoreDetails';
import { PHYSICIAN_PROVIDERS_PATH } from '../../routes/physicianRoutes';
import { PortraitTable } from '../../components/common/TableNew/Table';

const MD_PROVIDER_COLUMNS = [
  { id: 'name', title: 'Name', className: 'Name-header' },
  { id: 'role', title: 'Role Type', className: 'Type-header' },
  { id: 'id', title: 'ID', className: 'ID-header' },
  { id: 'license', title: 'Licenses', className: 'Licenses-header' },
  { id: 'completed_review_percentage', title: 'Chart Review', className: 'Visits-header' },
];

const CLEARING_PROVIDER_COLUMNS = [
  { id: 'name', title: 'Name', className: 'Name-header' },
  { id: 'role', title: 'Role Type', className: 'Type-header' },
  { id: 'id', title: 'ID', className: 'ID-header' },
  { id: 'license', title: 'Licenses', className: 'Licenses-header' },
  { id: 'status', title: 'GFE Status', className: 'Status-header' },
];

const PractitionerRow = ({ practitionerData }: { practitionerData: IPractitionerForPhysician }) => {
  const classes = useStyles();
  const { user } = useSelector(({ auth }: any) => auth);
  const history = useHistory();
  const hasPendingGfe = practitionerData.pendingGfeCount > 0;

  return (
    <TableRow
      onClick={() => history.push(`${PHYSICIAN_PROVIDERS_PATH}/${practitionerData.id}`)}
      style={{ cursor: 'pointer' }}
      key={practitionerData.id}
    >
      <TableCell className={classes.flexRow}>
        <Avatar
          className={classes.avatar}
          alt={practitionerData.fullName}
          src={practitionerData.avatarUrl || '/static/images/avatar/1.jpg'}
        />
        {practitionerData.fullName}
      </TableCell>
      <TableCell>{practitionerData.role}</TableCell>
      <TableCell>{practitionerData.id}</TableCell>
      <TableCell>{practitionerData.licenses}</TableCell>
      {user.isAdvancedPractitioner && (
        <TableCell>
          <span className={hasPendingGfe ? classes.pending : classes.completed}>
            {hasPendingGfe ? `${practitionerData.pendingGfeCount} Pending` : 'Completed'}
          </span>
        </TableCell>
      )}
      {!user.isAdvancedPractitioner && <TableCell>{practitionerData.completedReviewPercentage}</TableCell>}
    </TableRow>
  );
};

export const PhysicianAssignedProvidersList = () => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>('');
  const [license, setLicense] = useState<string>('');
  const [gfeStatus, setGfeStatus] = useState<string>('');
  const { user } = useSelector(({ auth }: any) => auth);
  const {
    data: practitioners = [],
    isLoading,
    isFetching,
  } = usePractitionerForPhysicians({ search, gfeStatus, license });

  if (isLoading) {
    return <MultipleSkeleton addPosition={false} />;
  }

  return (
    <Page title="Assigned Providers">
      <div className={classes.physicianPage} data-testid="physician-provider-list-main">
        <div className={`${classes.flexRow} ${classes.filterContainer}`}>
          <FormControl size="small" variant="outlined">
            <InputLabel htmlFor="search" color="secondary" className={classes.search}>
              Search
            </InputLabel>
            <OutlinedInput
              data-cy="providerSearchInput"
              id="search"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton style={{ padding: 0 }}>
                    {isFetching ? (
                      <CircularProgress style={{ color: 'black' }} size={20} />
                    ) : (
                      <SearchIcon style={{ color: 'black' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          <div className={classes.flexRow}>
            <FormControl size="small" variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="licenseSearch" color="secondary" className={classes.search}>
                License
              </InputLabel>
              <SelectCustom
                data-cy="from"
                name="license"
                value={license}
                onChange={(e) => setLicense(e.target.value as string)}
                label="License"
              >
                <MenuItem value={undefined}>
                  <em>All</em>
                </MenuItem>
                {MEDICAL_LICENSES.map((licenseType) => (
                  <MenuItem value={licenseType} key={licenseType}>
                    {licenseType}
                  </MenuItem>
                ))}
              </SelectCustom>
            </FormControl>
            {user.isAdvancedPractitioner && (
              <FormControl size="small" className={classes.formControl} variant="outlined">
                <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>GFE Status</InputLabel>
                <SelectCustom
                  data-cy="from"
                  name="gfeStatus"
                  value={gfeStatus}
                  onChange={(e) => setGfeStatus(e.target.value as string)}
                  label="GFE Status"
                >
                  <MenuItem value={undefined}>
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value="notCleared">Pending</MenuItem>
                  <MenuItem value="cleared">Completed</MenuItem>
                </SelectCustom>
              </FormControl>
            )}
          </div>
        </div>
        <PortraitTable
          columns={user.isAdvancedPractitioner ? CLEARING_PROVIDER_COLUMNS : MD_PROVIDER_COLUMNS}
          infiScroll={false}
          isLoading={isLoading}
          isFetching={isFetching}
          data={practitioners}
          rowComponent={(row) => <PractitionerRow practitionerData={row} />}
          errorMessage="Error fetching Practitioners"
        />
      </div>
    </Page>
  );
};
