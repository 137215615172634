import { InputBase, withStyles } from '@material-ui/core';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
    '&.Mui-error': {
      '& input': {
        borderColor: '#f44336',
      },
      '& input::placeholder': {
        color: '#f44336',
      },
    },
  },
  input: {
    borderRadius: '0.375rem',
    borderColor: '#C8C8C8',
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
    padding: '0.75rem 1rem',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    backgroundColor: '#FFFFFF',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `#000 0 0 0 0rem`,
      borderColor: theme.palette.primary.main,
    },
    '&::placeholder': {
      color: 'rgb(64, 64, 64)',
      fontWeight: 400,
    },
  },
}))(InputBase);

export { BootstrapInput };
