import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, MenuItem, Typography } from '@material-ui/core';
import { useAddTrackingNumber, useValidateTrackingNumber } from 'src/hooks/queries/useInventoryOrders';
import { EHRSelect, EHRSelectOnChange, EHRTextField } from 'src/components/ui/v1';
import useSuppliers from 'src/hooks/queries/useSuppliers';
import { dispatch } from 'src/rematch';
import { ShipmentTrackingNumber } from 'src/services/ShipmentTrackingNumbers';
import classNames from 'classnames';
import { modalStyles } from './OrderApprovalRow.styles';

const MINIMUM_SEARCH_LENGTH = 6;

type Props = {
  id: number;
  dismiss: () => void;
};

export const AddTrackingModal = ({ id, dismiss }: Props) => {
  const classes = modalStyles();

  const [saving, setSaving] = useState<boolean>(false);
  const [supplierId, setSupplierId] = useState<number | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [trackingNumber, setTrackingNumber] = useState<string | null>(null);

  const formData = {
    supplierId,
    companyName,
    trackingNumber,
  } as Partial<ShipmentTrackingNumber>;

  const { mutateAsync: addTrackingNumber } = useAddTrackingNumber(id);
  const { data: suppliers = [], isLoading: isLoadingSuppliers } = useSuppliers();
  const { data: courierName } = useValidateTrackingNumber(
    trackingNumber ?? '',
    !companyName && !!trackingNumber && trackingNumber.length > MINIMUM_SEARCH_LENGTH
  );

  const handleChangeSupplier: EHRSelectOnChange = (value) => setSupplierId(+value);
  const handleChangeCompanyName = (event: ChangeEvent<HTMLInputElement>) => setCompanyName(event.currentTarget.value);
  const handleChangeTrackingNumber = (event: ChangeEvent<HTMLInputElement>) =>
    setTrackingNumber(event.currentTarget.value);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const invalidFields = Object.keys(formData).filter((key) => !formData[key as keyof ShipmentTrackingNumber]);

    if (!invalidFields.length) {
      try {
        setSaving(true);
        await addTrackingNumber(formData);
        setSaving(false);
        dismiss();
      } catch {
        setSaving(false);
      }
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: `Invalid Field(s): ${invalidFields.join(', ')}`,
          type: 'error',
        },
      });
    }
  };

  useEffect(() => {
    !!courierName && setCompanyName(courierName);
  }, [courierName]);

  return (
    <Box className={classes.confirmDialog} data-testid="addTracking">
      <form onSubmit={onSubmit} className={classes.trackingDialogForm}>
        <Box className={classes.confirmContent}>
          <Typography variant="h5">New Tracking #</Typography>
        </Box>
        <Box className={classes.confirmContent}>
          <EHRSelect
            label="Supplier"
            onChange={handleChangeSupplier}
            value={supplierId ?? ''}
            dataCy="addTracking.supplierId"
            data-testid="addTracking.supplierId"
            style={{ opacity: isLoadingSuppliers ? 0.5 : 1 }}
            required
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select a Supplier
            </MenuItem>
            {suppliers.map((supplier) => (
              <MenuItem key={supplier.id} value={supplier.id}>
                {supplier.name}
              </MenuItem>
            ))}
          </EHRSelect>
        </Box>
        <Box className={classes.confirmContent}>
          <EHRTextField
            label="Shipping Company"
            onChange={handleChangeCompanyName}
            placeholder="Select a company"
            value={companyName}
            dataCy="addTracking.companyName"
            data-testid="addTracking.companyName"
            required
          />
        </Box>
        <Box className={classes.confirmContent}>
          <EHRTextField
            label="Tracking #"
            onChange={handleChangeTrackingNumber}
            value={trackingNumber}
            dataCy="addTracking.trackingNumber"
            data-testid="addTracking.trackingNumber"
            required
          />
        </Box>
        <Box className={classNames(classes.confirmButtons, classes.smallButtons)}>
          <Button
            data-testid="addTracking.cancel"
            data-cy="addTracking.cancel"
            onClick={dismiss}
            variant="outlined"
            className={classes.actionButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            data-testid="addTracking.save"
            data-cy="addTracking.save"
            variant="outlined"
            className={classes.actionButton}
          >
            {saving ? <CircularProgress style={{ color: 'white' }} size={30} /> : 'Add'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddTrackingModal;
