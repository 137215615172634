import { makeStyles } from '@material-ui/core/styles';

const useStylesMedspaSettings = makeStyles(() => ({
  medspaSettingsContainer: {
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #DFDFDF',
    borderRadius: '10px',
    padding: '0 1.5rem',
    marginBottom: '0.5rem',
  },
  label: {
    width: '60%',
  },
}));

export default useStylesMedspaSettings;
