import React from 'react';

import { TextField } from '@material-ui/core';
import { Card } from '../common/card/Card';

import { useStyles } from './ProgressNoteTextInput.styles';
import { IProgressNoteTextInputProps } from '../../types/ProgressNoteTypes';

export const ProgressNoteTextInput = ({ value, onChange }: IProgressNoteTextInputProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <TextField
        label="Add progress note"
        type="text"
        rowsMax={5}
        variant="outlined"
        rows={2}
        fullWidth
        multiline
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={classes.textArea}
      />
    </Card>
  );
};
export default ProgressNoteTextInput;
