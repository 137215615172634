import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const CustomButton = withStyles(() => ({
  root: {
    color: '#393B3F',
    backgroundColor: '#E7EEED',
    minWidth: '134px',
    height: '56px',
    borderRadius: '3px',
    margin: 0,
    marginRight: '16px',
    '&:hover': {
      backgroundColor: '#E7EEED',
    },
  },
}))(Button);

export const AddNote = ({ note, setNote }: any) => {
  const [showNote, setShowNote] = useState<boolean>(!!note);

  return (
    <Box display="flex">
      <CustomButton onClick={() => setShowNote(!showNote)}>
        add note
      </CustomButton>
      {showNote && (
        <TextField
          variant="outlined"
          value={note}
          onChange={(event) => { setNote(event.target.value); }}
          placeholder="Note"
          fullWidth
        />
      )}
    </Box>
  );
};
