import React from 'react';
import { Box, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Done, NotificationImportant } from '@material-ui/icons';
import { IMonthlyBlocker } from 'src/interfaces/IMonthlyBlocker';
import { useMonthlyBlockers } from 'src/hooks/queries/useMonthlyBlockers';
import { tableStyles } from './practitionerDashboard.styles';

const LoadingPanels: React.FC = (): React.ReactElement => {
  const classes = tableStyles();

  return (
    <>
      <Grid container wrap="nowrap">
        {Array.from(new Array(4)).map(
          (item: number): React.ReactElement => (
            <Box
              key={`skeleton-${item}`}
              width={180}
              className={[classes.blockerItem, classes.skeletonItem].join(' ')}
              my={5}
            >
              <Box pt={0.5} marginBottom={10}>
                <Skeleton />
              </Box>
              <Skeleton variant="rect" width="60%" height={55} />
              <Box pt={0.5}>
                <Skeleton width="60%" />
                <Skeleton />
              </Box>
            </Box>
          )
        )}
      </Grid>
      <Box className={classes.bigBar}>
        <Skeleton variant="rect" width="75%" height={10} />
      </Box>
      <LinearProgress className={classes.blockerProgress} />
    </>
  );
};

const MonthlyBlockers = ({ practitionerId }: { practitionerId?: string }): React.ReactElement => {
  const { data, isLoading } = useMonthlyBlockers(practitionerId);

  const classes = tableStyles();

  return (
    <Paper elevation={2} variant="elevation" className={classes.blockerPaper}>
      {isLoading ? (
        <LoadingPanels />
      ) : (
        <>
          <Box className={classes.blockerBox}>
            {data?.map(
              (b: IMonthlyBlocker): React.ReactElement => (
                <Box className={classes.blockerItem} key={b.name}>
                  <Typography variant="h6">{b.name}</Typography>
                  {b.remaining > 0 ? (
                    <NotificationImportant className={classes.blockedIcon} viewBox="0 0 22 22" />
                  ) : (
                    <Done className={classes.completeIcon} viewBox="0 0 22 22" />
                  )}
                  {b.remaining > 0 ? (
                    <Typography variant="body2">{`You Have ${b.remaining} Remaining`}</Typography>
                  ) : (
                    <Typography variant="body2">You&#39;re All Clear</Typography>
                  )}
                </Box>
              )
            )}
          </Box>
          <Box className={classes.blockerHead}>
            <Typography variant="subtitle2" className={[classes.blockerTitle, classes.blockerSubtitle].join(' ')}>
              Please make sure you have completed all of the above tasks to ensure prompt and complete payment every
              month.
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
};

export { MonthlyBlockers, MonthlyBlockers as default };
