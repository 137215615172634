import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    borderRadius: '8px',
    padding: '24px',
    backgroundColor: 'white',
  },
});

export const ShadowCard = ({ children, ...props }: any) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root} {...props}>
      {children}
    </Paper>
  );
};
