import React from 'react';
import {
  Avatar,
  Button,
  Container,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Create } from '@material-ui/icons';

import IAvatarProps from '../../../interfaces/IAvatarProps';

type ThreadsHeaderProps = {
  user1: IAvatarProps;
  user2: IAvatarProps;
  conversationsCount: number;
  showCreateButton?: boolean;
  buttonClasses?: string;
  onCreate?: any;
};

const useStyles = makeStyles({
  listItemText: {
    textTransform: 'capitalize',
  },
});

const ThreadsHeader = ({
  user1,
  user2,
  conversationsCount,
  showCreateButton = false,
  buttonClasses = '',
  onCreate,
}: ThreadsHeaderProps) => {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ListItem key="user1">
            <ListItemIcon>
              <Avatar src={user1.avatarSrc} alt={user1.avatarAlt} />
            </ListItemIcon>
            <ListItemText
              primary={user1.userName}
              secondary={user1.secondaryText}
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem key="user2">
            <ListItemIcon>
              <Avatar alt="Patient" />
            </ListItemIcon>
            <ListItemText primary={user2.userName} secondary="Patient" classes={{ primary: classes.listItemText }} />
          </ListItem>
        </Grid>
      </Grid>
      <Divider />
      <Container style={{ margin: '2rem, 0' }}>
        <Typography variant="h6" align="center">
          Conversations <small>{`(${conversationsCount}) `}</small>
          {showCreateButton && (
            <Button variant="contained" color="primary" size="small" className={buttonClasses} onClick={onCreate}>
              <Create /> New
            </Button>
          )}
        </Typography>
      </Container>
    </>
  );
};

export default ThreadsHeader;
