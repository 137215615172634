import { createModel } from '@rematch/core';
import { SquareState } from '../types/SquareState';
import { RootModel } from './rootModel';
import axiosInstance from '../utils/axios';

export const square = createModel<RootModel>()({
  state: {
    message: '',
    hasError: false,
  } as SquareState,
  reducers: {
    updateState(state: any, payload: any) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch: any) => ({
    async matchCustomer(payload: { orderId: number; customerId: number }, _rootState: any) {
      try {
        const { orderId, customerId } = payload;
        const response = await axiosInstance.post(`square/match/${orderId}/${customerId}`);
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        if (response.data.error) {
          dispatch.square.updateState({ hasError: true, message: response.data.error });
          return;
        }
        dispatch.square.updateState({ message: response.data.error });
      } catch (error) {
        dispatch.square.updateState({ hasError: true, message: error });
      }
    },
  }),
});
