import { useQuery, UseQueryResult } from 'react-query';
import { EdgeTiers } from '../../services/EdgeTiers';
import { EdgeTierChartParams } from '../../interfaces/IEdgeTier';
import { EDGE_TIERS } from '../../constants/reactQuery.keys';

const useEdgeTiers = (): UseQueryResult<EdgeTierChartParams> =>
  useQuery<EdgeTierChartParams>({
    queryKey: [EDGE_TIERS],
    queryFn: (): EdgeTierChartParams => EdgeTiers.getTiers(),
  });

export { useEdgeTiers, useEdgeTiers as default };
