/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import Page from 'src/components/common/Page';
import { usePractitionerForPhysicians } from '../../hooks/practitioner/usePractitioner';
import { ProviderDetail } from './ProviderDetail';
import { MultipleSkeleton } from '../../components/common/LoadingSkeleton';
import { ProviderPatientsList } from './ProviderPatientsList';
import { useStyles } from './ProviderDetails.styles';
import { PHYSICIAN_PROVIDERS_PATH } from '../../routes/physicianRoutes';
import { ProviderClosedServiceVisitsList } from './ProviderClosedServiceVisitsList';
import { RootState } from '../../rematch';

export const PhysicianAssignedProviderDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const { providerId }: { providerId: string } = useParams();
  const { user } = useSelector(({ auth }: RootState) => auth);
  const { data: practitioners = [], isLoading } = usePractitionerForPhysicians({ practitionerIds: [providerId] });
  const practitionerData = practitioners[0];

  if (isLoading || !practitionerData) {
    return <MultipleSkeleton addPosition={false} />;
  }

  const navigateBack = () => {
    history.push(PHYSICIAN_PROVIDERS_PATH);
  };

  return (
    <Page title={practitionerData.fullName}>
      <div data-testid="physician-provider-detail-main">
        <div className={classes.navigateBack} onClick={navigateBack}>
          <KeyboardBackspaceIcon />
          <h2 className={classes.backTitle}>Providers</h2>
        </div>
        <ProviderDetail practitionerData={practitionerData} showChartReview={!user.isAdvancedPractitioner} />
        {user.isAdvancedPractitioner ? (
          <ProviderPatientsList practitionerId={practitionerData.id} />
        ) : (
          <ProviderClosedServiceVisitsList practitionerId={practitionerData.id} />
        )}
      </div>
    </Page>
  );
};
