import { makeStyles } from '@material-ui/core/styles';

export const useProviderMedspaStyles = makeStyles((theme) => ({
  createProviderButton: {
    background: '#1D584D',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    height: '47px',
    padding: '6px 16px',
    marginLeft: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  form: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 120,
    width: '100%',
  },
  halfWidthField: {
    width: `calc(50% - ${theme.spacing(1)}px)`,
  },
  profileSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
  },
  ehrProfilePhotoUploadError: {
    '& .ehrUploadContainerError': {
      borderColor: '#f44336',
      color: '#f44336',
    },
  },
  profilePicPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    backgroundPosition: '10px 10px',
    transform: 'translateX(-5%)',
  },
  profilePicPreview: {
    width: '5.5rem',
    height: '5.5rem',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem',
    border: `1px dashed #C8C8C8`,
    borderRadius: '0.375rem',
    width: 'calc(100% - 100px)',
    cursor: 'pointer',
  },
  uploadText: {
    marginLeft: theme.spacing(2),
  },
  selectFormControl: {
    minWidth: 120,
    width: '100%',
  },
  schedulingCheckbox: {
    display: 'flex',
    height: '100% !important',
    padding: '0rem 0.75rem 0.75rem !important',
    '& .MuiFormControlLabel-label': {
      color: '#000 !important',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontWeight: 400,
    },
  },
  schedulingHelperText: {
    marginLeft: '2rem',
    fontSize: '0.75rem',
    color: 'rgba(0,0,0,0.4)',
  },
  signatureUploadContainer: {
    cursor: 'pointer',
    marginTop: '0.5rem',
    border: `1px dashed #C8C8C8`,
    borderRadius: '0.375rem',
    padding: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  signaturePreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '56px',
  },
  signaturePreview: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  activeBadge: {
    color: '#005A4E',
    background: '#E6F9DA',
    padding: '4px',
    borderRadius: '4px',
    marginLeft: '8px',
    fontSize: '14px',
  },
  inactiveBadge: {
    color: '#757575',
    background: '#E0E0E0',
    padding: '4px',
    borderRadius: '4px',
    marginLeft: '8px',
    fontSize: '14px',
  },
  profileBadge: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    objectFit: 'cover',
    border: '2px solid #005A4E',
  },
  profileImageCell: {
    display: 'flex',
    alignItems: 'center',
  },
  profileRow: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

export const useProviderMedspaHeaderStyles = makeStyles((theme) => ({
  practHeaderActionsContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
}));
