import React from 'react';
import { BoxProps, Box } from '@material-ui/core';
import classnames from 'classnames';
import { useStyles } from './modalPoints.styles';

interface ModalTitlesProps {
  title: string;
  subtitle?: string;
  smallTitle?: boolean;
  boxContainerStyles?: BoxProps;
}

const ModalTitles = (props: ModalTitlesProps) => {
  const { title, subtitle, smallTitle, boxContainerStyles } = props;
  const classes = useStyles();

  return (
    <Box {...boxContainerStyles}>
      <Box component="span" className={classnames(classes.title, { [classes.titleSmall]: smallTitle })}>
        {title}
      </Box>
      {subtitle && (
        <Box component="small" className={classes.subtitle}>
          {subtitle}
        </Box>
      )}
    </Box>
  );
};

export default ModalTitles;
