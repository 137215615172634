import { useMutation, useQueryClient } from 'react-query';
import {
  MEDSPA_PRIMARY_SERVICES,
  SERVICES,
  SERVICE_GROUPS,
  SERVICE_GROUPS_LIST,
  SUPPLIERS_LIST,
} from 'src/constants/reactQuery.keys';
import MedspaServices from 'src/services/medspaAdmins/Services';
import { showSnackbar } from 'src/utils/global';
import compile from 'src/utils/toastMessagesCompiler';

export const useImportServiceGroupsMutation = (userGroupId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ serviceGroupIds, serviceIds }: any) =>
      MedspaServices.importServiceGroups(userGroupId, serviceGroupIds, serviceIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICES]);
        queryClient.invalidateQueries([SERVICE_GROUPS_LIST]);
      },
    }
  );
};

export const useCreateMedspaService = (userGroupId: number) => {
  const queryClient = useQueryClient();
  return useMutation((serviceData: any) => MedspaServices.createService(userGroupId, serviceData), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICES]);
      queryClient.invalidateQueries([SERVICES, SERVICE_GROUPS]);
      queryClient.invalidateQueries([SUPPLIERS_LIST]);
    },
  });
};

export const useUpdateMedspaService = (userGroupId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ serviceId, serviceData }: any) => MedspaServices.updateService(userGroupId, serviceId, serviceData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICES]);
        queryClient.invalidateQueries([SERVICES, SERVICE_GROUPS]);
        queryClient.invalidateQueries([SUPPLIERS_LIST]);
      },
    }
  );
};

export const useDeleteMedspaService = (userGroupId: number) => {
  const queryClient = useQueryClient();
  return useMutation((serviceId: number) => MedspaServices.deleteService(userGroupId, serviceId), {
    onSuccess: (response: any) => {
      if (!response.success) {
        const message = response?.message ? response?.message : compile('generic.error_message_without_params');
        showSnackbar(message);
        return;
      }

      queryClient.invalidateQueries([SERVICES]);
      queryClient.invalidateQueries([SERVICES, SERVICE_GROUPS]);
      queryClient.invalidateQueries([SUPPLIERS_LIST]);
      queryClient.invalidateQueries([MEDSPA_PRIMARY_SERVICES]);
    },
  });
};
