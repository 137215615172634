/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { Box, Button, Dialog, makeStyles } from '@material-ui/core';
import { InventoryOrderPartial } from 'src/interfaces/IInventoryOrder';
import { useShipOrder } from 'src/hooks/queries/useInventoryOrders';
import classNames from 'classnames';
import { OrderAddChargesModal } from './AddChargesModal';
import AddTrackingModal from './AddTrackingModal';
import AddConfirmationNumberModal from './AddConfirmationNumberModal';

type Props = {
  id: number;
  statusDetails?: InventoryOrderPartial;
  approve: () => void;
  handleAdminNote: () => void;
  handleRejectOrder: () => void;
  disabled: boolean;
  approved: boolean;
  isLegacy?: boolean;
};

export const ButtonsFooter = ({
  id,
  handleAdminNote,
  approve,
  approved,
  handleRejectOrder,
  disabled,
  isLegacy,
  statusDetails,
}: Props) => {
  const classes = useStyles();
  const [addChargesDialog, setAddChargesDialog] = useState<boolean>(false);
  const [addDiscountsDialog, setAddDiscountsDialog] = useState<boolean>(false);
  const [addTrackingDialog, setAddTrackingDialog] = useState<boolean>(false);
  const [addConfirmationDialog, setAddConfirmationDialog] = useState<boolean>(false);
  const [shipping, setShipping] = useState<boolean>(false);

  const { mutateAsync: shipOrder } = useShipOrder(id);

  const handleShip = async () => {
    setShipping(true);
    await shipOrder();
    setShipping(false);
  };

  return (
    <>
      <Box paddingY={0} className={classes.footerButtons}>
        <Button
          onClick={() => setAddChargesDialog(true)}
          variant="outlined"
          className={classNames(classes.actionButton, classes.chargesButton)}
          disabled={disabled}
        >
          Add Additional Charge
        </Button>
        <Button
          onClick={() => setAddDiscountsDialog(true)}
          variant="outlined"
          className={classNames(classes.actionButton, classes.chargesButton)}
          disabled={disabled}
        >
          Add Discount
        </Button>
        <Button
          onClick={() => setAddTrackingDialog(true)}
          variant="outlined"
          className={classNames(classes.actionButton, classes.discountsButton)}
          disabled={
            !approved ||
            !statusDetails?.orderConfirmationNumbers?.length ||
            (isLegacy && statusDetails?.purchaseOrderTotal === 0)
          }
        >
          Add Tracking #
        </Button>
        <Button
          onClick={() => setAddConfirmationDialog(true)}
          variant="outlined"
          className={classNames(classes.actionButton, classes.discountsButton)}
          disabled={!approved || (isLegacy && !statusDetails?.purchaseOrderTotal)}
        >
          Add Order #
        </Button>
        <Box className={classes.buttonsContainer}>
          <Button onClick={handleAdminNote} variant="outlined" className={classes.actionButton}>
            Add Note
          </Button>
          <Button
            data-cy="view"
            onClick={handleRejectOrder}
            variant="outlined"
            className={classNames(classes.actionButton, classes.rejectButton)}
            data-testid={`order reject ${id}`}
            disabled={disabled}
          >
            Reject
          </Button>
          {isLegacy && disabled ? (
            <Button
              data-cy="view"
              onClick={handleShip}
              variant="contained"
              className={classes.actionButton}
              data-testid={`order ship ${id}`}
              disabled={shipping || statusDetails?.purchaseOrderTotal !== 0}
            >
              Ship Order
            </Button>
          ) : (
            <Button
              data-cy="view"
              onClick={approve}
              variant="contained"
              className={classes.actionButton}
              data-testid={`order approve ${id}`}
              disabled={disabled}
            >
              Approve
            </Button>
          )}
        </Box>
      </Box>
      <Dialog
        open={addChargesDialog}
        maxWidth="lg"
        children={<OrderAddChargesModal id={id} dismiss={() => setAddChargesDialog(false)} />}
      />
      <Dialog
        open={addDiscountsDialog}
        maxWidth="lg"
        children={<OrderAddChargesModal id={id} dismiss={() => setAddDiscountsDialog(false)} discountMode />}
      />
      <Dialog
        open={addTrackingDialog}
        maxWidth="lg"
        children={<AddTrackingModal id={id} dismiss={() => setAddTrackingDialog(false)} />}
        PaperProps={{ style: { width: '25%' } }}
      />
      <Dialog
        open={addConfirmationDialog}
        maxWidth="lg"
        children={<AddConfirmationNumberModal id={id} dismiss={() => setAddConfirmationDialog(false)} />}
        PaperProps={{ style: { width: '25%' } }}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  footerButtons: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingRight: 25,
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
  },
  actionButton: {
    textTransform: 'none',
    fontSize: 14,
    minWidth: 132,
    height: 'auto',
    borderColor: '#ccc',
    color: '#000',
    backgroundColor: 'rgba(51,126,137,0)',
    '&:first-child': {
      margin: 'auto auto auto 0',
      padding: '8px 18px',
    },
    '&:last-child': {
      backgroundColor: 'rgba(29, 88, 77, 1)', // #1D584D
      border: '2px solid #1D584D',
      color: '#fff',
      margin: 'auto 0',
      padding: '8px 18px',
      '&:disabled': {
        backgroundColor: 'rgba(29,88,77,0.65)',
        border: 'none',
      },
    },
    '&:last-child:hover': {
      backgroundColor: 'rgba(29, 88, 77, 0.45)',
    },
  },
  rejectButton: {
    color: '#E63E3E',
    border: '2px solid #E63E3E',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#E63E3E',
    },
  },
  chargesButton: {
    margin: 'auto 15px auto 0 !important',
  },
  discountsButton: {
    padding: '8px 18px',
    margin: 'auto auto auto 0',
  },
}));
