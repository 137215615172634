import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  bar: {
    height: '64px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.34)',
    backgroundColor: '#e7eeed',
    padding: '0 19px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  homeIcon: {
    top: '4.5px',
    position: 'relative',
    marginRight: '20px',
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
    marginLeft: '21px',
  },
  cancelButton: {
    border: 'solid 1px rgba(57, 59, 63, 0.12)',
    color: '#12574d',
    marginLeft: 'auto',
    height: '47px',
    width: '150px',
  },
}));
