import { useMutation } from 'react-query';
import { Appointment } from '../../services/Appointment';

export interface IAppointmentReminderConfig {
  medspaLocationPractitionerId: number;
  appointmentReminderConfigurationId: number;
  header: string;
  footer: string;
  active: boolean;
  valueBeforeReminder: number;
  valueTypeBeforeReminder: string;
  message: string;
}

export interface IUpsertAppointmentConfigData {
  id: number;
  practitionerId: number;
  smsConfig?: IAppointmentReminderConfig;
  emailConfig?: IAppointmentReminderConfig;
}

export interface IUpsertPractitionerAppointmentConfigData {
  id: number;
  configType: string;
  enabled: boolean;
  practitionerId: number;
  valueBeforeReminder: number;
  valueTypeBeforeReminder: string;
}

export const useUpsertAppointmentReminderConfiguration = () =>
  useMutation((fullData: IUpsertPractitionerAppointmentConfigData) =>
    Appointment.upsertAppointmentReminderConfiguration(fullData)
  );

export const useUpsertMedspaAppointmentReminderConfiguration = () =>
  useMutation(({ id, data }: { id: number; data: IUpsertAppointmentConfigData }) =>
    Appointment.upsertMedspaAppointmentReminderConfiguration(id, data)
  );
