import IServiceGroup from 'src/interfaces/IServiceGroup';
import { MEDSPA_V4_PATH } from 'src/constants/apiRoutes.constants';
import IStandingOrder from 'src/interfaces/IStandingOrder';
import { ICustomCareMessage } from 'src/interfaces/ICareMessages';
import IConsent from 'src/interfaces/IConsent';
import Api from '../Api';

const SERVICE_GROUP_V4_PATH = (userGroupId: number) => `${MEDSPA_V4_PATH}/${userGroupId}/service_groups`;

const MedspaServiceGroups = {
  async getServiceGroups(userGroupId: number, params: any): Promise<IServiceGroup[]> {
    const { success, serviceGroups } = await Api.get(`${SERVICE_GROUP_V4_PATH(userGroupId)}`, params);

    return success ? serviceGroups : [];
  },
  async createServiceGroup(userGroupId: number, params: any): Promise<IServiceGroup> {
    const { success, serviceGroup } = await Api.post(`${SERVICE_GROUP_V4_PATH(userGroupId)}`, params);

    return success ? serviceGroup : {};
  },
  async updateServiceGroup(userGroupId: number, id: number, params: any): Promise<IServiceGroup> {
    return Api.put(`${SERVICE_GROUP_V4_PATH(userGroupId)}/${id}`, params);
  },
  async deleteServiceGroup(userGroupId: number, id: number): Promise<boolean> {
    return Api.delete(`${SERVICE_GROUP_V4_PATH(userGroupId)}/${id}`);
  },
  async getMedspaStandingOrderTemplates(userGroupId: number): Promise<IStandingOrder[]> {
    const { standingOrders } = await Api.get(`${SERVICE_GROUP_V4_PATH(userGroupId)}/standing_order_suggestions`);
    return standingOrders;
  },
  async getMedspaCareMessageTemplates(userGroupId: number): Promise<ICustomCareMessage[]> {
    const { careMessages } = await Api.get(`${SERVICE_GROUP_V4_PATH(userGroupId)}/care_message_suggestions`);
    return careMessages;
  },
  async getConsentTemplates(userGroupId: number): Promise<IConsent[]> {
    const { consents } = await Api.get(`${SERVICE_GROUP_V4_PATH(userGroupId)}/consent_suggestions`);
    return consents;
  },
};

export default MedspaServiceGroups;
