import React, { PropsWithChildren } from 'react';
import { InputLabel, Typography, makeStyles, TypographyProps } from '@material-ui/core';

const useStyles = makeStyles({
  ehrTypographyLabel: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1rem',
    color: '#706F6F',
  },
  ehrTypographyReadOnly: {
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    padding: '0.75rem 0',
    color: '#000',
  },
  ehrTypographyContent: {
    fontSize: '1rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#000',
  },
  ehrTypographyDescription: {
    fontSize: '0.815rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    padding: '0.25rem 0',
    color: '#000',
    opacity: 0.4,
  },
});

interface EHRTypographyProps {
  dataCy: string;
  id?: string;
  error?: boolean;
  variant: TypographyProps['variant'] | 'label' | 'readonly' | 'description' | 'content';
  style?: React.CSSProperties;
}

const EHRTypography = (props: PropsWithChildren<EHRTypographyProps>) => {
  const classes = useStyles();
  const { dataCy, children, variant, id, error, style } = props;

  if (variant === 'label') {
    return (
      <InputLabel htmlFor={id} className={classes.ehrTypographyLabel} error={error} style={style}>
        {children}
      </InputLabel>
    );
  }
  if (variant === 'readonly') {
    return (
      <InputLabel htmlFor={id} className={classes.ehrTypographyReadOnly} error={error} style={style}>
        {children}
      </InputLabel>
    );
  }
  if (variant === 'description') {
    return (
      <InputLabel htmlFor={id} className={classes.ehrTypographyDescription} error={error} style={style}>
        {children}
      </InputLabel>
    );
  }
  if (variant === 'content') {
    return (
      <InputLabel htmlFor={id} className={classes.ehrTypographyContent} error={error} style={style}>
        {children}
      </InputLabel>
    );
  }

  return (
    // @ts-expect-error
    <Typography {...props} data-cy={dataCy}>
      {children}
    </Typography>
  );
};

export { EHRTypography };
