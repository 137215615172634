import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, Chip, CardContent } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { ConsentModal } from '../NewServiceVisit/consents/ConsentModal';
import { RootState, dispatch } from '../../rematch';
import { Card } from '../common/card';

export const Consents = () => {
  const { patientId }: any = useParams();
  const {
    serviceGroups,
    customerConsents,
    firstName,
    lastName,
    contactInfo: {
      practitioner: { businessEntity, medicalGroup },
    },
  } = useSelector(({ patient }: RootState) => patient);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [consentContent, setConsentContent] = useState<string>('');
  const [consentName, setConsentName] = useState<string>('');
  const [patientName, setPatientName] = useState<string>('');
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [selectedServiceGroupId, setSelectedServiceGroup] = useState<number>(0);
  const CustomerConsentsOrder = customerConsents.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));

  const handleClose = () => {
    setIsOpen(false);
    setPatientName('');
  };

  useEffect(() => {
    dispatch.patient.fetchServiceGroups();
    dispatch.patient.fetchCustomerConsents(patientId);
  }, [patientId]);

  const signConsent = (signatureImage: any, patientNameSign: string) => {
    setIsSigning(true);
    dispatch({
      type: 'patient/signConsentRequest',
      payload: {
        patientId,
        serviceGroupId: selectedServiceGroupId,
        signatureImage,
        patientName: patientNameSign,
        finallyCallback: () => {
          setIsSigning(false);
        },
        successCallback: () => {
          setIsOpen(false);
        },
      },
    });
  };

  const isAlreadySignedByCustomer = (consent: any, groupId: number): boolean => {
    const validConsentsForThisGroup = CustomerConsentsOrder.filter(
      ({ serviceGroupId, updatedAt }: any) => serviceGroupId === groupId && moment(updatedAt).isAfter(consent.updatedAt)
    );

    return !!validConsentsForThisGroup.length;
  };

  const openConsentModal = (id: number, name: string, consent: any) => {
    if (isAlreadySignedByCustomer(consent, id)) {
      const { consentPdfUrl } = CustomerConsentsOrder?.find(({ serviceGroupId }: any) => serviceGroupId === id) || {};
      window.open(consentPdfUrl, '_blank');
    } else {
      const consentInfo = consent.content
        .replace('<%= business_entity %>', businessEntity)
        .replace('<%= medical_group %>', medicalGroup ? ` and ${medicalGroup}` : '');
      setSelectedServiceGroup(id);
      setConsentContent(consentInfo);
      setPatientName(`${firstName} ${lastName}`);
      setConsentName(name);
      setIsOpen(true);
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            {serviceGroups.length > 0 ? (
              <>
                {serviceGroups
                  .filter(({ consent }: any) => consent)
                  .map(({ id: serviceGroupId, name, consent }: any) => (
                    <Grid key={serviceGroupId} item>
                      <Chip
                        label={name}
                        icon={isAlreadySignedByCustomer(consent, serviceGroupId) ? <CheckCircleOutline /> : undefined}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          openConsentModal(serviceGroupId, name, consent);
                        }}
                        title={isAlreadySignedByCustomer(consent, serviceGroupId) ? 'Signed' : undefined}
                      />
                    </Grid>
                  ))}
              </>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
      <ConsentModal
        isOpen={isOpen}
        isSigning={isSigning}
        handleCloseCallback={handleClose}
        consentName={consentName}
        consentContent={consentContent}
        patientNameProp={patientName}
        signConsentCallback={signConsent}
      />
    </>
  );
};
