import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, Typography } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useStyles } from './transactions.styles';
import { dispatch } from '../../../rematch';

const TransactionsHeader = () => {
  const classes = useStyles();
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-01')); // first day of month
  const [toDate, setToDate] = useState(moment()); // current date

  useEffect(() => {
    dispatch({
      type: 'transaction/fetchTransactions',
      payload: { fromDate, toDate },
    });
  }, [fromDate, toDate]);

  const onChangeFromDate = (newFromDate: any) => {
    setFromDate(newFromDate);
  };

  const onChangeToDate = (newToDate: any) => {
    setToDate(newToDate);
  };

  const CustomFromDateTextField = (props: any) => (
    <TextField {...props} name="fromDate" style={{ marginBottom: '10px' }} fullWidth />
  );

  const CustomEndDateTextField = (props: any) => (
    <TextField {...props} name="endDate" style={{ marginBottom: '10px' }} fullWidth />
  );

  return (
    <Box padding={2}>
      <Box display="flex" justifyContent="space-between">
        <Box style={{ display: 'flex' }}>
          <Typography className={classes.title}>Transactions</Typography>
        </Box>
        <Box className={classes.filters}>
          <Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                variant="dialog"
                inputVariant="outlined"
                label="From date"
                format="MM/DD/yyyy"
                autoOk
                disableToolbar
                value={fromDate}
                onChange={onChangeFromDate}
                TextFieldComponent={CustomFromDateTextField}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                variant="dialog"
                inputVariant="outlined"
                label="To date"
                format="MM/DD/yyyy"
                autoOk
                disableToolbar
                value={toDate}
                onChange={onChangeToDate}
                TextFieldComponent={CustomEndDateTextField}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionsHeader;
