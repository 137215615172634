/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Switch, useHistory } from 'react-router-dom';
import BudgetPanel from 'src/components/common/BudgetPanel';
import SectionProducts from './SectionProducts';
import SupplierTitle from './SupplierTitle';
import { dispatch } from '../../../../rematch';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import ProductIdentifiers from '../../../common/ProductIdentifiers/ProductIdentifiers';
import Warnings from './WarningsList';
import RecoverAsset from './RecoverAsset';
import SetReconstitutionExpiry from './SetReconstitutionExpiry';
import PrivateRoute from '../../../../utils/PrivateRoute';
import {
  PRACTITIONER_INVENTORY_COUNT_PATH,
  PRACTITIONER_INVENTORY_COUNT_SERVICE_PATH,
} from '../../../../routes/practitionerRoutes';
import { FEATURE_INVENTORY_VALUE } from '../../../../constants/features.constants';
import { hasFeatureEnabled } from '../../../../utils/auth.utils';

const InventoryDefault: React.FC = () => {
  const { suppliersList, dashboardList, isLoadingInventory } = useSelector(({ inventoryDefault }: any) => ({
    ...inventoryDefault,
  }));
  const { userId, featureList } = useSelector(({ auth }: any) => auth);
  const [service, setService] = useState<any>(null);
  const history = useHistory();

  useEffect(() => {
    if (userId) {
      dispatch({
        type: 'inventoryDefault/getDashboardLists',
        payload: userId,
      });
    }
  }, [userId]);

  useEffect(() => {
    if (service) {
      history.push(PRACTITIONER_INVENTORY_COUNT_SERVICE_PATH);
    }
  }, [service]);

  const showProductsIds = ({ assets, productName, currentNumberOfUnits, quantity }: any) => {
    setService({
      assets,
      name: productName,
      units: currentNumberOfUnits,
      vials: quantity,
    });
  };

  const hideProductsIds = () => {
    history.replace(PRACTITIONER_INVENTORY_COUNT_PATH);
    setService(null);
  };

  if (isLoadingInventory) {
    return <MultipleSkeleton />;
  }

  return (
    <Switch>
      <PrivateRoute path={PRACTITIONER_INVENTORY_COUNT_SERVICE_PATH}>
        {service && (
          <Box paddingX={4}>
            <ProductIdentifiers
              aggregatedField="currentNumberOfUnits"
              service={service}
              canMarkAsEmpty
              hidePractitionerBar
              editionDisabled
              showPrice={hasFeatureEnabled(FEATURE_INVENTORY_VALUE, featureList)}
              goBack={hideProductsIds}
            />
          </Box>
        )}
      </PrivateRoute>

      <PrivateRoute path={PRACTITIONER_INVENTORY_COUNT_PATH}>
        <Box>
          <Warnings showProductsIds={showProductsIds} />
          <Box display="flex" paddingX={4} paddingTop={4} paddingBottom={2}>
            <BudgetPanel />
            <Box display="flex" justifyContent="right" marginLeft="auto" marginBottom="auto">
              <SetReconstitutionExpiry />
              <RecoverAsset />
            </Box>
          </Box>
          <Box marginTop={4} paddingX={4} paddingBottom={1.5} borderBottom="1px solid #e4e7eb">
            <Grid container>
              {suppliersList.map(({ supplierName, supplierId }: any) => (
                <SupplierTitle key={supplierId} title={supplierName} />
              ))}
            </Grid>
          </Box>
          <Box paddingX={4}>
            {dashboardList.map(({ title, color, products }: any) => (
              <SectionProducts
                key={title}
                title={title}
                color={color}
                products={products}
                suppliers={suppliersList}
                onClick={showProductsIds}
              />
            ))}
          </Box>
        </Box>
      </PrivateRoute>
    </Switch>
  );
};

export default InventoryDefault;
