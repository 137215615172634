import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@material-ui/core';
import React from 'react';

type DialogProps = {
  open: boolean;
  title: any;
  content: any;
  onOk: any;
  okText?: any;
  loading?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  cancelText?: any;
  onCancel?: any;
};

const CustomDialog = ({
  open,
  title,
  content,
  fullWidth = false,
  maxWidth = 'md',
  onCancel = null,
  onOk,
  okText = 'Yes',
  cancelText = null,
  loading = false,
}: DialogProps) => (
  <Dialog open={open} onClose={onCancel} fullWidth={fullWidth} maxWidth={maxWidth}>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>{content} </DialogContent>
    <DialogActions>
      {onCancel !== null && <Button onClick={onCancel}>{cancelText}</Button>}
      <Button onClick={onOk} color="primary" autoFocus>
        {loading ? <CircularProgress size={30} /> : okText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default CustomDialog;
