import { useEffect } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { PATIENTS_VIEW, PATIENTS_VIEW_COUNT } from '../../constants/reactQuery.keys';
import PatientView from '../../services/PatientView';
import { DEFAULT_LIMIT, SNACKBAR_SUCCESS } from '../../constants/general.constants';
import { ICustomerViewParams } from '../../interfaces/CustomerParams';
import Patients from '../../services/Patients';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import ICustomer from '../../interfaces/ICustomer';
import compile from '../../utils/toastMessagesCompiler';

export function usePatients(
  paramsQuery: ICustomerViewParams,
  total: number = 0,
  practitionerId?: string,
  isMedspaProvider?: boolean
) {
  const limit = paramsQuery?.limit || DEFAULT_LIMIT;

  const query = useInfiniteQuery<any>(
    [PATIENTS_VIEW, practitionerId, JSON.stringify(paramsQuery)],
    ({ pageParam = 1 }) =>
      PatientView.getPatients({ ...paramsQuery, page: pageParam, practitionerId, isMedspaProvider }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * limit < total : lastPage.length === limit;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const patients =
    query?.data?.pages
      ?.map((items) => items)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, patients, currentPage: query?.data?.pages.length } };
}

export function usePatientsCount(paramsQuery: ICustomerViewParams) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([PATIENTS_VIEW_COUNT, paramsQuery]);
  }, [paramsQuery]);

  return useQuery([PATIENTS_VIEW_COUNT, paramsQuery], () => PatientView.getPatientsCount(paramsQuery), {
    initialData: 0,
    refetchOnWindowFocus: false,
    retry: 1,
    keepPreviousData: true,
  });
}

export const useCreatePatient = () => {
  const history = useHistory();

  return useMutation((params: any) => Patients.createPatient(params), {
    onSuccess: ({ success, customer, error }: { error: string; success: boolean; customer: ICustomer }) => {
      if (!success) {
        showSnackbar(error || compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          action: 'added',
          element: 'Patient',
          duration: 3000,
        }),
        SNACKBAR_SUCCESS
      );

      history.push(`/patient/${customer.id}`);
    },
    onError: useReactQueryOnErrorHandler,
  });
};
