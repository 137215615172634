import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import { MEDSPA_LOCATION_PRACTITIONERS, MEDSPA_LOCATIONS } from 'src/constants/reactQuery.keys';
import { CalenderUpdateParams, MedspaLocationPractitioners } from 'src/services/medspaAdmins/medspaAdmin';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import compile from '../../../utils/toastMessagesCompiler';
import { SNACKBAR_SUCCESS } from '../../../constants/general.constants';

/* List of all Medspa Locations */
export const useMedspaLocationPractitioners = (practitionerId: number | undefined, enabled: boolean) => {
  const response = useQuery(
    [MEDSPA_LOCATION_PRACTITIONERS, practitionerId],
    () => MedspaLocationPractitioners.get(practitionerId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled,
      onError: () =>
        useReactQueryOnErrorHandler(
          compile('generic.error_message', { action: 'fetching', element: 'Fetching calendar configurations' })
        ),
    }
  );

  return {
    ...response,
    data: {
      medspaLocationPractitioners: response.data?.data || [],
    },
  };
};

export const useUpdateMedspaLocationPractitioners = () => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => MedspaLocationPractitioners.update(params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([MEDSPA_LOCATIONS]);
      queryClient.invalidateQueries([MEDSPA_LOCATION_PRACTITIONERS]);
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useUpdateMedspaLocationPractitionerCalendar = () => {
  const queryClient = useQueryClient();

  return useMutation((params: CalenderUpdateParams) => MedspaLocationPractitioners.updateWorkingHours(params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([MEDSPA_LOCATIONS]);
      queryClient.invalidateQueries([MEDSPA_LOCATION_PRACTITIONERS]);
      showSnackbar(
        compile('generic.success_message', { element: 'Calendar preference', action: 'saved' }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};
