export const OrderConfirmDialogStyles: object = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '470px',
    padding: 30,
    textAlign: 'center',
    '& h6': {
      fontSize: '1.25rem',
    },
    '& p': {
      width: '75%',
      fontSize: '1rem',
      margin: '10px auto',
    },
    '& button': {
      backgroundColor: 'rgb(18,87,77)',
      color: '#fff',
      margin: 'auto 0 auto 15px',
      width: 75,
    },
  },
  modalButtons: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    marginTOp: 10,
    borderTop: '1px solid #eaeaea',
  },
  modalButton: {
    fontSize: '16px',
    fontFamily: 'Messina Sans SemiBold',
    width: '90%',
    height: '46px',
    borderColor: '#ccc',
    color: '#000',
    backgroundColor: 'rgba(51,126,137,0)',
    margin: '0',
    marginTop: '40px',
    marginBottom: '25px',
    marginRight: 'auto',
    '&:last-of-type': {
      backgroundColor: 'rgb(17,86,74)',
      color: '#fff',
      marginLeft: 'auto',
      marginRight: 0,
      width: '100%',
    },
    '&:hover': {
      backgroundColor: 'rgb(37,106,94)',
    },
    '&:last-of-type:hover': {
      backgroundColor: 'rgb(51,126,137)',
    },
    '& circle': {
      color: '#fff',
      strokeWidth: 2,
    },
  },
  successIcon: {
    color: 'rgb(246,147,12)',
    borderRadius: '50%',
    fontSize: 88,
    margin: '35px auto 20px auto',
  },
};

export default OrderConfirmDialogStyles;
