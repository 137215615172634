import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  title: {
    fontSize: '18px',
    color: '#12574D',
  },
  dialog: {
    borderRadius: '10px',
  },
  confirmButton: {
    color: '#ffffff !important',
    backgroundColor: '#12574d',
    height: '36px',
    '&:hover': {
      backgroundColor: '#104e45',
    },
    minWidth: '104px',
    margin: '5px',
  },
  container: {
    height: '200px',
    width: '391px',
    padding: '30px 40px',
    position: 'relative',
    textAlign: 'center',
  },
}));
