import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

import useStyles from './header.styles';

interface HeaderProps {
  currentStepName: string;
  currentStep: number;
}

const Header: React.FC<HeaderProps> = ({ currentStepName, currentStep }: HeaderProps) => {
  const classes = useStyles();
  const stepsAmount = 4;

  return (
    <Grid container spacing={2} className={classes.header}>
      <Box className={classes.timelineContainer}>
        {Array.from(Array(stepsAmount)).map((_, index) => {
          const key = `step-${index}`;
          return (
            <Box
              key={key}
              className={`${classes.stepContainer} ${
                currentStep === index + 1 ? classes.stepContainerActive : classes.stepContainerInactive
              }`}
            >
              {currentStep === index + 1 && (
                <Box>
                  <Typography className={classes.stepTitle}>Step {currentStep}</Typography>
                  <Typography className={classes.stepSubtitle}>{currentStepName}</Typography>
                </Box>
              )}
              <hr className={currentStep === index + 1 ? classes.stepBarActive : classes.stepBarInactive} />
            </Box>
          );
        })}
      </Box>
    </Grid>
  );
};

export default Header;
