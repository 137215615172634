import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import Patients from '../../services/Patients';
import { dispatch } from '../../rematch';
import { PATIENT_VIEWED } from '../../constants/reactQuery.keys';

export const usePhysicianNavigate = (patientId: number, toURL = '') => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const visitedPatientMutation = useMutation(Patients.lastVisitByDoctor);

  return async () => {
    dispatch.patient.clearPatientData();
    await visitedPatientMutation.mutateAsync(patientId);
    queryClient.invalidateQueries([PATIENT_VIEWED, patientId]);

    history.push({ pathname: toURL || `/patient/${patientId}` });
  };
};
