import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import ButtonAhref from '../../common/ButtonAhref';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_PHYSICIAN } from '../../../constants/actions.constants';

const PhysicianHeader: FC = () => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const NEW_PHYSICIAN_URL = '/administrator/new-physician';

  return (
    <Box padding={2}>
      <Box display="flex" justifyContent="flex-end">
        <Box>
          {hasAccessTo(CREATE_PHYSICIAN, permissions) && (
            <ButtonAhref buttonStyle="big" text="NEW PHYSICIAN" href={NEW_PHYSICIAN_URL} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PhysicianHeader;
