import React from 'react';
import moment from 'moment';

import { TableRow } from '@material-ui/core';

import { ILineItem } from '../../../interfaces/ITransaction.interfaces';
import { Pill } from '../../common/Pill';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { useRowStyles } from './transactions.styles';

interface LineItemRowProps {
  lineItem: ILineItem;
}

const LineItemRow: React.FC<LineItemRowProps> = ({ lineItem }: { lineItem: ILineItem }) => {
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCellCustom>{lineItem.checkoutId}</TableCellCustom>
        <TableCellCustom>{lineItem.serviceVisitId}</TableCellCustom>
        <TableCellCustom>{lineItem.checkoutLineItemId}</TableCellCustom>
        <TableCellCustom>{lineItem.customerId}</TableCellCustom>
        <TableCellCustom>{lineItem.practitioner}</TableCellCustom>
        <TableCellCustom>{lineItem.serviceId}</TableCellCustom>
        <TableCellCustom>{moment(lineItem.serviceVisitCreatedAt).format('MM/DD/YYYY')}</TableCellCustom>
        <TableCellCustom>{lineItem.serviceName}</TableCellCustom>
        <TableCellCustom>{lineItem.quantity}</TableCellCustom>
        <TableCellCustom>{lineItem.unit}</TableCellCustom>
        <TableCellCustom>{lineItem.totalWithoutDiscount}</TableCellCustom>
        <TableCellCustom>{lineItem.totalWithDiscount}</TableCellCustom>
        <TableCellCustom>{lineItem.numberOfRealAssetsInvolved}</TableCellCustom>
        <TableCellCustom>{lineItem.sumOfAllCosts}</TableCellCustom>
        <TableCellCustom>{lineItem.allCosts}</TableCellCustom>
        <TableCellCustom>
          <a href={lineItem.ehrUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
            <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
          </a>
        </TableCellCustom>
      </TableRow>
    </>
  );
};

export default LineItemRow;
