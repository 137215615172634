import React, { useEffect, useRef, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import mergeImages from 'merge-images';
import { Box, Button, CircularProgress } from '@material-ui/core';
import {
  ArrowUpwardOutlined,
  ArrowBackOutlined,
  ArrowForwardOutlined,
  ArrowDownwardOutlined,
  RemoveOutlined,
  AddOutlined,
  RotateLeft,
  RotateRight,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import {
  BRUSH_RADIUS_MIN,
  SCALE_MIN,
  TRANSLATE_STATE,
  PAN_STEP_IN_PX,
  BRUSH_RADIUS_MAX,
  BRUSH_RADIUS_STEP,
  SCALE_MAX,
  SCALE_STEP,
  CANVAS_HEIGHT,
  BRUSH_RADIUS_DEFAULT,
} from '../../../constants/coverImage.constants';
import { useStyles } from '../../FullServiceHistory/FullServiceHistoryOpen/MyCoverImage.styles';
import ButtonBrush from '../../FullServiceHistory/FullServiceHistoryOpen/ButtonBrush.styles';
import { MySlider } from '../../FullServiceHistory/FullServiceHistoryOpen/MySlider.styles';
import Oval from '../../Oval';

const _ = require('lodash');

export type ImageProps = {
  imageUrl?: string;
  brushColor?: string;
  getPaths?: any;
  image?: any;
  canvasHeight?: number;
  canvasWidth?: number;
  photoServicesList?: any;
  deletedAnnotated?: any;
  onUndo?: any;
  performingUndo?: boolean;
  hideControls?: boolean;
};

const ImageEditor = ({
  imageUrl,
  brushColor,
  getPaths,
  image,
  canvasHeight,
  canvasWidth,
  photoServicesList,
  deletedAnnotated,
  onUndo,
  performingUndo,
  hideControls = false,
}: ImageProps) => {
  const classes = useStyles();
  const containerRef = useRef<any>(null);
  let canvasRef: any = useRef<any>(null);
  const photoRef = useRef<any>(null);
  const [scale, setScale] = useState(SCALE_MIN);
  const [brushRadius, setBrushRadius] = useState(BRUSH_RADIUS_DEFAULT);
  const [translate, setTranslate] = useState(TRANSLATE_STATE);
  const [serviceId, setServiceId] = useState<any>(null);
  const [isStroke, setIsStroke] = useState(false);
  const [strokesTracker, setStrokesTracker] = useState<any>({});
  const { currentService, serviceVisit, oldAnnotations } = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const { annotatedPhotos } = serviceVisit;

  useEffect(() => {
    if (currentService?.id) {
      setServiceId(currentService?.id);
      setIsStroke(true);
    }
  }, [currentService]);

  const onUpdate = async () => {
    if (isStroke) {
      try {
        const imagesToMerge = [canvasRef.canvasContainer.childNodes[1].toDataURL()];
        const annotation = annotatedPhotos.find(({ photo }: any) => photo.id === image.photo_id);

        if (_.get(annotation, 'photoServices[0].canvasData')) {
          imagesToMerge.push(annotation.photoServices[0].canvasData);
        }

        const mergedImages = await mergeImages(imagesToMerge);
        const newStrokeTracker = { ...strokesTracker };
        newStrokeTracker[image.photo_id] = strokesTracker[image.photo_id]
          ? [...strokesTracker[image.photo_id], serviceId]
          : [serviceId];
        setStrokesTracker(newStrokeTracker);
        getPaths(mergedImages);
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
      }
    }
  };

  const move = (x: number, y: number, sizeScale: number) => {
    const container = containerRef.current.getBoundingClientRect();
    const photo = photoRef.current.getBoundingClientRect();
    if (containerRef && photoRef) {
      const xDiff = photo.width * 1.0 - container.width;

      const xAdjust = Math.abs((photo.width * sizeScale - (photo.width * sizeScale) / sizeScale) / 2);
      const yDiff = photo.height * sizeScale - container.height;

      const yAdjust = Math.abs((photo.height * sizeScale - (photo.height * sizeScale) / sizeScale) / 2);

      const t1 = Math.max(-xDiff + xAdjust, Math.min(xAdjust, translate.t1 + x));
      const t2 = Math.max(-yDiff + yAdjust, Math.min(yAdjust, translate.t2 + y));

      setTranslate({ t1, t2 });
    }
  };

  const panUp = () => {
    move(0, PAN_STEP_IN_PX * scale, 1);
  };

  const panDown = () => {
    move(0, -PAN_STEP_IN_PX * scale, 1);
  };

  const panLeft = () => {
    move(PAN_STEP_IN_PX * scale, 0, 1);
  };

  const panRight = () => {
    move(-PAN_STEP_IN_PX * scale, 0, 1);
  };

  const onChangeSlider = (event: any, newValue: any) => {
    setBrushRadius(newValue);
  };

  const zoomIn = () => {
    setScale((prevState: any) => prevState + SCALE_STEP);
  };

  const zoomOut = () => {
    setScale((prevState: any) => prevState - SCALE_STEP);
  };

  const clearHandler = () => {
    canvasRef.clear();
    deletedAnnotated();
    setStrokesTracker({});
  };

  const isUndoDisabled = (): boolean => {
    if (_.isEmpty(strokesTracker)) {
      return true;
    } 
    return strokesTracker[image.photo_id] ? !strokesTracker[image.photo_id].length : true;
    
  };

  const undoHandler = () => {
    onUndo();
    if (!isUndoDisabled()) {
      setIsStroke(false);
      setTimeout(async () => {
        canvasRef?.undo();
        const mergeImageArray = [canvasRef.canvasContainer.childNodes[1].toDataURL()];
        const oldAnnotation = oldAnnotations.find(({ photo }: any) => photo.id === image.photo_id);
        const photoServices = _.get(oldAnnotation, 'photoServices');

        const oldPhotoService = photoServices
          ? photoServices.find(({ service }: any) => service && service.id === serviceId)
          : undefined;
        if (oldPhotoService) {
          mergeImageArray.push(oldPhotoService.canvasData);
        }

        try {
          const mergedImages = await mergeImages(mergeImageArray);
          strokesTracker[image.photo_id].pop();
          setStrokesTracker({ ...strokesTracker });
          getPaths(mergedImages);
        } catch (error) {
          console.error(error); // eslint-disable-line no-console
        }
      }, 500);
    }
  };

  const redoHandler = async () => {
    const exportImage = canvasRef?.exportImage;
    const redo = canvasRef?.redo;

    if (redo && exportImage) {
      redo();
      setTimeout(async () => {
        const exportedDataURI = await exportImage('png');
        getPaths(exportedDataURI);
      }, 500);
    }
  };

  /*
   * Function that re-calculate after a zoom in or out
   */
  const patchCanvas = (canvasObj: any) => {
    if (canvasObj) {
      canvasRef = canvasObj;

      // TODO: This is copy-pasted from the package. The only change is we divide the result by the scale from the zoom.
      canvasRef.getPointerPos = (e: any) => {
        const rect = canvasRef.canvas.interface.getBoundingClientRect();

        // use cursor pos as default
        let {clientX} = e;
        let {clientY} = e;

        // use first touch if available
        if (e.changedTouches && e.changedTouches.length > 0) {
          clientX = e.changedTouches[0].clientX;
          clientY = e.changedTouches[0].clientY;
        }

        // return mouse/touch position inside canvas
        return {
          x: (clientX - rect.left) / scale,
          y: (clientY - rect.top) / scale,
        };
      };
    }
  };

  const renderStrokes = () => {
    const annotatedPhoto = annotatedPhotos.find(({ photo }: any) => photo.id === image.photo_id);
    if (_.get(annotatedPhoto, 'photoServices.length')) {
      return annotatedPhoto.photoServices.map(({ canvasData, id, service }: any) => (
        <img
          key={id}
          style={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, zIndex: 99 }}
          width={canvasWidth || 331}
          height={canvasHeight || CANVAS_HEIGHT}
          src={canvasData}
          alt={_.get(service, 'name', null) || 'Service'}
        />
      ));
    } 
    return null;
    
  };

  return (
    <div style={{ overflow: 'hidden', position: 'relative' }} ref={containerRef}>
      <div style={{ transform: `translate(${translate.t1}px, ${translate.t2}px) scale(${scale})` }} ref={photoRef}>
        <CanvasDraw
          lazyRadius={0}
          style={{ border: '1px solid transparent' }}
          className={classes.canvas}
          ref={(ref: any) => {
            patchCanvas(ref);
          }}
          onChange={onUpdate}
          brushRadius={serviceId ? brushRadius : 0}
          brushColor={brushColor}
          hideGrid
          canvasWidth={canvasWidth || 331}
          canvasHeight={canvasHeight || CANVAS_HEIGHT}
          imgSrc={imageUrl}
          hideInterface
          disabled={!serviceId}
        />
        {renderStrokes()}
        {photoServicesList &&
          photoServicesList[0] &&
          photoServicesList[0].map(({ canvasData, id, service }: any) => (
            <img
              key={id}
              style={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, zIndex: 99 }}
              width={canvasWidth || 331}
              height={canvasHeight || CANVAS_HEIGHT}
              src={canvasData}
              alt={_.get(service, 'name', null) || 'Service'}
            />
          ))}
      </div>
      <div className="canvas-overlay">
        <Box style={{ marginBottom: 'auto' }} display="flex" flexDirection="row" justifyContent="space-between">
          <Box className="move-buttons">
            <Box display="flex" justifyContent="center" height="33%">
              <Oval onClick={panUp}>
                <ArrowUpwardOutlined htmlColor="#fff" />
              </Oval>
            </Box>
            <Box display="flex" justifyContent="space-between" height="33%">
              <Oval onClick={panLeft}>
                <ArrowBackOutlined htmlColor="#fff" />
              </Oval>
              <Oval onClick={panRight}>
                <ArrowForwardOutlined htmlColor="#fff" />
              </Oval>
            </Box>
            <Box display="flex" justifyContent="center" height="33%">
              <Oval onClick={panDown}>
                <ArrowDownwardOutlined htmlColor="#fff" />
              </Oval>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" className="zoom-buttons">
            <Oval disabled={scale === SCALE_MIN} onClick={zoomOut}>
              <RemoveOutlined htmlColor="#fff" />
            </Oval>
            <Oval disabled={scale >= SCALE_MAX} onClick={zoomIn}>
              <AddOutlined htmlColor="#fff" />
            </Oval>
          </Box>
        </Box>
        <Box className="cover-bottom" style={{ display: hideControls ? 'none' : undefined }}>
          <Box display="flex" flexDirection="column" alignItems="center" className="slider-container">
            <Box>
              <div className="circle" />
            </Box>
            <MySlider
              onChange={onChangeSlider}
              className="slider"
              style={{ height: '137px' }}
              step={BRUSH_RADIUS_STEP}
              min={BRUSH_RADIUS_MIN}
              max={BRUSH_RADIUS_MAX}
              orientation="vertical"
              value={brushRadius}
              defaultValue={brushRadius}
            />
            <Box>
              <div className="circle-small" />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" marginLeft={2}>
            <Box>
              <ButtonBrush
                onClick={() => setBrushRadius(BRUSH_RADIUS_DEFAULT)}
                style={{ fontFamily: 'Roboto', color: '#000' }}
              >
                BRUSH
              </ButtonBrush>
              <Button
                onClick={() => clearHandler()}
                style={{ pointerEvents: 'auto', fontFamily: 'Roboto', color: '#fff' }}
              >
                CLEAR
              </Button>
            </Box>
            <Box display="flex" flexDirection="row" ml="auto" mr={2} mt={1}>
              <Box mr={2}>
                {performingUndo ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <Oval onClick={undoHandler} isVariant>
                    <RotateLeft htmlColor="#fff" />
                  </Oval>
                )}
              </Box>
              {false && (
                <Oval onClick={redoHandler} isVariant>
                  <RotateRight htmlColor="#fff" />
                </Oval>
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default ImageEditor;
