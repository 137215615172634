/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import TableCampaigns from 'src/components/DashboardPractitioner/Tabs/MarketingTab/TableCampaigns';
import TableReportsMedspa from './TableReportsMedspa';
import TableLeadsMedspa from './TableLeadsMedspa';
import LeadReportsMedspa from './LeadReportsMedspa';
import { TabFrame, TabFrameProps, TabDataObject } from './TabFrameMedspa';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { READ_MARKETING } from '../../../../constants/actions.constants';
import { MEDSPA_ADMIN_ROLE } from '../../../../constants/general.constants';

const InsightsTabMedspa = ({ state }: { state?: string }) => {
  const { permissions, roleName } = useSelector(({ auth }: RootState) => auth);
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;

  const leadsTab: TabDataObject = {
    leads: {
      label: 'Lead Manager',
      order: 1,
      target: <TableLeadsMedspa practitionerView={!isMedspaAdmin} />,
    },
    lead_reports: {
      label: 'Lead Reports',
      order: 2,
      target: <LeadReportsMedspa practitionerView={!isMedspaAdmin} isMedspaAdmin={isMedspaAdmin} />,
    },
  };

  const tabs: TabDataObject = {
    reports: {
      label: 'Client Reports',
      order: 0,
      target: <TableReportsMedspa practitionerView={!isMedspaAdmin} isMedspaAdmin={isMedspaAdmin} />,
    },
    ...leadsTab,
    campaigns: {
      label: 'Text Messages',
      order: 3,
      target: <TableCampaigns />,
    },
  };

  const tabProps: TabFrameProps = {
    path: isMedspaAdmin ? '/medspa-admin/marketing' : '/practitioner/marketing',
    tabs,
    state,
  };

  if (!hasAccessTo(READ_MARKETING, permissions) && !isMedspaAdmin) {
    return null;
  }

  return <TabFrame {...tabProps} />;
};

export { InsightsTabMedspa, InsightsTabMedspa as default };
