import { uniq } from 'lodash';
import { OLD_DATA_PLACEHOLDER } from '../constants/annotations.constants';
import { IServiceNotes, Lines } from '../interfaces/annotation.interfaces';
import Annotation from '../interfaces/drawer.interfaces';
import IServices from '../interfaces/IServices';
import { IServicesUnits } from '../interfaces/serviceVisit.interfaces';

export const getCharges = (lines: Lines, totalServicesUnits: IServicesUnits, serviceNotes: IServiceNotes) => {
  const serviceIdsWithAnnotations: number[] = uniq(
    Object.values(lines)
      .flatMap((line) => line)
      .map(({ id }) => id)
  );

  return serviceIdsWithAnnotations.map((id) => ({
    units: totalServicesUnits[id],
    note: serviceNotes[id],
    serviceId: id,
  }));
};

const getPhotoService = (
  annotationLines: Annotation.Line[],
  services: IServices[],
  totalServicesUnits: IServicesUnits
) => {
  const servicesPrices: { [serviceId: number]: number } = {};
  const serviceAnnotatedIds = uniq(annotationLines.map(({ id }) => id));
  const annotatedServices = services.filter(({ id }) => serviceAnnotatedIds.includes(id));
  annotatedServices.forEach(({ id, price }) => {
    servicesPrices[id] = price;
  });

  return serviceAnnotatedIds.map((serviceAnnotatedId) => ({
    ...OLD_DATA_PLACEHOLDER,
    price: servicesPrices[serviceAnnotatedId],
    serviceId: serviceAnnotatedId,
    units: totalServicesUnits[serviceAnnotatedId],
  }));
};

export const getPhotosData = (lines: Lines, services: IServices[], totalServicesUnits: IServicesUnits) =>
  Object.entries(lines).map(([photoId, annotationLines]) => ({
    photoId: +photoId,
    photoServices: getPhotoService(annotationLines, services, totalServicesUnits),
    annotationLines,
  }));
