import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  content: {
    minHeight: 'calc(100vh - 175px)',
  },
  header: {
    display: 'flex',
  },
  legend: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '20px',
  },
  title: {
    fontFamily: 'Messina Sans Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#393B3F',
    mixBlendMode: 'normal',
    opacity: 0.7,
  },
  paragraph: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '14px',
    lineHeight: '23px',
    color: '#000000',
    marginLeft: '35px',
  },
  scan: {
    marginTop: '28px',
    marginRight: '36px',
  },
});
