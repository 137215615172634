/* eslint-disable max-len */
import { uniq, isEmpty, isNil, reject } from 'lodash';
import { ASSET_LABEL } from '../constants/general.constants';
import { UNITS } from '../constants/medicalProfile.constants';
import { PHOTO_TYPE, SERVICE_GROUPS_THAT_DONT_REQUIRE_BEFORE_PHOTO } from '../constants/newServiceVisit.constants';
import { IAnnotationsState } from '../interfaces/annotation.interfaces';
import IServiceGroup from '../interfaces/IServiceGroup';
import IServices from '../interfaces/IServices';
import { IServicesUnits } from '../interfaces/serviceVisit.interfaces';

export const addLoadingProp = (obj: any, type: string, value: boolean): any => {
  if (type === PHOTO_TYPE.BEFORE) {
    obj.isUploadingBeforePhoto = value; // eslint-disable-line no-param-reassign
  } else {
    obj.isUploadingAfterPhoto = value; // eslint-disable-line no-param-reassign
  }
  return obj;
};

export const areAnnotationsComplete = (selectedServices: number[], annotationsModel: IAnnotationsState): boolean => {
  const { annotations, serviceNotes } = annotationsModel;
  let hasMissingAnnotation = false;
  let hasMissingNotes = false;

  const servicesWithAnnotations = uniq(
    reject(Object.values(annotations), isNil)
      .flatMap((annotation) => annotation)
      .map(({ id }) => id)
  );

  hasMissingAnnotation = servicesWithAnnotations.length !== selectedServices.length;
  hasMissingNotes = selectedServices.some((id) => !serviceNotes[id]);

  return selectedServices.length ? !hasMissingAnnotation && !hasMissingNotes : true;
};

export const getSelectedAnnotatableServiceIds = (selectedServices: number[], services: IServices[]): number[] => {
  const annotatableServices = services.filter(({ annotatable }) => annotatable).map(({ id }) => id);
  return selectedServices.filter((id) => annotatableServices.includes(id));
};

export const getServiceLabel = (allowPartialSale: boolean, unitLabel: string, assetLabel: string): string =>
  allowPartialSale ? UNITS[unitLabel] : ASSET_LABEL[assetLabel];

export const getServiceGroupsSelectedForConsents = (
  selectedServices: number[],
  totalServicesUnits: IServicesUnits,
  services: IServices[],
  serviceGroups: any
): number[] => {
  const servicesSelectedForConsents = services.filter(
    ({ id }) => selectedServices.includes(id) && +totalServicesUnits[id] > 0
  );

  return serviceGroups
    .filter((serviceGroup: any) =>
      servicesSelectedForConsents.some((service) => +serviceGroup.id === +service.serviceGroupId)
    )
    .map(({ id }: any) => id);
};

export const areConsentsSigned = (
  customerConsents: any,
  serviceGroups: any,
  selectedServiceGroupsForConsents: any,
  serviceVisitId: number
): boolean => {
  const serviceGroupsWithConsents = serviceGroups.filter(({ consent }: any) => !!consent);
  const serviceGroupsWithConsentIds = serviceGroupsWithConsents.map(({ id }: any) => id);

  const unsigned = selectedServiceGroupsForConsents.filter(
    (id: number) =>
      !customerConsents.some(
        (consent: any) => consent.serviceGroupId === id && consent.serviceVisitId === +serviceVisitId
      )
  );
  const validUnsigned = unsigned.filter((id: number) => serviceGroupsWithConsentIds.includes(id));

  return !validUnsigned.length;
};

export const areUnitsChosenValid = (units: IServicesUnits, creditUnits: IServicesUnits): boolean => {
  const validUnits = Object.values(units).some((value: any) => +value >= 0);
  const validFutureUse = Object.values(creditUnits).some((value: any) => +value >= 0);
  const hasUnusedServices = Object.keys(units).some(
    // eslint-disable-next-line radix
    (key: string) => +units[parseInt(key)] === 0 && +creditUnits[parseInt(key)] === 0
  );

  // revisar
  return !isEmpty(units) && validUnits && validFutureUse && !hasUnusedServices;
};

export const isAfterPhotoRequired = (
  selectedServiceGroups: number[],
  serviceGroups: IServiceGroup[],
  hasAfterPhotos: boolean
) => {
  const isRequired = selectedServiceGroups.some((selectedServiceGroupId: number) => {
    const selectedServiceGroup = serviceGroups.find(({ id }) => id === selectedServiceGroupId);
    return selectedServiceGroup?.afterPhotoRequired;
  });
  return isRequired && !hasAfterPhotos;
};

export const isBeforePhotoRequired = (
  selectedServiceGroups: number[] = [],
  serviceGroups: IServiceGroup[],
  hasBeforePhotos: boolean
) => {
  if (selectedServiceGroups.length === 0) {
    return false;
  }

  const isRequired = !selectedServiceGroups.every((selectedServiceGroupId: number) => {
    const selectedServiceGroup = serviceGroups.find(({ id }) => id === selectedServiceGroupId);
    return (
      selectedServiceGroup &&
      (Object.values(SERVICE_GROUPS_THAT_DONT_REQUIRE_BEFORE_PHOTO) as string[]).includes(selectedServiceGroup.name)
    );
  });

  return isRequired && !hasBeforePhotos;
};

export const hasPhotosFromType = (patientPhotos: any[], photosIds: number[], type: string): boolean =>
  patientPhotos.some(({ photoType, id }: any) => photosIds.includes(id) && photoType === type);
