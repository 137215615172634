import { ChartReview, IUpdateChartNote, ChartReviewApi } from '../interfaces/IPractitionerChartReview';
import Api from './Api';

const PractitionerChartReviews = {
  async getCharts({ showSaved, showUnread, page }: IUpdateChartNote): Promise<ChartReview[]> {
    const { success, data }: ChartReviewApi = await Api.get(
      `/clinical_oversight/charts/${ showUnread ? '1' : '0'}/${page}`,
      { showSaved }
    )
    return success ? data : []
  },

  async updateChart(params: IUpdateChartNote): Promise<void> {
    await Api.post(
      `/clinical_oversight/update`,
      params
    )
  }
}

export { PractitionerChartReviews, PractitionerChartReviews as default }