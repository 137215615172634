/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Collapse, TableCell, TableRow, Button } from '@material-ui/core';

import { IDiscount } from '../../../interfaces/checkout.interfaces';
import { DISCOUNT_TYPES } from '../../../constants/checkout.constants';
import { calculateDiscount, formatNumber } from '../../../utils/checkout.utils';

const useStyles = makeStyles(() => ({
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  appliedDiscountContainer: {
    border: '1px solid #B7B7B7',
    margin: '0 10px 25px 30px',
    padding: '15px',
  },
  appliedDiscountSection: {
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    border: '1px solid #393B3F1F',
    backgroundColor: '#FFFFFF',
    color: '#12574D',
    width: '114px',
    height: '36px',
    marginRight: '15px',
  },
  discountLineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Messina Sans Regular',
    fontSize: '13px',
    alignItems: 'center',
  },
}));

interface IAppliedDiscounts {
  discounts: any;
  totalPrice: any;
  openDiscountsModal: (name: string, id: number, quantity: number, units: number, isVariantItem: boolean) => void;
  service: any;
  units: any;
  isVariantItem?: any;
}
export const AppliedDiscounts = ({
  discounts,
  totalPrice,
  openDiscountsModal,
  service,
  units,
  isVariantItem,
}: IAppliedDiscounts) => {
  const classes = useStyles();

  const getAmountElement = (amount: string | number, type: string) => {
    const amountElement: any = [<span>{(+amount).toFixed(2)}</span>];
    DISCOUNT_TYPES.PERCENTAGE === type ? amountElement.push('%') : amountElement.unshift('$ ');
    return amountElement;
  };

  const AppliedDiscountLine = ({ reason, amount, amountElement, discountName }: any) => (
    <div className={classes.discountLineItem}>
      <div style={{ width: '85%' }}>
        {!!discountName && <span>{discountName}: </span>}
        {amountElement}
        {!!reason && (
          <>
            <span> off - Custom Discount - </span>
            <span>{reason}</span>
          </>
        )}
      </div>
      <div>{amount}</div>
    </div>
  );

  return (
    <TableRow>
      <TableCell className={classes.tableCell} colSpan={6}>
        <Collapse in={!!discounts.length} timeout="auto" unmountOnExit>
          <div className={classes.appliedDiscountContainer}>
            <div className={classes.appliedDiscountSection}>
              <Button
                className={classes.editButton}
                onClick={() => {
                  openDiscountsModal(service.name, service.id, service.price, units, isVariantItem);
                }}
              >
                edit
              </Button>
              <div style={{ width: '100%' }}>
                {discounts.map(({ id, reason, type, amount, value }: IDiscount) => (
                  <AppliedDiscountLine
                    key={id}
                    amountElement={getAmountElement(amount, type)}
                    reason={reason}
                    discountName={value}
                    amount={`- $ ${formatNumber(calculateDiscount(amount, type, totalPrice))}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
