import { createModel } from '@rematch/core';
import { IPatientContactInfo } from 'src/types/Patient';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import ISubscription from 'src/interfaces/ISubscription';
import { PHOTO_REQUEST_MESSAGE } from 'src/constants/medicalProfile.constants';
import ICustomerDiagnosis from 'src/interfaces/ICustomerDiagnosis';
import IServiceRequest from 'src/interfaces/IServiceRequest';

import {
  fetchPatientData,
  fetchPatientsNotes,
  fetchPractitioners,
  fetchServiceGroups,
  uploadPhotosRequest,
  deletePhotoRequest,
  addNoteRequest,
  deleteNoteRequest,
  changePractitionerRequest,
  approveServiceRequest,
  requestPhoto,
  clearPhotoRequest,
  updateMedicalProfileRequest,
  signConsentRequest,
  fetchBasicInfo,
  fetchContactInfo,
  fetchMedicalProfile,
  fetchCustomerConsents,
  fetchCustomerStandingOrders,
  fetchPhotos,
  fetchCustomerDiagnoses,
  fetchServiceRequests,
  fetchMessagesThreads,
  fetchServiceVisits,
  discardServiceVisit,
  saveCustomerDiagnoses,
  toggleIsAdvocate,
  completeProfile,
  updateNewMedicalProfile,
  addCustomerCreditCard,
  deleteCustomerCreditCard,
  setCustomerDefaultCreditCard,
  chargeCustomerCreditCard,
  purchaseServicesAndProducts,
  prepareCheckoutForFee,
} from 'src/rematch/patient.actions';
import { RootModel } from 'src/rematch/rootModel';
import IConsent from 'src/interfaces/IConsent';
import { IPhoto } from 'src/interfaces/global.interfaces';

type YesOrNo = 'yes' | 'no';

export interface PatientState {
  id: number | null;
  isLoadingBasicInfo: boolean | null;
  isLoadingPractitioners: boolean | null;
  isLoadingMedicalProfile: boolean | null;
  serviceVisits: any[] | null;
  photos: IPhoto[] | null;
  contactInfo: IPatientContactInfo;
  basicInfo: {
    basicInfoLoadedAt: Date | null;
    basicInfoError: Error | null;
    id: number | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    createdAt: string | null;
    customerStatus: string | null;
    ignored: boolean | null;
    isAdvocate: boolean | null;
    advocateReason: string | null;
    referredBy: string | null;
    referredById: string | null;
    galdermaEmail: string | null;
    hasReferred: boolean | null;
    claimed: boolean | null;
    claimedBy: string | null;
    claimedByName: string | null;
    claimedAt: string | null;
    creditCardOnFile: string | null;
    source: string | null;
    marketingChannel: string | null;
    currentBalance: number | null;
    sex: string | null;
    dateOfBirth: string | null;
    subscription: ISubscription | null;
    creditCards: any[] | null;
    squareDefaultCreditCardId: string | null;
    usedIndustryDiscount: boolean | null;
    phone: string | null;
  };
  medicalProfile: {
    createdAt: string | null;
    conditions: any; // I guess deprecated, not returning from api.
    currentNonprescriptionTopicalMedications: string | null;
    currentPrescriptionOralMedications: string | null;
    currentPrescriptionOtherMedications: string | null;
    currentPrescriptionTopicalMedications: string | null;
    customerId: number | null;
    dateOfBirth: string | null;
    goalsAndNotes: string | null; // I guess deprecated, not returning from api.
    hasBeenOnAccutane: boolean | null;
    hasHormonalIssues: boolean | null;
    hasKnownAllergies: boolean | null;
    hasMedicationAllergies: boolean | null;
    heightInCms: number | null;
    id: number | null;
    interestedInWeightLoss: YesOrNo | null;
    isBreastFeeding: YesOrNo | null;
    isOnBirthControl: YesOrNo | null;
    isPregnant: YesOrNo | null;
    isSmoker: YesOrNo | null;
    knownAllergies: string | null;
    medicationAllergies: string[] | null;
    oralMedications: string[] | null;
    otherConcerns: string | null;
    otherMedications: string[] | null;
    pastNonprescriptionTopicalMedications: string | null;
    pastPrescriptionOralMedications: string | null;
    pastPrescriptionOtherMedications: string | null;
    pastPrescriptionTopicalMedications: string | null;
    preferredFragrance: string | null;
    procedures: any; // I guess deprecated, not returning from api.
    serviceImprovements: string[] | null;
    sex: string | null;
    skinConcerns: string[] | null;
    skinType: string | null;
    sunscreenBrand: string | null;
    sunscreenFrequency: string | null;
    topicalMedications: string[] | null;
    updatedAt: string | null;
    updatedFields: string[] | null;
    userActiveMedicalConditions: string | null;
    userSkinExtraDetails: string | null;
    usingPeels: YesOrNo | null;
    usingRetinol: YesOrNo | null;
    weightInLbs: number | null;
  };
  isLoadingContactInfo: boolean;
  isLoadingToggleIsAdvocate: boolean;
  isLoading: boolean;
  practitioners?: IPractitioner[];
  // Coming from fetchPatientData
  firstName: string | null;
  lastName: string | null;
  createdAt: string | null;
  phone: string | null;
  addressLine1?: string;
  state?: string;
  zipCode?: string;
  city?: string;
  customerConsents: IConsent[];
  currentBalance: number;
}

const INITIAL_STATE: PatientState = {
  id: 0,
  basicInfo: {
    basicInfoLoadedAt: null,
    basicInfoError: null,
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    createdAt: null,
    customerStatus: null,
    ignored: null,
    isAdvocate: null,
    advocateReason: null,
    referredBy: null,
    referredById: null,
    galdermaEmail: null,
    hasReferred: null,
    claimed: null,
    claimedBy: null,
    claimedByName: null,
    claimedAt: null,
    creditCardOnFile: null,
    source: null,
    marketingChannel: null,
    currentBalance: null,
    sex: null,
    dateOfBirth: null,
    subscription: null,
    creditCards: null,
    squareDefaultCreditCardId: null,
    usedIndustryDiscount: null,
    phone: null,
  },
  contactInfo: {
    addressLine1: '',
    addressLine2: '',
    contactInfoLoadedAt: null,
    dateOfBirth: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    phone: '',
    city: '',
    state: '',
    unreadMessagesCount: 0,
    zipCode: '',
    physician: {
      email: '',
      firstName: '',
      id: 0,
      lastName: '',
      professionalName: '',
      signatureImageUrl: '',
    },
    practitioner: {
      id: 0,
      firstName: '',
      lastName: '',
      businessEntity: '',
      medicalGroup: '',
      hasBeautibankEnabled: undefined,
      professionalName: '',
      providerLabeling: '',
    },
    shippingAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      createdAt: '',
      customerId: 0,
      id: 0,
      state: '',
      updatedAt: '',
      zipCode: '',
    },
  },
  medicalProfile: {
    id: null,
    sex: '',
    dateOfBirth: null,
    knownAllergies: '',
    medicationAllergies: [],
    isPregnant: null,
    isBreastFeeding: null,
    skinConcerns: [],
    serviceImprovements: [],
    skinType: '',
    sunscreenFrequency: '',
    usingRetinol: null,
    usingPeels: null,
    userActiveMedicalConditions: '',
    goalsAndNotes: '',
    userSkinExtraDetails: '',
    oralMedications: [],
    topicalMedications: [],
    otherMedications: [],
    conditions: {},
    procedures: {},
    weightInLbs: null,
    heightInCms: null,
    createdAt: null,
    currentNonprescriptionTopicalMedications: null,
    currentPrescriptionOralMedications: null,
    currentPrescriptionOtherMedications: null,
    currentPrescriptionTopicalMedications: null,
    customerId: 0,
    updatedAt: null,
    hasBeenOnAccutane: null,
    hasHormonalIssues: null,
    hasKnownAllergies: null,
    hasMedicationAllergies: null,
    interestedInWeightLoss: null,
    isOnBirthControl: null,
    isSmoker: null,
    pastNonprescriptionTopicalMedications: null,
    pastPrescriptionOralMedications: null,
    pastPrescriptionOtherMedications: null,
    pastPrescriptionTopicalMedications: null,
    preferredFragrance: null,
    otherConcerns: null,
    sunscreenBrand: null,
    updatedFields: null,
  },
  firstName: '',
  lastName: '',
  createdAt: '',
  email: '',
  galdermaEmail: '',
  hasReferred: false,
  currentBalance: 0,
  practitionersAssigned: [],
  messagesThreads: [],
  physicianApprovalDraftLoaded: false,
  customerDiagnoses: [],
  serviceRequests: [],
  otherServiceRequests: [],
  customerConsents: [],
  photos: [],
  notes: [],
  practitioners: [],
  serviceVisits: [],
  serviceGroups: [],
  isLoading: false,
  isUploadingPhoto: false,
  photoRequestReSent: false,
  isLoadingBasicInfo: false,
  isLoadingMedicalProfile: false,
  isLoadingContactInfo: false,
  isLoadingToggleIsAdvocate: false,
  isLoadingPractitioners: false,
  isAdvocate: false,
  advocateReason: '',
  isLoadingDiscardServiceVisit: false,
  usedIndustryDiscount: false,
  referreBy: '',
};

export const patient = createModel<RootModel>()({
  state: INITIAL_STATE,
  reducers: {
    getPatient(state: any) {
      return { ...state, isLoading: true };
    },
    getPatientSuccess(state: any, payload?: PatientState): PatientState {
      let extra: { id?: number } = {};
      if (payload?.id) {
        extra = { id: payload.id };
      }
      return { ...state, ...payload, ...extra, isLoading: false };
    },
    getPatientFail(state: any) {
      return { ...state, isLoading: false };
    },
    getServiceGroups(state: any, payload: any) {
      return { ...state, serviceGroups: payload };
    },
    getPatientNotes(state: any, payload: any) {
      return { ...state, notes: payload };
    },
    getPractitioners(state: any, payload: any) {
      return { ...state, practitioners: payload };
    },
    uploadPhotosLoading(state: any, loading: boolean) {
      return { ...state, isUploadingPhoto: loading };
    },
    uploadPhotosSuccess(state: any, payload: any) {
      const photos = payload.concat(state.photos);
      return { ...state, photos };
    },
    deletePhotoSuccess(state: any, payload: any) {
      const photos = state.photos.filter((photo: { id: any }) => photo.id !== payload.photoId);
      return { ...state, photos };
    },
    addNoteSuccess(state: any, payload: any) {
      const notes = [...state.notes];
      notes.push(payload);
      return { ...state, notes };
    },
    deleteNoteSuccess(state: any, { noteId }: any) {
      const notes = state.notes.filter(({ id }: any) => id !== noteId);
      return { ...state, notes };
    },
    updateContactInfo(state: any, payload: any) {
      return { ...state, ...payload };
    },
    updatePractitionerSuccess(state: any, { practitioner_id: practitionerId }: any) {
      const practitioner = state.practitioners.find(({ id }: any) => id === practitionerId);
      return { ...state, practitioner };
    },
    updatePractitionerFail(state: any) {
      return { ...state };
    },
    toggleDiagnosis(state: any, { diagnosisId, isSelected, treatmentPlan }: any) {
      const editedDiagnosis = state.customerDiagnoses.find(
        ({ defaultDiagnosisId }: any) => defaultDiagnosisId === diagnosisId
      );

      const updatedDiagnoses = { ...editedDiagnosis, defaultDiagnosisId: diagnosisId };
      if (isSelected !== undefined) {
        updatedDiagnoses.isSelected = isSelected;
      }
      if (treatmentPlan !== undefined) {
        updatedDiagnoses.treatmentPlan = treatmentPlan;
      }
      return {
        ...state,
        customerDiagnoses: [
          ...state.customerDiagnoses.filter(
            (diagnosis: ICustomerDiagnosis) => diagnosis.defaultDiagnosisId !== diagnosisId
          ),
          updatedDiagnoses,
        ],
      };
    },
    resetPhysicianApprovalDraft(state: any) {
      const serviceRequests = state.serviceRequests.map((service: any) => ({ ...service, approved: false }));
      return { ...state, physicianApprovalDraftLoaded: false, customerDiagnoses: [], serviceRequests };
    },
    loadPhysicianApprovalFromDraft(
      state: any,
      {
        physicianApprovalDraft: { customerDiagnosesChecked, serviceRequestsChecked },
        defaultDiagnoses,
      }: {
        physicianApprovalDraft: { customerDiagnosesChecked: number[]; serviceRequestsChecked: number[] };
        defaultDiagnoses: any;
      }
    ) {
      /* Method that load the draft data if exits and it will replace
       * the real services requests and diagnoses approved by the draft
       */
      const { serviceRequests } = state;
      let { customerDiagnoses } = state;

      if (serviceRequestsChecked?.length || customerDiagnosesChecked?.length) {
        serviceRequests.forEach((service: any) => {
          if (serviceRequestsChecked?.includes(service.serviceGroupId)) {
            service.approved = true; // eslint-disable-line no-param-reassign
          } else {
            service.approved = false; // eslint-disable-line no-param-reassign
          }
        });

        customerDiagnoses = [];

        defaultDiagnoses.forEach((diagnoses: any) => {
          const customerDiagnosesAux = customerDiagnosesChecked?.filter(
            (elem: any) => elem.defaultDiagnosisId === diagnoses.id
          );
          if (customerDiagnosesAux.length > 0) {
            const { treatmentPlan }: any = customerDiagnosesAux[0];
            customerDiagnoses.push({
              defaultDiagnosisId: diagnoses.id,
              isSelected: true,
              treatmentPlan,
            });
          }
        });
      }

      return { ...state, serviceRequests, customerDiagnoses, physicianApprovalDraftLoaded: true };
    },
    // if the service has no id and toogle to not approved, not save it
    toggleService(state: any, { serviceId }: any) {
      const serviceRequests = [...state.serviceRequests];
      const service = serviceRequests.find(({ serviceGroupId }: any) => serviceGroupId === serviceId);

      if (service) {
        service.approved = !service.approved;
      }

      return { ...state, serviceRequests };
    },
    // add a non requested service group to the list of requested services
    // the doctor could approve any sg, in the patient profile
    addServiceToRequestedService(state: any, service: IServiceRequest) {
      return { ...state, serviceRequests: [...state.serviceRequests, service] };
    },
    toggleNote(state: any, { noteId }: any) {
      const notes = [...state.notes];
      const note = notes.find(({ id }: any) => id === noteId);
      note.isOpen = !note.isOpen;
      return { ...state, notes };
    },
    updateMedicalProfile(state: any, { medicalProfile }: PatientState): PatientState {
      return { ...state, medicalProfile, id: medicalProfile.customerId };
    },
    updateServiceVisit(state: any, payload: any) {
      const serviceVisits = [...state.serviceVisits];
      serviceVisits.push(payload);
      return { ...state, serviceVisits };
    },
    addServiceVisitNoteSuccess(state: any, payload: any) {
      let serviceVisits = [...state.serviceVisits];

      const addOrUpdateNote = (sV: any, note: any) => {
        const exists = sV.notes.find((item: any) => item.id === note.id);

        if (exists) {
          const notes = sV.notes.filter((item: any) => item.id !== note.id);
          return { ...sV, notes: notes.concat([note]) };
        }
        return { ...sV, notes: sV.notes.concat([note]) };
      };

      serviceVisits = serviceVisits.map((sV) => (sV.id === payload.serviceVisitId ? addOrUpdateNote(sV, payload) : sV));

      return { ...state, serviceVisits };
    },
    updatePatientConsent(state: any, payload: any) {
      const customerConsents = [...state.customerConsents];
      customerConsents.push(payload);
      return { ...state, customerConsents };
    },
    clearPatientData() {
      return { ...INITIAL_STATE, isLoading: true };
    },
    setPhotoRequestPreResponse(state: any, payload: any) {
      const messagesThreads = [...state.messagesThreads];
      const openRequestPhotoMessage: any = state.messagesThreads.filter(
        ({ open, messages }: any) => open && messages.find(({ content }: any) => content === PHOTO_REQUEST_MESSAGE)
      );

      if (!openRequestPhotoMessage.length) {
        messagesThreads.push({ open: true, messages: [{ content: PHOTO_REQUEST_MESSAGE }], [payload]: 'mockId' });
      }

      return { ...state, messagesThreads };
    },
    setPhotoRequest(state: any, payload: any) {
      return { ...state, ...payload };
    },
    clearPhoto(state: any, payload: number) {
      const thread = state.messagesThreads.find(({ id }: any) => id === payload);
      thread.open = false;
      return { ...state };
    },
    updatePhotoRequestReSent(state: any) {
      return { ...state, photoRequestReSent: true };
    },
    markServiceVisitAsClosed(state: any) {
      const updatedServiceVisits = state.serviceVisits.map((serviceVisit: any) => ({ ...serviceVisit, opened: false }));

      return { ...state, serviceVisits: updatedServiceVisits };
    },
    completeProfileSuccess(state: PatientState) {
      return { ...state, basicInfo: { ...state.basicInfo, customerStatus: 'to_clear' } };
    },
    setIsLoadingBasicInfo(state: any, payload) {
      return { ...state, isLoadingBasicInfo: payload };
    },
    setIsLoadingContactInfo(state: any, payload) {
      return { ...state, isLoadingContactInfo: payload };
    },
    setIsLoadingMedicalProfile(state: any, payload) {
      return { ...state, isLoadingMedicalProfile: payload };
    },
    setIsLoadingCustomerConsents(state: any, payload) {
      return { ...state, isLoadingCustomerConsents: payload };
    },
    setIsLoadingPhotos(state: any, payload) {
      return { ...state, isLoadingPhotos: payload };
    },
    setIsLoadingServiceRequests(state: any, payload) {
      return { ...state, isLoadingServiceRequests: payload };
    },
    setIsLoadingMessagesThreads(state: any, payload) {
      return { ...state, isLoadingMessagesThreads: payload };
    },
    setIsLoadingDiscardServiceVisit(state: any, payload) {
      return { ...state, isLoadingDiscardServiceVisit: payload };
    },
    setIsLoadingToggleIsAdvocate(state: any, payload: boolean) {
      return { ...state, isLoadingToggleIsAdvocate: payload };
    },
    setBasicInfoError(state: any, payload: any) {
      return { ...state, basicInfo: { ...state.basicInfo, basicInfoError: payload } };
    },
    setIsLoadingPractitioners(state: any, payload: boolean) {
      return { ...state, isLoadingPractitioners: payload };
    },
    setStandingOrderDraft(state: any, payload: { name: string; standingOrder: string }) {
      const standingOrderDraftTmp = { ...state.standingOrderDraft };
      standingOrderDraftTmp[payload.name] = payload.standingOrder;
      return { ...state, standingOrderDraft: { ...standingOrderDraftTmp } };
    },
    updateServiceVisits(state: any, payload: any) {
      return { ...state, serviceVisits: [...payload] };
    },
    resetPatientProfile() {
      return { ...INITIAL_STATE };
    },
  },
  effects: {
    chargeCustomerCreditCard,
    addCustomerCreditCard,
    setCustomerDefaultCreditCard,
    deleteCustomerCreditCard,
    fetchPatientData,
    fetchPatientsNotes,
    fetchPractitioners,
    fetchServiceGroups,
    uploadPhotosRequest,
    deletePhotoRequest,
    addNoteRequest,
    deleteNoteRequest,
    changePractitionerRequest,
    approveServiceRequest,
    requestPhoto,
    clearPhotoRequest,
    updateMedicalProfileRequest,
    signConsentRequest,
    fetchBasicInfo,
    fetchContactInfo,
    fetchMedicalProfile,
    fetchCustomerConsents,
    fetchCustomerStandingOrders,
    fetchPhotos,
    fetchCustomerDiagnoses,
    fetchServiceRequests,
    discardServiceVisit,
    fetchMessagesThreads,
    fetchServiceVisits,
    toggleIsAdvocate,
    completeProfile,
    saveCustomerDiagnoses,
    updateNewMedicalProfile,
    purchaseServicesAndProducts,
    prepareCheckoutForFee,
  },
});
