import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Modal,
  Switch,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  FormHelperText,
  Chip,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCities, useCoordinates, useStates } from 'src/hooks/queries/useStates';
import { Autocomplete } from '@material-ui/lab';
import IState from 'src/interfaces/IState';
import { useMedspaPractitioners } from 'src/hooks/queries/useMedspaAdmins';
import {
  useCreateMedspaLocationMutation,
  useUpdateMedspaLocationMutation,
} from 'src/hooks/queries/medspaAdmins/useMedspaLocations';
import { yupResolver } from '@hookform/resolvers';
import { USA_COORDS } from 'src/constants/google';
import { debounce } from 'lodash';
import { MedspaLocationMap } from './MedspaLocationMap';
import { ACUITY_TIMEZONE_OPTIONS } from '../../../constants/acuity/acuity.constants';
import MultipleSelector from '../../common/Custom/MultipleSelector';

const schema = yup.object().shape({
  name: yup.string().required(),
  state: yup.string().required(),
  city: yup.string().required(),
  address: yup.string().required(),
  timezone: yup.string().required(),
  practitioners: yup.array(),
  zipCode: yup
    .string()
    .required()
    .matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code'),
});

export const useStyles = makeStyles(() => ({
  title: {
    margin: '0 1rem 0 0',
  },
  formModal: {
    width: '38.2%',
    margin: '0.625rem 0.625rem auto auto',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '24px',
    minWidth: '550px',
    maxWidth: '550px',
  },
  formModalContent: {
    height: '98vh',
    width: '50%',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '24px',
    minWidth: '550px',
  },
  submitButton: {
    alignSelf: 'center',
    minWidth: '110px',
  },
  separator: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  formContainer: {
    overflowY: 'scroll',
    height: 'calc(100vh - 215px)',
  },
  footer: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '100%',
  },
  timezoneSelect: {
    height: '40px !important',
    marginBottom: '1rem',
    '& > div': {
      height: '100%',
    },
  },
}));

interface Props {
  openModal: boolean;
  onClose: () => void;
  medspaLocation?: any;
  medspaLegacyPracIds: number[] | null;
  onSuccessCallback?: () => void;
}

const DEFAULT_VALUE_FORM = {
  name: '',
  slug: '',
  city: '',
  state: '',
  address: '',
  zipCode: '',
  timezone: '',
};
const TIME_DO_SEARCH = 2000;

const MedspaLocationModal = ({
  openModal,
  onClose: propsOnClose,
  medspaLocation,
  medspaLegacyPracIds,
  onSuccessCallback,
}: Props) => {
  const classes = useStyles();
  const coordinatesSetByMap = useRef<any>(false);
  const [coordinatesParams, setCoordinateParams] = React.useState({});
  const [slugChangedFromInput, setSlugChangedFromInput] = React.useState(!!medspaLocation?.id);

  const { register, handleSubmit, control, setValue, watch, errors, reset } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: medspaLocation || DEFAULT_VALUE_FORM,
  });
  const name = watch('name', '');
  const active = watch('active', true);
  const state = watch('state', '');
  const city = watch('city', '');
  const zipCode = watch('zipCode');
  const address = watch('address', '');
  const latitude = watch('latitude', '');
  const longitude = watch('longitude', '');
  const practitioners = watch('practitioners', []);
  const timezone = watch('timezone', '');

  const { data: states = [], isFetching: isFetchingStates } = useStates();
  const { data: cities = [], isFetching: isFetchingCities } = useCities(state);
  const { data: coordinates = USA_COORDS, isFetching: isFetchingCoordinates } = useCoordinates(
    coordinatesParams,
    !!coordinatesParams?.zipCode
  );
  const { data: practitionerList, isFetching: isFetchingPractitionerList } = useMedspaPractitioners();

  const onClose = () => {
    reset();
    setSlugChangedFromInput(false);
    propsOnClose();
  };

  const createMedspaLocation = useCreateMedspaLocationMutation(() => {
    onClose();
    reset();
  });
  const updateMedspaLocation = useUpdateMedspaLocationMutation(medspaLocation?.id, () => {
    onClose();
    reset();
  });

  const isLoading = updateMedspaLocation.isLoading || createMedspaLocation.isLoading;

  const findCoordinatesParams = useCallback(
    debounce((payload: any) => setCoordinateParams(payload), 2000),
    []
  );

  const setAutoSlug = useCallback(
    debounce((payload: any) => {
      if (payload && !medspaLocation?.id) {
        setValue('slug', payload.trim().replace(/\s+/g, '-').toLowerCase());
      }
    }, TIME_DO_SEARCH),
    []
  );

  useEffect(() => {
    if (name && !medspaLocation?.id && !slugChangedFromInput) {
      setAutoSlug(name);
    }
  }, [name]);

  useEffect(() => {
    if (!coordinatesSetByMap.current && zipCode && zipCode !== coordinatesParams?.zipCode) {
      findCoordinatesParams({ zipCode });
    }
  }, [zipCode]);

  useEffect(() => {
    const newForm = medspaLocation || DEFAULT_VALUE_FORM;
    reset();
    Object.keys(newForm).forEach((key) => {
      setValue(key, newForm[key] || '');
    });

    setSlugChangedFromInput(!!medspaLocation?.id);
  }, [medspaLocation]);

  useEffect(() => {
    if (medspaLegacyPracIds) {
      setValue('practitioners', medspaLegacyPracIds);
    }
  }, [medspaLegacyPracIds]);

  const onChangeMarker = (data: {
    lat: number;
    lng: number;
    zipCode: number;
    formattedAddress: string;
    city: string;
    state: string;
  }) => {
    setValue('latitude', data.lat);
    setValue('longitude', data.lng);

    if (data.zipCode) {
      coordinatesSetByMap.current = true;
      setValue('zipCode', +data.zipCode);
    }
    if (data.formattedAddress) {
      setValue('address', data.formattedAddress);
    }
    if (data.city) {
      setValue('city', data.city);
    }
    if (data.state) {
      const stateFound = states.find((stateOption) => stateOption.name === data.state);
      if (stateFound?.code) {
        setValue('state', stateFound.code);
      }
    }
  };

  const onChangeZipCode = (ev: any) => {
    const value = ev?.target?.value;
    coordinatesSetByMap.current = false;
    // Remove marker if zip code changed
    setValue('latitude', '');
    setValue('longitude', '');
    setValue('zipCode', value);
  };

  const submit = (data: any) => {
    if (medspaLocation?.id) {
      updateMedspaLocation.mutate(data);
    } else {
      createMedspaLocation.mutate(data);
    }
    onSuccessCallback?.();
  };

  const citiesOptions = useMemo(() => {
    const options = cities?.map((cityName) => ({ name: cityName, code: cityName })) || ([] as IState[]);
    if (!cities.includes(city)) {
      // Add option if the autocomplete city is not present in the cities list
      options.push({ name: city, code: city });
    }
    options.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return options;
  }, [city, cities]);

  const practitionerListOptions = useMemo(
    () =>
      practitionerList?.map(({ firstName, lastName, id: practitionerId }) => ({
        name: `${firstName} ${lastName}`,
        value: practitionerId,
      })) || [],
    [practitionerList]
  );

  const renderTags = (tagValue: any, getTagProps: any) =>
    tagValue.map((option: any, index: number) => {
      if (medspaLocation?.practitioners.includes(option.value)) {
        return <Chip label={option.name} {...getTagProps({ index })} disabled />;
      }
      return <Chip label={option.name} {...getTagProps({ index })} />;
    });

  return (
    <Modal open={openModal} onClose={onClose} className={classes.formModal}>
      <Box className={classes.formModalContent}>
        {/* Modal Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center" p="1.5rem">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom className={classes.title}>
              Medspa Location
            </Typography>
          </Box>
          <Button onClick={onClose} style={{ minWidth: 'auto', border: '1px solid #CFCFCF' }}>
            <CloseIcon />
          </Button>
        </Box>
        <hr className={classes.separator} />
        {/* Modal Body */}
        <Box
          component="form"
          onSubmit={handleSubmit(submit)}
          alignItems="center"
          p="1.5rem"
          className={classes.formContainer}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false}>
                Location Name
              </InputLabel>
              <TextField
                inputRef={register}
                name="name"
                error={!!errors.name}
                helperText={errors.name?.message || ''}
                required
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false}>
                Address
              </InputLabel>
              <TextField
                inputRef={register}
                name="address"
                error={!!errors.address}
                value={address}
                helperText={errors.address?.message || ''}
                required
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false}>
                State
              </InputLabel>
              <Autocomplete
                inputValue={medspaLocation ? state : undefined}
                options={states}
                groupBy={(option) => option.name[0]}
                getOptionLabel={(usState) => usState.name}
                style={{ minWidth: '10rem' }}
                disabled={isFetchingStates}
                helperText={errors.states?.message || ''}
                onChange={(ev, value: IState) => {
                  setValue('state', value?.code || '');
                  setValue('city', '');
                }}
                renderInput={(params: any) => (
                  <TextField {...params} error={!!errors.states} size="small" autocomplete="off" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel color="secondary" focused={false}>
                City
              </InputLabel>
              <Autocomplete
                inputValue={medspaLocation ? city : undefined}
                options={citiesOptions}
                groupBy={(option) => option.name[0]}
                getOptionLabel={(usCity) => usCity.name}
                disabled={isFetchingCities}
                style={{ minWidth: '10rem' }}
                helperText={errors.city?.message || ''}
                onChange={(ev, value: IState) => setValue('city', value?.code || '')}
                renderInput={(params: any) => (
                  <TextField {...params} error={!!errors.city} size="small" autocomplete="off" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel color="secondary" focused={false}>
                ZIP Code
              </InputLabel>
              <TextField
                inputRef={register}
                name="zipCode"
                error={!!errors.zipCode}
                value={zipCode}
                onChange={onChangeZipCode}
                helperText={errors.zipCode?.message || ''}
                // disabled={isFetchingCoordinates}
                required
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false}>
                Time Zone
              </InputLabel>
              <FormControl variant="outlined" fullWidth>
                <Controller
                  style={{ height: '40px' }}
                  control={control}
                  name="timezone"
                  error={!!errors.timezone}
                  as={
                    <TextField
                      select
                      id="timezone"
                      data-cy="input-timezone"
                      variant="outlined"
                      className={classes.timezoneSelect}
                      label={null}
                      value={timezone}
                      InputLabelProps={{ shrink: false }}
                    >
                      {ACUITY_TIMEZONE_OPTIONS.map((timezoneOption: string) => (
                        <MenuItem key={timezoneOption} value={timezoneOption}>
                          {timezoneOption.replace('_', ' ')}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                />
                {errors.timezone && <FormHelperText error>{errors.timezone.message}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <MedspaLocationMap
                lat={coordinates.latitude}
                lng={coordinates.longitude}
                legend={coordinates?.formattedAddress}
                defaultMarker={latitude && longitude ? { lat: latitude, lng: longitude } : undefined}
                loading={isFetchingCoordinates}
                onChangeMarker={onChangeMarker}
                getExtraInfo
              />
            </Grid>
            {!medspaLegacyPracIds && (
              <Grid item xs={12}>
                <InputLabel color="secondary" focused={false}>
                  Providers
                </InputLabel>
                <MultipleSelector
                  autocomplete
                  disabled={isFetchingPractitionerList}
                  name="practitioners"
                  error={!!errors.practitioners}
                  helperText={errors.practitioners?.message || ''}
                  control={control}
                  value={practitioners}
                  onChange={(v) => setValue('practitioners', v)}
                  errors={null}
                  options={practitionerListOptions}
                  renderTags={renderTags}
                  disableClearable
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl>
                <Box display="flex">
                  <FormControlLabel
                    control={<Switch inputRef={register} color="primary" defaultChecked={active} name="active" />}
                    label="Location is Active"
                  />
                </Box>
              </FormControl>
            </Grid>
          </Grid>
          <Box className={classes.footer}>
            <hr className={classes.separator} />
            <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Box style={{ width: '100%' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.submitButton}
                  style={{ width: '100%' }}
                  disabled={isLoading}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MedspaLocationModal;
