import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SERVICE_VISIT_CONSENTS } from '../../constants/reactQuery.keys';
import ServiceVisit from '../../services/ServiceVisit';
import { useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';

export function useServiceVisitConsent(id: number, enabled: boolean) {
  const response = useQuery([SERVICE_VISIT_CONSENTS, id], () => ServiceVisit.serviceVisitConsents(id), {
    enabled: !!id && enabled,
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: true,
    onError: () =>
      useReactQueryOnErrorHandler(
        compile('generic.error_message', { action: 'fetching', element: 'customer consents' })
      ),
  });

  return {
    ...response,
    data: response.data?.consents || [],
  };
}
export const useSignServiceVisitConsent = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => ServiceVisit.signServiceVisitConsent(serviceVisitId, params), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICE_VISIT_CONSENTS, serviceVisitId]);
    },
    onError: useReactQueryOnErrorHandler,
  });
};
