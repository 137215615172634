import React, { ReactElement } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { formatCurrency } from 'src/utils/formatNumber';
import { useRemoveCharge } from 'src/hooks/queries/useInventoryOrders';
import TrashCan from 'src/components/common/TrashCan';
import { round } from 'lodash';
import { useStyle, TableCellHeader } from '../inventoryOrder.styles';
import { Props, RowProps } from '.';

const ChargeRow: React.FC<RowProps> = ({ children, charge, isLoading, colFill = 0 }) => {
  const classes = useStyle();

  return (
    <TableRow className={classes.pulseOnly} data-loading={isLoading}>
      <TableCell className={classes.approvalRowName}>{charge.description} </TableCell>
      {!!colFill && <TableCell colSpan={colFill - (children ? 1 : 0)} />}
      <TableCell className={colFill ? classes.approvalRowPrice : undefined}>{formatCurrency(charge.amount)}</TableCell>
      {!!children && <TableCell className={classes.removeItem}>{children}</TableCell>}
    </TableRow>
  );
};

const ChargeRowEdit: React.FC<RowProps> = ({ charge, colFill = 0 }) => {
  const { mutateAsync: removeCharge, isLoading } = useRemoveCharge({
    inventoryOrderChargeId: `${charge.id}`,
    inventoryOrderId: charge.inventoryOrderId ?? 0,
  });

  return (
    <ChargeRow charge={charge} isLoading={isLoading} colFill={colFill}>
      {isLoading || !charge.inventoryOrderId || !charge.id ? null : (
        <Box
          onClick={async () => {
            await removeCharge();
          }}
        >
          <TrashCan />
          Remove
        </Box>
      )}
    </ChargeRow>
  );
};

export const ChargeContainer: React.FC<{ label?: string; children?: ReactElement | null } & Omit<Props, 'charges'>> = ({
  priceLabel,
  label,
  colFill = 0,
  readOnly = false,
  children,
}) => {
  const classes = useStyle();

  return (
    <Box>
      <Box padding="1rem">
        <Typography variant="h6">{label}</Typography>
      </Box>
      <TableContainer data-testid={`order ${label}`}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader>Note</TableCellHeader>
              {!!colFill && <TableCellHeader colSpan={colFill - (!readOnly ? 1 : 0)} />}
              <TableCellHeader>{priceLabel ?? 'Price'}</TableCellHeader>
              {!readOnly && <TableCellHeader className={classes.functional} />}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const ChargeList: React.FC<Props> = ({
  charges,
  transactionFeePct = 0,
  subtotal = 0,
  colFill = 0,
  readOnly = false,
}) => {
  const transactionFee = transactionFeePct * subtotal;

  return (
    <>
      {charges.map((charge) =>
        !readOnly ? (
          <ChargeRowEdit charge={charge} key={charge.id} colFill={colFill} />
        ) : (
          <ChargeRow charge={charge} key={charge.id} colFill={colFill} />
        )
      )}
      {transactionFee > 0 && (
        <ChargeRow
          charge={{
            description: `Transaction Fee (${round(transactionFeePct * 100, 1)}%)`,
            amount: transactionFee,
          }}
          colFill={colFill}
        />
      )}
    </>
  );
};

export default ChargeList;
