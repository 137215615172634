import React from 'react';
import { Typography, Box, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useStyles } from './practitionerDashboard.styles';
import {
  EARNINGS_ADJUSTMENTS,
  EARNINGS_BELOW_PRICE_FLOOR,
  EARNINGS_CALCULATIONS,
  EARNINGS_BEAUTIBANK,
} from '../../../../constants/adjustments.constants';

const Adjustments = ({ showPriceFloorAdjustments }: { showPriceFloorAdjustments?: boolean }) => {
  const classes = useStyles();

  return (
    <div className={classes.area}>
      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.title}>Adjustments</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                {EARNINGS_ADJUSTMENTS}
              </p>
              <p>
                {EARNINGS_CALCULATIONS}
              </p>
              {showPriceFloorAdjustments && (
                <p>
                  {EARNINGS_BELOW_PRICE_FLOOR}
                </p>
              )}
              {showPriceFloorAdjustments && (
                <p>
                  {EARNINGS_BEAUTIBANK}
                </p>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default Adjustments;
