import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    padding: 10,
    fontFamily:'Messina Sans Regular!important',
    backgroundColor: '#FFFFFF'
  }
}));
