import React from 'react';
import { Box } from '@material-ui/core';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';

export const Breadcrumbs = ({ purchaseId }: { purchaseId: number }) => (
  <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Inventory" />
        <BreadcrumbText text="Purchase orders" linkTo="/administrator/purchase-orders" />
        <BreadcrumbText text={purchaseId ? 'Purchase Order' : 'New Purchase Order'} isActive />
      </BreadcrumbsContainer>
    </Box>
  </Box>
);
