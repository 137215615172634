import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { AccountCircle as AccountCircleOutlinedIcon } from '@material-ui/icons';
import { EHRButton } from 'src/components/ui/v1';
import { Practitioners } from 'src/services/Practitioners';
import { dispatch } from '../../../rematch';
import { useRowStyles } from './Practitioner.styles';
import { useProviderMedspaStyles } from './ProviderMedspa.styles';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { hasAccessTo } from '../../../utils/auth.utils';
import { READ_PRACTITIONER_LOGIN } from '../../../constants/actions.constants';

export interface ProviderRowProps {
  profileImageUrl?: string;
  inactive: boolean;
  id: number;
  email: string;
  fullName: string;
  licenses?: string;
  handleEditClick: (id: number) => void;
}

const ProviderRowMedspa: React.FC<ProviderRowProps> = ({
  profileImageUrl,
  inactive,
  id: practitionerId,
  email,
  fullName,
  handleEditClick,
  licenses,
}) => {
  const history = useHistory();
  const classes = useRowStyles();
  const medSpaClasses = useProviderMedspaStyles();

  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const PractitionerBadge = () => {
    const styles = useProviderMedspaStyles();
    const inactiveText = inactive ? 'Inactive' : 'Active';
    const badgeStyle = inactive ? styles.inactiveBadge : styles.activeBadge;

    return <span className={badgeStyle}>{inactiveText}</span>;
  };

  const profileImage = () =>
    profileImageUrl ? (
      <img
        src={profileImageUrl}
        alt="Profile"
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          objectFit: 'cover',
          marginRight: '8px',
        }}
      />
    ) : (
      <AccountCircleOutlinedIcon style={{ fontSize: '45px', marginRight: '4px' }} />
    );

  const masqueradeUser = () => {
    dispatch.masquerade.masqueradeUser({
      id: practitionerId,
      callback: Practitioners.useMasqueradeCallback(history),
    });

    return () => {
      dispatch.masquerade.setReset();
    };
  };

  return (
    <>
      <TableRow data-cy="row" className={classes.root}>
        <TableCellCustom className={medSpaClasses.profileRow} data-cy="fullName">
          {profileImage()}
          <span>{fullName}</span>
          {PractitionerBadge()}
        </TableCellCustom>
        <TableCellCustom>{practitionerId}</TableCellCustom>
        <TableCellCustom data-cy="licenses">{licenses}</TableCellCustom>
        <TableCellCustom data-cy="email" className={classes.email}>
          {email}
        </TableCellCustom>
        <TableCell>
          <Box display="flex" gridGap="1rem">
            {hasAccessTo(READ_PRACTITIONER_LOGIN, permissions) && (
              <EHRButton
                dataCy="loginBtn"
                style={{ width: 'max-content' }}
                variant="outlined"
                onClick={() => masqueradeUser()}
                color="text"
                text="Log In"
              />
            )}
            <EHRButton
              dataCy="editBtn"
              variant="outlined"
              onClick={() => handleEditClick(practitionerId)}
              color="text"
              text="Edit"
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProviderRowMedspa;
