import ROUTES from '../../constants/apiRoutes.constants';
import Api from '../Api';

export const MedspaLocation = {
  async create(data: any): Promise<any> {
    return Api.post(ROUTES.MEDSPA_LOCATIONS, data);
  },
  async update(id: number, data: any): Promise<any> {
    return Api.put(`${ROUTES.MEDSPA_LOCATIONS}/${id}`, data);
  },
  async get(): Promise<any> {
    return Api.get(ROUTES.MEDSPA_LOCATIONS);
  },
  async checkSlug(slug: string, id?: number): Promise<{ available: boolean }> {
    return Api.get(ROUTES.MEDSPA_LOCATION_CHECK_SLUG, { id, slug });
  },
  async byUserGroup(userGroupId: number): Promise<any> {
    return Api.get(`${ROUTES.MEDSPA_LOCATIONS}/by_user_group`, { userGroupId });
  },
};

export interface CalenderUpdateParams {
  calendarId: string;
  practitionerId: number;
  calendar: Record<string, string[]>;
  medspaLocationId: number;
}

export const MedspaLocationPractitioners = {
  async get(practitionerId: number | undefined): Promise<any> {
    if (!practitionerId) {
      return null;
    }
    return Api.get(ROUTES.MEDSPA_LOCATION_PRACTITIONERS, { practitionerId });
  },
  async update(params: any): Promise<any> {
    return Api.put(ROUTES.MEDSPA_LOCATION_PRACTITIONERS_UPDATE(params.id), params);
  },
  async updateWorkingHours(params: CalenderUpdateParams): Promise<any> {
    return Api.put(ROUTES.MEDSPA_LOCATION_PRACTITIONERS_UPDATE_WORKING_HOURS, params);
  },
};

export const MedspaAdminPaymentMethods = {
  async setDefaultCC(userGroupId: number | undefined | null, cardId: string): Promise<any> {
    if (!userGroupId) {
      return null;
    }
    return Api.put(ROUTES.MEDSPA_PAYMENT_METHOD_SET_DEFAULT_CC(userGroupId), { cardId });
  },
  async delete(userGroupId: number | undefined | null, cardId: string): Promise<any> {
    if (!userGroupId) {
      return null;
    }
    return Api.delete(`${ROUTES.MEDSPA_PAYMENT_METHOD_DELETE_CC(userGroupId)}/${cardId}`);
  },
};
