import Api from './Api';
import apiRoutesConstants from '../constants/apiRoutes.constants';

export const sendResetPasswordEmail = (data: { email: string; redirectUrl: string }) =>
  Api.post(apiRoutesConstants.SEND_RESET_PASSWORD_EMAIL, data);

export const resetPassword = (data: { password: string; token: string }) =>
  Api.post(apiRoutesConstants.RESET_PASSWORD, data);

export const setPassword = (data: { password: string; token: string }) =>
  Api.post(apiRoutesConstants.PRACTITIONER_SET_PASSWORD, data);
