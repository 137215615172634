import React, { useState } from 'react';
import { Box, Button, makeStyles, MenuItem, Menu, TableCell } from '@material-ui/core';
import classnames from 'classnames';
import { ArrowDropDown as ArrowDropDownIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: any) => ({
  mainColor: {
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: '14px',
    fontFamily: 'Messina Sans Bold',
  },
  subtitle: {
    fontSize: '18px',
    fontFamily: 'Messina Sans Light',
  },
}));

export const PractitionerCell = (props: any) => {
  const { providerLabeling, handleHideRow, handleShowRow, isHide } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onShow = () => {
    handleShowRow();
    handleClose();
  };

  const onHide = () => {
    handleHideRow();
    handleClose();
  };

  return (
    <TableCell style={{ verticalAlign: 'top', padding: '4px' }}>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        {isHide ? (
          <Box pr={2}>{isHide && <VisibilityOffIcon data-cy="hiddenPractIcon" htmlColor="#A6A6A6" />}</Box>
        ) : (
          <Box pr={5} />
        )}
        <Box>
          <div data-cy="providerName" className={classnames(classes.title, classes.mainColor)}>
            {providerLabeling}
          </div>
          <div className={classnames(classes.subtitle)}>
            {/* {formatCurrency({ value: money })} // total in $ is for next version */}
          </div>
        </Box>
        <Box ml="auto">
          <Button
            data-cy="practOptionMenu"
            variant="text"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <ArrowDropDownIcon />
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {isHide ? (
              <MenuItem data-cy="showPract" onClick={() => onShow()}>
                Show row
              </MenuItem>
            ) : (
              <MenuItem data-cy="hidePract" onClick={() => onHide()}>
                Hide row
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
    </TableCell>
  );
};
