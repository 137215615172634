import API_ROUTES from 'src/constants/apiRoutes.constants';
import Api from './Api';

export type InventoryOrderCustomItem = {
  id: number;
  inventoryOrderId: number;
  supplierId: number;
  supplierName: string;
  name: string;
  notes: string | null;
  adminNote: string | null;
  quantity: number;
  price: number;
  subtotal: number;
  approved: boolean;
  createdAt: string;
  updatedAt: string;
};
export default {
  async create(params: Partial<InventoryOrderCustomItem>): Promise<InventoryOrderCustomItem> {
    const { data }: { data: InventoryOrderCustomItem } = await Api.post(
      API_ROUTES.INVENTORY_ORDER_CUSTOM_ITEMS(),
      params
    );
    return data;
  },

  async destroy(id: number): Promise<InventoryOrderCustomItem> {
    const { data }: { data: InventoryOrderCustomItem } = await Api.delete(API_ROUTES.INVENTORY_ORDER_CUSTOM_ITEMS(id));
    return data;
  },

  async update({
    id,
    ...params
  }: Partial<InventoryOrderCustomItem> & { id: number }): Promise<InventoryOrderCustomItem> {
    const { data }: { data: InventoryOrderCustomItem } = await Api.put(
      API_ROUTES.INVENTORY_ORDER_CUSTOM_ITEMS(id),
      params
    );
    return data;
  },
};
