import React from 'react';
import { Modal } from '@material-ui/core';
import { Button } from '../../../components/common/Button';
import { useStyles } from './CreditCardModal.styles';
import { ReactComponent as CardIcon } from '../../../assets/images/card-icon.svg';

export const ConfirmCreditCardActionModal = ({
  open,
  handleCloseCallback,
  confirmAction,
}: {
  open: boolean;
  handleCloseCallback: () => void;
  confirmAction: string;
}) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={handleCloseCallback}>
      <div className={classes.modalContent}>
        <CardIcon width="60px" />
        <div className={classes.modalTitle}>Card details {confirmAction} successfully</div>
        <div>
          <Button className={classes.doneButton} title="Done" onClick={handleCloseCallback} />
        </div>
      </div>
    </Modal>
  );
};
