import { useQuery } from 'react-query';
import { PATIENT_PHOTOS } from '../../constants/reactQuery.keys';
import CustomerService from '../../services/Customers';
import Id from '../../types/Id';

const { getPhotos } = CustomerService;

export function useCustomerHistoricalPhotos(customerId: Id) {
  return useQuery([PATIENT_PHOTOS, customerId], () => getPhotos(customerId));
}
