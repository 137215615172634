import React, { useEffect, useState } from 'react';
import { Avatar, Box, FormHelperText, makeStyles, TextareaAutosize } from '@material-ui/core';
import {
  EHRButton,
  EHRCRUDModal,
  EHRDatetimeStartEndRangeSelector,
  EHRTextField,
  EHRTypography,
  IDatetimeRange,
} from 'src/components/ui/v1';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { useCreateMedspaBlockOffMutation, useUpdateMedspaBlockOffMutation } from 'src/hooks/medspaCalendar/useBlockOff';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ACUITY_AVAILABILITY_START } from 'src/constants/schemaForm.constants';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { ExtendedAppointmentModel } from './grouping';

const blockOffHoursSchema = yup.object().shape({
  start: yup.string().required(ACUITY_AVAILABILITY_START),
  hourStart: yup.string().required('Start time is required'),
  hourEnd: yup.string().required('End time is required'),
  notes: yup.string(),
});

const useStyles = makeStyles(() => ({
  blockOffCRUDModal: {},
}));

interface BlockOffCRUDModalProps {
  appointmentData: ExtendedAppointmentModel;
  open: boolean;
  onClose: () => void;
  isNewAppointment: boolean;
  selectedMedspaLocation?: IMedspaLocation;
  providersMap: { [ket: number]: IPractitioner };
}

const BlockOffCRUDModal = (props: BlockOffCRUDModalProps) => {
  const classes = useStyles();
  const { appointmentData, open, onClose, isNewAppointment, selectedMedspaLocation, providersMap } = props;
  const { userGroupId } = useSelector(({ auth }: RootState) => auth);
  if (!userGroupId) {
    return <Box>No MedspaId </Box>;
  }
  const [blockOffDateRange] = useState<IDatetimeRange>({
    start: new Date(appointmentData.startDate.toString()),
    end: new Date(appointmentData.endDate.toString()),
  });

  const { mutate: mutateCreateBlockOffHour, isLoading: isCreatingBlockOffHour } = useCreateMedspaBlockOffMutation(
    userGroupId,
    () => onClose()
  );

  const { mutate: mutateUpdateBlockOffHour, isLoading: isUpdatingBlockOffHour } = useUpdateMedspaBlockOffMutation(
    userGroupId,
    appointmentData.id as number,
    () => onClose()
  );

  const isMutating = isCreatingBlockOffHour || isUpdatingBlockOffHour;

  const blockOffHoursDefaultValues = {
    start: moment(blockOffDateRange.start).format('YYYY-MM-DD'),
    hourStart: moment(blockOffDateRange.start).format('HH:mm'),
    hourEnd: moment(blockOffDateRange.end).format('HH:mm'),
    notes: '',
  };

  const { handleSubmit, errors, setValue, watch } = useForm({
    resolver: yupResolver(blockOffHoursSchema),
    defaultValues: blockOffHoursDefaultValues,
  });

  const watchNotes = watch('notes');

  useEffect(() => {
    if (appointmentData) {
      setValue('notes', appointmentData.notes || '');
    }
  }, [appointmentData]);

  const onSubmit = (data: any) => {
    const { years, months, date } = moment(data.start).toObject();
    const { hours: startHours, minutes: startMinutes } = moment(data.hourStart).toObject();
    const { hours: endHours, minutes: endMinutes } = moment(data.hourEnd).toObject();
    const start = moment({ years, months, date, hours: startHours, minutes: startMinutes }).format('YYYY-MM-DDTHH:mm');
    const end = moment({ years, months, date, hours: endHours, minutes: endMinutes }).format('YYYY-MM-DDTHH:mm');

    const mutationParams = {
      start,
      end,
      notes: data.notes,
      medspaLocationId: selectedMedspaLocation?.id,
      practitionerId: appointmentData.practitionerId,
    };

    if (isNewAppointment) {
      mutateCreateBlockOffHour(mutationParams);
    } else {
      mutateUpdateBlockOffHour(mutationParams);
    }
  };

  const provider = providersMap[appointmentData.practitionerId];

  return (
    <EHRCRUDModal dataCy="modal-new-block-off" open={open} onClose={onClose} className={classes.blockOffCRUDModal}>
      <EHRCRUDModal.Title
        dataCy="modal-new-block-off-modal-title"
        title={`${isNewAppointment ? 'New' : 'Edit'} block off`}
        handleClose={onClose}
      />

      <EHRCRUDModal.Body dataCy="modal-new-block-off-modal-body">
        <Box width="100%">
          <Box>
            <EHRTextField
              dataCy="input-medspaLocation"
              label="Location"
              value={[
                selectedMedspaLocation?.name,
                selectedMedspaLocation?.address,
                selectedMedspaLocation?.city,
                selectedMedspaLocation?.state,
                selectedMedspaLocation?.zipCode,
              ].join(', ')}
              helperText=" "
              readOnly
            />
          </Box>

          <Box display="flex" gridGap="1rem" flexDirection="column">
            <Box>
              <EHRTypography dataCy="label-provider" variant="label">
                Provider
              </EHRTypography>
            </Box>
            <Box>
              <Box display="flex" flexDirection="row" alignItems="center" gridGap="1rem">
                <Box>
                  <Avatar src={provider.profileImageUrl} />
                </Box>
                <Box>
                  {provider.firstName} {provider.lastName}
                </Box>
              </Box>
              <FormHelperText error>&nbsp;</FormHelperText>
            </Box>
          </Box>

          <Box marginBottom="1rem">
            <EHRDatetimeStartEndRangeSelector
              dataCy="input-datetimeStartEndRange"
              label="Date & Time"
              defaultDateRange={blockOffDateRange}
              onChange={({ start, end }) => {
                setValue('start', moment(start).format('YYYY-MM-DD'));
                setValue('hourStart', start.toISOString());
                setValue('hourEnd', end?.toISOString() || undefined);
              }}
              dateSelectorDisabled
              dateSelectorError={!!errors.start}
              dateSelectorHelperText={errors.start?.message || ' '}
              startTimeSelectorError={!!errors.hourStart}
              startTimeSelectorHelperText={errors.hourStart?.message || ' '}
              endTimeSelectorError={!!errors.hourEnd}
              endTimeSelectorHelperText={errors.hourEnd?.message || ' '}
            />
          </Box>

          <Box>
            <Box>
              <EHRTypography
                dataCy="label-for-notes"
                id="labelNotes"
                variant="label"
                style={{ marginBottom: '0.5rem' }}
              >
                Notes
              </EHRTypography>
              <TextareaAutosize
                data-cy="input-notes"
                name="notes"
                value={watchNotes}
                onChange={(e) => setValue('notes', e.target.value)}
                style={{ width: '100%', height: '200px', resize: 'none', fontFamily: 'inherit' }}
              />
              <FormHelperText error> </FormHelperText>
            </Box>
          </Box>
        </Box>
      </EHRCRUDModal.Body>
      <EHRCRUDModal.Footer dataCy="modal-new-block-off-modal-footer">
        <EHRButton
          dataCy="btn-schedule"
          color="primary"
          text="Schedule block off"
          onClick={handleSubmit(onSubmit)}
          fullWidth
          disabled={isMutating}
        />
      </EHRCRUDModal.Footer>
    </EHRCRUDModal>
  );
};

export default BlockOffCRUDModal;
