import React, { useEffect, useState } from 'react';
import { CardContent, makeStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import TableActivityLog from '../ActivityLog/TableActivityLog';
import { Card, SmallTitle } from '../common/card';
import { useActivityLogs } from '../../hooks/queries/useActivityLogs';
import { ACTIVITY_LOGS_PER_PAGE } from '../../constants/general.constants';

type propsType = {
  patientId: string;
};

const useStyles = makeStyles({
  btnMore: {
    backgroundColor: 'transparent !important',
    border: 'none !important',
    fontSize: '1rem',
    margin: '1rem 0',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  bottomBtn: {
    cursor: 'pointer',
    width: '100%',
    textAlign: 'right',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    margin: '20px 0',
  },
  imgArrow: {
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
});

const ActivityLogContainer = ({ patientId }: propsType) => {
  const classes = useStyles();
  const [showAllFetchedRows, setShowAllFetchedRows] = useState(true);
  const [totalLogs, setTotalLogs] = useState(0);

  const {
    data: activityLogs,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useActivityLogs(patientId);

  let limit;
  if (!isLoading) {
    limit = showAllFetchedRows ? activityLogs?.pages.length : 1;
  }

  useEffect(() => {
    /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
    activityLogs?.pages && setTotalLogs(activityLogs?.pages.flat().length);
  }, [activityLogs]);

  const renderViewMoreLessButton = () => {
    if (hasNextPage) {
      return (
        <button className={classes.btnMore} type="button" disabled={!hasNextPage} onClick={() => fetchNextPage()}>
          View more
          <ExpandMoreIcon width={12} height={12} className={classes.imgArrow} />
        </button>
      );
    }

    if (!hasNextPage && !showAllFetchedRows && totalLogs > ACTIVITY_LOGS_PER_PAGE) {
      return (
        <button className={classes.btnMore} type="button" onClick={() => setShowAllFetchedRows(true)}>
          View more {`(${totalLogs - ACTIVITY_LOGS_PER_PAGE})`}
          <ExpandMoreIcon width={12} height={12} className={classes.imgArrow} />
        </button>
      );
    }

    if (showAllFetchedRows && totalLogs > ACTIVITY_LOGS_PER_PAGE) {
      return (
        <button className={classes.btnMore} type="button" onClick={() => setShowAllFetchedRows(false)}>
          View less
          <ExpandLessIcon width={12} height={12} className={classes.imgArrow} />
        </button>
      );
    }

    return null;
  };

  return (
    <Card>
      <CardContent>
        <SmallTitle title="Activity Log" style={{ marginTop: '0', marginBottom: '18px' }} />
        {totalLogs > 0 ? (
          <>
            <TableActivityLog activityLogs={activityLogs?.pages.slice(0, limit)} />
            {totalLogs >= ACTIVITY_LOGS_PER_PAGE && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className={classes.bottomBtn}>{renderViewMoreLessButton()}</div>
              </div>
            )}
            <div>{isFetching && !isFetchingNextPage && 'Fetching...'}</div>
          </>
        ) : (
          <p>There are no activity logs available.</p>
        )}
      </CardContent>
    </Card>
  );
};

export default ActivityLogContainer;
