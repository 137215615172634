import * as yup from 'yup';
import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  VALID_EMAIL,
  PASSWORD,
  PROFESSIONAL_NAME,
  SIGNATURE_IMAGE,
  MEDICAL_MEMBER,
} from '../../../constants/schemaForm.constants';

export const schemaForm = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  password: yup.string().required(PASSWORD),
  professionalName: yup.string().required(PROFESSIONAL_NAME),
  signatureImage: yup.string().required(SIGNATURE_IMAGE),
  medicalMemberId: yup.string().required(MEDICAL_MEMBER),
});

export const schemaFormEdit = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  professionalName: yup.string().required(PROFESSIONAL_NAME),
  medicalMemberId: yup.string().required(MEDICAL_MEMBER),
});
