/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { MARKET_PRICE_TOOLTIP, PRICE_TOOLTIP, YOU_SAVE_TOOLTIP } from 'src/constants/inventory.constants';
import { TableCellHeader, useStyle } from './inventoryOrder.styles';
import OrderingTooltip from './OrderingTooltip';

type Props = {
  editMode?: boolean;
  isLegacy: boolean;
  noSavings?: boolean;
};

const OrderHeaderCells = ({ editMode = false, noSavings = false, isLegacy }: Props) => {
  const classes = useStyle();

  const savingsHeaderCell = noSavings
    ? []
    : [
        {
          className: classes.smaller,
          content: (
            <div className={classes.toolTipContainer}>
              You save
              <OrderingTooltip title={YOU_SAVE_TOOLTIP} />
            </div>
          ),
        },
      ];

  const cells = isLegacy
    ? [
        {
          className: '',
          content: 'Product Name',
        },
        {
          className: classes.smaller,
          content: 'Current Stock',
        },
        {
          className: classes.smaller,
          content: 'Items per Box',
        },
        {
          className: classes.smaller,
          content: 'Boxes',
        },
        {
          className: classes.smaller,
          content: 'Recommended',
        },
        {
          className: classes.centerCell,
          content: 'Price per Box',
        },
      ]
    : [
        {
          className: '',
          content: 'Product Name',
        },
        {
          className: '',
          content: 'Description',
        },
        {
          className: classes.smaller,
          content: (
            <div className={classes.toolTipContainer}>
              Market price
              <OrderingTooltip title={MARKET_PRICE_TOOLTIP} />
            </div>
          ),
        },
        ...savingsHeaderCell,
        {
          className: classes.centerCell,
          content: (
            <div className={classes.toolTipContainer}>
              You pay
              <OrderingTooltip title={PRICE_TOOLTIP} />
            </div>
          ),
        },
      ];

  return (
    <>
      {cells.map((cell) => (
        <TableCellHeader data-large="true" className={cell.className}>
          {cell.content}
        </TableCellHeader>
      ))}

      {editMode && (
        <>
          <TableCellHeader data-large="true" className={classes.smaller}>
            Qty
          </TableCellHeader>
          <TableCellHeader data-large="true" className={classes.smaller}>
            Total
          </TableCellHeader>
        </>
      )}
    </>
  );
};

export { OrderHeaderCells, OrderHeaderCells as default };
