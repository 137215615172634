/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';

import classnames from 'classnames';
import compile from '../../../utils/toastMessagesCompiler';
import { dispatch } from '../../../rematch';

const SaveText = (purchase: any) => (purchase && purchase?.status === 'waiting_to_receive' ? 'SAVE' : 'SAVE DRAFT');
const FinalText = (purchase: any) =>
  purchase && purchase?.status === 'waiting_to_receive' ? 'SAVE AND FINALIZE' : 'WAIT TO RECEIVE';

const useStyles = makeStyles(() => ({
  button: {
    height: '47px',
  },
  deleteButton: {
    width: '227px',
    backgroundColor: '#fbdada',
    color: '#000000',
    marginRight: 'auto',
  },
  saveButton: {
    width: '175px',
    marginRight: '20px',
    color: '#12574d',
  },
  doneButton: {
    width: '175px',
  },
}));

export const PurchaseOrderButtonsSection = ({
  purchaseId,
  canFinalize,
  referenceNumber,
  internalNote,
  orderType,
}: any) => {
  const history = useHistory();
  const classes = useStyles();
  const { productsSelected, purchase } = useSelector(({ newPurchaseOrder }: any) => newPurchaseOrder);
  const {
    effects: {
      newPurchaseOrder: {
        saveDraft: isLoadingSaveDraft,
        updateStatus: isLoadingUpdateStatus,
        updatePurchaseComplete: isLoadingUpdatePurchaseComplete,
      },
    },
  } = useSelector(({ loading }: any) => loading);
  const isComplete = purchase && purchase.status === 'complete';

  const disableDoneButton =
    !productsSelected.length ||
    isComplete ||
    isLoadingUpdateStatus ||
    isLoadingUpdatePurchaseComplete ||
    (!canFinalize && purchase?.status === 'waiting_to_receive');

  const navigateToBack = () => {
    dispatch.newPurchaseOrder.deleteDraft({
      id: purchaseId,
      callback: () => history.push('/administrator/purchase-orders'),
    });
  };

  const saveDraft = async (redirect = true) => {
    const callback = (id: number) => history.replace(`/administrator/new-purchase-order/${id}`);
    return dispatch.newPurchaseOrder.saveDraft({
      purchaseId,
      referenceNumber,
      internalNote,
      orderType,
      callback: redirect ? callback : () => {},
    });
  };

  const navigateToList = (status: string) => {
    switch (status) {
      case 'draft':
        dispatch.newPurchaseOrder.updateStatus({
          id: purchaseId,
          status: 'waiting_to_receive',
          callback: () => history.push('/administrator/purchase-orders'),
        });
        break;
      case 'waiting_to_receive':
        dispatch.newPurchaseOrder.updatePurchaseComplete({
          purchase_id: purchaseId,
          status: 'complete',
          callback: () => history.replace(`/administrator/new-purchase-order/${purchaseId}`),
        });
        break;
      default:
        history.push('/administrator/purchase-orders');
        break;
    }
  };

  const save = async () => {
    const purchaseItemsAreInvalid = productsSelected.some((item: any) => !item.isSample && +item.cost === 0);
    if (purchaseItemsAreInvalid) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('purchase_order.invalid_item'),
          type: 'error',
        },
      });
      return;
    }
    await saveDraft(false);
    navigateToList(purchase.status);
  };

  return (
    <Box display="flex" mt="auto" p={2} border="1px solid #E7E7E7">
      {!isComplete && (
        <Button
          data-cy="deletePurchaseOrderBtn"
          onClick={navigateToBack}
          className={classnames(classes.button, classes.deleteButton)}
        >
          DELETE PURCHASE ORDER
        </Button>
      )}
      {!isComplete && (
        <Button
          data-cy="save"
          onClick={() => saveDraft()}
          disabled={!productsSelected.length || isLoadingSaveDraft}
          variant="outlined"
          className={classnames(classes.button, classes.saveButton)}
        >
          {isLoadingSaveDraft ? <CircularProgress size={25} /> : SaveText(purchase)}
        </Button>
      )}

      {purchase?.status && (
        <Button
          data-cy="changeStatusBtn"
          onClick={save}
          disabled={disableDoneButton}
          color="primary"
          variant="contained"
          disableElevation
          className={classnames(classes.button, classes.doneButton)}
        >
          {isLoadingUpdateStatus || isLoadingUpdatePurchaseComplete ? (
            <CircularProgress size={25} color="secondary" />
          ) : (
            FinalText(purchase)
          )}
        </Button>
      )}
    </Box>
  );
};
