import Api from './Api';

const ServiceAcknowledgments = {
  async getServiceAcknowledgments() {
    const { serviceAcknowledgments, error } = await Api.get('/service_acknowledgments');
    if (error) {
      throw error;
    }
    return serviceAcknowledgments;
  },

  async acceptedServiceAcknowledgmentActivityLog({
    patientId,
    serviceId,
    serviceVisitId,
  }: {
    patientId: string;
    serviceId: number;
    serviceVisitId: number;
  }): Promise<{ success: boolean }> {
    const { success, error } = await Api.post(`/service_acknowledgments/${patientId}/record_service`, {
      serviceId,
      serviceVisitId,
    });
    if (error) {
      throw error;
    }
    return success;
  },
};
export default ServiceAcknowledgments;
