import React, { FC, useState } from 'react';
import papa from 'papaparse';
import { Box, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Page from 'src/components/common/Page';
import BreadcrumbsContainer from '../../components/common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../components/common/Breadcrumb/BreadcrumbText';
import { useStyles } from './bulkUpload.styles';
import { PRACTITIONER_ROLE } from '../../constants/general.constants';
import PatientDataTable, { IPatientData } from './PatientDataTable';
import { useBulkUpload } from '../../hooks/mutations/useBulkUpload';
import { dispatch } from '../../rematch';
import compile from '../../utils/toastMessagesCompiler';
import { BULK_UPLOAD_TYPES, REQUIRED_FIELDS } from '../../constants/bulkUpload.constants';
import { ADMIN_BASE_PATH } from '../../routes/administratorRoutes';
import { PRACTITIONER_HOME_PATH } from '../../routes/practitionerRoutes';

export type FileType = {
  name: string;
  type: string;
  fields: { name: string; displayName: string }[];
};

const BulkUpload: FC = () => {
  const classes = useStyles();
  const { userType } = useSelector(({ auth }: any) => auth);
  const [data, setData] = useState<IPatientData[]>([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<FileType>();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const buklUploadMutatition = useBulkUpload();

  const fileTypes: FileType[] = [
    {
      name: 'Patient Profiles',
      type: BULK_UPLOAD_TYPES.PATIENT_PROFILES,
      fields: [
        { name: 'firstName', displayName: 'First Name' },
        { name: 'lastName', displayName: 'Last Name' },
        { name: 'email', displayName: 'Email' },
        { name: 'phone', displayName: 'Phone' },
        { name: 'physician', displayName: 'Physician' },
        { name: 'practitioner', displayName: 'Practitioner' },
        { name: 'address', displayName: 'Address' },
        { name: 'birthdate', displayName: 'Birthdate' },
      ],
    },
    {
      name: 'Medical Profiles',
      type: BULK_UPLOAD_TYPES.MEDICAL_PROFILES,
      fields: [
        { name: 'email', displayName: 'Email' },
        { name: 'biologicalSex', displayName: 'Biological sex' },
        { name: 'knownAllergies', displayName: 'Known allergies' },
        { name: 'medicationAllergies', displayName: 'Medication allergies' },
        { name: 'currentOralMedication', displayName: 'Current oral medication' },
        { name: 'historicalOralMedication', displayName: 'Historical oral medication' },
        { name: 'currentTopicalMedication', displayName: 'Current topical medication' },
        { name: 'historicalTopicalMedication', displayName: 'Historical topical medication' },
        { name: 'medicalCondition', displayName: 'Medical condition' },
        { name: 'previousProcedures', displayName: 'Previous procedures' },
        { name: 'previousConditions', displayName: 'Previous conditions' },
        { name: 'extraDetails', displayName: 'Extra details' },
        { name: 'patientPhoto', displayName: 'Patient Photo' },
      ],
    },
    {
      name: 'Skin Profiles',
      type: BULK_UPLOAD_TYPES.SKIN_PROFILES,
      fields: [
        { name: 'email', displayName: 'Email' },
        { name: 'skinConcerns', displayName: 'Skin concerns' },
        { name: 'serviceImprovements', displayName: 'Service improvements' },
        { name: 'skinType', displayName: 'Skin type' },
        { name: 'sunscreenFrequency', displayName: 'Sunscreen frequency' },
        { name: 'retinalRetinA', displayName: 'Retinal/Retin-A' },
        { name: 'usingPeels', displayName: 'Using peels' },
        { name: 'goalsNotes', displayName: 'Goals/Notes' },
      ],
    },
  ];

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
    const { files } = event.target;
    if (files) {
      const file = files[0];
      const selectedFileTypeData = fileTypes.find(({ type }) => type === fileType);

      if (selectedFileTypeData) {
        setSelectedFileType(selectedFileTypeData);
        papa.parse(file, {
          complete: (results: any) => {
            const requiredFields = selectedFileTypeData.fields;

            const cleanedData = results.data.filter(
              (entry: any) =>
                !requiredFields.every((field) => {
                  const value = entry[field.name] || entry[field.displayName];
                  return value === '' || value === null;
                })
            );

            setData(cleanedData);

            const missingFields = requiredFields.filter(
              (field) =>
                !(
                  results.meta.fields.includes(field.name) ||
                  (field.displayName && results.meta.fields.includes(field.displayName))
                )
            );

            if (missingFields.length > 0) {
              setErrorMessage(
                `${REQUIRED_FIELDS} ${missingFields.map((field) => field.displayName || field.name).join(', ')}`
              );
              setOpenErrorDialog(true);
            }
          },
          header: true,
          skipEmptyLines: true,
        });
      }
    }
  };

  const uploadData = async () => {
    try {
      setDisabledButton(true);
      await buklUploadMutatition.mutateAsync({
        dataType: selectedFileType?.type,
        data,
      });

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: `${selectedFileType?.name}`,
            action: 'uploaded',
          }),
        },
      });
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'uploading',
            element: `the ${selectedFileType?.name}`,
          }),
          type: 'error',
        },
      });
    } finally {
      setDisabledButton(false);
    }
  };

  const MyBreadcrumb = () => (
    <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BreadcrumbsContainer>
          <BreadcrumbText
            text="Patients"
            linkTo={`${userType !== PRACTITIONER_ROLE ? ADMIN_BASE_PATH : PRACTITIONER_HOME_PATH}`}
          />
          <BreadcrumbText text="Bulk Upload" isActive />
        </BreadcrumbsContainer>
      </Box>
    </Box>
  );

  return (
    <Page title="Bulk Upload">
      <MyBreadcrumb />
      <Box width="100%" display="flex" height="100%" flexDirection="column">
        <Box paddingX={2}>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Box>
              <Typography className={classes.title}>Bulk {selectedFileType && selectedFileType.name} Upload</Typography>
            </Box>
          </Box>
          <Box>
            {data && selectedFileType && data.length > 0 ? (
              <div>
                <PatientDataTable data={data} setData={setData} selectedFileType={selectedFileType} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Button variant="contained" color="primary" component="span" onClick={() => setData([])}>
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => uploadData()}
                    disabled={disabledButton}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                {fileTypes.map((fileType) => (
                  <label key={fileType.type} htmlFor={`file-input-${fileType.type}`}>
                    <input
                      type="file"
                      accept=".csv"
                      style={{ display: 'none' }}
                      id={`file-input-${fileType.type}`}
                      onChange={(event) => handleFileUpload(event, fileType.type)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ width: '200px', margin: '10px 0' }}
                    >
                      {fileType.name} CSV
                    </Button>
                  </label>
                ))}
              </div>
            )}
          </Box>
        </Box>

        <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>{errorMessage}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenErrorDialog(false);
                setData([]);
              }}
              variant="contained"
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Page>
  );
};

export default BulkUpload;
