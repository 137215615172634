import { useQuery } from 'react-query';
import { DEFAULT_DIAGNOSES } from '../../constants/reactQuery.keys';
import Customers from '../../services/Customers';

export function useDefaultDiagnoses() {
  return useQuery([DEFAULT_DIAGNOSES], Customers.getDefaultDiagnoses, {
    initialData: [],
    keepPreviousData: true,
  });
}
