import React, { FormEvent, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import compile from '../../utils/toastMessagesCompiler';

import { NavigationBar } from '../PatientProfile/NavigationBar';
import { dispatch } from '../../rematch';
import { ROUTES } from '../../constants/routes.constants';

import AddProgressNote from './AddProgressNote';
import ProgressNotesList from './ProgressNotesList';
import { useStyles } from './index.styles';
import { AuthState } from '../../types/AuthState';
import { IProgressNotephoto } from '../../types/ProgressNoteTypes';
import { useCreateProgressNote } from '../../hooks/mutations/useCreateProgressNote';
import { useUploadProgressNotePhoto } from '../../hooks/mutations/useUploadProgressNotePhotos';
import { useProgressNotes } from '../../hooks/queries/useProgressNotes';
import { PRACTITIONER_ROLE } from '../../constants/general.constants';
import { compressImage } from '../../utils/image.utils';
import { IPhoto } from '../../interfaces/global.interfaces';
import { PROGRESS_NOTES } from '../../constants/reactQuery.keys';

const ProgressNote = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const userType = useSelector(({ auth }: { auth: AuthState }) => auth.userType);
  const { patientId }: { patientId: string } = useParams();
  const patientIdtoInt = parseInt(patientId, 10);
  const { data: progressNotes, isLoading } = useProgressNotes(patientIdtoInt);
  const createProgressNoteMutation = useCreateProgressNote();
  const uploadProgressNotePhotoMutation = useUploadProgressNotePhoto();

  const [noteValue, setNoteValue] = useState<string>('');
  const [photosList, setPhotosList] = useState<Blob[]>([]);
  const [tempPhotoList, setTempPhotoList] = useState<IProgressNotephoto[]>([]);
  const [uploadedPhotos, setUploadedPhotos] = useState<IPhoto[]>([]);
  const [noteMutationInProgress, setNoteMutationInProgress] = useState<boolean>(false);
  const progressListRef = useRef<HTMLUListElement | null>(null);

  const navigateBack = () => {
    history.push(ROUTES.PATIENT_ID(patientId));
  };

  useEffect(() => {
    if (progressListRef.current && !createProgressNoteMutation.isLoading) {
      const progressList = Array.from(progressListRef.current.children);
      const { lastChild } = progressListRef.current;
      const listChildrenLenght = progressList.reduce((prev, current) => {
        if (current instanceof HTMLLIElement) {
          return Math.max(current.offsetHeight, current.scrollHeight) + prev;
        }
        return 0;
      }, 0);
      if (listChildrenLenght > progressListRef.current.scrollHeight) {
        // eslint-disable-next-line no-param-reassign
        progressListRef.current.style.placeContent = 'flex-start';
        if (lastChild instanceof HTMLLIElement) {
          lastChild.scrollIntoView();
        }
      }
    }
  }, [progressNotes, noteMutationInProgress]);

  const uploadProgressNotePhotosRequest = () => {
    if (photosList) {
      photosList.forEach((photo) => {
        compressImage({
          file: photo,
          onSuccess: async (imageCompressed) => {
            const formData = new FormData();
            formData.append('images[]', imageCompressed);
            try {
              const photoRes = await uploadProgressNotePhotoMutation.mutateAsync({
                patientId: patientIdtoInt,
                formData,
              });
              setUploadedPhotos((prev) => [...prev, photoRes[0]]);
            } catch (error) {
              dispatch({
                type: 'snackbar/enqueueSnackBar',
                payload: {
                  message: compile('generic.error_message', {
                    action: 'uploading',
                    element: 'your photo',
                  }),
                  type: 'error',
                },
              });
            }
          },
          onError: (error: any) => {
            Sentry.captureMessage(error.message, 'debug' as Sentry.Severity);
            dispatch({
              type: 'snackbar/enqueueSnackBar',
              payload: {
                message: compile('generic.error_message', {
                  action: 'compressing',
                  element: 'the image',
                }),
                type: 'error',
              },
            });
          },
        });
      });
    }
  };

  const newProgressNote = async () => {
    try {
      await createProgressNoteMutation.mutateAsync({
        patientId: patientIdtoInt,
        text: noteValue,
        photosIds: uploadedPhotos.map((photo) => photo.id),
      });
      queryClient.invalidateQueries([PROGRESS_NOTES, patientIdtoInt]);
      setNoteValue('');
      setTempPhotoList([]);
      setPhotosList([]);
      setUploadedPhotos([]);
      setNoteMutationInProgress(false);
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'creating',
            element: 'your progress note',
          }),
          type: 'error',
        },
      });
    }
  };

  useEffect(() => {
    if (photosList.length > 0 && photosList.length === uploadedPhotos.length) {
      newProgressNote();
    }
  }, [uploadedPhotos]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setNoteMutationInProgress(true);
    if (photosList && photosList.length > 0) {
      uploadProgressNotePhotosRequest();
    } else {
      newProgressNote();
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.navigationContainer}>
        <NavigationBar title="Progress note" onBackButtonClick={navigateBack} style={{ height: '100%' }} />
      </div>
      <div className={classes.progressNotesList}>
        <ProgressNotesList progressNotes={progressNotes} progressListRef={progressListRef} isLoading={isLoading} />
      </div>
      <div>
        {userType === PRACTITIONER_ROLE ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            <AddProgressNote
              value={noteValue}
              onChange={setNoteValue}
              setPhotosList={setPhotosList}
              tempPhotoList={tempPhotoList}
              setTempPhotoList={setTempPhotoList}
              noteMutationInProgress={noteMutationInProgress}
            />
          </form>
        ) : null}
      </div>
    </div>
  );
};

export default ProgressNote;
