import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';
import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';
import { EHRButton, EHRModal } from 'src/components/ui/v1';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { IAppointmentType } from 'src/interfaces/acuity/acuity.interface';
import moment from 'moment';
import { SchedulerDateTime } from '@devexpress/dx-react-scheduler';
import AppointmentCRUDModal from './AppointmentCRUDModal';
import BlockOffCRUDModal from './BlockOffCRUDModal';

import { BookingTypeEnum } from './EnumBookingType';
import { capDatetimeSeconds, isWithinAWorkingHour } from './medspaCalendarUtils';
import { ExtendedAppointmentModel } from './grouping';
import { VIEW_NAME_WEEK } from '../indexV2';

const useStyles = makeStyles(() => ({
  MedspaCaledarAppointmentForm: {},
}));

interface MedspaCaledarAppointmentFormProps {
  providersMap: { [key: number]: IPractitioner };
  workingHoursMap: { [key: number]: IWorkingHour[] };
  selectedMedspaLocation?: IMedspaLocation;
  appointmentTypes: IAppointmentType[];
  view: string;
}

const MedspaCaledarAppointmentForm = (props: MedspaCaledarAppointmentFormProps) => {
  const { providersMap, workingHoursMap, selectedMedspaLocation, appointmentTypes, view } = props;
  const classes = useStyles();
  const [appointmentFormOpened, setAppointmentFormOpened] = useState(false);
  const [selectedBookingType, setSelectedBookingType] = useState<BookingTypeEnum | null>(null);
  const closeAppointmentModal = () => {
    setSelectedBookingType(null);
    setAppointmentFormOpened(false);
  };

  return (
    <AppointmentForm
      readOnly
      visible={appointmentFormOpened}
      onVisibilityChange={(visible) => {
        if (!visible) {
          setSelectedBookingType(null);
        }
        setAppointmentFormOpened(visible);
      }}
      overlayComponent={(overlayProps) => (
        <Box className={classes.MedspaCaledarAppointmentForm}>{overlayProps.children}</Box>
      )}
      layoutComponent={(layoutComponentProps) => {
        const { basicLayoutComponent } = layoutComponentProps;
        return (
          // Removes default close button
          <Box>{basicLayoutComponent}</Box>
        );
      }}
      basicLayoutComponent={(basicLayoutProps) => {
        const { appointmentData, appointmentResources } = basicLayoutProps;
        const appointment = appointmentData as ExtendedAppointmentModel;
        const selectedResource = appointmentResources[0];
        if (!selectedResource) {
          return <></>;
        }
        const provider = providersMap[appointmentResources[0].id as number];
        const isNewAppointment = !appointment.id;

        const bookingType: BookingTypeEnum | null | string = selectedBookingType || appointment.bookingType;
        const workingHours = workingHoursMap[provider.id];
        const { startDate, endDate } = appointment;

        if (!startDate || !endDate) {
          return <></>;
        }

        const isWithinAValidWorkingHour =
          view === VIEW_NAME_WEEK ||
          isWithinAWorkingHour(
            workingHours,
            capDatetimeSeconds(new Date(startDate)),
            capDatetimeSeconds(new Date(endDate))
          );
        if (view === VIEW_NAME_WEEK) {
          // Set by default the first working slot available
          const selectedDate = moment(appointment.startDate);
          const nearestAvailableDate = workingHours.find((workingHour) =>
            moment(workingHour.startDate).isSameOrAfter(selectedDate)
          )?.startDate;
          appointment.startDate = nearestAvailableDate as SchedulerDateTime;
          appointment.endDate = nearestAvailableDate as SchedulerDateTime;
        }

        if (isNewAppointment && !isWithinAValidWorkingHour) {
          // eslint-disable-next-line no-console
          console.warn('New appointment cannot open the modal for invalid working hours');
          closeAppointmentModal();
          return <></>;
        }

        // eslint-disable-next-line no-nested-ternary
        return !appointmentFormOpened ? (
          <></>
        ) : // eslint-disable-next-line no-nested-ternary
          isNewAppointment && !selectedBookingType ? (
            <EHRModal dataCy="modal-booking-type" open={appointmentFormOpened} onClose={closeAppointmentModal}>
              <EHRModal.Body dataCy="modal-booking-type-body">
                <Box display="flex" flexDirection="column" gridGap="1rem">
                  <Box>
                    <EHRButton
                      dataCy="btn-schedule-appointment"
                      color="primary"
                      onClick={() => setSelectedBookingType(BookingTypeEnum.APPOINTMENT)}
                      text="Schedule Appointment"
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <EHRButton
                      dataCy="btn-block-off"
                      color="primary"
                      onClick={() => setSelectedBookingType(BookingTypeEnum.BLOCK_OFF_HOUR)}
                      text="Block off"
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <EHRButton
                      dataCy="btn-block-off"
                      color="default"
                      onClick={closeAppointmentModal}
                      text="Cancel"
                      fullWidth
                    />
                  </Box>
                </Box>
              </EHRModal.Body>
            </EHRModal>
          ) : // eslint-disable-next-line no-nested-ternary
            bookingType === BookingTypeEnum.APPOINTMENT && !!provider ? (
              <AppointmentCRUDModal
                open={appointmentFormOpened}
                appointmentData={appointment}
                onClose={closeAppointmentModal}
                isNewAppointment={isNewAppointment}
                selectedMedspaLocation={selectedMedspaLocation}
                selectedProvider={provider}
                appointmentTypes={appointmentTypes}
              />
            ) : bookingType === BookingTypeEnum.BLOCK_OFF_HOUR ? (
              <BlockOffCRUDModal
                open={appointmentFormOpened}
                appointmentData={appointment}
                onClose={closeAppointmentModal}
                isNewAppointment={isNewAppointment}
                selectedMedspaLocation={selectedMedspaLocation}
                providersMap={providersMap}
              />
            ) : (
              <span>No booking type supported</span>
            );
      }}
    />
  );
};

export default MedspaCaledarAppointmentForm;
