import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import useStyles from './loadingModal.styles';

interface LoadingModalProps {
  open: boolean;
  loadingText: string;
}

function LoadingModal({ open, loadingText }: LoadingModalProps) {
  const classes = useStyles();
  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.title}>{loadingText}</DialogTitle>
      <Box className={classes.circularProgress}>
        <CircularProgress />
      </Box>
    </Dialog>
  );
}

export default LoadingModal;
