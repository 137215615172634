import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import { INewCard, IPaymentCard } from 'src/interfaces/IMedspaAdminList';
import { PAYMENT_METHODS } from 'src/constants/reactQuery.keys';
import PaymentMethods from 'src/services/medspaAdmins/PaymentMethods';

const useGetCards = (userGroupId: number, enabled: boolean = true): UseQueryResult<IPaymentCard[]> =>
  useQuery<IPaymentCard[]>({
    queryKey: [PAYMENT_METHODS],
    queryFn: (): Promise<IPaymentCard[]> => PaymentMethods.getCards(userGroupId),
    refetchOnWindowFocus: false,
    enabled,
  });

const useAddCard = (userGroupId: number): UseMutationResult<void, unknown, INewCard, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: INewCard): Promise<void> => {
      await PaymentMethods.addCard(userGroupId, params);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [PAYMENT_METHODS] });
    },
  });
};

export { useAddCard, useGetCards, useGetCards as default };
