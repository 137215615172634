import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#efefef',
    padding: 15,
    margin: '10px 25px 5px 5px',
  },
  dialogContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: 25,
    minHeight: 400,
    '& *': {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      textAlign: 'center'
    },
    '& > div': {
      width: '100%',
    }
  },
  subtitle: {
    textAlign: 'justify',
    border: '1px solid #ddd',
    padding: '20px',
    borderRadius: '4px',
    fontFamily: 'Messina Sans Regular'
  },
  progress: {
    fontSize: 20,
    height: 20,
    width: 20
  },
  save: {
    width: 120,
    height: 60,
    padding: '15px',
    marginLeft: 10
  },
  rowBox: {
    flexDirection: 'row',
    margin: 'auto'
  },
  chevron: {
    display: 'inline',
    fontSize: 25,
    verticalAlign: 'top'
  },
  adornment: {
    marginLeft: 0,
    fontSize: 11
  },
  rowIcon: {
    position: 'absolute',
    top: 15,
    left: 15
  }
}))

export { useStyles, useStyles as default }