import { useEffect } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

import { UPCOMING_PATIENTS, UPCOMING_PATIENT_COUNT } from '../../constants/reactQuery.keys';
import UpcomingAppointments from '../../services/UpcomingAppointments';
import { CustomerParams } from '../../interfaces/CustomerParams';
import { DEFAULT_LIMIT } from '../../constants/general.constants';

export function useUpcomingAppointments(paramsQuery: CustomerParams, total: number = 0) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([UPCOMING_PATIENTS, paramsQuery]);
  }, [paramsQuery]);

  const query = useInfiniteQuery<any>(
    [UPCOMING_PATIENTS, paramsQuery],
    ({ pageParam = 1 }) => UpcomingAppointments.getUpComingAppointmentsPatients({ ...paramsQuery, page: pageParam }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * DEFAULT_LIMIT < total : lastPage.length === DEFAULT_LIMIT;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const appointments =
    query?.data?.pages
      ?.map((items) => items)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, appointments, currentPage: query?.data?.pages.length } };
}

export function useUpcomingAppointmentCount(paramsQuery: CustomerParams) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([UPCOMING_PATIENT_COUNT, paramsQuery]);
  }, [paramsQuery]);

  return useQuery(
    [UPCOMING_PATIENT_COUNT, paramsQuery],
    () => UpcomingAppointments.getUpcomingAppointmentsPatientCount(paramsQuery),
    {
      initialData: 1,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
}

export { UPCOMING_PATIENTS } from '../../constants/reactQuery.keys';
