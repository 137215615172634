import React, { ReactNode } from 'react';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { ShadowCard } from '../ShadowCard';
import CardHeader from './CardHeader';

interface FormCardProps {
  children?: ReactNode;
  required?: boolean;
  headline?: string;
  status?: StepStatus;
  description?: string;
}

const FormCard: React.FC<FormCardProps> = ({
  children,
  required = false,
  headline = '',
  status = 'empty',
  description = '',
}) => (
  <ShadowCard>
    <CardHeader description={description} required={required} headline={headline} status={status} />
    {children}
  </ShadowCard>
);

export default FormCard;
