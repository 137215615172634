import React from 'react';
import { Button } from '@material-ui/core';
import { useInputMatchingStyles } from './InputMatching.styles';

interface Props {
  text: string;
  onClick?: any;
}

const ButtonAction = ({ text, onClick }: Props) => {
  const classes = useInputMatchingStyles();
  return (
    <Button className={classes.button} variant="contained" onClick={onClick} disableElevation>
      {text}
    </Button>
  );
};

export default ButtonAction;
