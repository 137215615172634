import { useMutation, useQuery, useQueryClient } from 'react-query';
import ServiceVisit from '../../services/ServiceVisit';
import {
  APPLIED_SERVICE_VISIT_CREDITS,
  SERVICE_VISIT_CHECKOUT,
  SERVICE_VISIT_CREDITS,
} from '../../constants/reactQuery.keys';

const useServiceVisitCredits = (serviceVisitId: number, enabled: boolean) =>
  useQuery([SERVICE_VISIT_CREDITS, serviceVisitId], () => ServiceVisit.getServiceVisitCredits(serviceVisitId), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: true,
    enabled,
  });

export const useAppliedServiceVisitCredits = (serviceVisitId: number, enabled: boolean) =>
  useQuery(
    [APPLIED_SERVICE_VISIT_CREDITS, serviceVisitId],
    () => ServiceVisit.getAppliedServiceVisitCredits(serviceVisitId),
    {
      refetchOnWindowFocus: true, // For multi-tab purposes
      keepPreviousData: true,
      enabled,
    }
  );

export interface ApplyServiceCreditParams {
  value: number;
  serviceCreditId: number;
}

export const useApplyServiceVisitCredits = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ApplyServiceCreditParams) => ServiceVisit.applyServiceVisitCredits(serviceVisitId, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_VISIT_CREDITS, serviceVisitId]);
        queryClient.invalidateQueries([APPLIED_SERVICE_VISIT_CREDITS, serviceVisitId]);
      },
    }
  );
};

export default useServiceVisitCredits;
