import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { Pill } from '../../common/Pill';
import { useRowStyles } from './transactions.styles';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { IAssetSold } from '../../../interfaces/ITransaction.interfaces';

interface AssetsSoldRowProps {
  assetSold: IAssetSold;
}

const AssetsSoldRow: React.FC<AssetsSoldRowProps> = ({ assetSold }: { assetSold: IAssetSold }) => {
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCellCustom>{assetSold.checkoutId}</TableCellCustom>
        <TableCellCustom>{assetSold.serviceVisitId}</TableCellCustom>
        <TableCellCustom>{assetSold.checkoutLineItemId}</TableCellCustom>
        <TableCellCustom>{assetSold.serviceId}</TableCellCustom>
        <TableCellCustom>{assetSold.serviceName}</TableCellCustom>
        <TableCellCustom>{assetSold.quantityInLineItem}</TableCellCustom>
        <TableCellCustom>{assetSold.numberOfAssetsInvolved}</TableCellCustom>
        <TableCellCustom>{assetSold.costsOfAssetsInvolved}</TableCellCustom>
        <TableCellCustom>{assetSold.sumOfAllCosts}</TableCellCustom>
        <TableCellCustom>{assetSold.salePrice}</TableCellCustom>
        <TableCellCustom>{assetSold.margin}</TableCellCustom>
        <TableCellCustom>{assetSold.internalSerialNumbers}</TableCellCustom>
        <TableCellCustom>{assetSold.warehouseName}</TableCellCustom>
        <TableCellCustom>{assetSold.purchaseOrderIds}</TableCellCustom>
        <TableCellCustom>
          <a href={assetSold.ehrUrl} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
            <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
          </a>
        </TableCellCustom>
      </TableRow>
    </>
  );
};

export default AssetsSoldRow;
