import React, { FC, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../Header';
import Footer from '../Footer';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH, PRACTITIONER_HOME_PATH } from '../../../routes/practitionerRoutes';
import ServicesManagementComponent from './ServicesManagmentComponent';
import { usePractitionerInfo } from '../../../hooks/queries/usePractitioners';

const ServicesManagement: FC = () => {
  const history = useHistory();
  const { id: identityId, userId } = useSelector(({ auth }: any) => auth);
  const { practitionerInfo } = usePractitionerInfo(userId, identityId);

  const handleSubmit = async () => {
    history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('patient-bulk-upload'));
  };

  useEffect(() => {
    if (practitionerInfo?.isEhrReady) {
      history.replace(PRACTITIONER_HOME_PATH);
    }
  }, [practitionerInfo]);

  return (
    <>
      <Header currentStepName="Services Management" currentStep={2} />
      <ServicesManagementComponent />
      <Footer
        isSaving={false}
        currentStep={2}
        handleSubmit={handleSubmit}
        saveTitle="Continue"
        skipLink={PRACTITIONER_ACCOUNT_SETTINGS_PATH('patient-bulk-upload')}
        backLink={PRACTITIONER_ACCOUNT_SETTINGS_PATH('more-details')}
      />
    </>
  );
};

export default ServicesManagement;
