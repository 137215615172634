import React from 'react';
import { Box } from '@material-ui/core';
import { IMedspaAdminList } from 'src/interfaces/IMedspaAdminList';
import { IUserGroup } from 'src/interfaces/IUserGroup';
import AdminsList from './AdminsList';
import ProfileDetails from './ProfileDetails';
import Legal from './Legal';

interface AdminSettingsProps {
  admins: IMedspaAdminList;
  isLegacyRole: boolean;
  currentUser: any;
  userGroup: IUserGroup;
}

const AdminSettings = (props: AdminSettingsProps) => {
  const { admins, userGroup, isLegacyRole, currentUser } = props;

  return (
    <Box display="flex" flexDirection="column" gridGap="1rem">
      <ProfileDetails user={currentUser} userGroup={userGroup} />

      <Box>{!isLegacyRole ? <AdminsList adminsList={admins} userGroup={userGroup} /> : <Legal />}</Box>
      <Box height="1rem" />
    </Box>
  );
};

export default AdminSettings;
