export const MEDICATION_MESSAGES = {
  areYouUsing: 'Are you currently using $NAME?',
  whatType: 'What type of $NAME do you use?',
  potency: 'Please enter potency for $NAME',
  frequency: 'Please enter frequency for $NAME',
};

export const PATIENT_INITIAL_STATE = {
  id: 0,
  firstName: '',
  lastName: '',
  customerStatus: '',
  galdermaEmail: '',
  advocateReason: '',
  hasReferred: '',
  isAdvocate: '',
  referredBy: '',
  referredById: '',
  createdAt: '',
};

export const MEDICAL_PROFILE_INITIAL_STATE = {
  id: 0,
  customerId: 0,
  dateOfBirth: '',
  sex: '',
  skinConcerns: '',
  serviceImprovements: [],
  skinType: '',
  preferredFragrance: '',
  sunscreenFrequency: '',
  sunscreenBrand: '',
  goalsAndNotes: '',
  userSkinExtraDetails: '',
  userActiveMedicalConditions: '',
  isSmoker: '',
  isPregnant: '',
  isBreastFeeding: '',
  isOnBirthControl: '',
  hasBeenOnAccutane: '',
  hasHormonalIssues: '',
  currentNonprescriptionTopicalMedications: '',
  pastNonprescriptionTopicalMedications: '',
  pastPrescriptionTopicalMedications: '',
  pastPrescriptionOralMedications: '',
  pastPrescriptionOtherMedications: '',
  usingPeels: '',
  usingRetinol: '',
  hasKnownAllergies: false,
  knownAllergies: '',
  otherConcerns: '',
  currentPrescriptionTopicalMedications: '',
  currentPrescriptionOralMedications: '',
  currentPrescriptionOtherMedications: '',
  hasMedicationAllergies: false,
  oralMedications: [],
  topicalMedications: [],
  otherMedications: [],
  medicationAllergies: [],
  updatedFields: [],
  createdAt: '',
  updatedAt: '',
  visitId: '',
  skipCondition: false,
  cancerCurrentlyReceivingTreatment: '',
  immuneComments: '',
  confirmedInfo: false,
  upcomingEvent: '',
  usingPeelsDescription: '',
  procedures: {},
  conditions: {},
};
