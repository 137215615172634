import React from 'react';
import { TableContainer, Table, TableCell, TableHead, TableBody, withStyles } from '@material-ui/core';
import IServiceGroup from 'src/interfaces/IServiceGroup';

import { MEDSPA_ADMIN_SERVICES_MANAGEMENT } from 'src/routes/medspaAdminRoutes';
import IServices from '../../../interfaces/IServices';
import ServiceRow from './ServiceRow';

import IVariant, { IVariantItem } from '../../../interfaces/IVariants';
import { MultipleSkeleton } from '../../../components/common/LoadingSkeleton';
import ButtonAhref from '../../../components/common/ButtonAhref';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH } from '../../../routes/practitionerRoutes';

const TableCellHeader = withStyles(() => ({
  root: {
    fontSize: '1rem',
    padding: '1.25rem',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.48)',
  },
}))(TableCell);

interface Props {
  services: IServices[];
  isFetching: boolean;
  selectedServices?: number[];
  handleOnChangeServiceSelection: (serviceId: number, serviceGroupId: number, serviceIds: number[]) => void;
  height?: string;
  servicesManagementStepCompleted: boolean;
  renderLocation?: string;
  serviceGroup: IServiceGroup;
  isMedspaAdmin?: boolean;
}

const ServiceTable = ({
  services,
  isFetching,
  selectedServices = [],
  handleOnChangeServiceSelection,
  height = '500px',
  servicesManagementStepCompleted,
  renderLocation,
  serviceGroup,
  isMedspaAdmin = false,
}: Props) => (
  <div style={{ height }}>
    <TableContainer style={{ backgroundColor: '#F5F5F5', borderRadius: '12px' }}>
      <Table>
        <TableHead>
          <TableCellHeader width="40%">Name</TableCellHeader>
          <TableCellHeader width="10%">Price</TableCellHeader>
          <TableCellHeader width="20%">Service Group</TableCellHeader>
          <TableCellHeader width="20%">Supplier</TableCellHeader>
          {servicesManagementStepCompleted ? (
            <TableCellHeader width="10%">Status</TableCellHeader>
          ) : (
            <TableCellHeader width="10%">Type</TableCellHeader>
          )}
        </TableHead>
        {!isFetching && !services?.length && (!!serviceGroup.practitionerId || !!serviceGroup.userGroupId) ? (
          <div style={{ margin: '10px', textAlign: 'center' }}>
            <ButtonAhref
              buttonStyle="big"
              text="New Service"
              href={
                isMedspaAdmin
                  ? `${MEDSPA_ADMIN_SERVICES_MANAGEMENT}?serviceId=new&serviceGroupId=${serviceGroup.id}`
                  : `${PRACTITIONER_ACCOUNT_SETTINGS_PATH(
                    'services-management',
                    renderLocation
                  )}?serviceId=new&serviceGroupId=${serviceGroup.id}`
              }
            />
          </div>
        ) : (
          <TableBody>
            {services
              ?.sort((i, j) => (i.name > j.name ? 1 : -1))
              .map((service: IServices) => (
                <>
                  <ServiceRow
                    selectedService={selectedServices.includes(service.id)}
                    onChangeSelection={() => {
                      const matchingServiceIds = services
                        .filter((serviceToBeMatched) => serviceToBeMatched.serviceGroupId === serviceGroup.id)
                        .map((matchingService) => matchingService.id);
                      handleOnChangeServiceSelection(service.id, service.serviceGroupId, matchingServiceIds);
                    }}
                    service={service}
                    servicesManagementStepCompleted={servicesManagementStepCompleted}
                    renderLocation={renderLocation}
                    isMedspaAdmin={isMedspaAdmin}
                  />
                  {service.variants &&
                    service.variants.map((variant: IVariant) =>
                      variant.items.map((item: IVariantItem) => (
                        <ServiceRow
                          service={item}
                          servicesManagementStepCompleted={servicesManagementStepCompleted}
                          renderLocation={renderLocation}
                          isMedspaAdmin={isMedspaAdmin}
                        />
                      ))
                    )}
                </>
              ))}
            {isFetching && <MultipleSkeleton />}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  </div>
);

export default ServiceTable;
