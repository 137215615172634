import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tableContainer: {
    overflow: 'auto',
    marginTop: '10px',
    position: 'relative',
    maxHeight: '1000px',
    height: '84.7vh',
  },
}));
