import { useMutation } from 'react-query';
import {
  IPractitionerRegistrationPasswordParams,
  PractitionerRegistrations,
} from '../../services/PractitionerRegistration';
import { dispatch } from '../../rematch';

export const useSetPasswordPractitionerRegistration = () =>
  useMutation((params: IPractitionerRegistrationPasswordParams) => PractitionerRegistrations.setPassword(params), {
    onSuccess: (response) => {
      dispatch.auth.loginFromCreatePassword({ data: response.data });
    },
  });

export const useResendResetPassword = () =>
  useMutation((email: string) => PractitionerRegistrations.sendResetPassword(email));
