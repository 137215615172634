import {
  SERVICE_GROUPS_WITH_OPTIONAL_AFTER_PHOTOS,
  SERVICE_GROUPS_WITH_OPTIONAL_BEFORE_PHOTOS,
} from '../../constants/serviceVisits/serviceVisit.constants';
import Sentry from '../../initializers/sentry';
import IServiceGroup from '../../interfaces/IServiceGroup';
import { ILineItem } from '../../interfaces/IServiceVisits';
import { showSnackbar } from '../global';
import { compressImage, validatesImageFormat } from '../image.utils';
import compile from '../toastMessagesCompiler';

export const isFutureUseOnly = (serviceGroupId: number, lineItems: ILineItem[]) =>
  lineItems.some((lineItem: ILineItem) => {
    if (lineItem.serviceGroupId === serviceGroupId) {
      return !(lineItem.futureUseQuantity > 0 && lineItem.currentUseQuantity === 0);
    }
    return false;
  });

export const isServiceVisitAfterPhotoRequired = (
  serviceGroups: IServiceGroup[],
  lineItems: ILineItem[],
  accessToOptionalPhotos: boolean
): boolean =>
  serviceGroups.some(
    ({ name, id, afterPhotoRequired }) =>
      afterPhotoRequired ||
      (!accessToOptionalPhotos &&
        !SERVICE_GROUPS_WITH_OPTIONAL_AFTER_PHOTOS.includes(name) &&
        isFutureUseOnly(id, lineItems))
  );

export const isServiceVisitBeforePhotoRequired = (serviceGroups: IServiceGroup[], lineItems: ILineItem[]): boolean =>
  serviceGroups.some(
    ({ name, id }) => !SERVICE_GROUPS_WITH_OPTIONAL_BEFORE_PHOTOS.includes(name) && isFutureUseOnly(id, lineItems)
  );

const prepareImage = (image: File): Promise<File> =>
  new Promise((resolve: any) =>
    compressImage({
      file: image as File,
      onSuccess: async (imageCompressed) => {
        resolve(imageCompressed);
      },
      onError: (error: any) => {
        if (process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION') {
          // eslint-disable-next-line no-console
          console.log(error?.message);
        }
        showSnackbar(compile('generic.error_message', { action: 'compressing', element: 'your photo' }));
        Sentry.captureMessage(error.message, 'debug' as Sentry.Severity);
        resolve(null);
      },
    })
  );

export const preparePhotoParams = async (photos: File[]) => {
  const formData = new FormData();

  for (let index = 0; index < photos.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    const validatedFile = await validatesImageFormat(photos[index]);
    // eslint-disable-next-line no-await-in-loop
    const image = await prepareImage(validatedFile);
    if (image) {
      formData.append('images[]', image);
    }
  }

  return formData;
};
