import React, { useState, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';
import { uniq } from 'lodash';
import { dispatch } from '../../../rematch';
import Drawer from '../../common/Drawer/Drawer';
import Annotation from '../../../interfaces/drawer.interfaces';
import { PhotoSlider } from './PhotoSlider';
import { ServicesButtons } from './ServicesButtons';
import { IPhoto } from '../../../interfaces/global.interfaces';
import { ServiceNotes } from './ServiceNotes';
import IServices from '../../../interfaces/IServices';
import { getCharges, getPhotosData } from '../../../utils/annotations.utils';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import { IAnnotationsState } from '../../../interfaces/annotation.interfaces';
import { SELECTED_PHOTO_INITIAL_STATE } from '../../../constants/annotations.constants';
import { IServicesUnits } from '../../../interfaces/serviceVisit.interfaces';
import {
  ITEMS_PHOTOS_ANNOTATIONS_LIMIT_MOBILE,
  ITEMS_PHOTOS_ANNOTATIONS_LIMIT_DESKTOP,
  WIDTH_MAX_DESKTOP,
} from '../../../constants/general.constants';
import { useStyles } from './annotation.styles';

interface Props {
  serviceVisitId: number;
  showOnly?: boolean;
}
/*
 * Note: call patient.fetchPatientData before render this component
 */
const Annotations = ({ serviceVisitId, showOnly }: Props) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const { annotations, serviceNotes } = useSelector((store: any) => store.annotations as IAnnotationsState);
  const { totalServicesUnits, selectedServices, serviceVisit, isLoadingGetServiceVisitFromPatient, services } =
    useSelector((store: any) => store.newServiceVisit);
  const patient = useSelector((store: any) => store.patient);
  const patientPhotos: IPhoto[] = patient.photos;
  const [currentLayer, setCurrentLayer] = useState<number>(0);
  const [selectedPhoto, setSelectedPhoto] = useState<IPhoto>({ ...SELECTED_PHOTO_INITIAL_STATE });
  const [drawerLines, setDrawerLines] = useState<Annotation.Line[]>([]);
  const { width } = useWindowDimensions();
  const classes = useStyles();

  const ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE =
    width > WIDTH_MAX_DESKTOP ? ITEMS_PHOTOS_ANNOTATIONS_LIMIT_DESKTOP : ITEMS_PHOTOS_ANNOTATIONS_LIMIT_MOBILE;

  const getPhotos = (): IPhoto[] => {
    let photoIds: number[];
    let photos;

    if (showOnly) {
      photoIds = serviceVisit.annotatedPhotos.map(({ photo }: any) => photo.id);
      photos = patientPhotos.filter(({ id }) => photoIds.includes(id));
    } else {
      photoIds = serviceVisit.photos.map(({ photoId }: any) => photoId);
      photos = patientPhotos.filter(({ id, photoType }) => photoIds.includes(id) || !photoType);
    }

    return photos;
  };

  useEffect(() => {
    if (patientPhotos.length) {
      setSelectedPhoto(getPhotos()[0] || SELECTED_PHOTO_INITIAL_STATE);
    }
  }, [patientPhotos, serviceVisit.photos, serviceVisit.annotatedPhotos]);

  useEffect(() => {
    if (selectedPhoto.id) {
      setDrawerLines(annotations[selectedPhoto.id] || []);
    }
  }, [selectedPhoto]);

  useEffect(() => {
    setCurrentLayer(selectedServices.includes(currentLayer) ? currentLayer : 0);
    if (annotations[selectedPhoto.id]) {
      setDrawerLines(annotations[selectedPhoto.id].filter(({ id }) => selectedServices.includes(id)));
    }
  }, [selectedServices]);

  const getLayers = (): Annotation.Layer[] =>
    selectedServices.map((serviceId: number) => {
      const service = services.find(({ id }: IServices) => serviceId === id);

      return {
        id: serviceId,
        name: service?.name,
        color: service?.color,
      };
    });

  const getAnnotatableServices = (): IServices[] => {
    const servicesWithUnits = Object.entries(totalServicesUnits as IServicesUnits)
      .filter(([, units]) => !!+units)
      .map(([serviceId]) => +serviceId);

    return services.filter(({ id, annotatable }: IServices) => servicesWithUnits.includes(id) && annotatable);
  };

  const onSave = (lines: Annotation.Line[]): void => {
    const filteredLines = lines.filter(({ color }) => color);
    const newLines = { ...annotations, [selectedPhoto.id]: filteredLines };

    const newNotes = { ...serviceNotes };
    newNotes[currentLayer] =
      newNotes[currentLayer] || services.find(({ id }: IServices) => id === currentLayer)?.internalNote || '';

    dispatch({
      type: 'annotations/setLines',
      payload: { photoId: selectedPhoto.id, lines: filteredLines },
    });

    dispatch({
      type: 'annotations/setNote',
      payload: { serviceId: currentLayer, note: newNotes[currentLayer] },
    });

    dispatch({
      type: 'annotations/saveAnnotations',
      payload: {
        charges: getCharges(newLines, totalServicesUnits, newNotes),
        photos: getPhotosData(newLines, services, totalServicesUnits),
        serviceVisitId,
      },
    });
  };

  const ServiceButtons: ReactNode = (
    <ServicesButtons
      services={getAnnotatableServices()}
      setCurrentLayer={setCurrentLayer}
      currentLayer={currentLayer}
    />
  );

  if (isLoadingGetServiceVisitFromPatient) {
    return (
      <div style={{ textAlign: 'center', width: '100%', padding: '100px 0' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid container>
      <Grid item sm={6} className={classes.annotationPhoto}>
        <Drawer
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          imageUrl={selectedPhoto?.mediumUrl}
          layers={getLayers()}
          currentLayer={currentLayer}
          initialLines={drawerLines}
          handleSave={onSave}
          bottomButtons={ServiceButtons}
          hideToolbar={showOnly}
        />
        {!showOnly && ServiceButtons}
      </Grid>
      <Grid item sm={6} className={classes.annotationForm}>
        <PhotoSlider
          photos={getPhotos()}
          setSelectedPhoto={setSelectedPhoto}
          selectedPhoto={selectedPhoto}
          lines={annotations}
          onlyShowAnnotated={!!showOnly}
          itemsPhotos={ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE}
        />
        <ServiceNotes
          servicesUsedIds={uniq(annotations[selectedPhoto?.id]?.map(({ id }) => id) || [])}
          services={services}
          units={totalServicesUnits}
          serviceNotes={serviceNotes}
          disableInputs={!!showOnly}
        />
      </Grid>
    </Grid>
  );
};

Annotations.defaultProps = {
  showOnly: false,
};

// eslint-disable-next-line import/prefer-default-export
export { Annotations };
