import { withStyles, Tabs } from '@material-ui/core';

const MainTabs = withStyles((theme) => ({
  root: {
    backgroundColor: '#f2f5f5',
    color: theme.palette.primary.main,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  indicator: {
    backgroundColor: '#f16c00',
  },
}))(Tabs);

export default MainTabs;
