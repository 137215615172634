import { Button, makeStyles, withStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  containerDialog: {
    width: '450px',
    height: '650px',
  },
  containerStep1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    width: '100%',
  },
  title: {
    fontFamily: 'Messina Sans SemiBold',
    display: 'flex',
    justifyContent: 'center',
    margin: '0',
    alignItems: 'center',
  },
  biggerTitle: {
    '& h2': {
      fontSize: '1.75rem',
    },
  },
  content: {
    textAlign: 'center',
    fontFamily: 'Messina Sans Regular',
    fontSize: '14px',
    marginBottom: '0',
    color: '#F3CA8A',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '30px',
    flexDirection: 'column',
  },
  button: {
    width: '120px',
    height: '47px',
    borderRadius: '5px',
  },
  buttonReview: {
    backgroundColor: '#e7eeed',
    fontSize: '14px',
  },
  reviewIcons: {
    cursor: 'pointer',
    textTransform: 'capitalize',
    lineHeight: '1',
    width: '80px',
    height: '80px',
    backgroundColor: '#12574d',
    color: '#fff',

    '& i': {
      fontSize: 30,
    },
    '& p': {
      padding: '4px',
    },
  },
  qrContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '80%',
    textAlign: 'center',
    padding: '24px',
  },
}));

export const CustomButton = withStyles(() => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#12574d',
    minWidth: '159px',
    borderRadius: '4px',
    padding: '15px',
    '&:hover': {
      backgroundColor: '#12574d',
    },
  },
  disabled: {
    opacity: 0.5,
    color: '#FFFFFF !important',
  },
}))(Button);
