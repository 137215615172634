import loadingPlugin from '@rematch/loading';
import { init, RematchRootState } from '@rematch/core';
import { models, RootModel } from './rootModel';

const store = init({
  models,
  // uncomment to debug rematch
  // redux: {
  //   middlewares: [() => (next: any) => (action: any) => {
  //     console.log(action.type, action.payload)
  //     return ((action.type === 'RESET') ? models : next(action))
  //   }]
  // },
  plugins: [loadingPlugin()],
});

export type RootState = RematchRootState<RootModel>;

const { dispatch } = store;

export { dispatch, store };
