import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from './chart.style';
import { months } from '../../../constants/general.constants';
import { getChartsDataFormater } from '../../../utils/chart';

interface ChartProps {
  titleChart: string;
  yLegend: string;
  xLegend: string;
  xAxis?: string[];
  chartColor: string;
  prefix?: string;
  data: any;
  isLoading: boolean;
  className?: string;
  header?: React.ReactElement;
}

const ChartBar = ({
  titleChart,
  yLegend,
  xLegend,
  chartColor,
  data,
  prefix,
  isLoading,
  xAxis = months,
  className,
  header,
}: ChartProps) => {
  const classes = useStyles();

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        barHeight: '100%',
      },
    },
    fill: {
      colors: [chartColor],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: xAxis,
      title: {
        text: xLegend,
        style: {
          fontFamily: 'Messina Sans SemiBold',
          color: '#000',
          fontWeight: 'normal',
        },
      },
    },
    yaxis: {
      title: {
        text: yLegend,
        style: {
          fontFamily: 'Messina Sans SemiBold',
          color: '#000',
          fontWeight: 'normal',
        },
      },
      labels: {
        formatter(value) {
          return getChartsDataFormater(value, prefix);
        },
      },
    },

    title: {
      text: titleChart,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Messina Sans SemiBold',
        color: '#263238',
      },
    },
  };

  return (
    <div className={className || classes.container}>
      {isLoading && <CircularProgress />}
      {header}
      <Chart options={options} series={data} height={300} type="bar" />
    </div>
  );
};

export default ChartBar;
