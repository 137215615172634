import React from 'react';

import { Box, TableCell, TableHead, withStyles } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons/';

import SortDirection, { ASCENDING, DESCENDING } from '../../../types/SortDirection';

/* eslint-disable react/no-unused-prop-types */
export interface ColumnType {
  id: string;
  title: string | React.ReactNode;
  sort?: boolean;
  align?: 'start' | 'center' | 'end';
  className?: string;
}

type TableHeaderProps = {
  columns: ColumnType[];
  sortBy?: string;
  sortDirection?: SortDirection;
  onSortChange?: (by: string, direction: SortDirection) => void;
};

const TableCellHeader = withStyles(() => ({
  root: {
    fontSize: '15px',
  },
}))(TableCell);

const OrderIcon = ({ active, direction, onClick }: any) => {
  if (!active) {
    return (
      <span style={{ maxHeight: '24px' }}>
        <ExpandMore data-cy="orderIcon" color="disabled" onClick={onClick} style={{ cursor: 'pointer' }} />
      </span>
    );
  }

  return direction === ASCENDING ? (
    <span role="button" title="ascending" style={{ maxHeight: '24px' }}>
      <ExpandLess onClick={onClick} style={{ cursor: 'pointer' }} />
    </span>
  ) : (
    <span role="button" title="descending" style={{ maxHeight: '24px' }}>
      <ExpandMore onClick={onClick} style={{ cursor: 'pointer' }} />
    </span>
  );
};

const TableHeader = ({ columns, sortBy, sortDirection = 'asc', onSortChange = () => {} }: TableHeaderProps) => {
  const handleOnSortChange = (column: string) => {
    const newSortDirection = sortDirection === ASCENDING ? DESCENDING : ASCENDING;
    onSortChange(column, newSortDirection);
  };

  return (
    <TableHead>
      {columns.map(({ id, title, sort, className, align = 'start' }: ColumnType) => (
        <TableCellHeader key={`${id}_${title}`} sortDirection="asc">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={`${align}`}
            textAlign={`${align}`}
            data-cy="columnTitle"
            className={className}
          >
            {title}
            {sort && (
              <OrderIcon
                active={id === sortBy}
                direction={sortDirection}
                onClick={() => handleOnSortChange(id)}
                key={title}
              />
            )}
          </Box>
        </TableCellHeader>
      ))}
    </TableHead>
  );
};

export default TableHeader;
