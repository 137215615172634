import React, { useCallback, useEffect, useRef } from 'react';

import { Box, CircularProgress, debounce } from '@material-ui/core';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import { EHRTextField } from 'src/components/ui/v1';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { useCreateProviderEntity } from 'src/hooks/queries/useAccountSettings';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';

interface ProviderEntityProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus) => void;
  entityName?: string;
  progressPercentage?: number;
  disabled?: boolean;
}

const ProviderEntity = ({
  stepId,
  onStepUpdate,
  entityName,
  progressPercentage,
  disabled = false,
}: ProviderEntityProps) => {
  const [providerEntity, setProviderEntity] = React.useState('');
  const prevState = useRef(entityName);
  const { mutateAsync: updateProviderEntity, isError, isLoading, isSuccess } = useCreateProviderEntity();
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();

  const handleUpdateCheckoutNote = async (entity: string) => {
    await updateProviderEntity({ entityName: entity });
  };

  const updateNote = useCallback(debounce(handleUpdateCheckoutNote, 1500), []);

  const handleTextInput = (value?: string) => {
    setProviderEntity(value ?? '');
    if (value) {
      updateNote(value ?? '');
    } else {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  };

  useEffect(() => {
    setProviderEntity(entityName ?? '');
    onStepUpdate(stepId, entityName ? STEP_COMPLETED : STEP_EMPTY);
  }, [entityName, isSuccess]);

  useEffect(() => {
    if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isError) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [isLoading, isError]);

  useEffect(() => {
    if (prevState.current === null && entityName) {
      updateProgress({
        stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
        percentage: progressPercentage as number,
      });
    }
    prevState.current = entityName;
  }, [entityName]);

  return (
    <Box>
      <EHRTextField
        error={isError}
        helperText={isError ? 'Error updating Provider Entity' : ''}
        label=""
        onChange={(event) => handleTextInput(event?.target?.value)}
        dataCy="provider-entity-input"
        value={providerEntity}
        disabled={disabled}
        startAdornment={
          isLoading ? (
            <Box width="40px" textAlign="center">
              <CircularProgress size={20} />
            </Box>
          ) : null
        }
      />
    </Box>
  );
};

export default ProviderEntity;
