import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  navbar: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '10px',
    '& button': {
      height: 55,
      margin: 'auto auto auto 15px',
    },
  },
  leadTable: {
    marginTop: '15px',
  },
  tableHeader: {
    borderTop: '1px solid #0001',
  },
  statusSelect: {
    width: '100%',
  },
  statusSelectLabel: {
    color: theme.palette.primary.dark,
    backgroundColor: '#FFFFFF',
  },
  pagination: {
    margin: 'auto 15px auto 0px',
    minWidth: '230px',
  },
  theader: {
    textAlign: 'center',
    padding: '16px 10px 5px 10px',
    fontSize: 14,
    '&:first-of-type': {
      textAlign: 'left',
      paddingLeft: 35,
    },
    '&[data-shrink="true"]': {
      fontSize: '11px',
      minWidth: '96px',
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
  centerRow: {
    textAlign: 'center',
  },
  groupName: {
    paddingLeft: 35,
  },
  createGroupDialog: {
    display: 'flex',
    flexDirection: 'column',
    padding: 35,
    width: '30vw',
    maxWidth: '30vw',
    minWidth: 400,
  },
  createGroupTitle: {
    fontSize: 18,
    margin: '0 0 30px 0',
  },
  createGroupField: {
    margin: '10px 0',
    '&:first-of-type': {
      '& *': {
        width: '100%',
      },
    },
  },
  editGroupField: {
    margin: '10px 0',
    '& *': {
      width: '100%',
    },
  },
  createGroupButton: {
    margin: '10px 0',
    width: '100%',
    '&:first-of-type': {
      margin: '45px 0 0 0',
    },
  },
  createGroupNameLabel: {
    fontSize: 12,
  },
  selectGroupType: {
    width: '100%',
    margin: 'auto',
  },
  editGroupDialog: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: 35,
    width: '50vw',
    maxWidth: '50vw',
    minWidth: 400,
  },
  editGroupSelect: {
    width: '100%',
  },
  progressBox: {
    position: 'absolute',
    display: 'none',
    height: 400,
    maxHeight: 400,
    overflow: 'hidden',
    width: 'calc(100% - 70px)',
    '&[data-loading="true"]': {
      display: 'flex',
    },
  },
  progress: {
    margin: 'auto',
    minHeight: 200,
    minWidth: 200,
    '& svg': {
      color: '#00000011',
    },
  },
  inputSearch: {
    maxWidth: '200px',
  },
}));

export { useStyles, useStyles as default };
