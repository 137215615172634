import { hasUserGroupFeatureEnabled } from 'src/utils/auth.utils';
import { FEATURE_MEDSPA_ONBOARDING } from 'src/constants/features.constants';
import { OnboardingDoneFooter, OnboardingFooter } from 'src/pages/MedspaAdmin/Onboarding/components/OnboardingFooter';
import { hasActiveShippingAddress } from 'src/utils/onboarding.utils';
import { useMedspaAdminInfo } from './queries/useMedspaAdmins';
import { useCurrentUserGroup } from './queries/useUserGroups';

const useMedspaAdminOnboarding = () => {
  const { data: medspaAdminInfo, isLoading: isLoadingMedspaAdminInfo } = useMedspaAdminInfo();
  const { data: userGroupData, isLoading: isLoadingUserGroup, refetch: refetchUserGroup } = useCurrentUserGroup();

  const isLoading = isLoadingMedspaAdminInfo || isLoadingUserGroup;

  const commercialShippingAddress = userGroupData?.commercialShippingAddress;

  const pendingCommercialShippingAddress = commercialShippingAddress
    ? !hasActiveShippingAddress(userGroupData?.commercialShippingAddress)
    : null;

  const userGroupMD = userGroupData?.medicalDirector;
  const externalMD = userGroupMD?.externalMedicalDirector;

  const hasPendingExternalMD = externalMD?.id && !externalMD?.agreementPdf;

  const doNotHaveMedicalDirector = userGroupMD?.hasNoMedicalDirector === true;

  const pendingMedicalDirector = Boolean(hasPendingExternalMD) || doNotHaveMedicalDirector;

  const enableMedspaAdminOnboarding = hasUserGroupFeatureEnabled(
    FEATURE_MEDSPA_ONBOARDING,
    userGroupData?.features ?? []
  );

  const showFooter =
    enableMedspaAdminOnboarding &&
    medspaAdminInfo?.onboarding &&
    medspaAdminInfo?.onboardingCompleted &&
    !medspaAdminInfo?.allStepsCompleted;

  const showDoneFooter = true;

  const FooterComponent = showFooter ? OnboardingFooter : () => null;
  const DoneFooterComponent = showDoneFooter ? OnboardingDoneFooter : () => null;

  return {
    FooterComponent,
    DoneFooterComponent,
    showFooter,
    onboardingEnabled: enableMedspaAdminOnboarding && medspaAdminInfo?.onboarding,
    onboardingCompleted: medspaAdminInfo?.onboardingCompleted,
    allStepsCompleted: medspaAdminInfo?.allStepsCompleted,
    pendingCommercialShippingAddress,
    pendingMedicalDirector,
    isLoading,
    isLoadingMedspaAdminInfo,
    userGroupData,
    refetchUserGroup,
  };
};

export default useMedspaAdminOnboarding;
