import { useSelector } from 'react-redux';
import { PhysicianProviderQueryParam } from 'src/hooks/practitioner/usePractitioner';
import { IPractitioner, IPractitionerForPhysician, IPractitionerPost } from 'src/interfaces/IPractitioner';

import { USER_TYPES } from 'src/constants/general.constants';
import { FEATURE_MEDSPA_INTERFACE } from 'src/constants/features.constants';
import { MEDSPA_PROVIDER_PATIENTS } from 'src/routes/medspaPractitionerRoutes';
import { PRACTITIONER_PATIENT_CLEARED_PATH } from 'src/routes/practitionerRoutes';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as H from 'history';
import Api from './Api';

const BASE_PATH = '/v3/practitioners';

export const Practitioners = {
  async getAllDropdown(): Promise<IPractitioner[]> {
    const { practitioners } = await Api.get('v3/practitioners/practitioner_dropdown');

    return practitioners;
  },
  async getDropdown(filters: any): Promise<IPractitioner[]> {
    const { search } = filters;
    const { practitioners } = await Api.get('v3/practitioners/practitioner_dropdown', filters);

    if (search) {
      return practitioners.filter(({ firstName, lastName, licenses, email }: IPractitioner) =>
        `${firstName} ${lastName} ${licenses} ${email}`.toLowerCase().includes(search.toLowerCase())
      );
    }

    return practitioners;
  },
  async getAll(): Promise<IPractitioner[]> {
    const { practitioners } = await Api.get('practitioners');

    return practitioners;
  },
  async getAllForNewPatient(): Promise<IPractitioner[]> {
    return Api.get('practitioners/for_new_patient');
  },
  async get(filters: any): Promise<IPractitioner[]> {
    const { search } = filters;
    const { practitioners } = await Api.get(`${BASE_PATH}`, filters);

    if (search) {
      return practitioners.filter(({ firstName, lastName, licenses, email }: IPractitioner) =>
        `${firstName} ${lastName} ${licenses} ${email}`.toLowerCase().includes(search.toLowerCase())
      );
    }

    return practitioners;
  },
  async getAllMedspa(endpoint: string): Promise<IPractitioner[]> {
    const { practitioners } = await Api.get(endpoint);
    return practitioners;
  },
  async getMedspa(filters: any, endpoint: string): Promise<IPractitioner[]> {
    const { search } = filters;
    const { practitioners } = await Api.get(endpoint, filters);

    if (search) {
      return practitioners.filter(({ firstName, lastName, licenses, email }: IPractitioner) =>
        `${firstName} ${lastName} ${licenses} ${email}`.toLowerCase().includes(search.toLowerCase())
      );
    }

    return practitioners;
  },
  async createPractitioner(data: IPractitionerPost): Promise<any> {
    return Api.post(`${BASE_PATH}`, data);
  },
  async createMedspaProvider(userGroupId: string | number, data: FormData): Promise<any> {
    return Api.post(`v4/medspa/${userGroupId}/practitioners`, data);
  },
  async updateMedspaProvider(userGroupId: string | number, userId: string | number, data: FormData): Promise<any> {
    return Api.put(`v4/medspa/${userGroupId}/practitioners/${userId}`, data);
  },
  async editMedspaProvider(providerId: number, userGroupId: string, data: FormData): Promise<any> {
    return Api.put(`v4/medspa/${userGroupId}/practitioners/${providerId}`, data);
  },
  async getPractitionerInfo(id: number | undefined, identityId?: string): Promise<IPractitioner> {
    if (!id) {
      return {} as IPractitioner;
    }

    const { practitioner, error } = await Api.get(`${BASE_PATH}/${id}`, { identityId });

    if (error) {
      throw error;
    }
    return practitioner;
  },
  async getPractitionersForPhysician(queryParams: PhysicianProviderQueryParam): Promise<IPractitionerForPhysician[]> {
    let baseUrl = `${BASE_PATH}/by_physician?`;
    if (queryParams.search) {
      baseUrl += `search=${queryParams.search}&`;
    }
    if (queryParams.license) {
      baseUrl += `license=${queryParams.license}&`;
    }
    if (queryParams.gfeStatus) {
      baseUrl += `gfe_status=${queryParams.gfeStatus}`;
    }
    if (queryParams.practitionerIds) {
      baseUrl += `practitioner_ids=${queryParams.practitionerIds}`;
    }
    const { practitioners, error } = await Api.get(baseUrl);

    if (error) {
      throw error;
    }

    return practitioners;
  },
  async editPractitioner(data: IPractitionerPost): Promise<IPractitionerPost> {
    const { id } = data;
    const { results, error } = await Api.put(`${BASE_PATH}/${id}`, data);
    if (error) {
      throw error;
    }
    return results;
  },

  async practitionerPrimaryServices(): Promise<IPractitioner> {
    const { data, error } = await Api.get(`${BASE_PATH}/current/selected_primary_services`);

    if (error) {
      throw error;
    }

    return data;
  },
  isMedspaProvider: (): boolean => {
    const { userType, userGroupId, featureList } = useSelector(({ auth }: any) => auth);
    return (
      userType === USER_TYPES.PRACTITIONER &&
      !!userGroupId &&
      process.env.REACT_APP_MEDSPA_FIELDS === 'true' &&
      (featureList?.some((feature: any) => feature.key === FEATURE_MEDSPA_INTERFACE) ||
        // @ts-ignore
        featureList?.includes(FEATURE_MEDSPA_INTERFACE))
    );
  },
  useMasqueradeCallback: (history: H.History<H.LocationState>) => (hasMedspaInterfaceFeatureEnabled?: boolean) => {
    if (hasMedspaInterfaceFeatureEnabled) {
      history.push(MEDSPA_PROVIDER_PATIENTS);
    } else {
      history.push(PRACTITIONER_PATIENT_CLEARED_PATH);
    }
  },
};
