import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    padding: '20px',
  },
  label: {
    fontSize: '15px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: '#000000',
    whiteSpace: 'pre-line',
  },
  value: {
    fontWeight: 'normal',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textField: {
    width: '100%',
  },
  textFieldLabel: {
    color: '#949494',
  },
  nameContainer: {
    height: '80px',
    borderBottom: 'solid 1px #e4e7eb',
    paddingLeft: '25px',
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.88,
    letterSpacing: 'normal',
    color: '#000000',
  },
  loading: {
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  submitButton: {
    width: '200px',
    color: '#000000',
    backgroundColor: '#e7eeed',
    marginRight: '0',
  },
});
