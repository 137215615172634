import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  inventoryBudget: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: '1.5rem 2rem',
    borderRadius: 10,
    border: '1px solid rgb(231,231,231)',
    fontSize: '1rem',
    minWidth: 635,
    minHeight: 176,
    fontFamily: 'Messina Sans Regular',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    '&[data-budget="false"]': {
      minHeight: 'unset',
      margin: 'auto 0',
    },
    '&[data-minimal="true"]': {
      minHeight: 'unset',
      padding: '0.8rem 2rem',
      backgroundColor: '#fff',
    },
  },
  budgetDetails: {
    '& > div': {
      margin: 'auto 10px',
    },
    '&  > div:first-of-type': {
      margin: 'auto 0',
      fontSize: '1.8rem',
      fontFamily: 'Messina Sans Bold',
    },
    '&  > div:last-of-type': {
      margin: 'auto 0 auto auto',
      opacity: 0,
    },
    '& svg': {
      fontSize: '1rem',
      verticalAlign: 'text-top',
      marginLeft: 2,
      cursor: 'pointer',
    },
  },
  progressFill: {
    margin: '8px 0 15px 0',
    '&  div': {
      height: 38,
      width: '100%',
      backgroundColor: 'rgb(196,213,210)',
      borderRadius: 6,
    },
    '&  > div > div': {
      backgroundColor: 'rgb(29,88,77)',
    },
    '&[data-minimal="true"] div': {
      height: 25,
    },
  },
  legend: {
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto 0',
    },
    '& > div > div': {
      width: 18,
      height: 18,
      backgroundColor: 'rgb(29,88,77)',
      marginRight: 10,
      borderRadius: 4,
    },
    '& > div:last-of-type': {
      margin: 'auto 0 auto auto',
    },
    '& > div:last-of-type > div': {
      backgroundColor: 'rgb(196,213,210)',
    },
  },
  budgetTag: {
    color: 'rgb(143,141,139)',
  },
  loading: {
    width: '100%',
    margin: 0,
    borderRadius: 8,
  },
  legendKey: {
    fontSize: '1rem',
    fontFamily: 'Messina Sans Regular',
    '& span': {
      fontFamily: 'Messina Sans Bold',
    },
    '&[data-minimal="true"]': {
      fontSize: '0.8rem',
    },
  },
  tooltip: {
    maxWidth: 180,
    fontSize: '0.85rem',
    padding: 15,
    backgroundColor: 'rgb(50,50,50)',
  },
}));
