/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import _ from 'lodash';
import { Done, OpenInNew, Close, BookmarkBorder } from '@material-ui/icons';
// eslint-disable-next-line import/no-unresolved
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { useMutation, useQueryClient } from 'react-query';
import { TableRow, Typography } from '@material-ui/core';
import { useUpdateMedspaChartReviews } from 'src/hooks/queries/useMedspaAdmins';
import { MEDSPA_ADMIN_ROLE } from 'src/constants/general.constants';
import { useSelector } from 'react-redux';
import MedspaAdmins from 'src/services/MedspaAdmins';
import { ROUTES } from '../../../../constants/routes.constants';
import formatDate from '../../../../utils/formatDate';
import { TableCellCustom, useStyle as tableStyle } from '../TodoListTabs/rowItem.styles';
import { feedbackStyles, SquareButton } from './feedback.styles';
import { TableCellLink } from '../InsightsTab/RowItemReports';
import { ChartReviewProps } from '../../../../interfaces/IPractitionerChartReview';
import { useUpdateChartReviews } from '../../../../hooks/queries/usePractitionerChartReviews';
import ServiceVisit from '../../../../services/ServiceVisit';
import { MEDSPA_CHART_REVIEWS, PRACTITIONER_CHART_REVIEWS } from '../../../../constants/reactQuery.keys';

const NOTES_PLACEHOLDER = 'Approved without notes.';

const RowItem: React.FC<ChartReviewProps> = ({ row, showUnread, parentLoad }: ChartReviewProps): ReactElement => {
  const classes = { ...tableStyle(), ...feedbackStyles() };

  const {
    roleName,
    user: { userGroupId },
  } = useSelector(({ auth }: any) => auth);
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const queryClient = useQueryClient();
  const { mutateAsync: mutateChartAck } = useUpdateChartReviews();
  const { mutateAsync: mutateMedspaChartAck } = useUpdateMedspaChartReviews();
  const { mutateAsync: toggleSave } = useMutation({
    mutationFn: async (id: number): Promise<void> => ServiceVisit.toggleSave(id),
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries([PRACTITIONER_CHART_REVIEWS]);
    },
  });
  const { mutateAsync: toggleMedspaSave } = useMutation({
    mutationFn: async (id: number): Promise<void> =>
      MedspaAdmins.toggleMedspaChart({
        serviceVisitId: id,
        saved: !row.saved,
        userGroupId,
      }),
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries([MEDSPA_CHART_REVIEWS]);
    },
  });

  const handleAck = async (): Promise<void> => {
    if (isMedspaAdmin) {
      await mutateMedspaChartAck({
        acknowledged: !row.acknowledged,
        serviceVisitId: row.serviceVisitId,
        userGroupId,
      });
    } else {
      await mutateChartAck({
        acknowledged: !row.acknowledged,
        serviceVisitId: row.serviceVisitId,
      });
    }
  };

  const profileLink: string = ROUTES.PATIENT_ID(row.mrn);
  const visitLink: string = ROUTES.SERVICE_VISIT({
    mrn: row.mrn,
    serviceVisitId: row.serviceVisitId,
  });

  return (
    <>
      <TableRow className={[classes.ItemTable, parentLoad ? classes.dismissed : classes.feedbackRow].join(' ')}>
        <TableCellCustom>{formatDate(row.createdAt, 'MMM DD YYYY')}</TableCellCustom>

        <TableCellLink href={profileLink} text={String(row.mrn) || '_'} />

        <TableCellCustom>{_.uniqBy(row.services)?.join(', ') || ''}</TableCellCustom>

        <TableCellCustom>{row.cosignedBy}</TableCellCustom>

        <TableCellCustom>{row.cosignNotes || NOTES_PLACEHOLDER}</TableCellCustom>

        <TableCellCustom className={classes.highlightCell}>
          <SquareButton size="large" onClick={handleAck}>
            {showUnread ? <Done viewBox="0 0 22 22" /> : <Close viewBox="0 0 22 22" />}
            <Typography className={classes.readun}>mark {row.acknowledged ? 'unread' : 'read'}</Typography>
          </SquareButton>
          <SquareButton
            size="large"
            onClick={(): void => {
              window.open(visitLink, '_blank');
            }}
          >
            <OpenInNew viewBox="0 0 22 22" />
            <Typography className={classes.readun}>show visit</Typography>
          </SquareButton>
          <SquareButton
            size="large"
            onClick={async (): Promise<void> => {
              if (isMedspaAdmin) {
                await toggleMedspaSave(row.serviceVisitId);
              } else {
                await toggleSave(row.serviceVisitId);
              }
            }}
          >
            {row.saved ? <BookmarkIcon viewBox="0 0 22 22" /> : <BookmarkBorder viewBox="0 0 22 22" />}
            <Typography className={classes.readun}>Save</Typography>
          </SquareButton>
        </TableCellCustom>
      </TableRow>
    </>
  );
};

export { RowItem, RowItem as default };
