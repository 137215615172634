import React from 'react';
import moment from 'moment';

import { TableRow } from '@material-ui/core';
import { IDiscount } from '../../../interfaces/ITransaction.interfaces';
import { Pill } from '../../common/Pill';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { useRowStyles } from './transactions.styles';

interface DiscountRowProps {
  discount: IDiscount;
}

const DiscountRow: React.FC<DiscountRowProps> = ({ discount }: { discount: IDiscount }) => {
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCellCustom>{discount.checkoutId}</TableCellCustom>
        <TableCellCustom>{discount.serviceVisitId}</TableCellCustom>
        <TableCellCustom>{discount.checkoutLineItemId}</TableCellCustom>
        <TableCellCustom>{discount.customerId}</TableCellCustom>
        <TableCellCustom>{discount.practitioner}</TableCellCustom>
        <TableCellCustom>{moment(discount.date).format('MM/DD/YYYY')}</TableCellCustom>
        <TableCellCustom>{discount.value}</TableCellCustom>
        <TableCellCustom>{discount.discountType}</TableCellCustom>
        <TableCellCustom>{discount.description}</TableCellCustom>
        <TableCellCustom>{discount.discountName}</TableCellCustom>
        <TableCellCustom>{discount.discountId}</TableCellCustom>
        <TableCellCustom>{discount.servicePrice}</TableCellCustom>
        <TableCellCustom>{discount.actualDollarAmountDiscountedForPercentageType}</TableCellCustom>
        <TableCellCustom>
          <a href={discount.ehrUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
            <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
          </a>
        </TableCellCustom>
      </TableRow>
    </>
  );
};

export default DiscountRow;
