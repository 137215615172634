import { CONSENTS, CONSENT_SUGGESTIONS, PHOTO_CONSENT } from 'src/constants/apiRoutes.constants';
import Api from './Api';
import IConsent from '../interfaces/IConsent';

export interface IPractitionerConsent {
  id: string;
  practitionerId: null | number;
  content: string;
  name: string;
}

const Consents = {
  async getConsentTemplates(): Promise<IConsent[]> {
    const { consents } = await Api.get(CONSENT_SUGGESTIONS);
    return consents;
  },

  async getPractitionerConsents(): Promise<IPractitionerConsent[]> {
    const { consents, success } = await Api.get(CONSENTS);
    return success ? consents : [];
  },

  async getPractitionerConsent(id: number): Promise<IPractitionerConsent> {
    const { consent, success } = await Api.get(`${CONSENTS}/${id}`, {});
    return success ? consent : {};
  },

  async getPhotoConsent(): Promise<IConsent> {
    const { consent } = await Api.get(PHOTO_CONSENT, {});
    return consent;
  },

  async updatePractitionerConsent(params: { id: string; name: string; content: string }): Promise<boolean> {
    const { success } = await Api.put(`${CONSENTS}/${params.id}`, params);
    return success;
  },
  async createPractitionerConsent(params: {
    name: string;
    content: string;
  }): Promise<{ success: boolean; consent: IConsent }> {
    return Api.post(CONSENTS, params);
  },
};

export default Consents;
