import { makeStyles, withStyles, AccordionSummary, TableCell } from '@material-ui/core';

export const TableCellHeader = withStyles(() => ({
  root: {
    padding: '8px 16px',
    fontSize: 13,
    maxWidth: '100px',
    minWidth: '100px',
    borderBottom: 'none',
    fontWeight: 'normal',
    '&[data-large="true"]': {
      maxWidth: 'unset',
    },
  },
  '&:first-of-type': {
    maxWidth: '250px',
    minWidth: '250px',
  },
}))(TableCell);

export const IconLeftAccordionSummary = withStyles({
  root: {
    padding: '13px 16px',
    borderBottom: '1px solid #E8E8E8',
    overflow: 'auto',
  },
  expandIcon: {
    order: -1,
    marginRight: '-5px',
  },
  content: {
    overflow: 'auto',
  },
})(AccordionSummary);

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '18px',
    fontFamily: 'Messina Sans SemiBold',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    justifyContent: 'end',
    flexWrap: 'wrap',
    marginTop: '10px',
    gap: '30px',
  },
  formControl: {
    height: '46px',
    width: '100%',
  },
  infiScrollContainer: {
    width: '100%',
    marginTop: '10px',
  },
  checkbox: {
    color: '#12574d',
  },
  dropdown: {
    fontFamily: 'Messina Sans Regular',
    height: '40px',
    minWidth: '200px',
  },
  formDropdown: {
    display: 'flex',
    gap: '30px',
    flexDirection: 'row',
  },
  formInputSelect: {
    top: '-7px',
    color: '#000',
  },
  formControlLabel: {
    minWidth: 'fit-content',
  },
  tableGeneral: {
    position: 'relative',
  },
  bottomRef: {
    position: 'absolute',
    width: '100%',
    marginTop: '15px',
  },
  bottomRefHidden: {
    backgroundColor: 'black',
  },
  orderDate: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    textAlign: 'left',
    color: '#000000',
    fontFamily: 'Roboto',
    marginRight: '1rem',
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '4px',

    '& > div': {
      display: 'flex',
    },
  },
  accordionTag: {
    fontSize: '13px',
    fontFamily: 'Roboto',
    color: 'rgba(0, 0, 0, 0.48)',
  },
  orderMenu: {
    width: 20,
    height: 20,
    borderRadius: 4,
    backgroundColor: 'rgb(240,240,240)',
    '& svg': {
      fontSize: 20,
      color: '#111',
    },
  },
  orderMenuItem: {
    fontSize: '0.9rem',
  },
  delete: {
    color: '#D1505A',
  },
  orderMenuOpen: {
    margin: '45px 0 0 -40px',
    boxShadow: 'unset',
    border: '1px solid #ccc',
    borderRadius: 10,
    padding: 12,
  },
  historyRow: {
    '& td': {
      fontSize: '14px',
    },
    '& tr:last-child > td': {
      borderColor: '#fff0',
    },
  },
  orderNotes: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    '& textarea , p': {
      width: '100%',
      margin: 0,
      resize: 'none',
      borderRadius: '6px',
      minHeight: 180,
      padding: '16px',
      fontFamily: 'Messina Sans Regular',
      border: '1px solid #C8C8C8',
    },
    '& textarea:disabled': {
      color: '#000',
    },
    '& p:first-of-type': {
      minHeight: 'unset',
    },
    '& button': {
      marginLeft: '-55px',
      marginTop: '25px',
      zIndex: 10,
      fontSize: 30,
      display: 'none',
      '&[data-editing="true"]': {
        display: 'block',
      },
    },
  },
  noBorder: {
    border: 'none',
  },
  grayHeader: {
    '& *': {
      color: 'rgba(0, 0, 0, 0.48)',
      fontSize: '13px',
      fontFamily: 'Roboto',
    },
    '& th': {
      borderColor: '#fff0',
    },
  },
}));
