import { useQuery } from 'react-query';
import { PHYSICIANS, PHYSICIAN_INFO } from '../../constants/reactQuery.keys';
import { Physicians } from '../../services/Physicians';

export function usePhysicians(filter = {}, enabled: boolean = true) {
  return useQuery([PHYSICIANS, JSON.stringify(filter)], () => Physicians.getPhysicians(filter), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled,
  });
}

const defaultValues = {
  identity: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    magicLinkIssuer: '',
    roleId: 0,
    ui: '',
    createdAt: '',
    updatedAt: '',
    userId: 0,
    userType: 0,
    wasAnonymous: false,
  },
  professionalName: '',
  workingStates: [],
  medicalMemberId: 0,
  isAdvancedPractitioner: false,
  inactive: false,
  signatureImageUrl: '',
  canViewAllCustomers: false,
  assignedProviderIds: [],
};

export function usePhysicianInfo(id: number) {
  const {
    data: physicianInfo = defaultValues,
    isLoading,
    isError,
    isFetched,
  } = useQuery([PHYSICIAN_INFO, id], () => Physicians.getPhysicianInfo(id), { enabled: !!id });

  return {
    physicianInfo,
    isLoading,
    isError,
    isFetched,
  };
}

export { PHYSICIANS } from '../../constants/reactQuery.keys';
