import React, { useState } from 'react';

import { CircularProgress, List, ListItem, ListItemText } from '@material-ui/core';
import moment from 'moment';

import { useStyles } from './ProgressNotesList.styles';
import { IProgressNote, IProgressNotephoto, IProgressNoteProps } from '../../types/ProgressNoteTypes';
import { IPhoto } from '../../interfaces/global.interfaces';
import ProgressNotePhotoModal from './ProgressNotePhotoModal';

const ProgressNotesList = ({ progressListRef, progressNotes = [], isLoading }: IProgressNoteProps) => {
  const classes = useStyles();
  const [photoUrlSrc, setPhotoUrlSrc] = useState<IProgressNotephoto['src']>('');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const openModal = (largeUrl: string) => {
    setPhotoUrlSrc(largeUrl);
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setPhotoUrlSrc('');
  };

  const viewOriginal = () => {
    window.open(photoUrlSrc, '_blank');
  };

  return (
    <>
      <List
        id="progress-notes-list"
        className={classes.listNotes}
        ref={progressListRef}
        style={{ placeContent: 'flex-end' }}
      >
        {isLoading ? (
          <CircularProgress style={{ margin: 'auto' }} />
        ) : (
          progressNotes.map((note: IProgressNote) => (
            <ListItem className={classes.listItem} key={note.id}>
              <ListItem className={classes.listPhotos}>
                {note.photos &&
                  note.photos.length > 0 &&
                  note.photos.map((photoItem: IPhoto) => (
                    <div key={photoItem.id} onClick={() => openModal(photoItem.largeUrl)} aria-hidden>
                      <img src={photoItem.mediumUrl} alt="patient progress note" className={classes.photoItem} />
                    </div>
                  ))}
              </ListItem>
              <ListItemText
                primary={note.text}
                secondary={moment(note.createdAt).format('MM/DD/YYYY - hh:mm A')}
                className={classes.listItemNote}
              />
            </ListItem>
          ))
        )}
      </List>
      <ProgressNotePhotoModal
        isOpenModal={isOpenModal}
        photoUrlSrc={photoUrlSrc}
        closeModal={closeModal}
        viewOriginal={viewOriginal}
      />
    </>
  );
};

export default ProgressNotesList;
