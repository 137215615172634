import { useQuery } from 'react-query';
import { MedspaCalendar } from 'src/services/medspaAdmins/MedspaCalendar';

/* Update block off hour configuration */
export const useGetMedspaAddons = (userGroupId: number, successCallback?: (addons: any[]) => void) =>
  useQuery(['medspaCalendarAddons', userGroupId], () => MedspaCalendar.getMedspaAddons(userGroupId), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: ({ addons }) => {
      successCallback?.(addons);
    },
  });
