import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  table: {
    border: '1px solid #E7E7E7',
    '& td:first-child, th:first-child': {
      textAlign: 'left'
    }
  },
  practitionerNoteExpanded: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '14px',
    lineHeight: '23px',
    color: '#000000',
    mixBlendMode: 'normal',
    opacity: 0.34
  },
  headerItem: {
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000000'
  },
  tableBodyItem: {
    fontFamily: 'Messina Sans Regular',
    textAlign: 'center',
    borderBottom: 'none'
  },
  menuIcon: {
    border: '1px solid #E4E7EB',
    borderRadius: '2px',
    fontSize: '36px'
  },
  noteSection: {
    margin: '-2px 10px 10px 93px'
  },
  noteLabel: {
    fontSize: '15px',
    lineHeight: '18px',
    color: '#000000'
  },
  collapseTableCell: {
    paddingBottom: 0,
    paddingTop: 0
  }
}));
