import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  gfeManagementPage: {
    padding: '2rem',
    background: '#fafafa',
  },
  settingsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  settingsDescription: {
    width: '45%',
  },
  settingsOptionsContainer: {
    width: '45%',
  },
  title: {
    fontFamily: 'Messina Sans Bold',
    fontSize: '16px',
  },
  subTitle: {
    marginTop: '0.5rem',
    color: '#999999',
    fontSize: '14px',
  },
  selectedSettingsOption: {
    border: '1px solid #29564D',
    padding: '1rem',
    background: '#E8EEED',
    marginBottom: '1.5rem',
    borderRadius: '8px',
    fontFamily: 'robotoMedium',
  },
  settingsOption: {
    border: '1px solid #CFCFCF',
    padding: '1rem',
    background: 'white',
    marginBottom: '1.5rem',
    borderRadius: '8px',
  },
  radioButton: {
    alignSelf: 'baseline',
    position: 'relative',
    top: '-8px',
  },
}));
