import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CardContent } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { RootState } from 'src/rematch';
import ContactInfo from './ContactInfo';
import MedicalProfile from './MedicalProfile';
import SkinProfile from './SkinProfile';
import { Card } from '../../common/card';
import { formatMedicalProfileInfo, formatSkinProfileInfo } from '../../../utils/patient.util';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { useCustomMedicalProfile } from '../../../hooks/queries/useCustomMedicalProfile';
import NotesContainerHeader from './Notes/NotesContainerHeader';
import { CUSTOMERS_LIST, MEDSPA_PATIENTS_VIEW, PATIENT_INFO } from '../../../constants/reactQuery.keys';

type Props = {
  hideContactInfo?: boolean;
  isMedspaAdmin?: boolean;
};

const MainSection = ({ hideContactInfo = false, isMedspaAdmin = false }: Props) => {
  const { patientId }: { patientId: string } = useParams();
  const patientData = useSelector(({ patient }: RootState) => patient);
  const { isLoadingMedicalProfile } = patientData;
  const queryClient = useQueryClient();

  const { data: medicalProfile } = useCustomMedicalProfile(patientId);
  const medicalProfileInfo = formatMedicalProfileInfo(medicalProfile || {});
  const skinProfileInfo = formatSkinProfileInfo(medicalProfile || {});

  const onCompleteCallback = () => {
    queryClient.invalidateQueries([MEDSPA_PATIENTS_VIEW]);
    queryClient.invalidateQueries([CUSTOMERS_LIST]);
    queryClient.invalidateQueries([PATIENT_INFO, patientId]);
  };

  return (
    <Card>
      {!hideContactInfo && patientId && <ContactInfo patientId={patientId} isMedspaAdmin={isMedspaAdmin} />}

      {isLoadingMedicalProfile ? (
        <CardContent style={{ padding: '12px 15px' }}>
          <ShortMultipleSkeleton length={4} />
        </CardContent>
      ) : (
        <MedicalProfile
          {...medicalProfileInfo}
          sex={medicalProfile?.sex}
          needsDoctorReview={medicalProfile?.needsDoctorReview}
          patientId={patientId}
          isMedspaAdmin={isMedspaAdmin}
        />
      )}

      {isLoadingMedicalProfile ? (
        <CardContent style={{ padding: '12px 15px' }}>
          <ShortMultipleSkeleton length={4} />
        </CardContent>
      ) : (
        <SkinProfile {...skinProfileInfo} id={patientId} isMedspaAdmin={isMedspaAdmin} />
      )}
      <CardContent>
        <NotesContainerHeader patientId={patientId} patient={patientData} onCompleteCallback={onCompleteCallback} />
      </CardContent>
    </Card>
  );
};

export default MainSection;
