/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Dialog,
  Input,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Edit, Comment, Done } from '@material-ui/icons';
import {
  DISPLAY_DATE_FORMAT_VARIANT,
  FULLSCREEN,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_REQUESTED,
} from 'src/constants/inventory.constants';
import { orderHasShipped } from 'src/utils/inventoryOrdering';
import { formatCurrency } from 'src/utils/formatNumber';
import TrashCan from 'src/components/common/TrashCan';
import { TableCellHeader, useStyle } from '../../../DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import {
  InventoryOrderService,
  OrderAdminDialogParams,
  OrderStatusParams,
  IOrderNoteParams,
} from '../../../../interfaces/IInventoryOrder';
import {
  useUpdateAdminInventoryOrder,
  useRemoveInventoryOrderItem,
} from '../../../../hooks/queries/useInventoryOrders';

export const TableHeader = ({ status, isLegacy = false, hasCustomItems = false }: OrderStatusParams) => {
  const classes = useStyle();

  const pending = status === ORDER_STATUS_REQUESTED;

  return (
    <TableHead>
      <TableRow>
        <TableCellHeader>Product Name</TableCellHeader>
        <TableCellHeader className={classes.headerPad}>Price</TableCellHeader>
        <TableCellHeader className={classes.headerPad}>Quantity</TableCellHeader>
        {isLegacy && pending ? <TableCellHeader>Recommended</TableCellHeader> : null}
        {hasCustomItems && <TableCellHeader>Custom Price</TableCellHeader>}
        {orderHasShipped(status) ? <TableCellHeader>Completion Date</TableCellHeader> : null}
        <TableCellHeader className={classes.functional} />
      </TableRow>
    </TableHead>
  );
};

export const OrderNotes = ({ id, adminNote, setAdminNote }: IOrderNoteParams) => {
  const classes = useStyle();

  const handleChange = (event: React.ChangeEvent): void => {
    setAdminNote?.((event.currentTarget as HTMLInputElement).value || '');
  };

  return (
    <Box className={classes.orderNotes}>
      <textarea
        onChange={handleChange}
        placeholder="Your note"
        value={adminNote ?? ''}
        data-testid={`admin note ${id}`}
      />
    </Box>
  );
};

export const OrderApprovalSubRow = ({
  id,
  inventoryOrderId,
  serviceId,
  name,
  quantity,
  recommendation,
  status,
  notes,
  finalizedAt,
  createdAt,
  deliveredAt,
  unitPrice,
  allowPartialSale,
  startingUnits,
  isLegacy,
}: { finalizedAt: string | null } & InventoryOrderService) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<number | undefined>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [removing, setRemoving] = useState<boolean>(false);

  const { mutateAsync: updateItem } = useUpdateAdminInventoryOrder(serviceId, inventoryOrderId || 0);

  const { mutateAsync: removeItem } = useRemoveInventoryOrderItem(id);

  const isLoading = removing || (!editMode && userInput && quantity && quantity !== userInput);

  const matches = useMediaQuery(FULLSCREEN);
  const classes = useStyle();

  const itemStatus = deliveredAt ? ORDER_STATUS_DELIVERED : status || ORDER_STATUS_REQUESTED;

  const pending = itemStatus === ORDER_STATUS_REQUESTED;

  const handleRemove = async (event: React.MouseEvent): Promise<void> => {
    event?.preventDefault();
    setRemoving(true);
    await removeItem();
  };

  const handleChange = (event: React.ChangeEvent): void => {
    event.preventDefault();
    const target = event.currentTarget as HTMLInputElement;
    !Number.isNaN(+target.value) && setUserInput(+target.value);
  };

  const handleSave = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    if (!!userInput || userInput === 0) {
      setEditMode(false);
      await updateItem(userInput);
    }
  };

  const toggleDialog = (event?: React.MouseEvent) => {
    event?.preventDefault();
    setDialogOpen(!dialogOpen);
  };

  useEffect(() => {
    quantity && setUserInput(quantity);
  }, [quantity]);

  const editIcons = !editMode ? (
    <Edit
      className={classes.approveEdit}
      viewBox="0 0 22 22"
      onClick={() => setEditMode(!editMode)}
      data-testid={`order item ${inventoryOrderId} ${serviceId} edit`}
    />
  ) : (
    <Done
      className={classes.approveSave}
      viewBox="0 0 22 22"
      onClick={handleSave}
      data-testid={`order item ${inventoryOrderId} ${serviceId} save`}
    />
  );

  return (
    <TableRow
      className={classes.pulseOnly}
      data-loading={isLoading}
      data-testid={`order item ${inventoryOrderId} ${serviceId} row`}
    >
      <TableCell
        className={classes.approvalRowName}
        data-over-recommendation={pending && (!recommendation || quantity > recommendation)}
        data-testid={`order item ${inventoryOrderId} ${serviceId} name`}
      >
        {name}
      </TableCell>
      <TableCell>
        {formatCurrency((allowPartialSale ? startingUnits || 1 : 1) * (unitPrice ?? 0) * (quantity ?? 0))}
      </TableCell>
      <TableCell>
        <Input
          value={userInput}
          defaultValue={quantity}
          onChange={handleChange}
          className={classes.qtyInput}
          disableUnderline={!pending || !editMode}
          disabled={!pending || !editMode}
          data-testid={`order item ${inventoryOrderId} ${serviceId} input`}
        />
        {pending && editIcons}
        {!editMode && notes && (
          <Comment
            className={classes.approveEdit}
            viewBox="0 0 22 22"
            onClick={toggleDialog}
            style={{ marginLeft: '5px' }}
            data-testid={`order item ${serviceId} note button`}
          />
        )}
        <Dialog
          open={dialogOpen}
          maxWidth="lg"
          children={<ProviderNotesDialog dismiss={toggleDialog} text={notes} />}
        />
      </TableCell>
      {isLegacy && pending ? (
        <TableCell data-testid={`order item ${serviceId} recommendation`}>{recommendation}</TableCell>
      ) : null}

      <TableCell className={classes.smallText}>
        {moment(finalizedAt ?? createdAt).format(DISPLAY_DATE_FORMAT_VARIANT)}
      </TableCell>

      {orderHasShipped(itemStatus) ? (
        <TableCell className={classes.smallText}>
          {deliveredAt ? moment(deliveredAt).format(DISPLAY_DATE_FORMAT_VARIANT) : '--'}
        </TableCell>
      ) : null}

      <TableCell className={classes.removeItem}>
        {pending ? (
          <Box onClick={handleRemove} style={{ cursor: 'pointer' }} data-testid={`order item ${serviceId} remove`}>
            <TrashCan />
            {matches && 'Remove'}
          </Box>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export const ProviderNotesDialog = ({ text, dismiss }: OrderAdminDialogParams) => {
  const classes = useStyle();

  const back = (event: React.MouseEvent) => {
    event.preventDefault();
    dismiss();
  };

  return (
    <Box className={classes.periodConfirmDialog} data-testid="order item note modal">
      <Typography variant="h5">Order Note</Typography>
      <Typography>{text}</Typography>
      <Box style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Button
          data-cy="view"
          variant="outlined"
          data-missingback
          className={classes.actionButton}
          onClick={back}
          style={{ margin: 'auto 0 0 0' }}
        >
          {' '}
          back{' '}
        </Button>
      </Box>
    </Box>
  );
};
