import { makeStyles } from '@material-ui/core';

const ConversationsWrapper = makeStyles((theme) => ({
  chatSection: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  threadsArea: {
    overflowY: 'scroll',
    flexGrow: 1,
  },
  messageArea: {
    height: 'calc(85vh - 5rem )',
    overflowY: 'scroll',
  },
  messageAreaClosed: {
    height: 'calc(85vh - 2rem)!important',
    overflowY: 'scroll',
  },
  textMuted: {
    color: theme.palette.text.secondary,
  },
  roundedButton: {
    margin: theme.spacing(1),
    borderRadius: '5em',
  },
  closeButton: {
    top: '135px',
    position: 'absolute',
    right: '15px',
    marginRight: '1rem',
    zIndex: 100,
  },
}));

export default ConversationsWrapper;
