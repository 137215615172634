import { IAdCampaign, IAdCampaignUpdate, IAdCampaignLookup } from 'src/interfaces/IAdCampaign';
import Api from './Api';
import API_ROUTES from '../constants/apiRoutes.constants';

const AdCampaign = {
  async getAdCampaigns(params: IAdCampaignLookup): Promise<IAdCampaign[]> {
    const { success, data }: { success: boolean; data: IAdCampaign[] } = await Api.get(API_ROUTES.AD_CAMPAIGNS, params);
    return success ? data : [];
  },

  async createAdCampaign(params: IAdCampaignUpdate): Promise<void> {
    await Api.put(API_ROUTES.AD_CAMPAIGNS, params);
  },

  async updateAdCampaign(params: IAdCampaignUpdate): Promise<void> {
    params.id && (await Api.post(API_ROUTES.UPDATE_AD_CAMPAIGNS(params.id), params));
  },
};

export { AdCampaign, AdCampaign as default };
