import { createModel } from '@rematch/core';
import { CustomerParams } from '../interfaces/CustomerParams';
import { PractitionerState } from '../types/PractitionerState';
import Customers from '../services/Customers';
import { RootModel } from './rootModel';

const myInitialState: CustomerParams = {
  dashboard: 'practitioner',
  group: 'cleared',
  view: 'allPatients',
  filter: 'all',
  page: 1,
  search: '',
  sortBy: 'created_at DESC',
  version: 'v2',
};

const INITIAL_STATE = {
  undefined: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
  cleared: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
  pendingReclear: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
  notCleared: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
  incomplete: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
};

export const practitioner = createModel<RootModel>()({
  state: {
    ...INITIAL_STATE,
  } as PractitionerState,
  reducers: {
    updateByGroup(state: any, payload: any) {
      return { ...state, [payload.group]: { ...state[payload.group], ...payload } };
    },
    updateState(state: any, payload: any) {
      return { ...state, ...payload };
    },
    setReset() {
      return { ...INITIAL_STATE };
    },
  },
  effects: (dispatch: any) => ({
    async getPatients(payload: CustomerParams, rootState: any) {
      try {
        let results = [];
        const response = await Customers.getCustomers({ ...myInitialState, ...payload });
        if (
          response?.meta?.currentPage !== rootState.practitioner[payload.group!].currentPage &&
          response?.meta?.currentPage !== 1
        ) {
          /* if we go from page 
          one to two, we concatenate, but if the "search" or "sort" change and the previous request
           * was left on page two, we do not concatenate */
          results = [...rootState.practitioner[payload.group!].results, ...response.customers];
        } else {
          results = [...response.customers];
        }

        const setPayload = {
          results,
          currentPage: response.meta.currentPage,
          totalPages: response.meta.totalPages,
          totalCount: response.meta.totalCount,
          hasMorePages:
            response.meta.currentPage !== response.meta.totalPages &&
            response.meta.currentPage <= response.meta.totalPages,
        };
        dispatch.practitioner.updateByGroup({ group: payload.group, ...setPayload });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  }),
});
