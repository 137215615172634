import React, { FC, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  TablePagination,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Page from 'src/components/common/Page';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import PurchaseOrdersRow from './PurchaseOrdersRow';
import PurchaseOrdersHeader from './PurchaseOrdersHeader';
import DialogModal from '../../common/DialogModal';
import { dispatch } from '../../../rematch';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';
import { DATAMATRIX_PER_PAGE, DEFAULT_ROWS_PER_PAGE } from '../../../constants/general.constants';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import { downloadAssetPdf, getAssetsPages } from '../../../utils/datamatrix';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { useCustomPurchaseOrders } from '../../../hooks/queries/useCustomPurchaseOrders';
import compile from '../../../utils/toastMessagesCompiler';

const countSelection = (purchaseOrders: any[]) => {
  let assets = 0;

  purchaseOrders.forEach((purchaseOrder: any) => {
    purchaseOrder.purchaseOrderItems.forEach((item: any) => {
      assets += item.assets.length;
    });
  });

  const pages = Math.ceil(assets / DATAMATRIX_PER_PAGE);
  const remainingSpaces = pages * DATAMATRIX_PER_PAGE - assets;
  const wasted = (100 * remainingSpaces) / DATAMATRIX_PER_PAGE;
  return {
    assets,
    pages,
    wasted,
    percentageUsed: 100 - wasted,
  };
};

export interface IFilters {
  orderByDate?: string;
  search?: string;
  page?: number;
  waitingToReceive?: boolean;
  provider?: string;
  supplier?: string;
}

export const defaultQuery: IFilters = {
  orderByDate: 'desc',
  search: '',
  page: 0,
  waitingToReceive: false,
  provider: '',
  supplier: '',
};

export interface PurchaseOrdersPageProps {}

const PurchaseOrdersPage: FC<PurchaseOrdersPageProps> = () => {
  const [purchaseSelected, setPurchaseSelected] = useState<number[]>([]);
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [query, setQuery] = useState<IFilters>(defaultQuery);
  const history = useHistory();
  const { purchaseOrders, purchaseOrdersCount, isLoading, isFetching, isError } = useCustomPurchaseOrders(query);

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'purchase orders',
        }),
        type: 'error',
      },
    });
  }

  const handleChangePage = (event: any, newPage: any) => {
    setQuery({ ...query, page: newPage });
  };

  const emptyRows = DEFAULT_ROWS_PER_PAGE - purchaseOrders.length;

  const handleOrder = () => {
    setQuery({ ...query, orderByDate: query.orderByDate === 'asc' ? 'desc' : 'asc' });
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleCheck = (value: number) => {
    let newSelected;
    const exist = purchaseSelected.some((id: number) => id === value);
    if (exist) {
      newSelected = purchaseSelected.filter((id: number) => id !== value);
    } else {
      newSelected = [...purchaseSelected, value];
    }
    setPurchaseSelected(newSelected);
  };

  const MyBreadcrumb = (
    <Box paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BreadcrumbsContainer>
          <BreadcrumbText text="Inventory" />
          <BreadcrumbText text="Purchase orders" isActive linkTo="/administrator/purchase-orders" />
        </BreadcrumbsContainer>
      </Box>
    </Box>
  );

  const navigateToProductIdentifiers = (purchaseOrderId: number, serviceId: number, id: number) => {
    const successCallback = () => {
      history.push(`/administrator/purchase-order/${purchaseOrderId}/product-identifiers/${id}/${serviceId}`);
    };
    dispatch({
      type: 'purchaseOrders/getPurchaseOrder',
      payload: {
        purchaseOrderId,
        successCallback,
      },
    });
  };

  const confirm = () => {
    setLoadingPdf(true);
    let assets: IAsset[] = [];
    const ordersSelected = purchaseOrders.filter(({ id }: any) => purchaseSelected.includes(id));
    ordersSelected.forEach((purchaseOrder: any) => {
      purchaseOrder.purchaseOrderItems.forEach((item: any) => {
        assets = assets.concat(item.assets);
      });
    });
    const pagesAssetsList = getAssetsPages(assets);
    if (!pagesAssetsList.length) {
      return;
    }
    const fileConcatName = `${ordersSelected.map((item: any) => item.id).join('-')}`;

    downloadAssetPdf(pagesAssetsList, fileConcatName, () => {
      toggleModal();
      setLoadingPdf(false);
    });
  };

  const MyTable = isLoading ? (
    <MultipleSkeleton />
  ) : (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCellHeader>Purchase Order Id</TableCellHeader>
              <TableCellHeader>Reference Number</TableCellHeader>
              <TableCellHeader>Note</TableCellHeader>
              <TableCellHeader>Type</TableCellHeader>
              <TableCellHeader>Supplier</TableCellHeader>
              <TableCellHeader onClick={() => handleOrder()} style={{ cursor: 'pointer' }}>
                Creation Date
              </TableCellHeader>
              <TableCellHeader>Closed Date</TableCellHeader>
              <TableCellHeader>Status</TableCellHeader>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseOrders.map((purchaseOrder: any) => (
              <PurchaseOrdersRow
                key={purchaseOrder.id}
                id={purchaseOrder.id}
                supplierName={purchaseOrder.supplierName}
                referenceNumber={purchaseOrder.referenceNumber}
                internalNote={purchaseOrder.internalNote}
                orderType={purchaseOrder.orderType ? purchaseOrder.orderType : ''}
                date={moment(purchaseOrder.createdAt).format('MM/DD/YYYY')}
                closedDate={moment(purchaseOrder.updatedAt).format('MM/DD/YYYY')}
                status={purchaseOrder.status}
                dataCollapse={purchaseOrder.purchaseOrderItems}
                iconCallback={navigateToProductIdentifiers}
                handleCheck={handleCheck}
              />
            ))}
            {Boolean(emptyRows) && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
          component="div"
          count={purchaseOrdersCount}
          rowsPerPage={DEFAULT_ROWS_PER_PAGE}
          page={query.page as number}
          onChangePage={handleChangePage}
        />
      </TableContainer>
    </Box>
  );

  const dataPO = countSelection(purchaseOrders.filter(({ id }: any) => purchaseSelected.includes(id)));

  const onChangeFilters = (event: React.ChangeEvent<any>): void => {
    const propertyName: string = event.target.name as string;
    setQuery((prevState) => ({
      ...prevState,
      [propertyName]: Object.hasOwn(event.target, 'checked') ? event.target.checked : event.target.value,
      page: defaultQuery.page,
    }));
  };

  const onChangeSearch = (newSearch: string) => {
    setQuery((prevState) => ({
      ...prevState,
      search: newSearch,
      page: defaultQuery.page,
    }));
  };

  return (
    <Page title="Purchase Orders">
      <Box width="100%">
        {MyBreadcrumb}
        <PurchaseOrdersHeader
          toggleModal={toggleModal}
          data={dataPO}
          isFetching={isFetching}
          waitingToReceive={query.waitingToReceive}
          provider={query.provider}
          supplier={query.supplier}
          onChangeFilters={onChangeFilters}
          onChangeSearch={onChangeSearch}
        />
        <DialogModal
          open={openModal}
          closeModal={toggleModal}
          confirm={dataPO.assets ? confirm : undefined}
          confirmationText={dataPO.assets ? 'Are you sure you want to do it?' : undefined}
        >
          {dataPO.assets ? (
            <span>
              {!loadingPdf ? (
                <Typography style={{ fontWeight: 'normal', fontFamily: 'Messina Sans Regular' }}>
                  You are about to print{' '}
                  <b>
                    {dataPO.assets} labels on {dataPO.pages} page(s)
                  </b>
                  , for which you will use <b>{dataPO.percentageUsed.toFixed()}%</b> of the material and
                  <b> have a material waste of {dataPO.wasted.toFixed()}%</b>.
                </Typography>
              ) : (
                <CircularProgress size={30} />
              )}
            </span>
          ) : (
            <Typography style={{ fontSize: '138%' }}>Please select some Purchase Orders</Typography>
          )}
        </DialogModal>
        {MyTable}
      </Box>
    </Page>
  );
};

export default PurchaseOrdersPage;
