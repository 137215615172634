import React, { ReactElement, useState, useEffect } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableBody, TablePagination, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ADMIN_ROLE, MEDSPA_ADMIN_ROLE } from 'src/constants/general.constants';
import { useMedspaChartReviews } from 'src/hooks/queries/useMedspaAdmins';
import { RowItem } from './RowItemFeedback';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import { SelectList as ReportList, SelectListItems } from '../../../common/SelectList';
import { feedbackStyles } from './feedback.styles';
import { ChartReview, IUpdateChartNote } from '../../../../interfaces/IPractitionerChartReview';
import { useChartReviews } from '../../../../hooks/queries/usePractitionerChartReviews';
import { TableCellHeader } from '../MarketingTab/marketing.styles';

interface TableFeedbackProps {
  practitionerId?: string;
  userGroupId?: string;
}

const TableFeedback = ({ practitionerId, userGroupId }: TableFeedbackProps): ReactElement => {
  const { userType, roleName } = useSelector(({ auth }: any) => auth);
  const isMedspaRole = userType === ADMIN_ROLE && roleName === MEDSPA_ADMIN_ROLE;

  const [showUnread, setShowUnread] = useState<boolean>(true);
  const [selected, setSelected] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowTotal, setRowTotal] = useState<number>(0);
  const [showSaved, setShowSaved] = useState<boolean>(false);

  const practitionerChartData = useChartReviews({
    showSaved,
    showUnread,
    page,
    enabled: !isMedspaRole,
  } as IUpdateChartNote & { enabled: boolean });

  const medspaChartData = useMedspaChartReviews({
    practitionerId,
    userGroupId,
    showSaved,
    showUnread,
    page,
    enabled: isMedspaRole && !!userGroupId,
  } as IUpdateChartNote & { userGroupId: string; practitionerId: string; enabled: boolean });

  const {
    data: chartData,
    isLoading,
    isRefetching,
    isFetching,
  } = isMedspaRole ? medspaChartData : practitionerChartData;

  useEffect(() => {
    chartData && setRowTotal(chartData[0]?.total || 0);
  });

  const classes = feedbackStyles();

  const handleSelect = (t: string | null): void => {
    setSelected(t);
    setPage(0);
    if (t === 'saved') {
      setShowSaved(true);
    }
    setShowUnread(t === 'unread');
    setShowSaved(false);
  };

  const handleChangePage = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const filterOptions: SelectListItems = {
    unread: 'Show Unread',
    read: 'Show Read',
    saved: 'Show Saved',
  };

  const defaultFilter = Object.keys(filterOptions)[0];

  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
      <Box className={classes.navbar}>
        <ReportList
          title="filter"
          listItems={filterOptions}
          setSelected={handleSelect}
          selected={selected || defaultFilter}
        />
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={rowTotal}
          rowsPerPage={10}
          page={page}
          onChangePage={handleChangePage}
          className={classes.pager}
        />
      </Box>

      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCellHeader data-cy="todoPatientColumnTitle">Date</TableCellHeader>
            <TableCellHeader data-cy="todoDateColumnTitle">MRN</TableCellHeader>
            <TableCellHeader data-cy="todoDateColumnTitle" width={200}>
              Services
            </TableCellHeader>
            <TableCellHeader data-cy="todoPatientIdColumnTitle">Physician </TableCellHeader>
            <TableCellHeader data-cy="todoPatientColumnTitle" width={400}>
              Notes
            </TableCellHeader>
            <TableCellHeader data-cy="todoActionColumnTitle" width={160}>
              Actions
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody data-cy="todoTableBody">
          {isLoading ? (
            <MultipleSkeleton />
          ) : (
            (chartData || []).map(
              (row: ChartReview): ReactElement => (
                <RowItem
                  key={row.mrn}
                  row={row}
                  showUnread={showUnread}
                  page={page}
                  parentLoad={isLoading || isRefetching || isFetching}
                />
              )
            )
          )}
        </TableBody>
      </Table>
      {isLoading ? null : (
        <Box className={classes.navbar}>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rowTotal}
            rowsPerPage={10}
            page={page}
            onChangePage={handleChangePage}
            className={classes.pager2}
          />
        </Box>
      )}
    </TableContainer>
  );
};

export { TableFeedback, TableFeedback as default };
