import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { toast } from 'react-toastify';
import { dispatch } from 'src/rematch';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { SALES_ROUND_ROBIN } from 'src/constants/general.constants';
import { NEW_WINDOW } from 'src/constants/clientLead.constants';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'src/hooks/useQueryParams';
import Analytics from 'src/utils/analytics';
import { TRACK_EVENT_SELF_SIGN_UP_LITE } from 'src/constants/analytics.constants';
import { cloneDeep } from 'lodash';
import { EHRCheckbox } from 'src/components/ui/v1';
import Api from 'src/services/Api';
import ROUTES, { REGISTRATION_LITE_MEDSPA_SIGN_UP_WAIT_PAGE } from 'src/constants/apiRoutes.constants';
import { getFormData } from 'src/services/UserGroups';
import { useValidMedspaAdminInvitation } from 'src/hooks/mutations/medspaAdmins/useCreateInvitation';
import toastMessagesCompiler from 'src/utils/toastMessagesCompiler';
import axios from 'axios';
import { ONBOARDING_BASE_PATH } from 'src/routes/medspaOnboardingRoutes';
import { UserForm } from './UserForm';
import { useOperatingProviderStyles, useStyles } from './index.styles';
import { schemaSignUp } from './schemaForm';
import { PortraitMockUp } from './PortraitMockUp';
import { PasswordForm } from './PasswordForm';
import { OperatingProviderForm } from '../../Medspas/OperatingProviderForm';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  businessName?: string;
  provisionProviderAccount?: boolean;
  position?: string;
  licenses?: string;
  signatureImage: File | null;
  isOperatingProvider: boolean;
}

const bookAConsult = SALES_ROUND_ROBIN;
const openInNewWindow = () => window.open(bookAConsult, NEW_WINDOW);

export const Signup = () => {
  const history = useHistory();
  const classes = useStyles();
  const { queryParams } = useQueryParams();
  const providerClasses = useOperatingProviderStyles();

  const { getAllParamsStartingWith } = useQueryParams();

  const [saving, setSaving] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [selectedLookingToDoOption, setSelectedLookingToDoOption] = useState('');
  const [invitationCodeEmail, setInvitationCodeEmail] = useState<string>('');

  const [isOperatingProvider, setIsOperatingProvider] = useState<boolean>(false);
  const [signaturePreview, setSignaturePreview] = useState<string | null>(null);
  const [signatureImageFile, setSignatureImageFile] = useState<File>();

  const { mutateAsync: isValidInvitation } = useValidMedspaAdminInvitation();

  const code = queryParams.get('code');

  const [trackingParams] = useState({
    ...getAllParamsStartingWith('utm_'),
    url: window.location.href,
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(schemaSignUp),
    context: { invitationCodeEmail },
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      businessName: '',
      isOperatingProvider: false,
      position: '',
      licenses: '',
      signatureImage: null,
    },
  });

  const { register, errors, setValue, control, handleSubmit } = methods;

  const handleSelect = (lookingToDo: string) => setSelectedLookingToDoOption(lookingToDo);

  const handleRegistration = async (data: FormData) => {
    try {
      if (!selectedLookingToDoOption) {
        toast.error('Must select what are you looking for to continue.');
        return;
      }

      if (password !== passwordConfirmation) {
        toast.error('Passwords do not match');
        return;
      }

      Analytics.track(TRACK_EVENT_SELF_SIGN_UP_LITE.CLICK_REGISTER, cloneDeep(trackingParams));
      setSaving(true);

      const params = {
        ...data,
        password,
        selectedLookingToDoOption,
        signatureImage: signatureImageFile,
        provisionProviderAccount: isOperatingProvider,
        email: data.email || invitationCodeEmail,
        token: code,
      };

      const response = await Api.post(ROUTES.CREATE_MEDSPA_ADMIN_REGISTRATION, getFormData(params));
      const formErrors = response?.errors;

      if (formErrors) {
        const messages: string[] = [];
        formErrors.forEach((message: string) => {
          messages.push(message);
          toast.error(message);
        });
        Analytics.track(
          TRACK_EVENT_SELF_SIGN_UP_LITE.REGISTER_FAIL,
          cloneDeep({
            ...trackingParams,
            raw: messages.join(', '),
          })
        );
      } else {
        Analytics.track(
          TRACK_EVENT_SELF_SIGN_UP_LITE.REGISTER_SUCCESS,
          cloneDeep({
            ...trackingParams,
            raw: data,
          })
        );

        // if the user has a valid invitation code, we send them to the onboarding page
        if (!invitationCodeEmail) {
          history.push(REGISTRATION_LITE_MEDSPA_SIGN_UP_WAIT_PAGE);
        } else {
          dispatch.auth.logout();
          dispatch.auth.loginFromCreatePassword({ data: response.data });
          history.push(ONBOARDING_BASE_PATH);
        }
      }
    } catch (err) {
      const error = err as Error;
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: 'There was an error, please try again later.',
          type: 'error',
          duration: 5000,
        },
      });
      Analytics.track(TRACK_EVENT_SELF_SIGN_UP_LITE.REGISTER_EXCEPTION, cloneDeep({ ...trackingParams, raw: error }));
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (code) {
      const isValidInvitationAsync = async () => {
        try {
          const { success, data } = await isValidInvitation(code);
          if (success && data) {
            setValue('email', data);
            setInvitationCodeEmail(data);
          }
        } catch (error) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: toastMessagesCompiler('generic.error_message', {
                action: axios.isAxiosError(error) ? '' : 'accepting',
                element: axios.isAxiosError(error) ? error.response?.data?.error : 'invitation link',
              }),
              type: 'error',
            },
          });
        }
      };

      isValidInvitationAsync();
    }
  }, [code]);

  useEffect(() => {
    Analytics.track(TRACK_EVENT_SELF_SIGN_UP_LITE.PAGE_LOAD, cloneDeep(trackingParams));
  }, []);

  useEffect(() => {
    setValue('isOperatingProvider', isOperatingProvider);
  }, [isOperatingProvider]);

  return (
    <div className={classes.signupContainer}>
      <div className={classes.left}>
        <div className={classes.leftInside}>
          <PortraitMockUp />
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.signupDetails}>
          <div className={classes.btnContainer}>
            <p data-testid="text-have-a-question">Have a question?</p>
            <Button onClick={openInNewWindow} className={classes.btn} data-testid="btn-book-consult">
              Book a consult
            </Button>
          </div>

          <form className={classes.signupForm} onSubmit={handleSubmit(handleRegistration)}>
            <UserForm
              errors={errors}
              register={register}
              setValue={setValue}
              selectedLookingToDoOption={selectedLookingToDoOption}
              handleSelect={handleSelect}
              disabledEmail={Boolean(invitationCodeEmail)}
            />
            <div style={{ height: '2rem' }} />
            <PasswordForm
              password={password}
              passwordConfirmation={passwordConfirmation}
              setPassword={setPassword}
              setPasswordConfirmation={setPasswordConfirmation}
              show={false}
            />

            <Box marginBottom="1.375rem">
              <Controller
                name="isOperatingProvider"
                control={control}
                defaultValue={false}
                render={() => (
                  <EHRCheckbox
                    dataCy="label-is-operating-provider"
                    label="Active Provider"
                    checked={isOperatingProvider}
                    onChange={(e) => setIsOperatingProvider(e.target.checked)}
                    className={providerClasses.operatingProviderCheckbox}
                  >
                    <Typography variant="body2" className={providerClasses.operatingProviderSubtext}>
                      I will provide aesthetic services to patients.
                    </Typography>
                  </EHRCheckbox>
                )}
              />
            </Box>

            {isOperatingProvider && (
              <OperatingProviderForm
                methods={methods}
                signaturePreview={signaturePreview}
                setSignaturePreview={setSignaturePreview}
                setSignatureImageFile={setSignatureImageFile}
              />
            )}

            <div style={{ marginBottom: '10px' }}>
              <Typography style={{ color: 'grey', fontSize: '12px' }}>
                Fields marked with * are required to create an account.
              </Typography>
            </div>
            <button
              type="submit"
              className={classes.signupButton}
              disabled={saving || !password || !passwordConfirmation}
            >
              {saving ? (
                <>
                  Please wait...
                  <CircularProgress style={{ color: 'white', marginLeft: '10px' }} size={20} />
                </>
              ) : (
                'Submit'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
