import { createModel } from '@rematch/core';
import { logSentryException } from 'src/services/Sentry';
import { logDatadogException } from 'src/services/Datadog';
import { TodoState } from '../types/TodoState';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';

export const todo = createModel<RootModel>()({
  state: {
    todos: [],
  } as TodoState,
  reducers: {
    updateState(state: any, payload: any) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch: any) => ({
    async loadTodos(_payload: any, _rootState: any) {
      try {
        const response = await axiosInstance.get(`/square/todo-list`);

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        dispatch.todo.updateState({ todos: response.data.orders });
      } catch (err) {
        const error = err as Error;
        logSentryException(error);
        logDatadogException(error);
      }
    },
  }),
});
