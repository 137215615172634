/* eslint-disable no-plusplus */
import bwipjs from 'bwip-js';
import { jsPDF } from 'jspdf';
import { logSentryMessageError } from 'src/services/Sentry';
import { logDatadogMessageError } from 'src/services/Datadog';
import { getLastInternalSerialNumberId } from './inventory.utils';
import { IAsset } from '../interfaces/reconciliation.interfaces';
import { DATAMATRIX_PER_PAGE } from '../constants/general.constants';
import portraitLogo from '../assets/main-logo.png';

export const getDatamatrixFromAsset = (asset: any) => {
  const canvas = document.createElement('canvas');

  try {
    bwipjs.toCanvas(canvas, {
      bcid: 'datamatrix', // Barcode type
      text: asset.internalSerialNumber, // Text to encode
      scale: 2, // 2x scaling factor
      height: 15, // Bar height, in millimeters
      includetext: true, // Show human-readable text
      textxalign: 'center', // Always good to set this
    });

    let expDate = null;
    if (asset.expireAt) {
      const date = new Date(asset.expireAt);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      expDate = `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year}`;
    }

    return {
      name: asset.name,
      expireAt: expDate,
      lot: asset.lot,
      text: asset.internalSerialNumber,
      image: canvas.toDataURL('image/png'),
    };
  } catch (err) {
    logSentryMessageError('Error generating datamatrix codes', { raw: err });
    logDatadogMessageError('Error generating datamatrix codes', { raw: err });
    return {};
  }
};

/* This function returns the coordinate for each row / column depending on
 * the coordinate adding 25.5 millimeters per iteration. */
const getPosition = (time: number, coordinate: number) => coordinate + time * 25.5;

/* Function: formatPageWithText
 * This function add the datamatrix images and internal serial number to the pdf.
 * 8x10 Datamatrix (Assets) by page.
 */
const formatPageWithText = (doc: any, item: any) => {
  let index = 0;

  for (let i = 0; i < 10; i++) {
    for (let j = 0; j < 8; j++) {
      if (!item.codesList[index]?.image) {
        break;
      }

      doc.addImage(item.codesList[index]?.image, 'PNG', getPosition(j, 16), getPosition(i, 21.5), 6, 6);

      doc.addImage(portraitLogo, 'PNG', getPosition(j, 15.5), getPosition(i, 27.4), 6.9, 2.3);

      doc.setFontSize(9);
      doc.text(j % 2 === 0 ? 'A' : 'B', getPosition(j, 6), getPosition(i, 19));

      // human readable serial number:
      doc.setFontSize(5);

      const serialNumber = getLastInternalSerialNumberId(item.codesList[index]?.text);
      const serialFirst = serialNumber.slice(0, serialNumber.length - 2) || '';
      const serialLast = serialNumber.slice(serialNumber.length - 2) || '';
      const position = serialNumber.length > 6 ? 9.9 : 15.5;
      doc.text(getLastInternalSerialNumberId(serialFirst), getPosition(j, position), getPosition(i, 31));

      const pos = position + 0.1 + doc.getTextWidth(serialFirst);

      doc.setFontSize(6.3);
      doc.text(getLastInternalSerialNumberId(serialLast), getPosition(j, pos), getPosition(i, 31));

      // Service name:
      doc.setFontSize(5.7);
      if (item.codesList[index]?.name.length >= 12) {
        const nameArray: string[] = item.codesList[index]?.name.split(' ');
        doc.text(nameArray[0], getPosition(j, 11.5), getPosition(i, 19));
        doc.text(nameArray.splice(1).join(' '), getPosition(j, 11.5), getPosition(i, 21));
      } else {
        doc.text(item.codesList[index]?.name.slice(0, 13), getPosition(j, 11.5), getPosition(i, 19));
      }

      // Expiration date:
      doc.setFontSize(5);
      if (item.codesList[index]?.expireAt) {
        doc.text(getPosition(j, 25.5), getPosition(i, 30.5), item.codesList[index]?.expireAt, null, 90);
      }

      // Lot number:
      doc.text(getPosition(j, 13.5), getPosition(i, 30.5), item.codesList[index]?.lot, null, 90);

      if (j % 2 !== 0) {
        index++;
      }
    }
  }

  return doc;
};

export const downloadAssetPdf = async (assetPages: any, fileConcatName: string, callback: () => void) => {
  // eslint-disable-next-line new-cap
  let doc = new jsPDF('p', 'mm', [216, 279]); // 8.5x11 inch

  // Add datamatrix images and text for assets per page (8x10 assets)
  assetPages.forEach((item: any, index: number) => {
    doc = formatPageWithText(doc, item);
    if (index + 1 < assetPages.length) {
      doc.addPage('letter');
    } // 8.5x11 inch
  });

  doc.save(`${fileConcatName}_assets.pdf`);

  callback();
};

export const getAssetsPages = (assets: IAsset[]) => {
  const codesList = assets.map((asset: any) => getDatamatrixFromAsset(asset));

  const pagesAssetsList: any[] = [];

  while (codesList.length) {
    const codesPerPage = codesList.splice(0, DATAMATRIX_PER_PAGE); // 80 assets per page (A + B per asset)

    if (codesPerPage.length) {
      pagesAssetsList.push({ codesList: codesPerPage });
    }
  }

  return pagesAssetsList;
};
