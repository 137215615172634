import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  buttonsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#e3e3e3',
    padding: '7px 12px 150px 75px' 
  },
  reviewLaterButton: {
    backgroundColor: '#ffffff',
    color: '#12574d',
    margin: '0'
  },
  submitButton: {
    backgroundColor: '#12574d',
    color: '#ffffff !important',
    margin: '0',
    "&:focus": {
      backgroundColor: "#12574d"
    }
  },
  buttonRight: {
    width: '120px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#c3dad6',
    color: '#000000'
  },
  buttonLeft: {
    width: '120px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#e7eeed'
  },
  title: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '24px !important',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    textAlign: 'center',
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    paddingLeft: '15px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '30px'
  },
  containerDialog: {
    width: '450px',
    height: '251px'
  }
}));
