import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CardContent } from '@material-ui/core';
import { Card, SmallTitle } from '../../common/card';

import Signatures from './Signatures';

const SignaturesStep = () => {
  const serviceVisit = useSelector(({ newServiceVisit }: any) => newServiceVisit.serviceVisit);

  const [serviceVisitInfo, setServiceVisitInfo] = useState<any>({});

  useEffect(() => {
    const { closedAt, cosigned, cosignedBy, cosignedAt, cosignatureImg, signedBy, signatureImg, cosignNotes } =
      serviceVisit;

    setServiceVisitInfo({
      closedAt,
      cosigned,
      cosignNotes,
      cosignedBy,
      cosignedAt,
      cosignatureImg,
      signedBy,
      signatureImg,
    });
  }, [serviceVisit]);

  if (!serviceVisitInfo.closedAt) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <SmallTitle title="Signatures" style={{ margin: 0, marginBottom: '1rem' }} />
        <Signatures
          closedAt={serviceVisitInfo.closedAt}
          cosigned={serviceVisitInfo.cosigned}
          cosignedBy={serviceVisitInfo.cosignedBy}
          cosignedAt={serviceVisitInfo.cosignedAt}
          cosignatureImg={serviceVisitInfo.cosignatureImg}
          signedBy={serviceVisitInfo.signedBy}
          signatureImg={serviceVisitInfo.signatureImg}
        />
      </CardContent>
    </Card>
  );
};
export default SignaturesStep;
