import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { debounce } from 'lodash';
import { dispatch } from '../../rematch';
import { useStyles } from './CustomerInternalNotes.styles';

export const CustomerInternalNotes = () => {
  const classes = useStyles();
  const serviceVisit = useSelector(({ newServiceVisit }: any) => newServiceVisit.serviceVisit);
  const [customerNote, setCustomerNote] = useState<string>('');
  const [internalNote, setInternalNote] = useState<string>('');

  useEffect(() => {
    setInternalNote(serviceVisit.internalNote);
    setCustomerNote(serviceVisit.customerNote);
  }, [serviceVisit]);

  useEffect(() => {
    if (serviceVisit.customerNote === customerNote && serviceVisit.internalNote === internalNote) {
      return;
    }

    if (serviceVisit.id && (customerNote || internalNote)) {
      saveNotesDebounce(customerNote, internalNote, serviceVisit.id);
    }
  }, [customerNote, internalNote]);

  const saveNotesDebounce = useCallback(
    debounce((saveCustomerNote: string, saveInternalNote: string, serviceVisitId: number) => {
      dispatch({
        type: 'newServiceVisit/saveNotesForServiceVisit',
        payload: {
          serviceVisitId,
          body: {
            customerNote: saveCustomerNote,
            internalNote: saveInternalNote,
          },
        },
      });
    }, 500),
    []
  );

  return (
    <Box>
      <Box mb={1}>
        <Typography>Customer and Internal notes</Typography>
      </Box>
      <Box mb={1.5}>
        <TextareaAutosize
          onChange={(e) => {
            setCustomerNote(e.target.value);
          }}
          defaultValue={customerNote}
          placeholder="Customer note"
          className={classes.TextAreaStyles}
        />
      </Box>
      <Box>
        <TextareaAutosize
          onChange={(e) => {
            setInternalNote(e.target.value);
          }}
          defaultValue={internalNote}
          placeholder="Internal note"
          className={classes.TextAreaStyles}
        />
      </Box>
    </Box>
  );
};
