import { useQuery } from 'react-query';
import MedspaServices from 'src/services/medspaAdmins/Services';
import {
  SERVICE_GROUPS_LIST,
  SERVICE,
  DEFAULT_SERVICES,
  DEFAULT_SERVICE_GROUPS,
  MEDSPA_PRIMARY_SERVICES,
} from '../../../constants/reactQuery.keys';
import IServices from '../../../interfaces/IServices';

export function useService(userGroupdId: number, serviceId: number) {
  const response = useQuery([SERVICE, userGroupdId, serviceId], () =>
    MedspaServices.getService(userGroupdId, serviceId)
  );
  return {
    ...response,
    data: response.data || ({} as IServices),
  };
}

export function useDefaultServices(userGroupId: number) {
  const response = useQuery([DEFAULT_SERVICES], () => MedspaServices.getDefaultServices(userGroupId), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    ...response,
    data: response.data || [],
  };
}

export function useServiceGroups(userGroupId: number, patientId?: number, serviceVisitId?: number) {
  const response = useQuery(
    [SERVICE_GROUPS_LIST, patientId],
    () => MedspaServices.getServiceGroups(userGroupId, patientId, serviceVisitId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    ...response,
    data: response.data || [],
  };
}

export function useDefaultServiceGroups(userGroupId: number) {
  const response = useQuery(
    [DEFAULT_SERVICE_GROUPS, userGroupId],
    () => MedspaServices.getDefaultServiceGroups(userGroupId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    ...response,
    data: response.data || [],
  };
}

export const useMedspaPrimaryServices = (userGroupId: number) => {
  const response = useQuery([MEDSPA_PRIMARY_SERVICES], () => MedspaServices.medspaPrimaryServices(userGroupId));

  return {
    ...response,
    data: response.data || {
      selectedServicesFromPrimaryLibrary: [],
      selectedServiceGroupsFromPrimaryLibrary: [],
    },
  };
};
