import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  contentContainer: {
    width: '646px',
    margin: 'auto',
    paddingTop: '60px',
  },
  consentButtonsContainer: {
    display: 'block',
    textAlign: 'right',
  },
  consentTitle: {
    paddingTop: '29px',
    paddingRight: '60px',
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Messina Sans Regular',
  },
  contentButton: {
    textAlign: 'right',
  },
  consentFormButton: {
    width: '193px',
    height: '47px',
    borderRadius: '3px',
    border: '1px solid #12574d',
    color: '#12574d',
    fontSize: '14px',
    margin: '15px 10px',
  },
  signatureImage: {
    width: '100%',
    height: '97px',
    objectFit: 'contain',
  },
  secondaryTitle: {
    paddingTop: '5px',
    fontSize: '20px',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 'bold',
  },
  consentContent: {
    fontSize: '11px',
    display: 'block',
    height: 'auto',
    overflowY: 'hidden',
    backgroundColor: 'white',
    padding: '16px',
    textAlign: 'left',
    margin: '20px',
    '& h2': {
      fontSize: '16px',
    },
    '& h3': {
      fontSize: '14px',
    },
  },
  consentSignature: {
    display: 'block',
    height: '210px',
    width: '646px',
    backgroundColor: 'white',
    borderRadius: '4px',
    margin: '20px auto',
  },
  consentSignedContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '210px',
    width: '646px',
    backgroundColor: 'white',
    borderRadius: '4px',
    margin: '20px auto',
  },
  consentSignatureCanvas: {
    width: '646px',
    height: 'calc(100% - 40px)',
  },
  patientName: {
    margin: '20px auto',
    width: '646px',
    backgroundColor: 'white',
    padding: '12px',
    marginBottom: '8px',
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '0.88',
    letterSpacing: 'normal',
    borderRadius: '4px',
    border: 'solid 1px #979797',
  },
  signatureTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    position: 'relative',
    top: '15px',
    left: '20px',
    fontWeight: 'bold',
    fontSize: '15px',
  },
  clearSignatureButton: {
    width: '193px',
    height: '47px',
    borderRadius: '3px',
    color: '#12574d',
    fontSize: '14px',
    margin: '15px 10px',
  },
  doneSigningButton: {
    width: '193px',
    height: '47px',
    borderRadius: '3px',
    float: 'right',
    backgroundColor: '#12574d',
    color: '#ffffff',
    fontSize: '14px',
    margin: '15px 0',
  },
  closeIcon: {
    float: 'left',
    marginTop: '25px',
    marginLeft: '22px',
    width: '31px',
    height: '31px',
    cursor: 'pointer',
  },
  inputPatientName: {
    width: '646px',
    margin: '0 auto',
  },
  membershipConsentModal: {
    backgroundColor: '#e7eeed',
    height: '100%',
    overflowY: 'scroll',
  },
}));
