import React from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers';
import { Box, Button, Grid, TextField, makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { dispatch } from '../../rematch';
import { isValidSerialNumber, getPrefixByDigits } from '../../utils/inventory.utils';
import DatamatrixIcon from '../../assets/datamatrix.svg';

import { IAsset } from '../../interfaces/reconciliation.interfaces';
import { VALID_SCANNER_DIGITS } from '../../constants/general.constants';
import compile from '../../utils/toastMessagesCompiler';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline',
    '& .MuiTextField-root input': {
      height: '10px',
    },
    '& .MuiTextField-root label': {
      top: '-4px',
    },
  },
  cancelButton: {
    backgroundColor: 'white',
    color: '#12574d !important',
    width: '90px',
    height: '47px',
    marginRight: 0,
  },
  saveButton: {
    backgroundColor: '#12574d',
    color: 'white !important',
    width: '90px',
    height: '47px',
    marginRight: 0,
    '&:hover': {
      backgroundColor: '#0b433b',
    },
  },
  disabled: {
    backgroundColor: '#89aba6',
  },
  helperText: {
    color: '#12574D',
    fontSize: '14px',
  },
  pointer: {
    paddingTop: '13px',
    paddingBottom: '13px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  datamatrixIcon: {
    width: '38px',
  },
}));

const schema = yup.object().shape({
  serialNumber: yup.string().matches(/\w+_\w+_\d{8}_\w+|^[a-zA-Z0-9]{6}$|^[a-zA-Z0-9]{16}$/g),
});

interface Props {
  scannerInputRef: any; // Ref for auto focus
  inAssets?: IAsset[]; // Validate the code by the asset list provided
  inAssetsError?: string; // Message to show if serial number is not in inAssets
  disabled?: boolean;
  isInReceipt?: boolean; // Verifies that the asset does not exist in some supply receipt
  isAssetRecover?: boolean; // Allow to scan wasted products to be recovered
  successCallback?: (asset: IAsset) => void;
}

const Scanner = ({
  scannerInputRef,
  disabled,
  inAssets,
  isInReceipt,
  isAssetRecover,
  inAssetsError = '',
  successCallback = () => {},
}: Props) => {
  const classes = useStyles();

  const { id: identityId } = useSelector(({ auth }: any) => auth);
  const { register, handleSubmit, errors, reset } = useForm({ resolver: yupResolver(schema) });
  const { supplyReceipt } = useSelector((state: any) => state.newSupplyReceipt);
  const addQrCode = (text: string) => {
    dispatch({ type: 'scanner/setCode', payload: '' });

    if (isValidSerialNumber(text) || VALID_SCANNER_DIGITS.includes(text.length)) {
      scanProduct(text.trim().toUpperCase());
      reset();
    }
  };

  const scanProduct = (serialNumber: string): void => {
    let prefix = null;

    if (inAssets) {
      // validates that the code is in the assets provided
      const exists = inAssets?.some(({ internalSerialNumber }: any) =>
        [internalSerialNumber.split('_').pop(), internalSerialNumber].includes(serialNumber)
      );

      if (exists && VALID_SCANNER_DIGITS.includes(serialNumber.length)) {
        prefix = getPrefixByDigits(serialNumber, inAssets);
      }

      if (!exists) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: { message: compile(inAssetsError), type: 'error' },
        });
        return;
      }
    }

    dispatch({
      type: 'scanner/checkAssetByInternalSerialNumber',
      payload: {
        prefix,
        isInReceipt,
        isAssetRecover,
        internalSerialNumber: serialNumber,
        toWarehouseId: supplyReceipt.toWarehouseId,
        identityId,
        callback: successCallback,
      },
    });
  };

  const onSubmit = (event: any) => {
    addQrCode(event?.serialNumber);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={1}>
            <img className={classes.datamatrixIcon} src={DatamatrixIcon} alt="data matrix icon" />
          </Grid>
          <Grid item xs={9}>
            <Box className={classes.root}>
              <TextField
                data-cy="scannerInput"
                error={!isEmpty(errors.serialNumber)}
                label="Place cursor here and scan or enter the six character code"
                variant="outlined"
                type="text"
                size="medium"
                name="serialNumber"
                fullWidth
                disabled={disabled}
                inputRef={(e) => {
                  register(e, { required: true });
                  if (scannerInputRef) {
                    // eslint-disable-next-line no-param-reassign
                    scannerInputRef.current = e;
                  }
                }}
                inputProps={{ height: '10px', width: '202px' }}
              />
            </Box>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <Box mr="auto">
              <Button
                data-cy="submitBtn"
                type="submit"
                variant="outlined"
                color="primary"
                classes={{
                  root: classes.saveButton,
                  disabled: classes.disabled,
                }}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Scanner;
