import { createModel } from '@rematch/core';
import IServices from '../interfaces/IServices';
import IWarehouses from '../interfaces/IWarehouses';
import axios from '../utils/axios';
import { RootModel } from './rootModel';
import ISuppliers from '../interfaces/ISuppliers';
import compile from '../utils/toastMessagesCompiler';

export interface ICommonState {
  services: IServices[];
  suppliers: ISuppliers[];
  warehouses: IWarehouses[];
  practitioners: any[];
}

export const common = createModel<RootModel>()({
  state: {
    services: [],
    suppliers: [],
    warehouses: [],
    practitioners: [],
  } as ICommonState,
  reducers: {
    getServicesSuccess(state, payload: IServices[]) {
      return { ...state, services: payload };
    },
    setWarehouses(state, payload: any[]) {
      return { ...state, warehouses: payload };
    },
    setSuppliers(state, payload: any[]) {
      return { ...state, suppliers: payload };
    },
    setPractitioners(state, payload: any[]) {
      return { ...state, practitioners: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchServices(data: any) {
      try {
        const response = await axios.get('/services', { params: { service_visit_id: data?.contextServiceVisitId } });

        dispatch.common.getServicesSuccess(response.data.services);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'services',
            }),
            type: 'error',
          },
        });
      }
    },
    async fetchWarehouses(data: any, { common: commonModel }: any) {
      try {
        if (commonModel.warehouses.length) {
          return;
        }

        const response = await axios.get('/warehouses');
        dispatch.common.setWarehouses(response.data.warehouses);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'warehouses',
            }),
            type: 'error',
          },
        });
      }
    },
    async fetchSuppliers(data: any, { common: commonModel }: any) {
      try {
        if (commonModel.suppliers.length) {
          return;
        }

        const response = await axios.get('/suppliers');
        dispatch.common.setSuppliers(response.data.suppliers);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'warehouses',
            }),
            type: 'error',
          },
        });
      }
    },
    async fetchPractitioners(data: any, { common: commonModel }: any) {
      try {
        if (commonModel.practitioners.length) {
          return;
        }

        const response = await axios.get('/practitioners/active');
        dispatch.common.setPractitioners(response.data.practitioners);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'practitioners',
            }),
            type: 'error',
          },
        });
      }
    },
  }),
});
