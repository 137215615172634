import { Button, CircularProgress, FormControl, Grid, InputLabel, OutlinedInput, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomCKEditor from 'src/components/common/Custom/CustomCKEditor';
import { IMedspaCareMessage, IMedspaCustomCareMessage } from 'src/interfaces/IMedspaCareMessages';
import { IMedspaConsent } from 'src/interfaces/IMedspaConsents';
import { IMedspaStandingOrders } from 'src/interfaces/IMedspaStandingOrders';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { MEDSPA_ADMIN_SERVICES_MANAGEMENT, MEDSPA_DOCUMENTS_MANAGEMENT } from 'src/routes/medspaAdminRoutes';
import { CONSENTS_DOCUMENT_TYPE } from 'src/constants/medspa/documentsManagement.constants';
import {
  useCreateMedspaStandingOrders,
  useUpdateMedspaStandingOrders,
} from 'src/hooks/queries/useMedspaStandingOrders';
import { useCreateMedspaConsent, useUpdateMedspaConsents } from 'src/hooks/queries/useMedspaConsents';
import { CREATE, UPDATE, VIEW } from 'src/constants/medspa/servicesManagement.constants';
import { showSnackbar } from 'src/utils/global';
import { useStyles } from '../documentsManagement.styles';

interface LocationState {
  document: IMedspaConsent & IMedspaStandingOrders & IMedspaCareMessage & IMedspaCustomCareMessage;
  action: 'create' | 'update' | 'view';
  documentType: string;
  backUrl: string;
  form: any;
  searchUrl: string;
}

const INITIAL_STATE = {
  editable: false,
  documentType: '',
  backUrl: MEDSPA_DOCUMENTS_MANAGEMENT,
  document: {
    content: '',
    name: '',
  },
  form: {},
  searchUrl: '',
};

const CustomizeText = () => {
  const history = useHistory();
  const {
    user: { userGroupId },
  } = useSelector(({ auth }: any) => auth);
  const { state } = useLocation<LocationState>();
  const classes = useStyles();
  const { document, documentType, backUrl, action, form, searchUrl } = state || INITIAL_STATE;

  const [content, setContent] = useState(document.content || '');
  const [name, setName] = useState(document.name || '');

  const { mutateAsync: updateMedspaConsentMutation, isLoading: isLoadingUpdateConsents } = useUpdateMedspaConsents();
  const { mutateAsync: updateMedspaStandingOrdersMutation, isLoading: isLoadingUpdateStandingOrders } =
    useUpdateMedspaStandingOrders();

  const { mutateAsync: createMedspaConsentMutation, isLoading: isLoadingCreateConsents } = useCreateMedspaConsent();
  const { mutateAsync: createMedspaStandingOrdersMutation, isLoading: isLoadingCreateStandingOrders } =
    useCreateMedspaStandingOrders();

  const handleUpdate = async () => {
    if (documentType === CONSENTS_DOCUMENT_TYPE) {
      await updateMedspaConsentMutation({ userGroupId, consentId: document.id, content });
    } else {
      await updateMedspaStandingOrdersMutation({ userGroupId, standingOrderId: document.id, content });
    }
  };

  const handleCreate = async () => {
    if (action === CREATE && (!name || !content)) {
      showSnackbar(`All of the fields are required to create a new ${documentType}`);
      return;
    }

    let data = {};
    if (documentType === CONSENTS_DOCUMENT_TYPE) {
      data = await createMedspaConsentMutation({ userGroupId, name, content });
      navigateBack({ consent: data });
    } else {
      data = await createMedspaStandingOrdersMutation({ userGroupId, name, content });
      navigateBack({ standingOrder: data });
    }
  };

  const navigateBack = (formData = {}) => {
    if (backUrl) {
      history.push({
        pathname: backUrl,
        search: searchUrl,
        state: {
          form: { ...form, ...formData },
        },
      });
    } else {
      history.push(MEDSPA_ADMIN_SERVICES_MANAGEMENT);
    }
  };

  const loadingButton = () => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress size={30} style={{ color: 'white' }} />
      <p>Loading...</p>
    </div>
  );

  return (
    <Grid container alignItems="center" className={classes.gridContainer}>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
        <ArrowBackIcon onClick={() => navigateBack()} />
        <Typography component="h2">{`${documentType} (${action})`}</Typography>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="name-id" color="secondary" className={classes.formControlLabel}>
            Name
          </InputLabel>
          <OutlinedInput
            id="name-id"
            type="text"
            fullWidth
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setName(ev?.target?.value)}
            disabled={action !== CREATE}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <CustomCKEditor
          height="600px"
          className={classes.customEditor}
          onChange={setContent}
          value={content}
          disabled={action === VIEW}
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right', margin: '10px' }}>
        {action === UPDATE && (
          <Button color="primary" variant="contained" onClick={() => handleUpdate()}>
            {isLoadingUpdateConsents || isLoadingUpdateStandingOrders ? loadingButton() : <p>Update</p>}
          </Button>
        )}
        {action === CREATE && (
          <Button color="primary" variant="contained" onClick={() => handleCreate()}>
            {isLoadingCreateConsents || isLoadingCreateStandingOrders ? loadingButton() : <p>Create</p>}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
export default CustomizeText;
