import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '550px',
    fontSize: '16px',
  },
  button: {
    width: '400px',
    color: 'white',
    background: '#12574D',
    borderRadius: '8px',
    textTransform: 'none',
    margin: 0,
    '&:disabled': {
      color: 'white',
    },
    '&:hover': {
      background: '#12574D',
    },
  },
  processing: {
    color: 'white',
    marginLeft: theme.spacing(1),
    position: 'relative',
  },
  creditAmountContainer: {
    margin: '30px 0',
  },
  creditAmountInput: {
    width: '200px',
    borderRadius: '8px',
    color: '#5A5A5A',
    marginRight: 0,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    width: '440px',
    padding: '4rem 2rem',
    background: 'white',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '1rem 0 0.5rem',
  },
  doneButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '12px',
    padding: '1rem 2rem',
    borderRadius: '8px',
    margin: '2rem 0.5rem 0',
    width: '100%',
    textAlign: 'center',
    textTransform: 'none',
  },
  errorMsg: {
    color: '#FF1616',
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  errorMsgIcon: {
    marginRight: '8px',
  },
  chargeNoteContainer: {
    width: '100%',
    marginBottom: '30px',
  },
}));
