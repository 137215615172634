import { createModel } from '@rematch/core';
import { RootModel } from './rootModel';
import { IProductIdentifiers } from '../interfaces/productIdentifiers.interfaces';
import axiosInstance from '../utils/axios';
import compile from '../utils/toastMessagesCompiler';

export const productIdentifiers = createModel<RootModel>()({
  state: {
    aggregatedField: 'currentNumberOfUnits',
    productName: '',
    vials: null,
    units: null,
    hasScannedProducts: false,
    unitLabel: '',
    identifiers: [],
  },
  reducers: {
    setProductIdentifiersData(state: any, payload: IProductIdentifiers) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch: any) => ({
    async markAsEmpty(assetId: number, state: any) {
      await axiosInstance.post(`/assets/${assetId}/mark-as-empty`);

      dispatch({
        type: 'inventoryDefault/getDashboardLists',
        payload: state.auth.userId,
      });

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('product_identifier.marked_empty'),
        },
      });
    },
    async undoMarkAsEmpty(assetId: number, state: any) {
      await axiosInstance.post(`/v3/assets/${assetId}/undo-mark-as-empty`);

      dispatch({
        type: 'inventoryDefault/getDashboardLists',
        payload: state.auth.userId,
      });

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('product_identifier.undo_marked_empty'),
        },
      });
    },
  }),
});
