import React from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import MedspaAdmins from 'src/services/MedspaAdmins';
import { useSelector } from 'react-redux';
import { MEDSPA_ADMIN_ROLE } from 'src/constants/general.constants';
import { useStyles } from './ModalPricingStyle.styles';
import Services from '../../../../services/Services';
import IServices from '../../../../interfaces/IServices';
import Variants from '../../../../services/Variants';
import { SERVICES } from '../../../../constants/reactQuery.keys';
import { IVariantItem } from '../../../../interfaces/IVariants';

interface Props {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpenModalReset: (reset: boolean) => void;
  data: IServices | IVariantItem | undefined;
}

const ModalPricingReset = ({ data, open, setOpenModalReset }: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { roleName } = useSelector(({ auth }: any) => auth);

  const variantItemMutation = useMutation(
    (newPrice: { variantItemId: number; price: number }) =>
      Variants.updateVariantItemPricing(newPrice.variantItemId, newPrice.price),
    { onSuccess: () => queryClient.invalidateQueries(SERVICES) }
  );
  const serviceMutation = useMutation(
    (newPrice: { serviceId: number; price: number }) =>
      Services.updateServicePricing(newPrice.serviceId, newPrice.price),
    { onSuccess: () => queryClient.invalidateQueries(SERVICES) }
  );

  const medspaServiceMutation = useMutation(
    (newPrice: { serviceId: number; price: number }) =>
      MedspaAdmins.updateMedspaServicePricing({ serviceId: newPrice.serviceId, priceOverride: newPrice.price }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SERVICES);
      },
    }
  );

  const medspaVariantItemMutation = useMutation(
    (newPrice: { variantItemId: number; price: number }) =>
      MedspaAdmins.updateMedspaServicePricing({ variantItemId: newPrice.variantItemId, priceOverride: newPrice.price }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SERVICES);
      },
    }
  );
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;

  const discard = () => {
    if (data?.id) {
      if (data.isVariantItem) {
        if (isMedspaAdmin) {
          variantItemMutation.mutate({ variantItemId: data.id, price: data.defaultPrice });
        } else {
          medspaVariantItemMutation.mutate({ variantItemId: data.id, price: data.defaultPrice });
        }
      } else if (isMedspaAdmin) {
        medspaServiceMutation.mutate({ serviceId: data.id, price: data.defaultPrice });
      } else {
        serviceMutation.mutate({ serviceId: data.id, price: data.defaultPrice });
      }
      setOpenModalReset(false);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => setOpenModalReset(false)}>
        <div className={classes.containerDialogReset} data-cy="resetPriceModal">
          <DialogTitle className={classes.headerTitle}>Are you sure you want to reset the price?</DialogTitle>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={() => setOpenModalReset(false)} className={classes.buttonLeft}>
              No, I don&lsquo;t.
            </Button>
            <Button
              data-cy="confirmResetPrice"
              onClick={discard}
              className={classes.buttonRight}
              disabled={serviceMutation.isLoading}
            >
              {serviceMutation.isLoading ? <CircularProgress /> : `Yes, let's do it!`}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
};

export default ModalPricingReset;
