import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  physicianPage: {
    background: 'white',
    marginTop: '1rem',
    padding: '2rem',
    flexGrow: 1,
  },
  tableContainer: {
    paddingTop: '2rem',
  },
  avatar: {
    width: '32px',
    height: '32px',
    marginRight: '16px',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  filterContainer: {
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  search: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '14px',
  },
  formControl: {
    width: '150px',
    marginLeft: '21px',
  },
  completed: {
    color: '#005A4E',
    background: '#E6F9DA',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
  },
  pending: {
    color: '#704822',
    background: '#F8DCC1',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
  },
  'Name-header': {
    minWidth: '250px',
  },
  'Type-header': {
    minWidth: '140px',
  },
  'ID-header': {
    minWidth: '100px',
  },
  'Licenses-header': {
    minWidth: '175px',
  },
  'Email-header': {
    minWidth: '300px',
  },
  'Status-header': {
    minWidth: '150px',
  },
  'Visits-header': {
    minWidth: '150px',
  },
}));
