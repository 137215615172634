import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import classnames from 'classnames';
import { debounce, sortBy } from 'lodash';
import moment from 'moment';
import { useStyles } from './referral.styles';
import { RootState, dispatch } from '../../../rematch';
import ConfirmReferred from './ConfirmReferred';
import IReferral from '../../../interfaces/IReferral';
import { hasMoreThanDate } from '../../../utils/global';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_REFERRAL } from '../../../constants/actions.constants';
import { ROUTES } from '../../../constants/routes.constants';

interface Props {
  showReferredLabelButton?: boolean;
  showFormIfCannotReferrer?: boolean;
  isAdmin?: boolean;
}

const ReferralsSelector = ({ showReferredLabelButton, showFormIfCannotReferrer, isAdmin }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { id, isAdvocate, hasReferred, referredBy, referredById } = useSelector(
    ({ patient }: RootState) => patient.basicInfo
  );
  const { serviceVisits } = useSelector(({ patient }: RootState) => patient);

  const { referrals } = useSelector(({ referrals: referralsState }: RootState) => referralsState);

  const {
    effects: {
      referrals: { setReferredBy: isLoadingSetReferredBy },
    },
  } = useSelector(({ loading }) => loading);
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const [inputValue, setInputValue] = useState('');
  const [openModalConfirmReferred, setOpenModalConfirmReferred] = useState<boolean>(false);
  const [referralSelected, setReferralSelected] = useState<IReferral | undefined>(undefined);

  const canRefer =
    !(
      // Cannot refer if has more than 24hours from the first service visit, except for admins
      (
        (serviceVisits || []).length > 0 &&
        hasMoreThanDate(sortBy(serviceVisits || [], ({ createdAt }: any) => moment(createdAt))[0].createdAt, 1, 'day')
      )
    ) ||
    (isAdmin && (serviceVisits || []).length > 0);

  const doSearch = useMemo(
    () => debounce((name: string) => dispatch({ type: 'referrals/getReferrals', payload: { params: { name } } }), 100),
    []
  );

  useEffect(
    () => () => {
      setReferralSelected(undefined);
    },
    []
  );

  useEffect(() => {
    if (inputValue.length >= 2 && !(inputValue.length > 25)) {
      doSearch(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (id) {
      if (!referredById) {
        dispatch.patient.fetchBasicInfo({ patientId: id });
      }
    }
  }, [id]);

  const goToProfile = (patientId: string) => {
    history.push(ROUTES.PATIENT_ID(patientId));
  };

  const onClick = (customerId: number, referredSelectedId: number | undefined) => {
    dispatch({
      type: 'referrals/setReferredBy',
      payload: { customerId, referredById: referredSelectedId, onSuccess: () => setOpenModalConfirmReferred(false) },
    });
  };

  const toggleModalConfirmReferred = () => {
    setOpenModalConfirmReferred(!openModalConfirmReferred);
  };

  const handleReferralSelected = (event: any, newValue: any) => {
    if (newValue) {
      setReferralSelected(newValue);
      toggleModalConfirmReferred();
    }
  };

  if (referredBy) {
    return (
      <Box display="flex" alignItems="center" className={classes.referralContainer}>
        <Box
          mr="23px"
          className={classnames(classes.referralByText, { [classes.referralByLabelButton]: showReferredLabelButton })}
        >
          REFERRED BY
        </Box>
        <Box
          width="90%"
          className={classes.hasReferredText}
          onClick={() => referredById !== null && goToProfile(referredById)}
        >
          {referredBy}
        </Box>
      </Box>
    );
  }

  if (!showFormIfCannotReferrer && !canRefer) {
    return null;
  }

  return !canRefer && !hasReferred ? (
    <div style={{ fontSize: '115%' }}>This is an active patient who can not be referred</div>
  ) : (
    <Box className={classes.referralContainer}>
      <ConfirmReferred
        open={openModalConfirmReferred}
        closeModal={toggleModalConfirmReferred}
        selected={referralSelected}
        onConfirm={() => id !== null && onClick(id, referralSelected?.id)}
        isLoading={isLoadingSetReferredBy}
      />
      {((!isAdvocate && !hasReferred && hasAccessTo(CREATE_REFERRAL, permissions)) || (isAdmin && !referredBy)) && (
        <Box display="flex" alignItems="center">
          <Box
            mr="23px"
            className={classnames(classes.referralByText, { [classes.referralByLabelButton]: showReferredLabelButton })}
          >
            REFERRED BY
          </Box>
          <Box width="90%">
            <Autocomplete
              disabled={isLoadingSetReferredBy}
              noOptionsText="No results"
              options={referrals}
              onChange={handleReferralSelected}
              open={inputValue?.length >= 2}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              loading={referrals.loading}
              getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} - ${option.phone}`}
              fullWidth
              renderInput={(params) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...params} label="Search by first name, last name or phone number" variant="outlined" />
              )}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

ReferralsSelector.defaultProps = {
  showFormIfCannotReferrer: true,
  showReferredLabelButton: false,
  isAdmin: false,
};

export default ReferralsSelector;
