import React, { useRef } from 'react';
import { Switch } from 'react-router-dom';
import PractitionerDashboardTab from 'src/components/DashboardPractitioner/Tabs/PractitionerDashboardTab';

import Wrapper from 'src/components/common/Wrapper';
import { PRACTITIONER_ROLE } from 'src/constants/general.constants';
import MedspaAccountSettings from 'src/pages/MedspaProvider/Account/accountSettings';
import CalendarConfigurationWrapper from 'src/pages/MedspaProvider/Calendar/calendarConfiguration';
import NewPrivateRoute, { NewPrivateRouteWithoutRolesProps } from 'src/utils/NewPrivateRoute';
import MedspaPractitionerCalendar from 'src/pages/MedspaProvider/Calendar/MedspaPractitionerCalendar';
import MedspaAdminToDoList from 'src/components/DashboardMedspaAdmin/TodoList';
import { USE_EHR } from 'src/constants/actions.constants';
import { PatientsMedspaProviderPage } from '../components/DashboardMedspaAdmin/Patients/PatientsMedspaProviderPage';

export const MEDSPA_PROVIDER_BASE_PATH = '/medspa-provider';
export const MEDSPA_PROVIDER_EARNINGS = `${MEDSPA_PROVIDER_BASE_PATH}/earnings`;
export const MEDSPA_PROVIDER_PATIENTS = `${MEDSPA_PROVIDER_BASE_PATH}/patients`;
export const MEDSPA_PROVIDER_TODO_LIST = `${MEDSPA_PROVIDER_BASE_PATH}/todo-list`;
export const MEDSPA_PROVIDER_CALENDAR = `${MEDSPA_PROVIDER_BASE_PATH}/calendar`;
export const MEDSPA_PROVIDER_CALENDAR_CONFIGURATION = `${MEDSPA_PROVIDER_BASE_PATH}/calendar-configuration`;
export const MEDSPA_PROVIDER_ACCOUNT_SETTINGS = `${MEDSPA_PROVIDER_BASE_PATH}/account-settings`;

const MedspaPractitionerPrivateRoute = (props: NewPrivateRouteWithoutRolesProps) => (
  <NewPrivateRoute {...props} roles={PRACTITIONER_ROLE} />
);

const MedspaPractitionerRoutes: React.FC = () => {
  const scrollMainRef = useRef(null);

  return (
    <Wrapper scrollMainRef={scrollMainRef}>
      <Switch>
        <MedspaPractitionerPrivateRoute
          path={MEDSPA_PROVIDER_EARNINGS}
          permission={USE_EHR}
          component={PractitionerDashboardTab}
        />
        <MedspaPractitionerPrivateRoute
          path={MEDSPA_PROVIDER_PATIENTS}
          permission={USE_EHR}
          component={PatientsMedspaProviderPage}
        />
        <MedspaPractitionerPrivateRoute
          path={MEDSPA_PROVIDER_TODO_LIST}
          permission={USE_EHR}
          component={MedspaAdminToDoList}
        />
        <MedspaPractitionerPrivateRoute
          path={MEDSPA_PROVIDER_CALENDAR_CONFIGURATION}
          permission={USE_EHR}
          component={CalendarConfigurationWrapper}
        />
        <MedspaPractitionerPrivateRoute
          path={MEDSPA_PROVIDER_CALENDAR}
          permission={USE_EHR}
          component={MedspaPractitionerCalendar}
        />
        <MedspaPractitionerPrivateRoute
          path={MEDSPA_PROVIDER_ACCOUNT_SETTINGS}
          permission={USE_EHR}
          component={MedspaAccountSettings}
        />
      </Switch>
    </Wrapper>
  );
};

export default MedspaPractitionerRoutes;
