import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  parentGrid: {
    padding: '16px 0',
    justifyContent: 'space-around',
  },
  switchGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  shadowedLabel: {
    fontSize: '14px',
    opacity: 0.4,
  },
  switchCustom: {
    color: '#12574D',
  },
});

export default useStyles;
