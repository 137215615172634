import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MEDSPA_ADMIN_ACCOUNT_SETTINGS, MEDSPA_ADMIN_BANK_ACCOUNT } from 'src/constants/reactQuery.keys';
import { BankAccountType } from 'src/pages/Onboarding/AccountSettings/StepsCards/BankAccount';
import PaymentMethods from 'src/services/medspaAdmins/PaymentMethods';

export interface BankAccountParams {
  address: string;
  city: string;
  state: string;
  routing: number | null;
  account: number | null;
  name: string;
  accountType: BankAccountType;
}

export const useBankAccount = (userGroupId: number, enabled: boolean = true) =>
  useQuery({
    queryKey: [MEDSPA_ADMIN_BANK_ACCOUNT],
    queryFn: () => PaymentMethods.getBankAccount(userGroupId),
    refetchOnWindowFocus: false,
    enabled,
    staleTime: 60000, // 1 minute
  });

export const useCreateBankAccount = (userGroupId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: BankAccountParams) => {
      await PaymentMethods.addBankAccount(userGroupId, params);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_BANK_ACCOUNT] });
    },
  });
};
