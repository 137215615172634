import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '18px',
    fontFamily: 'Messina Sans SemiBold',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  formControl: {
    height: '46px',
    width: '100%',
  },
  infiScrollContainer: {
    width: '100%',
    marginTop: '10px',
  },
  checkbox: {
    color: '#12574d',
  },
}));
