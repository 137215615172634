import React, { useEffect, PropsWithChildren } from 'react';
import { useSelector, connect } from 'react-redux';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';

import { isArray, isString } from 'lodash';
import { ADMIN_ROLE, MEDSPA_ADMIN_ROLE } from 'src/constants/general.constants';
import { hasAccessTo } from './auth.utils';
import { RootState, dispatch } from '../rematch';
import compile from './toastMessagesCompiler';

interface PrivateRouteProps extends PropsWithChildren<RouteProps> {
  isAuthenticate: RootState['auth']['isAuthenticate'];
  userType: RootState['auth']['userType'];
  isLoading: RootState['auth']['isLoading'];
  permission?: string;
  role?: string | string[];
}

const mapState = (state: RootState) => ({
  isAuthenticate: state.auth.isAuthenticate,
  userType: state.auth.userType,
  isLoading: state.auth.isLoading,
});

const PrivateRoute = (props: PrivateRouteProps) => {
  const { isAuthenticate, userType, permission, isLoading, role, children } = props;
  const permissions = useSelector(({ auth }: RootState) => auth.permissions);
  const IsAuthenticated = useSelector(({ auth }: RootState) => auth.isAuthenticate);

  useEffect(() => {
    if (!IsAuthenticated) {
      let params = '';
      const ehrLoggedOut = sessionStorage.getItem('ehr_loggedOut');
      if (ehrLoggedOut) {
        sessionStorage.removeItem('ehr_loggedOut');
      } else {
        params = `?nextURL=${encodeURIComponent(`/${window.location.href.split('/').slice(3, 100).join('/')}`)}`;
      }
      window.location.href = `/login${params}`;
    }
  }, [IsAuthenticated]);

  const onRender = ({ location }: RouteComponentProps<any>) => {
    let isValidRole = false;

    if (!role || isLoading) {
      isValidRole = true; // hide errors while loading redux
    } else if (isString(role)) {
      const isMedspaAdmin = userType === ADMIN_ROLE && role === MEDSPA_ADMIN_ROLE;
      isValidRole = userType === role || isMedspaAdmin;
    }

    if (isArray(role) && userType) {
      isValidRole = role.includes(userType) || role.includes(MEDSPA_ADMIN_ROLE);
    }

    if (permission) {
      if (isArray(permission)) {
        isValidRole = permission.every((perm) => hasAccessTo(perm, permissions));
      } else {
        isValidRole = hasAccessTo(permission, permissions);
      }
    }

    if ((isAuthenticate && isValidRole) || isLoading) {
      return children;
    }
    if (!isLoading && !isValidRole) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.no_permission_to_access'),
          type: 'error',
          duration: 3000,
        },
      });
    }
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  };

  return <Route {...props} render={onRender} />;
};

export default connect(mapState)(PrivateRoute);
