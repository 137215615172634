import React, { useState, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Edit as EditIcon, Replay as ReplayIcon } from '@material-ui/icons';
import { useStyles } from './binaryQuestion.styles';
import { dispatch } from '../../../rematch';
import { Button } from '../../common/Button';

interface Props {
  value: string;
  question: string;
  objectKey: string;
}

export const BinaryQuestion = ({ value, question, objectKey }: Props) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [selectedValue, setSelectedValue] = useState('no');

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const toggleEdit = () => {
    if (editMode) {
      setSelectedValue(value);
    }

    setEditMode(!editMode);
  };

  const callback = () => {
    setEditMode(false);
  };

  const save = () => {
    dispatch({ type: 'patient/updateMedicalProfileRequest', payload: { value: selectedValue, objectKey, callback } });
  };

  const isButtonDisabled = (): boolean => selectedValue === value;

  const onSwitchChange = () => {
    setSelectedValue(selectedValue === 'yes' ? 'no' : 'yes');
  };

  return (
    <div>
      <div className={classes.question}>
        <div>{question}</div>
        <div className={classes.edit}>
          <FormControlLabel
            label={selectedValue === 'yes' ? 'Yes' : 'No'}
            control={<Switch checked={selectedValue === 'yes'} onChange={onSwitchChange} disabled={!editMode} />}
          />
          {editMode ? <ReplayIcon onClick={toggleEdit} /> : <EditIcon onClick={toggleEdit} />}
        </div>
      </div>
      <Collapse in={editMode} timeout="auto" disableStrictModeCompat>
        <div className={classes.selectSection}>
          <Button
            title="Save"
            style={{ minWidth: '200px', color: '#ffffff', backgroundColor: '#12574d', marginRight: '0' }}
            onClick={save}
            disabled={isButtonDisabled()}
          />
        </div>
      </Collapse>
    </div>
  );
};
