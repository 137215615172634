import React from 'react';

import { Box, TableHead } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons/';

import { ASCENDING, DESCENDING } from '../../../types/SortDirection';
import { ColumnType, TableHeaderProps } from '../../../types/TablePaginator';
import { TableCellHeader } from '../TableStyles/TableCellHeader';

const CustomHeader = ({ children, width, extraStyles = {} }: any) => (
  <TableCellHeader style={{ ...extraStyles }}>
    <div style={{ whiteSpace: 'nowrap', fontSize: '15px', width }}>{children}</div>
  </TableCellHeader>
);

const OrderIcon = ({ active, direction, onClick }: any) => {
  if (!active) {
    return (
      <span style={{ maxHeight: '24px' }}>
        <ExpandMore data-cy="orderIcon" color="disabled" onClick={onClick} style={{ cursor: 'pointer' }} />
      </span>
    );
  }

  return direction === ASCENDING ? (
    <span role="button" title="ascending" style={{ maxHeight: '24px' }}>
      <ExpandLess onClick={onClick} style={{ cursor: 'pointer' }} />
    </span>
  ) : (
    <span role="button" title="descending" style={{ maxHeight: '24px' }}>
      <ExpandMore onClick={onClick} style={{ cursor: 'pointer' }} />
    </span>
  );
};

const TableHeader = ({
  columns,
  sortBy,
  showRenderDetails = false,
  sortDirection = 'asc',
  backgroundAlternated,
  onSortChange = () => {},
}: TableHeaderProps) => {
  const styles: any = {};
  if (!backgroundAlternated) {
    styles.borderBottom = 'none';
    styles.color = '#8e8e8e';
  }
  const handleOnSortChange = (column: string) => {
    const newSortDirection = sortDirection === ASCENDING ? DESCENDING : ASCENDING;
    onSortChange(column, newSortDirection);
  };

  return (
    <TableHead>
      {showRenderDetails && <CustomHeader />}
      {columns.map(({ field, title, sortable, align = 'start', sortField, width = 'initial' }: ColumnType) => (
        <CustomHeader key={`${field}_${title}`} sortDirection="asc" extraStyles={styles}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={`${align}`}
            textAlign={`${align}`}
            data-cy="columnTitle"
            style={{ paddingTop: '5px', paddingBottom: '15px', width }}
          >
            {title}
            {sortable && (
              <OrderIcon
                active={sortField ? sortField === sortBy : field === sortBy}
                direction={sortDirection}
                onClick={() => handleOnSortChange(sortField ?? field)}
                key={title}
              />
            )}
          </Box>
        </CustomHeader>
      ))}
    </TableHead>
  );
};

export default TableHeader;
