import React from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { CONSENTS_TITLE } from '../../constants/configuration.constants';

// Hooks
import { usePractitionerConsent } from '../../hooks/queries/useConsents';
import { usePractitionerStandingOrder } from '../../hooks/queries/useStandingOrders';

// Routes
import { PRACTITIONER_CONFIGURATION_PATH } from '../../routes/practitionerRoutes';

type LocationState = {
  id?: number;
  name?: string;
  configuration?: string;
};

const ViewConfiguration = () => {
  const history = useHistory();
  const { id, configuration }: LocationState = history.location.state || '';

  if (!id) {
    history.push(PRACTITIONER_CONFIGURATION_PATH);
    return null;
  }

  const { data } =
    configuration === CONSENTS_TITLE
      ? usePractitionerConsent(id.toString())
      : usePractitionerStandingOrder(id.toString());

  const navigateBack = () => {
    history.push(PRACTITIONER_CONFIGURATION_PATH);
  };

  return (
    <div>
      <NavigationBar title="View" onBackButtonClick={navigateBack} />
      <div style={{ width: '70%', margin: 'auto', marginTop: '50px' }}>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: data?.content as string }}
        />
      </div>
    </div>
  );
};

export default ViewConfiguration;
