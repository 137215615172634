import React from 'react';

import { AttachFile } from '@material-ui/icons';
import { CardContent, Grid, Fab } from '@material-ui/core';

import { useStyles } from './AddImage.styles';
import { IAddPhotoProps } from '../../types/ProgressNoteTypes';

const AddPhoto = ({ setPhotosList, setTempPhotoList }: IAddPhotoProps) => {
  const classes = useStyles();

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const filesArr = Array.prototype.slice.call(files);
    filesArr.forEach((file: Blob) => {
      setPhotosList((prev: Blob[]) => [...prev, file]);
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setTempPhotoList((prev) => [...prev, { src: String(reader.result) }]);
        };
      }
    });
  };

  return (
    <div className={classes.AttachFileContainer}>
      <CardContent className={classes.cardContent}>
        <Grid container justify="center" alignItems="center">
          <label htmlFor="patientPhoto" aria-hidden="true">
            <Fab component="span" className={classes.AttachFilesButton}>
              <AttachFile />
            </Fab>
            <input
              accept="image/png, image/jpeg"
              className={classes.AttachFilesInput}
              id="patientPhoto"
              type="file"
              multiple
              onChange={handleChangeImage}
            />
          </label>
        </Grid>
      </CardContent>
    </div>
  );
};
export default AddPhoto;
