/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import { CardContent, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Scanner from '../common/Scanner';
import { Card } from '../common/card';
import { RemainingUnitsModal } from '../common/RemainingUnitsModal/RemainingUnitsModal';
import { IAsset } from '../../interfaces/reconciliation.interfaces';
import { getPluralWord } from '../../utils/inventory.utils';
import { useStyles } from '../NewServiceVisit/scanner/ServiceVisitScanner.styles';

import compile from '../../utils/toastMessagesCompiler';
import IServices from '../../interfaces/IServices';
import { ILineItem, IServiceVisitAssetScanned } from '../../interfaces/IServiceVisits';
import { useSaveServiceVisitAssetMutation } from '../../hooks/serviceVisit/useServiceVisitAssets';
import { showSnackbar } from '../../utils/global';
import { SNACKBAR_WARNING } from '../../constants/general.constants';
import {
  calculateUnitsToSuggest,
  getServiceUnitsScanned,
  getServiceUnitsToScan,
} from '../../utils/serviceVisit/scanner.utils';
import ServiceVisitScannedProductList from './ServiceVisitScannedProductList';
import { ManualForm } from '../NewServiceVisit/scanner/ManualForm';

const defaultModalData = { name: '', internalSerialNumber: '', currentNumberOfUnits: 0, toDraw: 0 };

interface Props {
  services: IServices[];
  pendingServices: IServices[];
  lineItems: ILineItem[];
  serviceVisitAssets: IServiceVisitAssetScanned[];
  disabled: boolean;
  display?: string;
}

export const ServiceVisitScannerStep = ({
  services,
  pendingServices,
  lineItems,
  serviceVisitAssets,
  disabled,
  display = '',
}: Props) => {
  const classes = useStyles();
  const { serviceVisitId }: any = useParams();

  const scannerInputRef = useRef<HTMLInputElement>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(defaultModalData);
  // TODO: check if we can remove showInputs var
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showInputs, setShowInputs] = useState<any>({});

  const saveServiceVisitAssetMutation = useSaveServiceVisitAssetMutation();

  const isServiceSelected = (serviceId: number) => services.some(({ id }) => id === serviceId);

  const confirmUnits = async (asset: IAsset, units: number): void => {
    if (modalData.toDraw) {
      await saveServiceVisitAssetMutation.mutate({
        serviceVisitId,
        assetId: asset.id,
        units,
      });
    }
    setModalData(defaultModalData);
    setIsModalOpen(false);
    // Timeout set for transition effects when asset is added
    setTimeout(() => focusScannerInput(), 1000);
  };

  const handleAssetChecked = (asset: IAsset) => {
    if (!isServiceSelected(asset.serviceId)) {
      showSnackbar(compile('new_service_visit.asset_not_in_service_selected'));
      return;
    }

    const lineItem = lineItems.find(({ serviceId }) => serviceId === asset.serviceId);
    const service = services.find(({ id }) => id === asset.serviceId);
    const unitsAllowedToScan = service ? getServiceUnitsToScan(service, lineItems) : 0;

    if (unitsAllowedToScan === 0) {
      const warning = compile(
        lineItem?.futureUseQuantity ? 'new_service_visit.asset_for_future_use' : 'new_service_visit.no_units_selected'
      );
      showSnackbar(warning, SNACKBAR_WARNING);
      return;
    }

    const serviceVisitAssetForService = serviceVisitAssets.filter(({ serviceId }) => serviceId === asset.serviceId);
    const sumOfScannedUnits = getServiceUnitsScanned(serviceVisitAssetForService, asset.allowPartialSale);

    // Check if the amount of scanned products reach the limit
    if (sumOfScannedUnits >= unitsAllowedToScan) {
      showSnackbar(compile('new_service_visit.reached_asset_limit', { serviceName: asset.name }), SNACKBAR_WARNING);
      return;
    }

    if (asset.allowPartialSale) {
      const unitsToSugget = calculateUnitsToSuggest(asset, sumOfScannedUnits, unitsAllowedToScan);

      if (unitsToSugget === 0) {
        showSnackbar(compile('new_service_visit.reached_asset_limit', { serviceName: asset.name }), SNACKBAR_WARNING);
        return;
      }

      setModalData({ ...asset, toDraw: unitsToSugget });
      setIsModalOpen(true);
      return;
    }

    // Continue for non-allow partial sales
    saveServiceVisitAssetMutation.mutate({
      serviceVisitId,
      assetId: asset.id,
      units: asset.currentNumberOfUnits,
    });
  };

  const hasAnyManualInputOpened = () => Object.keys(showInputs).some((key: string) => showInputs[key]);

  const focusScannerInput = () => {
    if (scannerInputRef && scannerInputRef.current && !hasAnyManualInputOpened()) {
      scannerInputRef.current.focus();
    }
  };

  const getPrimaryTextForModal = (): string =>
    `This bottle is expected to have ${modalData.currentNumberOfUnits} units. <br> Will you draw <b>${
      modalData.toDraw
    } ${getPluralWord('unit', modalData.toDraw !== 1)}</b> from this vial?`;

  return (
    <div style={{ display }} onClick={focusScannerInput}>
      <Card style={{ marginBottom: '0px' }}>
        <CardContent className={classes.cardContent}>
          <div className={classes.contentCheckout}>
            <Typography>Inventory update</Typography>
          </div>
          {!disabled && (
            <div className={classes.contentButton}>
              <Scanner successCallback={handleAssetChecked} scannerInputRef={scannerInputRef} disabled={disabled} />
            </div>
          )}
          <div className={classes.contentTotal}>
            {!pendingServices.length && !serviceVisitAssets.length && compile('new_service_visit.no_assets_to_scan')}
            {pendingServices.map((service, i) => (
              <ManualForm key={`${service.id}-${i}`} service={service} showInputs={null} />
            ))}
            <ServiceVisitScannedProductList
              serviceVisitId={serviceVisitId}
              serviceVisitAssets={serviceVisitAssets}
              disabled={disabled}
            />
          </div>
        </CardContent>
      </Card>
      <RemainingUnitsModal
        open={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        confirmUnits={confirmUnits}
        asset={modalData}
        primaryText={getPrimaryTextForModal()}
        secondaryText="How many will you draw?"
        secondaryButtonLabel="No, I'll draw fewer or more"
        max={modalData.toDraw || 1}
      />
      {/* max must be deprecated (We're using same compoenent than old sv version) */}
    </div>
  );
};
