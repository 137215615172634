import React from 'react';
import { Box, Table, TableBody, TableContainer } from '@material-ui/core';
import Page from 'src/components/common/Page';
import { dispatch } from '../rematch';
import TableHeader from '../components/common/Table/TableHeader';
import { IPhysician } from '../interfaces/IPhysician';
import { usePhysicians } from '../hooks/queries/usePhysicians';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import compile from '../utils/toastMessagesCompiler';
import PhysicianHeader from '../components/DashboardAdministrator/Physicians/PhysicianHeader';
import PhysicianRow from '../components/DashboardAdministrator/Physicians/PhysicianRow';
import { usePractitioners } from '../hooks/queries/usePractitioners';

const TABLE_COLUMNS = [
  { id: 'id', title: 'ID' },
  { id: 'fullname', title: 'Fullname' },
  { id: 'professionalName', title: 'Professional Name' },
  { id: 'email', title: 'Email' },
  { id: 'workingStates', title: 'States' },
  { id: 'assignedProviderIds', title: 'Assigned Providers' },
  { id: 'medicalMemberType', title: 'Medical Member' },
];

const PhysiciansPage: React.FC = () => {
  const { data: physicians, isError, isLoading } = usePhysicians();
  const { data: practitioners, isLoading: isLoadingPractitioners } = usePractitioners();

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'physicians',
        }),
        type: 'error',
      },
    });
  }

  const TableComponent = (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHeader columns={TABLE_COLUMNS} />
          <TableBody>
            {isLoading || isLoadingPractitioners ? (
              <MultipleSkeleton width="80%" />
            ) : (
              physicians &&
              practitioners &&
              physicians.map(
                ({
                  id,
                  identity,
                  professionalName,
                  workingStates,
                  medicalMemberType,
                  assignedProviderIds,
                }: IPhysician) => (
                  <PhysicianRow
                    key={id}
                    id={id}
                    fullname={`${identity?.firstName} ${identity?.lastName}`}
                    professionalName={professionalName || ''}
                    email={identity?.email}
                    assignedProviders={assignedProviderIds.map((practitionerId) => {
                      const provider = practitioners.find((practitioner) => practitioner.id === +practitionerId);
                      return provider ? `${provider.firstName} ${provider.lastName}` : '';
                    })}
                    workingStates={workingStates}
                    medicalMemberType={medicalMemberType || ''}
                  />
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Page title="Physicians">
      <Box width="100%">
        <PhysicianHeader />
        <Box>{TableComponent}</Box>
      </Box>
    </Page>
  );
};

export default PhysiciansPage;
