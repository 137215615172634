import React from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TableCellHeader } from '../../../common/TableStyles/TableCellHeader';
import { TableCellCustom } from '../../../common/TableStyles/TableCellCustom';
import ButtonAhref from '../../../common/ButtonAhref';

const ProductList = ({ onSaveChanges }: any) => {
  const { supplyReceiptId }: any = useParams();
  const { productsSelected } = useSelector((state: any) => state.newSupplyReceipt);

  return (
    <Box width="100%">
      <TableContainer>
        <Table style={{ border: '1px solid #e7e7e7' }}>
          <TableHead>
            <TableRow>
              <TableCellHeader>Product</TableCellHeader>
              <TableCellHeader>Scanned</TableCellHeader>
              <TableCellHeader>Product IDs</TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsSelected.map((service: any) => (
              <TableRow key={service.id}>
                <TableCellCustom>{service.name}</TableCellCustom>
                <TableCellCustom>
                  <span>{service.assets.length}</span>
                  <br />
                </TableCellCustom>
                <TableCellCustom onClick={() => onSaveChanges(false, service.id)}>
                  <ButtonAhref
                    href={`administrator/new-supply-receipt-step-2/${supplyReceiptId}/${service.id}`}
                    buttonStyle="notStyle"
                    colorLink="#000"
                  >
                    <Box style={{ display: 'inline-flex', border: '1px solid #e5efed' }}>
                      <MenuIcon />
                    </Box>
                  </ButtonAhref>
                </TableCellCustom>
              </TableRow>
            ))}
            {!productsSelected.length && (
              <TableRow>
                <TableCellCustom colSpan={3} style={{ textAlign: 'center', padding: '20px' }}>
                  No assets selected
                </TableCellCustom>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProductList;
