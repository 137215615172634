import { useMutation } from 'react-query';
import Patients from '../../services/Patients';
import { IMedicalProfile } from '../../types/Patient';

interface IMedicalProfileUpdate {
  customerId: number;
  medicalProfile: IMedicalProfile;
  payload: any;
}

export const useUpdateMedicalProfile = () =>
  useMutation(({ customerId, medicalProfile, payload }: IMedicalProfileUpdate) =>
    Patients.updateMedicalProfile({ customerId, medicalProfile: { ...medicalProfile, ...payload } })
  );
