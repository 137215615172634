import { createModel } from '@rematch/core';
import { IServiceCredit, ServiceCredits } from '../types/ServiceCredits';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import compile from '../utils/toastMessagesCompiler';

const CREDITS_INITIAL_STATE: ServiceCredits = {
  credits: [],
  isLoadingServiceCredits: false,
};

export const serviceCredits = createModel<RootModel>()({
  state: CREDITS_INITIAL_STATE,
  reducers: {
    setIsLoadingServiceCredits(state: ServiceCredits, payload: boolean) {
      return { ...state, isLoadingServiceCredits: payload };
    },
    setCredits(state: ServiceCredits, payload: IServiceCredit[]) {
      return { ...state, credits: payload };
    },
    resetCredits() {
      return { ...CREDITS_INITIAL_STATE };
    },
  },
  effects: (dispatch: any) => ({
    async fetchCredits(payload: { customerId: number }) {
      dispatch.serviceCredits.setIsLoadingServiceCredits(true);
      try {
        const { customerId } = payload;
        const request = await axiosInstance.get(`/patient-profile/${customerId}/credits`);
        dispatch.serviceCredits.setCredits(request.data.credits);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'credits',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.serviceCredits.setIsLoadingServiceCredits(false);
      }
    },
  }),
});
