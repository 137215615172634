import React from 'react';
import { useSelector } from 'react-redux';
import { TableCell, TableRow } from '@material-ui/core';
import { useRowStyles } from './Location.styles';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import ButtonAhref from '../../common/ButtonAhref';
import { hasAccessTo } from '../../../utils/auth.utils';
import { UPDATE_LOCATION } from '../../../constants/actions.constants';

export interface LocationRowProps {
  id?: number;
  providerName?: string;
  customAddress?: string;
  name: string;
  city: string;
  state: string;
}

const LocationRow: React.FC<LocationRowProps> = (props) => {
  const { id: locationId, providerName, customAddress, name, city, state } = props;
  const classes = useRowStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  return (
    <>
      <TableRow data-cy="tableRow" className={classes.root}>
        <TableCellCustom>{providerName}</TableCellCustom>
        <TableCellCustom data-cy="name">{name}</TableCellCustom>
        <TableCellCustom data-cy="cityAndState">
          {city} / {state}
        </TableCellCustom>
        <TableCellCustom data-cy="address">{customAddress}</TableCellCustom>
        <TableCell>
          {hasAccessTo(UPDATE_LOCATION, permissions) && (
            <ButtonAhref text="EDIT" href={`/administrator/new-location/${locationId}`} />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default LocationRow;
