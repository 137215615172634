import React, { useState, useEffect, useCallback } from 'react';
import { TextField, makeStyles, Typography, CardContent } from '@material-ui/core';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useAddMedicalDirectorNoteMutation,
  useAddServiceVisitNoteMutation,
} from '../../hooks/serviceVisit/useServiceVisit';
import IServiceVisit from '../../interfaces/IServiceVisits';
import { Card } from '../common/card';
import { Button } from '../common/Button';
import { dispatch, RootState } from '../../rematch';
import compile from '../../utils/toastMessagesCompiler';
import formatDate from '../../utils/formatDate';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: '0',
    borderRadius: '0 0 9px 9px ',
  },
  cardContent: {
    padding: '0px 20px 9px !important',
  },
  title: {
    margin: theme.spacing(2.5, 5, 1.75, 0),
    fontSize: '15px',
    lineHeight: 1.93,
    color: '#000000',
  },
  noteField: {
    marginBottom: theme.spacing(1.5),
  },
  skeleton: {
    margin: theme.spacing(1.5, 0),
  },
  submitButton: {
    backgroundColor: '#12574d',
    color: '#ffffff !important',
    margin: '0',
    '&:focus': {
      backgroundColor: '#12574d',
    },
  },
  reviewButtons: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  medicalDirectorNoteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  medicalDirectorNote: {
    width: '48%',
    height: '180px',
  },
  medicalDirectorSignature: {
    width: '48%',
    height: '180px',
    '& > div': {
      border: '1px solid #0000003b !important',
    },
  },
  signature: {
    border: '1px solid #0000003b !important',
    borderRadius: '4px',
    padding: '1rem 2rem',
    height: '113px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signatureImage: {
    objectFit: 'contain',
    width: '80%',
    height: '100%',
  },
}));

interface INotesProps {
  serviceVisitId: number;
  serviceVisit: IServiceVisit;
  isPhysician?: boolean;
  canWriteMedicalNote?: boolean;
  addMedicalNoteCb?: () => void;
}

const ServiceVisitNotes = ({
  serviceVisitId,
  serviceVisit,
  isPhysician,
  canWriteMedicalNote,
  addMedicalNoteCb,
}: INotesProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector(({ auth }: RootState) => auth);
  const [customerNote, setCustomerNote] = useState<string>('');
  const [internalNote, setInternalNote] = useState<string>('');
  const [medicalDirectorNote, setMedicalDirectorNote] = useState<string>('');
  const [savingMedicalNote, setSavingMedicalNote] = useState<boolean>(false);
  const [isFirstRun, setIsFirstRun] = useState<boolean>(true);
  const addNoteMutation = useAddServiceVisitNoteMutation(+serviceVisitId);
  const addMedicalDirectorNoteMutation = useAddMedicalDirectorNoteMutation(+serviceVisitId);

  const saveNotesDebounce = useCallback(
    debounce((newCustomerNote: string, newInternalNote: string) => {
      addNoteMutation.mutateAsync({
        customerNote: newCustomerNote,
        internalNote: newInternalNote,
      });
    }, 2000),
    []
  );

  useEffect(() => {
    setInternalNote(serviceVisit.internalNote);
    setCustomerNote(serviceVisit.customerNote);
  }, [serviceVisit]);

  useEffect(() => {
    if (isFirstRun || (serviceVisit.customerNote === customerNote && serviceVisit.internalNote === internalNote)) {
      setIsFirstRun(false);
      return;
    }

    if (serviceVisit.id) {
      saveNotesDebounce(customerNote, internalNote);
    }
  }, [customerNote, internalNote]);

  const handleSubmitMedicalDirectorNote = async () => {
    try {
      setSavingMedicalNote(true);
      if (serviceVisit.id) {
        await addMedicalDirectorNoteMutation.mutateAsync({ medicalDirectorNote });
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Medical Note',
              action: 'added',
            }),
          },
        });
      }
      addMedicalNoteCb?.();
    } finally {
      setSavingMedicalNote(false);
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" className={classes.title}>
          Customer Note
        </Typography>
        <TextField
          onChange={(e) => setCustomerNote(e.target.value || '')}
          defaultValue={customerNote}
          placeholder="Customer Note"
          type="text"
          variant="outlined"
          rows={2}
          fullWidth
          multiline
          disabled={isPhysician}
          className={classes.noteField}
        />

        <Typography variant="h5" className={classes.title}>
          Internal Note
        </Typography>
        <TextField
          onChange={(e) => setInternalNote(e.target.value || '')}
          defaultValue={internalNote}
          placeholder="Internal Note"
          type="text"
          variant="outlined"
          rows={2}
          fullWidth
          multiline
          disabled={isPhysician}
          className={classes.noteField}
        />
        {serviceVisit?.medicalDirectorNotes?.map((mdNote) => (
          <div className={classes.medicalDirectorNoteContainer} key={mdNote.id}>
            <div className={classes.medicalDirectorNote}>
              <Typography variant="h5" className={classes.title}>
                Medical Director Notes written on {formatDate(mdNote.createdAt)}
              </Typography>
              <TextField
                value={mdNote.note}
                type="text"
                variant="outlined"
                rows={4}
                fullWidth
                disabled
                multiline
                className={classes.noteField}
              />
            </div>
            <div className={classes.medicalDirectorSignature}>
              <Typography variant="h5" className={classes.title}>
                Signed by {mdNote.physicianName}
              </Typography>
              <div className={classes.signature} id={classes.signature}>
                <img src={mdNote.signatureImageUrl} alt="signature" className={classes.signatureImage} />
              </div>
            </div>
          </div>
        ))}

        {canWriteMedicalNote && (
          <div className={classes.medicalDirectorNoteContainer}>
            <div className={classes.medicalDirectorNote}>
              <Typography variant="h5" className={classes.title}>
                Medical Director Notes
              </Typography>
              <TextField
                onChange={(e) => setMedicalDirectorNote(e.target.value || '')}
                value={medicalDirectorNote}
                placeholder="Medical Director Note"
                type="text"
                variant="outlined"
                rows={4}
                fullWidth
                multiline
                className={classes.noteField}
              />
            </div>
            <div className={classes.medicalDirectorSignature}>
              <Typography variant="h5" className={classes.title}>
                Your Signature
              </Typography>
              <div className={classes.signature}>
                <img src={user.signatureImageUrl} alt="signature" className={classes.signatureImage} />
              </div>
            </div>
          </div>
        )}
        {canWriteMedicalNote && (
          <div className={classes.reviewButtons}>
            <Button title="REVIEW LATER" onClick={() => history.goBack()} />
            <Button
              title="SIGN & SUBMIT VISIT"
              disabled={savingMedicalNote || !medicalDirectorNote}
              onClick={handleSubmitMedicalDirectorNote}
              className={classes.submitButton}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ServiceVisitNotes;
