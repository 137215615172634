import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TableContainer, Table, TableHead, TableRow, TableBody, withStyles, TableCell } from '@material-ui/core';
import { orderBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { IS_SUPPORT } from 'src/constants/actions.constants';
import { hasAccessTo } from 'src/utils/auth.utils';

import RowItem from './RowItem';
import { PHYSICIAN_ROLE, PRACTITIONER_ROLE } from '../../constants/general.constants';
import { useProgressNotes } from '../../hooks/queries/useProgressNotes';
import ProgressNoteItemRow from './ProgressNoteItemRow';
import { IProgressNote, IProgressNoteHistoryItem } from '../../types/ProgressNoteTypes';
import IServiceVisit from '../../interfaces/IServiceVisits';
import { SERVICE_VISIT_VERSIONS } from '../../constants/serviceVisits/serviceVisit.constants';
import RowItemV3 from './RowItemV3';

const TableCellHeader = withStyles(() => ({
  root: {
    fontSize: '15px',
  },
}))(TableCell);

interface ITableHistory {
  data: IServiceVisit[];
}
const TableHistory = (props: ITableHistory) => {
  const { userType, permissions } = useSelector(({ auth }: any) => auth);
  const [rowId, getRowId] = useState<number | null>(null);
  const { patientId }: { patientId: string } = useParams();

  const { data: visits } = props;
  const { data: progressNotes, isLoading } = useProgressNotes(+patientId);

  const kindVisits = visits?.map((visit: IServiceVisit) => ({ ...visit, type: 'serviceVisit' }));
  const kindProgressNotes = progressNotes?.map((note: IProgressNote) => ({ ...note, type: 'progressNote' }));
  const fullServiceHistoryList = kindVisits?.concat(kindProgressNotes);

  const isSupport = hasAccessTo(IS_SUPPORT, permissions);

  return (
    <TableContainer style={{ paddingTop: '15px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader width={50} />
            <TableCellHeader width={150}>Type</TableCellHeader>
            <TableCellHeader width={240}>Date</TableCellHeader>
            <TableCellHeader>Services</TableCellHeader>
            <TableCellHeader>Units</TableCellHeader>
            {([PRACTITIONER_ROLE, PHYSICIAN_ROLE].includes(userType) || isSupport) && (
              <TableCellHeader>Actions</TableCellHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            orderBy(fullServiceHistoryList, ['createdAt'], ['desc']).map((progressNoteOrVisit: any, index: number) => {
              if (progressNoteOrVisit.type === 'serviceVisit') {
                if (progressNoteOrVisit.version >= SERVICE_VISIT_VERSIONS.VERSION_JULY_2023) {
                  return (
                    <RowItemV3
                      key={`visit-${progressNoteOrVisit.id}`}
                      patientId={+patientId}
                      visit={progressNoteOrVisit as IServiceVisit}
                      striped={index}
                      getRowId={getRowId}
                      rowId={rowId}
                    />
                  );
                }

                return (
                  <RowItem
                    key={`visit-${progressNoteOrVisit.id}`}
                    patientId={+patientId}
                    visit={progressNoteOrVisit as IServiceVisit}
                    striped={index}
                    getRowId={getRowId}
                    rowId={rowId}
                  />
                );
              }

              return (
                <ProgressNoteItemRow
                  key={`progressNote-${progressNoteOrVisit.id}`}
                  progressNote={progressNoteOrVisit as IProgressNoteHistoryItem}
                  striped={index}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableHistory;
