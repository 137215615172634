import React from 'react';
import { Box } from '@material-ui/core';

const TabItem = (props: any) => {
  const { children, value, index } = props;

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabItem;
