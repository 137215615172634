import React from 'react';
import { useHistory } from 'react-router-dom';
import { CardContent } from '@material-ui/core';
import { Create as CreateIcon } from '@material-ui/icons/';
import { useStyles } from './contactInfo.styles';
import { Title, Details } from '../../common/card';
import { SKIN_TYPE_LABELS, FREQUENCY_LABELS } from '../../../constants/medicalProfile.constants';
import { IconButton } from '../../common/IconButton';

const SkinProfile = (props: any) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    skinConcerns,
    serviceImprovements,
    skinType,
    sunscreenFrequency,
    retinalRetinA,
    usingPeels,
    goalsNotes,
    id,
    isMedspaAdmin = false,
  } = props;

  const details = [
    { label: 'Skin concerns', value: skinConcerns },
    { label: 'Service improvements', value: serviceImprovements },
    { label: 'Skin type', value: SKIN_TYPE_LABELS[skinType] || 'None' },
    { label: 'Sunscreen frequency', value: FREQUENCY_LABELS[sunscreenFrequency] || 'None' },
    { label: 'Retinal/Retin-A', value: retinalRetinA },
    { label: 'Using peels', value: usingPeels },
    { label: 'Goals/Notes', value: goalsNotes },
  ];

  const navigateMedicalProfileForm = () => {
    history.push(`/patient/${id}/skinProfile`);
  };

  return (
    <CardContent className={classes.cardContent}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title title="Skin Profile" style={{ marginTop: '0', marginBottom: '15px' }} />
        {!isMedspaAdmin && (
          <IconButton
            onClick={navigateMedicalProfileForm}
            title="Edit"
            style={{ color: '#000000', backgroundColor: '#e7eeed' }}
            Icon={<CreateIcon />}
          />
        )}
      </div>
      <div>
        <Details details={details} />
      </div>
    </CardContent>
  );
};

export default SkinProfile;
