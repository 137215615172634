import { useMutation } from 'react-query';
import ServiceAcknowledgments from '../../services/ServicesAcknowledgments';

interface IServiceAcknowledgmentsInActivityLog {
  patientId: string;
  serviceId: number;
  serviceVisitId: number;
}

export const useAcceptedServiceAckActivityLog = () =>
  useMutation(({ patientId, serviceId, serviceVisitId }: IServiceAcknowledgmentsInActivityLog) =>
    ServiceAcknowledgments.acceptedServiceAcknowledgmentActivityLog({ patientId, serviceId, serviceVisitId })
  );
