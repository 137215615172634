import { datadogRum } from '@datadog/browser-rum';

const logDatadogMessage = (message: string, extra?: object) => {
  datadogRum.addError(message, extra);
};

export const logDatadogMessageError = (message: string, extra?: object) => {
  logDatadogMessage(message, extra);
};

export const logDatadogException = (error: Error, extra?: object) => {
  datadogRum.addError(error, extra);
};

export const logDatadogAction = (message: string, extra?: object) => {
  datadogRum.addAction(message, extra);
};
