import Api from './Api';
import { ILeadUpdateParams, ILeadContactDetails } from '../interfaces/ILeadLandingPage';
import API_ROUTES, { PUBLIC_CONTACT_DETAILS, PUBLIC_LEAD_SUBMIT } from '../constants/apiRoutes.constants';
import {
  IClientLeadApi,
  IClientLeadLookup,
  IClientLeadProviders,
  ILeadReportParams,
  ILeadReportData,
  ILeadInternalUpdateParams,
} from '../interfaces/IClientLead';

const ClientLeads = {
  async getClientLeads({
    practitionerId,
    status,
    searchTerms,
    page,
    sortBy,
    sortType,
  }: IClientLeadLookup): Promise<IClientLeadApi | null> {
    const { success, data }: { success: boolean; data: IClientLeadApi | string } = await Api.post(
      API_ROUTES.CLIENT_LEAD_INDEX,
      { practitionerId, status, searchTerms, page, sortBy, sortType }
    );
    return success && typeof data !== 'string' ? data : null;
  },

  async getClientLeadProviders(disallowShowAll?: boolean): Promise<IClientLeadProviders | null> {
    const { success, data }: { success: boolean; data: IClientLeadProviders | string } = await Api.post(
      API_ROUTES.CLIENT_LEAD_PROVIDERS,
      { disallowShowAll }
    );
    return success && typeof data !== 'string' ? data : null;
  },

  async getClientLeadReports(params: ILeadReportParams): Promise<ILeadReportData | null> {
    const { success, data }: { success: boolean; data: ILeadReportData | null } = await Api.post(
      API_ROUTES.CLIENT_LEAD_REPORTS,
      params
    );
    return success ? data : null;
  },

  async updateClientLead(params: ILeadInternalUpdateParams): Promise<void> {
    await Api.post(API_ROUTES.CLIENT_LEAD_UPDATE, params);
  },

  async updateExternalClientLead(params: ILeadUpdateParams): Promise<void> {
    await Api.post(PUBLIC_LEAD_SUBMIT, params);
  },

  async getContactDetails(adCampaignKey: string): Promise<ILeadContactDetails | null> {
    const { success, data }: { success: boolean; data: ILeadContactDetails | string } = await Api.post(
      PUBLIC_CONTACT_DETAILS,
      { adCampaignKey }
    );
    return success && typeof data !== 'string' ? data : null;
  },
};

export { ClientLeads, ClientLeads as default };
