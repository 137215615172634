import { useMutation, useQuery, useQueryClient } from "react-query";
import { SERVICE_VISIT_ASSET_SCANNED, SERVICE_VISIT_CHECKOUT } from "../../constants/reactQuery.keys";
import ServiceVisit from "../../services/ServiceVisit";
import {
  IDeleteServiceVisitAssetParams,
  ISaveServiceVisitAssetParams,
  IErrorResponseParams,
} from "../../interfaces/params/IServiceVisitParams";
import { dispatch } from "../../rematch";
import compile from "../../utils/toastMessagesCompiler";
import { showSnackbar, useReactQueryOnErrorHandler } from "../../utils/global";
import { SNACKBAR_SUCCESS } from "../../constants/general.constants";

/* Get Service visit assets scanned
 * Params: id (service visit id)
 */
export function useServiceVisitAssetsScanned(id: number, enabled = true) {
  const response = useQuery([SERVICE_VISIT_ASSET_SCANNED, id], () => ServiceVisit.getServiceVisitAssetsScanned(id), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: true,
    enabled,
    onError: () =>
      useReactQueryOnErrorHandler(compile("generic.error_message", { action: "fetching", element: "assets scanned" })),
  });

  return {
    ...response,
    data: response.data || [],
  };
}

export const useSaveServiceVisitAssetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ISaveServiceVisitAssetParams) => ServiceVisit.saveServiceVisitAssetsScanned(params.serviceVisitId, params),
    {
      onSuccess: (res, params: any) => {
        showSnackbar(compile("generic.product_added"), SNACKBAR_SUCCESS);
        queryClient.invalidateQueries([SERVICE_VISIT_ASSET_SCANNED]);
        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, +params.serviceVisitId]);
      },
      onError: (error: IErrorResponseParams) => {
        const errorMessage = error.response?.data?.message || "An unexpected error occurred";
        useReactQueryOnErrorHandler(errorMessage);
      },
    }
  );
};

export const useDeleteServiceVisitAssetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: IDeleteServiceVisitAssetParams) => ServiceVisit.deleteServiceVisitAsset(params.serviceVisitId, params),
    {
      onSuccess: (res, params: any) => {
        dispatch({
          type: "snackbar/enqueueSnackBar",
          payload: {
            message: compile("generic.product_removed"),
          },
        });
        queryClient.invalidateQueries([SERVICE_VISIT_ASSET_SCANNED]);
        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, +params.serviceVisitId]);
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};

export const useRescanServiceVisitAssetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { serviceVisitId: number; assetId: number; newAssetId: number }) => ServiceVisit.rescanAsset(data),
    {
      onSuccess: (res, params: any) => {
        queryClient.invalidateQueries([SERVICE_VISIT_ASSET_SCANNED]);
        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, +params.serviceVisitId]);
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};
