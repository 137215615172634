import { useQuery } from 'react-query';
import { IMedicalProfile } from 'src/types/Patient';
import Patients from '../../services/Patients';
import { MEDICAL_PROFILE } from '../../constants/reactQuery.keys';

interface IUseCustomMedicalProfile {
  data?: IMedicalProfile;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export function useCustomMedicalProfile(id: string): IUseCustomMedicalProfile {
  const { data, isLoading, isFetching, isError, isSuccess } = useQuery(
    [MEDICAL_PROFILE, id],
    () => Patients.getMedicalProfile(id),
    { refetchOnWindowFocus: false }
  );

  return {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  };
}

export { MEDICAL_PROFILE } from '../../constants/reactQuery.keys';
