import { useMutation } from 'react-query';
import ProgressNote from '../../services/ProgressNote';

interface IUploadProgressNotePhoto {
  patientId: number;
  formData: FormData;
}

export const useUploadProgressNotePhoto = () =>
  useMutation(({ patientId, formData }: IUploadProgressNotePhoto) =>
    ProgressNote.uploadProgressNotePhoto({ patientId, formData })
  );
