import { UseQueryResult, useQuery } from 'react-query';
import { PRODUCT_UTILIZATION } from 'src/constants/reactQuery.keys';
import { ProductUtilizationFilters, IProductUtilization } from 'src/interfaces/IInventoryOrder';
import ProductUtilization from 'src/services/ProductUtilization';

export const useProductUtilization = ({
  disabled,
  ...params
}: ProductUtilizationFilters): UseQueryResult<IProductUtilization | null> =>
  useQuery<IProductUtilization | null>({
    queryKey: [PRODUCT_UTILIZATION, ...Object.values(params)],
    queryFn: (): Promise<IProductUtilization | null> => ProductUtilization.getProductUtilization(params),
    enabled: !disabled,
  });
