// eslint-disable-next-line import/no-unresolved
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button as MaterialButton } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  navigationButtons: {
    display: 'flex',
    lineHeight: '50px',
    color: 'white',
  },
  navigationButton: {
    width: '36px',
    border: '1px solid #5F8E87',
    height: '36px',
    marginTop: '8px',
    textAlign: 'center',
    lineHeight: '48px',
    borderRadius: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  todayNavigation: {
    border: '1px solid #5F8E87',
    borderRadius: '3px',
    height: '36px',
    width: '72px',
    lineHeight: '32px',
    textAlign: 'center',
    margin: '8px 8px 0px 8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  currentDate: {
    fontSize: '20px',
    letterSpacing: '-1.36px',
    color: '#FFFFFF',
    lineHeight: '50px',
    marginLeft: '17px',
    width: '170px',
  },
  viewsSelectors: {
    width: '214px',
    height: '36px',
    background: '#2b6960',
    borderRadius: '9px',
    display: 'flex',
    margin: '7px',
    marginInlineStart: 'auto',
  },
  viewSelector: {
    height: '32px',
    padding: '7px 18px',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  viewSelectorActive: {
    background: '#5F8E87',
    borderRadius: '11px',
    marginTop: '2px',
    lineHeight: '17px',
  },
}));

export const useCardStyles = makeStyles(() => ({
  cardContainer: {
    top: '394px',
    left: '165px',
    width: '455px',
    background: '#E7EEED 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 8px #00000071',
  },
  cardContent: {
    padding: '24px 69px 19px 19px',
    color: '#173F61',
  },
  cardTitle: {
    color: '#38AFBC',
    fontSize: '19px',
    margin: '12px 0px 4px 0px',
    '&:hover': {
      cursor: ({ customerId }: any) => (customerId ? 'pointer' : 'auto'),
      textDecoration: ({ customerId }: any) => (customerId ? 'underline' : 'none'),
    },
  },
  cardSubtitle: {
    padding: '4px 0px 4px',
    font: '-webkit-small-control',
  },
  cardDate: {
    letterSpacing: '-0.2px',
    color: '#38AFBC',
  },
  cardLocation: {
    letterSpacing: '-0.6px',
    color: '#949EA9',
    font: '-webkit-small-control',
    padding: '0px 0px 8px',
  },
  cardContact: {
    color: '#12574D',
    font: '-webkit-small-control',
    padding: '4px 0px',
  },
  cardContactIcon: {
    fontSize: '21px',
    background: 'white',
    borderRadius: '15px',
    padding: '3px',
    position: 'relative',
    top: '6px',
    marginRight: '4px',
  },
  noteText: {
    color: '#222222',
    fontSize: '100%',
    marginLeft: '25px',
    fontFamily: 'Messina Sans Regular',
  },
}));

export const useNotClearedStyles = makeStyles(() => ({
  notClearedTab: {
    marginRight: '30px',
    marginTop: '90px',
    right: '0px',
    position: 'absolute',
    height: '40px',
    width: '165px',
    background: '#FF5252 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 3px #00000071',
    borderRadius: '0px 0px 9px 9px',
    zIndex: 3,
    color: 'white',
    textAlign: 'center',
    fontSize: '20px',
    paddingTop: '7px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hasMoreTabs: {
    borderRadius: '0px',
  },
  unknownTab: {
    color: '#7d7d7d',
    background: '#e7eeed',
  },
  unknownButton: {
    color: '#7d7d7d !important',
    background: '#e7eeed !important',
    border: 'solid 1px #7d7d7d',
    '&:hover': {
      background: '#f4faf9 !important',
    },
  },
  extraTab: {
    marginTop: '128px',
  },
  notClearedPopUp: {
    zIndex: 3,
    position: 'absolute',
    textAlign: 'center',
    paddingTop: '3px',
    top: '0px',
    left: '0px',
    width: '311px',
    height: '100vh',
    background: '#E7EEED 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 12px #0000006c',
  },
  header: {
    top: '0px',
    left: '0px',
    width: '311px',
    height: '63px',
    position: 'absolute',
    color: 'white',
    fontSize: '34px',
    lineHeight: '63px',
    background: '#12574D 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 12px #0000001c',
  },
  opacityFont: {
    width: '100vw',
    height: '100vh',
    top: '0',
    position: 'absolute',
    opacity: '0.3',
    background: '#000',
    zIndex: 2,
  },
  buttonsSection: {
    marginTop: '65px',
    marginLeft: '-8px',
    height: 'calc( 100% - 396px )',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  miniCalendarContainer: {
    background: 'white',
    position: 'fixed',
    bottom: '0px',
  },
}));

export const CardButton = withStyles(() => ({
  root: {
    fontFamily: 'Messina Sans SemiBold',
    color: 'white',
    background: '#f75352',
    fontSize: '11px',
    padding: '2px',
    position: 'absolute',
    right: '14px',
    top: '10px',
    width: '98px',
    height: '25px',
  },
}))(MaterialButton);

export const NotClearedButton = withStyles(() => ({
  root: {
    width: '240px',
    height: '48px',
    background: '#FF5252',
    borderRadius: '4px',
    margin: '6px',
    color: 'white',
    '&:hover': {
      background: '#FD6161',
    },
  },
}))(MaterialButton);
