import * as Sentry from '@sentry/react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { Extras } from '@sentry/types';

const logSentry = (message: string, extra: Extras | undefined, level: Sentry.SeverityLevel) => {
  Sentry.captureMessage(message, { extra, level });
};

export const logSentryMessageError = (errorMessage: string, extra: Extras | undefined) => {
  logSentry(errorMessage, extra, 'error');
};

export const logSentryMessageInfo = (infoMessage: string, extra: Extras | undefined) => {
  logSentry(infoMessage, extra, 'info');
};

export const logSentryException = (exception: Error, extra?: Extras | undefined) => {
  Sentry.captureException(exception, { extra });
};
