import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { Stage, Layer, Image, Line } from 'react-konva';
import { CircularProgress, Typography } from '@material-ui/core';
import { setTimeout } from 'timers';
import Toolbar from './Toolbar';
import style from './Drawer.module.css';
import Annotation from '../../../interfaces/drawer.interfaces';
import { getAspectRatio, getRelativePointerPosition } from '../../../utils/drawer.utils';
import { DEFAULT_STROKE } from '../../../constants/annotations.constants';

const WIDTH = 318;
const HEIGHT = 445;

type Props = {
  layers: Annotation.Layer[];
  currentLayer: number;
  imageUrl: string;
  initialLines?: Annotation.Line[];
  handleSave: Function;
  bottomButtons: React.ReactNode;
  hideToolbar?: boolean;
  fullScreen: boolean;
  setFullScreen: Function;
};

const Drawer = (props: Props) => {
  const {
    imageUrl,
    layers,
    currentLayer,
    initialLines,
    handleSave,
    bottomButtons,
    hideToolbar,
    fullScreen,
    setFullScreen,
  } = props;

  const getLayerById = useCallback(
    (id) => {
      const layer = layers.find((l) => l.id === id);
      return layer;
    },
    [layers]
  );

  const [color, setColor] = useState<any>(getLayerById(currentLayer)?.color);
  const [tool, setTool] = useState<'pen' | 'eraser'>('pen');
  const [strokeWidth, setStrokeWidth] = useState<any>(DEFAULT_STROKE);
  const [lines, setLines] = useState<any>(initialLines);
  const [history, setHistory] = useState<any>(lines);
  const [historyIndex, setHistoryIndex] = useState<any>(0);
  const [stageWidth, setStageWidth] = useState<any>(WIDTH);
  const [stageHeight, setStageHeight] = useState<any>(HEIGHT);
  const [aspectWidth, setAspectWidth] = useState<any>(WIDTH);
  const [aspectHeight, setAspectHeight] = useState<any>(HEIGHT);
  const [imageNode, setImageNode] = useState<any>(null);
  const [scale, setScale] = useState<any>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string>();

  const isDrawing = useRef(false);
  const isZooming = useRef(false);

  const handleImageLoadingError = (e: any, failedImageUrl: string) => {
    const { path = [] } = e;
    setImageNode(null);
    setLoading(false);
    setLoadingError("It's not possible to load the image at this moment");
    const message = 'Error loading image';
    const context = path.map(({ baseURI, currentSrc }: any) => `error page: ${baseURI} image's src: ${currentSrc}`);
    const sentryMessage = `${message}. imageUrl: ${failedImageUrl} context: ${context.join('')}`;
    Sentry.captureMessage(sentryMessage, 'debug' as Sentry.Severity);
  };

  useEffect(() => {
    if (!imageUrl) {return () => {};}
    setLoading(true);
    setLoadingError('');
    const image = new window.Image();
    image.src = `${imageUrl}?no-cache=${new Date().getTime()}`;

    image.crossOrigin = 'Anonymous';

    const handleLoadImage = () => {
      const { width, height } = getAspectRatio({ width: WIDTH, height: HEIGHT }, image);
      setAspectHeight(height);
      setAspectWidth(width);
      setStageWidth(width);
      setStageHeight(height);
      setImageNode(image);
      setLoading(false);
    };

    image.addEventListener('load', handleLoadImage);
    image.addEventListener('error', (e) => handleImageLoadingError(e, imageUrl));
    return () => {
      image.removeEventListener('load', handleLoadImage);
      image.removeEventListener('error', (e) => handleImageLoadingError(e, imageUrl));
    };
  }, [imageUrl]);

  useEffect(() => {
    setLines(initialLines);
    setHistory(initialLines);
    setHistoryIndex(initialLines?.length);
  }, [initialLines]);

  useEffect(() => {
    setHistoryIndex(lines.length);
  }, [lines]);

  useEffect(() => {
    setColor(getLayerById(currentLayer)?.color);
  }, [currentLayer]);

  const handleMouseDown = (e: any) => {
    // It's zooming
    if (e.evt?.touches?.length > 1) {
      isZooming.current = true;
      return;
    }

    isDrawing.current = true;
    // const pos = e.target.getStage().getPointerPosition();
    const pos = getRelativePointerPosition(e.target.getStage());

    setLines([
      ...lines,
      {
        id: currentLayer,
        color,
        strokeWidth,
        tool,
        points: [pos.x, pos.y],
      },
    ]);
  };

  const handleMouseMove = (e: any) => {
    try {
      if (!isDrawing.current) {
        return;
      }
      const stage = e.target.getStage();
      const point = getRelativePointerPosition(stage);

      const lastLine = lines[lines.length - 1];
      // add point
      lastLine.points = lastLine.points.concat([point.x, point.y]);

      // replace last
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
    handleSave(lines);
    setHistory(lines);
    setHistoryIndex(lines.length);
  };

  const handleToolbarChange = useCallback(
    (setting, value) => {
      let aspect: Annotation.Size = { width: 0, height: 0 };

      switch (setting) {
        case 'drawing_mode':
          setTool(value);
          break;
        case 'drawing_color':
          setColor(value);
          break;
        case 'drawing_stroke_width':
          setStrokeWidth(value);
          break;
        case 'reset_layer':
          setLines([]);
          break;
        case 'save_layer':
          handleSave(lines);
          break;
        case 'undo':
          setLines(lines.slice(0, lines.length - 1));
          handleSave(lines.slice(0, lines.length - 1));
          break;
        case 'full_screen':
          setFullScreen(true);
          aspect = getAspectRatio({ width: window.innerWidth, height: window.innerHeight }, imageNode);
          setStageWidth(aspect.width);
          setStageHeight(aspect.height);
          break;
        case 'normal_screen':
          setFullScreen(false);
          aspect = getAspectRatio({ width: WIDTH, height: HEIGHT }, imageNode);
          setStageWidth(aspect.width);
          setStageHeight(aspect.height);
          break;
        case 'redo':
          setLines(history.slice(0, historyIndex + 1));
          handleSave(history.slice(0, historyIndex + 1));
          break;
        default:
          break;
      }
    },
    [lines, history, historyIndex, imageNode]
  );

  useEffect(() => {
    const newScale = fullScreen ? Math.min(window.innerWidth / aspectWidth, window.innerHeight / aspectHeight) : 1;
    setScale(newScale);
  }, [fullScreen, window.innerHeight, window.innerHeight, aspectHeight, aspectWidth]);

  const reloadImageStyles = useCallback(() => {
    setTimeout(() => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (fullScreen) {
        const aspect = getAspectRatio({ width, height }, imageNode);
        setStageWidth(aspect.width);
        setStageHeight(aspect.height);
      }
    }, 50);
  }, [fullScreen, window.innerHeight, window.innerHeight]);

  window.onorientationchange = reloadImageStyles;

  const drawerStyle: any = {
    position: fullScreen ? 'fixed' : 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    width: fullScreen ? window.innerWidth : '100%',
    height: fullScreen ? window.innerHeight : HEIGHT,
    minWidth: stageWidth,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  };

  if (loading)
  {return (
    <div className={style.Drawer} style={drawerStyle}>
      <CircularProgress />
    </div>
  );}

  if (loadingError)
  {return (
    <div className={style.Drawer} style={drawerStyle}>
      <Typography variant="h6" gutterBottom>
        {loadingError}
      </Typography>
    </div>
  );}

  return (
    <div data-cy="drawer" className={style.Drawer} style={drawerStyle}>
      {imageNode && (
        <Stage
          width={stageWidth}
          height={stageHeight}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          draggable={false}
          scaleX={scale}
          scaleY={scale}
        >
          <Layer>
            <Image x={0} y={0} image={imageNode} width={aspectWidth} height={aspectHeight} />
          </Layer>
          <Layer>
            {lines.map((line: any, i: number) => (
              <Line
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                points={line.points}
                stroke={line.color}
                strokeWidth={Number(line.strokeWidth)}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={line.tool === 'eraser' ? 'destination-out' : 'source-over'}
              />
            ))}
          </Layer>
        </Stage>
      )}
      {!hideToolbar && (
        <Toolbar fullscreen={fullScreen} onToolChange={handleToolbarChange} bottomButtons={bottomButtons} />
      )}
    </div>
  );
};

Drawer.defaultProps = {
  initialLines: [],
  hideToolbar: false,
};

export default Drawer;
