/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '18px',
    color: '#12574D',
  },
  dialog: {
    borderRadius: '10px',
  },
  secondaryButton: {
    color: '#393B3F',
    border: 'solid 1px #FBDADA',
    backgroundColor: '#FBDADA',
    height: '36px',
    minWidth: '104px',
    margin: '5px',
  },
  questionContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    width: '100%',
  },
  applyButton: {
    color: '#ffffff !important',
    backgroundColor: '#12574d',
    height: '36px',
    '&:hover': {
      backgroundColor: '#104e45',
    },
    minWidth: '104px',
    margin: '5px',
  },
  container: {
    height: '207px',
    width: '391px',
    padding: '30px',
    position: 'relative',
  },
  footerButtons: {
    position: 'absolute',
    width: '85%',
    bottom: '20px',
    textAlign: 'center',
  },
}));

interface Props {
  children: React.ReactNode;
  open: boolean;
  confirmationText?: string;
  closeModal: () => void;
  confirm?: () => void;
  fullScreen?: boolean;
}

const DialogModal = ({ open, closeModal, confirm, confirmationText, children, fullScreen = false }: Props) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={closeModal} classes={{ paper: classes.dialog }}>
      <Box
        data-cy="modal"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        className={classes.container}
      >
        <Box>
          <div className={classes.questionContainer}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
              width="100%"
              textAlign="center"
              height="107px"
            >
              <Box mb={1}>
                <span data-cy="msg" style={{ fontFamily: 'Messina Sans Regular' }}>
                  {children}
                </span>
              </Box>
              {confirmationText && (
                <Box mb={1}>
                  <span data-cy="confirmationText" className={classes.title}>
                    {confirmationText}
                  </span>
                </Box>
              )}
            </Box>
            <Box className={classes.footerButtons}>
              <Button data-cy="noBtn" title="No" className={classes.secondaryButton} onClick={closeModal}>
                {confirm ? 'No' : 'Ok'}
              </Button>

              {confirm && (
                <Button data-cy="yesBtn" onClick={confirm} className={classes.applyButton}>
                  Yes
                </Button>
              )}
            </Box>
          </div>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogModal;
