import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CardContent, Box } from '@material-ui/core';
import classnames from 'classnames';
import moment from 'moment';
import { sortBy } from 'lodash';
import { useStyles } from './referral.styles';
import { Card } from '../../common/card';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { RootState, dispatch } from '../../../rematch';
import { hasMoreThanDate } from '../../../utils/global';
import ReferralsSummaryMemo from './ReferralsSummary';
import ReferralsSelector from './ReferralsSelector';
import ReferralAdvocateForm from './ReferralAdvocateForm';

interface Props {
  isAdmin: boolean;
}

const ReferralsCredit = ({ isAdmin = false }: Props) => {
  const classes = useStyles();

  const {
    basicInfo: { id, isAdvocate, advocateReason, hasReferred },
    serviceVisits,
    isLoadingBasicInfo,
    isLoadingToggleIsAdvocate,
  } = useSelector(({ patient }: RootState) => patient);

  const { isLoadingRedeemedCustomers, redeemed, notRedeemed } = useSelector(({ referrals }: RootState) => referrals);

  const canRefer =
    !(
      // Cannot refer if has more than 24hours from the first service visit, except for admins
      (
        serviceVisits !== null &&
        serviceVisits.length > 0 &&
        hasMoreThanDate(sortBy(serviceVisits, ({ createdAt }: any) => moment(createdAt))[0].createdAt, 1, 'day')
      )
    ) ||
    (isAdmin && serviceVisits.length > 0);

  useEffect(() => {
    if (id) {
      dispatch({ type: 'referrals/getRedeemedCustomers', payload: { customerId: id } });
    }
  }, [id]);

  const referralSummary = (isAdvocate || hasReferred) && (
    <Box mt={4}>
      <ReferralsSummaryMemo
        notRedeemed={notRedeemed}
        redeemed={redeemed}
        isAdvocate={isAdvocate}
        hasReferred={hasReferred}
      />
    </Box>
  );

  if (!id || isLoadingRedeemedCustomers) {
    return (
      <Card>
        <div style={{ margin: '20px' }}>
          <ShortMultipleSkeleton />
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box mb={2}>
          <p className={classes.title}>Referrals</p>
        </Box>

        {!isLoadingBasicInfo && (
          <ReferralAdvocateForm
            patientId={id}
            isAdvocate={isAdvocate}
            advocateReason={advocateReason}
            isLoadingToggleIsAdvocate={isLoadingToggleIsAdvocate}
            disabled={!canRefer}
          />
        )}
        <CardContent className={classnames(classes.referralsCreditContainer)}>
          <ReferralsSelector isAdmin={isAdmin} />
          {referralSummary}
        </CardContent>
      </CardContent>
    </Card>
  );
};

export default ReferralsCredit;
