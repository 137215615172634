import Api from './Api';
import { IRoles, IRolesParams } from '../interfaces/IRoles.interfaces';
import API_ROUTES from '../constants/apiRoutes.constants';

const Roles = {
  async getRoles(query: IRolesParams | null = null): Promise<IRoles[]> {
    const { roles } = await Api.get(API_ROUTES.ROLES, query);
    return roles;
  },
};

export default Roles;
