import React, { useCallback } from 'react';
import { Skeleton } from '@material-ui/lab/';
import { makeStyles, TextField } from '@material-ui/core';
import { debounce } from 'lodash';
import IServices from '../../../interfaces/IServices';
import { getServiceLabel } from '../../../utils/newServiceVisit.util';
import { ILineItem } from '../../../interfaces/IServiceVisits';

const useStyles = makeStyles(() => ({
  serviceNoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  nameSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  dot: {
    borderRadius: '50%',
    width: 16,
    height: 15,
    marginRight: 8,
  },
}));
const DEBOUNCE_INPUT_TIME = 250;

interface Props {
  service: IServices;
  lineItem: ILineItem | undefined;
  serviceNotes: { [serviceId: number]: string };
  serviceVisitOpened: boolean;
  isLoading: boolean;
  setServiceNotes: React.Dispatch<React.SetStateAction<{ [serviceId: number]: string }>>;
  setSaveMedicalCharting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ServiceNote = ({
  service,
  lineItem,
  serviceNotes,
  serviceVisitOpened,
  isLoading,
  setServiceNotes,
  setSaveMedicalCharting,
}: Props) => {
  const classes = useStyles();

  const handleNoteChange = useCallback(
    debounce((serviceId: number, note: string) => {
      setServiceNotes((prev) => ({
        ...prev,
        [serviceId]: note,
      }));
      setSaveMedicalCharting(true);
    }, DEBOUNCE_INPUT_TIME),
    []
  );

  return (
    <>
      {!isLoading ? (
        service && (
          <div key={service.id} className={classes.serviceNoteContainer}>
            <div className={classes.nameSection}>
              <div className={classes.dot} style={{ backgroundColor: service?.color || 'black' }} />
              <span>{service?.name}</span>
            </div>
            <TextField
              label={getServiceLabel(
                service?.allowPartialSale || false,
                service?.unitLabel || 'Vial',
                service?.assetLabel || 'Units'
              )}
              disabled
              value={lineItem?.currentUseQuantity}
              variant="outlined"
              style={{ marginBottom: 12, width: 134 }}
              size="small"
            />
            <TextField
              label="Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              error={serviceNotes[service.id] === ''}
              defaultValue={serviceNotes[service.id]}
              onChange={(e) => {
                handleNoteChange(service.id, e.target.value);
              }}
              disabled={!serviceVisitOpened}
            />
          </div>
        )
      ) : (
        <div className={classes.serviceNoteContainer}>
          <div className={classes.nameSection}>
            <Skeleton variant="circle" className={classes.dot} />
            <Skeleton variant="text" width={100} />
          </div>
          <Skeleton variant="text" style={{ marginBottom: 12, width: 134 }} />
          <Skeleton variant="rect" height={90} />
        </div>
      )}
    </>
  );
};
