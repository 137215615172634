import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  titleSummary: {
    fontSize: '15px',
    color: '#000',
  },
  titleColumn: {
    fontSize: '18px',
    fontFamily: 'Messina Sans Light',
    color: '#3A3B3F',
  },
  titleZeroItem: {
    textAlign: 'center',
    fontSize: '20px',
  },
  column: {
    width: '48%',
  },
  columnLeft: {
    backgroundColor: '#E4E7EB',
    padding: '6px',
  },
  columnRight: {
    backgroundColor: '#F5F5F5',
    padding: '6px',
  },
  nameCustomer: {
    color: '#3A3B3F',
    fontSize: '15px',
    fontFamily: 'Messina Sans Regular',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
