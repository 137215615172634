import { createModel } from '@rematch/core';
import { batch } from 'react-redux';
import { AxiosResponse } from 'axios';
import Cohere from 'cohere-js';
import { queryClient } from 'src/initializers/queryClient';
import axiosInstance from 'src/utils/axios';
import compile from 'src/utils/toastMessagesCompiler';
import { dispatchNewAuth } from 'src/utils/auth.utils';
import API_ROUTES from 'src/constants/apiRoutes.constants';
import { PRACTITIONER_ROLE } from 'src/constants/general.constants';
import { FEATURE_MEDSPA_INTERFACE } from 'src/constants/features.constants';
import { IIdentity } from 'src/interfaces/IIdentity';
import { RootModel } from './rootModel';

export type MasqueradeCallback = (condition?: boolean) => void;

function cohereIntegration(response: AxiosResponse<any>, adminEmail: string) {
  let displayName = null;
  if (adminEmail) {
    displayName = `${response.data.identity.firstName} ${response.data.identity.lastName} by ${adminEmail}`;
  } else {
    displayName = `${response.data.identity.firstName} ${response.data.identity.lastName}`;
  }
  if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
    Cohere.identify(
      response.data.identity.email, // unique ID
      {
        displayName,
        email: response.data.identity.email,
      }
    );
  }
}

const getMasqueradeCallbackCondition = (identity: IIdentity) => {
  let condition;
  // Backward compatibility with old medspa admins so they can masquerade to the old practitioner
  // dashboard without landing into a weird UI not ready yet because the flag below isn't enabled
  if (identity.userType === PRACTITIONER_ROLE) {
    const { userGroupId, featureList } = identity;
    // hasMedspaInterfaceFeatureEnabled
    condition = !!userGroupId && featureList?.includes(FEATURE_MEDSPA_INTERFACE);
  }

  return condition;
};

export const masquerade = createModel<RootModel>()({
  state: {
    showMasqueradeBar: !!localStorage.getItem('masqueradeFrom'),
    practitioners: [],
  },
  reducers: {
    setPractitioners(state: any, payload: any) {
      return { ...state, practitioners: payload };
    },
    setShowMasqueradeBar(state: any, payload: any) {
      return { ...state, showMasqueradeBar: payload };
    },
    setReset(state: any) {
      return { ...state, practitioners: [], showMasqueradeBar: false };
    },
  },
  effects: (dispatch: any) => ({
    async getPractitioners() {
      try {
        const response = await axiosInstance.get('practitioners/active');
        dispatch.masquerade.setPractitioners(response.data.practitioners);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            type: 'error',
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'practitioners',
            }),
          },
        });
      }
    },
    async switchAccount({
      id,
      callback,
      isAdminMasquerade = false,
    }: {
      id: number | string;
      callback: MasqueradeCallback;
      isAdminMasquerade?: boolean;
    }) {
      try {
        const response = await axiosInstance.post(API_ROUTES.SWITCH_ACCOUNTS, { identityId: id });

        if (!response.data.token.accessToken) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              type: 'error',
              message: compile('generic.error_message', {
                action: 'using',
                element: 'switch account',
              }),
            },
          });
          return;
        }

        if (isAdminMasquerade) {
          localStorage.setItem('masqueradeIdentity', JSON.stringify(response.data.identity));
          const identity: any = localStorage!.getItem('identity.token.uid');
          localStorage.setItem('masqueradeFrom', identity);
          // Save current login
          localStorage.setItem(
            'masqueradeFromIdentity',
            JSON.stringify({
              accessToken: localStorage.getItem('identity.token.access-token'),
              client: localStorage.getItem('identity.token.client'),
              expiry: localStorage.getItem('identity.token.expiry'),
              tokenType: localStorage.getItem('identity.token.token-type'),
              uid: localStorage.getItem('identity.token.uid'),
              userType: localStorage.getItem('identity.userType'),
              userGroupId: localStorage.getItem('identity.token.userGroupId'),
              roleName: localStorage.getItem('identity.token.roleName'),
              linkedAccounts: localStorage.getItem('identity.token.linkedAccounts'),
            })
          );
        }

        dispatch.auth.updateState({ isLoading: true });
        localStorage.setItem('identity.token.access-token', response.data.token.accessToken);
        localStorage.setItem('identity.token.token-type', response.data.token.tokenType);
        localStorage.setItem('identity.token.client', response.data.token.client);
        localStorage.setItem('identity.token.uid', response.data.token.uid);
        localStorage.setItem('identity.token.expiry', response.data.token.expiry);
        localStorage.setItem('identity.token.userType', response.data.identity.userType);
        localStorage.setItem('identity.token.roleName', response.data.identity.roleName);
        localStorage.setItem('identity.token.userGroupId', response.data.identity.userGroupId);
        localStorage.setItem('identity.token.userGroupName', response.data.identity.userGroupName);
        localStorage.setItem('identity.token.linkedAccounts', JSON.stringify(response.data.identity.linkedAccounts));
        sessionStorage.setItem('identity.token.uid', response.data.token.uid);
        sessionStorage.setItem('identity.token.roleId', response.data.identity.roleId);

        dispatch.practitioner.setReset();

        dispatchNewAuth(dispatch, response.data);

        cohereIntegration(response, response.data.token.uid);
        setTimeout(() => {
          dispatch.auth.updateState({ isLoading: false });
        }, 1000);

        callback(getMasqueradeCallbackCondition(response.data.identity));
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            type: 'error',
            message: error,
          },
        });
      }
    },
    async masqueradeUser(payload: { id: number; callback?: MasqueradeCallback }) {
      try {
        const { id, callback } = payload;
        const response = await axiosInstance.post(API_ROUTES.MASQUERADE_PRACTITIONER(id));

        if (!response.data.token.accessToken) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              type: 'error',
              message: compile('generic.error_message', {
                action: 'using',
                element: 'masquerade',
              }),
            },
          });
          return;
        }
        dispatch.auth.updateState({ isLoading: true });
        dispatch.masquerade.setShowMasqueradeBar(true);

        // TODO: review next line is producing an issue
        // It should be:
        // localStorage.setItem(
        //   'masqueradeIdentity',
        //   JSON.stringify({ ...response.data.identity, id: response.data.identity.userId }
        // ));
        localStorage.setItem('masqueradeIdentity', JSON.stringify(response.data.identity));
        const identity: any = localStorage!.getItem('identity.token.uid');
        localStorage.setItem('masqueradeFrom', identity);
        // Save current login
        localStorage.setItem(
          'masqueradeFromIdentity',
          JSON.stringify({
            accessToken: localStorage.getItem('identity.token.access-token'),
            client: localStorage.getItem('identity.token.client'),
            expiry: localStorage.getItem('identity.token.expiry'),
            tokenType: localStorage.getItem('identity.token.token-type'),
            uid: localStorage.getItem('identity.token.uid'),
            userType: localStorage.getItem('identity.userType'),
            userGroupId: localStorage.getItem('identity.token.userGroupId'),
            roleName: localStorage.getItem('identity.token.roleName'),
            linkedAccounts: localStorage.getItem('identity.token.linkedAccounts'),
          })
        );

        localStorage.setItem('identity.token.access-token', response.data.token.accessToken);
        localStorage.setItem('identity.token.token-type', response.data.token.tokenType);
        localStorage.setItem('identity.token.client', response.data.token.client);
        localStorage.setItem('identity.token.uid', response.data.token.uid);
        localStorage.setItem('identity.token.expiry', response.data.token.expiry);
        localStorage.setItem('identity.token.userType', response.data.identity.userType);
        localStorage.setItem('identity.token.roleName', response.data.identity.roleName);
        localStorage.setItem('identity.token.userGroupId', response.data.identity.userGroupId);
        localStorage.setItem('identity.token.userGroupName', response.data.identity.userGroupName);
        localStorage.setItem('identity.token.linkedAccounts', JSON.stringify([]));
        sessionStorage.setItem('identity.token.uid', response.data.token.uid);
        sessionStorage.setItem('identity.token.roleId', response.data.identity.roleId);

        dispatch.practitioner.setReset();

        response.data.identity.linkedAccounts = [];
        dispatchNewAuth(dispatch, response.data);
        dispatch.auth.currentUser();

        cohereIntegration(response, identity);
        setTimeout(() => {
          dispatch.auth.updateState({ isLoading: false });
        }, 1000);

        callback?.(getMasqueradeCallbackCondition(response.data.identity));
      } catch (error) {
        dispatch.masquerade.setShowMasqueradeBar(false);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            type: 'error',
            message: error,
          },
        });
      }
    },

    async masqueradeBack(payload: { callback: Function }) {
      try {
        const { callback } = payload;
        const masqueradeFromIdentity = JSON.parse(localStorage.getItem('masqueradeFromIdentity') || '{}');
        dispatch.auth.updateState({ isLoading: true });
        localStorage.setItem('identity.token.access-token', masqueradeFromIdentity.accessToken);
        localStorage.setItem('identity.token.token-type', masqueradeFromIdentity.tokenType);
        localStorage.setItem('identity.token.client', masqueradeFromIdentity.client);
        localStorage.setItem('identity.token.uid', masqueradeFromIdentity.uid);
        localStorage.setItem('identity.token.expiry', masqueradeFromIdentity.expiry);
        localStorage.setItem('identity.token.userType', masqueradeFromIdentity.userType);
        localStorage.setItem('identity.token.userGroupId', masqueradeFromIdentity.userGroupId);
        localStorage.setItem('identity.token.userGroupName', masqueradeFromIdentity.userGroupName);
        localStorage.setItem('identity.token.roleName', masqueradeFromIdentity.roleName);
        localStorage.setItem(
          'identity.token.linkedAccounts',
          !!masqueradeFromIdentity.linkedAccounts &&
            masqueradeFromIdentity.linkedAccounts !== 'undefined' &&
            masqueradeFromIdentity.linkedAccounts !== '[object Object]'
            ? JSON.parse(masqueradeFromIdentity.linkedAccounts || '[]')
            : []
        );

        sessionStorage.setItem('identity.token.roleId', masqueradeFromIdentity.roleId);

        sessionStorage.removeItem('identity.token.uid');
        localStorage.removeItem('masqueradeFrom');
        localStorage.removeItem('masqueradeFromIdentity');
        localStorage.removeItem('masqueradeIdentity');

        const response = await axiosInstance.get(API_ROUTES.ME);

        batch(() => {
          dispatchNewAuth(dispatch, response.data);
          setTimeout(() => {
            dispatch.auth.updateState({ isLoading: false });
            dispatch.masquerade.setShowMasqueradeBar(false);
          }, 1000);
          cohereIntegration(response, '');
          callback(masqueradeFromIdentity);
        });
        queryClient.clear();
      } catch (error) {
        dispatch.masquerade.setShowMasqueradeBar(false);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            type: 'error',
            message: error,
          },
        });
      }
    },
  }),
});
