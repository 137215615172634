import IGlobalNotification from '../interfaces/IGlobalNotification';
import Id from '../types/Id';
import Api from './Api';

const BASE_PATH = '/v3/notifications';

export const getNotifications = async (): Promise<IGlobalNotification[]> => {
  const { notifications, error } = await Api.get(`${BASE_PATH}`);
  if (error) {
    throw error;
  }

  return notifications;
};

export const markAsRead = async (id: Id) => {
  const { notification, error } = await Api.post(`${BASE_PATH}/${id}/mark_as_read`, {});
  if (error) {
    throw error;
  }
  return notification;
};
