import { sortBy } from 'lodash';
import { AT_COST_SUPPLIERS, EMPTY_CELL, PINNED_PRODUCTS_AND_SUPPLIERS } from 'src/constants/inventory.constants';
import { InventoryOrderItem, ProductOrderDetails } from 'src/interfaces/IInventoryOrder';
import { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import { IOrderProduct } from 'src/services/Products';
import { ClassNameMap } from 'src/types/Dom';
import { formatCurrency } from '../formatNumber';
import { CellFactory, SupplierItemProps, SupplierRowCell } from '.';

const makeProductRowCells = ({
  classes,
  isCustomItem,
  name,
  description,
  listPrice,
  price,
  savingsContent,
}: CellFactory): SupplierRowCell[] => [
  {
    className: classes?.nameCol,
    testId: 'name',
    content: name,
  },
  {
    className: classes?.description,
    testId: 'description',
    content: description,
  },
  {
    className: classes?.centerCell,
    testId: `marketPrice`,
    content: isCustomItem ? EMPTY_CELL : formatCurrency(listPrice),
  },
  ...(savingsContent ? [savingsContent] : []),
  {
    className: classes?.centerCell,
    testId: `price`,
    content: isCustomItem && !price ? EMPTY_CELL : formatCurrency(price),
  },
];

export const sortProductsBySupplier = ({
  products,
  supplierName,
  orderItems,
  expanded = false,
}: {
  products: IOrderProduct[];
  supplierName: string;
  orderItems: ProductOrderDetails;
  expanded?: boolean;
}): {
  noSavings: boolean;
  hasMore: boolean;
  products: IOrderProduct[];
} => {
  const sortOrder = PINNED_PRODUCTS_AND_SUPPLIERS[supplierName] ?? [];
  const noSavings = AT_COST_SUPPLIERS.indexOf(supplierName) > -1;

  const sortedProducts = sortBy(products, (item) => {
    const itemIndex = sortOrder.indexOf(item.name);
    return itemIndex < 0 ? item.name.toLowerCase().charCodeAt(0) + (sortOrder.length + 1) * 2 : itemIndex;
  });

  if (expanded || !sortOrder.length) {
    return { noSavings, hasMore: false, products: sortedProducts };
  }

  const filteredProducts = sortedProducts.filter(
    (item) => !!orderItems?.[item.id]?.quantity || sortOrder.indexOf(item.name) > -1
  );

  return {
    noSavings,
    hasMore: !!filteredProducts.length && filteredProducts.length < sortedProducts.length,
    products: filteredProducts.length ? filteredProducts : sortedProducts,
  };
};

export const getProductOrItemDetails = ({
  classes,
  product: {
    id: orderProductId = 0,
    name = '',
    description = '',
    listPrice = 0,
    rolePrice = 0,
  } = {} as Partial<IOrderProduct>,
  item: { quantity: productQuantity = 0, notes: productNotes = '' } = {},
  customItem: {
    id: customItemId = 0,
    name: customName = '',
    quantity: customQuantity = 0,
    price: customPrice = 0,
    notes: customNotes = '',
  } = {} as Partial<InventoryOrderCustomItem>,
}: ClassNameMap & {
  item?: Partial<InventoryOrderItem>;
  product?: Partial<IOrderProduct>;
  customItem?: Partial<InventoryOrderCustomItem>;
}): SupplierItemProps => {
  const isCustomItem = !orderProductId && !!customItemId;

  const itemDetails = {
    isCustomItem,
    ...(isCustomItem
      ? {
        id: customItemId,
        name: customName,
        testId: 'customItem',
        quantity: customQuantity,
        notes: customNotes,
        price: customPrice,
        description: EMPTY_CELL,
        listPrice: 0,
      }
      : {
        id: orderProductId,
        name,
        testId: 'orderProduct',
        quantity: productQuantity,
        notes: productNotes,
        price: rolePrice,
        description,
        listPrice,
      }),
  };

  return {
    ...itemDetails,
    makeCells: (savingsContent?: SupplierRowCell) =>
      makeProductRowCells({ ...(itemDetails as CellFactory), savingsContent, classes }),
  };
};
