import React, { useState } from 'react';

import { Button, Collapse, IconButton, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import IconCross from '../../common/IconCross';
import { useRowStyles } from './adminAssets.styles';
import { dispatch } from '../../../rematch';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { ButtonStatus } from '../../common/TableStyles/ButtonStatus';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import compile from '../../../utils/toastMessagesCompiler';

const handleStatus = (status: string, action?: any) => {
  switch (status) {
    case 'in_stock':
      return (
        <ButtonStatus onClick={() => action()} style={{ backgroundColor: '#d1f1bd' }}>
          In stock
        </ButtonStatus>
      );
    case 'waste':
      return (
        <ButtonStatus onClick={() => action()} style={{ backgroundColor: '#fac980', color: '#393b3f' }}>
          Waste
        </ButtonStatus>
      );
    case 'sold':
      return (
        <ButtonStatus onClick={() => action()} style={{ background: '#e7e7e7' }}>
          Sold
        </ButtonStatus>
      );
    default:
      break;
  }
  return null;
};

export interface AdminAssetsRowProps {
  asset: IAsset;
}

const AdminAssetsRow: React.FC<AdminAssetsRowProps> = ({ asset }: { asset: IAsset }) => {
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);

  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.copy_to_clipboard'),
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'copying',
            element: 'clipboard',
          }),
          type: 'error',
        },
      });
    }
  };

  return (
    <>
      <TableRow className={classes.root} style={{ backgroundColor: open ? '#f2f5f5' : 'white' }}>
        <TableCell width="50">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <IconCross open={open} />
          </IconButton>
        </TableCell>
        <TableCellCustom>{asset.name}</TableCellCustom>
        <TableCellCustom width="118px">{handleStatus(asset.status, () => {})}</TableCellCustom>
        <TableCellCustom>{asset.initialNumberOfUnits}</TableCellCustom>
        <TableCellCustom>{asset.currentNumberOfUnits}</TableCellCustom>
        <TableCellCustom>{asset.warehouse?.providerLabeling}</TableCellCustom>
        <TableCellCustom>
          <Button className={classes.serialNumber} onClick={() => copyToClipboard(asset.internalSerialNumber)}>
            {asset.internalSerialNumber}
          </Button>
        </TableCellCustom>
        <TableCellCustom>
          <Button className={classes.serialNumber} onClick={() => copyToClipboard(asset.lot)}>
            {asset.lot}
          </Button>
        </TableCellCustom>
        <TableCellCustom>{moment(asset.expireAt).format('MM/DD/YYYY')}</TableCellCustom>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: '10%' }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit />
        </TableCell>
      </TableRow>
    </>
  );
};

export default AdminAssetsRow;
