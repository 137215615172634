// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    paddingTop: '5px',
    color: '#000000'
  },
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.875rem'
  },
  subField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.875rem',
    marginLeft: '10px'
  },
  collapseRow: {
    width: '99.5%'
  },
  textField: {
    paddingTop: '0.5rem',
    width: '99.5%',
  }
}));
