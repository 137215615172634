import Api from './Api';
import API_ROUTES from '../constants/apiRoutes.constants';

const MembershipConsents = {
  async getMembershipConsent({ planId }: { planId: number }) {
    const { membershipConsent, error } = await Api.get(`${API_ROUTES.MEMBERSHIP_CONSENTS}/${planId}`);
    if (error) {
      throw new Error(error);
    }
    return membershipConsent;
  },
};

export default MembershipConsents;
