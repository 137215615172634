import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  listNotes: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.13), 0 1px 8px 0 rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    margin: '10px 15px 10px 15px',
    overflow: 'auto',
    padding: '0',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.13), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
    alignItems: 'flex-start',
    textAlign: 'left',
    justifyContent: 'space-between',
    padding: '10px',
  },
  listPhotos: {
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0',
  },
  listItemNote: {
    display: 'block',
    whiteSpace: 'pre-line',
    overflowWrap: 'anywhere',
  },
  ListContainer: {
    display: 'flex',
  },
  photoItem: {
    width: '80px',
    height: '80px',
    marginRight: '10px',
    cursor: 'pointer',
    objectFit: 'cover',
  },
  textArea: {
    fontFamily: 'Messina Sans Regular',
    outline: 'none',
    border: 'none',
    resize: 'none',
    height: '54px',
    padding: '0 15px',
  },
}));
