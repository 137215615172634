import { makeStyles } from '@material-ui/core';
import {
  PENDING_TAG_BACKGROUND_COLOR,
  PENDING_TAG_FONT_COLOR,
} from 'src/pages/Onboarding/AccountSettings/AccountSettings.styles';

export const greenColor = '#10974E';
export const greenBackground = '#E6F2E9';
export const blackColor = '#000000';
export const grayColor = '#EDEDED';
export const otherGrayColor = '#DFDFDF';
export const disabledColor = 'rgba(0, 0, 0, 0.4)';
const progressBarGray = '#F1F1F1';
const progressBarGreen = '#55B76F';

export const useStyles = makeStyles(() => ({
  loaderContainer: {
    textAlign: 'center',
    width: '100%',
    padding: '100px 0',
  },
  title: {
    fontSize: '30px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: blackColor,
  },
  subTitle: {
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: '24px',
  },
  stepsContainer: {
    position: 'relative',

    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.725rem',
      padding: 0,
    },
  },
  verticalLine: {
    position: 'absolute',
    left: '2rem',
    top: '12px',
    bottom: '12px',
    width: '1px',
    backgroundColor: otherGrayColor,
  },
}));

export const useStepsListStyles = makeStyles(() => ({
  root: {
    '& .MuiLinearProgress-root': {
      borderRadius: '30px',
      height: '8px',
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: progressBarGray,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: progressBarGreen,
    },
  },
  listItem: {
    padding: '2px 0',
  },
  listItemIcon: {
    marginRight: '0',
    minWidth: '35px',
    width: '100%',
  },
  listItemText: {
    fontSize: '13px',
    color: disabledColor,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: '1px solid #000000',
    borderRadius: '10px',
    padding: '1rem 1.25rem',
    backgroundColor: '#ffffff',
    textDecoration: 'none',

    '& p': {
      margin: 0,
    },
  },
  disabledContainer: {
    border: `1px solid ${otherGrayColor}`,
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.25rem',
    width: '25%',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3rem',
  },
  progressBarContainer: {
    width: '50%',

    // display none on mobile screen
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  completed: {
    border: 'none',
    backgroundColor: greenBackground,
  },
  textSection: {
    fontWeight: 'normal',
    fontSize: '15px',
    color: blackColor,
  },
  disabled: {
    color: disabledColor,
  },
  tagAndChevronContainer: {
    width: '12%',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    '@media(max-width: 780px)': {
      width: '18%',
    },
  },
  tagContainer: {
    width: '60%',
    textAlign: 'center',
    '@media(max-width: 1110px)': {
      width: '80%',
    },
  },
  tag: {
    padding: '0.125rem',
    borderRadius: '4px',
    backgroundColor: '#FFDBDB',
    color: '#C65858',
  },
  chevronContainer: {
    width: '20%',
    textAlign: 'right',
    height: '25px',
  },
}));

export const usePendingStyles = makeStyles((theme) => ({
  pendingChip: {
    backgroundColor: PENDING_TAG_BACKGROUND_COLOR,
    color: PENDING_TAG_FONT_COLOR,
    fontSize: '0.75rem',
  },
  pendingTag: {
    backgroundColor: PENDING_TAG_BACKGROUND_COLOR,
    color: PENDING_TAG_FONT_COLOR,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',

    '@media (max-width: 1200px)': {
      justifyContent: 'flex-start',
    },
  },
  pendingTagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    marginTop: '0.5rem',

    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
}));
