import axiosInstance from '../utils/axios';
import { ICheckout } from '../interfaces/checkout.interfaces';
import ROUTES from '../constants/apiRoutes.constants';
import Api from './Api';
import { ERROR_OCCURRED } from '../constants/general.constants';
import { UpdateCheckoutData } from '../hooks/serviceVisit/useCheckout';

const Checkout = {
  async getCheckout(serviceVisitId: number): Promise<ICheckout> {
    const { data } = await axiosInstance.get(`service_visits/${serviceVisitId}/fetch_checkout`);
    return data.checkout;
  },
  async getServiceVisitCheckout(id: number): Promise<ICheckout> {
    const { success, checkout } = await Api.get(ROUTES.SERVICE_VISIT_CHECKOUT(id));
    if (!success) {
      throw new Error(ERROR_OCCURRED);
    }
    return checkout;
  },
  async updateServiceVisitCheckout(id: number, data: UpdateCheckoutData): Promise<ICheckout> {
    const { success, checkout, error } = await Api.put(ROUTES.SERVICE_VISIT_CHECKOUT(id), data);
    if (!success) {
      throw new Error(error);
    }
    return checkout;
  },
  async applyDiscount(id: number, data: any): Promise<boolean> {
    const { success } = await Api.post(ROUTES.SERVICE_VISIT_DISCOUNTS(id), data);
    if (!success) {
      throw new Error(ERROR_OCCURRED);
    }
    return success;
  },
  async removeDiscount(id: number, checkoutLineItemId: number, discountId: number): Promise<boolean> {
    const { success } = await Api.delete(
      `${ROUTES.SERVICE_VISIT_DISCOUNTS(id)}?checkout_line_item_id=${checkoutLineItemId}&discount_id=${discountId}`
    );
    if (!success) {
      throw new Error(ERROR_OCCURRED);
    }
    return success;
  },
};

export default Checkout;
