import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    margin: '15px',
    paddingBottom: '24px !important',
    padding: '24px 30px',
    background: '#F5F5F5',
    marginTop: '-68px',
    marginLeft: '10px',
    border: '5px solid transparent',
    borderRadius: '15px',
    marginBottom: '5px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  checkoutButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '12px',
    padding: '1rem 2rem',
    borderRadius: '8px',
    margin: '1rem 0',
    width: '400px',
    textAlign: 'center',
    textTransform: 'none',
    '&:disabled': {
      color: 'white',
    },
    '&:hover': {
      background: '#12574D',
    },
  },
  productListContainer: {
    marginTop: '10px',
  },
  productScanned: {
    height: '47px',
    margin: '10px 0 10px',
    padding: '9px 33px 11px 22px',
    borderRadius: '3px',
    backgroundColor: '#ddf5d6',
    borderBottom: '8px solid #ffffff',
    transition: 'background-color 2s ease-out',
  },
  productScannedTransition: {
    height: '47px',
    margin: '10px 0 10px',
    padding: '9px 33px 11px 22px',
    borderRadius: '3px',
    backgroundColor: '#A8F392',
    borderBottom: '8px solid #ffffff',
  },
  processing: {
    color: 'white',
    marginLeft: theme.spacing(1),
    position: 'relative',
  },
  undoIcon: {
    fontSize: '25px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
