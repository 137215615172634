import { createModel } from '@rematch/core';
import { orderBy, uniq } from 'lodash';
import { SupplyReceiptsState } from '../types/SupplyReceiptsState';
import { TypeSort } from '../utils/types';
import { RootModel } from './rootModel';
import axiosInstance from '../utils/axios';
import { getTotal } from '../utils/inventory.utils';
import { toSnakeCase } from '../utils/global';
import compile from '../utils/toastMessagesCompiler';

export const supplyReceipts = createModel<RootModel>()({
  state: {
    orderByDate: 'asc',
    supplyReceipts: [],
    filteredSupplyReceipts: [],
    isLoading: false,
  } as SupplyReceiptsState,
  reducers: {
    setSupplyReceipts(state: any, payload: any) {
      payload.forEach((receipt: any) => {
        const { products } = receipt;
        const serviceIds: number[] = uniq(products.map(({ serviceId }: any) => serviceId));
        // eslint-disable-next-line no-param-reassign
        receipt.productsList = serviceIds.map((id: number) => {
          const filteredProducts = products.filter(({ serviceId }: any) => serviceId === id);

          return {
            serviceId: id,
            productName: filteredProducts[0].productName,
            assetsCount: filteredProducts.length,
            units: getTotal(filteredProducts, 'initialNumberOfUnits'),
            allowPartialSale: filteredProducts[0].allowPartialSale,
          };
        });
      });

      return { ...state, supplyReceipts: payload };
    },
    sortByDate(state: any, payload: TypeSort) {
      const orderedSupplyReceipts = orderBy(state.supplyReceipts, [(object: any) => new Date(object.date)], [payload]);
      return { ...state, supplyReceipts: orderedSupplyReceipts };
    },
    setSort(state: any, payload: TypeSort) {
      return { ...state, orderByDate: payload };
    },
    setFilterSupplyReceipts(state: any, payload: { value: string }) {
      const { value } = payload;
      const filteredSupplyReceipts = state.supplyReceipts.filter(
        (item: any) => item.nurseName.trim().toLowerCase().search(value) > -1 || value.trim() === ''
      );
      return { ...state, filteredSupplyReceipts };
    },
    setLoading(state: any, payload: boolean) {
      return { ...state, isLoading: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getSupplyReceipts(payload: { practitionerId?: number; status?: string } = {}, _rootState: any) {
      try {
        dispatch.supplyReceipts.setLoading(true);
        const {
          data: { supplyReceipts: fetchedSupplyReceipts },
        } = await axiosInstance.get('supply-receipts', { params: toSnakeCase(payload) });
        dispatch.supplyReceipts.setSupplyReceipts(fetchedSupplyReceipts);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'the supply receipts',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.supplyReceipts.setLoading(false);
      }
    },
  }),
});
