import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import classnames from 'classnames';

const SupplierTitleStyles = makeStyles({
  gridItem: {
    paddingRight: '48px'
  },
  box: {
    marginLeft: '9px', 
    paddingLeft: '28px'
  },
  title: {
    color: '#393b3f',
    fontSize: '15px',
    fontFamily: 'Messina Sans SemiBold',
    textAlign: 'left',
  }
});

const SupplierTitle: React.FC<{ title: string }> = ({ title }: any) => {
  const classes = SupplierTitleStyles();
  return (
    <Grid xs={3} item className={classes.gridItem}>
      <Box className={classnames(classes.title, classes.box)}>
        {title}
      </Box>
    </Grid>
  )
}

export default SupplierTitle;
