export type CSVCell = string | number | boolean | undefined | null;

export interface CSVRow {
  [k: string]: CSVCell;
}

export const makeCSV = (tableData: CSVRow[], colOffset?: number): string =>
  tableData
    ?.map((r: CSVRow): string =>
      Object.values(r)
        .slice(colOffset || 0)
        .map((m: CSVCell): string => (typeof m === 'number' || typeof m === 'boolean' ? m.toString() : m || ''))
        .join(',')
    )
    .join('\n') || '';
