import React, { useState, createRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Card } from '../common/card/Card';
import { Button } from '../common/Button';
import { SmallTitle } from '../common/card';
import { useAddServiceVisitNoteMutation } from '../../hooks/serviceVisit/useServiceVisit';

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.13), 0 1px 8px 0 rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    margin: '0 15px 10px 15px',
  },
  textArea: {
    fontFamily: 'Messina Sans Regular',
    outline: 'none',
    border: 'none',
    resize: 'none',
    width: '100%',
    height: '54px',
    padding: '0 15px',
  },
  buttonsSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  saveButton: {
    color: '#000000',
    backgroundColor: '#e7eeed',
    margin: '0 3px',
    paddingRight: '9px',
    paddingLeft: '9px',
  },
}));

// eslint-disable-next-line import/prefer-default-export
export const PatientHeaderNote = ({ serviceVisit, cancelCallback }: any) => {
  const { serviceVisitId }: { serviceVisitId: string } = useParams();
  const { internalNote, customerNote } = serviceVisit;
  const addNoteMutation = useAddServiceVisitNoteMutation(+serviceVisitId);

  const classes = useStyles();
  const ref: any = createRef();
  const [text, setText] = useState('');

  useEffect(() => {
    ref.current.focus();
  }, []);

  useEffect(() => {
    setText(internalNote || '');
  }, [internalNote]);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.currentTarget.value);
  };

  const cancelNote = () => {
    cancelCallback();
  };

  const saveNote = async () => {
    await addNoteMutation.mutateAsync({
      'customer-note': customerNote,
      'internal-note': text,
    });
    setText('');
    cancelCallback();
  };

  const isButtonDisabled = (): boolean => (text ? !text.length : true);

  return (
    <Card className={classes.card}>
      <SmallTitle title="Add service note" style={{ margin: '15px', marginBottom: '5px' }} />
      <textarea ref={ref} className={classes.textArea} value={text} onChange={onChange} name="addNote" />
      <div className={classes.buttonsSection}>
        <Button title="Cancel" onClick={cancelNote} />
        <Button
          title="Save Note"
          onClick={saveNote}
          className={classes.saveButton}
          style={{ marginLeft: '30px' }}
          disabled={isButtonDisabled()}
        />
      </div>
    </Card>
  );
};
