import React, { ReactElement } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { groupBy } from 'lodash';
import { hasAccessTo } from 'src/utils/auth.utils';
import { DESTROY_INVENTORY_ORDER } from 'src/constants/actions.constants';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { ORDER_STATUS_REQUESTED } from 'src/constants/inventory.constants';
import { ChargeContainer, ChargeList } from './ChargeList';
import { useStyle } from '../inventoryOrder.styles';

export type Charges = {
  id: number;
  description: string;
  amount: number;
  inventoryOrderId: number;
};

export type Props = {
  charges: Charges[];
  status?: string;
  transactionFeePct?: number;
  readOnly?: boolean;
  subtotal?: number;
  colFill?: number;
  priceLabel?: string;
  tableInsert?: boolean;
};

export type RowProps = {
  charge: Omit<Charges, 'id' | 'inventoryOrderId'> & { id?: number; inventoryOrderId?: number };
  isLoading?: boolean;
  children?: ReactElement | null;
  colFill?: number;
};

export const OrderCharges: React.FC<Props> = ({
  charges,
  status,
  priceLabel,
  transactionFeePct = 0,
  subtotal = 0,
  colFill = 0,
  readOnly = false,
  tableInsert = false,
}) => {
  const classes = useStyle();

  const { permissions } = useSelector(({ auth }: RootState) => auth);
  const editable = !readOnly && status === ORDER_STATUS_REQUESTED && hasAccessTo(DESTROY_INVENTORY_ORDER, permissions);

  const { additionalCharges = [], discounts = [] } = groupBy(charges, (charge) =>
    charge.amount > 0 ? 'additionalCharges' : 'discounts'
  );

  const additionalChargesList =
    !!additionalCharges.length || (subtotal > 0 && transactionFeePct > 0) ? (
      <ChargeList
        charges={additionalCharges}
        status={status}
        subtotal={subtotal}
        transactionFeePct={transactionFeePct}
        colFill={colFill}
        readOnly={!editable}
      />
    ) : null;

  const discountsList = discounts.length ? (
    <ChargeList charges={discounts} status={status} colFill={colFill} readOnly={!editable} />
  ) : null;

  const additionalChargesComponent =
    !tableInsert && !additionalChargesList ? null : (
      <ChargeContainer
        status={status}
        label="Additional Charges"
        priceLabel={priceLabel}
        colFill={colFill}
        readOnly={!editable}
      >
        {additionalChargesList}
      </ChargeContainer>
    );

  const discountsComponent =
    !tableInsert && !discountsList ? null : (
      <ChargeContainer status={status} label="Discounts" priceLabel={priceLabel} colFill={colFill} readOnly={!editable}>
        {discountsList}
      </ChargeContainer>
    );

  return tableInsert ? (
    <>
      {!!discountsList && (
        <TableRow className={classes.subheadingRow}>
          <TableCell>Discounts</TableCell>
          <TableCell colSpan={colFill ? colFill + 1 : 1} />
        </TableRow>
      )}
      {discountsList}
      {!!additionalChargesList && (
        <TableRow className={classes.subheadingRow}>
          <TableCell>Additional Charges</TableCell>
          <TableCell colSpan={colFill ? colFill + 1 : 1} />
        </TableRow>
      )}
      {additionalChargesList}
    </>
  ) : (
    <>
      {discountsComponent}
      {additionalChargesComponent}
    </>
  );
};

export default OrderCharges;
