import { Box, Grid, MenuItem, colors as materialColors, CircularProgress, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Resource, ResourceInstance } from '@devexpress/dx-react-scheduler';
import { cloneDeep, sortBy } from 'lodash';
import moment from 'moment';
import { useGetMedspaAppointments, useRescheduleMedspaAppointment } from 'src/hooks/medspaCalendar/useAppointments';
import { RootState } from 'src/rematch';
import { MedspaCalendarGFEStatusEnum } from 'src/constants/general.constants';
import { IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import Page from '../../common/Page';
import { useMedspaLocations } from '../../../hooks/queries/medspaAdmins/useMedspaLocations';
import { IMedspaLocation } from '../../../interfaces/ILocation';
import { EHRSelect } from '../../ui/v1';
import { useMedspaPractitioners } from '../../../hooks/queries/usePractitioners';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { IPractitioner } from '../../../interfaces/IPractitioner';
import { IAppointmentType } from '../../../interfaces/acuity/acuity.interface';
import MedspaCalendar from './MedspaCalendar';
import { useMedspaAppointmentTypes } from '../../../hooks/queries/medspaAdmins/useMedspaAppointmentTypes';
import { ExtendedAppointmentModel } from './MedspaCalendar/grouping';
import { BookingTypeEnum } from './MedspaCalendar/EnumBookingType';
import { useStyles } from './index.styles';
import {
  MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG,
  MEDSPA_ADMIN_LOCATIONS,
  MEDSPA_ADMIN_PRACTITIONERS,
} from '../../../routes/medspaAdminRoutes';
import EmptyPage from '../../common/EmptyPage';
import { getGFEStatusIconComponent } from './MedspaCalendar/gfeStatusUtils';
import { AppointmentStatusEnum } from './MedspaCalendar/AppointmentStatusEnum';

const colors = [
  materialColors.amber,
  materialColors.blue,
  materialColors.blueGrey,
  materialColors.brown,
  materialColors.cyan,
  materialColors.deepOrange,
  materialColors.deepPurple,
  materialColors.green,
  materialColors.indigo,
  materialColors.lightBlue,
  materialColors.lightGreen,
  materialColors.lime,
  materialColors.orange,
  materialColors.pink,
  materialColors.purple,
  materialColors.red,
  materialColors.teal,
  materialColors.yellow,
];
export const VIEW_NAME_WEEK = 'Week';
export const VIEW_NAME_DAY = 'Day';
export const VIEW_NAME_WEEK_PROVIDER = 'WeekProvider';

export const MedspaAdminCalendarV2 = () => {
  const history = useHistory();
  const classes = useStyles();
  const { userGroupId } = useSelector(({ auth }: RootState) => auth);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [selectedMedspaLocation, setSelectedMedspaLocation] = useState<IMedspaLocation>();
  const [selectedPractitionerId, setSelectedPractitionerId] = useState<string | number>('');
  const [selectedAppTypeId, setSelectedAppTypeId] = useState<string | number>('');
  const [selectedGFEStatus, setSelectedGFEStatus] = useState<string>('');
  const [dummyData, setDummyData] = useState<any>([]);
  const [workingHoursMap, setWorkingHoursMap] = useState<any>([]);
  const [currentViewName, setCurrentViewName] = useState<string>(VIEW_NAME_DAY);

  const {
    data: { medspaLocations = [] },
    isLoading: isLoadingMedspaLocations,
  } = useMedspaLocations();
  const { data: locationAppTypes = [], isLoading: isLoadingTypes } = useMedspaAppointmentTypes(userGroupId, {
    medspaLocationId: selectedMedspaLocation?.id,
  });
  const { data: practitioners = [], isLoading: isLoadingPractitioners } = useMedspaPractitioners(
    {},
    !!userGroupId,
    () => `/v4/medspa/${userGroupId}/practitioners`
  );

  const enabledAppTypes = locationAppTypes.filter((appType: IAppointmentType) => !appType.hidden);

  const {
    data: medspaAppointments,
    isLoading: isLoadingMedspaAppointments,
    isFetching: isFetchingMedspaAppointments,
  } = useGetMedspaAppointments(
    selectedMedspaLocation?.id,
    moment(currentDate).format('YYYY-MM-DD'),
    currentViewName === VIEW_NAME_WEEK_PROVIDER ? VIEW_NAME_DAY : currentViewName.toLocaleLowerCase()
  );

  const isLoading = isLoadingPractitioners || isLoadingMedspaLocations || isLoadingTypes || isLoadingMedspaAppointments;

  const onChangePractitionerSelected = (id: number | string) => {
    if (currentViewName === VIEW_NAME_WEEK && !!id) {
      setCurrentViewName(VIEW_NAME_WEEK_PROVIDER);
    } else if (currentViewName === VIEW_NAME_WEEK_PROVIDER && !id) {
      setCurrentViewName(VIEW_NAME_WEEK);
    }
    setSelectedPractitionerId(id);
  };

  const onChangeCurrentViewName = (name: string) => {
    if (name === VIEW_NAME_WEEK && !!selectedPractitionerId) {
      setCurrentViewName(VIEW_NAME_WEEK_PROVIDER);
    } else {
      setCurrentViewName(name);
    }
  };

  useEffect(() => {
    if (!selectedMedspaLocation && medspaLocations.length > 0 && !isLoadingMedspaLocations) {
      setSelectedMedspaLocation(sortBy(medspaLocations, (medspaLocation: IMedspaLocation) => medspaLocation.name)[0]);
    }
  }, [medspaLocations, selectedMedspaLocation, isLoadingMedspaLocations]);

  const availableMedspaLocationPractitioners = useMemo(() => {
    if (selectedMedspaLocation) {
      return practitioners.filter((practitioner: IPractitioner) =>
        selectedMedspaLocation.practitioners
          .filter((provider) => provider.activeForScheduling)
          .map((provider) => provider.id)
          .includes(practitioner.id)
      );
    }
    return [];
  }, [practitioners, selectedMedspaLocation]);

  const providersResourceInstances: ResourceInstance[] = availableMedspaLocationPractitioners.map(
    (provider, index) => ({
      id: provider.id,
      text: `${provider.firstName} ${provider.lastName}`,
      color: colors[index],
    })
  );

  const providersMap = availableMedspaLocationPractitioners.reduce<{ [key: number]: IPractitioner }>(
    (acc, provider) => {
      acc[provider.id] = provider;
      return acc;
    },
    {}
  );

  const providerResources: Resource[] = [
    {
      fieldName: 'practitionerId',
      title: 'Provider',
      instances:
        typeof selectedPractitionerId === 'number'
          ? [
            providersResourceInstances[
              providersResourceInstances.findIndex((instance) => instance.id === selectedPractitionerId)
            ],
          ]
          : providersResourceInstances,
    },
  ].filter((resource) => !!resource.instances?.[0]);
  const providerIsNotInLocation = providerResources.length === 0 && selectedPractitionerId;

  const [appointments, setAppointments] = useState<ExtendedAppointmentModel[]>([]);
  const [changedAppointment, setChangedAppointment] = useState<ExtendedAppointmentModel | null>(null);
  const { mutate: mutateRescheduleMedspaAppointment } = useRescheduleMedspaAppointment(
    changedAppointment?.id,
    () => {}
  );

  const handleAppointmentsChange = useCallback(
    (newAppointments: ExtendedAppointmentModel[]) => {
      setAppointments(newAppointments);

      const newAppointment = newAppointments.find(
        (appointment, index) =>
          appointment.startDate !== dummyData[index]?.startDate || appointment.endDate !== dummyData[index]?.endDate
      );

      setChangedAppointment(newAppointment as ExtendedAppointmentModel | null);

      if (changedAppointment) {
        const params = {
          id: changedAppointment.id,
          appointment: {
            practitionerId: changedAppointment.practitionerId,
            medspaLocationId: selectedMedspaLocation?.id,
            datetime: moment(changedAppointment.startDate).format('YYYY-MM-DDTHH:mm'),
            notes: changedAppointment.notes,
            appointmentTypeId: changedAppointment.appointmentTypeId,
            addOnDuration: changedAppointment.addOnDuration,
            addOnIds: changedAppointment.addOnIds,
            serviceGroups: changedAppointment.serviceGroups || [],
            services: changedAppointment.services || [],
          },
        };

        mutateRescheduleMedspaAppointment(params, {
          onSuccess: () => {
            setDummyData(newAppointments);
          },
          onError: (error: any) => {
            console.error('Failed to reschedule appointment:', error);
          },
        });
      }
    },
    [dummyData, selectedMedspaLocation, mutateRescheduleMedspaAppointment, setDummyData]
  );

  useEffect(() => {
    if (!isFetchingMedspaAppointments) {
      const appointments2: ExtendedAppointmentModel[] = (medspaAppointments?.calendars || []).flatMap((data) => {
        const results: ExtendedAppointmentModel[] = data.appointments.map((appointment) => {
          const bookingType = BookingTypeEnum.APPOINTMENT;
          const { practitionerId } = data;
          return {
            title: 'Appointment',
            practitionerId,
            startDate: appointment.startTime,
            endDate: appointment.endTime,
            addOnDuration: appointment.addOnDuration,
            allDay: appointment.allDay || false,
            id: appointment.id,
            bookingType,
            status: appointment.status as AppointmentStatusEnum,
            notes: appointment.notes,
            gfeStatus: appointment.gfeStatus as MedspaCalendarGFEStatusEnum,
            appointmentTypeId: appointment.appointmentTypeId,
            appointmentTypeName: appointment.appointmentTypeName,
            patientName: appointment.patientName,
            patientEmail: appointment.patientEmail,
            addOns: appointment.addOns,
            serviceGroups: appointment.serviceGroups,
            services: appointment.services,
          };
        });
        return results;
      });

      const blockOffs: ExtendedAppointmentModel[] = (medspaAppointments?.calendars || []).flatMap((data) => {
        const results: ExtendedAppointmentModel[] = data.blockOffs.map((blockOff) => {
          const bookingType = BookingTypeEnum.BLOCK_OFF_HOUR;
          const { practitionerId } = data;
          return {
            title: 'Block Off',
            practitionerId,
            startDate: blockOff.start,
            endDate: blockOff.end,
            allDay: false,
            id: blockOff.id,
            bookingType,
            notes: blockOff.notes,
            gfeStatus: null,
            appointmentTypeId: 0,
            appointmentTypeName: null,
            patientName: null,
            patientEmail: null,
          };
        });
        return results;
      });

      const newWorkingHoursMap = (medspaAppointments?.calendars || []).reduce<{ [key: number]: IWorkingHour[] }>(
        (acc, data) => {
          const { practitionerId } = data;
          acc[practitionerId] = data.workingHours?.map((workingHour) => ({
            ...workingHour,
            startDate: moment(`${workingHour.date} ${workingHour.start}`).toDate(),
            endDate: moment(`${workingHour.date} ${workingHour.end}`).toDate(),
          }));
          return acc;
        },
        {}
      );
      setWorkingHoursMap(newWorkingHoursMap);

      const testData: ExtendedAppointmentModel[] = [...appointments2, ...blockOffs];

      const newDummyData: ExtendedAppointmentModel[] = cloneDeep(testData)
        .map((dummyAppointment) => {
          const provider = practitioners[dummyAppointment.practitionerId - 1];
          if (!provider) {
            return dummyAppointment;
          }

          return {
            ...dummyAppointment,
            practitionerId: provider.id,
          };
        })
        .filter((appointment) => {
          if (appointment.bookingType !== BookingTypeEnum.APPOINTMENT) {
            return true;
          }
          return (
            (selectedGFEStatus === '' || (selectedGFEStatus !== '' && appointment.gfeStatus === selectedGFEStatus)) &&
            (selectedAppTypeId === '' ||
              (selectedAppTypeId !== '' && appointment.appointmentTypeId === selectedAppTypeId))
          );
        });
      setDummyData(newDummyData);
    }
  }, [medspaAppointments, isFetchingMedspaAppointments, appointments, selectedGFEStatus, selectedAppTypeId]);

  const missingDataInfo = useMemo(() => {
    const missingDataMap: Record<string, any> = {};

    if (!medspaLocations.length) {
      missingDataMap.buttonText = 'Set up Location';
      missingDataMap.bodyText = (
        <span>
          It looks like you haven&apos;t set up your MedSpa location yet. To proceed, please set up your first location
          by clicking the button below.
        </span>
      );
      missingDataMap.buttonAction = () => history.push(MEDSPA_ADMIN_LOCATIONS);
      return missingDataMap;
    }

    if (!practitioners.length) {
      missingDataMap.buttonText = 'Add Provider';
      missingDataMap.bodyText = (
        <span>
          It looks like you haven&apos;t added any providers to your MedSpa yet. To proceed, please add your first
          provider by clicking the button below.
        </span>
      );
      missingDataMap.buttonAction = () => history.push(MEDSPA_ADMIN_PRACTITIONERS);
      return missingDataMap;
    }

    if (
      medspaLocations.length > 0 &&
      medspaLocations.every((location: IMedspaLocation) => !location.practitioners.length)
    ) {
      missingDataMap.buttonText = 'Assign Provider';
      missingDataMap.bodyText = (
        <span>
          It looks like you haven&apos;t assigned any providers to a location yet. To proceed, please assign a provider
          to a location by clicking the button below.
        </span>
      );
      missingDataMap.buttonAction = () => history.push(MEDSPA_ADMIN_LOCATIONS);
      return missingDataMap;
    }
    return missingDataMap;
  }, [medspaLocations, practitioners]);

  if (isLoading) {
    return (
      <EmptyPage title="Calendar">
        <Box textAlign="center" width="100%" padding="100px 0">
          <CircularProgress />
        </Box>
      </EmptyPage>
    );
  }

  const missingData = Object.keys(missingDataInfo).length > 0;

  if (missingData) {
    return (
      <EmptyPage title="Calendar">
        <h1>Welcome To Your Calendar Section!</h1>
        <Box width="50%">
          <p>{missingDataInfo.bodyText}</p>
        </Box>
        <Box marginTop="24px">
          <Button className={classes.saveButton} type="button" onClick={missingDataInfo.buttonAction}>
            {missingDataInfo.buttonText}
          </Button>
        </Box>
      </EmptyPage>
    );
  }

  const IconComponent = ({ gfeStatus }: { gfeStatus: MedspaCalendarGFEStatusEnum }) => {
    const Component = getGFEStatusIconComponent(gfeStatus);
    return <Component />;
  };

  return (
    <Page title="Calendar">
      <Box display="flex" flexDirection="column" height="100%" gridGap="1rem">
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <EHRSelect
              label="Location"
              dataCy="select-location"
              onChange={(value: string | number) =>
                setSelectedMedspaLocation(
                  medspaLocations.find((medspaLocation: IMedspaLocation) => medspaLocation.id === +value)
                )
              }
              id="medspaLocation"
              value={selectedMedspaLocation?.id || null}
              fullWidth
            >
              {sortBy(medspaLocations, (medspaLocation: IMedspaLocation) => medspaLocation.name).map(
                (medspaLocation: IMedspaLocation) => {
                  const { name, address, city, state, zipCode } = medspaLocation;
                  return (
                    <MenuItem key={medspaLocation.id} value={medspaLocation.id}>
                      <strong>{name}</strong>&nbsp;/ {address}, {city}, {state}, {zipCode}
                    </MenuItem>
                  );
                }
              )}
            </EHRSelect>
          </Grid>
          <Grid item xs={3}>
            <EHRSelect
              label="Provider"
              dataCy="select-provider"
              onChange={(value: string | number) => onChangePractitionerSelected(value)}
              id="provider"
              value={selectedPractitionerId || ''}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">All Providers</MenuItem>
              {availableMedspaLocationPractitioners.map((practitioner: IPractitioner) => (
                <MenuItem key={practitioner.id} value={practitioner.id}>
                  {practitioner.firstName} {practitioner.lastName}
                </MenuItem>
              ))}
            </EHRSelect>
          </Grid>
          <Grid item xs={2}>
            <EHRSelect
              label="Appointments"
              dataCy="select-app-type"
              onChange={(value: string | number) => setSelectedAppTypeId(value)}
              id="appointments"
              value={selectedAppTypeId || ''}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">All Appointments</MenuItem>
              {enabledAppTypes.map((appType: IAppointmentType) => (
                <MenuItem key={appType.id} value={appType.id}>
                  {appType.name}
                </MenuItem>
              ))}
            </EHRSelect>
          </Grid>
          <Grid item xs={2}>
            <EHRSelect
              label="GFE Status"
              dataCy="select-gfe-status"
              onChange={(value: string | number) => setSelectedGFEStatus(value as string)}
              id="gfeStatus"
              value={selectedGFEStatus || ''}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Any Status</MenuItem>
              <MenuItem value={MedspaCalendarGFEStatusEnum.TO_CLEAR}>
                <Box display="flex" gridGap="0.25rem" alignItems="center">
                  <IconComponent gfeStatus={MedspaCalendarGFEStatusEnum.TO_CLEAR} />
                  To Clear
                </Box>
              </MenuItem>
              <MenuItem value={MedspaCalendarGFEStatusEnum.TO_RECLEAR}>
                <Box display="flex" gridGap="0.25rem" alignItems="center">
                  <IconComponent gfeStatus={MedspaCalendarGFEStatusEnum.TO_RECLEAR} />
                  To Re-Clear
                </Box>
              </MenuItem>
              <MenuItem value={MedspaCalendarGFEStatusEnum.INCOMPLETE}>
                <Box display="flex" gridGap="0.25rem" alignItems="center">
                  <IconComponent gfeStatus={MedspaCalendarGFEStatusEnum.INCOMPLETE} />
                  Incomplete
                </Box>
              </MenuItem>
              <MenuItem value={MedspaCalendarGFEStatusEnum.CLEARED}>
                <Box display="flex" gridGap="0.25rem" alignItems="center">
                  <IconComponent gfeStatus={MedspaCalendarGFEStatusEnum.CLEARED} />
                  Cleared
                </Box>
              </MenuItem>
            </EHRSelect>
          </Grid>
        </Grid>
        {isFetchingMedspaAppointments ? <Skeleton height="15px" /> : <div style={{ height: '15px' }} />}
        {isLoading && <MultipleSkeleton addPosition={false} />}
        {!isLoading &&
          availableMedspaLocationPractitioners.length > 0 &&
          enabledAppTypes.length > 0 &&
          !providerIsNotInLocation && (
          <Box display="flex">
            <MedspaCalendar
              isFetching={isFetchingMedspaAppointments}
              appointmentsData={dummyData}
              selectedPractitionerId={selectedPractitionerId}
              onChangePractitionerSelected={onChangePractitionerSelected}
              providerResources={providerResources}
              providersMap={providersMap}
              selectedMedspaLocation={selectedMedspaLocation}
              date={currentDate}
              onDateChange={(newDate) => setCurrentDate(newDate)}
              appointmentTypes={enabledAppTypes}
              workingHoursMap={workingHoursMap}
              currentViewName={currentViewName}
              setCurrentViewName={onChangeCurrentViewName}
              onAppointmentsChange={handleAppointmentsChange}
            />
          </Box>
        )}
        {!isLoading && (!availableMedspaLocationPractitioners.length || providerIsNotInLocation) && (
          <Box display="flex">
            <Box className={classes.missingDataContainer}>
              <Box style={{ textAlign: 'center', width: '50%', maxWidth: '500px' }}>
                <h4>No active provider available for this location</h4>
                <p style={{ marginBottom: '1rem' }}>
                  It looks like you don&apos;t have any active providers for scheduling at this location. Click the
                  button below to set providers as active or to add providers to this location.
                </p>
                <Button
                  className={classes.saveButton}
                  type="button"
                  onClick={() => history.push(MEDSPA_ADMIN_LOCATIONS)}
                >
                  Edit Location
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {!isLoading && availableMedspaLocationPractitioners.length > 0 && !enabledAppTypes.length && (
          <Box display="flex">
            <Box className={classes.missingDataContainer}>
              <Box style={{ textAlign: 'center', width: '50%', maxWidth: '500px' }}>
                <h4>No active appointment types for this location</h4>
                <p style={{ marginBottom: '1rem' }}>
                  It looks like you don&apos;t have any active appointment types enabled for this location. Click the
                  button below to set appointment types as active or to add appointment types to this location.
                </p>
                <Button
                  className={classes.saveButton}
                  type="button"
                  onClick={() => history.push(MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG)}
                >
                  Edit Appointment Types
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Page>
  );
};
