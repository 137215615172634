import * as React from 'react';
import { Box, Breadcrumbs } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';

export interface BreadcrumbsContainerProps {
  children: React.ReactNode;
  backgroundColor?: string;
  padding?: number | string;
}

const BreadcrumbsContainer: React.FC<BreadcrumbsContainerProps> = ({ children, backgroundColor, padding }) => (
  <Box padding={padding} style={{ backgroundColor }}>
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>{children}</Breadcrumbs>
  </Box>
);

BreadcrumbsContainer.defaultProps = {
  backgroundColor: '#f2f5f5',
  padding: 2,
};

export default BreadcrumbsContainer;
