import React, { useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MEDSPA_PROVIDER_PATIENTS } from 'src/routes/medspaPractitionerRoutes';
import { PractitionerSignupSidebar } from './PractitionerSignupSidebar';
import { useStyles } from './index.styles';
import { SIGNUP_STEP } from './constants';
import { schemaSignUp } from './schemaForm';
import { useCreatePractitionerRegistration } from '../../hooks/mutations/useCreatePractitionerRegistration';
import { dispatch } from '../../rematch';
import { PRACTITIONER_HOME_PATH } from '../../routes/practitionerRoutes';
import useQueryParams from '../../hooks/useQueryParams';
import { Practitioners } from '../../services/Practitioners';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export const Signup = () => {
  const classes = useStyles();
  const history = useHistory();
  const { queryParams } = useQueryParams();
  const { isAuthenticate } = useSelector(({ auth }: any) => auth);
  const isMedspaProvider = Practitioners.isMedspaProvider();
  const { mutateAsync: savePractitionerRegistration } = useCreatePractitionerRegistration();
  const [finishedSignup, setFinishedSignup] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const code = queryParams.get('code');

  const { register, errors, handleSubmit, setValue, setError } = useForm<FormData>({
    resolver: yupResolver(schemaSignUp),
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
  });

  if (isAuthenticate) {
    if (isMedspaProvider) {
      history.push(MEDSPA_PROVIDER_PATIENTS);
    } else {
      history.push(PRACTITIONER_HOME_PATH);
    }
  }

  if (!code) {
    history.push('/login');
  }

  const handleRegistration = async (data: FormData) => {
    try {
      setSaving(true);
      await savePractitionerRegistration({ ...data, token: code || '' });
      setFinishedSignup(true);
    } catch (error) {
      setSaving(false);
      const formErrors = error?.response?.data;
      if (formErrors) {
        Object.keys(formErrors).forEach((key: string) => {
          // @ts-ignore
          setError(key, { type: 'custom', message: formErrors[key] });
        });
        if (formErrors.token) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: 'This registration link is not valid please contact support.',
              type: 'error',
              duration: 5000,
            },
          });
        }
      } else {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: 'There was an error, please try again later.',
            type: 'error',
            duration: 5000,
          },
        });
      }
    }
  };

  return (
    <div className={classes.signupContainer}>
      <PractitionerSignupSidebar step={SIGNUP_STEP} />
      {finishedSignup ? (
        <div className={classes.signupDetails}>
          <div className={classes.signupComplete}>
            <h2 className={classes.signupCompleteHeader}>
              Great! You&apos;re one step away from completing your EHR account!
            </h2>
            <p>We&apos;ve just sent an email to the address you provided.</p>
            <p>
              To finish the account creation process, please check your inbox and click on the confirmation link
              we&apos;ve sent you.
            </p>
            <p>
              If you don&apos;t see the email in your inbox, be sure to check your spam folder. It may take a few
              moments to arrive.
            </p>
          </div>
        </div>
      ) : (
        <div className={classes.signupDetails}>
          <form className={classes.signupForm} onSubmit={handleSubmit(handleRegistration)}>
            <h2>Your Details</h2>
            <p>Please provider your name, phone number, and email</p>
            <br />
            <TextField
              className={classes.signupField}
              data-cy="firstNameInput"
              fullWidth
              name="firstName"
              error={!!errors.firstName}
              helperText={errors.firstName?.message || ''}
              onChange={(event) => setValue('firstName', event.target.value)}
              inputRef={register}
              label="First Name*"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className={classes.signupField}
              data-cy="lastNameInput"
              fullWidth
              name="lastName"
              error={!!errors.lastName}
              helperText={errors.lastName?.message || ''}
              onChange={(event) => setValue('lastName', event.target.value)}
              inputRef={register}
              label="Last Name*"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className={classes.signupField}
              data-cy="phoneInput"
              fullWidth
              name="phone"
              error={!!errors.phone}
              helperText={errors.phone?.message || ''}
              onChange={(event) => setValue('phone', event.target.value)}
              inputRef={register}
              label="Phone Number*"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className={classes.signupField}
              data-cy="emailInput"
              fullWidth
              name="email"
              error={!!errors.email}
              helperText={
                errors.email?.message
                  ? errors.email.message.charAt(0).toUpperCase() + errors.email.message.substring(1)
                  : ''
              }
              onChange={(event) => setValue('email', event.target.value)}
              inputRef={register}
              label="Email*"
              type="email"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <button type="submit" className={classes.signupButton} disabled={saving}>
              {saving ? (
                <>
                  Setting up account. Please wait...
                  <CircularProgress style={{ color: 'white', marginLeft: '10px' }} size={20} />
                </>
              ) : (
                'Continue'
              )}
            </button>
            {!!errors.email && (
              <p className={classes.loginLink}>
                Already have an account? Click <a href="/login">here</a> to login.
              </p>
            )}
          </form>
        </div>
      )}
    </div>
  );
};
