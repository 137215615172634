import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  mainSection: {
    display: 'flex',
    padding: '18px 0 30px 0',
  },
  ghostTitle: {
    borderRight: '8px solid #E7EEED',
    display: 'flex',
    alignItems: 'center',
    padding: '0 35px',
    textAlign: 'right',
    fontSize: '18px',
    fontFamily: 'Messina Sans Light',
    color: 'transparent',
  },
  tendersSection: {
    width: '100%',
    paddingLeft: '20px',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: '65%',
    marginRight: '15px',
  },
  input: {
    height: '46px',
    width: '100%',
    border: '1px solid #979797',
    borderRadius: '4px',
    fontSize: '15px',
    color: '#3A3B3F',
    paddingLeft: '10px',
    '&::placeholder': {
      color: '#C0C0C0',
    },
    '&:disabled': {
      border: '1px solid #EEEEEE',
    },
  },
  creditsAvailable: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '20px',
    width: '100%',
    fontSize: '15px',
    color: '#3A3B3F',
    paddingLeft: '10px',
  },
}));
