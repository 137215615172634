import React, { useState } from 'react';

import * as yup from 'yup';
import { Box, Grid, InputAdornment, makeStyles } from '@material-ui/core';
import SignDocumentButton from 'src/components/common/SignDocumentButton';
import FullPageModal from 'src/components/common/FullPageModal';
import { EHRButton, EHRTextField } from 'src/components/ui/v1';
import IState from 'src/interfaces/IState';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { yupResolver } from '@hookform/resolvers';
import moment from 'moment';
import { EHRSignatureCanvas } from 'src/components/ui/v1/EHRSignatureCanvas';
import { REAL_ESTATE_MANAGEMENT_AGREEMENT } from 'src/constants/onboardingAgreements.constant';
import { ShippingAddress } from 'src/interfaces/onboarding.interface';
import { REAL_ESTATE_DOCUMENT_HELPER_TEXT } from 'src/constants/onboarding.constants';
import { AddressDetails, DEFAULT_ADDRESS_DETAILS } from '.';

const useStyles = makeStyles({
  addressFormContainer: {
    width: '100%',
    maxWidth: '480px',
    margin: '60px auto',
  },
});

interface DocumentSigningFormProps {
  enableAgreementSigning: boolean;
  states: IState[];
  addressDetails: AddressDetails;
  onFormSigned: (data: ShippingAddress) => void;
  file?: string | null;
  disable?: boolean;
}

const addressDetailsSchema = yup.object().shape({
  monthlyRent: yup.number().required('Monthly rent is a required field'),
  leaseStartDate: yup.date().required('Lease Start Date is a required field'),
  leaseTermLength: yup.string().required('Lease Term Length is a required field'),
  signature: yup.string().nullable().required('Signature is a required field'),
  fullName: yup.string().required('Full Name is a required field'),
});

const DocumentSigningForm = ({
  addressDetails,
  onFormSigned,
  file = null,
  enableAgreementSigning = false,
  disable = false,
}: DocumentSigningFormProps) => {
  const title = 'Real Estate Business Management';
  const classes = useStyles();
  const { control, setValue, handleSubmit, errors, reset } = useForm<ShippingAddress>({
    resolver: yupResolver(addressDetailsSchema),
    defaultValues: {
      monthlyRent: addressDetails.monthlyRent ?? 0,
      leaseStartDate: addressDetails.leaseStartDate ?? moment().toDate(),
      leaseTermLength: addressDetails.leaseTermLength ?? '',
      signature: null,
      fullName: '',
      ...DEFAULT_ADDRESS_DETAILS,
    },
  });
  const overrideSetValue = (key: string, value: any) => setValue(key, value, { shouldValidate: true });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalHeaderText = 'REAL ESTATE BUSINESS MANAGEMENT AMENDMENT TO PORTRAIT MASTER SERVICES AGREEMENT';

  const handleModal = () => {
    if (isModalOpen) {
      reset();
    }
    setIsModalOpen((prevState) => !prevState);
  };

  const onSign = (signatureFile: string) => {
    overrideSetValue('signature', signatureFile);
  };

  const onClearSignature = () => {
    setValue('signature', null);
  };

  const onSubmit = (data: ShippingAddress) => {
    onFormSigned(data);
    handleModal();
  };

  return (
    <Box width="100%" marginTop="32px">
      <SignDocumentButton
        label={REAL_ESTATE_DOCUMENT_HELPER_TEXT}
        title={title}
        onClick={handleModal}
        disabled={!enableAgreementSigning || disable}
        pdf={file as string}
      />
      <FullPageModal headerText={modalHeaderText} isOpen={isModalOpen} handleClose={handleModal}>
        <Box width="100%" maxWidth="800px" margin="auto">
          <Box
            margin="40px 0"
            padding="20px"
            bgcolor="white"
            dangerouslySetInnerHTML={{ __html: REAL_ESTATE_MANAGEMENT_AGREEMENT }}
          />
          <hr />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="document-form">
          <Grid container spacing={1} className={classes.addressFormContainer}>
            <Grid item xs={12} sm={6}>
              <EHRTextField
                value={addressDetails.city}
                label="City"
                dataCy="city"
                data-testid="input-select-city"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EHRTextField
                value={addressDetails.state}
                label="State"
                dataCy="state"
                data-testid="input-select-state"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EHRTextField
                value={addressDetails.streetAddress}
                label="Address"
                dataCy="street-address"
                data-testid="input-street-address"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EHRTextField
                inputProps={{ min: 0 }}
                value={addressDetails.zip}
                label="Zip code"
                dataCy="shipping-address-zip"
                type="number"
                data-testid="input-zip"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="monthlyRent"
                control={control}
                render={({ onChange, value }) => (
                  <EHRTextField
                    type="number"
                    label="Monthly Rent"
                    dataCy="monthly-rent"
                    data-testid="monthly-rent"
                    inputProps={{ min: 0 }}
                    onChange={onChange}
                    value={value}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="leaseStartDate"
                control={control}
                render={({ onChange, value }) => (
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      variant="dialog"
                      inputVariant="outlined"
                      format="YYYY-MM-DD"
                      value={value}
                      onChange={onChange}
                      autoOk
                      label="Lease Start Date"
                      TextFieldComponent={(props) => (
                        <EHRTextField
                          {...props}
                          dataCy="lease-start-date"
                          data-testid="lease-start-date"
                          style={{ marginBottom: '10px' }}
                          fullWidth
                        />
                      )}
                      error={!!errors?.leaseStartDate}
                      helperText={errors?.leaseStartDate?.message}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="leaseTermLength"
                control={control}
                render={({ onChange, value }) => (
                  <EHRTextField
                    type="text"
                    label="Lease Term Length"
                    dataCy="lease-term-length"
                    data-testid="lease-term-length"
                    onChange={onChange}
                    value={value}
                    error={!!errors?.leaseTermLength}
                    helperText={errors?.leaseTermLength?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <EHRSignatureCanvas
                onSign={onSign}
                onClear={onClearSignature}
                errors={!!errors?.signature?.message}
                helperText={errors?.signature?.message}
              />
            </Grid>
            <Controller
              name="fullName"
              control={control}
              render={({ onChange, value }) => (
                <Grid item xs={12}>
                  <EHRTextField
                    type="text"
                    label="Your Full Name"
                    dataCy="full-name-input"
                    data-testid="full-name-input"
                    onChange={onChange}
                    value={value}
                    error={!!errors?.fullName}
                    helperText={errors?.fullName?.message}
                  />
                </Grid>
              )}
            />
            <Grid item xs={12}>
              <EHRButton
                type="submit"
                dataCy="submit-form-btn"
                data-testid="submit-form-btn"
                text="Sign"
                color="primary"
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </FullPageModal>
    </Box>
  );
};

export default DocumentSigningForm;
