import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Star, StarBorder } from '@material-ui/icons';
import Customers from '../../services/Customers';
import compile from '../../utils/toastMessagesCompiler';
import { dispatch } from '../../rematch';
import { CUSTOMERS_LIST, PHYSICIAN_SERVICE_VISITS_BY_PROVIDER } from '../../constants/reactQuery.keys';
import { PRIMARY_COLOR } from '../../constants/general.constants';

const ERROR_STARRING_CUSTOMER = compile('generic.error_message', {
  action: 'Marking patient as important',
  element: "wasn't successful",
});
const SUCCESS_STARRING_CUSTOMER = compile('generic.success_message', {
  action: 'marked as important',
  element: 'The customer was',
});
const ERROR_STARRING_CUSTOMER_NOT_AUTHORIZED = compile('generic.error_message', {
  action: 'Selecting Patient as Important',
  element: 'was unsuccessful as you are not authorized to do so',
});
const ERROR_UNSTARRING_CUSTOMER = compile('generic.error_message', {
  action: 'Marking patient as not important',
  element: "wasn't successful",
});
const SUCCESS_UNSTARRING_CUSTOMER = compile('generic.success_message', {
  action: 'unmarked as important',
  element: 'The customer was',
});
const ERROR_UNSTARRING_CUSTOMER_NOT_AUTHORIZED = compile('generic.error_message', {
  action: 'Selecting Patient as not Important',
  element: 'was unsuccessful as you are not authorized to do so',
});

export const useMarkCustomerImportant = (patientId: number, row: any) => {
  const queryClient = useQueryClient();
  const { markImportantPatientV3 } = Customers;
  const markImportantPatientMutation = useMutation(markImportantPatientV3);

  const StarColumn = () =>
    row.markedImportant ? (
      <Star htmlColor={PRIMARY_COLOR} onClick={handleStarPatient} />
    ) : (
      <StarBorder onClick={handleStarPatient} />
    );

  const handleStarPatient = async () => {
    const { markedImportant } = row;
    const successMessage = markedImportant ? SUCCESS_UNSTARRING_CUSTOMER : SUCCESS_STARRING_CUSTOMER;
    const errorMessage = markedImportant ? ERROR_UNSTARRING_CUSTOMER : ERROR_STARRING_CUSTOMER;
    const errorMessageNotAuthorized = markedImportant
      ? ERROR_UNSTARRING_CUSTOMER_NOT_AUTHORIZED
      : ERROR_STARRING_CUSTOMER_NOT_AUTHORIZED;

    try {
      await markImportantPatientMutation.mutateAsync({
        patientId,
        marked: !markedImportant,
      });

      row.markedImportant = !markedImportant; // eslint-disable-line no-param-reassign

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: successMessage,
          type: 'success',
        },
      });

      await queryClient.invalidateQueries([CUSTOMERS_LIST, 'physician']);
      await queryClient.invalidateQueries([PHYSICIAN_SERVICE_VISITS_BY_PROVIDER]);
    } catch (starError) {
      const isUnauthorized = starError?.includes('You are not authorized to do that');
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: isUnauthorized ? errorMessageNotAuthorized : errorMessage,
          type: 'error',
        },
      });
    }
  };

  return {
    isLoading: markImportantPatientMutation.isLoading,
    StarColumn,
  };
};
