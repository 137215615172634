import { makeStyles, withStyles, TableCell, AccordionSummary, Theme } from '@material-ui/core';

export const TableCellHeader = withStyles(() => ({
  root: {
    padding: '8px 16px',
    fontSize: 13,
    maxWidth: '100px',
    minWidth: '100px',
    borderBottom: 'none',
    backgroundColor: '#fff',
    color: '#0000007a',
    fontWeight: 'normal',
    '&[data-large="true"]': {
      maxWidth: 'unset',
    },
  },
  '&:first-of-type': {
    maxWidth: '250px',
    minWidth: '250px',
  },
}))(TableCell);

export const TableCellBody = withStyles(() => ({
  root: {
    fontSize: '11px',
  },
}))(TableCell);

export const IconLeftAccordionSummary = withStyles({
  root: {
    padding: '13px 16px',
    width: '100%',
    overflow: 'hidden',
  },
  content: {
    overflow: 'hidden',
  },
  expandIcon: {
    order: -1,
    marginRight: '-5px',
  },
})(AccordionSummary);

const orderingNotice = (theme: Theme) => ({
  padding: '8px 16px',
  backgroundColor: 'rgb(254,242,226)',
  color: 'rgb(133,76,0)',
  '& p': {
    margin: 'auto auto auto 25px',
    fontSize: '0.9rem',
    fontFamily: 'Messina Sans Regular',
  },
  '& p > span': {
    fontFamily: 'Roboto',
  },
  '& svg': {
    fontSize: 28,
    color: 'rgb(247,147,13)',
  },

  [theme.breakpoints.down('sm')]: {
    width: '900px',
  },
});

export const useStyle = makeStyles((theme) => ({
  subheadingRow: {
    backgroundColor: '#eee',
  },
  orderDashboard: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    width: '100%',
    padding: '0 15px',
    gap: '0.5rem',
  },
  tableContainer: {
    border: '1px solid #E1E1E1',
    backgroundColor: '#fff',
    borderRadius: '10px',
    marginBottom: '12px',
  },
  innerTable: {
    padding: '12px',
  },
  innerTableCellHeader: {
    color: '#000000',
    opacity: '100%',
    fontWeight: 'bolder',
    fontSize: '16px',
    minWidth: 215,
    maxWidth: 215,
  },
  chip: {
    backgroundColor: '#CBEAC9',
    color: '#256E20',
    padding: '4px 15px',
    borderRadius: '4px',
    width: 'max-content',
  },
  scrollTable: {
    maxHeight: '56vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  orderContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '8px',
    '@media (max-width: 820px)': {
      flexDirection: 'column',
    },
  },
  orderStatusBar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px',
    borderRadius: '14px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
    gap: '1rem',
    justifyContent: 'center',
  },
  orderWarning: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px 25px',
    borderRadius: '14px',
    border: '1px solid #ddd',
    minWidth: '40%',
    maxWidth: '500px',
    backgroundColor: '#fff',
    gap: '1rem',

    '& svg': {
      fontSize: 28,
      color: 'rgb(247,147,13)',
    },

    '& p': {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 13,
      textAlign: 'left',
      color: 'rgba(0, 0, 0, 0.48)',
    },

    '@media (max-width: 820px)': {
      maxWidth: '100%',
    },
  },
  orderSteps: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    flexWrap: 'nowrap',
    gap: '1rem',
    justifyContent: 'space-between',
  },
  orderStatusStep: {
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'row',
    padding: 8,
    minWidth: '169px',
    backgroundColor: 'rgb(245,245,245)',
    color: '#777',
    '&[data-current="true"]': {
      color: '#111',
    },
    '& > *:first-child': {
      backgroundColor: 'rgb(254,254,254)',
      borderRadius: '50%',
      width: 30,
      height: 30,
      padding: 5,
      textAlign: 'center',
    },
    '& > *:last-child': {
      margin: 'auto',
      marginLeft: 10,
    },
    '&[data-current="true"] > *:first-child': {
      color: '#fff',
      backgroundColor: 'rgb(40,85,76)',
    },
  },
  orderStatusStepLabel: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto auto auto 0',
  },
  faqs: {
    display: 'none',
    flexDirection: 'column',
    margin: 'auto 10px auto auto',
    minWidth: '135px',
    textAlign: 'right',
  },
  stepChevron: {
    display: 'block',
    margin: 'auto 0 auto auto',
    paddingLeft: '10px',
    fontSize: '42px',
  },
  stepNum: {
    fontSize: '18px',
    fontVariant: 'all-small-caps',
  },
  actualInput: {
    border: 'none',
    outline: 'none',
    maxWidth: '99px',
    textAlign: 'center',
    '& *': {
      border: 'none',
      outline: 'none',
      textAlign: 'center',
    },
  },
  actualInput2: {
    border: 'none',
    outline: 'none',
    maxWidth: '55px',
    textAlign: 'center',
    '& *': {
      border: 'none',
      outline: 'none',
      textAlign: 'center',
    },
  },
  qtyInput: {
    border: 'none',
    outline: 'none',
    maxWidth: '50px',
    minWidth: '50px',
    textAlign: 'center',
    '& *': {
      border: 'none',
      outline: 'none',
      textAlign: 'center',
    },
    '& input[type="number"]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  orderInput: {
    maxWidth: '99px',
    maxHeight: '45px',
    textAlign: 'center',
    fontSize: 16,
    '& *': {
      textAlign: 'center',
      maxHeight: '45px',
      fontSize: 12,
    },
    '& input': {
      padding: '10px 14px',
      minWidth: 20,
    },
    '& input[type="number"]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  lotNumber: {
    fontSize: '18px',
    fontVariantCaps: 'all-small-caps',
    fontVariantNumeric: 'tabular-nums',
  },
  footerButtons: {
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    minHeight: '70px',
    maxHeight: '70px',
    background: 'rgba(232, 238, 237, 1)',
    width: '100%',
    bottom: '0px',
    left: '0px',
    paddingRight: 25,
    zIndex: 100,

    '@media (max-width: 820px)': {
      overflowX: 'auto',
    },
  },
  orderTotalFooter: {
    padding: '10px 18px',
    minWidth: '155px',
    backgroundColor: 'rgba(255, 255, 255, 0.64)',
    borderRadius: '4px',
    color: '#000',
    margin: 'auto 16px auto auto',
    textAlign: 'center',
  },
  save: {
    backgroundColor: 'rgba(203, 234, 201, 1)', // #CBEAC9
    color: '#256E20',
  },
  buttonsContainer: {
    display: 'flex',
  },
  actionButton: {
    textTransform: 'none',
    fontSize: 14,
    minWidth: 132,
    height: 'auto',
    borderColor: '#ccc',
    color: '#000',
    backgroundColor: 'rgba(51,126,137,0)',
    '&:first-child': {
      margin: 'auto auto auto 0',
      padding: '8px 18px',
    },
    '&:last-child': {
      backgroundColor: 'rgba(29, 88, 77, 1)', // #1D584D
      color: '#fff',
      margin: 'auto 0',
      padding: '8px 18px',
      '&:disabled': {
        backgroundColor: 'rgba(29,88,77,0.65)',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(29,88,77,0.25)',
    },
    '&:last-child:hover': {
      backgroundColor: 'rgba(29, 88, 77, 0.45)',
    },
    '&[data-showmore="true"]': {
      margin: 'auto',
      marginTop: '25px',
      marginBottom: '50px',
      width: '400px',
      color: '#222',
      borderColor: '#eee',
      backgroundColor: '#fff',
      '&:hover': {
        color: '#333',
        borderColor: '#dadada',
        backgroundColor: '#f8f8f8',
      },
    },
    '&[data-approve="true"]': {
      marginRight: '10px',
      marginLeft: '20px',
      width: '30%',
      color: '#fff',
      backgroundColor: '#5f9ea0',
    },
    '&[data-missing="true"]': {
      margin: 'auto 15px',
      width: '25%',
      color: '#000',
      padding: 10,
      backgroundColor: 'transparent',
      '&:hover': {
        color: '#333',
        backgroundColor: 'rgba(81,116,127,0.25)',
      },
    },
    '&[data-missingback="true"]': {
      margin: '0',
      marginTop: '35px',
      width: '100%',
      color: '#000',
      backgroundColor: 'transparent',
      '&:hover': {
        color: '#333',
        backgroundColor: 'rgba(81,116,127,0.25)',
      },
    },
  },
  exceed: {
    fontSize: '11px',
  },
  functional: {
    maxWidth: '90px',
    minWidth: '90px',
    textAlign: 'right',
    opacity: '100%',
  },
  removeItem: {
    maxWidth: '90px',
    minWidth: '90px',
    textAlign: 'right',
    color: '#E63E3E',
    fontSize: '14px',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      justifyContent: 'flex-end',
      cursor: 'pointer',
    },
  },
  removeIcon: {
    fontSize: '1rem',
    verticalAlign: 'bottom',
  },
  flag: {
    maxWidth: '225px',
    minWidth: '225px',
    textAlign: 'center',
    fontSize: '9px',
  },
  notesCell: {
    maxWidth: '225px',
    minWidth: '225px',
    textAlign: 'center',
    fontSize: '9px',
    '&[data-mobile="true"]': {
      minWidth: 'unset',
    },
  },
  flagger: {
    margin: '20px 0px',
  },
  nameCol: {
    maxWidth: '145px',
    overflow: 'hidden',
    transitionDuration: '0.1s',
    '& svg': {
      fontSize: 15,
      verticalAlign: 'middle',
      marginLeft: 5,
      color: 'rgb(246,147,12)',
    },
  },
  nameCol2: {
    maxWidth: '200px',
    minWidth: '200px',
    transitionDuration: '0.1s',
    borderLeft: '4px solid rgba(76,97,120,0)',
    '&[data-ignore="false"]': {
      borderLeft: '4px solid rgba(76,97,120,1)',
    },
  },
  deliveryDateLabel: {
    margin: 'auto 20px auto auto',
  },
  confirmBox: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '470px',
    padding: '15px',
    textAlign: 'center',
    paddingBottom: '30px',
    '& h4': {
      fontSize: '1.5rem',
    },
    '& p': {
      width: '75%',
      fontSize: '1rem',
      margin: '10px auto',
    },
    '& button': {
      backgroundColor: 'rgb(18,87,77)',
      color: '#fff',
      margin: 'auto 0 auto 15px',
      width: 75,
    },
  },
  divider: {
    height: 1,
    borderBottom: '1px solid #ccc',
    margin: '15px 0',
  },
  successIcon: {
    color: 'rgb(246,147,12)',
    borderRadius: '50%',
    fontSize: '70px',
    margin: '40px auto 20px auto',
  },
  recRow: {
    '& td': {
      fontSize: 11,
      borderBottom: 'none',
      padding: '8px 16px',
    },
  },
  viewMore: {
    display: 'flex',
    width: '100%',
    marginBottom: '100px',
    minHeight: '121px',
  },
  viewMoreButton: {
    margin: 'auto',
  },
  modalButtons: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  modalButton: {
    fontSize: '16px',
    fontFamily: 'Messina Sans SemiBold',
    width: '90%',
    height: '46px',
    borderColor: '#ccc',
    color: '#000',
    backgroundColor: 'rgba(51,126,137,0)',
    margin: '0',
    marginTop: '40px',
    marginBottom: '25px',
    marginRight: 'auto',
    '&:last-of-type': {
      backgroundColor: 'rgb(17,86,74)',
      color: '#fff',
      marginLeft: 'auto',
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: 'rgb(37,106,94)',
    },
    '&:last-of-type:hover': {
      backgroundColor: 'rgb(51,126,137)',
    },
  },
  smaller: {
    fontSize: '12px',
    textAlign: 'center',
  },
  toolTipContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerCell: {
    textAlign: 'center',
  },
  rightCell: {
    textAlign: 'right',
  },
  inputCell: {
    textAlign: 'center',
    display: 'flex',
    gap: '1rem',
    margin: '0 25px auto auto',
    '& > button': {
      borderRadius: 0,
    },
  },
  smallerInput: {
    fontSize: '13px',
    maxWidth: '90px',
    textAlign: 'center',
  },
  orderStatus: {
    width: '125px',
    display: 'flex',
    margin: 'auto 25px auto auto',
    color: 'rgb(209,241,189)',
    backgroundColor: 'rgb(49,79,31)',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontFamily: 'Messina Sans Regular',
    '&[data-status="order confirmed"]': {
      backgroundColor: 'rgb(189,235,241)',
      color: 'rgb(21,83,88)',
    },
    '&[data-status="order requested"]': {
      color: 'rgb(83,61,110)',
      backgroundColor: 'rgb(230,211,255)',
    },
    '&[data-status="rejected"] , &[data-status="canceled"]': {
      color: 'rgb(109,59,59)',
      backgroundColor: 'rgb(255,203,203)',
    },
  },
  rowStatus: {
    margin: '0',
  },
  orderStatusText: {
    margin: 'auto',
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
    },
  },
  orderHeader: {
    overflow: 'hidden',
  },
  orderSummary: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  orderTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  orderDate: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto 10px',
    fontSize: 14,
    textAlign: 'center',
    color: '#000000',
    fontFamily: 'Roboto',
    marginRight: '1rem',
  },
  orderSupplier: {
    margin: 'auto 0 auto 10px',
    fontSize: 12,
    border: '1px solid',
    borderRadius: '8px',
    width: 125,
    overflowY: 'hidden',
    textAlign: 'center',
    '&:nth-child(1)': {
      marginLeft: 'auto',
    },
  },
  accordionTag: {
    width: 170,
    fontSize: '0.9rem',
    fontFamily: 'Messina Sans Regular',
  },
  warehouseTag: {
    fontSize: '0.9rem',
    fontFamily: 'Messina Sans Regular',
  },
  smallText: {
    fontSize: '12px',
  },
  headerPad: {
    flexWrap: 'nowrap',
  },
  approveEdit: {
    fontSize: '1.2rem',
  },
  adminDate: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#fff',
    marginBottom: '25px',
    minHeight: '92px',
    padding: '8px 4px',
    flexWrap: 'nowrap',
  },
  adminDateInput: {
    margin: 'auto 0',
    width: '150px',
  },
  adminDateLabel: {
    margin: 'auto 20px',
    width: '160px',
  },
  approvalRowName: {
    marginLeft: -4,
    transitionDuration: '0.1s',
    width: 200,
    '&[data-over-recommendation="true"]': {
      borderLeft: '4px solid rgba(220,0,0,1)',
    },
  },
  approvalRowPrice: {
    width: 175,
  },
  approvalTag: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '96px',
  },
  approvalTagDate: {
    margin: 'auto',
    marginLeft: '15px',
    borderBottom: '1px solid #7770',
    transitionDuration: '0.1s',
    padding: '10px',
    '&[data-background="true"]': {
      color: '#777',
      borderBottom: '1px solid #7770',
    },
    '&[data-underline="true"]': {
      borderBottom: '1px solid #777f',
    },
  },
  bannerButton: {
    margin: 'auto',
    backgroundColor: 'rgb(95,158,160)',
    color: '#fff',
    border: '1px solid #ccc',
    fontSize: '1rem',
    padding: '20px',
    display: 'flex',
    '&:hover': {
      backgroundColor: 'rgb(51,126,137)',
    },
    '&[data-mobile="true"]': {
      margin: 'auto',
      padding: '10px',
      position: 'relative',
      bottom: '85px',
      width: '205px',
      marginLeft: '-185px',
      marginRight: '20px',
    },
  },
  missingDialog: {
    width: '650px',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px',
  },
  missingCell: {
    color: 'red',
  },
  modTitle: {
    marginBottom: '10px',
  },
  modTable: {
    maxHeight: '270px',
  },
  singleSkeleton: {
    margin: 'auto 0',
    width: '150px',
  },
  orderingBanner: {
    width: '100%',
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '60px',
    maxHeight: '60px',
    backgroundColor: 'rgb(150,38,58)',
    padding: '6px 0',
    transitionDuration: '1s',
    '&[data-mobile="true"]': {
      maxHeight: '77px',
    },
    '& > button': {
      margin: 'auto 0 auto 15px',
      backgroundColor: 'transparent',
      border: '1px solid #fff',
      color: '#fff',
      display: 'flex',
      fontSize: 12,
      padding: '6px 25px',
    },
    '& > button:last-child': {
      marginRight: 'auto',
    },
  },
  orderBannerLabel: {
    margin: 'auto',
    marginRight: 15,
    color: '#fff',
  },
  orderNotification: {
    margin: 'auto',
    color: 'red',
    fontSize: '0.8rem',
  },
  faqsDialog: {
    minHeight: '400px',
    width: '70vw',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  faqsTitle: {
    margin: '0 auto auto 0',
    paddingBottom: '5px',
    borderBottom: '1px solid #0003',
    width: '100%',
    fontSize: '2.5rem',
  },
  skelWrap: {
    display: 'flex',
    margin: 0,
  },
  h100: {
    height: '100px',
  },
  pulseOnly: {
    color: '#000',
    '&[data-loading="true"]': {
      animation: '$pulse 2s ease-in-out infinite',
    },
  },
  pulse: {
    height: '85px',
    backgroundColor: 'inherit',
    '&[data-edit="true"]': {
      transitionDuration: '0.1s',
      backgroundColor: '#f1f2f488',
    },
    '&[data-loading="true"]': {
      animation: '$pulse 2s ease-in-out infinite',
    },
  },
  pulse2: {
    backgroundColor: 'inherit',
    '&[data-edit="true"]': {
      transitionDuration: '0.1s',
      backgroundColor: '#f1f2f488',
    },
    '&[data-loading="true"]': {
      animation: '$pulse 2s ease-in-out infinite',
    },
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '30%': {
      opacity: 0.26,
    },
    '100%': {
      opacity: 1,
    },
  },
  columnize: {
    flexDirection: 'column',
    '& *': {
      flexDirection: 'column',
    },
  },
  orderFlash: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 45,
    padding: 0,
    margin: 0,
    marginBottom: 15,
    '& button': {
      color: '#fff',
      margin: 'auto 0 auto auto',
      backgroundColor: 'rgb(17,86,74)',
      textTransform: 'capitalize',
      fontSize: '0.9rem',
      padding: '8px 30px',
    },
    '& button:hover': {
      color: '#555',
    },
    '& > div': {
      margin: '10px 75px 0 auto',
      display: 'flex',
      '& circle': {
        strokeWidth: 1,
        color: '#aaa',
      },
    },
  },
  stackCell: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 0,
    margin: 0,
  },
  addNote: {
    color: '#000',
    width: '100%',
    fontSize: '10px',
    textAlign: 'center',
    maxWidth: '225px',
    margin: 'auto',
    '& *': {
      textAlign: 'center',
    },
    '& :disabled': {
      color: '#000',
    },
  },
  periodConfirmDialog: {
    width: '465px',
    padding: '35px 25px 10px 25px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  periodConfirmButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  approveSave: {
    fontSize: '2rem',
    marginLeft: '5px',
    verticalAlign: 'bottom',
  },
  approvalSave: {
    marginLeft: '-5px',
  },
  boxLoader: {
    width: '180px',
    margin: 'auto 25px auto 0',
    display: 'flex',
    '& *': {
      margin: 'auto',
    },
  },
  doneCaption: {
    fontSize: '11px',
  },
  doneIcon: {
    fontSize: '28px',
  },
  orderNotes: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    '& textarea , p': {
      width: '100%',
      margin: 0,
      resize: 'none',
      borderRadius: '6px',
      minHeight: 180,
      padding: '16px',
      fontFamily: 'Messina Sans Regular',
      border: '1px solid #C8C8C8',
    },
    '& textarea:disabled': {
      color: '#000',
    },
    '& p:first-of-type': {
      minHeight: 'unset',
    },
    '& button': {
      marginLeft: '-55px',
      marginTop: '25px',
      zIndex: 10,
      fontSize: 30,
      display: 'none',
      '&[data-editing="true"]': {
        display: 'block',
      },
    },
  },
  headerContainer: {
    width: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    '& > div:nth-child(2)': {
      margin: '20px 0 30px 0',
    },
    '@media (max-width: 820px)': {
      flexDirection: 'column',
    },
  },
  orderStepContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  mediumHeader: {
    fontSize: '0.85rem',
  },
  confirmOrderButton: {
    backgroundColor: '#fff',
    border: '1px solid #111',
    color: '#111',
    width: 110,
  },
  getLabelsButton: {
    backgroundColor: 'rgb(29,88,77)',
    color: '#fff',
    width: 110,
    '&:hover': {
      backgroundColor: 'rgb(49,108,97)',
    },
  },
  editInsert: {
    position: 'absolute',
    top: 15,
    right: 5,
    height: 'calc(118vh)',
    width: '40%',
    minWidth: 600,
    zIndex: 10,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    zoom: 0.8,
    overflowY: 'scroll',
    '& > div': {
      display: 'flex',
      width: '100%',
      padding: 25,
    },
  },
  detailInsert: {
    width: '36%',
    minWidth: 450,
  },
  confirmTitle: {
    margin: 0,
    color: '#111',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    flexDirection: 'row',
    '& > *:first-child': {
      marginLeft: 0,
      marginRight: 'auto',
      fontSize: '1.4rem',
    },
    '& > *:last-child': {
      marginRight: 0,
      marginLeft: 'auto',
    },
  },
  closeButton: {
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: 8,
    color: '#000',
    '& svg': {
      fontSize: '1.1rem',
    },
  },
  productDetail: {
    borderBottom: '1px solid #ddd',
    margin: '0 auto',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.9rem',
    '& div': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& > div:first-child': {
      color: '#777',
      fontSize: '0.8rem',
    },
    '& > div > *': {
      margin: '0 auto',
      textAlign: 'center',
    },
    '& > div > *:first-child': {
      marginLeft: 0,
      marginRight: 'auto',
      textAlign: 'left',
    },
    '& > div > *:last-child': {
      marginLeft: 'auto',
      marginRight: '0',
      textAlign: 'right',
    },
  },
  supplierDetail: {
    margin: '0 auto',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.9rem',
    '& div': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& p': {
      fontSize: '0.9rem',
    },
    '& > div': {
      borderBottom: '1px solid #ddd',
      padding: '15px 0',
    },
    '& > div:first-child': {
      borderBottom: 'none',
      '& > p': {
        color: '#777',
        fontSize: '0.8rem',
      },
    },
    '& > div > *': {
      margin: '0 auto',
      textAlign: 'center',
    },
    '& > div > *:first-child': {
      marginLeft: 0,
      marginRight: 'auto',
      textAlign: 'left',
    },
    '& > div > *:last-child': {
      marginLeft: 'auto',
      marginRight: '0',
      textAlign: 'right',
    },
  },
  confirmQtyInput: {
    margin: 'auto auto auto 0',
    width: '55%',
  },
  lotInput: {
    margin: 'auto 0 auto auto',
  },
  lotTextInput: {
    height: 48,
    '& *': {
      height: 48,
      fontSize: 18,
      paddingBottom: 0,
    },
  },
  saveDelivery: {
    height: '55px',
    backgroundColor: 'rgb(18,87,77)',
    color: '#fff',
    margin: 25,
    marginTop: 'auto',
    '&:disabled': {
      color: '#aaa',
    },
    '&:hover': {
      backgroundColor: 'rgb(49,108,97)',
    },
  },
  fullWidth: {
    width: '100%',
  },
  columns: {
    flexDirection: 'column',
  },
  signAndConfirm: {
    padding: 25,
  },
  signature: {
    padding: '35px 75px',
    width: 'calc(100% - 50px)',
    maxHeight: 280,
    margin: '0 auto auto auto',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  cprogress: {
    color: '#ddd',
  },
  tooltip: {
    maxWidth: 180,
    fontSize: '0.85rem',
    padding: 15,
    backgroundColor: 'rgb(50,50,50)',
  },
  recCell: {
    textAlign: 'center',
    position: 'relative',
    '& svg': {
      fontSize: '14px',
      color: '#777',
      position: 'absolute',
      right: '30%',
      cursor: 'pointer',
    },
  },
  warningModal: {
    padding: 20,
    '& > *': {
      margin: 'auto',
      width: '100%',
    },
    '& p:last-of-type': {
      color: '#777',
    },
    '& textarea': {
      width: '100%',
      margin: '20px 0',
      minHeight: 120,
      padding: 8,
      outline: 'none',
      border: '2px solid #aaa',
    },
    '& button': {
      backgroundColor: '#fff',
      color: '#111',
      margin: 'auto 0 auto auto',
      width: 75,
    },
    '& button:last-child': {
      backgroundColor: 'rgb(18,87,77)',
      color: '#fff',
      margin: 'auto 0 auto 15px',
    },
  },
  grayHeader: {
    '& *': {
      color: '#777',
      fontSize: '0.8rem',
      fontFamily: 'Messina Sans Regular',
    },
    '& th': {
      borderColor: '#fff0',
    },
  },
  historyRow: {
    '& td': {
      fontSize: '0.9rem',
    },
    '& tr:last-child > td': {
      borderColor: '#fff0',
    },
  },
  deliveryNotice: orderingNotice(theme),
  deliveryNotes: {
    '& div > p': {
      fontSize: '0.9rem',
      fontFamily: 'Messina Sans Regular',
    },
    '& div > p:first-child': {
      fontFamily: 'Roboto',
      marginBottom: 8,
      fontSize: '1rem',
    },
  },
  orderMenu: {
    width: 20,
    height: 20,
    borderRadius: 4,
    backgroundColor: 'rgb(240,240,240)',
    '& svg': {
      fontSize: 20,
      color: '#111',
    },
  },
  orderMenuItem: {
    fontSize: '0.9rem',
  },
  orderMenuOpen: {
    margin: '45px 0 0 -40px',
    boxShadow: 'unset',
    border: '1px solid #ccc',
    borderRadius: 10,
    padding: 12,
  },
  orderDeliveryParentGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0',
    width: '100%',
  },
  orderDeliveryGrid: {
    width: '49.5%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    borderRadius: '14px',
    '& > div': {
      borderRadius: '14px',
      border: '1px solid #ddd',
      padding: '30px 30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '& > div:first-child': {
      marginBottom: 25,
    },
  },
  radioItem: {
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0',
    marginRight: '0',
    borderRadius: '10px',
    border: '1px solid #ddd',
    padding: '5px',
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  shadowedLabel: {
    fontSize: '14px',
    opacity: 0.4,
  },
  labelTitle: {
    fontSize: '15px',
    marginBottom: '10px',
  },
  labelContent: {
    fontSize: '14px',
  },
  labelTitleMid: {
    fontSize: '14px',
    marginTop: '20px',
    marginBottom: '10px',
  },
  deliveryDateInput: {
    margin: 'auto 25px auto 0',
    width: '180px',
    '& *': {
      fontSize: '0rem',
    },
    '&[data-confirmed="true"] *': {
      fontSize: '1rem',
    },
  },
  modalTextField: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  summaryParentContainer: {
    width: '49.5%',
    height: 175,
    maxHeight: 375,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0',
    padding: '30px 30px',
    borderRadius: '14px',
    border: '1px solid #ddd',
  },
  summaryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  detailItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  totalItem: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    marginTop: '16px',
  },
  deliveryAddress: {
    textTransform: 'capitalize',
  },
  orderOverviewHeading: {
    fontSize: 16,
    width: '100%',
    borderBottom: '1px solid #eee',
    textAlign: 'left',
    paddingBottom: 8,
  },
  orderOverview: {
    overflowY: 'scroll',
    '& > div': {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr 1fr',
    },
    '& > div > p': {
      fontSize: 13,
      margin: '10px 0 5px 0',
      paddingBottom: 5,
      borderBottom: '1px solid #eee',
    },
    '& > div:nth-child(2) > p': {
      margin: '20px 0 0 0',
      color: '#777',
      borderBottom: 'none',
    },
  },
  dateCaption: {
    lineHeight: 1.3,
    fontSize: 11,
  },
  legacyDatePicker: {
    '& input': {
      fontSize: 14,
    },
  },
  noOrders: {
    margin: '1rem',
    width: '100%',
    textAlign: 'center',
    marginTop: '3rem',
  },
}));

export const OrderSupplierProductsStyles = (theme: Theme) => ({
  tableContainer: {
    border: '1px solid #E1E1E1',
    backgroundColor: '#fff',
    borderRadius: '10px',
    marginBottom: 12,
    paddingBottom: 25,
  },
  innerTable: {
    padding: '12px',
    marginTop: 5,
  },
  innerTableCellHeader: {
    color: '#000000',
    opacity: '100%',
    fontWeight: 600,
    fontSize: '16px',
    minWidth: 215,
    maxWidth: 215,
  },
  showMoreButton: {
    margin: '20px auto auto auto',
    width: '50%',
  },
  suppliersHelperText: orderingNotice(theme),
});
