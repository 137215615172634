import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Switch, Collapse, TextField } from '@material-ui/core';
import { Edit as EditIcon, Replay as ReplayIcon } from '@material-ui/icons';
// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';
import { dispatch } from '../../../rematch';
import { Button } from '../../common/Button';

const useStyles = makeStyles(() => ({
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    color: '#000000',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

interface Props {
  question: string;
  objectKey: string;
  value: string;
  flag?: boolean;
  flagName?: string;
  placeholder?: string;
  onSaveCondition?: (key: string, text: string) => void;
  alternative?: boolean;
}

export const FreeTextFields = ({
  question,
  objectKey,
  value,
  flag,
  flagName,
  placeholder,
  onSaveCondition = () => {},
  alternative = false,
}: Props) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [text, setText] = useState('');

  useEffect(() => {
    setIsChecked(flag ?? !!value);
    setText(value || '');
  }, []);

  const onEdit = () => {
    if (editMode === false) {
      setIsChecked(flag ?? !!value);
      setText(value);
    }

    setEditMode(!editMode);
  };

  const onSwitchChange = () => {
    setText(isChecked ? '' : value || '');
    setIsChecked(!isChecked);
  };

  const callback = () => {
    setEditMode(true);
  };

  const onSave = () => {
    dispatch({
      type: 'patient/updateMedicalProfileRequest',
      payload: {
        objectKey,
        value: text,
        flag: isChecked,
        flagName,
        callback,
      },
    });
  };

  const isButtonDisabled = (): boolean => (isChecked ? !text.length : (flag ?? !!value) === isChecked);

  if (alternative) {
    return (
      <>
        <div style={{ fontSize: '0.875rem', color: '#3a3b3f', marginBottom: '0.5rem' }}>{question}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            value={text}
            name="userActiveMedicalConditions"
            fullWidth
            label={placeholder}
            variant="outlined"
            onChange={(e) => setText(e.currentTarget.value)}
            multiline
          />
          <Button
            title="Save"
            style={{ minWidth: '100px', color: '#ffffff', backgroundColor: '#12574d' }}
            onClick={() => onSaveCondition(objectKey, text)}
            disabled={text === '' || text === null}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.field}>
          {question}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              label={isChecked ? 'Yes' : 'No'}
              control={<Switch onChange={onSwitchChange} checked={isChecked} disabled={editMode} />}
            />
            {editMode ? <EditIcon onClick={onEdit} /> : <ReplayIcon onClick={onEdit} />}
          </div>
        </div>
        {editMode && <span className={classes.label}>{value}</span>}
      </Grid>
      <Grid item xs={10} md={10}>
        <Collapse in={!editMode && isChecked} timeout="auto">
          <TextField
            value={text}
            name="userActiveMedicalConditions"
            fullWidth
            label={placeholder}
            variant="outlined"
            onChange={(e) => {
              setText(e.currentTarget.value);
            }}
            multiline
          />
        </Collapse>
      </Grid>
      <Grid item xs={2} md={2}>
        <Collapse in={!editMode} timeout="auto">
          <Button
            title="Save"
            style={{ minWidth: '100px', color: '#ffffff', backgroundColor: '#12574d', marginRight: '0' }}
            onClick={onSave}
            disabled={isButtonDisabled()}
          />
        </Collapse>
      </Grid>
    </>
  );
};
