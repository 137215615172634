import React, { useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Table, TableBody, TableContainer } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { debounce } from 'lodash';
import Page from 'src/components/common/Page';
import { MEDSPA_ADMIN_LOCATIONS } from 'src/routes/medspaAdminRoutes';
import EmptyPage from 'src/components/common/EmptyPage';
import { EHRButton } from 'src/components/ui/v1';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { dispatch } from '../rematch';
import TableHeader from '../components/common/Table/TableHeader';
import { IPractitioner } from '../interfaces/IPractitioner';
import { useMedspaPractitioners } from '../hooks/queries/usePractitioners';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import compile from '../utils/toastMessagesCompiler';
import PractitionerHeader from '../components/DashboardAdministrator/Practitioners/PractitionerHeader';
import ProviderRowMedspa from '../components/DashboardAdministrator/Practitioners/ProviderRowMedspa';
import ProviderHeaderMedspa from '../components/DashboardAdministrator/Practitioners/ProviderHeaderMedspa';
import PractitionerRow from '../components/DashboardAdministrator/Practitioners/PractitionerRow';
import { PRACTITIONERS } from '../constants/reactQuery.keys';
import { useCustomRoles } from '../hooks/queries/useCustomRoles';
import { USER_TYPES, MEDSPA_ADMIN_ROLE } from '../constants/general.constants';
import { IRoles } from '../interfaces/IRoles.interfaces';
import { useFeatures } from '../hooks/queries/useFeatures';
import { IFeature } from '../interfaces/IFeature';
import { useUpdateEffect } from '../hooks/useUpdateEffect';
import { UpsertMedspaProviderModal } from '../components/DashboardMedspaAdmin/Practitioners/UpsertMedspaProviderModal';
import { ONBOARDING_STEPS, ONBOARDING_VIDEOS, SHOW_SUCCESS_MESSAGE } from './MedspaAdmin/Onboarding/constants';
import { VideoModal } from './MedspaAdmin/Onboarding/components/VideoModal';
import { VideoBanner } from './MedspaAdmin/Onboarding/components/VideoBanner';

const TABLE_COLUMNS = [
  { id: 'fullname', title: 'Full Name' },
  { id: 'id', title: 'ID' },
  { id: 'offering', title: 'Offering' },
  { id: 'license', title: 'Licenses' },
  { id: 'email', title: 'Email' },
  { id: 'features', title: 'Add-on Features' },
];

const TABLE_COLUMNS_MEDSPA = [
  { id: 'fullname', title: 'Full Name' },
  { id: 'id', title: 'ID' },
  { id: 'license', title: 'Licenses' },
  { id: 'email', title: 'Email' },
];

const INITIAL_FILTERS = {
  includeAll: false,
  search: '',
  roleId: '',
};

const ProvidersMedspaPage = () => {
  const queryClient = useQueryClient();
  const { userGroupId } = useSelector(({ auth }: any) => auth);
  const { roleName } = useSelector(({ auth }: any) => auth);

  const [showAllPractitioners, setShowAllPractitioners] = useState<boolean>(false);
  const [selectedRoleId, setSelectedRoleId] = useState<string>('all');
  const [paramsQuery, setParamsQuery] = useState(INITIAL_FILTERS);
  const [showNewProviderModal, setShowNewProviderModal] = useState(false);
  const [currentProviderId, setCurrentProviderId] = useState(0);

  const [openEhrOnboardingSuccessModal, setOpenEhrOnboardingSuccessModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const { FooterComponent, onboardingEnabled, allStepsCompleted, DoneFooterComponent } = useMedspaAdminOnboarding();
  const updateMedspaAdminOnboardingProgressMutation = useUpdateMedspaAdminOnboardingProgress();

  const isMedspa = roleName === MEDSPA_ADMIN_ROLE;

  const handleEditClick = (providerId: number) => {
    setCurrentProviderId(providerId);
    setShowNewProviderModal(true);
  };

  const endpointBuilder = useMemo(
    () => () => isMedspa ? `/v4/medspa/${userGroupId}/practitioners` : `/v3/practitioners`,
    [roleName, userGroupId]
  );

  const { data: roles = [] as IRoles[], isFetching: isLoadingRole } = useCustomRoles({
    userType: USER_TYPES.PRACTITIONER,
  });
  const { data: features = [], isLoading: isLoadingFeatures } = useFeatures();
  const {
    data: practitioners,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useMedspaPractitioners(paramsQuery, !isMedspa || !!userGroupId, endpointBuilder);

  useUpdateEffect(() => {
    setParamsQuery({
      ...paramsQuery,
      includeAll: showAllPractitioners,
      roleId: selectedRoleId,
    });
  }, [showAllPractitioners, selectedRoleId]);

  useEffect(
    () => () => queryClient.removeQueries({ predicate: (query) => query.queryHash.indexOf(PRACTITIONERS) > -1 }),
    []
  );

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'practitioners',
        }),
        type: 'error',
      },
    });
  }

  const debounceSearch = useMemo(
    () =>
      // @ts-ignore
      debounce((query: string, otherParams) => {
        setParamsQuery({
          ...otherParams,
          search: query,
        });
      }, 1500),
    []
  );

  const onSearch = (event: any) => {
    debounceSearch(event.target.value, paramsQuery);
  };

  const MedspaProviderTableComponent = () => (
    <Box paddingTop="2.5rem">
      <TableContainer>
        <Table>
          <TableHeader columns={TABLE_COLUMNS_MEDSPA} />
          <TableBody>
            {isLoading || isFetching || isLoadingRole || isLoadingFeatures ? (
              <MultipleSkeleton width="80%" />
            ) : (
              practitioners?.map(
                ({ id, profileImageUrl, inactive, firstName, lastName, email, licenses }: IPractitioner) => (
                  <ProviderRowMedspa
                    profileImageUrl={profileImageUrl}
                    inactive={inactive || false}
                    key={id}
                    id={id}
                    fullName={`${firstName} ${lastName}`}
                    email={email}
                    licenses={licenses}
                    handleEditClick={handleEditClick}
                  />
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const PractitionerTableComponent = () => (
    <Box paddingTop="2.5rem">
      <TableContainer>
        <Table>
          <TableHeader columns={TABLE_COLUMNS} />
          <TableBody>
            {isLoading || isFetching || isLoadingRole || isLoadingFeatures ? (
              <MultipleSkeleton width="80%" />
            ) : (
              practitioners?.map(
                ({
                  id,
                  firstName,
                  lastName,
                  email,
                  licenses,
                  roleId,
                  featureSettings,
                  userGroupId: usrGroupId,
                  profileImageUrl,
                }: IPractitioner) => (
                  <PractitionerRow
                    profileImageUrl={profileImageUrl}
                    key={id}
                    id={id}
                    fullName={`${firstName} ${lastName}`}
                    email={email}
                    licenses={licenses}
                    role={roles.find((role) => role.id === roleId)}
                    userGroupId={usrGroupId}
                    features={features}
                    refetch={refetch}
                    enabledFeatures={featureSettings as IFeature[]}
                  />
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const HeaderComponent = isMedspa ? ProviderHeaderMedspa : PractitionerHeader;
  const TableComponent = isMedspa ? MedspaProviderTableComponent : PractitionerTableComponent;

  if (isMedspa && practitioners?.length === 0) {
    return (
      <>
        {onboardingEnabled && (
          <div style={{ padding: '2rem' }}>
            <VideoBanner title="How to add providers to your MedSpa?" onClick={() => setShowVideoModal(true)} />
          </div>
        )}

        <EmptyPage title="Providers">
          <h1>Welcome to Your Providers Management Section!</h1>
          <p>It looks like you haven’t added your first provider yet.</p>
          <p>Click below to add your first provider.</p>
          <Box marginTop="24px">
            <EHRButton
              dataCy="btn-new-provider"
              text="New Provider"
              color="primary"
              onClick={() => setShowNewProviderModal(true)}
            />
          </Box>
          <UpsertMedspaProviderModal
            onClose={() => setShowNewProviderModal(false)}
            open={showNewProviderModal}
            onSuccessCallback={async () => {
              if (onboardingEnabled && !allStepsCompleted) {
                const resp = await updateMedspaAdminOnboardingProgressMutation.mutateAsync({
                  stepName: ONBOARDING_STEPS.PROVIDERS_MANAGEMENT_NAME,
                  percentage: 100,
                });

                if (resp.completed && resp?.message === SHOW_SUCCESS_MESSAGE) {
                  setOpenEhrOnboardingSuccessModal(true);
                }
              }
            }}
          />
        </EmptyPage>

        {isMedspa ? (
          <FooterComponent currentStep="Providers" nextStep="Add Locations" urlToNextStep={MEDSPA_ADMIN_LOCATIONS} />
        ) : null}

        <VideoModal
          open={showVideoModal}
          onClose={() => setShowVideoModal(false)}
          url={ONBOARDING_VIDEOS.PROVIDERS_MANAGEMENT_NAME}
        />
      </>
    );
  }

  return (
    <>
      <Page title="Providers">
        <Box width="100%">
          <HeaderComponent
            inactiveChecked={showAllPractitioners}
            onChangeInactiveCheckbox={() => setShowAllPractitioners((prevState) => !prevState)}
            roles={roles}
            selectedRole={selectedRoleId}
            onSelectRole={(roleId) => setSelectedRoleId(roleId)}
            onSearch={onSearch}
            isLoading={isLoading}
          />

          <Box height="1.25rem" />

          {onboardingEnabled && (
            <VideoBanner title="How to add providers to your MedSpa?" onClick={() => setShowVideoModal(true)} />
          )}
          {isLoading ? <CircularProgress /> : <TableComponent />}
          <UpsertMedspaProviderModal
            practitioner={practitioners?.find((prac) => prac.id === currentProviderId)}
            onClose={() => setShowNewProviderModal(false)}
            open={showNewProviderModal}
          />
        </Box>
      </Page>

      {isMedspa ? (
        <FooterComponent currentStep="Providers" nextStep="Add Locations" urlToNextStep={MEDSPA_ADMIN_LOCATIONS} />
      ) : null}

      {openEhrOnboardingSuccessModal && <DoneFooterComponent />}

      <VideoModal
        open={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        url={ONBOARDING_VIDEOS.PROVIDERS_MANAGEMENT_NAME}
      />
    </>
  );
};

export default ProvidersMedspaPage;
