import { makeStyles } from '@material-ui/core';

export default makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1.5rem',
  },
  timelineContainer: {
    display: 'flex',
    width: '80%',
    justifyContent: 'start',
    alignItems: 'end',
  },
  stepContainer: {
    width: '20%',
    margin: '0 0.5rem',
  },
  stepContainerActive: {
    [theme.breakpoints.down('md')]: {
      width: '28%',
    },
  },
  stepContainerInactive: {
    [theme.breakpoints.down('md')]: {
      width: '18%',
    },
  },
  stepTitle: {
    color: '#12574D',
    fontSize: '12px',
  },
  stepSubtitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '2px',
  },
  stepBarActive: {
    width: '100%',
    background: '#12574D',
    border: 'none',
    height: '8px',
    margin: '2px 0',
  },
  stepBarInactive: {
    width: '100%',
    border: 'none',
    height: '8px',
    background: '#12574D',
    opacity: '30%',
    margin: '2px 0',
  },
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
    height: '47px',
    lineHeight: '47px',
  },
}));
