import React from 'react';
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import { useStyles } from './medicalProfile.styles';
import ConditionalField from './ConditionalFields';
import { FreeTextFields } from './FreeTextFields';

interface Props {
  question: string;
  value: any;
  save: (data: any) => void;
}

const Conditions = (props: Props) => {
  const { question, value: conditions, save } = props;
  const classes = useStyles();

  const onToggleSwitch = (key: string) => {
    if (key === 'noCondition' && !conditions[key]) {
      const newValues = conditions;
      Object.keys(conditions).forEach((objKey) => {
        if (typeof newValues[objKey] === 'boolean') {
          newValues[objKey] = false;
        } else if (typeof newValues[objKey] === 'string') {
          newValues[objKey] = '';
        } else {
          newValues[objKey] = [];
        }
      });
      save({ conditions: { ...newValues, [key]: true } });
    } else {
      save({ conditions: { ...conditions, [key]: !conditions[key], noCondition: false } });
    }
  };

  const onToggleSwitchMelasmaOrVitiligo = (key: string) => {
    let newArray = [];
    if (conditions.melasmaOrVitiligoConditions.includes(key)) {
      newArray = conditions.melasmaOrVitiligoConditions.filter((condition: string) => condition !== key);
    } else {
      newArray = [...conditions.melasmaOrVitiligoConditions, key];
    }
    save({
      conditions: {
        ...conditions,
        melasmaOrVitiligo: newArray.length > 0,
        melasmaOrVitiligoConditions: newArray,
        noCondition: false,
      },
    });
  };

  const onToggleSwitchAllergiesConditions = (key: string) => {
    let newArray = [];
    if (conditions.allergiesConditions.includes(key)) {
      newArray = conditions.allergiesConditions.filter((condition: string) => condition !== key);
    } else {
      newArray = [...conditions.allergiesConditions, key];
    }
    save({
      conditions: {
        ...conditions,
        allergyEggMilkAlbuminBees: newArray.length > 0,
        allergiesConditions: newArray,
        noCondition: false,
      },
    });
  };

  const setText = (key: string, text: string) => {
    save({ conditions: { ...conditions, [key]: text } });
  };

  const isChecked = (key: string): boolean => {
    if (conditions[key] === 'no') {
      return false;
    }
    if (conditions[key] === 'yes') {
      return true;
    }
    return conditions[key] || false;
  };

  const isCheckedMelasmaOrVitiligo = (key: string): boolean => conditions.melasmaOrVitiligoConditions?.includes(key);
  const isCheckedAllergiesConditions = (key: string): boolean => conditions.allergiesConditions?.includes(key);

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.title}>{question}</div>
      </Grid>

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="autoimmuneDisease"
        label="Autoimmune disease (ex: lupus, rheumatoid arthritis, thyroid disease)"
        collapsedField="checkbox"
        collapsedLabel="Is your autoimmune disease currently stable/controlled?"
        collapsedFieldName="autoimmuneDiseaseControlled"
        collapsedFieldValue={conditions.autoimmuneDiseaseControlled}
        extraForm={
          <FreeTextFields
            question="Which autoimmune disease?"
            objectKey="autoimmuneDiseaseDiagnose"
            value={conditions?.autoimmuneDiseaseDiagnose}
            flagName="autoimmuneDiseaseDiagnose"
            onSaveCondition={setText}
            alternative
          />
        }
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="diabetes"
        label="Diabetes"
        collapsedField="checkbox"
        collapsedLabel="Is your blood sugar currently stable/controlled?"
        collapsedFieldName="diabetesControlled"
        collapsedFieldValue={conditions.diabetesControlled}
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="neurologicOrNeuromuscular"
        label="Neurologic or neuromuscular disease (myasthenia gravis, Bell’s palsy, stroke)"
        collapsedField="checkbox"
        collapsedLabel="Is your neurologic disease currently stable/controlled?"
        collapsedFieldName="neurologicOrNeuromuscularControlled"
        collapsedFieldValue={conditions.neurologicOrNeuromuscularControlled}
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="bleedingOrClotting"
        label="Bleeding or clotting problems"
        collapsedField="text"
        collapsedFieldName="bleedingComments"
        collapsedFieldValue={conditions.bleedingComments}
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="immuneDeficiency"
        label="Immune deficiency (ex: organ transplant, diabetes, HIV)"
        collapsedField="text"
        collapsedFieldName="immuneComments"
        collapsedFieldValue={conditions.immuneComments}
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="cancer"
        label="Cancer"
        collapsedField="text"
        collapsedFieldName="cancerType"
        collapsedFieldValue={conditions.cancerType}
        extraForm={
          <div className={classes.subField}>
            Are you currently receiving treatment?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                label={isChecked('cancerCurrentlyReceivingTreatment') ? 'Yes' : 'No'}
                control={
                  <Switch
                    checked={conditions.cancerCurrentlyReceivingTreatment === 'yes'}
                    onChange={() =>
                      setText(
                        'cancerCurrentlyReceivingTreatment',
                        conditions.cancerCurrentlyReceivingTreatment === 'yes' ? 'no' : 'yes'
                      )
                    }
                  />
                }
              />
            </div>
          </div>
        }
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="heartDisease"
        label="Heart disease"
        collapsedField="text"
        collapsedFieldName="heartDiseaseComments"
        collapsedFieldValue={conditions.heartDiseaseComments}
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="anaphylaxisOrAngioedema"
        label="Anaphylaxis or angioedema"
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="coldSores"
        label="Cold sores"
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="keloidsOrSarcoidosis"
        label="Keloids or sarcoidosis"
        setText={setText}
      />

      <ConditionalField
        isChecked={isCheckedMelasmaOrVitiligo}
        onToggleSwitch={onToggleSwitchMelasmaOrVitiligo}
        name="melasma"
        label="Melasma"
        setText={setText}
      />

      <ConditionalField
        isChecked={isCheckedMelasmaOrVitiligo}
        onToggleSwitch={onToggleSwitchMelasmaOrVitiligo}
        name="vitiligo"
        label="Vitiligo"
        setText={setText}
      />

      <ConditionalField
        isChecked={isCheckedAllergiesConditions}
        onToggleSwitch={onToggleSwitchAllergiesConditions}
        name="egg"
        label="Allergy to egg"
        setText={setText}
      />
      <ConditionalField
        isChecked={isCheckedAllergiesConditions}
        onToggleSwitch={onToggleSwitchAllergiesConditions}
        name="milk"
        label="Allergy to milk"
        setText={setText}
      />
      <ConditionalField
        isChecked={isCheckedAllergiesConditions}
        onToggleSwitch={onToggleSwitchAllergiesConditions}
        name="albumin"
        label="Allergy to albumin"
        setText={setText}
      />
      <ConditionalField
        isChecked={isCheckedAllergiesConditions}
        onToggleSwitch={onToggleSwitchAllergiesConditions}
        name="bees"
        label="Allergy to bees"
        setText={setText}
      />

      <ConditionalField
        isChecked={isChecked}
        onToggleSwitch={onToggleSwitch}
        name="noCondition"
        label="None of the above"
        setText={setText}
      />
    </>
  );
};

export default Conditions;
