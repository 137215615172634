/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable curly */
import { useEffect, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { getBandwidthConfig, saveNetworkData } from '../services/Bandwidth';
import { BandwidthConfig } from '../interfaces/bandwidth.interface';

const CONNECTION_MESSAGES = {
  online: 'You are back online.',
  lowConnection: 'Your internet connection is low.',
  offline: 'Connection lost.',
};

const BandwidthMeter = () => {
  const wasOnline = useRef(navigator.onLine);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const { mutate: saveNetworkDataMutation } = useMutation({ mutationFn: saveNetworkData });
  const { data } = useQuery<BandwidthConfig>(['bandwidthConfig'], getBandwidthConfig, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { isAuthenticate } = useSelector(({ auth }: any) => auth);

  useEffect(() => {
    const notifyUser = () => {
      if (!data) return;
      const { threshold, timeout } = data;
      const { onLine } = navigator;
      if (onLine) {
        if (!wasOnline.current) {
          toast.success(CONNECTION_MESSAGES.online, {
            toastId: 'connectionStablished',
          });
          wasOnline.current = true;
        }
      } else {
        toast.error(CONNECTION_MESSAGES.offline, {
          toastId: 'connectionLost',
        });
        wasOnline.current = false;
      }

      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        const { connection, userAgent } = navigator;
        if (!threshold || !connection || connection.downlink === undefined) return;
        const { downlink, effectiveType } = connection;

        if (downlink < threshold) {
          toast.warn(CONNECTION_MESSAGES.lowConnection, {
            toastId: 'connectionLow',
          });
          if (isAuthenticate) {
            saveNetworkDataMutation({
              bandwidth: downlink,
              connectionType: effectiveType || 'unknown',
              userAgent,
            });
          }
        }
      }, timeout * 1000);
    };

    navigator.connection?.addEventListener('change', notifyUser);

    return () => {
      navigator.connection?.removeEventListener('change', notifyUser);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [data, isAuthenticate]);

  return null;
};

export default BandwidthMeter;
