import React from 'react';
import {
  Box,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { PRACTITIONER_BASE_PATH } from 'src/routes/practitionerRoutes';
import { useDocuments } from 'src/hooks/queries/useDocuments';
import { TableCellHeader } from 'src/components/common/TableStyles/TableCellHeader';
import { TableCellCustom } from 'src/components/common/TableStyles/TableCellCustom';
import { IDocument } from 'src/interfaces/documents.interface';
import { formatLocalDateTime } from 'src/utils/formatDate';
import { ReactComponent as DocumentIcon } from '../assets/images/document.svg';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';

const useStyles = makeStyles(() => ({
  tableRow: {
    height: '60px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  flex_class: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
}));

const Documents = () => {
  const { data: documents = [], isFetching } = useDocuments({});

  const history = useHistory();
  const classes = useStyles();

  const navigate = () => {
    history.push(PRACTITIONER_BASE_PATH);
  };

  const handleCellClick = (document: IDocument) => {
    const newTab = window.open(document.documentPdfUrl, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  const sortCallback = (i: IDocument, j: IDocument) => {
    const iDateString = new Date(i.createdAt).toLocaleDateString();
    const jDateString = new Date(j.createdAt).toLocaleDateString();
    const sameDate = jDateString === iDateString;

    if (!sameDate) {
      return iDateString > jDateString ? -1 : 1;
    }
    return i.name.localeCompare(j.name);
  };

  return (
    <div>
      <NavigationBar title="Documents" onBackButtonClick={navigate} />
      <Box width="80%" margin="auto">
        <Box margin="30px 0 30px 0" display="flex" alignItems="center" justifyContent="space-between">
          <h1>Documents</h1>
        </Box>
        <TableContainer>
          {isFetching ? (
            <div className={classes.flex_class} style={{ justifyContent: 'center' }}>
              <CircularProgress style={{ color: 'black' }} size={30} />
              <p>Fetching your documents</p>
            </div>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHeader>Document ID</TableCellHeader>
                  <TableCellHeader>Document Name</TableCellHeader>
                  <TableCellHeader>Uploaded on</TableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {(documents as IDocument[])?.sort(sortCallback).map((document: IDocument) => {
                  const { day, month: uploadedMonth, year } = formatLocalDateTime(document.createdAt);
                  return (
                    <TableRow className={classes.tableRow} key={document.id} onClick={() => handleCellClick(document)}>
                      <TableCellCustom>
                        <div className={classes.flex_class}>
                          <DocumentIcon />
                          {document.id}
                        </div>
                      </TableCellCustom>
                      <TableCellCustom>{document.name}</TableCellCustom>
                      <TableCellCustom>{` ${uploadedMonth} ${day}, ${year}`}</TableCellCustom>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Box>
    </div>
  );
};

export default Documents;
