import { Button } from '@material-ui/core';
import React from 'react';

type Props = {
  title: string;
  onClick: () => void;
};

export const VideoBanner = ({ title, onClick }: Props) => (
  <div
    style={{
      backgroundColor: '#DBEEEB',
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      borderRadius: '6px',
      padding: '0 1rem',
      color: '#000',
    }}
  >
    <p>{title}</p>
    <Button
      style={{
        textDecoration: 'underline',
        cursor: 'pointer',
        margin: '0',
        textTransform: 'none',
        color: '#000',
      }}
      onClick={onClick}
    >
      Watch the video
    </Button>
  </div>
);
