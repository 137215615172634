import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStyles } from './scanProducts.styles';
import { ProductsTable } from './ProductsTable';
import { WizardFooter } from './WizardFooter';
import { RootState, dispatch } from '../../../rematch';
import { WizardHeader } from './WizardHeader';
import Scanner from '../../common/Scanner';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { getProductIdentifiersData } from '../../../utils/inventory.utils';
import { ReactComponent as QRCode } from '../../../assets/images/qr-code.svg';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import { RemainingUnitsModal } from '../../common/RemainingUnitsModal/RemainingUnitsModal';
import compile from '../../../utils/toastMessagesCompiler';
import { PRACTITIONER_RECONCILIATION_PATH } from '../../../routes/practitionerRoutes';
import { RECONCILIATION_HEADERS_WITH_DEDUCTIONS } from '../../../constants/inventory.constants';

const defaultModalData = { name: '', internalSerialNumber: '', currentNumberOfUnits: 0, initialNumberOfUnits: 0 };

const NewReport = () => {
  const history = useHistory();
  const classes = useStyles();
  const scannerInputRef = useRef<HTMLInputElement>(null);
  const inventoryPractitioner = useSelector((store: any) => store.inventoryPractitioner);
  const { scannedAssets, productList, remainingAssets, isLoading } = inventoryPractitioner;
  const { userId } = useSelector(({ auth }: RootState) => auth);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(defaultModalData);

  useEffect(() => {
    if (!productList.length && userId) {
      dispatch({
        type: 'inventoryPractitioner/fetchDraftReport',
        payload: { practitionerId: userId },
      });
    }
  }, [userId]);

  const focusScan = () => {
    setTimeout(() => {
      if (scannerInputRef && scannerInputRef.current) {
        scannerInputRef.current.focus();
      }
    }, 100);
  };

  useEffect(() => {
    focusScan();
  }, [remainingAssets, scannedAssets]);

  const cancel = (): void => {
    history.push(PRACTITIONER_RECONCILIATION_PATH);
  };

  const nextStep = (): void => {
    history.push('/inventory/product-summary');
  };

  const handleAssetChecked = (asset: IAsset) => {
    const serialNumber = asset.internalSerialNumber;

    if (scannedAssets.includes(serialNumber)) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('dashboard_practitioner.asset_already_scanned'),
          type: 'warning',
          duration: 4000,
        },
      });
    }

    if (asset.allowPartialSale) {
      setModalData(asset);
      setIsModalOpen(true);
      return;
    }

    dispatch({
      type: 'inventoryPractitioner/addItem',
      payload: { serialNumber, serviceId: asset.serviceId },
    });
  };

  const navigateProductIdentifiers = ({ serviceId }: any): void => {
    const assets = remainingAssets.filter((asset: IAsset) => asset.serviceId === serviceId);

    assets.forEach((asset: any) => {
      asset.units = asset.manuallyCountedUnits; // eslint-disable-line no-param-reassign
      asset.counted = asset.manuallyCountedUnits || asset.counted || 0; // eslint-disable-line no-param-reassign
    });

    dispatch({
      type: 'productIdentifiers/setProductIdentifiersData',
      payload: { ...getProductIdentifiersData(assets), aggregatedField: 'counted' },
    });

    history.push('/product-identifiers');
  };

  const confirmUnits = (asset: IAsset, remainingUnits: number): void => {
    dispatch({
      type: 'inventoryPractitioner/addItem',
      payload: {
        serialNumber: asset.internalSerialNumber,
        serviceId: asset.serviceId,
        units: remainingUnits,
      },
    });

    setIsModalOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <MultipleSkeleton />
      ) : (
        <article>
          <div className={classes.content}>
            <div className={classes.header}>
              <div className={classes.legend}>
                <WizardHeader title="Scan Instructions" />
                <p className={classes.paragraph}>
                  Scan every unused product you have in your inventory with your scanner. As you scan them, your
                  expected remaining products remaining will decrease. If you do not have a product the system expects
                  you to have in your inventory, you will be able to add and explanation in the next step.
                </p>
              </div>
              <div className={classes.scan}>
                <QRCode />
              </div>
            </div>
            <div style={{ padding: '30px' }}>
              <Scanner
                successCallback={handleAssetChecked}
                inAssets={remainingAssets}
                inAssetsError="dashboard_practitioner.asset_doesnt_belong_remainig_asset"
                scannerInputRef={scannerInputRef}
              />
            </div>
            <ProductsTable
              header={RECONCILIATION_HEADERS_WITH_DEDUCTIONS}
              body={productList}
              iconCallback={navigateProductIdentifiers}
            />
          </div>
          <WizardFooter
            primaryButtonText="NEXT"
            secondaryButtonText="CANCEL"
            step="1/3"
            primaryCallback={nextStep}
            secondaryCallback={cancel}
          />
          <RemainingUnitsModal
            open={isModalOpen}
            closeModal={() => {
              setIsModalOpen(false);
            }}
            confirmUnits={confirmUnits}
            asset={modalData}
            primaryText={`Does this bottle include <b>${modalData.currentNumberOfUnits}</b> units?`}
            secondaryText="Units Remaining"
            secondaryButtonLabel="No"
            isReconciliation
          />
        </article>
      )}
    </>
  );
};

export default NewReport;
