import { makeStyles } from '@material-ui/core';

export const modalStyles = makeStyles(() => ({
  infiScrollContainer: {
    overflow: 'auto',
    height: '84.7vh',
    width: '100%',
    marginTop: '10px',
  },
  confirmDialog: {
    display: 'flex',
    flexDirection: 'column',
    padding: '36px',
    alignItems: 'center',
    gap: '1rem',
    minHeight: '254px',
    borderRadius: '12px',
    justifyContent: 'space-between',
  },
  confirmContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  },
  confirmButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    width: '100%',
    justifyContent: 'end',
  },
  smallButtons: {
    paddingTop: '10px !important',
    '& button': {
      minWidth: '80px !important',
    },
  },
  actionButton: {
    textTransform: 'none',
    fontSize: 14,
    minWidth: 132,
    height: 'auto',
    borderColor: '#303030',
    color: '#000',
    backgroundColor: 'rgba(51,126,137,0)',

    '&:last-child': {
      backgroundColor: 'rgba(29, 88, 77, 1)', // #1D584D
      color: '#fff',
      margin: 'auto 0',
      padding: '8px 18px',
      '&:disabled': {
        backgroundColor: 'rgba(29,88,77,0.65)',
      },
    },
  },
  rejectButton: {
    backgroundColor: '#E63E3E !important',
    borderColor: '#E63E3E !important',
  },
  orderNotes: {
    width: '375px',
    display: 'flex',
    flexDirection: 'row',
    '& textarea , p': {
      width: '100%',
      margin: 0,
      resize: 'none',
      borderRadius: '6px',
      minHeight: 180,
      padding: '16px',
      fontFamily: 'Messina Sans Regular',
      border: '1px solid #C8C8C8',
    },
    '& input , p': {
      width: '100%',
      margin: 0,
      resize: 'none',
      borderRadius: '6px',
      padding: '16px',
      fontFamily: 'Messina Sans Regular',
      border: '1px solid #C8C8C8',
    },
  },
  dialogForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  trackingDialogForm: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '1rem',
  },
  subheading: {
    color: '#888',
    fontSize: 13,
    width: '100%',
    margin: 0,
    padding: 0,
  },
}));
