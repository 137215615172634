import React from 'react';
import { TableContainer, Paper, Table, TableBody, makeStyles } from '@material-ui/core';
import { InfiniteData } from 'react-query';
import InfiScroller from 'react-infi-scroller';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import RowItem from './RowItem';
import { ICustomersResponse } from '../../../interfaces/ICustomer';
import TableHeader from '../../common/Table/TableHeader';
import Message from '../../common/Message/Message';

import SortDirection from '../../../types/SortDirection';

type TableCustomerProps = {
  data: InfiniteData<ICustomersResponse> | undefined;
  scrollerMain: any;
  hasNextPage: any;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  errorMessage: string;
  fetchNextPage: any;
  sortBy: string;
  sortDirection?: SortDirection;
  onChangeSortBy: (by: string, direction: SortDirection) => void;
  state: any;
};

type TableCustomerResultsProps = {
  data: any | undefined;
  isError: boolean;
  errorMessage: string;
  state?: any;
};

const useStyles = makeStyles(() => ({
  tableGeneral: {
    position: 'relative',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'hidden',
  },
  name: {
    paddingLeft: 20,
  },
  bodyContainer: {
    position: 'relative',
  },
}));

const TABLE_COLUMNS = [
  { id: 'first_name', title: 'Name', sort: true },
  { id: 'id', title: 'ID' },
  { id: 'email', title: 'Email' },
  { id: 'created_at', title: 'Created', sort: true },
  { id: 'service_visits', title: 'Visits', sort: true },
  { id: 'updates', title: 'Updates' },
];

const TableResults = ({ data, isError, errorMessage = '', state }: TableCustomerResultsProps) => {
  if (isError) {
    return <Message text={errorMessage} />;
  }

  if (!data) {
    return <></>;
  }

  return data.length > 0 ? (
    <>
      {data.map((row: any) => (
        <RowItem key={`customer_${row.id}`} row={{ ...row }} state={state} />
      ))}
    </>
  ) : (
    <Message text="No data to show" colSize={12} />
  );
};

const TableCustomer = ({
  data,
  sortDirection,
  sortBy,
  fetchNextPage,
  hasNextPage,
  isLoading,
  isFetching,
  isError,
  errorMessage,
  scrollerMain,
  onChangeSortBy,
  state,
}: TableCustomerProps) => {
  const classes = useStyles();

  const allCustomers = data?.pages?.map(({ customers = [] }) => customers).flat();

  return (
    <div className={classes.tableGeneral}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="collapsible table">
          <TableHeader
            columns={TABLE_COLUMNS}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSortChange={onChangeSortBy}
          />
          <TableBody>
            <InfiScroller
              active={hasNextPage}
              scrollTarget={scrollerMain?.current}
              hasMore={hasNextPage}
              gutter={100}
              shouldLoadMore={(scrollTargetHeight: any, scrollYOffset: any, gutter: any, scrollHeight: any) => {
                const shouldLoadMore: any = scrollTargetHeight + scrollYOffset + gutter >= scrollHeight;
                return shouldLoadMore;
              }}
              onLoadMore={() => {
                fetchNextPage();
              }}
            >
              {isLoading ? (
                <tr>
                  <td>
                    <MultipleSkeleton />
                  </td>
                </tr>
              ) : (
                <>
                  <TableResults data={allCustomers} isError={isError} errorMessage={errorMessage} state={state} />
                  {isFetching && <MultipleSkeleton />}
                </>
              )}
            </InfiScroller>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableCustomer;
