import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { ILocation, IMedspaLocation } from 'src/interfaces/ILocation';

const DeliveryAddress = ({
  location,
  className,
  singleLine,
}: {
  location?: IMedspaLocation | ILocation | null;
  className?: string;
  singleLine?: boolean;
}): ReactElement | null => {
  const isMedspaLocation = location && 'address' in location;

  const address = isMedspaLocation ? location.address : location?.customAddress ?? '';
  const zipCode = isMedspaLocation ? location.zipCode : location?.customAddress?.split(' ').slice(-1)[0];

  return !location || address.length < 1 ? (
    <Typography component="p" className={className}>
      Deliveries will be sent to the address specified in your Account Settings
    </Typography>
  ) : (
    <Typography component="p" className={className} style={{ textTransform: 'capitalize' }}>
      {address.toLowerCase().split(`, ${location.city?.toLowerCase() ?? ''}`)[0]}
      {singleLine ? ' - ' : <br />}
      {location.city}, {location.state} {zipCode}
    </Typography>
  );
};

export { DeliveryAddress, DeliveryAddress as default };
