import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProductsTable } from './ProductsTable';
import { WizardFooter } from './WizardFooter';
import { useStyles } from './productsSummary.styles';
import { RootState, dispatch } from '../../../rematch';
import { WizardHeader } from './WizardHeader';
import { getProductIdentifiersData } from '../../../utils/inventory.utils';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { RECONCILIATION_HEADERS_WITH_DEDUCTIONS } from '../../../constants/inventory.constants';

const ProductsSummary = () => {
  const history = useHistory();
  const classes = useStyles();
  const inventoryPractitioner = useSelector((store: any) => store.inventoryPractitioner);
  const { productList, remainingAssets, isLoading } = inventoryPractitioner;
  const { userId } = useSelector(({ auth }: RootState) => auth);

  useEffect(() => {
    dispatch.common.fetchServices();
    if (!productList.length && userId) {
      dispatch({
        type: 'inventoryPractitioner/fetchDraftReport',
        payload: { practitionerId: userId },
      });
    }
  }, [userId]);

  const cancel = () => {
    history.replace('/inventory/reconciliation');
  };

  const nextStep = () => {
    history.push('/inventory/finalize-reconciliation');
  };

  const navigateProductIdentifiers = ({ serviceId }: any) => {
    const assets = remainingAssets.filter((asset: IAsset) => asset.serviceId === serviceId);

    assets.forEach((asset: any) => {
      asset.units = asset.manuallyCountedUnits; // eslint-disable-line no-param-reassign
      asset.counted = asset.manuallyCountedUnits || asset.counted || 0; // eslint-disable-line no-param-reassign
    });

    dispatch({
      type: 'productIdentifiers/setProductIdentifiersData',
      payload: { ...getProductIdentifiersData(assets), aggregatedField: 'currentNumberOfUnits' },
    });

    history.push('/product-identifiers');
  };

  return (
    <>
      {isLoading ? (
        <MultipleSkeleton />
      ) : (
        <article>
          <div className={classes.content}>
            <WizardHeader title="Summary" />
            <ProductsTable
              header={RECONCILIATION_HEADERS_WITH_DEDUCTIONS}
              body={productList}
              iconCallback={navigateProductIdentifiers}
              fullheight
            />
          </div>
          <WizardFooter
            primaryButtonText="NEXT"
            secondaryButtonText="SCAN MORE PRODUCTS"
            step="2/3"
            primaryCallback={nextStep}
            secondaryCallback={cancel}
          />
        </article>
      )}
    </>
  );
};

export default ProductsSummary;
