import React, { useEffect, useRef, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import { EHRCheckbox } from 'src/components/ui/v1';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { useAcknowledgeInventoryDisclaimer } from 'src/hooks/queries/useAccountSettings';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';

interface InventoryOrderingAgreementProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus, value?: any) => void;
  hasAcknowledgedInventoryDisclaimer: boolean;
  progressPercentage?: number;
}

const useStyles = makeStyles({
  agreementText: {
    width: '100%',
    margin: '15px 0',
    padding: 0,
    '& p': {
      fontSize: '12px !important',
    },
  },
  loading: {
    opacity: 0.7,
  },
});

const InventoryOrderingAgreement = ({
  stepId,
  onStepUpdate,
  progressPercentage,
  hasAcknowledgedInventoryDisclaimer = false,
}: InventoryOrderingAgreementProps) => {
  const classes = useStyles();
  const [radioState, setRadioState] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { mutateAsync: acknowledge, isError, isLoading, isSuccess } = useAcknowledgeInventoryDisclaimer();
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();
  const prevState = useRef(hasAcknowledgedInventoryDisclaimer);

  const handleRadioClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasAcknowledgedInventoryDisclaimer && event.target.checked) {
      setIsUpdating(true);
      try {
        await acknowledge();
      } finally {
        setIsUpdating(false);
      }
    }
  };

  useEffect(() => {
    setRadioState(hasAcknowledgedInventoryDisclaimer);
    onStepUpdate(stepId, hasAcknowledgedInventoryDisclaimer ? STEP_COMPLETED : STEP_EMPTY);
  }, [hasAcknowledgedInventoryDisclaimer, isSuccess]);

  useEffect(() => {
    if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isError) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [isLoading, isError]);

  useEffect(() => {
    if (prevState.current === null && hasAcknowledgedInventoryDisclaimer) {
      updateProgress({
        stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
        percentage: progressPercentage as number,
      });
    }

    prevState.current = hasAcknowledgedInventoryDisclaimer;
  }, [hasAcknowledgedInventoryDisclaimer]);

  return (
    <Box>
      <EHRCheckbox
        dataCy="hasAcknowledgedInventoryDisclaimer-checkbox"
        label="I agree"
        onChange={handleRadioClick}
        disabled={hasAcknowledgedInventoryDisclaimer || isUpdating}
        checked={radioState}
        className={isUpdating ? classes.loading : undefined}
      />
    </Box>
  );
};

export default InventoryOrderingAgreement;
