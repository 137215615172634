import { useEffect } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

import {
  CHECKOUT_TRANSACTIONS,
  CHECKOUT_TRANSACTIONS_COUNT,
  CHECKOUT_TRANSACTIONS_LINE_ITEMS,
  CHECKOUT_TRANSACTIONS_LINE_ITEMS_COUNT,
  CHECKOUT_TRANSACTIONS_DISCOUNT_COUNT,
  CHECKOUT_TRANSACTIONS_DISCOUNTS,
  CHECKOUT_TRANSACTIONS_TENDER_COUNT,
  CHECKOUT_TRANSACTIONS_TENDERS,
  CHECKOUT_TRANSACTIONS_ASSETS_SOLD_COUNT,
  CHECKOUT_TRANSACTIONS_ASSETS_SOLD,
  CHECKOUT_TRANSACTIONS_DETAILS,
} from '../../constants/reactQuery.keys';
import CheckoutTransaction from '../../services/CheckoutTransaction';
import { DEFAULT_LIMIT } from '../../constants/general.constants';
import { useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';

export function useCheckoutTransactions(paramsQuery: any, total: number = 0) {
  const limit = paramsQuery?.limit || DEFAULT_LIMIT;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS, paramsQuery]);
  }, [paramsQuery]);

  const query = useInfiniteQuery<any>(
    [CHECKOUT_TRANSACTIONS, paramsQuery],
    ({ pageParam = 1 }) => CheckoutTransaction.getTransactions({ ...paramsQuery, page: pageParam }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * limit < total : lastPage.length === limit;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const transactions =
    query?.data?.pages
      ?.map((items) => items)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, transactions, currentPage: query?.data?.pages.length } };
}

export function useCheckoutTransactionsCount(paramsQuery: any) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS_COUNT, paramsQuery]);
  }, [paramsQuery]);

  return useQuery(
    [CHECKOUT_TRANSACTIONS_COUNT, paramsQuery],
    () => CheckoutTransaction.getTransactionsCount(paramsQuery),
    {
      initialData: 0,
      refetchOnWindowFocus: false,
      retry: 1,
      keepPreviousData: true,
    }
  );
}

export function useCheckoutTransactionsLineItems(paramsQuery: any, total: number = 0) {
  const limit = paramsQuery?.limit || DEFAULT_LIMIT;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS_LINE_ITEMS, paramsQuery]);
  }, [paramsQuery]);

  const query = useInfiniteQuery<any>(
    [CHECKOUT_TRANSACTIONS_LINE_ITEMS, paramsQuery],
    ({ pageParam = 1 }) => CheckoutTransaction.getTransactionsLineItems({ ...paramsQuery, page: pageParam }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * limit < total : lastPage.length === limit;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const lineItems =
    query?.data?.pages
      ?.map((items) => items)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, lineItems, currentPage: query?.data?.pages.length } };
}

export function useCheckoutLineItemsCount(paramsQuery: any) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS_LINE_ITEMS_COUNT, paramsQuery]);
  }, [paramsQuery]);

  return useQuery(
    [CHECKOUT_TRANSACTIONS_LINE_ITEMS_COUNT, paramsQuery],
    () => CheckoutTransaction.getTransactionsLineItemsCount(paramsQuery),
    {
      initialData: 0,
      refetchOnWindowFocus: false,
      retry: 1,
      keepPreviousData: true,
    }
  );
}

export function useDiscountCount(paramsQuery: any) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS_DISCOUNT_COUNT, paramsQuery]);
  }, [paramsQuery]);

  return useQuery(
    [CHECKOUT_TRANSACTIONS_DISCOUNT_COUNT, paramsQuery],
    () => CheckoutTransaction.getTransactionsDiscountCount(paramsQuery),
    {
      initialData: 0,
      refetchOnWindowFocus: false,
      retry: 1,
      keepPreviousData: true,
    }
  );
}

export function useCheckoutTransactionsDiscounts(paramsQuery: any, total: number = 0) {
  const limit = paramsQuery?.limit || DEFAULT_LIMIT;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS_DISCOUNTS, paramsQuery]);
  }, [paramsQuery]);

  const query = useInfiniteQuery<any>(
    [CHECKOUT_TRANSACTIONS_DISCOUNTS, paramsQuery],
    ({ pageParam = 1 }) => CheckoutTransaction.getTransactionsDiscount({ ...paramsQuery, page: pageParam }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * limit < total : lastPage.length === limit;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const discounts =
    query?.data?.pages
      ?.map((items) => items)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, discounts, currentPage: query?.data?.pages.length } };
}

export function useCheckoutTenderCount(paramsQuery: any) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS_TENDER_COUNT, paramsQuery]);
  }, [paramsQuery]);

  return useQuery(
    [CHECKOUT_TRANSACTIONS_TENDER_COUNT, paramsQuery],
    () => CheckoutTransaction.getTransactionsTenderCount(paramsQuery),
    {
      initialData: 0,
      refetchOnWindowFocus: false,
      retry: 1,
      keepPreviousData: true,
    }
  );
}

export function useCheckoutTransactionsTenders(paramsQuery: any, total: number = 0) {
  const limit = paramsQuery?.limit || DEFAULT_LIMIT;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS_TENDERS, paramsQuery]);
  }, [paramsQuery]);

  const query = useInfiniteQuery<any>(
    [CHECKOUT_TRANSACTIONS_TENDERS, paramsQuery],
    ({ pageParam = 1 }) => CheckoutTransaction.getTransactionsTenders({ ...paramsQuery, page: pageParam }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * limit < total : lastPage.length === limit;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const tenders =
    query?.data?.pages
      ?.map((items) => items)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, tenders, currentPage: query?.data?.pages.length } };
}

export function useCheckoutAssetsSoldCount(paramsQuery: any) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS_ASSETS_SOLD_COUNT, paramsQuery]);
  }, [paramsQuery]);

  return useQuery(
    [CHECKOUT_TRANSACTIONS_ASSETS_SOLD_COUNT, paramsQuery],
    () => CheckoutTransaction.getTransactionsAssetsSoldCount(paramsQuery),
    {
      initialData: 0,
      refetchOnWindowFocus: false,
      retry: 1,
      keepPreviousData: true,
    }
  );
}

export function useCheckoutTransactionsAssetsSold(paramsQuery: any, total: number = 0) {
  const limit = paramsQuery?.limit || DEFAULT_LIMIT;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CHECKOUT_TRANSACTIONS_ASSETS_SOLD, paramsQuery]);
  }, [paramsQuery]);

  const query = useInfiniteQuery<any>(
    [CHECKOUT_TRANSACTIONS_ASSETS_SOLD, paramsQuery],
    ({ pageParam = 1 }) => CheckoutTransaction.getTransactionsAssetsSold({ ...paramsQuery, page: pageParam }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * limit < total : lastPage.length === limit;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const assetsSold =
    query?.data?.pages
      ?.map((items) => items)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, assetsSold, currentPage: query?.data?.pages.length } };
}

/* List of all checkout transactions per checkout id
 * Params: id (checkout id)
 */
export const useCheckoutTransactionDetails = (id: number) => {
  const response = useQuery(
    [CHECKOUT_TRANSACTIONS_DETAILS, id],
    () => CheckoutTransaction.getCheckoutTransactionsDetails(id),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: () =>
        useReactQueryOnErrorHandler(
          compile('generic.error_message', { action: 'fetching', element: 'checkout transactions' })
        ),
    }
  );

  return {
    ...response,
    data: response.data || [],
  };
};
