import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { IRedeemedItem } from 'src/types/Referrals';
import { useStyles } from './referralSummary.styles';
import { getRedeemableAmount, getReferredTotalAmount } from '../../../utils/checkout.utils';
import { ROUTES } from '../../../constants/routes.constants';

interface ReferralsSummaryProps {
  notRedeemed: IRedeemedItem[];
  redeemed: IRedeemedItem[];
  isAdvocate: boolean | null;
  hasReferred: boolean | null;
}

const ReferralsSummary = ({ notRedeemed, redeemed, isAdvocate, hasReferred }: ReferralsSummaryProps) => {
  const classes = useStyles();
  const history = useHistory();

  if (!hasReferred) {
    return <Box className={classes.titleZeroItem}>This user has no referrals yet.</Box>;
  }

  const goToProfile = (id: string) => {
    history.push(ROUTES.PATIENT_ID(id));
  };

  return (
    <Box>
      {isAdvocate && (
        <Box component="p" className={classes.titleSummary} mb="20px">
          Redeemable total: ${getRedeemableAmount(notRedeemed)}
        </Box>
      )}
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.column}>
          <Box
            display="flex"
            justifyContent="space-between"
            className={classNames(classes.titleColumn, classes.columnLeft)}
          >
            <Box component="span">Not redeemed ${getReferredTotalAmount(notRedeemed)}</Box>
            <Box component="span" />
          </Box>
          <Box>
            <Box pl="81px">
              {notRedeemed?.map((item: any) => (
                <p
                  role="presentation"
                  key={item.name}
                  className={classes.nameCustomer}
                  onClick={() => goToProfile(item.referredId)}
                >
                  ${item.value} {item.fullName}
                </p>
              ))}
            </Box>
          </Box>
        </Box>
        <Box className={classes.column}>
          <Box
            display="flex"
            justifyContent="space-between"
            className={classNames(classes.titleColumn, classes.columnRight)}
          >
            <Box component="span">Redeemed</Box>
          </Box>
          <Box>
            <Box pl="81px">
              {redeemed?.map((item: any) => (
                <p
                  role="presentation"
                  key={item.key}
                  className={classes.nameCustomer}
                  onClick={() => goToProfile(item.referredId)}
                >
                  {item.fullName}
                </p>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralsSummary;
