/* eslint-disable @typescript-eslint/indent */
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ListItem, ListItemIcon, ListItemText, Typography, makeStyles, Collapse } from '@material-ui/core';
import {
  SupervisorAccount as SupervisorAccountIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  PeopleAlt as PeopleAltIcon,
  ListAlt as ListAltIcon,
  ShowChart as ShowChartIcon,
  AttachMoney as MoneyIcon,
  BarChart as BarChartIcon,
  DateRange as DateRangeIcon,
  BubbleChart as BubbleChartIcon,
} from '@material-ui/icons';

import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, USER_TYPES } from 'src/constants/general.constants';
import { RootState } from 'src/rematch';
import { MEDSPA_ADMIN_URLS } from 'src/routes/medspaAdminRoutes';
import { FEATURE_ENABLE_CHARTING, FEATURE_MEDSPA_MARKETING } from 'src/constants/features.constants';
import { useMedspaRole } from 'src/hooks/queries/useMedspaAdmins';
import { MEDSPA_PROVIDER_TYPE, MENU_OPTIONS, MenuOption } from 'src/constants/menu.constants';
import { CUSTOM_SERVICES } from 'src/constants/actions.constants';
import { ONBOARDING_BASE_PATH } from 'src/routes/medspaOnboardingRoutes';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { hasAccessTo } from '../utils/auth.utils';

export const useStyles = makeStyles({
  'selected-0': {
    borderRadius: '0.5rem',
    backgroundColor: 'rgba(255, 255, 255, 0.12) !important',
  },
  'selected-1': {
    borderRadius: '0.5rem',
    backgroundColor: 'rgba(255, 255, 255, 0.12) !important',
  },
  iconList: {
    minWidth: '37px',
  },
  iconColor: {
    fill: '#fff',
    opacity: 0.3,
  },
  subContainer: {
    paddingTop: '0.5rem',
  },
  text: {
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'lighter',
    paddingLeft: '2px',
    opacity: 0.7,
  },
  expandActive: {
    color: 'white',
  },
  textActive: {
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingLeft: '2px',
  },
  listItem: {
    height: '52px',
    padding: '0 0.75rem',
  },
  iconActive: {
    fill: 'white',
    opacity: 1,
  },
  arrowIcon: {
    fontSize: '28px',
  },
  2: { backgroundColor: '#156358' },
});

export const MainListItems = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { permissions, roleName, featureList, user, userType } = useSelector(({ auth }: RootState) => auth);
  const { data: medspaRoleName } = useMedspaRole(roleName === MEDSPA_ADMIN_ROLE);

  const [subMenus, setSubMenus] = useState([] as any);

  const isMedspaActive = process.env.REACT_APP_MEDSPA_FIELDS === 'true';
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;

  const hasOptedInToDoList = featureList?.some((feature: any) => feature.key === FEATURE_ENABLE_CHARTING);
  const hasOptedInMarketing = featureList?.some((feature: any) => feature.key === FEATURE_MEDSPA_MARKETING);
  const isMedspaLegacyOrLegacyPlus = medspaRoleName === PORTRAIT_LEGACY || medspaRoleName === PORTRAIT_LEGACY_PLUS;
  const optionsType = userType === USER_TYPES.PRACTITIONER && Boolean(user.userGroupId) ? MEDSPA_PROVIDER_TYPE : '';

  const { onboardingEnabled, onboardingCompleted } = useMedspaAdminOnboarding();

  const medspaOptions: MenuOption[] = useMemo(
    () => [
      ...(onboardingEnabled
        ? [
            {
              name: 'My Portrait',
              permission: '',
              options: [{ name: 'To Do', url: ONBOARDING_BASE_PATH, permission: '' }],
              icon: (isActive: boolean) => (
                <BubbleChartIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
              ),
            },
          ]
        : []),
      {
        name: 'Earnings',
        url: MEDSPA_ADMIN_URLS.Earnings,
        disabled: onboardingEnabled && !onboardingCompleted,
        permission: '',
        icon: (isActive: boolean) => (
          <MoneyIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
        ),
      },
      {
        name: 'Patients',
        url: MEDSPA_ADMIN_URLS.Patients,
        disabled: onboardingEnabled && !onboardingCompleted,
        permission: '',
        icon: (isActive: boolean) => (
          <SupervisorAccountIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
        ),
      },
      {
        name: 'Services',
        url: MEDSPA_ADMIN_URLS.ServicesManagement,
        disabled: onboardingEnabled && !onboardingCompleted,
        permission: CUSTOM_SERVICES,
        icon: (isActive: boolean) => (
          <BubbleChartIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
        ),
      },
      {
        name: 'Inventory Ordering',
        url: MEDSPA_ADMIN_URLS.Inventory,
        disabled: onboardingEnabled && !onboardingCompleted,
        permission: '',
        icon: (isActive: boolean) => (
          <ShowChartIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
        ),
      },
      ...(hasOptedInMarketing
        ? [
            {
              name: 'Marketing',
              url: MEDSPA_ADMIN_URLS.Marketing,
              disabled: onboardingEnabled && !onboardingCompleted,
              permission: '',
              icon: (isActive: boolean) => (
                <BarChartIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
              ),
            },
          ]
        : []),
      ...(isMedspaLegacyOrLegacyPlus || hasOptedInToDoList
        ? [
            {
              name: 'To-do list',
              url: MEDSPA_ADMIN_URLS.TodoList,
              disabled: onboardingEnabled && !onboardingCompleted,
              permission: '',
              icon: (isActive: boolean) => (
                <ListAltIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
              ),
            },
          ]
        : []),
      ...(!isMedspaLegacyOrLegacyPlus
        ? [
            {
              name: 'Providers',
              url: MEDSPA_ADMIN_URLS.Practitioners,
              disabled: onboardingEnabled && !onboardingCompleted,
              permission: '',
              icon: (isActive: boolean) => (
                <PeopleAltIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
              ),
            },
          ]
        : []),
      ...(!isMedspaLegacyOrLegacyPlus
        ? [
            {
              name: 'Scheduling',
              permission: '',
              disabled: onboardingEnabled && !onboardingCompleted,
              options: [
                { name: 'Calendar', url: MEDSPA_ADMIN_URLS.Calendar },
                { name: 'Provider Configuration', url: MEDSPA_ADMIN_URLS.CalendarConfiguration },
                { name: 'Appointment Types', url: MEDSPA_ADMIN_URLS.AppointmentTypesConfig },
                { name: 'Locations', url: MEDSPA_ADMIN_URLS.Locations },
              ],
              icon: (isActive: boolean) => (
                <DateRangeIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
              ),
            },
          ]
        : [
            {
              name: 'Scheduling',
              permission: '',
              disabled: onboardingEnabled && !onboardingCompleted,
              options: [
                { name: 'Calendar', url: MEDSPA_ADMIN_URLS.Calendar },
                { name: 'Provider Configuration', url: MEDSPA_ADMIN_URLS.CalendarConfiguration },
                { name: 'Locations', url: MEDSPA_ADMIN_URLS.Locations },
              ],
              icon: (isActive: boolean) => (
                <DateRangeIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
              ),
            },
          ]),
    ],
    [
      featureList,
      isMedspaLegacyOrLegacyPlus,
      hasOptedInToDoList,
      hasOptedInMarketing,
      onboardingEnabled,
      onboardingCompleted,
    ]
  );

  useEffect(() => {
    const medspaPaths = medspaOptions.map((option) => option.url);

    if (medspaPaths.includes(history.location.pathname)) {
      setSubMenus([]);
    }
  }, [location, medspaOptions]);

  const handleSubMenuClick = (values: string[], href: string) => {
    let newValues: any;
    if (subMenus.includes(values[values.length - 1])) {
      newValues = values.filter((v) => v !== values[values.length - 1]); // if exist, remove it
    } else {
      newValues = values;
    } // if it doesn't exist add everything
    setSubMenus(newValues);

    if (href) {
      history.push(href);
    }
  };

  const recursiveMenu = (optionsMenu: MenuOption[], list: string[] = []) =>
    optionsMenu.map((option: any, i: number) => {
      const hasArrayPermissions =
        Array.isArray(option.permission) &&
        option.permission.some((permission: string) => hasAccessTo(permission, permissions));
      const hasPermission = option.permission && hasAccessTo(option.permission, permissions);
      if (option.permission && !(hasArrayPermissions || hasPermission)) {
        return;
      }

      let isActive;
      if (option.options) {
        isActive =
          option.options.map((subOption: any) => subOption.url).includes(history.location.pathname) ||
          subMenus.includes(`${i}-${option.name}`);
      } else {
        isActive = history.location.pathname.includes(option.url);
      }

      const item = (
        <ListItem
          button
          onClick={() => handleSubMenuClick([...list, `${i}-${option.name}`], option.url)}
          className={classNames(classes.listItem, classes[list.length], {
            [classes[`selected-${list.length}`]]: isActive,
          })}
          disabled={option.disabled}
        >
          <ListItemIcon className={classes.iconList}>{option.icon && option.icon(isActive)}</ListItemIcon>
          <ListItemText>
            <Typography variant="body1" className={isActive ? classes.textActive : classes.text}>
              {option.name}
            </Typography>
          </ListItemText>
          {option.options && (
            <>
              {isActive ? (
                <ExpandLessIcon
                  className={classNames(classes.arrowIcon, classes.text, { [classes.expandActive]: isActive })}
                />
              ) : (
                <ExpandMoreIcon className={classNames(classes.arrowIcon, classes.iconColor)} />
              )}
            </>
          )}
        </ListItem>
      );

      let childItems: any;

      if (option.options) {
        childItems = recursiveMenu(option.options, [...list, `${i}-${option.name}`]);
      }
      // eslint-disable-next-line consistent-return
      return (
        <>
          {item}
          <Collapse in={isActive} timeout="auto" unmountOnExit className={classes.subContainer}>
            {childItems}
          </Collapse>
        </>
      );
    });

  return (
    <div>{recursiveMenu(isMedspaActive && isMedspaAdmin ? medspaOptions : MENU_OPTIONS(classes, optionsType))}</div>
  );
};
