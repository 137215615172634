import React, { useState } from 'react';
import classnames from 'classnames';
// Components
import { useHistory } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import Page from 'src/components/common/Page';
import { NavigationBar } from '../../../components/PatientProfile/NavigationBar';

// Hooks

// Routes
import { PRACTITIONER_HOME_PATH } from '../../../routes/practitionerRoutes';

import AccountDetails from './AccountDetails';
import { useStyles } from './styles';
import AppointmentTypes from './AppointmentTypes';
import PatientNotification from './PatientNotification';
import Availability from './Availability';

const ACCOUNT_TAB = 'account';
const APPOINTMENT_TYPES_TAB = 'appointmentTypes';
const AVAILABILITY_TAB = 'availability';
const PATIENT_NOTIFICATIONS = 'patientNotifications';

const ItemTab = ({
  active,
  onClick,
  children,
}: {
  active: boolean;
  onClick: () => void;
  children: React.Component | React.ReactNode;
}) => {
  const classes = useStyles();
  return (
    <Box width="200px" onClick={onClick}>
      <Box className={classnames(classes.menuItemTop, { [classes.menuActiveTop]: active })}>
        <Box>{children}</Box>
      </Box>
      <Box className={classnames({ [classes.menuItemBottom]: !active, [classes.menuItemBottomActive]: active })} />
    </Box>
  );
};

interface Props {
  hideNavBar?: boolean;
}
const CalendarConfiguration = ({ hideNavBar }: Props) => {
  const [tab, setTab] = useState(ACCOUNT_TAB);
  const classes = useStyles();
  const history = useHistory();

  const navigateBack = () => {
    history.push(PRACTITIONER_HOME_PATH);
  };

  return (
    <Page title="Calendar Configuration">
      <main>
        {!hideNavBar && <NavigationBar title="Configuration" onBackButtonClick={navigateBack} />}
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid item>
              <Box display="flex">
                <ItemTab onClick={() => setTab(ACCOUNT_TAB)} active={tab === ACCOUNT_TAB}>
                  Account Details
                </ItemTab>
                <ItemTab onClick={() => setTab(APPOINTMENT_TYPES_TAB)} active={tab === APPOINTMENT_TYPES_TAB}>
                  Appointment types
                </ItemTab>
                <ItemTab onClick={() => setTab(AVAILABILITY_TAB)} active={tab === AVAILABILITY_TAB}>
                  Availability Configuration
                </ItemTab>
                <ItemTab onClick={() => setTab(PATIENT_NOTIFICATIONS)} active={tab === PATIENT_NOTIFICATIONS}>
                  Patient notifications
                </ItemTab>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {tab === ACCOUNT_TAB && <AccountDetails />}
            {tab === APPOINTMENT_TYPES_TAB && <AppointmentTypes />}
            {tab === AVAILABILITY_TAB && <Availability />}
            {tab === PATIENT_NOTIFICATIONS && <PatientNotification />}
          </Grid>
        </Grid>
      </main>
    </Page>
  );
};

export default CalendarConfiguration;
