import React from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';

import { DATE_FORMAT_FULL } from 'src/constants/clientLead.constants';
import { formatCurrency } from 'src/utils/formatNumber';
import { ILocation, IMedspaLocation } from 'src/interfaces/ILocation';
import { useStyle } from '../inventoryOrder.styles';

import DeliveryAddress from '../DeliveryAddress';

type Props = {
  adminNote: string | null | undefined;
  totalCost: number;
  desiredDeliveryDate: string;
  deliveryLocation: IMedspaLocation | ILocation | null | undefined;
};

export const DeliveryDetails = ({ adminNote, totalCost, desiredDeliveryDate, deliveryLocation }: Props) => {
  const classes = useStyle();

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 2fr 1fr 1fr"
      padding="25px"
      margin="0 -16px"
      borderBottom="1px solid #ddd"
      className={classes.deliveryNotes}
    >
      <Box display="flex" flexDirection="column">
        <Typography component="p">Delivery Address</Typography>
        <DeliveryAddress location={deliveryLocation} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography component="p">Order Notes</Typography>
        <Typography component="p">{adminNote ?? '--'}</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography component="p">Total</Typography>
        <Typography component="p">{formatCurrency(totalCost)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography component="p">Scheduled Delivery</Typography>
        <Typography component="p">{moment.utc(desiredDeliveryDate).format(DATE_FORMAT_FULL)}</Typography>
      </Box>
    </Box>
  );
};

type AdminProps = {
  totalCost: number;
  desiredDeliveryDate: string;
  adminNote: string;
  order: string;
  deliveryLocation: IMedspaLocation | ILocation | null | undefined;
};

export const DeliveryDetailsAdmin = ({
  adminNote,
  totalCost,
  desiredDeliveryDate,
  order,
  deliveryLocation,
}: AdminProps) => {
  const classes = useStyle();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      borderBottom="1px solid #ddd"
      borderTop="1px solid #ddd"
      padding="1rem"
      className={classes.deliveryNotes}
    >
      <Box display="flex" flexDirection="column">
        <Typography component="p">Delivery Address</Typography>
        <DeliveryAddress location={deliveryLocation} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography component="p">Desired Delivery</Typography>
        <Typography component="p">{moment.utc(desiredDeliveryDate).format(DATE_FORMAT_FULL)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography component="p">Total</Typography>
        <Typography component="p">{formatCurrency(totalCost) || '--'}</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography component="p">Order</Typography>
        <Typography component="p">#{order || '--'}</Typography>
      </Box>
      {adminNote && (
        <Box maxWidth="150px" display="flex" flexDirection="column">
          <Typography component="p">Admin Note</Typography>
          <Typography component="p">{adminNote}</Typography>
        </Box>
      )}
    </Box>
  );
};
