import _ from 'lodash';
import React, { useState } from 'react';
import { Box, Button, Grid, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon, Check as CheckIcon, DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import moment from 'moment';
import formatCurrency from 'src/utils/formatNumber';
import { dispatch } from '../../../rematch';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import ModalDeleteItems from '../../DashboardAdministrator/Inventory/ModalDeleteItems';
import ModalMarkAsEmptyItem from './ModalMarkAsEmptyItem';
import ProductIdentifierVialUnitsBox from './ProductIdentifierVialUnitsBox';
import {
  getExpirationDate,
  getBackgroundAssetColor,
  getBackgroundAssetActionColor,
} from '../../../utils/inventory.utils';
import { useStyles } from './productIdentifiers.styles';

interface Props {
  showUnitDetails?: boolean;
  asset: IAsset;
  canMarkAsEmpty?: boolean;
  hideDetailColors?: boolean;
  showPrice?: boolean;
  handleRemove?: (ids: number[]) => void;
  goBack: () => void;
}

const ProductIdentifierDetails = ({
  hideDetailColors,
  showUnitDetails,
  asset,
  canMarkAsEmpty,
  showPrice,
  goBack,
  handleRemove,
}: Props) => {
  const classes = useStyles();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openMarkAsEmptyModal, setOpenMarkAsEmptyModal] = useState<boolean>(false);

  const handleRemoveModal = () => {
    if (handleRemove) {
      handleRemove([asset.id]);
      setOpenDeleteModal(false);
    }
  };

  const handleMarkAsEmpty = () => {
    if (asset.markedAsEmpty) {
      dispatch.productIdentifiers.undoMarkAsEmpty(asset.id);
    } else {
      dispatch.productIdentifiers.markAsEmpty(asset.id);
    }
    setOpenMarkAsEmptyModal(false);
    goBack();
  };

  return (
    <Box width="100%" mr="auto" ml="auto">
      <Grid container style={{ background: getBackgroundAssetColor(asset, hideDetailColors), height: '160px' }}>
        <Grid item xs={10}>
          <ProductIdentifierVialUnitsBox asset={asset} showUnitDetails={showUnitDetails} />

          <Box display="flex" justifyContent="space-between" className={classes.detailBox}>
            <Box color="#949494">Lot Nº:</Box>
            <Box data-cy="assetLot" color="#393B3F">
              {asset.lot}
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" className={classes.detailBox}>
            <Box color="#949494">Exp. Date:</Box>
            <Box data-cy="expDate" color="#393B3F">
              {getExpirationDate(asset).format('MM/DD/YYYY')}
            </Box>
          </Box>

          {asset.reconstitutedAt && (
            <Box display="flex" justifyContent="space-between" className={classes.detailBox}>
              <Box color="#949494">Rec. Date:</Box>
              <Box color="#393B3F">{moment(asset.reconstitutedAt).format('MM/DD/YYYY')}</Box>
            </Box>
          )}

          {!!showPrice && asset.reimbursementCost && (
            <Box
              display="flex"
              justifyContent="space-between"
              data-extended={!asset.reconstitutedAt}
              className={classes.detailBoxPrice}
              data-testid="asset price"
            >
              <Box color="#949494">Price:</Box>
              <Box color="#393B3F">${formatCurrency(_.round(asset.reimbursementCost, 2))}</Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={2} style={{ paddingLeft: '11px' }}>
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
            className={classes.actionButtonContainer}
            style={{ background: getBackgroundAssetActionColor(asset, hideDetailColors) }}
          >
            {canMarkAsEmpty && asset.allowPartialSale && !asset.markedAsEmpty && (
              <Grid item className={classes.actionButtonDiv}>
                <Tooltip title="Mark as empty">
                  <span>
                    <Button disabled={asset.markedAsEmpty} className={classes.button}>
                      <DeleteOutlineIcon
                        data-cy="deleteIcon"
                        onClick={() => setOpenMarkAsEmptyModal(true)}
                        className={classes.actionButton}
                      />
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}

            {handleRemove && (
              <Grid item className={classes.actionButtonDiv}>
                <DeleteIcon onClick={() => setOpenDeleteModal(true)} className={classes.actionButton} />
              </Grid>
            )}
            {asset.counted && asset.counted > 0 ? (
              <Grid item className={classes.actionButtonDiv}>
                <CheckIcon className={classes.actionButton} style={{ color: '#12574D' }} />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <ModalDeleteItems
        handleSaveChanges={handleRemoveModal}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      />
      <ModalMarkAsEmptyItem
        isMarkedAsEmpty={asset.markedAsEmpty}
        handleSaveChanges={handleMarkAsEmpty}
        open={openMarkAsEmptyModal}
        units={asset.currentNumberOfUnits}
        handleClose={() => setOpenMarkAsEmptyModal(false)}
      />
    </Box>
  );
};

export default ProductIdentifierDetails;
