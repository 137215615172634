import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: '15px 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  signature: {
    height: '158px',
    borderRadius: '5px',
    border: '1px solid lightgray',
    alignSelf: 'center',
    width: '700px',
  },
  signatureLabel: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#A6A6A6',
    position: 'relative',
    top: '85px',
    width: 'fit-content',
    margin: '0',
    zIndex: -10,
    alignSelf: 'center',
  },
  paragraph: {
    fontFamily: 'Messina Sans Regular',
    marginBottom: '13px',
    fontSize: '14px',
    lineHeight: '23px',
    color: '#000000',
  },
  signatureImage: {
    padding: '25px',
    border: '1px #E4E7EB solid',
    borderRadius: '4px',
    width: '700px',
    alignSelf: 'center',
  },
}));

interface Props {
  onEndStroke: (signature: string) => void;
  signature: string;
  status: string;
}

export const PractitionerSignature = ({ signature, onEndStroke, status }: Props) => {
  let signCanvasRef: any;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.paragraph}>
        I have previously acknowledged that I have scanned all inventory that I know to be in my possession and any of
        my notes above are truthful and accurate.
      </p>
      {status === 'pending_signature' && (
        <>
          <p className={classes.signatureLabel}>Signature By Practitioner</p>
          <SignatureCanvas
            canvasProps={{ className: classes.signature }}
            ref={(ref) => {
              signCanvasRef = ref;
            }}
            onEnd={() => {
              onEndStroke(signCanvasRef.getTrimmedCanvas().toDataURL('image/png'));
            }}
            clearOnResize={false}
          />
        </>
      )}
      {signature && (
        <img
          src={signature}
          className={classes.signatureImage}
          alt="Signature"
          onError={(e: any) => {
            e.target.style.display = 'none';
          }}
        />
      )}
    </div>
  );
};
