import { useQuery, useQueryClient, useMutation } from 'react-query';
import { SERVICE_VISIT_PHOTOS } from '../../constants/reactQuery.keys';
import ServiceVisit from '../../services/ServiceVisit';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';

export function useServiceVisitPhotos(id: number, enabled: boolean) {
  const response = useQuery([SERVICE_VISIT_PHOTOS, id], () => ServiceVisit.serviceVisitPhotos(id), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: true,
    enabled,
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'photos' })),
  });

  return {
    ...response,
    data: response.data || [],
  };
}

export const useSaveServiceVisitPhotoMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => ServiceVisit.saveServiceVisitPhotos(params.serviceVisitId, params.formData), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([SERVICE_VISIT_PHOTOS]);
    },
    onError: useReactQueryOnErrorHandler,
  });
};
