import { makeStyles, withStyles, createStyles, Select } from '@material-ui/core';

export const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  buttonStatus: {
    pending: {
      backgroundColor: '#e7e7e7',
    },
  },
  menuIcon: {
    border: '1px solid #E4E7EB',
    borderRadius: '2px',
  },
  serialNumber: {
    fontSize: '80%',
    background: '#e7e7e7',
    borderRadius: '2px',
    height: '22px',
    lineHeight: '23px',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export const useStyles = makeStyles(() => ({
  newPurchaseOrderButton: {
    backgroundColor: '#E7EEED',
    height: '47px',
    width: '189px',
  },
  message: {
    height: '40px',
    background: '#F2D1AB',
    lineHeight: '40px',
    padding: '0px 5px',
    marginTop: '10px',
  },
  dropdown: {
    fontFamily: 'Messina Sans Regular',
    height: '46px',
  },
  formDropdown: {
    width: '255px',
    left: '30px',
  },
  formInputSelect: {
    top: '-4px',
    color: '#000',
  },
  formControl: {
    height: '46px',
    width: '100% !important',
  },
  autocompleteForm: {
    width: '100% !important',
  },
}));

export const SelectCustom = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: 0,
      color: 'rgba(0, 0, 0, .54)',
      fontSize: '15px',
      fontFamily: 'Messina Sans Regular',
      width: '250px',
    },
    select: {
      color: '#000',
    },
  })
)(Select);
