import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

type StandingOrderState = {
  standingOrder: string;
  name: string;
  onChange: ({ standingOrderDraft }: any) => void;
};

const StandingOrderEditor = ({ name, standingOrder, onChange }: StandingOrderState) => {
  if (standingOrder === null || name === null) {
    return null;
  }

  return (
    <div style={{ width: '70%', margin: 'auto' }}>
      <CKEditor
        editor={ClassicEditor}
        data={standingOrder}
        config={{
          removePlugins: ['MediaEmbed', 'CKFinder', 'Link'],
        }}
        onChange={(event: any, editor: { getData: () => any }) => {
          const data = editor.getData();
          onChange({ name: name.toLowerCase(), standingOrder: data });
        }}
      />
    </div>
  );
};

export default StandingOrderEditor;
