import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControlLabel,
  Switch,
  Collapse,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Replay as ReplayIcon
} from '@material-ui/icons';
// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../../common/Button';

const useStyles = makeStyles(() => ({
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    color: '#000000'
  }
}));

export const FemaleCondition = ({ objectKey, value, question, save }: any) => {
  const classes = useStyles();
  const [isStatus, setIsStatus] = useState('no');
  const [editMode, setEditMode] = useState(true);

  useEffect(() => {
    setIsStatus(value);
  }, []);

  const onEdit = () => {
    if (editMode === false) {
      setIsStatus(value);
    }

    setEditMode(!editMode);
  };

  const onSwitchChange = () => {
    const status = isStatus === 'yes' ? 'no' : 'yes';
    setIsStatus(status);
  };

  const onSave = () => {
    save({ [objectKey]: isStatus });
    setEditMode(true);
  };

  const isButtonDisabled = (): boolean => isStatus === value;

  return (
    <Grid item xs={12}>
      <div className={classes.field}>
        {question}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            label={isStatus === 'yes' ? 'Yes' : 'No'}
            control={
              <Switch
                checked={isStatus === 'yes'}
                onChange={onSwitchChange} disabled={editMode} />}
          />
          {editMode ? <EditIcon onClick={onEdit} /> : <ReplayIcon onClick={onEdit} />}
        </div>
      </div>
      <Collapse in={!editMode} timeout="auto">
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '98.8%' }}>
          <Button
            title="Save"
            style={{ minWidth: '100px', color: '#ffffff', backgroundColor: '#12574d', marginRight: '0' }}
            onClick={onSave}
            disabled={isButtonDisabled()}
          />
        </div>
      </Collapse>
    </Grid>
  );
};
