import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, CardHeader } from '@material-ui/core';
import { RootState } from 'src/rematch';
import { FEATURE_BEAUTIBANK } from 'src/constants/features.constants';
import { SQUARE_ERROR_MESSAGES, SQUARE_STATUS } from '../../constants/checkout.constants';
import PaymentTag from '../../components/ServiceVisitCheckout/PaymentTag';
import formatDate from '../../utils/formatDate';
import { BeautyBankBadge } from '../../components/PatientProfile/BeautyBankSection/BeautyBankBadge';
import { hasAccessTo, hasFeatureEnabled } from '../../utils/auth.utils';
import { READ_BEAUTIBANK } from '../../constants/actions.constants';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  cardHeader: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.05px',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: '160px',
  },
  transactionFeedback: {
    width: '75%',
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    marginLeft: '15px',
  },
}));

interface ICheckoutHeaderProps {
  patientId: string;
  transactionStatus: string;
  squareErrorCode: string;
  lastCheckoutTransactionCreatedAt: string | undefined;
  processedInSquare: boolean | undefined;
  toPay: number;
  amountPaid: number;
  hasPartialRefund: boolean;
}

export const CheckoutHeader = ({
  patientId,
  transactionStatus,
  squareErrorCode,
  lastCheckoutTransactionCreatedAt,
  processedInSquare,
  toPay,
  amountPaid,
  hasPartialRefund,
}: ICheckoutHeaderProps) => {
  const classes = useStyles();

  const { featureList, permissions } = useSelector(({ auth }: RootState) => auth);

  const formattedPaymentDate = lastCheckoutTransactionCreatedAt
    ? formatDate(lastCheckoutTransactionCreatedAt, 'MM/DD/YYYY')
    : '';

  return (
    <div className={classes.container}>
      <CardHeader title={<div className={classes.cardHeader}>Order details</div>} />
      {transactionStatus !== SQUARE_STATUS.PENDING && (
        <div
          className={classes.transactionFeedback}
          style={{ justifyContent: transactionStatus === SQUARE_STATUS.ERROR ? undefined : 'space-between' }}
        >
          <div>
            <PaymentTag
              transactionStatus={transactionStatus}
              squareErrorCode={squareErrorCode}
              processedInSquare={processedInSquare}
              toPay={toPay}
              amountPaid={amountPaid}
              hasPartialRefund={hasPartialRefund}
            />
            <span> - {formattedPaymentDate}</span>
          </div>

          {transactionStatus === SQUARE_STATUS.ERROR && (
            <span className={classes.errorMessage}>{SQUARE_ERROR_MESSAGES[squareErrorCode]}</span>
          )}
        </div>
      )}
      {patientId && hasAccessTo(READ_BEAUTIBANK, permissions) && hasFeatureEnabled(FEATURE_BEAUTIBANK, featureList) && (
        <BeautyBankBadge patientId={patientId} />
      )}
    </div>
  );
};
