import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import Page from 'src/components/common/Page';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import { InputSearchProps } from '../../../../interfaces/IPractitionerReport';
import { IClientLead, LeadTableParams, ProviderListProps, IClientLeadStatus } from '../../../../interfaces/IClientLead';
import {
  DEFAULT_SORT_KEY,
  DEFAULT_SELECTION,
  TABLE_LIST,
  ADMIN_COLUMN_NAMES,
  PRACTITIONER_COLUMN_NAMES,
  ROWS_PER_PAGE,
  COLUMN_SHRINK_PFX,
  SEARCH_PLACEHOLDER,
  STATUS_FILTER_TITLE,
  PROVIDER_FILTER_TITLE,
  SORT_ASC,
  SORT_DESC,
  SORTABLE_COLS,
} from '../../../../constants/clientLead.constants';
import { useStyles, TableCellHeader } from './reports.styles';
import RowItem from './RowItemLeads';
import { InputSearch } from './TableReports';
import { useClientLeads, useClientLeadProviders } from '../../../../hooks/queries/useClientLeads';
import { SelectListItems, SelectList as ReportList } from '../../../common/SelectList';

const ProviderList: React.FC<ProviderListProps> = ({
  selected,
  setSelected,
  className,
  loadSelected,
  disallowShowAll,
}: ProviderListProps): React.ReactElement => {
  const { isLoading, data: providerData } = useClientLeadProviders(disallowShowAll);

  const classes = useStyles();

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    providerData && setSelected(Object.keys(providerData)[0]);
  }, [providerData]);

  return !providerData || isLoading ? (
    <Skeleton variant="rect" width="100%" height={48} />
  ) : (
    <ReportList
      title={PROVIDER_FILTER_TITLE}
      listItems={providerData as SelectListItems}
      selected={`${selected}`}
      setSelected={setSelected}
      className={[classes.providerform, className].join(' ')}
      loadSelected={loadSelected}
    />
  );
};

const TableLeads: React.FC<LeadTableParams> = ({ practitionerView }: LeadTableParams): React.ReactElement => {
  const [statusFilter, setStatusFilter] = useState<string | undefined>();
  const [practitionerId, setPractitionerId] = useState<string | undefined>();
  const [sortKey, setSortKey] = useState<string | undefined>();
  const [page, setPage] = useState<number>(0);
  const [searchTerms, setSearchTerms] = useState<string>('');
  const [asc, setAsc] = useState<boolean>(false);
  const [focusDialogOpen, setFocusDialogOpen] = useState<boolean>(false);
  const [focusDialog, setFocusDialog] = useState<React.ReactElement | null>(null);

  const columnNames = practitionerView ? PRACTITIONER_COLUMN_NAMES : ADMIN_COLUMN_NAMES;
  const focusPractitioner = practitionerId ? parseInt(practitionerId, 10) : null;

  const {
    isLoading,
    isFetching,
    isRefetching,
    data: leadData,
  } = useClientLeads({
    page,
    searchTerms,
    status: statusFilter as IClientLeadStatus,
    sortBy: sortKey,
    sortType: asc ? SORT_ASC : SORT_DESC,
    practitionerId: focusPractitioner,
  });

  const { clientLeads, total } = leadData || {};

  useEffect(() => {
    setStatusFilter(DEFAULT_SELECTION);
    setSortKey(DEFAULT_SORT_KEY);
  }, []);

  const classes = useStyles();

  const handleStatusSelect = (label: string | null): void => {
    setPage(0);
    setSearchTerms('');
    if (label) {
      setStatusFilter(label);
    }
  };

  const handleProviderSelect = (newPractitionerId: string | null): void => {
    setPage(0);
    setSearchTerms('');
    if (newPractitionerId) {
      setPractitionerId(newPractitionerId);
    }
  };

  const handleChangePage = (event: any, newPage: number): void => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newText: string = event.currentTarget?.value || '';
    if (newText !== searchTerms) {
      setSearchTerms(newText.toLowerCase());
      setPage(0);
    }
  };

  const handleHeaderClick = (event: React.MouseEvent<HTMLElement>): void => {
    const newKey: string = event.currentTarget?.getAttribute('data-colname') || '';
    if (SORTABLE_COLS.indexOf(newKey) > -1) {
      if (newKey === sortKey) {
        setAsc(!asc);
      } else {
        setAsc(false);
        setSortKey(newKey);
      }
      setPage(0);
    }
  };

  const searchProps: InputSearchProps = {
    value: searchTerms,
    placeholder: SEARCH_PLACEHOLDER,
    onChangeValue: handleChangeSearch,
    isLoading: isLoading || isFetching || isRefetching,
  };

  return (
    <Page title="Client Leads">
      <TableContainer component={Paper} className={classes.noShadow}>
        <Box className={classes.navbar}>
          <ReportList
            title={STATUS_FILTER_TITLE}
            listItems={TABLE_LIST}
            selected={statusFilter || DEFAULT_SELECTION}
            setSelected={handleStatusSelect}
            className={classes.statusform}
          />
          {!practitionerView && <ProviderList selected={`${practitionerId}`} setSelected={handleProviderSelect} />}
          <InputSearch {...searchProps} />
          <TablePagination
            rowsPerPageOptions={[]}
            count={total || 0}
            rowsPerPage={ROWS_PER_PAGE}
            page={page}
            onChangePage={handleChangePage}
            className={classes.pagination}
          />
        </Box>
        <Table
          data-testid="leads table"
          className={classes.leadTable}
          stickyHeader={true /* eslint-disable-line react/jsx-boolean-value */}
        >
          {isLoading || isRefetching ? (
            <MultipleSkeleton />
          ) : (
            <>
              <TableHead className={classes.tableHeader}>
                <TableRow className={classes.centerRow}>
                  <TableCellHeader />
                  {Object.entries(columnNames).map(
                    ([headerKey, headerName]: [string, string]): React.ReactElement => (
                      <TableCellHeader
                        className={classes.theader}
                        onClick={handleHeaderClick}
                        data-colname={headerKey}
                        data-shrink={headerName.split(' ')[0]?.toLowerCase() === COLUMN_SHRINK_PFX}
                        key={headerKey}
                        data-testid={`table header ${headerName}`}
                      >
                        <Box className={classes.headerBox} data-left={headerKey === 'firstName'}>
                          {headerName}
                          {asc ? (
                            <KeyboardArrowUp
                              data-active={sortKey === headerKey}
                              className={classes.chevron}
                              data-sortable={SORTABLE_COLS.indexOf(headerKey) > -1}
                              data-testid={`table header asc ${headerName}`}
                            />
                          ) : (
                            <KeyboardArrowDown
                              data-active={sortKey === headerKey}
                              className={classes.chevron}
                              data-sortable={SORTABLE_COLS.indexOf(headerKey) > -1}
                              data-testid={`table header desc ${headerName}`}
                            />
                          )}
                        </Box>
                      </TableCellHeader>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(clientLeads || []).map(
                  (row: IClientLead): React.ReactElement => (
                    <RowItem
                      key={`${row.id}{row.practitionerId}`}
                      row={row}
                      cols={columnNames}
                      setFocusDialog={setFocusDialog}
                      setDialogOpen={setFocusDialogOpen}
                      practitionerView={practitionerView}
                    />
                  )
                )}
              </TableBody>
            </>
          )}
        </Table>
        <Dialog
          open={focusDialogOpen}
          maxWidth="lg"
          children={focusDialog /* eslint-disable-line react/no-children-prop */}
        />
        {isLoading || isRefetching ? null : (
          <Box className={classes.navbar}>
            <TablePagination
              rowsPerPageOptions={[]}
              count={total || 0}
              rowsPerPage={ROWS_PER_PAGE}
              page={page}
              onChangePage={handleChangePage}
              className={classes.pagerBottom}
            />
          </Box>
        )}
      </TableContainer>
    </Page>
  );
};

export { ProviderList, TableLeads, TableLeads as default };
