import React from 'react';
import {
  Button as MaterialButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface ConfirmModalProps {
  title: string;
  contentText: string | React.ReactNode;
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onClickConfirm: () => void;
  confirmText?: string;
  onClickCancel?: () => void;
}

const ConfirmModal = ({
  title,
  contentText,
  confirmText = 'Confirm',
  openDialog,
  setOpenDialog,
  onClickConfirm,
  onClickCancel,
}: ConfirmModalProps) => {
  const handleDialogCloseCancel = () => {
    setOpenDialog(false);
    if (onClickCancel) {
      onClickCancel();
    }
  };

  const handleDialogCloseConfirm = () => {
    setOpenDialog(false);
    onClickConfirm();
  };
  return (
    <Dialog open={openDialog} onClose={handleDialogCloseCancel} data-cy="confirmationModal">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-content-text">{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MaterialButton data-cy="closeModal" onClick={handleDialogCloseCancel}>
          Cancel
        </MaterialButton>
        <MaterialButton data-cy="confirmModal" onClick={handleDialogCloseConfirm} color="primary" autoFocus>
          {confirmText}
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
