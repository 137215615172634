import ISuppliers from 'src/interfaces/ISuppliers';
import { MEDSPA_V4_PATH } from 'src/constants/apiRoutes.constants';
import IServices, { IServicesListParams } from 'src/interfaces/IServices';
import IServiceGroup from 'src/interfaces/IServiceGroup';
import { ServiceFormData } from 'src/interfaces/administrator.interface';
import Api from '../Api';
import { parseGaldermaSkus } from '../Services';

const MedspaServices = {
  async getService(userGroupId: number, serviceId: number): Promise<IServices> {
    const { service } = await Api.get(`${MEDSPA_V4_PATH}/${userGroupId}/services/${serviceId}`);
    return service;
  }, // Updated
  async getAllServices(userGroupId: number, paramsQuery: IServicesListParams): Promise<IServices[]> {
    const { services } = await Api.get(`${MEDSPA_V4_PATH}/${userGroupId}/services`, paramsQuery);
    return services;
  },
  async getDefaultServices(userGroupId: number): Promise<IServices[]> {
    const { services } = await Api.get(`${MEDSPA_V4_PATH}/${userGroupId}/services/default`);
    return services;
  },
  async getAllServicesCount(userGroupId: number, paramsQuery: IServicesListParams): Promise<number> {
    const { count } = await Api.get(`${MEDSPA_V4_PATH}/${userGroupId}/services/count`, paramsQuery);
    return count;
  },
  async getServiceGroups(userGroupId: number, customerId?: number, serviceVisitId?: number): Promise<IServiceGroup[]> {
    const params: { [key: string]: number } = {};
    if (customerId) {
      params.customerId = customerId;
    }
    if (serviceVisitId) {
      params.serviceVisitId = serviceVisitId;
    }
    const { serviceGroups }: { serviceGroups: IServiceGroup[] } = await Api.get(
      `${MEDSPA_V4_PATH}/${userGroupId}/service_groups`,
      params
    );
    return serviceGroups.sort((a, b) => (a.name < b.name ? -1 : 1));
  },
  async getDefaultServiceGroups(userGroupId: number): Promise<IServiceGroup[]> {
    const { serviceGroups }: { serviceGroups: IServiceGroup[] } = await Api.get(
      `${MEDSPA_V4_PATH}/${userGroupId}/service_groups/default`
    );
    return serviceGroups.sort((a, b) => (a.name < b.name ? -1 : 1));
  },
  async createService(
    userGroupId: number,
    service: ServiceFormData
  ): Promise<{ success: boolean; service: ServiceFormData; message?: string }> {
    const parsedService = {
      ...service,
      currentCost: +service.currentCost,
      daysToExpireAfterOpened: +service.daysToExpireAfterOpened,
      followUpIntervalDays: +service.followUpIntervalDays,
      prefix: service.prefix && service.prefix.toUpperCase(),
      price: +service.price,
      startingUnits: +service.startingUnits,
      untracked: !service.isTracked,
      galdermaSku: service.galdermaSku ? parseGaldermaSkus(service.galdermaSku) : null,
    };
    return Api.post(`${MEDSPA_V4_PATH}/${userGroupId}/services`, parsedService);
  },
  async updateService(
    userGroupId: number,
    serviceId: number,
    service: ServiceFormData
  ): Promise<{ success: boolean; service: IServices; message?: string }> {
    const parsedService = {
      ...service,
      currentCost: +service.currentCost,
      daysToExpireAfterOpened: +service.daysToExpireAfterOpened,
      followUpIntervalDays: +service.followUpIntervalDays,
      prefix: service.prefix && service.prefix.toUpperCase(),
      price: +service.price,
      startingUnits: +service.startingUnits,
      untracked: !service.isTracked,
      galdermaSku: service.galdermaSku ? parseGaldermaSkus(service.galdermaSku) : null,
    };
    return Api.put(`${MEDSPA_V4_PATH}/${userGroupId}/services/${serviceId}`, parsedService);
  },

  async deleteService(userGroupId: number, serviceId: number): Promise<{ success: boolean; message?: string }> {
    return Api.delete(`${MEDSPA_V4_PATH}/${userGroupId}/services/${serviceId}`);
  },

  async importServiceGroups(userGroupId: number, serviceGroupIds: number[], serviceIds: number[]): Promise<boolean> {
    const { success } = await Api.post(`${MEDSPA_V4_PATH}/${userGroupId}/service_groups/import`, {
      serviceGroupIds,
      serviceIds,
    });

    return success;
  },

  async getMedspaSuppliers({ userGroupId, kind }: { userGroupId: number; kind: string }): Promise<ISuppliers[]> {
    const params = kind && kind === 'default' ? { kind } : {};
    const { suppliers } = await Api.get(`${MEDSPA_V4_PATH}/${userGroupId}/suppliers`, params);

    return suppliers;
  },
  async medspaPrimaryServices(userGroupId: number): Promise<{
    selectedServiceGroupsFromPrimaryLibrary: number[];
    selectedServicesFromPrimaryLibrary: number[];
  }> {
    const { data, error } = await Api.get(`${MEDSPA_V4_PATH}/${userGroupId}/service_groups/selected_primary_services`);

    if (error) {
      throw error;
    }

    return data;
  },
};

export default MedspaServices;
