/* eslint-disable consistent-return */
import React, { useEffect, useRef } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

export const MapMarkerComponent = ({
  center,
  zoom,
  loading,
  defaultMarker,
  onChangeMarker,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
  loading?: boolean;
  onChangeMarker?: (value: { lat: number; lng: number }) => void;
  defaultMarker?: { lat: number; lng: number };
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const firstRef = useRef(false);
  const [markerCluster, setMarkerClusters] = React.useState<MarkerClusterer>();
  const [marker, setMarker] = React.useState<{ lat: number; lng: number } | undefined>();

  useEffect(() => {
    // Draw map
    if (loading) {
      return;
    }

    if (markerCluster) {
      markerCluster.clearMarkers();
    }
    const newMap = new window.google.maps.Map(ref?.current, {
      center: marker?.lat && marker?.lng ? marker : center,
      zoom,
    });

    if (newMap) {
      newMap.addListener('click', (mapsMouseEvent: any) => {
        const { lat, lng } = mapsMouseEvent.latLng.toJSON();
        setMarker({ lat, lng });
        // Center in marker
        newMap.setCenter({ lat, lng });
        newMap.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      });

      setMarkerClusters(new MarkerClusterer({ map: newMap, markers: [] }));
      // Set default marker by params only first time.
      if (!firstRef.current && defaultMarker?.lat && defaultMarker?.lng) {
        firstRef.current = true;
        setMarker({ lat: defaultMarker.lat, lng: defaultMarker.lng });
        // Center in marker
        newMap.setCenter({ lat: defaultMarker.lat, lng: defaultMarker.lng });
        newMap.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      }
    }

    return () => {
      if (newMap) {
        google.maps.event.clearListeners(newMap, 'click');
      }
    };
  }, [ref, center, loading]);

  useEffect(() => {
    // reset markers
    markerCluster?.clearMarkers?.();
    if (defaultMarker?.lat && defaultMarker?.lng) {
      setMarker({ lat: defaultMarker.lat, lng: defaultMarker.lng });
    } else {
      setMarker(undefined);
    }
  }, [defaultMarker]);

  useEffect(() => {
    // Set marker
    if (marker && markerCluster) {
      markerCluster.clearMarkers();
      if (marker.lat && marker.lng) {
        markerCluster.addMarker(
          new window.google.maps.Marker({
            position: { lat: marker.lat, lng: marker.lng },
          })
        );

        onChangeMarker?.({ lat: marker.lat, lng: marker.lng });
      }
    }
  }, [marker, markerCluster]);

  const border = defaultMarker?.lat && defaultMarker?.lng ? 'solid 2px #12574d' : 'solid 2px gray';
  return (
    <div style={{ border }}>
      <div style={{ height: '200px', width: '100%' }} ref={ref} id="map" />
    </div>
  );
};

export const MarkerComponent: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
