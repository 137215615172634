/* eslint-disable no-empty */
import React, { useEffect, useRef } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { onClassChangeEvent } from 'src/utils/global';

interface Props {
  label?: string;
  value: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
  className?: string | string[];
  placeholder?: string;
  height?: string;
}

const useStyles = makeStyles({
  customCKEditor: { width: '80%', margin: 'auto', marginTop: '50px' },
});

const CustomCKEditor = ({
  label,
  value = '',
  onChange,
  disabled = false,
  className,
  placeholder,
  height = '500px',
}: Props) => {
  const ref = useRef();

  const inputHandler = (event: any, editor: any) => {
    onChange?.(editor.getData());
  };
  const classes = useStyles();

  useEffect(() => {
    // .ck-editor__editable_inline
    setTimeout(() => {
      try {
        const element = ref.current.getElementsByClassName('ck-editor__editable_inline')[0];

        onClassChangeEvent(element, () => {
          element.style.height = height;
          element.style.minHeight = height;
        });
        element.style.height = height;
        element.style.minHeight = height;
      } catch (e) {}
    }, 100); // Delay for refresh page
  }, []);

  return (
    <div ref={ref}>
      <div className={classNames(classes.customCKEditor, className)}>
        {label && <h1>{label}</h1>}
        <CKEditor
          editor={ClassicEditor}
          data={value}
          placeholder={placeholder}
          config={{
            removePlugins: ['MediaEmbed', 'CKFinder', 'Link'],
          }}
          onChange={inputHandler}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default CustomCKEditor;
