import { Box, CircularProgress, withStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Square from '@square/web-sdk';
import { useAddCard } from 'src/hooks/queries/medspaAdmins/useMedspaPaymentMethods';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/rematch';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import compile from 'src/utils/toastMessagesCompiler';
import { PORTRAIT_ORDERING_SQUARE_LOCATION_ID } from 'src/constants/inventory.constants';
import { ClassNameMap } from 'src/types/Dom';
import { EHRButton } from 'src/components/ui/v1';

export const AddCreditCard: React.FC<ClassNameMap & { closeDialog?: () => void; onSave?: () => void }> = ({
  closeDialog,
  onSave,
  classes,
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const paymentButtonTextRef = useRef(null);

  const {
    user: { userGroupId },
    isLoading: isLoadingState,
  } = useSelector(({ auth }: RootState) => auth);

  const { mutateAsync: addCard } = useAddCard(userGroupId ?? 0);

  const applicationId = process.env.REACT_APP_SQUARE_APPLICATION_ID || '';

  const isLoading = processing || isLoadingState;

  const showError = () =>
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'saving',
          element: 'the credit card',
        }),
        type: 'error',
      },
    });

  const focusCallback = (event: Square.SqEvent<Square.CardInputEvent>) => {
    if (event.detail.currentState.isCompletelyValid && !!paymentButtonTextRef?.current) {
      const payButton = (paymentButtonTextRef.current as HTMLSpanElement).parentElement;
      payButton?.focus();
    }
  };

  const handleSave = async ({ status, token, details }: Square.TokenResult): Promise<void> => {
    setProcessing(true);
    if (status === 'OK' && !!token) {
      const { givenName, familyName, addressLines, city, state, postalCode } = details?.billing || {};
      try {
        await addCard({
          cardNonce: token,
          cardholderName: `${givenName} ${familyName}`,
          billingAddress: {
            addressLine1: addressLines ? addressLines[0] : undefined,
            addressLine2: addressLines ? addressLines[1] : undefined,
            locality: city,
            administrativeDistrictLevel1: state,
            postalCode,
          },
        });
        onSave?.();
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: 'Card has been added successfully!',
            type: 'success',
          },
        });

        closeDialog?.();
      } catch {
        showError();
      } finally {
        setProcessing(false);
      }
    } else {
      showError();
    }
  };

  return (
    <Box data-testid="orderAddCard" className={classes?.paymentContainer}>
      <PaymentForm
        applicationId={applicationId}
        locationId={PORTRAIT_ORDERING_SQUARE_LOCATION_ID}
        cardTokenizeResponseReceived={handleSave}
      >
        <Box marginBottom="1rem">
          <CreditCard
            includeInputLabels
            buttonProps={{
              isLoading,
              css: {
                background: '#12574D',
                width: '100%',
              },
            }}
            callbacks={{
              errorClassRemoved: focusCallback,
              postalCodeChanged: focusCallback,
              cardBrandChanged: focusCallback,
            }}
          >
            <span ref={paymentButtonTextRef}>{isLoading ? <CircularProgress size={20} /> : <>Add Card</>}</span>
          </CreditCard>
        </Box>
      </PaymentForm>
      <EHRButton
        text="Cancel"
        color="default"
        dataCy="cancelNewCard"
        className={classes?.button}
        onClick={() => closeDialog?.()}
      />
    </Box>
  );
};

export default withStyles({
  button: {
    width: '100%',
    fontSize: 16,
  },
  paymentContainer: {
    minHeight: 307,
    minWidth: 430,
    padding: 30,
  },
})(AddCreditCard);
