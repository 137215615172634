import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
// eslint-disable-next-line import/no-unresolved
import TextField from '@material-ui/core/TextField';
import { CustomToggleButton, CustomToggleButtonGroup } from '../../pages/Checkout/customDiscounts.styles';
import {
  DISCOUNT_TYPES,
  EMPTY_DISCOUNT,
  MAX_AMOUNT_ERROR_TEXT as TEXT,
  MIN_AMOUNT_ERROR_TEXT,
} from '../../constants/checkout.constants';
import { ICustomDiscount, IDiscount } from '../../interfaces/checkout.interfaces';
import { ILineItem } from '../../interfaces/IServiceVisits';

type Props = {
  discounts: IDiscount[];
  disabled: boolean;
  isValidAmount: (type: string, amount: string) => boolean;
  lineItem: ILineItem;
  setCustomDiscount: (customDiscount: ICustomDiscount) => void;
  initialState?: ICustomDiscount;
};

export const CustomDiscount = (props: Props) => {
  const { discounts, lineItem, disabled, setCustomDiscount, isValidAmount, initialState = {} } = props;

  const [discountsState, setDiscountsState] = useState<any>(
    Object.keys(initialState).length > 0 ? initialState : { ...EMPTY_DISCOUNT }
  );

  useEffect(() => {
    const customDiscount = discounts.find((discount: any) => discount.reason);
    if (customDiscount) {
      setDiscountsState(customDiscount);
    }
  }, [discounts]);

  const handleOnChange = ({ name, value }: any): void => {
    const newDiscounts = { ...discountsState };
    newDiscounts[name] = value;
    setDiscountsState(newDiscounts);
    setCustomDiscount(newDiscounts);
  };

  const getAmountErrorMessage = (type: string, amount: number): string => {
    if (amount <= 0) {
      return `${MIN_AMOUNT_ERROR_TEXT} 0`;
    }
    if (type === DISCOUNT_TYPES.PERCENTAGE) {
      return `${TEXT} 100%`;
    }
    return `${TEXT} ${lineItem.totalWithoutDiscount}`;
  };

  const helperText = (): string => {
    const { type, amount } = discountsState;
    return isValidAmount(type, amount) ? ' ' : getAmountErrorMessage(type, amount);
  };

  return (
    <div>
      <div style={{ display: 'flex', marginTop: '20px' }}>
        <NumberFormat
          style={{ marginRight: '10px' }}
          decimalScale={2}
          fixedDecimalScale={discountsState.type === DISCOUNT_TYPES.AMOUNT}
          customInput={TextField}
          autoComplete="off"
          label="Amount"
          allowNegative={false}
          name="amount"
          variant="outlined"
          value={discountsState.amount}
          onChange={(e) => {
            handleOnChange(e.target);
          }}
          error={!isValidAmount(discountsState.type, discountsState.amount)}
          helperText={helperText()}
          type="tel"
          fullWidth
          disabled={disabled}
        />
        <CustomToggleButtonGroup
          value={discountsState.type || DISCOUNT_TYPES.PERCENTAGE}
          exclusive
          onChange={(e: any, value: any) => {
            handleOnChange({ name: 'type', value: value || discountsState.type || DISCOUNT_TYPES.PERCENTAGE });
          }}
        >
          <CustomToggleButton
            value={DISCOUNT_TYPES.PERCENTAGE}
            disabled={disabled}
            style={{ borderRadius: '7px 0px 0px 7px' }}
          >
            %
          </CustomToggleButton>
          <CustomToggleButton
            value={DISCOUNT_TYPES.AMOUNT}
            disabled={disabled}
            style={{ borderRadius: '0px 7px 7px 0px' }}
          >
            $
          </CustomToggleButton>
        </CustomToggleButtonGroup>
      </div>
      <TextField
        autoComplete="off"
        label="Description (Required)"
        name="reason"
        variant="outlined"
        placeholder="Add reason"
        value={discountsState.reason}
        onChange={(e) => {
          handleOnChange(e.target);
        }}
        helperText=" "
        fullWidth
        disabled={disabled}
      />
    </div>
  );
};
