import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  practitionerDropdown: {
    width: '100%',
    marginTop: '12px',
  },
  dropdown: {
    fontFamily: 'Messina Sans Regular',
    height: '46px',
  },
  selectedPractitioner: {
    fontFamily: 'Messina Sans Regular',
    borderRadius: '4px',
    backgroundColor: '#e7eeed',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.93,
    letterSpacing: 'normal',
    color: '#000000',
    padding: '11px 6px 11px 11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '46px',
  },
}));
