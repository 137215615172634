export const CANVAS_WIDTH = '100%';
export const CANVAS_HEIGHT = 445;
export const BRUSH_RADIUS_DEFAULT = 1;
export const BRUSH_RADIUS_MIN = 0.3;
export const BRUSH_RADIUS_MAX = 12;
export const BRUSH_RADIUS_STEP = 1;
export const SCALE_MIN = 1;
export const SCALE_MAX = 2;
export const SCALE_STEP = 0.1;
export const TRANSLATE_STATE = { t1: 0, t2: 0 };
export const PAN_STEP_IN_PX = 10;