import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Grid } from '@material-ui/core';
import { Check as CheckIcon, Edit as EditIcon, Replay as ReplayIcon } from '@material-ui/icons';
import { SKIN_TYPE_KIND } from '../../../constants/medicalProfile.constants';
import { Button } from '../../common/Button';
import { RootState, dispatch } from '../../../rematch';
import { useStyles } from './skinTone.styles';

export const SkinTone = () => {
  const classes = useStyles();
  const { skinType } = useSelector(({ patient }: RootState) => patient.medicalProfile);
  const [selectedSkinTone, setSelectedSkinTone] = useState<string | null>('');
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setSelectedSkinTone(skinType);
  }, [skinType]);

  const toggleEdit = () => {
    if (editMode) {
      setSelectedSkinTone(skinType);
    }

    setEditMode(!editMode);
  };

  const onSkinToneClick = (skinTypeKey: string) => {
    setSelectedSkinTone(skinTypeKey);
  };

  const callback = () => {
    setEditMode(false);
  };

  const save = () => {
    dispatch({
      type: 'patient/updateMedicalProfileRequest',
      payload: { value: selectedSkinTone, objectKey: 'skinType', callback },
    });
  };

  const getLabel = () => {
    const skin: any = Object.entries(SKIN_TYPE_KIND).find(([key]) => key === skinType);
    return skin ? skin[1]?.title : '';
  };

  return (
    <div>
      <div className={classes.editSection}>
        <div>Skin Type</div>
        {editMode ? <ReplayIcon onClick={toggleEdit} /> : <EditIcon onClick={toggleEdit} />}
      </div>
      {!editMode && <div className={classes.label}>{getLabel()}</div>}
      <Collapse in={editMode} timeout="auto" disableStrictModeCompat>
        <Grid container spacing={1}>
          {Object.entries(SKIN_TYPE_KIND).map(
            ([key, { description, backgroundColor, borderColor, title, type }]: any) => (
              <Grid key={key} item xs={4} md={4}>
                <div
                  className={classes.skinTypeContainer}
                  onClick={() => {
                    onSkinToneClick(key);
                  }}
                  onKeyPress={() => {
                    onSkinToneClick(key);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    className={classes.border}
                    style={{
                      borderColor: selectedSkinTone === key ? backgroundColor : borderColor,
                      borderStyle: selectedSkinTone === key ? 'solid' : undefined,
                    }}
                  >
                    <div className={classes.circle} style={{ backgroundColor }}>
                      {selectedSkinTone === key && <CheckIcon className={classes.icon} />}
                    </div>
                  </div>
                  <div className={classes.type}>{type}</div>
                  <div
                    className={classes.title}
                    style={{ color: selectedSkinTone === key ? backgroundColor : '#000000' }}
                  >
                    {title}
                  </div>
                  <div className={classes.description}>{description}</div>
                </div>
              </Grid>
            )
          )}
        </Grid>
        <div className={classes.buttonContainer}>
          <Button
            title="Save"
            style={{ color: '#ffffff', backgroundColor: '#12574d', marginRight: '0' }}
            onClick={save}
            disabled={skinType === selectedSkinTone}
          />
        </div>
      </Collapse>
    </div>
  );
};
