import React from 'react';
import { LogoAlt } from 'src/components/LogoAlt';
import { leftSideClasses } from './index.styles';
import backgroundImage from '../../../../assets/backgroundImage.png';
import avatar from '../../../../assets/avatar01.png';
import stars from '../../../../assets/stars.png';

export const PortraitMockUp = () => {
  const classes = leftSideClasses();

  return (
    <>
      <div className={classes.portraitContainer}>
        <LogoAlt />
        <h1 className={`${classes.portraitTitle} ${classes.title}`}>
          Sign up now to <span>save and grow</span> — no monthly fees, no revenue share, no strings attached.
        </h1>
        <div>
          <p className={classes.portraitDescription}>
            I&apos;ve become more independent financially, I&apos;m able to contribute to my family, and enjoy my
            children. Portrait has brought me more financial freedom than I&apos;ve ever known in my career.
          </p>
          <div className={classes.portraitFooter}>
            <div className={classes.portraitAvatarSection}>
              <img src={avatar} alt="avatar of a person" className={classes.portraitAvatar} />
              <div className={classes.portraitAvatarInfo}>
                <div className={classes.portraitAvatarTitle}>
                  <span className={classes.portraitAvatarLine} />
                  <span className={classes.portraitProvider}>Provider</span>
                </div>
                <p className={classes.portraitPoweredBy}>Powered by Portrait</p>
              </div>
            </div>
            <img src={stars} alt="stars" className={classes.portraitStars} />
          </div>
        </div>
      </div>
      <img src={backgroundImage} alt="backgroundImage" className={classes.portraitBackgroundImage} />
    </>
  );
};
