/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import moment from 'moment';
import {
  Box,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@material-ui/core';
import { ExpandMore, Edit as EditIcon } from '@material-ui/icons';
import {
  ADMIN_ROLE,
  PHYSICIAN_ROLE,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  USER_TYPES,
} from '../../constants/general.constants';
import IServiceRequest from '../../interfaces/IServiceRequest';
import { Card } from '../common/card';
import { ShortMultipleSkeleton } from '../common/LoadingSkeleton';
import { Button } from '../common/Button';
import { useDefaultDiagnoses } from '../../hooks/queries/useDefaultDiagnoses';
import { usePhysicianApprovalDraft, PHYSICIAN_APPROVAL_DRAFT } from '../../hooks/queries/usePhysicianApprovalDraft';
import { useStyles } from './services.style';
import { dispatch } from '../../rematch';
import { EXTERNAL_PHYSICIAN_NOTES, PATIENT_INFO } from '../../constants/reactQuery.keys';
import { ACTIVITY_LOGS } from '../../hooks/queries/useActivityLogs';
import Customers from '../../services/Customers';
import { StandingOrderEditorModal } from './StandingOrder/StandingOrderEditorModal';
import PhysicianForm from './PhysicianForm';
import { useCustomRoles } from '../../hooks/queries/useCustomRoles';
import { usePractitionerInfo } from '../../hooks/queries/usePractitioners';
import { useSaveFlexPractitionerPhysician } from '../../hooks/mutations/useSaveFlexPractitionerPhysician';
import { showSnackbar, toSnakeCase } from '../../utils/global';
import OtherServiceRequests from './OtherServiceRequests';
import compile from '../../utils/toastMessagesCompiler';

type PatientState = {
  serviceRequests: IServiceRequest[];
  otherServiceRequests: IServiceRequest[];
  practitioners: any;
  id: number;
  practitioner: any;
  serviceGroups: any;
  isLoadingServiceRequests: boolean;
  customerDiagnoses: any;
  physicianApprovalDraftLoaded: boolean;
  standingOrderDraft: any;
};

const DiagnosesServices = () => {
  const [selectedStandingOrder, setSelectedStandingOrder] = useState({ standingOrder: null, name: null });
  const [showEditor, setShowEditor] = useState(false);
  const [showPhysicianForm, setShowPhysicianForm] = useState(false);
  const [processingGFE, setProcessingGFE] = useState(false);

  const classes = useStyles();
  const { patientId } = useParams<{ patientId: string }>();
  const queryClient = useQueryClient();
  const userType = useSelector(({ auth }: any) => auth.userType);

  const isPhysician = userType === PHYSICIAN_ROLE;
  const [diagnosesCollapsed, setDiagnosesCollapsed] = useState<boolean>(isPhysician);

  const { data: defaultDiagnoses = [], isLoading: isLoadingDefaultDiagnoses } = useDefaultDiagnoses();
  const { isFetching: isFetchingDraft, data: physicianApprovalDraft } = usePhysicianApprovalDraft(patientId, userType);
  const physicianApprovalDraftMutation = useMutation(Customers.updateServiceRequestApprovalDraft);
  const isDraft =
    physicianApprovalDraft.customerDiagnosesChecked?.length > 0 ||
    physicianApprovalDraft.serviceRequestsChecked?.length > 0;

  const {
    id,
    serviceGroups,
    serviceRequests,
    otherServiceRequests,
    isLoadingServiceRequests,
    customerDiagnoses,
    physicianApprovalDraftLoaded,
    standingOrderDraft,
  }: PatientState = useSelector(({ patient }: any) => patient);

  const { userId } = useSelector(({ auth }: any) => auth);
  const { data: roles = [] } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });
  const { practitionerInfo } = usePractitionerInfo(userId);

  const roleName = roles.find((role) => +role.id === practitionerInfo?.roleId)?.name;
  const isNonLegacyPractitioner = roleName !== PORTRAIT_LEGACY && roleName !== PORTRAIT_LEGACY_PLUS;
  const practitionerPhysicianMutation = useSaveFlexPractitionerPhysician();

  useEffect(() => {
    if (serviceGroups.length) {
      dispatch.patient.fetchCustomerDiagnoses(+patientId);
      dispatch.patient.fetchServiceRequests(+patientId);
    }

    // Unmount component
    return () => {
      dispatch({ type: 'patient/resetPhysicianApprovalDraft' });
    };
  }, [serviceGroups]);

  useEffect(() => {
    queryClient.invalidateQueries([PHYSICIAN_APPROVAL_DRAFT, id.toString()]);
  }, [id]);

  useEffect(() => {
    // Update Redux State of serviceRequests and customerDiagnoses with initial data taken from draft
    if (
      isPhysician &&
      id &&
      !isFetchingDraft &&
      serviceRequests.length &&
      defaultDiagnoses.length &&
      !physicianApprovalDraftLoaded
    ) {
      dispatch({
        type: 'patient/loadPhysicianApprovalFromDraft',
        payload: { physicianApprovalDraft, defaultDiagnoses },
      });
    }
  }, [id, defaultDiagnoses, serviceRequests, isFetchingDraft, physicianApprovalDraft, physicianApprovalDraftLoaded]);

  const onSaveServiceAndDiagnosesDraft = async () => {
    if (id) {
      const customerDiagnosesChecked = customerDiagnoses.filter(({ isSelected }: any) => isSelected);

      const serviceRequestsChecked = serviceRequests
        .filter(({ approved }) => approved)
        .map(({ serviceGroupId }) => serviceGroupId);

      await physicianApprovalDraftMutation.mutateAsync({
        patientId,
        payload: {
          customerDiagnosesChecked,
          serviceRequestsChecked,
        },
      });
      queryClient.invalidateQueries([PHYSICIAN_APPROVAL_DRAFT, id.toString()]);
    }
  };

  function isChecked(serviceId: number): boolean {
    const service = serviceRequests.find(({ serviceGroupId }) => serviceGroupId === serviceId);
    return service ? service.approved : false;
  }

  function isDiagnosisChecked(diagnosisId: number): boolean {
    return customerDiagnoses
      .filter(({ isSelected }: any) => isSelected)
      .map(({ defaultDiagnosisId }: any) => defaultDiagnosisId)
      .some((defaultDiagnosisId: number) => defaultDiagnosisId === diagnosisId);
  }

  /** returns the customer's treatment or the default treatment for the diagnosis */
  function customerTreatment(diagnosisId: number): string | undefined {
    const { treatmentPlan } = customerDiagnoses.find(
      ({ defaultDiagnosisId }: any) => defaultDiagnosisId === diagnosisId
    );

    if (treatmentPlan !== null && treatmentPlan !== undefined) {
      return treatmentPlan;
    }

    const defaultDiagnosis = defaultDiagnoses.find((el) => el.id === diagnosisId);
    return defaultDiagnosis ? defaultDiagnosis.defaultTreatmentPlan : '';
  }

  function isRequested(serviceId: number): string {
    const service = serviceRequests.find(({ serviceGroupId }: any) => serviceGroupId === serviceId);
    return service && service.requested ? 'black' : 'white';
  }

  function isYellowFlagHighlighted(serviceId: number): boolean {
    const service = serviceRequests.find(({ serviceGroupId }: any) => serviceGroupId === serviceId);
    return !isNonLegacyPractitioner && !!(service && service.yellowFlagHighlighted);
  }

  function isRedFlagHighlighted(serviceId: number): boolean {
    const service = serviceRequests.find(({ serviceGroupId }: any) => serviceGroupId === serviceId);
    return !isNonLegacyPractitioner && !!(service && service.redFlagHighlighted);
  }

  // to take the date of last modification take in consideration
  // only the servicesRequests that are saved on database
  // that means that have updatedAt
  const lastReviewed =
    serviceRequests &&
    serviceRequests.length > 0 &&
    serviceRequests
      .filter(({ updatedAt }: any) => Boolean(updatedAt))
      .sort((a: any, b: any) => (moment(a.updatedAt) > moment(b.updatedAt) ? -1 : 1))
      .find((sr: any) => sr.checkedByPhysician === true);

  const saveStandingOrderDraft = (modifyStandingOrder: { standingOrder: string; name: string }) => {
    setShowEditor(false);
    if (modifyStandingOrder !== null) {
      dispatch({
        type: 'patient/setStandingOrderDraft',
        payload: {
          standingOrder: modifyStandingOrder.standingOrder,
          name: modifyStandingOrder.name,
        },
      });
    }
  };

  const approveRequest = (clearedByExternalPhysician: boolean = false) => {
    setProcessingGFE(true);
    dispatch({ type: 'patient/approveServiceRequest', payload: { patientId: id } });
    dispatch({
      type: 'patient/saveCustomerDiagnoses',
      payload: {
        patientId: id,
        successCallback: () => {
          queryClient.invalidateQueries(PATIENT_INFO);
          queryClient.invalidateQueries([ACTIVITY_LOGS, id.toString()]);
          queryClient.invalidateQueries([PHYSICIAN_APPROVAL_DRAFT, id.toString()]);
          dispatch.patient.fetchBasicInfo({ patientId: +patientId, useCache: false });
          setProcessingGFE(false);
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile('generic.success_message', {
                element: 'Services and diagnoses',
                action: 'updated',
              }),
            },
          });
        },
        clearedByExternalPhysician,
      },
    });
  };

  const onChangeDiagnosisIsSelected = ({ diagnosisId, isSelected }: any) => {
    dispatch({ type: 'patient/toggleDiagnosis', payload: { diagnosisId, isSelected } });
  };
  const onChangeDiagnosisTreatment = ({ diagnosisId, treatmentPlan }: any) => {
    dispatch({ type: 'patient/toggleDiagnosis', payload: { diagnosisId, treatmentPlan } });
  };
  const onChangeServices = (serviceId: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const isInRequestedService = serviceRequests.some(({ serviceGroupId }) => serviceGroupId === serviceId);

    if (isInRequestedService) {
      dispatch({ type: 'patient/toggleService', payload: { serviceId } });
    } else {
      const service = {
        serviceGroupId: serviceId,
        approved: e.target.checked,
        requested: false,
        checkedByPhysician: true,
      };
      dispatch({ type: 'patient/addServiceToRequestedService', payload: service });
    }
  };

  if (isLoadingServiceRequests || isLoadingDefaultDiagnoses) {
    return isPhysician ? (
      <Card>
        <CardContent style={{ padding: '12px 15px' }}>
          <h5 className={classes.serviceTitle}>Diagnoses</h5>
          <ShortMultipleSkeleton length={3} />
          <h5 className={classes.serviceTitle}>Service Requests</h5>
          <ShortMultipleSkeleton length={5} />
        </CardContent>
      </Card>
    ) : (
      <Card>
        <CardContent style={{ padding: '12px 15px' }}>
          <h5 className={classes.serviceTitle}>Service Requests</h5>
          <ShortMultipleSkeleton length={5} />
        </CardContent>
      </Card>
    );
  }

  const handlePhysicianFormSubmit = async (physicianInfo: any) => {
    try {
      const params = {
        practitionerId: userId,
        customerId: patientId,
        ...physicianInfo,
      };
      await practitionerPhysicianMutation.mutateAsync(toSnakeCase(params));
      approveRequest(true);
      queryClient.invalidateQueries([PATIENT_INFO, patientId]);
      queryClient.invalidateQueries([EXTERNAL_PHYSICIAN_NOTES]);
      setShowPhysicianForm(false);
      showSnackbar('Physician info has been sent succesfully.', SNACKBAR_SUCCESS);
    } catch (e) {
      showSnackbar('Physician info could not be saved.', SNACKBAR_ERROR);
    }
  };

  const handleDisableGfeClearance = !(isPhysician || roleName !== PORTRAIT_LEGACY);

  return (
    <Card>
      <Prompt
        message="GFE approval is still processing, are you sure you want cancel approval and leave?"
        when={processingGFE}
      />
      <CardContent>
        <Accordion
          expanded={diagnosesCollapsed}
          onChange={() => setDiagnosesCollapsed((prevState) => !prevState)}
          className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="services-content"
            id="panel-services"
            className={classes.accordionSummary}
          >
            <h5 className={classes.serviceTitle}>Diagnoses</h5>
          </AccordionSummary>

          <AccordionDetails className={classes.accordionDetails}>
            <Grid container spacing={2} className={classes.mx5}>
              {defaultDiagnoses.map(({ name, id: diagnosisId }) => (
                <Grid key={`diagnosis_${diagnosisId}`} item xs={6} lg={4}>
                  <li className={`${classes.diagnosesContainer} ${classes.listItem}`} style={{ color: 'white' }}>
                    <FormControlLabel
                      label={name}
                      control={
                        <Checkbox
                          color="default"
                          className={classes.checkbox}
                          checked={isDiagnosisChecked(diagnosisId)}
                          onChange={(e) => {
                            onChangeDiagnosisIsSelected({ diagnosisId, isSelected: e?.target?.checked });
                          }}
                          name={name}
                          disabled={handleDisableGfeClearance}
                        />
                      }
                    />
                    {isDiagnosisChecked(diagnosisId) && (
                      <TextField
                        style={{ width: '90%' }}
                        label="Treatment plan"
                        multiline
                        value={customerTreatment(diagnosisId)}
                        onChange={(e) => {
                          onChangeDiagnosisTreatment({ diagnosisId, treatmentPlan: e.target.value });
                        }}
                        disabled={handleDisableGfeClearance}
                      />
                    )}
                  </li>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </CardContent>
      {/* Foreign users service requests */}
      {otherServiceRequests.map((serviceReq) => (
        <OtherServiceRequests
          by={serviceReq.by}
          serviceGroups={serviceGroups}
          serviceRequests={serviceReq.serviceRequests}
        />
      ))}
      {userType !== ADMIN_ROLE && (
        <CardContent>
          <h5 className={classes.serviceTitle}>Indicated treatments</h5>
          <Grid container spacing={0}>
            {serviceGroups.map(({ name, id: serviceGroupId, standingOrder }: any) => (
              <Grid key={`serviceGroup_${serviceGroupId}`} item xs={6} md={4} lg={3}>
                <li className={classes.listItem} style={{ color: isRequested(serviceGroupId) }}>
                  <FormControlLabel
                    label={name}
                    classes={{
                      root: `${isYellowFlagHighlighted(serviceGroupId) ? classes.listItemYellow : ''}
                            ${isRedFlagHighlighted(serviceGroupId) ? classes.listItemRed : ''}`,
                      label: `${isRedFlagHighlighted(serviceGroupId) ? classes.label : ''}`,
                    }}
                    control={
                      <Checkbox
                        color="default"
                        className={classes.checkbox}
                        checked={isChecked(serviceGroupId)}
                        onChange={(e) => {
                          onChangeServices(serviceGroupId, e);
                        }}
                        name={name}
                        disabled={handleDisableGfeClearance}
                        style={{ color: `${isRedFlagHighlighted(serviceGroupId) ? '#fff' : ''}` }}
                      />
                    }
                  />
                  {(isPhysician || isNonLegacyPractitioner) && isChecked(serviceGroupId) && standingOrder !== null && (
                    <EditIcon
                      style={{ color: '#12574d', cursor: 'pointer' }}
                      onClick={() => {
                        setShowEditor(true);
                        if (standingOrderDraft && standingOrderDraft[name.toLowerCase()]) {
                          setSelectedStandingOrder({
                            standingOrder: standingOrderDraft[name.toLowerCase()],
                            name: name.toLowerCase(),
                          });
                        } else {
                          setSelectedStandingOrder({ standingOrder: standingOrder.content, name: name.toLowerCase() });
                        }
                      }}
                    />
                  )}
                </li>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" style={{ marginTop: '40px' }}>
            <p className={classes.lastReviewed}>
              {lastReviewed && (
                <>
                  <b>Last reviewed:</b> {moment(lastReviewed.updatedAt).format('LLLL')}
                  {lastReviewed.physicianProfessionalName && (
                    <div>
                      <b>By:</b> {lastReviewed.physicianProfessionalName}
                    </div>
                  )}
                </>
              )}
            </p>

            {isPhysician ? (
              <Box display="flex" style={{ marginLeft: 'auto' }}>
                {(physicianApprovalDraftMutation.isLoading || isFetchingDraft || processingGFE) && (
                  <CircularProgress style={{ color: 'black', marginRight: '10px', marginTop: '10px' }} size={20} />
                )}
                {isDraft && (
                  <i
                    className="fa-solid fa-pen-ruler"
                    style={{ fontSize: '20px', marginRight: '10px', marginTop: '14px' }}
                  />
                )}
                <Button
                  title="Save draft"
                  style={{ color: '#000000', backgroundColor: '#e7eeed', marginLeft: 'auto' }}
                  onClick={onSaveServiceAndDiagnosesDraft}
                />
                {processingGFE ? (
                  <Button
                    title="Processing..."
                    style={{ color: '#000000', backgroundColor: '#e7eeed', marginLeft: 'auto' }}
                    onClick={() => null}
                    disabled
                  />
                ) : (
                  <Button
                    title="Approve service requests and diagnoses"
                    style={{ color: '#000000', backgroundColor: '#e7eeed', marginLeft: 'auto' }}
                    onClick={() => approveRequest()}
                    disabled={!serviceGroups.length}
                  />
                )}
              </Box>
            ) : (
              roleName !== PORTRAIT_LEGACY && (
                <div style={{ marginLeft: 'auto' }}>
                  {processingGFE ? (
                    <Button
                      title="Processing..."
                      style={{ color: '#000000', backgroundColor: '#e7eeed', marginLeft: 'auto' }}
                      onClick={() => null}
                      disabled
                    />
                  ) : (
                    <Button
                      title="Approve service requests and diagnoses"
                      style={{ color: '#000000', backgroundColor: '#e7eeed' }}
                      onClick={() => setShowPhysicianForm(true)}
                      disabled={handleDisableGfeClearance || !serviceGroups.length}
                    />
                  )}
                  {!serviceGroups.length && (
                    <p style={{ textAlign: 'center' }}>
                      <a href="/practitioner/inventory/count">Please Add Service Groups</a>
                    </p>
                  )}
                </div>
              )
            )}
          </Box>

          <StandingOrderEditorModal
            isOpen={showEditor}
            saveStandingOrderDraft={saveStandingOrderDraft}
            selectedStandingOrder={selectedStandingOrder}
            handleCloseCallback={() => setShowEditor(false)}
          />

          <PhysicianForm
            isModalOpen={showPhysicianForm}
            handleCloseCallback={() => setShowPhysicianForm(false)}
            submitCallback={handlePhysicianFormSubmit}
          />
        </CardContent>
      )}
    </Card>
  );
};

export default DiagnosesServices;
