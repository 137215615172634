import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, CardContent, FormControl, MenuItem, Select } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useMedspaPractitioners } from '../../../hooks/queries/usePractitioners';
import { Card, SmallTitle } from '../../common/card';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { dispatch, RootState } from '../../../rematch';
import { useStyles } from '../AssignedPractitioner/index.style';
import IServiceVisit from '../../../interfaces/IServiceVisits';
import { IPractitioner } from '../../../interfaces/IPractitioner';
import { CONTACT_INFO } from '../../../constants/reactQuery.keys';
import {
  useCustomerPractitionersAssigned,
  useUpdateCustomerPractitionersAssignedMutation,
} from '../../../hooks/queries/customers/customerPractitioners';
import MultipleSelector from '../../common/Custom/MultipleSelector';
import useCurrentUserGroup from '../../../hooks/queries/useUserGroups';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from '../../../constants/general.constants';
import { useCustomContactInfo } from '../../../hooks/queries/useCustomContactInfo';

const buttonStyle = {
  color: '#000000',
  backgroundColor: '#e7eeed',
  border: 'solid 1px rgba(0, 0, 0, 0.12)',
  height: '34px',
  marginRight: 0,
};

const MedspaAssignedPractitioner = () => {
  const classes = useStyles();
  const { patientId }: any = useParams();
  const queryClient = useQueryClient();
  const { userGroupId, roleName } = useSelector(({ auth }: any) => auth);
  const [changePractitioner, setChangePractitioner] = useState(false);
  const updatePractitionersAssignedMutation = useUpdateCustomerPractitionersAssignedMutation(+patientId);
  const { data: userGroupData, isLoading: isLoadingUserGroup } = useCurrentUserGroup(roleName === MEDSPA_ADMIN_ROLE);
  const { serviceVisits } = useSelector(({ patient }: RootState) => patient);
  const {
    data: contactInfo,
    isLoading: isLoadingContactInfo,
    refetch: refetchContactInfo,
  } = useCustomContactInfo(patientId.toString());
  const { data: practitioners = [], isLoading: isLoadingPractitioners } = useMedspaPractitioners(
    {},
    true,
    () => `/v4/medspa/${userGroupId}/practitioners`
  );
  const { practitioner } = contactInfo || {};
  const {
    data: practitionersAssigned,
    isLoading: isLoadingPractitionersAssigned,
    isFetching: isFetchingPractitionersAssigned,
  } = useCustomerPractitionersAssigned(+patientId);

  const handleChangePractitioner = () => {
    if (serviceVisits !== null && serviceVisits.some(({ opened }: IServiceVisit) => opened)) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: 'There is a service visit currently open',
          type: 'error',
        },
      });
      return;
    }
    setChangePractitioner(!changePractitioner);
  };

  const setNewPractitioner = (event: any) => {
    dispatch({
      type: 'patient/changePractitionerRequest',
      payload: {
        practitioner_id: event.target.value,
        patientId,
        callback: () => {
          setChangePractitioner(false);
          // invalidate the contact to refresh the practitioner and physician
          patientId !== null && queryClient.invalidateQueries([CONTACT_INFO, patientId.toString()]);
        },
      },
    });
  };

  const customerPractitionersSchema = yup.object().shape({
    customerPractitioners: yup.array(),
    medspaAdmins: yup.array(),
  });

  const onChangePractitioners = (newVal: any) => {
    updatePractitionersAssignedMutation.mutate(
      {
        practitionerIds: [...newVal, practitioner?.id],
      },
      {
        onSuccess: () => refetchContactInfo(),
      }
    );
  };

  const fullyLoaded =
    !isLoadingPractitioners && !isLoadingPractitionersAssigned && !isLoadingContactInfo && !isLoadingUserGroup;
  const { control } = useForm<FormData>({
    resolver: yupResolver(customerPractitionersSchema),
    defaultValues: { customerPractitioners: [], medspaAdmins: [] },
  });

  if (userGroupData?.roleName === PORTRAIT_LEGACY || userGroupData?.roleName === PORTRAIT_LEGACY_PLUS) {
    return null;
  }

  return (
    <Card>
      {!fullyLoaded ? (
        <Card>
          <CardContent style={{ padding: '12px 15px' }}>
            <ShortMultipleSkeleton length={2} />
          </CardContent>
        </Card>
      ) : (
        <CardContent>
          <SmallTitle title="Assigned practitioner" style={{ marginTop: '0', marginBottom: '18px' }} />
          <div className={classes.selectedPractitioner}>
            Default Practitioner: {practitioner ? `${practitioner.providerLabeling}` : 'None'}
            <Button style={buttonStyle} onClick={() => handleChangePractitioner()}>
              {changePractitioner ? 'Cancel' : 'Change'}
            </Button>
          </div>
          {changePractitioner && (
            <FormControl variant="outlined" className={classes.practitionerDropdown}>
              <Select
                className={classes.dropdown}
                value={practitioner?.id}
                onChange={(newPractitioner) => {
                  setNewPractitioner(newPractitioner);
                }}
              >
                {(practitioners || []).map(({ providerLabeling, id: practitionerId }: IPractitioner) => (
                  <MenuItem key={practitionerId} value={practitionerId}>
                    {providerLabeling}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box paddingTop="10px">
            {updatePractitionersAssignedMutation.isLoading || isLoadingPractitionersAssigned ? (
              <ShortMultipleSkeleton length={2} />
            ) : (
              <MultipleSelector
                autocomplete
                disabled={
                  updatePractitionersAssignedMutation.isLoading ||
                  isFetchingPractitionersAssigned ||
                  isLoadingPractitioners
                }
                label="Practitioners"
                name="customerPractitioners"
                control={control}
                value={practitionersAssigned}
                onChange={onChangePractitioners}
                errors={null}
                options={practitioners
                  .filter((practitionerOption) => practitionerOption.id !== practitioner?.id)
                  .map((practitionerOption) => ({
                    name:
                      `${practitionerOption.providerLabeling} (${practitionerOption.firstName}` +
                      ` ${practitionerOption.lastName})`,
                    value: practitionerOption.id,
                  }))}
              />
            )}
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default MedspaAssignedPractitioner;
