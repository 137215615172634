import { Box, TableBody } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import SortDirection from 'src/types/SortDirection';
import TableHeader, { ColumnType } from '../Table/TableHeader';
import { useStyles } from './paginatedTable.styles';

interface PaginatedTableProps {
  columns: ColumnType[];
  sortBy: string;
  sortDirection: SortDirection;
  onChangeSort: (by: string, direction: SortDirection) => void;
  page: number;
  pages: number;
  customTableRows: React.ReactElement[];
  onPageChange: (page: number) => void;
}

const PaginatedTable = (props: PaginatedTableProps) => {
  const { customTableRows, page, columns, sortBy, sortDirection, onChangeSort, pages, onPageChange } = props;
  const classes = useStyles();
  return (
    <Box width="100%" data-testid="paginatedTable" data-page={page} data-pageCount={pages}>
      <table className={classes.mainTable}>
        <TableHeader columns={columns} sortBy={sortBy} sortDirection={sortDirection} onSortChange={onChangeSort} />
        <TableBody>{customTableRows}</TableBody>
      </table>
      <Box className={classes.pagination}>
        <Pagination
          classes={{ ul: classes.ul }}
          onChange={(_, value) => {
            onPageChange(value);
          }}
          count={pages}
          variant="outlined"
          shape="rounded"
          page={page}
        />
      </Box>
    </Box>
  );
};

export default PaginatedTable;
