import { useQuery, useQueryClient, useMutation } from 'react-query';
import { SERVICE_VISIT_ANNOTATED_PHOTOS } from '../../constants/reactQuery.keys';
import { ISaveIMedicalChartingsParams } from '../../interfaces/params/IServiceVisitParams';
import ServiceVisit from '../../services/ServiceVisit';
import { useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import { IMedicalCharting } from '../../interfaces/IServiceVisits';

const useMedicalCharting = (id: number) => {
  const response = useQuery([SERVICE_VISIT_ANNOTATED_PHOTOS, id], () => ServiceVisit.getMedicalChartings(id), {
    refetchOnWindowFocus: true,
    keepPreviousData: true,

    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'charting notes' })),
  });

  return {
    ...response,
    data: response.data || ({ annotatedPhotos: [], charges: [] } as IMedicalCharting),
  };
};

export const useMedicalChartingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ISaveIMedicalChartingsParams) => ServiceVisit.saveMedicalCharting(params.serviceVisitId, params),
    {
      onSuccess: (res, params: any) => {
        queryClient.invalidateQueries([SERVICE_VISIT_ANNOTATED_PHOTOS, +params.serviceVisitId]);
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};

export default useMedicalCharting;
