import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Box, Button, Dialog, Typography, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DATEPICKER_FORMAT, FULLSCREEN, INPUT_DATE_FORMAT } from 'src/constants/inventory.constants';
import { useStyle } from '../../DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { OrderAdminDialogParams, PickersDate } from '../../../interfaces/IInventoryOrder';
import { useInventoryOrderPeriod, useUpdateInventoryOrderPeriod } from '../../../hooks/queries/useInventoryOrders';

const OrderPeriodConfirmDialog = ({ dismiss, action }: OrderAdminDialogParams) => {
  const classes = useStyle();

  const back = (event: React.MouseEvent) => {
    event.preventDefault();
    dismiss();
  };

  return (
    <Box className={classes.periodConfirmDialog} data-testid="save period dialog">
      <Typography variant="h5">Are you sure?</Typography>
      <Typography>This will close any currently active order periods.</Typography>
      <Box className={classes.periodConfirmButtons}>
        <Button data-testid="cancel save period" onClick={back} variant="outlined" className={classes.actionButton}>
          {' '}
          cancel{' '}
        </Button>
        <Button data-testid="confirm save period" onClick={action} variant="outlined" className={classes.actionButton}>
          {' '}
          confirm{' '}
        </Button>
      </Box>
    </Box>
  );
};

export const BannerDates = () => {
  const classes = useStyle();
  const [triggerDate, setTriggerDate] = useState<string | undefined>();
  const [cutoffDate, setCutoffDate] = useState<string | undefined>();
  const [requiresSave, setRequiresSave] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { data, refetch, isLoading, isFetching } = useInventoryOrderPeriod();
  const { startDate, endDate } = data || {};

  const { mutateAsync: updateOrderPeriod } = useUpdateInventoryOrderPeriod();

  const loading =
    isLoading ||
    (isFetching &&
      ((triggerDate && triggerDate !== moment(startDate).format(INPUT_DATE_FORMAT)) ||
        (cutoffDate && cutoffDate !== moment(endDate).format(INPUT_DATE_FORMAT))));

  const matches = useMediaQuery(FULLSCREEN);

  const updateTriggerDate = (date: PickersDate) => {
    if (date) {
      setTriggerDate(date.format(INPUT_DATE_FORMAT));
      setRequiresSave(true);
    }
  };

  const updateCutoffDate = (date: PickersDate) => {
    if (date) {
      setCutoffDate(date.format(INPUT_DATE_FORMAT));
      setRequiresSave(true);
    }
  };

  const saveBannerDate = async () => {
    if (triggerDate && cutoffDate) {
      setDialogOpen(false);
      setRequiresSave(false);
      await updateOrderPeriod({
        startDate: triggerDate,
        endDate: cutoffDate,
      });
      refetch?.();
    }
  };

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  useEffect(() => {
    startDate && setTriggerDate(moment(startDate).format(INPUT_DATE_FORMAT));
    endDate && setCutoffDate(moment(endDate).format(INPUT_DATE_FORMAT));
  }, [startDate, endDate]);

  return (
    <Box className={classes.adminDate} data-mobile={!matches} data-testid="order period">
      <Typography className={classes.adminDateLabel}>Banner Trigger Date</Typography>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {loading ? (
          <Skeleton
            data-testid="loading start date"
            className={classes.singleSkeleton}
            variant="rect"
            width={150}
            height={45}
          />
        ) : (
          <KeyboardDatePicker
            format={DATEPICKER_FORMAT}
            autoOk
            disableToolbar
            value={moment(triggerDate, INPUT_DATE_FORMAT).format(DATEPICKER_FORMAT)}
            defaultValue={moment(startDate).format(DATEPICKER_FORMAT)}
            onChange={updateTriggerDate}
            className={classes.adminDateInput}
            data-testid="period start date"
          />
        )}
      </MuiPickersUtilsProvider>
      <Typography className={classes.adminDateLabel}>Banner Cut-Off Date</Typography>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {loading ? (
          <Skeleton
            data-testid="loading end date"
            className={classes.singleSkeleton}
            variant="rect"
            width={150}
            height={45}
          />
        ) : (
          <KeyboardDatePicker
            format={DATEPICKER_FORMAT}
            autoOk
            disableToolbar
            value={moment(cutoffDate, INPUT_DATE_FORMAT).format(DATEPICKER_FORMAT)}
            defaultValue={moment(endDate).format(DATEPICKER_FORMAT)}
            onChange={updateCutoffDate}
            className={classes.adminDateInput}
            data-testid="period end date"
          />
        )}
      </MuiPickersUtilsProvider>
      {!requiresSave ? null : (
        <Button
          data-testid="save period"
          onClick={toggleDialog}
          variant="outlined"
          className={classes.bannerButton}
          data-mobile={!matches}
        >
          {' '}
          save changes{' '}
        </Button>
      )}
      <Dialog
        open={dialogOpen}
        maxWidth="lg"
        // eslint-disable-next-line react/no-children-prop
        children={<OrderPeriodConfirmDialog dismiss={toggleDialog} action={saveBannerDate} />}
      />
    </Box>
  );
};
