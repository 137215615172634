/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import moment from 'moment';
import {
  AppBar,
  Tabs,
  Button,
  Box,
  TableContainer,
  Table,
  TableRow,
  Paper,
  TableHead,
  TableBody,
  TablePagination,
  withStyles,
} from '@material-ui/core';

import compile from '../../../utils/toastMessagesCompiler';
import { removeDraft } from '../../../services/ReconciliationReport';
import { TabCustom } from './TodoListTabs/tabGreen.styles';
import InventoryRow from './InventoryRow';
import { dispatch } from '../../../rematch';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';
import { ButtonStatus } from '../../common/TableStyles/ButtonStatus';
import SupplyReceiptsTab from './SupplyReceiptsTab';
import InventoryDefault from './Inventory';
import OrderHistory from './OrderingTab/OrderHistory';
import OrderDashboard from './OrderingTab';
import { Button as CustomButton } from '../../common/Button';
import { Pill } from '../../common/Pill';
import { IProductIdentifiers } from '../../../interfaces/productIdentifiers.interfaces';
import { getTotal } from '../../../utils/inventory.utils';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { useStyles } from './tabs.styles';
import DialogModal from '../../common/DialogModal';
import { INVENTORY_NOTIFICATIONS } from '../../../constants/reactQuery.keys';
import { ROUTES } from '../../../constants/routes.constants';
import { PRACTITIONER_INVENTORY_PATH } from '../../../routes/practitionerRoutes';
import { OrderingStatusContext } from './OrderingTab/OrderBanner';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CUSTOM_SERVICES, READ_INVENTORY } from '../../../constants/actions.constants';
import ProductUtilization from './Inventory/ProductUtilization';

export const DEFAULT_ITEMS_PER_PAGE = 50;

export const InventoryTabCustom = withStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: '#f2f5f5',
    display: 'flex !important',
  },
  indicator: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
}))(Tabs);

export const TabPanel = (props: any) => {
  const { children, value, index, noPadding, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={noPadding ? 0 : 3}>{children}</Box>}
    </div>
  );
};

export const a11yProps = (index: any) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const tabsObj = new Map([
  ['count', 0],
  // ["open", 1],
  ['supply-receipt', 1],
  ['reconciliation', 2],
  ['orders', 3],
  ['new-order', 4],
  ['product-utilization', 5],
]);

export interface Props {
  state: string;
  hasProductsAtRisk: boolean;
  hasPendingSupplyReceipt: boolean;
  hasPendingReconciliation: boolean;
}
const InventoryTab = ({ state, hasProductsAtRisk, hasPendingSupplyReceipt, hasPendingReconciliation }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { inventory, orderByDate, isLoading } = useSelector((state: any) => state.inventoryReconciliation);
  const { userId, permissions } = useSelector(({ auth }: any) => auth);
  const [value, setValue] = React.useState(tabsObj.get(state) || 0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [showRemoveDraftDialog, setShowRemoveDraftDialog] = useState(false);

  const orderingStatus = useContext(OrderingStatusContext);

  const removeDraftReportMutation = useMutation(removeDraft);

  const queryClient = useQueryClient();
  const hasInventoryAccess = Boolean(permissions?.length) && hasAccessTo(READ_INVENTORY, permissions);

  useEffect(() => {
    if (location.pathname === PRACTITIONER_INVENTORY_PATH && !hasAccessTo(CUSTOM_SERVICES, permissions)) {
      history.replace(`${PRACTITIONER_INVENTORY_PATH}/count`);
    }
    const urlTab = location.pathname.split('/').slice(-1)[0];
    setValue(tabsObj.get(urlTab) || 0);
  }, [location.pathname]);

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!userId && hasInventoryAccess) {
      dispatch({
        type: 'inventoryReconciliation/getInventory',
        payload: {
          practitionerId: userId,
        },
      });
    }
  }, [userId, hasInventoryAccess]);

  const handleChange = (event: any, newValue: number) => {
    const values = Array.from(tabsObj.keys());
    history.push(`${PRACTITIONER_INVENTORY_PATH}/${values[newValue]}`);
    setValue(newValue);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOrder = () => {
    if (orderByDate === 'desc') {
      dispatch.inventoryReconciliation.setSort('asc');
    }
    if (orderByDate === 'asc') {
      dispatch.inventoryReconciliation.setSort('desc');
    }
    dispatch.inventoryReconciliation.sortByDate(orderByDate);
  };

  const navigateToReconciliationWizard = (reportId?: number) => {
    if (!reportId) {
      dispatch({
        type: 'inventoryPractitioner/createDraft',
        payload: {
          callback: () => {
            history.push('/inventory/reconciliation');

            queryClient.invalidateQueries(INVENTORY_NOTIFICATIONS);
          },
        },
      });
    }
    // by the else must load the drafted report
    else {
      dispatch({
        type: 'inventoryPractitioner/fetchDraftReport',
        payload: {
          practitionerId: userId,
          callback: () => history.push(`/inventory/reconciliation`),
        },
      });
    }
  };

  const navigateToSignReport = (id: number) => {
    dispatch({
      type: 'inventoryPractitioner/fetchReviewedReconciliationReport',
      payload: {
        reportId: id,
        successCallback: () => {
          history.push(`/inventory/completed-inventory-report/${id}`, { from: history.location.pathname });
        },
      },
    });
  };

  const getStatus = ({ status, id }: { status: string; id: number }) => {
    switch (status) {
      case 'pending_review':
        return (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Pill title="Pending Review" color="gray" />
            <CustomButton
              title="VIEW"
              onClick={() => {
                navigateToSignReport(id);
              }}
              className={classes.signButton}
            />
          </Box>
        );
      case 'pending_signature':
        return (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Pill title="Pending Signature" color="orange" />
            <CustomButton
              title="SIGN"
              onClick={() => {
                navigateToSignReport(id);
              }}
              className={classes.signButton}
            />
          </Box>
        );
      case 'closed':
        return (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Pill title="Closed" color="green" />
            <CustomButton
              title="VIEW"
              onClick={() => {
                navigateToSignReport(id);
              }}
              className={classes.signButton}
            />
          </Box>
        );
      case 'draft':
        return (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Pill title="Draft" color="orange" />
            <CustomButton
              title="Continue"
              onClick={() => {
                navigateToReconciliationWizard(id);
              }}
              className={classes.signButton}
            />
          </Box>
        );
      default:
        return <ButtonStatus style={{ textTransform: 'capitalize' }}>{status}</ButtonStatus>;
    }
  };

  const navigateProductIdentifiers = (reportId: number, serviceId: number) => {
    const { reconciliationReportItems } = inventory.find(({ id }: any) => id === reportId);
    const filteredAssets = reconciliationReportItems.filter((item: any) => serviceId === item.serviceId);
    const allowPartialSale = filteredAssets[0]?.allowPartialSale;

    const payload: IProductIdentifiers = {
      aggregatedField: 'counted',
      productName: filteredAssets[0].product,
      vials: allowPartialSale ? filteredAssets.length : null,
      units: allowPartialSale ? getTotal(filteredAssets, 'counted') : null,
      hasScannedProducts: false,
      identifiers: filteredAssets.map(
        ({
          internalSerialNumber,
          counted,
          expected,
          allowPartialSale,
          initialNumberOfUnits,
          markedAsEmpty,
          reconstitutedAt,
          expireAt,
          lot,
        }: any) => ({
          internalSerialNumber,
          units: allowPartialSale ? counted : null,
          counted,
          expected,
          allowPartialSale,
          currentNumberOfUnits: expected,
          initialNumberOfUnits,
          markedAsEmpty,
          reconstitutedAt,
          expireAt,
          lot,
        })
      ),
    };

    dispatch({
      type: 'productIdentifiers/setProductIdentifiersData',
      payload,
    });

    history.push('/product-identifiers');
  };

  const discardDraftReport = async () => {
    try {
      setShowRemoveDraftDialog(false);

      await removeDraftReportMutation.mutateAsync();

      // when removing from backend removing the draft from the store too
      dispatch.inventoryReconciliation.removeDraft();

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'The draft report',
            action: 'discarded',
          }),
          type: 'success',
        },
      });

      queryClient.invalidateQueries(INVENTORY_NOTIFICATIONS);
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'discarding',
            element: 'the draft report',
          }),
          type: 'error',
        },
      });
    }
  };

  const disableNewReportButton = (): boolean => inventory.some(({ status }: any) => status !== 'closed');

  const draftReportExists = inventory.some(({ status }: any) => status === 'draft');

  const hideOrderHistory = orderingStatus?.disabled || !hasInventoryAccess;
  const hideCurrentOrder = !orderingStatus?.active || location.pathname !== ROUTES.NEW_ORDER || !hasInventoryAccess;

  return (
    <>
      <AppBar position="static">
        <InventoryTabCustom value={value} onChange={handleChange} aria-label="simple tabs example">
          <TabCustom
            {...a11yProps(0)}
            label={
              <div className={classes.inventoryTab} data-cy="inventoryCountTab">
                Inventory Count
                {hasProductsAtRisk && <div className={classes.pendingNotification} />}
              </div>
            }
          />
          {/* <TabCustom label='Open Inventory' {...a11yProps(1)} /> */}
          <TabCustom
            {...a11yProps(1)}
            label={
              <div data-cy="supplyReceipts" className={classes.inventoryTab}>
                Supply Receipts
                {hasPendingSupplyReceipt && (
                  <div data-cy="receiptNotification" className={classes.pendingNotification} />
                )}
              </div>
            }
          />

          <TabCustom
            {...a11yProps(2)}
            label={
              <div className={classes.inventoryTab}>
                Reconciliation
                {hasPendingReconciliation && <div className={classes.pendingNotification} />}
              </div>
            }
          />

          {hideOrderHistory ? (
            <div {...a11yProps(3)} />
          ) : (
            <TabCustom {...a11yProps(3)} label={<div className={classes.inventoryTab}>Orders</div>} />
          )}
          {hideCurrentOrder ? (
            <div {...a11yProps(4)} />
          ) : (
            <TabCustom {...a11yProps(4)} label={<div className={classes.inventoryTab}>Current Order</div>} />
          )}

          <TabCustom
            {...a11yProps(5)}
            label={
              <div className={classes.inventoryTab} data-testid="productUtilizationTab" data-cy="productUtilizationTab">
                Product Utilization
              </div>
            }
          />
        </InventoryTabCustom>
      </AppBar>
      <TabPanel noPadding value={value} index={0}>
        <InventoryDefault />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        Open Inventory
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        <SupplyReceiptsTab />
      </TabPanel>
      <TabPanel value={value} index={2} style={{ backgroundColor: '#fff' }}>
        {isLoading ? (
          <MultipleSkeleton />
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Box component="p" style={{ marginRight: 'auto' }} fontSize="16px" color="#949494">
                Reconciliation
              </Box>
              <Button
                className={classes.newReportButton}
                onClick={() => navigateToReconciliationWizard()}
                disabled={disableNewReportButton()}
              >
                NEW REPORT
              </Button>
              {draftReportExists && (
                <Button
                  className={classes.newReportButton}
                  onClick={() => setShowRemoveDraftDialog(true)}
                  disabled={removeDraftReportMutation.isLoading}
                >
                  DISCARD DRAFT
                </Button>
              )}
            </Box>
            <TableContainer component={Paper} style={{ backgroundColor: '255, 255, 255', boxShadow: 'none' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHeader className={classes.headTableCell} />
                    <TableCellHeader onClick={() => handleOrder()} className={classes.headTableCell}>
                      Date
                    </TableCellHeader>
                    <TableCellHeader className={classes.headTableCell}>Status</TableCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => (
                    <InventoryRow
                      key={row.id}
                      reportId={row.id}
                      showExpand={row.status !== 'draft'}
                      date={moment(row.createdAt).format('MM/DD/YYYY')}
                      dataCollapse={row.productsList}
                      status={getStatus(row)}
                      iconCallback={navigateProductIdentifiers}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center">
              {inventory.length >= DEFAULT_ITEMS_PER_PAGE && (
                <TablePagination
                  rowsPerPageOptions={[DEFAULT_ITEMS_PER_PAGE]}
                  component="div"
                  count={inventory.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Box>
          </>
        )}
      </TabPanel>
      {hideOrderHistory ? null : (
        <TabPanel value={value} index={3}>
          <OrderHistory />
        </TabPanel>
      )}
      {hideCurrentOrder ? null : (
        <TabPanel value={value} index={4}>
          <OrderDashboard />
        </TabPanel>
      )}
      <TabPanel value={value} index={5}>
        <ProductUtilization />
      </TabPanel>
      <DialogModal
        open={showRemoveDraftDialog}
        closeModal={() => setShowRemoveDraftDialog(false)}
        confirm={discardDraftReport}
      >
        Are you sure you want to discard the draft report?
      </DialogModal>
    </>
  );
};

export default InventoryTab;
