import React, { MouseEvent } from 'react';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { isEqual } from 'lodash';

const useStyles = makeStyles({
  ehrButton: {
    padding: '0.5rem 1rem',
    borderRadius: '0.375rem',
    textTransform: 'unset',
    minWidth: '7.625rem',
    textWrap: 'nowrap',
  },
  ehrBtnColor_text: {
    border: '1px solid #303030',
    backgroundColor: '#fff',
    minWidth: '4.5rem',
    maxHeight: '1.875rem',
  },
  ehrBtnColor_undefined: {},
  ehrBtnColor_default: {
    border: '1px solid #c8c8c8',
    backgroundColor: '#fff',
  },
  ehrBtnColor_inherit: {},
  ehrBtnColor_primary: {},
  ehrBtnColor_secondary: {},
  ehrBtnColor_danger: {
    border: '1px solid #EC403E',
    backgroundColor: '#fff',
  },
});

// @ts-expect-error
interface EHRButtonProps extends ButtonProps {
  dataCy: string;
  text: string;
  href?: string;
  color: ButtonProps['color'] | 'text' | 'danger' | 'gray' | undefined;
  component?: 'button' | 'span' | 'a';
}

const EHRButton = React.memo(
  (props: EHRButtonProps) => {
    const classes = useStyles();
    const history = useHistory();
    const { className, onClick, text, href, color } = props;
    const { dataCy, variant, ...buttonProps } = props;

    const ehrButtonOnClick = (e: MouseEvent<HTMLButtonElement>): void => {
      if (typeof onClick === 'function') {
        onClick(e);
      } else if (typeof href === 'string') {
        history?.push(href);
      }
    };

    const style = {
      ...(color === 'danger' && { color: '#EC403E' }),
    };

    return (
      <Button
        data-cy={dataCy}
        {...buttonProps}
        // eslint-disable-next-line no-nested-ternary
        color={color === 'text' ? 'default' : color === 'danger' ? undefined : color}
        className={classNames(classes.ehrButton, className, classes[`ehrBtnColor_${color}`])}
        variant={variant || 'contained'}
        onClick={ehrButtonOnClick}
        style={style}
        disableElevation
      >
        {text}
      </Button>
    );
  },
  (oldProps, newProps) => isEqual(oldProps, newProps)
);

export { EHRButton };
