import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  canvas: {
    '& svg': {
      backgroundSize: 'cover !important',
    },
  },
  images: {
    position: 'absolute',
    width: '100%',
    height: '1350px',
    top: 0,
    left: 0,
    backgroundSize: 'contain',
    objectFit: 'contain',
    paddingTop: '35px',
  },
  containerFull: {
    marginBottom: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsArrowRight: {
    pointerEvents: 'auto',
    fontFamily: 'Messina Sans Light',
    color: '#fff',
    backgroundColor: 'black',
    opacity: 0.1,
    borderRadius: '300%',
    minWidth: '35px !important',
    height: '35px !important',
    padding: 0,
  },
  buttonsArrowLeft: {
    padding: 0,
    borderRadius: '100%',
    pointerEvents: 'auto',
    fontFamily: 'Messina Sans Light',
    color: '#fff',
    backgroundColor: 'black',
    opacity: 0.1,
    transform: 'rotate(180deg)',
    minWidth: '35px !important',
    height: '35px !important',
  },
  containerArrowBottom: {
    paddingRight: 20,
    width: '100px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
