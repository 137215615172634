import * as yup from 'yup';
import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  VALID_EMAIL,
  PHONE,
  PHYSICIAN,
  PRACTITIONER,
} from '../../constants/schemaForm.constants';

export const schemaForm = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  phone: yup.string().required(PHONE),
  physicianId: yup.string().required(PHYSICIAN),
  practitionerId: yup.string().required(PRACTITIONER),
  addressLine1: yup.string().required('Address is a required field'),
  addressLine2: yup.string(),
  city: yup.string().required('City is a required field'),
  state: yup.string().required('State is a required field'),
  zipCode: yup
    .string()
    .required('Zip code is a required field')
    .matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code'),
});

export const patientSchemaFormForPractitioner = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  phone: yup.string().required(PHONE),
  addressLine1: yup.string().required('Address is a required field'),
  addressLine2: yup.string(),
  city: yup.string().required('City is a required field'),
  state: yup.string().required('State is a required field'),
  zipCode: yup
    .string()
    .required('Zip code is a required field')
    .matches(/^\d{5}(-\d{4})?$/, 'Must be a valid zip code'),
});

export const specialOffersSchemaForm = yup.object().shape({
  name: yup.string().required('Specials name is a required field'),
  discounts: yup.array().of(
    yup.object().shape({
      orderProductId: yup.number().required('Product is a required field').typeError('Product is a required field'),
      quantity: yup.number().required('Quantity is a required field').typeError('Quantity must be a number'),
      discount: yup.number().required('Discount is a required field').typeError('Discount must be a number'),
    })
  ),
  supplierIds: yup.array().of(yup.number()),
  expirationDate: yup.date().required('Expiration date is a required field'),
  note: yup.string(),
});
