/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Box, Button, makeStyles, Menu, MenuItem, TableCell } from '@material-ui/core';
import classnames from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme: any) => ({
  mainColor: {
    color: theme.palette.primary.main,
  },
  container: {
    width: '100%',
    textAlign: 'center',
  },
  title: {
    fontSize: '15px',
    fontFamily: 'Messina Sans Bold',
    whiteSpace: 'nowrap',
  },
  subtitle: {
    fontSize: '12px',
    fontFamily: 'Messina Sans Bold',
    whiteSpace: 'nowrap',
  },
  text: {
    fontSize: '13px',
    fontFamily: 'Messina Sans Bold',
    color: '#969696',
  },
  textSecondary: {
    fontSize: '12px',
    fontFamily: 'Messina Sans Regular',
    color: '#969696',
  },
}));

interface Props {
  index: number;
  serviceId: number;
  totalNumberOfUnits?: number;
  openVials: number;
  isColumnHide: boolean;
  handleHideColumn?: () => void;
  serviceName?: string;
  unopenedVials?: number;
  countedUnits?: number;
  isHeader?: boolean;
  handleShowColumn?: () => void;
  showAllColumns?: boolean;
  allowPartialSale?: boolean;
}

export const ServiceCell = (props: Props) => {
  const {
    index,
    serviceName,
    unopenedVials,
    openVials,
    countedUnits,
    handleHideColumn,
    totalNumberOfUnits,
    isHeader,
    handleShowColumn,
    isColumnHide,
    showAllColumns,
    allowPartialSale,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onShow = () => {
    handleShowColumn && handleShowColumn();
    handleClose();
  };

  const onHide = () => {
    handleHideColumn && handleHideColumn();
    handleClose();
  };

  const handleColumnVisibility = (showAllColumns: boolean = false, isColumnHide: boolean): string => {
    return isColumnHide && !showAllColumns ? 'none' : '';
  };

  return (
    <TableCell
      style={{
        verticalAlign: allowPartialSale || isHeader ? 'top' : 'middle',
        padding: '6px',
        backgroundColor: Boolean(index % 2) ? '#fff' : '#F2F5F5',
        display: handleColumnVisibility(showAllColumns, isColumnHide),
      }}
    >
      <Box display="flex" alignItems="start">
        <div className={classnames({ [classes.container]: !allowPartialSale && !isHeader })}>
          {isHeader && (
            <div data-cy="serviceName" className={classnames(classes.title, classes.mainColor)}>
              {serviceName}
            </div>
          )}
          {allowPartialSale ? (
            <>
              <div className={classnames(classes.subtitle, { [classes.mainColor]: isHeader })}>
                {unopenedVials !== undefined && `${unopenedVials} Unopened vials`}
              </div>
              <div className={classes.text}>{openVials} Open vials</div>
              <div className={classnames({ [classes.textSecondary]: unopenedVials, [classes.subtitle]: countedUnits })}>
                {totalNumberOfUnits && (
                  <div
                    className={classnames({ [classes.subtitle]: !unopenedVials, [classes.mainColor]: !unopenedVials })}
                  >
                    {totalNumberOfUnits} {!unopenedVials ? 'Total' : 'Units'}
                  </div>
                )}
                {countedUnits && (
                  <div className={classes.textSecondary}>
                    {countedUnits} {unopenedVials && 'Units'}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className={classnames(classes.subtitle, { [classes.mainColor]: isHeader })}>
              {totalNumberOfUnits} {isHeader && 'Total'}
            </div>
          )}
        </div>
        {isHeader && (
          <Box textAlign="center">
            <Box height={20}>
              {isColumnHide && <VisibilityOffIcon data-cy="hiddenServiceIcon" htmlColor="#A6A6A6" />}
            </Box>
            <Button
              data-cy="serviceOptionMenu"
              variant="text"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <ArrowDropDownIcon />
            </Button>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              {isColumnHide ? (
                <MenuItem data-cy="showService" onClick={() => onShow()}>
                  Show column
                </MenuItem>
              ) : (
                <MenuItem data-cy="hideService" onClick={() => onHide()}>
                  Hide column
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}
      </Box>
    </TableCell>
  );
};
