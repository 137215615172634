import * as d3 from 'd3';
import React, { useRef, useEffect } from 'react';

function BarChart({ height, data }: any) {
  const ref: any = useRef();

  useEffect(() => {
    d3.select(ref.current).attr('width', '100%').attr('height', height);
  });

  useEffect(() => {
    draw();
  }, [data]);

  const draw = () => {
    const svg = d3.select(ref.current);
    const selection = svg.selectAll('rect').data(data);
    // @ts-ignore
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data)])
      .range([0, height - 100]);
    selection
      .transition()
      .duration(300)
      .attr('height', (d: any) => yScale(d))
      .attr('y', (d: any) => height - yScale(d));

    selection
      .enter()
      .append('rect')
      .attr('x', (_d, i) => i * 30)
      .attr('y', (_d) => height)
      .attr('width', 20)
      .attr('height', 0)
      .attr('fill', '#199f90')
      .transition()
      .duration(300)
      .attr('height', (d: any) => yScale(d))
      .attr('y', (d: any) => height - yScale(d));

    selection
      .exit()
      .transition()
      .duration(300)
      .attr('y', (_d) => height)
      .attr('height', 0)
      .remove();
  };

  return (
    <div>
      <svg ref={ref} />
    </div>
  );
}

export default BarChart;
