import React, { useContext } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ClassNameMap } from 'src/types/Dom';
import { Error as ErrorIcon } from '@material-ui/icons';
import DeliveryDate from '../DeliveryDate';
import DeliveryAddress from '../DeliveryAddress';
import { OrderContext } from '..';
import { DeliveryAddressFormFormProps } from './DeliveryAddressForm';

const EDIT_INSTRUCTIONS =
  // eslint-disable-next-line max-len
  'To edit this address, please update your Business Address in your Account Settings. Please note that verification of a new business address by the suppliers can take up to 2 weeks, though it is often faster.';

// eslint-disable-next-line react/no-unused-prop-types
export const OrderDeliveryOptions = ({ classes }: ClassNameMap & { FormProps?: DeliveryAddressFormFormProps }) => {
  const { orderData: { deliveryLocation = null } = {} } = useContext(OrderContext) || {};

  return (
    <Container classes={classes}>
      <Typography className={classes?.heading}>Delivery</Typography>
      {/* FormProps && <DeliveryAddressForm {...FormProps} classes={classes} /> */}
      <Typography className={classes?.labelTitleMid}>Delivery Address</Typography>
      <DeliveryAddress location={deliveryLocation} className={classes?.shadowedLabel} />
      <Box className={classes?.editInstructions}>
        <ErrorIcon />
        <Typography component="p">{EDIT_INSTRUCTIONS}</Typography>
      </Box>
      <Typography className={classes?.labelTitleMid}>Preferred Delivery Date (Not Guaranteed)</Typography>
      <DeliveryDate className={classes?.legacyDatePicker} ProgressParams={{ height: 32 }} />
    </Container>
  );
};
export default withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subHeading: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: '#555',
  },
  labelTitleMid: {
    fontSize: 15,
    marginTop: 20,
    marginBottom: 10,
  },
  legacyDatePicker: {
    '& input': {
      fontSize: 14,
    },
  },
  shadowedLabel: {
    fontSize: '14px',
    opacity: 0.4,
  },
  editInstructions: {
    margin: 0,
    marginTop: 10,
    padding: 15,
    backgroundColor: 'rgb(254,242,226)',
    color: 'rgb(133,76,0)',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '8px',
    border: '1px solid rgb(237,225,209)',
    '& p': {
      margin: 'auto auto auto 15px',
      fontSize: '0.6rem',
      fontFamily: 'Messina Sans Regular',
    },
    '& p > span': {
      fontFamily: 'Roboto',
    },
    '& svg': {
      fontSize: 22,
      color: 'rgb(247,147,13)',
    },
  },
}))(OrderDeliveryOptions);
