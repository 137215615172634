import React, { useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { dispatch } from '../../../rematch';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import ProductIdentifiers from "../../common/ProductIdentifiers/ProductIdentifiers";

const useQuery = () => new URLSearchParams(useLocation().search);

const NewSupplyReceiptsPageStep2 = () => {
  const query = useQuery();
  const history = useHistory();
  const { supplyReceiptId, serviceId }: any = useParams();
  const { supplyReceipt, productsSelected } = useSelector((state: any) => state.newSupplyReceipt);
  const serviceSelected = productsSelected.find(({ id }: any) => id === +serviceId) || { assets: [] };
  const editionDisabled = supplyReceipt.status === 'closed';

  useEffect(() => {
    dispatch({ type: 'newSupplyReceipt/getSupplyReceipt', payload: supplyReceiptId });
  }, []);

  const goSupplyReceiptStep1 = () => {
    history.push(`/administrator/new-supply-receipt-step-1/${supplyReceipt.id}`);
  };

  const handleRemove = (assetsSelected: number[]) => {
    dispatch({
      type: 'newSupplyReceipt/removeSupplyReceiptAssets',
      payload: {
        assets: assetsSelected,
        supplyReceiptId: supplyReceipt.id,
        onSuccess: serviceSelected.assets.length > 1 ? undefined : goSupplyReceiptStep1,
      },
    });
  };

  const goBack = () => (query.get('readonly') === 'true' ? history.goBack() : goSupplyReceiptStep1());

  const Breadcrumb = (
    <Box mb="17px">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Inventory" />
        <BreadcrumbText text="Supply receipts" linkTo="/administrator/supply-receipts" />
        <BreadcrumbText text="Products Identifiers" isActive />
      </BreadcrumbsContainer>
    </Box>
  );

  const TableData = (
    <ProductIdentifiers
      aggregatedField="initialNumberOfUnits"
      service={serviceSelected}
      editionDisabled={editionDisabled}
      goBack={goBack}
      handleRemove={handleRemove}
    />
  );

  return (
    <Box width="100%">
      {Breadcrumb}
      {TableData}
    </Box>
  );
};

export default NewSupplyReceiptsPageStep2;
