import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ReconstitutionExpiry } from '../../services/ReconstitutionExpiry';
import { ExpirationDays } from '../../interfaces/ISectionProducts';
import { RECONSTITUTION_EXPIRY } from '../../constants/reactQuery.keys';

const useReconstitutionExpiry = () =>
  useQuery({
    queryKey: [RECONSTITUTION_EXPIRY],
    queryFn: (): Promise<ExpirationDays> => ReconstitutionExpiry.getReconstitutionExpiry(),
  });

const useUpdateReconstitutionExpiry = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (days: number): Promise<void> => {
      await ReconstitutionExpiry.updateReconstitutionExpiry(days);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [RECONSTITUTION_EXPIRY] });
    },
  });
};

export { useReconstitutionExpiry, useUpdateReconstitutionExpiry, useReconstitutionExpiry as default };
