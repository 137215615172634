/* eslint-disable max-len */
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { CardContent } from '@material-ui/core';
import { PATIENT_PHOTOS } from '../../constants/reactQuery.keys';
import { RootState, dispatch } from '../../rematch';
// this later could be a hook to get the other notifications too
import { useNotifications } from '../../hooks/queries/useNotifications';
import CustomersService from '../../services/Customers';
import { Card } from '../../components/common/card';
import Header from '../../components/PatientProfile/Header';
import AssignedPractitioner from '../../components/PatientProfile/AssignedPractitioner';
import ContactInfo from '../../components/PatientProfile/MainSection/ContactInfo';
import MainSection from '../../components/PatientProfile/MainSection/MainSection';
import DiagnosesServices from '../../components/PatientProfile/Services';
import ConsentHistory from '../../components/PatientProfile/ConsentHistory';
import { PhotoContainer } from '../../components/PatientProfile/PhotoContainer';
import PhotoRequest from '../../components/PatientProfile/PhotoRequest';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import HistoricalPhotoContainer from '../../components/PatientProfile/HistoricalPhotoContainer';
import ActivityLogContainer from '../../components/PatientProfile/ActivityLogContainer';
import StickyNotes from '../../components/PatientProfile/MainSection/Notes/StickyNotes';
import MedicalHistoryAcknowledgements from '../../components/PatientProfile/MainSection/Notes/MedicalHistoryAcknowledgements';
import { useCustomCustomerNotes } from '../../hooks/queries/useCustomCustomerNotes';
import { useCustomPatientHistoryAcknowledgements } from '../../hooks/queries/useCustomPatientMedicalHistoryAcknowledgements';
import { hasAccessTo } from '../../utils/auth.utils';
import { READ_ADMIN_NOTE } from '../../constants/actions.constants';
import NextAppointment from '../../components/PatientProfile/NextAppointment';

const { markPhotoAsSeen } = CustomersService;

const PhysicianPatientProfile = ({
  match: {
    params: { patientId },
  },
}: {
  match: { params: { patientId: string } };
}) => {
  const history = useHistory();
  const permissions = useSelector(({ auth }: RootState) => auth.permissions);
  const { isLoading: isLoadingStickyNotes, data: customerNotesCollection = [] } = useCustomCustomerNotes(patientId);
  const { isLoadingMHA, dataMHA: medicalHistoryAckCollection = [] } =
    useCustomPatientHistoryAcknowledgements(patientId);
  const markAsReadMutation = useMutation(markPhotoAsSeen);
  const queryClient = useQueryClient();
  const { practitioner } = useSelector(({ patient }: RootState) => patient.contactInfo);

  const { data: notifications } = useNotifications(patientId);

  useEffect(() => {
    dispatch.patient.fetchBasicInfo({ patientId: +patientId });
    dispatch.patient.fetchContactInfo(+patientId);
    dispatch.patient.fetchMedicalProfile(+patientId);
    dispatch.patient.fetchCustomerConsents(+patientId);
    dispatch.patient.fetchPatientsNotes(+patientId);
    dispatch.patient.fetchCustomerDiagnoses(+patientId);
    dispatch.patient.fetchPhotos(+patientId);
    dispatch.patient.fetchServiceVisits(+patientId);
    // dispatch.patient.fetchPractitioners();
    dispatch.patient.fetchServiceGroups(+patientId);

    // this is used for the photo request too
    dispatch.patient.fetchMessagesThreads(+patientId);

    return () => {
      dispatch.patient.resetPatientProfile();
    };
  }, [patientId]);

  const navigate = () => {
    history.goBack();
  };

  const handleShowConversations = () => {
    history.push(`/patient/${patientId}/messaging`);
  };

  // in the future maybe we should mark as seen only if the patient is a patient of the currect doctor
  const markPhotosAsSeen = async (photoId: number) => {
    try {
      await markAsReadMutation.mutateAsync(photoId);
      queryClient.invalidateQueries([PATIENT_PHOTOS, patientId]);
    } catch (error) {
      Sentry.captureMessage(`${error}`, 'debug' as Sentry.Severity);
    }
  };

  return (
    <main>
      <NavigationBar title="Patient Profile" onBackButtonClick={navigate} />
      <article
        style={{
          padding: '12px',
          width: 'inherit',
          backgroundColor: '#e3e3e3',
        }}
      >
        <Header
          patientId={patientId}
          handleShowConversations={handleShowConversations}
          unreadMessagesCount={notifications?.unreadMessagesCount}
          practitionerId={String(practitioner?.id)}
        />
        <PhotoRequest />
        {patientId && <NextAppointment patientId={patientId} />}
        {patientId && (
          <Card>
            <ContactInfo patientId={patientId} />
          </Card>
        )}
        <AssignedPractitioner />
        <DiagnosesServices />
        <MainSection patientId={patientId} hideContactInfo />
        {hasAccessTo(READ_ADMIN_NOTE, permissions) && (
          <>
            <Card>
              <CardContent>
                <MedicalHistoryAcknowledgements
                  patientId={patientId}
                  medicalHistoryAckCollection={medicalHistoryAckCollection}
                  isLoading={isLoadingMHA}
                />
                <StickyNotes
                  patientId={patientId}
                  customerNotesCollection={customerNotesCollection}
                  isLoading={isLoadingStickyNotes}
                />
              </CardContent>
            </Card>
          </>
        )}
        <ConsentHistory />
        <PhotoContainer />
        <HistoricalPhotoContainer patientId={patientId} showUnseenPhotosNotification onPhotoOpen={markPhotosAsSeen} />
        <ActivityLogContainer patientId={patientId} />
      </article>
    </main>
  );
};

export default PhysicianPatientProfile;
