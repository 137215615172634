import { Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const ButtonModal = withStyles({
  root: {
    backgroundColor: '#E7EEED',
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '14px',
    textTransform: 'uppercase',
    height: '36px',
    color: '#000',
  },
})(Button);

export const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: '10px',
  },
  container: {
    height: '207px',
    width: '391px',
    padding: '30px',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '10px 0',
    width: '100%',
  },
  scanned: {
    padding: '10px',
    border: '1px solid rgba(57, 59, 63, 0.12)',
    borderRadius: '4px',
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '-0.0535714px',
    color: '#12574D',
  },
  form: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  inputsSection: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '10px 0',
    padding: 0,
  },
  secondaryButton: {
    color: '#393B3F',
    border: 'solid 1px #FBDADA',
    backgroundColor: '#FBDADA',
    height: '36px',
  },
  applyButton: {
    color: '#ffffff !important',
    backgroundColor: '#12574d',
    height: '36px',
    '&:hover': {
      backgroundColor: '#104e45',
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingBottom: '15px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '-0.0428571px',
    color: '#393B3F',
    mixBlendMode: 'normal',
    opacity: 0.4,
  },
  value: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '23px',
    color: '#393B3F',
  },
  closeButton: {
    height: '25px',
    width: '25px',
    minWidth: '25px',
  },
  doneButton: {
    width: '120px',
  },
  dataContainer: {
    backgroundColor: '#fff',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    margin: '0 auto',
    padding: '20px',
    width: '95%',
  },
  questionContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    width: '100%',
  },
  plusChar: {
    '& > p': {
      color: theme.palette.primary.main,
      marginTop: 0,
      position: 'relative',
      left: '8px',
    },
  },
  inputQuantity: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    width: '80px',
  },
}));
