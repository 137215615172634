import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Tab, Tabs } from '@material-ui/core';
import Page from 'src/components/common/Page';
import TabItem from 'src/components/DashboardPractitioner/Tabs/TodoListTabs/TabItem';
import { IPhysician } from 'src/interfaces/IPhysician';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { useMedspaAdminsByUserGroup } from '../../hooks/queries/useMedspaAdmins';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from '../../constants/general.constants';
import { IMedspaAdminList } from '../../interfaces/IMedspaAdminList';
import { IUserGroup } from '../../interfaces/IUserGroup';
import { usePhysicianInfo } from '../../hooks/queries/usePhysicians';
import MedspaAdminPaymentMethods from './MedspaAdminPaymentMethodsSettings';
import MedspaAdminMedspaSettings from './MedspaAdminMedspaSettings';
import AdminSettings from './AdminSettings';
import { MedspaAdminGfeManagementSetting } from './MedspaAdminGfeManagementSetting';
import MedspaBackAccountPaymentMethods from './MedspaBackAccountPaymentMethodsSettings';

const MedspaAdminSettings = () => {
  const [admins, setAdmins] = useState<IMedspaAdminList>([]);
  const [userGroup, setUserGroup] = useState<IUserGroup>();
  const [medicalDirectorId, setMedicalDirectorId] = useState<number>(0);
  const [isLegacyRole, setIsLegacyRole] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const [tab, setTab] = useState(0);

  const user = useSelector(({ auth }: any) => auth);

  const { data: adminsData = [], isLoading: isLoadingAdmins } = useMedspaAdminsByUserGroup(
    user.user.userGroupId,
    user.roleName === MEDSPA_ADMIN_ROLE
  );

  const {
    isLoading: isLoadingUserGroup,
    userGroupData,
    refetchUserGroup,
    pendingMedicalDirector,
    onboardingCompleted,
    onboardingEnabled,
  } = useMedspaAdminOnboarding();

  const { physicianInfo: physician, isLoading: isLoadingPhysician } = usePhysicianInfo(medicalDirectorId);

  const canUpdateGfeSetting = userGroupData?.roleName && userGroupData.roleName !== PORTRAIT_LEGACY;

  useEffect(() => {
    if (userGroupData && !isLoadingUserGroup) {
      setUserGroup(userGroupData);
      setMedicalDirectorId(userGroupData.medicalDirectorId || 0);
      setIsLegacyRole(userGroupData.roleName === PORTRAIT_LEGACY || userGroupData.roleName === PORTRAIT_LEGACY_PLUS);
    }

    if (adminsData && adminsData.length && !isLoadingAdmins) {
      // This is exclusively for the logged user to be the first in the list
      const loggedUser = adminsData.find((admin) => admin.id === user.userId);
      const otherAdmins = adminsData.filter((admin) => admin.id !== user.userId);
      const reorderedAdmins = loggedUser ? [loggedUser, ...otherAdmins] : [...adminsData];

      setCurrentUser(loggedUser);
      setAdmins(reorderedAdmins);
    }
  }, [userGroup, userGroupData, adminsData, physician, isLoadingUserGroup, isLoadingAdmins, isLoadingPhysician]);

  if (isLoadingUserGroup || isLoadingAdmins || isLoadingPhysician) {
    return (
      <div style={{ textAlign: 'center', width: '100%', padding: '100px 0' }}>
        <CircularProgress />
      </div>
    );
  }

  const handleTabChange = (_e: any, newTab: number) => setTab(newTab);

  return (
    <Page title="Account Settings">
      <Tabs
        TabIndicatorProps={{ style: { backgroundColor: 'black' } }}
        value={tab}
        onChange={handleTabChange}
        style={{ borderBottom: '1px solid lightgrey', width: '100%' }}
      >
        <Tab label="Admin" id="medspa-account-settings-admin-tab" />
        <Tab label="Medspa" id="medspa-account-settings-medspa-tab" />
        <Tab label="Payment Methods" id="medspa-account-settings-payment-methods-tab" />
        {canUpdateGfeSetting && <Tab label="GFE Management" id="medspa-account-settings-gfe-management-tab" />}
      </Tabs>

      <TabItem value={tab} index={0}>
        {userGroup && (
          <AdminSettings isLegacyRole={isLegacyRole} admins={admins} currentUser={currentUser} userGroup={userGroup} />
        )}
      </TabItem>

      <TabItem value={tab} index={1}>
        {userGroup && (
          <MedspaAdminMedspaSettings
            userGroup={userGroup}
            refetchUserGroup={refetchUserGroup}
            medicalDirector={physician as IPhysician}
            onboardingCompleted={onboardingCompleted && onboardingEnabled}
          />
        )}
      </TabItem>

      <TabItem value={tab} index={2}>
        <MedspaAdminPaymentMethods />
        <MedspaBackAccountPaymentMethods />
      </TabItem>
      {canUpdateGfeSetting && (
        <TabItem value={tab} index={3}>
          <MedspaAdminGfeManagementSetting
            userGroup={userGroup as IUserGroup}
            refetchUserGroup={refetchUserGroup}
            pendingMedicalDirector={pendingMedicalDirector}
          />
        </TabItem>
      )}
    </Page>
  );
};

export default MedspaAdminSettings;
