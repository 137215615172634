import React from 'react';
import { Box } from '@material-ui/core';

import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';

const AdminServiceVisitAssetBreadcrumb = () => (
  <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Inventory" />
        <BreadcrumbText text="Assets" isActive linkTo="/administrator/assets" />
      </BreadcrumbsContainer>
    </Box>
  </Box>
);

export default AdminServiceVisitAssetBreadcrumb;
