import NumberFormat from 'react-number-format';
import React, { useEffect, useState } from 'react';
import { useStyles } from './OtherTenders.styles';

export const TenderSection = ({
  tenderName,
  tenderIcon,
  tenderValue,
  tenderVoucher,
  tenderValueOnSave,
  tenderVoucherOnSave,
  isTotalTenderValueValid,
  disabled,
}: {
  tenderName: string | null;
  tenderIcon?: React.ReactNode;
  tenderVoucher: string;
  tenderValue: string;
  tenderVoucherOnSave: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tenderValueOnSave: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isTotalTenderValueValid: (obj: any, value: string) => boolean;
  disabled?: boolean;
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(tenderValue);
  const [shouldChange, setShouldChange] = useState(true);

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    tenderValueOnSave(e);
    setInputValue(e.target.value);
  };

  useEffect(() => {
    /* eslint-disable no-unused-expressions */
    tenderValue === '' ? setInputValue('') : shouldChange && (setInputValue(tenderValue), setShouldChange(false));
  }, [tenderValue]);

  return (
    <div className={classes.section}>
      {tenderIcon}
      {tenderName && <div className={classes.title}>{tenderName}</div>}
      <input
        type="text"
        value={tenderVoucher}
        onChange={tenderVoucherOnSave}
        placeholder="Code/Note"
        className={classes.input}
        style={{ marginRight: '20px' }}
        disabled={disabled}
      />
      <NumberFormat
        decimalScale={2}
        autoComplete="off"
        placeholder="Amount"
        value={inputValue}
        onChange={onAmountChange}
        isAllowed={(inputObj) => isTotalTenderValueValid(inputObj, tenderValue)}
        className={classes.input}
        disabled={disabled}
        onFocus={(e) => e.target.select()}
      />
    </div>
  );
};
