import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  mainSection: {
    display: 'flex',
    padding: '18px 0 0 0',
  },
  otherTenderTitle: {
    borderRight: '8px solid #E7EEED',
    display: 'flex',
    alignItems: 'center',
    padding: '0 35px',
    textAlign: 'right',
    fontSize: '18px',
    color: '#3A3B3F',
    fontFamily: 'Messina Sans Light',
  },
  tendersSection: {
    width: '100%',
    paddingLeft: '20px',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '15px',
  },
  title: {
    width: '65%',
    marginRight: '15px',
    fontFamily: 'Arial !important',
    fontSize: '15px',
  },
  input: {
    height: '46px',
    width: '100%',
    border: '1px solid #979797',
    borderRadius: '4px',
    fontSize: '15px',
    color: '#3A3B3F',
    paddingLeft: '10px',
    '&::placeholder': {
      color: '#C0C0C0',
    },
    '&:disabled': {
      border: '1px solid #EEEEEE',
    },
  },
  noPointsAvailableText: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    color: '#3A3B3F',
  },
  referralSection: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0 5px 0',
  },
  referralCreditTitle: {
    width: '30%',
    marginRight: '15px',
  },
  availableCreditSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: '15px',
  },
  availableLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  availableRight: {
    alignSelf: 'center',
  },
  availableLabel: {
    marginRight: '19px',
  },
  plusButton: {
    padding: '5px',
    backgroundColor: '#E7EEED',
    minWidth: 0,
    fontSize: '30px',
    height: '24px',
    width: '24px',
    paddingTop: 0,
  },
  dialog: {
    borderRadius: '6px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 24px 20px 20px',
  },
  referralTitle: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '24px',
    letterSpacing: '-0.05px',
    color: '#12574D',
    fontWeight: 100,
  },
  dialogContent: {
    padding: 0,
    minWidth: '430px',
    display: 'flex',
    flexDirection: 'column',
  },
  stripe: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    padding: '9px 24px 9px 19px',
    width: '100%',
    marginBottom: '21px',
    fontFamily: 'Messina Sans Light',
    color: '#3A3B3F',
  },
  stripeTitle: {
    letterSpacing: '-0.07px',
    fontSize: '20px',
  },
  stripeTitleRight: {
    letterSpacing: '-0.05px',
    fontSize: '13px',
  },
  referralsAvailableSection: {
    width: '100%',
  },
  buttonSection: {
    paddingLeft: '20px',
    paddingBottom: '16px',
  },
  applyButton: {
    backgroundColor: '#E7EEED',
    width: '152px',
    height: '47px',
    '&:hover': {
      backgroundColor: '#E7EEED',
    },
  },
  removeButton: {
    backgroundColor: '#FBDADA',
    width: '152px',
    height: '47px',
    '&:hover': {
      backgroundColor: '#FBDADA',
    },
  },
  referralsAvailable: {
    fontFamily: 'Messina Sans Regular',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    paddingLeft: '76px',
    color: '#3A3B3F',
  },
  referralsAvailableAmount: {
    fontSize: '20px',
    letterSpacing: '-0.07px',
    fontWeight: 'bold',
    paddingRight: '5px',
  },
  referralsAvailableName: {
    fontSize: '15px',
    letterSpacing: '-0.05px',
  },
  redeemedSection: {
    width: '100%',
    paddingLeft: '76px',
  },
  redeemedNames: {
    letterSpacing: '-0.05px',
    color: '#3A3B3F',
    fontSize: '15px',
    fontFamily: 'Messina Sans Regular',
    paddingBottom: '5px',
  },
}));
