import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  makeStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { PENDING_STEPS_FOR_GFE_WARNING } from 'src/constants/onboarding.constants';
import {
  GFE_SETTING_NO_PROVIDER_NAME,
  GFE_SETTING_OWN_PROVIDER_NAME,
  GFE_SETTING_PORTRAIT_PROVIDER_NAME,
  NORMAL_REQUEST,
  NORMAL_REQUEST_NAME,
  URGENT_REQUEST,
  URGENT_REQUEST_NAME,
} from '../../constants/general.constants';
import { IGfeRequest } from '../../types/Patient';
import { RootState } from '../../rematch';
import { PatientState } from '../../rematch/patient.model';
import Banner from './Banner';

const useStyles = makeStyles((theme: any) => ({
  warning: {
    backgroundColor: '#FFF3E0',
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: '4px 4px 4px 4px',
    width: '90%',
  },
  urgentWarning: {
    backgroundColor: '#B2DFDB',
    color: '#004D40',
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: '4px 4px 4px 4px',
    width: '90%',
  },
  dialogPaper: {
    maxWidth: '700px',
    borderRadius: '10px',
    width: '100%',
    margin: '0',
  },
  title: {
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  disabledOption: {
    backgroundColor: '#f0f0f0',
    color: '#c0c0c0',
    pointerEvents: 'none',
    opacity: 0.5,
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    width: '45%',
    border: `1px solid #12574d`,
    borderRadius: theme.shape.borderRadius,
  },
  radio: {
    color: '#12574d',
    '&.Mui-checked': {
      color: '#12574d',
    },
  },
  selected: {
    backgroundColor: 'rgba(18, 87, 77, 0.1)',
    border: `2px solid #12574d`,
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    textAlign: 'right',
  },
  price: {
    fontWeight: 'normal',
    marginTop: theme.spacing(1),
    color: 'black',
  },
  button: {
    margin: theme.spacing(1),
    fontWeight: 'bold',
  },
  cancelButton: {
    borderColor: '#12574d',
    color: '#12574d',
  },
  requestButton: {
    backgroundColor: '#12574d',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const addressFieldMap: Record<string, string> = {
  addressLine1: 'Address',
  state: 'State',
  city: 'City',
  zipCode: 'Zip Code',
};

const ConfirmGfeModal = ({
  isFlex,
  isLuxe,
  isLegacyPlus,
  gfeSetting,
  currentGfeRequest,
  openDialog,
  setOpenDialog,
  onRequestNewGfe,
  hasServiceGroups,
}: {
  isFlex: boolean;
  isLuxe: boolean;
  isLegacyPlus: boolean;
  gfeSetting: string;
  currentGfeRequest: IGfeRequest | null;
  openDialog: boolean;
  setOpenDialog: (dialog: boolean) => void;
  onRequestNewGfe: (isUrgent: number) => void;
  hasServiceGroups: boolean;
}) => {
  const classes = useStyles();
  const patientData = useSelector(({ patient }: RootState) => patient);
  const [isUrgentGfeRadio, setIsUrgentGfeRadio] = useState<number | null>(null);
  const { pendingMedicalDirector } = useMedspaAdminOnboarding();
  const profileIncomplete = patientData.basicInfo.customerStatus === 'incomplete';
  const isUrgent = currentGfeRequest && currentGfeRequest.requestType === URGENT_REQUEST_NAME;
  const standardRequestDisabled =
    (currentGfeRequest && currentGfeRequest.requestType === NORMAL_REQUEST_NAME) ||
    gfeSetting === GFE_SETTING_OWN_PROVIDER_NAME ||
    gfeSetting === GFE_SETTING_PORTRAIT_PROVIDER_NAME;

  const PRICING_STRUCTURE = {
    doNotRoute: {
      flex: { standard: '$30 per GFE', urgent: '$40 per GFE', upgrade: '+$10 per GFE' },
      luxe: { standard: '$20 per GFE', urgent: '$30 per GFE', upgrade: '+$10 per GFE' },
      legacyPlus: {},
      lite: { standard: '$30 per GFE', urgent: '$40 per GFE', upgrade: '+$10 per GFE' },
    },
    routeOwnProvider: {
      flex: { urgent: '$40 per GFE' },
      luxe: { urgent: '$30 per GFE' },
      lite: { urgent: '$40 per GFE' },
      legacyPlus: {},
    },
    routePortraitProvider: {
      flex: { standard: '$30 per GFE', urgent: '+$10 per GFE' },
      luxe: { standard: '$20 per GFE', urgent: '+$10 per GFE' },
      lite: { standard: '$30 per GFE', urgent: '+$10 per GFE' },
      legacyPlus: {},
    },
  };

  const checkMissingInfo = (data: PatientState) => {
    const missingInfo = [];

    if (!hasServiceGroups) {
      // TODO: ER-4519 we should not be rendering the service management under the inventory sub route...
      missingInfo.push(
        <>
          Service Groups: please add or import <a href="/practitioner/inventory/count">here</a>
        </>
      );
    }

    if (!data.photos || data.photos.length === 0) {
      missingInfo.push('Photo');
    }

    if (!data.basicInfo.dateOfBirth) {
      missingInfo.push('Date of Birth');
    }

    const addressFields = ['addressLine1', 'city', 'state', 'zipCode'];
    const missingAddressFields = addressFields
      // @ts-ignore
      .filter((field) => !data.contactInfo[field])
      .map((field) => addressFieldMap[field]);

    if (missingAddressFields.length > 0) {
      missingInfo.push(`Contact Information: ${missingAddressFields.join(', ')}`);
    }

    return missingInfo;
  };

  const getGfePricing = (): Record<string, string> => {
    let pricing;

    switch (gfeSetting) {
      case GFE_SETTING_NO_PROVIDER_NAME:
        pricing = getPricingByType(PRICING_STRUCTURE.doNotRoute);

        if (currentGfeRequest?.requestType === NORMAL_REQUEST_NAME) {
          pricing.urgent = pricing.upgrade;
        }
        break;
      case GFE_SETTING_OWN_PROVIDER_NAME:
        pricing = getPricingByType(PRICING_STRUCTURE.routeOwnProvider);
        break;
      case GFE_SETTING_PORTRAIT_PROVIDER_NAME:
        pricing = getPricingByType(PRICING_STRUCTURE.routePortraitProvider);
        break;
      default:
        pricing = {};
    }

    return pricing;
  };

  function getPricingByType(pricingStructure: any) {
    if (isFlex) {
      return pricingStructure.flex;
    }

    if (isLuxe) {
      return pricingStructure.luxe;
    }

    if (isLegacyPlus) {
      return pricingStructure.legacyPlus;
    }

    return pricingStructure.lite;
  }

  const displayPrice = (type: string) => {
    const pricing = getGfePricing();
    return type === 'standard' ? pricing.standard : pricing.urgent;
  };

  const getIncompleteProfileMessage = () => {
    if (profileIncomplete) {
      const missingInfo = checkMissingInfo(patientData);

      if (missingInfo.length > 0) {
        return (
          <Paper className={classes.warning} square>
            <Typography variant="body2" style={{ textAlign: 'left' }}>
              The Medical Profile is incomplete. Please add the missing information before requesting a GFE. Missing
              Info:
              <ul>
                {missingInfo.map((info, index) => (
                  /* eslint-disable react/no-array-index-key */
                  <li key={index}>{info}</li>
                ))}
              </ul>
            </Typography>
          </Paper>
        );
      }
      return (
        <Paper className={classes.warning} square>
          <Typography variant="body2" style={{ textAlign: 'left' }}>
            The Medical Profile review is incomplete. Please click on the &quot;Complete Customer Profile&quot; button
            under Contact Info to confirm patient basic and medical information is accurate.
          </Typography>
        </Paper>
      );
    }
    return null;
  };

  const getRequestTypeMessage = () => {
    if (currentGfeRequest) {
      const formattedDate = moment(currentGfeRequest.createdAt).local().format('MMM D, YYYY [at] h:mm A');
      const message = isUrgent
        ? `Urgent GFE request was made on ${formattedDate}. These are completed within 24hr with the exception of
        holidays.`
        : `Standard GFE request was made on ${formattedDate}.`;

      const warningStyle = isUrgent ? classes.urgentWarning : classes.warning;

      return (
        <Paper className={warningStyle} square>
          <Typography variant="body2">{message}</Typography>
        </Paper>
      );
    }
    return null;
  };

  const handleRequestClick = () => {
    if (isUrgentGfeRadio !== null) {
      onRequestNewGfe(isUrgentGfeRadio);
      setOpenDialog(false);
      setIsUrgentGfeRadio(null);
    }
  };

  const renderContent = () => {
    if (pendingMedicalDirector) {
      return (
        <div className={classes.content}>
          <Banner content={PENDING_STEPS_FOR_GFE_WARNING} type="warning" />
        </div>
      );
    }
    if (profileIncomplete) {
      return <div className={classes.content}>{getIncompleteProfileMessage()}</div>;
    }
    if (currentGfeRequest) {
      return <div className={classes.content}>{getRequestTypeMessage()}</div>;
    }
    return null;
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={classes.title}>Request GFE</DialogTitle>
      {renderContent()}
      <DialogContent className={classes.content}>
        {!isUrgent && !profileIncomplete && !pendingMedicalDirector && (
          <>
            <Paper
              className={`
              ${classes.option}
              ${isUrgentGfeRadio !== null && !isUrgentGfeRadio ? classes.selected : ''}
              ${standardRequestDisabled ? classes.disabledOption : ''}
            `}
            >
              <RadioGroup
                className={classes.cardContainer}
                value={isUrgentGfeRadio}
                onChange={() => setIsUrgentGfeRadio(NORMAL_REQUEST)}
              >
                <FormControlLabel
                  disabled={standardRequestDisabled || false}
                  value={NORMAL_REQUEST}
                  control={<Radio className={classes.radio} />}
                  label={<Typography color="textPrimary">Standard GFE Request</Typography>} // Set text to black
                />
                <Typography variant="body2" color="textPrimary">
                  Standard GFEs are completed within 72hr (as long as the patient profile and any required action has
                  been completed).
                </Typography>
                <div className={classes.priceContainer}>
                  <Typography variant="body1" className={classes.price}>
                    {displayPrice('standard')}
                  </Typography>
                </div>
              </RadioGroup>
            </Paper>
            <Paper
              className={`${classes.option} ${
                isUrgentGfeRadio !== null && isUrgentGfeRadio === URGENT_REQUEST ? classes.selected : ''
              }`}
            >
              <RadioGroup
                className={classes.cardContainer}
                value={isUrgentGfeRadio}
                onChange={() => setIsUrgentGfeRadio(URGENT_REQUEST)}
              >
                <FormControlLabel
                  value={URGENT_REQUEST}
                  control={<Radio className={classes.radio} />}
                  label={<Typography color="textPrimary">Urgent Clearance Request</Typography>}
                />
                <Typography variant="body2" color="textPrimary">
                  Urgent GFEs are prioritized for completion and are completed within 24hr (as long as the patient
                  profile and any required action has been completed).
                </Typography>
                <div className={classes.priceContainer}>
                  <Typography variant="body1" className={classes.price}>
                    {displayPrice('urgent')}
                  </Typography>
                </div>
              </RadioGroup>
            </Paper>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenDialog(false)}
          variant="outlined"
          className={`${classes.button} ${classes.cancelButton}`}
        >
          Cancel
        </Button>
        {!isUrgent && !profileIncomplete && (
          <Button
            onClick={() => handleRequestClick()}
            variant="contained"
            className={`${classes.button} ${classes.requestButton}`}
          >
            Request
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmGfeModal;
