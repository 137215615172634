import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Collapse,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Checkbox,
  FormGroup,
  IconButton,
} from '@material-ui/core/';

import { Menu } from '@material-ui/icons/';
import IconCross from '../../common/IconCross';
import { useRowStyles } from './PurchaseOrders.styles';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';
import { ButtonStatus } from '../../common/TableStyles/ButtonStatus';
import ButtonAhref from '../../common/ButtonAhref';
import { PURCHASE_ORDER_STATUS } from '../../../constants/general.constants';

const handlePurchaseOrderStatus = (status: string, href: string, action?: any) => {
  switch (status) {
    case 'waiting_to_receive':
      return (
        <ButtonStatus href={href} onClick={action} style={{ backgroundColor: '#e7e7e7', height: 'auto' }}>
          Waiting to receive
        </ButtonStatus>
      );
    case 'draft':
      return (
        <ButtonStatus
          href={href}
          onClick={action}
          style={{ backgroundColor: '#fac980', color: '#393b3f', height: 'auto' }}
        >
          Draft
        </ButtonStatus>
      );
    case 'complete':
      return <ButtonStatus style={{ background: '#d1f1bd', cursor: 'default', height: 'auto' }}>Complete</ButtonStatus>;
    default:
      break;
  }
  return null;
};

export interface PurchaseOrdersRowProps {
  id: number;
  supplierName: string;
  referenceNumber: string;
  internalNote: string;
  orderType: string;
  date: string;
  closedDate: string;
  status: string;
  dataCollapse: any[];
  iconCallback: (reportId: number, serviceId: number, id: number) => void;
  handleCheck: (id: number) => void;
}

const PurchaseOrdersRow: React.FC<PurchaseOrdersRowProps> = (props) => {
  const {
    id: purchaseOrderId,
    supplierName,
    referenceNumber,
    internalNote,
    orderType,
    date,
    closedDate,
    status,
    dataCollapse,
    iconCallback,
    handleCheck,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const history = useHistory();

  const ordersTypes: { [key: string]: string } = {
    supplier_purchase: 'Supplier purchase',
    samples: 'Samples',
    supplier_exchange: 'Supplier exchange',
    non_traditional_transfer: 'Non-traditional transfer',
  };

  const navigateToDraft = (event: any) => {
    event.preventDefault();
    history.push(`/administrator/new-purchase-order/${purchaseOrderId}`);
  };

  const iconClick = ({ serviceId, assets, id }: any) => {
    if (assets.length) {
      iconCallback(purchaseOrderId, serviceId, id);
    }
  };

  const handleHref = ({ serviceId, assets, id }: any): string => {
    if (assets.length) {
      return `/administrator/purchase-order/${purchaseOrderId}/product-identifiers/${id}/${serviceId}`;
    }
    return '';
  };

  const handleOnClick =
    (data: any) =>
      (event: React.MouseEvent): void => {
        event.preventDefault();
        iconClick(data);
      };

  return (
    <>
      <TableRow data-cy="row" className={classes.root} style={{ backgroundColor: open ? '#f2f5f5' : '#fff' }}>
        <TableCell width="10">
          <FormGroup row>
            <Checkbox data-cy="selectOrder" onChange={() => handleCheck(purchaseOrderId)} color="primary" />
          </FormGroup>
        </TableCell>
        <TableCell width="50">
          <IconButton data-cy="expandIcon" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <IconCross open={open} />
          </IconButton>
        </TableCell>
        <TableCellCustom data-cy="idCell">{purchaseOrderId}</TableCellCustom>
        <TableCellCustom data-cy="referenceNumberCell">{referenceNumber}</TableCellCustom>
        <TableCellCustom data-cy="internalNoteCell">{internalNote}</TableCellCustom>
        <TableCellCustom data-cy="orderTypeCell">{ordersTypes[orderType]}</TableCellCustom>
        <TableCellCustom data-cy="supplierNameCell">{supplierName}</TableCellCustom>
        <TableCellCustom data-cy="creationDateCell" width="100">
          <Box color="#000" component="span">
            {date}
          </Box>
        </TableCellCustom>
        <TableCellCustom data-cy="closeDateCell" width="100">
          {status === PURCHASE_ORDER_STATUS.COMPLETE && (
            <Box color="#000" component="span">
              {closedDate}
            </Box>
          )}
        </TableCellCustom>
        <TableCell data-cy="statusCell">
          <Box display="flex" justifyContent="space-between">
            {handlePurchaseOrderStatus(status, `/administrator/new-purchase-order/${purchaseOrderId}`, navigateToDraft)}
          </Box>
        </TableCell>
        <TableCell>
          <ButtonAhref text="VIEW" href={`/administrator/new-purchase-order/${purchaseOrderId}`} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: '10%' }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>{internalNote}</Box>
            <Box margin={1}>
              <Table data-cy="expandedTable">
                <TableHead>
                  <TableRow>
                    <TableCellHeader>Product</TableCellHeader>
                    <TableCellHeader>Quantity</TableCellHeader>
                    <TableCellHeader>IDs</TableCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataCollapse
                    ? dataCollapse.map((data: any) => (
                      <TableRow data-cy="expandedRow" key={data.name} style={{ height: '56px' }}>
                        <TableCellCustom data-cy="productNameCell">{data.name}</TableCellCustom>
                        <TableCellCustom data-cy="quantityCell">{data.quantity}</TableCellCustom>
                        <TableCellCustom>
                          {!data.assets?.length ? (
                            <Menu
                              data-cy="disabledMenu"
                              className={classes.menuIcon}
                              style={{ color: !data.assets?.length ? '#E4E7EB' : undefined }}
                            />
                          ) : (
                            <a
                              data-cy="enabledMenu"
                              href={handleHref(data)}
                              onClick={handleOnClick(data)}
                              style={{ color: '#000' }}
                            >
                              <Menu
                                className={classes.menuIcon}
                                style={{ color: !data.assets?.length ? '#E4E7EB' : undefined }}
                              />
                            </a>
                          )}
                        </TableCellCustom>
                      </TableRow>
                    ))
                    : null}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PurchaseOrdersRow;
