import { useMutation, useQueryClient } from 'react-query';
import { PROFILE_PROGRESS } from '../../constants/reactQuery.keys';
import ProfileProgress from '../../services/ProfileProgress';

export const useUpdateProfileProgress = () => {
  const queryClient = useQueryClient();

  return useMutation(({ stepName }: { stepName: string }) => ProfileProgress.updateProfileProgress({ stepName }), {
    onSuccess: () => {
      queryClient.invalidateQueries([PROFILE_PROGRESS]);
    },
  });
};
