import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Collapse, Grid, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { useForceOpenCloseServiceVisit } from 'src/hooks/serviceVisit/useServiceVisit';

import { TableRowStriped } from '../DashboardPractitioner/Tabs/TodoListTabs/rowItem.styles';
import IconCross from '../common/IconCross';
import { ReactComponent as Eye } from '../../assets/images/icon-awesome-eye.svg';
import { ReactComponent as DollarSign } from '../../assets/images/icon-awesome-dollar-sign.svg';
import { ROUTES } from '../../constants/routes.constants';
import { ADMIN_ROLE, PHYSICIAN_ROLE, PRACTITIONER_ROLE } from '../../constants/general.constants';
import { TableCellData, useStyles } from './RowItem.styles';
import ServiceVisitScannedProductList from '../ServiceVisitContainer/ServiceVisitScannedProductList';
import { AFTER_PHOTO_TYPE, BEFORE_PHOTO_TYPE } from '../../constants/serviceVisits/serviceVisit.constants';
import { BeforePhotosStep } from '../ServiceVisitContainer/BeforePhotoStep';
import { AfterPhotosStep } from '../ServiceVisitContainer/AfterPhotosStep';
import { useServiceVisitAssetsScanned } from '../../hooks/serviceVisit/useServiceVisitAssets';
import { useServiceVisitPhotos } from '../../hooks/serviceVisit/useServiceVisitPhotos';
import IServiceVisit, { IServiceVisitPhoto } from '../../interfaces/IServiceVisits';
import MedicalChartingStep from '../ServiceVisitContainer/MedicalChartingStep/MedicalChartingStep';
import useMedicalCharting from '../../hooks/serviceVisit/useMedicalCharting';
import { useSelectedServices } from '../../hooks/serviceVisit/useLineItems';
import { useCustomer } from '../../hooks/customer/usePatient';
import { useServices } from '../../hooks/queries/services/useServices';
import ServiceVisitNotes from '../ServiceVisitContainer/ServiceVisitNotes';
import { hasAccessTo } from '../../utils/auth.utils';
import { IS_SUPPORT, MEDICAL_DIRECTOR_NOTE_UPDATE } from '../../constants/actions.constants';
import { RootState } from '../../rematch';
import ConfirmModal from '../common/ConfirmModal';
import { ConfirmForceOpenCloseSVContent } from './ConfirmForceOpenCloseSVContent';
import { ReactComponent as GearIcon } from '../../assets/images/gear.svg';

interface IRowItemV3Props {
  patientId: number;
  visit: IServiceVisit;
  striped: number;
  getRowId: React.Dispatch<React.SetStateAction<number | null>>;
  rowId: number | null;
}

const RowItemV3 = ({ patientId, visit, striped, getRowId, rowId }: IRowItemV3Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const { userType, permissions } = useSelector(({ auth }: RootState) => auth);
  const { pathname } = history.location;
  const [showForceOpenedModal, setShowForceOpenedModal] = useState(false);

  const isPhysician = userType === PHYSICIAN_ROLE;

  const serviceVisitId = visit.id;

  const isSupport = hasAccessTo(IS_SUPPORT, permissions);

  const allowNavigateToServiceVisit = [PRACTITIONER_ROLE, PHYSICIAN_ROLE].includes(userType || '') || isSupport;
  const allowNavigateToCheckout = userType === PRACTITIONER_ROLE;

  const { data: serviceVisitAssets, isLoading: isLoadingServiceVisitAssets } = useServiceVisitAssetsScanned(
    +serviceVisitId,
    open
  );

  const {
    data: photos = [],
    isLoading: isLoadingPhotos,
    isFetching: isFetchingPhotos,
  } = useServiceVisitPhotos(+serviceVisitId, open);

  const navigateToServiceVisit = () => {
    history.push(ROUTES.NEW_SERVICE_VISIT_VERSION(patientId, serviceVisitId), { from: pathname });
  };

  const navigateToCheckout = () => {
    history.push(ROUTES.NEW_SERVICE_VISIT_CHECKOUT_VERSION(patientId, serviceVisitId), { from: pathname });
  };

  const beforePhotos = photos.filter(
    ({ photoType, serviceVisitId: photoSVId }: IServiceVisitPhoto) =>
      photoType === BEFORE_PHOTO_TYPE && serviceVisitId === photoSVId
  );
  const afterPhotos = photos.filter(
    ({ photoType, serviceVisitId: photoSVId }: IServiceVisitPhoto) =>
      photoType === AFTER_PHOTO_TYPE && serviceVisitId === photoSVId
  );
  const { data: customer } = useCustomer(+patientId);

  const { data: patientServices, isFetching: isFetchingServices } = useServices(+patientId);

  const { data: lineItems, selectedServiceIds } = useSelectedServices(+serviceVisitId);
  const services = patientServices.filter(({ id }) => selectedServiceIds.includes(id));

  const { data: medicalCharting, isFetching: isFetchingAnnotatedPhotos } = useMedicalCharting(+serviceVisitId);

  const medicalNoteAllowed = hasAccessTo(MEDICAL_DIRECTOR_NOTE_UPDATE, permissions) && isPhysician;

  const forceOpenCloseServiceVisit = useForceOpenCloseServiceVisit(+patientId, +serviceVisitId);

  useEffect(() => {
    setOpen(rowId === serviceVisitId);
  }, [rowId]);

  return (
    <>
      <TableRowStriped style={{ backgroundColor: striped % 2 ? '#f2f5f5' : '#fff' }}>
        <TableCell width={50} style={{ verticalAlign: 'top', paddingTop: '12px' }}>
          {!visit.opened && (
            <IconButton
              size="small"
              onClick={() => {
                getRowId(serviceVisitId);
                setOpen(!open);
              }}
              style={{ padding: '7px' }}
            >
              <IconCross open={open} />
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <div className={classes.dataClass} style={{ fontWeight: open ? 'bold' : 400 }}>
            Service visit
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.dataClass} style={{ fontWeight: open ? 'bold' : 400 }}>
            {`${moment(visit.createdAt).format('MM/DD/YYYY')} - ${moment(visit.createdAt).fromNow()}`}
          </div>
        </TableCell>
        <TableCellData>
          {visit.services.map(({ name }) => (
            <Fragment key={`${visit.id}-${name}`}>
              {' '}
              <div
                key={get(name, 'id', null)}
                className={classes.serviceName}
                style={{ fontWeight: open ? 'bold' : 'normal' }}
              >
                {name}
              </div>
            </Fragment>
          ))}
        </TableCellData>
        <TableCellData>
          {visit.services.map(({ id: serviceId, units }) => (
            <div key={`unit-${serviceId}`}>{Math.floor(+units)}</div>
          ))}
        </TableCellData>
        <TableCellData>
          {visit.fromNewEhr && allowNavigateToServiceVisit && (
            <Box display="flex">
              {userType !== ADMIN_ROLE && (
                <Box mr={2}>
                  <Button onClick={() => navigateToServiceVisit()} className={classes.boxActionItem}>
                    <Eye />
                  </Button>
                </Box>
              )}
              {visit.hasCheckout && allowNavigateToCheckout && userType === PRACTITIONER_ROLE && (
                <Button onClick={() => navigateToCheckout()} className={classes.boxActionItem}>
                  <DollarSign />
                </Button>
              )}

              {userType === ADMIN_ROLE && isSupport && (
                <Button
                  data-testid="force-close-open-modal-v3"
                  style={{ backgroundColor: '#FBDADA' }}
                  onClick={() => setShowForceOpenedModal(true)}
                >
                  <GearIcon />
                  <span style={{ width: '4px' }} />
                  {`FORCE ${visit.opened ? 'CLOSE' : 'OPEN'}`}
                </Button>
              )}
            </Box>
          )}
        </TableCellData>
      </TableRowStriped>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, maxWidth: '450px' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box mb={2}>
                <Grid container item xs={12} direction="row" justify="space-between" alignItems="center">
                  <Typography className={classes.typographyClass}>Service Details</Typography>
                </Grid>
              </Box>
              {visit.fromNewEhr && visit.annotatedPhotos.length !== 0 ? (
                <Box my={2} className={classes.medicalChartingContainer}>
                  <MedicalChartingStep
                    serviceVisit={visit}
                    patient={customer}
                    services={services}
                    lineItems={lineItems}
                    medicalCharting={medicalCharting}
                    patientPhotos={photos}
                    selectedServiceIds={selectedServiceIds}
                    serviceVisitId={+serviceVisitId}
                    isLoading={isFetchingServices || isFetchingAnnotatedPhotos || isFetchingPhotos}
                  />
                </Box>
              ) : null}
              <Box my={2} className={classes.inventoryContainer}>
                {/* eslint-disable no-nested-ternary */}
                {visit.fromNewEhr ? (
                  isLoadingServiceVisitAssets ? (
                    <Skeleton height={150} style={{ margin: '0 17px' }} />
                  ) : (
                    <div className={classes.inventoryBox}>
                      <div className={classes.inventoryTitle}>
                        <Typography>Inventory update</Typography>
                      </div>
                      {serviceVisitAssets?.length ? (
                        <ServiceVisitScannedProductList
                          serviceVisitAssets={serviceVisitAssets}
                          disabled
                          serviceVisitId={visit.id}
                        />
                      ) : null}
                    </div>
                  )
                ) : null}
              </Box>
              {visit.fromNewEhr && visit.photos.length !== 0 && isLoadingPhotos ? (
                <Skeleton height={150} style={{ margin: '0 17px' }} />
              ) : (
                <>
                  <Box my={2} className={classes.photosContainer}>
                    <BeforePhotosStep
                      opened={visit.opened}
                      isPhotoRequired={false}
                      photos={beforePhotos}
                      isLoading={false}
                    />
                  </Box>
                  <Box my={2} className={classes.photosContainer}>
                    <AfterPhotosStep
                      opened={visit.opened}
                      isPhotoRequired={false}
                      photos={afterPhotos}
                      isLoading={false}
                    />
                  </Box>
                </>
              )}
              <Box my={2} className={classes.notesContainer}>
                <ServiceVisitNotes
                  serviceVisitId={+serviceVisitId}
                  serviceVisit={visit}
                  canWriteMedicalNote={medicalNoteAllowed}
                  isPhysician={isPhysician}
                />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <ConfirmModal
        title={`Force ${visit.opened ? 'close' : 'open'}`}
        contentText={<ConfirmForceOpenCloseSVContent opened={visit.opened} />}
        openDialog={showForceOpenedModal}
        setOpenDialog={setShowForceOpenedModal}
        onClickConfirm={forceOpenCloseServiceVisit}
      />
    </>
  );
};

export default RowItemV3;
