import React from 'react';
import { useSelector } from 'react-redux';
import { CardContent, Grid } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from './consentHistory.styles';
import { Card, SmallTitle } from '../common/card';

const ConsentHistory = () => {
  const classes = useStyles();
  const { customerConsents } = useSelector(({ patient }: any) => patient);

  if (!customerConsents.length) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <SmallTitle title="Consent history" style={{ marginTop: '0', marginBottom: '18px' }} />
        <Grid container spacing={1}>
          {customerConsents.map(({ serviceGroupId, serviceGroupName, updatedAt, consentPdfUrl }: any) => (
            <Grid key={`${serviceGroupId}_${updatedAt}`} item md={3}>
              <a className={classes.consent} href={consentPdfUrl} target="_blank" rel="noopener noreferrer">
                {serviceGroupName}
              </a>
              <div className={classes.date}>{moment(updatedAt).format('MM/DD/YYYY')}</div>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ConsentHistory;
