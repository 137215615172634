import React, { useState, useEffect, useMemo } from 'react';
import { getFeaturesByRole } from 'src/services/Features';
import * as UsersService from 'src/services/Users';
import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  makeStyles,
  Box,
  TableRow,
  TableCell,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { Search as SearchIcon, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { IUser } from 'src/interfaces/IUser';
import { PortraitTable } from 'src/components/common/TableNew/Table';
import classnames from 'classnames';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { showSnackbar } from 'src/utils/global';
import { SNACKBAR_ERROR } from 'src/constants/general.constants';
import MultipleSelector from 'src/components/common/Custom/MultipleSelector';
import Page from 'src/components/common/Page';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
    height: '47px',
    lineHeight: '47px',
  },
  message: {
    height: '40px',
    background: '#F2D1AB',
    lineHeight: '40px',
    padding: '0px 5px',
    marginTop: '10px',
  },
  dropdown: {
    fontFamily: 'Messina Sans Regular',
    height: '46px',
  },
  formDropdown: {
    width: '255px',
    left: '30px',
  },
  formInputSelect: {
    top: '-4px',
    color: '#000',
  },
  patientListContainer: {
    maxHeight: '1000px',
    height: '84.7vh',
  },
  tableGeneral: {
    position: 'relative',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'hidden',
  },
  infiScrollContainer: {
    overflow: 'auto',
    marginTop: '10px',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  filterContainer: {
    justifyContent: 'space-between',
    padding: '0 16px',
    marginBottom: '2rem',
  },
  search: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '14px',
  },
  formControl: {
    width: '150px',
    marginLeft: '21px',
  },
}));

const TABLE_COLUMNS = [
  { id: 'id', title: 'ID' },
  { id: 'firstName', title: 'Fist Name' },
  { id: 'lastName', title: 'Last Name' },
  { id: 'email', title: 'Email' },
  { id: 'userType', title: 'User Type' },
  { id: 'features', title: 'Features' },
];

const initialFilters = {
  search: '',
  sortBy: '',
  sortDirection: '',
  practitionerId: '',
  startDate: '',
  endDate: '',
  featureFlags: [],
};

const featureFlagsSchema = yup.object().shape({
  featureFlags: yup.array(),
});

const UsersFlagsPage = () => {
  const [filters, setFilters] = useState<any>(initialFilters);
  const debouncedFilters = useDebouncedValue(filters, 500);
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { data: featuresByRole, isLoading: isLoadingFeaturesByRole } = useQuery(['FEATURES_BY_ROLE', 1], () =>
    getFeaturesByRole()
  );
  const {
    data: usersResponse,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
    fetchNextPage: fetchMoreUsers,
    isError: isErrorUsers,
    hasNextPage: hasNextPageUsers,
  } = useInfiniteQuery(
    ['USERS', debouncedFilters],
    ({ pageParam = 1 }) =>
      UsersService.getUsers({
        page: pageParam,
        search: filters.search,
        sortBy: filters.sortBy,
        sortDirection: filters.sortDirection,
        keys: filters.featureFlags,
      }),
    {
      getNextPageParam: ({ meta }) => {
        const hasMore = meta.currentPage < meta.totalPages;
        if (hasMore) {
          return meta.currentPage + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );
  const { control } = useForm<FormData>({
    resolver: yupResolver(featureFlagsSchema),
    defaultValues: { featureFlags: [] },
  });
  const featureFlagsOptions = useMemo(
    () =>
      Object.keys(featuresByRole || {})
        .map((role: string) => (featuresByRole || {})[role])
        .flat()
        .map((key) => ({ name: key, value: key })),
    [featuresByRole]
  );
  const doSortBy = (column: string, direction: string) => {
    setFilters({ ...filters, ...{ sortBy: column, sortDirection: direction } });
  };

  useEffect(
    () => () => {
      queryClient.resetQueries(['USERS', debouncedFilters]);
    },
    []
  );

  const removeFeatureFromIdentity = async (key: string, userId: number) => {
    const { success, message } = await UsersService.removeFeatureFlagFromUser(key, userId);
    if (!success) {
      showSnackbar(message, SNACKBAR_ERROR);
      return;
    }
    queryClient.invalidateQueries({ queryKey: ['USERS', debouncedFilters] });
    queryClient.refetchQueries({ queryKey: ['USERS', debouncedFilters] });
  };
  const addFeatureToIdentity = async (key: string, userId: number) => {
    const { success, message } = await UsersService.addFeatureFlagToUser(key, userId);
    if (!success) {
      showSnackbar(message, SNACKBAR_ERROR);
      return;
    }
    queryClient.invalidateQueries({ queryKey: ['USERS', debouncedFilters] });
    queryClient.refetchQueries({ queryKey: ['USERS', debouncedFilters] });
  };

  const users: IUser[] = (usersResponse?.pages || []).map(({ users: myUsers }) => myUsers).flat();
  const fullyLoaded = !isLoadingFeaturesByRole && !isLoadingUsers;

  const onFeatureClick = (haveFeature: boolean, key: string, userId: number) =>
    haveFeature ? removeFeatureFromIdentity(key, userId) : addFeatureToIdentity(key, userId);

  return (
    <Page title="Users Flags">
      <Box width="100%">
        <div className={`${classes.flexRow} ${classes.filterContainer}`}>
          <FormControl size="small" variant="outlined">
            <InputLabel htmlFor="search" color="secondary" className={classes.search}>
              Search
            </InputLabel>
            <OutlinedInput
              data-cy="providerSearchInput"
              id="search"
              type="text"
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton style={{ padding: 0 }}>
                    {isFetchingUsers ? (
                      <CircularProgress style={{ color: 'black' }} size={20} />
                    ) : (
                      <SearchIcon style={{ color: 'black' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
        </div>
        <div className={`${classes.flexRow} ${classes.filterContainer}`}>
          <FormControl size="small" variant="outlined" fullWidth>
            <MultipleSelector
              autocomplete
              label="Enabled Feature Flags"
              name="enabledFeatureFlagsFilter"
              control={control}
              value={filters.featureFlags}
              onChange={(selectedFeatureFlags: string) => {
                setFilters({ ...filters, featureFlags: selectedFeatureFlags });
              }}
              errors={null}
              options={featureFlagsOptions}
            />
          </FormControl>
        </div>
        <PortraitTable
          columns={TABLE_COLUMNS}
          sortBy={filters.sortBy}
          sortDirection={filters.sortDirection}
          onSortChange={doSortBy}
          hasNextPage={hasNextPageUsers}
          fetchNextPage={fetchMoreUsers}
          data={users || []}
          rowComponent={(user: IUser) => {
            const userKeys = user.features.map((f) => f.key);
            return (
              <TableRow data-cy="row" key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell data-cy="firstName">{user.firstName}</TableCell>
                <TableCell data-cy="lastName">{user.lastName}</TableCell>
                <TableCell data-cy="email">{user.email}</TableCell>
                <TableCell data-cy="userType">{user.userType}</TableCell>
                <TableCell data-cy="features">
                  {((featuresByRole || {})[user.userType.toLowerCase()] || []).map((key) => {
                    const haveFeature = userKeys.indexOf(key) > -1;
                    return (
                      <p key={key} style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => onFeatureClick(haveFeature, key, user.id)}
                          style={{ cursor: 'pointer' }}
                          onClick={() => onFeatureClick(haveFeature, key, user.id)}
                        >
                          {haveFeature ? (
                            <CheckCircleIcon style={{ color: '#3A8F3E', marginTop: '2px', marginRight: '0.25rem' }} />
                          ) : (
                            <CancelIcon style={{ color: 'red', marginTop: '3px', marginRight: '0.25rem' }} />
                          )}
                        </div>
                        <span>{key}</span>
                      </p>
                    );
                  })}
                </TableCell>
              </TableRow>
            );
          }}
          errorMessage="ERrormessage"
          infiScroll
          isLoading={!fullyLoaded}
          isFetching={isFetchingUsers}
          isError={isErrorUsers}
          tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
        />
      </Box>
    </Page>
  );
};

export default UsersFlagsPage;
