export const CURRENCY_CODE = 'USD';
export const SQUARE_VERSION = '1.3';
export const SQUARE_CLIENT_ID = 'sq0idp-K3mN7cefrbIv8U-oEUcxLw';
export const SUPPORTED_TENDER_TYPES = ['CREDIT_CARD', 'CASH', 'OTHER', 'SQUARE_GIFT_CARD', 'CARD_ON_FILE'];
export const CALLBACK_URL = `${window.location.origin}/squaretransaction`;
export const MAX_AMOUNT_ERROR_TEXT = "Amount can't be greater than";
export const MIN_AMOUNT_ERROR_TEXT = 'Amount needs to be greater than';
export const CHECKOUT_ACCOUNT_CREDIT_ERROR = 'Cannot apply account credit';

export enum SQUARE_STATUS {
  ERROR = 'error',
  OK = 'ok',
  PENDING = 'pending',
  PARTIAL_REFUNDED = 'partial_refunded',
}

export enum DISCOUNT_TYPES {
  PERCENTAGE = 'percentage',
  VALUE = 'value',
  AMOUNT = 'amount',
  BUNDLE = 'bundle',
  BUY_X_GET_Y = 'buy_x_get_y',
}

export const EMPTY_DISCOUNT = {
  type: DISCOUNT_TYPES.PERCENTAGE,
  amount: '',
  reason: '',
};

export const SQUARE_MESSAGES: any = {
  [SQUARE_STATUS.OK]: 'Processed',
  [SQUARE_STATUS.ERROR]: 'Failed',
  [SQUARE_STATUS.PENDING]: 'Pending',
  [SQUARE_STATUS.PARTIAL_REFUNDED]: 'Partial refunded',
};
export const SQUARE_MESSAGE_COLORS: any = {
  [SQUARE_STATUS.OK]: 'green',
  [SQUARE_STATUS.ERROR]: 'red',
  [SQUARE_STATUS.PENDING]: 'white',
};

export const SQUARE_ERROR_CODES: any = {
  payment_canceled: 'Payment Canceled',
  amount_invalid_format: 'Amount Invalid Format',
  amount_too_large: 'Amount Too Large',
  amount_too_small: 'Amount Too Small',
  client_not_authorized_for_user: 'Client not Authorized for User',
  could_not_perform: 'Could not Perform',
  currency_code_mismatch: 'Currency Code Mismatch',
  currency_code_missing: 'Currency Code Missing',
  customer_management_not_supported: 'Customer Management not Supported',
  data_invalid: 'Data Invalid',
  invalid_customer_id: 'Invalid Customer Id',
  invalid_tender_type: 'Invalid Tender Type',
  no_network_connection: 'No Network Connection',
  not_logged_in: 'Not Logged In',
  unsupported_api_version: 'Unsupported API Version',
  unsupported_currency_code: 'Unsupported Currency Code',
  unsupported_tender_type: 'Unsupported Tender Type',
  user_id_mismatch: 'user ID Mismatch',
  user_not_active: 'User not Active',
};

export const SQUARE_ERROR_MESSAGES: any = {
  payment_canceled: 'The payment was cancelled',
  amount_invalid_format: 'The amount has an invalid form',
  amount_too_large: 'The amount is too large',
  amount_too_small: 'The amount is to small',
  client_not_authorized_for_user: 'The client is not authorize for the user',
  could_not_perform: 'The transaction could not be perform',
  currency_code_mismatch: 'The currency does not match',
  currency_code_missing: 'The currency code is missing',
  customer_management_not_supported: 'Customer management is not supported',
  data_invalid: 'The data is invalid',
  invalid_customer_id: 'The customer has an invalid id',
  invalid_tender_type: 'Invalid tender type',
  no_network_connection: 'Without connection',
  not_logged_in: 'Not logged in',
  unsupported_api_version: 'Unsupported API version',
  unsupported_currency_code: 'Unsupported currency code',
  unsupported_tender_type: 'Unsupported tender type',
  user_id_mismatch: 'User ID mismatch',
  user_not_active: 'User is not active',
};

export const INDUSTRY_DISCOUNT_NAME = 'Industry (no commission) - 25%';

export const CARECREDIT_LIMIT_FOR_DISCOUNT = 750;
export const MAX_SERVICE_UNITS = 500;
export const ACCOUNT_CREDIT_CHARGE_TYPE = 'account_credit';
export const CANCELLATION_FEE_CHARGE_TYPE = 'cancellation_fee';
export const DEPOSIT_FEE_CHARGE_TYPE = 'cancellation_fee';
export const OTHER_FEE_CHARGE_TYPE = 'other_fee';
export const FEE_RELATED_CHARGE_TYPES = [
  ACCOUNT_CREDIT_CHARGE_TYPE,
  CANCELLATION_FEE_CHARGE_TYPE,
  DEPOSIT_FEE_CHARGE_TYPE,
  OTHER_FEE_CHARGE_TYPE,
];
export const ASPIRE_TENDER = 'aspire';
export const BRILLIANT_TENDER = 'brilliant';
export const ALLE_WITH_CHERRY_TENDER = 'alleCherry';
export const CARE_CREDIT_TENDER = 'careCredit';
export const XPERIENCE_TENDER = 'xperience';
export const PATIENTFI_TENDER = 'patientfi';

export const TENDER_TYPES = [
  BRILLIANT_TENDER,
  ALLE_WITH_CHERRY_TENDER,
  CARE_CREDIT_TENDER,
  XPERIENCE_TENDER,
  ASPIRE_TENDER,
  PATIENTFI_TENDER,
];
export const TENDER_TYPE_NAMES: Record<string, string> = {
  [BRILLIANT_TENDER]: 'All&#x0113;',
  [ALLE_WITH_CHERRY_TENDER]: 'All&#x0113; Payment with Cherry',
  [CARE_CREDIT_TENDER]: 'Care credit',
  [XPERIENCE_TENDER]: 'Xperience',
  [ASPIRE_TENDER]: 'Aspire',
  [PATIENTFI_TENDER]: 'PatientFi',
};
export const PRICE_FLOOR_EXCLUDED_SERVICE_NAMES = [
  'PDO Threads',
  'Glycolic Peel',
  'Branded Peel',
  'TCA Peel',
  'Hyaluronidase',
];
export const CUSTOM_TREATMENT_SERVICE_NAME = 'Custom Treatment';
