import React, { useState } from 'react';
import { Button, useFormControl } from '@material-ui/core';

type RadioButtonOption = {
  value: string;
  label: string;
  ariaLabel?: string;
};

type RadioButtonsProps = {
  selectedOption?: string;
  options: RadioButtonOption[];
  onChange: (value: string) => void;
};

export const RadioButtons = ({ selectedOption, options, onChange }: RadioButtonsProps) => {
  const { disabled } = useFormControl() || {};
  const [selectedValue, setSelectedValue] = useState<string | undefined>(selectedOption);

  const handleOnClick = (value: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
      {options.map((radioButtonOption: RadioButtonOption) => {
        const checked = radioButtonOption.value === selectedValue;
        return (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Button
              disableRipple
              disableElevation
              defaultChecked={checked}
              fullWidth
              disabled={disabled}
              color="primary"
              onClick={() => handleOnClick(radioButtonOption.value)}
              variant={checked ? 'contained' : 'outlined'}
            >
              {radioButtonOption.label}
            </Button>
          </div>
        );
      })}
    </div>
  );
};
