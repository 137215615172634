import { IAppointment } from 'src/components/DashboardMedspaAdmin/Calendar/MedspaCalendar/IAppointment';
import ROUTES from 'src/constants/apiRoutes.constants';
import { IBlockOff } from 'src/interfaces/acuity/acuity.interface';
import Api from 'src/services/Api';

export interface IAddon {
  active: boolean;
  duration: number;
  id: number;
  name: string;
  price: string;
  private: boolean;
}

export interface IWorkingHour {
  id: number;
  date: string; // 2024-07-18
  start: string; // 09:00
  end: string; // 17:00
  startDate: Date;
  endDate: Date;
  type: string;
}

interface IPractitionerCalendar {
  appointments: IAppointment[];
  appointmentTypes: [];
  blockOffs: Pick<IBlockOff, 'start' | 'end' | 'id' | 'notes'>[];
  workingHours: IWorkingHour[];
  practitionerId: number;
  practitionerName: string;
}

interface IMedspaAppointments {
  calendars: IPractitionerCalendar[];
  date: string;
  medspaLocationId: number | undefined;
  medspaLocationName: string | undefined;
}

export interface ICreateOverrideHoursParams {
  practitionerId: number;
  medspaLocationId: number;
  startDate: string;
  endDate: string;
}

export interface IDeleteOverrideHoursParams {
  overrideWorkingHourId: number;
}

const MedspaCalendar = {
  async createBlockOff(userGroupId: number, data: any): Promise<any> {
    return Api.post(ROUTES.MEDSPA_CALENDAR_BLOCK_OFF(userGroupId), data);
  },
  async updateBlockOff(userGroupId: number | null, blockOffHourId: number | null, data: any): Promise<any> {
    if (!userGroupId || !blockOffHourId) {
      return {};
    }
    return Api.put(ROUTES.MEDSPA_CALENDAR_BLOCK_OFF_UPDATE(userGroupId, blockOffHourId), data);
  },
  async deleteBlockOff(userGroupId: number | null, blockOffHourId: number): Promise<any> {
    if (!userGroupId) {
      return {};
    }
    return Api.delete(ROUTES.MEDSPA_CALENDAR_BLOCK_OFF_DELETE(userGroupId, blockOffHourId));
  },
  async getMedspaAppointments(
    medspaLocationId: number | undefined,
    date: string,
    viewType: string
  ): Promise<IMedspaAppointments> {
    if (!medspaLocationId) {
      return new Promise((res) =>
        res({
          calendars: [],
          date,
          medspaLocationId: undefined,
          medspaLocationName: undefined,
        })
      );
    }
    const queryParams = {
      medspaLocationId,
      date,
      viewType,
    };
    return Api.get(ROUTES.MEDSPA_LOCATION_PRACTITIONERS_APPOINTMENTS_VIEW, queryParams);
  },
  async createOverrideHours(data: ICreateOverrideHoursParams) {
    return Api.post(ROUTES.MEDSPA_CALENDAR_OVERRIDE_HOURS, data);
  },
  async deleteOverrideHours({ overrideWorkingHourId }: IDeleteOverrideHoursParams) {
    return Api.delete(ROUTES.MEDSPA_CALENDAR_OVERRIDE_HOURS_DELETE(overrideWorkingHourId));
  },
  async getMedspaAddons(userGroupId: number): Promise<{ addons: IAddon[] }> {
    if (!userGroupId) {
      return new Promise((res) =>
        res({
          addons: [],
        })
      );
    }
    return Api.get(ROUTES.MEDSPA_CALENDAR_ADDONS(userGroupId));
  },
};

export { MedspaCalendar };
