import React from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Skeleton } from '@material-ui/lab/';
import { makeStyles, TextField } from '@material-ui/core';
import IServices from '../../../interfaces/IServices';
import { IServicesUnits } from '../../../interfaces/serviceVisit.interfaces';
import { dispatch } from '../../../rematch';
import { getServiceLabel } from '../../../utils/newServiceVisit.util';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 30,
  },
  serviceNoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  nameSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  dot: {
    borderRadius: '50%',
    width: 16,
    height: 15,
    marginRight: 8,
  },
}));

interface Props {
  servicesUsedIds: number[];
  services: IServices[];
  units: IServicesUnits;
  serviceNotes: { [serviceId: number]: string };
  disableInputs: boolean;
}

export const ServiceNotes = ({ servicesUsedIds, services, units, serviceNotes, disableInputs }: Props) => {
  const classes = useStyles();
  const { serviceVisitId }: any = useParams();

  const debounceFun = debounce((note: string, serviceId: number) => {
    const trimmedNote = note.trim();

    dispatch({ type: 'annotations/setNote', payload: { serviceId, note: trimmedNote } });
    dispatch({ type: 'annotations/saveNotes', payload: { serviceVisitId } });
  }, 1000);

  const handleNoteChange = (note: string, serviceId: number): void => {
    debounceFun(note, serviceId);
  };

  return (
    <div className={classes.container}>
      {servicesUsedIds.map((serviceId) => {
        const service = services.find(({ id }) => id === serviceId);
        return (
          <div key={service?.id} className={classes.serviceNoteContainer}>
            <div className={classes.nameSection}>
              <div className={classes.dot} style={{ backgroundColor: service?.color || 'black' }} />
              <span>{service?.name}</span>
            </div>
            <TextField
              label={getServiceLabel(
                service?.allowPartialSale || false,
                service?.unitLabel || 'Units',
                service?.assetLabel || 'Vial'
              )}
              disabled
              value={units[serviceId]}
              variant="outlined"
              style={{ marginBottom: 12, width: 134 }}
              size="small"
            />
            {service ? (
              <TextField
                label="Notes"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                error={!serviceNotes[serviceId]}
                defaultValue={serviceNotes[serviceId]}
                onChange={(e) => {
                  handleNoteChange(e.target.value, serviceId);
                }}
                disabled={disableInputs}
              />
            ) : (
              <Skeleton height={180} />
            )}
          </div>
        );
      })}
    </div>
  );
};
