import { Box, Button, ButtonGroup, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import moment from 'moment';
import { VIEW_NAME_DAY, VIEW_NAME_WEEK, VIEW_NAME_WEEK_PROVIDER } from '../indexV2';

const useStyles = makeStyles(() => ({
  dateNavigator: {
    padding: '0.6rem',
    fontSize: '1rem',
    display: 'flex',
    width: '40%',
    justifyContent: 'center',
  },
  dateFormat: {
    padding: '0 1rem',
  },
  buttons: {
    display: 'flex',
    float: 'left',
    width: '30%',
    height: '2.1rem',
    marginTop: '0.3rem',
  },
}));

interface Props {
  view: string;
  currentDate: Date;
  onCurrentDateChange: (date: Date) => void;
  onCurrentViewNameChange: (view: string) => void;
}
const CustomViewSwitcher = ({ view, currentDate, onCurrentDateChange, onCurrentViewNameChange }: Props) => {
  const classes = useStyles();

  const onChangeDate = (direction: string) => {
    const type = view === VIEW_NAME_DAY ? 'day' : 'week';
    if (direction === 'left') {
      onCurrentDateChange(moment(currentDate).subtract(1, type).toDate());
    } else {
      onCurrentDateChange(moment(currentDate).add(1, type).toDate());
    }
  };
  return (
    <Box display="flex" padding="0.5rem">
      <Box width="100%">
        <Box className={classes.buttons}>
          <ButtonGroup variant="contained" aria-label="Basic button group">
            <Button
              dataCy="dayBtn"
              style={{ minWidth: '5rem ' }}
              onClick={() => onCurrentViewNameChange(VIEW_NAME_DAY)}
              color={view === VIEW_NAME_DAY ? 'primary' : 'gray'}
            >
              {VIEW_NAME_DAY}
            </Button>
            <Button
              dataCy="dayBtn"
              size="small"
              style={{ minWidth: '5rem ' }}
              onClick={() => onCurrentViewNameChange(VIEW_NAME_WEEK)}
              color={[VIEW_NAME_WEEK, VIEW_NAME_WEEK_PROVIDER].includes(view) ? 'primary' : 'gray'}
            >
              {VIEW_NAME_WEEK}
            </Button>
          </ButtonGroup>
        </Box>
        <Box className={classes.dateNavigator}>
          <IconButton aria-label="left" size="small" style={{ display: 'flex' }} onClick={() => onChangeDate('left')}>
            <ChevronLeftIcon fontSize="inherit" />
          </IconButton>
          {view === VIEW_NAME_DAY ? (
            <Box className={classes.dateFormat}>{moment(currentDate).format('ll')}</Box>
          ) : (
            <Box className={classes.dateFormat}>
              {moment(currentDate).endOf('week').format('MMMM ')}
              {moment(currentDate).startOf('week').format('DD')}-{moment(currentDate).endOf('week').format('DD, YYYY')}
            </Box>
          )}
          <IconButton aria-label="left" size="small" style={{ display: 'flex' }} onClick={() => onChangeDate('right')}>
            <ChevronRightIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Box width="30%" />
      </Box>
    </Box>
  );
};

export { CustomViewSwitcher };
