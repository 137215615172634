import React from 'react';
import { Chip } from '@material-ui/core';
import { round } from 'lodash';
import classNames from 'classnames';
import { useStyles } from './PercentChip.styles';

export interface PercentChipProps {
  dividend?: number;
  divisor?: number;
  number?: number;
  className?: string;
}

const PercentChip = ({ dividend, divisor, number, className }: PercentChipProps) => {
  const classes = useStyles();

  if (number) {
    return (
      <Chip
        className={classNames(classes.chip, number > 0 ? classes.percentChipActive : classes.percentChip, className)}
        label={`${number}%`}
      />
    );
  }

  if (dividend === undefined || divisor === undefined) {
    return null;
  }

  const percent = round(
    dividend === divisor || dividend > divisor || divisor === 0 ? 0 : (1 - dividend / divisor) * 100 || 100,
    0
  );

  return (
    <Chip
      className={classNames(classes.chip, percent > 0 ? classes.percentChipActive : classes.percentChip, className)}
      label={`${percent}%`}
    />
  );
};

export { PercentChip, PercentChip as default };
