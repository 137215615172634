/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from 'react';
import { makeStyles, TableCell, TableRow, IconButton, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { TablePaginatorRowProps } from '../../../types/TablePaginator';
import IconCross from '../IconCross';

const useStyles = makeStyles(() => ({
  practitioner: {
    maxWidth: '150px !important',
    padding: 10,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  paddingStar: {
    padding: '0 5px 0 16px !important',
  },
  cellName: {
    width: '160px',
    paddingLeft: 0,
    paddingRight: 0,
    color: '#7e7a7a',
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  CreatedClass: {
    fontWeight: 600,
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '14px',
    width: '150px',
    padding: 0,
  },
  itemTab: {
    border: ({ backgroundAlternated }: { backgroundAlternated: boolean }) =>
      backgroundAlternated ? 'solid 2px #f2f5f5' : 'none',
    borderTop: ({ backgroundAlternated }: { backgroundAlternated: boolean }) =>
      backgroundAlternated ? 'initial' : '1px solid rgba(224, 224, 224, 1)',
    cursor: 'pointer',
  },
  itemCell: {
    fontFamily: 'Messina Sans Regular',
    border: ({ backgroundAlternated }: { backgroundAlternated: boolean }) =>
      backgroundAlternated ? 'initial' : 'none',
  },
  notificationsContainer: {
    '&> *:not(:last-child)': {
      marginRight: '.5rem',
    },
  },
}));

const RowStriped = (backgroundAlternated: boolean) =>
  withStyles(() => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: backgroundAlternated ? '#f2f5f5' : 'initial',
        minHeight: '20px',
      },
    },
  }))(TableRow);

const TablePaginatorRows: React.FC<TablePaginatorRowProps> = (props) => {
  const { rows, columns, onClickRow, RenderDetails, renderDetailsByRowClick, displayAllDetails, backgroundAlternated } =
    props;
  const classes = useStyles({ backgroundAlternated });
  const TableRowStriped = useMemo(() => RowStriped(backgroundAlternated), [backgroundAlternated]);
  const [openDetailIndex, setOpenDetailIndex] = useState(-1);

  const handleOnRowClick = (row: any, rowIndex: number = -1) => {
    onClickRow?.(row);
    if (renderDetailsByRowClick) {
      // By row click will display details without the plus button
      setOpenDetailIndex(openDetailIndex === rowIndex ? -1 : rowIndex);
    }
  };

  return (
    <>
      {rows.map((row, rowIndex) => (
        <React.Fragment key={`row-${rowIndex}`}>
          <TableRowStriped
            style={{ cursor: onClickRow || renderDetailsByRowClick ? 'pointer' : 'default' }}
            onClick={() => handleOnRowClick(row, rowIndex)}
            data-cy="tableRow"
            className={classes.itemTab}
            hover
          >
            {!!RenderDetails && !renderDetailsByRowClick && (
              <TableCell className={classes.itemCell}>
                <IconButton
                  data-cy="expandIcon"
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenDetailIndex(openDetailIndex === rowIndex ? -1 : rowIndex)}
                >
                  <IconCross open={!!row?.openDetails} />
                </IconButton>
              </TableCell>
            )}

            {columns.map((column, columnIndex) => (
              <TableCell
                className={classNames(classes.itemCell, `table-cell-${column.field}`)}
                key={`${column.field}-${columnIndex}-${rowIndex}`}
                data-cy={column.field}
              >
                <span>{column?.render ? column.render(row[column.field], row) : row[column.field]}</span>
              </TableCell>
            ))}
          </TableRowStriped>

          {/* Show details column: */}
          {!!RenderDetails && (displayAllDetails || openDetailIndex === rowIndex) && (
            <TableRowStriped>
              {/* looks like offset is not supported on this version */}
              {!renderDetailsByRowClick && <TableCell />}
              <TableCell colSpan={renderDetailsByRowClick ? columns.length + 1 : columns.length}>
                <RenderDetails row={row} />
              </TableCell>
            </TableRowStriped>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default TablePaginatorRows;
