import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      '& > div#root': {
        height: '100%',
      },
    },
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  navigationContainer: {
    flex: '1',
    maxHeight: '64px',
  },
  progressNotesList: {
    flex: 1,
    display: 'flex',
    height: 'calc(100vh - 64px - 170px)',
  },
  form: {
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.13), 0 1px 8px 0 rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    margin: '10px 15px 10px 15px',
    overflow: 'auto',
  },
}));
