import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, IconButton, withStyles } from '@material-ui/core';
import { FULLSCREEN } from 'src/constants/inventory.constants';
import { Close } from '@material-ui/icons';
import { SUBMIT_SUPPORT_LINK } from 'src/constants/general.constants';
import { NEW_WINDOW } from 'src/constants/clientLead.constants';
import { ClassNameMap } from 'src/types/Dom';

export const OrderBetaBanner: React.FC<ClassNameMap> = ({ classes }): React.ReactElement | null => {
  const [dismissed, setDismissed] = useState<boolean>(false);

  const matches = useMediaQuery(FULLSCREEN);

  const dismiss = () => setDismissed(true);

  return !dismissed ? (
    <Box className={classes?.orderingBanner} data-mobile={!matches} data-testid="betaBanner">
      <Typography className={classes?.orderBetaBannerLabel}>
        New ordering experience is live! Please submit a
        <a target={NEW_WINDOW} href={SUBMIT_SUPPORT_LINK} data-testid="betaBanner.ticket">
          {' '}
          ticket{' '}
        </a>
        if you are having any issues with your order.
      </Typography>
      <IconButton data-testid="betaBanner.dismiss" onClick={dismiss}>
        <Close />
      </IconButton>
    </Box>
  ) : null;
};

export default withStyles({
  orderingBanner: {
    width: '100vw',
    margin: '0 0 25px -39px',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '60px',
    maxHeight: '60px',
    backgroundColor: 'rgb(150,38,58)',
    padding: '6px 0',
    '&[data-mobile="true"]': {
      maxHeight: '77px',
    },
    '& a': {
      color: '#fff',
      margin: 5,
    },
    '& > button': {
      margin: 'auto 0 auto 15px',
      backgroundColor: 'transparent',
      border: '1px solid #fff',
      color: '#fff',
      display: 'flex',
      fontSize: 12,
      padding: '6px 25px',
      borderColor: '#fff0',
    },
    '& > button:last-child': {
      marginRight: 'auto',
    },
  },
  orderBetaBannerLabel: {
    margin: 'auto',
    color: '#fff',
  },
})(OrderBetaBanner);
