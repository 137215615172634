/* eslint-disable no-console */
import React from 'react';
import { Box, Grid } from '@material-ui/core';
import classnames from 'classnames';
import { dispatch } from '../../../rematch';
import {
  getDetachedSerialId,
  getLastInternalSerialNumberId,
  showAssetScannedStatusFormat,
} from '../../../utils/inventory.utils';
import compile from '../../../utils/toastMessagesCompiler';

import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import { useStyles } from './productIdentifiers.styles';
import { INVENTORY_COLORS } from '../../../constants/inventory.constants';

interface Props {
  showUnitDetails?: boolean;
  asset: IAsset;
}

const ProductIdentifierVialUnitsBox = ({ showUnitDetails, asset }: Props) => {
  const classes = useStyles();

  const copySerialNumberToClipboard = () => {
    try {
      navigator.clipboard.writeText(asset.internalSerialNumber);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: { message: compile('generic.copy_to_clipboard') },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const ShowUnitsBox = (
    <>
      <Box display="flex" justifyContent="space-between" className={classes.detailBox}>
        <Box color="#949494">ID</Box>
        <Box
          data-cy="id"
          color="#393B3F"
          className={classes.productId}
          onClick={copySerialNumberToClipboard}
          style={{ fontSize: getLastInternalSerialNumberId(asset.internalSerialNumber).length > 6 ? '80%' : '100%' }}
        >
          {getDetachedSerialId(asset.internalSerialNumber)[0]}
          <span style={{ fontSize: '114%' }}>{getDetachedSerialId(asset.internalSerialNumber)[1]}</span>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" className={classes.detailBox}>
        <Box color="#949494">Units</Box>
        <Box color="#393B3F">{showAssetScannedStatusFormat(asset, showUnitDetails || false)}</Box>
      </Box>
    </>
  );

  const percentageFilled =
    asset.currentNumberOfUnits < 0 ? 0 : (asset.currentNumberOfUnits * 100) / asset.initialNumberOfUnits;
  const contentPercentageInPx = (40 * (percentageFilled > 100 ? 100 : percentageFilled)) / 100;
  const ShowJarFilled = (
    <Grid item xs={1}>
      <Box
        className={classes.jarCover}
        style={{
          background:
            asset.currentNumberOfUnits === asset.initialNumberOfUnits
              ? INVENTORY_COLORS.JAR_COVERED
              : INVENTORY_COLORS.JAR_UNCOVERED,
        }}
      />
      <Box className={classes.jar}>
        <Box
          className={classnames(classes.jarContent, { [classes.jarMissingContent]: asset.markedAsEmpty })}
          style={{
            height: contentPercentageInPx,
            marginTop: 40 - contentPercentageInPx,
          }}
        />
      </Box>
    </Grid>
  );

  if (showUnitDetails) {
    return (
      <Grid container alignItems="flex-end" style={{ padding: '9px 0px 8px 11px' }}>
        {ShowJarFilled}
        <Grid item xs={11}>
          {ShowUnitsBox}
        </Grid>
      </Grid>
    );
  }
  return <Box paddingTop="20px">{ShowUnitsBox}</Box>;
};

export default ProductIdentifierVialUnitsBox;
