import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import { dispatch } from '../rematch';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';

const ProductBoardPractitioner = () => {
  const { token } = useSelector(({ productboard }: any) => productboard);
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      dispatch({ type: 'productboard/getToken' });
    }
  });

  const navigateBack = () => {
    history.push('practitioner/patients/cleared');
  };

  return (
    <main>
      <NavigationBar title="Dashboard" onBackButtonClick={navigateBack} />
      <Box>
        {!token ? (
          <MultipleSkeleton />
        ) : (
          <iframe
            title="Practitioner Product Board"
            style={{ width: '100%', height: '92vh' }}
            src={`https://portal.productboard.com/etrsvnddeh7qu35pg9mvwwnm?token=${token}`}
            frameBorder="0"
            // eslint-disable-next-line
            frame-ancestors="none"
          />
        )}
      </Box>
    </main>
  );
};

export default ProductBoardPractitioner;
