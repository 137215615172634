import { Card, CardContent, makeStyles } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, USER_TYPES } from '../../../../constants/general.constants';
import { EXTERNAL_PHYSICIAN_NOTES } from '../../../../constants/reactQuery.keys';
import { useCustomRoles } from '../../../../hooks/queries/useCustomRoles';
import { usePractitionerInfo } from '../../../../hooks/queries/usePractitioners';
import { FlexPractitionerPhysician } from '../../../../services/FlexPractitionerPhysician';

const useStyles = makeStyles(() => ({
  noteSection: {
    marginBottom: '20px',
    marginTop: '20px',
    borderRadius: '9px',
    boxShadow: 'none',
  },
  title: {
    fontSize: '24px',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 'normal',
  },
  notes: {
    marginBottom: '20px',
  },
}));

export const ExternalPhysicianNotes = ({ practitionerId, patientId }: any) => {
  const { data: roles = [] } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });
  const { practitionerInfo } = usePractitionerInfo(practitionerId);
  const classes = useStyles();
  const { data } = useQuery(
    [EXTERNAL_PHYSICIAN_NOTES],
    () => FlexPractitionerPhysician.getFlexPractitionerPhysician(patientId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  const doctorNotes = data?.notes;

  const roleName = roles.find((role) => +role.id === practitionerInfo?.roleId)?.name;
  const isNonLegacyPractitioner = roleName !== PORTRAIT_LEGACY && roleName !== PORTRAIT_LEGACY_PLUS;

  if (!isNonLegacyPractitioner || !doctorNotes) {
    return null;
  }

  return (
    <Card className={classes.noteSection}>
      <CardContent>
        <h2 className={classes.title}>Doctor notes:</h2>
      </CardContent>
      <CardContent className={classes.notes}>{doctorNotes}</CardContent>
    </Card>
  );
};
