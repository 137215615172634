/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Link, makeStyles } from '@material-ui/core';
import React from 'react';

const useWarningStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(0.5),
  },
  text: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    color: '#000000',
  },
  linkContainer: {
    borderBottom: '2px solid #000000',
    cursor: 'pointer',
  },
  link: {
    fontFamily: 'Messina Sans Bold',
    color: '#000000',
    fontWeight: 700,
  },
}));

const Warning = ({ message, productAtRiskCount, onClick, color }: any) => {
  const classes = useWarningStyles();

  return (
    <Box style={{ backgroundColor: color }} className={classes.container}>
      <Box component="span" className={classes.text}>
        {productAtRiskCount} {message}{' '}
        <Link className={classes.linkContainer} underline="none" onClick={onClick}>
          <Box component="span" className={classes.link}>
            View Items
          </Box>
        </Link>
      </Box>
    </Box>
  );
};
export default Warning;
