import React from 'react';
import { AmountsSection, BoldLineItem, SubTotalLineItem } from './DiscountsSubTotal';
import { formatNumber } from '../../../utils/checkout.utils';

interface Props {
  toPay: number;
  tips: string;
  totalLabel: string;
  accountCreditUsed: number;
  amountPaid: number;
}

export const RemainingToPay = ({ amountPaid, toPay, tips, totalLabel, accountCreditUsed = 0 }: Props) => {
  const renderProcessed = () => {
    /* Validation for new field amountPaid not present in others transactions */
    if (!!amountPaid && amountPaid < toPay) {
      return (
        <>
          <BoldLineItem title={totalLabel} amount={formatNumber(amountPaid)} />
          <BoldLineItem title="Amount due" amount={formatNumber(toPay - amountPaid)} />
        </>
      );
    } 
    return <BoldLineItem title={totalLabel} amount={formatNumber(toPay)} />;
    
  };

  return (
    <AmountsSection>
      <SubTotalLineItem title="Account credit" amount={accountCreditUsed.toFixed(2)} />
      {renderProcessed()}
      {+tips > 0 && <SubTotalLineItem title="Tips" amount={tips} />}
    </AmountsSection>
  );
};
