import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%',
    background: '#FAFAFA',
  },
  checkboxLabel: {
    fontSize: '.85rem',
    fontFamily: 'Messina Sans Regular !important',
    color: '#12574D',
  },
  checkbox: {
    color: '#12574D',
  },
}));
