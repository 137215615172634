import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { CALENDAR_DATETIME_FORMAT } from 'src/constants/inventory.constants';
import { BLOCK_OFF_HOURS } from 'src/constants/acuity/acuity.constants';
import { DEFAULT_CHIP_COLOR, MULTIPLE_SELECTOR_OPTION_COLORS } from 'src/components/common/Custom/MultipleSelector';
import {
  ACUITY_ACCOUNT,
  ACUITY_CALENDAR_AVAILABILITY,
  ACUITY_CALENDAR_AVAILABILITY_OVERRIDE,
  ACUITY_CALENDAR_BLOCK_OFF_HOURS,
  APPOINTMENT_TYPES,
  CARE_MESSAGES_SUGGESTIONS,
} from '../../constants/reactQuery.keys';
import { Appointment } from '../../services/Appointment';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import { SNACKBAR_SUCCESS } from '../../constants/general.constants';
import { IAppointmentType, IBlockOff, IBlockOffForCalendar } from '../../interfaces/acuity/acuity.interface';

/* Acuity Account Details
 * Params: headers only
 */
export const useAcuityAccount = (params: { practitionerId?: number | string; locationId?: number }) => {
  const response = useQuery([ACUITY_ACCOUNT, JSON.stringify(params)], () => Appointment.getAccount(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: 0,
    onError: useReactQueryOnErrorHandler,
  });

  return {
    ...response,
    data: response.data?.data || {},
  };
};

export const useUpdateAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => Appointment.updateAccount(params), {
    onSuccess: ({ success }: { success: boolean; messages: string[] }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([ACUITY_ACCOUNT]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Account',
          action: 'updated',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useUpdateAppointmentTypeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((params: IAppointmentType) => Appointment.updateAppointmentType(params.id, params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([APPOINTMENT_TYPES]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Appointment type',
          action: 'updated',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useCreateAppointmentTypeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((params: IAppointmentType) => Appointment.createAppointmentType(params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([APPOINTMENT_TYPES]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Appointment type',
          action: 'created',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useDeleteAppointmentTypeMutation = (practitionerId?: number | string) => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => Appointment.deleteAppointmentType(id, practitionerId), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([APPOINTMENT_TYPES]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Appointment type',
          action: 'deleted',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Acuity Calendar Availability
 * Params: headers only
 */
export const useAvailabilityAccount = (params: { practitionerId?: number | string; locationId?: number }) => {
  const response = useQuery([ACUITY_CALENDAR_AVAILABILITY], () => Appointment.getCalendarAvailability(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: 0,
    onError: useReactQueryOnErrorHandler,
  });

  return {
    ...response,
    data: response.data?.availability?.data || {},
  };
};

/* Update availability configuration */
export const useUpdateAvailabilityMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => Appointment.updateCalendarAvailability(params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([ACUITY_CALENDAR_AVAILABILITY]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Availability',
          action: 'updated',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Acuity Calendar Availability Override hours
 * Params: headers only
 */
export const useAvailabilityOverride = (params: { practitionerId?: number | string; locationId?: number }) => {
  const response = useQuery(
    [ACUITY_CALENDAR_AVAILABILITY_OVERRIDE],
    () => Appointment.getAvailabilityOverride(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      retry: 0,
      onError: useReactQueryOnErrorHandler,
    }
  );

  return {
    ...response,
    data: response?.data?.data || {},
  };
};

/* Update availability override configuration */
export const useUpdateAvailabilityOverrideMutation = (successCallback?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => Appointment.updateAvailabilityOverride(params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([ACUITY_CALENDAR_AVAILABILITY_OVERRIDE]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Availability override',
          action: 'updated',
        }),
        SNACKBAR_SUCCESS
      );
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Update block off hour configuration */
export const useUpdateBlockOffHoursMutation = (successCallback?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => Appointment.updateBlockOffHours(params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([ACUITY_CALENDAR_BLOCK_OFF_HOURS]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Block off hours',
          action: 'updated',
        }),
        SNACKBAR_SUCCESS
      );

      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Acuity Calendar Block off hours
 * Params: headers only
 */
export const useBlockOffHours = (
  params: { start: any; end: any; practitionerIds?: number[] | null },
  enabled?: boolean
) => {
  const key = JSON.stringify(params);

  const [blocksForCalendar, setBlocksForCalendar] = useState<IBlockOffForCalendar[]>([]);

  const response = useQuery([ACUITY_CALENDAR_BLOCK_OFF_HOURS, key], () => Appointment.getBlockOffHours(params), {
    refetchOnWindowFocus: true,
    keepPreviousData: false,
    retry: 0,
    enabled,
    onError: useReactQueryOnErrorHandler,
  });

  useEffect(() => {
    const newBlocks: IBlockOffForCalendar[] =
      response?.data?.blockOffs?.map((item: IBlockOff): IBlockOffForCalendar => {
        let practitionerColor = '';
        if (params.practitionerIds?.length) {
          // Hexadecimal for AppointmentBox resources
          const practitionerIdIndex = params.practitionerIds?.length
            ? params.practitionerIds?.findIndex((id) => id === item?.practitionerId)
            : -1;
          practitionerColor = MULTIPLE_SELECTOR_OPTION_COLORS[practitionerIdIndex] || DEFAULT_CHIP_COLOR;
        }

        return {
          ...item,
          startDate: moment(item.start).format(CALENDAR_DATETIME_FORMAT),
          endDate: moment(item.end).format(CALENDAR_DATETIME_FORMAT),
          type: BLOCK_OFF_HOURS,
          practitionerColor,
        } as IBlockOffForCalendar;
      }) || [];

    setBlocksForCalendar(newBlocks);
  }, [response?.data?.blockOffs]);

  return {
    ...response,
    data: response?.data?.blockOffs || [],
    blocksForCalendar,
  };
};

export const useBlockOffHoursDeleteMutation = (successCallback?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => Appointment.deleteBlockOffHours(id), {
    onSuccess: ({ success, messages }: { success: boolean; messages: string[] }) => {
      if (!success) {
        if (messages?.length > 0) {
          messages.forEach((message) => showSnackbar(message));
        } else {
          showSnackbar(compile('generic.server_error'));
        }
        return;
      }

      queryClient.invalidateQueries([ACUITY_CALENDAR_BLOCK_OFF_HOURS]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Block off hour',
          action: 'deleted',
        }),
        SNACKBAR_SUCCESS
      );

      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Care messages suggestions
 * Params: headers only
 */
export const useCareMessagesSuggestion = (params: any) => {
  const response = useQuery(
    [CARE_MESSAGES_SUGGESTIONS, JSON.stringify(params)],
    () => Appointment.getCareMessagesSuggestion(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: params?.serviceGroupIds?.length > 0,
      onError: () =>
        useReactQueryOnErrorHandler(
          compile('generic.error_message', { action: 'fetching', element: 'care messages suggestions' })
        ),
    }
  );

  return {
    ...response,
    data: response.data || {},
  };
};
