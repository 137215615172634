import { makeStyles, TableCell, TableRow, withStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  typographyClass: {
    letterSpacing: '-0.05px',
    fontSize: '24px',
    fontFamily: 'Messina Sans Regular',
    color: 'rgba(0, 0, 0, .87)',
    margin: '10px 0',
  },
  serviceName: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    lineHeight: 1.67,
    letterSpacing: '-0.05px',
    color: '#3a3b3f',
  },
  dataClass: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '15px !important',
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#3a3b3f',
  },
  boxActionItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#12574D',
    height: '34px',
    width: '34px !important',
    minWidth: '34px !important',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#12574D',
    },
  },
  photosContainer: {
    borderRadius: '3px',
    padding: '8px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '& div': {
      border: 'none !important',
    },
  },
  inventoryContainer: {
    borderRadius: '3px',
    padding: '0px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '& div': {
      border: 'none !important',
    },
  },
  inventoryBox: {
    padding: '11px 20px 9px',
  },
  inventoryTitle: {
    marginBottom: '10px',
  },
  medicalChartingContainer: {
    borderRadius: '3px',
    padding: '8px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '& div': {
      border: 'none !important',
    },
  },
  notesContainer: {
    borderRadius: '3px',
    padding: '8px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '& div': {
      border: 'none',
    },
  },
}));

export const TableCellData = withStyles(() => ({
  root: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    lineHeight: 1.67,
    letterSpacing: '-0.05px',
  },
}))(TableCell);

export const TableRowStriped = withStyles(() => ({
  root: {
    fontFamily: 'Messina Sans Regular',
    height: '56px',
  },
}))(TableRow);
