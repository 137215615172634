import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './ProgressNotes.styles';
import { IProgressNotephoto, IProgressNotesProps } from '../../../types/ProgressNoteTypes';
import { IPhoto } from '../../../interfaces/global.interfaces';
import ProgressNotePhotoModal from '../../ProgressNote/ProgressNotePhotoModal';

const ProgressNotes = ({ progressNote: { text, photos } }: IProgressNotesProps) => {
  const classes = useStyles();

  const [progressNotephotoUrlSrc, setProgressNotePhotoUrlSrc] = useState<IProgressNotephoto['src']>('');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const openModal = (largeUrl: string) => {
    setProgressNotePhotoUrlSrc(largeUrl);
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setProgressNotePhotoUrlSrc('');
  };

  const viewOriginalPhoto = () => {
    window.open(progressNotephotoUrlSrc, '_blank');
  };

  return (
    <>
      <Box>
        <Box className={classes.listPhotos}>
          {photos &&
            photos.length > 0 &&
            photos.map((photoItem: IPhoto) => (
              <div key={photoItem.id} onClick={() => openModal(photoItem.largeUrl)} aria-hidden>
                <img src={photoItem.mediumUrl} alt="patient progress note" className={classes.photoItem} />
              </div>
            ))}
        </Box>
        <Box className={classes.ListItemText}>
          <span>{text}</span>
        </Box>
      </Box>
      <ProgressNotePhotoModal
        isOpenModal={isOpenModal}
        photoUrlSrc={progressNotephotoUrlSrc}
        closeModal={closeModal}
        viewOriginal={viewOriginalPhoto}
      />
    </>
  );
};

export default ProgressNotes;
