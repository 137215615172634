import React, { useEffect } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';

export const PriceField = ({
  ref,
  defaultValue,
  disabled,
  onChange,
}: {
  ref?: React.Ref<any>;
  defaultValue: any;
  disabled?: boolean;
  onChange?: (value: number) => void;
}) => {
  useEffect(() => {
    // Hotfix for ref form setting current.value
    onChange?.(defaultValue);
  }, []);
  return (
    <TextField
      required
      fullWidth
      inputRef={ref}
      type="number"
      defaultValue={defaultValue || 0}
      variant="outlined"
      size="small"
      placeholder="0.00"
      disabled={disabled}
      onChange={(ev: any) => {
        onChange?.(+ev?.target?.value);
      }}
      inputProps={{
        step: 0.01,
        min: 0,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      onBlur={(e) => e.target.reportValidity()}
    />
  );
};
