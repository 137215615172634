import React from 'react';
import { useSelector } from 'react-redux';
import { TableCell, TableRow } from '@material-ui/core';
import { useRowStyles } from './Physician.styles';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import ButtonAhref from '../../common/ButtonAhref';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_PHYSICIAN } from '../../../constants/actions.constants';

export interface PhysicianRowProps {
  id: number;
  email: string;
  fullname: string;
  professionalName?: string;
  workingStates?: string[];
  medicalMemberType: string;
  assignedProviders?: string[];
}

const PhysicianRow: React.FC<PhysicianRowProps> = (props) => {
  const {
    id: physicianId,
    email,
    fullname,
    professionalName,
    workingStates,
    medicalMemberType,
    assignedProviders,
  } = props;
  const classes = useRowStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const EDIT_URL = `/administrator/new-physician/${physicianId}`;

  return (
    <>
      <TableRow data-cy="row" className={classes.root}>
        <TableCellCustom>{physicianId}</TableCellCustom>
        <TableCellCustom data-cy="fullName">{fullname}</TableCellCustom>
        <TableCellCustom data-cy="professionalName">{professionalName}</TableCellCustom>
        <TableCellCustom data-cy="email" className={classes.email}>
          {email}
        </TableCellCustom>
        <TableCellCustom data-cy="workingStates">{workingStates?.join(', ') || ''}</TableCellCustom>
        <TableCellCustom data-cy="assignedProviders">{assignedProviders?.join(', ') || ''}</TableCellCustom>
        <TableCellCustom data-cy="memberType">{medicalMemberType}</TableCellCustom>
        <TableCell>
          {hasAccessTo(CREATE_PHYSICIAN, permissions) && <ButtonAhref text="EDIT" href={EDIT_URL} />}
        </TableCell>
      </TableRow>
    </>
  );
};

export default PhysicianRow;
