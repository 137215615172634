import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Divider, MenuItem, Modal } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { ReactComponent as Warning } from 'src/assets/images/warning.svg';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/rematch';
import { VideoBanner } from 'src/pages/MedspaAdmin/Onboarding/components/VideoBanner';
import EmptyPage from 'src/components/common/EmptyPage';
import { VideoModal } from 'src/pages/MedspaAdmin/Onboarding/components/VideoModal';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { ONBOARDING_STEPS, ONBOARDING_VIDEOS, SHOW_SUCCESS_MESSAGE } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { useMedspaLocations } from '../../../hooks/queries/medspaAdmins/useMedspaLocations';
import { IMedspaLocation } from '../../../interfaces/ILocation';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { EHRButton, EHRSelect } from '../../ui/v1';
import Page from '../../common/Page';
import { IAppointmentType } from '../../../interfaces/acuity/acuity.interface';
import TablePaginator from '../../common/Table/TablePaginator';
import { ColumnType } from '../../../types/TablePaginator';
import { Pill } from '../../common/Pill';
import {
  useMedspaAppointmentTypes,
  useUpdateMedspaAppointmentTypeMutation,
} from '../../../hooks/queries/medspaAdmins/useMedspaAppointmentTypes';
import MedspaAppointmentTypeForm from './MedspaAppointmentTypeForm';
import {
  MEDSPA_ADMIN_CALENDAR_CONFIGURATION,
  MEDSPA_ADMIN_LOCATIONS,
  MEDSPA_ADMIN_PRACTITIONERS,
} from '../../../routes/medspaAdminRoutes';
import { useStyles } from './index.styles';
import { useMedspaPractitioners } from '../../../hooks/queries/usePractitioners';

const APPOINTMENT_TYPE_COLUMNS: ColumnType[] = [
  { field: 'name', title: 'Name', width: '200px' },
  { field: 'description', title: 'Appointment type description' },
  { field: 'duration', title: 'Duration', width: '150px', render: (value: number) => `${value} min` },
  {
    field: 'hidden',
    title: 'Status',
    render: (value: string) => (
      <Pill
        title={value ? 'Disabled' : 'Active'}
        color={value ? 'gray' : 'green'}
        style={{ color: value ? 'gray' : '#2C6109' }}
      />
    ),
  },
  {
    field: 'id',
    title: '',
    render: (_value: string, row: any) => {
      const styles = {
        fontSize: '20px',
        color: row.default ? 'gray' : '#12574d',
        margin: '0 5px',
        cursor: 'pointer',
      };
      return <EditIcon viewBox="0 0 22 22" style={styles} onClick={row.onEdit} />;
    },
  },
];

export const MedspaAppointmentTypes = () => {
  const history = useHistory();
  const { userGroupId } = useSelector(({ auth }: RootState) => auth);
  const [selectedMedspaLocation, setSelectedMedspaLocation] = useState<number | string>();
  const [appointmentType, setAppointmentType] = useState<IAppointmentType | null>();
  const [openDialog, setOpenDialog] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [appTypeFilter, setAppTypeFilter] = useState<Record<string, number | string>>({});
  const { data: appointmentTypes = [], isLoading: isLoadingTypes } = useMedspaAppointmentTypes(
    userGroupId,
    appTypeFilter
  );
  const {
    data: { medspaLocations = [] },
    isLoading,
  } = useMedspaLocations();
  const { data: practitioners = [], isLoading: isLoadingPractitioners } = useMedspaPractitioners(
    {},
    true,
    () => `/v4/medspa/${userGroupId}/practitioners`
  );

  const [openEhrOnboardingSuccessModal, setOpenEhrOnboardingSuccessModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const updateMedspaAdminOnboardingProgressMutation = useUpdateMedspaAdminOnboardingProgress();
  const {
    FooterComponent,
    DoneFooterComponent,
    onboardingEnabled,
    allStepsCompleted,
    isLoading: isLoadingOnboarding,
  } = useMedspaAdminOnboarding();

  const updateMutation = useUpdateMedspaAppointmentTypeMutation(userGroupId);
  const loading = isLoading || isLoadingTypes || updateMutation.isLoading || isLoadingPractitioners;

  useEffect(() => {
    setAppTypeFilter(selectedMedspaLocation ? { medspaLocationId: selectedMedspaLocation } : {});
  }, [selectedMedspaLocation]);

  const missingProviders =
    medspaLocations.length > 0 &&
    medspaLocations.map((medspaLocation: IMedspaLocation) => medspaLocation.practitioners).flat().length === 0;

  const validState = medspaLocations.length > 0 && !missingProviders;

  const dataToShow = useMemo(
    () =>
      appointmentTypes.map((appType: IAppointmentType) => ({
        ...appType,
        onEdit: () => {
          handleOpen(appType);
        },
      })) || [],
    [appointmentTypes, validState]
  );

  const handleWarning = () => {
    if (missingProviders) {
      if (practitioners.length === 0) {
        history.push(MEDSPA_ADMIN_PRACTITIONERS);
        return;
      }
    }
    history.push(MEDSPA_ADMIN_LOCATIONS);
  };

  const handleOpen = (appType: any = null) => {
    setAppointmentType(appType);
    if (validState) {
      setOpenDialog(true);
    } else {
      setShowWarningModal(true);
    }
  };

  const updateProgressOnSuccess = async () => {
    if (onboardingEnabled && !allStepsCompleted) {
      const resp = await updateMedspaAdminOnboardingProgressMutation.mutateAsync({
        stepName: ONBOARDING_STEPS.APPOINTMENT_TYPES_NAME,
        percentage: 100,
      });

      if (resp.completed && resp?.message === SHOW_SUCCESS_MESSAGE) {
        setOpenEhrOnboardingSuccessModal(true);
      }
    }
  };

  if (loading || isLoadingOnboarding) {
    return <MultipleSkeleton addPosition={false} />;
  }

  if (appointmentTypes.length === 0 && !selectedMedspaLocation) {
    return (
      <>
        {onboardingEnabled && (
          <div style={{ padding: '2rem' }}>
            <VideoBanner title="How to add appointment types?" onClick={() => setShowVideoModal(true)} />
          </div>
        )}

        <EmptyPage title="Appointment Types">
          <h1>Welcome to Appointment Types Section!</h1>
          <p>It looks like you haven&apos;t added an appointment type yet.</p>
          <p>Click below to add your first appointment type.</p>
          <Box marginTop="24px" display="flex" gridGap="1rem">
            <EHRButton
              dataCy="btn-new-appointment-type"
              color="primary"
              text="New Appointment Type"
              onClick={handleOpen}
            />
          </Box>

          <MedspaAppointmentTypeForm
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            appointmentType={appointmentType}
            medspaLocations={medspaLocations}
            onSuccessCallback={updateProgressOnSuccess}
          />
          <WarningModal
            open={showWarningModal}
            onClose={() => {
              setAppointmentType(undefined);
              setShowWarningModal(false);
            }}
            missingProviders={missingProviders}
            appointmentType={appointmentType}
            onConfirm={handleWarning}
          />
        </EmptyPage>

        <FooterComponent
          currentStep="Appointment Types"
          nextStep="Set up Calendar"
          urlToNextStep={MEDSPA_ADMIN_CALENDAR_CONFIGURATION}
        />

        <VideoModal
          open={showVideoModal}
          onClose={() => setShowVideoModal(false)}
          url={ONBOARDING_VIDEOS.APPOINTMENT_TYPES_NAME}
        />
      </>
    );
  }

  return (
    <>
      <Page title="Appointment Types">
        <Box>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <EHRSelect
              label="Select Location"
              dataCy="select-location"
              onChange={(value: string) => setSelectedMedspaLocation(value)}
              displayEmpty
              id="medspaLocation"
              value={selectedMedspaLocation}
              style={{ minWidth: '500px' }}
            >
              <MenuItem>All</MenuItem>
              {medspaLocations.map((medspaLocation: IMedspaLocation) => (
                <MenuItem key={medspaLocation.id} value={medspaLocation.id}>
                  {medspaLocation.name}, {medspaLocation.address}, {medspaLocation.city}, {medspaLocation.state},
                  {medspaLocation.zipCode}
                </MenuItem>
              ))}
            </EHRSelect>
            <Button
              data-cy="submitBtn"
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
              style={{ textTransform: 'none', padding: '0.5rem 1.5rem' }}
              onClick={handleOpen}
            >
              Create
            </Button>
          </Box>
          <TablePaginator
            isLoading={isLoadingTypes}
            isFetching={loading}
            backgroundAlternated={false}
            data={dataToShow}
            columns={APPOINTMENT_TYPE_COLUMNS}
            total={appointmentTypes.length}
          />
        </Box>

        <MedspaAppointmentTypeForm
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          appointmentType={appointmentType}
          medspaLocations={medspaLocations}
        />
        <WarningModal
          open={showWarningModal}
          onClose={() => {
            setAppointmentType(undefined);
            setShowWarningModal(false);
          }}
          missingProviders={missingProviders}
          appointmentType={appointmentType}
          onConfirm={handleWarning}
        />
      </Page>
      <FooterComponent
        currentStep="Appointment Types"
        nextStep="Set up Calendar"
        urlToNextStep={MEDSPA_ADMIN_CALENDAR_CONFIGURATION}
      />

      {openEhrOnboardingSuccessModal && <DoneFooterComponent />}

      <VideoModal
        open={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        url={ONBOARDING_VIDEOS.APPOINTMENT_TYPES_NAME}
      />
    </>
  );
};

interface WarningModalProps {
  open: boolean;
  onClose: () => void;
  missingProviders: boolean;
  appointmentType: any;
  onConfirm: () => void;
}

const WarningModal = ({ open, onClose, missingProviders, appointmentType, onConfirm }: WarningModalProps) => {
  const classes = useStyles();

  return (
    <Modal open={open} className={classes.modal} onClose={onClose}>
      <Box className={classes.modalContent}>
        <Box>
          <Warning className={classes.warningIcon} />
          <h3>
            {missingProviders ? (
              <span>Seems like you didn&apos;t add a provider to any location yet.</span>
            ) : (
              <span>Seems like you didn&apos;t add your first location yet.</span>
            )}
          </h3>
          <p className={classes.modalBody}>
            {missingProviders
              ? `Please add a provider to a location in order to ${appointmentType ? 'create a new' : 'edit an'} 
              appointment type.`
              : `Please add your location in order to 
              ${appointmentType ? 'edit an' : 'create a new'}  appointment type.`}
          </p>
          <Divider />
          <Box display="flex" justifyContent="flex-end" gridGap="1rem" style={{ padding: '1rem 1rem 0 1rem' }}>
            <EHRButton dataCy="btn-cancel-modal-disable-medspa" color="default" onClick={onClose} text="Cancel" />
            <EHRButton
              dataCy="btn-disable-modal-disable-medspa"
              color="primary"
              onClick={onConfirm}
              text={missingProviders ? 'Add Provider' : 'Add Location'}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
