import { useMutation, useQuery, useQueryClient } from 'react-query';
import MedspaServiceGroups from 'src/services/medspaAdmins/ServiceGroups';
import { CONSENT_SUGGESTIONS } from 'src/constants/apiRoutes.constants';
import { SNACKBAR_SUCCESS } from '../../constants/general.constants';
import {
  CARE_MESSAGES_SUGGESTIONS,
  CARE_MESSAGE_TEMPLATES,
  CONSENT_TEMPLATES,
  DEFAULT_SERVICE_GROUPS,
  MEDSPA_PRIMARY_SERVICES,
  SERVICE_GROUPS,
  SERVICE_GROUPS_LIST,
} from '../../constants/reactQuery.keys';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';

export function useMedspaServiceGroups(userGroupId: number, enabled: boolean = true) {
  const response = useQuery(
    [SERVICE_GROUPS, userGroupId],
    () => MedspaServiceGroups.getServiceGroups(userGroupId, {}),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled,
    }
  );

  return {
    ...response,
    data: response.data || [],
  };
}

export const useMedspaCreateServiceGroupMutation = (userGroupId: number) => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => MedspaServiceGroups.createServiceGroup(userGroupId, params), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICE_GROUPS]);
      queryClient.invalidateQueries([DEFAULT_SERVICE_GROUPS]);
      // Required for new care message created after service group creation from fixed field:
      queryClient.invalidateQueries([CARE_MESSAGE_TEMPLATES]);
      queryClient.invalidateQueries([SERVICE_GROUPS_LIST]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Service group',
          action: 'created',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useMedspaUpdateServiceGroupMutation = (userGroupId: number) => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => MedspaServiceGroups.updateServiceGroup(userGroupId, params.id, params), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([SERVICE_GROUPS]);
      queryClient.invalidateQueries([DEFAULT_SERVICE_GROUPS]);
      queryClient.invalidateQueries([SERVICE_GROUPS_LIST]);

      if (response.success) {
        showSnackbar(
          compile('generic.success_message', {
            element: 'Service group',
            action: 'deleted',
          }),
          SNACKBAR_SUCCESS
        );
      } else {
        showSnackbar(response?.message || compile('generic.error_message_without_params'));
      }
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useMedspaDeleteServiceGroupMutation = (userGroupId: number) => {
  const queryClient = useQueryClient();
  return useMutation((serviceGroupId: number) => MedspaServiceGroups.deleteServiceGroup(userGroupId, serviceGroupId), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([SERVICE_GROUPS]);
      queryClient.invalidateQueries([DEFAULT_SERVICE_GROUPS]);
      queryClient.invalidateQueries([MEDSPA_PRIMARY_SERVICES]);
      queryClient.invalidateQueries([SERVICE_GROUPS_LIST]);

      if (response.success) {
        showSnackbar(
          compile('generic.success_message', {
            element: 'Service group',
            action: 'deleted',
          }),
          SNACKBAR_SUCCESS
        );
      } else {
        showSnackbar(response?.message || compile('generic.error_message_without_params'));
      }
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useMedspaConsentTemplates = (userGroupId: number, enabled: boolean = true) => {
  const response = useQuery(
    [CONSENT_TEMPLATES, userGroupId],
    () => MedspaServiceGroups.getConsentTemplates(userGroupId),
    {
      enabled,
    }
  );
  return {
    ...response,
    data: response.data,
  };
};

export const useMedspaCareMessageTemplates = (userGroupId: number, enabled: boolean = true) => {
  const response = useQuery(
    [CARE_MESSAGES_SUGGESTIONS, userGroupId],
    () => MedspaServiceGroups.getMedspaCareMessageTemplates(userGroupId),
    {
      enabled,
    }
  );
  return {
    ...response,
    data: response.data,
  };
};

export const useMedspaStandingOrderTemplates = (userGroupId: number, enabled: boolean = true) => {
  const response = useQuery(
    [CONSENT_SUGGESTIONS, userGroupId],
    () => MedspaServiceGroups.getMedspaStandingOrderTemplates(userGroupId),
    {
      enabled,
    }
  );
  return {
    ...response,
    data: response.data,
  };
};
