import React, { forwardRef } from 'react';
// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Color } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
}));

const NotifierCustomComponent = forwardRef<
HTMLDivElement,
{
  message: string | React.ReactNode;
  type: Color;
  action: React.ReactNode;
  onClose: () => void;
}
>((props, ref) => {
      const classes = useStyles();
      const { message, action, type, onClose: onCloseAction } = props;

      const renderActionsButtons = () => {
        const closeButton = (
          <IconButton data-cy="closeAlertBtn" onClick={() => onCloseAction()} aria-label="dimiss">
            <Close />
          </IconButton>
        );
        return action ? (
          <>
            {action}
            {closeButton}
          </>
        ) : (
          closeButton
        );
      };

      return (
        <Alert data-cy="alert" ref={ref} className={classes.root} severity={type} action={renderActionsButtons()}>
          {message}
        </Alert>
      );
    });

export default NotifierCustomComponent;
