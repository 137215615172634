import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
// eslint-disable-next-line import/no-unresolved
import TextField from '@material-ui/core/TextField';
import { CustomToggleButton, CustomToggleButtonGroup } from './customDiscounts.styles';
import { DISCOUNT_TYPES, EMPTY_DISCOUNT, MAX_AMOUNT_ERROR_TEXT as TEXT } from '../../constants/checkout.constants';
import { ICustomDiscount, IDiscount } from '../../interfaces/checkout.interfaces';

type Props = {
  discounts: IDiscount[];
  disabled: boolean;
  isValidAmount: (type: string, amount: string) => boolean;
  medicationData: {
    name: string;
    serviceId: number;
    price: number;
    unit: number;
  };
  setCustomDiscount: (customDiscount: ICustomDiscount) => void;
};

export const CustomDiscount = (props: Props) => {
  const { discounts, medicationData, disabled, setCustomDiscount, isValidAmount } = props;

  const medicationTotalPrice = +medicationData.price * +medicationData.unit;
  const [discountsState, setDiscountsState] = useState<any>({ ...EMPTY_DISCOUNT });

  useEffect(() => {
    const customDiscount = discounts.find((discount: any) => discount.reason);
    if (customDiscount) {
      setDiscountsState(customDiscount);
    }
  }, [discounts]);

  const handleOnChange = ({ name, value }: any): void => {
    const newDiscounts = { ...discountsState };
    newDiscounts[name] = value;
    setDiscountsState(newDiscounts);
    setCustomDiscount(newDiscounts);
  };

  const getAmountErrorMessage = (type: string): string => {
    if (type === DISCOUNT_TYPES.PERCENTAGE) {
      return `${TEXT} 100%`;
    }
    return `${TEXT} ${medicationTotalPrice}`;
  };

  const helperText = (): string => {
    const { type, amount } = discountsState;
    return isValidAmount(type, amount) ? ' ' : getAmountErrorMessage(type);
  };

  return (
    <div>
      <div style={{ display: 'flex', marginTop: '20px' }}>
        <NumberFormat
          style={{ marginRight: '10px' }}
          decimalScale={2}
          fixedDecimalScale={discountsState.type === DISCOUNT_TYPES.AMOUNT}
          customInput={TextField}
          autoComplete="off"
          label="Amount"
          name="amount"
          variant="outlined"
          value={discountsState.amount}
          onChange={(e) => {
            handleOnChange(e.target);
          }}
          error={!isValidAmount(discountsState.type, discountsState.amount)}
          helperText={helperText()}
          type="tel"
          fullWidth
          disabled={disabled}
        />
        <CustomToggleButtonGroup
          value={discountsState.type || DISCOUNT_TYPES.PERCENTAGE}
          exclusive
          onChange={(e, value) => {
            handleOnChange({ name: 'type', value: value || discountsState.type || DISCOUNT_TYPES.PERCENTAGE });
          }}
        >
          <CustomToggleButton
            value={DISCOUNT_TYPES.PERCENTAGE}
            disabled={disabled}
            style={{ borderRadius: '7px 0px 0px 7px' }}
          >
            %
          </CustomToggleButton>
          <CustomToggleButton
            value={DISCOUNT_TYPES.AMOUNT}
            disabled={disabled}
            style={{ borderRadius: '0px 7px 7px 0px' }}
          >
            $
          </CustomToggleButton>
        </CustomToggleButtonGroup>
      </div>
      <TextField
        autoComplete="off"
        label="Description (Required)"
        name="reason"
        variant="outlined"
        placeholder="Add reason"
        value={discountsState.reason}
        onChange={(e) => {
          handleOnChange(e.target);
        }}
        helperText=" "
        fullWidth
        disabled={disabled}
      />
    </div>
  );
};
