import React from 'react';
import { CardContent } from '@material-ui/core';
import moment from 'moment';
import { Title } from '../common/card';
import { GENDER } from '../../constants/medicalProfile.constants';
import { calculateAge } from '../../utils/patient.util';
import { useStyles } from './PatientDetailHeader.styles';

const PatientDetailHeader = ({ patient }: any) => {
  const classes = useStyles();
  const { contactInfo, basicInfo } = patient || {};

  return (
    <CardContent className={`${classes.card} ${classes.headerCard}`}>
      <>
        <div className={classes.nameContainer}>
          {contactInfo && (
            <Title title={`${contactInfo.firstName} ${contactInfo.lastName}`} className={classes.headerTitle} />
          )}
        </div>
        <h5 className={classes.subtitle}>
          {basicInfo &&
            // eslint-disable-next-line max-len
            `${GENDER[basicInfo.sex] || ''}${basicInfo.sex ? ', ' : ''} ${calculateAge(basicInfo.dateOfBirth) || ''} ${
              basicInfo.dateOfBirth ? ' - ' : ''
            } Created ${moment(basicInfo.createdAt).format('MM/DD/YYYY')} - ${contactInfo?.phone ? 'Phone ' : ''}${
              contactInfo?.phone || ''
            }
              - MRN: ${basicInfo.id}
            `}
        </h5>
      </>
    </CardContent>
  );
};

export default PatientDetailHeader;
