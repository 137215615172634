import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    width: '440px',
    padding: '4rem 2rem',
    background: 'white',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '1rem 0 2rem',
  },
  modalBody: {
    marginBottom: '2rem',
  },
  doneButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '12px',
    padding: '1rem 2rem',
    borderRadius: '8px',
    margin: '0 0.5rem',
    width: '80%',
    textAlign: 'center',
    textTransform: 'none',
  },
  deleteButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '12px',
    padding: '1rem 2rem',
    borderRadius: '8px',
    margin: '0 0.5rem',
    width: '150px',
    textAlign: 'center',
    textTransform: 'none',
  },
  cancelButton: {
    textAlign: 'center',
    color: '#12574D',
    border: '1px solid #12574D',
    borderRadius: '8px',
    margin: '0 0.5rem',
    width: '150px',
    textTransform: 'none',
  },
}));
