import React from 'react';
import { OrderStatusParams } from 'src/interfaces/IInventoryOrder';
import { Container, Typography, withStyles } from '@material-ui/core';

const OrderStatus: React.FC<OrderStatusParams> = ({ status, classes, className }) => (
  <Container className={className} classes={classes} data-status={status}>
    <Typography variant="caption" className={classes?.orderStatusText}>
      {status}
    </Typography>
  </Container>
);

export default withStyles({
  root: {
    width: '125px',
    display: 'flex',
    margin: 'auto 25px auto auto',
    color: 'rgb(209,241,189)',
    backgroundColor: 'rgb(49,79,31)',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontFamily: 'Messina Sans Regular',
    '&[data-status="order confirmed"]': {
      backgroundColor: 'rgb(189,235,241)',
      color: 'rgb(21,83,88)',
    },
    '&[data-status="order requested"]': {
      color: 'rgb(83,61,110)',
      backgroundColor: 'rgb(230,211,255)',
    },
    '&[data-status="order placed"] , &[data-status="paid"]': {
      color: 'rgb(49,79,31)',
      backgroundColor: 'rgb(209,241,189)',
    },
    '&[data-status="rejected"] , &[data-status="canceled"]': {
      color: 'rgb(109,59,59)',
      backgroundColor: 'rgb(255,203,203)',
    },
  },
  orderStatusText: { margin: 'auto' },
})(OrderStatus);
