import { useQuery } from 'react-query';
import { NOTIFICATIONS, GLOBAL_NOTIFICATIONS } from '../../constants/reactQuery.keys';
import Patients from '../../services/Patients';
import { getNotifications } from '../../services/Notifications';

const { getContactInfo } = Patients;

// by now this hook is a wrapper for the contact info endpoint,
// because there is the unreadMessagesCount property
// but it we could replace the endpoint to a notification endpoint and include other things there
export function useNotifications(customerId: string | number) {
  return useQuery([NOTIFICATIONS, customerId], () => getContactInfo(customerId));
}

export function useGlobalsNotifications() {
  return useQuery(GLOBAL_NOTIFICATIONS, getNotifications);
}

export { GLOBAL_NOTIFICATIONS } from '../../constants/reactQuery.keys';
