import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, withStyles } from '@material-ui/core';
import React from 'react';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import RowItem from './RowItem';
import Message from '../../../common/Message/Message';

const TableCellHeader = withStyles(() => ({
  root: {
    fontSize: '15px',
  },
}))(TableCell);

const TableTodo = (props: any) => {
  const { data, isLoading, services } = props;

  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCellHeader width={5} />
            <TableCellHeader data-cy="todoDateColumnTitle" width={50}>
              Date
            </TableCellHeader>
            <TableCellHeader data-cy="todoPatientColumnTitle">Patient</TableCellHeader>
            <TableCellHeader data-cy="todoPatientIdColumnTitle" style={{ padding: 5, width: 90 }}>
              Patient ID
            </TableCellHeader>
            <TableCellHeader data-cy="todoActionColumnTitle">Actions</TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody data-cy="todoTableBody">
          {!data?.length && !isLoading && <Message text="No data to show" colSize={12} />}
          {isLoading && !data?.length && <MultipleSkeleton />}
          {data &&
            data.map((row: any) => (
              <RowItem data-cy="todoLineItem" key={row.index} row={{ ...row }} services={services} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableTodo;
