import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { CardContent, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Title } from '../../common/card';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { useStyles } from './beautyBankSection.styles';
import compile from '../../../utils/toastMessagesCompiler';
import { PATIENT_BASIC_INFO } from '../../../constants/reactQuery.keys';
import { Button } from '../../common/Button';
import { useCustomerMembershipConsent } from '../../../hooks/queries/useCustomerMembershipConsent';
import { useCreateCustomerMembershipConsent } from '../../../hooks/mutations/useCreateCustomerMembershipConsent';
import { MembershipConsentModal } from '../MembershipConsent/MembershipConsentModal';
import { RootState, dispatch } from '../../../rematch';
import { useMembershipConsent } from '../../../hooks/queries/useMembershipConsent';
import {
  BEAUTY_BANK,
  SQUARE_PLAN_ID,
  SIGN_AGREEMENT,
  MANAGE_MEMBERSHIP,
  BEAUTY_BANK_SUBSCRIPTION_STATUS,
} from '../../../constants/beautyBank.constants';
import ISubscription from '../../../interfaces/ISubscription';

type BeautyBankSectionProps = {
  patientId: string;
  isMedspaAdmin?: boolean;
};

const BeautyBankSection: React.FC<BeautyBankSectionProps> = ({
  patientId,
  isMedspaAdmin = false,
}: BeautyBankSectionProps) => {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { firstName, lastName } = useSelector(({ patient }: any) => patient);
  const { data: customerMembershipConsent, refetch: customerMembershipConsentRefetch } = useCustomerMembershipConsent(
    Number(patientId)
  );
  const createCustomerMembershipConsentMutation = useCreateCustomerMembershipConsent();
  const { data: membershipConsent } = useMembershipConsent(SQUARE_PLAN_ID);
  const [opened, setOpened] = useState<boolean>(false);
  const [consentContent, setConsentContent] = useState<string>('');
  const [consentName, setConsentName] = useState<string>('');
  const [patientName, setPatientName] = useState<string>('');
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [customerSubscription, setCustomerSubscription] = useState<ISubscription | null>(null);

  const handleClose = () => {
    setOpened(false);
    setPatientName('');
  };

  const {
    isLoadingBasicInfo,
    basicInfo: { basicInfoLoadedAt, subscription },
  } = useSelector(({ patient }: RootState) => patient);

  useEffect(() => {
    if (basicInfoLoadedAt && subscription && subscription.status !== BEAUTY_BANK_SUBSCRIPTION_STATUS.CREATED) {
      setCustomerSubscription(subscription);
    }
  }, [basicInfoLoadedAt, subscription]);

  // Actions
  const signMembershipConsent = async (file: string, name: string) => {
    setIsSigning(true);
    try {
      await createCustomerMembershipConsentMutation.mutateAsync({
        patientId,
        planId: membershipConsent.squarePlanId,
        patientName: name,
        signature: file,
      });

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Membership consent',
            action: 'signed',
          }),
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'signing',
            element: 'the membership consent',
          }),
          type: 'error',
        },
      });
    } finally {
      setIsSigning(false);
      setOpened(false);
      await queryClient.invalidateQueries([PATIENT_BASIC_INFO, patientId]);
      customerMembershipConsentRefetch();
    }
  };

  const { metadataPdfUrl } = customerMembershipConsent || {};

  const handleButtonClick = async () => {
    if (customerMembershipConsent) {
      history.push(`/patient/${patientId}/membership`);
    } else {
      setOpened(true);
      setPatientName(`${firstName} ${lastName}`);
      setConsentName(membershipConsent.name);
      setConsentContent(membershipConsent.content);
    }
  };

  const openSquareUrl = () => {
    const { manageMembershipUrl } = membershipConsent;
    const membershipUrl = customerSubscription?.subscriptionId
      ? `${manageMembershipUrl}${customerSubscription?.subscriptionId}`
      : manageMembershipUrl;
    window.open(membershipUrl, '_blank');
  };

  return (
    <Card>
      {!basicInfoLoadedAt || isLoadingBasicInfo ? (
        <CardContent style={{ padding: '12px 15px' }}>
          <ShortMultipleSkeleton length={2} />
        </CardContent>
      ) : (
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title title={BEAUTY_BANK} className={classes.my0} />
            <div>
              {customerMembershipConsent && (
                <Button
                  title="Open Square"
                  style={{
                    minWidth: '164px',
                    height: '43px',
                    color: '#000000',
                    backgroundColor: '#e7eeed',
                    marginLeft: 0,
                    borderRadius: '8px',
                    fontWeight: 'bold',
                  }}
                  onClick={openSquareUrl}
                />
              )}
              {!isMedspaAdmin && (
                <Button
                  title={!customerMembershipConsent ? SIGN_AGREEMENT : MANAGE_MEMBERSHIP}
                  style={{
                    minWidth: '164px',
                    height: '43px',
                    color: '#000000',
                    backgroundColor: '#e7eeed',
                    marginLeft: 0,
                    borderRadius: '8px',
                    fontWeight: 'bold',
                  }}
                  onClick={handleButtonClick}
                  disabled={!membershipConsent}
                />
              )}
            </div>
          </div>

          <Grid container spacing={1} className={classes.beautyBankSection}>
            <Grid item xs={4} className={classes.title}>
              Status
            </Grid>
            <Grid item xs={8}>
              {customerSubscription?.statusLabel}
            </Grid>
            <Grid item xs={4} className={classes.title}>
              Member Since
            </Grid>
            <Grid item xs={8}>
              {customerSubscription?.startDate}{' '}
              {customerSubscription?.canceledDate && <>(active until {customerSubscription?.canceledDate})</>}
            </Grid>
            <Grid item xs={4} className={classes.title}>
              Membership Agreement
            </Grid>
            <Grid item xs={8}>
              {metadataPdfUrl && (
                <a className={classes.documentLink} href={metadataPdfUrl} target="_blank" rel="noreferrer">
                  View Agreement
                </a>
              )}
            </Grid>
          </Grid>
        </CardContent>
      )}
      <MembershipConsentModal
        isOpen={opened}
        onClose={handleClose}
        consentName={consentName}
        consentContent={consentContent}
        patientNameProp={patientName}
        onSign={signMembershipConsent}
        isSigning={isSigning}
      />
    </Card>
  );
};

function areEqual(prevProps: { patientId: string }, nextProps: { patientId: string }) {
  return prevProps.patientId === nextProps.patientId;
}

export default React.memo(BeautyBankSection, areEqual);
