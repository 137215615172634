import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

type Props = {
  text: string;
  colSize?: number;
};

const useStyles = makeStyles(() => ({
  message: {
    width: '100%',
    display: 'inline-block',
  },
}));

const Message = ({ text, colSize }: Props) => {
  const classes = useStyles();
  return (
    <td colSpan={colSize} className={colSize ? '' : classes.message}>
      <Box textAlign="center">
        <p data-cy="message">{text}</p>
      </Box>
    </td>
  );
};

export default Message;
