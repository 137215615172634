import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    '&:last-child': {
      marginRight: 0,
    },
  },
  formControl: {
    width: '92px',
    marginRight: '21px',
  },
});
