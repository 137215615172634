import React, { useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Modal } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Button } from '../../common/Button';
import { useStyles } from './MembershipConsentModal.styles';
import { MembershipCosentAgreementModal } from './MembershipConsentAgreementModal';

interface IMembershipConsentModalProps {
  isOpen: boolean;
  isSigning: boolean;
  onClose: () => void;
  onSign: (signatureUrl: string, name: string) => Promise<void>;
  consentName: string;
  consentContent: string;
  patientNameProp: string;
}
export const MembershipConsentModal = ({
  isOpen,
  isSigning,
  onClose,
  onSign,
  consentName,
  consentContent,
  patientNameProp,
}: IMembershipConsentModalProps) => {
  let signCanvas: any;
  const classes = useStyles();
  const [patientName, setPatientName] = useState(patientNameProp);
  const [isSigned, setIsSigned] = useState(false);
  const [openAgreementModal, setOpenAgreementModal] = useState(false);

  useEffect(() => {
    setPatientName(patientNameProp);
  }, [patientNameProp]);

  const clearSignature = () => {
    setIsSigned(false);
    signCanvas.clear();
  };

  const signConsent = () => {
    const file = signCanvas.getTrimmedCanvas().toDataURL('image/png');

    onSign(file, patientName);
    setIsSigned(false);
  };

  const onBeginStroke = () => {
    setIsSigned(true);
  };

  const handleClose = () => {
    onClose();
  };

  const handleOpenAgreementModal = () => {
    setOpenAgreementModal(true);
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <div className={classes.membershipConsentModal}>
          <div>
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
            <div className={classes.contentContainer}>
              <div className={classes.contentButton}>
                <Button
                  className={classes.consentFormButton}
                  title="MEMBERSHIP AGREEMENT"
                  onClick={handleOpenAgreementModal}
                />
              </div>
              <div className={classes.secondaryTitle}>Signature of Patient</div>
              <div data-cy="signatureCanvas" className={classes.consentSignature}>
                <div className={classes.signatureTitle}>Signature of Patient</div>
                <SignatureCanvas
                  canvasProps={{ className: classes.consentSignatureCanvas }}
                  ref={(ref) => {
                    signCanvas = ref;
                  }}
                  onBegin={onBeginStroke}
                  clearOnResize={false}
                />
              </div>
              <div className={classes.secondaryTitle}>Patient Name</div>
              <div className={classes.inputPatientName}>
                <input
                  type="text"
                  value={patientName}
                  className={classes.patientName}
                  onChange={(e) => setPatientName(e.currentTarget.value)}
                />
              </div>
              <div className={classes.consentButtonsContainer}>
                <Button className={classes.clearSignatureButton} title="CLEAR SIGNATURE" onClick={clearSignature} />
                <Button
                  className={classes.doneSigningButton}
                  title={isSigning ? 'SIGNING...' : 'DONE SIGNING'}
                  onClick={signConsent}
                  disabled={!isSigned || isSigning || !patientName}
                  style={{ color: !isSigned || isSigning ? 'white' : undefined }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <MembershipCosentAgreementModal
        open={openAgreementModal}
        consentName={consentName}
        consentContent={consentContent}
        onClose={() => setOpenAgreementModal(false)}
      />
    </>
  );
};
