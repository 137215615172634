import React, { useEffect, useState } from 'react';
import { MenuItem, TextField, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';

import { IFollowUpMessage, Frequency } from '../../../interfaces/ICustomCareMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      '& .MuiTextField-root,.MuiTypography-root': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    row: {
      display: 'flex',
    },
    mxAuto: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  })
);

type Props = {
  readOnly: boolean | undefined;
  data: IFollowUpMessage | undefined;
  onChange: any;
};
const FollowUpForm = ({ readOnly = false, data, onChange }: Props) => {
  const classes = useStyles();

  const { message, periodNumber, periodFrequency } = data || {};

  const [emptyMessage, setEmptyMessage] = useState<boolean>(true);

  useEffect(() => {
    setEmptyMessage(Boolean(!message));
  }, [message]);

  return (
    <div className={classes.root}>
      <TextField
        id="follow_up_message"
        label="Send the patient a follow up message via SMS (Optional)"
        style={{ margin: 8, marginBottom: '1.5rem' }}
        fullWidth
        disabled={readOnly}
        margin="normal"
        value={message}
        onChange={(e) => onChange({ message: e.target.value })}
      />
      <div className={classes.row}>
        <Typography className={classes.mxAuto}> Send </Typography>
        <TextField
          id="follow_up_period_number"
          className={classes.mxAuto}
          type="number"
          disabled={readOnly || emptyMessage}
          margin="dense"
          defaultValue="1"
          value={periodNumber}
          onChange={(e) => onChange({ periodNumber: e.target.value })}
        />
        <TextField
          id="follow_up_period_frequency"
          className={classes.mxAuto}
          select
          disabled={readOnly || emptyMessage}
          margin="dense"
          value={periodFrequency}
          onChange={(e) => onChange({ periodFrequency: e.target.value })}
        >
          {Frequency.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Typography className={classes.mxAuto}> After Service </Typography>
      </div>
    </div>
  );
};

export default FollowUpForm;
