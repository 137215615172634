import { createModel } from '@rematch/core';
import { groupBy, filter, map, flatten } from 'lodash';
import { InventoryDefaultState } from '../types/InventoryDefault';
import { RootModel } from './rootModel';
import axiosInstance from '../utils/axios';
import { IAsset } from '../interfaces/reconciliation.interfaces';
import { isAssetAboutToExpire } from '../utils/inventory.utils';
import compile from '../utils/toastMessagesCompiler';

export const inventoryDefault = createModel<RootModel>()({
  state: {
    suppliersList: [],
    dashboardList: [],
    groupedProductList: [],
    productAtRiskCount: 0,
    isLoadingInventory: false,
  } as InventoryDefaultState,
  reducers: {
    setSuppliersList(state: InventoryDefaultState, payload: any) {
      return { ...state, suppliersList: payload };
    },
    setDashboardList(state: InventoryDefaultState, payload: any) {
      let assets: IAsset[] = [];
      payload.forEach((item: any) => {
        assets = flatten(assets.concat(map(item.products, (product: any) => product.assets)));
      });

      const productAtRiskCount = filter(assets, (asset: IAsset) => isAssetAboutToExpire(asset)).length;

      return { ...state, dashboardList: payload, productAtRiskCount, groupedProductList: groupBy(assets, 'name') };
    },
    setIsLoadingInventory(state: InventoryDefaultState, payload: boolean) {
      return { ...state, isLoadingInventory: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getDashboardLists(practitionerId: number) {
      try {
        dispatch.inventoryDefault.setIsLoadingInventory(true);
        const response = await axiosInstance.get(`/assets/dashboard-items-for-practitioner/${practitionerId}`);

        if (response.status !== 200) {
          throw new Error(response.data.message);
        }

        dispatch.inventoryDefault.setDashboardList(response.data.items);
        dispatch.inventoryDefault.setSuppliersList(response.data.suppliers);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            type: 'error',
            message: compile('generic.error_message', {
              action: 'loading',
              element: 'the inventory dashboard',
            }),
          },
        });
      } finally {
        dispatch.inventoryDefault.setIsLoadingInventory(false);
      }
    },
  }),
});
