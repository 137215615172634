import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  activeTab: {
    color: 'white',
    background: '#12574D',
    textTransform: 'none',
    borderRadius: '25px',
    padding: '4px 25px',
    '&:hover': {
      background: '#12574D',
    },
  },
  inactiveTab: {
    color: '#12574D',
    border: '1px solid #12574D',
    background: '#E7EEED',
    textTransform: 'none',
    borderRadius: '25px',
    padding: '4px 25px',
    '&:hover': {
      background: '#E7EEED',
    },
  },
  mr10: {
    marginRight: '10px',
  },
  selectCardsContainer: {
    marginTop: '20px',
  },
  checkoutButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '12px',
    width: '236px',
    padding: '1rem 2rem',
    borderRadius: '8px',
    margin: '1rem 0',
    textAlign: 'center',
    textTransform: 'none',
    '&:disabled': {
      color: 'white',
      opacity: '50%',
    },
    '&:hover': {
      background: '#12574D',
    },
  },
  processing: {
    color: 'white',
    marginLeft: theme.spacing(2),
  },
  tabsContainer: {
    marginBottom: '2rem',
  },
  subtitle: {
    margin: '0.8rem 0 2rem',
  },
}));
