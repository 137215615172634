import { Theme } from '@material-ui/core';
import { classes } from './classes';

const groupingStyles = ({ theme }: { theme: Theme }) => ({
  // Horizontal
  [`&.${classes.providerHeader}`]: {
    '& > div > div': {
      width: '100%',
    },
    [`& .${classes.providerNameContainer}`]: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  // Vertical
  [`&.${classes.providerHeaderVertical}`]: {
    '& > div > div': {
      width: '100%',
      height: '100%',
    },
    [`& .${classes.providerNameContainer}`]: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    [`& .${classes.providerName}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
    },
  },
  [`& .${classes.icon}`]: {
    paddingLeft: theme.spacing(1),
    verticalAlign: 'middle',
  },
  '&.disabled-date': {
    backgroundImage: `
      repeating-linear-gradient(
        -60deg,
        #e5e5e5 1px,
        #f0f0f0 4px,
        #f0f0f0 9px
      )`,
    color: '#9b6467',
    cursor: 'not-allowed',
  },
});

export { groupingStyles };
