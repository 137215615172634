import { useEffect } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

import { SERVICE_VISIT_ASSETS, SERVICE_VISIT_ASSET_COUNT } from '../../../constants/reactQuery.keys';
import Asset from '../../../services/Asset';
import { DEFAULT_LIMIT } from '../../../constants/general.constants';
import { IServiceVisitAssetViewParams } from '../../../interfaces/IServiceVisitAsset.interface';

export function useServiceVisitAssetsCount(paramsQuery: IServiceVisitAssetViewParams) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([SERVICE_VISIT_ASSET_COUNT, paramsQuery]);
  }, [paramsQuery]);

  return useQuery([SERVICE_VISIT_ASSET_COUNT, paramsQuery], () => Asset.getServiceVisitAssetsCount(paramsQuery), {
    initialData: 0,
    refetchOnWindowFocus: false,
    retry: 1,
    keepPreviousData: true,
  });
}

export function useServiceVisitAssets(paramsQuery: IServiceVisitAssetViewParams, total: number = 0) {
  const limit = paramsQuery?.limit || DEFAULT_LIMIT;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([SERVICE_VISIT_ASSETS, paramsQuery]);
  }, [paramsQuery]);

  const query = useInfiniteQuery<any>(
    [SERVICE_VISIT_ASSETS, paramsQuery],
    ({ pageParam = 1 }) => Asset.getServiceVisitAssets({ ...paramsQuery, page: pageParam }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * limit < total : lastPage.length === limit;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const assets =
    query?.data?.pages
      ?.map((items) => items)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, assets, currentPage: query?.data?.pages.length } };
}
