import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export const CustomToggleButtonGroup = withStyles(() => ({
  root: {
    alignSelf: 'baseline',
    height: '58px'
  },
  grouped: {
    paddingRight: '25px',
    paddingLeft: '25px',
    fontSize: '20px'
  }
}))(ToggleButtonGroup);

export const CustomToggleButton = withStyles(() => ({
  selected: {
    color: '#12574d !important',
    backgroundColor: '#f0f0f0 !important'
  },
  disabled: {
    opacity: 0.5
  }
}))(ToggleButton);
