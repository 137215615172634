import { ICustomerNote, ICustomerNotePost } from '../interfaces/ICustomerNote';
import compile from '../utils/toastMessagesCompiler';
import { dispatch } from '../rematch';
import Api from './Api';

const BASE_PATH = '/v3/sticky_notes';
const ERROR_GETTING_NOTES = compile('generic.error_message', {
  action: 'getting',
  element: 'sticky notes',
});

interface SaveParams {
  customerNoteInformation: ICustomerNote;
}

const getCustomerNotes = async (customerId: number): Promise<ICustomerNote[]> => {
  const { stickyNotes, error } = await Api.get(`${BASE_PATH}/customer_sticky_notes/${customerId}`);
  if (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: ERROR_GETTING_NOTES,
        type: 'error',
        duration: 1000,
      },
    });
  }
  return stickyNotes;
};

const saveCustomerNote = async ({ customerNoteInformation }: SaveParams): Promise<ICustomerNote | undefined> => {
  const { id } = customerNoteInformation;
  const { results, error } = await Api.put(`${BASE_PATH}/${id}/mark_as_viewed`, { id });
  if (error) {
    throw error;
  }
  return results;
};

const createCustomerNote = async ({ customerId, note }: ICustomerNotePost): Promise<ICustomerNotePost> => {
  const { results, error } = await Api.post(`${BASE_PATH}/create`, {
    customerId,
    note,
  });
  if (error) {
    throw error;
  }
  return results;
};

const removeCustomerNote = async ({ id }: any): Promise<number | undefined> => {
  if (typeof id === 'undefined' && id === '') {
    throw new Error('Something went wrong');
  }
  const { data, error } = await Api.delete(`${BASE_PATH}/${id}/destroy`);
  if (error) {
    throw error;
  }
  return data;
};

export default {
  getCustomerNotes,
  removeCustomerNote,
  saveCustomerNote,
  createCustomerNote,
};
