import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 72px'
  },
  headerTitle: {
    fontSize: '24px',
    letterSpacing: '-0.05px',
    color: '#12574D',
    opacity: 1,
    fontFamily: 'Messina Sans Regular'
  },
  cancelButton: {
    color: '#12574d',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    width: '117px',
    marginRight: '20px'
  },
  applyButton: {
    color: '#ffffff !important',
    backgroundColor: '#12574d',
    width: '117px'
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '46px'
  }
}));
