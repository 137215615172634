/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orderBy, debounce } from 'lodash';
import { Box, TextField, FormControl, MenuItem, InputLabel, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useStyles, SelectCustom } from './adminAssets.styles';
import { IAssetQuery } from '../../../interfaces/global.interfaces';
import { ICommonState } from '../../../rematch/common.model';
import InputSearch from '../../common/InputSearch';

interface Props {
  query: IAssetQuery;
  setQuery: (query: IAssetQuery) => void;
}
const STATUS_OPTIONS = [
  { value: undefined, name: 'All' },
  { value: 'sold', name: 'Sold' },
  { value: 'in_stock', name: 'In Stock' },
  { value: 'waste', name: 'Waste' },
];

const AdminAssetsHeader = ({ setQuery, query }: Props) => {
  const classes = useStyles();
  const [search, setSearch] = useState<string | any>('');
  const { warehouses, services, suppliers } = useSelector((state: any) => state.common as ICommonState);
  const { isLoading } = useSelector((state: any) => state.assets);

  const [form, setForm] = useState({
    warehouseName: '',
    serviceName: '',
    supplierName: '',
  });

  const handleChange = ({ name, value }: { name: string; value: any }) => {
    const newQuery = {
      ...query,
      page: 1,
      [name || '']: value,
    };
    setQuery(newQuery);
  };

  const doSearch = useMemo(
    () => debounce((valueSearch: any) => handleChange({ value: valueSearch, name: 'internalSerialNumber' }), 2000),
    [query]
  );

  const onChange = (event: React.ChangeEvent<any>) => {
    const newValue = event.target.value;
    setSearch(newValue);
    doSearch(newValue);

    if (newValue === '') {
      setSearch('');
      doSearch('');
    }
  };

  useEffect(() => {
    setForm({
      ...form,
      warehouseName: warehouses.find(({ id }) => id === query.warehouseId)?.providerLabeling || '',
      serviceName: services.find(({ id }) => id === query.serviceId)?.name || '',
      supplierName: suppliers.find(({ id }) => id === query.supplierId)?.name || '',
    });
  }, [query, warehouses, services, suppliers]);

  return (
    <Box padding={2} paddingBottom={1}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            className={classes.autocompleteForm}
            size="small"
            options={orderBy(warehouses, ['providerLabeling'], ['asc'])}
            getOptionLabel={(option) => option.providerLabeling}
            inputValue={form.warehouseName}
            value={query.warehouseId as any}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Warehouse"
                variant="outlined"
                onChange={(e) => setForm({ ...form, warehouseName: e.target.value })}
              />
            )}
            onChange={(ev, newValue) => handleChange({ value: newValue?.id || null, name: 'warehouseId' })}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            className={classes.autocompleteForm}
            size="small"
            options={orderBy(services, ['name'], ['asc'])}
            getOptionLabel={(option) => option.name}
            value={query.serviceId as any}
            inputValue={form.serviceName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Service"
                variant="outlined"
                onChange={(e) => setForm({ ...form, serviceName: e.target.value })}
              />
            )}
            onChange={(ev, newValue) => handleChange({ value: newValue?.id || null, name: 'serviceId' })}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            className={classes.autocompleteForm}
            size="small"
            options={orderBy(suppliers, ['name'], ['asc'])}
            getOptionLabel={(option) => option.name}
            value={query.supplierId as any}
            inputValue={form.supplierName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supplier"
                variant="outlined"
                onChange={(e) => setForm({ ...form, supplierName: e.target.value })}
              />
            )}
            onChange={(ev, newValue) => handleChange({ value: newValue?.id || null, name: 'supplierId' })}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2} style={{ marginTop: '5px' }}>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormControl size="small" className={classes.formControl} variant="outlined">
            <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>Status</InputLabel>
            <SelectCustom
              label="Status"
              name="status"
              defaultValue={query.status}
              onChange={(ev) => handleChange({ value: ev.target.value, name: 'status' })}
            >
              {STATUS_OPTIONS.map(({ value, name }) => (
                <MenuItem value={value} key={value}>
                  {name}
                </MenuItem>
              ))}
            </SelectCustom>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <InputSearch
            placeholder="Internal serial number"
            onChangeValue={onChange}
            value={search}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </Box>
  );
};

export default AdminAssetsHeader;
