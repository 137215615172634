import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';

import { useHistory, useLocation } from 'react-router-dom';
import { IMedspaCustomCareMessage } from 'src/interfaces/IMedspaCareMessages';
import { ExpandMore as ExpandMoreIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useMutation } from 'react-query';
import MedspaCareMessages from 'src/services/MedspaCareMessages';
import { useSelector } from 'react-redux';
import { MEDSPA_ADMIN_SERVICES_MANAGEMENT, MEDSPA_DOCUMENTS_MANAGEMENT } from 'src/routes/medspaAdminRoutes';
import { CARE_MESSAGES_DOCUMENT_TYPE } from 'src/constants/medspa/documentsManagement.constants';
import { hasAccessTo } from 'src/utils/auth.utils';
import { CUSTOM_SERVICES, UPDATE } from 'src/constants/actions.constants';
import CustomDialog from 'src/pages/CareMessages/components/CustomDialog';
import { useMedspaServiceGroups } from 'src/hooks/mutations/useMedspaServiceGroups';
import { CREATE, VIEW } from 'src/constants/medspa/servicesManagement.constants';
import { showSnackbar } from 'src/utils/global';
import { useStyles } from '../documentsManagement.styles';
import Form from '../../../../pages/CareMessages/components/MessageForm';

interface LocationState {
  document: IMedspaCustomCareMessage;
  isFromMasterLibrary: boolean;
  editable: boolean;
  documentType: string;
  backUrl: string;
  action: string;
  form: any;
  searchUrl: string;
}

const INITIAL_STATE = {
  document: {
    name: '',
    preCare: {
      introductoryMessage: '',
      mandatoryMessage: '',
      endingMessage: '',
    },
    postCare: {
      introductoryMessage: '',
      mandatoryMessage: '',
      endingMessage: '',
    },
    followUp: {
      message: '',
      periodNumber: '',
      periodFrecuency: '',
    },
  },
  isFromMasterLibrary: true,
  documentType: CARE_MESSAGES_DOCUMENT_TYPE,
  backUrl: MEDSPA_DOCUMENTS_MANAGEMENT,
  action: VIEW,
  searchUrl: '',
};

const CustomizeCareMessages = () => {
  const {
    user: { userGroupId },
    permissions,
  } = useSelector(({ auth }: any) => auth);

  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation<LocationState>();
  const [name, setName] = useState('');
  const [selectedServicesGroups, setSelectedServicesGroups] = useState<{ [key: string]: boolean }>({});

  const { document, isFromMasterLibrary, backUrl, action, form, searchUrl, documentType } = state || INITIAL_STATE;

  const { data: serviceGroups = [] } = useMedspaServiceGroups(userGroupId);
  const { mutateAsync: updateCustomCareMessage, isLoading: isLoadingUpdate } = useMutation(
    MedspaCareMessages.updateMedspaCareMessages
  );
  const { mutateAsync: createCustomCareMessage } = useMutation(MedspaCareMessages.createMedspaCareMessages);

  const [customPreCareMessage, setCustomPreCareMessage] = useState(document.preCare);
  const [customPostCareMessage, setCustomPostCareMessage] = useState(document.postCare);

  const [servicesDialogOpen, setServicesDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSubmit = async (isCreate: boolean = false) => {
    if (isCreate && (!name || !customPostCareMessage?.mandatoryMessage || !customPreCareMessage?.mandatoryMessage)) {
      showSnackbar(`All of the fields are required to create a new ${documentType}`);
      return;
    }

    const copyTo = Object.entries(selectedServicesGroups)
      .filter(([, isSelected]) => isSelected)
      .map(([serviceId]) => +serviceId);

    const params = {
      name: isCreate ? name : document.name,
      preCare: {
        ...customPreCareMessage,
      },
      postCare: {
        ...customPostCareMessage,
      },
      followUp: {
        ...document.followUp,
      },
      serviceGroupApplied: copyTo,
    };
    if (isCreate) {
      const data = await createCustomCareMessage({ userGroupId, params });
      navigateBack({ careMessages: data });
    } else {
      await updateCustomCareMessage({ userGroupId, params, documentId: document.id });
    }
  };

  const navigateBack = (formData = {}) => {
    if (backUrl) {
      history.push({
        pathname: backUrl,
        search: searchUrl,
        state: {
          form: { ...form, ...formData },
        },
      });
    } else {
      history.push(MEDSPA_ADMIN_SERVICES_MANAGEMENT);
    }
  };

  const selectedServiceGroupsNames = () => {
    const selectedServicesGroupsIds = Object.entries(selectedServicesGroups)
      .filter(([, isSelected]) => isSelected)
      .map(([serviceId]) => serviceId);

    const names = serviceGroups
      .filter((sg) => selectedServicesGroupsIds.includes(sg.id.toString()))
      .map((sg) => sg.name)
      .join(', ');
    const replacingLastComma = `, ${names}`.replace(/,(?=[^,]*$)/, ' & ');
    return names ? replacingLastComma : '';
  };

  const Title =
    action === VIEW ? (
      <div style={{ marginBottom: '1rem' }}>
        <Typography variant="h5" component="h2" color="textPrimary">
          Template used for: {document.name}
        </Typography>
      </div>
    ) : (
      <div style={{ marginBottom: '1rem' }}>
        <Typography variant="h5" component="h2" color="textPrimary">
          Customize messages for: {document.name}
          {!document.isGlobal && (
            <Button
              size="small"
              variant="contained"
              color="default"
              style={{ marginLeft: '.5rem' }}
              onClick={() => setServicesDialogOpen(true)}
            >
              {hasAccessTo(CUSTOM_SERVICES, permissions)
                ? 'Apply same Care Messages to'
                : 'Apply the same Intro/Ending to'}
            </Button>
          )}
        </Typography>
        <Typography color="textSecondary">Write messages to your patients for before and after services</Typography>
      </div>
    );

  const PreCareTab = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h5" className={classes.heading}>
          Pre care service messages{' '}
          <small className={classes.secondaryHeading}>(Will be sent approximately one week before the service)</small>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.root}>
        <Form
          isFromMasterLibrary={isFromMasterLibrary}
          readOnly={action === VIEW || isFromMasterLibrary}
          data={customPreCareMessage}
          onChange={(el: any) => setCustomPreCareMessage({ ...customPreCareMessage, ...el })}
        />
      </AccordionDetails>
    </Accordion>
  );

  const PostCareTab = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
        <Typography variant="h5" className={classes.heading}>
          Post care service messages{' '}
          <small className={classes.secondaryHeading}>(Will be sent immediately upon completion of the service)</small>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.root}>
        <Form
          isFromMasterLibrary={isFromMasterLibrary}
          readOnly={action === VIEW || isFromMasterLibrary}
          data={customPostCareMessage}
          onChange={(el: any) => setCustomPostCareMessage({ ...customPostCareMessage, ...el })}
        />
      </AccordionDetails>
    </Accordion>
  );

  const handleChangeCheckboxes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedServicesGroups({ ...selectedServicesGroups, [event.target.name]: event.target.checked });
  };

  const ServicesGroupCheckbox = (
    <Grid container spacing={2}>
      {serviceGroups.map(({ name: serviceName, id: serviceId }) => (
        <Grid item xs={12} sm={6} md={4} key={`checkbox_group_${serviceId}`}>
          <FormControlLabel
            key={serviceId}
            label={serviceName}
            control={
              <Checkbox
                color="primary"
                checked={selectedServicesGroups[serviceId] === true}
                onChange={handleChangeCheckboxes}
                name={`${serviceId}`}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid container alignItems="center" className={classes.gridContainer}>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Box style={{ cursor: 'pointer', marginRight: '10px' }}>
          <ArrowBackIcon onClick={() => history.push(backUrl)} />
        </Box>
        {Title}
      </Grid>

      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="name-id" color="secondary" className={classes.formControlLabel}>
            Name
          </InputLabel>
          <OutlinedInput
            id="name-id"
            type="text"
            fullWidth
            onChange={(ev: any) => setName(ev?.target?.value)}
            disabled={action !== CREATE}
            value={action === CREATE ? name : document.name}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {PreCareTab}
      </Grid>
      <Grid item xs={12}>
        {PostCareTab}
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right', margin: '10px' }}>
        {!isFromMasterLibrary && action === UPDATE && (
          <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
            Update
          </Button>
        )}
        {!isFromMasterLibrary && action === CREATE && (
          <Button color="primary" variant="contained" onClick={() => setDialogOpen(true)}>
            Create
          </Button>
        )}
      </Grid>
      <CustomDialog
        open={dialogOpen}
        title="Customize Message"
        content={
          <DialogContentText>
            <Typography>You are about to save your changes as a template to be used in:</Typography>
            <Typography color="primary" variant="h6" component="h2">
              {document.name || name}
              <small>{selectedServiceGroupsNames()}</small>
            </Typography>
            <small>This message will be used for all service groups messages which are not customized</small>
          </DialogContentText>
        }
        loading={isLoadingUpdate}
        okText="Yes, save this template"
        onOk={() => handleSubmit(true)}
        cancelText="Continue editing"
        onCancel={() => setDialogOpen(false)}
      />
      <CustomDialog
        open={servicesDialogOpen}
        title="Choose the service groups"
        content={ServicesGroupCheckbox}
        loading={isLoadingUpdate}
        okText="Done!"
        onOk={() => setServicesDialogOpen(false)}
      />
    </Grid>
  );
};

export default CustomizeCareMessages;
