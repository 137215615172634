/* eslint-disable no-unused-vars */
import Compressor from 'compressorjs';
import heic2any from 'heic2any';

const COMPRESS_QUALITY = 0.7;
const MAX_WIDTH = 2400;
const MAX_HEIGHT = 2400;
const HIEC_FORMAT = 'heic';
const JPEG_FORMAT_TYPE = 'image/jpeg';

interface Args {
  file: File | Blob;
  onSuccess: (file: Blob) => void;
  onError: (error: Error) => void;
}

export const compressImage = ({ file, onSuccess, onError }: Args) => {
  // eslint-disable-next-line no-new
  new Compressor(file, {
    quality: COMPRESS_QUALITY,
    success: onSuccess,
    error: onError,
    maxHeight: MAX_HEIGHT,
    maxWidth: MAX_WIDTH,
  });
};

export const validatesImageFormat = (file: any) => {
  const format = file.name.split('.').pop().toLocaleLowerCase().trim();

  if (HIEC_FORMAT === format) {
    return heic2any({
      blob: file,
      toType: JPEG_FORMAT_TYPE,
      quality: 1,
    });
  }
  return file;
};
