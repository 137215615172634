import { useMutation } from 'react-query';
import {
  ICreateOverrideHoursParams,
  IDeleteOverrideHoursParams,
  MedspaCalendar,
} from 'src/services/medspaAdmins/MedspaCalendar';
import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import compile from 'src/utils/toastMessagesCompiler';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { queryClient } from 'src/initializers/queryClient';

/* Create Override Hours */
export const useCreateOverrideHoursMutation = (successCallback?: () => void) =>
  useMutation((data: ICreateOverrideHoursParams) => MedspaCalendar.createOverrideHours(data), {
    onSuccess: ({ success }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          element: 'Override hours',
          action: 'updated',
        }),
        SNACKBAR_SUCCESS
      );

      queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf('medspaCalendar') > -1 });
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });

export const useDeleteOverrideHoursMutation = (successCallback?: () => void) =>
  useMutation((data: IDeleteOverrideHoursParams) => MedspaCalendar.deleteOverrideHours(data), {
    onSuccess: ({ success }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          element: 'Override hours',
          action: 'deleted',
        }),
        SNACKBAR_SUCCESS
      );

      queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf('medspaCalendar') > -1 });
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });
