import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: 'Messina Sans Regular',
    textAlign: 'start',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    height: '56px',
    backgroundColor: '#ECECEC',
    borderRadius: '3px',
    marginBottom: '10px'
  },
  input: {
    backgroundColor: 'white',
    marginRight: '10px'
  },
  form: {
    marginLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  iconButton: {
    color: 'white',
    backgroundColor: '#12574D',
    margin: '0',
    height: '40px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#12574D'
    }
  },
  cancelButton: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: '#12574D',
    height: '40px'
  },
  saveButton: {
    backgroundColor: '#12574D',
    color: 'white',
    height: '40px',
    marginRight: '0'
  },
  datePicker: {
    color: '#787878',
    fontFamily: 'Messina Sans Regular',
    backgroundColor: 'white',
    marginRight: '5px'
  },
  pendingScanText: {
    marginLeft: '15px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '32px'
    },
  }
}));
