import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  bar: {
    padding: '0 18px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginLeft: '8px',
    display: 'flex',
  },
  secondary: {
    color: '#fff',
  },
}));
