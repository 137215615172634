import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  nameContainer: {
    height: '80px',
    borderBottom: 'solid 1px #e4e7eb',
    paddingLeft: '25px',
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.88,
    letterSpacing: 'normal',
    color: '#000000'
  },
  grid: {
    alignItems: 'center'
  },
  form: {
    padding: '20px',
    maxWidth: '1024px'
  },
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));
