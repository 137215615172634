import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  titleField: {
    fontSize: '12px',
    color: '#706F6F',
    margin: 0,
  },
  optional: {
    opacity: 0.6,
    margin: '0 0.5rem',
  },
}));

const TitleComponent = ({
  title,
  optional = false,
  tooltipComponent,
}: {
  title: string;
  optional?: boolean;
  tooltipComponent?: React.ReactElement;
}) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Typography className={classes.titleField}>
        {title}
        {optional && <span className={classes.optional}>Optional</span>}
      </Typography>
      {tooltipComponent && (
        <Box p="0 0.5rem" display="flex" justifyContent="center">
          {tooltipComponent}
        </Box>
      )}
    </Box>
  );
};

export default TitleComponent;
