import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: "Messina Sans Regular",
    margin: '0 10px',
    padding: '16px',
    border: '1px solid #E4E7EB'
  },
  label: {
    fontSize: '14px',
    lineHeight: '23px',
    color: '#393B3F',
    marginRight: '40px',
    marginLeft: '7px'
  },
  note: {
    fontSize: '14px',
    lineHeight: '23px',
    color: '#000000',
    mixBlendMode: 'normal',
    opacity: 0.34
  }
}));

export const AdminNote = ({ adminNote }: any) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.label}>Admin Notes</span>
      <span className={classes.note}>{adminNote}</span>
    </div>
  );
};
