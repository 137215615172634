/* eslint-disable max-len */
import React from 'react';
import { Box, ButtonBase, Divider, Grid } from '@material-ui/core';
import { EHRTypography } from 'src/components/ui/v1';
import useStyles, { COMPLETED_STEP_COLOR } from 'src/components/StepsTracker/StepsTracker.styles';
import { CheckRounded } from '@material-ui/icons';
import { linkWrapper } from 'src/components/common/SignDocumentButton';
import { CommercialShippingAddress } from 'src/interfaces/onboarding.interface';
import { hasActiveShippingAddress, formatAddress } from 'src/utils/onboarding.utils';
import { IUserGroup } from '../../../interfaces/IUserGroup';
import useStylesMedspaSettings from '../index.styles';
import { UserInfoRow } from '../common/UserInfoRow';
import { pdfLinkStyle } from './MedicalDirectorSection';
import ShippingAddressModal from './ShippingAddressModal';
import ConfirmationModal from './ConfirmationModal';

interface MedspaDetailsProps {
  userGroup: IUserGroup;
  onboardingCompleted?: boolean;
}

const MedspaDetails = ({ userGroup, onboardingCompleted = false }: MedspaDetailsProps) => {
  const classes = useStyles();
  const classesMedspaSettings = useStylesMedspaSettings();
  const [isShippingAddressModalOpen, setIsShippingAddressModalOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [currentShippingAddress, setCurrentShippingAddress] = React.useState<CommercialShippingAddress>();
  const [openWarningModal, setOpenWarningModal] = React.useState(false);

  const { commercialShippingAddress } = userGroup;

  return (
    <>
      <EHRTypography variant="h6" dataCy="medspa-tab-title">
        MedSpa
      </EHRTypography>
      <Box className={classesMedspaSettings.medspaSettingsContainer}>
        <UserInfoRow label="MedSpa Name" userData={userGroup?.name} />
        <Divider />
        <UserInfoRow label="Product Type" userData={userGroup?.roleName ?? ''} />
        <Divider />
        <UserInfoRow label="Provider Entity" userData={userGroup?.businessEntityName} />
      </Box>
      {hasActiveShippingAddress(commercialShippingAddress) && (
        <>
          <Grid container justify="space-between">
            <Grid item>
              <EHRTypography variant="h6" dataCy="medspa-section-title">
                Commercial Shipping Address
              </EHRTypography>
            </Grid>
            <Grid item>
              {onboardingCompleted && (
                <ButtonBase
                  onClick={() => {
                    setIsShippingAddressModalOpen(true);
                    setEditMode(false);
                    setCurrentShippingAddress(undefined);
                  }}
                >
                  <EHRTypography dataCy="update-commercial-shipping-address" variant="body2">
                    Add
                  </EHRTypography>
                </ButtonBase>
              )}
            </Grid>
          </Grid>
          <Box>
            {commercialShippingAddress?.map((shippingAddress) => (
              <Box className={classesMedspaSettings.medspaSettingsContainer}>
                <UserInfoRow
                  label="Commercial Shipping Address"
                  userData={formatAddress(
                    shippingAddress?.address ?? '',
                    shippingAddress?.city ?? '',
                    shippingAddress?.state ?? '',
                    shippingAddress?.zip
                  )}
                  third={
                    onboardingCompleted ? (
                      <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                          {commercialShippingAddress.length > 1 && (
                            <ButtonBase
                              onClick={() => {
                                setOpenWarningModal((prevState) => !prevState);
                                setCurrentShippingAddress(shippingAddress);
                              }}
                            >
                              <EHRTypography dataCy="remove-commercial-shipping-address" variant="body2">
                                Remove
                              </EHRTypography>
                            </ButtonBase>
                          )}
                        </Grid>
                        <Grid item>
                          <ButtonBase
                            onClick={() => {
                              setIsShippingAddressModalOpen(true);
                              setEditMode(true);
                              setCurrentShippingAddress(shippingAddress);
                            }}
                          >
                            <EHRTypography dataCy="update-commercial-shipping-address" variant="body2">
                              Update
                            </EHRTypography>
                          </ButtonBase>
                        </Grid>
                      </Grid>
                    ) : null
                  }
                />

                {shippingAddress?.shippingAddressAmendmentPdf && (
                  <>
                    <Divider />
                    <UserInfoRow
                      label="Real Estate Business Management"
                      content={
                        <Box display="flex" alignItems="center" gridGap="0.5rem">
                          <div className={classes.filledGreenCircle}>
                            <CheckRounded className={classes.roundedCheck} />
                          </div>

                          <EHRTypography
                            dataCy="sign-document-sign-on"
                            variant="readonly"
                            style={{ color: COMPLETED_STEP_COLOR }}
                          >
                            Signed on {shippingAddress?.pdfCreatedAt}
                          </EHRTypography>
                        </Box>
                      }
                      third={
                        <EHRTypography variant="body2" dataCy="sign-document-pdf">
                          {linkWrapper('View', shippingAddress.shippingAddressAmendmentPdf, pdfLinkStyle)}
                        </EHRTypography>
                      }
                    />
                  </>
                )}
              </Box>
            ))}
          </Box>
          <ShippingAddressModal
            isOpen={isShippingAddressModalOpen}
            onClose={() => setIsShippingAddressModalOpen(false)}
            editMode={editMode}
            commercialShippingAddress={currentShippingAddress}
          />
          <ConfirmationModal
            open={openWarningModal}
            handleClose={() => setOpenWarningModal((prevState) => !prevState)}
            commercialShippingAddress={currentShippingAddress}
          />
        </>
      )}
    </>
  );
};

export default MedspaDetails;
