import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import classnames from 'classnames';
import { useStyles } from './confirmAdvocate.styles';
import IReferral from '../../../interfaces/IReferral';

interface Props {
  open: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  selected?: IReferral;
}

const ConfirmReferred = ({ open, closeModal, onConfirm, isLoading, selected }: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={closeModal} classes={{ paper: classes.dialog }}>
      <Box padding="60px">
        <Box className={classes.body}>
          You are about to attach this patient to{' '}
          <b className={classes.patientName}>
            {selected?.firstName} {selected?.lastName}
          </b>{' '}
          as a referral. You will not be able to attach this patient to another advocate in the future.
        </Box>
        <Box marginTop="13px" marginBottom="22px" className={classes.callToAction}>
          Are you sure?
        </Box>
        <Box textAlign="center">
          <Button className={classnames(classes.baseButton, classes.cancelButton)} title="No" onClick={closeModal}>
            No, I don&apos;t.
          </Button>
          <Button className={classnames(classes.baseButton, classes.yesButton)} onClick={onConfirm}>
            {isLoading ? 'Loading...' : "Yes, let's do it!"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmReferred;
