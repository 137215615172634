import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(0, 0, 0, 0.87)'
  }
}));

type Props = {
  title: string;
  style?: object;
  className?: string;
}

export const SmallTitle = ({ title, style, className }: Props) => {
  const classes = useStyles();
  return <h5 className={`${classes.title} ${className}`} style={style}>{title}</h5>
};
