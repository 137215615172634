import React from 'react';

interface Props {
  children: React.ReactNode;
  isVariant?: boolean;
  disabled?: boolean;
  onClick: () => void;
  style?: any;
}

const Oval = ({ children, ...props }: Props) => (
  <button type="button" className={`oval ${props.isVariant && 'is-variant'} `} {...props}>
    {children}
  </button>
);

export default Oval;
