import { ProductUtilizationFilters, IProductUtilization } from 'src/interfaces/IInventoryOrder';
import API_ROUTES from 'src/constants/apiRoutes.constants';
import Api from './Api';
import compile from '../utils/toastMessagesCompiler';
import { SNACKBAR_ERROR } from '../constants/general.constants';
import { showSnackbar } from '../utils/global';

const ProductUtilization = {
  getProductUtilization: async (filters: ProductUtilizationFilters): Promise<IProductUtilization | null> => {
    try {
      if (typeof filters !== 'object') {
        throw new Error('Filters must be an object');
      }

      const params = Object.fromEntries(
        Object.entries(filters).filter(
          (param: [string, string | number | boolean]): boolean => !!param[1] || param[1] === 0
        )
      );

      const { data } = await Api.get(API_ROUTES.PRODUCT_UTILIZATION, params);

      return data;
    } catch (error: unknown) {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'product utilization',
        }),
        `${SNACKBAR_ERROR}: ${error}`
      );
      return null;
    }
  },
};

export { ProductUtilization, ProductUtilization as default };
