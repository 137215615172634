import API_ROUTES from 'src/constants/apiRoutes.constants';
import { IOrderUpdate, InventoryOrderItem } from '../interfaces/IInventoryOrder';
import Api from './Api';

const InventoryOrderItems = {
  async update(params: Partial<InventoryOrderItem>): Promise<void> {
    await Api.post(API_ROUTES.INVENTORY_ORDER_ITEM_UPSERT, params);
  },

  async destroy(id: number): Promise<void> {
    await Api.delete(API_ROUTES.INVENTORY_ORDER_ITEM_DESTROY(id));
  },

  async confirmDelivery(params: IOrderUpdate): Promise<void> {
    await Api.post(API_ROUTES.INVENTORY_ORDER_CONFIRM_DELIVERY, params);
  },
};

export { InventoryOrderItems, InventoryOrderItems as default };
