// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    position: 'initial',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  subtitle: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '10px 0',
  },
  medicalHistoryAckCard: {
    position: 'absolute',
    zIndex: 11,
    width: '40%',
    marginTop: '-4%',
  },
  bold: {
    fontWeight: 'bold',
  },
  textRed: {
    color: theme.palette.error.dark,
  },
  textGreen: {
    color: theme.palette.success.dark,
  },
  manageCardLinkButton: {
    marginLeft: '24px',
    width: '240px',
    height: '47px',
    borderRadius: '8px',
    border: '1px solid #12574d',
    background: '#FFF',
    textTransform: 'none',
    color: '#12574d',
    fontSize: '14px',
    margin: '15px 0',
  },
  creditCardLinkButton: {
    width: '193px',
    height: '47px',
    borderRadius: '8px',
    textTransform: 'none',
    border: '1px solid #12574d',
    color: '#12574d',
    fontSize: '14px',
    margin: '15px 0',
  },
  chargeCardLinkButton: {
    width: '240px',
    height: '47px',
    borderRadius: '8px',
    background: '#12574d',
    textTransform: 'none',
    color: 'white',
    fontSize: '14px',
    margin: '15px 0',
    '&:hover': {
      background: '#12574D',
    },
  },
  seperator: {
    margin: '20px -15px',
    height: '1px',
    border: 'none',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  circularProgress: {
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    margin: '0 5px 0 5px',
    color: 'white',
    backgroundColor: '#12574d',
    padding: '10px',
    borderRadius: '3px',
  },
}));
