import React, { useEffect, useState } from 'react';

// Components
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { patientNotificationDefaultValues, patientNotificationSchema } from './schemas';
import { useAppointmentReminderConfiguration } from '../../../hooks/queries/useAppointmentReminderConfiguration';
// eslint-disable-next-line max-len
import { useUpsertAppointmentReminderConfiguration } from '../../../hooks/mutations/useUpsertAppointmentReminderConfiguration';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';

export const SampleTextMessage = ({ styles }: { styles?: Record<string, string> }) => {
  const classes = useStyles();
  return (
    <Box paddingTop="20px">
      <Box fontSize="12px" color="#706F6F">
        Sms Body
      </Box>
      <Box className={classes.form} style={styles}>
        You have an upcoming appointment with
        <span style={{ color: 'black' }}> [Dynamic field Providers name] </span>
        on <span style={{ color: 'black' }}> [Dynamic field appointment date and time] </span> at
        <span style={{ color: 'black' }}> [Dynamic field suite address] </span>
      </Box>
    </Box>
  );
};

export const SampleEmailMessage = ({ styles }: { styles?: Record<string, string> }) => {
  const classes = useStyles();
  return (
    <Box paddingTop="20px">
      <Box fontSize="12px" color="#706F6F">
        Email Body
      </Box>
      <Box className={classes.form} style={styles}>
        <p>
          Hello <span style={{ color: 'black' }}>[Dynamic field Patient first name]</span>,
        </p>
        <p>
          You have an upcoming appointment with <span style={{ color: 'black' }}> [Dynamic field Providers name]</span>.
        </p>
        <p>
          Date: <span style={{ color: 'black' }}>[Dynamic field appointment date]</span>
        </p>
        <p>
          Appointment Type: <span style={{ color: 'black' }}>[Dynamic field appointment type]</span>
        </p>
        <p>
          When: <span style={{ color: 'black' }}>[Dynamic field appointment time]</span>
        </p>
        <p>
          Where: <span style={{ color: 'black' }}>[Dynamic field appointment suite address]</span>
        </p>
        <p>
          If you need to reschedule or cancel your appointment, please log in to your account by clicking on the button
          below.
        </p>
      </Box>
    </Box>
  );
};

const PatientNotification = () => {
  const classes = useStyles();
  const useQuery = () => new URLSearchParams(window.location.search);
  const query = useQuery();
  const practitionerId = query.get('practitionerId') || undefined;
  const { userId } = useSelector(({ auth }: any) => auth);
  const currentPractitionerId = practitionerId ? +practitionerId : userId;
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(patientNotificationSchema),
    defaultValues: patientNotificationDefaultValues,
  });
  const { mutateAsync } = useUpsertAppointmentReminderConfiguration();
  const { data: configurations = [], isLoading: loading } = useAppointmentReminderConfiguration(
    +currentPractitionerId,
    !!currentPractitionerId
  );
  const smsConfig = configurations.find(
    (configuration: any) => configuration.type === 'SMSAppointmentReminderConfiguration'
  );
  const emailConfig = configurations.find(
    (configuration: any) => configuration.type === 'EmailAppointmentReminderConfiguration'
  );

  useEffect(() => {
    if (smsConfig) {
      setSMSReminderBeforeType(smsConfig.valueTypeBeforeReminder);
      setSMSReminderBeforeValue(smsConfig.valueBeforeReminder);
      setValue('smsRemainders', smsConfig.enabled);
    }
    if (emailConfig) {
      setEmailReminderBeforeType(emailConfig.valueTypeBeforeReminder);
      setEmailReminderBeforeValue(emailConfig.valueBeforeReminder);
      setValue('emailRemainders', emailConfig.enabled);
    }
  }, [smsConfig, emailConfig]);

  const watchSmsRemainders = watch('smsRemainders');
  const watchEmailRemainders = watch('emailRemainders');

  const [smsReminderBeforeValue, setSMSReminderBeforeValue] = useState<number | undefined>(30);
  const [smsReminderBeforeType, setSMSReminderBeforeType] = useState<string>('minutes');

  const [emailReminderBeforeValue, setEmailReminderBeforeValue] = useState<number | undefined>(30);
  const [emailReminderBeforeType, setEmailReminderBeforeType] = useState<string>('minutes');

  const onSubmit = async () => {
    try {
      const smsData = {
        valueBeforeReminder: smsReminderBeforeValue || 30,
        valueTypeBeforeReminder: smsReminderBeforeType,
        enabled: watchSmsRemainders,
        configType: 'sms',
        practitionerId: currentPractitionerId,
      };
      const emailData = {
        valueBeforeReminder: emailReminderBeforeValue || 30,
        valueTypeBeforeReminder: emailReminderBeforeType,
        enabled: watchEmailRemainders,
        configType: 'email',
        practitionerId: currentPractitionerId,
      };
      await mutateAsync(smsData);
      await mutateAsync(emailData);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Patient notification settings',
            action: 'saved',
          }),
          type: 'success',
        },
      });
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'saving',
            element: 'patient notification settings',
          }),
          type: 'error',
        },
      });
    }
  };

  return (
    <Grid item xs>
      <Grid item>
        <h2>Patient Notifications</h2>
      </Grid>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between">
          <Grid item xs className={classes.form}>
            <Grid item className={classes.formItem}>
              <Box display="flex" className={classes.formItemTitle}>
                <Box width="185px" margin="auto">
                  SMS Reminders
                </Box>
                <FormControlLabel
                  style={{ justifyContent: 'flex-end', width: '100%' }}
                  label=""
                  control={
                    <Switch
                      color="primary"
                      checked={watchSmsRemainders}
                      onChange={() => setValue('smsRemainders', !watchSmsRemainders)}
                    />
                  }
                />
              </Box>
              <Box className={classes.formMinutesBeforeContainer}>
                <TextField
                  data-cy="smsRemainderNumberBefore"
                  name="smsRemainderNumberBefore"
                  error={!!errors.name}
                  helperText={errors.name?.message || ''}
                  disabled={loading}
                  value={smsReminderBeforeValue}
                  onChange={(e) => setSMSReminderBeforeValue(e.target.value ? +e.target.value : undefined)}
                  inputRef={register}
                  variant="outlined"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: '0',
                  }}
                />
                <Select
                  variant="outlined"
                  data-cy="smsMinutesHoursOrDays"
                  value={smsReminderBeforeType}
                  name="smsMinutesHoursOrDays"
                  className={classes.formMinuteBeforeSetting}
                  onChange={(e) => setSMSReminderBeforeType(e.target.value as string)}
                >
                  <MenuItem value="minutes">Minutes</MenuItem>
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                </Select>
              </Box>
              <SampleTextMessage />
            </Grid>
          </Grid>
          <Grid item xs className={classes.form}>
            <Grid item className={classes.formItem}>
              <Box display="flex" className={classes.formItemTitle}>
                <Box width="185px" margin="auto">
                  Email Reminders
                </Box>
                <FormControlLabel
                  style={{ justifyContent: 'flex-end', width: '100%' }}
                  label=""
                  control={
                    <Switch
                      color="primary"
                      checked={watchEmailRemainders}
                      onChange={() => setValue('emailRemainders', !watchEmailRemainders)}
                    />
                  }
                />
              </Box>
              <Box className={classes.formMinutesBeforeContainer}>
                <TextField
                  data-cy="emailRemainderInput"
                  name="emailRemainderDuration"
                  error={!!errors.name}
                  helperText={errors.name?.message || ''}
                  disabled={loading}
                  inputRef={register}
                  value={emailReminderBeforeValue}
                  onChange={(e) => setEmailReminderBeforeValue(e.target.value ? +e.target.value : undefined)}
                  variant="outlined"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: '0',
                  }}
                />
                <Select
                  variant="outlined"
                  data-cy="smsMinutesHoursOrDays"
                  value={emailReminderBeforeType}
                  name="smsMinutesHoursOrDays"
                  onChange={(e) => setEmailReminderBeforeType(e.target.value as string)}
                  className={classes.formMinuteBeforeSetting}
                >
                  <MenuItem value="minutes">Minutes</MenuItem>
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                </Select>
              </Box>
              <SampleEmailMessage />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.formItem}>
          <Button data-cy="submitBtn" disabled={loading} variant="contained" color="primary" type="submit">
            Save Changes
            {loading && <CircularProgress size={25} />}
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};

export default PatientNotification;
