import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { CardContent } from '@material-ui/core';
import { Create as CreateIcon } from '@material-ui/icons';
import { formatAddress } from '../../../utils/patient.util';
import { useStyles } from './contactInfo.styles';
import { Title, Details } from '../../common/card';
import { IconButton } from '../../common/IconButton';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { IPatientContactInfo } from '../../../types/Patient';
import { useCustomContactInfo } from '../../../hooks/queries/useCustomContactInfo';
import { hasAccessTo } from '../../../utils/auth.utils';
import { GFE_REQUEST, UPDATE_PATIENT_CONTACT_INFO } from '../../../constants/actions.constants';

const fullName = ({ firstName = '', lastName = '' }) => `${firstName} ${lastName}`;

const formatContactInfo = (data: IPatientContactInfo): object => ({
  physician: fullName(data.physician || {}),
  practitioner: data.practitioner?.professionalName,
  email: data.email || 'None',
  phone: data.phone || 'None',
  address: formatAddress(data),
  shippingAddress: formatAddress(data.shippingAddress),
  dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth).utc().format('MM/DD/YYYY') : 'None',
});

type Props = {
  patientId: string;
};

const ContactInfo: React.FC<Props> = ({ patientId }: Props) => {
  const [contactInfo, setContactInfo] = useState<any>({});
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const { data, isLoading } = useCustomContactInfo(patientId.toString());
  useEffect(() => {
    if (data) {
      const formatedContatInfo = formatContactInfo(data);
      setContactInfo(formatedContatInfo);
    }
  }, [data]);

  const classes = useStyles();
  const history = useHistory();

  const { serviceRequests } = useSelector(({ patient }: any) => patient);

  const lastReviewed =
    serviceRequests &&
    serviceRequests.length > 0 &&
    serviceRequests
      .filter(({ updatedAt }: any) => Boolean(updatedAt))
      .sort((a: any, b: any) => (moment(a.updatedAt) > moment(b.updatedAt) ? -1 : 1))
      .find((sr: any) => sr.checkedByPhysician === true);

  const details = [
    { label: 'Practitioner', value: contactInfo.practitioner },
    { label: 'Email', value: contactInfo.email },
    { label: 'Phone', value: contactInfo.phone },
    { label: 'Address', value: contactInfo.address, double: true },
    { label: 'Birth Date', value: contactInfo.dateOfBirth },
  ];

  if (hasAccessTo(GFE_REQUEST, permissions)) {
    details.unshift({ label: 'Physician', value: contactInfo.physician });
  }

  if (lastReviewed && lastReviewed.checkedByAdvancedPractitioner) {
    details.splice(1, 0, { label: 'Advanced Practitioner', value: lastReviewed.physicianProfessionalName });
  }

  const navigateEditContactInfo = () => {
    history.push(`/patient/${patientId}/edit`);
  };

  if (isLoading) {
    return (
      <CardContent style={{ padding: '12px 15px' }}>
        <ShortMultipleSkeleton length={6} />
      </CardContent>
    );
  }

  return (
    <CardContent className={classes.cardContent}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title title="Contact Info" style={{ marginTop: '0', marginBottom: '0' }} />
        {hasAccessTo(UPDATE_PATIENT_CONTACT_INFO, permissions) && (
          <IconButton
            onClick={navigateEditContactInfo}
            title="Edit"
            style={{ color: '#000000', backgroundColor: '#e7eeed' }}
            Icon={<CreateIcon />}
          />
        )}
      </div>
      <div>
        <Details details={details} />
      </div>
    </CardContent>
  );
};

function areEqual(prevProps: { patientId: string }, nextProps: { patientId: string }) {
  return prevProps.patientId === nextProps.patientId;
}

export default React.memo(ContactInfo, areEqual);
