import React from 'react';
import { useParams } from 'react-router-dom';
import { CardContent, Grid } from '@material-ui/core';

import { Card, SmallTitle } from '../common/card';
import { getServiceLabel } from '../../utils/newServiceVisit.util';

import { useStyles } from '../NewServiceVisit/servicesUnits.styles';
import IServices from '../../interfaces/IServices';
import IVariant from '../../interfaces/IVariants';
import { ISaveSelectedServicesParams } from '../../interfaces/params/IServiceVisitParams';
import { ILineItem } from '../../interfaces/IServiceVisits';
import { useSaveSelectedServiceMutation } from '../../hooks/serviceVisit/useLineItems';
import { ServiceUnitsInput } from './ServiceUnitInput';

interface Props {
  services: IServices[];
  lineItems: ILineItem[];
  disabled: boolean;
}
export const ServiceUnitStep = (props: Props) => {
  const classes = useStyles();
  const { serviceVisitId }: any = useParams();

  const { services, lineItems, disabled } = props;
  const saveSelectedServicesMutation = useSaveSelectedServiceMutation();

  const onChangeUnit = async (data: ISaveSelectedServicesParams) =>
    saveSelectedServicesMutation.mutateAsync({
      ...data,
      serviceVisitId: +serviceVisitId,
    });

  const renderServicesUnits = (): React.ReactNode[] =>
    services.map(({ name, id, unitLabel, allowPartialSale, assetLabel, variants = [] }) => {
      const lineItem = lineItems.find(({ serviceId, variantItemId }) => serviceId === id && variantItemId === null);
      if (!lineItem) {
        return <></>;
      }

      return (
        <React.Fragment key={id}>
          <Grid key={id} item sm={6} md={6} data-cy="unitsForService">
            <div className={classes.serviceName}>{name}</div>
            <div className={classes.inputsSection}>
              <ServiceUnitsInput
                id={id}
                lineItem={lineItem}
                label={getServiceLabel(allowPartialSale, unitLabel, assetLabel)}
                disabled={disabled}
                isLoading={saveSelectedServicesMutation.isLoading}
                onChangeUnit={onChangeUnit}
              />
              <ServiceUnitsInput
                id={id}
                lineItem={lineItem}
                label="Future use"
                disabled={disabled}
                isLoading={saveSelectedServicesMutation.isLoading}
                inputName="futureUseQuantity"
                onChangeUnit={onChangeUnit}
              />
            </div>
          </Grid>
          {variants.length > 0 &&
            variants.map(({ items }: IVariant) =>
              items.map(({ name: variantName, minAmount, id: variantItemId }) => {
                const variantLineItem = lineItems.find(({ variantItemId: vItemId }) => vItemId === variantItemId);
                const currentUseQuantity = variantLineItem?.currentUseQuantity || 0;
                const futureUseQuantity = variantLineItem?.futureUseQuantity || 0;

                return (
                  <Grid item sm={6} md={6} data-cy="unitsForService" key={`${id}-${variantItemId}`}>
                    <div className={classes.serviceName}>
                      {variantName} (min {minAmount} - max {minAmount})
                    </div>
                    <div className={classes.inputsSection}>
                      <ServiceUnitsInput
                        id={id}
                        lineItem={{ ...variantLineItem, currentUseQuantity, futureUseQuantity }}
                        label={getServiceLabel(allowPartialSale, unitLabel, assetLabel)}
                        disabled={disabled}
                        isLoading={saveSelectedServicesMutation.isLoading}
                        variantItemId={variantItemId}
                        minAmount={minAmount}
                        onChangeUnit={onChangeUnit}
                      />
                      <ServiceUnitsInput
                        id={id}
                        lineItem={{ ...variantLineItem, currentUseQuantity, futureUseQuantity }}
                        label="Future use"
                        disabled
                        isLoading={saveSelectedServicesMutation.isLoading}
                        inputName="futureUseQuantity"
                        variantItemId={variantItemId}
                        onChangeUnit={onChangeUnit}
                      />
                    </div>
                  </Grid>
                );
              })
            )}
        </React.Fragment>
      );
    });

  return (
    <Card style={{ marginBottom: '0', minHeight: '148px' }}>
      <CardContent className={classes.cardContent}>
        <SmallTitle title="Units for the chosen services" className={classes.smallTitle} />
        <div className={classes.unitsSection}>
          <Grid container spacing={2}>
            {!!services.length && renderServicesUnits()}
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};
