import React from 'react';

type Props = {
  activeStep: number;
};

const PasswordStepper = ({ activeStep }: Props) => {
  const stepStyle = {
    flex: 1,
    height: '4px',
    marginRight: '10px',
    borderRadius: '12px',
  };

  const activeStepStyle = {
    backgroundColor: '#4CAF50',
  };

  const inactiveStepStyle = {
    backgroundColor: '#9c9c9c',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '0.5rem' }}>
      {Array.from({ length: 4 }, (_, index) => (
        <div
          key={`step-${index}`}
          style={{ ...stepStyle, ...(activeStep > index ? activeStepStyle : inactiveStepStyle) }}
        />
      ))}
    </div>
  );
};

export default PasswordStepper;
