import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, FormControl, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import { IPractitionerDashboardGraphs } from 'src/services/PractitionersDashboard';
import { IMedspaAdminList } from 'src/interfaces/IMedspaAdminList';
import { useStyles } from './practitionerDashboard.styles';
import ChartBar from '../../../common/Chart/ChartBar';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { READ_EARNING_DASHBOARD } from '../../../../constants/actions.constants';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';

type PropsType = {
  selectedDate: any;
  isFlexOrLite: boolean;
  formatToShow: string;
  onChangeDate: (newDate: Moment) => void;
  minDate: Moment | null;
  chartData: IPractitionerDashboardGraphs;
  isLoadingChartData: boolean;
  medspaPractitioners?: IMedspaAdminList | null;
  handlePractitionerSelect?: (practitionerId: string) => void;
  selectedPractitioner?: string;
};

const ChartsDashboard = ({
  chartData,
  isLoadingChartData,
  selectedDate,
  isFlexOrLite,
  onChangeDate,
  formatToShow,
  minDate,
  medspaPractitioners = [],
  selectedPractitioner = '',
  handlePractitionerSelect = () => {},
}: PropsType) => {
  const classes = useStyles();
  const { permissions } = useSelector(({ auth }: any) => auth);
  const isMedspaActive = process.env.REACT_APP_MEDSPA_FIELDS === 'true';
  const [disableSelection, setDisableSelection] = useState(true);

  useEffect(() => {
    if (medspaPractitioners && medspaPractitioners.length) {
      const isSingleProvider = medspaPractitioners.length === 1;
      handlePractitionerSelect(isSingleProvider ? medspaPractitioners[0].id.toString() : 'all');
      setDisableSelection(isSingleProvider);
    }
  }, [medspaPractitioners]);

  if (!permissions?.length) {
    return <MultipleSkeleton />;
  }

  return (
    <div className={isMedspaActive || isFlexOrLite ? classes.flexArea : classes.area}>
      <Typography variant="h6" className={classes.title}>
        Monthly Charts
      </Typography>

      {(isMedspaActive || isFlexOrLite) && (
        <Box display="flex" justifyContent="space-between">
          <Box style={{ marginBottom: '1rem' }}>
            <FormControl>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  views={['year', 'month']}
                  openTo="month"
                  autoOk
                  label="Month"
                  variant="inline"
                  inputVariant="outlined"
                  format={formatToShow}
                  {...(minDate && { minDate })}
                  maxDate={moment()}
                  value={selectedDate}
                  onChange={() => {}}
                  onAccept={(date: any) => onChangeDate(date)}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Box>
          {!disableSelection && (
            <Box display="flex" justifyContent="right" width="100%">
              <Select
                id="practitioners"
                data-cy="practitioners"
                labelId="practitioners"
                name="practitioners"
                variant="outlined"
                onChange={(event: any) => handlePractitionerSelect(event.target.value)}
                style={{ height: '50px', width: '200px' }}
                value={selectedPractitioner}
              >
                <MenuItem data-cy="selectedPractitioners" value="all" key="practitioners-all">
                  All
                </MenuItem>
                {(medspaPractitioners || []).map((value) => (
                  <MenuItem data-cy="selectedPractitioners" value={value.id} key={`practitioners-${value.id}`}>
                    {value.firstName} {value.lastName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ChartBar
            chartColor="#00a94a"
            xAxis={chartData?.revenue.xAxisLabels}
            data={chartData?.revenue.series}
            isLoading={isLoadingChartData}
            prefix="$"
            titleChart="Revenue"
            xLegend="Months"
            yLegend="Amount ($)"
          />
        </Grid>
        {hasAccessTo(READ_EARNING_DASHBOARD, permissions) && (
          <Grid item xs={6}>
            <ChartBar
              chartColor="#00a94a"
              isLoading={isLoadingChartData}
              xAxis={chartData?.earnings.xAxisLabels}
              data={chartData?.earnings.series}
              prefix="$"
              titleChart="Earnings Before Adjustments"
              xLegend="Months"
              yLegend="Amount ($)"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <ChartBar
            chartColor="#12574d"
            xAxis={chartData?.appointments.xAxisLabels}
            data={chartData?.appointments.series}
            isLoading={isLoadingChartData}
            titleChart="Appointments"
            xLegend="Months"
            yLegend="Qty"
          />
        </Grid>
        <Grid item xs={6}>
          <ChartBar
            isLoading={isLoadingChartData}
            chartColor="#12574d"
            xAxis={chartData?.appointmentsAverage.xAxisLabels}
            data={chartData?.appointmentsAverage.series}
            prefix="$"
            titleChart="Average Appointment Value"
            xLegend="Months"
            yLegend="Amount ($)"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ChartsDashboard;
