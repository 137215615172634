const DEFAULT_LOCATE = 'en-US';

const DEFAULT_CURRENCY_FORMATS = {
  locate: DEFAULT_LOCATE,
  style: 'currency',
  currency: 'USD',
};

export default function formatNumber(value: number, format?: Intl.NumberFormatOptions) {
  const { locate, ...rest } = { locate: DEFAULT_LOCATE, ...format };
  return new Intl.NumberFormat(locate, rest).format(value);
}

export function formatCurrency(value: number, format?: Intl.NumberFormatOptions) {
  const { locate, ...rest } = { ...DEFAULT_CURRENCY_FORMATS, ...format };
  return new Intl.NumberFormat(locate, rest).format(value);
}

export const formatPhone = (val: string): string => val.replace(/[^a-zA-Z0-9]/g, '');
