import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Grid, Tab, Tabs } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { dispatch } from '../../rematch';
import { useStyles } from '../NewServiceVisit/services.styles';
import IServices from '../../interfaces/IServices';
import compile from '../../utils/toastMessagesCompiler';
import IServiceAcknowledgment from '../../interfaces/IServiceAcknowledgment';
import { useServiceAcknowledgments } from '../../hooks/queries/useServiceAcknowledgments';
import { useAcceptedServiceAckActivityLog } from '../../hooks/mutations/useAcceptedServiceAcknowledgmentActivityLog';
import IServiceGroup from '../../interfaces/IServiceGroup';
import { ServiceList } from '../NewServiceVisit/ServiceList';
import ServiceGroupsList from '../NewServiceVisit/ServiceGroupList';
import DialogModal from '../common/DialogModal';
import { ShortMultipleSkeleton } from '../common/LoadingSkeleton';
import {
  useRemoveSelectedServiceMutation,
  useSaveSelectedServiceMutation,
} from '../../hooks/serviceVisit/useLineItems';

const renderIsLoading = () => (
  <Grid container spacing={2} style={{ padding: '12px 15px' }}>
    <Grid item xs={12} sm={4}>
      <ShortMultipleSkeleton length={5} />
    </Grid>
    <Grid item xs={12} sm={8}>
      <ShortMultipleSkeleton length={5} />
    </Grid>
  </Grid>
);

interface Props {
  services: IServices[];
  selectedServices: number[];
  serviceGroups: IServiceGroup[];
  disabled: (groupId: number) => boolean;
  isLoading: boolean;
}

export const ServiceStep = (props: Props) => {
  const { services, selectedServices, serviceGroups, disabled, isLoading } = props;
  const { serviceVisitId, patientId }: { patientId: string; serviceVisitId: string } = useParams();
  const classes = useStyles();

  const saveSelectedServicesMutation = useSaveSelectedServiceMutation();
  const { isLoading: isSavingSelectedService } = saveSelectedServicesMutation;
  const removeSeletedServiceMutation = useRemoveSelectedServiceMutation();
  const { isLoading: isRemovingSelectedService } = removeSeletedServiceMutation;

  const isSavingOrRemovingAService = () => isSavingSelectedService || isRemovingSelectedService;

  const { skusSelected, practitionerCertificatesSelected, patientCertificatesSelected } = useSelector(
    (state: any) => state.patientPointCertificates
  );

  const { data: serviceAcknowledgments } = useServiceAcknowledgments();
  const [openedDialog, setOpenedDialog] = useState(false);
  const [preSelectedServiceId, setPreSelectedServiceId] = useState<number | null>(null);
  const [selectedServiceAcknowledgment, setSelectedServiceAcknowledgment] = useState<IServiceAcknowledgment | null>(
    null
  );
  const useAcceptedServiceAcknowledgmentActivityLogMutation = useAcceptedServiceAckActivityLog();
  const [selectedServiceGroup, setSelectedServiceGroup] = useState(1);

  const saveServiceSelection = (serviceId: number) => {
    const resetGaldermaForCheckout =
      Object.keys(skusSelected).length > 0 ||
      practitionerCertificatesSelected.length > 0 ||
      patientCertificatesSelected > 0;

    // Reset galderma integration changes applied for checkout
    if (resetGaldermaForCheckout) {
      dispatch.patientPointCertificates.setSkusSelected({});
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('galderma.reset_services'),
          type: 'info',
        },
      });
    }

    const queryParams = {
      serviceVisitId: +serviceVisitId,
      serviceId,
      variantItemId: null,
      quantity: 1,
      current_use_quantity: 1,
    };

    // Check/Uncheck services
    if (selectedServices.includes(serviceId) && !isRemovingSelectedService) {
      removeSeletedServiceMutation.mutate(queryParams);
    } else if (!isSavingSelectedService) {
      saveSelectedServicesMutation.mutate(queryParams);
    }
  };

  const handleServiceAcknowledgmentAccepted = async () => {
    setOpenedDialog(false);
    if (preSelectedServiceId) {
      try {
        const success = await useAcceptedServiceAcknowledgmentActivityLogMutation.mutateAsync({
          patientId,
          serviceId: preSelectedServiceId,
          serviceVisitId: +serviceVisitId,
        });
        if (success) {
          saveServiceSelection(preSelectedServiceId);
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('services.service_acknowledgment_accepted_error'),
            type: 'error',
          },
        });
      }
    }
  };

  const handleOnCloseDialog = () => {
    setOpenedDialog(false);
    setPreSelectedServiceId(null);
    setSelectedServiceAcknowledgment(null);
  };

  const isServiceSelected = (selectedServiceId: number): boolean => selectedServices.includes(selectedServiceId);

  const handleOnChangeServiceSelection = (serviceId: number): void => {
    const selectedService = services.find((service: IServices) => service.id === serviceId);
    if (selectedService && selectedServiceGroup) {
      const serviceAcknowledgment = serviceAcknowledgments?.find(
        (acknowledgment: IServiceAcknowledgment) => acknowledgment.serviceGroupId === selectedServiceGroup
      );
      if (!isServiceSelected(serviceId) && serviceAcknowledgment) {
        setSelectedServiceAcknowledgment(serviceAcknowledgment);
        setPreSelectedServiceId(serviceId);
        setOpenedDialog(true);
        return;
      }
    }
    saveServiceSelection(serviceId);
  };

  const handleServiceGroupSelect = (serviceGroupId: any) => {
    setSelectedServiceGroup(serviceGroupId);
  };

  const servicesToShowInEmr = services.filter(
    ({ serviceGroupId, showInEmr }) => serviceGroupId === selectedServiceGroup && showInEmr
  );

  return (
    <>
      <Card style={{ marginBottom: '0', minHeight: '200px' }}>
        <Tabs value={0} classes={{ root: classes.tabs, indicator: classes.selectedTab }}>
          <Tab label="Services" className={classes.tab} />
        </Tabs>
        {isLoading ? (
          renderIsLoading()
        ) : (
          <div className={classes.root}>
            <Grid container spacing={2}>
              {services.length === 0 || serviceGroups.length === 0 ? (
                <Grid item xs={12} sm={4}>
                  There are no services for this customer
                  <p style={{ textAlign: 'center' }}>
                    <a href="/practitioner/inventory/count">Please Add Service Groups</a>
                  </p>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={4}>
                    <ServiceGroupsList
                      selectedServices={selectedServices}
                      serviceGroups={serviceGroups}
                      services={services}
                      onServiceGroupSelect={handleServiceGroupSelect}
                      selectedServiceGroup={selectedServiceGroup}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <ServiceList
                      services={servicesToShowInEmr}
                      onChangeServiceSelection={handleOnChangeServiceSelection}
                      selectedServices={selectedServices}
                      checkDisableFunc={(serviceGroupId: number) =>
                        disabled(serviceGroupId) || isSavingOrRemovingAService()
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}
        {selectedServiceAcknowledgment ? (
          <DialogModal
            open={openedDialog}
            confirm={handleServiceAcknowledgmentAccepted}
            closeModal={handleOnCloseDialog}
          >
            {selectedServiceAcknowledgment.message}
          </DialogModal>
        ) : null}
      </Card>
    </>
  );
};
