import { Grid, Box, makeStyles, CircularProgress } from '@material-ui/core';
import React from 'react';
import { EHRTypography } from 'src/components/ui/v1';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { STEP_COMPLETED, STEP_PENDING } from 'src/components/StepsTracker';
import {
  COMPLETED_TAG_BACKGROUND_COLOR,
  COMPLETED_TAG_FONT_COLOR,
  PENDING_TAG_BACKGROUND_COLOR,
  PENDING_TAG_FONT_COLOR,
  REQUIRED_TAG_BACKGROUND_COLOR,
  REQUIRED_TAG_FONT_COLOR,
  OPTIONAL_TAG_BACKGROUND_COLOR,
  OPTIONAL_TAG_FONT_COLOR,
} from '../../../../pages/Onboarding/AccountSettings/AccountSettings.styles';

const useStyles = makeStyles({
  container: {
    margin: '0',
  },
  tagContainer: { display: 'flex', justifyContent: 'right', alignItems: 'center' },
  tag: { padding: '2px 15px', borderRadius: '4px' },
});

const tagStylesMap = {
  completed: { backgroundColor: COMPLETED_TAG_BACKGROUND_COLOR, color: COMPLETED_TAG_FONT_COLOR, text: 'Done' },
  pending: { backgroundColor: PENDING_TAG_BACKGROUND_COLOR, color: PENDING_TAG_FONT_COLOR, text: 'Pending' },
  required: { backgroundColor: REQUIRED_TAG_BACKGROUND_COLOR, color: REQUIRED_TAG_FONT_COLOR, text: 'Required' },
  optional: { backgroundColor: OPTIONAL_TAG_BACKGROUND_COLOR, color: OPTIONAL_TAG_FONT_COLOR, text: 'Optional' },
};

const CardHeader = ({
  required,
  headline,
  description = '',
  status,
}: {
  required: boolean;
  headline: string;
  status: StepStatus;
  description?: string;
}) => {
  const classes = useStyles();

  const { backgroundColor, color, text } =
    status === STEP_COMPLETED || status === STEP_PENDING
      ? tagStylesMap[status]
      : tagStylesMap[required ? 'required' : 'optional'];

  const tagStyles = { backgroundColor, color };
  const tagText = text;

  return (
    <Grid container alignItems="flex-start" justify="space-between" className={classes.container}>
      <Grid item xs={8}>
        <EHRTypography variant="h6" dataCy="medspa-admin-onboarding-card-headline">
          {headline}
        </EHRTypography>
      </Grid>
      <Grid item xs={4} className={classes.tagContainer}>
        {status !== 'loading' ? (
          <Box style={tagStyles} className={classes.tag}>
            <EHRTypography variant="body2" dataCy="medspa-admin-onboarding-card-status">
              {tagText}
            </EHRTypography>
          </Box>
        ) : (
          <CircularProgress size={20} />
        )}
      </Grid>
      <Grid item xs={12} style={{ padding: '4px 0 24px 0' }}>
        <EHRTypography variant="body2" dataCy="medspa-admin-onboarding-card-description">
          {description}
        </EHRTypography>
      </Grid>
    </Grid>
  );
};

export default CardHeader;
