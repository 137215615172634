import React, { FC, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { dispatch } from '../../../rematch';
import NewPurchaseOrderForm from './NewPurchaseOrderForm';
import { Breadcrumbs } from './Breadcrumbs';
import { PurchaseOrderButtonsSection } from './PurchaseOrderButtonsSection';
import { ProductsAddedTable } from './ProductsAddedTable';

const NewPurchaseOrder: FC = () => {
  const { purchaseId }: any = useParams();
  const [canFinalize, setCanFinalize] = useState(false);
  const newPurchaseOrder = useSelector((store: any) => store.newPurchaseOrder);
  const { productsSelected, purchase, referenceNumber, internalNote, warehouseId, orderType } = newPurchaseOrder;
  const isComplete = purchase?.status === 'complete';

  useEffect(() => {
    dispatch.common.fetchServices();
    dispatch.newPurchaseOrder.getSuppliers();
    dispatch.newPurchaseOrder.getWarehouses();
    dispatch.newPurchaseOrder.getProductsSelected();

    return () => {
      dispatch.newPurchaseOrder.setResetState();
    };
  }, []);

  useEffect(() => {
    if (productsSelected && purchase?.status === 'waiting_to_receive') {
      let mayFinalize = true;

      productsSelected.forEach((product: any) => {
        const { quantity, expireAt, lot, units } = product || {};

        if (!quantity || !expireAt || !lot || !units) {
          mayFinalize = false;
        }
      });

      setCanFinalize(mayFinalize && !!referenceNumber && !!warehouseId);
    }
  }, [productsSelected, referenceNumber, warehouseId]);

  useEffect(() => {
    if (purchaseId) {
      dispatch.newPurchaseOrder.loadDraft({ id: purchaseId });
    }
  }, [purchaseId]);

  return (
    <Box width="100%" display="flex" height="100%" flexDirection="column">
      <>
        <Breadcrumbs purchaseId={purchaseId} />
        {!isComplete && <NewPurchaseOrderForm />}
        <form>
          <ProductsAddedTable isComplete={isComplete} purchaseId={purchaseId} productsSelected={productsSelected} />
          <PurchaseOrderButtonsSection
            purchaseId={purchaseId}
            canFinalize={canFinalize}
            referenceNumber={referenceNumber}
            internalNote={internalNote}
            orderType={orderType}
          />
        </form>
      </>
    </Box>
  );
};

export default NewPurchaseOrder;
