/* eslint-disable max-len */
import React from 'react';
import { Box, CardContent, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { ICustomerStandingOrder } from 'src/interfaces/IStandingOrder';
import { Card, SmallTitle } from '../common/card';
import { useStyles } from '../NewServiceVisit/standingOrders.styles';
import { useSignServiceVisitStandingOrder } from '../../hooks/serviceVisit/useStandingOrders';
import IServices from '../../interfaces/IServices';
import { SERVICE_VISIT_STANDING_ORDER_FIX_DATE } from '../../constants/serviceVisits/serviceVisit.constants';

interface Props {
  customerStandingOrders: ICustomerStandingOrder;
  signedStandingOrders: any;
  servicesWithStandingOrders: IServices[];
  disabled: boolean;
}

export const StandingOrderStep = (props: Props) => {
  const { customerStandingOrders, signedStandingOrders, servicesWithStandingOrders, disabled } = props;
  const classes = useStyles();
  const { serviceVisitId }: { serviceVisitId: string } = useParams();
  const signedServiceGroupIds = signedStandingOrders.map(
    ({ serviceGroupId }: { serviceGroupId: number }) => serviceGroupId
  );
  const signedStandingOrderIds = servicesWithStandingOrders
    .filter((service) => signedServiceGroupIds.includes(service.serviceGroupId))
    .map((service) => service.id);
  const signStanginOrderMutation = useSignServiceVisitStandingOrder(+serviceVisitId);

  const onOrderCheck = (serviceGroupId: number): void => {
    const so = customerStandingOrders.find((cso) => cso.serviceGroupId === serviceGroupId);

    signStanginOrderMutation.mutate({
      customerStandingOrderId: so.id,
    });
  };

  const getStandingOrderLinkOld = (groupName: string): string | undefined => {
    let pdfName: string = groupName.replace(/ /g, '_');
    pdfName = `${pdfName.toLocaleLowerCase()}.pdf`;
    try {
      // eslint-disable-next-line import/no-dynamic-require, global-require, import/no-dynamic-require
      return require(`../../assets/standingOrders/${pdfName}`).default;
    } catch (er) {
      return undefined;
    }
  };
  const getStandingOrderLink = (groupId: number): string | undefined => {
    try {
      const signedStandingOrder = signedStandingOrders.find(({ serviceGroupId }) => serviceGroupId === groupId);
      if (signedStandingOrder?.pdfUrl) {
        // if is signed, show the signed document (CustomerStandingOrder)
        return signedStandingOrder.pdfUrl;
      }

      const so = customerStandingOrders.find(({ serviceGroupId }) => serviceGroupId === groupId);
      let link;
      // Due to an issue with AWS configs, pdfs created before Sept 2023 did not have correct access permissions so the
      // url does not work.
      if (Date.parse(so.createdAt) > SERVICE_VISIT_STANDING_ORDER_FIX_DATE) {
        link = so?.standingOrderPdfUrl;
      } else {
        link = getStandingOrderLinkOld(so.serviceGroupName);
        if (!link) {
          link = so?.standingOrderPdfUrl;
        }
      }
      return link;
    } catch (er) {
      return undefined;
    }
  };

  const renderStandingOrders = () => {
    const standingOrders = servicesWithStandingOrders.map((service) => (
      <div key={service.id} className={classes.standingOrder}>
        <FormControlLabel
          classes={{ root: classes.formControlLabel, label: `${classes.checkboxLabel} ${classes.label}` }}
          label={service.name}
          control={
            <Checkbox
              data-cy="standingOrderCheckbox"
              color="default"
              checked={signedStandingOrderIds.includes(service.id)}
              classes={{ checked: classes.checkbox }}
              onChange={() => {
                onOrderCheck(service.serviceGroupId);
              }}
              disabled={signedStandingOrderIds.includes(service.id) || disabled}
            />
          }
        />
        <a
          className={`${classes.documentLink} ${classes.label}`}
          href={getStandingOrderLink(service.serviceGroupId)}
          target="_blank"
          rel="noreferrer"
        >
          Document Link
        </a>
      </div>
    ));

    if (!standingOrders.length) {
      return (
        <Box component="p" mt={0}>
          No standing orders
        </Box>
      );
    }
    return standingOrders;
  };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <SmallTitle title="Standing orders reviewed and accepted" className={classes.smallTitle} />
        <FormGroup className={classes.formGroup}>{renderStandingOrders()}</FormGroup>
      </CardContent>
    </Card>
  );
};
