import React from 'react';

type Props = {
  opened: boolean;
};

export const ConfirmForceOpenCloseSVContent = ({ opened }: Props) => (
  <div>
    <p>
      Are you sure you want to force {opened ? 'close' : 'open'} this service visit?
      {opened && (
        <p style={{ color: 'red', fontWeight: 'bold' }}>
          Warning: if you force close the service visit and it’s not paid, the checkout status will automatically change
          to Paid status, which will record the amount in the earnings and commissions will be paid out for that amount.
        </p>
      )}
    </p>
  </div>
);
