import React from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useCustomerHistoricalPhotos } from '../../hooks/queries/useCustomerHistoricalPhotos';
import Id from '../../types/Id';
import Photos from './Photos';
import {
  MIN_ITEMS_PHOTOS_LIMIT_MOBILE,
  ITEMS_PHOTOS_LIMIT_DESKTOP,
  WIDTH_MAX_DESKTOP,
} from '../../constants/general.constants';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { ACTIVITY_LOGS, PATIENT_PHOTOS, CUSTOMER_PHOTOS } from '../../constants/reactQuery.keys';
import { dispatch } from '../../rematch';

type PropsType = {
  patientId: Id;
  showUnseenPhotosNotification?: boolean;
  onPhotoOpen?: (photoId: number) => void;
};

const HistoricalPhotoContainer = ({
  patientId,
  showUnseenPhotosNotification = false,
  onPhotoOpen = () => {},
}: PropsType) => {
  const queryClient = useQueryClient();
  const { isUploadingPhoto } = useSelector(({ patient }: any) => patient);
  const { width } = useWindowDimensions();
  const { data: historicalPhotos = [] } = useCustomerHistoricalPhotos(patientId);
  const ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE =
    width > WIDTH_MAX_DESKTOP ? ITEMS_PHOTOS_LIMIT_DESKTOP : MIN_ITEMS_PHOTOS_LIMIT_MOBILE;

  const deletePhoto = (photoId: number, deletePhotoReason: string) => {
    const deletePhotoCallback = () => {
      queryClient.invalidateQueries([PATIENT_PHOTOS, patientId]);
      queryClient.invalidateQueries([ACTIVITY_LOGS, patientId]);
    };
    dispatch({
      type: 'patient/deletePhotoRequest',
      payload: { patientId, photoId, deletePhotoReason, deletePhotoCallback },
    });
  };

  const uploadPhoto = (files: File[]) => {
    // technical debt, find what triggers uploadPhotos from Photos.tsx and remove if (work around)
    // steps to reproduce, upload photo on patient profile, navigate to new service visit, navigate back to
    // patient profile through navigation header
    if (patientId) {
      const callback = () => {
        queryClient.invalidateQueries([ACTIVITY_LOGS, patientId]);
        queryClient.invalidateQueries([PATIENT_PHOTOS, patientId]);
        queryClient.invalidateQueries([CUSTOMER_PHOTOS, patientId]);
      };
      dispatch({ type: 'patient/uploadPhotosRequest', payload: { files, id: patientId, callback } });
    }
  };

  return (
    <Photos
      cardStyles={{ marginBottom: '10px' }}
      showUnseenPhotosNotification={showUnseenPhotosNotification}
      inputFileId="patientPhoto"
      title="All photos"
      photos={historicalPhotos}
      isUploadingPhoto={isUploadingPhoto}
      isLoading={false}
      itemsPhotos={ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE}
      onPhotoOpen={(photoId) => onPhotoOpen(photoId)}
      deletePhotoCallback={deletePhoto}
      showDeleteButton
      uploadPhotosCallback={uploadPhoto}
      patientId={patientId}
      showUploadPhotoButton
      showPhotoRequestButton
    />
  );
};
export default HistoricalPhotoContainer;
