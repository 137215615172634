const DEFAULT_EXPIRED_TIMESPAN_MS = 60000;

export default function isCacheExpired(date: Date | null): boolean {
  if (!date) {
    return false;
  }
  const now = new Date();
  const timediff = now.getTime() - date.getTime();
  return timediff > DEFAULT_EXPIRED_TIMESPAN_MS;
}
