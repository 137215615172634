import React, { useState } from 'react';
import { Select, MenuItem, FormControl, Collapse, Grid } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { Edit as EditIcon, Replay as ReplayIcon } from '@material-ui/icons';
import { IMedicalProfile } from '../../../types/Patient';
import { useUpdateMedicalProfile } from '../../../hooks/mutations/useUpdateMedicalProfile';
import { dispatch } from '../../../rematch';
import { MEDICAL_PROFILE } from '../../../hooks/queries/useCustomMedicalProfile';
import compile from '../../../utils/toastMessagesCompiler';
import { useStyles } from './BiologicalSex.styles';
import { Button } from '../../common/Button';

interface IBiologicalSex {
  medicalProfile: IMedicalProfile;
}

enum BiologicalSexOptions {
  female = 'female',
  male = 'male',
}

const BiologicalSex = ({ medicalProfile }: IBiologicalSex) => {
  const [editing, setEditing] = useState(false);
  const [biologicalSex, setBiologicalSex] = useState<BiologicalSexOptions>(
    (medicalProfile.sex as unknown as BiologicalSexOptions) || BiologicalSexOptions.female
  );
  const queryClient = useQueryClient();
  const updateBiologicalSex = useUpdateMedicalProfile();
  const classes = useStyles();

  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBiologicalSex(event.target.value as BiologicalSexOptions);
  };

  const handleToggleEditMode = () => {
    setBiologicalSex((medicalProfile.sex as unknown as BiologicalSexOptions) || BiologicalSexOptions.female);
    setEditing(!editing);
  };

  const handleSave = async () => {
    try {
      const { medicalProfile: updatedMedicalProfile } = await updateBiologicalSex.mutateAsync({
        customerId: medicalProfile.customerId,
        medicalProfile,
        payload: {
          sex: biologicalSex,
          intendingPregnant: 'no',
          isBreastFeeding: 'no',
          isOnBirthControl: null,
          isPregnant: 'no',
        },
      });

      if (updatedMedicalProfile) {
        setEditing(false);
        queryClient.invalidateQueries([MEDICAL_PROFILE, medicalProfile.customerId.toString()]);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Medical Profile',
              action: 'updated',
            }),
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'updating',
            element: 'the medical profile',
          }),
          type: 'error',
        },
      });
    }
  };

  return (
    <Grid item xs={12}>
      {editing ? (
        <>
          <div className={classes.editSection}>
            <div className={classes.title}>
              <span>Biological sex</span>
            </div>
            <div>
              <ReplayIcon onClick={handleToggleEditMode} />
            </div>
          </div>
          <Collapse in={editing} appear={editing} timeout="auto">
            <div>
              <FormControl variant="outlined" fullWidth>
                <Select
                  className={classes.label}
                  id={`${medicalProfile.id}-biologicalSex`}
                  value={biologicalSex}
                  onChange={handleOnChange}
                >
                  <MenuItem className={classes.label} value={BiologicalSexOptions.female}>
                    {BiologicalSexOptions.female}
                  </MenuItem>
                  <MenuItem className={classes.label} value={BiologicalSexOptions.male}>
                    {BiologicalSexOptions.male}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <Button
                title="Save"
                style={{ minWidth: '200px', color: '#ffffff', backgroundColor: '#12574d', marginLeft: 0 }}
                onClick={handleSave}
                disabled={medicalProfile.sex === biologicalSex || updateBiologicalSex.isLoading}
              />
            </div>
          </Collapse>
        </>
      ) : (
        <>
          <div className={classes.editSection}>
            <div className={classes.title}>
              <span>Biological sex</span>
            </div>
            <div>
              <EditIcon onClick={handleToggleEditMode} />
            </div>
          </div>
          <div>
            <span className={classes.label}>{biologicalSex}</span>
          </div>
        </>
      )}
    </Grid>
  );
};

export default BiologicalSex;
