import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import { CLIENT_LEAD, CLIENT_LEAD_PROVIDERS, CLIENT_LEAD_REPORTS } from '../../constants/reactQuery.keys';
import {
  IClientLeadApi,
  IClientLeadStatus,
  IClientLeadLookup,
  IClientLeadProviders,
  ILeadReportParams,
  ILeadReportData,
} from '../../interfaces/IClientLead';
import ClientLeads from '../../services/ClientLeads';

const useClientLeads = ({
  practitionerId,
  status,
  searchTerms,
  page,
  sortBy,
  sortType,
  disabled,
}: IClientLeadLookup): UseQueryResult<IClientLeadApi | null> =>
  useQuery<IClientLeadApi | null>({
    queryKey: [CLIENT_LEAD, status, searchTerms, sortBy, sortType, page || -1, practitionerId],
    queryFn: (): Promise<IClientLeadApi | null> =>
      ClientLeads.getClientLeads({
        status,
        searchTerms,
        sortBy,
        sortType,
        page: page ?? -1,
        practitionerId: practitionerId === 0 ? undefined : practitionerId,
      }),
    enabled: !disabled,
  });

const useClientLeadProviders = (disallowShowAll?: boolean): UseQueryResult<IClientLeadProviders | null> =>
  useQuery<IClientLeadProviders | null>({
    queryKey: [CLIENT_LEAD_PROVIDERS, disallowShowAll],
    queryFn: (): Promise<IClientLeadProviders | null> => ClientLeads.getClientLeadProviders(disallowShowAll),
  });

const useClientLeadReports = ({
  practitionerId,
  adCampaignId,
  dateIncrement,
  disabled,
}: ILeadReportParams): UseQueryResult<ILeadReportData | null> =>
  useQuery<ILeadReportData | null>({
    queryKey: [CLIENT_LEAD_REPORTS, practitionerId, adCampaignId, dateIncrement],
    queryFn: (): Promise<ILeadReportData | null> =>
      ClientLeads.getClientLeadReports({
        practitionerId,
        adCampaignId,
        dateIncrement,
      }),
    enabled: !disabled,
  });

const useUpdateClientLeadStatus = (
  clientLeadId: number
): UseMutationResult<void, unknown, IClientLeadStatus, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (status: IClientLeadStatus): Promise<void> => {
      await ClientLeads.updateClientLead({ clientLeadId, status });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [CLIENT_LEAD] });
    },
  });
};

const useUpdateClientLeadNotes = (clientLeadId: number): UseMutationResult<void, unknown, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (notes: string): Promise<void> => {
      await ClientLeads.updateClientLead({ clientLeadId, notes });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [CLIENT_LEAD] });
    },
  });
};

export {
  useUpdateClientLeadStatus,
  useClientLeadReports,
  useClientLeadProviders,
  useUpdateClientLeadNotes,
  useClientLeads,
  useClientLeads as default,
};
