import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, CardContent } from '@material-ui/core';
import { Card, SmallTitle } from '../../common/card';
import { RootState, dispatch } from '../../../rematch';
import { useStyles } from './consents.styles';
import { ActiveStepWrapper } from '../ActiveStepWrapper';
import { ConsentModal } from './ConsentModal';
import { getServiceGroupsSelectedForConsents, areConsentsSigned } from '../../../utils/newServiceVisit.util';
import useLog from '../../../hooks/mutations/useLog';

interface Props {
  isFollowUpSelectedOnly?: boolean;
}

export const Consents = ({ isFollowUpSelectedOnly }: Props) => {
  const classes = useStyles();
  const {
    id,
    serviceGroups,
    customerConsents,
    firstName,
    lastName,
    contactInfo: {
      practitioner: { businessEntity, medicalGroup },
    },
  } = useSelector(({ patient }: RootState) => patient);
  const {
    selectedServiceGroups,
    selectedServices,
    services,
    totalServicesUnits,
    showConsentsAlert,
    serviceVisit: { id: serviceVisitId, opened },
  } = useSelector(({ newServiceVisit }: RootState) => newServiceVisit);

  const selectedServiceGroupsForConsents = getServiceGroupsSelectedForConsents(
    selectedServices,
    totalServicesUnits,
    services,
    serviceGroups
  );

  const [isOpened, setOpened] = useState(false);
  const [consentContent, setConsentContent] = useState('');
  const [consentServiceGroupId, setConsentServiceGroupId] = useState(0);
  const [consentName, setConsentName] = useState('');
  const [patientName, setPatientName] = useState('');
  const [isSigning, setIsSigning] = useState(false);

  const { mutateAsync: log } = useLog();

  useEffect(() => {
    if (services.length) {
      dispatch({ type: 'newServiceVisit/populateForSelectedServiceGroups' });
    }
  }, [services]);

  useEffect(() => {
    const isValidConsentStep = areConsentsSigned(
      customerConsents,
      serviceGroups,
      selectedServiceGroupsForConsents,
      serviceVisitId
    );

    dispatch({ type: 'newServiceVisit/showConsentsAlert', payload: !isValidConsentStep });
  }, [selectedServiceGroups, isSigning, totalServicesUnits]);

  const handleOpen = (serviceGroupId: number, name: string, consent: any) => {
    if (!opened) {
      return;
    }
    if (isSignedConsent(serviceGroupId)) {
      const consentObject = customerConsents.find(
        (consentObj: any) =>
          consentObj.serviceGroupId === serviceGroupId && consentObj.serviceVisitId === serviceVisitId
      );
      window.open(consentObject.consentPdfUrl, '_blank');
    } else {
      const consentInfo = consent.content
        .replace('<%= business_entity %>', businessEntity)
        .replace('<%= medical_group %>', medicalGroup ? ` and ${medicalGroup}` : '');
      setOpened(true);
      setPatientName(`${firstName} ${lastName}`);
      setConsentName(name);
      setConsentServiceGroupId(serviceGroupId);
      setConsentContent(consentInfo);
    }
  };

  const handleClose = () => {
    setOpened(false);
    setPatientName('');
  };

  const isSignedConsent = (serviceGroupId: number) =>
    customerConsents.some(
      (consent: any) => consent.serviceGroupId === serviceGroupId && consent.serviceVisitId === serviceVisitId
    );

  const signConsent = (signatureImage: any, patientNameConsent: string) => {
    setIsSigning(true);
    dispatch({
      type: 'patient/signConsentRequest',
      payload: {
        patientId: id,
        serviceGroupId: consentServiceGroupId,
        patientName: patientNameConsent,
        serviceVisitId,
        signatureImage,
        successCallback: () => {
          setOpened(false);
        },
        finallyCallback: () => {
          setIsSigning(false);
        },
      },
    });
  };

  const setCurrentStep = () => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 5 });
    log({
      feature: 'Service Visit',
      step: 5,
      logId: null,
      params: {
        patient: { id, serviceGroups, customerConsents, firstName, lastName },
        serviceVisit: {
          selectedServiceGroups,
          selectedServices,
          services,
          totalServicesUnits,
          showConsentsAlert,
          id: serviceVisitId,
        },
      },
    });
  };

  return (
    <ActiveStepWrapper step={5} onClick={setCurrentStep}>
      <Card style={{ marginBottom: '0' }}>
        <CardContent>
          <div className={classes.title}>
            <SmallTitle title="Patient medical consents" style={{ marginTop: '0', marginBottom: '18px' }} />
            {showConsentsAlert && !isFollowUpSelectedOnly && (
              <div className={classes.alertText}>There are consents pending to sign</div>
            )}
          </div>
          <Grid container spacing={1}>
            {!selectedServiceGroupsForConsents.length && <div>No consents pending to sign</div>}
            {serviceGroups.map(
              ({ id: idService, consent, name }: any) =>
                consent !== null &&
                selectedServiceGroupsForConsents.includes(idService) && (
                  <div key={idService}>
                    <Grid item md={3}>
                      <div
                        data-cy="consentBtn"
                        className={[
                          classes.consent,
                          !isSignedConsent(idService) && !isFollowUpSelectedOnly ? classes.notSigned : classes.optional,
                        ].join(' ')}
                        onClick={() => handleOpen(idService, name, consent)}
                        role="none"
                      >
                        {name}
                      </div>
                    </Grid>
                  </div>
                )
            )}
          </Grid>
          <ConsentModal
            isOpen={isOpened}
            isSigning={isSigning}
            handleCloseCallback={handleClose}
            consentName={consentName}
            consentContent={consentContent}
            patientNameProp={patientName}
            signConsentCallback={signConsent}
          />
        </CardContent>
      </Card>
    </ActiveStepWrapper>
  );
};
