import { TableRow, makeStyles, withStyles, TableCell } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    '.MuiButton-root': {
      textTransform: 'none',
    },
    '.MuiTooltip-tooltip': {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
  shadedBackground: {
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    // zIndex: 1,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      fontSize: '28px',
    },
    '& p': {
      fontSize: '14px',
      marginBottom: '0',
    },
    marginBottom: '2rem',
  },
  content: {
    marginBottom: '2rem',
  },
  saveButton: {
    backgroundColor: '#12574d',
    color: 'white',
    height: '47px',
    width: '146px',
    marginRight: 0,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0b433b',
    },
    '&:disabled': {
      backgroundColor: '#979797',
      color: 'white',
    },
  },
  serviceGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2rem 0',
    height: '50px',
    '& div:focus': {
      backgroundColor: 'transparent',
    },
  },
  serviceGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& h2': {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    '& p': {
      marginLeft: '15px',
      fontSize: '0.875rem',
    },
  },
  serviceGroupEdit: {
    width: '300px',
  },
  serviceGroupTableHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2rem 1rem',
    fontSize: '16px',
  },
  nml4: {
    marginLeft: '-4rem',
  },
  subTableContainer: {
    display: 'table',
    width: '100%',
  },
  dropdown: {
    fontFamily: 'Messina Sans Regular',
    height: '40px',
    minWidth: '200px',
  },
  formDropdown: {
    display: 'flex',
    gap: '30px',
    flexDirection: 'row',
    margin: '0 10px',
  },
  formInputSelect: {
    top: '-7px',
    color: '#000',
  },
  actionButton: {
    color: theme.palette.primary.main,
    margin: '0 5px',
    cursor: 'pointer',
  },
  actionButtonClose: {
    cursor: 'pointer',
  },
  input: {
    fontSize: 20,
    width: 100,
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  name: {
    paddingLeft: 20,
  },
  centeredColumn: {
    textAlign: 'center',
  },
  itemTab: {
    border: 'solid 2px #F5F5F5',
    backgroundColor: '#F5F5F5',
    cursor: 'pointer',
  },
  buttonDisabled: {
    color: 'gray',
  },
  saveButtonText: {
    paddingLeft: '5px',
    position: 'relative',
    top: '-5px',
    cursor: 'pointer',
  },
  warning: {
    position: 'relative',
    top: '7px',
  },
  editButton: {
    border: '1px solid #C0C0C0',
    cursor: 'pointer',
    fontSize: '14px',
    textTransform: 'none',
  },
  createButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  serviceGroupWrapper: {
    margin: '0 0 2rem 0',
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid white', // O cualquier color que desees
    },
  },
}));

export const TableRowStriped = withStyles(() => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: '#f2f5f5',
    //   height: '56px',
    // },
    '& td': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      padding: '0.5rem 1.25rem',
      border: '0',
    },
  },
}))(TableRow);

export const TableCellCustom = withStyles(() => ({
  root: (props: any) => {
    let backgroundColor = 'rgb(250, 128, 134);';

    if (props.backgroundColor) {
      backgroundColor = props.backgroundColor;
    } else if (props.active) {
      backgroundColor = 'rgb(209, 241, 189);';
    }

    return {
      fontSize: '14px',
      fontFamily: 'Messina Sans Regular',
      color: '#393b3f',
      letterSpacing: '-0.05px',
      textTransform: 'capitalize',
      '& div': {
        backgroundColor,
        borderRadius: '4px',
        width: 'fit-content',
        padding: '2px 15px',
      },
    };
  },
}))(TableCell);
