import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { VideoModal } from 'src/pages/MedspaAdmin/Onboarding/components/VideoModal';
import { ONBOARDING_VIDEOS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { VideoBanner } from 'src/pages/MedspaAdmin/Onboarding/components/VideoBanner';
import Page from '../../common/Page';
import { MedspaProviderAvailability } from './MedspaProviderAvailability';

export const MedspaCalendarConfigurationV2 = () => {
  const [openEhrOnboardingSuccessModal, setOpenEhrOnboardingSuccessModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const { DoneFooterComponent, onboardingEnabled } = useMedspaAdminOnboarding();

  const handleOnSuccess = () => {
    setOpenEhrOnboardingSuccessModal(true);
  };

  return (
    <>
      <Page title="Provider Configuration">
        {onboardingEnabled && (
          <VideoBanner title="How to configure your calendar?" onClick={() => setShowVideoModal(true)} />
        )}
        <MedspaProviderAvailability onSuccess={handleOnSuccess} />
        <Box height={100} />
      </Page>

      {openEhrOnboardingSuccessModal && <DoneFooterComponent />}

      <VideoModal
        open={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        url={ONBOARDING_VIDEOS.CALENDAR_SET_UP_NAME}
      />
    </>
  );
};
