import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  InputAdornment,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DATEPICKER_FORMAT, INPUT_DATE_FORMAT } from 'src/constants/inventory.constants';
import moment from 'moment';
import { IDeliveryBlackoutDates, PickersDate } from 'src/interfaces/IInventoryOrder';
import { useBlackoutDates, useCreateBlackoutDate, useDestroyBlackoutDate } from 'src/hooks/queries/useInventoryOrders';
import { Close as CloseIcon, Today as TodayIcon } from '@material-ui/icons';

const BlackoutDate: React.FC<IDeliveryBlackoutDates & { classes?: { [key: string]: string } }> = ({
  id,
  blackoutDate,
  classes,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync: destroyBlackoutDate } = useDestroyBlackoutDate(id);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await destroyBlackoutDate();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box data-loading={isLoading} className={classes?.blackoutDate}>
      <Typography>{moment.utc(blackoutDate).format(DATEPICKER_FORMAT)}</Typography>
      <IconButton disabled={isLoading} className={classes?.destroyDate} onClick={handleClick}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

const BlackoutDates: React.FC<{ classes?: { [key: string]: string } }> = ({ classes }) => {
  const [blackoutDate, setBlackoutDate] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { data: blackoutDates, isLoading: isLoadingBlackoutDates } = useBlackoutDates();
  const { mutateAsync: createBlackoutDate } = useCreateBlackoutDate();

  const disableCalendarDays = (date: PickersDate): boolean => {
    if (!date) {
      return true;
    }

    const dateStrings =
      blackoutDates?.map((dateObject: IDeliveryBlackoutDates) =>
        moment.utc(dateObject.blackoutDate).format(INPUT_DATE_FORMAT)
      ) ?? [];
    const isAlreadyBlocked = dateStrings.indexOf(date.format(INPUT_DATE_FORMAT)) > -1;
    const isWeekendOrMonday = [0, 1, 6].indexOf(date.weekday()) > -1;

    return isWeekendOrMonday || isAlreadyBlocked;
  };

  const handleChangeDate = (date: PickersDate) => {
    !!date && setBlackoutDate(date.format(INPUT_DATE_FORMAT));
  };

  const saveDate = async () => {
    if (blackoutDate) {
      setIsUpdating(true);
      try {
        await createBlackoutDate(blackoutDate);
      } finally {
        setBlackoutDate(null);
        setIsUpdating(false);
        setDialogOpen(false);
      }
    }
  };

  return (
    <Container classes={classes} data-testid="blackoutDates">
      <Container className={classes?.datePickerContainer}>
        <Typography>Holiday Dates</Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            placeholder="Select a date"
            className={classes?.datePicker}
            value={blackoutDate ? moment(blackoutDate) : null}
            format={DATEPICKER_FORMAT}
            autoOk
            disablePast
            disableToolbar
            shouldDisableDate={disableCalendarDays}
            onChange={handleChangeDate}
            disabled={isLoadingBlackoutDates}
            data-testid="blackoutDates.datepicker"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TodayIcon />
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>
        <Button disabled={!blackoutDate} onClick={() => setDialogOpen(true)}>
          save
        </Button>
      </Container>
      <Container className={classes?.blackoutDatesContainer}>
        <>
          {isLoadingBlackoutDates ? (
            <CircularProgress className={classes?.progress} size={22} />
          ) : (
            blackoutDates?.map(
              (date: IDeliveryBlackoutDates): React.ReactElement => (
                <BlackoutDate {...date} classes={classes} key={date.id} />
              )
            )
          )}
        </>
      </Container>
      <Dialog onBackdropClick={() => setDialogOpen(false)} open={dialogOpen} PaperProps={{ className: classes?.paper }}>
        <Typography className={classes?.blackoutDatesPlaceholder}>Are you sure?</Typography>
        <Container>
          <Button disabled={isUpdating} onClick={() => setDialogOpen(false)}>
            cancel
          </Button>
          <Button disabled={isUpdating} onClick={saveDate}>
            {isUpdating ? <CircularProgress size={15} /> : 'save'}
          </Button>
        </Container>
      </Dialog>
    </Container>
  );
};

export default withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 'unset',
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 90,
    borderBottom: '1px solid #eee',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '30%': {
      opacity: 0.26,
    },
    '100%': {
      opacity: 1,
    },
  },
  datePicker: {
    margin: 'auto 25px auto 0',
    width: 150,
  },
  datePickerContainer: {
    margin: 0,
    width: 420,
    display: 'flex',
    flexDirection: 'row',
    '& > p': {
      margin: 'auto 30px auto 0',
      width: 105,
    },
  },
  blackoutDatesContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    maxWidth: 'calc(100% - 420px)',
    overflowX: 'scroll',
  },
  blackoutDate: {
    backgroundColor: 'rgb(213,194,231)',
    color: '#000',
    borderRadius: 8,
    width: 150,
    maxWidth: 150,
    padding: '4px 12px',
    margin: 'auto 0 auto 10px',
    display: 'flex',
    '&:first-child': {
      marginLeft: 0,
    },
    '& > p': {
      margin: 'auto',
      fontSize: 16,
      paddingRight: 10,
    },
    '& > button': {
      width: 15,
      height: 15,
    },
    '& svg': {
      fontSize: 20,
    },
    '&[data-loading="true"]': {
      animation: '$pulse 2s ease-in-out infinite',
    },
  },
  paper: {
    width: 300,
    padding: 25,
    '& > p': {
      margin: 'auto auto 20px auto',
      fontSize: 18,
    },
    '& > div': {
      display: 'flex',
    },
    '& button': {
      margin: 'auto',
    },
  },
  destroyDate: {
    backgroundColor: 'rgb(170,155,185)',
  },
  progress: {
    color: '#bbb',
    margin: 'auto',
    marginLeft: 15,
  },
})(BlackoutDates);
