import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  editSection: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '38px',
    alignItems: 'center',
  },
  TextFieldSection: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '20px',
  },
  label: {
    marginBottom: '10px',
    color: '#949ea9',
    whiteSpace: 'pre-line',
  },
}));
