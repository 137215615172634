import { makeStyles } from '@material-ui/core/styles';
import { TableCell, withStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  card: {
    margin: '0',
  },
  smallCard: {
    margin: '0',
    height: '160px',
  },
  cardContent: {
    padding: '11px 20px 9px !important',
  },
  smallTitle: {
    margin: '0',
  },
  contentButton: {
    display: 'block',
    paddingTop: '15px',
  },
  button: {
    backgroundColor: '#12574d',
    color: 'white !important',
    width: '170px',
    height: '47px',
    marginRight: 0,
  },
  contentCheckout: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentTotal: {
    marginTop: '10px',
    textAlign: 'right',
  },
  productScanned: {
    height: '47px',
    margin: '10px 0 10px',
    padding: '9px 33px 11px 22px',
    borderRadius: '3px',
    backgroundColor: '#ddf5d6',
    borderBottom: '8px solid #ffffff',
  },
  productScannedTransition: {
    height: '47px',
    margin: '10px 0 10px',
    padding: '9px 33px 11px 22px',
    borderRadius: '3px',
    backgroundColor: '#d0e6ca',
    borderBottom: '8px solid #ffffff',
  },
  productsPending: {
    margin: '10px 0 10px',
    height: '47px',
    padding: '9px 33px 11px 22px',
    background: '#ECECEC',
    borderBottom: '8px solid #ffffff',
    borderRadius: '3px',
  },
  qrImage: {
    width: '86px',
    height: '86px',
    margin: '3px 0 21px 190px',
    padding: '9px',
    borderRadius: '4px',
    border: 'solid 1px #979797',
    backgroundColor: '#ffffff',
  },
  checkedIcon: {
    color: '#31bb49',
  },
  undoIcon: {
    fontSize: '25px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  scan: {
    marginTop: '28px',
    marginRight: '36px',
  },
}));

export const TableCellCustom = withStyles(() => ({
  root: {
    fontFamily: 'Messina Sans Regular',
    color: '#393b3f',
    letterSpacing: '-0.05px',
    padding: '10px 16px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}))(TableCell);
