import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import Patients from '../services/Patients';
import ContactInfoForm from '../components/PatientProfile/MainSection/ContactInfoForm';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import { ROUTES } from '../constants/routes.constants';

const EditContactInfo = ({
  match: {
    params: { patientId },
  },
}: any) => {
  const history = useHistory();
  const { data, isLoading } = useQuery(['contactInfo', patientId], () => Patients.getContactInfo(patientId));

  const navigate = () => {
    history.push(ROUTES.PATIENT_ID(patientId));
  };

  return (
    <main>
      <NavigationBar title="Edit Contact Information" onBackButtonClick={navigate} />
      {data && <ContactInfoForm data={data} isLoading={isLoading} />}
    </main>
  );
};

export default EditContactInfo;
