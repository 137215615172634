import { makeStyles } from '@material-ui/core/styles';
import {
  PENDING_TAG_BACKGROUND_COLOR,
  PENDING_TAG_FONT_COLOR,
} from 'src/pages/Onboarding/AccountSettings/AccountSettings.styles';

export const COMPLETED_STEP_COLOR = '#10974E';
export const PENDING_STEP_COLOR = PENDING_TAG_BACKGROUND_COLOR;
export const PENDING_STEP_FONT_COLOR = '#DE9323';

const useStyles = makeStyles({
  container: {
    '&:last-child::before': {
      display: 'none',
    },
  },
  stepComponent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    textAlign: 'left',
  },
  filledGreenCircle: {
    height: '20px',
    width: '20px',
    borderRadius: '100%',
    backgroundColor: COMPLETED_STEP_COLOR,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filledYellowCircle: {
    height: '20px',
    width: '20px',
    borderRadius: '100%',
    backgroundColor: PENDING_STEP_COLOR,
    color: PENDING_TAG_FONT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgressContainer: {
    height: '20px',
    width: '20px',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyCircle: {
    height: '20px',
    width: '20px',
    borderRadius: '100%',
    margin: '10px 24px',
    border: '1px lightgrey solid',
  },
  roundedCheck: {
    transform: 'scale(0.6)',
  },

  circleContainer: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '75%',
      left: '50%',
      width: '2px',
      height: '20px',
      backgroundColor: 'lightgrey',
      zIndex: -1,
      transform: 'translate(-50%)',
    },
  },
});
export default useStyles;
