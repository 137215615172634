import React, { useRef } from 'react';
import { Box, ButtonBase } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';
import { EHRTypography } from './EHRTypography';

interface EHRSignatureCanvasProps {
  errors?: boolean;
  helperText?: string;
  onSign: (signature: string) => void;
  onClear: () => void;
}

export const EHRSignatureCanvas: React.FC<EHRSignatureCanvasProps> = ({ errors, helperText, onSign, onClear }) => {
  const signCanvasRef = useRef<SignatureCanvas>(null);

  const clearSignature = () => {
    if (signCanvasRef.current) {
      signCanvasRef.current.clear();
      onClear?.();
    }
  };

  const sign = () => {
    if (signCanvasRef.current) {
      const signature = signCanvasRef.current.toDataURL();
      onSign(signature);
    }
  };

  return (
    <>
      <Box bgcolor="white" border="1px dashed lightgrey" borderRadius="6px" padding="14px">
        <Box display="flex" justifyContent="space-between">
          <EHRTypography style={{ color: 'grey' }} variant="body2" dataCy="onboarding-signature-step">
            Draw your signature here
          </EHRTypography>
          <ButtonBase onClick={clearSignature}>
            <EHRTypography
              style={{ color: 'grey', textDecoration: 'underline' }}
              variant="body2"
              dataCy="onboarding-signature-step"
            >
              Clear
            </EHRTypography>
          </ButtonBase>
        </Box>
        <Box>
          <SignatureCanvas
            canvasProps={{
              style: {
                width: '100%',
                height: '158px',
              },
            }}
            ref={signCanvasRef}
            clearOnResize={false}
            onEnd={sign}
          />
        </Box>
      </Box>
      <Box color="red">{errors && (helperText || 'Signature is required.')}</Box>
    </>
  );
};
