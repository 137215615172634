/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import moment from 'moment';
import { Box, FormControl, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useSelector } from 'react-redux';
import { useStyles } from './practitionerDashboard.styles';
import EarningsDashboardTable from './EarningsDashboardTable';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { READ_EARNING_DASHBOARD, READ_FLOOR_PRICE } from '../../../../constants/actions.constants';

const CURRENT_DATE = moment();
const EarningsCalculator = ({
  selectedDate,
  onChangeDate,
  onChangeTotalServicesWithDiscount,
  formatToShow,
  earnings,
  minDate,
  errorMessage,
  isError,
  isLoading,
}: any) => {
  const classes = useStyles();

  const { isLoading: isLoadingPermissions, permissions } = useSelector(({ auth }: any) => auth);

  const EARNINGS_LABEL = 'Earnings Before Adjustments';

  const enabledPriceFloor = hasAccessTo(READ_FLOOR_PRICE, permissions);
  const showEarningsReport = permissions?.length > 0 && hasAccessTo(READ_EARNING_DASHBOARD, permissions);

  useEffect(() => {
    onChangeTotalServicesWithDiscount(earnings?.baseCalculations?.totalServicesWithDiscount);
  }, [earnings]);

  return (
    <div className={classes.area}>
      {showEarningsReport ? (
        <Typography variant="h6" className={classes.title}>
          Earnings Calculator
        </Typography>
      ) : (
        <Typography variant="h6" className={classes.title}>
          Filter by Month
        </Typography>
      )}
      <Box width="100%">
        <Box display="flex" justifyContent="space-between">
          <Box style={{ marginBottom: '1rem' }}>
            <FormControl>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  views={['year', 'month']}
                  openTo="month"
                  autoOk
                  label="Month"
                  variant="inline"
                  inputVariant="outlined"
                  format={formatToShow}
                  minDate={minDate}
                  maxDate={CURRENT_DATE}
                  value={selectedDate}
                  onChange={() => {}}
                  onAccept={(date: any) => onChangeDate(date)}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Box>
        </Box>
        {showEarningsReport && (
          <EarningsDashboardTable
            data={earnings}
            enabledPriceFloor={enabledPriceFloor}
            earningsLabel={EARNINGS_LABEL}
            isLoading={isLoading || isLoadingPermissions}
            isError={isError}
            errorMessage={errorMessage}
          />
        )}
      </Box>
    </div>
  );
};

export default EarningsCalculator;
