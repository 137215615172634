import Api from './Api';

export const getReports = async (reportType: string, fromDate: string, toDate: string) => {
  const paramsList = [];

  paramsList.push(`report_type=${reportType}`);
  paramsList.push(`from_date=${fromDate}`);
  paramsList.push(`end_date=${toDate}`);

  const { message, error } = await Api.get(`/csv_reports/email_reports_by_range_and_type?${paramsList.join('&')}`);

  if (error) {
    throw error;
  }

  return { message };
};

export const getInventoryBalanceReports = async (reportType: string) => {
  const paramsList = [];

  paramsList.push(`report_type=${reportType}`);

  const { message, error } = await Api.get(`/csv_reports/email_inventory_balance_reports?${paramsList.join('&')}`);

  if (error) {
    throw error;
  }

  return { message };
};

export const getOldDailyInventoryBalanceReports = async (reportType: string, date: string) => {
  const paramsList = [];

  paramsList.push(`report_type=${reportType}`);
  paramsList.push(`date=${date}`);

  const { message, error } = await Api.get(
    `/csv_reports/email_old_daily_inventory_balance_reports?${paramsList.join('&')}`
  );

  if (error) {
    throw error;
  }

  return { message };
};
