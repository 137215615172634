import { MEDSPA_CARD_LIST, MEDSPA_CARD_CREATE, USER_GROUP_BANK_ACCOUNTS } from 'src/constants/apiRoutes.constants';
import { INewCard, IPaymentCard } from 'src/interfaces/IMedspaAdminList';
import { BankAccountParams } from 'src/hooks/queries/medspaAdmins/useMedspaBankAccount';
import { BankAccountFormData } from 'src/pages/Onboarding/AccountSettings/StepsCards/BankAccount';
import Api from '../Api';

const PaymentMethods = {
  async getCards(userGroupId: number): Promise<IPaymentCard[]> {
    const data = await Api.get(MEDSPA_CARD_LIST(`${userGroupId}`));
    if (Array.isArray(data)) {
      return data;
    }
    return [];
  },

  async addCard(userGroupId: number, params: INewCard): Promise<void> {
    const response = await Api.post(MEDSPA_CARD_CREATE(`${userGroupId}`).replaceAll('//', '/'), { card: params });
    if (!response.success) {
      throw new Error(response.message);
    }
    return response;
  },
  async getBankAccount(userGroupId: number): Promise<BankAccountFormData> {
    const url = USER_GROUP_BANK_ACCOUNTS(`${userGroupId}`);
    const { data } = await Api.get(url);
    return data;
  },
  async addBankAccount(userGroupId: number, params: BankAccountParams): Promise<BankAccountFormData> {
    const url = USER_GROUP_BANK_ACCOUNTS(`${userGroupId}`);
    const response = await Api.post(url, params);
    if (!response.success) {
      throw new Error(response.message);
    }
    return response;
  },
};

export { PaymentMethods, PaymentMethods as default };
