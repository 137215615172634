/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-console */

let Analytics: {
  identify: (id: any, traits?: object) => void;
  track: (eventName?: string, properties?: object) => void;
  page: (pageName?: string) => void;
};

if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' && typeof window !== 'undefined' && (window as any).analytics) {
  // Pull in the analytics object from the Segment <head> JS (or whatever other package might
  // define this).
  Analytics = (window as any).analytics;
} else {
  // Use a no-op Analyics for server-side rendering.
  Analytics = {
    identify: (data: any) => {
      console.log(data);
    },
    track: (data: any, props: any | undefined) => {
      console.log(data, props);
    },
    page: (data: any) => {
      console.log(data);
    },
  };
}

export default Analytics;
