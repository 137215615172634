import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import InfiScroller from 'react-infi-scroller';
import Page from 'src/components/common/Page';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import AdminAssetsRow from './AdminAssetsRow';
import AdminAssetsHeader from './AdminAssetsHeader';
import { dispatch } from '../../../rematch';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import { IAssetQuery } from '../../../interfaces/global.interfaces';

const useStyles = makeStyles(() => ({
  infiScrollContainer: {
    overflow: 'auto',
    height: '84.7vh',
    width: '104%',
    marginTop: '10px',
  },
}));

const AdminAssets = () => {
  const classes = useStyles();
  const { assets, isLoading, meta } = useSelector((state: any) => state.assets);
  const history = useHistory();

  const queryParams = new URLSearchParams(useLocation().search);
  const refComponent = useRef(null);
  const warehouseId = queryParams.get('warehouseId');
  const serviceId = queryParams.get('serviceId');
  const supplierId = queryParams.get('supplierId');
  const status = queryParams.get('status');
  const internalSerialNumber = queryParams.get('internalSerialNumber');

  const [query, setQuery] = useState<IAssetQuery>({
    page: 1,
    warehouseId: warehouseId ? +warehouseId : undefined,
    serviceId: serviceId ? +serviceId : undefined,
    supplierId: supplierId ? +supplierId : undefined,
    status,
    internalSerialNumber: internalSerialNumber || undefined,
  });

  useEffect(() => {
    dispatch.assets.fetchAssets();
    // @ts-ignore
    dispatch.common.fetchServices();
    dispatch.common.fetchWarehouses();
    dispatch.common.fetchSuppliers();
  }, []);

  useEffect(() => {
    dispatch.assets.fetchAssets(query);
  }, [query]);

  useEffect(() => {
    const params = new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
      if (key !== 'page' && !!value) {
        params.append(key, String(value));
      }
    });

    history.replace({ search: params.toString() });
  }, [query, history]);

  const loadMore = () => {
    if (!isLoading) {
      setQuery((prevState: any) => ({ ...prevState, page: prevState.page + 1 }));
    }
  };

  const MyBreadcrumb = (
    <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BreadcrumbsContainer>
          <BreadcrumbText text="Inventory" />
          <BreadcrumbText text="Assets" isActive linkTo="/administrator/assets" />
        </BreadcrumbsContainer>
      </Box>
    </Box>
  );

  const MyTable = isLoading ? (
    <MultipleSkeleton />
  ) : (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader />
              <TableCellHeader>Name</TableCellHeader>
              <TableCellHeader>Status</TableCellHeader>
              <TableCellHeader>Initial Units</TableCellHeader>
              <TableCellHeader>Remaining Units</TableCellHeader>
              <TableCellHeader>Warehouse</TableCellHeader>
              <TableCellHeader>Internal Serial Number</TableCellHeader>
              <TableCellHeader>Lot</TableCellHeader>
              <TableCellHeader>Expiration Date</TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.map((asset: IAsset) => (
              <AdminAssetsRow key={asset.id} asset={asset} />
            ))}
          </TableBody>
        </Table>
        {isLoading && query.page !== 1 && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={25} />
          </div>
        )}
      </TableContainer>
    </Box>
  );

  return (
    <Page title="Assets">
      <Box width="100%">
        {MyBreadcrumb}
        <AdminAssetsHeader setQuery={setQuery} query={query} />

        <Box paddingLeft={2} mt={5}>
          <Box display="flex" justifyContent="space-between">
            <Typography>{meta.totalCount} Results.</Typography>
          </Box>
        </Box>

        <div ref={refComponent} className={classes.infiScrollContainer}>
          <InfiScroller
            hasMore={query.page !== meta.totalPages}
            scrollTarget={refComponent.current}
            onLoadMore={loadMore}
          >
            {MyTable}
          </InfiScroller>
        </div>
      </Box>
    </Page>
  );
};

export default AdminAssets;
