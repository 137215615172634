import { makeStyles } from '@material-ui/core';

export const useRowStyles = makeStyles({
  root: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    '& > *': {
      border: '0 !important',
    },
  },
  email: {
    textTransform: 'lowercase',
  },
  menuIcon: {
    border: '1px solid #E4E7EB',
    borderRadius: '2px',
  },
  tableGeneral: {
    position: 'relative',
  },
  infiScrollContainer: {
    overflow: 'auto',
    height: '84.7vh',
    width: '100%',
    marginTop: '10px',
  },
  bottomRef: {
    position: 'absolute',
    width: '100%',
    marginTop: '15px',
  },
  bottomRefHidden: {
    // display: 'none',
    backgroundColor: 'black',
  },
});

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
    height: '47px',
    lineHeight: '47px',
  },
  formControl: {
    width: '100%',
  },
  add: {
    width: '100%',
    height: '55px',
  },
  clipboardLink: {
    marginTop: '8px',
    color: '#2172BB',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    textDecoration: 'underline',
  },
  selectedPractitioner: {
    fontFamily: 'Messina Sans Regular',
    borderRadius: '4px',
    backgroundColor: '#e7eeed',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.93,
    letterSpacing: 'normal',
    color: '#000000',
    padding: '11px 6px 11px 11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '46px',
  },
  dialogActions: {
    display: 'block',
    textAlign: 'center',
  },
  message: {
    height: '40px',
    background: '#F2D1AB',
    lineHeight: '40px',
    padding: '0px 5px',
    marginTop: '10px',
  },
  dropdown: {
    fontFamily: 'Messina Sans Regular',
    height: '46px',
  },
  formDropdown: {
    width: '255px',
    left: '30px',
  },
  formInputSelect: {
    top: '-4px',
    color: '#000',
  },
  checkbox: {
    color: '#12574d',
    marginLeft: '20px',
  },
  containerDialog: {
    width: '450px',
  },
  buttonRight: {
    background: theme.palette.primary.main,
    color: 'white',
  },
  buttonLeft: {
    background: '#FBDADA',
  },
}));
