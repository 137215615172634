import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Button } from '../../../components/common/Button';
import { IDiscount } from '../../../interfaces/checkout.interfaces';
import { discountValidationFactory, getDiscountAmountByType } from '../../../utils/checkout.utils';
import { useStyles } from './newDiscountsModal.styles';
import { CustomDiscount } from '../CustomDiscounts';
import { DISCOUNT_TYPES } from '../../../constants/checkout.constants';
import { DiscountItem } from './DiscountItem';

export const NewDiscountsModal = (props: any) => {
  const classes = useStyles();
  const { open, appliedDiscounts, medicationData, handleClose, applyCallback } = props;
  const medicationTotalPrice = +medicationData.price * +medicationData.unit;
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const newServiceVisit = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const { totalServicesUnits, totalCreditServicesUnits } = newServiceVisit;
  const { discounts }: { discounts: IDiscount[] } = newServiceVisit;
  const { isAdvocate, usedIndustryDiscount } = useSelector(({ patient }: any) => patient);
  const [selectedDiscountIds, setSelectedDiscountIds] = useState<number[]>([]);
  const [customDiscountChecked, setCustomDiscountChecked] = useState<boolean>(false);
  const [customDiscount, setCustomDiscount] = useState<any>({});
  const hasMutuallyExclusive = discounts
    .filter(({ id }) => selectedDiscountIds.includes(id))
    .some(({ mutuallyExclusive }) => mutuallyExclusive);

  useEffect(() => {
    const ids = appliedDiscounts.filter(({ id }: any) => id).map(({ id }: any) => id);
    setSelectedDiscountIds(ids);
    if (appliedDiscounts.length > 0) {
      setCustomDiscountChecked(appliedDiscounts.some(({ reason }: any) => reason));
    }
  }, [appliedDiscounts]);

  const selectDiscount = ({ id, mutuallyExclusive }: IDiscount): void => {
    let newSelectedDiscountsIds = [...selectedDiscountIds];

    if (newSelectedDiscountsIds.includes(id)) {
      newSelectedDiscountsIds = newSelectedDiscountsIds.filter((discountId: number) => discountId !== id);
    } else if (mutuallyExclusive) {
      newSelectedDiscountsIds = [id];
    } else {
      newSelectedDiscountsIds.push(id);
    }

    setSelectedDiscountIds(newSelectedDiscountsIds);
  };

  const onClose = (): void => {
    handleClose();
    setSelectedDiscountIds([]);
  };

  const applyDiscount = (): void => {
    let selectedDiscounts;
    if (customDiscountChecked) {
      selectedDiscounts = [customDiscount];
    } else {
      setCustomDiscount({});
      selectedDiscounts = discounts
        .filter(({ id }) => selectedDiscountIds.includes(id))
        .map((discount) => {
          let amount;
          const discountAmount: number = getDiscountAmountByType(discount);

          if (discount.discountType === 'percentage') {
            amount = discountAmount;
          } else {
            amount = discountAmount > medicationTotalPrice ? medicationTotalPrice : discountAmount;
          }

          return {
            id: discount.id,
            type: discount.discountType === 'value' ? 'amount' : discount.discountType,
            amount,
            value: discount.name,
          };
        });
    }

    applyCallback(selectedDiscounts);
    handleClose();
  };

  const disableIfMutuallyExclusiveAndCombinableSelected = (isMutuallyExclusive: boolean): boolean =>
    isMutuallyExclusive &&
    selectedDiscountIds.some((selectedDiscountId: number) =>
      discounts.some(({ id, mutuallyExclusive }: any) => id === selectedDiscountId && !mutuallyExclusive)
    );

  const isDiscountDisabled = (discount: IDiscount): boolean => {
    const { id, blacklist, variantItemBlacklist, mutuallyExclusive, isIndustryDiscount } = discount;

    if (usedIndustryDiscount && isIndustryDiscount) {
      return true;
    }

    if (!isAdvocate && isIndustryDiscount) {
      return true;
    }

    if (hasMutuallyExclusive && !selectedDiscountIds.includes(id)) {
      return true;
    }

    if (blacklist.includes(medicationData.serviceId)) {
      return true;
    }
    if (variantItemBlacklist.includes(medicationData.variantItemId)) {
      return true;
    }

    if (disableIfMutuallyExclusiveAndCombinableSelected(mutuallyExclusive)) {
      return true;
    }

    return false;
  };

  const showDiscount = ({ discountType, blacklist, variantItemBlacklist, options }: IDiscount): boolean => {
    const meetExtraValidations = discountValidationFactory(
      discountType,
      options,
      totalServicesUnits,
      totalCreditServicesUnits
    );
    let isInBlacklist = true;
    if (!medicationData.isVariantItem && variantItemBlacklist.length === 0) {
      isInBlacklist = blacklist.includes(medicationData.serviceId);
    } else if (medicationData.isVariantItem) {
      isInBlacklist = variantItemBlacklist.includes(medicationData.variantItemId);
    }
    return !isInBlacklist && meetExtraValidations;
  };

  const isValidAmount = (type: string, amount: string): boolean =>
    type === DISCOUNT_TYPES.PERCENTAGE ? +amount <= 100 : +amount <= medicationTotalPrice;

  const isCustomDiscountValid = (): boolean => {
    const { type, amount } = customDiscount;
    const values = Object.values(customDiscount);

    if (values.length) {
      return !values.some((value: any) => !value) && isValidAmount(type, amount);
    }
    return false;
  };

  const disableApplyButton = (): boolean => (customDiscountChecked ? !isCustomDiscountValid() : false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { borderRadius: '9px', maxWidth: '536px', maxHeight: '89%' } }}
    >
      <DialogTitle style={{ padding: '35px 27px 20px 39px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span className={classes.headerTitle}>Apply Discount to{` ${medicationData.name}`}</span>
          <CloseIcon onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.listContainer}>
          {discounts
            .filter((discount) => discount.active && showDiscount(discount))
            .map((discount) => (
              <DiscountItem
                label={discount.name}
                onChange={() => {
                  selectDiscount(discount);
                }}
                disabled={customDiscountChecked || isDiscountDisabled(discount)}
                checked={selectedDiscountIds.includes(discount.id)}
              />
            ))}
          <DiscountItem
            label="Custom Discount"
            onChange={() => {
              setCustomDiscountChecked((prevState) => !prevState);
            }}
            disabled={!!selectedDiscountIds.length}
            checked={customDiscountChecked}
          />
          <div style={{ marginLeft: '65px' }}>
            <CustomDiscount
              discounts={appliedDiscounts}
              medicationData={medicationData}
              disabled={!customDiscountChecked}
              setCustomDiscount={setCustomDiscount}
              isValidAmount={isValidAmount}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonSection}>
          <Button className={classes.cancelButton} title="Cancel" onClick={onClose} />
          <Button
            className={classes.applyButton}
            title="Apply"
            onClick={applyDiscount}
            disabled={disableApplyButton()}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};
