import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import bwipjs from 'bwip-js';
import { useSelector } from 'react-redux';
import { logSentryMessageError } from 'src/services/Sentry';
import { logDatadogMessageError } from 'src/services/Datadog';
import { useStyles, CustomButton } from './index.styles';
import { NavigationBar } from './NavigationBar';
import { Step1 } from './Step1';

interface Props {
  isDialogPositiveReviewOpen: boolean;
  setIsDialogPositiveReviewOpen: (value: boolean) => void;
}

export const ReviewModal = ({ isDialogPositiveReviewOpen, setIsDialogPositiveReviewOpen }: Props) => {
  const classes = useStyles();

  const socialMediaReviewsOptions = useSelector(
    ({ auth }: any) => auth.practitionerInformation?.socialMediaReviewsInfo
  );

  let reviewButtonsOptions = [];

  if (socialMediaReviewsOptions) {
    reviewButtonsOptions = Object.values(socialMediaReviewsOptions).map(
      (value) => value as typeof socialMediaReviewsOptions
    );
  }

  const [QRvalue, setQRvalue] = useState(reviewButtonsOptions[0] || '');
  const [step1, setStep1] = useState(true);

  // Setup QR code
  const canvas = document.createElement('canvas');

  if (QRvalue) {
    try {
      bwipjs.toCanvas(canvas, {
        bcid: 'qrcode',
        text: QRvalue?.url,
        scale: 1,
        height: 100,
        width: 100,
      });
    } catch (err) {
      logSentryMessageError('Error generating qr codes', { raw: err });
      logDatadogMessageError('Error generating qr codes', { raw: err });
    }
  }

  const onCloseModal = () => {
    setIsDialogPositiveReviewOpen(false);
    setTimeout(() => {
      setStep1(true);
    }, 500);
  };

  return (
    <Dialog open={isDialogPositiveReviewOpen} onClose={() => onCloseModal()}>
      {reviewButtonsOptions.length === 0 ? (
        <div className={classes.containerDialog} style={{ height: '80px' }}>
          <DialogTitle className={classes.title}>You don&apos;t have review links associated</DialogTitle>
        </div>
      ) : (
        <div className={classes.containerDialog}>
          {step1 ? (
            <Step1
              setQRvalue={setQRvalue}
              reviewButtonsOptions={reviewButtonsOptions}
              setIsDialogPositiveReviewOpen={setIsDialogPositiveReviewOpen}
              setStep1={setStep1}
            />
          ) : (
            <div
              style={{
                height: '100%',
                color: '#12574d',
                padding: '18px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <NavigationBar title="Go Back" onBackButtonClick={() => setStep1(true)} />
              <DialogContent className={classes.qrContainer}>
                <div>
                  <img src={canvas.toDataURL('image/png')} alt="QR code" />
                  <p>{QRvalue.name}</p>
                </div>
                <CustomButton onClick={() => onCloseModal()}>Done</CustomButton>
              </DialogContent>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};
