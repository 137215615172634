/* eslint-disable jsx-a11y/iframe-has-title */
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { EHRModal } from 'src/components/ui/v1';

type Props = {
  open: boolean;
  onClose: (value: boolean) => void;
  url: string;
};

const useStyles = makeStyles(() => ({
  video: {
    width: '600px',
    height: '400px',

    '@media (max-width: 600px)': {
      width: '100%',
      height: '200px',
    },
  },
}));

export const VideoModal = ({ open, onClose, url }: Props) => {
  const classes = useStyles();

  return (
    <EHRModal open={open} onClose={onClose} dataCy="modal-video">
      <EHRModal.Body dataCy="modal-video-body">
        <iframe src={url} className={classes.video} allowFullScreen />
      </EHRModal.Body>
    </EHRModal>
  );
};
