import React from 'react';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useRowStyles, TableRowStriped } from './practitionerDashboard.styles';
import { TableCellCustom } from '../../../common/TableStyles/TableCellCustom';
import { formatCurrency } from '../../../../utils/formatNumber';
import { ILineItem } from '../../../../interfaces/IServiceVisits';
import { RETAIL_SERVICE_GROUP_ID, RETAIL_SERVICES } from '../../../../constants/retail.constants';
import { ReactComponent as Alert } from '../../../../assets/images/alert.svg';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { READ_EARNING_DASHBOARD } from '../../../../constants/actions.constants';

export interface DashboardTableProps {
  date: string;
  customerId: number;
  customer: string;
  productAmount: number;
  serviceAmount: number;
  tipAmount: number;
  amountBelowPriceFloor: number;
  promotion?: string[];
  openEHR: string;
  hasPriceFloorEnabled: boolean;
  lineItemsBelowPriceFloor: ILineItem[];
}

const FORMAT_TO_SHOW_TRANSACTIONS = 'MM/DD/YYYY hh:mm A';
const TransactionsDashboardTableRow: React.FC<DashboardTableProps> = (props) => {
  const {
    date,
    customerId,
    customer,
    productAmount,
    serviceAmount,
    tipAmount,
    amountBelowPriceFloor,
    promotion = [],
    openEHR,
    hasPriceFloorEnabled,
    lineItemsBelowPriceFloor,
  } = props;
  const classes = useRowStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const showServiceBelowPriceWarning =
    hasPriceFloorEnabled &&
    amountBelowPriceFloor < 0 &&
    !!lineItemsBelowPriceFloor.find(
      (lineItem) =>
        lineItem.serviceGroupId !== RETAIL_SERVICE_GROUP_ID && !RETAIL_SERVICES.includes(lineItem.serviceName)
    );
  const showProductBelowPriceWarning =
    hasPriceFloorEnabled &&
    amountBelowPriceFloor < 0 &&
    !!lineItemsBelowPriceFloor.find(
      (lineItem) =>
        lineItem.serviceGroupId === RETAIL_SERVICE_GROUP_ID || RETAIL_SERVICES.includes(lineItem.serviceName)
    );

  return (
    <TableRowStriped className={classes.root}>
      <TableCellCustom className={classes.bold}>
        {`${moment(date).utcOffset('-0700').format(FORMAT_TO_SHOW_TRANSACTIONS)} PDT`}
      </TableCellCustom>
      <TableCellCustom>{customerId}</TableCellCustom>
      <TableCellCustom>{customer}</TableCellCustom>
      <TableCellCustom align="right">
        {formatCurrency(serviceAmount)}
        {showServiceBelowPriceWarning && <Alert className={classes.warning} />}
      </TableCellCustom>
      <TableCellCustom align="right">
        {formatCurrency(productAmount)}
        {showProductBelowPriceWarning && <Alert className={classes.warning} />}
      </TableCellCustom>
      <TableCellCustom align="right">{formatCurrency(tipAmount)}</TableCellCustom>
      {hasAccessTo(READ_EARNING_DASHBOARD, permissions) && (
        <TableCellCustom>
          {promotion.map((p) => (
            <div style={{ marginBottom: '5px' }}>{p}</div>
          ))}
        </TableCellCustom>
      )}
      <TableCellCustom>
        <a href={openEHR} target="_blank" rel="noreferrer">
          <Button variant="contained" color="primary" style={{ width: '1rem', height: '2rem', minWidth: 0 }}>
            <i className="fa fas fa-eye" />
          </Button>
        </a>
      </TableCellCustom>
    </TableRowStriped>
  );
};

export default TransactionsDashboardTableRow;
