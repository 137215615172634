import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Tooltip } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';

import moment from 'moment';
import { dispatch } from '../rematch';
import useSaveProgressWorker from '../hooks/mutations/useSaveProgressWorker';
import DialogModal from './common/DialogModal';
import { getServiceVisitProgress, getServiceVisitProgressToWatch } from '../constants/reselect/serviceVisitProgress';
import { useStyles } from './saveProgressWrapper.styles';
import { UPDATING_SERVICE_VISIT, PROGRESS_SAVE_ERROR } from '../constants/general.constants';
import LoadingModal from './common/LoadingModal';
import { MINIMUM_TIME_TO_UPDATE_THE_SERVICE_VISIT } from '../constants/newServiceVisit.constants';

const SaveProgressWrapper = ({ children, patientId, serviceVisitId }: any) => {
  const classes = useStyles();
  const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION';
  const { synchronizingServiceVisit, allowSaveProgress } = useSelector((state: any) => state.newServiceVisit);
  const serviceVisit = useSelector((state: any) => state.newServiceVisit.serviceVisit);
  const serviceVisitProgress = useSelector(getServiceVisitProgress);
  const serviceVisitProgressToWatch = useSelector(getServiceVisitProgressToWatch);
  const { startWorker, progressQueueFailed } = useSaveProgressWorker();
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();

  const visibilitychange = () => {
    if (document.visibilityState === 'visible') {
      setEndTime(moment());
    } else {
      setEndTime(null);
      setStartTime(moment());
      dispatch({ type: 'newServiceVisit/setAllowSaveProgress', payload: false });
    }
  };

  const refreshServiceVisit = () => {
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', visibilitychange);
    return () => {
      window.removeEventListener('visibilitychange', visibilitychange);
    };
  }, []);

  useEffect(() => {
    if (allowSaveProgress && serviceVisit.opened) {
      startWorker({ body: serviceVisitProgress, serviceVisitId, patientId });
    }
  }, [serviceVisitProgressToWatch, allowSaveProgress, serviceVisit]);

  useEffect(() => {
    if (!startTime || !endTime) {
      return;
    }
    if (endTime - startTime > MINIMUM_TIME_TO_UPDATE_THE_SERVICE_VISIT && serviceVisit.opened) {
      dispatch({ type: 'newServiceVisit/setSynchronizingServiceVisit', payload: true });
      dispatch({
        type: 'newServiceVisit/getServiceVisitFromPatient',
        payload: { serviceVisitId: +serviceVisitId, currentPatientId: +patientId },
      });
    } else {
      dispatch({ type: 'newServiceVisit/setAllowSaveProgress', payload: true });
    }
  }, [startTime, endTime]);

  return (
    <>
      {children}
      <LoadingModal open={synchronizingServiceVisit} loadingText={UPDATING_SERVICE_VISIT} />
      <DialogModal open={Boolean(progressQueueFailed.error)} closeModal={refreshServiceVisit}>
        <Typography className={classes.typographySize}>{PROGRESS_SAVE_ERROR}</Typography>
      </DialogModal>

      {!IS_PRODUCTION && progressQueueFailed.error && (
        <Tooltip title="Data inconsistency: last changes were not saved!">
          <div className={classes.warningProgressiveSaving}>
            <WarningIcon />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default SaveProgressWrapper;
