import { Box, ButtonBase, CircularProgress, Grid, makeStyles, Modal } from '@material-ui/core';
import React, { useEffect } from 'react';
import { EHRButton, EHRTypography } from 'src/components/ui/v1';
import SignAuthorization from 'src/pages/Onboarding/AccountSettings/StepsCards/MedicalDirector/SignAuthorization';
import { useUpdateMedspaMedicalDirector } from 'src/hooks/queries/useMedspaAdmins';
import { ExternalMedicalDirector, MedicalLicenseDetails } from 'src/interfaces/onboarding.interface';
import MedicalDirectorForm from 'src/pages/Onboarding/AccountSettings/StepsCards/MedicalDirector/MedicalDirectorForm';
import { ArrowBackIos as ArrowBack , Close as CloseIcon } from '@material-ui/icons';

interface MedicalDirectorProps {
  isOpen: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    gap: '10px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
  modalContent: {
    width: '500px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '2rem',
    margin: 'auto',
  },
  closeIcon: {
    transform: 'scale(1.2)',
  },
  closeIconContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
});

const UpdateMedicalDirectorModal = (props: MedicalDirectorProps) => {
  const externalMD = 'External Medical Director';
  const ownMD = 'Own Medical Director';
  const classes = useStyles();
  const { isOpen, onClose } = props;
  const [option, setOption] = React.useState<number | null>(null);
  const { mutateAsync: updateMedicalDirector, isLoading, isSuccess } = useUpdateMedspaMedicalDirector();

  const handleSignAuthorization = async (medicalLicenseDetails: MedicalLicenseDetails) => {
    updateMedicalDirector({
      medicalDirectorInfo: {
        isOwnMedicalDirector: true,
        hasNoMedicalDirector: false,
      },
      medicalLicenseDetails,
    });
  };

  const handleCompleteMDForm = (formData: ExternalMedicalDirector) => {
    updateMedicalDirector({
      medicalDirectorInfo: {
        isOwnMedicalDirector: false,
        hasNoMedicalDirector: false,
        externalMedicalDirector: formData,
      },
    });
  };

  const handleClose = () => {
    onClose?.();
    setOption(null);
  };

  const componentWrapper = (element: React.ReactNode, title: string) => (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.backButtonContainer}>
        <ButtonBase onClick={() => setOption(null)}>
          <ArrowBack />
          <EHRTypography variant="body2" dataCy="">
            Back
          </EHRTypography>
        </ButtonBase>
      </Grid>
      <Grid item xs={12}>
        <EHRTypography variant="h6" dataCy="">
          {title}
        </EHRTypography>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={50} style={{ marginRight: '10px' }} />
            <p>Loading...</p>
          </Box>
        ) : (
          element
        )}
      </Grid>
    </Grid>
  );

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isLoading, isSuccess]);

  return (
    <Modal open={isOpen} onClose={handleClose} className={classes.modal}>
      <Box className={classes.modalContent}>
        {!option && (
          <Box className={classes.formContainer}>
            <Box className={classes.closeIconContainer}>
              <ButtonBase onClick={handleClose}>
                <CloseIcon className={classes.closeIcon} />
              </ButtonBase>
            </Box>
            <EHRTypography variant="h6" dataCy="" style={{ textAlign: 'center' }}>
              Update Medical Director
            </EHRTypography>

            <EHRButton onClick={() => setOption(1)} dataCy="" text={ownMD} color="primary" variant="outlined" />
            <EHRButton
              onClick={() => setOption(2)}
              dataCy=""
              text={externalMD}
              color="primary"
              variant="outlined"
            />
          </Box>
        )}
        {option === 1 &&
          componentWrapper(<SignAuthorization disabled={isLoading} onSigned={handleSignAuthorization} />, ownMD)}
        {option === 2 &&
          componentWrapper(
            <MedicalDirectorForm disabled={isLoading} onComplete={handleCompleteMDForm} hideBanner />,
            externalMD
          )}
      </Box>
    </Modal>
  );
};

export default UpdateMedicalDirectorModal;
