import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  editSection: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '38px',
    alignItems: 'center'
  },
  selectSection: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '20px'
  },
  label: {
    marginBottom: '10px',
    color: '#949ea9'
  },
  dropdown: {
    width: '100%'
  }
}));
