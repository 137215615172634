import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from '../../rematch';
import ServiceVisit, { ProgressSeriesPayload } from '../../services/ServiceVisit';

declare global {
  interface Window {
    progressQueue: { (): Promise<any> }[];
    progressQueueWorking: boolean;
  }
}

export default function useSaveProgressWorker() {
  const [progressQueueFailed, setProgressQueueFailed] = useState<{
    error: string | null;
  }>({ error: null });
  const { isProgressQueueWorking } = useSelector(({ newServiceVisit }: any) => newServiceVisit);

  useEffect(() => {
    if (window.progressQueueWorking !== isProgressQueueWorking) {
      dispatch({ type: 'newServiceVisit/setIsProgressQueueWorking', payload: window.progressQueueWorking });
    }
  }, [window.progressQueueWorking, isProgressQueueWorking]);

  const progressSeries: () => Promise<void> | any = () => {
    if (window.progressQueue) {
      if (window.progressQueue.length === 0) {
        window.progressQueueWorking = false;
        setProgressQueueFailed({ error: null });
        return;
      }
      window.progressQueue.shift()!()
        .then(() => progressSeries())
        .catch((error: Error) => {
          window.progressQueue = [];
          window.progressQueueWorking = false;
          setProgressQueueFailed({ error: error.message || '' });
        });
    }
  };
  const startWorker = ({ body, patientId, serviceVisitId }: ProgressSeriesPayload) => {
    if (!window.progressQueue || !window.progressQueueWorking) {
      window.progressQueue = [];
      window.progressQueueWorking = false;
      setProgressQueueFailed({ error: null });
    }
    window.progressQueue.push(() => ServiceVisit.fetchSaveProgress({ body, patientId, serviceVisitId }));
    if (!window.progressQueueWorking) {
      window.progressQueueWorking = true;
      progressSeries();
    }
  };
  return {
    startWorker,
    progressQueueFailed,
  };
}
