/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, Tooltip, Button as MaterialButton } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { dispatch } from '../../rematch';
import compile from '../../utils/toastMessagesCompiler';

const CustomButton = withStyles(() => ({
  root: {
    fontFamily: 'Messina Sans SemiBold',
    borderRadius: 3,
    margin: '0 5px',
    padding: '15px',
    fontSize: '13px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    textAlign: 'center',
    maxHeight: '47px',
  },
  disabled: {
    opacity: '0.5',
  },
}))(MaterialButton);

export type Props = {
  title?: string;
  onClick?: any;
  style?: object;
  size?: string;
  Icon?: React.ReactElement;
  startIcon?: React.ReactElement;
  disabled?: boolean;
  type?: any;
  className?: string;
  dataCy?: string;
};

export const Button = ({ title, className, style, onClick = () => {}, disabled, type, dataCy }: Props) => {
  const [originalBackground, setOriginalBackground] = useState('');

  useEffect(() => {
    const originalClass = document.querySelector<any>(`.${className}`);
    if (originalClass) {
      const originalStyle = getComputedStyle(originalClass);
      setOriginalBackground(originalStyle.backgroundColor);
    }
  }, [className]);

  /**
   * This function fix the color bug with :hover and :focus on mobile devices.
   */
  const updateBackgroundColor = () => {
    if (!isMobile) {
      return;
    }

    try {
      const element = document.querySelector<any>(`.${className}:hover`);
      if (!element) {
        return;
      }
      element.style.backgroundColor = originalBackground;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onClickEvent = (event: any) => {
    updateBackgroundColor();
    onClick(event);
  };

  return (
    <CustomButton
      data-cy={dataCy}
      type={type}
      className={className}
      style={style}
      onClick={onClickEvent}
      disabled={disabled}
    >
      {title}
    </CustomButton>
  );
};

const useSerialStyles = makeStyles({
  serialNumber: {
    fontSize: '80%',
    background: '#e7e7e7',
    borderRadius: '2px',
    height: '22px',
    lineHeight: '23px',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface SerialProps {
  title: string;
  value: string;
}

export const SerialNumberButton = ({ title, value }: SerialProps) => {
  const classes = useSerialStyles();
  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.copy_to_clipboard'),
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'copying',
            element: 'clipboard',
          }),
          type: 'error',
        },
      });
    }
  };

  return (
    <Tooltip title={value}>
      <CustomButton className={classes.serialNumber} onClick={() => copyToClipboard(value)}>
        {title}
      </CustomButton>
    </Tooltip>
  );
};
