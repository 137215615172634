import { UseQueryResult, useQuery } from 'react-query';
import { MEDSPA_CARE_MESSAGES, MEDSPA_CUSTOM_CARE_MESSAGES } from 'src/constants/reactQuery.keys';
import { IMedspaCareMessage, IMedspaCustomCareMessage } from 'src/interfaces/IMedspaCareMessages';
import MedspaCareMessages from 'src/services/MedspaCareMessages';

export const useMedspaCareMessages = (
  userGroupId: number,
  enabled: boolean = true
): UseQueryResult<IMedspaCareMessage[]> =>
  useQuery<IMedspaCareMessage[]>({
    queryKey: [MEDSPA_CARE_MESSAGES],
    queryFn: (): Promise<IMedspaCareMessage[]> => MedspaCareMessages.getMedspaCareMessages({ userGroupId }),
    enabled,
  });

export const useMedspaCustomCareMessages = (
  userGroupId: number,
  enabled: boolean = true
): UseQueryResult<IMedspaCustomCareMessage[]> =>
  useQuery<IMedspaCustomCareMessage[]>({
    queryKey: [MEDSPA_CUSTOM_CARE_MESSAGES],
    queryFn: (): Promise<IMedspaCustomCareMessage[]> => MedspaCareMessages.getMedspaCustomCareMessages({ userGroupId }),
    enabled,
  });
