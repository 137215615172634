import React from 'react';
import { chunk, sortBy } from 'lodash';
import { Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import { useStyles } from './serviceList.styles';

export function ServiceList({ services, onChangeServiceSelection, selectedServices = [], checkDisableFunc }: any) {
  const classes = useStyles();

  const isServiceSelected = (selectedServiceId: number): boolean => selectedServices.includes(selectedServiceId);

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        {chunk(sortBy(services, ['name']), Math.ceil(services.length / 2)).map((servicesHalf, i: number) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <Grid container item xs={6} key={`shalf-${i}`}>
            {servicesHalf.map(({ name: serviceName, id: serviceId, serviceGroupId }) => (
              <Grid item container xs={12} key={`g-${serviceId}-${serviceGroupId}`}>
                <FormControlLabel
                  data-cy="serviceSelector"
                  key={serviceId}
                  classes={{ label: classes.checkboxLabel }}
                  label={serviceName}
                  control={
                    <Checkbox
                      data-cy="serviceSelectorCheckbox"
                      color="default"
                      checked={isServiceSelected(serviceId)}
                      classes={{ checked: classes.checkbox }}
                      onChange={() => onChangeServiceSelection(serviceId)}
                      name={serviceName}
                      disabled={checkDisableFunc && checkDisableFunc(serviceGroupId)}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
