import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  detailsContainer: {
    maxWidth: '500px',
  },
  detailBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '60px',
    alignItems: 'center',
  },
  detailWithHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailHeader: {
    color: 'black',
    opacity: '50%',
    marginBottom: '0.5rem',
  },
  subtitle: {
    margin: '0.8rem 0 2rem',
  },
  agreementButton: {
    marginTop: '2rem',
    color: 'white',
    background: '#12574D',
    borderRadius: '50px',
    padding: '8px 24px',
    '&:hover': {
      color: 'white',
      background: '#12574D',
    },
  },
  changeCardButton: {
    borderRadius: '50px',
    color: '#12574D',
    border: '1px solid #12574D',
    padding: '8px 24px',
  },
  cardForm: {
    marginTop: '1rem',
  },
  savedMessage: {
    marginTop: '1rem',
    color: '#12574D',
    background: '#E6F9DA',
    borderRadius: '8px',
    width: '250px',
    padding: '8px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  savedIcon: {
    marginRight: '10px',
  },
  errorMsgIcon: {
    marginRight: '8px',
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    color: '#FF1616',
    marginBottom: '1rem',
  },
  detailsCard: {
    display: 'flex',
    alignItems: 'center',
  },
  cardIcon: {
    position: 'relative',
    top: '8px',
    marginRight: '5px',
  },
  refreshButton: {
    border: 'none',
    color: 'blue',
    textDecoration: 'underline',
    background: 'none',
    padding: 0,
    cursor: 'pointer',
  },
}));
