import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  dialog: {
    borderRadius: '10px',
  },
  body: {
    display: 'block',
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    color: '#393B3F',
    textAlign: 'center',
    paddingBottom: '15px',
  },
  callToAction: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '24px',
    color: '#12574D',
    textAlign: 'center',
  },
  baseButton: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '5px',
    textTransform: 'none',
    fontFamily: 'Messina Sans Regular',
    fontSize: '14px',
  },
  cancelButton: {
    backgroundColor: '#FBDADA',
    color: '#393B3F',
    height: '47px',
    width: '104px',
    marginRight: '30px',
    '&:hover': {
      backgroundColor: 'rgba(251, 218, 218, .8)',
    },
  },
  yesButton: {
    backgroundColor: '#12574D',
    color: '#FFFFFF',
    height: '47px',
    width: '151px',
    '&:hover': {
      backgroundColor: 'rgba(18, 87, 77, .8)',
    },
  },
  patientName: {
    textTransform: 'capitalize',
  },
});
