import React from 'react';
import { DialogActions } from '@material-ui/core';
import { EHRButton, EHRModal, EHRTypography } from 'src/components/ui/v1';
import { useRemoveCommercialShippingAddress } from 'src/hooks/queries/useAccountSettings';
import { CommercialShippingAddress } from 'src/interfaces/onboarding.interface';
import { formatAddress } from 'src/utils/onboarding.utils';

interface ConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  commercialShippingAddress?: CommercialShippingAddress;
}

const ConfirmationModal = ({ open, handleClose, commercialShippingAddress }: ConfirmationModalProps) => {
  const { mutateAsync } = useRemoveCommercialShippingAddress();

  const handleConfirmRemove = async () => {
    if (commercialShippingAddress && commercialShippingAddress?.id) {
      const response = await mutateAsync(commercialShippingAddress.id);
      if (response?.success) {
        handleClose();
      }
    }
  };

  return (
    <EHRModal dataCy="remove-address-confirmation-modal" open={open} onClose={handleClose}>
      <EHRModal.Body dataCy="appointment-conflict-modal-body">
        <EHRTypography variant="h6" dataCy="remove-address-confirmation-title">
          Are you sure you want to delete this address?
        </EHRTypography>
        <EHRTypography variant="body1" dataCy="remove-address-confirmation-body">
          This action cannot be undone.
        </EHRTypography>
        <br />
        <EHRTypography variant="body2" dataCy="remove-address-confirmation-body">
          {formatAddress(
            commercialShippingAddress?.address ?? '',
            commercialShippingAddress?.city ?? '',
            commercialShippingAddress?.state ?? '',
            commercialShippingAddress?.zip
          )}
        </EHRTypography>
      </EHRModal.Body>
      <EHRModal.Footer dataCy="remove-address-confirmation-modal-footer">
        <DialogActions>
          <EHRButton onClick={handleClose} color="primary" variant="outlined" dataCy="closeModal" text="Cancel" />
          <EHRButton onClick={handleConfirmRemove} color="primary" dataCy="removeModal" text="Delete" />
        </DialogActions>
      </EHRModal.Footer>
    </EHRModal>
  );
};

export default ConfirmationModal;
