import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  feesContainer: {
    padding: '2rem',
  },
  title: {
    color: '#12574d',
  },
  feeBoldText: {
    fontWeight: 'bolder',
    fontSize: '18px',
    maxWidth: '550px',
  },
  input: {},
  button: {
    width: '400px',
    color: 'white',
    background: '#12574D',
    borderRadius: '8px',
    textTransform: 'none',
    margin: '2rem 0 1rem',
    '&:disabled': {
      color: 'white',
    },
    '&:hover': {
      background: '#12574D',
    },
  },
  savedMessage: {
    marginTop: '1rem',
    color: '#12574D',
    background: '#E6F9DA',
    borderRadius: '8px',
    width: '180px',
    padding: '8px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  savedIcon: {
    marginRight: '10px',
  },
  boxGroupItem: {
    margin: '0.5rem',
  },
}));
