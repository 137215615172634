import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Accept, useDropzone } from 'react-dropzone';
import { ReactComponent as DocumentIcon } from 'src/assets/images/file_upload_icon.svg';
import { useStyles } from './DragAndDrop.styles';
import FilesList from './FilesList';

function DragAndDropComponent({
  acceptedFileType,
  onFileChange,
  acceptMultiple = false,
  hideRemoveFile = false,
  hideDocumentIcon = false,
  height,
  error,
  helperText,
  disabled = false,
}: {
  acceptedFileType: string | Accept;
  onFileChange: (files: File[]) => void;
  acceptMultiple?: boolean;
  hideRemoveFile?: boolean;
  hideDocumentIcon?: boolean;
  height?: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}) {
  const [files, setFiles] = useState<File[]>([]);
  const [fileError, setFileError] = useState<string | null>(null);
  const classes = useStyles();

  const formatFileTypes = () =>
    (acceptedFileType as string)
      .split(',')
      .map((type) => (type.split('/')[1] || type.split('/')[0]).toUpperCase())
      .join(', ');

  const onRemoveDoc = (filesList: File[]) => {
    onFileChange([]);
    setFiles(filesList);
  };

  const onDrop = useCallback(
    (droppedFiles) => {
      if (disabled) {
        return;
      }

      const isInvalidFileType = droppedFiles.some(
        (file: File) =>
          !(acceptedFileType as string)
            .split(',')
            .map((t) => t.trim())
            .includes(file.type)
      );
      const errorMessage = isInvalidFileType ? `Invalid file type. Only ${formatFileTypes()} files are allowed.` : null;

      setFileError(errorMessage);
      if (!errorMessage) {
        onFileChange(droppedFiles);
        setFiles(droppedFiles);
      }
    },
    [acceptedFileType, onFileChange, disabled]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
    onDrop,
    accept: acceptedFileType ? (acceptedFileType as Accept) : undefined,
    multiple: acceptMultiple,
    disabled,
  });

  useEffect(() => {
    setFileError(error ? (helperText as string) : null);
  }, [error]);

  return (
    <Box className={classes.container}>
      <Box
        {...getRootProps()}
        style={{
          width: '100%',
          height: height || '120px',
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          border: `dashed ${fileError || error ? '#f44336' : 'lightgrey'} 1px`,
          backgroundColor: 'transparent',
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <input {...getInputProps()} disabled={disabled} />

        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <Box
            style={{
              width: '100%',
              height: height || '120px',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            {!hideDocumentIcon && (
              <Box className={classes.documentIconContainer}>
                <DocumentIcon className={classes.documentIcon} />
              </Box>
            )}
            <div style={{ margin: 0, textAlign: 'center' }}>
              <p>
                <span style={{ textDecoration: 'underline', fontWeight: 'bolder' }}>Click to upload</span>
                {` ${acceptMultiple ? 'files' : 'single file'} or Drag and Drop`}
              </p>
              <p
                style={{
                  fontWeight: 100,
                  color: 'grey',
                  margin: 0,
                }}
              >
                File types: {formatFileTypes()}
              </p>
            </div>
          </Box>
        )}
        {isDragAccept && !acceptMultiple && files.length > 1 && <p>Can&apos;t upload multiple documents at once</p>}
        {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
        {isDragAccept && files.length === 1 && <p>File name: {files[0].name}</p>}
      </Box>
      {!hideRemoveFile && <FilesList files={files} onRemoveDocument={onRemoveDoc} />}
    </Box>
  );
}

export default DragAndDropComponent;
