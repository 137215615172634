import React, { useEffect, useMemo } from 'react';
import { CardContent, Grid, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useStyles } from './ProductUnitsStep.styles';
import IServices from '../../../interfaces/IServices';
import { dispatch } from '../../../rematch';
import { getServiceLabel } from '../../../utils/newServiceVisit.util';
import { MAX_SERVICE_UNITS } from '../../../constants/checkout.constants';
import { RETAIL_SERVICE_GROUP_ID } from '../../../constants/retail.constants';
import { ILineItem } from '../../../interfaces/IServiceVisits';

const ProductUnitsStep = ({
  selectedServices,
  lineItems,
  setLineItems,
}: {
  selectedServices: number[];
  lineItems: ILineItem[];
  setLineItems: React.Dispatch<React.SetStateAction<ILineItem[]>>;
}) => {
  const classes = useStyles();
  const serviceVisit = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const { services = [] }: { services: IServices[] } = serviceVisit;

  const serviceToIdMap = useMemo((): Record<string, IServices> | undefined => {
    if (services.length) {
      return services.reduce((obj, service) => ({ ...obj, ...{ [service.id]: service } }), {});
    }
    return undefined;
  }, [services]);

  useEffect(() => {
    // TODO: remove this, is already declared on SelectProducts.tsx:28
    dispatch({
      type: 'newServiceVisit/fetchServices',
      payload: {},
    });
  }, []);

  useEffect(() => {
    const defaultLineItems = selectedServices
      .filter((serviceId) => !lineItems.some((item) => item.serviceId === serviceId))
      .map((serviceId) => {
        const service = serviceToIdMap && serviceToIdMap[serviceId];

        return {
          serviceId,
          quantity: 1,
          currentUseQuantity: service?.serviceGroupId === RETAIL_SERVICE_GROUP_ID ? 1 : 0,
          futureUseQuantity: service?.serviceGroupId === RETAIL_SERVICE_GROUP_ID ? 0 : 1,
        };
      });

    const newLineItems = [...lineItems, ...defaultLineItems];
    const filteredLineItems = newLineItems.filter((lineItem) =>
      selectedServices.includes(lineItem.serviceId)
    ) as ILineItem[];

    setLineItems(filteredLineItems);
  }, [selectedServices]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (event.key === '.' || event.key === ',') {
      event.preventDefault();
    }
  };

  const renderUnitsForService = (serviceId: number) => {
    // @ts-ignore
    const { name, id, unitLabel, allowPartialSale, assetLabel, serviceGroupId } = services.find(
      (service: any) => serviceId === service.id
    );

    return (
      <div key={id} className={classes.serviceContainer}>
        <Grid key={id} item sm={6} md={6} data-cy="unitsForService">
          <div className={classes.serviceName}>
            {name} {serviceGroupId !== RETAIL_SERVICE_GROUP_ID ? '(Future Use)' : null}
          </div>
          <TextField
            data-cy="currentUseUnitsForService"
            inputProps={{
              min: '0',
              max: '500',
              onKeyDown: (e) => {
                onKeyDown(e);
              },
            }}
            label={getServiceLabel(allowPartialSale, unitLabel, assetLabel)}
            value={lineItems.find((lineItem) => lineItem.serviceId === serviceId)?.quantity}
            type="number"
            variant="outlined"
            onChange={(e) => {
              if (+e.target.value <= MAX_SERVICE_UNITS) {
                const units = e.target.value;
                const newLineItems = [...lineItems];
                const lineItemIndex = newLineItems.findIndex((lineItem) => lineItem.serviceId === serviceId);
                newLineItems[lineItemIndex].quantity = +units;
                newLineItems[lineItemIndex].currentUseQuantity =
                  serviceGroupId === RETAIL_SERVICE_GROUP_ID ? +units : 0;
                newLineItems[lineItemIndex].futureUseQuantity = serviceGroupId === RETAIL_SERVICE_GROUP_ID ? 0 : +units;
                setLineItems(newLineItems);
              }
            }}
            className={classes.textField}
            fullWidth
          />
        </Grid>
      </div>
    );
  };

  return (
    <CardContent className={classes.card}>
      <div className={classes.title}>Units for chosen services</div>
      <div>{selectedServices.map((serviceId) => renderUnitsForService(serviceId))}</div>
    </CardContent>
  );
};

export default ProductUnitsStep;
