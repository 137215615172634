/* eslint-disable no-param-reassign */
import { useInfiniteQuery } from 'react-query';

import { SUPPLY_RECEIPTS } from '../../constants/reactQuery.keys';
import { IMetaPagination } from '../../interfaces/global.interfaces';
import { ISupplyReceipt } from '../../interfaces/ISupplyReceipt';
import SupplyReceipts from '../../services/SupplyReceipts';

// This hook receive practitionerId and page, per page will concat the new array with the previous ones
export function usePractitionerSupplyReceiptsPagination(params: { practitionerId: number }) {
  const query = useInfiniteQuery<{ meta?: IMetaPagination; supplyReceipts?: ISupplyReceipt[] }>(
    [SUPPLY_RECEIPTS, params.practitionerId],
    ({ pageParam = 1 }) => SupplyReceipts.practitionerSupplyReceipts({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.meta?.currentPage && lastPage?.meta?.currentPage < lastPage?.meta?.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );
  const newSupplyReceipts =
    query?.data?.pages
      ?.map(({ supplyReceipts }) => supplyReceipts)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, supplyReceipts: newSupplyReceipts } };
}
