import React, { useState } from 'react';
import { Avatar, Box, Divider, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { EHRButton, EHRTypography } from 'src/components/ui/v1';
import { ActiveChip } from 'src/components/common/Global/CustomChip';
import { DEFAULT_AVATAR_IMAGE } from '../../../constants/general.constants';
import { IMedspaAdminList, IMedspaAdminListEntry } from '../../../interfaces/IMedspaAdminList';
import useStyles from './index.styles';
import { Button } from '../../../components/common/Button';
import { IUserGroup } from '../../../interfaces/IUserGroup';
import { UpsertMedspaAdminModal } from './UpsertMedspaAdminModal';
import useStylesMedspaSettings from '../index.styles';

interface AdminsListProps {
  adminsList: IMedspaAdminList;
  userGroup: IUserGroup | undefined;
}

const AdminsList: React.FC<AdminsListProps> = ({ adminsList, userGroup }) => {
  const classes = useStyles();
  const classesMedspaSettings = useStylesMedspaSettings();
  const { userId } = useSelector(({ auth }: any) => auth);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<IMedspaAdminListEntry>();

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1rem">
        <EHRTypography variant="h6" dataCy="admin-tab-admin-list-title">
          Admins
        </EHRTypography>
        <EHRButton
          text="New Admin"
          color="primary"
          dataCy="btn-new-admin"
          onClick={() => {
            setSelectedAdmin(undefined);
            setOpenModal(true);
          }}
        />
      </Box>
      <Box className={classesMedspaSettings.medspaSettingsContainer}>
        <Grid item xs={12}>
          <List className={classes.adminList}>
            {adminsList?.map((admin, index, array) => (
              <>
                <ListItem key={admin.id} style={{ padding: 0 }}>
                  <Avatar
                    className={classes.avatar}
                    alt={`${admin.firstName}'s avatar`}
                    src={admin.profileImageUrl || DEFAULT_AVATAR_IMAGE}
                    data-cy="practAvatarLetter"
                  />
                  <ListItemText
                    primary={
                      <Box display="flex" gridGap="1rem" alignItems="center">
                        <EHRTypography variant="content" dataCy={`admin-list-${admin.id}`}>
                          {`${admin.firstName} ${admin.lastName}`}
                        </EHRTypography>
                        <ActiveChip active={admin.active} />
                      </Box>
                    }
                    secondary={
                      <EHRTypography variant="description" dataCy={`admin-list-${admin.email}`}>
                        {admin.email}
                      </EHRTypography>
                    }
                  />
                  {(userId === admin.id || userId === userGroup?.ownerId) && (
                    <Button
                      title="Edit"
                      className={classes.editAdmin}
                      onClick={() => {
                        setSelectedAdmin(admin);
                        setOpenModal(true);
                      }}
                      type="button"
                    />
                  )}
                </ListItem>
                {index !== array.length - 1 && (
                  <Box margin="0.5rem 0">
                    <Divider />
                  </Box>
                )}
              </>
            ))}
          </List>
        </Grid>
      </Box>
      <UpsertMedspaAdminModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        userGroupAdmin={selectedAdmin}
        linkedPractitioner={selectedAdmin?.linkedAccounts && selectedAdmin.linkedAccounts[0]}
      />
    </Box>
  );
};

export default AdminsList;
