import { IFeature } from '../interfaces/IFeature';
import Api from './Api';

const BASE_PATH = '/v3';

export const getFeatures = async (): Promise<IFeature[]> => {
  const { features } = await Api.get(`${BASE_PATH}/features`);
  return features;
};

export const getFeaturesByRole = async (): Promise<{ [key: string]: string[] }> => {
  const { featuresByRole } = await Api.get(`${BASE_PATH}/features_by_role`);
  return featuresByRole;
};
