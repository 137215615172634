/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useQueryClient } from 'react-query';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { REQUEST_STATUS_OPTIONS } from 'src/constants/newPhotoRequest.constants';
import { useSelector } from 'react-redux';
import Page from 'src/components/common/Page';
import { CUSTOMERS_LIST, useCustomPatients } from '../../hooks/queries/useCustomPatients';
import { PHYSICIAN_DASHBOARD_KEY, USER_TYPES } from '../../constants/general.constants';
import compile from '../../utils/toastMessagesCompiler';
import { PortraitTable } from '../../components/common/TableNew/Table';
import { MedicalProfileUpdated, NewMessages, PhotoRequested, UnseenPhotos } from './ProviderPatientsList';
import { useStyles } from './PhysicianPatientsList.styles';
import { SelectCustom } from '../../components/DashboardAdministrator/Inventory/supplyReceipt.styles';
import { IPractitionerForPhysician } from '../../interfaces/IPractitioner';
import { STATES } from '../../constants/medicalProfile.constants';
import { usePractitionerForPhysicians } from '../../hooks/practitioner/usePractitioner';
import formatDate, { DATETIME_FORMAT } from '../../utils/formatDate';
import { usePhysicianNavigate } from '../../hooks/customer/usePhysicianNavigate';
import { IRoles } from '../../interfaces/IRoles.interfaces';
import { useCustomRoles } from '../../hooks/queries/useCustomRoles';
import { MEDICAL_LICENSES } from '../AccountSetting/MoreDetails';
import { hasAccessTo } from '../../utils/auth.utils';
import { MARK_CUSTOMER_IMPORTANT_UPDATE } from '../../constants/actions.constants';
import { useMarkCustomerImportant } from '../../hooks/customer/useMarkCustomerImportant';
import { PENDING_RECLEAR } from '../../constants/scheduler.constants';

const allInitialFilters = {
  group: 'all',
  view: 'all',
  filter: 'all',
  license: '',
  search: '',
  sortBy: '',
  sortDirection: '',
  practitionerId: '',
  state: '',
  roleId: '',
  marked: '',
};

const ignoredInitialFilters = {
  group: 'ignored',
  view: 'all',
  filter: 'all',
  license: '',
  search: '',
  sortBy: '',
  sortDirection: '',
  practitionerId: '',
  state: '',
  roleId: '',
  marked: '',
};

export const PATIENT_COLUMNS = [
  { id: 'star', title: '' },
  { id: 'name', title: 'Name' },
  { id: 'id', title: 'ID' },
  { id: 'practitioner_id', title: 'Provider' },
  { id: 'appointment_type', title: 'Appt. Type' },
  { id: 'updates', title: 'Updates' },
  { id: 'last_visited_at', title: 'Recently viewed', sort: true },
  { id: 'visits', title: 'Visits', sort: true },
  { id: 'customer_views.created_at', title: 'Registered at', sort: true },
];

export const PatientRow = ({ patient }: { patient: any }) => {
  const classes = useStyles();
  const navigateToPatient = usePhysicianNavigate(patient.id);
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const { REJECTED, REQUESTED } = REQUEST_STATUS_OPTIONS;
  const isPhotoRequested = [REJECTED, REQUESTED].includes(patient?.lastPhotoRequestStatus);
  const { StarColumn, isLoading } = useMarkCustomerImportant(patient.id, patient);

  return (
    <TableRow className={classes.patientRow} key={patient.id}>
      <TableCell>
        {hasAccessTo(MARK_CUSTOMER_IMPORTANT_UPDATE, permissions) &&
          (isLoading ? <CircularProgress size={20} /> : <StarColumn />)}
      </TableCell>
      <TableCell onClick={navigateToPatient}>
        {patient.firstName} {patient.lastName}
      </TableCell>
      <TableCell onClick={navigateToPatient}>{patient.id}</TableCell>
      <TableCell onClick={navigateToPatient}>{patient.providerLabeling}</TableCell>
      <TableCell onClick={navigateToPatient}>{patient.appointmentType}</TableCell>
      <TableCell onClick={navigateToPatient}>
        {patient.unreadMessagesCount > 0 && <NewMessages count={patient.unreadMessagesCount} />}
        {patient.unseenPhotosCount > 0 && <UnseenPhotos count={patient.unseenPhotosCount} />}
        {patient.needsDoctorReview && <MedicalProfileUpdated />}
        {(patient.hasRequestedPhotos || isPhotoRequested) && <PhotoRequested />}
      </TableCell>
      <TableCell onClick={navigateToPatient}>{formatDate(patient.lastVisitedAt, DATETIME_FORMAT)}</TableCell>
      <TableCell onClick={navigateToPatient}>{patient.visits}</TableCell>
      <TableCell onClick={navigateToPatient}>{formatDate(patient.createdAt)}</TableCell>
    </TableRow>
  );
};

export const PatientFilters = ({
  search,
  setSearch,
  isFetching,
  patientState,
  setPatientState,
  providers,
  providerId,
  setProviderId,
  roles,
  roleId,
  setRoleId,
  license,
  setLicense,
  patientType,
  setPatientType,
  removeRequiresUpdate,
  setRemoveRequiresUpdate,
}: {
  search: string;
  setSearch: (search: string) => void;
  isFetching: boolean;
  patientState: string;
  setPatientState: (state: string) => void;
  providers: IPractitionerForPhysician[];
  providerId: string | number;
  setProviderId: (provider: string | number) => void;
  roles: IRoles[];
  roleId: string | number;
  setRoleId: (roleId: string | number) => void;
  license: string;
  setLicense: (newLicense: string) => void;
  patientType: string;
  setPatientType: (newType: string) => void;
  removeRequiresUpdate: boolean;
  setRemoveRequiresUpdate: (newSetting: boolean) => void;
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.flexRow} ${classes.filterContainer}`}>
        <FormControl size="small" variant="outlined">
          <InputLabel htmlFor="search" color="secondary" className={classes.search}>
            Search
          </InputLabel>
          <OutlinedInput
            data-cy="providerSearchInput"
            id="search"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton style={{ padding: 0 }}>
                  {isFetching ? (
                    <CircularProgress style={{ color: 'black' }} size={20} />
                  ) : (
                    <SearchIcon style={{ color: 'black' }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>

        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="role" color="secondary" className={classes.search}>
            Role
          </InputLabel>
          <SelectCustom
            data-cy="from"
            name="role"
            value={roleId}
            onChange={(e) => setRoleId(e.target.value as string)}
            label="Provider"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {roles.map((role) => (
              <MenuItem value={role.id} key={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </SelectCustom>
        </FormControl>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="licenseSearch" color="secondary" className={classes.search}>
            License
          </InputLabel>
          <SelectCustom
            data-cy="from"
            name="license"
            value={license}
            onChange={(e) => setLicense(e.target.value as string)}
            label="License"
          >
            <MenuItem value={undefined}>
              <em>All</em>
            </MenuItem>
            {MEDICAL_LICENSES.map((licenseType) => (
              <MenuItem value={licenseType} key={licenseType}>
                {licenseType}
              </MenuItem>
            ))}
          </SelectCustom>
        </FormControl>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="provider" color="secondary" className={classes.search}>
            Provider
          </InputLabel>
          <SelectCustom
            data-cy="from"
            name="provider"
            value={providerId}
            onChange={(e) => setProviderId(e.target.value as string)}
            label="Provider"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {providers.map((provider) => (
              <MenuItem value={provider.id} key={provider.id}>
                {provider.fullName}
              </MenuItem>
            ))}
          </SelectCustom>
        </FormControl>
        <FormControl size="small" className={classes.formControl} variant="outlined">
          <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>State</InputLabel>
          <SelectCustom
            data-cy="from"
            name="state"
            value={patientState}
            onChange={(e) => setPatientState(e.target.value as string)}
            label="State"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {STATES.map((s: string) => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </SelectCustom>
        </FormControl>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="patientType" color="secondary" className={classes.search}>
            Patient
          </InputLabel>
          <SelectCustom
            data-cy="from"
            name="patientType"
            value={patientType}
            onChange={(e) => setPatientType(e.target.value as string)}
            label="patientType"
          >
            <MenuItem value={undefined}>
              <em>All</em>
            </MenuItem>
            <MenuItem value="important">Important</MenuItem>
          </SelectCustom>
        </FormControl>
      </div>
      <div className={classes.removeRequiresUpdateContainer}>
        <FormControlLabel
          label="Remove Requires Update"
          control={
            <Checkbox
              color="primary"
              checked={removeRequiresUpdate}
              onChange={() => setRemoveRequiresUpdate(!removeRequiresUpdate)}
              name="remove_requires_update"
            />
          }
        />
      </div>
    </>
  );
};

export const PhysicianPatientsList = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [allSearch, setAllSearch] = useState<string>('');
  const [allPatientType, setAllPatientType] = useState<string>('');
  const [allProviderId, setAllProviderId] = useState<string | number>('');
  const [allRoleId, setAllRoleId] = useState<string | number>('');
  const [allPatientState, setAllPatientState] = useState<string>('');
  const [allLicense, setAllLicense] = useState<string>('');
  const [tab, setTab] = useState<string>('all');
  const [allFilters, setAllFilters] = useState<any>(allInitialFilters);
  const [ignoredFilters, setIgnoredFilters] = useState<any>(ignoredInitialFilters);
  const [ignoredSearch, setIgnoredSearch] = useState<string>('');
  const [ignoredProviderId, setIgnoredProviderId] = useState<string | number>('');
  const [ignoredRoleId, setIgnoredRoleId] = useState<string | number>('');
  const [ignoredPatientState, setIgnoredPatientState] = useState<string>('');
  const [ignoredLicense, setIgnoredLicense] = useState<string>('');
  const [ignoredPatientType, setIgnoredPatientType] = useState<string>('');
  const [removeRequiresUpdate, setRemoveRequiresUpdate] = useState<boolean>(false);
  const { data: practitioners = [], isLoading: isLoadingProviders } = usePractitionerForPhysicians({});
  const { data: roles, isLoading: isLoadingRoles } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });

  // Ignored Patients
  const {
    results: ignoredResults,
    isLoading: ignoredIsLoading,
    isError: ignoredIsError,
    fetchNextPage: ignoredFetchNextPage,
    hasNextPage: ignoredHasNextPage,
    isFetching: ignoredIsFetching,
  } = useCustomPatients(PHYSICIAN_DASHBOARD_KEY, ignoredFilters);
  const ignoredPatients = ignoredResults?.pages.map(({ customers = [] }) => customers).flat() || [];

  // All Patients
  const {
    results: allResults,
    isLoading: allIsLoading,
    isError: allIsError,
    fetchNextPage: allFetchNextPage,
    hasNextPage: allHasNextPage,
    isFetching: allIsFetching,
  } = useCustomPatients(PHYSICIAN_DASHBOARD_KEY, allFilters);
  const allPatients = allResults?.pages.map(({ customers = [] }) => customers).flat() || [];

  useEffect(() => {
    setAllFilters({
      ...allFilters,
      ...{
        search: allSearch,
        practitionerId: allProviderId,
        state: allPatientState,
        roleId: allRoleId,
        license: allLicense,
        excludedStatus: removeRequiresUpdate ? PENDING_RECLEAR : '',
        marked: allPatientType ? true : '',
      },
    });
  }, [allSearch, allProviderId, allPatientState, allRoleId, allLicense, removeRequiresUpdate, allPatientType]);

  useEffect(() => {
    setIgnoredFilters({
      ...ignoredFilters,
      ...{
        search: ignoredSearch,
        practitionerId: ignoredProviderId,
        state: ignoredPatientState,
        roleId: ignoredRoleId,
        license: ignoredLicense,
        excludedStatus: removeRequiresUpdate ? 'pending_reclear' : '',
        marked: ignoredPatientType ? true : '',
      },
    });
  }, [
    ignoredSearch,
    ignoredProviderId,
    ignoredPatientState,
    ignoredRoleId,
    ignoredLicense,
    removeRequiresUpdate,
    ignoredPatientType,
  ]);

  useEffect(() => {
    queryClient.invalidateQueries([CUSTOMERS_LIST]);
  }, []);

  const doIgnoredSortBy = (column: string, direction: string) => {
    setIgnoredFilters({ ...ignoredFilters, ...{ sortBy: column, sortDirection: direction } });
  };

  const doAllSortBy = (column: string, direction: string) => {
    setAllFilters({ ...allFilters, ...{ sortBy: column, sortDirection: direction } });
  };

  return (
    <Page title="Patients">
      <div className={classes.physicianPage}>
        <div className={classes.patientsTabs}>
          <p
            role="button"
            className={`${tab === 'all' ? classes.activeTabItem : classes.tabItem} ${classes.mr3}`}
            onClick={() => setTab('all')}
          >
            All Patients ({allIsLoading ? '...' : allResults?.pages?.[0]?.meta?.totalCount})
          </p>
          <p
            role="button"
            className={tab === 'ignored' ? classes.activeTabItem : classes.tabItem}
            onClick={() => setTab('ignored')}
          >
            Ignored Patients ({ignoredIsLoading ? '...' : ignoredResults?.pages?.[0]?.meta?.totalCount})
          </p>
        </div>
        {tab === 'ignored' && (
          <>
            {!isLoadingProviders && !isLoadingRoles && (
              <PatientFilters
                providers={practitioners}
                search={ignoredSearch}
                setSearch={setIgnoredSearch}
                patientState={ignoredPatientState}
                setPatientState={setIgnoredPatientState}
                providerId={ignoredProviderId}
                setProviderId={setIgnoredProviderId}
                isFetching={ignoredIsFetching}
                roles={roles}
                roleId={ignoredRoleId}
                setRoleId={setIgnoredRoleId}
                license={ignoredLicense}
                setLicense={setIgnoredLicense}
                patientType={ignoredPatientType}
                setPatientType={setIgnoredPatientType}
                removeRequiresUpdate={removeRequiresUpdate}
                setRemoveRequiresUpdate={setRemoveRequiresUpdate}
              />
            )}
            <PortraitTable
              columns={PATIENT_COLUMNS}
              sortBy={ignoredFilters.sortBy}
              sortDirection={ignoredFilters.sortDirection}
              onSortChange={doIgnoredSortBy}
              hasNextPage={ignoredHasNextPage}
              fetchNextPage={ignoredFetchNextPage}
              data={ignoredPatients}
              rowComponent={(row) => <PatientRow patient={row} />}
              errorMessage={compile('generic.error_message', { action: 'fetching', element: 'patients' })}
              infiScroll
              isLoading={ignoredIsLoading}
              isFetching={ignoredIsFetching}
              isError={ignoredIsError}
              tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
            />
          </>
        )}
        {tab === 'all' && (
          <>
            {!isLoadingProviders && !isLoadingRoles && (
              <PatientFilters
                providers={practitioners}
                search={allSearch}
                setSearch={setAllSearch}
                patientState={allPatientState}
                setPatientState={setAllPatientState}
                providerId={allProviderId}
                setProviderId={setAllProviderId}
                isFetching={allIsFetching}
                roles={roles}
                roleId={allRoleId}
                setRoleId={setAllRoleId}
                license={allLicense}
                setLicense={setAllLicense}
                patientType={allPatientType}
                setPatientType={setAllPatientType}
                removeRequiresUpdate={removeRequiresUpdate}
                setRemoveRequiresUpdate={setRemoveRequiresUpdate}
              />
            )}
            <PortraitTable
              columns={PATIENT_COLUMNS}
              sortBy={allFilters.sortBy}
              sortDirection={allFilters.sortDirection}
              onSortChange={doAllSortBy}
              hasNextPage={allHasNextPage}
              fetchNextPage={allFetchNextPage}
              data={allPatients}
              rowComponent={(row) => <PatientRow patient={row} />}
              errorMessage={compile('generic.error_message', { action: 'fetching', element: 'patients' })}
              infiScroll
              isLoading={allIsLoading}
              isFetching={allIsFetching}
              isError={allIsError}
              tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
            />
          </>
        )}
      </div>
    </Page>
  );
};
