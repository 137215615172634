import * as yup from 'yup';
import {
  ADDRESS,
  CITY,
  NAME,
  NAME_MAX,
  STATE,
  PROVIDER_ID,
  LATITUDE,
  LONGITUDE,
  CALENDAR_ID,
  ACUITY_LOCATION,
  ACUITY_TIMEZONE,
} from '../../../constants/schemaForm.constants';

export const schemaForm = yup.object().shape({
  customAddress: yup.string().required(ADDRESS),
  city: yup.string().required(CITY),
  name: yup.string().required(NAME).max(28, NAME_MAX),
  state: yup.string().required(STATE),
  providerId: yup.string().required(PROVIDER_ID),
  latitude: yup.string().required(LATITUDE),
  longitude: yup.string().required(LONGITUDE),
  calendarId: yup.string().required(CALENDAR_ID),
  timezone: yup.string().required(ACUITY_TIMEZONE),
});

export const schemaFormEdit = yup.object().shape({
  customAddress: yup.string().required(ADDRESS),
  acuityLocation: yup.string().required(ACUITY_LOCATION),
  city: yup.string().required(CITY),
  name: yup.string().required(NAME).max(28, NAME_MAX),
  state: yup.string().required(STATE),
  latitude: yup.string().required(LATITUDE),
  longitude: yup.string().required(LONGITUDE),
  timezone: yup.string().required(ACUITY_TIMEZONE),
});
