import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import Page from 'src/components/common/Page';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import ProductIdentifiers from '../../common/ProductIdentifiers/ProductIdentifiers';
import { dispatch } from '../../../rematch';

const breadcrumbStyles = {
  fontFamily: 'Messina Sans Regular',
  fontSize: '0.875rem',
  color: '#12574d',
  cursor: 'default',
};

const PurchaseOrderAssets = () => {
  const { serviceId, purchaseId, id }: any = useParams();
  const { purchaseOrder } = useSelector((store: any) => store.purchaseOrders);
  const [service, setService] = useState({ name: '', assets: [] });

  useEffect(() => {
    if (isEmpty(purchaseOrder)) {
      dispatch({
        type: 'purchaseOrders/getPurchaseOrder',
        payload: { purchaseOrderId: purchaseId },
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(purchaseOrder)) {
      const { purchaseOrderItems } = purchaseOrder;
      const selectedPurchaseOrderItem = purchaseOrderItems?.find(
        (item: any) => item.serviceId === +serviceId && item.id === +id
      );
      const { assets, name } = selectedPurchaseOrderItem;

      const selectedService = {
        name,
        assets,
      };

      setService(selectedService);
    }
  }, [purchaseOrder]);

  const Breadcrumb = (
    <Box mb="17px">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Inventory" />
        <BreadcrumbText text="Purchase orders" linkTo="/administrator/purchase-orders" />
        <Typography color="textPrimary" style={breadcrumbStyles}>
          Products Identifiers
        </Typography>
      </BreadcrumbsContainer>
    </Box>
  );

  return (
    <Page title="Product Identitifies">
      <Box width="100%">
        {Breadcrumb}
        <ProductIdentifiers hideDetailColors aggregatedField="initialNumberOfUnits" service={service} editionDisabled />
      </Box>
    </Page>
  );
};

export default PurchaseOrderAssets;
