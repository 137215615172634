import { DayView } from '@devexpress/dx-react-scheduler-material-ui';
import { styled } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { groupingStyles } from './groupingStyles';

// @ts-expect-error
const StyledDayViewDayScaleCell = styled(DayView.DayScaleCell)(groupingStyles);

const DayViewDayScaleCell = (props: DayView.DayScaleCellProps) => {
  const { groupingInfo, ...restProps } = props;
  const groupId = groupingInfo?.[0].id;
  return (
    <StyledDayViewDayScaleCell
      className={classNames(`day-view-scale-cell-group-${groupId}`)}
      groupingInfo={groupingInfo}
      {...restProps}
    />
  );
};

export { DayViewDayScaleCell };
