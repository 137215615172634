import { Collapse, FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import { Edit as EditIcon, Replay as ReplayIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { convertCmToFtInch, convertFtInchToCm } from 'src/utils/patient.util';
import { Button } from '../../common/Button';

const useStyles = makeStyles(() => ({
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    color: '#000000',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

export const WeightFields = ({ interestedInWeightLoss, weightValue, heightValue, onSave }: any) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(true);
  const [weight, setWeight] = useState<string>();
  const [feet, setFeet] = useState<string>();
  const [inches, setInches] = useState<string>();
  const { ft, inch } = convertCmToFtInch(heightValue);

  useEffect(() => {
    setIsChecked(interestedInWeightLoss === 'yes');
    setWeight(weightValue);
    setFeet(ft.toString());
    setInches(inch.toString());
  }, [interestedInWeightLoss, weightValue, heightValue]);

  const onEdit = () => {
    if (!editMode) {
      setIsChecked(!!interestedInWeightLoss);
      setWeight(weightValue);
      setFeet(ft.toString());
      setInches(inch.toString());
    }

    setEditMode(!editMode);
  };

  const onSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  const isButtonDisabled = (): boolean =>
    isChecked ? !weight || !feet || !inches : !!interestedInWeightLoss === isChecked;

  const handleOnSave = async () => {
    const feetTall = parseFloat(feet as string);
    const inchesTall = parseFloat(inches as string);
    await onSave({
      heightInCms: +(convertFtInchToCm(feetTall, inchesTall) || 0),
      weightInLbs: +(weight || 0),
      interestedInWeightLoss: isChecked ? 'yes' : 'no',
    });
    setEditMode(!editMode);
  };

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.field}>
          Are you interested in weight loss?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              label={isChecked ? 'Yes' : 'No'}
              control={<Switch onChange={onSwitchChange} checked={isChecked} disabled={editMode} />}
            />
            {editMode ? <EditIcon onClick={onEdit} /> : <ReplayIcon onClick={onEdit} />}
          </div>
        </div>
        {editMode && isChecked ? (
          <span className={classes.label}>
            {weight} lbs
            <br />
            {feet} ft {inches} in
          </span>
        ) : null}
      </Grid>
      <Grid item xs={5} md={5}>
        <Collapse in={!editMode && isChecked} timeout="auto">
          <NumberFormat
            value={feet}
            name="heightInFeet"
            fullWidth
            allowNegative={false}
            autoComplete="off"
            customInput={TextField}
            label="feet"
            placeholder="ft"
            variant="outlined"
            onChange={(e) => {
              setFeet(e.currentTarget.value);
            }}
            multiline
          />
        </Collapse>
      </Grid>
      <Grid item xs={5} md={5}>
        <Collapse in={!editMode && isChecked} timeout="auto">
          <NumberFormat
            value={inches}
            name="heightInInch"
            fullWidth
            allowNegative={false}
            autoComplete="off"
            customInput={TextField}
            label="inches"
            placeholder="in"
            variant="outlined"
            onChange={(e) => {
              setInches(e.currentTarget.value);
            }}
            multiline
          />
        </Collapse>
      </Grid>
      <Grid item xs={5} md={5}>
        <Collapse in={!editMode && isChecked} timeout="auto">
          <NumberFormat
            value={weight}
            name="weightInLbs"
            autoComplete="off"
            fullWidth
            allowNegative={false}
            label="lbs"
            placeholder="lbs"
            customInput={TextField}
            variant="outlined"
            onChange={(e) => {
              setWeight(e.currentTarget.value);
            }}
            multiline
          />
        </Collapse>
      </Grid>
      <Grid item xs={2} md={2}>
        <Collapse in={!editMode} timeout="auto">
          <Button
            title="Save"
            style={{ minWidth: '100px', color: '#ffffff', backgroundColor: '#12574d', marginRight: '0' }}
            onClick={handleOnSave}
            disabled={isButtonDisabled()}
          />
        </Collapse>
      </Grid>
    </>
  );
};
