import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Typography,
  Tooltip,
  CircularProgress,
  DialogContentText,
  Chip,
} from '@material-ui/core';

import { Visibility, Edit, DeleteOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { dispatch } from '../../rematch';

import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { ShortMultipleSkeleton } from '../../components/common/LoadingSkeleton';
import Messaging from '../../services/CareMessage';
import { ICustomCareMessage } from '../../interfaces/ICustomCareMessage';
import compile from '../../utils/toastMessagesCompiler';

import CustomDialog from './components/CustomDialog';
import useStyles from './index.styles';

import { CUSTOM_CARE_MESSAGE, useCustomCareMessages } from '../../hooks/useCustomCareMessages';
import {
  PRACTITIONER_MESSAGING_CUSTOMIZE_PATH,
  PRACTITIONER_PATIENT_ALL_PATH,
  PRACTITIONER_PATIENT_CLEARED_PATH,
} from '../../routes/practitionerRoutes';
import { hasAccessTo } from '../../utils/auth.utils';
import { GFE_REQUEST } from '../../constants/actions.constants';

const { removeCustomCareMessages } = Messaging;

const PAGE_TITLE = 'Care message templates';
const ERROR_GETTING_MESSAGES = compile('generic.error_message', { action: 'getting', element: 'the messages' });
const ERROR_REMOVING_MESSAGE = compile('generic.error_message', { action: 'removing', element: 'the message' });
const SUCESS_REMOVING_MESSAGE = compile('generic.success_message', { action: 'removed', element: 'Message' });

const MessagingIndex = () => {
  const history = useHistory();
  const classes = useStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const queryClient = useQueryClient();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentCareMessage, setCurrentCareMessage] = useState<ICustomCareMessage>();

  const { data: customCareMessagesCollection = [], isLoading, isFetching, isError } = useCustomCareMessages();

  const mutation = useMutation(removeCustomCareMessages);

  const navigateHome = () => {
    history.push(
      hasAccessTo(GFE_REQUEST, permissions) ? PRACTITIONER_PATIENT_CLEARED_PATH : PRACTITIONER_PATIENT_ALL_PATH
    );
  };

  const goToCustomizeMessage = (currentCustomCareMessage?: ICustomCareMessage, readOnly: boolean = false) => {
    history.push({
      pathname: PRACTITIONER_MESSAGING_CUSTOMIZE_PATH,
      state: {
        customCareMessages: currentCustomCareMessage || {},
        readOnly,
      },
    });
  };

  const removeCustomMessage = async (id: number) => {
    try {
      await mutation.mutateAsync({
        id,
      });
      queryClient.invalidateQueries([CUSTOM_CARE_MESSAGE]);

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCESS_REMOVING_MESSAGE,
          type: 'success',
        },
      });
    } catch (removingError) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_REMOVING_MESSAGE,
          type: 'error',
        },
      });
    } finally {
      setDialogOpen(false);
    }
  };

  const showConfirmDialog = (careMessage: ICustomCareMessage) => {
    setDialogOpen(true);
    setCurrentCareMessage(careMessage);
  };

  const globalMessagesComponent = customCareMessagesCollection
    .filter((el) => el.isGlobal)
    .map((service) => {
      const labelId = `service-group-${service.serviceGroupId}`;
      return (
        <ListItem key={labelId} dense>
          <ListItemText
            id={labelId}
            primary={
              <Typography variant="h6" component="span">
                Global template
              </Typography>
            }
            secondary={
              <Typography variant="subtitle2">
                Primary template used for all service groups without custom message below
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <Tooltip title="Customize global message">
              <Button color="primary" disabled={isFetching} onClick={() => goToCustomizeMessage(service)}>
                <Edit />
              </Button>
            </Tooltip>
            <Tooltip title="Preview">
              <Button onClick={() => goToCustomizeMessage(service, true)}>
                <Visibility />
              </Button>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });

  const renderServicesGroups = customCareMessagesCollection
    // .filter((el) => !!el.isGlobal === false)
    .sort((a, b) => (a?.name?.toUpperCase() > b?.name?.toUpperCase() ? 1 : -1))
    .map((careMessage: ICustomCareMessage) => {
      const labelId = `service-group-${careMessage.serviceGroupId}`;
      return (
        <ListItem key={labelId} role={undefined} dense>
          <ListItemText
            id={labelId}
            primary={
              <h3>
                {careMessage.name}
                {careMessage.serviceGroupAppliedNames.map((name) => (
                  <Chip label={name} style={{ marginLeft: '2px', marginRight: '2px' }} />
                ))}
              </h3>
            }
          />
          <ListItemSecondaryAction>
            {careMessage.practitionerId && (
              <Tooltip title="Use global message">
                <Button color="default" disabled={isFetching} onClick={() => showConfirmDialog(careMessage)}>
                  <DeleteOutline />
                </Button>
              </Tooltip>
            )}
            {careMessage.practitionerId && (
              <Tooltip title="Customize">
                <Button color="primary" disabled={isFetching} onClick={() => goToCustomizeMessage(careMessage)}>
                  <Edit />
                </Button>
              </Tooltip>
            )}
            <Tooltip title="Preview">
              <Button onClick={() => goToCustomizeMessage(careMessage, true)}>
                <Visibility />
              </Button>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });

  if (isError) {
    return (
      <>
        <NavigationBar title={PAGE_TITLE} onBackButtonClick={navigateHome} />
        <article style={{ padding: '12px', width: 'inherit', backgroundColor: '#e3e3e3' }}>
          <Card className={classes.root} variant="outlined">
            <CardContent style={{ padding: '12px 15px' }}>
              <Alert severity="error">{ERROR_GETTING_MESSAGES}</Alert>
            </CardContent>
          </Card>
        </article>
      </>
    );
  }

  return (
    <>
      <NavigationBar title={PAGE_TITLE} onBackButtonClick={navigateHome} />
      <article style={{ padding: '12px', width: 'inherit', backgroundColor: '#e3e3e3' }}>
        <Card className={classes.root} variant="outlined">
          {isLoading ? (
            <CardContent style={{ padding: '12px 15px' }}>
              <ShortMultipleSkeleton length={4} />
            </CardContent>
          ) : (
            <CardContent style={{ padding: '12px 15px' }}>
              <List>
                <ListItem key="labelId_title" dense>
                  <ListItemText
                    id="labelId_title"
                    primary={
                      <>
                        <Typography variant="h5" component="span">
                          Pre and post care messages
                        </Typography>
                        {(isLoading || isFetching) && <CircularProgress size={20} />}
                        <Button
                          style={{ float: 'inline-end', backgroundColor: '#e7eeed' }}
                          onClick={() => goToCustomizeMessage()}
                        >
                          New
                        </Button>
                      </>
                    }
                  />
                </ListItem>

                <Divider />
                {globalMessagesComponent}
                {renderServicesGroups}
              </List>
            </CardContent>
          )}
          <CustomDialog
            open={dialogOpen}
            title="Do you want to remove your customized mesage?"
            content={
              <DialogContentText>
                Are you sure you want to remove your customized messages for:{' '}
                <Typography color="primary" variant="h6" component="span">
                  {currentCareMessage?.name}
                </Typography>
              </DialogContentText>
            }
            loading={mutation.isLoading}
            okText="Yes, use global messages"
            onOk={() => currentCareMessage && removeCustomMessage(currentCareMessage.id)}
            cancelText="I'm not Sure!"
            onCancel={() => setDialogOpen(false)}
          />
        </Card>
      </article>
    </>
  );
};

export default MessagingIndex;
