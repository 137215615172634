import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers';
import useSuppliers from 'src/hooks/queries/useSuppliers';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import IServices from 'src/interfaces/IServices';
import { useCreateSpecialOffer, useUpdateSpecialOffer } from 'src/hooks/queries/useSpecialOffers';
import { ISpecialOffer } from 'src/services/SpecialOffers';
import { useAllProducts } from 'src/hooks/queries/useProducts';
import compile from '../../utils/toastMessagesCompiler';
import { dispatch } from '../../rematch';
import { NewTextField, useStyles } from './specialsModal.styles';
import OfferDiscountSection from './OfferDiscountSection';
import { specialOffersSchemaForm } from './schemaForm';
import { dateFormat } from './utils';

interface OfferDiscountForm {
  orderProductId: string;
  quantity: number;
  discount: number;
}
export interface SpecialOfferFormData {
  name: string;
  discounts: OfferDiscountForm[];
  supplierIds: number[];
  expirationDate: Moment | null;
  note: string | null;
}

export interface SpecialOfferPostFormData {
  id?: number;
  name: string;
  discounts: OfferDiscountForm[];
  supplierIds: number[] | undefined;
  expirationDate: Moment | null;
  note: string | undefined;
}

const CustomFromDateTextField = (props: any) => (
  <NewTextField {...props} name="fromDate" style={{ marginBottom: '10px' }} fullWidth />
);

interface Props {
  openModal: boolean;
  onClose: () => void;
  selectedSpecial: ISpecialOffer | null;
  setSelectedSpecial: Dispatch<SetStateAction<null>>;
  editMode: boolean;
}

const NewSpecialModal = ({ openModal, onClose, selectedSpecial, setSelectedSpecial, editMode }: Props) => {
  const classes = useStyles();
  const [supplierIds, setSupplierIds] = useState<number[]>();
  const [filteredProducts, setFilteredProducts] = useState<IServices[]>([]);

  const { data: suppliers, isLoading: isLoadingSuppliers, isError: isErrorSuppliers } = useSuppliers();

  const { data: products = [], isLoading: isLoadingProducts } = useAllProducts();
  const { mutateAsync: updateSpecial } = useUpdateSpecialOffer();

  const methods = useForm<SpecialOfferFormData>({
    resolver: yupResolver(specialOffersSchemaForm),
    shouldFocusError: true,
    defaultValues: {
      name: '',
      discounts: [{ orderProductId: '', quantity: 1, discount: 0 }],
      supplierIds: [],
      expirationDate: null,
      note: '',
    },
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = methods;

  const watchStart = watch('expirationDate');

  const mutationCreate = useCreateSpecialOffer();
  const loadingCreate = mutationCreate.isLoading;

  const handleSuppliersChange = (value: number[]) => setSupplierIds(value);

  const resetForm = () => {
    setSelectedSpecial(null);
    setSupplierIds([]);
    reset({
      name: '',
      discounts: [{ orderProductId: '', quantity: 1, discount: 0 }],
      supplierIds: [],
      expirationDate: null,
      note: '',
    });
  };

  const onSubmit = async (dataForm: SpecialOfferFormData) => {
    const dataToCreate: SpecialOfferPostFormData = {
      ...dataForm,
      id: selectedSpecial?.id,
      name: dataForm.name.trim(),
      supplierIds,
      expirationDate: dataForm.expirationDate,
      note: dataForm?.note?.trim(),
    };

    if (selectedSpecial) {
      await updateSpecial(dataToCreate);
    } else {
      await mutationCreate.mutateAsync(dataToCreate);
    }
    resetForm();
    onClose();
  };

  useEffect(() => {
    const fp = products.filter((product) => product?.supplierId && supplierIds?.includes(product.supplierId));
    setFilteredProducts(fp);
  }, [supplierIds]);

  useEffect(() => {
    if (selectedSpecial && editMode) {
      const { name, expirationDate, note, discounts } = selectedSpecial;

      setSupplierIds(discounts.map((d) => d.orderProductSupplierId));

      reset({
        name,
        discounts: discounts.map((d) => ({
          orderProductId: d.orderProductId,
          quantity: d.quantity,
          discount: d.discount,
        })),
        note,
      });

      setValue('expirationDate', moment(expirationDate));
    } else {
      resetForm();
    }
  }, [selectedSpecial]);

  if (isErrorSuppliers) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'loading',
          element: 'suppliers',
        }),
        type: 'error',
        duration: 2500,
      },
    });
  }

  return (
    <Modal open={openModal} onClose={onClose} className={classes.formModal}>
      <Box className={classes.formModalContent}>
        {/* Modal Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center" p="1.5rem">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom className={classes.title}>
              New Special
            </Typography>
          </Box>
          <Button onClick={onClose} className={classes.closeBtn}>
            <CloseIcon />
          </Button>
        </Box>

        <hr className={classes.separator} />

        {/* Modal Body */}
        <Box component="form" onSubmit={handleSubmit(onSubmit)} alignItems="center" p="1.5rem">
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Special Name
              </InputLabel>
              <NewTextField
                fullWidth
                name="name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message || ''}
                onChange={(e) => setValue('name', e.target.value)}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '4px' }}>
              <InputLabel
                color="secondary"
                focused={false}
                htmlFor="suppliers"
                error={!!errors?.supplierIds}
                className={classes.label}
              >
                Suppliers
              </InputLabel>
              <FormControl data-cy="suppliers" size="medium" variant="outlined" className={classes.editGroupSelect}>
                <Select
                  data-cy="suppliers-select"
                  data-testid="suppliers field"
                  labelId="suppliers-select"
                  id="suppliers-select"
                  name="suppliers"
                  onChange={(e) => handleSuppliersChange(e.target.value as number[])}
                  value={supplierIds || []}
                  disabled={isLoadingSuppliers}
                  placeholder="select suppliers"
                  fullWidth
                  multiple
                >
                  {(suppliers || []).map(({ id, name }) => (
                    <MenuItem data-cy="suppliers-select" value={id} key={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <hr className={classes.separator} />

            <OfferDiscountSection
              filteredProducts={filteredProducts}
              isLoadingProducts={isLoadingProducts}
              methods={methods}
            />

            <hr className={classes.separator} />

            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Expiration Date
              </InputLabel>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  variant="dialog"
                  inputVariant="outlined"
                  format={dateFormat}
                  minDate={moment().add(1, 'days')}
                  value={watchStart}
                  error={!!errors?.expirationDate}
                  helperText={errors?.expirationDate?.message}
                  onChange={(date: any) => {
                    setValue('expirationDate', date.format(dateFormat));
                    trigger('expirationDate');
                  }}
                  TextFieldComponent={CustomFromDateTextField}
                  autoOk
                  disableToolbar
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false} htmlFor="note" className={classes.label}>
                Notes (Optional)
              </InputLabel>
              <Controller
                control={control}
                defaultValue=""
                name="note"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="note"
                    fullWidth
                    multiline
                    onChange={onChange}
                    rows={4}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Box height="2rem" />

          {/* Buttons actions */}
          <Box className={classes.footer}>
            <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Box width="100%">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loadingCreate || Object.keys(errors).length > 0 || filteredProducts.length === 0}
                  className={classes.submitButton}
                  fullWidth
                >
                  {loadingCreate ? <CircularProgress size={25} /> : `${editMode ? 'Edit' : 'Add'}`}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewSpecialModal;
