// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#12574d',
    color: 'white !important',
    height: '47px',
    margin: '1rem 0 0 0',
    borderRadius: '4px',
    padding: '20px 40px',
  },
  editSignatureButton: {
    color: '#12574d',
    border: '1px solid #12574d',
    borderRadius: '4px',
    padding: '20px 40px',
  },
  signatureImage: {
    width: '260px',
    padding: '20px',
    border: '1px solid rgba(191, 191, 191, 0.5)',
    borderRadius: '4px',
    marginRight: '20px',
  },
  editSignatureContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0',
  },
  circularProgress: {
    color: '#12574d',
  },
}));
