import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Edit as EditIcon, Replay as ReplayIcon } from '@material-ui/icons';
import { Collapse, FormControl, TextField } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { useCustomMedicalProfile, MEDICAL_PROFILE } from '../../../hooks/queries/useCustomMedicalProfile';
import { formatSkinProfileInfo } from '../../../utils/patient.util';
import { useUpdateMedicalProfile } from '../../../hooks/mutations/useUpdateMedicalProfile';
import { useStyles } from './GoalsNotes.styles';
import { Button } from '../../common/Button';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';

export const GoalsNotes = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { data: medicalProfile, isSuccess: medicalProfileFetchSuccess } = useCustomMedicalProfile(patientId);
  const { goalsNotes: initialGoalsNotes } = formatSkinProfileInfo(medicalProfile || {});
  const [editMode, setEditMode] = useState(false);
  const [goalsNotes, setGoalsNotes] = useState(initialGoalsNotes);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [collapseTranstion, setCollapseTransition] = useState(false);

  const queryClient = useQueryClient();
  const classes = useStyles();

  const toggleEdit = () => {
    if (medicalProfileFetchSuccess) {
      setEditMode(!editMode);
      setGoalsNotes(initialGoalsNotes);
    }
  };

  const updateGoalsNotesMutation = useUpdateMedicalProfile();

  const save = async () => {
    if (medicalProfile) {
      try {
        setDisabledSaveButton(true);
        await updateGoalsNotesMutation.mutateAsync({
          customerId: +patientId,
          medicalProfile,
          payload: { goalsAndNotes: goalsNotes || null },
        });
        setEditMode(false);
        queryClient.invalidateQueries([MEDICAL_PROFILE, patientId]);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Medical Profile',
              action: 'updated',
            }),
          },
        });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'updating',
              element: 'the medical profile',
            }),
            type: 'error',
          },
        });
      } finally {
        setDisabledSaveButton(false);
      }
    }
  };

  const handleTextFieldOnChange = (input: React.ChangeEvent<HTMLInputElement>) => {
    if (input.target.value !== goalsNotes) {
      setDisabledSaveButton(false);
    }
    setGoalsNotes(input.target.value);
  };

  const isSaveButtonDisabled = (): boolean => goalsNotes === initialGoalsNotes || disabledSaveButton;

  const goalsNotesText = () => {
    if (medicalProfileFetchSuccess && medicalProfile) {
      if (goalsNotes !== medicalProfile.goalsAndNotes) {
        setGoalsNotes(medicalProfile.goalsAndNotes);
      }
      return goalsNotes || 'None';
    }

    return '';
  };

  const goalsNotesOnEditText = () => {
    if (editMode && goalsNotes === 'None') {
      return '';
    }
    return goalsNotes;
  };

  useEffect(() => {
    setCollapseTransition(editMode);
  }, [editMode]);

  return (
    <div>
      {editMode ? (
        <>
          <div className={classes.editSection}>
            <div>
              <span>Goals/Notes</span>
            </div>
            <div>
              <ReplayIcon onClick={toggleEdit} />
            </div>
          </div>
          <Collapse in={collapseTranstion} timeout="auto">
            <div className={classes.TextFieldSection}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Edit"
                  multiline
                  rows={4}
                  defaultValue={goalsNotesOnEditText()}
                  variant="outlined"
                  onChange={handleTextFieldOnChange}
                />
              </FormControl>
            </div>
            <div>
              <Button
                title="Save"
                style={{ minWidth: '200px', color: '#ffffff', backgroundColor: '#12574d', marginLeft: 0 }}
                onClick={save}
                disabled={isSaveButtonDisabled()}
              />
            </div>
          </Collapse>
        </>
      ) : (
        <>
          <div className={classes.editSection}>
            <div>
              <span>Goals/Notes</span>
            </div>
            <div>
              <EditIcon onClick={toggleEdit} />
            </div>
          </div>
          <div>
            <span className={classes.label}>{goalsNotesText()}</span>
          </div>
        </>
      )}
    </div>
  );
};
