import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SNACKBAR_SUCCESS } from '../../../constants/general.constants';
import {
  CARE_MESSAGE_TEMPLATES,
  DEFAULT_SERVICE_GROUPS,
  PRACTITIONER_PRIMARY_SERVICES,
  SERVICE_GROUPS,
} from '../../../constants/reactQuery.keys';
import ServiceGroup from '../../../services/ServiceGroup';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../../utils/global';
import compile from '../../../utils/toastMessagesCompiler';

export function useServiceGroups() {
  const response = useQuery([SERVICE_GROUPS], () => ServiceGroup.getServiceGroups({}), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    ...response,
    data: response.data || [],
  };
}

export const useCreateServiceGroupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => ServiceGroup.createServiceGroup(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICE_GROUPS]);
      queryClient.invalidateQueries([DEFAULT_SERVICE_GROUPS]);
      // Required for new care message created after service group creation from fixed field:
      queryClient.invalidateQueries([CARE_MESSAGE_TEMPLATES]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Service group',
          action: 'created',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useUpdateServiceGroupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => ServiceGroup.updateServiceGroup(params.id, params), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([SERVICE_GROUPS]);
      queryClient.invalidateQueries([DEFAULT_SERVICE_GROUPS]);

      if (response.success) {
        showSnackbar(
          compile('generic.success_message', {
            element: 'Service group',
            action: 'deleted',
          }),
          SNACKBAR_SUCCESS
        );
      } else {
        showSnackbar(response?.message || compile('generic.error_message_without_params'));
      }
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useDeleteServiceGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((serviceGroupId: number) => ServiceGroup.deleteServiceGroup(serviceGroupId), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([SERVICE_GROUPS]);
      queryClient.invalidateQueries([DEFAULT_SERVICE_GROUPS]);
      queryClient.invalidateQueries([PRACTITIONER_PRIMARY_SERVICES]);

      if (response.success) {
        showSnackbar(
          compile('generic.success_message', {
            element: 'Service group',
            action: 'deleted',
          }),
          SNACKBAR_SUCCESS
        );
      } else {
        showSnackbar(response?.message || compile('generic.error_message_without_params'));
      }
    },
    onError: useReactQueryOnErrorHandler,
  });
};
