import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { every, range, map, filter } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Box, Button, LinearProgress, Input, Typography, useMediaQuery } from '@material-ui/core';
import {
  Brush as BrushIcon,
  Call as CallIcon,
  Done,
  Home as HomeIcon,
  Room as PinIcon,
  Schedule as ScheduleIcon,
  Star as StarIcon,
  StarOutline,
  ThumbUp,
} from '@material-ui/icons';
import {
  ReviewStarAlign,
  LandingPageProps,
  ILeadDetails,
  ILeadUtmParams,
  ILeadValidation,
  ReviewStarParams,
} from '../../interfaces/ILeadLandingPage';
import { landingPageStyles } from './leadLandingPage.styles';
import {
  INPUT_TOTAL,
  MAX_REVIEW_STARS,
  FULLSCREEN,
  XL_SCREEN,
  PHONE_NUM_LENGTH,
  PHONE_REGEX,
  PHONE_FORMAT,
  DATEMONTH,
  DATEYEAR,
  DIGIT_REGEX,
  PHONE,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE_TYPE,
  BUSINESS_HOURS,
  SECURITY_NOTE,
  DISCLAIMER_TEXT,
  FORMATTED_PHONE_LENGTH,
  ALLOWED_UTMS,
  GOOGLE,
  YELP,
  META_PIXEL_COOKIE,
  META_CLICK_COOKIE,
  META_CLICK_PREFIX,
  META_PIXEL_TRACK,
  META_PIXEL_LEAD,
  META_TAG_CONTENT,
} from '../../constants/leadLandingPage.constants';
import { useUpdateExternalClientLead, useContactDetails } from '../../hooks/mutations/useUpdateExternalClientLead';
import { useIdempotencyKey } from '../../hooks/queries/useIdempotencyKey';

declare global {
  interface Window {
    fbq?: (action: string, eventName: string, data?: any, eventId?: { eventId?: string | null }) => void;
  }
}

const Hero: React.FC<LandingPageProps> = ({ classes, mobile }: LandingPageProps): React.ReactElement => {
  const xlScreen = useMediaQuery(XL_SCREEN);

  const currentMonth = moment().format(DATEMONTH);
  const nextMonth = moment().add(1, 'month').format(DATEMONTH);

  return (
    <Box className={classes?.hero} data-mobile={mobile} data-xl={xlScreen}>
      <Box className={classes?.heroContent} data-mobile={mobile}>
        <Typography className={classes?.heroTitle} data-mobile={mobile}>
          Dream care.
        </Typography>
        <Typography className={classes?.heroTitle} data-mobile={mobile}>
          Best-in-class work.
        </Typography>
        <Typography className={classes?.heroText} data-mobile={mobile}>
          New specials for {` ${currentMonth} and ${nextMonth} `}
          at our 60 full-service medical aesthetics boutiques from coast-to-coast.
        </Typography>
        <Typography className={[classes?.heroText, classes?.regular].join(' ')} data-mobile={mobile}>
          500+ 5 star reviews and counting
        </Typography>
        <ReviewStars score={5} align="left" caption="(4.8)" classes={classes} />
      </Box>
    </Box>
  );
};

const FormLoader: React.FC<LandingPageProps> = ({
  submitted,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.formLoader} data-mobile={mobile}>
    {!submitted ? (
      <>
        <Typography className={classes?.justAMoment}>Just a moment...</Typography>
        <LinearProgress className={classes?.formLoaderProgress} />
      </>
    ) : (
      <>
        <Done className={classes?.successIcon} />
        <Typography className={classes?.allSet}>All Set!</Typography>
      </>
    )}
  </Box>
);

const LeadForm: React.FC<LandingPageProps> = ({ classes, mobile, leadUtms }: LandingPageProps): React.ReactElement => {
  const [leadDetails, setLeadDetails] = useState<ILeadDetails>({});
  const [inputValidations, setInputValidations] = useState<ILeadValidation>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [highlightErrors, setHighlightErrors] = useState<boolean>(false);

  const { mutateAsync } = useUpdateExternalClientLead();

  const updateUserInput = (key: string, value: string, valid: boolean) => {
    setInputValidations((validations: ILeadValidation) => ({ ...validations, [key]: valid }));
    setLeadDetails((details: ILeadDetails) => ({ ...details, [key]: value }));

    highlightErrors && setHighlightErrors(false);
  };

  const handleChange = (key: string, value: string, event: React.ChangeEvent<HTMLInputElement>) => {
    updateUserInput(key, value, event.currentTarget.validity?.valid || false);
  };

  const handlePhone = (value: string) => {
    const rawPhone = value.match(DIGIT_REGEX);
    const rawVal = rawPhone ? rawPhone.join('').slice(0, PHONE_NUM_LENGTH) : '';
    const newPhone = rawVal?.replace(PHONE_REGEX, PHONE_FORMAT);

    const valid = rawVal?.length === PHONE_NUM_LENGTH;

    updateUserInput(PHONE, newPhone, valid);
  };

  const validate = () => Object.keys(inputValidations).length === INPUT_TOTAL && every(Object.values(inputValidations));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { campaign: _, ...formUtms } = { ...leadUtms, adCampaignKey: leadUtms?.campaign };

  const handleSubmit = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();

    if (validate()) {
      const { fbq: submitMetaEvent } = window as Window;

      submitMetaEvent &&
        submitMetaEvent(
          META_PIXEL_TRACK,
          META_PIXEL_LEAD,
          {},
          {
            eventId: formUtms?.eventId,
          }
        );
      setSubmitting(true);
      await mutateAsync({ ...(formUtms || {}), ...leadDetails });
      setSubmitted(true);
    } else {
      setHighlightErrors(true);
    }
  };

  const invalidPhone = highlightErrors && (leadDetails?.phone || '').length < FORMATTED_PHONE_LENGTH;

  return (
    <Box
      className={classes?.leadForm}
      data-mobile={mobile}
      data-submitted={submitted || submitting}
      data-highlight={highlightErrors}
    >
      <Typography variant="h4" className={classes?.leadFormTitle} data-mobile={mobile}>
        Book a Consultation
      </Typography>
      {submitting || submitted ? (
        <FormLoader submitted={submitted} classes={classes} mobile={mobile} />
      ) : (
        <>
          <Input
            value={leadDetails?.email || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(EMAIL, event.currentTarget.value, event);
            }}
            className={classes?.emailInput}
            placeholder={EMAIL}
            type={EMAIL}
            disableUnderline
            required
          />
          <Box className={classes?.nameInputs} data-mobile={mobile}>
            <Input
              value={leadDetails?.firstName || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(FIRST_NAME, event.currentTarget.value, event);
              }}
              className={classes?.nameInput}
              placeholder="first name"
              disableUnderline
              required
            />
            <Input
              value={leadDetails?.lastName || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(LAST_NAME, event.currentTarget.value, event);
              }}
              className={classes?.nameInput}
              placeholder="last name"
              disableUnderline
              required
            />
          </Box>
          <Input
            value={leadDetails?.phone || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handlePhone(event.currentTarget.value);
            }}
            className={classes?.phoneInput}
            placeholder={PHONE}
            type={PHONE_TYPE}
            disableUnderline
            required
            data-highlight={invalidPhone}
          />
          <Button onClick={handleSubmit} className={classes?.submitLeadButton}>
            GO TO STEP 2!
          </Button>
        </>
      )}
      <Typography className={classes?.securityNote}>{SECURITY_NOTE}</Typography>
    </Box>
  );
};

const ReviewStars: React.FC<ReviewStarParams> = ({
  score,
  caption,
  align,
  classes,
}: ReviewStarParams): React.ReactElement => {
  const alignment: ReviewStarAlign = align ?? 'center';

  const filledStars = (score || 0) < MAX_REVIEW_STARS ? score || 0 : MAX_REVIEW_STARS;

  return (
    <Box className={classes?.starReview} data-align={alignment}>
      {range(0, filledStars).map(
        (value: number): React.ReactElement => (
          <StarIcon key={`filledStar${value}`} className={classes?.starIcon} />
        )
      )}
      {range(0, MAX_REVIEW_STARS - filledStars).map(
        (value: number): React.ReactElement => (
          <StarOutline key={`emptyStar${value}`} className={classes?.starIcon} />
        )
      )}
      {!caption ? null : <Typography component="span">{caption}</Typography>}
    </Box>
  );
};

const IconPanel: React.FC<LandingPageProps> = ({
  iconElement,
  title,
  description,
  order,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.iconPanel} data-order={order || 1} data-mobile={mobile}>
    {iconElement}
    <Typography className={classes?.iconPanelTitle}>{title}</Typography>
    <Typography className={classes?.iconPanelText}>{description}</Typography>
  </Box>
);

const ContactBarPanel: React.FC<LandingPageProps> = ({
  iconElement,
  description,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.contactBarPanel} data-mobile={mobile}>
    {iconElement}
    <Typography className={classes?.contactPanelText}>{description}</Typography>
  </Box>
);

const ContactBar: React.FC<LandingPageProps> = ({
  classes,
  mobile,
  campaign,
}: LandingPageProps): React.ReactElement => {
  const { data: contactDetails } = useContactDetails(campaign ?? '');

  const { address, phone } = contactDetails || {};

  return (
    <Box className={classes?.contactBar} data-mobile={mobile}>
      <ContactBarPanel iconElement={<ScheduleIcon />} classes={classes} mobile={mobile} description={BUSINESS_HOURS} />
      <ContactBarPanel iconElement={<CallIcon />} classes={classes} mobile={mobile} description={phone || ''} />
      <ContactBarPanel iconElement={<PinIcon />} classes={classes} mobile={mobile} description={address || ''} />
    </Box>
  );
};

const ReviewBox: React.FC<LandingPageProps> = ({
  reviewSource,
  description,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.leadSectionReview} data-mobile={mobile}>
    <Typography className={classes?.formSectionReview}>{description}</Typography>
    <ReviewStars score={5} classes={classes} />
    <Box className={classes?.reviewIcon} data-icon={reviewSource || GOOGLE} />
  </Box>
);

const BeforeAfterFeature: React.FC<LandingPageProps> = ({
  serviceName,
  title,
  description,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.baSection} data-mobile={mobile}>
    <Box className={classes?.baDescription} data-mobile={mobile}>
      <Typography className={classes?.baText} data-mobile={mobile}>
        {title}
      </Typography>
      <Typography className={[classes?.baText, classes?.regular].join(' ')}>{description}</Typography>
    </Box>
    <Box className={classes?.baFeature} data-mobile={mobile}>
      <Box className={classes?.beforeImage} data-mobile={mobile} data-service={serviceName} />
      <Box className={classes?.afterImage} data-mobile={mobile} data-service={serviceName} />
    </Box>
  </Box>
);

const ReviewsFeature: React.FC<LandingPageProps> = ({ classes, mobile }: LandingPageProps): React.ReactElement => (
  <>
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description={
        '\
          “She did my jawline and cheeks and I have NEVER been happier or more \
          confident. She is seriously amazing!”\
        '
      }
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={YELP}
      description="“I feel confident with my treatments because it feels like a collaboration.”"
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description={
        "\
          “What a great experience. I've had botox and fillers in the past and never \
          felt I received good results. I felt I was just a number, not a patient with \
          other providers … a true artist”\
        "
      }
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description="“Excellent injector. I will definitely be going back.”"
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={YELP}
      description="“Thank you for making me feel confident and beautiful.”"
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description={"“You can't ask or pay for a better experience.”"}
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={YELP}
      description="“So much knowledge with everything he does”"
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description={
        '\
          “She was so thorough and walked me through every \
          step of the way and took her time!”\
        '
      }
    />
  </>
);

const LeadLandingPage: React.FC = (): React.ReactElement => {
  const [leadUtms, setLeadUtms] = useState<ILeadUtmParams>({});

  const { search: locationSearch } = useLocation();

  const { data: eventId } = useIdempotencyKey(leadUtms.eventId || undefined);

  const classes = landingPageStyles();
  const mobile = useMediaQuery(FULLSCREEN);

  const { cookie } = document;

  const cleanParam = (data: string[]): string =>
    data.length && data[0].indexOf('=') > -1 ? data[0].split('=')[1] : '';

  const getCookie = (cookieName: string): string =>
    cleanParam(
      cookie
        .split(';')
        .filter((e) => e.indexOf(cookieName) > -1)
        .slice(-1)
    );

  const addMetaPixel = (): void => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = META_TAG_CONTENT;

    document.head.appendChild(script);
  };

  const createMetaClick = (fbClid: string | undefined | null): string =>
    `${META_CLICK_PREFIX}.${new Date().valueOf()}.${fbClid || ''}`;

  const updateMetaParams = (): void => {
    setLeadUtms((utms: ILeadUtmParams) => ({
      ...utms,
      sourceData: getCookie(META_PIXEL_COOKIE),
      clickData: getCookie(META_CLICK_COOKIE) || createMetaClick(utms.fb_clid),
    }));
  };

  useEffect(() => addMetaPixel(), []);

  useEffect(() => {
    eventId && !leadUtms.eventId && setLeadUtms(() => ({ ...leadUtms, eventId }));
  }, [eventId]);

  useEffect(() => {
    const uriInput: string[] = decodeURIComponent(locationSearch.slice(1)).split('&');
    const utms: ILeadUtmParams = Object.fromEntries(
      filter(
        map(uriInput, (param: string): string[] => param.split('=')),
        (param: string[]) => ALLOWED_UTMS.indexOf(param[0]) > -1
      )
    );
    setLeadUtms(utms);
  }, []);

  useEffect(() => {
    if (!leadUtms.clickData || (getCookie(META_PIXEL_COOKIE) && !leadUtms.sourceData)) {
      updateMetaParams();
    }
  }, [leadUtms, cookie]);

  return (
    <main>
      <Box className={classes.rootContainer}>
        <Hero classes={classes} mobile={mobile} />
        <Box className={classes.formSection} data-mobile={mobile}>
          <LeadForm classes={classes} mobile={mobile} leadUtms={leadUtms} />
          <Typography className={classes.formSectionTag}>Your care is our dream work</Typography>
          <Typography className={classes.formSectionTag}>
            World-class aesthetic services to help you look and feel your best
          </Typography>
          <ReviewsFeature classes={classes} mobile={mobile} />
        </Box>
        <Box className={classes.boutiqueSection}>
          <Typography className={classes.boutiqueText} data-mobile={mobile}>
            Aesthetics boutiques,
          </Typography>
          <Typography className={classes.boutiqueText} data-mobile={mobile}>
            not Corporate MedSpas.
          </Typography>
        </Box>
        <BeforeAfterFeature
          classes={classes}
          mobile={mobile}
          title="Before & After Treatment"
          description={
            '\
              Full service medical aesthetics boutiques for all your goals. \
              From chemical peels, microneedling, and skincare products, to \
              wrinkle relaxers, volumizers, body sculpting, and laser treatments.\
            '
          }
        />
        <BeforeAfterFeature
          classes={classes}
          mobile={mobile}
          serviceName="relaxer"
          title="Wrinkle Relaxers"
          description=""
        />
        <BeforeAfterFeature
          classes={classes}
          mobile={mobile}
          serviceName="volumizer"
          title="Volumizers"
          description=""
        />
        <BeforeAfterFeature
          classes={classes}
          mobile={mobile}
          serviceName="contour"
          title="Facial Contouring"
          description=""
        />
        <BeforeAfterFeature
          classes={classes}
          mobile={mobile}
          serviceName="sculpting"
          title="Body Sculpting"
          description=""
        />

        <Box className={classes.expectSection}>
          <Typography className={classes.expectText}>What to expect</Typography>
          <Typography className={[classes.expectText, classes.regular].join(' ')}>
            We emphasize education when it comes to your anti-aging treatment plan. Stay informed and feel confident
            about your next steps to getting natural-locking results:
          </Typography>
          <Box className={classes.iconPanelGroup} data-mobile={mobile}>
            <IconPanel
              order={1}
              iconElement={<CallIcon />}
              title="Book Your Intro Phone Call"
              description="Our helpful staff will answer your questions & schedule your upcoming visit."
              classes={classes}
              mobile={mobile}
            />
            <IconPanel
              order={2}
              iconElement={<HomeIcon />}
              title="Attend Your In-Person Discovery Session"
              description="Get a custom facial analysis from your injector to determine exactly what your skin needs."
              classes={classes}
              mobile={mobile}
            />
          </Box>
          <Box className={classes.iconPanelGroup} data-mobile={mobile}>
            <IconPanel
              order={3}
              iconElement={<BrushIcon />}
              title="Kickstart Your Results"
              description={"Create science-backed plan that's perfect for YOU--and get same-day treatment."}
              classes={classes}
              mobile={mobile}
            />
            <IconPanel
              order={4}
              iconElement={<ThumbUp />}
              title="Look and Feel Your Best"
              description="Enjoy immediate, natural and transformative results to look rested & refreshed."
              classes={classes}
              mobile={mobile}
            />
          </Box>
        </Box>
        <Box className={classes.footerSection}>
          {!leadUtms.campaign ? null : <ContactBar classes={classes} mobile={mobile} campaign={leadUtms.campaign} />}
          <Typography className={classes.footerTag}>© {moment().format(DATEYEAR)} All Rights Reserved</Typography>
          <Typography className={classes.footerDisclaimer} data-mobile={mobile}>
            {DISCLAIMER_TEXT}
          </Typography>
        </Box>
      </Box>
    </main>
  );
};

export default LeadLandingPage;
