import React from 'react';
import { TableRow, TableCell, IconButton, Collapse, Box, Typography } from '@material-ui/core';
import moment from 'moment';
import classnames from 'classnames';
import InputMatching from './InputMatching';
import ButtonAction from './ButtonAction';
import IconCross from '../../../common/IconCross';
import IServices from '../../../../interfaces/IServices';
import { TableCellCustom, TableRowStriped, useStyle } from './rowItem.styles';
import { useStyles as useTabStyles } from '../tabs.styles';
import { SERVICE_VISIT_VERSIONS } from '../../../../constants/serviceVisits/serviceVisit.constants';

const RowItem = (props: any) => {
  const { row, services } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyle();
  const tabClasses = useTabStyles();

  const navigateToServiceVisit = () => {
    if (row.version >= SERVICE_VISIT_VERSIONS.VERSION_JULY_2023) {
      window.location.href = `/patient/${row.customerId}/newServiceVisit/${row.id}`;
    } else {
      window.location.href = `/patient/${row.customerId}/serviceVisit/${row.id}`;
    }
  };

  // eslint-disable-next-line consistent-return
  const renderActionButton = (reason: any, orderId?: any) => {
    switch (reason) {
      case 'customer_unmatched':
        return <InputMatching orderId={orderId} />;
      case null:
        return <ButtonAction text="Charting" onClick={navigateToServiceVisit} />;
      default:
        break;
    }
  };

  const renderServices = () => {
    if (services.length) {
      const serviceNames =
        row.detail
          ?.map((serviceId: number) => services.find(({ id }: IServices) => serviceId === id)?.name)
          .join(', ') || '';

      return serviceNames.length > 0 ? `${serviceNames}.` : 'No services.';
    }

    return 'No services.';
  };

  return (
    <>
      <TableRowStriped className={classes.ItemTable}>
        <TableCellCustom className={classes.iconCross}>
          <IconButton
            data-cy="todoSpandLineItemIcon"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <IconCross open={open} />
          </IconButton>
        </TableCellCustom>
        <TableCellCustom data-cy="todoLineItemDate" width={70}>
          {moment(row.createdAt).format('MM/DD/YYYY')}
        </TableCellCustom>
        <TableCellCustom data-cy="todoLineItemPatient" width={215} className={classes.patientName}>
          {(row.showYellowFlag || row.showRedFlag) && (
            <div
              data-cy="todoLineItemDotAlert"
              className={classnames(tabClasses.pendingNotification, {
                [tabClasses.redPendingNotification]: row.showRedFlag,
              })}
              style={{ float: 'right' }}
            />
          )}
          {row.patientName}
        </TableCellCustom>
        <TableCellCustom data-cy="todoLineItemPatientId" className={classes.patientId}>
          {row.customerId}
        </TableCellCustom>
        <TableCellCustom data-cy="todoLineItemChartingButton">{renderActionButton(row.reason, row.id)}</TableCellCustom>
      </TableRowStriped>
      <TableRow style={{ background: row.index % 2 ? 'none' : '#f2f5f5', borderTop: '3px solid transparent' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.expandedText}>
              <Typography
                data-cy="todoLineItemServicesProvided"
                gutterBottom
                component="p"
                style={{ color: '#000', fontSize: 14 }}
              >
                Services provided
              </Typography>
              <Typography style={{ fontSize: '14px', fontFamily: 'Messina Sans Regular', color: '#000' }}>
                {renderServices()}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RowItem;
