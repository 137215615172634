import { Box, Grid, MenuItem, Select, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TableTodo from 'src/components/DashboardPractitioner/Tabs/TodoListTabs/TableTodo';
import TabItem from 'src/components/common/TabItem';
import { MEDSPA_ADMIN_ROLE } from 'src/constants/general.constants';
import { useMedspaPractitioners, useOpenServiceVisits } from 'src/hooks/queries/useMedspaAdmins';
import { useServices } from 'src/hooks/queries/services/useServices';
import TableFeedback from 'src/components/DashboardPractitioner/Tabs/FeedbackTab/TableFeedback';
import Page from 'src/components/common/Page';

const MedspaAdminToDoList = () => {
  const { roleName, userGroupId, userId } = useSelector(({ auth }: any) => auth);

  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;

  const { data: practitioners = [], isLoading: isLoadingPractitioners } = useMedspaPractitioners(isMedspaAdmin);
  const { data: services, isFetching: isFetchingServices } = useServices();

  const [practitionerId, setPractitionerId] = useState<string>(isMedspaAdmin ? 'all' : userId);
  const [disableSelection, setDisableSelection] = useState(practitioners?.length === 1 || !isMedspaAdmin);
  const [tab, setTab] = useState(0);

  const handleTabChange = (event: any, newTab: number) => {
    setTab(newTab);
  };

  const { isLoading, data: serviceVisits } = useOpenServiceVisits(practitionerId, userGroupId);

  useEffect(() => {
    if (practitioners && practitioners.length && !isLoadingPractitioners) {
      const isSingleProvider = practitioners.length === 1;
      setPractitionerId(isSingleProvider ? practitioners[0].id.toString() : 'all');
      setDisableSelection(isSingleProvider || !isMedspaAdmin);
    }
  }, [practitioners, isLoadingPractitioners]);

  const a11yProps = (index: any) => ({
    id: `medspa-todo-list-tab-${index}`,
    'aria-controls': `transactions-tabpanel-${index}`,
  });

  const charting = serviceVisits?.filter((todo: any) => todo.matched === null);

  return (
    <Page title="To-Do list">
      <Grid container>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: 'black' } }}
          value={tab}
          onChange={handleTabChange}
          style={{ borderBottom: '1px solid lightgrey', width: '100%' }}
        >
          <Tab label="Charting" {...a11yProps('to-do-list')} />
          <Tab label="Physician Chart Reviews" {...a11yProps('physician-chart-reviews')} />
        </Tabs>

        {!disableSelection && (
          <Box display="flex" width="100%" margin="20px 0">
            <Select
              id="practitioners"
              data-cy="practitioners"
              labelId="practitioners"
              name="practitioners"
              variant="outlined"
              onChange={(event: any) => setPractitionerId(event.target.value)}
              style={{ height: '50px', width: '200px' }}
              value={practitionerId}
            >
              <MenuItem data-cy="selectedPractitioners" value="all" key="practitioners-">
                All
              </MenuItem>
              {(practitioners || []).map((value) => (
                <MenuItem data-cy="selectedPractitioners" value={value.id} key={`practitioners-${value.id}`}>
                  {value.firstName} {value.lastName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <TabItem value={tab} index={0}>
          <TableTodo data={isLoading ? [] : charting} isLoading={isLoading || isFetchingServices} services={services} />
        </TabItem>
        <TabItem value={tab} index={1}>
          <TableFeedback practitionerId={practitionerId} userGroupId={userGroupId} />
        </TabItem>
      </Grid>
    </Page>
  );
};

export default MedspaAdminToDoList;
