import { snakeCase } from 'lodash';
import Api from './Api';
import API_ROUTES from '../constants/apiRoutes.constants';
import {
  IUserGroup,
  IUserGroupList,
  IUserGroupListParams,
  IUserGroupOption,
  IUserGroupOptionParams,
  IUserGroupUpdateParams,
} from '../interfaces/IUserGroup';
import { filterUnusedParams } from '../utils/global';

export const getFormData = (params: any) => {
  const formData = new FormData();
  // this is necessary to send the data as form data for the signature
  Object.entries(params).forEach(([key, value]) => {
    if (key === 'active' || value) {
      // @ts-ignore
      formData.append(snakeCase(key), value);
    }
  });
  return formData;
};

const UserGroups = {
  async getUserGroupInfinity(params: IUserGroupListParams): Promise<any> {
    const { results } = await Api.get(API_ROUTES.USER_GROUPS_INFINITY, params);
    return results;
  },
  async getUserGroupInfinityCount(params: IUserGroupListParams): Promise<any> {
    const { count } = await Api.get(API_ROUTES.USER_GROUPS_INFINITY_COUNT, params);
    return count;
  },
  async getUserGroups(params: IUserGroupListParams): Promise<IUserGroup[] | IUserGroupList | null> {
    const { success, data }: { success: boolean; data: IUserGroup[] | string } = await Api.get(
      API_ROUTES.USER_GROUPS,
      filterUnusedParams<IUserGroupListParams, number | undefined>(params)
    );
    return success && typeof data !== 'string' ? data : [];
  },
  async getUserGroupOptions(params: IUserGroupOptionParams): Promise<IUserGroupOption[]> {
    const { userGroups }: { userGroups: IUserGroupOption[] } = await Api.get(API_ROUTES.USER_GROUPO_OPTIONS, params);
    return userGroups || [];
  },

  async getCurrentUserGroup(): Promise<IUserGroup | null> {
    const { success, userGroup }: { success: boolean; userGroup: IUserGroup | string } = await Api.get(
      API_ROUTES.CURRENT_USER_GROUP
    );

    return success && typeof userGroup !== 'string' ? userGroup : null;
  },

  async updateUserGroup(params: IUserGroupUpdateParams): Promise<void> {
    if (params.id) {
      await Api.post(API_ROUTES.UPDATE_USER_GROUP(params.id), params);
    }
  },

  async createUserGroup(params: IUserGroupUpdateParams): Promise<void> {
    await Api.put(API_ROUTES.USER_GROUPS, params);
  },
  async createV4UserGroup(params: any): Promise<void> {
    const response = await Api.post(API_ROUTES.v4_USER_GROUPS, getFormData(params));
    return response;
  },

  addFeatureFlagToUserGroup(key: string, medspaId: number) {
    return Api.put(`${API_ROUTES.v4_USER_GROUPS}/add_feature_to_user_group`, {
      medspaId,
      key,
    });
  },
  removeFeatureFlagFromUserGroup(key: string, medspaId: number) {
    return Api.delete(`${API_ROUTES.v4_USER_GROUPS}/remove_feature_from_user_group/${key}/${medspaId}`);
  },
};

export { UserGroups, UserGroups as default };
