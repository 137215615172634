import { useQuery } from 'react-query';
import { PHYSICIAN_APPROVAL_DRAFT } from '../../constants/reactQuery.keys';
import { PHYSICIAN_ROLE } from '../../constants/general.constants';
import Customers from '../../services/Customers';

export function usePhysicianApprovalDraft(patientId: number, userType: any) {
  if (userType !== PHYSICIAN_ROLE) {
    return { isFetching: false, data: {} };
  }

  return useQuery(
    [PHYSICIAN_APPROVAL_DRAFT, patientId.toString()],
    () => Customers.getPhysicianApprovalDraft(patientId),
    {
      initialData: { customerDiagnoses: [], serviceRequests: [] },
      keepPreviousData: true,
    }
  );
}

export { PHYSICIAN_APPROVAL_DRAFT } from '../../constants/reactQuery.keys';
