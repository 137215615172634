import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  editSection: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '38px',
    alignItems: 'center'
  },
  skinTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  border: {
    borderRadius: '50%',
    height: '144px',
    width: '144px',
    padding: '15px',
    marginBottom: '15px',
    '&:hover': {
      border: 'solid',
    }
  },
  label: {
    marginBottom: '10px',
    color: '#949ea9'
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px'
  },
  icon: {
    fontSize: '55px',
    color: '#ffffff'
  },
  type: {
    color: '#a4a7aa',
    marginBottom: '10px'
  },
  title: {
    marginBottom: '10px',
    fontWeight: 600,
    fontSize: '16px'
  },
  description: {

  }
}));
