import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import IConsent from 'src/interfaces/IConsent';
import { PHOTO_CONSENT } from 'src/constants/apiRoutes.constants';
import { CONSENT_TEMPLATES, PRACTITIONER_CONSENT, PRACTITIONER_CONSENTS } from '../../constants/reactQuery.keys';
import Consents from '../../services/Consents';
import compile from '../../utils/toastMessagesCompiler';

export function useConsentTemplates() {
  const response = useQuery([CONSENT_TEMPLATES], () => Consents.getConsentTemplates());
  return {
    ...response,
    data: response.data,
  };
}

export function usePractitionerConsents() {
  return useQuery([PRACTITIONER_CONSENTS], () => Consents.getPractitionerConsents(), {
    refetchOnWindowFocus: true,
  });
}

export function usePractitionerConsent(id: number) {
  return useQuery([PRACTITIONER_CONSENT], () => Consents.getPractitionerConsent(id), {
    refetchOnWindowFocus: true,
  });
}

export function usePhotoConsent() {
  return useQuery([PHOTO_CONSENT], () => Consents.getPhotoConsent(), {
    refetchOnWindowFocus: false,
  });
}

export const useUpdatePractitionerConsents = (successCallback?: () => void): UseMutationResult =>
  useMutation((params: any) => Consents.updatePractitionerConsent(params), {
    onSuccess: (success: boolean) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }
      showSnackbar(compile('generic.success_message', { element: 'Consent', action: 'updated' }), SNACKBAR_SUCCESS);
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });

export const useCreatePractitionerConsent = (successCallback?: (data?: any) => void): UseMutationResult =>
  useMutation((params: any) => Consents.createPractitionerConsent(params), {
    onSuccess: ({ success, consent }: { success: boolean; consent: IConsent }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }
      showSnackbar(compile('generic.success_message', { element: 'Consent', action: 'created' }), SNACKBAR_SUCCESS);
      successCallback?.({ consent });
    },
    onError: useReactQueryOnErrorHandler,
  });
