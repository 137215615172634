import { createModel } from '@rematch/core';
import { logSentryException } from 'src/services/Sentry';
import { logDatadogException } from 'src/services/Datadog';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';

type ProductboardType = {
  token: string;
};

export const productboard = createModel<RootModel>()({
  state: {
    token: '',
  } as ProductboardType,
  reducers: {
    setToken(state: any, payload: any) {
      return { ...state, token: payload };
    },
  },
  effects: (dispatch) => ({
    async getToken() {
      try {
        const response: any = await axiosInstance.get('/productboards/token');

        dispatch({ type: 'productboard/setToken', payload: response.data.token });
      } catch (err) {
        const error = err as Error;
        logSentryException(error);
        logDatadogException(error);
      }
    },
  }),
});
