import React, { useState } from 'react';
import { TextField, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// eslint-disable-next-line import/no-unresolved
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import PasswordStepper from './PasswordStepper'; // Make sure the path is correct

import { useStyles, usePasswordStyles } from './index.styles';

type Props = {
  password: string;
  passwordConfirmation: string;
  setPassword: (value: string) => void;
  setPasswordConfirmation: (value: string) => void;
  show?: boolean;
};

const passwordCriteria = {
  length: { text: 'A minimum of 7 characters', test: (pwd: string) => pwd.length >= 7 },
  mixedCase: { text: 'Upper & lowercase letters', test: (pwd: string) => /[a-z]/.test(pwd) && /[A-Z]/.test(pwd) },
  numberOrSymbol: { text: 'A number or symbol', test: (pwd: string) => /[0-9!@#$%^&*]/.test(pwd) },
};

export const PasswordForm = ({
  password,
  passwordConfirmation,
  setPassword,
  setPasswordConfirmation,
  show = true,
}: Props) => {
  const classes = useStyles();
  const passwordStyles = usePasswordStyles();

  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const validatePassword = (pwd: string) => {
    const isValid = Object.values(passwordCriteria).every((criteria) => criteria.test(pwd));
    setPasswordValid(isValid);
    setPassword(pwd);
    if (passwordConfirmation) {
      setPasswordsMatch(pwd === passwordConfirmation);
    }
  };

  const validatePasswordConfirmation = (confirmation: string) => {
    setPasswordConfirmation(confirmation);
    if (password) {
      setPasswordsMatch(password === confirmation);
    }
  };

  const getActiveStep = () => {
    const criteriaList = [
      /[A-Z]/, // Contains an uppercase letter
      /[a-z]/, // Contains a lowercase letter
      /[0-9]/, // Contains a number
      /[^A-Za-z0-9]/, // Contains a special character
    ];

    let activeSteps = criteriaList.reduce((acc, criteria) => (criteria.test(password) ? acc + 1 : acc), 0);
    if (password && password === passwordConfirmation) {
      activeSteps += 1;
    }
    return activeSteps;
  };

  return (
    <div>
      <TextField
        className={classes.signupField}
        data-cy="passwordInput"
        fullWidth
        name="password"
        onChange={(event) => validatePassword(event.target.value)}
        label="Password*"
        variant="outlined"
        type="password"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        className={classes.signupField}
        data-cy="passwordConfirmationInput"
        fullWidth
        name="passwordConfirmation"
        onChange={(event) => validatePasswordConfirmation(event.target.value)}
        label="Confirm Password*"
        type="password"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      {show && (
        <>
          <h2 className={passwordStyles.h2}>{passwordValid && passwordsMatch ? 'Good Password' : ''}</h2>
          <PasswordStepper activeStep={getActiveStep()} />
          <List>
            {Object.entries(passwordCriteria).map(([key, criteria]) => (
              <ListItem key={key} className={passwordStyles.listItem}>
                <ListItemIcon className={passwordStyles.listItemIcon}>
                  {criteria.test(password) ? (
                    <>
                      <FiberManualRecordIcon style={{ color: '#20bd3d' }} />
                      <ListItemText
                        disableTypography
                        className={passwordStyles.listItemText}
                        style={{ color: '#20bd3d' }}
                        primary={criteria.text}
                      />
                    </>
                  ) : (
                    <>
                      <FiberManualRecordOutlinedIcon style={{ color: '#9c9c9c' }} />
                      <ListItemText
                        disableTypography
                        className={passwordStyles.listItemText}
                        style={{ color: '#9c9c9c' }}
                        primary={criteria.text}
                      />
                    </>
                  )}
                </ListItemIcon>
              </ListItem>
            ))}
            <ListItem className={passwordStyles.listItem}>
              <ListItemIcon className={passwordStyles.listItemIcon}>
                {password && password === passwordConfirmation ? (
                  <>
                    <FiberManualRecordIcon style={{ color: '#20bd3d' }} />
                    <ListItemText
                      disableTypography
                      className={passwordStyles.listItemText}
                      style={{ color: '#20bd3d' }}
                      primary="Passwords match"
                    />
                  </>
                ) : (
                  <>
                    <FiberManualRecordOutlinedIcon style={{ color: '#9c9c9c' }} />
                    <ListItemText
                      disableTypography
                      className={passwordStyles.listItemText}
                      style={{ color: '#9c9c9c' }}
                      primary="Passwords match"
                    />
                  </>
                )}
              </ListItemIcon>
            </ListItem>
          </List>
        </>
      )}
    </div>
  );
};
