import IServices from '../interfaces/IServices';
import { IServicesUnits } from '../interfaces/serviceVisit.interfaces';
import { IGaldermaProduct, PatientCertificate, IPractitionerCertificate } from '../types/PatientPointCertificatesState';
import { dispatch } from '../rematch';
import compile from './toastMessagesCompiler';
import { ICertificateOptions } from '../interfaces/galderma.interface';

// Function that returns the available options given the amount of Galderma user points
export const getCertificateOptions = (pointsTotal: number): ICertificateOptions[] => {
  const pointsOptions = [];
  for (let i = 1; i <= Math.floor(pointsTotal / 100); i++) {
    const amount = i * 10;
    const points = i * 100;
    pointsOptions.push({
      id: i,
      text: `${points} points = $${amount}`,
      amount,
      points,
    });
  }

  return pointsOptions;
};

export const getGaldermaServicesSelected = (
  galdermaProducts: IGaldermaProduct[],
  totalServicesUnits: IServicesUnits,
  services: IServices[]
): IServices[] =>
  services.filter(({ galdermaSku, id }) =>
    galdermaProducts.some(({ sku }) => {
      const galdermaSkus = JSON.parse(galdermaSku || '{}');
      return Object.values(galdermaSkus).includes(sku) && +totalServicesUnits[id] > 0;
    })
  );

export const galdermaSkuIncludeProduct = (galdermaSku: string, sku: string) => {
  const galdermaSkus = JSON.parse(galdermaSku);
  const skuList = Object.values(galdermaSkus);
  skuList.includes(sku);
};

export const parseGaldermaProductsAndCertificates = ({
  patientCertificates,
  practitionerCertificates,
  galdermaProducts,
  certificateIds,
  practitionerCertificateIds,
  totalServicesUnits,
  services,
  skusSelected = {},
  freeTextUsed = false,
  freeTextVoucher = '',
}: {
  patientCertificates: PatientCertificate[];
  practitionerCertificates: IPractitionerCertificate[];
  galdermaProducts: IGaldermaProduct[];
  certificateIds: number[];
  practitionerCertificateIds: number[];
  totalServicesUnits: IServicesUnits;
  services: IServices[];
  skusSelected: any;
  freeTextUsed?: boolean;
  freeTextVoucher?: string;
}) => {
  const filteredProducts: { productId: number; units: number }[] = services.map(({ galdermaSku, id }) => {
    const galdermaSkus = JSON.parse(galdermaSku || '{}');

    const product = galdermaProducts.find(({ sku }) =>
      skusSelected[id] ? sku === skusSelected[id] : sku === Object.values(galdermaSkus)[0]
    );
    const productId = product?.id || 0;
    const maxQuantityPerTreatment = product?.maxQuantityPerTreatment || 0;
    return {
      productId,
      units: +totalServicesUnits[id] <= maxQuantityPerTreatment ? +totalServicesUnits[id] : maxQuantityPerTreatment,
    };
  });

  const products = filteredProducts.filter(({ productId, units }) => productId > 0 && units > 0);

  const certificateCodes = patientCertificates.filter(({ id }) => certificateIds.includes(id)).map(({ code }) => code);

  const practitionerCertificateCodes = practitionerCertificates
    .filter(({ id }) => practitionerCertificateIds.includes(id))
    .map(({ code }) => code);

  return {
    products,
    certificates: [...certificateCodes, ...practitionerCertificateCodes],
    freeTextUsed,
    freeTextVoucher,
  };
};

export const getGaldermaDiscountByCredits = (
  patientCertificatesSelected: number[],
  patientCertificates: PatientCertificate[] | IPractitionerCertificate[]
) => {
  // @ts-ignore https://github.com/microsoft/TypeScript/issues/44373
  const certificates = patientCertificates.filter(({ id }: PatientCertificate | IPractitionerCertificate) =>
    patientCertificatesSelected.includes(id)
  );

  const sum = certificates.reduce(
    (acc: number, { amount }: PatientCertificate | IPractitionerCertificate) =>
      acc + parseFloat(amount.replace('$ ', '')),
    0
  );

  return sum;
};

export const getGaldermaAmountByCertificates = (
  certificatesUsed: string[],
  certificates: PatientCertificate[] | IPractitionerCertificate[]
) => {
  // @ts-ignore https://github.com/microsoft/TypeScript/issues/44373
  const filteredCertificates = certificates.filter(({ code }: PatientCertificate | IPractitionerCertificate) =>
    certificatesUsed.includes(code)
  );

  const sum = filteredCertificates.reduce(
    (acc: number, { amount }: PatientCertificate | IPractitionerCertificate) =>
      acc + parseFloat(amount.replace('$ ', '')),
    0
  );

  return sum;
};

export const handleGaldermaUserError = (data: any, errorMsg?: string) => {
  if (data.errorList?.length) {
    let messages = '';

    data.errorList.forEach((error: any) => {
      const [, message] = Object.entries(error)[0];
      messages += `${message}. `.replace('Aspire', 'ASPIRE');
    });
    const message = compile('galderma.generic_error', { errors: messages });
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message,
        type: 'error',
        duration: 10000,
      },
    });
  } else {
    const message = errorMsg || compile('galderma.validation_error');
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message,
        type: 'error',
        duration: 10000,
      },
    });
  }
};
