/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { TableCell, TableRow, IconButton, Collapse, Box, Typography, Grid, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Chat } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { IS_SUPPORT } from 'src/constants/actions.constants';
import { hasAccessTo } from 'src/utils/auth.utils';
import { useForceOpenCloseServiceVisit } from 'src/hooks/serviceVisit/useServiceVisit';

import { dispatch } from '../../rematch';
import MyCoverImage from './FullServiceHistoryOpen/MyCoverImage';
import { ReactComponent as DollarSign } from '../../assets/images/icon-awesome-dollar-sign.svg';
import { ReactComponent as Eye } from '../../assets/images/icon-awesome-eye.svg';
import IconCross from '../common/IconCross';
import { Annotations } from '../NewServiceVisit/annotations/Annotations';
import ServiceVisitNotes from './FullServiceHistoryOpen/ServiceVisitNotes';
import { ADMIN_ROLE, PHYSICIAN_ROLE, PRACTITIONER_ROLE } from '../../constants/general.constants';
import { BeforePhotos } from '../NewServiceVisit/BeforePhotos';
import { AfterPhotos } from '../NewServiceVisit/afterPhotos/AfterPhotos';
import { ServiceVisitScanner } from '../NewServiceVisit/scanner/ServiceVisitScanner';
import { SERVICE_VISIT_VERSIONS } from '../../constants/serviceVisits/serviceVisit.constants';
import { ROUTES } from '../../constants/routes.constants';
import { useStyles, TableCellData, TableRowStriped } from './RowItem.styles';
import ConfirmModal from '../common/ConfirmModal';
import { ConfirmForceOpenCloseSVContent } from './ConfirmForceOpenCloseSVContent';
import { ReactComponent as GearIcon } from '../../assets/images/gear.svg';

const RowItem = ({ visit, striped, getRowId, rowId }: any) => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const { patientId: id }: any = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { userType } = useSelector(({ auth }: any) => auth);
  const { isLoadingServices, isLoadingGetServiceVisitFromPatient, isLoadingProgressingSaving } = useSelector(
    (state: any) => state.newServiceVisit
  );
  const [open, setOpen] = useState(false);
  const [showForceOpenedModal, setShowForceOpenedModal] = useState(false);

  const serviceVisitId = visit.id;
  const { pathname } = history.location;

  const isSupport = hasAccessTo(IS_SUPPORT, permissions);

  const forceOpenCloseServiceVisit = useForceOpenCloseServiceVisit(+id, +serviceVisitId);

  const allowNavigateToServiceVisit = [PRACTITIONER_ROLE, PHYSICIAN_ROLE].includes(userType) || isSupport;
  const allowNavigateToCheckout = userType === PRACTITIONER_ROLE;

  const navigateToServiceVisit = () => {
    dispatch({ type: 'newServiceVisit/cleanServiceVisitData' });

    if (visit.version >= SERVICE_VISIT_VERSIONS.VERSION_JULY_2023) {
      history.push(ROUTES.NEW_SERVICE_VISIT_VERSION(id, serviceVisitId), { from: pathname });
    } else {
      history.push(`/patient/${id}/serviceVisit/${serviceVisitId}`, { from: pathname });
    }
  };

  const navigateToCheck = () => {
    if (visit.version >= SERVICE_VISIT_VERSIONS.VERSION_JULY_2023) {
      history.push(ROUTES.NEW_SERVICE_VISIT_CHECKOUT_VERSION(id, serviceVisitId), { from: pathname });
    } else {
      history.push(`/patient/${id}/serviceVisit/${serviceVisitId}/checkout`, { from: pathname });
    }
  };

  useEffect(() => {
    dispatch({ type: 'newServiceVisit/cleanServiceVisitData' });
    setOpen(rowId === serviceVisitId);
    if (rowId === serviceVisitId) {
      dispatch({
        type: 'newServiceVisit/fetchServices',
        payload: { contextServiceVisitId: serviceVisitId },
      });
      dispatch({
        type: 'newServiceVisit/getServiceVisitFromPatient',
        payload: { serviceVisitId: rowId, currentPatientId: id },
      });
    }
  }, [rowId]);

  return (
    <>
      <TableRowStriped style={{ backgroundColor: striped % 2 ? '#f2f5f5' : '#fff' }}>
        <TableCell width={50} style={{ verticalAlign: 'top', paddingTop: '12px' }}>
          {!visit.opened && (
            <IconButton
              size="small"
              onClick={() => {
                getRowId(serviceVisitId);
                setOpen(!open);
              }}
              style={{ padding: '7px' }}
            >
              <IconCross open={open} />
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <div className={classes.dataClass} style={{ fontWeight: open ? 'bold' : 400 }}>
            Service visit
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.dataClass} style={{ fontWeight: open ? 'bold' : 400 }}>
            {`${moment(visit.createdAt).format('MM/DD/YYYY')} - ${moment(visit.createdAt).fromNow()}`}
          </div>
        </TableCell>
        <TableCellData>
          {visit.services.map(({ name }: any) => (
            <Fragment key={`${visit.id}-${name}`}>
              {' '}
              {/* <== do not remove */}
              <div
                key={get(name, 'id', null)}
                className={classes.serviceName}
                style={{ fontWeight: open ? 'bold' : 'normal' }}
              >
                {name}
              </div>
            </Fragment>
          ))}
        </TableCellData>

        <TableCellData>
          {visit.services.map(({ id: serviceId, units }: any) => (
            <div key={`unit-${serviceId}`}>{Math.floor(+units)}</div>
          ))}
        </TableCellData>

        <TableCellData>
          {visit.fromNewEhr && allowNavigateToServiceVisit && (
            <Box display="flex">
              {userType !== ADMIN_ROLE && (
                <Box mr={2}>
                  <Button onClick={() => navigateToServiceVisit()} className={classes.boxActionItem}>
                    <Eye />
                  </Button>
                </Box>
              )}
              {visit.hasCheckout && allowNavigateToCheckout && userType !== ADMIN_ROLE && (
                <Button onClick={() => navigateToCheck()} className={classes.boxActionItem}>
                  <DollarSign />
                </Button>
              )}
            </Box>
          )}

          {userType === ADMIN_ROLE && isSupport && (
            <Button
              data-testid="force-close-open-modal"
              style={{ backgroundColor: '#FBDADA' }}
              onClick={() => setShowForceOpenedModal(true)}
            >
              <GearIcon />
              <span style={{ width: '4px' }} />
              {`FORCE ${visit.opened ? 'CLOSE' : 'OPEN'}`}
            </Button>
          )}
        </TableCellData>
      </TableRowStriped>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, maxWidth: '450px' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box mb={2}>
                <Grid container item xs={12} direction="row" justify="space-between" alignItems="center">
                  <Typography className={classes.typographyClass}>Service Details</Typography>
                  {/* commented for future implementation: */}
                  {false && (
                    <Button
                      style={{ backgroundColor: '#e7eeed', width: '183px', height: '47px' }}
                      variant="contained"
                      disableElevation
                      endIcon={<Chat />}
                    >
                      <span style={{ color: '#000' }}>Jump to thread</span>
                    </Button>
                  )}
                </Grid>
              </Box>
              {visit.annotatedPhotos.length ? (
                <>
                  {visit.annotatedPhotos.some(({ annotationLines }: any) => annotationLines?.length) ? (
                    <Annotations serviceVisitId={serviceVisitId} showOnly />
                  ) : (
                    <MyCoverImage visit={visit} hideControls />
                  )}
                </>
              ) : null}
              <Box my={2} className={classes.inventoryContainer}>
                {visit.fromNewEhr ? (
                  isLoadingServices && isLoadingProgressingSaving && isLoadingGetServiceVisitFromPatient ? (
                    <Skeleton height={150} style={{ margin: '0 17px' }} />
                  ) : (
                    <ServiceVisitScanner
                      display="contents"
                      serviceVisitId={serviceVisitId}
                      patientId={id}
                      pendingServices={[]}
                    />
                  )
                ) : null}
              </Box>

              {visit.fromNewEhr && visit.photos.length !== 0 && (
                <>
                  <Box my={2} className={classes.photosContainer}>
                    <BeforePhotos fromHistory />
                  </Box>
                  <Box my={2} className={classes.photosContainer}>
                    <AfterPhotos fromHistory />
                  </Box>
                </>
              )}

              <ServiceVisitNotes visit={visit} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmModal
        title={`Force ${visit.opened ? 'close' : 'open'}`}
        contentText={<ConfirmForceOpenCloseSVContent opened={visit.opened} />}
        openDialog={showForceOpenedModal}
        setOpenDialog={setShowForceOpenedModal}
        onClickConfirm={forceOpenCloseServiceVisit}
      />
    </>
  );
};

export default RowItem;
