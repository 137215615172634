import moment from 'moment';
import { WindowTarget, TableHeaderSet, IClientLead, IClientLeadStatus } from '../interfaces/IClientLead';

export const DATE_FORMAT_COMPACT = 'MM/DD/YY';
export const DATE_FORMAT_FULL = 'MMM DD YYYY';
export const FULLSCREEN = '(min-width:900px)';
export const NEW_WINDOW: WindowTarget = '_blank';
export const DEFAULT_SORT_KEY = 'createdAt';
export const DEFAULT_SELECTION = 'lead';
export const CSV_FILE_PFX = 'leads';
export const DEFAULT_SEARCH_TERMS = '_';
export const ROWS_PER_PAGE = 10;
export const EMPTY_CELL = '--';
export const EMPTY_HREF = '#';
export const NONE_CELL = 'none';
export const BOOKED = 'booked';
export const CONVERTED = 'converted';
export const NOT_INTERESTED = 'notInterested';

export const SEARCH_PLACEHOLDER = 'search clients';
export const STATUS_FILTER_TITLE = 'Lead Status';
export const PROVIDER_FILTER_TITLE = 'Provider';
export const COLUMN_SHRINK_PFX = 'last';
export const TEXT_CSV = 'text/csv';
export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const ALL_PRACTITIONERS = '0';

export const APPEND_CSV_COLUMNS = ['email', 'smsOptedIn'];

export const TABLE_LIST: TableHeaderSet = {
  lead: 'Working Lead ',
  converted: 'Converted',
  notInterested: 'Not Interested',
};

export const ADMIN_COLUMN_NAMES: TableHeaderSet = {
  firstName: 'Lead Name',
  status: 'Client Lead Status',
  practitionerName: 'Provider',
  originalSource: 'Source',
  originalCampaign: 'Campaign',
  notes: 'Notes',
  nextAppointment: 'Next Appointment',
  firstContactTime: 'First Contact',
  lastInboundMessageTime: 'Last SMS In',
  lastOutboundMessageTime: 'Last SMS Out',
  lastInboundCallTime: 'Last Call In',
  lastOutboundCallTime: 'Last Call Out',
  createdAt: 'Date Created',
};

const ADMIN_COLUMN_ARRAY = Object.entries(ADMIN_COLUMN_NAMES);

export const PRACTITIONER_COLUMN_NAMES: TableHeaderSet = {
  ...Object.fromEntries(ADMIN_COLUMN_ARRAY.slice(0, 2)),
  ...Object.fromEntries(ADMIN_COLUMN_ARRAY.slice(3)),
};

export const SORTABLE_COLS = ['firstName', 'status', 'originalCampaign', 'createdAt', 'originalSource'];

export const ERROR_EMAIL_EXISTS = 'Email already exists';
export const EMAIL_EXISTS_MESSAGE =
  "This email is already associated with another patient's record. Please provide a unique email to proceed";

export const REPORT_DATE_FORMAT = 'MM/DD/YY';
export const LEAD_REPORTS_DATE_RANGES = { month: 'Monthly', week: 'Weekly' };
export const ALL_CAMPAIGNS = '0';
export const DEFAULT_DATE_INTERVAL = 'month';

export const REPORT_COLUMNS_FULL = {
  provider: 'Provider',
  leads: 'Leads',
  leadsWithAppt: 'Leads w/ Appt',
  leadsWithRevenue: 'Leads w/ Revenue',
  leadsWithBb: 'Leads w/ Beauty Bank',
  totalRevenue: 'Total Revenue',
  avgRevenuePerLead: 'Avg Revenue per Lead',
  totalAppts: 'Total Appts',
  apptsPerLead: 'Appts per Lead',
  avgTimeToFirstMsg: 'Avg Time to First SMS',
  avgTimeToFirstMsgBizHours: 'Avg Time to First SMS (in Business Hours)',
  firstMsgInFiveMin: 'First SMS in 5 Min',
  firstMsgInFiveMinBizHours: 'First SMS in 5 Min (in Business Hours)',
  avgTimeToFirstCall: 'Avg Time to First Call',
  avgTimeToFirstCallBizHours: 'Avg Time to First Call (in Business Hours)',
  firstCallInFiveMin: 'First Call in 5 Min',
  firstCallInFiveMinBizHours: 'First Call in 5 Min (in Business Hours)',
  avgMsgCountPerLead: 'Avg Messages per Lead',
  avgCallCountPerLead: 'Avg Calls per Lead',
};

export const REPORT_COLUMNS = Object.keys(REPORT_COLUMNS_FULL);

export const SHOW_PERCENT: TableHeaderSet = {
  leadsWithAppt: 'leads',
  leadsWithRevenue: 'leads',
  leadsWithBb: 'leads',
};

export const PROVIDER_COLUMN_NAME = 'provider';

export const TEST_ROW_REPORT = {
  provider: 'Test Practitioner',
  leads: 15,
  leadsWithAppt: 5,
  leadsWithRevenue: 5,
  leadsWithBb: 2,
  totalRevenue: 1000.0,
  avgRevenuePerLead: 200.0,
  totalAppts: 6,
  apptsPerLead: 1,
  avgTimeToFirstMsg: '1 hour',
  firstMsgInFiveMin: '100%',
  avgTimeToFirstCall: '1 hour',
  firstCallInFiveMin: '100%',
  stage: moment().format(REPORT_DATE_FORMAT),
};

export const TEST_ROW_REPORT_ALT = {
  ...TEST_ROW_REPORT,
  stage: moment().subtract(1, 'month').format(REPORT_DATE_FORMAT),
};

export const ALL_CAMPAIGN_ROW = [ALL_CAMPAIGNS, 'All Campaigns'];

export const TEST_AD_CAMPAIGNS = [ALL_CAMPAIGN_ROW, ['campaign', 'Test Campaign']];

export const TEST_PROVIDERS = {
  [ALL_PRACTITIONERS]: 'All Providers',
  '1': 'Test Provider',
};

export const TEST_ROW_LEAD = {
  id: 1,
  practitionerId: 1,
  practitionerName: 'Test Practitioner',
  customerId: 1,
  createdAt: moment(),
  firstName: 'Test',
  lastName: 'Test',
  phone: '+12345678901',
  email: 'test@example.com',
  originalSource: 'facebook',
  originalCampaign: 'test_campaign',
  latestSource: 'facebook',
  latestCampaign: 'test_campaign',
  status: CONVERTED as IClientLeadStatus,
  totalSpend: 100,
  visitCount: 1,
  outboundCallTotal: 2,
  outboundSmsTotal: 4,
  cardOnFile: true,
  fullName: 'Test Test',
  openphoneConversationLink: 'https://my.openphone.com',
  nextAppointment: moment(),
  firstContactTime: moment(),
  lastInboundSmsTime: moment(),
  lastOutboundSmsTime: moment(),
  lastInboundCallTime: moment(),
  lastOutboundCallTime: moment(),
  notes: 'notes',
};

export const TEST_ROW_PAIR = [
  TEST_ROW_LEAD,
  {
    ...TEST_ROW_LEAD,
    practitionerId: 2,
    customerId: 2,
    id: 2,
  },
] as IClientLead[];
