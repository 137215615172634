import {
  MAX_PAYMENT_AUTH_ATTEMPTS,
  PAYMENT_STATUS_AUTHORIZED,
  PAYMENT_STATUS_AUTHORIZING,
} from 'src/constants/inventory.constants';
import { OrderPayment } from '.';

export const watchPaymentStatus = async ({ isError, onSuccess, onFail, enabled, attempts, status }: OrderPayment) => {
  if (enabled) {
    if (status === PAYMENT_STATUS_AUTHORIZED) {
      await onSuccess();
    } else {
      const webhookTimeoutError = attempts > MAX_PAYMENT_AUTH_ATTEMPTS;
      if (isError || (!!status && status !== PAYMENT_STATUS_AUTHORIZING) || webhookTimeoutError) {
        await onFail(webhookTimeoutError);
      }
    }
  }
};
