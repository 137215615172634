import React, { useEffect, useState } from 'react';
import {
  InputAdornment,
  Input,
  Button,
  IconButton,
  Dialog,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import {
  useReconstitutionExpiry,
  useUpdateReconstitutionExpiry,
} from '../../../../hooks/queries/useReconstitutionExpiry';
import { useStyles } from './SetReconstitutionExpiry.styles';
import { DialogOpenState } from '../../../../interfaces/ISectionProducts';

const ReconstitutionExpiryDialog: React.FC<DialogOpenState> = ({
  isOpen,
  toggleOpen,
}: DialogOpenState): React.ReactElement => {
  const [userInput, setUserInput] = useState<string | undefined>();

  const classes = useStyles();

  const { data: expirationDays, isLoading, isFetching } = useReconstitutionExpiry();
  const { mutateAsync: setExpirationDays, isLoading: isUpdating } = useUpdateReconstitutionExpiry();

  const loading = isLoading || isFetching || isUpdating;

  useEffect(() => {
    expirationDays && setUserInput(String(expirationDays.days));
  }, [expirationDays]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined = (event) => {
    const newVal = event.currentTarget?.value;
    setUserInput(parseInt(newVal || '0', 10).toString());
  };

  const handleClick = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    if (!userInput) {
      return;
    }
    await setExpirationDays(parseInt(userInput, 10));
    toggleOpen(event);
  };

  const handleClose = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    toggleOpen(event);
  };

  return (
    <Dialog open={isOpen}>
      <Box className={classes.dialogContent}>
        <IconButton size="small" onClick={handleClose} className={classes.rowIcon}>
          <CloseIcon />
        </IconButton>

        <Typography variant="h4">Reconstitution Settings</Typography>

        <Box className={classes.rowBox}>
          <Input
            name="days"
            value={userInput || ''}
            onChange={handleChange}
            disabled={loading}
            startAdornment={
              <InputAdornment className={classes.adornment} position="start">
                <span>Days</span>
              </InputAdornment>
            }
          />
          <Button onClick={handleClick} className={classes.save}>
            {loading ? <CircularProgress size={20} /> : 'Save'}
          </Button>
        </Box>
        <Typography className={classes.subtitle} variant="caption">
          Please use the input below to set the number of days a neurotoxin product is usable after reconstitution. Note
          that product will automatically expire after the given period and will no longer be usable.
        </Typography>
      </Box>
    </Dialog>
  );
};

const SetReconstitutionExpiry: React.FC = (): React.ReactElement => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = async (event: React.MouseEvent): Promise<void> => {
    event?.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button onClick={toggleOpen} className={classes.button}>
        Reconstitution Settings
      </Button>
      <ReconstitutionExpiryDialog isOpen={isOpen} toggleOpen={toggleOpen} />
    </>
  );
};

export { SetReconstitutionExpiry, SetReconstitutionExpiry as default };
