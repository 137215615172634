import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  timeConfigField: {
    width: '100%',
    background: 'white',
    color: 'black',
    fontSize: '14px',
  },
  workingDaysButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  activeWorkingDay: {
    marginRight: '0.5rem',
    width: '50px',
    borderRadius: '4px',
    color: 'white',
    background: '#12574D',
    padding: '0.5rem 1rem',
    '&:hover': {
      opacity: '90%',
      color: 'white',
      background: '#12574D',
    },
  },
  inactiveWorkingDay: {
    marginRight: '0.5rem',
    width: '50px',
    borderRadius: '4px',
    color: 'black',
    border: '1px solid #B9B9B9',
    background: 'white',
    padding: '0.5rem 1rem',
  },
  dayAvailabilityRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  dayAvailabilityDate: {
    width: '70px',
    textAlign: 'right',
    marginRight: '0.5rem',
  },
  dayAvailabilityInput: {
    width: '135px',
    margin: '0 0.5rem',
    height: '40px',
  },
  adjustTimeButton: {
    border: '1px solid #B9B9B9',
    color: '#B9B9B9',
    fontSize: '18px',
    borderRadius: '4px',
    padding: '0.25rem 0.5rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.5rem',
    minWidth: '41px',
    width: '41px',
    height: '41px',
    cursor: 'pointer',
  },
  subTitle: {
    color: '#706F6F',
    marginBottom: '1rem',
  },
  subText: {
    color: 'black',
    opacity: 0.4,
  },
  activeForSchedulingContainer: {
    margin: '1rem 0',
    padding: '1.5rem 1rem 2rem',
    border: '1px solid #12574D',
    background: 'white',
    height: '100px',
    '& > div': {
      display: 'block',
    },
  },
  activeForSchedulingSubText: {
    marginLeft: '30px',
    color: 'black',
    opacity: 0.4,
    textWrap: 'wrap',
    wordBreak: 'break-word',
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'right',
    marginRight: '2rem',
  },
  notificationSaveButtonContainer: {
    display: 'flex',
    justifyContent: 'right',
    margin: '2rem 0',
  },
  saveButton: {
    color: 'white',
    border: 'none',
    background: '#12574D',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      color: 'white',
      background: '#12574D',
      opacity: '90%',
    },
    '&:disabled': {
      color: 'white',
      background: '#12574D',
      opacity: '60%',
    },
  },
  locationSelect: {
    width: '500px',
  },
  missingDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    width: '100%',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '700px',
    height: '500px',
    margin: 'auto',
    borderRadius: '8px',
  },
  modalContent: {
    backgroundColor: 'white',
    width: '100%',
    textAlign: 'center',
    padding: '1rem 0',
  },
  modalBody: {
    margin: 'auto',
    marginBottom: '2rem',
    width: '70%',
  },
  warningIcon: {
    marginTop: '2rem',
  },
});
