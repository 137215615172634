/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { MEDSPA_LOCATION_DETAILS_COLUMNS } from 'src/constants/medspaAdmins/columns.constants';
import { useUpdateMedspaLocationPractitioners } from 'src/hooks/queries/medspaAdmins/useMedspaLocationPractitioners';
import { sortBy } from 'lodash';
import { PortraitTable } from '../../common/TableNew/Table';
import { ProfileImageWithText } from '../../common/ProfileImage';
import { EHRSwitch } from '../../ui/v1';
import { IPractitioner } from '../../../interfaces/IPractitioner';

export const useStyles = makeStyles(() => ({
  detailContainer: {
    // border: 'solid 5px #f2f5f5',
    borderRadius: '15px',
    padding: '20px',
    backgroundColor: 'white',
  },
  editButton: {
    background: '#1d584d',
    color: 'white',
    float: 'right',
  },
  bookingLink: {
    color: '#12574d',
    cursor: 'pointer',
  },
  detailProvidersContainer: {
    height: 'auto',
  },
}));

const MedpsaLocationPractitionerRow = ({ practitioner }: { practitioner: any }) => {
  const [activeForScheduling, setActiveForScheduling] = useState<boolean>(false);

  useEffect(() => {
    setActiveForScheduling(practitioner.activeForScheduling);
  }, [practitioner]);

  const { mutateAsync: updateMedspaLocation } = useUpdateMedspaLocationPractitioners();
  const handleChangeActiveForScheduling = async (active: boolean, id: number) => {
    await updateMedspaLocation({
      medspaLocationPractitioner: {
        activeForScheduling: active,
      },
      id,
    });
    setActiveForScheduling(active);
  };

  return (
    <TableRow data-cy="row" key={practitioner.id}>
      <TableCell>
        <ProfileImageWithText src={practitioner?.profileImageUrl} text={practitioner.fullName} />
      </TableCell>
      <TableCell>{practitioner.licenses}</TableCell>
      <TableCell>{practitioner.email}</TableCell>
      <TableCell>
        <EHRSwitch
          onChange={(e) => {
            handleChangeActiveForScheduling(e.target.checked, practitioner.medspaLocationPractitionerId);
          }}
          checked={activeForScheduling}
          color="primary"
        />
      </TableCell>
    </TableRow>
  );
};

export const MedspaAdminLocationTableDetails = (props: any) => {
  const classes = useStyles();
  const { row, onEditMedspaLocation } = props;

  return (
    <Box className={classes.detailContainer}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <h3>Providers</h3>
          <span style={{ color: 'gray' }}>All the providers working under this location</span>
          {/* <div
            className={classes.bookingLink}
            onClick={() => copyToClipboard(`${SIGN_UP_ROUTES.medspaLocationBooking}/${row.slug}`)}
          >
            Booking link
          </div> */}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={8}>
          <Button className={classes.editButton} onClick={() => onEditMedspaLocation(row)}>
            Edit
          </Button>
          <PortraitTable
            tableStyles={classes.detailProvidersContainer}
            isLoading={false}
            infiScroll={false}
            rowComponent={(practitioner) => <MedpsaLocationPractitionerRow practitioner={practitioner} />}
            errorMessage="Error fetching practitioners for this location"
            data={sortBy(row.practitioners, (practitioner: IPractitioner) => practitioner.firstName) || []}
            columns={MEDSPA_LOCATION_DETAILS_COLUMNS}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
