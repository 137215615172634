import { useQuery } from 'react-query';
import { CONTACT_INFO } from '../../constants/reactQuery.keys';
import Patients from '../../services/Patients';

const { getContactInfo } = Patients;

export function useCustomContactInfo(customerId: string) {
  return useQuery([CONTACT_INFO, customerId], () => getContactInfo(customerId));
}

export { CONTACT_INFO } from '../../constants/reactQuery.keys';
