/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useState, useEffect } from 'react';
import { IconButton, TableRow, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { TableCellCustom, useStyle as tableStyle } from '../TodoListTabs/rowItem.styles';
import { rowStyle } from './marketing.styles';
import { TemplateEdit, TemplateEditProps } from './TemplateEdit';
import { IUpdateSMSTemplate, CampaignRowProps } from '../../../../interfaces/IPractitionerSMSTemplate';
import { useUpdateSMSTemplates } from '../../../../hooks/queries/usePractitionerSMSTemplates';
import { SelectList as ReportList, SelectListItems } from '../../../common/SelectList';

const TABLE_LIST: SelectListItems = {
  active: 'active',
  paused: 'paused',
};

const RowItem: React.FC<CampaignRowProps> = ({ row, practitionerId }: CampaignRowProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [paused, setPaused] = useState<boolean>(false);

  const classes = { ...tableStyle(), ...rowStyle() };

  useEffect(() => {
    setPaused(!row.active);
  }, []);

  const { mutate: mutateTemplateData } = useUpdateSMSTemplates(row.templateId);

  const handleStatusToggle = (status: string | null): void => {
    const active = status === 'active';
    if (paused === active) {
      const data: { active: boolean; practitionerId?: number } = { active };
      if (practitionerId) {
        data.practitionerId = practitionerId;
      }
      mutateTemplateData(data as IUpdateSMSTemplate);
      setPaused(!active);
    }
  };

  const template: TemplateEditProps = {
    body: row.body,
    macros: row.macros,
    open,
    setOpen,
    practitionerId,
    mutateTemplateData,
  };

  return (
    <>
      <TableRow className={[classes.ItemTable, classes.rowItem].join(' ')}>
        <TableCellCustom width={200} className={classes.rowFirst}>
          <Typography className={classes.pill}>
            <Typography className={classes.pilltext}>{row.label}</Typography>
          </Typography>
        </TableCellCustom>

        <TableCellCustom width={550} className={classes.rowItem}>
          {row.description}
        </TableCellCustom>
        <TableCellCustom width={120} className={classes.patientName}>
          <ReportList
            title=""
            className={classes.shortselect}
            listItems={TABLE_LIST}
            variant="standard"
            selected={paused ? 'paused' : 'active'}
            setSelected={handleStatusToggle}
          />
        </TableCellCustom>
        <TableCellCustom width={80}>
          <IconButton onClick={() => setOpen(!open)}>{open ? null : <Edit viewBox="0 0 22 22" />}</IconButton>
          <Typography className={classes.edittext} onClick={() => setOpen(!open)}>
            {open ? ' Cancel' : ' Edit'}
          </Typography>
        </TableCellCustom>
      </TableRow>
      <TemplateEdit {...template} />
    </>
  );
};

export { RowItem, RowItem as default };
