import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CardContent } from '@material-ui/core';
import { Card, SmallTitle } from '../../common/card';
import { ActiveStepWrapper } from '../ActiveStepWrapper';
import { dispatch } from '../../../rematch';
import { Notes } from './Notes';
import { Annotations } from './Annotations';

export const AnnotationsStep = () => {
  const { serviceVisitId }: any = useParams();
  const { firstName, lastName } = useSelector((store: any) => store.patient);
  const { serviceVisit } = useSelector((store: any) => store.newServiceVisit);

  const updateCurrentStep = (): void => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 10 });
  };

  return (
    <ActiveStepWrapper step={10} onClick={updateCurrentStep}>
      <Card style={{ marginBottom: '0', minHeight: '148px' }}>
        <CardContent style={{ margin: 0 }}>
          <SmallTitle title="Medical charting" style={{ margin: 0 }} />
          <p style={{ color: 'gray', fontFamily: 'Messina Sans Regular', fontSize: '14px', marginTop: '6px' }}>
            If you charged <b>{`${firstName}, ${lastName}`}</b> for this visit, you may chart later via your to-do list
          </p>
          <Annotations serviceVisitId={+serviceVisitId} showOnly={!serviceVisit.opened} />
          <Notes />
        </CardContent>
      </Card>
    </ActiveStepWrapper>
  );
};
