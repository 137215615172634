import API_ROUTES from 'src/constants/apiRoutes.constants';
import { filterUnusedParams } from 'src/utils/global';
import IServices from 'src/interfaces/IServices';
import Api from './Api';

export interface IOrderProduct {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  name: string;
  description: string;
  listPrice: number;
  minimumOrderQuantity: number;
  itemCount: number;
  unitsPerItem: number;
  savings: number;
  supplierId: number | null;
  supplierName: string | null;
  rolePrice?: number;
}

export type IOrderProductAdmin = IOrderProduct & {
  currentCost: number;
  currentCostWithGuaranteedRebates: number;
  currentCostWithAllRebates: number;
  legacyPrice: number;
  legacyPlusPrice: number;
  flexPrice: number;
  luxePrice: number;
  litePrice: number;
  active: boolean;
};

export interface ProductMeta {
  currentPage: number;
  totalPages: number;
  totalCount: number;
}

export interface ProductResponse<T = IOrderProduct> {
  meta: ProductMeta;
  data: T[];
}

export type ProductImportParams = { products: Partial<IOrderProductAdmin>[]; upsert?: boolean };
export type ProductCreateParams = Partial<IOrderProductAdmin> & { upsert?: boolean };

export default {
  async getAllProducts(): Promise<IServices[]> {
    const { data } = await Api.get('/v4/inventory_orders/order_products');
    return data;
  },
  async getProducts(params: { page?: number; limit?: number } = {}): Promise<ProductResponse> {
    return Api.get(
      API_ROUTES.INVENTORY_ORDER_PRODUCTS(),
      filterUnusedParams<{ page?: number; limit?: number }, number | undefined>(params)
    );
  },

  async getAdminProducts(params: { page?: number; limit?: number } = {}): Promise<ProductResponse<IOrderProductAdmin>> {
    return Api.get(
      API_ROUTES.INVENTORY_ORDER_PRODUCTS(),
      filterUnusedParams<{ page?: number; limit?: number }, number | undefined>(params)
    );
  },

  async createProduct(params: ProductCreateParams): Promise<IOrderProductAdmin> {
    const { data }: { data: IOrderProductAdmin } = await Api.post(API_ROUTES.INVENTORY_ORDER_PRODUCTS(), params);
    return data;
  },

  async createProducts(params: ProductImportParams): Promise<IOrderProductAdmin[]> {
    const { data }: { data: IOrderProductAdmin[] } = await Api.post(API_ROUTES.INVENTORY_ORDER_PRODUCTS(), params);
    return data;
  },

  async updateProduct(params: Partial<IOrderProductAdmin>): Promise<IOrderProductAdmin> {
    const { data }: { data: IOrderProductAdmin } = await Api.put(
      API_ROUTES.INVENTORY_ORDER_PRODUCTS(params.id),
      params
    );
    return data;
  },

  async destroyProduct(id: number): Promise<IOrderProductAdmin> {
    const { data }: { data: IOrderProductAdmin } = await Api.delete(API_ROUTES.INVENTORY_ORDER_PRODUCTS(id));
    return data;
  },
};
