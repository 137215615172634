import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonServiceGroup: {
    display: 'flex',
    padding: '10px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '270PX',
    maxWidth: '340px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'initial',
      maxWidth: 'initial',
      width: '100%',
    },
    height: '53px',
    borderRadius: '12px',
    textAlign: 'left',
    fontSize: '0.8rem',
    color: '#12574D',
  },
  amountOfServicesAndArrowForwardIcon: {
    display: 'flex',
  },
  amountOfSelectedServices: {
    width: '1.6rem',
    height: '1.6rem',
    margin: 'auto 0.5rem',
    fontSize: '0.9rem',
    backgroundColor: '#C9D9D7',
    color: '#12574D',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ArrowForwardIcon: {
    alignSelf: 'center',
  },
}));
