import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { assignOrderStatus } from 'src/utils/inventoryOrdering';
import { DATE_FORMAT_FULL } from 'src/constants/clientLead.constants';
import { Error as ErrorIcon, MoreVert as MoreVertIcon, Close as CloseIcon } from '@material-ui/icons';
import { MEDSPA_ADMIN_NEW_ORDER, MEDSPA_ADMIN_PROVIDERS } from 'src/routes/medspaAdminRoutes';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, USER_TYPES } from 'src/constants/general.constants';
import { FEATURE_INVENTORY_ORDERING_FLEX_LUXE_LITE } from 'src/constants/features.constants';
import useCurrentUserGroup from 'src/hooks/queries/useUserGroups';
import OrderHistory from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderHistory';
import Page from 'src/components/common/Page';
import { ILocation, IMedspaLocation } from 'src/interfaces/ILocation';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { hasFeatureEnabled } from 'src/utils/auth.utils';
import OrderLoading from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderLoading';
import { EHRButton } from 'src/components/ui/v1';
import EmptyPage from 'src/components/common/EmptyPage';
import OrderStatus from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderStatus';
import { OrderCharges } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderCharges';
import { ROUTES } from 'src/constants/routes.constants';
import { VideoModal } from 'src/pages/MedspaAdmin/Onboarding/components/VideoModal';
import { VideoBanner } from 'src/pages/MedspaAdmin/Onboarding/components/VideoBanner';
import { ONBOARDING_VIDEOS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import OrderTrackingNumbers from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderTrackingNumbers';
import {
  OrderHistoryRowParams,
  InventoryOrderWithId,
  InventoryOrderItem,
  InventoryOrderSpecial,
} from 'src/interfaces/IInventoryOrder';
import { useCancelOrder, useInventoryOrders } from 'src/hooks/queries/useInventoryOrders';
import { formatCurrency } from 'src/utils/formatNumber';
import { PENDING_STEPS_FOR_INVENTORY_WARNING } from 'src/constants/onboarding.constants';
import Banner from 'src/components/common/Banner';
import { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import OrderConfirmationNumbers from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderConfirmationNumbers';
import IconCross from '../../common/IconCross';
import { IconLeftAccordionSummary, TableCellHeader, useStyle } from './inventory.styles';
import DeliveryAddress from '../../DashboardPractitioner/Tabs/OrderingTab/DeliveryAddress';
import { OrderHistorySubRow, OrderHistorySpecialSubRow, OrderHistorySubRowCustom } from './OrderHistorySubRow';
import PricingPdf from './PricingPdf';

const TableHeader = () => {
  const classes = useStyle();

  return (
    <TableHead>
      <TableRow className={classes.grayHeader}>
        <TableCellHeader>Product Name</TableCellHeader>
        <TableCellHeader>Market Price</TableCellHeader>
        <TableCellHeader>You save</TableCellHeader>
        <TableCellHeader>You pay</TableCellHeader>
        <TableCellHeader>Quantity</TableCellHeader>
        <TableCellHeader>Total</TableCellHeader>
      </TableRow>
    </TableHead>
  );
};

const OrderHistoryRow = ({ orderDetails, autoOpen, setShowLoadingSpinner }: OrderHistoryRowParams) => {
  const classes = useStyle();
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  const { mutateAsync: cancelOrder } = useCancelOrder(orderDetails.id);
  const status = assignOrderStatus(orderDetails);
  const { adminNote, referenceNumber } = orderDetails;
  const showSavings = !!orderDetails.savingsTotal;

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setMenuAnchor(menuAnchor ? null : event.currentTarget);

  const handleCancelOrder = async (): Promise<void> => {
    setMenuAnchor(null);
    setShowLoadingSpinner?.(true);
    await cancelOrder();
    setShowLoadingSpinner?.(false);
  };

  // const handleEditOrder = async (): Promise<void> => {
  //   setMenuAnchor(null);
  //   setShowLoadingSpinner?.(true);
  //   await editOrder(false);
  //   history.push(userType === USER_TYPES.PRACTITIONER ? ROUTES.FLEX_ORDER : MEDSPA_ADMIN_NEW_ORDER);
  // };

  useEffect(() => {
    setExpanded(autoOpen);
  }, []);

  const expandIcon = (
    <IconButton size="small" onClick={() => setExpanded(!expanded)} data-testid={`order expand ${orderDetails.id}`}>
      <IconCross open={expanded} />
    </IconButton>
  );

  return (
    <Accordion expanded={expanded}>
      <IconLeftAccordionSummary expandIcon={expandIcon}>
        <Box className={classes.accordionSummary}>
          <Typography className={classes.accordionTag}>Order #{referenceNumber}</Typography>
          <Typography className={classes.orderTotalTag}>Total: {formatCurrency(orderDetails.total ?? 0)}</Typography>
          <Typography className={classes.orderDate}>
            Placed on {moment(orderDetails.createdAt).format(DATE_FORMAT_FULL)}
          </Typography>
          <OrderStatus status={status} />
          <IconButton onClick={handleMenuClick} className={classes.orderMenu}>
            {menuAnchor ? <CloseIcon /> : <MoreVertIcon />}
          </IconButton>
          <Menu
            open={Boolean(menuAnchor)}
            anchorEl={menuAnchor}
            onClose={handleMenuClick}
            PaperProps={{ className: classes.orderMenuOpen }}
          >
            {/* <MenuItem className={classes.orderMenuItem} disabled={!orderDetails.editable} onClick={handleEditOrder}>
              Edit
            </MenuItem> */}
            <MenuItem className={classes.orderMenuItem} disabled={!orderDetails.cancelable} onClick={handleCancelOrder}>
              Cancel
            </MenuItem>
          </Menu>
        </Box>
      </IconLeftAccordionSummary>

      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Box display="flex" flexDirection="row" className={classes.deliveryNotice}>
          <ErrorIcon />
          <Typography component="p">
            Please note that someone must be able to receive the package on the day of the delivery. Deliveries can
            arrive as early as <span>8:00am</span>
          </Typography>
        </Box>

        <DeliveryDetailsAdmin
          adminNote={adminNote}
          desiredDeliveryDate={orderDetails.desiredDeliveryDate}
          deliveryLocation={orderDetails?.deliveryLocation}
        />

        <TableContainer data-testid={`order details ${orderDetails.id}`}>
          <Table>
            <TableHeader />
            <TableBody className={classes.historyRow}>
              {orderDetails.customItems.map((orderItem: InventoryOrderCustomItem) => (
                <OrderHistorySubRowCustom {...orderItem} key={orderItem.name} />
              ))}
              {orderDetails.services.map((orderItem: InventoryOrderItem) => (
                <OrderHistorySubRow {...orderItem} key={orderItem.name} />
              ))}
              {orderDetails.specials.map((orderItem: InventoryOrderSpecial) => (
                <OrderHistorySpecialSubRow special={orderItem} key={orderItem.id} />
              ))}
              <OrderCharges
                charges={orderDetails.charges}
                status={status}
                subtotal={orderDetails.subtotal}
                transactionFeePct={orderDetails.transactionFeePct}
                colFill={4}
                readOnly
                tableInsert
              />
              <TableRow>
                {!showSavings ? (
                  <TableCell colSpan={4} />
                ) : (
                  <TableCell colSpan={4} className={classes.totalRow}>
                    Saved: {formatCurrency(orderDetails.savingsTotal ?? 0)}
                  </TableCell>
                )}
                <TableCell className={classes.totalRowLabel}>Total:</TableCell>
                <TableCell width={175} className={classes.totalRowValue}>
                  {formatCurrency(orderDetails.total ?? 0)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {orderDetails.approved && (
          <>
            <OrderConfirmationNumbers
              confirmationNumbers={orderDetails.orderConfirmationNumbers ?? []}
              colFill={5}
              readOnly
            />
            <OrderTrackingNumbers trackingNumbers={orderDetails.shipmentTrackingNumbers ?? []} colFill={4} readOnly />
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export const DeliveryDetailsAdmin: React.FC<{
  adminNote: string | null;
  desiredDeliveryDate: string;
  deliveryLocation: ILocation | IMedspaLocation | null | undefined;
}> = ({ adminNote, desiredDeliveryDate, deliveryLocation }) => {
  const classes = useStyle();

  return (
    <Box
      display="flex"
      padding="1rem"
      borderBottom="1px solid #ddd"
      borderTop="1px solid #ddd"
      className={classes.deliveryNotes}
    >
      <Box flexDirection="column" marginRight="5rem">
        <Typography component="p">Delivery Address</Typography>
        <DeliveryAddress location={deliveryLocation} />
      </Box>
      <Box flexDirection="column" marginRight="5rem">
        <Typography component="p">Preferred Date</Typography>
        <Typography component="p">{moment.utc(desiredDeliveryDate).format(DATE_FORMAT_FULL)}</Typography>
      </Box>
      {adminNote && (
        <Box flexDirection="column">
          <Typography component="p">Admin Note:</Typography>
          <Typography component="p">{adminNote || ''} </Typography>
        </Box>
      )}
    </Box>
  );
};

const MedspaAdminInventory = () => {
  const history = useHistory();
  const classes = useStyle();
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
  // const [supplierDialogOpen, setSupplierDialogOpen] = useState<boolean>(false);
  const [openEhrOnboardingSuccessModal, setOpenEhrOnboardingSuccessModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const { userType, featureList, isLoading: isLoadingAuth } = useSelector(({ auth }: RootState) => auth);
  const { data: userGroupData, isLoading: isLoadingUserGroup } = useCurrentUserGroup();
  const isInitializing = isLoadingAuth || isLoadingUserGroup;

  const {
    FooterComponent,
    DoneFooterComponent,
    onboardingEnabled,
    pendingMedicalDirector,
    pendingCommercialShippingAddress,
  } = useMedspaAdminOnboarding();

  const shouldRestrictAccess = pendingMedicalDirector || pendingCommercialShippingAddress;

  const isPractitioner = userType === USER_TYPES.PRACTITIONER;

  const nonLegacyOrderingAllowed = isPractitioner
    ? hasFeatureEnabled(FEATURE_INVENTORY_ORDERING_FLEX_LUXE_LITE, featureList)
    : userGroupData?.features?.some((feat) => feat.key === FEATURE_INVENTORY_ORDERING_FLEX_LUXE_LITE);

  const isLegacy = userGroupData?.roleName === PORTRAIT_LEGACY || userGroupData?.roleName === PORTRAIT_LEGACY_PLUS;

  const { data: orderHistory, isLoading: isLoadingOrderHistory } = useInventoryOrders({
    disabled: isInitializing || isLegacy,
  });

  const isLoading = isInitializing || isLoadingOrderHistory;

  const gotoOrderForm = () => {
    history.push(isPractitioner ? ROUTES.FLEX_ORDER_HISTORY : MEDSPA_ADMIN_NEW_ORDER);
  };

  const PlaceAnOrderButton = () => (
    <EHRButton
      dataCy="view"
      onClick={gotoOrderForm}
      color="primary"
      text="Place an Order"
      data-testid="current order button"
      disabled={shouldRestrictAccess ?? undefined}
    />
  );

  // const SupplierDetailsButton = () => (
  //   <EHRButton
  //     text="Supplier Details"
  //     dataCy="supplierDetails"
  //     color="primary"
  //     onClick={() => setSupplierDialogOpen(true)}
  //   />
  // );

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const openEhrOnboardingModalValue = queryParams.get('openEhrOnboardingModal');

    if (openEhrOnboardingModalValue) {
      setOpenEhrOnboardingSuccessModal(openEhrOnboardingModalValue === 'true');
    }
  }, [history]);

  const warningBanner = (
    <div style={{ padding: '2rem' }}>
      <Banner content={PENDING_STEPS_FOR_INVENTORY_WARNING} type="warning" />
    </div>
  );

  if (isLoading) {
    return <OrderLoading />;
  }

  if (isLegacy) {
    return <OrderHistory />;
  }

  if (nonLegacyOrderingAllowed && orderHistory?.length === 0) {
    return (
      <>
        {shouldRestrictAccess && warningBanner}

        {onboardingEnabled && (
          <div style={{ padding: '2rem' }}>
            <VideoBanner title="How to add orders to your MedSpa?" onClick={() => setShowVideoModal(true)} />
          </div>
        )}
        <EmptyPage title="Inventory Ordering">
          {isPractitioner && <Box height="4rem" />}
          <h1>Welcome to Your Inventory Ordering</h1>
          <p>It looks like you haven’t added your first order yet. </p>
          <p>Click below to place your first order.</p>
          <Box marginTop="24px">
            <PlaceAnOrderButton />
          </Box>
          {/* <Box marginTop="24px">
            <SupplierDetailsButton />
          </Box> */}
          {/* <SupplierDetails open={supplierDialogOpen} dismiss={() => setSupplierDialogOpen(false)} /> */}
        </EmptyPage>

        <FooterComponent currentStep="an order" nextStep="Add Provider" urlToNextStep={MEDSPA_ADMIN_PROVIDERS} />

        <VideoModal
          open={showVideoModal}
          onClose={() => setShowVideoModal(false)}
          url={ONBOARDING_VIDEOS.INVENTORY_ORDER_NAME}
        />
      </>
    );
  }

  return !nonLegacyOrderingAllowed ? (
    <PricingPdf shouldRestrictAccess={shouldRestrictAccess ?? undefined} restrictionComponent={warningBanner} />
  ) : (
    <>
      <Page title="Inventory Ordering">
        <Box className={classes.orderDashboard} data-testid="order history">
          <Box className={classes.orderFlash}>
            {/* <SupplierDetailsButton /> */}
            {!isPractitioner && <PlaceAnOrderButton />}
          </Box>
          {onboardingEnabled && (
            <VideoBanner title="How to add orders to your MedSpa?" onClick={() => setShowVideoModal(true)} />
          )}
          {orderHistory?.map((orderRow: InventoryOrderWithId, index: number) => (
            <OrderHistoryRow
              key={orderRow.createdAt.toString()}
              orderDetails={orderRow}
              autoOpen={!isLoadingOrderHistory && index === 0}
              setShowLoadingSpinner={setShowLoadingSpinner}
            />
          ))}
        </Box>
        {/* <SupplierDetails open={supplierDialogOpen} dismiss={() => setSupplierDialogOpen(false)} /> */}
        {!isLoading && showLoadingSpinner && <OrderLoading />}
      </Page>

      <FooterComponent currentStep="an order" nextStep="Add Provider" urlToNextStep={MEDSPA_ADMIN_PROVIDERS} />

      {openEhrOnboardingSuccessModal && <DoneFooterComponent />}

      <VideoModal
        open={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        url={ONBOARDING_VIDEOS.INVENTORY_ORDER_NAME}
      />
    </>
  );
};

export { MedspaAdminInventory, MedspaAdminInventory as default };
