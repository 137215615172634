import { TableCell, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface RowClass {
  [key: string]: string | number
}

export interface RowStyles {
  [key: string]: RowClass
}

export const TableCellHeader = withStyles(() => ({
  root: {
    fontSize: '17px', 
    padding: '30px 16px 25px 20px'
  },
}))(TableCell);

export const rowStyle = makeStyles((): RowStyles => ({
  shortselect: {
    minWidth: 'unset',
    width: '100px',
    height: '65px',
    fontSize: '13px'
  },
  edittext: {
    verticalAlign: 'middle'
  },
  rowFirst: {
    fontSize: '14px'
  },
  rowItem: {
    fontSize: '14px'
  },
  pill: {
    color: '#111',
    display: 'flex',
    padding: '4px',
    minHeight: '56px',
    borderRadius: '12px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: '1px solid rgba(41,86,77,0.45)',
    fontSize: '15px'
  },
  pilltext: {
    margin: 'auto',
    marginLeft: 5
  },
  actionButton: {
    fontSize: '16px',
    fontFamily: 'Messina Sans SemiBold',
    width: '98%',
    height: '36px',
    borderColor: '#ccc',
    color: '#12574d',
  }
}))


export const templateStyle = makeStyles((): RowStyles => ({
  actionButton: {
    fontSize: '16px',
    fontFamily: 'Messina Sans SemiBold',
    width: '180px',
    height: '46px',
    borderColor: '#ccc',
    color: '#fff',
    backgroundColor: 'rgb(41,86,77)',
    margin: 'auto',
    marginTop: '25px',
    marginBottom: '25px',
    marginLeft: 0
  },
  editor: { 
    fontSize: '14px', 
    color: '#333', 
    backgroundColor: '#fefefe', 
    padding: '15px', 
    borderRadius: '3px', 
    width: '85%',
    height: '145px',
    border: '2px solid #ddd',
    marginTop: '10px',
    resize: 'none',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  slim: { 
    paddingBottom: 0, 
    paddingTop: 0
  },
  slimTop: { 
    paddingBottom: 0, 
    paddingTop: 0,
    verticalAlign: 'top'
  },
  slimFlex: { 
    paddingBottom: 0, 
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  typography: { 
    color: '#000', 
    fontSize: 14 ,
    marginTop: '20px'
  },
  macrobutton: { 
    background: '#fff', 
    textTransform: 'capitalize',
    marginRight: '10px',
    marginTop: '8px',
    marginBottom: '10px',
    margin: 'auto auto auto 10px',
    border: '1px solid rgb(41,86,77)',
    borderRadius: '16px',
    height: '34px',
    minWidth: '122px'
  },
  footer: {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    backgroundColor: '#fafafa',
    marginTop: '-12px',
    width: '85%',
    display: 'flex',
    paddingTop: '10px',
    borderLeft: '2px solid #ddd',
    borderRight: '2px solid #ddd',
    borderBottom: '2px solid #ddd',
    flexWrap: 'wrap'

  }
}))