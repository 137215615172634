import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Box, TextField } from '@material-ui/core';
import classnames from 'classnames';
import { useStyles } from './confirmAdvocate.styles';

interface Props {
  open: boolean;
  closeModal: () => void;
  onConfirm: (advocateReason: string) => void;
  isLoadingToggleIsAdvocate: boolean;
}

const ConfirmAdvocate = ({ open, closeModal, onConfirm, isLoadingToggleIsAdvocate }: Props) => {
  const classes = useStyles();
  const [advocateReason, setAdvocateReason] = useState<string>('');

  const handleOnYes = () => {
    if (advocateReason) {
      onConfirm(advocateReason);
    }
  };

  return (
    <Dialog open={open} onClose={closeModal} classes={{ paper: classes.dialog }}>
      <Box padding="60px" width="520px">
        <Box marginTop="13px" marginBottom="22px" className={classes.callToAction}>
          Please provide a reason this patient is an Advocate
        </Box>
        <Box className={classes.body}>
          <TextField
            placeholder="Influencer with IG handle, Sola Esthetician, etc"
            type="text"
            size="medium"
            name="advocateReason"
            fullWidth
            disabled={isLoadingToggleIsAdvocate}
            value={advocateReason}
            onChange={(e: any) => setAdvocateReason(e.target.value)}
          />
        </Box>
        <Box textAlign="center">
          <Button className={classnames(classes.baseButton, classes.cancelButton)} title="No" onClick={closeModal}>
            Cancel
          </Button>
          <Button className={classnames(classes.baseButton, classes.yesButton)} onClick={handleOnYes}>
            {isLoadingToggleIsAdvocate ? 'Loading...' : 'Make an Advocate'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmAdvocate;
