import Api from './Api';
import ICareMessage, { ICustomCareMessage } from '../interfaces/ICareMessages';

const Services = {
  async getCareMessages(): Promise<ICareMessage[]> {
    const { careMessages } = await Api.get('/v3/care_messages');
    return careMessages;
  },
  async getCareMessageTemplates(): Promise<ICustomCareMessage[]> {
    const { careMessages } = await Api.get('/v3/service_groups/care_message_suggestions');
    return careMessages;
  },
  async updatePractitionerCareMessage(params: any): Promise<boolean> {
    const { success } = await Api.put('/v3/care_messages', params);
    return success;
  },
  async createPractitionerCareMessage(params: any): Promise<{ success: boolean; careMessages: ICustomCareMessage }> {
    return Api.post('/v3/care_messages', params);
  },
};

export default Services;
