import Id from '../types/Id';
import Api from './Api';

const NewPhotoRequest = {
  async updatePhotoRequestStatus(patientId: Id, payload: any) {
    const { success } = await Api.post(`/v3/customers/${patientId}/update_photo_request_status`, payload);
    return success;
  },
  async getNewPhotoRequestStatus(patientId: Id) {
    const { success, data } = await Api.get(`/v3/customers/${patientId}/get_photo_request_status`);
    return success ? data : [];
  },
};

export default NewPhotoRequest;
