import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Button, Box, FormControl, InputLabel, Select, MenuItem, Checkbox } from '@material-ui/core';
import { usePractitionerPrimaryServices } from 'src/hooks/practitioner/usePractitioner';
import { useStyles } from './index.styles';
import {
  useServices,
  useImportServiceGroupsMutation,
  useDefaultServiceGroups,
  useDefaultServices,
} from '../../../hooks/queries/services/useServices';
import { ShortMultipleSkeleton } from '../../../components/common/LoadingSkeleton';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import IServices from '../../../interfaces/IServices';
import ServiceTable from './ServiceTable';
import useSuppliers from '../../../hooks/queries/useSuppliers';
import ButtonAhref from '../../../components/common/ButtonAhref';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH } from '../../../routes/practitionerRoutes';
import ServiceGroupModal from './ServiceGroupModal';
import { useProfileProgress } from '../../../hooks/queries/useProfileProgress';
import DialogModal from './DialogModal';
import { useServiceGroups } from '../../../hooks/queries/serviceGroups/useServiceGroup';
import { useUpdateProfileProgress } from '../../../hooks/mutations/useUpdateProfileProgress';
import ServiceModal from './ServiceModal';

const filtersInitialState = {
  status: '',
  supplierId: '',
};

const ServicesManagementComponent = ({ renderLocation }: { renderLocation?: string }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const serviceGroupId = params.get('serviceGroupId');
  const serviceId = params.get('serviceId');
  const firstTime = params.get('firstTime');
  const howWork = params.get('howWork');

  const ViewPrimaryServicesLibrary = params.get('ViewPrimaryServicesLibrary');
  const { data: services = [], refetch: refetchServices, isLoading: isLoadingServices } = useServices();
  const { data: serviceGroups, refetch: refetchServiceGroups, isLoading: isLoadingServiceGroups } = useServiceGroups();
  const { data: suppliers, isFetching: isFetchingSuppliers } = useSuppliers({ kind: serviceId ? 'default' : '' });
  const { data: defaultServiceGroups, isLoading: isLoadingDefaultServiceGroups } = useDefaultServiceGroups();
  const { data: defaultServices, isFetching: isLoadingDefaultServices } = useDefaultServices();
  const [saving, setSaving] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(filtersInitialState);
  const { data: progress, isLoading } = useProfileProgress();
  const {
    data: {
      selectedServiceGroupsFromPrimaryLibrary: currentSelectedServiceGroups,
      selectedServicesFromPrimaryLibrary: currentSelectedServices,
    },
    isLoading: isLoadingCurrentSelectedPrimaryServices,
  } = usePractitionerPrimaryServices();
  const [selectedServiceGroups, setSelectedServiceGroups] = useState<number[]>([]);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const importServiceGroupsMutation = useImportServiceGroupsMutation();
  const updateProfileProgressMutation = useUpdateProfileProgress();

  useEffect(() => {
    setSelectedServiceGroups(currentSelectedServiceGroups);
    setSelectedServices(currentSelectedServices);
  }, [JSON.stringify(currentSelectedServiceGroups), JSON.stringify(currentSelectedServices)]);

  const servicesManagementStepCompleted = useMemo(
    () => progress?.stepsCompleted.includes('services-management') || false,
    [progress]
  );

  const servicesToShow = useMemo(() => {
    if (servicesManagementStepCompleted && !ViewPrimaryServicesLibrary) {
      return services;
    }
    return defaultServices.filter((service) => service.showInEmr);
  }, [services, defaultServices, servicesManagementStepCompleted, ViewPrimaryServicesLibrary]);

  const filterServices = useMemo(() => {
    if (!servicesToShow) {
      return [];
    }
    return servicesToShow.filter((service: IServices) => {
      const isShowInEmrValid = filters.status === '' || service.showInEmr === !!filters.status;
      const isSupplierIdValid = filters.supplierId === '' || service.supplierId === +filters.supplierId;

      return isShowInEmrValid && isSupplierIdValid;
    });
  }, [servicesToShow, filters]);

  const serviceGroupsToShow = useMemo(() => {
    if (servicesManagementStepCompleted && !ViewPrimaryServicesLibrary) {
      return serviceGroups.length
        ? serviceGroups
        : defaultServiceGroups.filter((defaultServiceGroup) =>
          filterServices.some((service) => service.serviceGroupId === defaultServiceGroup.id)
        );
    }

    return defaultServiceGroups.filter((defaultServiceGroup) =>
      filterServices.some((service) => service.serviceGroupId === defaultServiceGroup.id)
    );
  }, [
    serviceGroups,
    defaultServiceGroups,
    servicesManagementStepCompleted,
    filterServices,
    ViewPrimaryServicesLibrary,
  ]);

  const serviceIdsToServiceGroupIdMap: Record<number, number[]> = useMemo(() => {
    if (!serviceGroupsToShow || !servicesToShow) {
      return {};
    }
    return serviceGroupsToShow.reduce(
      (obj, serviceGroup) => ({
        [serviceGroup.id]: filterServices
          .filter((service) => service.serviceGroupId === serviceGroup.id)
          .map(({ id }) => id),
        ...obj,
      }),
      {}
    );
  }, [serviceGroupsToShow, servicesToShow, filterServices]);

  const handleOnChangeServiceGroupSelection = (selectedServiceGroupId: number) => {
    let newSelectedGroups: number[];
    if (selectedServiceGroups.includes(selectedServiceGroupId)) {
      newSelectedGroups = selectedServiceGroups.filter((id) => id !== selectedServiceGroupId);
    } else {
      newSelectedGroups = [...selectedServiceGroups, selectedServiceGroupId];
    }
    setSelectedServiceGroups(newSelectedGroups);

    const servicesInGroup = serviceIdsToServiceGroupIdMap[selectedServiceGroupId] || [];
    setSelectedServices((prevSelectedServices) => {
      if (newSelectedGroups.includes(selectedServiceGroupId)) {
        return [...prevSelectedServices, ...servicesInGroup];
      }
      return prevSelectedServices.filter((prevSelectedServiceId) => !servicesInGroup.includes(prevSelectedServiceId));
    });
  };
  const handleOnChangeServiceSelection = (
    selectedServiceId: number,
    selectedServiceGroupId: number,
    serviceIds: number[]
  ) => {
    let newSelectedServiceIds: number[] = [];

    if (selectedServices.includes(selectedServiceId)) {
      newSelectedServiceIds = selectedServices.filter((id) => id !== selectedServiceId);
    } else {
      newSelectedServiceIds = [...selectedServices, selectedServiceId];
      if (!selectedServiceGroups.includes(selectedServiceGroupId)) {
        setSelectedServiceGroups([...selectedServiceGroups, selectedServiceGroupId]);
      }
    }

    const servicesForGroup = serviceIds.filter((id) => newSelectedServiceIds.includes(id));

    if (servicesForGroup.length === 0) {
      if (selectedServiceGroups.includes(serviceGroupId)) {
        setSelectedServiceGroups(selectedServiceGroups.filter((groupId) => groupId !== serviceGroupId));
      }
    }

    setSelectedServices(newSelectedServiceIds);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await importServiceGroupsMutation.mutate({
        serviceGroupIds: selectedServiceGroups,
        serviceIds: selectedServices,
      });
      await updateProfileProgressMutation.mutateAsync({ stepName: 'services-management' });
      await refetchServices();
      await refetchServiceGroups();
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Services and service groups.',
            action: 'saved',
          }),
          type: 'success',
        },
      });
      history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management?howWork=serviceGroups', renderLocation));
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'saving',
            element: 'the services and service groups.',
          }),
          type: 'error',
        },
      });
    } finally {
      setSaving(false);
    }
  };

  if (
    isLoadingServices ||
    isLoadingServiceGroups ||
    isLoadingDefaultServices ||
    isLoadingDefaultServiceGroups ||
    isLoadingCurrentSelectedPrimaryServices
  ) {
    return (
      <Grid container spacing={2} style={{ padding: '12px 15px' }}>
        <Grid item xs={12}>
          <ShortMultipleSkeleton length={10} />
        </Grid>
      </Grid>
    );
  }

  const onChangeFilters = (event: React.ChangeEvent<any>): void => {
    const propertyName: string = event.target.name as string;
    setFilters((prevState: any) => ({
      ...prevState,
      [propertyName]: event.target.value,
    }));
  };

  return (
    <div className={`${classes.root} ${howWork ? classes.shadedBackground : ''}`}>
      <div className={classes.headerContainer}>
        {servicesManagementStepCompleted && !ViewPrimaryServicesLibrary ? (
          <>
            <div style={{ maxWidth: '60%' }}>
              <h1>Services Management</h1>
              <p>Manage services you wish to provide to your patients.</p>
            </div>
            <div className={classes.createButtons}>
              <div>
                <ButtonAhref
                  buttonStyle="big"
                  text="View Primary Services Library"
                  href={`${PRACTITIONER_ACCOUNT_SETTINGS_PATH(
                    'services-management',
                    renderLocation
                  )}?ViewPrimaryServicesLibrary=true`}
                />
              </div>
              <div>
                <ButtonAhref
                  buttonStyle="big"
                  text="New Service Group"
                  href={`${PRACTITIONER_ACCOUNT_SETTINGS_PATH(
                    'services-management',
                    renderLocation
                  )}?serviceGroupId=new`}
                />
              </div>
              <div>
                <ButtonAhref
                  buttonStyle="big"
                  text="New Service"
                  href={`${PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management', renderLocation)}?serviceId=new`}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ maxWidth: '60%' }}>
              <h1>Select your services from our Primary Services Library</h1>
              <p>
                Select the Service groups and Services you would like to import into your EHR system as part of the
                Services you would like to offer. By importing any of our Library items we will auto-populate all the
                necessary information to utilize them during patients Service visits. You will also be able to edit
                these Services and adjust them to your needs.
              </p>
            </div>
            <div className={classes.createButtons}>
              {servicesManagementStepCompleted && (
                <div>
                  <ButtonAhref
                    buttonStyle="big"
                    text="View My Services Library"
                    href={PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management', renderLocation)}
                  />
                </div>
              )}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saving || selectedServiceGroups.length === 0}
                  onClick={handleSave}
                >
                  Finish
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <hr />
      {servicesManagementStepCompleted && !ViewPrimaryServicesLibrary && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p>Filter by</p>
          <FormControl size="medium" variant="outlined" className={classes.formDropdown}>
            <InputLabel id="status" className={classes.formInputSelect}>
              Status
            </InputLabel>
            <Select
              data-cy="status"
              labelId="status"
              name="status"
              className={classes.dropdown}
              value={filters.status}
              onChange={onChangeFilters}
              fullWidth
              label="Supplier"
              disabled={isFetchingSuppliers}
            >
              <MenuItem value="">None</MenuItem>
              {[
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
              ].map(({ name, value }) => (
                <MenuItem value={value}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="medium" variant="outlined" className={classes.formDropdown}>
            <InputLabel id="supplier" className={classes.formInputSelect}>
              Supplier
            </InputLabel>
            <Select
              data-cy="supplier"
              labelId="supplier"
              name="supplierId"
              className={classes.dropdown}
              value={filters.supplierId}
              onChange={onChangeFilters}
              fullWidth
              label="Supplier"
              disabled={isFetchingSuppliers}
            >
              <MenuItem value="">None</MenuItem>
              {suppliers?.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {serviceGroupsToShow.map((serviceGroup, index) => (
        <div
          key={serviceGroup.id}
          style={{ display: 'flex', flexDirection: 'column', zIndex: index === 0 ? 1300 : -1 }}
          className={classes.serviceGroupWrapper}
        >
          <div className={classes.serviceGroupContainer}>
            <div className={classes.serviceGroup}>
              {(!servicesManagementStepCompleted || Boolean(ViewPrimaryServicesLibrary)) && (
                <Checkbox
                  color="primary"
                  checked={selectedServiceGroups.includes(serviceGroup.id)}
                  onChange={() => handleOnChangeServiceGroupSelection(serviceGroup.id)}
                />
              )}
              <h2>{serviceGroup.name}</h2>
            </div>
            {servicesManagementStepCompleted && !ViewPrimaryServicesLibrary && (
              <Button
                className={classes.editButton}
                onClick={() => {
                  history.push(
                    `${PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management', renderLocation)}?serviceGroupId=${
                      serviceGroup.id
                    }`
                  );
                }}
              >
                Edit Group
              </Button>
            )}
          </div>
          <Box className={classes.subTableContainer}>
            <ServiceTable
              height="auto"
              handleOnChangeServiceSelection={handleOnChangeServiceSelection}
              selectedServices={selectedServices}
              isFetching={isLoadingServices}
              services={filterServices.filter((service) => service.serviceGroupId === serviceGroup.id)}
              servicesManagementStepCompleted={servicesManagementStepCompleted && !ViewPrimaryServicesLibrary}
              renderLocation={renderLocation}
              serviceGroup={serviceGroup}
            />
          </Box>
        </div>
      ))}
      <ServiceGroupModal
        open={!!serviceGroupId && serviceId !== 'new'}
        onClose={() => {
          history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management', renderLocation));
        }}
        serviceGroupId={serviceGroupId || ''}
        userServiceGroups={serviceGroups}
        defaultServiceGroups={defaultServiceGroups}
      />
      {serviceId && (
        <ServiceModal
          open={!!serviceId}
          onClose={() => {
            history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management', renderLocation));
          }}
          serviceId={serviceId}
          serviceGroupId={serviceGroupId}
          services={servicesToShow}
          suppliers={suppliers}
          serviceGroups={serviceGroupsToShow}
        />
      )}
      <DialogModal
        openModal={!servicesManagementStepCompleted && !!firstTime}
        confirmModal={() => history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management', renderLocation))}
        confirmTextModal="Let’s do it"
        titleModal="Welcome to our Primary Services Library!"
        modalTexts={[
          // eslint-disable-next-line max-len
          'Before we show you new experience lets select services you would like to use in your EHR from our Primary Services Library.',
        ]}
      />
      <DialogModal
        openModal={saving}
        isLoading={saving}
        titleModal="Hang on!"
        modalTexts={[
          // eslint-disable-next-line max-len
          'We are preparing your Services and Products management with the Imported Services from our Primary Services Library.',
        ]}
      />
      <DialogModal
        openModal={!!howWork && !servicesManagementStepCompleted}
        isLoading={isLoading}
        modalTexts={[
          // eslint-disable-next-line max-len
          'Let’s take a quick tour of how things work here. \n We utilize Service Groups to create distinct groupings of services, allowing for efficient management. Within a Service Group, key elements such as consents, standing orders, and pre/post-care instructions are configured. For example, consider "Neurotoxins" as a Service Group, encompassing the entire class of neurotoxins like Botox, Dysport (and all other neurotoxins) within its category.',
          // eslint-disable-next-line max-len
          'Services are the individual components within a Service Group. They represent both treatments rendered, such as Botox, and retail products that can be sold to patients. When navigating through a check out, selecting the appropriate Service from the Service Group is crucial for accurate fee generation, patient record keeping, and inventory management. When you select a Service, it will populate additional specific information such as a template procedure note and an ability to annotate a patient photo.',
        ]}
        skipModal
        closeModal={() => history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management', renderLocation))}
        confirmModal={() => history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management', renderLocation))}
        confirmTextModal="Ok, got it!"
      />
    </div>
  );
};

export default ServicesManagementComponent;
