import { useMutation } from 'react-query';
import Customers from '../../services/Customers';

interface ICreateBeautiBankSubscription {
  patientId: string;
  squareCardId: string | undefined;
}
export const useCreateBeautiBankSubscription = () =>
  useMutation(({ patientId, squareCardId }: ICreateBeautiBankSubscription) =>
    Customers.createCustomerBeautiBankSubscription({ patientId, squareCardId })
  );
