import React from 'react';
import { MedspaCalendarGFEStatusEnum } from 'src/constants/general.constants';
import { Error as ErrorIcon, CheckCircle as CheckCircleIcon, Help as HelpIcon } from '@material-ui/icons';

const { CLEARED, TO_CLEAR, TO_RECLEAR, INCOMPLETE } = MedspaCalendarGFEStatusEnum;

export const gfeStatusColors = {
  [CLEARED]: {
    iconBGColor: '#000000',
  },
  [TO_RECLEAR]: {
    iconBGColor: '#FF971C',
    appointmentBGColor: '#FFECD6',
  },
  [TO_CLEAR]: {
    iconBGColor: '#FF1C1C',
    appointmentBGColor: '#FFDBDB',
  },
  [INCOMPLETE]: {
    iconBGColor: '#000000',
    appointmentBGColor: '',
  },
};

export const getGFEStatusIconComponent = (gfeStatus: MedspaCalendarGFEStatusEnum) => {
  let IconComponent = HelpIcon;

  if (gfeStatus === CLEARED) {
    IconComponent = CheckCircleIcon;
  } else if ([TO_CLEAR, TO_RECLEAR, INCOMPLETE].includes(gfeStatus)) {
    IconComponent = ErrorIcon;
  }

  return () => <IconComponent style={{ fill: gfeStatusColors[gfeStatus]?.iconBGColor || '#c3c3c3' }} />;
};

export const getGFEStatusTooltipText = (gfeStatus: MedspaCalendarGFEStatusEnum | null) => {
  let tooltipText = '';

  switch (gfeStatus) {
    case TO_CLEAR: {
      tooltipText = 'To clear';
      break;
    }
    case TO_RECLEAR: {
      tooltipText = 'To reclear';
      break;
    }
    case INCOMPLETE: {
      tooltipText = 'Incomplete';
      break;
    }
    case CLEARED: {
      tooltipText = 'Cleared';
      break;
    }
    default: {
      tooltipText = String(gfeStatus);
      break;
    }
  }

  return tooltipText;
};
