import { createModel } from '@rematch/core';
import { xor, filter } from 'lodash';
import { RootModel } from './rootModel';
import {
  PatientPointCertificatesState,
  PatientCertificate,
  CertificationPoints,
  IPractitionerCertificate,
  IGaldermaProduct,
  IGaldermaPayload,
} from '../types/PatientPointCertificatesState';
import axiosInstance from '../utils/axios';
import { getCertificateOptions, parseGaldermaProductsAndCertificates } from '../utils/galderma.utils';
import { ICertificateOptions } from '../interfaces/galderma.interface';
import { toSnakeCase } from '../utils/global';
import compile from '../utils/toastMessagesCompiler';

const snackBarGaldermaErrors = {
  type: 'info',
  duration: 10000,
};

export const patientPointCertificates = createModel<RootModel>()({
  state: {
    toggleModal: false,
    galdermaProducts: [],
    galdermaApplicableProducts: [],
    patientCertificates: [],
    practitionerCertificates: [],
    isLoadingPractitionerCertificates: false,
    isLoadingPatientCertificates: false,
    isLoadingPatientPoints: false,
    isGaldermaUserError: false,
    isLoadingProducts: false,
    patientCertificatesSelected: [],
    practitionerCertificatesSelected: [],
    isLoadingToggleCertificate: false,
    patientPointsToRedeem: 0,
    patientPoints: 0,
    treatmentConfigured: false,
    patientSMSCodeSent: false,
    certificateOptions: [],
    selectedCertificatesConfirmed: false,
    skusSelected: {},
  } as PatientPointCertificatesState,
  reducers: {
    setGaldermaProducts(state: PatientPointCertificatesState, payload: IGaldermaProduct[]) {
      return { ...state, galdermaProducts: payload };
    },
    setApplicableProducts(state: PatientPointCertificatesState, payload: IGaldermaProduct[]) {
      return { ...state, galdermaApplicableProducts: payload };
    },
    setPractitionerCertificates(state: PatientPointCertificatesState, payload: IPractitionerCertificate[]) {
      return { ...state, practitionerCertificates: payload };
    },
    setPatientPointsToRedeem(state: PatientPointCertificatesState, payload: number) {
      return { ...state, patientPointsToRedeem: payload };
    },
    setPatientSMSCodeSent(state: PatientPointCertificatesState, payload: boolean) {
      return { ...state, patientSMSCodeSent: payload };
    },
    setPatientCertificates(state: PatientPointCertificatesState, payload: PatientCertificate[]) {
      return { ...state, patientCertificates: payload };
    },
    setPointsCertificateOptions(
      state: PatientPointCertificatesState,
      payload: {
        points: number;
        certificatesOptions: CertificationPoints[];
      }
    ) {
      return { ...state, patientPoints: payload.points, certificateOptions: payload.certificatesOptions };
    },
    setToggleModal(state: PatientPointCertificatesState) {
      return { ...state, toggleModal: !state.toggleModal, treatmentConfigured: state.toggleModal };
    },
    setPatientCertificateChecked(state: PatientPointCertificatesState, payload: number[]) {
      return { ...state, patientCertificatesSelected: payload };
    },
    setPractitionerCertificatesChecked(state: PatientPointCertificatesState, payload: number[]) {
      return { ...state, practitionerCertificatesSelected: payload };
    },
    setIsLoadingToggleCertificate(state: PatientPointCertificatesState, payload: boolean) {
      return { ...state, isLoadingToggleCertificate: payload };
    },
    setIsLoadingPractitionerCertificate(state: PatientPointCertificatesState, payload: boolean) {
      return { ...state, isLoadingPractitionerCertificates: payload };
    },
    setIsLoadingPatientCertificate(state: PatientPointCertificatesState, payload: boolean) {
      return { ...state, isLoadingPatientCertificates: payload };
    },
    setIsLoadingPatientPoints(state: PatientPointCertificatesState, payload: boolean) {
      return { ...state, isLoadingPatientPoints: payload };
    },
    setGaldemraUserError(state: PatientPointCertificatesState, payload: boolean) {
      return { ...state, isGaldermaUserError: payload };
    },
    setIsLoadingProducts(state: PatientPointCertificatesState, payload: boolean) {
      return { ...state, isLoadingProducts: payload };
    },
    setTreatmentConfigured(state: PatientPointCertificatesState, payload: boolean) {
      return { ...state, treatmentConfigured: payload };
    },
    setConfirmSelectedCertificates(state: PatientPointCertificatesState, payload: boolean) {
      return { ...state, selectedCertificatesConfirmed: payload };
    },
    setSkusSelected(state: PatientPointCertificatesState, payload: object) {
      return { ...state, skusSelected: payload, patientCertificatesSelected: [], practitionerCertificatesSelected: [] };
    },
  },
  effects: (dispatch: any) => ({
    async fetchGaldermaProducts() {
      try {
        dispatch.patientPointCertificates.setIsLoadingProducts(true);
        const { data } = await axiosInstance.get('/galderma/product_list');
        if (!data.success) {
          throw Error;
        }

        dispatch.patientPointCertificates.setGaldermaProducts(data.products);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'Galderma Products',
            }),
            type: 'error',
          },
        });
      }
      dispatch.patientPointCertificates.setIsLoadingProducts(false);
    },
    async fetchPatientPoints(patientEmail: string) {
      try {
        dispatch.patientPointCertificates.setIsLoadingPatientPoints(true);
        dispatch.patientPointCertificates.setGaldemraUserError(false);
        let certificatesOptions: ICertificateOptions[] = [];

        const { data } = await axiosInstance.get(`/galderma/find_user_by_email?email=${patientEmail}`);

        if (!data.user.emailAddress) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile('patient_profile.aspire_user_not_found', {
                element: 'Aspire user',
              }),
              type: 'error',
            },
          });
          dispatch.patientPointCertificates.setGaldemraUserError(true);
          return;
        }

        if (data.success) {
          certificatesOptions = getCertificateOptions(data.user.pointsTotal);
        }

        dispatch.patientPointCertificates.setPointsCertificateOptions({
          points: data.user.pointsTotal,
          certificatesOptions,
        });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'certificates points',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.patientPointCertificates.setIsLoadingPatientPoints(false);
      }
    },
    async sendPatientSmsPointsCode(patientId: number) {
      try {
        const { data } = await axiosInstance.post('/galderma/send_sms_redeem_patient_points', {
          customerId: patientId,
        });

        if (data.success) {
          dispatch({ type: 'patientPointCertificates/setPatientSMSCodeSent', payload: true });
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: compile('generic.sms_code_sended'),
            },
          });
        } else {
          throw new Error();
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'sending',
              element: 'the confirmation code',
            }),
            type: 'error',
          },
        });
      }
    },
    async redeemPatientPointsToCertificates(payload: {
      patientId: number;
      sixDigitCode: string;
      points: number;
      callback: () => void;
      errorCallback: () => void;
    }) {
      const { patientId, sixDigitCode, points, callback, errorCallback } = payload;
      try {
        const params = { customerId: patientId, galdermaSmsCode: sixDigitCode, points };
        const { data } = await axiosInstance.post(
          '/galderma/redeem_patient_points_to_certificates',
          toSnakeCase(params)
        );

        if (data.success) {
          callback();
        } else {
          throw new Error();
        }
      } catch (error) {
        errorCallback();
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'validating',
              element: 'the confirmation code',
            }),
            type: 'error',
          },
        });
      }
    },
    async fetchPractitionerCertificates() {
      try {
        dispatch.patientPointCertificates.setIsLoadingPractitionerCertificate(true);
        const { data } = await axiosInstance.get('/galderma/practitioner_certificates');

        if (data.success) {
          dispatch.patientPointCertificates.setPractitionerCertificates(data.certificates);
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'certificates points',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.patientPointCertificates.setIsLoadingPractitionerCertificate(false);
      }
    },
    async configureAndFetchCertificates(
      payload: { galdermaPayload: IGaldermaPayload; showError?: boolean },
      { patientPointCertificates: { galdermaProducts: productList } }: any
    ) {
      try {
        const {
          galdermaPayload: { products },
        } = payload;
        dispatch.patientPointCertificates.setIsLoadingPatientCertificate(true);
        dispatch.patientPointCertificates.setIsLoadingPractitionerCertificate(true);

        const { data } = await axiosInstance.post('/galderma/configure_treatment', payload.galdermaPayload);

        if (data.success) {
          dispatch.patientPointCertificates.setPatientCertificates(data.certificates.patient);
          dispatch.patientPointCertificates.setPractitionerCertificates(data.certificates.hcp);
          const newApplicableProducts = productList.filter(({ id }: IGaldermaProduct) =>
            products.map(({ productId }) => productId).includes(id)
          );
          dispatch.patientPointCertificates.setApplicableProducts(newApplicableProducts);
        } else if (data.errorList?.length > 0) {
          // Second try without products with cooldown
          const invalidTreatmentProductIds = data.errorList
            .map(({ field, step }: { field?: string; step: string }) =>
              field === 'productId' && step ? Number(step.split('productId=')[1]) : null
            )
            .filter(Boolean);

          const noInvalidProduct = !invalidTreatmentProductIds.length;
          const allProductAreInvalid = invalidTreatmentProductIds.length === products.length;

          if (noInvalidProduct || allProductAreInvalid) {
            // There are not products to remove from payload
            dispatch.patientPointCertificates.setPatientCertificates([]);
            dispatch.patientPointCertificates.setPractitionerCertificates([]);
            dispatch.patientPointCertificates.setApplicableProducts([]);

            if (payload.showError) {
              showGaldermaProductsError(dispatch, data.errorList, productList);
            }
          } else {
            // Remove the invalid products from treatment configuration
            const validProducts = products.filter(({ productId }) => !invalidTreatmentProductIds.includes(productId));
            if (validProducts.length) {
              const { data: configData } = await axiosInstance.post('/galderma/configure_treatment', {
                ...payload.galdermaPayload,
                products: validProducts,
              });

              if (configData.success) {
                dispatch.patientPointCertificates.setPatientCertificates(configData.certificates.patient);
                dispatch.patientPointCertificates.setPractitionerCertificates(configData.certificates.hcp);
                const newApplicableProductList = productList.filter(({ id }: IGaldermaProduct) =>
                  validProducts.map(({ productId }) => productId).includes(id)
                );
                dispatch.patientPointCertificates.setApplicableProducts(newApplicableProductList);

                if (payload.showError) {
                  showGaldermaProductsError(dispatch, data.errorList, productList);
                }
              } else {
                throw Error('Configuration failed');
              }
            }
          }
        } else {
          throw Error('Server error');
        }
      } catch (error) {
        dispatch.patientPointCertificates.setPatientCertificates([]);
        dispatch.patientPointCertificates.setPractitionerCertificates([]);
        dispatch.patientPointCertificates.setApplicableProducts([]);

        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'retrieving',
              element: 'the patient certificates',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.patientPointCertificates.setIsLoadingPatientCertificate(false);
        dispatch.patientPointCertificates.setIsLoadingPractitionerCertificate(false);
        dispatch.patientPointCertificates.setTreatmentConfigured(true);
      }
    },
    async fetchPatientCertificates(payload: string) {
      try {
        dispatch.patientPointCertificates.setIsLoadingPatientCertificate(true);
        const params = { email: payload };
        const { data } = await axiosInstance.get('/galderma/patient_certificates', { params });

        if (data.success) {
          const { certificates } = data.certificates;
          const certificatesList = certificates.map((certificate: PatientCertificate) => ({
            ...certificate,
          }));

          dispatch.patientPointCertificates.setPatientCertificates(certificatesList);
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'retrieving',
              element: 'the patient certificates',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.patientPointCertificates.setIsLoadingPatientCertificate(false);
      }
    },
    async validateGaldermaPayload(
      // eslint-disable-next-line no-unused-vars
      payload: { galdermaPayload: IGaldermaPayload; successCallback: () => void; errorCallback: (e: any) => void }
    ) {
      const { data } = await axiosInstance.post('/galderma/validate_user_certificates', payload.galdermaPayload);

      if (data.success) {
        payload.successCallback();
      } else {
        payload.errorCallback(data);
      }
    },
    async validateToggleCertificateChecked(
      payload: {
        patientId: number;
        certificateId: number;
        // temporary certs selected until click 'confirm' on aspire modal
        tempPatientCertsSelected: number[];
        tempPractitionerCertsSelected: number[];
        type?: string;
        successCallback?: any;
      },
      { patientPointCertificates: patientState, newServiceVisit, common }
    ) {
      try {
        const { patientCertificates, practitionerCertificates, galdermaApplicableProducts, skusSelected } =
          patientState as PatientPointCertificatesState;
        const {
          patientId: customerId,
          certificateId,
          tempPatientCertsSelected,
          tempPractitionerCertsSelected,
          successCallback,
        } = payload;
        const { selectedServices, totalServicesUnits } = newServiceVisit;
        const { services } = common;

        dispatch.patientPointCertificates.setIsLoadingToggleCertificate(true);
        let certificateIds = tempPatientCertsSelected;
        let practitionerCertificateIds = tempPractitionerCertsSelected;

        if (payload.type === 'Practitioner') {
          practitionerCertificateIds = xor(tempPractitionerCertsSelected, [certificateId]);
        } else {
          certificateIds = xor(tempPatientCertsSelected, [certificateId]);
        }

        const servicesIncluded = filter(services, ({ id }) => selectedServices.includes(id));

        const body: IGaldermaPayload = {
          customerId,
          ...parseGaldermaProductsAndCertificates({
            patientCertificates,
            practitionerCertificates,
            galdermaProducts: galdermaApplicableProducts,
            certificateIds,
            practitionerCertificateIds,
            totalServicesUnits,
            services: servicesIncluded,
            skusSelected,
          }),
        };

        const { data } = await axiosInstance.post('/galderma/validate_user_certificates', body);

        if (data.success) {
          if (successCallback) {
            successCallback(certificateId, payload.type);
          }
        } else if (data.errorList?.length > 0) {
          let messages = '';

          data.errorList.forEach(({ message }: any) => {
            messages += `${message} `.replace('Aspire', 'ASPIRE');
          });

          const message = compile('galderma.generic_error', { errors: messages });

          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message,
              ...snackBarGaldermaErrors,
            },
          });
        } else {
          throw Error(data);
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'validating',
              element: 'the certificate',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.patientPointCertificates.setIsLoadingToggleCertificate(false);
      }
    },
  }),
});

const showGaldermaProductsError = (dispatch: any, errorList: any, productList: IGaldermaProduct[]): void => {
  let messages = '';

  errorList.forEach(({ message, step }: any) => {
    const productId = Number(step.replace('productId=', ''));
    const productName = productList.find(({ id }) => id === productId)?.name;
    let newMessage = `${message} `.replace('Aspire', 'ASPIRE');
    if (productName) {
      newMessage = newMessage.replace('of this product', `of ${productName}`);
    }

    messages += newMessage;
  });

  messages += 'Product(s) not taken into account.';

  const message = compile('galderma.generic_error', {
    errors: messages.trim().charAt(0).toLowerCase() + messages.trim().slice(1),
  });

  dispatch({ type: 'snackbar/enqueueSnackBar', payload: { message, ...snackBarGaldermaErrors } });
};
