import { Box, FormHelperText, makeStyles, TextareaAutosize, TextareaAutosizeProps, Tooltip } from '@material-ui/core';
import React from 'react';
import { Info as InfoIcon } from '@material-ui/icons';
import classNames from 'classnames';
import { EHRTypography } from './EHRTypography';

type EHRTextareaProps = TextareaAutosizeProps & {
  dataCy: string;
  label?: string;
  helperText?: string;
  tooltip?: string;
  error?: boolean;
  height?: string;
};

const useStyles = makeStyles({
  ehrTextarea: {
    width: '100%',
    resize: 'none',
    borderRadius: '6px',
    padding: '10px',
    fontFamily: 'inherit',
    '& .Mui-disabled': {
      color: 'black',
      background: '#EEEEEE',
    },
  },
  ehrTextareaContainer: {
    width: '100%',
  },
});

const EHRTextarea = (props: EHRTextareaProps) => {
  const styles = useStyles();
  const { className, label, id, error, helperText, tooltip, readOnly, height, ...restProps } = props;

  return (
    <Box>
      {label && (
        <EHRTypography dataCy={`label-for-${id}`} id={id} variant="label" error={error}>
          <Box display="flex" gridGap="0.25rem" alignItems="center">
            {label}
            {tooltip && (
              <Tooltip title={tooltip} style={{ fontSize: '1rem' }}>
                <InfoIcon />
              </Tooltip>
            )}
          </Box>
        </EHRTypography>
      )}
      <TextareaAutosize
        {...restProps}
        id={id}
        disabled={readOnly}
        className={classNames(styles.ehrTextarea, className)}
        style={{
          height: height || '200px',
        }}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Box>
  );
};

export { EHRTextarea };
