import { useQuery, useMutation, UseQueryResult, useQueryClient, UseMutationResult } from 'react-query';
import { InventoryBudgetParams } from 'src/interfaces/IInventoryOrder';
import { INVENTORY_BUDGET } from '../../constants/reactQuery.keys';
import InventoryBudget from '../../services/InventoryBudget';

const useInventoryBudget = ({ disabled, practitionerId }: InventoryBudgetParams): UseQueryResult<number | null> =>
  useQuery<number | null>({
    queryKey: [INVENTORY_BUDGET, practitionerId],
    queryFn: (): Promise<number | null> => InventoryBudget.getInventoryBudget(practitionerId),
    enabled: !disabled,
  });

const useUpdateInventoryBudget = (practitionerId?: number): UseMutationResult<void, unknown, number, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (orderingBudget: number): Promise<void> => {
      await InventoryBudget.updateInventoryBudget({ practitionerId, orderingBudget });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_BUDGET] });
    },
  });
};

export { useUpdateInventoryBudget, useInventoryBudget, useInventoryBudget as default };
