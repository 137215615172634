import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem 1.5rem',
    alignItems: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1.5rem',
  },
  saveAndContinue: {
    width: '198px',
    height: '55px',
  },
  secondaryButton: {
    minWidth: '198px',
    height: '55px',
    background: 'transparent',
    border: '2px solid #12574d',
    color: '#12574d',
    '&:hover': {
      backgroundColor: '#E7EEED',
    },
  }
}));
