import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TableCell,
  TableRow,
} from '@material-ui/core';
import classnames from 'classnames';
import { Search as SearchIcon } from '@material-ui/icons';
import { usePhysicianServiceVisitsByProvider } from '../../hooks/serviceVisit/useServiceVisit';
import compile from '../../utils/toastMessagesCompiler';
import { PortraitTable } from '../../components/common/TableNew/Table';
import IServiceVisit from '../../interfaces/IServiceVisits';
import { useStyles } from './ProviderPatientsList.styles';
import formatDate from '../../utils/formatDate';
import { useMarkCustomerImportant } from '../../hooks/customer/useMarkCustomerImportant';
import { SelectCustom } from '../../components/DashboardAdministrator/Inventory/supplyReceipt.styles';

const TABLE_COLUMNS = [
  { id: 'markedImportant', title: '' },
  { id: 'patientName', title: 'Patient Name' },
  { id: 'patientId', title: 'MRN' },
  { id: 'service_visits.created_at', title: 'Appt. Date', sort: true },
  { id: 'services', title: 'Services' },
  { id: 'chartReview', title: 'Chart Review' },
];

const ServiceVisitRow = ({ serviceVisit }: { serviceVisit: IServiceVisit }) => {
  const history = useHistory();
  const classes = useStyles();
  const navigateToServiceVisit = () => {
    history.push(`/patient/${serviceVisit.patientId}/newServiceVisit/${serviceVisit.id}`);
  };
  const { isLoading, StarColumn } = useMarkCustomerImportant(serviceVisit.patientId, serviceVisit);

  return (
    <TableRow className={classes.patientRow} key={serviceVisit.id}>
      <TableCell>{isLoading ? <CircularProgress size={20} /> : <StarColumn />}</TableCell>
      <TableCell onClick={navigateToServiceVisit}>{serviceVisit.patientName}</TableCell>
      <TableCell onClick={navigateToServiceVisit}>{serviceVisit.patientId}</TableCell>
      <TableCell onClick={navigateToServiceVisit}>{formatDate(serviceVisit.createdAt)}</TableCell>
      <TableCell onClick={navigateToServiceVisit}>
        {serviceVisit.charges.map((charge) => charge.service?.name).join(', ')}
      </TableCell>
      <TableCell onClick={navigateToServiceVisit}>
        {serviceVisit.chartReviewed ? (
          <span className={classes.completed}>Reviewed</span>
        ) : (
          <span className={classes.pending}>Pending</span>
        )}
      </TableCell>
    </TableRow>
  );
};

export const ProviderClosedServiceVisitsList = ({ practitionerId }: { practitionerId: number }) => {
  const initialFilters = {
    group: 'allPatients',
    view: 'all',
    filter: 'all',
    license: '',
    search: '',
    sort: '',
    sort_direction: '',
    practitioner_id: practitionerId,
    gfeStatus: '',
    state: '',
    marked: '',
  };
  const [filters, setFilters] = useState<any>(initialFilters);
  const [search, setSearch] = useState('');
  const [patientType, setPatientType] = useState('');
  const { results, isLoading, hasNextPage, isFetching, isError, fetchNextPage } =
    usePhysicianServiceVisitsByProvider(filters);
  const classes = useStyles();

  const doSortBy = (column: string, direction: string) => {
    setFilters({
      ...filters,
      sort: column,
      sort_direction: direction,
    });
  };

  useEffect(() => {
    setFilters({ ...filters, ...{ search, marked: patientType ? true : '' } });
  }, [search, patientType]);

  const serviceVisitData = results?.pages.map(({ serviceVisits = [] }) => serviceVisits).flat() || [];

  return (
    <div className={classes.providerPatientList}>
      <h3 className={classes.heading}>Service Visits ({isLoading ? '...' : results?.pages?.[0]?.meta?.totalCount})</h3>
      <p className={classes.subHeading}>In the past 90 days</p>
      <div className={`${classes.flexRow} ${classes.filterContainer}`}>
        <FormControl size="small" variant="outlined">
          <InputLabel htmlFor="search" color="secondary" className={classes.search}>
            Search
          </InputLabel>
          <OutlinedInput
            data-cy="providerSearchInput"
            id="search"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton style={{ padding: 0 }}>
                  {isFetching ? (
                    <CircularProgress style={{ color: 'black' }} size={20} />
                  ) : (
                    <SearchIcon style={{ color: 'black' }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="patientType" color="secondary" className={classes.search}>
            Patient
          </InputLabel>
          <SelectCustom
            data-cy="from"
            name="patientType"
            value={patientType}
            onChange={(e) => setPatientType(e.target.value as string)}
            label="patientType"
          >
            <MenuItem value={undefined}>
              <em>All</em>
            </MenuItem>
            <MenuItem value="important">Important</MenuItem>
          </SelectCustom>
        </FormControl>
      </div>
      <PortraitTable
        columns={TABLE_COLUMNS}
        hasNextPage={hasNextPage}
        sortBy={filters.sort}
        sortDirection={filters.sort_direction}
        fetchNextPage={fetchNextPage}
        onSortChange={doSortBy}
        data={serviceVisitData}
        rowComponent={(row) => <ServiceVisitRow serviceVisit={row} />}
        errorMessage={compile('generic.error_message', { action: 'fetching', element: 'service visits.' })}
        infiScroll
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
      />
    </div>
  );
};
