import Api from './Api';
import { IAppointmentUpcoming } from '../interfaces/IAppointmentUpcoming';
import { CustomerParams } from '../interfaces/CustomerParams';

const BASE_PATH = '/appointments/upcoming';

const UpcomingAppoiments = {
  async getUpComingAppointmentsPatients(paramsQuery: CustomerParams): Promise<IAppointmentUpcoming[]> {
    const { sortBy = 'created_at ASC' } = paramsQuery;
    const { appointments } = await Api.get(BASE_PATH, { ...paramsQuery, sort: sortBy });
    return appointments;
  },
  async getUpcomingAppointmentsPatientCount(paramsQuery: CustomerParams): Promise<number> {
    const { total } = await Api.get(`${BASE_PATH}_count`, paramsQuery);
    return total;
  },
};

export default UpcomingAppoiments;
