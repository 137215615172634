/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ArrowBack } from '@material-ui/icons';
import classnames from 'classnames';
import { useStyles } from './navigationBar.styles';

type Props = {
  title: string;
  onBackButtonClick?: any;
};

export const NavigationBar = ({ title, onBackButtonClick }: Props) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={classnames(classes.bar)} onClick={onBackButtonClick}>
      <ArrowBack />
      <div className={classes.title}>{title}</div>
    </div>
  );
};
