import Api from './Api';
import IInventoryNotifications from '../interfaces/IInventoryNotification';

const BASE_PATH = '/v3/inventory_notifications';

export const getInventoryNotifications = async (): Promise<IInventoryNotifications> => {
  const { notifications, error } = await Api.get(`${BASE_PATH}`);

  if (error) {
    throw error;
  }

  return notifications;
};
