import { IPurchaseOrders } from '../interfaces/IPurchaseOrder';
import Api from './Api';
import ROUTES from '../constants/apiRoutes.constants';
import { IFilters } from '../components/DashboardAdministrator/PurchaseOrders/PurchaseOrders';

const PurchaseOrders = {
  async getPurchaseOrders(query: IFilters): Promise<IPurchaseOrders> {
    const data = await Api.get(ROUTES.PURCHASE_ORDERS, query);
    return data;
  },
};

export default PurchaseOrders;
