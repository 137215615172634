import React, { useState } from 'react';
import { Box, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { Description as DescriptionIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import ConfirmModal from '../ConfirmModal';

interface Props {
  title: string;
  disabled: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  value: string;
}

const useStyles = makeStyles(() => ({
  icon: {
    cursor: 'pointer',
  },
  title: {
    paddingLeft: '6px',
  },
  updateCard: {
    margin: '2px 0px 2px 0px',
    backgroundColor: '#EEEEEE',
    padding: '9px 10px 5px',
    borderRadius: '5px',
  },
  rightItem: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
}));

const DocMenu = ({ title, value, disabled, onEdit, onDelete }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showText, setShowText] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickDelete = () => {
    onDelete?.();
    handleClose();
  };
  const onClickEdit = () => {
    onEdit?.();
    handleClose();
  };

  return (
    <Box className={classes.updateCard} display="flex">
      <ConfirmModal
        title={title}
        // eslint-disable-next-line react/no-danger
        contentText={<div dangerouslySetInnerHTML={{ __html: value || 'No content.' }} />}
        openDialog={showText}
        setOpenDialog={() => setShowText(false)}
        confirmText="Copy to clipboard"
        onClickConfirm={() => {
          copyToClipboard(value);
          setShowText(false);
        }}
      />
      <Box className={classes.icon} onClick={() => setShowText(true)}>
        <DescriptionIcon style={{ color: value ? 'black' : 'gray' }} />
      </Box>

      <Typography className={classes.title}>{title}</Typography>
      <Box className={classes.rightItem}>
        <MoreVertIcon onClick={handleClick} />
      </Box>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} keepMounted onClose={handleClose}>
        {disabled ? (
          <>{onEdit && <MenuItem onClick={onClickEdit}>View</MenuItem>}</>
        ) : (
          <>
            {onEdit && <MenuItem onClick={onClickEdit}>Edit</MenuItem>}
            {onDelete && <MenuItem onClick={onClickDelete}>Delete</MenuItem>}
          </>
        )}
      </Menu>
    </Box>
  );
};

export default DocMenu;
