import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  TablePagination,
} from '@material-ui/core';
import moment from 'moment';
import { orderBy } from 'lodash';
import Page from 'src/components/common/Page';
import SupplyReceiptsRow from './SupplyReceiptsRow';
import { SelectCustom, useStyles } from './supplyReceipt.styles';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { STATUS_SUPPLY_RECEIPTS } from '../../../constants/supplyReceipts.constants';
import ButtonAhref from '../../common/ButtonAhref';
import { DEFAULT_ROWS_PER_PAGE, SUPPLY_RECEIPT_STATUS } from '../../../constants/general.constants';
import { useCustomSupplyReceipts } from '../../../hooks/queries/useCustomSupplyReceipts';
import { usePractitioners } from '../../../hooks/queries/usePractitioners';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import { TypeSort } from '../../../utils/types';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_SUPPLY_RECEIPT } from '../../../constants/actions.constants';
import { IPractitioner } from '../../../interfaces/IPractitioner';

const SupplyReceiptsPage = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [orderByDate, setOrderBy] = useState<TypeSort>('desc');
  const [searchQuery, setSearchQuery] = useState<any>({});
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const { supplyReceipts, supplyReceiptsCount, isLoading, isError } = useCustomSupplyReceipts(
    orderByDate,
    searchQuery,
    page
  );

  const { data: practitioners, isLoading: isLoadingPractitioners, isError: isErrorPractitioners } = usePractitioners();

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'supply receipts',
        }),
        type: 'error',
      },
    });
  }

  if (isErrorPractitioners) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'practitioners',
        }),
        type: 'error',
      },
    });
  }

  const handleChangePage = (_: any, newPage: any) => {
    setPage(newPage);
  };

  const emptyRows = DEFAULT_ROWS_PER_PAGE - supplyReceipts.length;

  const handleOrder = () => {
    setOrderBy(orderByDate === 'asc' ? 'desc' : 'asc');
  };

  const handleChange = (event: React.ChangeEvent<{ value: number | unknown; name?: string | undefined }>) => {
    const newQuery = { ...searchQuery, [event.target.name || '']: event.target.value };
    setSearchQuery(newQuery);
    setPage(0);
  };

  const Breadcrumb = (
    <Box mb="17px">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Inventory" />
        <BreadcrumbText text="Supply receipts" linkTo="/administrator/new-supply-receipt-step-1" isActive />
      </BreadcrumbsContainer>
    </Box>
  );

  const TitleSearch = (
    <Box padding={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" mt={1}>
          <FormControl size="small" className={classes.formControl} variant="outlined">
            <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>Practitioners</InputLabel>
            <SelectCustom
              disabled={isLoadingPractitioners}
              fullWidth
              style={{ width: '250px', marginRight: '20px' }}
              label="Practitioners"
              name="practitionerId"
              onChange={handleChange}
            >
              <MenuItem value={undefined}>
                <em>All</em>
              </MenuItem>
              {orderBy(practitioners, ['providerLabeling'], ['asc']).map(({ id, providerLabeling }: IPractitioner) => (
                <MenuItem key={id} value={id}>
                  {providerLabeling}
                </MenuItem>
              ))}
            </SelectCustom>
          </FormControl>

          <FormControl size="small" className={classes.formControl} variant="outlined">
            <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>Status</InputLabel>
            <SelectCustom
              disabled={isLoading}
              fullWidth
              style={{ width: '250px' }}
              label="Status"
              name="status"
              onChange={handleChange}
            >
              <MenuItem value={undefined}>
                <em>All</em>
              </MenuItem>
              {STATUS_SUPPLY_RECEIPTS.map(({ text, value }: any) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </SelectCustom>
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box>
            {hasAccessTo(CREATE_SUPPLY_RECEIPT, permissions) && (
              <ButtonAhref
                text="NEW SUPPLY RECEIPT"
                href="/administrator/new-supply-receipt-step-1"
                buttonStyle="big"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const TableData = isLoading ? (
    <MultipleSkeleton />
  ) : (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader>Nurse name</TableCellHeader>
              <TableCellHeader onClick={() => handleOrder()} style={{ cursor: 'pointer' }}>
                Creation Date
              </TableCellHeader>
              <TableCellHeader>Closed Date</TableCellHeader>
              <TableCellHeader>Status</TableCellHeader>
              <TableCellHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {supplyReceipts.map((item: any) => (
              <SupplyReceiptsRow
                key={item.id}
                id={item.id}
                nurseName={item.nurseName}
                date={moment(item.createdAt).format('MM/DD/YYYY')}
                closedDate={
                  item.status === SUPPLY_RECEIPT_STATUS.CLOSED &&
                  moment(item.signatureUpdatedAt || item.updatedAt).format('MM/DD/YYYY')
                }
                status={item.status}
              />
            ))}
            {Boolean(emptyRows) && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
          component="div"
          count={supplyReceiptsCount}
          rowsPerPage={DEFAULT_ROWS_PER_PAGE}
          page={page}
          onChangePage={handleChangePage}
        />
      </TableContainer>
    </Box>
  );

  return (
    <Page title="Supply Receipts">
      <Box width="100%">
        {Breadcrumb}
        {TitleSearch}
        {TableData}
      </Box>
    </Page>
  );
};

export default SupplyReceiptsPage;
