import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { withStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { LineItemRow } from './LineItemRow';
import IServices from '../../../interfaces/IServices';
import { AppliedDiscounts } from './AppliedDiscounts';
import { IServicesUnits } from '../../../interfaces/serviceVisit.interfaces';
import { NewDiscountsModal } from './NewDiscountsModal';
import { IDiscount } from '../../../interfaces/checkout.interfaces';
import { dispatch } from '../../../rematch';
import IVariant, { IVariantItem } from '../../../interfaces/IVariants';
import { CheckoutLineItem } from '../../../types/CheckoutLineItem';

const CustomTableCell = withStyles(() => ({
  root: {
    fontSize: '15px',
  },
}))(TableCell);

const LineItemTable = ({ accountChargeLineItems }: { accountChargeLineItems: CheckoutLineItem[] }) => {
  const { servicesUnits, creditServicesUnits, variantsUnits, creditVariantsUnits, totalCreditServicesUnits, checkout } =
    useSelector((store: any) => store.newServiceVisit);
  const { services } = useSelector((store: any) => store.common);
  const { servicesDiscounts, variantsDiscounts } = checkout;
  const [selectedDiscounts, setSelectedDiscounts] = useState<any>([]);
  const [selectedMedication, setSelectedMedication] = useState<any>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openDiscountsModal = (name: string, id: number, price: number, unit: number, isVariantItem?: boolean): void => {
    let discounts: any = [];

    if (isVariantItem) {
      discounts = variantsDiscounts[id] || [];
      setSelectedMedication({ name, variantItemId: id, price, unit, isVariantItem });
    } else {
      discounts = servicesDiscounts[id] || [];
      setSelectedMedication({ name, serviceId: id, price, unit, isVariantItem: false });
    }
    setSelectedDiscounts(discounts);
    setModalOpen(true);
  };

  const handlePrePopulatedDiscountModalClose = (): void => {
    setModalOpen(false);
  };

  const applyDiscount = (discounts: IDiscount[]): void => {
    const newServicesDiscounts = { ...servicesDiscounts };
    const newVariantsDiscounts = { ...variantsDiscounts };

    if (!discounts.length) {
      if (selectedMedication.isVariantItem) {
        delete newVariantsDiscounts[selectedMedication.variantItemId];
      } else {
        delete newServicesDiscounts[selectedMedication.serviceId];
      }
    } else if (selectedMedication.isVariantItem) {
      newVariantsDiscounts[selectedMedication.variantItemId] = discounts;
    } else {
      newServicesDiscounts[selectedMedication.serviceId] = discounts;
    }

    if (selectedMedication.isVariantItem) {
      dispatch({ type: 'newServiceVisit/applyVariantsDiscounts', payload: newVariantsDiscounts });
    } else {
      dispatch({ type: 'newServiceVisit/applyServicesDiscounts', payload: newServicesDiscounts });
    }
  };

  const showFutureColumn = () => Object.values(totalCreditServicesUnits as IServicesUnits).some((u) => u && +u > 0);

  const accountChargeLineItemsIds = accountChargeLineItems.map(({ serviceId }) => +serviceId);

  return (
    <>
      <TableContainer style={{ border: '1px solid #E4E7EB', borderBottom: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>Quantity</CustomTableCell>
              {showFutureColumn() && <CustomTableCell>Future Use</CustomTableCell>}
              <CustomTableCell width="25%">Product</CustomTableCell>
              <CustomTableCell>Unit Price</CustomTableCell>
              <CustomTableCell>Discount</CustomTableCell>
              <CustomTableCell align="right">Total to pay</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!services.length &&
              // to not duplicate the services in account charge line items, filter those services here
              Object.entries(servicesUnits)
                .filter(([serviceId]) => !accountChargeLineItemsIds.includes(+serviceId))
                .map(([serviceId, units]: any) => {
                  const service = services.find(
                    ({ id }: IServices) =>
                      id === +serviceId && (+servicesUnits[id] !== 0 || +creditServicesUnits[id] !== 0)
                  );
                  const variantsItems = services
                    .find(({ id }: IServices) => id === +serviceId)
                    .variants.flatMap(({ items }: IVariant) => items)
                    .filter(
                      (item: IVariantItem) => +variantsUnits[item.id] !== 0 || +creditVariantsUnits[item.id] !== 0
                    );

                  const appliedServiceDiscount = !!servicesDiscounts[serviceId];

                  return (
                    <>
                      {service && (
                        <Fragment key={serviceId}>
                          <LineItemRow
                            quantity={units}
                            credit={creditServicesUnits[serviceId] || 0}
                            service={service}
                            discounts={servicesDiscounts[serviceId]}
                            appliedDiscount={appliedServiceDiscount}
                            openDiscountsModal={openDiscountsModal}
                            showFutureColumn={showFutureColumn()}
                          />
                          <AppliedDiscounts
                            discounts={servicesDiscounts[serviceId] || []}
                            totalPrice={service.price * ((+creditServicesUnits[serviceId] || 0) + +units)}
                            openDiscountsModal={openDiscountsModal}
                            service={service}
                            units={+units + (+creditServicesUnits[serviceId] || 0)}
                          />
                        </Fragment>
                      )}
                      {variantsItems.length > 0 &&
                        variantsItems.map((variantItem: IVariantItem) => {
                          const appliedVariantDiscount = !!variantsDiscounts[variantItem.id];
                          const variantUnits = +variantsUnits[variantItem.id] / variantItem.minAmount;
                          const creditVariantUnits = +creditVariantsUnits[variantItem.id] / variantItem.minAmount;

                          return (
                            <Fragment key={variantItem.id}>
                              <LineItemRow
                                discounts={variantsDiscounts[variantItem.id]}
                                quantity={+variantsUnits[variantItem.id]}
                                credit={+creditVariantsUnits[variantItem.id] || 0}
                                service={variantItem}
                                appliedDiscount={appliedVariantDiscount}
                                openDiscountsModal={openDiscountsModal}
                                showFutureColumn={showFutureColumn()}
                                isVariantItem
                              />
                              <AppliedDiscounts
                                discounts={variantsDiscounts[variantItem.id] || []}
                                totalPrice={variantItem.price * (variantUnits + creditVariantUnits)}
                                openDiscountsModal={openDiscountsModal}
                                service={variantItem}
                                units={variantUnits + creditVariantUnits}
                                isVariantItem
                              />
                            </Fragment>
                          );
                        })}
                    </>
                  );
                })}
            {Boolean(accountChargeLineItems.length) &&
              accountChargeLineItems.map((lineItem) => {
                const service = services.find(({ id }: IServices) => id === lineItem.serviceId);

                const appliedServiceDiscount = !!servicesDiscounts[lineItem.serviceId];

                return (
                  <Fragment key={lineItem.id}>
                    <LineItemRow
                      quantity={lineItem.quantity}
                      credit={0}
                      discounts={servicesDiscounts[lineItem.serviceId]}
                      service={service}
                      appliedDiscount={appliedServiceDiscount}
                      openDiscountsModal={() => null}
                      showFutureColumn={false}
                      priceOverride={lineItem.totalWithoutDiscount}
                    />
                    <AppliedDiscounts
                      discounts={servicesDiscounts[lineItem.serviceId] || []}
                      totalPrice={lineItem.totalWithoutDiscount}
                      openDiscountsModal={openDiscountsModal}
                      service={service}
                      units={lineItem.quantity}
                    />
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <NewDiscountsModal
        appliedDiscounts={[...selectedDiscounts]}
        open={modalOpen}
        medicationData={selectedMedication}
        handleClose={handlePrePopulatedDiscountModalClose}
        applyCallback={applyDiscount}
      />
    </>
  );
};

export default LineItemTable;
