import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControl, MenuItem } from '@material-ui/core';
import { EHRCheckbox, EHRButton, EHRSelect } from 'src/components/ui/v1';
import InvitationModal from 'src/components/InvitationModal';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_PRACTITIONER } from '../../../constants/actions.constants';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LUXE, PORTRAIT_FLEX, PORTRAIT_LITE } from '../../../constants/general.constants';
import { useStyles, usePractitionersHeaderStyles } from './Practitioner.styles';
import InputSearch from '../../common/InputSearch';
import { IRoles } from '../../../interfaces/IRoles.interfaces';
import { useCreatePractitionerInvitation } from '../../../hooks/mutations/useCreatePractitionerInvitation';
import { REGISTRATION_SIGN_UP_PAGE } from '../../../constants/apiRoutes.constants';
import compile from '../../../utils/toastMessagesCompiler';
import { dispatch } from '../../../rematch';

interface PractitionerHeaderProps {
  inactiveChecked: boolean;
  onChangeInactiveCheckbox: () => void;
  onSearch: (event: React.ChangeEvent) => any;
  isLoading?: boolean;
  roles?: IRoles[];
  onSelectRole: (e: any) => void;
  selectedRole: string | undefined;
}

const PractitionerHeader: FC<PractitionerHeaderProps> = ({
  inactiveChecked,
  onChangeInactiveCheckbox,
  onSearch,
  isLoading = false,
  onSelectRole,
  selectedRole,
  roles,
}) => {
  const classesPractHeader = usePractitionersHeaderStyles();
  const classes = useStyles();
  const { permissions, roleName } = useSelector(({ auth }: any) => auth);
  const { mutateAsync: createInvitation } = useCreatePractitionerInvitation();
  const [invitationToken, setInvitationToken] = useState<string>();
  const [showInvitationModal, setShowInvitationModal] = useState<boolean>(false);
  const [selectedLinkRole, setSelectedLinkRole] = useState<IRoles>();
  const flexRole = roles?.find(({ name }: IRoles) => name === PORTRAIT_FLEX);
  const luxeRole = roles?.find(({ name }: IRoles) => name === PORTRAIT_LUXE);
  const liteRole = roles?.find(({ name }: IRoles) => name === PORTRAIT_LITE);

  const invitationUrl = `${window.location.origin}${REGISTRATION_SIGN_UP_PAGE}?code=${invitationToken}`;

  const handleCloseInviteModal = () => {
    setInvitationToken(undefined);
    setSelectedLinkRole(undefined);
    setShowInvitationModal(false);
  };

  const generateInvitationLink = async () => {
    if (selectedLinkRole) {
      const { success, data } = await createInvitation(selectedLinkRole.id);
      if (success) {
        setInvitationToken(data.token);
        setShowInvitationModal(true);
      } else {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'creating',
              element: 'invitation link',
            }),
            type: 'error',
          },
        });
      }
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" gridGap="1rem" flexWrap="wrap">
        <Box display="flex" flexGrow={1} gridGap="1rem" flexWrap="wrap">
          <Box className={classesPractHeader.practHeaderFormControlContainer}>
            <FormControl variant="outlined" className={classesPractHeader.practHeaderInputSearch}>
              <InputSearch placeholder="Search..." onChangeValue={onSearch} isLoading={isLoading} />
            </FormControl>
          </Box>

          <Box className={classesPractHeader.practHeaderFormControlContainer}>
            <FormControl variant="outlined" className={classesPractHeader.practHeaderShowInactive}>
              <EHRCheckbox
                dataCy="showInactive"
                label="Show Inactive providers"
                checked={inactiveChecked}
                onChange={onChangeInactiveCheckbox}
                value="order"
              />
            </FormControl>
          </Box>

          {roleName !== MEDSPA_ADMIN_ROLE && (
            <Box className={classesPractHeader.practHeaderFormControlContainer}>
              <FormControl variant="outlined" className={classesPractHeader.practHeaderRolesDropdown}>
                <EHRSelect dataCy="role" labelId="role" value={selectedRole} onChange={onSelectRole} fullWidth>
                  <MenuItem value="all">All Offerings</MenuItem>
                  {roles?.map((role) => (
                    <MenuItem value={role.id}>{role.name}</MenuItem>
                  ))}
                </EHRSelect>
              </FormControl>
            </Box>
          )}
        </Box>
        <Box display="flex" gridGap="1rem" flexWrap="wrap" className={classesPractHeader.practHeaderActionsContainer}>
          <Box>
            {hasAccessTo(CREATE_PRACTITIONER, permissions) && (
              <EHRButton
                dataCy="new-provider"
                text="New Provider"
                href="/administrator/new-practitioner"
                color="primary"
                className={classes.btnNewProvider}
              />
            )}
          </Box>
          <Box style={{ minWidth: '142px' }}>
            {hasAccessTo(CREATE_PRACTITIONER, permissions) && luxeRole && flexRole && liteRole && (
              <EHRButton
                dataCy="create-invite-link"
                text="Create Invite Link"
                color="primary"
                onClick={() => setShowInvitationModal(true)}
              />
            )}
          </Box>
        </Box>
      </Box>

      <InvitationModal
        open={showInvitationModal}
        onClose={handleCloseInviteModal}
        invitationToken={invitationToken}
        invitationUrl={invitationUrl}
        selectedLinkRole={selectedLinkRole}
        setSelectedLinkRole={setSelectedLinkRole}
        generateInvitationLink={generateInvitationLink}
        roles={{ flexRole, luxeRole, liteRole }}
      />
    </>
  );
};

export default PractitionerHeader;
