import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { makeStyles, TableCell, TableRow, Tooltip, withStyles } from '@material-ui/core';
import { InsertPhotoOutlined, MessageOutlined, FlipCameraIosOutlined, Update } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { isArray } from 'lodash';
import { dispatch } from '../../../../rematch';
import { PATIENT_BASE } from '../../../../constants/routes.constants';

export interface UpcomingPatientRowProps {
  row: any;
  state: string | undefined;
}

interface IAppointment {
  datetime: Date;
}

const useStyles = makeStyles(() => ({
  practitioner: {
    maxWidth: '150px !important',
    padding: 10,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  paddingStar: {
    padding: '0 5px 0 16px !important',
  },
  paddingClass: {
    padding: '0 0 0 14px',
  },
  cellName: {
    width: '160px',
    paddingLeft: 0,
    paddingRight: 0,
    color: '#12574d',
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  CreatedClass: {
    fontWeight: 600,
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '14px',
    width: '150px',
    padding: 0,
  },
  itemTab: {
    border: 'solid 2px #f2f5f5',
    cursor: 'pointer',
  },
  notificationsContainer: {
    '&> *:not(:last-child)': {
      marginRight: '.5rem',
    },
  },
}));

const TableRowStriped = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f5f5',
      height: '56px',
    },
  },
}))(TableRow);

const UpcomingPatientsRow: React.FC<UpcomingPatientRowProps> = (props) => {
  const { row, state } = props;
  const status = state;
  const classes = useStyles();
  const history = useHistory();
  const patientId = useSelector(({ patient }: any) => patient.id);

  const navigateToPatient = () => {
    if (row.customerId !== patientId) {
      dispatch.patient.clearPatientData();
    }
    history.push({
      pathname: `${PATIENT_BASE}/${row.customerId}`,
      state: { newState: status },
    });
  };

  const nextAppointment = (appointment: IAppointment | any) => {
    if (!appointment) {
      return '';
    }
    const appointmentDate = moment(appointment.datetime ? appointment.datetime : appointment);
    const lapse = appointmentDate.from(moment());
    return (
      <>
        {appointmentDate.format('MMMM  DD HH:mm')} <small>({lapse})</small>
      </>
    );
  };

  const NewMessages = ({ count }: { count: number }) => (
    <Tooltip title={`${count} new messages`}>
      <MessageOutlined />
    </Tooltip>
  );

  const UnseenPhotos = ({ count }: { count: number }) => (
    <Tooltip title={`${count} new photos`}>
      <InsertPhotoOutlined />
    </Tooltip>
  );

  const MedicalProfileUpdated = () => (
    <Tooltip title="medical profile updated since last view">
      <Update />
    </Tooltip>
  );
  const PhotoRequested = () => (
    <Tooltip title="new photos were requested">
      <FlipCameraIosOutlined data-cy="cameraIcon" />
    </Tooltip>
  );
  return (
    <>
      <TableRowStriped data-cy="tableRow" className={classes.itemTab} hover key={row.id}>
        <TableCell
          data-cy="fullName"
          className={`${classes.cellName} ${classes.paddingStar}`}
          onClick={navigateToPatient}
        >
          <span>
            {row.firstName} {row.lastName}
          </span>
        </TableCell>
        <TableCell data-cy="mrn" className={classes.paddingClass} onClick={navigateToPatient}>
          {row.customerId}
        </TableCell>
        <TableCell className={classes.paddingClass} onClick={navigateToPatient}>
          {row.id}
        </TableCell>
        <TableCell className={classes.practitioner} onClick={navigateToPatient}>
          {row.providerLabeling}
        </TableCell>
        <TableCell data-cy="email" className={classes.paddingClass} onClick={navigateToPatient}>
          {row.email}
        </TableCell>
        <TableCell data-cy="updateIcons" className={classes.notificationsContainer} onClick={navigateToPatient}>
          {row.unreadMessagesCount > 0 && <NewMessages count={row.unreadMessagesCount} />}
          {row.unseenPhotosCount > 0 && <UnseenPhotos count={row.unseenPhotosCount} />}
          {row.needsDoctorReview && <MedicalProfileUpdated />}
          {row.hasRequestedPhotos && <PhotoRequested />}
        </TableCell>
        <TableCell data-cy="nextAppoinmentDate" onClick={navigateToPatient}>
          {nextAppointment(row.datetime)}
        </TableCell>
        <TableCell data-cy="numberOfVisitsCell" onClick={navigateToPatient}>
          {isArray(row.visits) ? row.visits.length : row.visits}
        </TableCell>
      </TableRowStriped>
    </>
  );
};

export default UpcomingPatientsRow;
