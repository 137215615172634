import { OrderStatusBarParams } from 'src/interfaces/IInventoryOrder';
import { Box, Typography } from '@material-ui/core';
import { HEADER_TEXT_DEFAULT } from 'src/constants/inventory.constants';
import React, { useContext } from 'react';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import { useStyle } from './inventoryOrder.styles';
import { OrderContext } from '.';

const OrderStatusBar = ({ currentStep, steps }: OrderStatusBarParams) => {
  const classes = useStyle();

  const { orderData, isLoading } = useContext(OrderContext) || {};
  const isLegacy = orderData?.roleName === PORTRAIT_LEGACY || orderData?.roleName === PORTRAIT_LEGACY_PLUS;

  return (
    <Box className={classes.orderContainer}>
      <Box className={classes.orderStatusBar}>
        {isLegacy && !isLoading && <Typography className={classes.mediumHeader}>{HEADER_TEXT_DEFAULT}</Typography>}
        <Box className={classes.orderSteps}>
          {steps.map((step) => (
            <Box
              key={step.label}
              data-testid={`order step ${step.orderSequence}`}
              className={classes.orderStatusStep}
              data-current={step.orderSequence <= currentStep}
            >
              <Box>{step.orderSequence}</Box>
              <Typography className={classes.mediumHeader}>{step.label}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export { OrderStatusBar, OrderStatusBar as default };
