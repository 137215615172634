import { useInfiniteQuery } from 'react-query';
import { ACTIVITY_LOGS_PER_PAGE } from '../../constants/general.constants';
import { ACTIVITY_LOGS } from '../../constants/reactQuery.keys';

import Patients from '../../services/Patients';
import { IActivityLogRes } from '../../types/Patient';

export function useActivityLogs(patientId: string) {
  return useInfiniteQuery<IActivityLogRes[]>(
    [ACTIVITY_LOGS, patientId],
    ({ pageParam = 1 }) => Patients.getActivityLogs(patientId, pageParam, ACTIVITY_LOGS_PER_PAGE),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length && lastPage.length >= ACTIVITY_LOGS_PER_PAGE) {
          return pages.length + 1;
        }
        // No more activity logs to display
        return undefined;
      },
    }
  );
}

export { ACTIVITY_LOGS } from '../../constants/reactQuery.keys';
