import { Box, Container, Typography, withStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { formatCurrency } from 'src/utils/formatNumber';
import { InventoryOrderItem, InventoryOrderSpecial } from 'src/interfaces/IInventoryOrder';
import { ClassNameMap } from 'src/types/Dom';
import { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import { OrderContext } from '.';

const OrderOverview = ({ classes }: ClassNameMap): React.ReactElement => {
  const { orderData: { services = [], specials = [], customItems = [] } = {} } = useContext(OrderContext) || {};

  const filteredSpecials = specials.filter((special) => special.quantity > 0);

  return (
    <Container classes={classes} data-testid="orderOverview">
      <Typography className={classes?.heading}>Order Overview</Typography>
      <Box>
        <Typography>Product</Typography>
        <Typography>Qty</Typography>
        <Typography>Total</Typography>
      </Box>
      {customItems
        .filter(({ quantity }: InventoryOrderCustomItem): boolean => !!quantity)
        .map(
          ({ name, quantity, subtotal }: InventoryOrderCustomItem): React.ReactElement => (
            <Box key={name}>
              <Typography>{name}</Typography>
              <Typography>{quantity}</Typography>
              <Typography>{subtotal > 0 ? formatCurrency(subtotal) : 'awaiting review'}</Typography>
            </Box>
          )
        )}
      {services
        .filter(({ quantity }: InventoryOrderItem): boolean => !!quantity)
        .map(
          ({ name, quantity, subtotal }: InventoryOrderItem): React.ReactElement => (
            <Box key={name}>
              <Typography>{name}</Typography>
              <Typography>{quantity}</Typography>
              <Typography>{formatCurrency(subtotal)}</Typography>
            </Box>
          )
        )}

      <SpecialsComponent filteredSpecials={filteredSpecials} />
    </Container>
  );
};

type Props = {
  filteredSpecials: InventoryOrderSpecial[];
};

const SpecialsComponent = ({ filteredSpecials }: Props) =>
  filteredSpecials.length === 0 ? null : (
    <>
      <Box style={{ margin: '20px 0 0 0', color: '#777', borderBottom: 'none' }}>
        <Typography>Special</Typography>
        <Typography>Qty</Typography>
        <Typography>Total</Typography>
      </Box>
      {filteredSpecials?.map((special) => (
        <Box key={special.id}>
          <Typography>{special.specialOffer.productNameLabel}</Typography>
          <Typography>{special.quantity}</Typography>
          <Typography>{formatCurrency(special.subtotal)}</Typography>
        </Box>
      ))}
    </>
  );

export default withStyles({
  root: {
    maxHeight: 450,
    overflowY: 'scroll',
    '& > div': {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr 1fr',
    },
    '& > div > p': {
      fontSize: 13,
      margin: '10px 0 5px 0',
      paddingBottom: 5,
      borderBottom: '1px solid #eee',
    },
    '& > div:nth-child(2) > p': {
      margin: '20px 0 0 0',
      color: '#777',
      borderBottom: 'none',
    },
  },
  heading: {
    fontSize: 16,
    width: '100%',
    borderBottom: '1px solid #eee',
    textAlign: 'left',
    paddingBottom: 8,
  },
})(OrderOverview);
