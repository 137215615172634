import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { RootState } from 'src/rematch';
import { ORDERING_DISABLED } from 'src/constants/inventory.constants';
import { hasAccessTo } from '../utils/auth.utils';
import Header from '../components/Header';
import PractitionerTabs from '../components/DashboardPractitioner/Tabs/PractitionerTabs';
import { useGetOrderingStatus } from '../hooks/queries/useInventoryOrders';
import { OrderingStatusContext, OrderBanner } from '../components/DashboardPractitioner/Tabs/OrderingTab/OrderBanner';
import { READ_INVENTORY_ORDER_PERIOD, USE_EHR } from '../constants/actions.constants';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH } from '../routes/practitionerRoutes';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    width: '100%',
    overflowX: 'hidden',
  },
});

const DashboardPractitioner = () => {
  const { tabId, state }: any = useParams();
  const history = useHistory();

  const { permissions } = useSelector(({ auth }: RootState) => auth);
  const orderBannerEnabled = hasAccessTo(READ_INVENTORY_ORDER_PERIOD, permissions);

  const { data: orderingStatus } = useGetOrderingStatus(orderBannerEnabled);

  const bannerStatus = orderBannerEnabled ? orderingStatus || undefined : ORDERING_DISABLED;

  const classes = useStyles();
  const scrollMainRef = useRef(null);

  if (permissions?.length && !hasAccessTo(USE_EHR, permissions)) {
    history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('more-details'));
  }

  return (
    <div className={classes.root}>
      <main ref={scrollMainRef} className={classes.content}>
        <OrderingStatusContext.Provider value={bannerStatus}>
          <OrderBanner />
          <Header />
          <PractitionerTabs scrollerMain={scrollMainRef} tabs={tabId} state={state} />
        </OrderingStatusContext.Provider>
      </main>
    </div>
  );
};

export default DashboardPractitioner;
