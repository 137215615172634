import React, { useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, TextField, FormControl, InputLabel, MenuItem, Button, Grid, CircularProgress } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { SelectCustom } from '../../Assets/adminAssets.styles';
import {
  getInventoryBalanceReports,
  getOldDailyInventoryBalanceReports,
  getReports,
} from '../../../../services/Reports';

const REPORTS_OPTIONS = [
  { value: 'all_transactions_csv', name: 'All Transactions CSV' },
  { value: 'line_items_csv', name: 'Line Items CSV' },
  { value: 'discounts_csv', name: 'Discounts CSV' },
  { value: 'tenders_csv', name: 'Tenders CSV' },
  { value: 'assets_csv', name: 'Assets CSV' },
  { value: 'future_use_csv', name: 'Future Use CSV' },
  { value: 'inventory_balance_csv', name: 'Today Inventory Balance CSV' },
  { value: 'old_daily_inventory_balance_csv', name: 'Old Daily Inventory Balance CSV' },
  { value: 'unused_service_credit', name: 'Unused service credits CSV' },
];

const ReportsBody = () => {
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-01'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-15'));
  const [oldDailyReportDate, setOldDailyReportDate] = useState(moment().format('YYYY-MM-15'));
  const [reportType, setReportType] = useState('');
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [showResponse, setShowResponse] = useState('');

  const reset = () => {
    setShowResponse('');
  };

  const onChangeFromDate = (date: any) => {
    reset();
    setFromDate(date.format('YYYY-MM-DD'));
  };

  const onChangeToDate = (date: any) => {
    reset();
    setToDate(date.format('YYYY-MM-DD'));
  };

  const onChangeOldDailyReportDate = (date: any) => {
    reset();
    setOldDailyReportDate(date.format('YYYY-MM-DD'));
  };

  const CustomFromDateTextField = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...props} name="fromDate" style={{ marginBottom: '10px' }} fullWidth />
  );

  const CustomEndDateTextField = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...props} name="endDate" style={{ marginBottom: '10px' }} fullWidth />
  );

  const handleClickSendReport = async () => {
    reset();
    setIsGeneratingReport(true);
    try {
      if (reportType === 'inventory_balance_csv') {
        const { message } = await getInventoryBalanceReports(reportType);
        setShowResponse(message);
      } else if (reportType === 'old_daily_inventory_balance_csv') {
        const { message } = await getOldDailyInventoryBalanceReports(reportType, oldDailyReportDate);
        setShowResponse(message);
      } else {
        const { message } = await getReports(reportType, fromDate, toDate);
        setShowResponse(message);
      }
    } catch (e) {
      setShowResponse(
        'Error generating report. Please contact support if you have not received an email in the next few minutes.'
      );
    }

    setIsGeneratingReport(false);
  };

  const disableDatePickers = () =>
    reportType === 'inventory_balance_csv' || reportType === 'old_daily_inventory_balance_csv';

  const showOldDailyReportDate = () => reportType === 'old_daily_inventory_balance_csv';

  return (
    <Grid container spacing={2} style={{ padding: '1.5rem', height: '100%' }}>
      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Box>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              variant="dialog"
              inputVariant="outlined"
              label="From date"
              format="MM/DD/yyyy"
              autoOk
              disableToolbar
              value={fromDate}
              onChange={onChangeFromDate}
              TextFieldComponent={CustomFromDateTextField}
              disabled={disableDatePickers()}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              variant="dialog"
              inputVariant="outlined"
              label="To date"
              format="MM/DD/yyyy"
              autoOk
              disableToolbar
              value={toDate}
              onChange={onChangeToDate}
              TextFieldComponent={CustomEndDateTextField}
              disabled={disableDatePickers()}
            />
          </MuiPickersUtilsProvider>
        </Box>
        {showOldDailyReportDate() && (
          <Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                variant="dialog"
                inputVariant="outlined"
                label="Choose date of the report"
                format="MM/DD/yyyy"
                autoOk
                disableToolbar
                value={oldDailyReportDate}
                onChange={onChangeOldDailyReportDate}
                TextFieldComponent={CustomEndDateTextField}
                minDate="2022-11-16"
                maxDate="2022-12-15"
              />
            </MuiPickersUtilsProvider>
          </Box>
        )}
      </Grid>
      <Grid item md={2} />
      <Grid item xs={4}>
        <FormControl size="small" variant="outlined">
          <InputLabel>Report</InputLabel>
          <SelectCustom
            label="Report"
            name="report"
            onChange={(ev) => {
              reset();
              setReportType(ev.target.value as string);
            }}
          >
            {REPORTS_OPTIONS.map(({ value, name }) => (
              <MenuItem value={value} key={value}>
                {name}
              </MenuItem>
            ))}
          </SelectCustom>
        </FormControl>

        {isGeneratingReport && (
          <Box marginTop={2}>
            Generating report... <CircularProgress size={20} />
          </Box>
        )}
        {showResponse && <Box marginTop={2}>{showResponse}</Box>}
      </Grid>

      <Grid item xs={6}>
        <Button
          disabled={isGeneratingReport || !reportType || !fromDate || !toDate}
          variant="contained"
          color="primary"
          onClick={() => handleClickSendReport()}
          style={{ height: '55px' }}
        >
          Generate Report
        </Button>
      </Grid>
    </Grid>
  );
};

export default ReportsBody;
