import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CardContent, makeStyles, Typography } from '@material-ui/core';
import { Button } from '../common/Button';
import { Card } from '../common/card';
import { SQUARE_STATUS } from '../../constants/checkout.constants';
import { ICheckout } from '../../interfaces/checkout.interfaces';
import { formatNumber } from '../../utils/inventory.utils';
import PaymentTag from '../ServiceVisitCheckout/PaymentTag';

const useStyles = makeStyles(() => ({
  card: {
    margin: '0',
  },
  smallCard: {
    margin: '0',
    height: '160px',
  },
  cardContent: {
    padding: '11px 20px 9px !important',
  },
  smallTitle: {
    margin: '0',
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
  button: {
    backgroundColor: '#12574d',
    color: 'white !important',
    width: '170px',
    height: '47px',
    marginRight: 0,
  },
  contentCheckout: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentTotal: {
    marginTop: '10px',
    textAlign: 'right',
  },
}));

interface Props {
  checkout: ICheckout;
  disabled: boolean;
}

export const CheckoutStep = (props: Props) => {
  const { checkout, disabled } = props;
  const classes = useStyles();
  const history = useHistory();

  const { serviceVisitId, patientId }: any = useParams();

  const navigateToCheckout = () => {
    history.push(`/patient/${patientId}/newServiceVisit/${serviceVisitId}/checkout`);
  };

  return (
    <Card
      className={
        checkout.transactionStatus === 'pending' || typeof checkout.transactionStatus === 'undefined'
          ? classes.smallCard
          : classes.card
      }
    >
      <CardContent className={classes.cardContent}>
        <div className={classes.contentCheckout}>
          <Typography>Checkout</Typography>
          {checkout.transactionStatus && checkout.transactionStatus !== SQUARE_STATUS.PENDING ? (
            <PaymentTag
              transactionStatus={checkout.transactionStatus}
              squareErrorCode={checkout.squareErrorCode || ''}
              processedInSquare={checkout.processedInSquare}
              toPay={checkout.toPay}
              amountPaid={checkout.amountPaid}
              hasPartialRefund={checkout.hasPartialRefund}
            />
          ) : null}
        </div>
        <div className={classes.contentTotal}>
          {checkout.transactionStatus !== SQUARE_STATUS.OK ? (
            <Typography>Subtotal: $ {formatNumber(checkout.subtotal)} </Typography>
          ) : (
            <div>
              <Typography>Subtotal: $ {formatNumber(checkout.subtotal)} </Typography>
              {!!checkout.discounts && (
                <Typography color="textSecondary">Discount: -$ {formatNumber(checkout.discounts)}</Typography>
              )}
              <Typography>Total: $ {formatNumber(checkout.total)}</Typography>
              {!!checkout.brilliantValue && (
                <Typography
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '15px',
                  }}
                  color="textSecondary"
                >
                  All&#x0113;: -$ {formatNumber(checkout.brilliantValue)}
                </Typography>
              )}
              {!!checkout.alleCherryValue && (
                <Typography
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '15px',
                  }}
                  color="textSecondary"
                >
                  All&#x0113; Payment with Cherry: -$ {formatNumber(checkout.alleCherryValue)}
                </Typography>
              )}
              {!!checkout.serviceCreditsValue && (
                <Typography color="textSecondary">
                  Service Credits: -$ {formatNumber(checkout.serviceCreditsValue)}
                </Typography>
              )}
              {!!checkout.aspireValue && (
                <Typography color="textSecondary">
                  ASPIRE: -${' '}
                  {formatNumber(
                    Array.isArray(checkout.aspireValue)
                      ? checkout.aspireValue.reduce((acc: number, val: number) => acc + val)
                      : checkout.aspireValue
                  )}
                </Typography>
              )}
              {!!checkout.careCreditValue && (
                <Typography color="textSecondary">CareCredit: -$ {formatNumber(checkout.careCreditValue)}</Typography>
              )}
              {checkout.referralValue > 0 && (
                <Typography color="textSecondary">Referral: -$ {formatNumber(checkout.referralValue)}</Typography>
              )}
              {!!checkout.xperienceValue && (
                <Typography color="textSecondary">Xperience: -$ {formatNumber(checkout.xperienceValue)}</Typography>
              )}
              {!!checkout.patientfiValue && (
                <Typography color="textSecondary">PatientFi: -$ {formatNumber(checkout.patientfiValue)}</Typography>
              )}

              <Typography>Account credit: $ {formatNumber(checkout.accountCreditUsed)}</Typography>
              {!!checkout.amountPaid && checkout.amountPaid < checkout.toPay ? (
                <>
                  <Typography>Total paid: $ {formatNumber(checkout.amountPaid)}</Typography>
                  <Typography>Amount due: $ {formatNumber(checkout.toPay - checkout.amountPaid)}</Typography>
                </>
              ) : (
                <Typography>Total paid: $ {formatNumber(checkout.toPay)}</Typography>
              )}

              {checkout.tips > 0 && <Typography>Tips: $ {formatNumber(checkout.tips)}</Typography>}
            </div>
          )}
        </div>
        <div className={classes.contentButton}>
          <Button
            dataCy="checkoutBtn"
            className={classes.button}
            title={
              checkout.transactionStatus === 'pending' || typeof checkout.transactionStatus === 'undefined'
                ? 'CHARGE'
                : 'VIEW TRANSACTION'
            }
            onClick={navigateToCheckout}
            disabled={disabled}
          />
        </div>
      </CardContent>
    </Card>
  );
};
