import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, CardContent, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { ActiveStepWrapper } from './ActiveStepWrapper';
import { Card, SmallTitle } from '../common/card';
import { dispatch } from '../../rematch';
import { useStyles } from './standingOrders.styles';
import useLog from '../../hooks/mutations/useLog';

export const StandingOrders = () => {
  const classes = useStyles();
  const newServiceVisit = useSelector((state: any) => state.newServiceVisit);
  const { signedStandingOrders, selectedServices: allSelectedServices, services, totalServicesUnits } = newServiceVisit;
  const patient = useSelector((state: any) => state.patient);

  const { mutateAsync: log } = useLog();

  useEffect(() => {
    if (patient.id && newServiceVisit.serviceVisit.id) {
      dispatch.patient.fetchCustomerStandingOrders({
        patientId: patient.id,
        serviceVisitId: newServiceVisit.serviceVisit.id,
      });
    }
  }, [patient.id, newServiceVisit.serviceVisit]);

  const onOrderCheck = (serviceId: number): void => {
    const newSignedStandingOrders = [...newServiceVisit.signedStandingOrders];
    const standingOrder = newSignedStandingOrders.find((standingOrderId: number) => standingOrderId === serviceId);

    if (!standingOrder) {
      newSignedStandingOrders.push(serviceId);
    }
    dispatch({
      type: 'newServiceVisit/updateStandingOrders',
      payload: { signedStandingOrders: newSignedStandingOrders },
    });
  };

  const getStandingOrderLinkOld = (groupName: string): string | undefined => {
    let pdfName: string = groupName.replace(/ /g, '_');
    pdfName = `${pdfName.toLocaleLowerCase()}.pdf`;

    try {
      // eslint-disable-next-line import/no-dynamic-require, global-require, import/no-dynamic-require
      return require(`../../assets/standingOrders/${pdfName}`).default;
    } catch (er) {
      return undefined;
    }
  };

  const getStandingOrderLink = (groupName: string): string | undefined => {
    try {
      const customerStandingOrder = patient.customerStandingOrders.filter(
        (so: { serviceGroupName: string }) => so.serviceGroupName === groupName
      );
      const closestStandingOrderToServiceVisit = customerStandingOrder[0];

      if (!closestStandingOrderToServiceVisit) {
        return getStandingOrderLinkOld(groupName);
      }

      return closestStandingOrderToServiceVisit.standingOrderPdfUrl;
    } catch (er) {
      return undefined;
    }
  };

  const getServicePDFLink = (serviceGroupId: number): string | undefined => {
    const serviceGroup = patient.serviceGroups.find((sg: any) => sg.id === serviceGroupId);

    if (serviceGroup) {
      return getStandingOrderLink(serviceGroup.name);
    }

    return undefined;
  };

  const updateCurrentStep = (): void => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 6 });
    log({
      feature: 'Service Visit',
      step: 6,
      logId: null,
      params: {
        patient,
        newServiceVisit,
      },
    });
  };

  const renderStandingOrders = () => {
    const customerStandingOrders = allSelectedServices?.map((serviceId: number) => {
      const service = services.find((s: any) => s.id === serviceId && +totalServicesUnits[serviceId] > 0);
      const { opened } = newServiceVisit.serviceVisit;
      return (
        service &&
        service.hasStandingOrder && (
          <div key={serviceId} className={classes.standingOrder}>
            <FormControlLabel
              classes={{ root: classes.formControlLabel, label: `${classes.checkboxLabel} ${classes.label}` }}
              label={service.name}
              control={
                <Checkbox
                  data-cy="standingOrderCheckbox"
                  color="default"
                  checked={signedStandingOrders.includes(serviceId)}
                  classes={{ checked: classes.checkbox }}
                  onChange={() => {
                    onOrderCheck(serviceId);
                  }}
                  disabled={signedStandingOrders.includes(serviceId) || !opened}
                />
              }
            />
            <a
              className={`${classes.documentLink} ${classes.label}`}
              href={getServicePDFLink(service.serviceGroupId)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Document Link
            </a>
          </div>
        )
      );
    });

    if (!customerStandingOrders.filter((elem: any) => elem !== false).length) {
      return (
        <Box component="p" mt={0}>
          No standing orders
        </Box>
      );
    }

    return customerStandingOrders;
  };

  return (
    <ActiveStepWrapper step={6} onClick={updateCurrentStep}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <SmallTitle title="Standing orders reviewed and accepted" className={classes.smallTitle} />
          <FormGroup className={classes.formGroup}>{renderStandingOrders()}</FormGroup>
        </CardContent>
      </Card>
    </ActiveStepWrapper>
  );
};
