import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line max-len
import { CompletedInventoryReport } from '../components/DashboardPractitioner/InventoryWizard/CompletedInventoryReport/CompletedInventoryReport';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import { dispatch } from '../rematch';

const CompletedInventoryReportPage = () => {
  const history = useHistory();
  const { reportId }: any = useParams();

  useEffect(() => {
    dispatch({
      type: 'inventoryPractitioner/fetchReviewedReconciliationReport',
      payload: { reportId },
    });
  }, []);

  const navigateBack = () => {
    history.goBack();
  };

  return (
    <article>
      <NavigationBar title="Completed Inventory Report" onBackButtonClick={navigateBack} />
      <CompletedInventoryReport />
    </article>
  );
};

export default CompletedInventoryReportPage;
