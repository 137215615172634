import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  creditCardForm: {
    width: '400px',
    position: 'relative',
  },
  processing: {
    color: 'white',
    marginLeft: theme.spacing(2),
    position: 'relative',
    top: '4px',
  },
  buttonOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    zIndex: 99,
  },
}));
