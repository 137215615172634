import React, { useContext, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes.constants';
import {
  ERR_PAYMENT_FAILED,
  ERR_PAYMENT_TIMEOUT,
  ERR_SUBMIT_FAILED,
  MEDSPA_ONBOARDING_ORDERING_URI_PARAMS,
  PAYMENT_STATUS_AUTHORIZED,
  PAYMENT_STATUS_COMPLETED,
} from 'src/constants/inventory.constants';
import { MEDSPA_ADMIN_INVENTORY } from 'src/routes/medspaAdminRoutes';
import { useFinalizeOrder, usePollPaymentStatus, useVoidPayment } from 'src/hooks/queries/useInventoryOrders';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { ONBOARDING_STEPS, SHOW_SUCCESS_MESSAGE } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { showOrderingError, watchPaymentStatus } from 'src/utils/inventoryOrdering';
import { ClassNameMap } from 'src/types/Dom';
import { PAYMENT_STATUS } from 'src/constants/reactQuery.keys';
import { OrderContext } from '..';
import ConfirmDialog from './ConfirmDialog';

const OrderConfirmDialog: React.FC<
ClassNameMap & {
  open: boolean;
  closeDialog?: () => void;
}
> = ({ open = true, closeDialog, classes }) => {
  const history = useHistory();

  const [finalizing, setFinalizing] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [attempts, setAttempts] = useState<number>(0);

  const {
    orderData: { id = 0, total = 0, desiredDeliveryDate = null, paymentStatus: initialPaymentStatus = null } = {},
    isAdmin,
    isLegacy,
  } = useContext(OrderContext) || {};

  const isAlreadyAuthorized =
    !!initialPaymentStatus && [PAYMENT_STATUS_COMPLETED, PAYMENT_STATUS_AUTHORIZED].indexOf(initialPaymentStatus) > -1;

  const pollPaymentStatus = !isLegacy && !isAlreadyAuthorized && confirmed && !isAuthorized;

  const queryClient = useQueryClient();
  const { data: paymentStatus, isError } = usePollPaymentStatus({
    id,
    enabled: pollPaymentStatus,
    onSuccess: () => setAttempts(attempts + 1),
  });
  const { onboardingEnabled, allStepsCompleted } = useMedspaAdminOnboarding();
  const { mutateAsync: voidPayment } = useVoidPayment(id);
  const { mutateAsync: finalizeOrder } = useFinalizeOrder(id);
  const { mutateAsync: updateOnboardingProgress } = useUpdateMedspaAdminOnboardingProgress();

  const getOnboardingParams = async () => {
    try {
      const { completed, message } = await updateOnboardingProgress({
        stepName: ONBOARDING_STEPS.INVENTORY_ORDER_NAME,
        percentage: 100,
      });
      return completed && message === SHOW_SUCCESS_MESSAGE ? MEDSPA_ONBOARDING_ORDERING_URI_PARAMS : '';
    } catch {
      return '';
    }
  };

  const resetAndClose = () => {
    setConfirmed(false);
    setIsAuthorized(false);
    setFinalizing(false);
    setIsLoading(false);
    closeDialog?.();
  };

  const onPaymentFail = async (webhookTimeoutError: boolean) => {
    setConfirmed(false);
    setAttempts(0);
    showOrderingError('authorizing payment', webhookTimeoutError ? ERR_PAYMENT_TIMEOUT : ERR_PAYMENT_FAILED);
    try {
      if (webhookTimeoutError) {
        await voidPayment();
      }
    } finally {
      resetAndClose();
    }
  };

  const confirm = async () => {
    queryClient.setQueryData([PAYMENT_STATUS, id], undefined);
    setIsLoading(true);
    setConfirmed(true);

    if (isAlreadyAuthorized) {
      setIsAuthorized(true);
    }
    if (isLegacy || isAlreadyAuthorized) {
      await finalize();
    }
  };

  const finalize = async () => {
    setFinalizing(true);

    try {
      const { finalized } = (await finalizeOrder(true)) ?? {};

      if (finalized) {
        if (isAdmin) {
          const onboardingParams = onboardingEnabled && !allStepsCompleted ? await getOnboardingParams() : '';
          history.push(MEDSPA_ADMIN_INVENTORY + onboardingParams);
        } else {
          history.push(isLegacy ? ROUTES.ORDER_HISTORY : ROUTES.FLEX_ORDER_HISTORY);
        }
      } else {
        throw new Error(ERR_SUBMIT_FAILED);
      }
    } catch (error: unknown) {
      try {
        if (isAlreadyAuthorized || isAuthorized) {
          await voidPayment();
        }
      } finally {
        if ((error as Error)?.message === ERR_SUBMIT_FAILED) {
          showOrderingError('finalizing order', ERR_SUBMIT_FAILED);
        }
        resetAndClose();
      }
    }
  };

  useEffect(() => {
    watchPaymentStatus({
      attempts,
      isError,
      status: paymentStatus,
      enabled: pollPaymentStatus,
      onSuccess: async () => {
        setIsAuthorized(true);
        setAttempts(0);
        await finalize();
      },
      onFail: onPaymentFail,
    });
  }, [pollPaymentStatus, paymentStatus, attempts, isError]);

  return (
    <ConfirmDialog
      classes={classes}
      open={open}
      closeDialog={closeDialog}
      confirm={confirm}
      isLegacy={isLegacy}
      desiredDeliveryDate={desiredDeliveryDate}
      total={total}
      finalizing={finalizing}
      validating={pollPaymentStatus}
      isLoading={isLoading}
    />
  );
};

export default OrderConfirmDialog;
