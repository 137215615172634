export enum BULK_UPLOAD_TYPES {
  PATIENT_PROFILES = 'patientProfiles',
  MEDICAL_PROFILES = 'medicalProfiles',
  SKIN_PROFILES = 'skinProfiles',
}
export const REQUIRED_FIELDS = 'The following fields are required:';

export const PATIENT_TYPE = {
  name: 'All-patients',
  type: BULK_UPLOAD_TYPES.PATIENT_PROFILES,
  fields: [
    { name: 'firstName', displayName: 'First Name' },
    { name: 'lastName', displayName: 'Last Name' },
    { name: 'email', displayName: 'Email' },
    { name: 'phone', displayName: 'Phone' },
    { name: 'address', displayName: 'Address' },
    { name: 'birthdate', displayName: 'Birthdate' },
  ],
  example: {
    firstName: 'first name',
    lastName: 'last name',
    email: 'email',
    phone: 'phone number',
    address: 'address, city, state, zip code',
    birthdate: 'MM/DD/YYYY',
  } as Record<string, string>,
};
