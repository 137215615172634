import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    padding: '1.5rem',
    backgroundColor: '#fff',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#000000',
  },
  titleSecundary: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgb(172, 172, 172)',
  },
  formControl: {
    width: '100%',
  },
  add: {
    width: '100%',
    height: '55px',
  },
  clipboardLink: {
    color: '#2172BB',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    textDecoration: 'underline',
    border: 'none',
    background: 'transparent',
    marginLeft: '20px',
  },
  selectedPractitioner: {
    fontFamily: 'Messina Sans Regular',
    borderRadius: '4px',
    backgroundColor: '#e7eeed',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.93,
    letterSpacing: 'normal',
    color: '#000000',
    padding: '11px 6px 11px 11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '46px',
  },
  dialogActions: {
    display: 'block',
    textAlign: 'center',
  },
  message: {
    height: '40px',
    background: '#F2D1AB',
    lineHeight: '40px',
    padding: '0px 5px',
    marginTop: '10px',
  },
  dropdown: {
    fontFamily: 'Messina Sans Regular',
    height: '46px',
  },
  formDropdown: {
    width: '255px',
    left: '30px',
  },
  formInputSelect: {
    top: '-4px',
    color: '#000',
  },
  checkbox: {
    color: '#12574d',
    marginLeft: '20px',
  },
  containerDialog: {
    width: '450px',
  },
  buttonRight: {
    background: theme.palette.primary.main,
    color: 'white',
  },
  buttonLeft: {
    background: '#FBDADA',
  },
  stepsContainer: {
    display: 'flex',
    marginBottom: '2rem',
    alignItems: 'end',
    padding: '0 8px',
  },
  stepContainer: {
    width: '15rem',
    marginRight: '1rem',
  },
  stepTitle: {
    color: '#12574D',
    fontSize: '18px',
  },
  stepSubtitle: {
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  stepBarActive: {
    width: '100%',
    background: '#12574D',
    border: 'none',
    height: '8px',
  },
  stepBarInactive: {
    width: '100%',
    border: 'none',
    height: '8px',
    background: '#12574D',
    opacity: '30%',
  },
  mt2: {
    marginTop: '2rem',
  },
  ml2: {
    marginLeft: '2rem',
  },
  actionButton: {
    color: 'white',
    background: '#12574D',
    borderRadius: '8px',
    padding: '10px 8px',
    border: 'none',
    fontSize: '16px',
    width: '168px',
    cursor: 'pointer',
    '&:disabled': {
      color: 'white',
      opacity: '50%',
    },
  },
  backButton: {
    border: 'none',
    background: 'none',
    color: '#12574D',
    fontSize: '16px',
    cursor: 'pointer',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '12px',
  },
  uploadSignature: {
    display: 'flex',
    alignItems: 'center',
  },
  practitionerInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    alignItems: 'center',
    '& > div': {
      margin: '0',
    },
  },
  profileImage: {
    width: '80px',
    height: '80px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  roleAndFullName: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    paddingLeft: '1rem',
    '& > h5': {
      fontSize: '16px',
    },
    '& > p': {
      fontSize: '10px',
      backgroundColor: 'rgb(233,248,221)',
      margin: '5px 0 10px 0',
      padding: '3px 12px',
      borderRadius: '6px',
      fontFamily: 'Messina Sans Regular',
    },
  },
  formsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  formContainerLeft: {
    padding: '1.5rem',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    margin: '10px 10px 20px 0',
  },
  formContainerRight: {
    padding: '1.5rem',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    margin: '10px 0 10px 10px',
  },
  formMargin: {
    margin: '0.75rem 0',
  },
  imageContainer: {
    width: '140px',
    height: 'auto',
  },
  signatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0.75rem 0',
  },
  signatureImage: {
    width: '140px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    margin: '0.75rem 0',
  },
  formItem: {
    padding: '10px',
  },
  formItemLegend: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '12px',
    paddingTop: '18px',
    '@media (max-width: 1279px)': {
      paddingTop: '0px',
    },
  },
  formItemTitle: {
    fontSize: '110%',
  },
  formMinutesBeforeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.875rem',
  },
  subField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.875rem',
  },
  collapseRow: {
    width: '99.5%',
  },
  textField: {
    paddingTop: '0.5rem',
    width: '99.5%',
  },
  hiddenSection: {
    display: 'none',
  },
}));
