import React, { useState, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { CardContent } from '@material-ui/core';
import { Card } from '../../../common/card';
// eslint-disable-next-line max-len
import { LIMIT_INITIAL_CUSTOMER_NOTE, PRACTITIONER_ROLE } from '../../../../constants/general.constants';
import { dispatch } from '../../../../rematch';
import { PatientProfileNote } from '../../../common/PatientProfileNote';

const PractitionersNotes = () => {
  const [message, setMessage] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showMorePractitionerNotes, setMorePractitionerNotes] = useState<boolean>(false);
  const patient = useSelector((state: any) => state.patient);
  const { notes, id } = patient;
  const userType = useSelector(({ auth }: any) => auth.userType);
  const notesData = _.orderBy(notes, ['updatedAt'], ['desc']).filter(({ practitionerId }: any) => practitionerId);

  const clearMessage = () => {
    setDisabled(false);
    setMessage('');
  };

  const viewMorePractitionerNotes = () => {
    setMorePractitionerNotes(!showMorePractitionerNotes);
  };

  const addNote = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisabled(true);
    if (message.trim() !== '') {
      dispatch({
        type: 'patient/addNoteRequest',
        payload: {
          message,
          id,
          callback: clearMessage,
        },
      });
    }
  };

  const deleteMessage = (noteId: number) => {
    dispatch({ type: 'patient/deleteNoteRequest', payload: { noteId } });
  };

  const notesLengthPractitionerNotes = showMorePractitionerNotes ? notes.length : LIMIT_INITIAL_CUSTOMER_NOTE;

  return (
    <Card>
      <CardContent>
        <PatientProfileNote
          title="Practitioner's notes"
          contentPropName="content"
          onSubmit={addNote}
          disabled={disabled}
          inputValue={message}
          onChangeValue={setMessage}
          notesLength={notesLengthPractitionerNotes}
          onDelete={deleteMessage}
          viewMoreState={showMorePractitionerNotes}
          viewMoreAction={viewMorePractitionerNotes}
          data={notesData}
          practitionerNote
          readOnly={userType !== PRACTITIONER_ROLE}
        />
      </CardContent>
    </Card>
  );
};

export default PractitionersNotes;
