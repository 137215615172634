import React, { useEffect, useState, useMemo } from 'react';
import { TextField, Button, debounce, withStyles, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { dispatch } from '../../../../rematch';

const useStyles = makeStyles(() => ({
  inputClass: {
    display: 'flex',
    alignItems: 'flex-end',
    color: 'black',
    paddingTop: 5,
    fontFamily: 'Messina Sans Regular',
    fontSize: '14px',
    opacity: 0.47,
  },
}));

const mapState = (state: any) => ({
  isLoading: state.loading.effects.square.matchCustomer,
  undefined: state.practitioner.undefined,
  hasError: state.square.hasError,
  message: state.square.message,
});

const mapDispatch = ({ practitioner: { getPatients }, square: { matchCustomer }, todo: { loadTodos } }: any) => ({
  getPatients: () => getPatients(),
  matchCustomer: () => matchCustomer(),
  loadTodos: () => loadTodos(),
});

const AutoComplete = withStyles((theme) => ({
  root: {
    backgroundColor: 'white',
  },
  input: {
    display: 'inline !important',
    [theme.breakpoints.down('sm')]: {
      width: '60px !important',
    },
  },
}))(Autocomplete);

const ButtonMatch = withStyles(() => ({
  root: {
    display: 'inline !important',
  },
}))(Button);

const InputMatching = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line no-shadow-restricted-names
  const { undefined, orderId, isLoading, hasError, message } = props;
  const [value, setValue] = useState<any>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  });
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    dispatch.practitioner.getPatients({ group: 'undefined', view: 'all', page: 1 });
  }, []);

  useEffect(() => {
    if (hasError) {
      enqueueSnackbar(message, { variant: 'error', preventDuplicate: true });
    }
  }, [hasError]);

  const searchPatients = (query: any) => {
    dispatch.practitioner.getPatients({ group: 'undefined', view: 'all', page: 1, search: query.trim() });
  };

  const doSearch = useMemo(() => debounce((query: any) => searchPatients(query), 2000), []);

  const onInputChange = (event: any) => {
    doSearch(event);
  };

  const onClickMatching = () => {
    dispatch.square.matchCustomer({ orderId, customerId: value.id });
    dispatch.todo.loadTodos();
  };

  const classes = useStyles();

  return (
    <>
      <AutoComplete
        size="small"
        value={value}
        onChange={(event: any, newValue: any) => {
          setValue({
            id: newValue.id,
            firstName: newValue.firstName,
            lastName: newValue.lastName,
            email: newValue.email,
          });
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          onInputChange(newInputValue);
        }}
        freeSolo
        disableClearable
        options={undefined.results}
        getOptionLabel={(patient: any) =>
          !patient.id || !patient.firstName || !patient.lastName ? '' : `${patient.firstName} ${patient.lastName}`
        }
        renderOption={(option: any) => (
          <span>
            {option.firstName} {option.lastName} <span style={{ color: 'gray' }}>({option.email})</span>
          </span>
        )}
        renderInput={(params: any) => (
          <TextField
            size="small"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={<div className={classes.inputClass}> Search patients </div>}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <ButtonMatch
                  disabled={isLoading}
                  onClick={() => onClickMatching()}
                  style={{
                    fontSize: '14px',
                    height: '33px',
                    backgroundColor: '#e7eeed',
                    width: '77px',
                  }}
                  variant="contained"
                  disableElevation
                >
                  MATCH
                </ButtonMatch>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default connect(mapState, mapDispatch)(InputMatching);
