import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH, PRACTITIONER_HOME_PATH } from '../../../routes/practitionerRoutes';
import { dispatch } from '../../../rematch';
import { useStyles } from './index.styles';
import { usePractitionerInfo } from '../../../hooks/queries/usePractitioners';

export const CompletedOnboarding = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: identityId, userId } = useSelector(({ auth }: any) => auth);
  const { practitionerInfo } = usePractitionerInfo(userId, identityId);

  useEffect(() => {
    if (practitionerInfo?.isEhrReady) {
      history.replace(PRACTITIONER_HOME_PATH);
    }
  }, [practitionerInfo]);

  const handleLogout = () => {
    dispatch.auth.logout();
  };

  return (
    <>
      <Header currentStepName="Complete" currentStep={5} />
      <div className={classes.container}>
        <h2>Congratulations! You&apos;ve reached the final step of your account setup.</h2>
        <p>
          Your dedicated operations associate will contact you shortly to schedule a meeting and review the information
          you provided.
        </p>
        <p>
          They will work with you to complete your account configuration and ensure everything is set up exactly how you
          want it.
        </p>
      </div>
      <Footer
        saveTitle="Logout"
        isSaving={false}
        currentStep={5}
        handleSubmit={handleLogout}
        backLink={PRACTITIONER_ACCOUNT_SETTINGS_PATH('calendar-configuration')}
      />
    </>
  );
};
