import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useMutation, useQueryClient } from 'react-query';
import Patients from '../../../services/Patients';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import { useStyles } from './medicalProfileForm.styles';
import { FemaleCondition } from './FemaleCondition';
import { FreeTextFields } from './FreeTextFields';
import { Multiselect } from './Multiselect';
import Procedures from './Procedures';
import Conditions from './Conditions';
import { useCustomPatientProfile } from '../../../hooks/queries/useCustomPatientProfile';
import { useCustomMedicalProfile } from '../../../hooks/queries/useCustomMedicalProfile';
import { FEMALE } from '../../../constants/general.constants';
import { MEDICAL_PROFILE } from '../../../constants/reactQuery.keys';
import BiologicalSex from './BiologicalSex';
import { WeightFields } from './WeightFields';

const showMessage = (message: any, type: string = 'success') => {
  dispatch({
    type: 'snackbar/enqueueSnackBar',
    payload: {
      message,
      type,
    },
  });
};

const MedicalProfileForm = () => {
  const classes = useStyles();
  const { patientId }: any = useParams();
  const { data: patient, isLoading: isLoadingPatient } = useCustomPatientProfile(patientId);
  const { data: medicalProfile, isLoading: isLoadingMedicalProfile } = useCustomMedicalProfile(patientId);

  const isLoading = isLoadingMedicalProfile || isLoadingPatient;
  const queryClient = useQueryClient();

  const medicalProfileMutation = useMutation((data: any) => Patients.updateMedicalProfile(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(MEDICAL_PROFILE);
      showMessage(
        compile('generic.success_message', {
          element: 'Medical profile',
          action: 'updated',
        })
      );
    },
    onError: () => {
      queryClient.invalidateQueries(MEDICAL_PROFILE);
      showMessage(
        compile('generic.error_message', {
          action: 'updating',
          element: 'the medical profile',
        }),
        'error'
      );
    },
  });

  const onSave = (data: any) => {
    medicalProfileMutation.mutate({ customerId: patientId, medicalProfile: { ...medicalProfile, ...data } });
  };

  return (
    <div>
      <div className={classes.nameContainer}>
        {isLoading ? (
          <Skeleton style={{ height: '20px', width: '100px' }} />
        ) : (
          <h4 className={classes.name}>{`${patient?.firstName} ${patient?.lastName}`}</h4>
        )}
      </div>
      <div className={classes.form}>
        <Grid container spacing={1} className={classes.grid}>
          {medicalProfile && <BiologicalSex medicalProfile={medicalProfile} />}
          {medicalProfile?.sex === FEMALE && (
            <>
              <FemaleCondition
                save={onSave}
                question="Are you pregnant?"
                objectKey="isPregnant"
                value={medicalProfile.isPregnant}
              />
              {medicalProfile.isPregnant === 'no' && (
                <FemaleCondition
                  save={onSave}
                  question="Are you actively trying or expecting to become pregnant within the next 12 months?"
                  objectKey="intendingPregnant"
                  value={medicalProfile.intendingPregnant}
                />
              )}
              <FemaleCondition
                save={onSave}
                question="Are you breast feeding?"
                objectKey="isBreastFeeding"
                value={medicalProfile.isBreastFeeding}
              />
            </>
          )}
          <Multiselect
            question="Have any medication allergies?"
            objectKey="medicationAllergies"
            indexName="Allergies_development"
            flag={medicalProfile?.hasMedicationAllergies}
            flagName="hasMedicationAllergies"
            values={medicalProfile?.medicationAllergies?.map((name: any) => ({ name })) || []}
          />
          <FreeTextFields
            question="Any other allergies (foods, plants, etc.)?"
            objectKey="knownAllergies"
            value={medicalProfile?.knownAllergies || ''}
            flag={medicalProfile?.hasKnownAllergies}
            flagName="hasKnownAllergies"
            placeholder="Describe your allergies..."
          />
          <WeightFields
            onSave={onSave}
            interestedInWeightLoss={medicalProfile?.interestedInWeightLoss}
            heightValue={medicalProfile?.heightInCms}
            weightValue={medicalProfile?.weightInLbs}
          />
          <Multiselect
            question="Are you currently using any oral prescription medications?"
            objectKey="oralMedications"
            indexName="Orals_development"
            values={medicalProfile?.oralMedications || []}
          />
          <Multiselect
            question="Are you currently using any topical prescription medications?"
            objectKey="topicalMedications"
            indexName="Topicals_development"
            values={medicalProfile?.topicalMedications || []}
          />
          <Multiselect
            question="Are you taking any other prescription medications? Please include injections, 
            implants (ex: IUD), pumps (ex: insulin), pellets (ex: HRT), eye drops, and any others"
            objectKey="otherMedications"
            indexName="Others_development"
            values={medicalProfile?.otherMedications || []}
          />
          <FreeTextFields
            question="Is there anything else you would like us to know?"
            objectKey="userSkinExtraDetails"
            placeholder="Please explain here"
            value={medicalProfile?.userSkinExtraDetails || ''}
          />
          <FreeTextFields
            question="Do you have any other active medical conditions or symptoms?"
            objectKey="userActiveMedicalConditions"
            placeholder="Ex. Asthma, Hemophilia, Epilepsy..."
            value={medicalProfile?.userActiveMedicalConditions || ''}
          />
          <Procedures save={onSave} question="Have you had any procedures?" value={medicalProfile?.procedures || {}} />
          <Conditions
            save={onSave}
            question="Have you had any of the following conditions?"
            value={medicalProfile?.conditions || {}}
          />
        </Grid>
      </div>
    </div>
  );
};

export default MedicalProfileForm;
