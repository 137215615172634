import { useMutation } from 'react-query';
import { MedspaCalendar } from 'src/services/medspaAdmins/MedspaCalendar';
import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import compile from 'src/utils/toastMessagesCompiler';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { queryClient } from 'src/initializers/queryClient';

/* Update block off hour configuration */
export const useCreateMedspaBlockOffMutation = (userGroupId: number, successCallback?: () => void) =>
  useMutation((params: any) => MedspaCalendar.createBlockOff(userGroupId, params), {
    onSuccess: ({ success }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          element: 'Block off hours',
          action: 'created',
        }),
        SNACKBAR_SUCCESS
      );

      queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf('medspaCalendar') > -1 });
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });

export const useDeleteMedspaBlockOffMutation = (userGroupId: number | null, successCallback?: () => void) =>
  useMutation((blockOffHourId: number) => MedspaCalendar.deleteBlockOff(userGroupId, blockOffHourId), {
    onSuccess: ({ success }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          element: 'Block off hours',
          action: 'deleted',
        }),
        SNACKBAR_SUCCESS
      );

      // TODO: invalidate block off medspa query
      queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf('medspaCalendar') > -1 });
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });

export const useUpdateMedspaBlockOffMutation = (
  userGroupId: number | null,
  blockOffHourId: number | null,
  successCallback?: () => void
) =>
  useMutation((params: any) => MedspaCalendar.updateBlockOff(userGroupId, blockOffHourId, params), {
    onSuccess: ({ success }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          element: 'Block off hours',
          action: 'updated',
        }),
        SNACKBAR_SUCCESS
      );

      // TODO: invalidate block off medspa query
      queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf('medspaCalendar') > -1 });
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });
