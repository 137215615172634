import { STANDING_ORDERS, STANDING_ORDER_SUGGESTIONS } from 'src/constants/apiRoutes.constants';
import Api from './Api';
import IStandingOrder from '../interfaces/IStandingOrder';

export interface IPractitionerStandingOrder {
  id: string;
  practitionerId: null | number;
  content: string;
  header: string;
  name: string;
}

const StandingOrders = {
  async getStandingOrderTemplates(): Promise<IStandingOrder[]> {
    const { standingOrders } = await Api.get(STANDING_ORDER_SUGGESTIONS);
    return standingOrders;
  },

  async getPractitionerStandingOrders(): Promise<IPractitionerStandingOrder[]> {
    const { standingOrders, success } = await Api.get(STANDING_ORDERS);
    return success ? standingOrders : [];
  },

  async getPractitionerStandingOrder(id: number): Promise<IPractitionerStandingOrder> {
    const { standingOrder, success } = await Api.get(`${STANDING_ORDERS}/${id}`, {});
    return success ? standingOrder : {};
  },

  async updatePractitionerStandingOrder(params: { id: string; name: string; content: string }): Promise<boolean> {
    const { success } = await Api.put(`${STANDING_ORDERS}/${params.id}`, params);
    return success;
  },
  async createPractitionerStandingOrder(params: {
    name: string;
    content: string;
  }): Promise<{ success: boolean; standingOrder: IStandingOrder }> {
    return Api.post(STANDING_ORDERS, params);
  },
};

export default StandingOrders;
