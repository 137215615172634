import { Box, Modal, ModalProps, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

const useStyles = makeStyles((theme) => ({
  ehrModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  ehrModalContent: {
    borderRadius: '0.75rem',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      right: 0,
      top: 0,
      bottom: 0,
      left: 0,
      width: 'auto',
      height: 'auto',
    },
  },
  ehrModalTitle: {
    padding: '1.5rem 2rem',
  },
  ehrModalBody: {
    display: 'flex',
    padding: '1.5rem 2rem',
    width: '100%',
    overflow: 'auto',
    height: '100%',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  ehrModalFooter: {
    borderTop: '1px solid #c8c8c8',
    padding: '1.5rem 2rem',
    display: 'flex',
    justifyContent: 'flex-end',
    gridGap: '1rem',
  },
}));

interface EHRModalProps extends Omit<ModalProps, 'children'> {
  dataCy: string;
  children: React.ReactNode;
}

const EHRModal = (props: EHRModalProps) => {
  const classes = useStyles();
  const { dataCy, className, children, ...restProps } = props;

  return (
    <Modal {...restProps} data-cy={dataCy} className={classNames(classes.ehrModal, className)}>
      <Box className={classes.ehrModalContent}>{children}</Box>
    </Modal>
  );
};

interface EHRModalTitleProps {
  dataCy: string;
}

EHRModal.Title = (props: PropsWithChildren<EHRModalTitleProps>) => {
  const classes = useStyles();
  const { children, dataCy } = props;

  return (
    <Box data-cy={dataCy} className={classes.ehrModalTitle}>
      {children}
    </Box>
  );
};

interface EHRModalBodyProps {
  dataCy: string;
}

EHRModal.Body = (props: PropsWithChildren<EHRModalBodyProps>) => {
  const classes = useStyles();
  const { children, dataCy } = props;

  return (
    <Box data-cy={dataCy} className={classes.ehrModalBody}>
      {children}
    </Box>
  );
};

interface EHRModalFooterProps {
  dataCy: string;
}

EHRModal.Footer = (props: PropsWithChildren<EHRModalFooterProps>) => {
  const classes = useStyles();
  const { children, dataCy } = props;

  return (
    <Box data-cy={dataCy} className={classes.ehrModalFooter}>
      {children}
    </Box>
  );
};

export { EHRModal };
