/* eslint-disable */
import { createModel } from '@rematch/core';
import axios from '../utils/axios';
import { ViewReport } from '../types/ViewReport';
import { RootModel } from './rootModel';
import { getProductList } from '../utils/inventory.utils';
import compile from '../utils/toastMessagesCompiler';

const viewReportInitialState = {
  forceSignature: true,
  reconciliationReportItems: [],
  reconciliationReportPractitionerName: '',
  reconciliationReportPractitionerNote: '',
  reconciliationReportAdminNote: '',
  reconciliationReportStatus: '',
  reconciliationReportCreatedAt: undefined,
  productsList: [],
};

export const viewReport = createModel<RootModel>()({
  state: viewReportInitialState as ViewReport,
  reducers: {
    setForceSignature(state: ViewReport) {
      return { ...state, forceSignature: !state.forceSignature };
    },
    setViewReport(state: ViewReport, payload: any) {
      const productsList = getProductList(payload.reconciliationReportItems);
      return { ...state, ...payload, productsList };
    },
    setReset() {
      return viewReportInitialState;
    },
  },
  effects: (dispatch: any) => ({
    async saveAdminReport({ reportId, waived, forceSignature, adminNote }: any, { viewReport }) {
      const { reconciliationReportItems } = viewReport;
      let waivedByItemId: any = [];
      let waivedItems = { ...waived };

      reconciliationReportItems.forEach((item: any) => {
        const missing = item.expected - item.counted;

        let waivedCount = 0; // indicate waived for this item
        if (missing > 0 && waivedItems[item.serviceId] > 0) {
          if (item.allowPartialSale) {
            waivedCount = missing - waivedItems[item.serviceId]; // 20 - 300 = -280 | next item => 300 - 280 = 20
            if (waivedCount < 0) {
              // waive all the missing for this item
              waivedCount = missing; // 20
              waivedItems[item.serviceId] = waivedItems[item.serviceId] - missing; // 300 - 20 = 280
            } else {
              waivedCount = waivedItems[item.serviceId]; // waive 280
              waivedItems[item.serviceId] = 0; // 280 - 300 = -20
            }
          } else {
            // allowPartialSale = false && missing > 0
            waivedItems[item.serviceId] -= missing; // should always be 1
            waivedCount = missing;
          }
        }

        if (waivedCount > 0) {
          waivedByItemId.push({ itemId: item.id, count: waivedCount });
        }
      });

      const body = {
        id: reportId,
        waivedByItemId,
        forceSignature,
        adminNote,
      };

      await axios.post(`reconciliation-reports/${reportId}/admin-save`, body);

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Report',
            action: 'saved',
          }),
        },
      });
    },
    async getReport({ reconciliationReports, reportId }: any) {
      let report = reconciliationReports.find((report: any) => report.id === +reportId);

      if (!report || report?.productsList?.length === 0) {
        const response = await axios.get(`reconciliation-reports/${reportId}`);
        report = response.data.reconciliationReport;
      }

      dispatch.viewReport.setViewReport({
        reconciliationReportItems: report.reconciliationReportItems || [],
        reconciliationReportCreatedAt: report.createdAt,
        reconciliationReportPractitionerName: report.practitionerName,
        reconciliationReportPractitionerNote: report.practitionerNote,
        reconciliationReportAdminNote: report.adminNote,
        reconciliationReportStatus: report.status,
      });
    },
  }),
});
