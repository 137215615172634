import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TextField, makeStyles } from '@material-ui/core';
import { debounce } from 'lodash';
import { dispatch } from '../../../rematch';

const useStyles = makeStyles(() => ({
  header: {
    margin: '20px 39px 14px 0',
    fontSize: '15px',
    lineHeight: 1.93,
    color: '#000000',
  },
  TextAreaStyles: {
    width: '100%',
    minHeight: '75px',
    maxHeight: '98px',
    borderRadius: '3px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    padding: '13px 14px',
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    '&:focus': {
      border: '2px solid #12574d',
      outline: 'none !important',
    },
  },
}));

export const Notes = () => {
  const classes = useStyles();
  const serviceVisit = useSelector(({ newServiceVisit }: any) => newServiceVisit.serviceVisit);
  const [customerNote, setCustomerNote] = useState<string>('');
  const [internalNote, setInternalNote] = useState<string>('');
  const [isFirstRun, setIsFirstRun] = useState<boolean>(true);

  useEffect(() => {
    setInternalNote(serviceVisit.internalNote);
    setCustomerNote(serviceVisit.customerNote);
  }, [serviceVisit]);

  useEffect(() => {
    if (isFirstRun || (serviceVisit.customerNote === customerNote && serviceVisit.internalNote === internalNote)) {
      setIsFirstRun(false);
      return;
    }

    if (serviceVisit.id) {
      saveNotesDebounce(customerNote, internalNote, serviceVisit.id);
    }
  }, [customerNote, internalNote]);

  const saveNotesDebounce = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    debounce((customerNote: string, internalNote: string, serviceVisitId: number) => {
      dispatch({
        type: 'newServiceVisit/saveNotesForServiceVisit',
        payload: {
          serviceVisitId,
          body: { customerNote, internalNote },
        },
      });
    }, 2000),
    []
  );

  return (
    <div>
      <h5 className={classes.header}>Customer and Internal Notes</h5>
      <TextField
        onChange={(e) => setCustomerNote(e.target.value || '')}
        defaultValue={customerNote}
        placeholder="Customer Note"
        type="text"
        variant="outlined"
        rows={2}
        fullWidth
        multiline
        style={{ marginBottom: 12 }}
      />

      <TextField
        onChange={(e) => setInternalNote(e.target.value || '')}
        defaultValue={internalNote}
        placeholder="Internal Note"
        type="text"
        variant="outlined"
        rows={2}
        fullWidth
        multiline
        style={{ marginBottom: 12 }}
      />
    </div>
  );
};
