import React from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress, FormControlLabel, Radio } from '@material-ui/core';
import CreditCardIcon from './CreditCardIcon';
import { useStyles } from './SelectAndChargeCard.styles';
import { ISquareCard } from '../../../interfaces/ISquareCard.interfaces';

const SelectAndChargeCard = ({
  selectedCardId,
  patientCards,
  onCardSelect,
  onCharge,
  loading,
  buttonText,
  showChargeMessage = true,
}: {
  selectedCardId: string | undefined;
  patientCards: ISquareCard[];
  onCharge: () => void;
  loading: boolean;
  onCardSelect: (e: any) => void;
  buttonText: string;
  showChargeMessage?: boolean;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.selectCardsContainer}>
      {patientCards.map((card: ISquareCard) => (
        <div>
          <FormControlLabel
            value={card.id}
            key={card.id}
            control={<Radio color="primary" checked={card.id === selectedCardId} onClick={onCardSelect} />}
            label={
              <div className={classes.cardName}>
                Card ending in {card.last4}
                <span className={classes.cardIcon}>
                  <CreditCardIcon cardBrand={card.cardBrand} />
                </span>
              </div>
            }
          />
        </div>
      ))}
      {showChargeMessage && (
        <p className={classes.acknowledgeConsent}>
          By processing this payment I confirm the patient has provided verbal or written consent to charge the credit
          card on file for the amount charged.
        </p>
      )}
      <Button disabled={!selectedCardId} className={classes.checkoutButton} onClick={onCharge}>
        {loading ? (
          <>
            Processing
            <CircularProgress size={20} className={classes.processing} />
          </>
        ) : (
          buttonText
        )}
      </Button>
      {loading && <div className={classes.buttonOverlay} />}
    </div>
  );
};

export default SelectAndChargeCard;
