import { showSnackbar } from 'src/utils/global';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { IMedspaConsent, IMedspaConsentAPI } from 'src/interfaces/IMedspaConsents';
import { MEDSPA_V4_PATH } from '../constants/apiRoutes.constants';
import Api from './Api';

const MedspaConsents = {
  async getMedspaConsents({ userGroupId }: { userGroupId: number }): Promise<IMedspaConsent[]> {
    const { success, consents }: IMedspaConsentAPI = await Api.get(`${MEDSPA_V4_PATH}/${userGroupId}/consents`);
    if (!success) {
      showSnackbar('There was an error fetching consents.', SNACKBAR_ERROR);
      return [];
    }
    return consents;
  },
  async updateMedspaConsent({
    userGroupId,
    consentId,
    content,
    name,
  }: {
    userGroupId: number;
    consentId: number;
    content: string;
    name?: string;
  }) {
    const { success } = await Api.put(
      `${MEDSPA_V4_PATH}/${userGroupId}/consents/${consentId}`,
      name ? { name, content } : { content }
    );
    if (!success) {
      showSnackbar('There was an error updating consent.', SNACKBAR_ERROR);
      return [];
    }
    showSnackbar('Consent updated successfully.', SNACKBAR_SUCCESS);

    return success;
  },
  async createMedspaConsent({ userGroupId, content, name }: { userGroupId: number; content: string; name: string }) {
    const { success, consent } = await Api.post(`${MEDSPA_V4_PATH}/${userGroupId}/consents`, { name, content });
    if (!success) {
      showSnackbar('There was an error creating consent.', SNACKBAR_ERROR);
      return [];
    }
    showSnackbar('Consent updated successfully.', SNACKBAR_SUCCESS);
    return consent;
  },
};

export default MedspaConsents;
