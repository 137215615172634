/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack, Home as HomeIcon } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MEDSPA_PROVIDER_PATIENTS } from 'src/routes/medspaPractitionerRoutes';
import { useStyles } from './navigationBar.styles';
import { PRACTITIONER_HOME_PATH } from '../../routes/practitionerRoutes';
import { PHYSICIAN_ROLE, ADMIN_ROLE } from '../../constants/general.constants';
import { PHYSICIAN_PATIENT_NEW_PATH } from '../../routes/physicianRoutes';
import { MEDSPA_ADMIN_BASE_PATH } from '../../routes/medspaAdminRoutes';
import { Practitioners } from '../../services/Practitioners';

type Props = {
  title: string | Node;
  onBackButtonClick?: any;
  hasCancelButton?: boolean;
  onCancelButtonClick?: any;
  style?: any;
  hideHomeButton?: boolean;
};

export const NavigationBar = ({
  title,
  onBackButtonClick,
  hasCancelButton,
  onCancelButtonClick,
  style = {},
  hideHomeButton,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation<any>();
  const { userType } = useSelector(({ auth }: any) => auth);
  const isMedspaProvider = Practitioners.isMedspaProvider();

  const shouldRunCallBack = () => {
    if (isMedspaProvider) {
      history.goBack();
    } else if (state && state.from) {
      history.push(state.from);
    } else {
      onBackButtonClick();
    }
  };

  const homeUrl = useMemo(() => {
    if (isMedspaProvider) {
      return MEDSPA_PROVIDER_PATIENTS;
    }
    switch (userType) {
      case PHYSICIAN_ROLE:
        return PHYSICIAN_PATIENT_NEW_PATH;
      case ADMIN_ROLE:
        return MEDSPA_ADMIN_BASE_PATH;
      default:
        return PRACTITIONER_HOME_PATH;
    }
  }, [userType]);

  return (
    <div className={classes.bar} style={style}>
      <ArrowBack data-cy="backArrow" onClick={shouldRunCallBack} style={{ cursor: 'pointer' }} />
      <div className={classes.title} data-cy="title">
        {!hideHomeButton && <HomeIcon className={classes.homeIcon} onClick={() => history.push(homeUrl)} />}
        {title}
      </div>
      {hasCancelButton && (
        <Button onClick={onCancelButtonClick} className={classes.cancelButton} variant="outlined">
          Cancel
        </Button>
      )}
    </div>
  );
};
