import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

const OutlinedInputCustom = (props: any) => <TextField variant="outlined" {...props} />;

const PhoneNumberCustom = (props: NumberFormatProps) => (
  <NumberFormat customInput={OutlinedInputCustom} allowEmptyFormatting mask="_" format="+1 (###) ###-####" {...props} />
);

export default PhoneNumberCustom;
