import moment from 'moment';
import { months } from '../constants/general.constants';

export const DATETIME_FORMAT = 'MM/DD/YYYY HH:mm';

export const formatMonthWithZero = (month: number | undefined): string | null => {
  if (month) {
    if (month < 10) {
      return `0${month}`;
    }
    return `${month}`;
  }
  return null;
};

/** Format date function for DatePickerReact */
export default function formatDate(date?: Date | string, format: string = 'MM/DD/YYYY'): string {
  return moment(date).format(format);
}

export const formatLocalDateTime = (unformattedDate: string) => {
  const date = new Date(unformattedDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const timeString = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;

  return {
    time: timeString,
    day: date.getDate(),
    month: months[date.getMonth()],
    year: date.getFullYear(),
  };
};

// Input: '2024-03-01 14:00' Output: '03/01/2024 02:00PM'
export function convertTime(inputTime: string): string {
  const time = moment(inputTime, 'HH:mm').format('hh:mmA');
  return time;
}

// Input: 'Saturday, April 13, 2024 7:00am - 8:00am'
// Output: {startDate: '2024-04-12T07:00', endDate: '2024-04-12T20:00'}
export function transformDescriptionToDateFormat(input: string) {
  const a = input.split(' ');
  const startDate = a[4];
  const endDate = a[6];

  // Parsing the input string using Moment.js
  const parsedDate = moment(input, 'dddd, MMMM DD, YYYY hh:mma - hh:mma');

  // Extracting the date in the format 'YYYY-MM-DD'
  const extractedDate = parsedDate.format('YYYY-MM-DD');

  const result = {
    startDate: moment(`${extractedDate} ${startDate}`, 'YYYY-MM-DD h:mma').format('YYYY-MM-DDTHH:mm'),
    endDate: moment(`${extractedDate} ${endDate}`, 'YYYY-MM-DD h:mma').format('YYYY-MM-DDTHH:mm'),
  };

  return result;
}
