import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { SUPPLY_RECEIPTS } from '../../constants/reactQuery.keys';
import { ISupplyReceipt } from '../../interfaces/ISupplyReceipt';
import SupplyReceipts from '../../services/SupplyReceipts';
import { TypeSort } from '../../utils/types';

export function useCustomSupplyReceipts(
  orderByDate: TypeSort,
  searchQuery: { practitionerId?: number; status?: string } = {},
  page: number
) {
  const [supplyReceipts, setSupplyReceipt] = useState<Array<ISupplyReceipt>>([]);

  const params = {
    ...searchQuery,
    page,
  };

  const { data, isLoading, isFetching, isError } = useQuery([SUPPLY_RECEIPTS, params], () =>
    SupplyReceipts.getSupplyReceipts(params)
  );

  useEffect(() => {
    let supplyReceiptOrderArray: ISupplyReceipt[] = [];
    if (data?.supplyReceipts) {
      supplyReceiptOrderArray = _.orderBy(data.supplyReceipts, [(obj: ISupplyReceipt) => obj.createdAt], [orderByDate]);

      setSupplyReceipt(supplyReceiptOrderArray);
    }
  }, [data, orderByDate, searchQuery]);

  return {
    supplyReceipts,
    supplyReceiptsCount: data?.supplyReceiptsCount || 0,
    isLoading,
    isFetching,
    isError,
  };
}
