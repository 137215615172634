import React, { PropsWithChildren } from 'react';
import { Box, IconButton, Modal, ModalProps, Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  ehrCRUDModal: {
    position: 'fixed',
    bottom: 0,
    height: '100vh',
    padding: theme.spacing(2),
    overflowY: 'auto',
    outline: 'none',
  },
  ehrCRUDModalTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #c8c8c8',
    padding: '1.5rem 2rem',
    lineHeight: '1.25rem',
  },
  ehrCRUDModalTitleCloseModalButton: {
    cursor: 'pointer',
    border: '1px solid #cfcfcf',
    borderRadius: '0.375rem',
    padding: '9px',
  },
  ehrCRUDModalContent: {
    position: 'fixed',
    right: '1rem',
    borderRadius: '0.75rem',
    backgroundColor: '#fff',
    width: '34.375rem',
    height: '96.5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      right: 0,
      top: 0,
      bottom: 0,
      left: 0,
      width: 'auto',
      height: 'auto',
    },
  },
  ehrCRUDModalBody: {
    display: 'flex',
    padding: '2rem',
    width: '100%',
    overflow: 'auto',
    height: '100%',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  ehrCRUDModalFooter: {
    display: 'flex',
    width: '100%',
    padding: '1.5rem 2rem',
  },
}));

type EHRCRUDModalProps = Omit<ModalProps, 'children'> & {
  dataCy: string;
  title?: string;
  children: React.ReactNode;
  handleClose?: () => void;
};

const EHRCRUDModal = (props: EHRCRUDModalProps) => {
  const classes = useStyles();
  const { dataCy, children, className } = props;

  return (
    <Modal {...props} data-cy={dataCy} className={classNames(classes.ehrCRUDModal, className)}>
      <Box className={classes.ehrCRUDModalContent}>{children}</Box>
    </Modal>
  );
};

interface EHRCRUDModalTitleProps {
  dataCy: string;
  title: string;
  className?: string;
  handleClose: () => void;
}

EHRCRUDModal.Title = (props: EHRCRUDModalTitleProps) => {
  const classes = useStyles();
  const { dataCy, className, title, handleClose } = props;

  return (
    <Box data-cy={dataCy} className={classNames(classes.ehrCRUDModalTitle, className)}>
      <Typography variant="h5">{title}</Typography>
      <IconButton onClick={handleClose} className={classes.ehrCRUDModalTitleCloseModalButton}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

interface EHRCRUDModalBodyProps {
  dataCy: string;
}

EHRCRUDModal.Body = (props: PropsWithChildren<EHRCRUDModalBodyProps>) => {
  const classes = useStyles();
  const { children, dataCy } = props;

  return (
    <Box data-cy={dataCy} className={classes.ehrCRUDModalBody}>
      {children}
    </Box>
  );
};

interface EHRCRUDModalFooterProps {
  dataCy: string;
}

EHRCRUDModal.Footer = (props: PropsWithChildren<EHRCRUDModalFooterProps>) => {
  const classes = useStyles();
  const { children, dataCy } = props;

  return (
    <Box data-cy={dataCy} className={classes.ehrCRUDModalFooter}>
      {children}
    </Box>
  );
};

export { EHRCRUDModal };
