import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  content: {
    minHeight: 'calc(100vh - 210px)',
  },
  subTitle: {
    fontSize: '14px',
    lineHeight: '23px',
    color: 'rgba(0, 0, 0, 0.87)',
    mixBlendMode: 'normal',
    opacity: 0.7,
    margin: '18px 37px',
  },
  signature: {
    width: '100%',
    height: '158px',
    borderRadius: '5px',
    border: '1px solid lightgray',
  },
  signatureLabel: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#A6A6A6',
    position: 'relative',
    top: '85px',
    left: '260px',
    width: 'fit-content',
    margin: '0',
    zIndex: -10,
  },
  subTitleRadio: {
    fontSize: '14px',
    lineHeight: '23px',
    color: '#000000',
    mixBlendMode: 'normal',
    opacity: 0.7,
    margin: '18px 13px 0 13px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f1f1f1',
    borderBottom: '1px solid #ccc',
    minHeight: 75,
    '& *': {
      fontSize: 16,
    },
    '& > div': {
      textAlign: 'center',
    },
    '& > div:first-child': {
      textAlign: 'left'
    },
    '& > div:last-child': {
      width: 320
    }
  }
}));
