import { createModel } from '@rematch/core';
import { CustomerParams } from '../interfaces/CustomerParams';
import { PhysicianState } from '../types/PhysicianState';
import Customers from '../services/Customers';
import { RootModel } from './rootModel';

const myInitialState: CustomerParams = {
  dashboard: 'physician',
  group: 'new',
  view: 'all',
  filter: 'all',
  page: 1,
  search: '',
  sortBy: 'created_at DESC',
};

const INITIAL_STATE = {
  undefined: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
  toClear: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
  toReclear: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
  starred: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
  allPatients: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    hasMorePages: false,
  },
};

export const physician = createModel<RootModel>()({
  state: {
    ...INITIAL_STATE,
  } as PhysicianState,
  reducers: {
    updateByGroup(state: any, payload: any) {
      return { ...state, [payload.group]: { ...state[payload.group], ...payload } };
    },
    updateState(state: any, payload: any) {
      return { ...state, ...payload };
    },
    setReset() {
      return { ...INITIAL_STATE };
    },
  },
  effects: (dispatch: any) => ({
    async getPatients(payload: CustomerParams, rootState: any) {
      let results = [];
      if (!payload.group) {
        throw new Error('group is required');
      }

      /** when the state is new, we set the results in the store under "toClear" list */
      let groupList = payload.group === 'new' ? 'toClear' : payload.group;
      if (groupList === 'allPatients' && payload.filter) {
        groupList = 'starred';
      }
      const response = await Customers.getCustomers({ ...myInitialState, ...payload });

      if (
        response?.meta?.currentPage !== rootState.physician[groupList]?.currentPage &&
        response?.meta?.currentPage !== 1
      ) {
        /* if we go from page
          one to two, we concatenate, but if the "search" or "sort" change and the previous request
           * was left on page two, we do not concatenate */
        results = [...rootState.physician[groupList].results, ...response.customers];
      } else {
        results = [...response.customers];
      }

      const setPayload = {
        results,
        currentPage: response.meta.currentPage,
        totalPages: response.meta.totalPages,
        totalCount: response.meta.totalCount,
        hasMorePages:
          response.meta.currentPage !== response.meta.totalPages &&
          response.meta.currentPage <= response.meta.totalPages,
      };

      dispatch.physician.updateByGroup({ group: groupList, ...setPayload });
    },
  }),
});
