import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useStyles } from './tenderSection.styles';
import { AspireTenderLogo } from '../../../components/Points/AspireTenderLogo';

interface Props {
  aspireVoucher: string;
  setAspireVoucher: (value: string) => void;
  aspireValue: string;
  setAspireValue: (value: string) => void;
  isTotalTenderValueValid: (value: NumberFormatValues, tenderValue: string) => boolean;
  onSwap: () => void;
}

export const AspireTender = (props: Props) => {
  const {
    aspireVoucher,
    setAspireVoucher,
    aspireValue,
    setAspireValue,
    isTotalTenderValueValid,
    onSwap
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <AspireTenderLogo onSwap={onSwap} />

      <input type="text"
        value={aspireVoucher}
        onChange={(e) => setAspireVoucher(e.target.value)}
        placeholder="Code/Note"
        className={classes.input}
        style={{ marginRight: '20px' }}
      />
      <NumberFormat
        decimalScale={2}
        fixedDecimalScale
        autoComplete="off"
        placeholder="Amount"
        value={aspireValue}
        onChange={(e) => { setAspireValue(e.target.value) }}
        isAllowed={(inputObj) => isTotalTenderValueValid(inputObj, aspireValue)}
        className={classes.input}
        type="tel"
      />
    </div>
  );
};
