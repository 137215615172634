import React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab/';
import { ActiveStepWrapper } from '../ActiveStepWrapper';
import { dispatch } from '../../../rematch';
import { PHOTO_TYPE } from '../../../constants/newServiceVisit.constants';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import {
  MIN_ITEMS_PHOTOS_LIMIT_MOBILE,
  ITEMS_PHOTOS_LIMIT_DESKTOP,
  WIDTH_MAX_DESKTOP,
} from '../../../constants/general.constants';
import { Card } from '../../common/card';
import useLog from '../../../hooks/mutations/useLog';
import Photos from '../../PatientProfile/Photos';

type Props = {
  fromHistory?: boolean;
  isAfterPhotoRequired?: boolean;
};

const cardContentStyle = {
  padding: '11px 20px 20px 20px',
};

export const AfterPhotos = ({ fromHistory, isAfterPhotoRequired }: Props) => {
  const newServiceVisit = useSelector(({ newServiceVisit: serviceVisit }: any) => serviceVisit);
  const { isUploadingAfterPhoto, serviceVisit, isLoadingGetServiceVisitFromPatient } = useSelector(
    (store: any) => store.newServiceVisit
  );
  const { id, photos, opened } = serviceVisit;
  const patientPhotos = useSelector(({ patient }: any) => patient.photos);
  const { width } = useWindowDimensions();

  const { mutateAsync: log } = useLog();

  const ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE =
    width > WIDTH_MAX_DESKTOP ? ITEMS_PHOTOS_LIMIT_DESKTOP : MIN_ITEMS_PHOTOS_LIMIT_MOBILE;

  const uploadAfterPhoto = (files: File[]) => {
    dispatch({
      type: 'newServiceVisit/uploadPhotosRequest',
      payload: { files, type: PHOTO_TYPE.AFTER, serviceVisitId: id },
    });
  };

  const getPhotos = () => {
    const photosIds = photos.map(({ photoId }: any) => photoId);
    return patientPhotos.filter(
      ({ id: idPhoto, photoType }: any) => photosIds.includes(idPhoto) && photoType === PHOTO_TYPE.AFTER
    );
  };

  const selectStep = () => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 7 });
    log({
      feature: 'Service Visit',
      step: 7,
      logId: null,
      params: {
        newServiceVisit,
      },
    });
  };

  return (
    <ActiveStepWrapper step={7} onClick={selectStep} fromHistory={fromHistory}>
      {isLoadingGetServiceVisitFromPatient ? (
        <Skeleton height={150} />
      ) : (
        <Card style={{ marginBottom: '0' }}>
          <Photos
            required={isAfterPhotoRequired}
            inputFileId="afterPhoto"
            cardStyles={{ marginBottom: '0' }}
            cardContentStyle={cardContentStyle}
            title={`After Photos (${isAfterPhotoRequired ? 'required' : 'optional'})`}
            photos={getPhotos()}
            isUploadingPhoto={isUploadingAfterPhoto}
            isLoading={false}
            uploadPhotosCallback={uploadAfterPhoto}
            showUploadPhotoButton={opened}
            itemsPhotos={ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE}
            fromHistory={fromHistory}
          />
        </Card>
      )}
    </ActiveStepWrapper>
  );
};
