import { useQuery } from 'react-query';
import { CUSTOMER } from '../../constants/reactQuery.keys';
import PatientView from '../../services/PatientView';
import { IPatientView } from '../../types/Patient';

/* Get customer info
 * Params: id (service visit id)
 */
export function useCustomer(id: number) {
  const response = useQuery([CUSTOMER, id], () => PatientView.getPatient(id), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    ...response,
    data: response.data || ({} as IPatientView),
  };
}
