import React, { Fragment } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableBody, withStyles, TableCell } from '@material-ui/core';
import RowItem from './RowItem';
import { IActivityLog } from '../../types/Patient';

const TableCellHeader = withStyles(() => ({
  root: {
    fontSize: '15px',
  },
}))(TableCell);

const TableActivityLog = (props: any) => {
  const { activityLogs } = props;

  return (
    <TableContainer style={{ paddingTop: '15px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader width={250}>Activity Type</TableCellHeader>
            <TableCellHeader>Description</TableCellHeader>
            <TableCellHeader>Date</TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {activityLogs &&
            activityLogs.map((infinitePage: IActivityLog[], i: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={i}>
                {infinitePage?.map((activity: IActivityLog, index: number) => (
                  <RowItem key={activity.id} activity={activity} striped={index} />
                ))}
              </Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableActivityLog;
