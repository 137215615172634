import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { CardContent } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import Patients from '../../services/Patients';
import { Card, SmallTitle, Title } from '../common/card';
import { Button } from '../common/Button';
import { Pill } from '../common/Pill';
import { GENDER } from '../../constants/medicalProfile.constants';
import { calculateAge } from '../../utils/patient.util';
import { dispatch } from '../../rematch';
import { INCOMING_MEDICAL_HISTORY_ACK } from '../../constants/reactQuery.keys';
import DiscardModal from './DiscardModal';
// eslint-disable-next-line max-len
import { useCustomIncomingPatientHistoryAcknowledgements } from '../../hooks/queries/useCustomPatientMedicalHistoryAcknowledgements';
import StickyCard from '../common/StickyCard';
import compile from '../../utils/toastMessagesCompiler';
import { useStyles as useStickyStyles } from '../common/StickyCard/StickyCard.styles';
import { ShortMultipleSkeleton } from '../common/LoadingSkeleton';
import { useCustomMedicalProfile } from '../../hooks/queries/useCustomMedicalProfile';
import { patientHeaderStyles, patientHeaderActiveStyles, usePatientHeaderStyles } from './serviceVisitStyles';
import { useFullServiceHistory } from '../../hooks/serviceVisit/useServiceVisit';
import { TRANSACTION_STATUS_PAID } from '../../constants/serviceVisits/serviceVisit.constants';
import { PatientHeaderNote } from './PatientHeaderNote';
import { PHYSICIAN_ROLE } from '../../constants/general.constants';

const STICKY_CARD_OFFSET_LIMIT = 369;

export const PatientHeader = ({
  serviceVisit,
  serviceVisitId,
  currentPatientId,
  patient,
  checkout,
  userType = null,
}: any) => {
  const classes = usePatientHeaderStyles();
  const history = useHistory();
  const { firstName, lastName, createdAt, id, phone = 'None' } = patient;

  const [lastServiceVisit, setLastServiceVisit] = useState<any>(null);
  const [showAddNote, setShowAddNote] = useState(false);
  const [cardOffset, setCardOffset] = useState<number>(0);
  const { data: medicalProfile } = useCustomMedicalProfile(currentPatientId);
  const [openMedicalAcknowledgement, setOpenMedicalAcknowledgement] = useState<boolean>(false);
  const { data: patientHistoryAcknowledgement } = useCustomIncomingPatientHistoryAcknowledgements(currentPatientId);
  const { data: serviceVisits } = useFullServiceHistory(currentPatientId, { lastClosed: true });
  const stickyClasses = useStickyStyles();
  const { isProgressQueueWorking } = useSelector((state: any) => state.newServiceVisit);

  const queryClient = useQueryClient();
  const patientMHAMutation = useMutation(
    async () => {
      if (patientHistoryAcknowledgement) {
        await Patients.markMedicalHistoryAcknowledgementAsViewed(patientHistoryAcknowledgement.id, serviceVisitId);
      }
    },
    {
      onSuccess: () => {
        setOpenMedicalAcknowledgement(false);
        queryClient.invalidateQueries(INCOMING_MEDICAL_HISTORY_ACK);
      },
      onError: () => {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'updating',
              element: 'acknowledgement',
            }),
            type: 'error',
          },
        });
      },
    }
  );

  useEffect(() => {
    if (patientHistoryAcknowledgement?.id) {
      setOpenMedicalAcknowledgement(true);
    }
  }, [patientHistoryAcknowledgement]);

  useEffect(() => {
    setLastServiceVisit(serviceVisits?.filter(({ opened }: any) => !opened).pop() || null);
  }, [serviceVisits]);

  const navigateToFullServiceHistory = () => {
    history.push(`/history/${id}`, { from: history.location.pathname });
  };

  const getPatientData = () => {
    let result = '';
    if (medicalProfile?.sex) {
      result += `${GENDER[medicalProfile?.sex]}, `;
    }
    result += medicalProfile?.dateOfBirth ? calculateAge(medicalProfile?.dateOfBirth) : '';
    if (medicalProfile?.dateOfBirth) {
      result += ' - ';
    }
    return `${result} Created ${moment(createdAt).format('MM/DD/YYYY')} - Phone ${phone}`;
  };

  const medicalHistoryAcknowledgementConfirmation = () =>
    patientHistoryAcknowledgement && openMedicalAcknowledgement ? (
      <div
        className={classes.medicalHistoryAckCard}
        style={{
          left:
            cardOffset + STICKY_CARD_OFFSET_LIMIT > window.innerWidth
              ? window.innerWidth - STICKY_CARD_OFFSET_LIMIT
              : cardOffset,
        }}
      >
        <StickyCard
          colorText="#FFFFFF"
          backgroundColorNote="#173F61"
          minWidth="330px"
          title={patientHistoryAcknowledgement.physicianName}
          footer={
            <>
              <div className={stickyClasses.leftFooterText}>
                {moment(patientHistoryAcknowledgement.createdAt).format('MM/DD/YYYY - hh:mm A')}
              </div>
              <Button
                className={stickyClasses.primaryButton}
                title="Acknowledge"
                onClick={() => patientMHAMutation.mutate()}
              />
            </>
          }
        >
          {patientHistoryAcknowledgement.text}
        </StickyCard>
      </div>
    ) : null;

  return (
    <Card className={classes.card}>
      <CardContent style={{ padding: '12px 15px' }}>
        <Button
          style={{ ...patientHeaderStyles, marginLeft: '0' }}
          title="FULL SERVICE HISTORY"
          onClick={navigateToFullServiceHistory}
          disabled={isProgressQueueWorking}
        />
        <Button
          style={showAddNote ? patientHeaderActiveStyles : patientHeaderStyles}
          title="ADD SERVICE NOTE"
          onClick={() => {
            setShowAddNote(true);
          }}
          disabled={isProgressQueueWorking}
        />
        <DiscardModal
          serviceVisitId={serviceVisitId}
          currentPatientId={currentPatientId}
          disabled={checkout.transactionStatus === TRANSACTION_STATUS_PAID || userType === PHYSICIAN_ROLE}
        />
        <div style={{ display: 'initial' }} ref={(el) => el && setCardOffset(el.offsetLeft)}>
          {medicalHistoryAcknowledgementConfirmation()}
        </div>
      </CardContent>
      {showAddNote ? (
        <PatientHeaderNote
          serviceVisit={serviceVisit}
          cancelCallback={() => {
            setShowAddNote(false);
          }}
        />
      ) : (
        <>
          <CardContent style={{ padding: '12px 15px 5px 15px' }}>
            {!firstName ? (
              <ShortMultipleSkeleton length={1} />
            ) : (
              <div className={classes.nameContainer}>
                <Title
                  title={`${firstName} ${lastName}`}
                  style={{ marginTop: '0', marginBottom: '0', marginRight: '10px' }}
                />
                {medicalProfile?.isPregnant === 'yes' && <Pill title="Pregnancy" color="white" />}
              </div>
            )}
            {createdAt ? (
              <div>
                <h5 className={classes.subtitle}>
                  {getPatientData()} - MRN: <strong>{currentPatientId}</strong>
                </h5>
              </div>
            ) : (
              <ShortMultipleSkeleton length={1} />
            )}
          </CardContent>
          {lastServiceVisit && (
            <CardContent style={{ padding: '15px 15px', borderTop: '1px solid #e3e3e3' }}>
              <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '10px' }}>
                <SmallTitle title="Latest visit:" style={{ margin: '0', paddingRight: '10px' }} />
                <span className={classes.detail}>
                  {moment(lastServiceVisit.originDate || lastServiceVisit.createdAt).format('MM/DD/YYYY')}
                  {` - ${moment(lastServiceVisit.originDate || lastServiceVisit.createdAt).fromNow()}`}
                </span>
              </div>
              {lastServiceVisit.charges && (
                <span className={classes.detail}>
                  {lastServiceVisit.charges
                    .map(({ charge, service }: any) => {
                      const units = Math.trunc(charge.units) * Number(service.multiplier);
                      const unitsRounded = Math.round(units * 10) / 10;
                      const unitLabel = unitsRounded > 1 ? `${service.unitLabel}s` : service.unitLabel;
                      return `${service.name} (${unitsRounded} ${unitLabel})`;
                    })
                    .join(' - ')}
                </span>
              )}
            </CardContent>
          )}
        </>
      )}
    </Card>
  );
};
