import React from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  search: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '14px',
  },
}));

interface Props {
  value?: string;
  // eslint-disable-next-line no-unused-vars
  onChangeValue: (e: any) => void;
  isLoading?: boolean;
  placeholder?: string;
}

const InputSearch = (props: Props) => {
  const classes = useStyles();
  const { value, placeholder, onChangeValue, isLoading } = props;

  return (
    <FormControl size="small" variant="outlined">
      <InputLabel htmlFor="search" color="secondary" className={classes.search}>
        Search
      </InputLabel>
      <OutlinedInput
        data-cy='patientSearchInput'
        id="search"
        type="text"
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton style={{ padding: 0 }}>
              {isLoading ? (
                <CircularProgress style={{ color: 'black' }} size={20} />
              ) : (
                <SearchIcon style={{ color: 'black' }} />
              )}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={70}
      />
    </FormControl>
  );
};

InputSearch.defaultProps = {
  isLoading: false,
  placeholder: 'Search',
  value: '',
};
export default InputSearch;
