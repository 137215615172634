import * as yup from 'yup';
import {
  FIRST_NAME,
  LAST_NAME,
  LICENSE,
  PHONE,
  POSITION,
  SIGNATURE_IMAGE,
  VALID_PHONE,
  VALID_PHONE_REGEX,
} from 'src/constants/schemaForm.constants';

const requiredIfOperatingProvider = (schema: yup.StringSchema, errorMessage: string) =>
  schema.when('isOperatingProvider', {
    is: true,
    then: (sc: yup.StringSchema) => sc.required(errorMessage),
    otherwise: (sc: yup.StringSchema) => sc,
  });

export const schemaSignUp = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  phone: yup.string().trim().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
  email: yup.string().when('$invitationCodeEmail', {
    is: (invitationCodeEmail: string) => !invitationCodeEmail,
    then: yup.string().email().required('Email is required'),
    otherwise: yup.string().email(),
  }),
  licenses: requiredIfOperatingProvider(yup.string(), LICENSE),
  position: requiredIfOperatingProvider(yup.string(), POSITION),
  signatureImage: requiredIfOperatingProvider(yup.string(), SIGNATURE_IMAGE),
});
