import React, { useEffect, useRef, useState } from 'react';
import { Edit as EditIcon } from '@material-ui/icons';
import { Dialog, Box, Typography, makeStyles } from '@material-ui/core';
import Scanner from '../../common/Scanner';
import { getHumanReadableInternalSerialNumber } from '../../../utils/inventory.utils';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import { IServiceVisitAssetScanned } from '../../../interfaces/IServiceVisits';

export const useStyles = makeStyles(() => ({
  dialogTitle: {
    textAlign: 'center',
    paddingBottom: '10px',
  },
  dialog: {
    borderRadius: '10px',
    maxWidth: '700px',
  },
  container: {
    height: '260px',
    width: '670px',
    padding: '30px',
    overflow: 'hidden',
  },
  applyButton: {
    color: '#ffffff !important',
    backgroundColor: '#12574d',
    height: '36px',
    '&:hover': {
      backgroundColor: '#104e45',
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '-0.0428571px',
    color: '#393B3F',
    mixBlendMode: 'normal',
    opacity: 0.4,
  },
  value: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '23px',
    color: '#393B3F',
  },
  centered: {
    textAlign: 'center',
  },
  form: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  pencil: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface Props {
  asset: IAsset | IServiceVisitAssetScanned;
  unitsRequested: number;
  onScan: (asset: IAsset) => void;
}

const RescanAsset = ({ asset, unitsRequested, onScan }: Props) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const scannerInputRef = useRef<HTMLInputElement>(null);

  const focusScan = () => {
    setTimeout(() => {
      if (scannerInputRef && scannerInputRef.current) {
        scannerInputRef.current.focus();
      }
    }, 100);
  };

  useEffect(() => {
    focusScan();
  }, [openModal, scannerInputRef]);

  const handleRescan = async (assetItem: IAsset) => {
    await onScan(assetItem);
    setOpenModal(false);
  };

  return (
    <>
      <EditIcon className={classes.pencil} onClick={() => setOpenModal(!openModal)} />
      <Dialog open={openModal} onClose={() => setOpenModal(false)} classes={{ paper: classes.dialog }}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
          className={classes.container}
        >
          <Box>
            <Typography variant="h5" component="h2" color="textPrimary" className={classes.dialogTitle}>
              Re scan asset
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignContent="center" mb="12px">
            <Box>
              <Box mb={1}>
                <span className={classes.title}>PRODUCT: </span>
                <span className={classes.value}>{asset.name}</span>
              </Box>
              <div>
                <span className={classes.title}>ID: </span>
                <span className={classes.value}>
                  {getHumanReadableInternalSerialNumber(asset.internalSerialNumber)}
                </span>
              </div>
              <Box>
                <span className={classes.title}>Units: </span>
                <span className={classes.value}>{unitsRequested}</span>
              </Box>
            </Box>
          </Box>
          <Box>
            <Scanner successCallback={handleRescan} scannerInputRef={scannerInputRef} isInReceipt />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
export default RescanAsset;
