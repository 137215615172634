import React, { PropsWithChildren } from 'react';
import { Checkbox, CheckboxProps, FormGroup, FormControlLabel, makeStyles, Box } from '@material-ui/core';
import classNames from 'classnames';
import { isEqual } from 'lodash';

const useStyles = makeStyles({
  ehrCheckboxContainer: {
    border: '1px solid #C8C8C8',
    borderRadius: '0.375rem',
    height: '2.5rem',
    padding: '0.625rem',
    backgroundColor: '#FFFFFF',
    textWrap: 'nowrap',
    '&:hover': {
      borderColor: '#3a3b3f',
    },
  },
  ehrCheckboxLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: '0.875rem',
      fontWeight: '400',
      color: '#6F6F6F',
    },
  },
  ehrCheckbox: {
    color: '#12574D',
  },
});

export interface EHRCheckboxProps extends CheckboxProps {
  dataCy: string;
  label?: string;
}

const EHRCheckbox = React.memo(
  (props: PropsWithChildren<EHRCheckboxProps>) => {
    const classes = useStyles();
    const { className, dataCy, label, children, ...restProps } = props;

    return (
      <Box display="flex" alignItems="center" className={classNames([classes.ehrCheckboxContainer, className])}>
        <FormGroup>
          <FormControlLabel
            className={classes.ehrCheckboxLabel}
            control={<Checkbox data-cy={dataCy} {...restProps} color="default" className={classes.ehrCheckbox} />}
            label={label}
          />
          {children}
        </FormGroup>
      </Box>
    );
  },
  (oldProps, newProps) => isEqual(oldProps, newProps)
);

export { EHRCheckbox };
