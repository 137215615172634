import { AxiosError } from 'axios';
import { SNACKBAR_ERROR } from 'src/constants/general.constants';
import { ERROR_PREFIX } from 'src/constants/inventory.constants';
import { showSnackbar } from 'src/utils/global';

const formatErrorString = (error: string): string => (error ? `: ${error}` : '');

export const showOrderingError = (action: string, error: unknown) => {
  const errorString =
    typeof error === 'string' ? error : (error as AxiosError).response?.data?.errors ?? (error as Error).message;
  showSnackbar([ERROR_PREFIX, action, formatErrorString(errorString)].join(''), SNACKBAR_ERROR);
};

export const handleQueryError =
  (action: string, errorCallback?: (error: Error) => void | Promise<void>) => async (error: unknown) => {
    showOrderingError(action, error);
    await errorCallback?.(error as Error);
  };
