import { createModel } from '@rematch/core';
import { SnackbarState, SnackMessage } from '../types/SnackbarState';
import { DEFAULT_DURATION, DEFAULT_POSITION } from '../utils/toastMessagesCompiler';
import { RootModel } from './rootModel';

type closePayload = {
  dismissAll?: boolean;
  key?: number;
};

const defaultMessageValues = {
  type: 'success',
  anchorOrigin: DEFAULT_POSITION,
};

const notifications: SnackMessage[] = [];

export const snackbar = createModel<RootModel>()({
  state: {
    notifications,
  } as SnackbarState,
  reducers: {
    enqueueSnackBar: (state: SnackbarState, payload: SnackMessage): any => ({
      ...state,
      notifications: [
        ...state.notifications,
        {
          key: new Date().getTime() + Math.random(),
          ...defaultMessageValues,
          ...payload,
          autoHideDuration: payload.duration || DEFAULT_DURATION,
        },
      ],
    }),
    closeSnackbar: (state: SnackbarState, { dismissAll, key }: closePayload): any => ({
      ...state,
      notifications: state.notifications.map((notification) =>
        dismissAll || notification.key === key ? { ...notification, dismissed: true } : { ...notification }
      ),
    }),
    removeSnackbar: (state: SnackbarState, { key }: any): any => ({
      ...state,
      notifications: state.notifications.filter((notification: SnackMessage) => notification.key !== key),
    }),
  },
});
