import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Documents from 'src/pages/Documents';
import NewPatient from '../pages/NewPatient/NewPatient';
import {
  CREATE_CUSTOMER,
  PRACTITIONER_BOOK_APPOINTMENT_UPDATE,
  UPDATE_GFE_MANAGEMENT_SETTING,
  USE_EHR,
} from '../constants/actions.constants';
import {
  ALL_STATE,
  CLEARED_STATE,
  INCOMPLETE_STATE,
  LEAD_STATE,
  NOT_CLEARED_STATE,
  PENDING_RECLEAR_STATE,
  PRACTITIONER_ROLE,
  TO_RECLEAR_STATE,
} from '../constants/general.constants';
import MessagingIndex from '../pages/CareMessages';
import CustomizeMessage from '../pages/CareMessages/CustomizeMessage';
import DashboardPractitioner from '../pages/DashboardPractitioner';
import ProductBoardPractitioner from '../pages/ProductBoardPractitioner';
import ProductsAboutToExpire from '../pages/ProductsAboutToExpire';
import SupplyReceiptConfirmationPage from '../pages/SupplyReceiptConfirmation';
import PrivateRoute from '../utils/PrivateRoute';
import Configuration from '../pages/Configuration';
import CustomizeConfiguration from '../pages/Configuration/Customize';
import ViewConfiguration from '../pages/Configuration/View';
import CalendarConfiguration from '../pages/Configuration/Calendar/CalendarConfiguration';
import MoreDetails from '../pages/AccountSetting/MoreDetails';
import ServicesManagement from '../pages/AccountSetting/ServicesManagement/index';
import PatientBulkUpload from '../pages/AccountSetting/PatientBulkUpload/index';
import NewCalendarConfiguration from '../pages/AccountSetting/CalendarConfiguration';
import CreateOrEditPractitioners from '../components/DashboardAdministrator/Practitioners/CreateOrEditPractitioners';
import { CompletedOnboarding } from '../pages/AccountSetting/CompletedOnboarding';
import { GFEManagement } from '../pages/Practitioners/GFEManagement';

export const PRACTITIONER_BASE_PATH = '/practitioner';
export const PRACTITIONER_HOME_PATH = `${PRACTITIONER_BASE_PATH}/patients`;
export const PRACTITIONER_PATIENT_ALL_PATH = `${PRACTITIONER_BASE_PATH}/patients/${ALL_STATE}`;
export const PRACTITIONER_PATIENT_CLEARED_PATH = `${PRACTITIONER_BASE_PATH}/patients/${CLEARED_STATE}`;
export const PRACTITIONER_PATIENT_PENDING_RECLEAR_PATH = `${PRACTITIONER_BASE_PATH}/patients/${PENDING_RECLEAR_STATE}`;
export const PRACTITIONER_PATIENT_NOT_CLEARED_PATH = `${PRACTITIONER_BASE_PATH}/patients/${NOT_CLEARED_STATE}`;
export const PRACTITIONER_PATIENT_TO_RECLEAR_PATH = `${PRACTITIONER_BASE_PATH}/patients/${TO_RECLEAR_STATE}`;
export const PRACTITIONER_PATIENT_INCOMPLETE_PATH = `${PRACTITIONER_BASE_PATH}/patients/${INCOMPLETE_STATE}`;
export const PRACTITIONER_PATIENT_LEAD_PATH = `${PRACTITIONER_BASE_PATH}/patients/${LEAD_STATE}`;
export const PRACTITIONER_PRODUCT_BOARD_PATH = `${PRACTITIONER_BASE_PATH}/productboard`;
export const PRACTITIONER_MESSAGING_INDEX_PATH = `${PRACTITIONER_BASE_PATH}/messaging`;
export const PRACTITIONER_MESSAGING_CUSTOMIZE_PATH = `${PRACTITIONER_BASE_PATH}/messaging/customize`;
export const PRACTITIONER_INVENTORY_PATH = `${PRACTITIONER_BASE_PATH}/inventory`;
export const PRACTITIONER_INVENTORY_COUNT_PATH = `${PRACTITIONER_INVENTORY_PATH}/count`;
export const PRACTITIONER_INVENTORY_COUNT_SERVICE_PATH = `${PRACTITIONER_INVENTORY_COUNT_PATH}/service`;
export const PRACTITIONER_RECONCILIATION_PATH = `${PRACTITIONER_BASE_PATH}/inventory/reconciliation`;
export const PRACTITIONER_INVENTORY_SUPPLY_PATH = `${PRACTITIONER_BASE_PATH}/inventory/supply-receipt`;
export const NEW_EARNINGS_DASHBOARD_HIDDEN_ROUTE_PATH = `${PRACTITIONER_BASE_PATH}/hidden-dashboard`;
export const PRACTITIONER_TODO_ALL_PATH = `${PRACTITIONER_BASE_PATH}/todo/all`;
export const PRACTITIONER_PRICING_PATH = `${PRACTITIONER_BASE_PATH}/pricing`;
export const PRACTITIONER_PRICING_PRICES_PATH = `${PRACTITIONER_BASE_PATH}/pricing/prices`;
export const PRACTITIONER_PRICING_FEES_PATH = `${PRACTITIONER_BASE_PATH}/pricing/fees`;
export const PRACTITIONER_PRODUCTS_ABOUT_PATH = `${PRACTITIONER_BASE_PATH}/products-about-to-expire`;
export const PRACTITIONER_CONFIRMATION_SUPPLY_RECEIPT = (suppleyReceiptId: number) =>
  `${PRACTITIONER_BASE_PATH}/${suppleyReceiptId}/confirmation-supply-receipt`;
export const PRACTITIONER_COMPLETED_SUPPLY_RECEIPT = (suppleyReceiptId: number) =>
  `${PRACTITIONER_BASE_PATH}/${suppleyReceiptId}/completed-supply-receipt`;
export const PRACTITIONER_NEW_PATIENT_PATH = `${PRACTITIONER_BASE_PATH}/new-patient`;
export const PRACTITIONER_CONFIGURATION_PATH = `${PRACTITIONER_BASE_PATH}/configuration`;
export const PRACTITIONER_CONFIGURATION_CUSTOMIZE_PATH = `${PRACTITIONER_CONFIGURATION_PATH}/customize`;
export const PRACTITIONER_CONFIGURATION_VIEW_PATH = `${PRACTITIONER_CONFIGURATION_PATH}/view`;
export const PRACTITIONER_CALENDAR_CONFIGURATION_PATH = `${PRACTITIONER_BASE_PATH}/calendar-configuration`;
export const PRACTITIONER_ACCOUNT_SETTINGS = `${PRACTITIONER_BASE_PATH}/account-settings`;
export const PRACTITIONER_DOCUMENTS = `${PRACTITIONER_BASE_PATH}/documents`;
export const PRACTITIONER_GFE_MANAGEMENT_PATH = `${PRACTITIONER_BASE_PATH}/gfe-management`;
export const PRACTITIONER_ACCOUNT_SETTINGS_PATH = (setting: string, renderLocation?: string) => {
  if (renderLocation === 'tabs') {
    return `${PRACTITIONER_BASE_PATH}/inventory`;
  }
  return `${PRACTITIONER_BASE_PATH}/account-settings/${setting}`;
};

const PractitionerRoutes: React.FC = () => (
  <Switch>
    <PrivateRoute path={PRACTITIONER_MESSAGING_CUSTOMIZE_PATH} role={PRACTITIONER_ROLE} permission={USE_EHR}>
      <CustomizeMessage />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_MESSAGING_INDEX_PATH} role={PRACTITIONER_ROLE} permission={USE_EHR}>
      <MessagingIndex />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_NEW_PATIENT_PATH} permission={[CREATE_CUSTOMER, USE_EHR]}>
      <Route component={NewPatient} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_CONFIGURATION_CUSTOMIZE_PATH} role={PRACTITIONER_ROLE} permission={USE_EHR}>
      <Route component={CustomizeConfiguration} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_CONFIGURATION_VIEW_PATH} role={PRACTITIONER_ROLE} permission={USE_EHR}>
      <Route component={ViewConfiguration} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_CONFIGURATION_PATH} role={PRACTITIONER_ROLE} permission={USE_EHR}>
      <Route component={Configuration} />
    </PrivateRoute>
    <PrivateRoute
      path={PRACTITIONER_CALENDAR_CONFIGURATION_PATH}
      role={PRACTITIONER_ROLE}
      permission={[PRACTITIONER_BOOK_APPOINTMENT_UPDATE, USE_EHR]}
    >
      <Route component={CalendarConfiguration} />
    </PrivateRoute>
    <PrivateRoute
      path={[
        `${PRACTITIONER_BASE_PATH}/:id/confirmation-supply-receipt`,
        `${PRACTITIONER_BASE_PATH}/:id/completed-supply-receipt`,
      ]}
      role={PRACTITIONER_ROLE}
      permission={USE_EHR}
    >
      <Route component={SupplyReceiptConfirmationPage} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_PRODUCTS_ABOUT_PATH} role={PRACTITIONER_ROLE} permission={USE_EHR}>
      <Route component={ProductsAboutToExpire} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_PRODUCT_BOARD_PATH} role={PRACTITIONER_ROLE} permission={USE_EHR}>
      <Route component={ProductBoardPractitioner} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_ACCOUNT_SETTINGS_PATH('more-details')} role={PRACTITIONER_ROLE}>
      <Route component={MoreDetails} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management')} role={PRACTITIONER_ROLE}>
      <Route component={ServicesManagement} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_ACCOUNT_SETTINGS_PATH('patient-bulk-upload')} role={PRACTITIONER_ROLE}>
      <Route component={PatientBulkUpload} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_ACCOUNT_SETTINGS_PATH('calendar-configuration')} role={PRACTITIONER_ROLE}>
      <Route component={NewCalendarConfiguration} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_ACCOUNT_SETTINGS_PATH('completed-onboarding')} role={PRACTITIONER_ROLE}>
      <Route component={CompletedOnboarding} />
    </PrivateRoute>
    <PrivateRoute
      path={PRACTITIONER_GFE_MANAGEMENT_PATH}
      role={PRACTITIONER_ROLE}
      permission={[UPDATE_GFE_MANAGEMENT_SETTING]}
    >
      <Route component={GFEManagement} />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_ACCOUNT_SETTINGS} role={PRACTITIONER_ROLE} permission={USE_EHR}>
      <CreateOrEditPractitioners />
    </PrivateRoute>
    <PrivateRoute path={PRACTITIONER_DOCUMENTS} role={PRACTITIONER_ROLE}>
      <Route component={Documents} />
    </PrivateRoute>
    <PrivateRoute path="/" role={PRACTITIONER_ROLE}>
      <Route path={`${PRACTITIONER_BASE_PATH}/:tabId?/:state?`} component={DashboardPractitioner} />
    </PrivateRoute>
  </Switch>
);

export default PractitionerRoutes;
