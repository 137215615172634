import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import classnames from 'classnames';
import { useStyles } from './index.styles';
import { NavigationBar } from './NavigationBar';
import Analytics from '../../../../utils/analytics';
import {
  userInteractionProps,
  USER_INTERACTION_EVENT,
  USER_INTERACTION_GOOGLE_REVIEW,
  USER_INTERACTION_FACEBOOK_REVIEW,
  USER_INTERACTION_YELP_REVIEW,
} from '../../../../constants/analytics.constants';
import { FACEBOOK, GOOGLE, YELP } from '../../../../constants/socialMedia.constants';

interface Props {
  reviewButtonsOptions: any;
  setIsDialogPositiveReviewOpen: (value: boolean) => void;
  setQRvalue: (value: boolean) => void;
  setStep1: (value: boolean) => void;
}

export const Step1 = ({ reviewButtonsOptions, setIsDialogPositiveReviewOpen, setQRvalue, setStep1 }: Props) => {
  const classes = useStyles();

  const classesBtn = classnames(classes.button, classes.reviewIcons, classes.buttonReview);

  const onClickOption = (option: any) => {
    setQRvalue(option);
    setStep1(false);

    const optionLowerCased = option?.url?.toLowerCase();

    let componentName = '';
    if (optionLowerCased?.includes(GOOGLE)) {
      componentName = USER_INTERACTION_GOOGLE_REVIEW;
    } else if (optionLowerCased?.includes(FACEBOOK)) {
      componentName = USER_INTERACTION_FACEBOOK_REVIEW;
    } else if (optionLowerCased?.includes(YELP)) {
      componentName = USER_INTERACTION_YELP_REVIEW;
    }

    Analytics.track(USER_INTERACTION_EVENT, {
      ...userInteractionProps,
      component: componentName,
      url: window.location.href,
    });
  };

  return (
    <div className={classes.containerStep1}>
      <div
        style={{
          color: '#fff',
          width: '100%',
          height: '30%',
          backgroundColor: '#12574d',
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
        }}
      >
        <NavigationBar title="Go Back" onBackButtonClick={() => setIsDialogPositiveReviewOpen(false)} />
        <div>
          <DialogTitle className={classnames(classes.title, classes.biggerTitle)}>Patients reviews</DialogTitle>

          <DialogContent style={{ padding: '0' }}>
            <DialogContentText className={classes.content}>
              Select the site to have your patient review on
            </DialogContentText>
          </DialogContent>
        </div>
      </div>

      <DialogActions className={classes.dialogActions}>
        {reviewButtonsOptions &&
          reviewButtonsOptions.map((option: any) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                alignItems: 'center',
                marginBottom: '0.5rem',
                marginTop: '0.5rem',
              }}
            >
              <Button key={option.name} className={classnames(classesBtn)} onClick={() => onClickOption(option)}>
                <i className={classnames('fa-brands', option.icon)} />
              </Button>
              <p>{option.name}</p>
            </div>
          ))}
      </DialogActions>
    </div>
  );
};
