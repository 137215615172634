import React, { useState, useEffect, useContext, useMemo } from 'react';
import { TableRow, TableCell, IconButton, Box, withStyles } from '@material-ui/core';
import { OrderContext } from 'src/components/DashboardPractitioner/Tabs/OrderingTab';
import { IOrderProduct } from 'src/services/Products';
import { InventoryOrderItem } from 'src/interfaces/IInventoryOrder';
import { ClassNameMap } from 'src/types/Dom';
import { useUpdateInventoryOrderCustomItem, useUpdateInventoryOrderItem } from 'src/hooks/queries/useInventoryOrders';
import { formatCurrency } from 'src/utils/formatNumber';
import PercentChip from 'src/components/common/PercentChip';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import EHRNumberSelect from 'src/components/ui/v1/EHRNumberSelect';
import { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import { EMPTY_CELL } from 'src/constants/inventory.constants';
import { getProductOrItemDetails } from 'src/utils/inventoryOrdering';
import styles from './ProductSelection.styles';

type Props = ClassNameMap & {
  inventoryOrderId: number;
  product?: Partial<IOrderProduct>;
  noSavings?: boolean;
  item?: Partial<InventoryOrderItem>;
  customItem?: Partial<InventoryOrderCustomItem>;
};

export const ProductSelectionRow = ({
  classes,
  product,
  item,
  customItem,
  noSavings = false,
  inventoryOrderId,
}: Props) => {
  const [userQuantity, setUserQuantity] = useState<number>(0);
  const [userNote, setUserNote] = useState<string>('');
  const [isLoadingRow, setIsLoadingRow] = useState<boolean>(false);
  const { blockProgress, isFetching } = useContext(OrderContext) || {};
  const { id, quantity, price, notes, testId, listPrice, isCustomItem, makeCells } = useMemo(
    () => getProductOrItemDetails({ product, item, customItem, classes }),
    [item, customItem]
  );

  const { mutateAsync: updateOrderItem } = useUpdateInventoryOrderItem({
    inventoryOrderId,
    orderProductId: isCustomItem || !id ? 0 : id,
  });
  const { mutateAsync: updateCustomItem } = useUpdateInventoryOrderCustomItem(!isCustomItem || !id ? 0 : id);
  const isUpdating = (isLoadingRow || isFetching) && userQuantity !== quantity;

  const processChanges = async (newQuantity: number, updateUserInput: boolean = true): Promise<void> => {
    updateUserInput && setUserQuantity(newQuantity);
    setIsLoadingRow(true);
    try {
      if (isCustomItem) {
        await updateCustomItem({ quantity: newQuantity, notes: userNote });
      } else {
        await updateOrderItem({ quantity: newQuantity, notes: userNote });
      }
    } catch {
      setUserQuantity(quantity);
    } finally {
      setIsLoadingRow(false);
    }
  };

  useEffect(() => {
    setUserQuantity(quantity);
  }, [quantity]);

  useEffect(() => {
    setUserNote(notes ?? '');
  }, [notes]);

  useEffect(() => {
    blockProgress?.((!!userQuantity || userQuantity === 0) && quantity !== userQuantity);
  }, [quantity, userQuantity, blockProgress]);

  const savingsCell = noSavings
    ? undefined
    : {
      className: classes?.centerCell,
      testId: 'youSave',
      content: isCustomItem ? (
        EMPTY_CELL
      ) : (
        <>
          {formatCurrency(listPrice - price)}
          <PercentChip dividend={price} divisor={listPrice} className={classes?.percentChip} />
        </>
      ),
    };

  return (
    <TableRow classes={classes} data-loading={isUpdating} data-testid={`${testId}.${id}`}>
      {makeCells(savingsCell).map(({ className, testId: key, content }) => (
        <TableCell key={key} className={className}>
          {content}
        </TableCell>
      ))}
      <TableCell>
        <Box className={classes?.inputCell}>
          <IconButton
            disabled={!userQuantity || isLoadingRow}
            onClick={() => processChanges(userQuantity > 1 ? userQuantity : 0)}
            data-testid={`${testId}.${id}.remove`}
            className={classes?.iconButton}
          >
            <RemoveIcon />
          </IconButton>
          <EHRNumberSelect
            value={`${userQuantity}`}
            end={99}
            onChange={processChanges}
            data-testid={`${testId}.${id}.input`}
            dataCy={`${testId}.${id}.input`}
          />
          <IconButton
            disabled={isLoadingRow}
            onClick={() => processChanges(userQuantity + 1)}
            data-testid={`${testId}.${id}.add`}
            className={classes?.iconButton}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(ProductSelectionRow);
