import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  serviceTitle: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '0',
    marginBottom: '9px',
  },
  label: {
    '&.Mui-disabled': {
      color: '#fff !important',
    },
  },
  listItem: {
    fontSize: '30px',
    '& label': {
      color: 'black',
      '& span': {
        fontFamily: 'Messina Sans Regular',
      },
    },
  },
  listItemYellow: {
    backgroundColor: '#ff09',
    padding: '0px 10px 0px 0px',
    borderRadius: '4px',
  },
  listItemRed: {
    backgroundColor: '#ff5252',
    padding: '0px 10px 0px 0px',
    borderRadius: '4px',
    color: '#fff !important',
  },
  checkbox: {
    color: '#12574d',
  },
  lastReviewed: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.62,
    letterSpacing: 'normal',
    color: '#000000',
    marginTop: '0px',
  },
  diagnosesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  mx5: {
    marginRight: '2rem',
    marginLeft: '2rem',
  },
  accordion: {
    boxShadow: 'none',
  },
  accordionSummary: {
    border: '0',
    boxShadow: 'none',
    borderRadius: 'inherit',
    padding: '0px',
    minHeight: '45px !important',
    display: 'inline-block !important',
  },
  accordionDetails: {
    border: '0',
    display: 'inline-block',
    padding: '0 8px 8px 8px',
  },
}));
