import React from 'react';
import { makeStyles } from '@material-ui/core';
import IServices from '../../../interfaces/IServices';
import { ILineItem } from '../../../interfaces/IServiceVisits';
import { ServiceNote } from './ServiceNote';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 30,
  },
}));

interface IServiceNotesProps {
  servicesUsedIds: number[];
  services: IServices[];
  lineItems: ILineItem[];
  serviceNotes: { [serviceId: number]: string };
  serviceVisitOpened: boolean;
  isLoading: boolean;
  setServiceNotes: React.Dispatch<React.SetStateAction<{ [serviceId: number]: string }>>;
  setSaveMedicalCharting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ServiceNotes = ({
  servicesUsedIds,
  services,
  lineItems,
  serviceNotes,
  serviceVisitOpened,
  isLoading,
  setServiceNotes,
  setSaveMedicalCharting,
}: IServiceNotesProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {servicesUsedIds.map((serviceId) => {
        const service = services.find(({ id }) => id === serviceId);
        const lineItem = lineItems.find(({ serviceId: id }) => id === serviceId);
        return (
          service &&
          lineItem && (
            <ServiceNote
              key={serviceId}
              service={service}
              lineItem={lineItem}
              serviceNotes={serviceNotes}
              serviceVisitOpened={serviceVisitOpened}
              isLoading={isLoading}
              setServiceNotes={setServiceNotes}
              setSaveMedicalCharting={setSaveMedicalCharting}
            />
          )
        );
      })}
    </div>
  );
};
