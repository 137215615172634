import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  physicianPage: {
    background: 'white',
    width: '100%',
    padding: '2rem',
  },
  upcomingPatientListContainer: {
    height: '40vh',
    maxHeight: '500px',
  },
  patientListContainer: {
    maxHeight: '1000px',
    height: '84.7vh',
  },
  tableGeneral: {
    position: 'relative',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'hidden',
  },
  infiScrollContainer: {
    overflow: 'auto',
    marginTop: '10px',
  },
  patientRow: {
    cursor: 'pointer',
  },
  heading: {
    marginTop: '3rem',
    paddingLeft: '1rem',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  filterContainer: {
    justifyContent: 'space-between',
    paddingLeft: '16px',
    marginBottom: '2rem',
  },
  search: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '14px',
  },
  formControl: {
    width: '150px',
    marginLeft: '21px',
  },
  patientsTabs: {
    display: 'flex',
    marginLeft: '16px',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: '2rem',
  },
  activeTabItem: {
    fontFamily: 'Messina Sans Bold',
    color: 'black',
    cursor: 'pointer',
  },
  tabItem: {
    color: '#858585',
    cursor: 'pointer',
  },
  missingGFETab: {
    marginRight: '3rem',
  },
  gfeChart: {
    padding: '2rem',
    border: '1px solid #C8C8C8',
    borderRadius: '8px',
  },
  chartHeader: {
    justifyContent: 'space-between',
  },
  chartTotal: {
    fontFamily: 'Messina Sans Bold',
    fontSize: '18px',
    paddingRight: '8px',
  },
  mr1: {
    marginRight: '1rem',
  },
  mr3: {
    marginRight: '3rem',
  },
  closeButton: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #CFCFCF',
    borderRadius: '8px',
    cursor: 'pointer',
    padding: '0.5rem',
  },
  chartSubText: {
    color: '#707070',
  },
  exportButton: {
    textTransform: 'none',
    padding: '8px',
    height: '40px',
    marginLeft: '2rem',
    width: '160px',
  },
  modal: {
    display: 'flex',
    paddingRight: '1rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: 'auto',
    borderRadius: '8px',
  },
  modalContent: {
    width: '440px',
    height: '95%',
    background: 'white',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    position: 'relative',
  },
  modalHeader: {
    padding: '0rem 1rem 0 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalFormControl: {
    width: '45%',
  },
  exportDateSelection: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2rem 1rem',
  },
  modelProviderSelection: {
    padding: '0 1rem',
  },
  modalFormProviderControl: {
    width: '100%',
  },
  exportButtonContainer: {
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    padding: '1rem',
  },
  exportButtonDownload: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    background: '#12574D',
    border: 'none',
    cursor: 'pointer',
    color: 'white',
    fontSize: '16px',
    '&:disabled': {
      backgroundColor: '#979797',
      color: 'white',
      cursor: 'default',
    },
  },
  downloading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  removeRequiresUpdateContainer: {
    paddingLeft: '1rem',
  },
}));
