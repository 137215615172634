import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Badge,
  CircularProgress,
} from '@material-ui/core';
import { Done, Help, Info, Warning } from '@material-ui/icons';
import formatDate from '../../utils/formatDate';
import { NotificationType, GRAL_MESSAGE_TYPE, REC_REPORT_TYPE } from '../../interfaces/IGlobalNotification';

type NotificationItemProps = {
  createdAt: Date;
  loading: boolean;
  messageText: string;
  onActionClick: () => void;
  read: boolean;
  showAction: boolean;
  type: NotificationType;
};

type SimpleItemProps = {
  messageText: string;
  icon: React.ReactNode;
};

const NotificationIcon = ({ typeIcon }: { typeIcon: NotificationType }) => {
  switch (typeIcon) {
    case GRAL_MESSAGE_TYPE:
      return <Info color="primary" />;
    case REC_REPORT_TYPE:
      return <Warning color="error" />;
    default:
      return <Help />;
  }
};

const NotificationItem = ({
  createdAt,
  loading,
  messageText,
  onActionClick,
  read = false,
  showAction,
  type,
}: NotificationItemProps) => (
  <ListItem button>
    <ListItemIcon>
      <Badge color="error" badgeContent="new" invisible={read}>
        <NotificationIcon typeIcon={type} />
      </Badge>
    </ListItemIcon>
    <ListItemText primary={messageText} secondary={formatDate(createdAt)} />
    {showAction &&
      (loading ? (
        <CircularProgress size={20} />
      ) : (
        <ListItemSecondaryAction>
          <IconButton size="small" edge="end" title="mark as read" aria-label="read" onClick={onActionClick}>
            <Done color="primary" />
          </IconButton>
        </ListItemSecondaryAction>
      ))}
  </ListItem>
);

export const SimpleItem = ({ messageText, icon }: SimpleItemProps) => (
  <ListItem button>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={messageText} />
    <ListItemSecondaryAction />
  </ListItem>
);
export default NotificationItem;
