import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import { useAvailabilityAccount, useUpdateAvailabilityMutation } from '../../../hooks/acuity/useAcuityAccount';
import { useStyles } from './styles';
import { AvailabilityTimeBlock } from './AvailiabilityTimeBlock';
import AvailabilityOverride from './AvailabilityOverride';
import { DEFAULT_ACUITY_TIME_RANGE } from './schemas';

const sundayName = 'sunday';
const mondayName = 'monday';
const tuesdayName = 'tuesday';
const wednesdayName = 'wednesday';
const thursdayName = 'thursday';
const fridayName = 'friday';
const saturdayName = 'saturday';

interface Props {
  locationId?: number;
}

const Availability = ({ locationId }: Props) => {
  const useQuery = () => new URLSearchParams(window.location.search);
  const query = useQuery();
  const practitionerId = query.get('practitionerId') || undefined;
  const classes = useStyles();
  const [days, setDays] = useState({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  });
  const { isFetching, data: availability } = useAvailabilityAccount({ practitionerId, locationId });
  const updateAvailabilityMutation = useUpdateAvailabilityMutation();
  const loading = updateAvailabilityMutation.isLoading || isFetching;

  useEffect(() => {
    if (!isFetching) {
      setDays({
        sunday: availability.sunday || [],
        monday: availability.monday || [],
        tuesday: availability.tuesday || [],
        wednesday: availability.wednesday || [],
        thursday: availability.thursday || [],
        friday: availability.friday || [],
        saturday: availability.saturday || [],
      });
    }
  }, [availability, isFetching]);

  const onChangeDay = (value: any, name: string, index: number) => {
    const newVal = days[name];
    newVal[index] = value;
    setDays({ ...days, [name]: newVal });
  };

  const onRemove = (name: string, index: number) => {
    setDays({ ...days, [name]: days[name].filter((v: string, i: number) => i !== index) });
  };
  const onAdd = (name: string) => {
    setDays({ ...days, [name]: days[name].concat([DEFAULT_ACUITY_TIME_RANGE]) });
  };

  const onSubmit = () => {
    updateAvailabilityMutation.mutate({ days, practitionerId, locationId });
  };

  return (
    <Grid item xs>
      <Grid item>
        <h2>Availability Configuration</h2>
      </Grid>
      <Box paddingX={2} component="form" className={classes.form}>
        <h3>Set hours of availability</h3>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SUNDAY</TableCell>
                <TableCell>MONDAY</TableCell>
                <TableCell>TUESDAY</TableCell>
                <TableCell>WEDNESDAY</TableCell>
                <TableCell>THURSDAY</TableCell>
                <TableCell>FRIDAY</TableCell>
                <TableCell>SATURDAY</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <AvailabilityTimeBlock
                    name="sunday"
                    disabled={loading}
                    hours={days.sunday}
                    onChangeDay={onChangeDay}
                    onRemove={onRemove}
                  />
                  <Box className={classes.formItem} textAlign="right">
                    {!isFetching && <AddIcon className={classes.actionButton} onClick={() => onAdd(sundayName)} />}
                  </Box>
                </TableCell>
                <TableCell>
                  <AvailabilityTimeBlock
                    name="monday"
                    disabled={loading}
                    hours={days.monday}
                    onChangeDay={onChangeDay}
                    onRemove={onRemove}
                  />
                  <Box className={classes.formItem} textAlign="right">
                    {!isFetching && <AddIcon className={classes.actionButton} onClick={() => onAdd(mondayName)} />}
                  </Box>
                </TableCell>
                <TableCell>
                  <AvailabilityTimeBlock
                    name="tuesday"
                    disabled={loading}
                    hours={days.tuesday}
                    onChangeDay={onChangeDay}
                    onRemove={onRemove}
                  />
                  <Box className={classes.formItem} textAlign="right">
                    {!isFetching && <AddIcon className={classes.actionButton} onClick={() => onAdd(tuesdayName)} />}
                  </Box>
                </TableCell>
                <TableCell>
                  <AvailabilityTimeBlock
                    name="wednesday"
                    disabled={loading}
                    hours={days.wednesday}
                    onChangeDay={onChangeDay}
                    onRemove={onRemove}
                  />
                  <Box className={classes.formItem} textAlign="right">
                    {!isFetching && <AddIcon className={classes.actionButton} onClick={() => onAdd(wednesdayName)} />}
                  </Box>
                </TableCell>
                <TableCell>
                  <AvailabilityTimeBlock
                    name="thursday"
                    disabled={loading}
                    hours={days.thursday}
                    onChangeDay={onChangeDay}
                    onRemove={onRemove}
                  />
                  <Box className={classes.formItem} textAlign="right">
                    {!isFetching && <AddIcon className={classes.actionButton} onClick={() => onAdd(thursdayName)} />}
                  </Box>
                </TableCell>
                <TableCell>
                  <AvailabilityTimeBlock
                    name="friday"
                    disabled={loading}
                    hours={days.friday}
                    onChangeDay={onChangeDay}
                    onRemove={onRemove}
                  />
                  <Box className={classes.formItem} textAlign="right">
                    {!isFetching && <AddIcon className={classes.actionButton} onClick={() => onAdd(fridayName)} />}
                  </Box>
                </TableCell>
                <TableCell>
                  <AvailabilityTimeBlock
                    name="saturday"
                    disabled={loading}
                    hours={days.saturday}
                    onChangeDay={onChangeDay}
                    onRemove={onRemove}
                  />
                  <Box className={classes.formItem} textAlign="right">
                    {!isFetching && <AddIcon className={classes.actionButton} onClick={() => onAdd(saturdayName)} />}
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item lg={6} className={classes.formItem}>
          <Button data-cy="submitBtn" disabled={loading} variant="contained" color="primary" onClick={onSubmit}>
            Save Changes
            {loading && <CircularProgress size={25} />}
          </Button>
        </Grid>
      </Box>
      <AvailabilityOverride availability={availability} />
    </Grid>
  );
};

export default Availability;
