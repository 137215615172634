/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import {
  Button as MaterialButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { convertTime } from 'src/utils/formatDate';
import { ACUITY_AVAILABILITY_HOURS } from '../../../constants/schemaForm.constants';
import { blockOffHoursDefaultValues, blockOffHoursSchema } from './schemas';
import { useUpdateBlockOffHoursMutation } from '../../../hooks/acuity/useAcuityAccount';
import { useStyles } from './styles';

const dateFormat = 'YYYY-MM-DD';

const CustomFromDateTextField = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TextField {...props} name="fromDate" style={{ marginBottom: '10px' }} fullWidth />
);

interface ConfirmModalProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  practitionerId?: number; // if is MedSpa
}

const BlockOffHoursModal = ({ openDialog, setOpenDialog, practitionerId }: ConfirmModalProps) => {
  const classes = useStyles();

  const updateBlockOffHoursMutation = useUpdateBlockOffHoursMutation(() => setOpenDialog(false));

  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(blockOffHoursSchema),
    defaultValues: blockOffHoursDefaultValues,
  });

  const watchStart = watch('start');

  useEffect(() => {
    setValue('start', moment().format(dateFormat));
  }, []);

  const handleDialogCloseCancel = () => setOpenDialog(false);

  const onSubmit = (data: any) => {
    const startDate = `${data.start} ${convertTime(data.hourStart)}`;
    const endDate = `${data.start} ${convertTime(data.hourEnd)}`;

    updateBlockOffHoursMutation.mutate({ startDate, endDate, practitionerId });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={openDialog} onClose={handleDialogCloseCancel} data-cy="confirmationModal">
        <DialogTitle>Block off hours</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-content-text">
            <Box paddingBottom="8px">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disabled={updateBlockOffHoursMutation.isLoading}
                  variant="dialog"
                  inputVariant="outlined"
                  label="Start date"
                  format={dateFormat}
                  autoOk
                  disableToolbar
                  value={watchStart}
                  error={!!errors?.start}
                  helperText={errors?.start?.message}
                  onChange={(date: any) => setValue('start', date.format(dateFormat))}
                  TextFieldComponent={CustomFromDateTextField}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box className={classes.timeText} paddingLeft="5px">
              {'From '}
            </Box>
            <TextField
              disabled={updateBlockOffHoursMutation.isLoading}
              error={!!errors?.hourStart}
              inputRef={register}
              helperText={errors?.hourStart ? ACUITY_AVAILABILITY_HOURS : ''}
              type="time"
              defaultValue="7:00"
              name="hourStart"
              size="small"
              label="Hour"
              variant="outlined"
            />
            <div className={classes.timeText}>{' To '}</div>
            <TextField
              error={!!errors?.hourEnd}
              inputRef={register}
              disabled={updateBlockOffHoursMutation.isLoading}
              helperText={errors?.hourEnd ? ACUITY_AVAILABILITY_HOURS : ''}
              defaultValue="7:00"
              type="time"
              name="hourEnd"
              size="small"
              label="Hour"
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <MaterialButton
            disabled={updateBlockOffHoursMutation.isLoading}
            data-cy="closeModal"
            onClick={handleDialogCloseCancel}
          >
            Cancel
          </MaterialButton>
          <MaterialButton
            disabled={updateBlockOffHoursMutation.isLoading}
            data-cy="confirmModal"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            autoFocus
          >
            Save
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default BlockOffHoursModal;
