import React, { useEffect, useMemo } from 'react';
import {
  ExternalMedicalDirector,
  MedicalDirectorInfo,
  MedicalLicenseDetails,
  StepStatus,
} from 'src/interfaces/onboarding.interface';
import ExpandableRadioGroup from 'src/components/common/ExpandableRadioGroup';
import Banner from 'src/components/common/Banner';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING, STEP_PENDING } from 'src/components/StepsTracker';
import { useCreateMedicalDirectorInfo } from 'src/hooks/queries/useAccountSettings';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { usePhysicians } from 'src/hooks/queries/usePhysicians';
import MedicalDirectorForm from './MedicalDirectorForm';
import SignAuthorization from './SignAuthorization';

interface MedicalDirectorProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus) => void;
  medicalDirectorInfo?: MedicalDirectorInfo;
  progressPercentage?: number;
}

const isOwnMDValues = {
  isOwnMedicalDirector: true,
  hasNoMedicalDirector: false,
};

const hasNoMDValues = {
  isOwnMedicalDirector: false,
  hasNoMedicalDirector: true,
};

const externalMDValues = {
  isOwnMedicalDirector: false,
  hasNoMedicalDirector: false,
};

const MedicalDirector = ({ stepId, onStepUpdate, medicalDirectorInfo, progressPercentage }: MedicalDirectorProps) => {
  const [selectedOption, setSelectedOption] = React.useState<number | null>(null);
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();
  const { mutateAsync: updateMedicalDirector, isLoading, isError, isSuccess } = useCreateMedicalDirectorInfo();
  const [defaultOption, setDefaultOption] = React.useState<number | undefined>();
  const [agreementIsSigned, setAgreementIsSigned] = React.useState<boolean>(false);

  const handleStepStatus = (status: StepStatus) => {
    onStepUpdate(stepId, status);
  };

  const handleSignAuthorization = async (medicalLicenseDetails: MedicalLicenseDetails) => {
    const res = await updateMedicalDirector({
      medicalDirectorInfo: {
        ...isOwnMDValues,
      },
      medicalLicenseDetails,
    });

    if (res?.success) {
      updateProgress({
        stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
        percentage: progressPercentage as number,
      });
    }
  };

  const handleNoMD = () => {
    updateMedicalDirector({
      medicalDirectorInfo: { ...hasNoMDValues },
    });
  };

  const handleCompleteMDForm = (formData: ExternalMedicalDirector) => {
    updateMedicalDirector({
      medicalDirectorInfo: { ...externalMDValues, externalMedicalDirector: formData },
    });
  };

  const handleOptionChange = (index: number) => {
    setSelectedOption(index);
  };

  const MDdependencyArray = [
    medicalDirectorInfo?.hasNoMedicalDirector,
    medicalDirectorInfo?.externalMedicalDirector?.email,
    medicalDirectorInfo?.externalMedicalDirector?.firstName,
    medicalDirectorInfo?.externalMedicalDirector?.lastName,
    medicalDirectorInfo?.externalMedicalDirector?.phone,
    medicalDirectorInfo?.isOwnMedicalDirector,
  ];

  const { data: medicalDirectors = [], isLoading: isLoadingMedicalDirectors } = usePhysicians(
    {
      ids: medicalDirectorInfo?.medicalDirectorId,
    },
    !!medicalDirectorInfo?.medicalDirectorId
  );
  const currentMedicalDirector = useMemo(
    () => medicalDirectors?.find((medicalDirector) => medicalDirector.id === medicalDirectorInfo?.medicalDirectorId),
    [medicalDirectorInfo?.medicalDirectorId, medicalDirectors]
  );

  useEffect(() => {
    const { externalMedicalDirector } = medicalDirectorInfo || {};
    if (medicalDirectorInfo?.isOwnMedicalDirector) {
      setDefaultOption(0);
      onStepUpdate(stepId, STEP_COMPLETED);
    } else if (
      externalMedicalDirector?.email &&
      externalMedicalDirector?.firstName &&
      externalMedicalDirector?.lastName &&
      externalMedicalDirector?.phone
    ) {
      setDefaultOption(1);
      if (externalMedicalDirector?.agreementPdf) {
        setAgreementIsSigned(true);
        onStepUpdate(stepId, STEP_COMPLETED);
      } else {
        onStepUpdate(stepId, STEP_PENDING);
      }
    } else if (medicalDirectorInfo?.hasNoMedicalDirector) {
      setDefaultOption(2);
      onStepUpdate(stepId, STEP_PENDING);
    } else if (medicalDirectorInfo?.medicalDirectorId) {
      setDefaultOption(1);
      onStepUpdate(stepId, STEP_COMPLETED);
    }
  }, [...MDdependencyArray, selectedOption, isSuccess]);

  useEffect(() => {
    if (isError) {
      onStepUpdate(stepId, STEP_EMPTY);
    } else if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    }
  }, [isError, isLoading]);

  const groupButtons = [
    {
      label: 'I am the medical director.',
      component: (
        <SignAuthorization
          onSigned={handleSignAuthorization}
          signedFile={medicalDirectorInfo?.ownMedicalDirectorAgreementPdf}
          disabled={isLoading}
        />
      ),
      cta: () => handleStepStatus(STEP_EMPTY),
    },
    {
      label: 'I already have a medical director.',
      component: currentMedicalDirector?.id ? (
        <MedicalDirectorForm
          formData={{
            id: currentMedicalDirector?.id,
            firstName: currentMedicalDirector?.identity?.firstName,
            lastName: currentMedicalDirector?.identity?.lastName,
            email: currentMedicalDirector?.identity?.email,
            phone: currentMedicalDirector?.phone,
          }}
          hideBanner
          hideHeader
          disabled
        />
      ) : (
        <MedicalDirectorForm
          onComplete={handleCompleteMDForm}
          formData={medicalDirectorInfo?.externalMedicalDirector}
          disabled={defaultOption === 1 || isLoading}
          hideBanner={agreementIsSigned}
        />
      ),
      cta: () => handleStepStatus(STEP_EMPTY),
    },
    {
      label: 'I do not have a medical director.',
      component: (
        <Banner
          content={`Our Internal Operations team will follow up shortly with a 
            list of available Medical Directors in your area.`}
          type="warning"
        />
      ),
      cta: () => handleNoMD(),
    },
  ];

  return (
    <ExpandableRadioGroup
      onOptionChange={handleOptionChange}
      radioButtonDetails={groupButtons}
      defaultSelection={defaultOption}
      disabled={isLoading || defaultOption !== undefined || isLoadingMedicalDirectors}
    />
  );
};

export default MedicalDirector;
