import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import Page from 'src/components/common/Page';
import { dispatch } from '../../../rematch';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import ProductIdentifiers from '../../common/ProductIdentifiers/ProductIdentifiers';

const ReconciliationAssets = () => {
  const history = useHistory();
  const { reportId, serviceId }: any = useParams();
  const { reconciliationReport } = useSelector((state: any) => state.reconciliationReport);

  const editionDisabled = true; // supplyReceipt.status === 'closed';
  const productsSelected = reconciliationReport.reconciliationReportItems.filter(
    (item: any) => item.serviceId === +serviceId
  );

  const serviceSelected = {
    id: serviceId,
    name: productsSelected[0]?.product || '',
    assets:
      productsSelected.map((item: any) => ({
        id: item.id,
        internalSerialNumber: item.internalSerialNumber,
        lot: item.lot,
        counted: item.counted,
        expected: item.expected,
        currentNumberOfUnits: item.expected,
        initialNumberOfUnits: item.initialNumberOfUnits || 0,
        allowPartialSale: item.allowPartialSale,
        expireAt: item.expireAt,
        reconstitutedAt: item.reconstitutedAt,
        markedAsEmpty: item.markedAsEmpty,
      })) || [],
  };

  useEffect(() => {
    dispatch.reconciliationReport.getReconciliationReport(reportId || null);
  }, []);

  const goBack = () => {
    history.push(`/administrator/reconciliation-reports/${reportId}`);
  };

  const handleRemove = (assetsSelected: number[]) => {
    const assetsIds = assetsSelected.map(({ assetId }: any) => assetId);

    dispatch({
      type: 'reconciliationReport/removeReconciliationAssets',
      payload: {
        assetsIds,
        reconciliationReportId: reconciliationReport.id,
        onSuccess: goBack,
      },
    });
  };

  const Breadcrumb = (
    <Box mb="17px">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Inventory" />
        <BreadcrumbText text="Reconciliation Report" linkTo="/administrator/reconciliation-reports" />
        <Box>Products Identifiers</Box>
      </BreadcrumbsContainer>
    </Box>
  );

  return (
    <Page title="Products Identitifies">
      <Box width="100%">
        {Breadcrumb}
        <ProductIdentifiers
          aggregatedField="counted"
          service={serviceSelected}
          editionDisabled={editionDisabled}
          handleRemove={handleRemove}
        />
      </Box>
    </Page>
  );
};

export default ReconciliationAssets;
