import React from 'react';
import { Avatar } from '@material-ui/core';
import { IPractitionerForPhysician } from '../../interfaces/IPractitioner';
import { useStyles } from './ProviderDetails.styles';
import { usePractitionerBadgeStyles } from '../../components/DashboardAdministrator/Practitioners/Practitioner.styles';
import {
  PORTRAIT_FLEX,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  PORTRAIT_LITE,
  PORTRAIT_LUXE,
} from '../../constants/general.constants';

export const ProviderDetail = ({
  practitionerData,
  showChartReview,
}: {
  practitionerData: IPractitionerForPhysician;
  showChartReview: boolean;
}) => {
  const classes = useStyles();
  const badge = usePractitionerBadgeStyles();

  const getBadgeClassName = (roleName: string): string => {
    switch (roleName) {
      case PORTRAIT_FLEX:
        return badge.flex;
      case PORTRAIT_LUXE:
        return badge.luxe;
      case PORTRAIT_LITE:
        return badge.lite;
      case PORTRAIT_LEGACY:
      case PORTRAIT_LEGACY_PLUS:
        return badge.legacy;
      default:
        return '';
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        <Avatar
          className={classes.avatar}
          alt={practitionerData.fullName}
          src={practitionerData.avatarUrl || '/static/images/avatar/1.jpg'}
        />
        <div>
          <p>
            <b>{practitionerData.fullName}</b>{' '}
            <span className={getBadgeClassName(practitionerData.role)}>{practitionerData.role}</span>
          </p>
          <p className={classes.subTitle}>{practitionerData.licenses}</p>
        </div>
      </div>
      <div className={classes.providerDetails}>
        <div>
          <p className={classes.subTitle}>Email:</p>
          <p>{practitionerData.email}</p>
        </div>
        <div>
          <p className={classes.subTitle}>Suite Address:</p>
          {practitionerData.addresses.map((location: string) => (
            <p>{location}</p>
          ))}
          {practitionerData.addresses.length === 0 && <p>Location currently not available</p>}
        </div>
        {showChartReview && (
          <div>
            <p className={classes.subTitle}>Chart Review</p>
            <p>{practitionerData.completedReviewPercentage}</p>
          </div>
        )}
      </div>
    </div>
  );
};
