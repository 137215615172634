import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  card: {
    margin: '0',
    minHeight: '100px'
  },
  cardContent: {
    padding: '11px 20px 9px !important'
  },
  smallTitle: {
    margin: '0'
  },
  formGroup: {
    marginTop: '15px'
  },
  standingOrder: {
    display: 'flex',
    alignItems: 'center'
  },
  formControlLabel: {
    marginRight: '5px',
    marginLeft: '0'
  },
  checkbox: {
    color: '#12574d'
  },
  label: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px'
  },
  checkboxLabel: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  documentLink: {
    color: 'rgba(9, 28, 244, 0.99)'
  }
}));
