import { SpecialOfferPostFormData } from 'src/pages/DashboardAdministrator/NewSpecialModal';
import Api from './Api';

export interface OfferDiscount {
  id: number;
  discount: number;
  quantity: number;
  orderProductMarketPrice: number;
  orderProductSupplierId: number;
  orderProductName: string;
  specialOfferId: number;
  orderProductId: number;
}
export interface ISpecialOffer {
  id: number;
  name: string;
  createdAt: string | Date | undefined;
  updatedAt: Date | string;
  expirationDate: Date | string;
  active: boolean;
  note?: string;
  discounts: OfferDiscount[];
  productNameLabel?: string;
  marketPriceLabel?: number;
  youSaveLabel?: number;
  youPayLabel?: number;
}

const SpecialOffers = {
  async getSpecial(specialOfferId: number): Promise<ISpecialOffer> {
    const { specialOffer } = await Api.get(`v4/special_offers/${specialOfferId}`);
    return specialOffer;
  },
  async getAllSpecials(active?: boolean): Promise<ISpecialOffer[]> {
    const url = active ? `v4/special_offers?active=${active}` : `v4/special_offers`;
    const { data } = await Api.get(url);
    return data;
  },
  async createSpecialOffer(
    data: SpecialOfferPostFormData
  ): Promise<{ success: boolean; specialOffer: SpecialOfferPostFormData; message?: string }> {
    return Api.post(`v4/special_offers`, data);
  },
  async updateSpecialOffer(
    specialOfferId: number,
    specialOffer: SpecialOfferPostFormData
  ): Promise<{ success: boolean; specialOffer: ISpecialOffer; message?: string }> {
    return Api.put(`v4/special_offers/${specialOfferId}`, specialOffer);
  },
  async updateSpecialOfferStatus(
    specialOfferId: number
  ): Promise<{ success: boolean; specialOffer: ISpecialOffer; message?: string }> {
    return Api.put(`v4/special_offers/${specialOfferId}/toggle_status`, { specialOfferId });
  },
  async deleteSpecialOffer(specialOfferId: number): Promise<{ success: boolean; message?: string }> {
    return Api.delete(`v4/special_offers/${specialOfferId}`);
  },
};

export default SpecialOffers;
