import { withStyles, Box } from '@material-ui/core';

export const ProductBox = withStyles(() => ({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #E4E7EB',
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: '208px',
    marginBottom: '10px',
    fontFamily: 'Messina Sans Regular',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '95%',
  },
}))(Box);
