import React from 'react';
import { Button } from '@material-ui/core';
import IServices from '../../../interfaces/IServices';

interface Props {
  services: IServices[];
  setCurrentLayer: (serviceId: number) => void;
  currentLayer: number;
}

export const ServicesButtons = ({ services, setCurrentLayer, currentLayer }: Props) => (
  <div style={{ marginTop: 20 }}>
    {services.map(({ id, name, color }) => (
      <Button
        data-cy="serviceBtn"
        key={id}
        type="button"
        style={{
          backgroundColor: color || 'black',
          color: 'white',
          marginRight: 5,
          marginBottom: 3,
          opacity: id === currentLayer ? 1 : 0.35,
        }}
        onClick={() => setCurrentLayer(id)}
      >
        {name}
      </Button>
    ))}
  </div>
);
