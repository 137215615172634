import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { useStyles } from './ButtonImage.styles';

interface IButtonImage {
  tempImage: string | null;
  selectedImage: boolean;
  label: string;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFetched: boolean;
  valueToSet: string;
  errors: FieldErrors<FieldValues>;
}

export default function ButtonImage(props: IButtonImage) {
  const classes = useStyles();

  const { tempImage, selectedImage, label, onClick, isFetched, valueToSet, errors } = props;

  return (
    <div className={classes.root}>
      <Button
        focusRipple
        variant="contained"
        component="label"
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        disabled={!isFetched}
      >
        <input
          name={valueToSet}
          accept="image/png, image/jpg, image/jpeg"
          id="uploadPhoto"
          type="file"
          hidden
          onChange={onClick}
        />
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${tempImage})`,
          }}
        />
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <Typography component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>
            {!selectedImage ? `Load ${label}` : `Change ${label}`}
            <span className={classes.imageMarked} />
          </Typography>
        </span>
      </Button>
      {!!errors[valueToSet] && !tempImage ? (
        <span className={classes.errorMessage}>{errors[valueToSet]?.message}</span>
      ) : null}
    </div>
  );
}
