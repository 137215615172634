import { ILocation, ILocationPost } from '../interfaces/ILocation';
import Api from './Api';

const BASE_PATH = '/v3/locations';

export const Locations = {
  async getAllLocations(): Promise<ILocation[]> {
    const { locations } = await Api.get(`${BASE_PATH}`);
    return locations;
  },
  async createLocation(data: ILocationPost): Promise<ILocationPost> {
    const { results, error } = await Api.post(`${BASE_PATH}`, data);
    if (error) {
      throw error;
    }
    return results;
  },
  async getLocationInfo(id: number): Promise<ILocation> {
    const { location, error } = await Api.get(`${BASE_PATH}/${id}`);
    if (error) {
      throw error;
    }
    return location;
  },
  async getPractitionerLocation(practitionerId: number): Promise<ILocation[]> {
    const { locations, error } = await Api.get(`${BASE_PATH}/practitioner_calendars_locations`, { practitionerId });
    if (error) {
      throw error;
    }
    return locations;
  },
  async populateLocations(): Promise<any> {
    const { newRecords, errors } = await Api.post(`${BASE_PATH}/populate`, {});
    return { newRecords, errors };
  },
  async editLocation(data: ILocationPost): Promise<ILocationPost> {
    const { id } = data;
    const { results, error } = await Api.put(`${BASE_PATH}/${id}`, data);
    if (error) {
      throw error;
    }
    return results;
  },
};
