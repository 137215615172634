import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  chip: {
    minWidth: '40px',
    height: '20px',
    padding: '12px 0px',
  },
  percentChip: {
    marginLeft: 5,
    backgroundColor: 'rgb(217,217,217)',
    color: 'rgb(77,77,77)',
  },
  percentChipActive: {
    marginLeft: 5,
    backgroundColor: '#D1F1BD',
    color: '#314F1F',
  },
});
