import React from 'react';

import { Card, CardContent, Modal } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { Button } from '../common/Button';
import { IconButton } from '../common/IconButton';
import { useStyles } from './ProgressNotePhotoModal.styles';
import { IProgressNotePhotoModalProps } from '../../types/ProgressNoteTypes';

const ProgressNotePhotoModal = ({
  isOpenModal,
  photoUrlSrc,
  closeModal,
  viewOriginal,
}: IProgressNotePhotoModalProps) => {
  const classes = useStyles();

  return (
    <>
      <Modal open={isOpenModal} onClose={closeModal} className={classes.modal}>
        <Card style={{ width: 'fit-content' }}>
          <CardContent className={classes.cardContentPhoto}>
            <img className={classes.photo} src={photoUrlSrc} alt="item" />
          </CardContent>
          <CardContent className={classes.cardContentButtons}>
            <div className={classes.buttons}>
              <Button title="Close" className={classes.closeButton} onClick={closeModal} />
              <IconButton
                title="View Original"
                onClick={viewOriginal}
                className={classes.ViewOriginalButton}
                Icon={<Launch />}
              />
            </div>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};

export default ProgressNotePhotoModal;
