import React from 'react';
import { Chip } from '@material-ui/core';

export const ActiveChip = ({ active }: { active: boolean }) => {
  const styles = { width: '65px', height: '25px', borderRadius: '6px' };
  const activeStyles = { background: '#E7F9DA', color: '#386A18' };
  const inactiveStyles = { background: '#E2E2E2', color: '#6B6B6B' };
  return (
    <Chip
      label={active ? 'Active' : 'Inactive'}
      size="small"
      style={{ ...styles, ...(active ? activeStyles : inactiveStyles) }}
    />
  );
};
