import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PatientProfile from './PhysicianPatientProfile';
import EditContactInfo from './EditContactInfo';
import MedicalProfile from './MedicalProfile';
import PatientConsents from './PatientConsents';
import SkinProfile from './SkinProfile';
import Messaging from './Messaging';
import ProgressNote from '../components/ProgressNote';
import PatientChargeCard from './PatientChargeCard';
import PatientCreditCards from './PatientCreditCards';
import PrivateRoute from '../utils/PrivateRoute';
import {
  UPDATE_PATIENT_CONTACT_INFO,
  UPDATE_PATIENT_MEDICAL_PROFILE,
  READ_CUSTOMER,
} from '../constants/actions.constants';
import ServiceVisitContainer from './ServiceVisit/ServiceVisitContainer';

const PhysicianPatientRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute path={`${path}/:patientId/edit`} permission={UPDATE_PATIENT_CONTACT_INFO}>
        <Route component={EditContactInfo} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/consents`} permission={READ_CUSTOMER}>
        <Route component={PatientConsents} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/medicalProfile`} permission={UPDATE_PATIENT_MEDICAL_PROFILE}>
        <Route component={MedicalProfile} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/messaging`} permission={READ_CUSTOMER}>
        <Route component={Messaging} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/skinProfile`} permission={READ_CUSTOMER}>
        <Route component={SkinProfile} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/progressNote`} permission={READ_CUSTOMER}>
        <Route component={ProgressNote} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/newServiceVisit/:serviceVisitId`} permission={READ_CUSTOMER}>
        <Route component={ServiceVisitContainer} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/retailCheckout`} permission={UPDATE_PATIENT_CONTACT_INFO}>
        <Route component={PatientChargeCard} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId/creditCards`} permission={UPDATE_PATIENT_CONTACT_INFO}>
        <Route component={PatientCreditCards} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:patientId`} permission={READ_CUSTOMER}>
        <Route component={PatientProfile} />
      </PrivateRoute>
    </Switch>
  );
};

export default PhysicianPatientRoutes;
