import React, { useRef } from 'react';
import { Box, Button } from '@material-ui/core';
import { useStyles } from './UploadImage.styles';

const UploadImageButton = ({
  imageUrl,
  field,
  onChange,
}: {
  field: string;
  imageUrl: string | undefined;
  onChange: (file: string | null) => void;
}) => {
  const classes = useStyles();

  const fileRef = useRef();
  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          onChange(String(reader.result));
        };
      }
    }
  };

  const clickOnFileUpload = () => {
    // @ts-ignore
    fileRef?.current?.click();
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Button variant="contained" className={classes.button} color="primary" onClick={clickOnFileUpload}>
          {imageUrl ? `Edit` : `Upload`} {field}
          <input
            ref={fileRef}
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            id="uploadImage"
            hidden
            onChange={handleUploadImage}
          />
        </Button>
      </Box>
    </>
  );
};

export default UploadImageButton;
