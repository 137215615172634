/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Button, Dialog, Box, Typography } from '@material-ui/core';
import { useMutation } from 'react-query';
import {
  getHumanReadableInternalSerialNumber,
  getExpirationDate,
  isAssetExpired,
} from '../../../../utils/inventory.utils';
import Scanner from '../../../common/Scanner';
import { dispatch } from '../../../../rematch';
import compile from '../../../../utils/toastMessagesCompiler';
import { IAsset } from '../../../../interfaces/reconciliation.interfaces';
import { pluralize } from '../../../../utils/pluralize';

import Asset from '../../../../services/Asset';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    padding: '15px',
    margin: '10px 25px 5px 5px',
  },
  buttonRecover: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: '300px',
    marginBottom: '5px',
    '$:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonBack: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    width: '300px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '-0.0428571px',
    color: '#393B3F',
    mixBlendMode: 'normal',
    opacity: 0.4,
  },
  value: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '23px',
    color: '#393B3F',
  },
  container: {
    width: '617px',
    margin: '30px',
    overflow: 'hidden',
  },
  dialog: {
    borderRadius: '10px',
    maxWidth: '700px',
    height: '320px',
  },
  dialogTitle: {
    textAlign: 'center',
    paddingBottom: '10px',
  },
}));

const RecoverAsset: React.FC = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const { isSearchingAsset } = useSelector((state: any) => state.scanner);
  const [asset, setAsset] = useState<IAsset | null>(null);
  const scannerInputRef = useRef<HTMLInputElement>(null);
  const { userId } = useSelector(({ auth }: any) => auth);
  const assetRecoverMutation = useMutation(Asset.recoverAsset);

  const focusScan = () => {
    setTimeout(() => {
      if (scannerInputRef && scannerInputRef.current) {
        scannerInputRef.current.focus();
      }
    }, 100);
  };

  useEffect(() => {
    focusScan();
  }, [openModal, scannerInputRef]);

  const onScan = (newAsset: IAsset) => {
    if (newAsset.id) {
      if (!newAsset.markedAsEmpty && newAsset.status === 'in_stock') {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('product_identifier.active_product'),
            type: 'error',
          },
        });
      } else if (isAssetExpired(newAsset)) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.product_expired'),
            type: 'error',
          },
        });
      } else {
        setAsset(newAsset);
      }
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.element_not_found', { element: 'Product' }),
          type: 'error',
        },
      });
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setAsset(null);
  };

  const onRecover = async () => {
    if (!asset?.id) {
      return;
    }

    const success = await assetRecoverMutation.mutateAsync(asset.id);

    if (success) {
      setAsset(null);
      setOpenModal(false);
      dispatch({
        type: 'inventoryDefault/getDashboardLists',
        payload: userId,
      });
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            action: 'Recovered',
            element: 'Asset',
          }),
        },
      });
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'Recovering',
            element: 'Asset',
          }),
          type: 'error',
        },
      });
    }
  };

  return (
    <>
      <Button variant="outlined" size="small" className={classes.button} onClick={() => setOpenModal(true)}>
        Recover asset
      </Button>

      <Dialog open={openModal} onClose={onCloseModal} classes={{ paper: classes.dialog }}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
          className={classes.container}
        >
          <Box>
            <Typography variant="h5" color="textPrimary" className={classes.dialogTitle}>
              Recover asset
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" alignContent="center" mb="12px">
            <Box mb={1}>
              {asset?.id ? (
                <table>
                  <tr>
                    <td>ID:</td>
                    <td>{getHumanReadableInternalSerialNumber(asset.internalSerialNumber)}</td>
                  </tr>
                  <tr>
                    <td>Units:</td>
                    <td>
                      {asset.currentNumberOfUnits} {pluralize(asset.currentNumberOfUnits, asset.assetLabel || 'vial')}
                    </td>
                  </tr>
                  <tr>
                    <td>Lots:</td>
                    <td>{asset.lot}</td>
                  </tr>
                  <tr>
                    <td>Expiry Date:</td>
                    <td>{getExpirationDate(asset).format('MM/DD/YYYY')}</td>
                  </tr>
                </table>
              ) : (
                <Typography>{isSearchingAsset ? 'Loading...' : 'Find an asset to recover'}</Typography>
              )}
            </Box>
          </Box>
          <Box>
            {asset?.id ? (
              <>
                <Box display="flex" justifyContent="center" alignContent="center">
                  <Button className={classes.buttonRecover} onClick={() => onRecover()}>
                    Recover
                  </Button>
                </Box>
                <Box display="flex" justifyContent="center" alignContent="center">
                  <Button className={classes.buttonBack} variant="outlined" size="small" onClick={() => setAsset(null)}>
                    Back
                  </Button>
                </Box>
              </>
            ) : (
              <Scanner isAssetRecover successCallback={onScan} scannerInputRef={scannerInputRef} />
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default RecoverAsset;
