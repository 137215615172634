import React from 'react';
import { createModel } from '@rematch/core';
import { RootModel } from './rootModel';

interface IPageModel {
  title: string | null;
  titleComponent: React.ReactElement | null;
  sidebarOpened: boolean;
}

export const page = createModel<RootModel>()({
  state: {
    title: '',
    titleComponent: null,
    sidebarOpened: window.innerWidth >= 880,
  } as IPageModel,
  reducers: {
    setTitle(state, payload: string | null): IPageModel {
      return { ...state, title: payload, titleComponent: null };
    },
    setTitleComponent(state, payload: React.ReactElement | null): IPageModel {
      return { ...state, titleComponent: payload, title: null };
    },
    setSidebarOpened(state, payload: boolean): IPageModel {
      return { ...state, sidebarOpened: payload };
    },
  },
});
