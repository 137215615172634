/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import { Grid, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import formatDate from '../../../utils/formatDate';

const RIGHT = 'right';
const LEFT = 'left';

type PropsType = {
  content: string;
  author?: string;
  createdAt?: Date;
  dateFormat?: string;
  autoFocus?: boolean;
  position?: typeof RIGHT | typeof LEFT;
};

const useStyles = makeStyles((theme) => ({
  message: { padding: '.5rem', borderRadius: '15px' },
  messageRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  messageLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  floatRight: {
    float: 'right',
    textAlign: 'end',
    backgroundColor: theme.palette.grey[100],
  },
  floatLeft: {
    float: 'left',
    textAlign: 'start',
    backgroundColor: theme.palette.grey[200],
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
}));

const Message = ({
  content,
  author = '',
  createdAt,
  dateFormat = 'L H:mm',
  autoFocus = false,
  position = RIGHT,
}: PropsType) => {
  const classes = useStyles();
  const formattedDate = createdAt ? formatDate(createdAt, dateFormat) : '';

  const messageRef = React.createRef<any>();
  useEffect(() => {
    if (autoFocus) {
      messageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const authorDate = (
    <>
      <Typography
        style={{ display: 'inline', textTransform: 'capitalize' }}
        component="span"
        variant="subtitle1"
        color="textPrimary"
      >
        {author}
      </Typography>
      {` - ${formattedDate}`}
    </>
  );

  return (
    <ListItem ref={messageRef} style={{ display: 'flex' }} autoFocus={autoFocus}>
      <Grid container className={position === RIGHT ? `${classes.messageRight}` : `${classes.messageLeft}`}>
        <ListItemText secondary={authorDate} />
        <div className={classes.break} />
        <Grid item className={`${position === RIGHT ? classes.floatRight : classes.floatLeft} ${classes.message} `}>
          <ListItemText primary={content} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default Message;
