/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, FormControlLabel, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { SwitchCustom, ButtonFooter, useStyles } from '../supplyReceipt.styles';
import ModalDeleteDraft from '../ModalDeleteDraft';

const SupplyReceiptFooter = ({
  hasPractitioner,
  editionDisabled,
  handleSignature,
  onSaveChanges,
  autoSaveForm,
}: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { supplyReceiptId }: any = useParams();
  const { productsSelected, supplyReceipt, isLoadingUpdateSupplyReceipt } = useSelector(
    (state: any) => state.newSupplyReceipt
  );

  const goSupplyReceipts = () => {
    history.push('/administrator/supply-receipts');
  };

  return (
    <Box width="100%" paddingX={2} height="67px" display="flex" alignItems="center">
      <Box mr="auto" display="flex">
        <Box mr="auto" display="flex" className={classes.buttonPadding}>
          <ButtonFooter data-cy="cancelBtn" onClick={goSupplyReceipts} variant="outlined">
            Cancel
          </ButtonFooter>
        </Box>

        {!!supplyReceiptId && !editionDisabled && (
          <Box mr="auto" display="flex" className={classes.buttonPadding}>
            <ModalDeleteDraft onSuccess={goSupplyReceipts} />
          </Box>
        )}
      </Box>
      <Box display="flex">
        <Box mr="10px" display="flex">
          <FormControlLabel
            control={
              <SwitchCustom
                data-cy="forceSignature"
                checked={supplyReceipt.forceSignature}
                disabled={!hasPractitioner || editionDisabled}
                onChange={() => handleSignature(!supplyReceipt.forceSignature)}
                name="forceSignature"
              />
            }
            name="forceSignature"
            labelPlacement="start"
            label="Force Signature"
          />
        </Box>

        <Box mr="auto" display="flex" className={classes.buttonPadding}>
          {supplyReceipt.status === 'draft' && (
            <Box mr="auto" display="flex">
              <ButtonFooter
                data-cy="saveBtn"
                onClick={() => autoSaveForm({ supplyReceipt, productsSelected, showMsg: true })}
                variant="outlined"
                disableElevation
              >
                Save Draft
              </ButtonFooter>
            </Box>
          )}
        </Box>

        <Box mr="auto" display="flex" className={classes.buttonPadding}>
          {!editionDisabled && (
            <ButtonFooter
              data-cy="sendBtn"
              onClick={onSaveChanges}
              disabled={isLoadingUpdateSupplyReceipt}
              variant="contained"
              color="primary"
              disableElevation
            >
              {isLoadingUpdateSupplyReceipt ? (
                <CircularProgress size={25} color="secondary" />
              ) : supplyReceiptId ? (
                'Update and send'
              ) : (
                'Create and send'
              )}
            </ButtonFooter>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SupplyReceiptFooter;
