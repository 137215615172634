import {
  Accordion,
  AccordionDetails,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import {
  IconLeftAccordionSummary,
  TableCellHeader,
  useStyles,
} from 'src/pages/DashboardAdministrator/servicesListPage.styles';
import React, { useEffect, useState } from 'react';

import { ActiveChip } from 'src/components/common/Global/CustomChip';
import IconCross from 'src/components/common/IconCross';
import formatDate from 'src/utils/formatDate';
import { useDeleteSpecialOffer, useUpdateSpecialOfferStatus } from 'src/hooks/queries/useSpecialOffers';
import { ISpecialOffer, OfferDiscount } from 'src/services/SpecialOffers';
import { formatCurrency } from 'src/utils/formatNumber';
import PercentChip from 'src/components/common/PercentChip';
import { DATETIME_FORMAT } from './utils';

const TableHeaderSpecialOffer = () => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.grayHeader}>
        <TableCellHeader>Product Name</TableCellHeader>
        <TableCellHeader>Market Price</TableCellHeader>
        <TableCellHeader>Discount</TableCellHeader>
        <TableCellHeader>Final Price</TableCellHeader>
        <TableCellHeader>Quantity</TableCellHeader>
        <TableCellHeader>Total</TableCellHeader>
      </TableRow>
    </TableHead>
  );
};

type Props = {
  autoOpen: boolean;
  special: ISpecialOffer;
  setSelectedSpecial: (special: any) => void;
  setShowLoadingSpinner: (show: boolean) => void;
  toggleEditModal: () => void;
};

export const SpecialOfferRow = ({
  autoOpen,
  special,
  setSelectedSpecial,
  setShowLoadingSpinner,
  toggleEditModal,
}: Props) => {
  const classes = useStyles();
  const { name, active, expirationDate, note, createdAt } = special;
  const discounts = special.discounts || [];

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  const { mutateAsync: deleteSpecial } = useDeleteSpecialOffer();
  const { mutateAsync: disableSpecial } = useUpdateSpecialOfferStatus();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setMenuAnchor(menuAnchor ? null : event.currentTarget);

  const handleDeleteSpecialOrder = async (): Promise<void> => {
    setMenuAnchor(null);
    setShowLoadingSpinner(true);
    await deleteSpecial(special.id);
    setShowLoadingSpinner(false);
  };

  const handleDisableSpecialOrder = async (): Promise<void> => {
    setMenuAnchor(null);
    setShowLoadingSpinner(true);
    await disableSpecial(special.id);
    setShowLoadingSpinner(false);
  };

  const handleEditSpecialOrder = async (): Promise<void> => {
    setMenuAnchor(null);
    setShowLoadingSpinner(true);
    toggleEditModal();
    setSelectedSpecial(special);
  };

  useEffect(() => {
    setExpanded(autoOpen);
  }, []);

  const expandIcon = (
    <IconButton size="small" onClick={() => setExpanded(!expanded)} data-testid={`special offer expand ${special.id}`}>
      <IconCross open={expanded} />
    </IconButton>
  );

  return (
    <Accordion expanded={expanded} style={{ width: '100%', margin: '0 0 1rem 0' }}>
      <IconLeftAccordionSummary expandIcon={expandIcon}>
        <Box className={classes.accordionSummary}>
          <Box display="flex" flexDirection="column" width="156px">
            <Typography className={classes.accordionTag}>Special Name</Typography>
            <Typography className={classes.orderDate}>{name}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.accordionTag}>Products</Typography>
            <Typography className={classes.orderDate}>{discounts.length}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.accordionTag}>Creation Date</Typography>
            <Typography className={classes.orderDate}>{formatDate(createdAt, DATETIME_FORMAT)}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.accordionTag}>Expiration Date</Typography>
            <Typography className={classes.orderDate}>{formatDate(expirationDate, DATETIME_FORMAT)}</Typography>
          </Box>
          <ActiveChip active={active} />
          <IconButton onClick={handleMenuClick} className={classes.orderMenu}>
            {menuAnchor ? <CloseIcon /> : <MoreVertIcon />}
          </IconButton>
          <Menu
            open={Boolean(menuAnchor)}
            anchorEl={menuAnchor}
            onClose={handleMenuClick}
            PaperProps={{ className: classes.orderMenuOpen }}
          >
            <MenuItem className={classes.orderMenuItem} onClick={handleEditSpecialOrder}>
              Edit
            </MenuItem>
            <MenuItem className={classes.orderMenuItem} onClick={handleDisableSpecialOrder}>
              {active ? 'Disable' : 'Enable'}
            </MenuItem>
            <MenuItem className={`${classes.orderMenuItem} ${classes.delete}`} onClick={handleDeleteSpecialOrder}>
              Delete
            </MenuItem>
          </Menu>
        </Box>
      </IconLeftAccordionSummary>

      <AccordionDetails style={{ flexDirection: 'column' }}>
        <TableContainer data-testid={`order details ${special.id}`}>
          <Table>
            <TableHeaderSpecialOffer />
            <TableBody className={classes.historyRow}>
              {discounts.map((product) => (
                <SpecialOfferSubRow {...product} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {note && (
          <Box>
            <Paper className={`${classes.orderNotes} ${classes.noBorder}`} variant="outlined">
              <Typography>{note}</Typography>
            </Paper>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const SpecialOfferSubRow = (props: OfferDiscount) => {
  const { orderProductName, orderProductMarketPrice, quantity, discount } = props;

  const finalPrice = orderProductMarketPrice - discount;

  return (
    <TableRow>
      <TableCell>{orderProductName}</TableCell>
      <TableCell>{formatCurrency(orderProductMarketPrice)}</TableCell>
      <TableCell>
        {formatCurrency(discount)}
        <PercentChip number={Math.round((discount * 100) / orderProductMarketPrice)} />
      </TableCell>
      <TableCell>{formatCurrency(finalPrice)}</TableCell>
      <TableCell>{quantity}</TableCell>
      <TableCell>{formatCurrency(finalPrice * quantity)}</TableCell>
    </TableRow>
  );
};
