import React from 'react';

import { Box, TableCell, TableRow } from '@material-ui/core';

import ButtonAhref from '../../common/ButtonAhref';
import { ButtonStatus } from '../../common/TableStyles/ButtonStatus';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { useRowStyles } from './ReconciliationReports.styles';

const handleReconciliationReportStatus = (status: string) => {
  switch (status) {
    case 'pending_signature':
      return <ButtonStatus style={{ backgroundColor: '#e7e7e7' }}>Pending Signature</ButtonStatus>;
    case 'pending_review':
      return <ButtonStatus style={{ backgroundColor: '#fac980', color: '#393b3f' }}>Pending Review</ButtonStatus>;
    case 'closed':
      return <ButtonStatus style={{ background: '#d1f1bd' }}>Closed</ButtonStatus>;
    default:
      return null;
  }
};

export interface ReconciliationReportsRowProps {
  id: number;
  practitionerName: string;
  date: string;
  status: string;
}

const ReconciliationReportsRow: React.FC<ReconciliationReportsRowProps> = (props) => {
  const { id, practitionerName, date, status } = props;
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root} style={{ backgroundColor: 'white' }}>
        <TableCellCustom>{practitionerName}</TableCellCustom>
        <TableCellCustom width="100">
          <Box color="#000" component="span">
            {date}
          </Box>
        </TableCellCustom>
        <TableCell>
          <Box display="flex" justifyContent="space-between">
            {handleReconciliationReportStatus(status)}
            <ButtonAhref text="VIEW REPORT" href={`/administrator/reconciliation-reports/${id}`} />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReconciliationReportsRow;
