import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useCreateDocument, useRemoveDocument, useUpdateDocument } from 'src/hooks/queries/useDocuments';
import { Close as CloseIcon, Delete as DeleteIcon, OpenInNew } from '@material-ui/icons';
import { cutLongName } from 'src/utils/documents.utils';
import { useMedspaAdmins } from 'src/hooks/queries/useMedspaAdmins';
import { useStyles } from './Documents.styles';
import { IDocument } from '../../interfaces/documents.interface';
import { documentCreateSchema } from './schemas';
import { SelectCustom } from '../common/Select';
import { IPractitioner } from '../../interfaces/IPractitioner';
import { queryClient } from '../../initializers/queryClient';
import { DOCUMENTS } from '../../constants/reactQuery.keys';
import { ReactComponent as DocumentIcon } from '../../assets/images/document.svg';
import DragAndDropComponent from '../DragAndDrop';

const CustomSelection = withStyles(() => ({
  root: {
    width: '100%',
  },
}))(SelectCustom);

interface DocumentsModalProps {
  open: boolean;
  onClose: () => void;
  document?: IDocument;
  editMode?: boolean;
  practitioners?: IPractitioner[];
}

interface FormData {
  documentName: string;
  practitionerId?: string;
  userGroupAdminId?: string;
}

interface TabData {
  practitionerMode: boolean;
  toggleMode: () => void;
}

const AssignmentTabs = ({ practitionerMode, toggleMode }: TabData) => {
  const classes = useStyles();
  return (
    <Box className={classes.tabBar}>
      <Button
        onClick={() => !practitionerMode && toggleMode()}
        data-active={practitionerMode}
        data-cy="assignPractitioner"
      >
        Practitioners
      </Button>
      <Button
        onClick={() => practitionerMode && toggleMode()}
        data-active={!practitionerMode}
        data-cy="assignMedspaAdmin"
      >
        Medspa Admin
      </Button>
    </Box>
  );
};

const DocumentsModal = ({ open, onClose, document, practitioners = [], editMode = false }: DocumentsModalProps) => {
  const classes = useStyles();
  const createDocumentMutation = useCreateDocument();
  const updateDocumentMutation = useUpdateDocument();
  const removeDocumentMutation = useRemoveDocument();
  const [selectedPractitionerId, setSelectedPractitionerId] = useState('');
  const [selectedUserGroupAdminId, setSelectedUserGroupAdminId] = useState('');
  const [practitionerMode, setPractitionerMode] = useState<boolean>(true);
  const [documentFile, setDocumentFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState<string>('');
  const [userErrorMessage, setUserErrorMessage] = useState<string>('');

  const { data: medspaAdmins, isLoading: isLoadingMedspaAdmins } = useMedspaAdmins();

  const { errors, handleSubmit, setValue, control, reset, watch } = useForm<FormData>({
    resolver: yupResolver(documentCreateSchema),
    defaultValues: {
      documentName: '',
      practitionerId: '',
      userGroupAdminId: '',
    },
  });

  const editDocument = (data: FormData) =>
    updateDocumentMutation.mutateAsync({ ...data, documentId: document?.id as string });

  const createDocument = (data: FormData) =>
    createDocumentMutation.mutateAsync({ ...data, documentFile: documentFile as File });

  const removeDocument = () => removeDocumentMutation.mutateAsync({ documentId: document?.id as string });

  const assignedPractitioner = watch('practitionerId');
  const assignedAdmin = watch('userGroupAdminId');
  const validUserAssignment = !!assignedPractitioner || !!assignedAdmin;
  const validSubmission = editMode || (documentFile && validUserAssignment);

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    if (validSubmission) {
      if (editMode) {
        await editDocument(data);
      } else {
        await createDocument(data);
      }
      queryClient.invalidateQueries([DOCUMENTS]);
      resetModal();
    } else {
      !documentFile && setFileErrorMessage('File is a required field.');
      !validUserAssignment && setUserErrorMessage('Either Practitioner ID or User Group Admin ID must be provided.');
    }
    setIsLoading(false);
  };

  const getPractitionerOptions = () => {
    const filteredPractitioners = practitioners.filter((practitioner: IPractitioner) => !practitioner.inactive);
    return ((editMode ? practitioners : filteredPractitioners) as IPractitioner[]).map(
      ({ id, firstName, lastName }: IPractitioner) => (
        <MenuItem key={id} value={id}>
          {`${firstName} ${lastName}`}
        </MenuItem>
      )
    );
  };

  const handleFileInput = (file: File[]) => {
    setDocumentFile(file?.[0]);
    setFileErrorMessage('');
  };

  useEffect(() => {
    if (document && editMode) {
      setValue('practitionerId', document.practitionerId ?? '');
      setValue('userGroupAdminId', document.userGroupAdminId ?? '');
      setValue('documentName', document.name);
    } else {
      setValue('practitionerId', '');
      setValue('documentName', '');
      setValue('userGroupAdminId', '');
    }
  }, [document, open]);

  useEffect(() => {
    editMode && setPractitionerMode(!document?.userGroupAdminId);
  }, [editMode]);

  useEffect(() => {
    !!userErrorMessage && validUserAssignment && setUserErrorMessage('');
  }, [assignedPractitioner, assignedAdmin]);

  const handleClick = () => {
    const newTab = window.open(document?.documentPdfUrl, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  const handleIconButtonClick = async (event: any) => {
    event.stopPropagation();
    await removeDocument();
  };

  const getHeading = () => (!editMode ? 'New document' : `Edit document ${document?.deletedAt ? '(deleted)' : ''}`);
  const resetModal = () => {
    onClose();
    reset();
    setDocumentFile(null);
    setFileErrorMessage('');
    setUserErrorMessage('');
  };

  const toggleUserSelectors = () => {
    if (!editMode) {
      setSelectedPractitionerId('');
      setSelectedUserGroupAdminId('');
      setValue('practitionerId', '');
      setValue('userGroupAdminId', '');
      setPractitionerMode(!practitionerMode);
    }
  };

  return (
    <Modal open={open} onClose={resetModal}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.modal}>
        <Box className={classes.modalHeader}>
          <h1 title={getHeading()}>{getHeading()}</h1>
          <IconButton className={classes.closeButton} onClick={resetModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.formContainer}>
          <FormControl size="medium" fullWidth variant="outlined">
            <InputLabel htmlFor="documentName" error={!!errors?.documentName} className="" />
            <Controller
              control={control}
              name="documentName"
              rules={{ required: 'Name is required' }}
              disabled={!!document?.deletedAt}
              render={({ onChange, value }) => (
                <TextField
                  style={{ width: '100%', height: '46px' }}
                  data-cy="documentName"
                  name="documentName"
                  label="Document name"
                  onChange={onChange}
                  value={value}
                  variant="outlined"
                  disabled={!!document?.deletedAt}
                />
              )}
            />
            {errors?.documentName && <FormHelperText error>{errors?.documentName?.message}</FormHelperText>}
          </FormControl>
          <Box width="100%">
            <AssignmentTabs practitionerMode={practitionerMode} toggleMode={toggleUserSelectors} />
            {practitionerMode ? (
              <FormControl size="small" fullWidth variant="outlined">
                <Controller
                  control={control}
                  name="practitionerId"
                  as={
                    <CustomSelection
                      style={{ width: '100%', height: '46px' }}
                      id="practitionerId"
                      name="practitionerId"
                      variant="outlined"
                      onChange={(e: any) => {
                        setValue('practitionerId', e.target.value);
                        setSelectedPractitionerId(e.target.value);
                      }}
                      disabled={editMode || !practitioners.length}
                      value={selectedPractitionerId}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select a Practitioner
                      </MenuItem>
                      {getPractitionerOptions()}
                    </CustomSelection>
                  }
                />
                {!!userErrorMessage && <FormHelperText error>{userErrorMessage}</FormHelperText>}
              </FormControl>
            ) : (
              <FormControl size="small" fullWidth variant="outlined">
                <Controller
                  control={control}
                  name="userGroupAdminId"
                  as={
                    <CustomSelection
                      style={{ width: '100%', height: '46px' }}
                      id="userGroupAdminId"
                      name="userGroupAdminId"
                      variant="outlined"
                      onChange={(e: any) => {
                        setValue('userGroupAdminId', e.target.value);
                        setSelectedUserGroupAdminId(e.target.value);
                      }}
                      disabled={editMode || isLoadingMedspaAdmins}
                      value={selectedUserGroupAdminId}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select a Medspa Admin
                      </MenuItem>
                      {medspaAdmins?.map(({ id, firstName, lastName }) => (
                        <MenuItem key={id} value={id}>
                          {firstName} {lastName}
                        </MenuItem>
                      ))}
                    </CustomSelection>
                  }
                />
                {!!userErrorMessage && <FormHelperText error>{userErrorMessage}</FormHelperText>}
              </FormControl>
            )}
          </Box>
          {editMode ? (
            <Box className={classes.documentContainer}>
              <Box className={classes.documentNameContainer}>
                <DocumentIcon />
                <Tooltip title={document?.documentPdfFileName as string}>
                  <p>{cutLongName(document?.documentPdfFileName as string)}</p>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="Open document in new tab">
                  <IconButton onClick={handleClick} className={classes.deleteButton}>
                    <OpenInNew />
                  </IconButton>
                </Tooltip>
                {!document?.deletedAt && (
                  <Tooltip title="Remove this document">
                    <IconButton onClick={handleIconButtonClick} className={classes.deleteButton}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          ) : (
            <Box width="100%">
              <DragAndDropComponent acceptedFileType="application/pdf" onFileChange={handleFileInput} />
              {fileErrorMessage !== '' && <FormHelperText error>{fileErrorMessage}</FormHelperText>}
            </Box>
          )}
          <Button
            disabled={isLoading || !!document?.deletedAt}
            type="submit"
            className={!document?.deletedAt ? classes.primaryButton : classes.primaryButtonDisabled}
          >
            {isLoading && <CircularProgress style={{ color: 'white' }} size={30} />}
            <p>{editMode ? 'Update document' : 'Upload document'}</p>
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default DocumentsModal;
