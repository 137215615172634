import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  textContainer: {
    padding: '20px',
    borderRadius: '10px',
    width: '660px',
  },
  title: {
    fontFamily: 'Messina Sans SemiBold',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  text: {
    color: '#555',
    lineHeight: 1.6,
    marginBottom: '10px',
    alignItems: 'center',
  },
  button: {
    height: '36px',
    fontFamily: 'Messina Sans SemiBold',
    backgroundColor: '#fff',
    color: '#12574d',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'inline-flex',
    padding: '5px 16px',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(58, 59, 63, 0.04)',
    },
  },
  mappingDialog: {
    padding: '1.5rem',
  },
  mappingDialogActions: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F2F2F2',
    alignItems: 'start',
    padding: '1rem 100px',
    margin: '8px 24px;',
  },
  errorTitle: {
    color: '#12574d',
  },
}));
