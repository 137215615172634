import React, { useState } from 'react';
import { Card, CardContent, CircularProgress, Modal } from '@material-ui/core';
import { Launch, Create as CreateIcon } from '@material-ui/icons';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { IconButton } from './IconButton';
import { Button as ModalButton } from './Button';
import { useStyles } from './UploadImage.styles';
import ButtonImage from './ButtonImage';

interface IUploadImage {
  imageUrl: string | undefined;
  valueToSet: string;
  label: string;
  setValue: any;
  isFetched?: boolean;
  errors: FieldErrors<FieldValues>;
}

const UploadImage = (props: IUploadImage) => {
  const classes = useStyles();
  const { imageUrl, valueToSet, label, setValue, isFetched = true, errors } = props;

  const [selectedImage, setSelectedImage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [tempImage, setTempImage] = useState<string | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [changeImage, setChangeImage] = useState<boolean>(false);

  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => {
          setLoadingImage(true);
        };
        reader.onloadend = () => {
          setValue(valueToSet, reader.result);
          setTempImage(String(reader.result));
          setSelectedImage(true);
          setLoadingImage(false);
        };
      }
    }
  };
  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const viewOriginal = () => {
    window.open(imageUrl, '_blank');
  };

  return (
    <>
      {loadingImage ? (
        <CircularProgress style={{ color: 'black', alignItems: 'center', margin: 'auto 20px' }} size={30} />
      ) : (
        <>
          {imageUrl && !changeImage ? (
            <div className={classes.profileImage}>
              <div onClick={() => openModal()} aria-hidden>
                <img src={imageUrl} alt={label} className={classes.image} />
              </div>
              <IconButton
                className={classes.changeImageButton}
                onClick={() => setChangeImage(true)}
                Icon={<CreateIcon />}
              />
            </div>
          ) : (
            <ButtonImage
              tempImage={tempImage}
              selectedImage={selectedImage}
              label={label}
              onClick={handleUploadImage}
              isFetched={isFetched}
              valueToSet={valueToSet}
              errors={errors}
            />
          )}
        </>
      )}
      <Modal open={isOpenModal} onClose={closeModal} className={classes.modal}>
        <Card style={{ width: 'fit-content' }}>
          <CardContent className={classes.cardContentPhoto}>
            <img className={classes.photo} src={imageUrl} alt="item" />
          </CardContent>
          <CardContent className={classes.cardContentButtons}>
            <div className={classes.buttons}>
              <ModalButton title="Close" className={classes.closeButton} onClick={closeModal} />
              <IconButton
                title="View Original"
                onClick={viewOriginal}
                className={classes.ViewOriginalButton}
                Icon={<Launch />}
              />
            </div>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};

export default UploadImage;
