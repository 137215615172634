import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  appliedDiscountContainer: {
    border: '1px solid #B7B7B7',
    margin: '0 10px 25px 30px',
    padding: '15px',
  },
  appliedDiscountSection: {
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    border: '1px solid #393B3F1F',
    backgroundColor: '#FFFFFF',
    color: '#12574D',
    width: '114px',
    height: '36px',
    marginRight: '15px',
  },
  discountLineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Messina Sans Regular',
    fontSize: '13px',
    alignItems: 'center',
  },
}));
