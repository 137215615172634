import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  TextAreaStyles: {
    width: '100%',
    minHeight: '75px',
    maxHeight: '98px',
    borderRadius: '3px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    padding: '13px 14px',
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    "&:focus": {
      border: '2px solid #12574d',
      outline: 'none !important'
    }
  }
}));
