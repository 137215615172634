import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  patientListContainer: {
    margin: '2rem',
    maxHeight: '1000px',
  },
  tableGeneral: {
    position: 'relative',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'hidden',
  },
  infiScrollContainer: {
    overflow: 'auto',
    height: '84.7vh',
    marginTop: '10px',
  },
  viewSummaryButton: {
    zIndex: 10,
  },
  completed: {
    color: '#005A4E',
    background: '#E6F9DA',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
  },
  pending: {
    color: '#704822',
    background: '#F8DCC1',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
  },
  search: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '14px',
  },
  filterContainer: {
    justifyContent: 'space-between',
    margin: '1rem 2rem',
  },
  formControl: {
    width: '150px',
    marginLeft: '21px',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    margin: '1rem 2rem',
    fontFamily: 'Messina Sans Bold',
  },
  subHeading: {
    margin: '-1rem 2rem 0',
    color: '#999999',
  },
  patientRow: {
    cursor: 'pointer',
  },
  providerPatientList: {
    width: '100%',
    minWidth: '800px',
  },
}));
