import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { AlleTender } from './AlleTender';
import { CareCreditTender } from './CareCreditTender';
import { AspireTender } from './AspireTender';
import { ReferralCredit } from './ReferralCredit';
import { XperienceTender } from './XperienceTender';
import { Tender, TendersObject, TenderTypes } from '../../../interfaces/checkout.interfaces';
import { dispatch } from '../../../rematch';
import Points from '../../../components/Points';
import compile from '../../../utils/toastMessagesCompiler';
// import { getGaldermaServicesSelected } from '../../../utils/galderma.utils';
import {
  CARECREDIT_LIMIT_FOR_DISCOUNT,
  // SQUARE_STATUS
} from '../../../constants/checkout.constants';

const useStyles = makeStyles(() => ({
  mainSection: {
    display: 'flex',
    padding: '18px 0 0 0',
  },
  otherTenderTitle: {
    borderRight: '8px solid #E7EEED',
    display: 'flex',
    alignItems: 'center',
    padding: '0 35px',
    textAlign: 'right',
    fontSize: '18px',
    color: '#3A3B3F',
    fontFamily: 'Messina Sans Light',
  },
  tendersSection: {
    width: '100%',
    paddingLeft: '20px',
  },
}));

interface Props {
  // eslint-disable-next-line no-unused-vars
  setTenders: (tenders: any) => void;
  // eslint-disable-next-line no-unused-vars
  setReferrals: (referralsIds: number[]) => void;
  // eslint-disable-next-line no-unused-vars
  setReferralsGroupIndexes: (referralsIds: number[]) => void;
  totalTenderValue: number;
  toPay: number;
  showGaldermaBox: boolean;
  setShowGaldermaBox: (val: boolean) => void;
  total: number;
  discounts: number;
  onlyFutureUseCredit: boolean;
}

export const OtherTenders = ({
  setTenders,
  setReferrals,
  setReferralsGroupIndexes,
  totalTenderValue,
  toPay,
  total,
  showGaldermaBox,
  setShowGaldermaBox,
  discounts,
  onlyFutureUseCredit,
}: Props) => {
  const classes = useStyles();
  const { hasReferred, isAdvocate } = useSelector(({ patient }: any) => patient);
  const { checkout } = useSelector((store: any) => store.newServiceVisit);
  const { isLoadingPatientPoints, patientPoints } = useSelector(
    ({ patientPointCertificates }: any) => patientPointCertificates
  );
  const { services } = useSelector((store: any) => store.common);
  const { totalServicesUnits } = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const { galdermaProducts } = useSelector(({ patientPointCertificates }: any) => patientPointCertificates);

  const [aspireVoucher, setAspireVoucher] = useState<string>('');
  const [aspireValue, setAspireValue] = useState<string>('');
  const [brilliantVoucher, setBrilliantVoucher] = useState<string>('');
  const [xperienceVoucher, setXperienceVoucher] = useState<string>('');

  const [brilliantValue, setBrilliantValue] = useState<string>('');
  const [careCreditVoucher, setCareCreditVoucher] = useState<string>('');
  const [careCreditValue, setCareCreditValue] = useState<string>('');
  const [xperienceValue, setXperienceValue] = useState<string>('');

  const getTender = (type: TenderTypes, voucher: string, value: number): Tender => ({ type, voucher, value });

  useEffect(() => {
    // const hasAnyAspireProduct = getGaldermaServicesSelected(
    // galdermaProducts, totalServicesUnits, services).length > 0;
    // if ((checkout.transactionStatus === SQUARE_STATUS.OK || checkout.galdermaApplied) && hasAnyAspireProduct) {
    //   // reset ASPIRE voucher and value if checkout fail
    // }
    if (checkout.aspireVoucher) {
      setAspireVoucher(checkout.aspireVoucher);
    }
    if (checkout.aspireValue) {
      setAspireValue(checkout.aspireValue);
    }
    if (checkout.brilliantVoucher) {
      setBrilliantVoucher(checkout.brilliantVoucher);
    }
    if (checkout.brilliantValue) {
      setBrilliantValue(checkout.brilliantValue);
    }
    if (checkout.careCreditValue) {
      setCareCreditValue(checkout.careCreditValue);
    }
    if (checkout.careCreditVoucher) {
      setCareCreditVoucher(checkout.careCreditVoucher);
    }
    if (checkout.xperienceValue) {
      setXperienceValue(checkout.xperienceValue);
    }
    if (checkout.xperienceVoucher) {
      setXperienceVoucher(checkout.xperienceVoucher);
    }
  }, [checkout, services, totalServicesUnits, galdermaProducts]);

  useEffect(() => {
    const tenders: TendersObject = {};

    if (+aspireValue !== 0) {
      tenders.aspire = getTender('aspire', aspireVoucher, +aspireValue);
    }
    if (+brilliantValue !== 0) {
      tenders.brilliant = getTender('brilliant', brilliantVoucher, +brilliantValue);
    }
    if (+careCreditValue !== 0) {
      tenders.care_credit = getTender('careCredit', careCreditVoucher, +careCreditValue);
    }
    if (+xperienceValue !== 0) {
      tenders.xperience = getTender('xperience', xperienceVoucher, +xperienceValue);
    }

    setTenders(tenders);
  }, [
    aspireVoucher,
    aspireValue,
    brilliantVoucher,
    brilliantValue,
    careCreditVoucher,
    careCreditValue,
    xperienceValue,
    xperienceVoucher,
  ]);

  let enableCareCreditDiscount = true;
  if (discounts > 0) {
    enableCareCreditDiscount = total >= CARECREDIT_LIMIT_FOR_DISCOUNT;
  }

  if (onlyFutureUseCredit) {
    enableCareCreditDiscount = false;

    if (
      !enableCareCreditDiscount &&
      (careCreditVoucher.trim().length > 0 || String(careCreditValue).trim().length > 0)
    ) {
      setCareCreditValue('');
      setCareCreditVoucher('');
    }
  }

  useEffect(() => {
    /* Effect used to validate when we have tender discounts and is applied another discount */
    if (toPay < 0) {
      setAspireValue('');
      setBrilliantValue('');
      setCareCreditValue('');
      setXperienceValue('');

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('checkout.discounts_removed_other_tenders'),
          type: 'info',
        },
      });
    }

    if (
      !enableCareCreditDiscount &&
      (careCreditVoucher.trim().length > 0 || String(careCreditValue).trim().length > 0)
    ) {
      setCareCreditValue('');
      setCareCreditVoucher('');

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('checkout.discounts_removed_other_tenders'),
          type: 'info',
        },
      });
    }
  }, [toPay]);

  const getReferrals = (referralsIds: number[], selectedReferralRedeemedIndexes: number[]): void => {
    setReferrals(referralsIds);
    setReferralsGroupIndexes(selectedReferralRedeemedIndexes);
  };

  const isTotalTenderValueValid = (inputObj: any, tenderValue: string): boolean => {
    const preTotal = totalTenderValue - (parseFloat(tenderValue) || 0);
    const totalTender = (inputObj.floatValue || 0) + preTotal;
    const totalPayAndTenderValue = Math.round((toPay + totalTenderValue + Number.EPSILON) * 100) / 100;
    const isValid = totalPayAndTenderValue - totalTender >= 0;

    if (!isValid) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.limit_exceeded'),
          type: 'info',
        },
      });
    }
    return isValid;
  };

  return (
    <div className={classes.mainSection}>
      <div className={classes.otherTenderTitle}>Other Tenders</div>
      <div className={classes.tendersSection}>
        <AlleTender
          brilliantVoucher={brilliantVoucher}
          setBrilliantVoucher={setBrilliantVoucher}
          brilliantValue={brilliantValue}
          setBrilliantValue={setBrilliantValue}
          isTotalTenderValueValid={isTotalTenderValueValid}
        />

        {/* eslint-disable-next-line no-constant-condition */}
        {true ? (
          <AspireTender
            aspireVoucher={aspireVoucher}
            setAspireVoucher={setAspireVoucher}
            aspireValue={aspireValue}
            setAspireValue={setAspireValue}
            isTotalTenderValueValid={isTotalTenderValueValid}
            onSwap={() => setShowGaldermaBox(!showGaldermaBox)}
          />
        ) : (
          <Points
            patientPoints={patientPoints}
            isLoading={isLoadingPatientPoints}
            galdermaTenderTypeAmount={+aspireValue}
            galdermaTenderTypeVoucher={aspireVoucher}
            setAspireVoucher={setAspireVoucher}
            setAspireValue={setAspireValue}
            onSwap={() => setShowGaldermaBox(!showGaldermaBox)}
          />
        )}

        <CareCreditTender
          careCreditVoucher={careCreditVoucher}
          setCareCreditVoucher={setCareCreditVoucher}
          careCreditValue={careCreditValue}
          setCareCreditValue={setCareCreditValue}
          isTotalTenderValueValid={isTotalTenderValueValid}
          enableCareCreditDiscount={enableCareCreditDiscount}
        />
        <XperienceTender
          xperienceVoucher={xperienceVoucher}
          setXperienceVoucher={setXperienceVoucher}
          xperienceValue={xperienceValue}
          setXperienceValue={setXperienceValue}
          isTotalTenderValueValid={isTotalTenderValueValid}
        />
        {(isAdvocate || hasReferred) && <ReferralCredit getReferrals={getReferrals} />}
      </div>
    </div>
  );
};
