import { IClientSummaryData, IReportQuery, IReportParams, TableHeaderSet } from '../interfaces/IPractitionerReport';
import Api from './Api';

const PractitionerReports = {
  async getReport({
    reportLabel,
    sortKey,
    searchTerms,
    page,
    asc,
    practitionerId,
  }: IReportParams): Promise<IClientSummaryData[] | null> {
    const urlPage = !!page || page === 0 ? `/${page}` : '';
    let url = `/practitioner_reports/${reportLabel}/${sortKey}/${searchTerms}/${asc ? 'asc' : 'desc'}${urlPage}`;
    if (practitionerId) {
      url += `?practitioner_id=${practitionerId}`;
    }
    const { success, data }: IReportQuery<IClientSummaryData[]> = await Api.get(url);
    return success && typeof data !== 'string' ? data : null;
  },

  async getColumns(reportLabel: string, practitionerId?: string): Promise<TableHeaderSet | null> {
    let url = `/practitioner_reports/${reportLabel}`;
    if (practitionerId) {
      url += `?practitioner_id=${practitionerId}`;
    }

    const { success, data }: IReportQuery<TableHeaderSet> = await Api.get(url);
    return success && typeof data !== 'string' ? data : null;
  },
};

export { PractitionerReports, PractitionerReports as default };
