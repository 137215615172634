import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { useStyles } from './StickyCard.styles';

type Props = {
  title: string;
  backgroundColorNote?: string;
  colorText?: string;
  minWidth?: string;
  children?: React.ReactNode;
  doctorNote?: boolean;
  footer?: React.ReactNode;
};

const StickyCard = ({ title, backgroundColorNote, colorText, children, minWidth, doctorNote, footer }: Props) => {
  const classes = useStyles();

  return (
    <Card
      className={`${classes.note} ${doctorNote ? classes.noteInside : ''}`}
      style={{ backgroundColor: backgroundColorNote, minWidth }}
    >
      <CardContent style={{ color: colorText, whiteSpace: 'pre-wrap', wordWrap: 'break-word', paddingBottom: '1rem' }}>
        <span className={classes.title}>{title}</span>
        {children}
        {footer && <div className={classes.footer}>{footer}</div>}
      </CardContent>
    </Card>
  );
};

StickyCard.defaultProps = {
  backgroundColorNote: '#fff',
  colorText: '#000',
  children: null,
  doctorNote: false,
  footer: null,
};

export default StickyCard;
