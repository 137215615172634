import ISuppliers from 'src/interfaces/ISuppliers';
import Api from './Api';
import IServices, { IServicesListParams } from '../interfaces/IServices';
import IPrice from '../interfaces/IPrice';
import { ServiceFormData } from '../interfaces/administrator.interface';
import { IGaldermaSku } from '../interfaces/galderma.interface';
import IServiceGroup from '../interfaces/IServiceGroup';

export const parseGaldermaSkus = (galdermaSKUs: IGaldermaSku[]) => {
  const SKUS = galdermaSKUs
    .map(({ sku, name }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_firstPart, location = ''] = name.split(' - ');
      return { [location]: sku };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  return JSON.stringify(SKUS);
};

const Services = {
  async getService(serviceId: number): Promise<IServices> {
    const { service } = await Api.get(`/v3/services/${serviceId}`);
    return service;
  },
  async getServices(customerId: number | null, serviceVisitId?: number): Promise<IServices[]> {
    const params: { [key: string]: number } = {};
    if (customerId) {
      params.customerId = customerId;
    }
    if (serviceVisitId) {
      params.serviceVisitId = serviceVisitId;
    }
    const { services } = await Api.get('/services', params);
    return services;
  },
  async getAllServices(paramsQuery: IServicesListParams): Promise<IServices[]> {
    const { services } = await Api.get('/v3/services', paramsQuery);
    return services;
  },
  async getDefaultServices(): Promise<IServices[]> {
    const { services } = await Api.get('/v3/services/default');
    return services;
  },
  async getAllServicesCount(paramsQuery: IServicesListParams): Promise<number> {
    const { count } = await Api.get('/v3/services/count', paramsQuery);
    return count;
  },
  async getServicePricings(serviceId?: number): Promise<IPrice[]> {
    const { history } = await Api.get(`/practitioners/practitioner_pricings`, {
      serviceId,
    });
    return history;
  },
  async updateServicePricing(serviceId: number, priceOverride?: number): Promise<void> {
    await Api.put('/practitioners/update_practitioner_pricing', {
      serviceId,
      priceOverride,
    });
  },
  async getServiceGroups(customerId?: number, serviceVisitId?: number): Promise<IServiceGroup[]> {
    const params: { [key: string]: number } = {};
    if (customerId) {
      params.customerId = customerId;
    }
    if (serviceVisitId) {
      params.serviceVisitId = serviceVisitId;
    }
    const { serviceGroups }: { serviceGroups: IServiceGroup[] } = await Api.get('/service-groups', params);
    return serviceGroups.sort((a, b) => (a.name < b.name ? -1 : 1));
  },
  async getDefaultServiceGroups(): Promise<IServiceGroup[]> {
    const { serviceGroups }: { serviceGroups: IServiceGroup[] } = await Api.get('/v3/service_groups/default');
    return serviceGroups.sort((a, b) => (a.name < b.name ? -1 : 1));
  },
  async createService(
    service: ServiceFormData
  ): Promise<{ success: boolean; service: ServiceFormData; message?: string }> {
    const parsedService = {
      ...service,
      currentCost: +service.currentCost,
      daysToExpireAfterOpened: +service.daysToExpireAfterOpened,
      followUpIntervalDays: +service.followUpIntervalDays,
      prefix: service.prefix && service.prefix.toUpperCase(),
      price: +service.price,
      startingUnits: +service.startingUnits,
      untracked: !service.isTracked,
      galdermaSku: service.galdermaSku ? parseGaldermaSkus(service.galdermaSku) : null,
    };
    return Api.post(`/v3/services`, parsedService);
  },
  async updateService(
    serviceId: number,
    service: ServiceFormData
  ): Promise<{ success: boolean; service: IServices; message?: string }> {
    const parsedService = {
      ...service,
      currentCost: +service.currentCost,
      daysToExpireAfterOpened: +service.daysToExpireAfterOpened,
      followUpIntervalDays: +service.followUpIntervalDays,
      prefix: service.prefix && service.prefix.toUpperCase(),
      price: +service.price,
      startingUnits: +service.startingUnits,
      untracked: !service.isTracked,
      galdermaSku: service.galdermaSku ? parseGaldermaSkus(service.galdermaSku) : null,
    };
    return Api.put(`/v3/services/${serviceId}`, parsedService);
  },

  async deleteService(serviceId: number): Promise<{ success: boolean; message?: string }> {
    return Api.delete(`/v3/services/${serviceId}`);
  },

  async getSuppliers(params?: any): Promise<ISuppliers[]> {
    const { suppliers } = await Api.get('/suppliers', params);

    return suppliers;
  },

  async getGaldermaSkus(): Promise<IGaldermaSku[]> {
    const { galdermaSkus } = await Api.get('/galderma_skus');

    return galdermaSkus;
  },

  async importServiceGroups({
    serviceGroupIds,
    serviceIds,
  }: {
    serviceGroupIds: number[];
    serviceIds: number[];
  }): Promise<boolean> {
    const { success } = await Api.post('/v3/service_groups/import', { serviceGroupIds, serviceIds });

    return success;
  },
};

export default Services;
