import { SchedulerDateTime } from '@devexpress/dx-react-scheduler';
import moment from 'moment';
import { ILIKE_AVAILABILITY_INDICATOR_APP_TYPE, PATIENT_SCHEDULER_STATUSES } from '../constants/scheduler.constants';
import { IAppointmentType } from '../interfaces/acuity/acuity.interface';

type MomentUnitOfTimes = 'month' | 'months' | 'M' | 'week' | 'weeks' | 'w' | 'day' | 'days' | 'd';

export const getPatientStatusData = (statusId: string) =>
  PATIENT_SCHEDULER_STATUSES.find(({ id }: any) => id === statusId);

export const getViewStringType = (view: string) => {
  let momentView: MomentUnitOfTimes = 'days';

  switch (view) {
    case 'Week':
      momentView = 'week';
      break;
    case 'Month':
      momentView = 'month';
      break;

    default:
      break;
  }
  return momentView;
};

export const applyCalendarStyles = () => {
  try {
    const today = new Date();
    const tables = document.getElementsByTagName('table');
    const daysOfMonth = tables[1].getElementsByTagName('tbody')[0].getElementsByTagName('td') || [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < daysOfMonth.length; i++) {
      const div = daysOfMonth[i].getElementsByTagName('div')[0];
      if (div.textContent === `${today.getDate()}`) {
        div.classList.add('calendarTodayNumber');
      }
    }

    const days =
      document.getElementsByTagName('table')[0].getElementsByTagName('tbody')[0].getElementsByTagName('td') || [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < days.length; i++) {
      const p = days[i].getElementsByTagName('p')[0];
      if (p && p.classList.length > 1) {
        const todayDiv = days[i].getElementsByTagName('div')[1];
        todayDiv.classList.add('currentDate');
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getViewAppointments = (
  appointments: any,
  currentDate: SchedulerDateTime,
  view: string,
  statuses: string[]
) => {
  const startOfView = moment(currentDate).clone().startOf(getViewStringType(view));
  const endOfView = moment(currentDate).clone().endOf(getViewStringType(view));

  const appointmentFiltered = appointments.filter(
    ({ startDate, endDate }: any) =>
      moment(startDate).isSameOrAfter(startOfView) && moment(endDate).isSameOrBefore(endOfView)
  );

  return appointmentFiltered.filter(({ statusId }: any) => statuses.includes(statusId));
};

// Function that prevent duplicated appointments
export const filterNewAppointments = (newAppointments: any, appointments: any) =>
  newAppointments.filter((newData: any) => !appointments.map(({ id }: any) => id).includes(newData.id));

// Method to get relevant appointment types to book appointments.
export const relevantAppointmentTypes = (appointmentTypes: IAppointmentType[], hidePrivate: boolean = true) =>
  appointmentTypes.filter((appointmentType) => {
    if (appointmentType.name.includes(ILIKE_AVAILABILITY_INDICATOR_APP_TYPE)) {
      return false;
    }
    if (hidePrivate && appointmentType.private) {
      return false;
    }
    return true;
  });
