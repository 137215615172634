import React from 'react';
import { Button } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import { useStyles } from './serviceGroupButton.styles';

interface ServiceGroupButtonProps {
  id: number;
  name: string;
  selected: boolean;
  amount: number;
  onClick: (id: number) => void;
}

const ServiceGroupButton: React.FC<ServiceGroupButtonProps> = ({ id, name, selected, amount, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.buttonServiceGroup}
      style={{ background: selected ? '#e7eeed' : 'transparent' }}
      onClick={() => onClick(id)}
    >
      <div>{name}</div>
      <div className={classes.amountOfServicesAndArrowForwardIcon}>
        {amount !== 0 && <div className={classes.amountOfSelectedServices}>{amount}</div>}
        <ArrowForwardIosIcon className={classes.ArrowForwardIcon} fontSize="small" />
      </div>
    </Button>
  );
};

export default ServiceGroupButton;
