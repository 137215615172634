import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  feature: {
    textTransform: 'capitalize',
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
  },
  icon : {
    display: 'flex',
    alignItems: 'center',
    marginRight: '4px',
    cursor: 'pointer'
  },
  buttons: {
    display: 'flex'
  },
  cancelButton: {
    width: '110px',
    height: '40px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: '#12574D',
    borderRadius: '3px',
    backgroundColor: '#',
    fontSize: '14px',
    margin: '10px',
    cursor: 'pointer'
  },
  saveButton: {
    width: '110px',
    height: '40px',
    borderRadius: '3px',
    border: 'none',
    backgroundColor: '#12574d',
    color: '#ffffff',
    fontSize: '14px',
    margin: '10px',
    cursor: 'pointer'
  },
}))
