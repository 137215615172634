import API_ROUTES from 'src/constants/apiRoutes.constants';
import Api from './Api';

export type ShipmentTrackingNumber = {
  id: number;
  inventoryOrderId: number;
  supplierId: number;
  supplierName: string;
  companyName: string;
  trackingNumber: string;
  courierName: string | null;
  serviceType: string | null;
  shipperId: string | null;
  courierValidated: boolean;
};
export default {
  async create(params: Partial<ShipmentTrackingNumber>): Promise<ShipmentTrackingNumber> {
    const { data }: { data: ShipmentTrackingNumber } = await Api.post(API_ROUTES.TRACKING_NUMBERS(), params);
    return data;
  },

  async destroy(id: number): Promise<ShipmentTrackingNumber> {
    const { data }: { data: ShipmentTrackingNumber } = await Api.delete(API_ROUTES.TRACKING_NUMBERS(id));
    return data;
  },

  async validateTrackingNumber(trackingNumber: string): Promise<string | null> {
    const { data }: { data: string | null } = await Api.post(API_ROUTES.VALIDATE_TRACKING_NUMBER, { trackingNumber });
    return data;
  },
};
