/* eslint-disable curly */
/// <reference types="chrome"/>

import React, { useState } from 'react';
import styles from './BrowserWarning.module.css';

const isChrome = window.chrome || /chrome|chromium|CriOS/i.test(navigator.userAgent);

const BrowserWarning = () => {
  const [showWarning, setShowWarning] = useState(() => {
    if (localStorage.getItem('showBrowserWarning') === null) {
      localStorage.setItem('showBrowserWarning', 'true');
      return true;
    }
    return localStorage.getItem('showBrowserWarning') !== 'false';
  });

  const dismiss = () => {
    setShowWarning(false);
  };

  const dismissPermanently = () => {
    localStorage.setItem('showBrowserWarning', 'false');
    setShowWarning(false);
  };

  if (isChrome || !showWarning) return null;

  return (
    <div className={styles.boxWarning}>
      <div className={styles.container}>
        <p>
          Portrait EHR is designed for Chrome users you are currently using a different Browser. We recommend you to use
          Chrome.
        </p>
        <div className={styles.buttonsContainer}>
          <button className={`${styles.buttons} ${styles.secondaryButton}`} type="button" onClick={dismiss}>
            Dismiss
          </button>
          <button className={`${styles.buttons} ${styles.primaryButton}`} type="button" onClick={dismissPermanently}>
            Dismiss permanently
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrowserWarning;
