/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import classnames from 'classnames';
import Page from 'src/components/common/Page';
import { useStyles } from './PhysicianPatientsList.styles';
import { PHYSICIAN_DASHBOARD_KEY, USER_TYPES } from '../../constants/general.constants';
import { CUSTOMERS_LIST, useCustomPatients } from '../../hooks/queries/useCustomPatients';
import { PortraitTable } from '../../components/common/TableNew/Table';
import compile from '../../utils/toastMessagesCompiler';
import { PATIENT_COLUMNS, PatientFilters, PatientRow } from './PhysicianPatientsList';
import { usePractitionerForPhysicians } from '../../hooks/practitioner/usePractitioner';
import { CustomerParams } from '../../interfaces/CustomerParams';
import { useCustomRoles } from '../../hooks/queries/useCustomRoles';
import { PENDING_RECLEAR } from '../../constants/scheduler.constants';

const DEFAULT_UPCOMING_APPOINTMENT_WINDOW_HOUR = 72;
const DEFAULT_UPCOMING_APPOINTMENT_WINDOW_DATE = new Date();
DEFAULT_UPCOMING_APPOINTMENT_WINDOW_DATE.setHours(
  DEFAULT_UPCOMING_APPOINTMENT_WINDOW_DATE.getHours() + DEFAULT_UPCOMING_APPOINTMENT_WINDOW_HOUR
);

const URGENT_REQUEST = '1';

const notClearedInitialFilters = {
  group: 'notCleared',
  license: '',
  search: '',
  sortBy: '',
  sortDirection: undefined,
  practitionerId: '',
  state: '',
  roleId: '',
  marked: '',
  excludedStatus: '',
};

const upcomingAppointmentInitialFilters = {
  group: 'notCleared',
  license: '',
  search: '',
  sortBy: '',
  sortDirection: undefined,
  practitionerId: '',
  state: '',
  appointmentDate: DEFAULT_UPCOMING_APPOINTMENT_WINDOW_DATE.toISOString(),
  appointmentDateOperator: '<',
  roleId: '',
  marked: '',
  excludedStatus: '',
};

const urgentInitialFilters = {
  group: 'notCleared',
  license: '',
  search: '',
  sortBy: '',
  sortDirection: undefined,
  practitionerId: '',
  state: '',
  roleId: '',
  requestType: URGENT_REQUEST,
  marked: '',
  excludedStatus: '',
};

export const PhysicianPendingGFEList = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [search, setSearch] = useState<string>('');
  const [providerId, setProviderId] = useState<string | number>('');
  const [roleId, setRoleId] = useState<string | number>('');
  const [patientState, setPatientState] = useState<string>('');
  const [license, setLicense] = useState<string>('');
  const [notClearedFilters, setNotClearedFilters] = useState<CustomerParams>(notClearedInitialFilters);
  const [upcomingFilters, setUpcomingFilters] = useState<CustomerParams>(upcomingAppointmentInitialFilters);
  const [urgentFilters, setUrgentFilters] = useState<CustomerParams>(urgentInitialFilters);
  const [patientType, setPatientType] = useState<string>('');
  const [removeRequiresUpdate, setRemoveRequiresUpdate] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('urgent');
  const { data: practitioners = [], isLoading: isLoadingProviders } = usePractitionerForPhysicians({});
  const { data: roles, isLoading: isLoadingRoles } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });

  useEffect(() => {
    queryClient.invalidateQueries([CUSTOMERS_LIST]);
  }, []);

  // Not Cleared Patients
  const { results, isLoading, isError, fetchNextPage, hasNextPage, isFetching } = useCustomPatients(
    PHYSICIAN_DASHBOARD_KEY,
    notClearedFilters
  );
  const notClearedPatients = results?.pages.map(({ customers = [] }) => customers).flat() || [];

  // Upcoming Patients
  const {
    results: upcomingResults,
    isLoading: upcomingIsLoading,
    isError: upcomingIsError,
    fetchNextPage: upcomingFetchNextPage,
    hasNextPage: upcomingHasNextPage,
    isFetching: upcomingIsFetching,
  } = useCustomPatients(PHYSICIAN_DASHBOARD_KEY, upcomingFilters);
  const upcomingPatients = upcomingResults?.pages.map(({ customers = [] }) => customers).flat() || [];

  // Urgent Patients
  const {
    results: urgentResults,
    isLoading: urgentIsLoading,
    isError: urgentIsError,
    fetchNextPage: urgentFetchNextPage,
    hasNextPage: urgentHasNextPage,
    isFetching: urgentIsFetching,
  } = useCustomPatients(PHYSICIAN_DASHBOARD_KEY, urgentFilters);
  const urgentPatients = urgentResults?.pages.map(({ customers = [] }) => customers).flat() || [];

  const doNotClearedSortBy = (column: string, direction: 'desc' | 'asc' | undefined) => {
    setNotClearedFilters({ ...notClearedFilters, ...{ sortBy: column, sortDirection: direction } });
  };

  const doUpcomingSortBy = (column: string, direction: 'desc' | 'asc' | undefined) => {
    setUpcomingFilters({ ...upcomingFilters, ...{ sortBy: column, sortDirection: direction } });
  };

  const doUrgentSortBy = (column: string, direction: 'desc' | 'asc' | undefined) => {
    setUrgentFilters({ ...urgentFilters, ...{ sortBy: column, sortDirection: direction } });
  };

  useEffect(() => {
    const newSearch = {
      search,
      practitionerId: providerId,
      state: patientState,
      roleId,
      license,
      marked: patientType ? true : '',
      excludedStatus: removeRequiresUpdate ? PENDING_RECLEAR : '',
    };
    setNotClearedFilters({
      ...notClearedFilters,
      ...newSearch,
    });
    setUpcomingFilters({
      ...upcomingFilters,
      ...newSearch,
    });
    setUrgentFilters({
      ...urgentFilters,
      ...newSearch,
    });
  }, [search, providerId, patientState, roleId, license, patientType, removeRequiresUpdate]);

  return (
    <Page title="Pending GFEs">
      <div className={classes.physicianPage}>
        <div className={classes.patientsTabs}>
          <p
            role="button"
            className={`${tab === 'urgent' ? classes.activeTabItem : classes.tabItem} ${classes.mr3}`}
            onClick={() => setTab('urgent')}
          >
            Urgent GFE within 24 hours ({urgentIsLoading ? '...' : urgentResults?.pages?.[0]?.meta?.totalCount})
          </p>
          <p
            role="button"
            className={`${tab === 'upcoming' ? classes.activeTabItem : classes.tabItem} ${classes.mr3}`}
            onClick={() => setTab('upcoming')}
          >
            Upcoming appt within the next 72 hours (
            {upcomingIsLoading ? '...' : upcomingResults?.pages?.[0]?.meta?.totalCount})
          </p>
          <p
            role="button"
            className={`${tab === 'notCleared' ? classes.activeTabItem : classes.tabItem} ${classes.mr3}`}
            onClick={() => setTab('notCleared')}
          >
            Missing GFEs ({isLoading ? '...' : results?.pages?.[0]?.meta?.totalCount})
          </p>
        </div>
        {tab === 'notCleared' && (
          <>
            {!isLoadingProviders && !isLoadingRoles && (
              <PatientFilters
                providers={practitioners}
                search={search}
                setSearch={setSearch}
                patientState={patientState}
                setPatientState={setPatientState}
                providerId={providerId}
                setProviderId={setProviderId}
                isFetching={isFetching}
                roles={roles}
                roleId={roleId}
                setRoleId={setRoleId}
                license={license}
                setLicense={setLicense}
                patientType={patientType}
                setPatientType={setPatientType}
                removeRequiresUpdate={removeRequiresUpdate}
                setRemoveRequiresUpdate={setRemoveRequiresUpdate}
              />
            )}
            <PortraitTable
              columns={PATIENT_COLUMNS}
              sortBy={notClearedFilters.sortBy}
              sortDirection={notClearedFilters.sortDirection}
              onSortChange={doNotClearedSortBy}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              data={notClearedPatients}
              rowComponent={(row) => <PatientRow patient={row} />}
              errorMessage={compile('generic.error_message', { action: 'fetching', element: 'patients' })}
              infiScroll
              isLoading={isLoading}
              isFetching={isFetching}
              isError={isError}
              tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
            />
          </>
        )}
        {tab === 'upcoming' && (
          <>
            {!isLoadingProviders && !isLoadingRoles && (
              <PatientFilters
                providers={practitioners}
                search={search}
                setSearch={setSearch}
                patientState={patientState}
                setPatientState={setPatientState}
                providerId={providerId}
                setProviderId={setProviderId}
                isFetching={upcomingIsFetching}
                roles={roles}
                roleId={roleId}
                setRoleId={setRoleId}
                license={license}
                setLicense={setLicense}
                patientType={patientType}
                setPatientType={setPatientType}
                removeRequiresUpdate={removeRequiresUpdate}
                setRemoveRequiresUpdate={setRemoveRequiresUpdate}
              />
            )}
            <PortraitTable
              columns={PATIENT_COLUMNS}
              sortBy={upcomingFilters.sortBy}
              sortDirection={upcomingFilters.sortDirection}
              onSortChange={doUpcomingSortBy}
              hasNextPage={upcomingHasNextPage}
              fetchNextPage={upcomingFetchNextPage}
              data={upcomingPatients}
              rowComponent={(row) => <PatientRow patient={row} />}
              errorMessage={compile('generic.error_message', { action: 'fetching', element: 'patients' })}
              infiScroll
              isLoading={upcomingIsLoading}
              isFetching={upcomingIsFetching}
              isError={upcomingIsError}
              tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
            />
          </>
        )}
        {tab === 'urgent' && (
          <>
            {!isLoadingProviders && !isLoadingRoles && (
              <PatientFilters
                providers={practitioners}
                search={search}
                setSearch={setSearch}
                patientState={patientState}
                setPatientState={setPatientState}
                providerId={providerId}
                setProviderId={setProviderId}
                isFetching={urgentIsFetching}
                roles={roles}
                roleId={roleId}
                setRoleId={setRoleId}
                license={license}
                setLicense={setLicense}
                patientType={patientType}
                setPatientType={setPatientType}
                removeRequiresUpdate={removeRequiresUpdate}
                setRemoveRequiresUpdate={setRemoveRequiresUpdate}
              />
            )}
            <PortraitTable
              columns={PATIENT_COLUMNS}
              sortBy={urgentFilters.sortBy}
              sortDirection={urgentFilters.sortDirection}
              onSortChange={doUrgentSortBy}
              hasNextPage={urgentHasNextPage}
              fetchNextPage={urgentFetchNextPage}
              data={urgentPatients}
              rowComponent={(row) => <PatientRow patient={row} />}
              errorMessage={compile('generic.error_message', { action: 'fetching', element: 'patients' })}
              infiScroll
              isLoading={urgentIsLoading}
              isFetching={urgentIsFetching}
              isError={urgentIsError}
              tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
            />
          </>
        )}
      </div>
    </Page>
  );
};
