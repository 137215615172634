/* eslint-disable no-param-reassign */
import { useQuery, UseQueryResult } from 'react-query';
import { PRACTITIONER_REPORTS, PRACTITIONER_REPORT_COLS } from '../../constants/reactQuery.keys';
import { IClientSummaryData, TableHeaderSet, IReportParams } from '../../interfaces/IPractitionerReport';
import PractitionerReports from '../../services/PractitionerReports';

function usePractitionerReport(params: IReportParams): UseQueryResult<IClientSummaryData[] | null> {
  return useQuery({
    queryKey: [PRACTITIONER_REPORTS, ...Object.values(params)],
    queryFn: (): Promise<IClientSummaryData[] | null> => PractitionerReports.getReport(params),
  });
}

function usePractitionerReportColumns(
  reportLabel: string,
  practitionerId: string | undefined
): UseQueryResult<TableHeaderSet | null> {
  return useQuery({
    queryKey: [PRACTITIONER_REPORT_COLS, reportLabel],
    queryFn: (): Promise<TableHeaderSet | null> => PractitionerReports.getColumns(reportLabel, practitionerId),
  });
}

export { usePractitionerReportColumns, usePractitionerReport, usePractitionerReport as default };
