import React from 'react';
import { Container, List } from '@material-ui/core';
import { MultipleSkeleton } from '../LoadingSkeleton';

type PropsType = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: any;
  children: any;
  noItems: boolean;
  noItemsMessage: any;
};
const ThreadList = ({ isLoading, isError, errorMessage, noItems, noItemsMessage, children }: PropsType) => {
  if (isLoading) {
    return <MultipleSkeleton addPosition={false} />;
  }

  if (isError) {
    return (
      <List>
        <Container>{errorMessage}</Container>
      </List>
    );
  }

  if (noItems) {
    return noItemsMessage;
  }

  return <List>{children}</List>;
};

export default ThreadList;
