import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.13), 0 1px 8px 0 rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    padding: '15px',
    justifyContent: 'space-around',
  },
  inputBoxContainer: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    flex: 1,
    padding: '5px 15px',
    width: '70%',
  },
  inputText: {
    width: '100%',
    margin: 'auto',
  },
  saveBoxContainer: {
    alignSelf: 'center',
  },
  contentImages: {
    display: 'flex',
    overflow: 'auto',
  },

  contentImageItem: {
    height: '100px',
    borderRadius: '6px',
    margin: '3px',
  },
  saveButton: {
    height: '56px',
    width: '56px',
    borderRadius: '50%',
    padding: 0,
  },
}));
