import API_ROUTES from 'src/constants/apiRoutes.constants';
import { IAsset } from 'src/interfaces/reconciliation.interfaces';
import Api from './Api';

export type PurchaseOrderItem = {
  id: number;
  serviceId: number;
  cost: number;
  quantity: number;
  lot: string;
  price: number;
  expireAt: string;
  units: number;
  name: string;
  isSample: boolean;
  assets: IAsset[];
  createdAt: string;
  updatedAt: string;
};
export default {
  async update({ id, ...params }: Omit<Partial<PurchaseOrderItem>, 'id'> & { id: number }): Promise<PurchaseOrderItem> {
    const { data }: { data: PurchaseOrderItem } = await Api.put(API_ROUTES.PURCHASE_ORDER_ITEM(id), params);
    return data;
  },
};
