import { Box, Button, Checkbox, Dialog, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useProductUtilization } from 'src/hooks/queries/useProductUtilization';
import PaginatedTable from 'src/components/common/PaginatedTable';
import SortDirection from 'src/types/SortDirection';
import { Cancelable, debounce, round } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useInventoryBudget } from 'src/hooks/queries/useInventoryBudget';
import { formatCurrency } from 'src/utils/formatNumber';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import BudgetPanel from 'src/components/common/BudgetPanel';
import { hasAccessTo, hasFeatureEnabled } from 'src/utils/auth.utils';
import { IProductUtilizationService } from 'src/interfaces/IInventoryOrder';
import {
  TABLE_COLUMNS,
  DEFAULT_SORT_BY,
  DEFAULT_SORT_DIR,
  ROWS_PER_PAGE,
} from 'src/constants/productUtilization.constants';
import { UPDATE_ORDERING_BUDGET } from 'src/constants/actions.constants';
import { USER_TYPES } from 'src/constants/general.constants';
import { usePractitioners } from 'src/hooks/queries/usePractitioners';
import { Skeleton } from '@material-ui/lab';
import Page from 'src/components/common/Page';
import { SelectList } from 'src/components/common/SelectList';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { PROVIDER_FILTER_TITLE } from 'src/constants/clientLead.constants';
import { FEATURE_INVENTORY_BUDGET } from 'src/constants/features.constants';
import InputSearch from 'src/components/common/InputSearch';
import classNames from 'classnames';
import EditOrderingBudget from './EditOrderingBudget';
import { useStyle } from './productUtilization.styles';

const ProductUtilization = () => {
  const [budgetDialogOpen, setBudgetDialogOpen] = useState<boolean>(false);
  const [practitionerId, setPractitionerId] = useState<number | undefined>();

  const { permissions, userType, featureList } = useSelector(({ auth }: RootState) => auth);

  const adminView = userType === USER_TYPES.ADMINISTRATOR;

  const { data: providerData = null, isLoading: providerDataLoading } = usePractitioners({}, adminView);

  const providerList = !providerData
    ? {}
    : Object.fromEntries(
      providerData.map((provider: IPractitioner) => [
        provider.id.toString(),
        `${provider.firstName} ${provider.lastName}`,
      ])
    );

  const { data: budget = null, isLoading: budgetLoading } = useInventoryBudget({
    disabled: adminView && !practitionerId,
    practitionerId: (adminView && practitionerId) || undefined,
  });

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    page: 0,
    serviceFilter: '',
    hideUnused: true,
    rowsPerPage: ROWS_PER_PAGE,
    orderBy: DEFAULT_SORT_BY,
    orderType: DEFAULT_SORT_DIR as SortDirection,
  });

  const { data: utilization, isLoading } = useProductUtilization({
    ...filters,
    practitionerId,
    disabled: adminView && !practitionerId,
  });

  const totalInventoryValue = utilization?.summary.totalInventoryValue ?? 0;
  const weeklyAvgValue = (utilization?.summary?.averageMonthlyValue ?? 0) / 4;
  const totalWeeksOnHand = weeklyAvgValue > 0 ? round((totalInventoryValue || 0) / weeklyAvgValue, 0) : 0;
  const canEditBudget = hasAccessTo(UPDATE_ORDERING_BUDGET, permissions) && practitionerId && budget !== null;
  const canSeeBudget = adminView || hasFeatureEnabled(FEATURE_INVENTORY_BUDGET, featureList);

  const classes = useStyle();

  useEffect(() => {
    if (!practitionerId && providerData) {
      setPractitionerId(+Object.keys(providerList)[0]);
    }
  }, [practitionerId, providerList]);

  const customTableRows = (): React.ReactElement[] =>
    utilization?.services?.map((rowItem: IProductUtilizationService) => (
      <TableRow className={classes.tableRow} key={rowItem.name}>
        <TableCell data-testid={`${rowItem.name}.name`} data-cy="name">
          {rowItem.name}
        </TableCell>
        <TableCell data-testid={`${rowItem.name}.listPrice`} data-cy="listPrice">
          {formatCurrency(rowItem.listPrice)}
        </TableCell>
        <TableCell data-testid={`${rowItem.name}.price`} data-cy="price">
          {formatCurrency(rowItem.price)}
        </TableCell>
        <TableCell data-testid={`${rowItem.name}.unitPrice`} data-cy="unitPrice">
          {formatCurrency(rowItem.unitPrice)}
        </TableCell>
        <TableCell data-testid={`${rowItem.name}.weeksOnHand`} data-cy="weeksOnHand">
          {rowItem.weeksOnHand}
        </TableCell>
        <TableCell data-testid={`${rowItem.name}.unitsOnHand`} data-cy="unitsOnHand">
          {rowItem.unitsOnHand}
        </TableCell>
        <TableCell data-testid={`${rowItem.name}.valueOnHand`} data-cy="valueOnHand">
          {formatCurrency(rowItem.valueOnHand)}
        </TableCell>
        <TableCell data-testid={`${rowItem.name}.weeklyAvgUnits`} data-cy="weeklyAvgUnits">
          {rowItem.weeklyAvgUnits}
        </TableCell>
        <TableCell data-testid={`${rowItem.name}.weeklyAvgValue`} data-cy="weeklyAvgValue">
          {formatCurrency(rowItem.weeklyAvgValue)}
        </TableCell>
      </TableRow>
    )) ?? [];

  const doSearch: ((inputSearch: string) => void) & Cancelable = useMemo(
    () =>
      debounce((inputSearch: string) => {
        setFilters({
          ...filters,
          serviceFilter: inputSearch,
        });
      }, 1500),
    [filters]
  );

  const handleInputChange = (value: string) => {
    setSearch(value);
    doSearch(value);
  };

  const handleChangeSort = (sort: string, sortDirection: SortDirection) =>
    setFilters({
      ...filters,
      orderBy: sort,
      orderType: sortDirection,
    });

  const dataWithLoader = ({
    isLoading: loading,
    data,
    testid,
  }: {
    isLoading: boolean;
    data: string | number;
    testid?: string;
  }) =>
    loading ? (
      <CircularProgress size={20} />
    ) : (
      <p data-testid={testid} className={classes.dataWithLoader}>
        {data}
      </p>
    );

  return (
    <Page title="Product Utilization">
      <Box className={classes.productUtilization} data-testid="productUtilization">
        <Dialog open={budgetDialogOpen}>
          {adminView && practitionerId && (budget || !budgetLoading) && (
            <EditOrderingBudget
              practitionerId={practitionerId}
              orderingBudget={budget ?? 0.0}
              toggleDialog={() => setBudgetDialogOpen(!budgetDialogOpen)}
            />
          )}
        </Dialog>
        <Box className={classNames(classes.header, !adminView && !canSeeBudget && 'noBudget')}>
          {adminView && (
            <Box padding={2} data-testid="practitionerSelect">
              {providerDataLoading ? (
                <Skeleton data-testid="practitionerSelect.loader" />
              ) : (
                <SelectList
                  title={PROVIDER_FILTER_TITLE}
                  listItems={providerList}
                  selected={`${practitionerId}`}
                  setSelected={(newVal: string | null) => {
                    newVal && setPractitionerId(+newVal);
                  }}
                  className={classes.providerSelect}
                  loadSelected
                />
              )}
            </Box>
          )}

          <Box>
            <InputSearch
              placeholder="Search an item..."
              onChangeValue={(event: any) => handleInputChange(event.target.value as string)}
              value={search}
              isLoading={false}
            />
          </Box>
          <Box>
            <Checkbox
              onChange={() => {
                setFilters({
                  ...filters,
                  hideUnused: !filters.hideUnused,
                });
              }}
              checked={filters.hideUnused}
              className={classes.checkbox}
              color="default"
            />
            Hide all unused assets
          </Box>
        </Box>
        <Box className={classNames(classes.subheader, !canSeeBudget && 'noBudget')}>
          {canSeeBudget && (
            <Box className={classes.budget} data-testid="budget.mini">
              <Box className={classes.budgetContent}>
                <p className={classes.greyoutText}>Budget</p>
                <h2 className={classes.noMargin}>
                  {dataWithLoader({
                    isLoading: budgetLoading,
                    data: formatCurrency(budget ?? 0),
                    testid: 'paginatedTable.summary.budget',
                  })}
                </h2>
              </Box>
              {canEditBudget && (
                <Button
                  data-testid="budget.edit"
                  className={classes.primaryButton}
                  onClick={() => setBudgetDialogOpen(true)}
                >
                  Edit
                </Button>
              )}
            </Box>
          )}
          <BudgetPanel totalInventoryValue={totalInventoryValue} practitionerId={practitionerId} adminView hideHeader />

          <Box className={classes.totals} data-testid="paginatedTable.summary">
            <table className={classes.fullWidth}>
              <tr>
                <td className={[classes.greyoutText, classes.fontMedium].join(' ')}>Weekly Average</td>
                <td>
                  {dataWithLoader({
                    isLoading,
                    data: formatCurrency(weeklyAvgValue),
                    testid: 'paginatedTable.summary.weeklyAverage',
                  })}
                </td>
              </tr>
              <tr>
                <td className={[classes.greyoutText, classes.fontMedium].join(' ')}>Weeks On Hand</td>
                <td>
                  {dataWithLoader({
                    isLoading,
                    data: `${totalWeeksOnHand} weeks`,
                    testid: 'paginatedTable.summary.weeksOnHand',
                  })}
                </td>
              </tr>
            </table>
          </Box>
        </Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignContent="center" data-testid="paginatedTable.loader">
            <CircularProgress className={classes.circularProgress} size={40} />
            <p>Loading product utilization...</p>
          </Box>
        ) : (
          <PaginatedTable
            columns={TABLE_COLUMNS}
            sortBy={filters.orderBy}
            sortDirection={filters.orderType}
            onChangeSort={handleChangeSort}
            pages={
              utilization?.summary?.total && utilization.summary.total > filters.rowsPerPage
                ? Math.floor(utilization.summary.total / filters.rowsPerPage)
                : 1
            }
            page={filters.page + 1}
            onPageChange={(page: number) => {
              setFilters({
                ...filters,
                page: page - 1,
              });
            }}
            customTableRows={customTableRows()}
          />
        )}
      </Box>
    </Page>
  );
};

export default ProductUtilization;
