import React, { useRef, useEffect } from 'react';
import { useStyles } from './manualForm.styles';

export const ManualForm = ({ service, showInputs }: any) => {
  const classes = useStyles();
  const manualInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showInputs && manualInputRef && manualInputRef.current) {
      manualInputRef.current.focus();
    }
  }, [showInputs, manualInputRef]);

  return (
    <div className={classes.container} style={{ fontSize: '12px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ width: '150px' }}>{service.name}</div>
        {!showInputs && <div className={classes.pendingScanText}>Pending scan</div>}
      </div>
    </div>
  );
};
