import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Switch } from '@material-ui/core';
import { useMedspaRole } from 'src/hooks/queries/useMedspaAdmins';
import { useSelector } from 'react-redux';
import { EHRTypography } from 'src/components/ui/v1';
import useStyles from './index.styles';
import { useUpdateUserGroup } from '../../../hooks/queries/useUserGroups';
import {
  MEDSPA_ADMIN_ROLE,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  SNACKBAR_ERROR,
} from '../../../constants/general.constants';
import { showSnackbar } from '../../../utils/global';
import {
  FEATURE_BEAUTIBANK,
  FEATURE_ENABLE_CHARTING,
  FEATURE_MEDSPA_MARKETING,
  FEATURE_MEDSPA_PHYSICIAN_CHART_REVIEWS,
} from '../../../constants/features.constants';
import { IUserGroup } from '../../../interfaces/IUserGroup';
import { IFeatureSetting } from '../../../interfaces/IFeatureSetting';
import { IFeature } from '../../../interfaces/IFeature';
import { useFeatures } from '../../../hooks/queries/useFeatures';
import { RootState, dispatch } from '../../../rematch';

interface MedspaConfigurationProps {
  userGroup: IUserGroup | undefined;
}

const MedspaConfigurations: React.FC<MedspaConfigurationProps> = ({ userGroup }) => {
  const classes = useStyles();
  const { roleName } = useSelector(({ auth }: RootState) => auth);

  const { data: medspaRoleName } = useMedspaRole(roleName === MEDSPA_ADMIN_ROLE);
  const isMedspaLegacyOrLegacyPlus = medspaRoleName === PORTRAIT_LEGACY || medspaRoleName === PORTRAIT_LEGACY_PLUS;

  const [medspaFields, setMedspaFields] = useState<IFeatureSetting[]>([]);
  const [medspaFeatures, setMedspaFeatures] = useState<IFeature[]>([]);

  const { data: features = [], isLoading: isLoadingFeatures } = useFeatures();
  const { mutateAsync: updateGroup, isLoading: isUpdating, isError: updateError } = useUpdateUserGroup();

  useEffect(() => {
    if (userGroup) {
      const userGroupFeatures = userGroup.features || [];

      setMedspaFields([
        {
          key: FEATURE_BEAUTIBANK,
          enabled: userGroupFeatures.some((feat) => feat.key === FEATURE_BEAUTIBANK),
        },
        {
          key: FEATURE_MEDSPA_MARKETING,
          enabled: userGroupFeatures.some((feat) => feat.key === FEATURE_MEDSPA_MARKETING),
        },
        ...(!isMedspaLegacyOrLegacyPlus
          ? [
            {
              key: FEATURE_ENABLE_CHARTING,
              enabled: userGroupFeatures.some((feat) => feat.key === FEATURE_ENABLE_CHARTING),
            },
          ]
          : []),
        {
          key: FEATURE_MEDSPA_PHYSICIAN_CHART_REVIEWS,
          enabled: userGroupFeatures.some((feat) => feat.key === FEATURE_MEDSPA_PHYSICIAN_CHART_REVIEWS),
        },
      ]);
    }

    if (features && features.length > 0) {
      const legacyMedspaAllowedFeatures = [
        FEATURE_BEAUTIBANK,
        FEATURE_MEDSPA_MARKETING,
        FEATURE_MEDSPA_PHYSICIAN_CHART_REVIEWS,
      ];

      const medspaAllowedFeatures = [...legacyMedspaAllowedFeatures, FEATURE_ENABLE_CHARTING];

      setMedspaFeatures(
        features.filter((feature) => {
          const medspaAdminFeatures = isMedspaLegacyOrLegacyPlus ? legacyMedspaAllowedFeatures : medspaAllowedFeatures;
          return medspaAdminFeatures.includes(feature.key);
        })
      );
    }

    if (updateError) {
      showSnackbar('Error updating Medspa features', SNACKBAR_ERROR);
    }
  }, [userGroup, updateError, features, isLoadingFeatures]);

  const updateUserGroupFeatures = async (key: string, newVal: boolean) => {
    const updatedFeature: IFeatureSetting = { key, enabled: newVal };
    const existentFeatures = medspaFields.filter((feature) => feature.key !== key);
    const updatedMedspaFields = [...existentFeatures, updatedFeature].filter((feature) => feature.enabled);

    setMedspaFields(updatedMedspaFields);

    if (userGroup) {
      await updateGroup({ id: userGroup.id, features: updatedMedspaFields });
      dispatch.auth.updateFeatures({ newFeatures: updatedMedspaFields });
    }
  };

  return (
    <Box>
      {medspaFeatures.map((feature) => (
        <Box>
          <Grid container direction="row" className={classes.parentGrid}>
            <Grid item xs={6}>
              <EHRTypography variant="readonly" dataCy={`medspa-feature-row-${feature.key}`}>
                {feature.name}
              </EHRTypography>
              <EHRTypography variant="description" dataCy={`medspa-feature-row-${feature.description}`}>
                {feature.description}
              </EHRTypography>
            </Grid>
            <Grid item xs={6} className={classes.switchGrid}>
              <Switch
                data-cy="showToggle"
                checked={medspaFields.find((field) => field.key === feature.key)?.enabled || false}
                disabled={isUpdating}
                onChange={(e) => updateUserGroupFeatures(feature.key, e.target.checked)}
                color="primary"
              />
            </Grid>
          </Grid>
          <Divider />
        </Box>
      ))}
    </Box>
  );
};

export default MedspaConfigurations;
