/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import moment from 'moment';
import Page from 'src/components/common/Page';
import CheckoutTransactionsHeader from './CheckoutTransactionsHeader';
import TablePaginator from '../../common/Table/TablePaginator';
import {
  useCheckoutTransactions,
  useCheckoutTransactionsCount,
  useCheckoutTransactionsLineItems,
  useCheckoutLineItemsCount,
  useDiscountCount,
  useCheckoutTransactionsDiscounts,
  useCheckoutTenderCount,
  useCheckoutTransactionsTenders,
  useCheckoutAssetsSoldCount,
  useCheckoutTransactionsAssetsSold,
} from '../../../hooks/queries/useCheckoutTransactions';
import {
  ASSETS_SOLD_COLUMNS,
  DISCOUNT_COLUMNS,
  LINE_ITEM_COLUMNS,
  TENDER_COLUMNS,
  TRANSACTIONS_COLUMN,
} from '../../../constants/transactions/columns.constants';
import { TransactionDetails } from './AccordeonDetails';

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: any) => ({
  id: `transactions-tab-${index}`,
  'aria-controls': `transactions-tabpanel-${index}`,
});

const initialTransactionsQueryParams = {
  fromDate: moment().format('YYYY-MM-01'),
  toDate: moment(),
  limit: 20,
  sort: '',
  sortDirection: '',
};

const CheckoutTransactions = () => {
  const [tab, setTab] = useState(0);
  const [transactionsQuery, setTransactionsQuery] = useState<any>(initialTransactionsQueryParams);
  const { data: transactionsCount = 0 } = useCheckoutTransactionsCount(transactionsQuery);
  const { data: lineItemsCount = 0 } = useCheckoutLineItemsCount(transactionsQuery);
  const { data: discountCount = 0 } = useDiscountCount(transactionsQuery);
  const { data: tenderCount = 0 } = useCheckoutTenderCount(transactionsQuery);
  const { data: assetsSoldCount = 0 } = useCheckoutAssetsSoldCount(transactionsQuery);
  const {
    isLoading,
    isFetching,
    data: { transactions },
    fetchNextPage,
    isError,
  } = useCheckoutTransactions(transactionsQuery, transactionsCount);

  const {
    isLoading: isLoadingLineItems,
    data: { lineItems },
    fetchNextPage: fetchNextPageLineItems,
  } = useCheckoutTransactionsLineItems(transactionsQuery, lineItemsCount);
  const {
    isLoading: isLoadingDiscounts,
    isFetching: isFetchingDiscounts,
    data: { discounts },
    fetchNextPage: fetchNextPageDiscount,
  } = useCheckoutTransactionsDiscounts(transactionsQuery, discountCount);
  const {
    isLoading: isLoadingTenders,
    isFetching: isFetchingTenders,
    data: { tenders },
    fetchNextPage: fetchNextPageTenders,
  } = useCheckoutTransactionsTenders(transactionsQuery, tenderCount);
  const {
    isLoading: isLoadingAssetsSold,
    isFetching: isFetchingAssetsSold,
    data: { assetsSold },
    fetchNextPage: fetchNextPageAssetsSold,
  } = useCheckoutTransactionsAssetsSold(transactionsQuery, assetsSoldCount);

  const handleTabChange = (event: any, newTab: number) => {
    onChangeSortBy('', ''); // avoid order in others tables without that field
    setTab(newTab);
  };

  const onChangeSortBy = (column: string, direction: string) => {
    setTransactionsQuery({ ...transactionsQuery, sort: column, sortDirection: direction });
  };

  return (
    <Page title="Checkout Transactions">
      <Box width="100%">
        <CheckoutTransactionsHeader queryParams={transactionsQuery} setQueryParams={setTransactionsQuery} />

        <Box p={2}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label={`Transactions (${transactionsCount})`} {...a11yProps('transactions')} />
            <Tab label={`Line items (${lineItemsCount})`} {...a11yProps('line-items')} />
            <Tab label={`Discounts (${discountCount})`} {...a11yProps('discounts')} />
            <Tab label={`Tenders (${tenderCount})`} {...a11yProps('tenders')} />
            <Tab label={`Assets sold (${assetsSoldCount})`} {...a11yProps('assets-sold')} />
          </Tabs>

          <TabPanel value={tab} index={0}>
            <TablePaginator
              isLoading={isLoading}
              isFetching={isFetching}
              isError={isError}
              errorMessage=""
              data={transactions}
              columns={TRANSACTIONS_COLUMN}
              onChangeSortBy={onChangeSortBy}
              total={transactionsCount}
              fetchNextPage={fetchNextPage}
              RenderDetails={TransactionDetails}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <TablePaginator
              isLoading={isLoadingLineItems}
              data={lineItems}
              columns={LINE_ITEM_COLUMNS}
              onChangeSortBy={onChangeSortBy}
              total={lineItemsCount}
              fetchNextPage={fetchNextPageLineItems}
            />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <TablePaginator
              isLoading={isLoadingDiscounts}
              isFetching={isFetchingDiscounts}
              data={discounts}
              columns={DISCOUNT_COLUMNS}
              onChangeSortBy={onChangeSortBy}
              total={discountCount}
              fetchNextPage={fetchNextPageDiscount}
            />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <TablePaginator
              isLoading={isLoadingTenders}
              isFetching={isFetchingTenders}
              data={tenders}
              columns={TENDER_COLUMNS}
              onChangeSortBy={onChangeSortBy}
              total={tenderCount}
              fetchNextPage={fetchNextPageTenders}
            />
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <TablePaginator
              isLoading={isLoadingAssetsSold}
              isFetching={isFetchingAssetsSold}
              data={assetsSold}
              columns={ASSETS_SOLD_COLUMNS}
              onChangeSortBy={onChangeSortBy}
              total={assetsSoldCount}
              fetchNextPage={fetchNextPageAssetsSold}
            />
          </TabPanel>
        </Box>
      </Box>
    </Page>
  );
};

export default CheckoutTransactions;
