import { makeStyles } from '@material-ui/core';

// Patient Header styles
export const usePatientHeaderStyles = makeStyles(() => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
  },
  subtitle: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '10px 0',
  },
  detail: {
    fontFamily: 'Messina Sans Regular',
  },
  card: {
    position: 'initial',
  },
  medicalHistoryAckCard: {
    position: 'absolute',
    zIndex: 11,
    width: '40%',
    margin: '-46px 6px',
  },
}));

export const patientHeaderStyles = {
  color: '#000000',
  backgroundColor: '#e7eeed',
  margin: '0 3px',
  paddingRight: '9px',
  paddingLeft: '9px',
};

export const patientHeaderActiveStyles = {
  color: '#ffffff',
  backgroundColor: '#12574d',
  margin: '0 3px',
  paddingRight: '9px',
  paddingLeft: '9px',
};
