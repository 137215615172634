import * as yup from 'yup';
import { FIRST_NAME, LAST_NAME, EMAIL, VALID_EMAIL, PASSWORD, ROLE_ID } from '../../../constants/schemaForm.constants';

export const schemaForm = yup.object().shape({
  firstName: yup.string().trim().required(FIRST_NAME),
  lastName: yup.string().trim().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  password: yup.string().required(PASSWORD),
  roleId: yup.number().typeError(ROLE_ID).required(ROLE_ID),
  userGroupId: yup.number(),
});

export const schemaFormEdit = yup.object().shape({
  firstName: yup.string().trim().required(FIRST_NAME),
  lastName: yup.string().trim().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  roleId: yup.number().typeError(ROLE_ID).required(ROLE_ID),
});
