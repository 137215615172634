/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, FormControl, Grid, InputLabel, makeStyles, OutlinedInput, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Check as CheckIcon } from '@material-ui/icons/';

// Components
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';

// Routes
import { PRACTITIONER_CONFIGURATION_PATH } from '../../routes/practitionerRoutes';

// Constants
import { CONSENTS_TITLE } from '../../constants/configuration.constants';

// Hooks
import {
  useCreatePractitionerConsent,
  usePractitionerConsent,
  useUpdatePractitionerConsents,
} from '../../hooks/queries/useConsents';
import {
  useCreatePractitionerStandingOrder,
  usePractitionerStandingOrder,
  useUpdatePractitionerStandingOrders,
} from '../../hooks/queries/useStandingOrders';
import { dispatch } from '../../rematch';

// Utils
import compile from '../../utils/toastMessagesCompiler';

type LocationState = {
  id?: number;
  name?: string;
  configuration?: string;
  backUrl?: string;
  searchUrl?: string;
  form?: any;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    paddingBottom: '10px',
  },
  formControlLabel: {
    backgroundColor: 'white',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: '#12574d !important',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '15px',
    margin: '10px 0 10px 0',
    position: 'absolute',
    right: 0,
  },
  message: {
    height: '41px',
    width: 'fit-content',
    backgroundColor: '#E6F9DA',
    borderRadius: '8px',
    padding: '10px',
    margin: '10px 0 10px 0',
    position: 'absolute',
    left: 0,
  },
  typography: {
    margin: '0px',
    fontSize: '14px',
  },
  checkIcon: {
    color: '#12574D',
    width: '20px',
    fontSize: '20px',
    marginRight: '10px',
  },
}));

const Customize = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    id,
    name: defaultName,
    configuration,
    backUrl,
    searchUrl,
    form,
  }: LocationState = history.location.state || '';

  const navigateBack = (formData = {}) => {
    if (backUrl) {
      history.push({
        pathname: backUrl,
        search: searchUrl,
        state: {
          form: { ...form, ...formData },
        },
      });
    } else {
      history.push(PRACTITIONER_CONFIGURATION_PATH);
    }
  };

  const [editorData, setEditorData] = useState('');
  const [name, setName] = useState(defaultName);
  const { mutateAsync } =
    configuration === CONSENTS_TITLE ? useUpdatePractitionerConsents() : useUpdatePractitionerStandingOrders();

  const createMutation =
    configuration === CONSENTS_TITLE
      ? useCreatePractitionerConsent(navigateBack)
      : useCreatePractitionerStandingOrder(navigateBack);
  const [isSuccess, setIsSuccess] = useState<any>(false);

  const getData = () => {
    if (!id) {
      return { data: {}, isError: false };
    }

    if (configuration === CONSENTS_TITLE) {
      return usePractitionerConsent(id);
    }

    return usePractitionerStandingOrder(id);
  };
  const { data, isError } = getData();

  useEffect(() => {
    if (isError) {
      navigateBack();
    }
  }, [isError]);

  const inputHandler = (event: any, editor: any) => {
    setEditorData(editor.getData());
  };

  const handleCreate = async () => {
    createMutation.mutate({
      name,
      content: editorData,
      successCallback: () => navigateBack(),
    });
  };

  const handleSubmit = async () => {
    if (!id) {
      return handleCreate();
    }
    try {
      const success = await mutateAsync({
        id,
        name,
        content: editorData,
      });

      setIsSuccess(success);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'updating',
            element: 'document',
          }),
          type: 'error',
        },
      });
    }
  };

  return (
    <div>
      <NavigationBar title="Customize" onBackButtonClick={navigateBack} />
      <div style={{ width: '80%', margin: 'auto', marginTop: '50px' }}>
        <h1>{`${configuration === CONSENTS_TITLE ? 'Consent' : 'Standing order'}`} </h1>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="name-id" color="secondary" className={classes.formControlLabel}>
            Name
          </InputLabel>
          <OutlinedInput
            id="name-id"
            type="text"
            fullWidth
            value={name}
            onChange={(ev: any) => setName(ev?.target?.value)}
          />
        </FormControl>
        <CKEditor
          editor={ClassicEditor}
          data={data?.content ? data?.content : ''}
          config={{
            removePlugins: ['MediaEmbed', 'CKFinder', 'Link'],
          }}
          onChange={inputHandler}
        />
        <div style={{ position: 'relative' }}>
          <Button className={classes.button} onClick={handleSubmit}>
            Save
          </Button>
          {isSuccess ? (
            <Grid container className={classes.message}>
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.typography}>Your changes have been saved</Typography>
            </Grid>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Customize;
