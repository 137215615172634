import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  parentGrid: {
    padding: '2rem 0',
    justifyContent: 'space-around',
  },
  submitButton: {
    backgroundColor: '#12574D',
    color: '#FFFFFF',
    width: '80px',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#12574D',
    border: 'solid 1px',
    width: '80px',
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  shadowedLabel: {
    fontSize: '14px',
    opacity: 0.4,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBox: {
    width: 400,
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '15px',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    padding: '24px',
  },
  textField: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  radio: {
    color: '#12574D',
    '&$checked': {
      color: '#12574D',
    },
  },
  checked: {},
  radioItem: {
    borderRadius: '10px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0',
    marginRight: '0',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    padding: '5px',
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  boxGroup: {
    display: 'block',
  },
  boxGroupItem: {
    margin: '0.5rem',
  },
});

export default useStyles;
