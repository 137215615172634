import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FiberManualRecord } from '@material-ui/icons';
import { Grid, Box, Button, TextField, Typography, TextareaAutosize, CircularProgress } from '@material-ui/core';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { dispatch } from '../../../rematch';
import HistoryImageList from '../../common/HistoryImageList';
import ImageEditor from '../../common/ImageEdit';
import { getServiceUnits } from '../../../utils/getServiceUnits';

const setButtonServiceStyles = (color: string) => ({
  height: '44px',
  color: '#fff',
  fontSize: '15px',
  padding: '1.5rem',
  marginRight: '8px',
  marginBottom: '8px',
  backgroundColor: color || '#e3e3e3',
});

const TextAreaStyles = {
  width: '100%',
  minHeight: '75px',
  maxHeight: '98px',
  borderRadius: '3px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: '#fff',
  padding: '13px 14px',
  fontFamily: 'Messina Sans Regular',
  fontSize: '15px',
  '&:focus': {
    border: '2px solid #12574d',
    outline: 'none !important',
  },
};

const imageInitialState = { photo_id: '', url: '', photo_services: [] };

const MedicalChartingImages = ({ visit, hideControls }: any) => {
  const params: any = useParams();
  const { serviceVisit, services, selectedServices, servicesUnits, totalServicesUnits } = useSelector(
    ({ newServiceVisit }: any) => newServiceVisit
  );
  const { id, photos, customerNote, internalNote, isLoadingGetServiceVisitFromPatient } = serviceVisit;
  const [image, setImage] = useState(imageInitialState);
  const [photoList, setPhotoList] = useState<any>([]);
  const [listAnnotationItems, setListAnnotationItems] = useState<any>([]);
  const [color, setColor] = useState('blue');
  const [currentService, setCurrentService] = useState(null);

  const getServices = () => {
    let filteredServices = [];
    const unitValues = Object.entries(
      Object.keys(totalServicesUnits).length > 0 ? totalServicesUnits : servicesUnits
    ).map((unit) => ({ id: unit[0], units: unit[1] }));
    if (unitValues.length > 0) {
      filteredServices = services
        .filter((service: any) => selectedServices.includes(service.id))
        .map((item: any, index: any) => ({ ...item, units: unitValues[index].units }));
    }
    return filteredServices;
  };

  useEffect(() => {
    const imageExists = photoList.some((item: any) => item.photo_id === image.photo_id);
    if (image.photo_id && !imageExists) {
      setPhotoList((prevState: any) => [...prevState, { ...image }]);
    }
  }, [image]);

  useEffect(() => {
    const q = visit.annotatedPhotos
      .map((item: any) => ({
        photo_id: item.photo.id,
        url: item.photo.largeUrl,
        photo_services: item.photoServices.map((photoService: any) => {
          const serviceData: any = {};
          visit.charges.forEach((charges: any) => {
            const { service, charge } = charges;
            if (service?.id === photoService?.service?.id) {
              serviceData.units = charge.units;
              serviceData.note = charge.note;
            }
          });
          return { ...photoService, ...serviceData };
        }),
      }))
      .flatMap((item: any) => item);
    setPhotoList((prevState: any) => [...prevState, ...q]);
  }, [visit.annotatedPhotos, visit.charges]);

  useEffect(() => {
    const photoServices = photoList.filter((pList: any) => pList.photo_id === image.photo_id);
    setListAnnotationItems(photoServices.map((pS: any) => pS.photo_services) || []);
  }, [photoList, image]);

  useEffect(() => {
    const defaultPhoto: any = getPhotos()[0];
    if (defaultPhoto) {
      setImage({ photo_id: defaultPhoto.id, url: defaultPhoto.largeUrl, photo_services: [] });
    }
  }, [photos]);

  const getPhotos = () => {
    const newPhotos = visit.annotatedPhotos.map((item: any) => ({ ...item.photo }));
    return newPhotos;
  };

  const onDrawPath = (paths: any) => {
    if (currentService) {
      setPhotoList((prevState: any) => {
        const photoIndex = photoList.findIndex((item: any) => item.photo_id === image.photo_id);
        const prevImages = [...prevState];
        const serviceIndex = photoList[photoIndex].photo_services.findIndex((item: any) => item.id === currentService);
        if (prevImages[photoIndex].photo_services[serviceIndex]) {
          Object.assign(prevImages[photoIndex].photo_services[serviceIndex], { canvas_data: paths });
        }
        return [...prevImages];
      });
    }
  };

  const onServiceSelect = (service: any) => {
    setCurrentService(service.id);
    setColor(service.color);
    setPhotoList((prevState: any) => {
      const photoIndex = photoList.findIndex((photo: any) => photo.photo_id === image.photo_id);
      const prevImages = [...prevState];
      const photoServices = prevImages[photoIndex]?.photo_services || [];
      const exists = photoServices.some(
        (item: any) => item.service_id === service.id || item.service.id === service.id
      );
      if (!exists) {
        prevImages[photoIndex] = {
          ...prevImages[photoIndex],
          photo_services: photoServices
            ? [...photoServices, { ...service, service_id: service.id }]
            : [{ ...service, service_id: service.id }],
        };
      }
      return [...prevImages];
    });
  };

  const saveServiceVisit = (serviceVisitId: any) => {
    const charges = photoList
      .map((photo: any) => photo.photo_services)
      .flatMap((item: any) => item)
      .map((service: any) => ({ units: service.units, note: service.description, service_id: service.service_id }));

    dispatch({
      type: 'newServiceVisit/saveNewServiceVisit',
      payload: { serviceVisitId, body: photoList, charges },
    });
  };

  const saveNotes = () => {
    dispatch({
      type: 'newServiceVisit/saveNotesForServiceVisit',
      payload: { serviceVisitId: visit.id, body: { customerNote, internalNote } },
    });
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    saveNotes();
  };

  const savePartial = debounce(() => saveServiceVisit(params.serviceVisitId), 3000);

  useEffect(() => {
    const hasCanvas = photoList.some((item: any) => item.photo_services.some((object: any) => object.canvas_data));
    if (id && currentService && hasCanvas) {
      savePartial();
    }
  }, [photoList]);

  const deletedAnnotated = async () => {};

  if (isLoadingGetServiceVisitFromPatient) {
    return (
      <div style={{ textAlign: 'center', width: '100%', padding: '100px 0' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <Box mb={2}>
        <Grid container xs={12} spacing={6}>
          <Grid item xs={6}>
            <Box style={{ height: '445px' }}>
              {photoList && photoList[0] && (
                <div key={image.photo_id}>
                  <ImageEditor
                    image={image}
                    imageUrl={image.url}
                    brushColor={color}
                    getPaths={onDrawPath}
                    photoServicesList={listAnnotationItems}
                    deletedAnnotated={deletedAnnotated}
                    hideControls={hideControls}
                  />
                </div>
              )}
            </Box>
            <Box mt={2} display="block">
              {getServices().map((service: any) => (
                <Button
                  key={service.id}
                  onClick={() => onServiceSelect(service)}
                  size="large"
                  disabled={image.photo_id === ''}
                  style={{
                    ...setButtonServiceStyles(service.color),
                    cursor: !image.photo_id ? 'no-drop' : 'copy',
                  }}
                >
                  {service.name}
                </Button>
              ))}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={4}>
              <HistoryImageList handlerSelectPhoto={setImage} image={image} photos={getPhotos()} />
            </Box>
            <Box>
              {photoList.map((photo: any) =>
                photo.photo_services.map((service: any) => (
                  <Box
                    key={service.id}
                    mb={2.5}
                    style={{ paddingLeft: '10px', display: image.photo_id === photo.photo_id ? 'block' : 'none' }}
                  >
                    <Box mb={2.25} display="flex" justifyItems="center">
                      <FiberManualRecord htmlColor={`${service.service ? service.service.color : service.color}`} />
                      <Typography style={{ fontSize: '15px', marginLeft: '8px', color: '#000' }}>
                        {service.service ? service.service.name : service.name}
                      </Typography>
                    </Box>
                    <Box display="flex" mb={2}>
                      <Box width="50" mr={1} display="flex" alignItems="center">
                        <Box mr={2}>
                          <TextField
                            value={totalServicesUnits[service.id] || service.units}
                            name="units"
                            label={getServiceUnits(service.unitLabel)}
                            placeholder={getServiceUnits(service.unitLabel)}
                            variant="outlined"
                            type="number"
                            size="medium"
                            disabled
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <TextareaAutosize
                        disabled
                        name="note"
                        placeholder="Notes"
                        defaultValue={service.note}
                        style={TextAreaStyles}
                      />
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default MedicalChartingImages;
