import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import FullPageModal from 'src/components/common/FullPageModal';
import SignDocumentButton from 'src/components/common/SignDocumentButton';
import { EHRButton, EHRTextarea, EHRTextField } from 'src/components/ui/v1';
import * as yup from 'yup';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { EHRSignatureCanvas } from 'src/components/ui/v1/EHRSignatureCanvas';
import { useStates } from 'src/hooks/queries/useStates';
import LicenseNumberDetails from 'src/components/common/LicensesNumberDetails';
import { MEDICAL_DIRECTOR_AGREEMENT } from 'src/constants/onboardingAgreements.constant';
import { MedicalLicenseDetails, LicenseDetails } from 'src/interfaces/onboarding.interface';

const useStyles = makeStyles({
  medicalLicenseForm: {
    width: '100%',
    maxWidth: '480px',
    margin: '60px auto',
    gap: '30px',
    minWidth: '350px',
  },
});

const medicalLicenseDetails = yup.object().shape({
  orderingRestrictions: yup.string().nullable(),
  medicalDirectorLicenses: yup
    .array()
    .of(
      yup.object().shape({
        licenseNumber: yup.string(),
        state: yup.string(),
      })
    )
    .required('Medical Director Licenses is a required field'),
  businessName: yup.string().required('Business Name is a required field'),
  signature: yup.string().nullable().required('Signature is a required field'),
  fullName: yup.string().required('Full Name is a required field'),
});

const formDefaultValues: MedicalLicenseDetails = {
  orderingRestrictions: '',
  medicalDirectorLicenses: [],
  businessName: '',
  signature: null,
  fullName: '',
};

interface SignAuthorizationProps {
  onSigned?: (licenseDetails: MedicalLicenseDetails) => void;
  signedFile?: string | null;
  disabled?: boolean;
}

const MODAL_HEADER = 'Medical Director Authorization Agreement';

const SignAuthorization = ({ onSigned, signedFile, disabled = false }: SignAuthorizationProps) => {
  const classes = useStyles();
  const { data: states = [] } = useStates();
  const [openAgreementModal, setOpenAgreementModal] = useState(false);
  const { control, errors, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(medicalLicenseDetails),
    defaultValues: formDefaultValues,
  });
  const overrideSetValue = (key: string, value: any) => setValue(key, value, { shouldValidate: true });

  const handleModal = () => {
    setOpenAgreementModal((prevState) => !prevState);
    reset();
  };

  const onSign = (signature: string) => {
    overrideSetValue('signature', signature);
  };

  const onClear = () => {
    setValue('signature', null);
  };

  const onSubmit = (formData: MedicalLicenseDetails) => {
    handleModal();
    onSigned?.(formData);
  };

  const handleUpdateLicenses = (licenses: LicenseDetails[]) => {
    overrideSetValue('medicalDirectorLicenses', licenses);
  };

  return (
    <>
      <SignDocumentButton
        title="Medical Director Authorization Agreement"
        onClick={handleModal}
        pdf={signedFile}
        disabled={disabled}
      />
      <FullPageModal headerText={MODAL_HEADER} isOpen={openAgreementModal} handleClose={handleModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width="100%" maxWidth="800px" margin="auto">
            <Box
              margin="40px 0"
              padding="20px"
              bgcolor="white"
              dangerouslySetInnerHTML={{ __html: MEDICAL_DIRECTOR_AGREEMENT }}
            />
            <hr />
          </Box>
          <Grid container className={classes.medicalLicenseForm}>
            <Grid xs={12} item style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <LicenseNumberDetails
                states={states}
                onUpdate={handleUpdateLicenses}
                errors={errors?.medicalDirectorLicenses?.message}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                id="businessName"
                control={control}
                name="businessName"
                error={!!errors?.businessName}
                render={({ onChange, value }: any) => (
                  <EHRTextField
                    onChange={onChange}
                    value={value}
                    dataCy=""
                    label="Counter Party / Business Name"
                    helperText={errors?.businessName?.message}
                    error={!!errors?.businessName}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              Portrait procures controlled substances that are used in typical aesthetics, wellness, and weight loss
              medical practices, such as neuromodulators and injectable hyaluronic acid. If there are any restrictions
              on the type of products that Counterparty is authorized to procure, please list those restrictions below.
            </Grid>

            <Grid xs={12} item>
              <Controller
                id="orderingRestrictions"
                control={control}
                name="orderingRestrictions"
                error={!!errors?.orderingRestrictions}
                render={({ onChange, value }) => (
                  <EHRTextarea
                    onChange={onChange}
                    value={value}
                    dataCy=""
                    label="Ordering Restrictions"
                    helperText={errors?.orderingRestrictions?.message}
                    error={!!errors?.orderingRestrictions}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <EHRSignatureCanvas
                errors={!!errors?.signature}
                helperText={errors?.signature?.message}
                onSign={onSign}
                onClear={onClear}
              />
            </Grid>

            <Grid xs={12} item>
              <Controller
                id="fullName"
                control={control}
                name="fullName"
                error={!!errors?.fullName}
                render={({ onChange, value }) => (
                  <EHRTextField
                    onChange={onChange}
                    value={value}
                    dataCy=""
                    label="Your Full Name"
                    helperText={errors?.fullName?.message}
                    error={!!errors?.fullName}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} item>
              <EHRButton dataCy="" text="Sign" color="primary" type="submit" fullWidth />
            </Grid>
          </Grid>
        </form>
      </FullPageModal>
    </>
  );
};

export default SignAuthorization;
