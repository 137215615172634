import { makeStyles } from '@material-ui/core';

export const useInputMatchingStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    height: '100%',
    borderRadius: '3px',
    width: '100%',
    padding: '4px',
  },
  input: {
    fontFamily: 'Messina Sans Regular',
    borderColor: 'transparent',
    padding: '4px',
    fontSize: '14px',
  },
  button: {
    fontWeight: 'bold',
    width: '100%',
    padding: '9px',
    backgroundColor: '#e7eeed',
    color: '#000',
    border: '1px solid transparent',
    textTransform: 'uppercase',
  },
});
