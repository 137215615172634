import { IMetaPagination } from 'src/interfaces/global.interfaces';
import { IUser } from 'src/interfaces/IUser';
import Api from './Api';

const BASE_PATH = '/v3';

interface IGetUsers {
  users: IUser[];
  meta: IMetaPagination;
}

export const getUsers = async ({
  page = 1,
  sortBy = undefined,
  sortDirection = undefined,
  search = undefined,
  keys = undefined,
}): Promise<IGetUsers> => {
  const { users, meta } = await Api.get(`${BASE_PATH}/users`, { page, sortBy, sortDirection, search, keys });
  return { users, meta };
};

export const addFeatureFlagToUser = async (key: string, userId: number) =>
  Api.post(`${BASE_PATH}/users/${userId}/feature/${key}`, {});

export const removeFeatureFlagFromUser = async (key: string, userId: number) =>
  Api.delete(`${BASE_PATH}/users/${userId}/feature/${key}`);
