import React from 'react';
import { Box, FormControlLabel } from '@material-ui/core';
import { CheckboxCustom } from './modalPoints.styles';

interface CertificatesListItemProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (value: number) => void;
  value: number;
  checked?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

export const CertificatesListItem = (props: CertificatesListItemProps) => {
  const { onChange, children, value, checked, disabled } = props;

  const handleOnClick = () => {
    onChange(value);
  };

  return (
    <Box component="li" mb={3}>
      <FormControlLabel
        style={{ alignItems: 'start' }}
        control={<CheckboxCustom size="medium" disabled={disabled} checked={checked} onChange={handleOnClick} />}
        label={<>{children}</>}
      />
    </Box>
  );
};
CertificatesListItem.defaultProps = {
  checked: false,
  disabled: false,
};

interface CertificatesListProps {
  children: React.ReactNode;
}

export const CertificatesList = (props: CertificatesListProps) => {
  const { children } = props;

  return (
    <Box component="ul" paddingLeft={4} style={{ listStyleType: 'none' }}>
      {children}
    </Box>
  );
};
