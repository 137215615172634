import moment from 'moment';

export interface ITransaction {
  checkoutId: number;
  serviceVisitId: number;
  customerId: number;
  customerName: string;
  practitioner?: string;
  eventType?: string;
  total?: number;
  discounts?: number;
  discountNames: string[];
  netSales?: number;
  productsAmount: number;
  servicesAmount: number;
  tipsAmount: number;
  otherTendersTotal?: number;
  squareTransactionAmount?: string;
  significantSquareTransactionId?: string;
  note?: string;
  details?: string;
  ehrUrl?: string;
  commissionEligibility?: string;
  promotion: any;
  createdAt?: any;
  dateCreatedAt?: any;
  timeCreatedAt?: any;
  amountBelowPriceFloor: number;
  lineItemsBelowPriceFloor: any;
  processedInSquare: boolean;
}

export interface IPossibleTransaction {
  id: number;
  serviceVisitId: number;
  customerId: number;
  customerName: string;
  discounts: number;
  productsAmount: number;
  servicesAmount: number;
  tipsAmount: number;
  ehrUrl: string;
  createdAt: any;
  processedStatus: boolean;
}

export interface ILineItem {
  checkoutId: number;
  serviceVisitId: number;
  checkoutLineItemId: number;
  customerId: number;
  customerName: string;
  practitioner: string;
  serviceId: number;
  serviceVisitCreatedAt: moment.Moment;
  serviceName: string;
  quantity: number;
  unit: number;
  totalWithoutDiscount: number;
  totalWithDiscount: number;
  numberOfRealAssetsInvolved: number;
  sumOfAllCosts: number;
  allCosts: string;
  ehrUrl: string;
}

export interface IDiscount {
  checkoutId: number;
  serviceVisitId: number;
  checkoutLineItemId: number;
  customerId: number;
  customerName: string;
  practitioner: string;
  date: moment.Moment;
  value: number;
  discountType: string;
  description: number;
  discountName: number;
  discountId: number;
  servicePrice: number;
  actualDollarAmountDiscountedForPercentageType: number;
  ehrUrl: string;
}

export enum TenderType {
  Aspire = 'aspire',
  Brilliant = 'brilliant',
  CareCredit = 'careCredit',
  Referral = 'referral',
  Xperience = 'xperience',
  Patientfi = 'patientfi',
  AlleCherry = 'alleCherry',
}

export interface ITender {
  checkoutId: number;
  serviceVisitId: number;
  checkoutTenderId: number;
  transactionDateCreatedAt: moment.Moment;
  transactionTimeCreatedAt: moment.Moment;
  customerId: number;
  customerName: string;
  practitioner: string;
  voucher: string;
  value: number;
  tenderType: TenderType;
  ehrUrl: string;
}

export interface IAssetSold {
  checkoutId: number;
  serviceVisitId: number;
  checkoutLineItemId: number;
  serviceId: number;
  serviceName: string;
  quantityInLineItem: number;
  numberOfAssetsInvolved: number;
  costsOfAssetsInvolved: number;
  sumOfAllCosts: number;
  salePrice: number;
  margin: number;
  internalSerialNumbers: string;
  warehouseName: string;
  purchaseOrderIds: number;
  ehrUrl: string;
}
