import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Checkbox } from '@material-ui/core';
import Page from 'src/components/common/Page';
import { dispatch } from '../rematch';
// eslint-disable-next-line max-len
import UpcomingPatientsTable from '../components/DashboardAdministrator/Patients/UpcomingPatients/UpcomingPatientsTable';
import compile from '../utils/toastMessagesCompiler';
import { useUpcomingAppointmentCount, useUpcomingAppointments } from '../hooks/queries/useUpcomingAppointments';
import { CustomerParams } from '../interfaces/CustomerParams';
import { ASCENDING } from '../types/SortDirection';

const useStyles = makeStyles(() => ({
  checkbox: {
    color: '#12574d',
  },
}));

const ERROR_MESSAGE_PATIENTS_UPCOMING = compile('generic.error_message', {
  action: 'getting',
  element: 'patients',
});

const INITIAL_ORDER_BY = 'datetime';
const INITIAL_DIRECTION_UPCOMING = ASCENDING;

const PatientsUpcomingPage = () => {
  const classes = useStyles();
  const [byPhotoRequest, setByPhotoRequest] = useState<boolean>(false);

  const initialParamsUpcoming: CustomerParams = {
    sortBy: `${INITIAL_ORDER_BY} ${INITIAL_DIRECTION_UPCOMING}`,
    byPhotoRequest,
  };

  const [sort, setSort] = useState<any>(INITIAL_ORDER_BY);
  const [sortDirectionUpcoming, setSortDirectionUpcoming] = useState<any>(INITIAL_DIRECTION_UPCOMING);
  const [paramsQueryUpcoming, setParamsQueryUpcoming] = useState<CustomerParams>({ ...initialParamsUpcoming });

  const { data: upcomingTotal = 0, refetch: refetchCount } = useUpcomingAppointmentCount(paramsQueryUpcoming);
  const {
    isLoading,
    data: { appointments: upcomingPatients, currentPage },
    isError,
    refetch,
    fetchNextPage,
  } = useUpcomingAppointments(paramsQueryUpcoming, upcomingTotal);

  useEffect(() => {
    setParamsQueryUpcoming({
      ...paramsQueryUpcoming,
      byPhotoRequest,
    });
    refetch();
    refetchCount();
  }, [byPhotoRequest]);

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: ERROR_MESSAGE_PATIENTS_UPCOMING,
        type: 'error',
        duration: 2000,
      },
    });
  }

  const doSortUpcomingBy = (column: string, direction: string) => {
    const orderField = `${column} ${direction.toUpperCase()}`;
    setParamsQueryUpcoming({
      ...paramsQueryUpcoming,
      sortBy: orderField,
    });
    setSort(column);
    setSortDirectionUpcoming(direction);
  };

  return (
    <Page title="Upcoming patients">
      <Box padding={2} width="100%">
        <Box display="flex" justifyContent="space-between">
          <Box data-cy="photoRequestCheckbox" style={{ marginRight: '80px' }}>
            <Checkbox
              color="default"
              className={classes.checkbox}
              checked={byPhotoRequest}
              onChange={() => {
                setByPhotoRequest(!byPhotoRequest);
              }}
              value="byPhotoRequest"
            />
            With photo request
          </Box>
        </Box>
        <Box>
          <UpcomingPatientsTable
            isLoading={isLoading}
            isError={isError}
            errorMessage={ERROR_MESSAGE_PATIENTS_UPCOMING}
            data={upcomingPatients}
            sortBy={sort}
            sortDirection={sortDirectionUpcoming}
            onChangeSortBy={doSortUpcomingBy}
            page={currentPage || 1}
            total={upcomingTotal}
            fetchNextPage={fetchNextPage}
          />
        </Box>
      </Box>
    </Page>
  );
};

export default PatientsUpcomingPage;
