import { useEffect } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { showSnackbar } from 'src/utils/global';
import { DEFAULT_LIMIT } from '../../../constants/general.constants';
import {
  SERVICES,
  SERVICE_GROUPS_LIST,
  SERVICES_LIST,
  SERVICES_LIST_COUNT,
  SERVICE,
  DEFAULT_SERVICES,
  DEFAULT_SERVICE_GROUPS,
  SERVICE_GROUPS,
  SUPPLIERS_LIST,
  PRACTITIONER_PRIMARY_SERVICES,
} from '../../../constants/reactQuery.keys';
import IServices, { IServicesListParams } from '../../../interfaces/IServices';
import Services from '../../../services/Services';
import compile from '../../../utils/toastMessagesCompiler';

/* List of all IServices
 * Let's keep the previous data in order to make the request faster
 * the serivces do not use to change constantly.
 */

export function useService(serviceId: number) {
  const response = useQuery([SERVICE, serviceId], () => Services.getService(serviceId), { enabled: !!serviceId });
  return {
    ...response,
    data: response.data || ({} as IServices),
  };
}

export function useServices(patientId?: number | null, serviceVisitId?: number, enabled = true) {
  const response = useQuery([SERVICES, patientId], () => Services.getServices(patientId, serviceVisitId), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled,
  });

  return {
    ...response,
    data: response.data || [],
  };
}
export function useDefaultServices() {
  const response = useQuery([DEFAULT_SERVICES], () => Services.getDefaultServices(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    ...response,
    data: response.data || [],
  };
}
export function useServicesList(paramsQuery: IServicesListParams, total: number = 0) {
  const limit = paramsQuery?.limit || DEFAULT_LIMIT;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([SERVICES_LIST, paramsQuery]);
  }, [paramsQuery]);

  const query = useInfiniteQuery<any>(
    [SERVICES_LIST, paramsQuery],
    ({ pageParam = 1 }) => Services.getAllServices({ ...paramsQuery, page: pageParam }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * limit < total : lastPage.length === limit;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const services =
    query?.data?.pages
      ?.map((items) => items)
      .flat()
      .filter(Boolean) || [];

  return { ...query, data: { ...query.data, services, currentPage: query?.data?.pages.length } };
}

export function useServicesListCount(paramsQuery: IServicesListParams) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([SERVICES_LIST_COUNT]);
  }, [paramsQuery]);

  return useQuery([SERVICES_LIST_COUNT, paramsQuery], () => Services.getAllServicesCount(paramsQuery), {
    initialData: 0,
    refetchOnWindowFocus: false,
    retry: 1,
    keepPreviousData: true,
  });
}

/* List of all Service groups
 * Let's keep the previous data in order to make the request faster
 * Previous data will be updated once the request finished.
 */
export function useServiceGroups(patientId?: number, serviceVisitId?: number) {
  const response = useQuery(
    [SERVICE_GROUPS_LIST, patientId],
    () => Services.getServiceGroups(patientId, serviceVisitId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    ...response,
    data: response.data || [],
  };
}

export function useDefaultServiceGroups() {
  const response = useQuery([DEFAULT_SERVICE_GROUPS], () => Services.getDefaultServiceGroups(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    ...response,
    data: response.data || [],
  };
}

export const useImportServiceGroupsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ serviceGroupIds, serviceIds }: any) => Services.importServiceGroups({ serviceGroupIds, serviceIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICES]);
        queryClient.invalidateQueries([SERVICE_GROUPS_LIST]);
      },
    }
  );
};

export const useCreateService = () => {
  const queryClient = useQueryClient();
  return useMutation((serviceData: any) => Services.createService(serviceData), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICES]);
      queryClient.invalidateQueries([SERVICES, SERVICE_GROUPS]);
      queryClient.invalidateQueries([SUPPLIERS_LIST]);
    },
  });
};

export const useUpdateService = () => {
  const queryClient = useQueryClient();
  return useMutation(({ serviceId, serviceData }: any) => Services.updateService(serviceId, serviceData), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICES]);
      queryClient.invalidateQueries([SERVICES, SERVICE_GROUPS]);
      queryClient.invalidateQueries([SUPPLIERS_LIST]);
    },
  });
};

export const useDeleteService = () => {
  const queryClient = useQueryClient();
  return useMutation((serviceId: number) => Services.deleteService(serviceId), {
    onSuccess: (response: any) => {
      if (!response.success) {
        const message = response?.message ? response?.message : compile('generic.error_message_without_params');
        showSnackbar(message);
        return;
      }

      queryClient.invalidateQueries([SERVICES]);
      queryClient.invalidateQueries([SERVICES, SERVICE_GROUPS]);
      queryClient.invalidateQueries([SUPPLIERS_LIST]);
      queryClient.invalidateQueries([PRACTITIONER_PRIMARY_SERVICES]);
    },
  });
};
