import { createModel } from '@rematch/core';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import { toSnakeCase } from '../utils/global';
import compile from '../utils/toastMessagesCompiler';

export const transaction = createModel<RootModel>()({
  state: {
    transactions: [],
    lineItems: [],
    discounts: [],
    tenders: [],
    assetsSold: [],
    isLoading: false,
  },
  reducers: {
    setData(state: any, payload: any[]) {
      return { ...state, ...payload };
    },
    setLoading(state: any, payload: boolean) {
      return { ...state, isLoading: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchTransactions(payload: any = {}) {
      dispatch.transaction.setLoading(true);

      try {
        const response = await axiosInstance.get('/transactions', {
          params: toSnakeCase(payload),
        });

        dispatch.transaction.setData(response.data);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'the transactions',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.transaction.setLoading(false);
      }
    },
  }),
});
