import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Button } from '../../common/Button';
import { useStyles } from './standingOrderEditorModal.styles';
import StandingOrderEditor from './StandingOrderEditor';

export const StandingOrderEditorModal = ({
  isOpen,
  handleCloseCallback,
  saveStandingOrderDraft,
  selectedStandingOrder,
}: any) => {
  const classes = useStyles();
  const [modifyStandingOrder, setModifyStandingOrder] = useState(null);
  const { standingOrder, name } = selectedStandingOrder;

  return (
    <Modal open={isOpen} onClose={handleCloseCallback}>
      <div style={{ backgroundColor: '#e7eeed', height: '100%', overflowY: 'scroll' }}>
        <div>
          <div>
            <CloseIcon className={classes.closeIcon} onClick={handleCloseCallback} />
            <div className={classes.title}>Editing the {name} the standing order</div>
          </div>
          <hr style={{ border: '0.5px solid lightgray', marginTop: '25px', marginBottom: '30px' }} />

          <StandingOrderEditor standingOrder={standingOrder} name={name} onChange={setModifyStandingOrder} />

          <hr style={{ border: '0.5px solid lightgray', marginTop: '25px', marginBottom: '20px' }} />

          <div style={{ padding: '8px 16px', margin: 'auto', width: 'fitContent' }}>
            <Button className={classes.cancelButton} title="Cancel" onClick={handleCloseCallback} />
            <Button
              className={classes.saveButton}
              title="Save"
              onClick={() => saveStandingOrderDraft(modifyStandingOrder)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
