import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import { SERVICE_VISIT_PHOTO_CONSENT } from '../../constants/reactQuery.keys';
import Customers from '../../services/Customers';
import { ICreateCustomerPhotoConsent, IUpdateCustomerPhotoConsent } from '../../types/Patient';

export const useCustomerPhotoConsent = (patientId: number, serviceVisitId: number, enabled: boolean) =>
  useQuery(
    [SERVICE_VISIT_PHOTO_CONSENT, patientId, serviceVisitId],
    () => Customers.getCustomerPhotoConsent({ patientId, serviceVisitId }),
    {
      enabled,
      refetchOnWindowFocus: true, // For multi-tab purposes
      keepPreviousData: true,
      onError: () =>
        useReactQueryOnErrorHandler(
          compile('generic.error_message', { action: 'fetching', element: 'customer photo consent' })
        ),
    }
  );

export const useCreateCustomerPhotoConsent = (patientId: number, serviceVisitId: number) => {
  const queryClient = useQueryClient();
  return useMutation((params: ICreateCustomerPhotoConsent) => Customers.createCustomerPhotoConsentV2(params), {
    onSuccess: () => queryClient.invalidateQueries([SERVICE_VISIT_PHOTO_CONSENT, patientId, serviceVisitId]),
  });
};

export const useUpdateCustomerPhotoConsent = (patientId: number, serviceVisitId: number) => {
  const queryClient = useQueryClient();
  return useMutation((params: IUpdateCustomerPhotoConsent) => Customers.updateCustomerPhotoConsent(params), {
    onSuccess: () => queryClient.invalidateQueries([SERVICE_VISIT_PHOTO_CONSENT, patientId, serviceVisitId]),
  });
};
