import React from 'react';
import { Box } from '@material-ui/core';
import Page from 'src/components/common/Page';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';

const Dashboard = () => (
  <Page title="Forecasts">
    <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
      <Box display="flex">
        <BreadcrumbsContainer>
          <BreadcrumbText text="Dashboards" />
          <BreadcrumbText text="Forecasts" isActive linkTo="/administrator/forecasts" />
        </BreadcrumbsContainer>
      </Box>
    </Box>
    <Box width="100%" height="100vh">
      <iframe
        title="dashboard-forecasts"
        src={process.env.REACT_APP_ADMIN_FORECAST}
        frameBorder="0"
        width="100%"
        height="100%"
        // eslint-disable-next-line
        allowTransparency
      />
    </Box>
  </Page>
);

export default Dashboard;
