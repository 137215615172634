import React from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { EHRTextField, EHRTextFieldProps } from './EHRTextField';

const useStyles = makeStyles(() => ({
  ehrDatePicker: {},
}));

export interface EHRDatePickerProps extends EHRTextFieldProps {
  valueFormat?: string;
}

const EHRDatePicker = (props: EHRDatePickerProps) => {
  const classes = useStyles();
  const { dataCy, id, className, value, valueFormat, readOnly, ...restProps } = props;

  return (
    <EHRTextField
      {...restProps}
      dataCy={dataCy}
      id={`${id}-date`}
      type={readOnly && valueFormat ? 'text' : 'date'}
      value={readOnly && valueFormat ? moment(value || null).format(valueFormat) : value}
      className={classNames(classes.ehrDatePicker, className)}
    />
  );
};

export { EHRDatePicker };
