/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import Cohere from 'cohere-js';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, CssBaseline, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum';
import { messinaLight, messinaRegular, messinaBold, messinaSemiBold, robotoMedium } from './utils/fonts';
import Login from './pages/Login';
import LeadLandingPage from './pages/External';
import PrivateRoute from './utils/PrivateRoute';
import { dispatch } from './rematch';
import ProductIdentifiers from './pages/ProductIdentifiers';
import SquareTransaction from './pages/SquareTransaction';
import PractitionerPatientRoutes from './pages/PractitionerPatientRoutes';
import PhysicianPatientRoutes from './pages/PhysicianPatientRoutes';
import FullServiceHistoryPage from './pages/FullServiceHistory';
import MasqueradeBar from './components/common/MasqueradeBar';
import ScrollToTop from './components/common/ScrollToTop/ScrollToTop';
import { PHYSICIAN_ROLE, PRACTITIONER_ROLE, ADMIN_ROLE, MEDSPA_ADMIN_ROLE } from './constants/general.constants';
import { PhysicianRoutes, PractitionerRoutes, InventoryRoutes, AdministratorRoutes } from './routes/index';
import { PRACTITIONER_BASE_PATH } from './routes/practitionerRoutes';
import { PHYSICIAN_BASE_PATH } from './routes/physicianRoutes';
import { ADMIN_BASE_PATH } from './routes/administratorRoutes';
import BandwidthMeter from './components/BandwidthMeter';
import UpdatePassword from './pages/UpdatePassword';
import BrowserWarning from './components/BrowserWarning';
import {
  REGISTRATION_LITE_MEDSPA_SIGN_UP_PAGE,
  REGISTRATION_LITE_MEDSPA_SIGN_UP_WAIT_PAGE,
  REGISTRATION_PASSWORD_PAGE,
  REGISTRATION_SIGN_UP_PAGE,
} from './constants/apiRoutes.constants';
import { Signup as FlexPractitionerSignup } from './components/PractitionerRegistration/Signup';
import { Signup as LiteMedspaSignup } from './components/DashboardAdministrator/Users/Lite/Signup';
import { SetPassword as FlexPractitionerSetPassword } from './components/PractitionerRegistration/SetPassword';
import MedspaAdminRoutes, { MEDSPA_ADMIN_BASE_PATH } from './routes/medspaAdminRoutes';
import MedspaPractitionerRoutes, { MEDSPA_PROVIDER_BASE_PATH } from './routes/medspaPractitionerRoutes';
import { SignUpWait } from './components/DashboardAdministrator/Users/Lite/SignupWait';
import { USE_EHR } from './constants/actions.constants';

const theme = createMuiTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#12574d',
      light: '#086f60',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff8000',
    },
    text: {
      primary: '#3a3b3f',
    },
  },
  typography: {
    fontFamily: ['Roboto'].join(','),
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#FAFAFA',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [messinaBold, messinaRegular, messinaLight, messinaSemiBold, robotoMedium],
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#C8C8C8',
      },
    },
  },
});

const mapState = (state: any) => ({
  isAuthenticate: state.auth.isAuthenticate,
  email: state.auth.email,
  name: state.auth.name,
  userType: state.auth.userType,
  roleName: state.auth.roleName,
  isLoading: state.auth.isLoading,
  userGroupId: state.auth.userGroupId,
  permissions: state.auth.permissions,
  userId: state.auth.userId,
  userGroupName: state.auth.userGroupName,
});

const mapDispatch = ({ auth: { currentUser } }: any) => ({
  currentUser: () => currentUser(),
});

function App(props: any) {
  const {
    isAuthenticate,
    email,
    name,
    userType,
    roleName,
    isLoading,
    userGroupId,
    permissions,
    userId,
    userGroupName,
  } = props;
  const isMedspaRole = userType === ADMIN_ROLE && roleName === MEDSPA_ADMIN_ROLE;
  const isMedspaActive = process.env.REACT_APP_MEDSPA_FIELDS === 'true';
  const location = window.location.pathname;

  useEffect(() => {
    if (isAuthenticate) {
      dispatch.auth.currentUser();
      Sentry.setUser({ email });
    }
  }, [isAuthenticate]);

  useEffect(() => {
    if (isAuthenticate && email && name) {
      const identity: string | null = localStorage!.getItem('masqueradeFrom');

      let displayName = null;
      if (identity) {
        displayName = `${name} by ${identity}`;
      } else {
        displayName = name;
      }

      let pendoEnvPrefix = '';
      if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
        // identify Cohere
        try {
          Cohere.identify(
            email, // unique ID
            {
              displayName,
              email,
            }
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
      if (process.env.REACT_APP_ENVIRONMENT === 'STAGING') {
        pendoEnvPrefix = 'stg-';
      }
      if (process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') {
        pendoEnvPrefix = 'dev-';
      }
      // Identify datadog RUM - set user info
      try {
        datadogRum.setUser({
          id: userId,
          name: displayName,
          email,
          masquerade: !!identity,
          role: roleName,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      // Initialize pendo
      try {
        const [firstName, ...lastNameParts] = displayName.split(' ');
        // @ts-expect-error
        window.pendo.initialize({
          visitor: {
            id: `${pendoEnvPrefix}${email}`,
            email,
            firstName,
            lastName: lastNameParts.join(' '),
            userPermissions: permissions,
            role: roleName,
            masquerade: identity,
            userType,
            // department: '<department-goes-here>',
            // paidOrTrialUser: '<paid-or-trial-user-goes-here>',
          },
          account: {
            id: userGroupId,
            accountName: userGroupName || (userGroupId ? `<medspa-${userGroupId}>` : 'no medspa set'),
            // payingStatus: '<paying-status-goes-here>',
            // accountValue: '<account-value-goes-here>',
            // businessTier: '<business-tier-goes-here>',
            // industry: '<industry-goes-here>',
            // marketSegment: '<market-segment-goes-here>',
            // accountCreationDate: '<account-creation-date-goes-here>',
            // contractStartDate: '<contract-start-date-goes-here>',
            // renewalDate: '<renewal-date-goes-here>',
            // planPrice: '<plan-price-goes-here>',
            // accountManager: '<account-manager-goes-here>',
            // location: '<location-goes-here>',
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }, [email]);

  if (isLoading) {
    return (
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={50} style={{ marginRight: '10px' }} />
        <p>Loading...</p>
      </div>
    );
  }

  const hideBrowserWarning = [
    REGISTRATION_LITE_MEDSPA_SIGN_UP_PAGE,
    REGISTRATION_LITE_MEDSPA_SIGN_UP_WAIT_PAGE,
  ].includes(location);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!hideBrowserWarning && <BrowserWarning />}
      <BandwidthMeter />
      <Router>
        <ScrollToTop />
        <MasqueradeBar />
        <Switch>
          <Route path="/reset-password" component={() => <UpdatePassword text="Reset Password" mode="reset" />} />
          <Route path="/set-password" component={() => <UpdatePassword text="Set Password" mode="set" />} />
          <Route path={PHYSICIAN_BASE_PATH}>
            <PhysicianRoutes />
          </Route>
          <Route path={PRACTITIONER_BASE_PATH}>
            <PractitionerRoutes />
          </Route>
          <Route path="/inventory">
            <InventoryRoutes />
          </Route>
          <PrivateRoute path="/patient" role={[PRACTITIONER_ROLE, ADMIN_ROLE, PHYSICIAN_ROLE]}>
            {userType === PHYSICIAN_ROLE ? <PhysicianPatientRoutes /> : <PractitionerPatientRoutes />}
          </PrivateRoute>
          <PrivateRoute path="/history/:patientId" role={[PRACTITIONER_ROLE, ADMIN_ROLE, PHYSICIAN_ROLE]}>
            <Route component={FullServiceHistoryPage} />
          </PrivateRoute>
          <PrivateRoute path="/product-identifiers/" role={PRACTITIONER_ROLE}>
            <Route component={ProductIdentifiers} />
          </PrivateRoute>
          <PrivateRoute path="/squaretransaction" role={PRACTITIONER_ROLE}>
            <Route component={SquareTransaction} />
          </PrivateRoute>
          {isMedspaRole && isMedspaActive && (
            <PrivateRoute path={MEDSPA_ADMIN_BASE_PATH} role={MEDSPA_ADMIN_ROLE}>
              <MedspaAdminRoutes />
            </PrivateRoute>
          )}
          <PrivateRoute path={MEDSPA_PROVIDER_BASE_PATH} role={PRACTITIONER_ROLE}>
            <MedspaPractitionerRoutes />
          </PrivateRoute>

          <PrivateRoute path={ADMIN_BASE_PATH} role={ADMIN_ROLE} permission={USE_EHR}>
            <AdministratorRoutes />
          </PrivateRoute>
          <Route path={REGISTRATION_SIGN_UP_PAGE} component={FlexPractitionerSignup} />
          <Route path={REGISTRATION_PASSWORD_PAGE} component={FlexPractitionerSetPassword} />
          <Route path={REGISTRATION_LITE_MEDSPA_SIGN_UP_PAGE} component={LiteMedspaSignup} />
          <Route path={REGISTRATION_LITE_MEDSPA_SIGN_UP_WAIT_PAGE} component={SignUpWait} />
          <Route path="/external" component={LeadLandingPage} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
      <ToastContainer position="top-center" />
    </ThemeProvider>
  );
}

export default connect(mapState, mapDispatch)(App);
