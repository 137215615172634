import { Box, CircularProgress, Grid } from '@material-ui/core';
import React, { useState } from 'react';

import { EHRButton } from 'src/components/ui/v1';
import Page from 'src/components/common/Page';
import { useSpecialOffers } from 'src/hooks/queries/useSpecialOffers';
import { ShortMultipleSkeleton } from 'src/components/common/LoadingSkeleton';
import NewSpecialModal from './NewSpecialModal';
import { SpecialOfferRow } from './SpecialOfferRow';

const SpecialsListPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedSpecial, setSelectedSpecial] = useState(null);
  const [editMode, setEditMode] = useState(true);

  const { data: specials, isLoading: isLoadingSpecial } = useSpecialOffers();

  const handleOpenModal = () => {
    setEditMode(false);
    setSelectedSpecial(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditMode(true);
    setSelectedSpecial(null);
    setModalOpen(false);
  };

  if (isLoadingSpecial) {
    return (
      <Page title="Specials">
        <Grid container spacing={2} style={{ padding: '12px 15px' }}>
          <Grid item xs={12}>
            <ShortMultipleSkeleton length={10} />
          </Grid>
        </Grid>
      </Page>
    );
  }

  return (
    <Page title="Specials">
      <Box padding={2} width="100%" display="flex" flexDirection="column" alignItems="flex-end">
        <EHRButton dataCy="new-special" text="New Special" color="primary" onClick={handleOpenModal} />
        <Box height="2rem" />

        {isLoadingSpecial ? (
          <CircularProgress size={22} />
        ) : (
          specials?.map((special, index: number) => (
            <SpecialOfferRow
              key={special.id}
              special={special}
              autoOpen={index === 0}
              setShowLoadingSpinner={() => {}}
              toggleEditModal={() => setModalOpen(true)}
              setSelectedSpecial={setSelectedSpecial}
            />
          ))
        )}

        <NewSpecialModal
          openModal={isModalOpen}
          onClose={handleCloseModal}
          selectedSpecial={selectedSpecial}
          setSelectedSpecial={setSelectedSpecial}
          editMode={editMode}
        />
      </Box>
    </Page>
  );
};

export default SpecialsListPage;
