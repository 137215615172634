import React, { Fragment } from 'react';
import classnames from 'classnames';
import { capitalize, upperCase, filter } from 'lodash';
import { makeStyles, Box, Grid } from '@material-ui/core';
import ISectionProducts from '../../../../interfaces/ISectionProducts';
import { IAsset } from '../../../../interfaces/reconciliation.interfaces';
import { pluralize } from '../../../../utils/pluralize';
import { twoDecimalPlacesIfCents, isAssetAboutToExpire } from '../../../../utils/inventory.utils';

const useSectionProductsStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '16px',
    color: '#949494',
    marginBottom: theme.spacing(0.5),
  },
  productsContainer: {
    borderLeftWidth: '4px',
    borderLeftStyle: 'solid',
    paddingLeft: '28px',
    marginLeft: theme.spacing(1.125),
  },
  gridItem: {
    paddingRight: '15px',
    marginBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  box: {
    borderRadius: '10px',
    border: '1px solid #e4e7eb',
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  boxIsSelected: {
    border: '3px solid #fac980',
  },
  productName: {
    fontFamily: 'Messina Sans Bold',
    fontSize: '13px',
  },
  quantity: {
    fontFamily: 'Messina Sans Bold',
    fontSize: '20px',
    marginRight: theme.spacing(0.5),
    width: '32px',
    textAlign: 'right',
  },
  quantityNeuro: {
    marginRight: theme.spacing(0.5),
  },
  units: {
    fontFamily: 'Messina Sans Bold',
  },
  unitsNonNeuro: {
    fontFamily: 'Messina Sans Bold',
  },
  openedCount: {
    backgroundColor: '#F0F2F4',
    padding: '10px',
    fontFamily: 'Messina Sans Bold',
  },
}));

const SectionProducts: React.FC<ISectionProducts> = ({ title, products, color, onClick, suppliers }) => {
  const classes = useSectionProductsStyles();

  const Product = ({
    productName,
    quantity,
    assetLabel,
    unitLabel,
    allowPartialSale,
    currentNumberOfUnits,
    assets,
    openedCount,
  }: any) => {
    const hasProductsAtRisk = filter(assets, (asset: IAsset) => isAssetAboutToExpire(asset)).length;
    return (
      <Box
        data-cy="prodCard"
        marginBottom="12px"
        className={classnames(classes.box, { [classes.boxIsSelected]: hasProductsAtRisk })}
        onClick={() => onClick({ productName, assets, currentNumberOfUnits, quantity })}
      >
        <div style={{ padding: '10px' }}>
          <Box data-cy="prodName" color={color} className={classes.productName}>
            {upperCase(productName)}
          </Box>
          <Box display="flex" flexDirection="column">
            {allowPartialSale && (
              <>
                <Box display="flex" alignItems="baseline">
                  <Box data-cy="totalVialQuantity" component="div" color={color} className={classes.quantity}>
                    {quantity}
                  </Box>
                  <Box data-cy="totalText" color={color} className={classes.units} component="div">
                    Total {pluralize(quantity, assetLabel)}
                  </Box>
                </Box>
              </>
            )}
            {!allowPartialSale && (
              <Box>
                <Box data-cy="totalUnitQuantity" component="span" color={color} className={classes.quantity}>
                  {twoDecimalPlacesIfCents(quantity)}
                </Box>
                <Box data-cy="totalText" component="span" color={color} className={classes.unitsNonNeuro}>
                  {capitalize(pluralize(quantity, assetLabel))}
                </Box>
              </Box>
            )}
          </Box>
        </div>

        {allowPartialSale && (
          <Box display="flex" color="#969696" flexDirection="column" className={classes.openedCount}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                data-cy="openVials"
                style={{ fontSize: '18px', width: '36px', textAlign: 'right', paddingRight: '5px' }}
              >
                {openedCount}
              </div>
              Open vials
            </div>
            <Box display="flex">
              <Box
                data-cy="unitsTotal"
                style={{ width: '36px', textAlign: 'right', paddingRight: '5px' }}
                component="div"
              >
                {twoDecimalPlacesIfCents(currentNumberOfUnits)}
              </Box>
              <Box className={classes.unitsNonNeuro} component="span">
                {capitalize(pluralize(currentNumberOfUnits, unitLabel))} total
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box marginBottom={2.5}>
      <Box component="p" className={classes.title}>
        {title}
      </Box>
      <Box borderColor={color} className={classes.productsContainer}>
        <Grid container>
          {suppliers.map((supplier: any) => (
            <Grid item xs={3} className={classes.gridItem}>
              {products
                .filter(({ supplierId }: any) => supplierId === supplier.supplierId)
                .map(
                  ({
                    productName,
                    quantity,
                    unitLabel,
                    assetLabel,
                    allowPartialSale,
                    currentNumberOfUnits,
                    assets,
                    openedCount,
                  }: any) => (
                    <Product
                      key={productName}
                      productName={productName}
                      quantity={quantity}
                      unitLabel={unitLabel}
                      assetLabel={assetLabel}
                      allowPartialSale={allowPartialSale}
                      currentNumberOfUnits={currentNumberOfUnits}
                      assets={assets}
                      openedCount={openedCount}
                    />
                  )
                )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SectionProducts;
