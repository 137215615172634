import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useStyles } from './AccountCreditErrorModal.styles';

export const AccountCreditErrorModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <Box
        data-cy="modal"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        className={classes.container}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          width="100%"
          textAlign="center"
        >
          <Box mb={1}>
            <span data-cy="msg" style={{ fontFamily: 'Messina Sans Regular' }}>
              <p>
                Account credits cannot be used with retail products. To fix, remove either the product(s) or the account
                credit.
              </p>
            </span>
          </Box>
        </Box>
        <Button data-cy="noBtn" title="No" className={classes.confirmButton} onClick={onClose}>
          Ok
        </Button>
      </Box>
    </Dialog>
  );
};
