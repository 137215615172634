import { createSelector } from 'reselect';

export const getServiceVisitProgress = createSelector(
  [
    (state: any) => state.newServiceVisit.selectedServices,
    (state: any) => state.newServiceVisit.servicesUnits,
    (state: any) => state.newServiceVisit.selectedServiceGroups,
    (state: any) => state.newServiceVisit.signedConsents,
    (state: any) => state.newServiceVisit.signedStandingOrders,
    (state: any) => state.newServiceVisit.afterPhotos,
    (state: any) => state.newServiceVisit.customerPhotoConsent,
    (state: any) => state.newServiceVisit.checkout,
    (state: any) => state.newServiceVisit.productsSelected,
    (state: any) => state.newServiceVisit.creditServicesUnits,
    (state: any) => state.newServiceVisit.futureCreditUsed,
    (state: any) => state.newServiceVisit.futureVariantCreditUsed,
    (state: any) => state.newServiceVisit.variantsUnits,
    (state: any) => state.newServiceVisit.creditVariantsUnits,
    (state: any) => state.newServiceVisit.totalServicesUnits,
    (state: any) => state.newServiceVisit.totalCreditServicesUnits,
  ],
  (
    selectedServices,
    servicesUnits,
    selectedServiceGroups,
    signedConsents,
    signedStandingOrders,
    afterPhotos,
    customerPhotoConsent,
    checkout,
    productsSelected,
    creditServicesUnits,
    futureCreditUsed,
    futureVariantCreditUsed,
    variantsUnits,
    creditVariantsUnits,
    totalServicesUnits,
    totalCreditServicesUnits
  ) => ({
    selectedServices,
    servicesUnits,
    selectedServiceGroups,
    signedConsents,
    signedStandingOrders,
    afterPhotos,
    customerPhotoConsent,
    checkout,
    productsSelected,
    creditServicesUnits,
    futureCreditUsed,
    futureVariantCreditUsed,
    variantsUnits,
    creditVariantsUnits,
    totalServicesUnits,
    totalCreditServicesUnits,
  })
);

/**
 * This constant get the service visit progress and return
 * the significant keys, in order to update only valid progressive payloads
 * keys will be changed by a consistent: totalServicesUnits, totalCreditServicesUnits;
 */
export const getServiceVisitProgressToWatch = createSelector(
  [
    (state: any) => state.newServiceVisit.selectedServices,
    (state: any) => state.newServiceVisit.selectedServiceGroups,
    (state: any) => state.newServiceVisit.signedConsents,
    (state: any) => state.newServiceVisit.signedStandingOrders,
    (state: any) => state.newServiceVisit.afterPhotos,
    (state: any) => state.newServiceVisit.customerPhotoConsent,
    (state: any) => state.newServiceVisit.checkout,
    (state: any) => state.newServiceVisit.productsSelected,
    (state: any) => state.newServiceVisit.futureCreditUsed,
    (state: any) => state.newServiceVisit.futureVariantCreditUsed,
    (state: any) => state.newServiceVisit.totalServicesUnits,
    (state: any) => state.newServiceVisit.totalCreditServicesUnits,
    (state: any) => state.newServiceVisit.allowSaveProgress,
  ],
  (
    selectedServices,
    selectedServiceGroups,
    signedConsents,
    signedStandingOrders,
    afterPhotos,
    customerPhotoConsent,
    checkout,
    productsSelected,
    futureCreditUsed,
    futureVariantCreditUsed,
    totalServicesUnits,
    totalCreditServicesUnits,
    allowSaveProgress
  ) => ({
    selectedServices,
    selectedServiceGroups,
    signedConsents,
    signedStandingOrders,
    afterPhotos,
    customerPhotoConsent,
    checkout,
    productsSelected,
    futureCreditUsed,
    futureVariantCreditUsed,
    totalServicesUnits,
    totalCreditServicesUnits,
    allowSaveProgress,
  })
);
