import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  makeStyles,
  TableBody,
  Typography,
  Table,
  TableHead,
  TableRow,
  FormControlLabel,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon, Menu as MenuIcon } from '@material-ui/icons';

import { orderBy } from 'lodash';
import { formatCurrency } from 'src/utils/formatNumber';
import Page from 'src/components/common/Page';
import { ButtonStatus } from '../components/common/TableStyles/ButtonStatus';
import BreadcrumbsContainer from '../components/common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../components/common/Breadcrumb/BreadcrumbText';
import { TableCellHeader } from '../components/common/TableStyles/TableCellHeader';
import { TableCellCustom } from '../components/common/TableStyles/TableCellCustom';
import { ButtonFooter, SwitchCustom } from '../components/DashboardAdministrator/Inventory/supplyReceipt.styles';
import { dispatch } from '../rematch';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import { getPluralWord, showQuantityUnits } from '../utils/inventory.utils';
import { hasAccessTo } from '../utils/auth.utils';
import { UPDATE_RECONCILIATION_REPORT } from '../constants/actions.constants';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '1.125rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
  },
}));

const BoxIcon = {
  display: 'inline-flex',
  border: '1px solid #e5efed',
  cursor: 'pointer',
};

const TextAreaStyles = {
  width: '100%',
  borderRadius: '3px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: '#fff',
  padding: '13px 14px',
  margin: '8px 0',
  fontFamily: 'Messina Sans Regular',
  fontSize: '14px',
};

const waiveAllStyles = (disabled: boolean) => ({
  height: 'auto',
  fontFamily: 'Messina Sans SemiBold',
  backgroundColor: '#fff',
  color: disabled ? undefined : '#12574d',
  border: '1px solid rgba(0, 0, 0, 0.12)',
});

interface IViewReportParams {
  reportId: string;
}

const ViewReport = () => {
  const classes = useStyles();
  const history = useHistory();
  const { reportId } = useParams<IViewReportParams>();

  const [waived, setWaived] = useState<any>({});
  const [adminNote, setAdminNote] = useState<string>('');

  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const {
    forceSignature,
    reconciliationReportPractitionerName,
    reconciliationReportCreatedAt,
    reconciliationReportAdminNote,
    reconciliationReportStatus,
    productsList,
    effects: {
      viewReport: { getReport: isLoading },
    },
  } = useSelector(({ viewReport, loading }: any) => ({ ...viewReport, ...loading }));
  const { reconciliationReports } = useSelector((state: any) => state.reconciliationReport);
  const editionDisabled =
    reconciliationReportStatus === 'closed' || !hasAccessTo(UPDATE_RECONCILIATION_REPORT, permissions);

  useEffect(() => {
    dispatch({
      type: 'viewReport/getReport',
      payload: { reconciliationReports, reportId },
    });

    return () => {
      dispatch.viewReport.setReset();
    };
  }, []);

  useEffect(() => {
    const waivedAssets: any = {};
    productsList.forEach(({ serviceId, waived: productWaived }: any) => {
      waivedAssets[serviceId] = (waivedAssets[serviceId] || 0) + productWaived;
    });

    setWaived(waivedAssets);
    setAdminNote(reconciliationReportAdminNote);
  }, [productsList]);

  const handleSignature = () => {
    dispatch.viewReport.setForceSignature();
  };

  const handleWaived = (id: number, count: number) => {
    const newWaived = { ...waived };
    newWaived[id] = count;

    setWaived(newWaived);
  };

  const submitReconciliationReport = () => {
    dispatch({
      type: 'viewReport/saveAdminReport',
      payload: { reportId, waived, forceSignature, adminNote },
    });

    history.goBack();
  };

  const prepareNameForBreadcrumb = (recReportPractitionerName: string, recReportCreatedAt: string) => (
    <Box>
      {recReportPractitionerName} -
      <Typography component="span" color="primary" style={{ fontSize: '14px', paddingLeft: '4px' }}>
        {moment(recReportCreatedAt).format('MM/DD/YYYY')}
      </Typography>
    </Box>
  );

  const goToProductsIdentifiers = ({ serviceId }: any) => {
    history.push(`/administrator/reconciliation-reports/${reportId}/${serviceId}/products`);
  };

  const Breadcrumb = (
    <Box mb="17px">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Inventory" />
        <BreadcrumbText text="Reconciliation Report" linkTo="/administrator/reconciliation-reports" />
        <BreadcrumbText
          text={prepareNameForBreadcrumb(reconciliationReportPractitionerName, reconciliationReportCreatedAt)}
        />
      </BreadcrumbsContainer>
    </Box>
  );

  const Header = (
    <Box>
      <Box paddingX={4} paddingBottom={2} display="flex" alignItems="center">
        <Box mr="1.288rem">
          <ArrowBackIosIcon
            onClick={() => history.goBack()}
            style={{ fontSize: 15, marginTop: 2, cursor: 'pointer' }}
          />
        </Box>
        <Typography className={classes.title}>
          Reconciliation report - {reconciliationReportPractitionerName} -{' '}
          {moment(reconciliationReportCreatedAt).format('MM/DD/YYYY')}
        </Typography>
      </Box>
      <Box paddingX={4} border={1} borderColor="#e7e7e7">
        <Box component="p" fontSize="14px" color="#393b3f" letterSpacing="-0.05">
          Reconciliation report
        </Box>
      </Box>
    </Box>
  );

  const MyTable = (
    <Box paddingX={4}>
      <Table style={{ border: '1px solid #e7e7e7', borderTop: 'none' }}>
        <TableHead>
          <TableRow>
            <TableCellHeader>Product</TableCellHeader>
            <TableCellHeader>Expected</TableCellHeader>
            <TableCellHeader>Counted</TableCellHeader>
            <TableCellHeader>Marked As Empty</TableCellHeader>
            <TableCellHeader>Expired</TableCellHeader>
            <TableCellHeader>Missing</TableCellHeader>
            <TableCellHeader>IDs</TableCellHeader>
            <TableCellHeader>Practitioner Note</TableCellHeader>
            <TableCellHeader>Waived</TableCellHeader>
            <TableCellHeader>Final Missing</TableCellHeader>
            <TableCellHeader>Cost</TableCellHeader>
            <TableCellHeader />
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(productsList, ['product'], ['asc']).map((item: any) => {
            const canWaive = item.allowPartialSale ? item.missingUnits !== 0 : item.missing !== 0;

            let missingResult = 0;
            if (item.allowPartialSale) {
              missingResult = item.missingUnits - waived[item.serviceId];
            } else {
              missingResult = item.missing - waived[item.serviceId];
            }

            const finalMissing = missingResult > 0 ? missingResult : 0;
            return (
              <TableRow key={item.serviceId}>
                <TableCellCustom>{item.product}</TableCellCustom>
                <TableCellCustom width="110">
                  {showQuantityUnits({ ...item, quantity: item.expected, units: item.expectedUnits })}
                </TableCellCustom>
                <TableCellCustom width="110">
                  {showQuantityUnits({ ...item, quantity: item.counted, units: item.countedUnits })}
                </TableCellCustom>
                <TableCellCustom>
                  {showQuantityUnits({
                    ...item,
                    quantity: item.markedAsEmpty,
                    units: item.markedAsEmptyUnits,
                    showRedText: true,
                  })}
                </TableCellCustom>
                <TableCellCustom>
                  {showQuantityUnits({
                    ...item,
                    quantity: item.expired,
                    units: item.expiredUnits,
                    showRedText: true,
                  })}
                </TableCellCustom>
                <TableCellCustom>
                  {showQuantityUnits({ ...item, quantity: item.missing, units: item.missingUnits, showRedText: true })}
                </TableCellCustom>
                <TableCellCustom>
                  <Box style={BoxIcon} onClick={() => goToProductsIdentifiers(item)}>
                    <MenuIcon />
                  </Box>
                </TableCellCustom>
                <TableCellCustom>
                  <Box component="span" color="#a9a9a9" fontFamily="Messina Sans Regular">
                    {item.practitionerNote}
                  </Box>
                </TableCellCustom>
                <TableCellCustom width={100}>
                  <TextField
                    disabled={!canWaive || editionDisabled}
                    inputProps={{
                      style: { textAlign: 'center', padding: '12px 0' },
                      max: item.allowPartialSale ? item.missingUnits : item.missing,
                      min: 0,
                    }}
                    type="number"
                    variant="outlined"
                    value={waived[item.serviceId]}
                    onChange={(ev: any) => handleWaived(item.serviceId, +ev.target.value)}
                    fullWidth
                  />
                </TableCellCustom>
                <TableCellCustom>
                  <span style={{ color: finalMissing > 0 ? '#FF5252' : 'black' }}>
                    {finalMissing}
                    <span> </span>
                    {item.allowPartialSale && getPluralWord('Unit', finalMissing !== 1)}
                  </span>
                </TableCellCustom>
                <TableCellCustom>{formatCurrency(item.cost < 0 ? 0 : item.cost)}</TableCellCustom>
                <TableCellCustom width={155}>
                  {canWaive && (
                    <ButtonStatus
                      disabled={editionDisabled}
                      onClick={() =>
                        handleWaived(item.serviceId, item.allowPartialSale ? item.missingUnits : item.missing)
                      }
                      style={waiveAllStyles(editionDisabled)}
                    >
                      WAIVE ALL
                    </ButtonStatus>
                  )}
                </TableCellCustom>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );

  const Footer = hasAccessTo(UPDATE_RECONCILIATION_REPORT, permissions) && (
    <Box width="100%" paddingX={4} height="67px" display="flex" alignItems="center">
      <Box mr="auto" display="flex">
        <ButtonFooter
          variant="outlined"
          color="primary"
          style={{ width: '120px', borderColor: 'rgba(57, 59, 63, 0.12)' }}
          onClick={() => history.push('/administrator/reconciliation-reports')}
        >
          Cancel
        </ButtonFooter>
      </Box>
      <Box display="flex">
        <Box mr="10px" display="flex">
          <FormControlLabel
            control={
              <SwitchCustom
                checked={forceSignature}
                disabled={editionDisabled}
                onChange={() => handleSignature()}
                name="forceSignature"
              />
            }
            name="forceSignature"
            labelPlacement="start"
            label="Force Signature"
          />
        </Box>
        {!editionDisabled && (
          <ButtonFooter
            onClick={submitReconciliationReport}
            variant="contained"
            color="primary"
            disableElevation
            style={{ width: '150px' }}
          >
            Submit
          </ButtonFooter>
        )}
      </Box>
    </Box>
  );

  const AdminNotes = (
    <Box paddingX={4} marginY={2}>
      <Box paddingX={2} display="flex" alignItems="start" border={1} borderColor="#e4e7eb">
        <Box component="p" width={100} mr={2} color="#393b3f" fontFamily="Messina Sans Regular">
          Admin Notes
        </Box>
        <TextareaAutosize
          style={TextAreaStyles}
          rowsMin={2}
          onChange={(ev: any) => setAdminNote(ev.target.value)}
          disabled={editionDisabled}
          value={adminNote}
          placeholder="This is a private note."
        />
      </Box>
    </Box>
  );

  return (
    <Page title="View Report">
      {isLoading ? (
        <MultipleSkeleton />
      ) : (
        <Box width="100%">
          {Breadcrumb}
          {Header}
          {MyTable}
          {AdminNotes}
          {Footer}
        </Box>
      )}
    </Page>
  );
};

export default ViewReport;
