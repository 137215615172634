import { useEffect } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import { MEDSPA_PATIENTS_VIEW, MEDSPA_PROVIDER_PATIENTS_VIEW } from '../../constants/reactQuery.keys';
import PatientView from '../../services/PatientView';
import Patients from '../../services/Patients';

import { SNACKBAR_INFO, SNACKBAR_SUCCESS } from '../../constants/general.constants';
import { ICustomerViewParams } from '../../interfaces/CustomerParams';
import { showSnackbar } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';

export function useMedspaPatients(userGroupId: string, paramsQuery: ICustomerViewParams) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([MEDSPA_PATIENTS_VIEW, userGroupId, JSON.stringify(paramsQuery)]);
  }, [queryClient, userGroupId, paramsQuery]);

  const fetchPatients = ({ pageParam = 1 }) =>
    PatientView.getMedspaPatients(userGroupId, { ...paramsQuery, page: pageParam }).then((response) => ({
      items: response.customers,
      nextPage: response.meta.currentPage < response.meta.totalPages ? response.meta.currentPage + 1 : undefined,
      totalCount: response.meta.totalCount,
    }));

  const query = useInfiniteQuery([MEDSPA_PATIENTS_VIEW, userGroupId, JSON.stringify(paramsQuery)], fetchPatients, {
    getNextPageParam: (lastPage) => lastPage.nextPage,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const patients = query.data?.pages.reduce((acc, page) => [...acc, ...page.items], []) || [];
  const currentPage = query.data?.pages[query.data?.pages.length - 1]?.nextPage ?? 1;
  const totalCount = query.data?.pages[0]?.totalCount ?? 0;

  return {
    ...query,
    data: {
      patients,
      currentPage,
      totalCount,
    },
  };
}

export function useMedspaPracPatients(providerId: string | number, paramsQuery: ICustomerViewParams) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([MEDSPA_PROVIDER_PATIENTS_VIEW, providerId, JSON.stringify(paramsQuery)]);
  }, [queryClient, providerId, paramsQuery]);

  const fetchPatients = ({ pageParam = 1 }) =>
    PatientView.getMedspaProviderPatients(providerId, { ...paramsQuery, page: pageParam }).then((response) => ({
      items: response.customers,
      nextPage: response.meta.currentPage < response.meta.totalPages ? response.meta.currentPage + 1 : undefined,
      totalCount: response.meta.totalCount,
    }));

  const query = useInfiniteQuery(
    [MEDSPA_PROVIDER_PATIENTS_VIEW, providerId, JSON.stringify(paramsQuery)],
    fetchPatients,
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );

  // @ts-ignore
  const patients = query.data?.pages.reduce((acc, page) => [...acc, ...page.items], []) || [];
  const currentPage = query.data?.pages[query.data?.pages.length - 1]?.nextPage ?? 1;
  const totalCount = query.data?.pages[0]?.totalCount ?? 0;

  return {
    ...query,
    data: {
      patients,
      currentPage,
      totalCount,
    },
  };
}

export const useCreateMedSpaPatient = (userGroupId: string, successCallback?: () => void, showSuccessMessage = true) =>
  useMutation((patientData: FormData) => Patients.createMedspaPatient(userGroupId, patientData), {
    onSuccess: () => {
      if (showSuccessMessage) {
        showSnackbar(compile('patient.created'), SNACKBAR_SUCCESS);
      }

      successCallback?.();
    },
    onError: (error: any) => {
      let errorMessage = 'Failed to create patient';

      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.message) {
        errorMessage = error.message;
      }

      showSnackbar(errorMessage, SNACKBAR_INFO);
    },
  });

export const useCreateMedSpaProviderPatient = (
  providerId: number | string,
  successCallback?: () => void,
  showSuccessMessage = true
) =>
  useMutation((patientData: FormData) => Patients.createMedspaProviderPatient(providerId, patientData), {
    onSuccess: () => {
      if (showSuccessMessage) {
        showSnackbar(compile('patient.created'), SNACKBAR_SUCCESS);
      }

      successCallback?.();
    },
    onError: (error: any) => {
      let errorMessage = 'Failed to create patient';

      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.message) {
        errorMessage = error.message;
      }

      showSnackbar(errorMessage, SNACKBAR_INFO);
    },
  });
