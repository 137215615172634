/* eslint-disable @typescript-eslint/indent */
/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { useContext, useState, useEffect } from 'react';
import { Box, IconButton, TableRow, TableCell, Dialog, Button, Typography } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon, Error as ErrorIcon } from '@material-ui/icons';
import { round } from 'lodash';
import { formatCurrency } from 'src/utils/formatNumber';
import { InventoryOrderService } from 'src/interfaces/IInventoryOrder';
import { useUpdateInventoryOrderItem } from 'src/hooks/queries/useInventoryOrders';
import EHRNumberSelect from 'src/components/ui/v1/EHRNumberSelect';
import { useStyle } from '../inventoryOrder.styles';
import { OrderContext } from '../index';
import OrderingTooltip from '../OrderingTooltip';

const ServiceSelectionRow = ({
  name,
  serviceId,
  quantity,
  currentTotal,
  average,
  recommendation,
  notes,
  unitPrice = 0,
  startingUnits = 1,
  orderMultiplier = 1,
  pricePerBox,
  displayBudgetWarning,
  allowPartialSale,
}: InventoryOrderService) => {
  const [warningDialogueOpen, setWarningDialogueOpen] = useState<boolean>(false);
  const [userQuantity, setUserQuantity] = useState<number>(0);
  const [userNote, setUserNote] = useState<string>('');
  const [isLoadingRow, setIsLoadingRow] = useState<boolean>(false);

  const {
    orderData: { id: inventoryOrderId = 0 } = {},
    totalBudget,
    budgetUsed,
    blockProgress,
    isFetching,
  } = useContext(OrderContext) || {};

  const { mutateAsync: updateOrderItem } = useUpdateInventoryOrderItem({ inventoryOrderId, serviceId });

  const isUpdating = (isLoadingRow || isFetching) && userQuantity !== undefined && userQuantity !== quantity;

  const itemValue = (qty: number = 1) => (allowPartialSale ? startingUnits : 1) * unitPrice * qty;

  const isOverBudget = !totalBudget || (!!budgetUsed && budgetUsed > Number(totalBudget));

  const processChanges = async (newQuantity: number, updateUserInput: boolean = true): Promise<void> => {
    const qtyModulo = newQuantity % orderMultiplier;
    const adjustedQty = newQuantity - qtyModulo;

    if (updateUserInput || adjustedQty !== newQuantity) {
      setUserQuantity(newQuantity);
    }

    setIsLoadingRow(true);

    const isAdjustedQtyGreaterThanRecommendation = (adjustedQty ?? 0) > recommendation;

    const isPotentialOverBudget = () => {
      const addedValue = itemValue(adjustedQty) - itemValue(quantity);
      return !totalBudget && totalBudget !== 0 ? false : addedValue + (budgetUsed ?? 0) > totalBudget;
    };

    if (!userNote && isAdjustedQtyGreaterThanRecommendation) {
      setWarningDialogueOpen(true);
    } else {
      if (!isOverBudget) {
        if (isPotentialOverBudget()) {
          displayBudgetWarning?.();
        }
      }
      try {
        await updateOrderItem({ quantity: adjustedQty, notes: userNote });
      } catch {
        setUserQuantity(quantity);
      }
    }
    setIsLoadingRow(false);
  };

  const cancelWarningOverride = () => {
    setUserQuantity(quantity);
    setWarningDialogueOpen(false);
  };

  const handleWarningOverride = (): void => {
    processChanges(userQuantity, false);
    setWarningDialogueOpen(false);
  };

  const addItem = (event: React.MouseEvent): void => {
    event.preventDefault();
    processChanges(userQuantity + orderMultiplier);
  };

  const removeItem = (event: React.MouseEvent): void => {
    event.preventDefault();
    const newQuantity = userQuantity - orderMultiplier;
    processChanges(newQuantity > 0 ? newQuantity : 0);
  };

  const classes = useStyle();

  useEffect(() => {
    setUserQuantity(quantity);
  }, [quantity]);

  useEffect(() => {
    setUserNote(notes ?? '');
  }, [notes]);

  useEffect(() => {
    blockProgress?.((!!userQuantity || userQuantity === 0) && quantity !== userQuantity);
  }, [quantity, userQuantity, blockProgress]);

  const cells = [
    {
      className: classes.nameCol,
      testId: 'name',
      content: (
        <>
          {name}
          {quantity > recommendation && <ErrorIcon />}
        </>
      ),
    },
    {
      className: classes.centerCell,
      testId: 'current',
      content: currentTotal,
    },
    {
      className: classes.centerCell,
      testId: 'orderMultiplier',
      content: orderMultiplier,
    },
    {
      className: classes.centerCell,
      testId: 'boxes',
      content: round(userQuantity / orderMultiplier),
    },
    {
      className: classes.recCell,
      testId: 'recommendation',
      content: (
        <>
          {recommendation}
          <OrderingTooltip title={`Based on your estimated monthly usage which is ${round(average)}`} />
        </>
      ),
    },
    {
      className: classes.centerCell,
      testId: 'pricePerBox',
      content: formatCurrency(pricePerBox ?? itemValue()),
    },
  ];

  return (
    <TableRow
      className={[classes.recRow, classes.pulse2].join(' ')}
      data-ignore={quantity < 1}
      data-loading={isUpdating}
      data-testid={`service ${serviceId}`}
    >
      {cells.map(({ className, testId, content }) => (
        <TableCell key={testId} className={className} data-testid={`service ${serviceId} ${testId}`}>
          {content}
        </TableCell>
      ))}

      <TableCell>
        <Box className={classes.inputCell} style={{ width: 'min-content', margin: '0 auto' }}>
          <IconButton
            disabled={!userQuantity || isLoadingRow}
            onClick={removeItem}
            data-testid={`service ${serviceId} remove`}
            style={{
              borderRadius: '6px',
              width: '32px',
              height: '32px',
              backgroundColor: '#E8EEED',
              color: '#1D584D',
              margin: 'auto',
            }}
          >
            <RemoveIcon />
          </IconButton>
          <EHRNumberSelect
            value={`${userQuantity}`}
            rangeFilter={(option: number) => option % orderMultiplier === 0}
            end={99}
            onChange={(value) => processChanges(value, true)}
            data-testid={`service ${serviceId} input`}
            dataCy={`service ${serviceId} input`}
          />
          <IconButton
            disabled={isLoadingRow}
            onClick={addItem}
            data-testid={`service ${serviceId} add`}
            style={{
              borderRadius: '6px',
              width: '32px',
              height: '32px',
              backgroundColor: '#E8EEED',
              color: '#1D584D',
              margin: 'auto',
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>

        <Dialog open={warningDialogueOpen}>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.warningModal}
            data-testid={`service ${serviceId} warning`}
          >
            <Box>
              <Typography variant="h5">Warning for {name}</Typography>
              <Typography>Seems like you exceeded the recommended quantity</Typography>
            </Box>
            <Box marginTop={20}>
              <textarea
                placeholder="Please define a reason"
                defaultValue={userNote}
                onChange={(event: React.ChangeEvent): void =>
                  setUserNote((event.currentTarget as HTMLInputElement).value || '')
                }
              />
            </Box>
            <Box display="flex" flexDirection="row">
              <Button data-testid={`service ${serviceId} cancel note`} onClick={cancelWarningOverride}>
                Cancel
              </Button>
              <Button
                data-testid={`service ${serviceId} submit note`}
                disabled={!userNote}
                onClick={handleWarningOverride}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};

export default ServiceSelectionRow;
