import { makeStyles } from '@material-ui/core/';

export const useStyles = makeStyles(() => ({
  completeSectionVisible: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  completeSectionHidden: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  lastUpdateLabel: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.62,
    letterSpacing: 'normal',
    color: '#000000',
    marginTop: '10px',
  },
  noteSection: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  noteInput: {
    width: '100%',
  },
  input: {
    fontFamily: 'Messina Sans Regular',
    paddingRight: '0',
  },
  noteLabel: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.93,
    letterSpacing: 'normal',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  note: {
    fontFamily: 'Messina Sans Regular',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0',
    padding: '0 8px',
    alignItems: 'center',
  },
  addNoteIcon: {
    marginRight: '10px',
    opacity: 0.39,
    color: '#000000',
  },
  deleteIcon: {
    marginLeft: '10px',
    color: '#c62828',
    cursor: 'pointer',
  },
  containerForm: {
    margin: '50px 0',
    paddingBottom: '20px',
  },
  noMarginTop: {
    marginTop: '0',
  },
  titleForm: {
    width: '100%',
    padding: '1rem',
    backgroundColor: 'rgb(231, 238, 237)',
    borderRadius: '3px',
    textTransform: 'uppercase',
    display: 'inline-block',
    marginBottom: '30px',
  },
  containerAbsolute: {
    zIndex: 1500,
    position: 'absolute',
    width: '40%',
    height: 'auto',
    right: '1rem',
    top: '16rem',
  },
  dateCard: {
    width: '100%',
    display: 'inline-block',
    textAlign: 'right',
    color: '#c3c3c3',
    position: 'relative',
    marginTop: '15px',
    padding: '6px',
  },
  btnMore: {
    backgroundColor: 'transparent !important',
    border: 'none !important',
    fontSize: '1rem',
    margin: '1rem 0',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  bottomBtn: {
    cursor: 'pointer',
    width: '100%',
    textAlign: 'right',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    margin: '20px 0',
  },
  btn: {
    textTransform: 'none',
    color: '#fff !important',
    backgroundColor: '#173f61 !important',
    padding: '0.15rem 1rem !important',
  },
  btnSecond: {
    textTransform: 'none',
    border: '1px solid #173f61 !important',
    color: '#173f61 !important',
    backgroundColor: '#fff !important',
    padding: '0.15rem 1rem !important',
    marginRight: '10px',
  },
  imgArrow: {
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
}));
