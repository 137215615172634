import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Box,
  Typography,
  Avatar,
  makeStyles,
  Button,
  MenuItem,
  Menu,
  withStyles,
  Divider,
  Link,
} from '@material-ui/core';
import { ExpandMore, ArrowBack } from '@material-ui/icons';

import { connect, useSelector } from 'react-redux';
import { isTablet, isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { RootState, dispatch } from '../../rematch';

import {
  REFER_A_PROVIDER_LINK,
  BG_COLOR,
  PRACTITIONER_ROLE,
  DEFAULT_AVATAR_IMAGE,
  PORTRAIT_RESOURCES_LINK,
  PREFERRED_PARTNER_DIRECTORY_DOCUMENT,
} from '../../constants/general.constants';

import Notifications from '../Notifications';
import {
  PRACTITIONER_CALENDAR_CONFIGURATION_PATH,
  PRACTITIONER_CONFIGURATION_PATH,
  PRACTITIONER_MESSAGING_INDEX_PATH,
  PRACTITIONER_ACCOUNT_SETTINGS,
  PRACTITIONER_DOCUMENTS,
  PRACTITIONER_GFE_MANAGEMENT_PATH,
} from '../../routes/practitionerRoutes';
import { hasAccessTo } from '../../utils/auth.utils';
import {
  PRACTITIONER_BOOK_APPOINTMENT_UPDATE,
  DOCUMENT_READ,
  READ_NOTIFICATION_BELL,
  READ_POLICIES,
  READ_PORTRAIT_RESOURCES,
  READ_REFER_PROVIDER,
  CONSENT_UPDATE,
  STANDING_ORDER_UPDATE,
} from '../../constants/actions.constants';

const useStyles = makeStyles({
  avatar: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
  },
  title: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '20px !important',
  },
});

const HeaderButton = withStyles(() => ({
  root: {
    padding: 0,
    minWidth: '32px',
  },
}))(Button);

const mapDispatch = ({ auth: { logout } }: any) => ({
  logout: () => logout(),
});

const Header = () => {
  const classes = useStyles();
  const history = useHistory();

  const { userType, avatarUrl = DEFAULT_AVATAR_IMAGE, user } = useSelector(({ auth }: RootState) => auth);
  const isPractitioner = userType === PRACTITIONER_ROLE;
  const bgColorByUserType = isPractitioner ? BG_COLOR : '#fff';
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const [anchorEl, setAnchorEl] = useState(null);

  const goBack = () => history.goBack();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch.auth.logout();
  };

  const goToConfigurationTab = () => {
    history.push(PRACTITIONER_CONFIGURATION_PATH);
  };

  const goToReferProvider = () => {
    const newTab = window.open(REFER_A_PROVIDER_LINK, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  const goToPolicyFile = (policy: string) => {
    try {
      // eslint-disable-next-line import/no-dynamic-require, global-require, import/no-dynamic-require
      return require(`../../assets/policies/${policy}`).default;
    } catch (er) {
      return undefined;
    }
  };

  const goToMessageSetting = () => {
    history.push(PRACTITIONER_MESSAGING_INDEX_PATH);
  };

  const goToCalendarConfiguration = () => {
    history.push(PRACTITIONER_CALENDAR_CONFIGURATION_PATH);
  };

  const goToGFEManagement = () => {
    history.push(PRACTITIONER_GFE_MANAGEMENT_PATH);
  };

  const goToPortraitResources = () => {
    const newTab = window.open(PORTRAIT_RESOURCES_LINK, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  const goToPractitionerDocuments = () => {
    history.push(PRACTITIONER_DOCUMENTS);
  };

  const headerTitle = isPractitioner ? (
    <div data-cy="userDashboardtittle">Practitioner Dashboard</div>
  ) : (
    <>Physician Dashboard</>
  );
  const practitionerMenuItems = (
    <>
      <MenuItem>
        <Link href={PRACTITIONER_ACCOUNT_SETTINGS}>Account Settings</Link>
      </MenuItem>
      {hasAccessTo(CONSENT_UPDATE, permissions) && hasAccessTo(STANDING_ORDER_UPDATE, permissions) && (
        <MenuItem onClick={goToConfigurationTab}>Consents/Standing Orders configuration</MenuItem>
      )}
      {hasAccessTo(READ_POLICIES, permissions) && (
        <>
          <MenuItem>
            <a
              style={{ textDecoration: 'none', color: 'black' }}
              href={goToPolicyFile('inventory_policy.pdf')}
              target="_blank"
              rel="noreferrer"
            >
              Inventory Policy
            </a>
          </MenuItem>
          <MenuItem>
            <a
              style={{ textDecoration: 'none', color: 'black' }}
              href={goToPolicyFile('payment_policy.pdf')}
              target="_blank"
              rel="noreferrer"
            >
              Payment Policy
            </a>
          </MenuItem>
        </>
      )}
      <MenuItem>
        <a
          style={{ textDecoration: 'none', color: 'black' }}
          href={PREFERRED_PARTNER_DIRECTORY_DOCUMENT}
          target="_blank"
          rel="noreferrer"
        >
          Preferred Partner Directory
        </a>
      </MenuItem>
      {hasAccessTo(PRACTITIONER_BOOK_APPOINTMENT_UPDATE, permissions) && (
        <MenuItem onClick={goToCalendarConfiguration}>Calendar configuration</MenuItem>
      )}
      <MenuItem onClick={goToMessageSetting}>Care Messages Settings</MenuItem>
      {hasAccessTo(READ_PORTRAIT_RESOURCES, permissions) && (
        <MenuItem onClick={goToPortraitResources}>Medical Resources</MenuItem>
      )}
      {hasAccessTo(DOCUMENT_READ, permissions) && <MenuItem onClick={goToPractitionerDocuments}>Documents</MenuItem>}
      <MenuItem onClick={goToGFEManagement}>GFE Management</MenuItem>
      <Divider />
    </>
  );

  return (
    <Grid item xs={12}>
      <Paper>
        <Box display="flex" px={2} py={1} style={{ backgroundColor: bgColorByUserType }}>
          <Box className={classes.title} display="flex" alignItems="center" flexGrow={1}>
            {(isTablet || isMobile) && (
              <Box mt={1} mr={2.125}>
                <ArrowBack onClick={goBack} />
              </Box>
            )}
            {headerTitle}
          </Box>
          <Box display="flex" alignItems="center">
            {hasAccessTo(READ_REFER_PROVIDER, permissions) && (
              <Box mr={1}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={goToReferProvider}
                  data-cy="referAProviderButton"
                >
                  Refer a Provider
                </Button>
              </Box>
            )}
            {isPractitioner && hasAccessTo(READ_NOTIFICATION_BELL, permissions) && (
              <Box>
                <Notifications />
              </Box>
            )}
            <Box mx={2}>
              <Typography
                style={{ color: '#000', fontFamily: 'Messina Sans Regular', fontSize: '14px' }}
                data-cy="practNameAvatar"
              >
                {user.professionalName || `${user.firstName} ${user.lastName}`}
              </Typography>
            </Box>
            <HeaderButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <Avatar
                className={classes.avatar}
                alt={user.professionalName}
                src={avatarUrl}
                data-cy="practAvatarLetter"
              />
              <ExpandMore />
            </HeaderButton>
            <Menu
              id="simple-menu"
              style={{ marginTop: '32px' }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {isPractitioner && practitionerMenuItems}
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default connect(mapDispatch)(Header);
