/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InfiniteData, useQueryClient } from 'react-query';
import moment, { Moment } from 'moment';
import { RootState } from 'src/rematch';
import { AxiosError } from 'axios';
import { IPractitionerDashboardGraphs } from 'src/services/PractitionersDashboard';
import { useStyles } from './practitionerDashboard.styles';
import Adjustments from './Adjustments';
import Transactions from './Transactions';
import ChartsDashboard from './ChartsDashboard';
import EarningsCalculator from './EarningsCalculator';
import { EARNINGS_DASHBOARD, GRAPHICS_DASHBOARD, TRANSACTIONS_DASHBOARD } from '../../../../constants/reactQuery.keys';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { TabFrame, TabFrameProps, TabDataObject } from '../TabFrame';
import PortraitEdgeChart from './JointInvestments';
import MonthlyBlockers from './MonthlyBlockers';
import { READ_FLOOR_PRICE, READ_JOINT_INVESTMENT } from '../../../../constants/actions.constants';
import { PORTRAIT_FLEX, PORTRAIT_LITE, months } from '../../../../constants/general.constants';
import { FlexTransactions } from './FlexTransactions';
import {
  PossibleTransactionResponse,
  useEarningsDashboard,
  useGraphsDashboard,
  usePossibleTransactionsDashboard,
} from '../../../../hooks/queries/usePractitionersDashboard';
import compile from '../../../../utils/toastMessagesCompiler';

const FORMAT_TO_SHOW_INPUT = 'MMM YYYY';
const FORMAT_TO_SEND_TO_API = 'MM-YYYY';

const BASE_PATH = '/practitioner/dashboard';
const EARNINGS_CALCULATOR = 'Earnings Calculator';
const JOINT_INVESTMENTS = 'Joint Investments';

const initialData = {
  earningsTiers: { services: [], retail: [], tips: [], tier5Percent: [], tier10Percent: [] },
  totals: {
    services: 0,
    retail: 0,
    tips: 0,
    totalEarnings: 0,
    tier5Percent: 0,
    tier10Percent: 0,
    amountBelowPriceFloor: 0,
  },
  baseCalculations: {
    totalServicesWithDiscount: 0,
  },
  firstReport: moment().format('YYYY-MM-DD'),
};

const initialSerieValues = Array(12).fill(0);

export const initialChartData = {
  revenue: {
    xAxisLabels: [...months],
    series: [
      {
        label: 'Revenue',
        data: [...initialSerieValues],
      },
    ],
  },
  earnings: {
    xAxisLabels: [...months],
    series: [
      {
        label: 'Earnings',
        data: [...initialSerieValues],
      },
    ],
  },
  appointments: {
    xAxisLabels: [...months],
    series: [
      {
        label: 'Appointments',
        data: [...initialSerieValues],
      },
    ],
  },
  appointmentsAverage: {
    xAxisLabels: [...months],
    series: [
      {
        label: 'Appointments Average',
        data: [...initialSerieValues],
      },
    ],
  },
};

const ERROR_GETTING_EARNINGS = compile('generic.error_message', {
  action: 'getting',
  element: 'earnings',
});

const initialFilter = {
  search: '',
  page: 1,
  limit: 20,
  month: '',
  year: '',
  sort: '',
  sortDirection: '',
  status: '',
  practitionerId: '',
};

const oldestRecordFilter = {
  search: '',
  page: 1,
  per: 1,
  month: '',
  year: '',
  sort: 'checkouts.created_at',
  sortDirection: 'asc',
};

const PractitionerDashboardTab = () => {
  const practitioner = useSelector(({ auth }: RootState) => auth);
  const classes = useStyles();
  const queryClient = useQueryClient();
  const permissions = useSelector(({ auth }: RootState) => auth.permissions);
  const [searchQuery, setSearchQuery] = useState<Record<string, Moment | string | number | null>>(initialFilter);

  const enabledPriceFloor = hasAccessTo(READ_FLOOR_PRICE, permissions);
  const enabledPortraitEdge = hasAccessTo(READ_JOINT_INVESTMENT, permissions);

  const [selectedDate, setSelectedDate] = useState<Moment>(moment());

  const [totalServicesWithDiscount, setTotalServicesWithDiscount] = useState(0);
  const isFlexOrLite =
    !!practitioner.roleName && (practitioner.roleName === PORTRAIT_FLEX || practitioner.roleName === PORTRAIT_LITE);

  const {
    data: earnings = initialData,
    isLoading: isLoadingEarnings,
    isError,
    error,
  } = useEarningsDashboard(selectedDate.format(FORMAT_TO_SEND_TO_API));

  const { data: chartData = initialChartData, isLoading: isLoadingChartData } = useGraphsDashboard(
    selectedDate.format(FORMAT_TO_SEND_TO_API)
  );

  const {
    results,
    isLoading,
    isFetching,
    isError: isTransactionError,
    hasNextPage,
    fetchNextPage,
  } = usePossibleTransactionsDashboard(searchQuery);

  const { results: oldestRecords, isLoading: isLoadingOldestRecords } =
    usePossibleTransactionsDashboard(oldestRecordFilter);

  const [minDate, setMinDate] = useState<Moment>(moment(earnings.firstReport));
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (isError) {
      const err = error as AxiosError;
      const dateForInput = err?.response?.data?.firstReport ? moment(err?.response?.data?.firstReport) : moment();
      setMinDate(dateForInput);
      setErrorMessage(err?.response?.data?.error || ERROR_GETTING_EARNINGS);
      return;
    }

    if (earnings) {
      const dateForInput = earnings.firstReport ? moment(earnings.firstReport) : moment();
      setMinDate(dateForInput);
    }
  }, [earnings, error, isError]);

  useEffect(
    () => () => {
      queryClient.invalidateQueries([TRANSACTIONS_DASHBOARD]);
      queryClient.invalidateQueries([EARNINGS_DASHBOARD]);
      queryClient.invalidateQueries([GRAPHICS_DASHBOARD]);
    },
    []
  );

  if (!practitioner.id) {
    return null;
  }

  const NEW_EARNINGS_DASBOARD = (
    <div className={classes.container}>
      {enabledPortraitEdge ? <MonthlyBlockers /> : null}

      <Adjustments showPriceFloorAdjustments={enabledPriceFloor} />
      <ChartsDashboard
        chartData={chartData as IPractitionerDashboardGraphs}
        isLoadingChartData={isLoadingChartData}
        selectedDate={selectedDate}
        onChangeDate={setSelectedDate}
        formatToShow={FORMAT_TO_SHOW_INPUT}
        isFlexOrLite={isFlexOrLite}
        minDate={minDate}
      />
      <EarningsCalculator
        earnings={earnings}
        isLoading={isLoadingEarnings}
        isError={isError}
        minDate={minDate}
        errorMessage={errorMessage}
        selectedDate={selectedDate}
        onChangeDate={setSelectedDate}
        onChangeTotalServicesWithDiscount={setTotalServicesWithDiscount}
        formatToShow={FORMAT_TO_SHOW_INPUT}
        isFlexOrLite={isFlexOrLite}
      />
      {isFlexOrLite ? (
        <FlexTransactions
          transactions={results as InfiniteData<PossibleTransactionResponse>}
          oldestTransactionRecords={oldestRecords as InfiniteData<PossibleTransactionResponse>}
          setSearchQuery={(search) => setSearchQuery(search)}
          searchQuery={searchQuery}
          isLoading={isLoading}
          isLoadingOldestRecord={isLoadingOldestRecords}
          isFetching={isFetching}
          isError={isTransactionError}
          hasNextPage={hasNextPage as boolean}
          fetchNextPage={fetchNextPage}
        />
      ) : (
        <Transactions
          selectedDate={selectedDate}
          formatToShow={FORMAT_TO_SHOW_INPUT}
          formatToQuery={FORMAT_TO_SEND_TO_API}
          totalServicesWithDiscount={totalServicesWithDiscount}
        />
      )}
    </div>
  );

  const tabs: TabDataObject = {
    earnings: {
      label: EARNINGS_CALCULATOR,
      order: 0,
      target: NEW_EARNINGS_DASBOARD,
    },
    ...(enabledPortraitEdge
      ? {
        investments: {
          label: JOINT_INVESTMENTS,
          order: 1,
          target: <PortraitEdgeChart />,
        },
      }
      : {}),
  };

  const tabProps: TabFrameProps = {
    path: BASE_PATH,
    tabs,
  };

  return <TabFrame {...tabProps} />;
};

export default PractitionerDashboardTab;
