import { createModel } from '@rematch/core';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import compile from '../utils/toastMessagesCompiler';

export const serviceVisitNotes = createModel<RootModel>()({
  state: {
    hasError: false,
  },
  reducers: {
    updateState(state: any, payload: any) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch: any) => ({
    async addNewNote(payload: { serviceVisitId: number; content: string }, _rootState: any) {
      try {
        const { serviceVisitId, content } = payload;

        const response = await axiosInstance.post(`/service-visit-notes/create`, {
          serviceVisitId,
          content,
        });

        if (response.status !== 200) {
          throw new Error(response.data.message);
        }

        if (response.data.error) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: response.data.error,
              type: 'error',
            },
          });
          return;
        }

        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Note',
              action: 'added',
            }),
          },
        });

        dispatch({ type: 'patient/addServiceVisitNoteSuccess', payload: response.data.data });
      } catch (error) {
        dispatch.serviceVisitNotes.updateState({ hasError: true });
      }
    },
    async updateNote(payload: { id: number; content: string }, _rootState: any) {
      try {
        const { id, content } = payload;

        const response = await axiosInstance.post(`/service-visit-notes/update`, {
          id,
          content,
        });

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        if (response.data.error) {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: response.data.error,
              type: 'error',
            },
          });
          return;
        }

        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Note',
              action: 'added',
            }),
          },
        });
        dispatch({ type: 'patient/addServiceVisitNoteSuccess', payload: response.data.data });
      } catch (error) {
        dispatch.serviceVisitNotes.updateState({ hasError: true });
      }
    },
  }),
});
