import {
  ORDER_STATUS_APPROVED,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_ORDERED,
  ORDER_STATUS_PAID,
  ORDER_STATUS_PARTIALLY_DELIVERED,
  ORDER_STATUS_PATRIALLY_SHIPPED,
  ORDER_STATUS_PENDING_PAYMENT,
  ORDER_STATUS_PLACED,
  ORDER_STATUS_REJECTED,
  ORDER_STATUS_REQUESTED,
  PAYMENT_STATUS_COMPLETED,
  SHIPPED_ORDER_STATUSES,
} from 'src/constants/inventory.constants';
import { InventoryOrderStatus, InventoryOrder } from 'src/interfaces/IInventoryOrder';
import { uniqBy } from 'lodash';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';

type OrderStateCheck<T = boolean> = (order: Partial<InventoryOrder>) => T;

const isDelivered: OrderStateCheck = (order) => !!order.delivered && order.purchaseOrderTotal === order.delivered;
const isPartiallyDelivered: OrderStateCheck = (order) => !!order.delivered;
const isPaid: OrderStateCheck = (order) => order.paymentStatus === PAYMENT_STATUS_COMPLETED;
const isPlaced: OrderStateCheck = (order) => !!order.orderConfirmationNumbers?.length;
const isShipped: OrderStateCheck = (order) =>
  (!isLegacy && !!order.shipmentTrackingNumbers?.length) || !!order.purchaseOrderTotal;

const isLegacy: OrderStateCheck = (order) =>
  order.roleName === PORTRAIT_LEGACY || order.roleName === PORTRAIT_LEGACY_PLUS;

const hasAllTrackingNumbers: OrderStateCheck = (order) => {
  const trackedSuppliers = order.shipmentTrackingNumbers?.map((tracking) => tracking.supplierName) || [];
  const serviceSuppliers = uniqBy(order.services, 'supplierName');
  return !serviceSuppliers.filter((service) => trackedSuppliers.indexOf(service.supplierName) < 0).length;
};

const assignLegacyOrderStatus: OrderStateCheck<InventoryOrderStatus> = (order) => {
  if (isDelivered(order)) {
    return ORDER_STATUS_DELIVERED;
  }
  if (isShipped(order)) {
    if (isPartiallyDelivered(order)) {
      return ORDER_STATUS_PARTIALLY_DELIVERED;
    }
    return hasAllTrackingNumbers(order) ? ORDER_STATUS_ORDERED : ORDER_STATUS_PATRIALLY_SHIPPED;
  }
  return isPlaced(order) ? ORDER_STATUS_PLACED : ORDER_STATUS_APPROVED;
};

export const assignOrderStatus: OrderStateCheck<InventoryOrderStatus> = (order) => {
  if (order.canceled) {
    return ORDER_STATUS_CANCELED;
  }
  if (!order.approved) {
    return ORDER_STATUS_REQUESTED;
  }
  if (order.rejected) {
    return ORDER_STATUS_REJECTED;
  }
  if (isLegacy(order)) {
    return assignLegacyOrderStatus(order);
  }
  if (isShipped(order)) {
    return hasAllTrackingNumbers(order) ? ORDER_STATUS_ORDERED : ORDER_STATUS_PATRIALLY_SHIPPED;
  }
  if (isPlaced(order)) {
    return ORDER_STATUS_PLACED;
  }

  return isPaid(order) ? ORDER_STATUS_PAID : ORDER_STATUS_PENDING_PAYMENT;
};

export const orderHasShipped = (status: InventoryOrderStatus): boolean => SHIPPED_ORDER_STATUSES.indexOf(status) > -1;
