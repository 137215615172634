import React, { useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  MEDSPA_ADMIN_ROLE,
  PORTRAIT_FLEX,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  PORTRAIT_LITE,
  PORTRAIT_LUXE,
  PRACTITIONER_ROLE,
  SNACKBAR_ERROR,
} from 'src/constants/general.constants';
import { SIGN_UP_ROUTES } from 'src/constants/routes.constants';
import { MEDSPA_ADMIN_BASE_PATH } from 'src/routes/medspaAdminRoutes';
import IMedspa from 'src/interfaces/IMedspa.interface';
import { IMedspaAdminListEntry } from 'src/interfaces/IMedspaAdminList';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { useUpdateUserGroup } from 'src/hooks/queries/useUserGroups';
import { useMedspaPractitioners } from 'src/hooks/queries/usePractitioners';
import { useMedspaAdmins } from 'src/hooks/queries/useMedspaAdmins';
import { ReactComponent as Warning } from 'src/assets/images/warning.svg';
import { ReactComponent as Close } from 'src/assets/images/close.svg';
import { ReactComponent as Expand } from 'src/assets/images/expand.svg';
import { ReactComponent as LinkIcon } from 'src/assets/images/link.svg';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import formatDate from 'src/utils/formatDate';
import { dispatch } from 'src/rematch';
import { MultipleSkeleton } from 'src/components/common/LoadingSkeleton';
import { EHRButton, EHRSwitch, EHRTypography } from 'src/components/ui/v1';
import UserGroups from 'src/services/UserGroups';
import { showSnackbar } from 'src/utils/global';
import { queryClient } from 'src/initializers/queryClient';
import { USER_GROUPS_INFINITY } from 'src/constants/reactQuery.keys';
import { Practitioners } from 'src/services/Practitioners';
import { MasqueradeCallback } from 'src/rematch/masquerade.model';
import { useStyles } from './index.styles';
import { usePractitionerBadgeStyles } from '../Practitioners/Practitioner.styles';

const ListSectionHeader = ({ rowspan, isPractitioner }: { rowspan: number; isPractitioner?: boolean }) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell
        rowSpan={rowspan}
        style={{ border: 'none', padding: '0 1rem 0 0', width: '15rem', verticalAlign: 'top' }}
      >
        {isPractitioner ? (
          <Box>
            <h4>Providers</h4>
            <span className={classes.subText}>All the providers working under this location</span>
          </Box>
        ) : (
          <Box>
            <h4>Admins</h4>
            <span className={classes.subText}>All the admins registered within this MedSpa</span>
          </Box>
        )}
      </TableCell>
      <TableCell className={classes.subText}>Full Name</TableCell>
      <TableCell className={classes.subText}>Licenses</TableCell>
      <TableCell className={classes.subText}>Phone</TableCell>
      <TableCell className={classes.subText}>Email</TableCell>
      <TableCell className={classes.subText}>Status</TableCell>
      <TableCell className={classes.subText}>&nbsp;</TableCell>
    </TableRow>
  );
};

const ListSectionRow = ({
  user,
  medspa,
  isPractitioner,
  operatingAdmin,
  operatingLicenses,
  handleEditUser,
  isLast,
}: {
  isLast?: boolean;
  medspa: IMedspa;
  user: IPractitioner | IMedspaAdminListEntry;
  isPractitioner: boolean;
  operatingAdmin?: IPractitioner;
  operatingLicenses?: string;
  handleEditUser: (user: IPractitioner | IMedspaAdminListEntry, userType: string, subUser?: IPractitioner) => void;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const switchAccount = (id: number, userType: string) => {
    let callback: MasqueradeCallback;
    if (userType === MEDSPA_ADMIN_ROLE) {
      callback = () => history.push(MEDSPA_ADMIN_BASE_PATH);
    } else {
      callback = Practitioners.useMasqueradeCallback(history);
    }
    dispatch.masquerade.switchAccount({
      id,
      callback,
      isAdminMasquerade: true,
    });
  };

  if (isPractitioner) {
    const { identityId, phone, email, firstName, lastName, inactive, licenses, profileImageUrl } =
      user as IPractitioner;

    return (
      <TableRow className={isLast ? 'last' : ''}>
        <TableCell>
          <Box className={classes.fullNameContainer}>
            <Box>
              <Avatar className={classes.avatar} alt={firstName} src={profileImageUrl} />
            </Box>
            <Box
              style={{
                overflowWrap: 'break-word',
                whiteSpace: 'break-spaces',
                display: 'block',
                maxWidth: '100%',
              }}
            >
              {firstName} {lastName}
            </Box>
          </Box>
        </TableCell>
        <TableCell>{licenses || <>&mdash;</>}</TableCell>
        <TableCell>{phone}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>
          {!inactive && medspa.active ? (
            <span className={classes.activeUser}>Active</span>
          ) : (
            <span className={classes.inactiveUser}>Inactive</span>
          )}
        </TableCell>
        <TableCell style={{ padding: '1rem 0 1rem 0' }}>
          <Box display="flex" gridGap="1rem" justifyContent="flex-end">
            <EHRButton
              style={!inactive && medspa.active ? {} : { visibility: 'hidden' }}
              dataCy="btn-masquerade-medspa-provider-user"
              variant="text"
              color="text"
              onClick={() => !inactive && medspa.active && switchAccount(identityId, PRACTITIONER_ROLE)}
              text="Log in"
            />
            <EHRButton
              dataCy="btn-edit-medspa-provider-user"
              variant="text"
              color="text"
              onClick={() => handleEditUser(user, 'Provider')}
              text="Edit"
            />
          </Box>
        </TableCell>
      </TableRow>
    );
  }
  const { identityId, phone, email, firstName, lastName, active, profileImageUrl } = user as IMedspaAdminListEntry;

  return (
    <TableRow className={isLast ? 'last' : ''}>
      <TableCell>
        <Box className={classes.fullNameContainer}>
          <Box>
            <Avatar className={classes.avatar} alt={firstName} src={profileImageUrl} />
          </Box>
          <Box
            style={{
              overflowWrap: 'break-word',
              whiteSpace: 'break-spaces',
              display: 'block',
              maxWidth: '100%',
            }}
          >
            {firstName} {lastName}
            <br />
            {!!operatingAdmin && <span className={classes.subText}>Operating Provider</span>}
          </Box>
        </Box>
      </TableCell>
      <TableCell>{operatingLicenses || <>&mdash;</>}</TableCell>
      <TableCell>{phone}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        {active && medspa.active ? (
          <span className={classes.activeUser}>Active</span>
        ) : (
          <span className={classes.inactiveUser}>Inactive</span>
        )}
      </TableCell>
      <TableCell style={{ padding: '1rem 0 1rem 0' }}>
        <Box display="flex" gridGap="1rem" justifyContent="flex-end">
          <EHRButton
            style={active && medspa.active ? {} : { visibility: 'hidden' }}
            dataCy="btn-masquerade-medspa-admin-user"
            variant="text"
            color="text"
            onClick={() => active && medspa.active && switchAccount(identityId, MEDSPA_ADMIN_ROLE)}
            text="Log in"
          />
          <EHRButton
            dataCy="btn-edit-medspa-admin-user"
            variant="text"
            color="text"
            onClick={() => handleEditUser(user, 'Admin', operatingAdmin)}
            text="Edit"
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export const MedspaInfoCard = ({
  medspaFeatures,
  medspa,
  handleEditMedspa,
  handleEditUser,
}: {
  medspaFeatures: string[];
  medspa: IMedspa;
  handleEditMedspa: (selectedMedspa: IMedspa) => void;
  handleEditUser: (user: IPractitioner | IMedspaAdminListEntry, userType: string, subUser?: IPractitioner) => void;
}) => {
  const classes = useStyles();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showToggleWarning, setShowToggleWarning] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const badge = usePractitionerBadgeStyles();

  const { data: practitioners = [], isLoading: isLoadingPractitioners } = useMedspaPractitioners(
    { includeAll: 'true', userGroupId: medspa.id },
    expanded,
    () => `/v4/medspa/${medspa.id}/practitioners`
  );

  const { data: medspaAdmins = [], isLoading: isLoadingMedspaAdmins } = useMedspaAdmins(
    expanded && medspa.administratorIds.length > 0,
    { ids: medspa.administratorIds }
  );
  const isLoading = isLoadingPractitioners || isLoadingMedspaAdmins;
  const { mutateAsync: updateGroup } = useUpdateUserGroup();

  const linkedPractitionerMap: Record<number, IPractitioner> = useMemo(() => {
    if (medspaAdmins?.length > 0 && practitioners.length > 0) {
      return (
        medspaAdmins
          .filter(
            (admin: IMedspaAdminListEntry) =>
              !!admin.linkedPractitionerId &&
              practitioners.find((practitionerObj) => practitionerObj.id === admin.linkedPractitionerId)
          )
          .reduce(
            (obj: any, admin) =>
              Object.assign(obj, {
                [admin.id]: practitioners.find((practitionerObj) => practitionerObj.id === admin.linkedPractitionerId),
              }),
            {}
          ) || {}
      );
    }
    return [];
  }, [medspaAdmins, practitioners]);

  const handleActiveToggle = () => {
    updateGroup({ active: !medspa.active, id: medspa.id });
    setShowToggleWarning(false);
  };

  const removeFeatureFromMedspa = async (key: string, userId: number) => {
    setIsUpdating(true);
    const { success, message } = await UserGroups.removeFeatureFlagFromUserGroup(key, userId);
    if (!success) {
      showSnackbar(message, SNACKBAR_ERROR);
      setIsUpdating(false);
      return;
    }
    queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf(USER_GROUPS_INFINITY) > -1 });
    setIsUpdating(false);
  };

  const addFeatureToMedspa = async (key: string, userId: number) => {
    setIsUpdating(true);
    const { success, message } = await UserGroups.addFeatureFlagToUserGroup(key, userId);
    if (!success) {
      showSnackbar(message, SNACKBAR_ERROR);
      setIsUpdating(false);
      return;
    }
    queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf(USER_GROUPS_INFINITY) > -1 });
    setIsUpdating(false);
  };

  const nonLinkedPractitioners = useMemo(() => {
    if (practitioners.length > 0) {
      const linkedPractitionerIds = Object.values(linkedPractitionerMap).map((practitioner) => practitioner.id);
      return practitioners.filter((practitioner) => !linkedPractitionerIds.includes(practitioner.id));
    }
    return [];
  }, [linkedPractitionerMap, practitioners]);

  const getBadgeClassName = (roleName: string): string => {
    switch (roleName) {
      case PORTRAIT_FLEX:
        return badge.flex;
      case PORTRAIT_LUXE:
        return badge.luxe;
      case PORTRAIT_LITE:
        return badge.lite;
      case PORTRAIT_LEGACY:
      case PORTRAIT_LEGACY_PLUS:
        return badge.legacy;
      default:
        return '';
    }
  };

  const titleStyle: React.CSSProperties = {
    fontSize: '1.125rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };

  return (
    <Card className={classes.medspaInfoCard}>
      <Box className={classes.medspaCardHeader}>
        <Box className={classes.summary} flex={1}>
          <button className={classes.cardDetailButton} onClick={() => setExpanded(!expanded)} type="button">
            {expanded ? <Close /> : <Expand />}
          </button>
          <EHRTypography dataCy="title-medspa-name" variant="h6" style={titleStyle}>
            {medspa.name}
          </EHRTypography>
          <span className={getBadgeClassName(medspa.roleName)}>{medspa.roleName}</span>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gridGap="0.5rem"
          flexWrap="wrap"
          flex={1}
          justifyContent="flex-end"
          marginRight="1rem"
          position="relative"
        >
          {medspaFeatures.map((medspaFeatureKey) => {
            const haveFeature = medspa.features.find((feature) => feature !== null && feature.key === medspaFeatureKey);
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gridGap="0.25rem"
                fontSize="0.8rem"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (haveFeature) {
                    removeFeatureFromMedspa(medspaFeatureKey, medspa.id);
                  } else {
                    addFeatureToMedspa(medspaFeatureKey, medspa.id);
                  }
                }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  {medspa.features.find((feature) => feature !== null && feature.key === medspaFeatureKey) ? (
                    <CheckCircleIcon style={{ color: '#3A8F3E', fontSize: '1rem' }} />
                  ) : (
                    <CancelIcon style={{ color: 'red', fontSize: '1rem' }} />
                  )}
                </Box>
                <Box>{medspaFeatureKey}</Box>
              </Box>
            );
          })}
          {isUpdating && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="1rem"
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Box>
          <EHRButton dataCy="btn-edit-medspa" color="text" onClick={() => handleEditMedspa(medspa)} text="Edit" />
        </Box>
      </Box>
      <Box className={classes.medspaCardDetails}>
        <Box display="flex" gridGap="2rem" alignItems="center" flexWrap="wrap">
          <Box
            className={classes.medspaLink}
            onClick={() => copyToClipboard(`${SIGN_UP_ROUTES.userGroupBooking}/${medspa.slug}`)}
            fontSize="0.75rem"
          >
            <LinkIcon width="1rem" />
            Booking Link
          </Box>
          <Box fontSize="0.75rem">Square ID: {medspa.squareLocationId || 'N/A'}</Box>
          <Box fontSize="0.75rem">Created At: {formatDate(medspa.createdAt)}</Box>
          <Box fontSize="0.75rem">ID: {medspa.id}</Box>
        </Box>
        <Box>
          Status: {medspa.active ? 'Active' : 'Inactive'}
          <EHRSwitch
            onChange={medspa.active ? () => setShowToggleWarning(true) : handleActiveToggle}
            checked={medspa.active}
            color="primary"
          />
        </Box>
      </Box>
      {expanded && isLoading && <MultipleSkeleton length={4} addPosition={false} />}
      {expanded && !isLoading && (
        <Box overflow="auto">
          <Table className={classes.medspaUsersTable}>
            <TableBody>
              <ListSectionHeader rowspan={medspaAdmins.length + 1} />
              {medspaAdmins?.map((admin, index) => (
                <ListSectionRow
                  isLast={index === medspaAdmins.length - 1}
                  user={admin}
                  medspa={medspa}
                  isPractitioner={false}
                  operatingAdmin={linkedPractitionerMap[admin.id]}
                  operatingLicenses={linkedPractitionerMap[admin.id]?.licenses}
                  handleEditUser={handleEditUser}
                />
              ))}
              {nonLinkedPractitioners.length > 0 && (
                <TableRow style={{ border: 'none' }}>
                  <TableCell colSpan={7} style={{ border: 'none' }}>
                    <Divider />
                  </TableCell>
                </TableRow>
              )}
              {nonLinkedPractitioners.length > 0 && (
                <ListSectionHeader rowspan={nonLinkedPractitioners.length + 1} isPractitioner />
              )}
              {nonLinkedPractitioners.map((prac, index) => (
                <ListSectionRow
                  isLast={index === nonLinkedPractitioners.length - 1}
                  medspa={medspa}
                  user={prac}
                  handleEditUser={handleEditUser}
                  isPractitioner
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      <Modal open={showToggleWarning} onClose={() => setShowToggleWarning(false)} className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box>
            <Warning className={classes.warningIcon} />
            <h3>Disable {medspa.name}</h3>
            <p className={classes.modalBody}>
              All active users will be set to an Inactive state and they won&#39;t be able to log in to accounts
              anymore.
            </p>
            <Divider />
            <Box display="flex" justifyContent="flex-end" gridGap="1rem" style={{ padding: '1rem 1rem 0 1rem' }}>
              <EHRButton
                dataCy="btn-cancel-modal-disable-medspa"
                color="default"
                onClick={() => setShowToggleWarning(false)}
                text="Cancel"
              />
              <EHRButton
                dataCy="btn-disable-modal-disable-medspa"
                color="primary"
                onClick={handleActiveToggle}
                text="Disable"
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Card>
  );
};
