import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider, setLogger } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { queryClient } from 'src/initializers/queryClient';
import { initializeDataDog } from 'src/initializers/datadog';
import { MAX_SNACKS_MESSAGES } from './utils/toastMessagesCompiler';

import { store } from './rematch';
import * as serviceWorker from './serviceWorker';
import App from './App';
import Notifier from './components/common/Notifier';
import './index.scss';
import Sentry, { initializeSentryWithCohere } from './initializers/sentry';

initializeSentryWithCohere();
initializeDataDog();

setLogger({
  log: () => {},
  warn: () => {},
  error: (error) => {
    Sentry.captureException(error);
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <SnackbarProvider maxSnack={MAX_SNACKS_MESSAGES}>
          <App />
          <Notifier />
        </SnackbarProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
