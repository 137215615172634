// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, withStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: theme.palette.primary.main,
    margin: '0 5px',
    cursor: 'pointer',
  },
  actionButtonClose: {
    cursor: 'pointer',
  },
  input: {
    fontSize: 20,
    width: 100,
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  name: {
    paddingLeft: 20,
  },
  centeredColumn: {
    textAlign: 'center',
  },
  itemTab: {
    border: 'solid 2px #f2f5f5',
  },
  saveButton: {
    color: theme.palette.primary.main,
  },
  buttonDisabled: {
    color: 'gray',
  },
  saveButtonText: {
    paddingLeft: '5px',
    position: 'relative',
    top: '-5px',
    cursor: 'pointer',
  },
  warning: {
    position: 'relative',
    top: '7px',
  },
}));

export const TableRowStriped = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f5f5',
      height: '56px',
    },
  },
}))(TableRow);
