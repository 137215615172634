import React from 'react';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.05px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginRight: '20px',
  },
}));

type Props = {
  title: string | React.ReactNode;
  className?: string;
  style?: object;
};

export const Title = ({ title, className, style }: Props) => {
  const classes = useStyles();
  return (
    <h2 data-cy="profileMainTitle" className={`${classes.title} ${className}`} style={style}>
      {title}
    </h2>
  );
};
