import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonLeft: {
    width: '123px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#e7eeed',
  },
  buttonRight: {
    width: '187px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#fbdada',
  },
  title: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '24px !important',
    fontWeight: 600,
    paddingLeft: '35px',
    paddingTop: '25px',
  },
  content: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    fontHeight: 1.38,
    paddingLeft: '15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '30px',
  },
  containerDialog: {
    width: '450px',
    height: '251px',
  },
});
