import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, makeStyles, MenuItem, Select } from '@material-ui/core';
import { debounce, snakeCase } from 'lodash';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { SIGN_UP_ROUTES } from 'src/constants/routes.constants';
import Page from 'src/components/common/Page';
import InputSearch from '../../components/common/InputSearch';
import { ICustomerViewParams } from '../../interfaces/CustomerParams';
import { ALL_PATIENTS_STATE, MEDSPA_ADMIN_ROLE, USER_TYPES } from '../../constants/general.constants';
import ButtonAhref from '../../components/common/ButtonAhref';

import { hasAccessTo } from '../../utils/auth.utils';
import { CREATE_CUSTOMER } from '../../constants/actions.constants';
import TablePaginator from '../../components/common/Table/TablePaginator';
import { usePatients, usePatientsCount } from '../../hooks/queries/usePatients';
import { ALL_PATIENT_COLUMNS, PRACTITIONER_PATIENT_COLUMNS } from '../../constants/patients/columns.constants';
import { CheckboxCustom } from '../Checkout/ModalPoints/modalPoints.styles';
import { PATIENT_STATUS_OPTIONS } from '../../constants/patients/fields';
import { ADMIN_NEW_BULK_UPLOAD_PATH } from '../../routes/administratorRoutes';
import { IMedspaAdminListEntry } from '../../interfaces/IMedspaAdminList';
import { useMedspaPractitioners } from '../../hooks/queries/useMedspaAdmins';
import NewPatientModal from './NewPatientModal';
import { Practitioners } from '../../services/Practitioners';

const INITIAL_ORDER_BY = 'next_appointment';
const INITIAL_DIRECTION = 'ASC';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '18px',
    fontFamily: 'Messina Sans SemiBold',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  formControl: {
    height: '46px',
    width: '100%',
  },
  infiScrollContainer: {
    width: '100%',
    marginTop: '10px',
  },
  checkbox: {
    color: '#12574d',
  },
}));

const initialParams = {
  status: snakeCase(ALL_PATIENTS_STATE),
  page: 1,
  search: '',
  hasRequestedPhotos: false,
  createdAsLead: false,
  sort: INITIAL_ORDER_BY,
  sortDirection: INITIAL_DIRECTION,
};

const PatientsPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const classes = useStyles();
  const { permissions, roleName, userType, user } = useSelector(({ auth }: any) => auth);

  const isSpaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const isMedspaProvider = Practitioners.isMedspaProvider();

  const [searchQuery, setSearchQuery] = useState('');
  const [queryParams, setQueryParams] = useState<ICustomerViewParams>(initialParams);
  const { data: patientsCount = 0 } = usePatientsCount(queryParams);
  const { data: assignedPractitioners } = useMedspaPractitioners(isSpaAdmin);

  const practitionerId = userType === USER_TYPES.PRACTITIONER ? user.id : undefined;

  const {
    isLoading,
    isFetching,
    data: { patients },
    fetchNextPage,
    isError,
  } = usePatients(queryParams, patientsCount, practitionerId, isMedspaProvider);

  const onChangeSortBy = (column: string, direction: string) => {
    setQueryParams({ ...queryParams, sort: column, sortDirection: direction });
  };
  const debounceCall = (query: string, params: ICustomerViewParams) => {
    setQueryParams({
      ...params,
      search: query,
    });
  };

  const doSearch = useMemo(
    () => debounce((query: string, params: ICustomerViewParams) => debounceCall(query, params), 1000),
    []
  );

  const onInputChange = (event: any) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    doSearch(newSearchQuery, queryParams);
  };

  return (
    <Page title="Patients">
      <Box padding={2} width="100%">
        <Box display="flex" justifyContent="flex-end">
          <Box style={{ marginLeft: '18px' }}>
            {!!user.slug && user.activeForScheduling && Boolean(permissions?.length > 0) && (
              <ButtonAhref
                styles={{ marginRight: '10px' }}
                buttonStyle="big"
                text="REGISTRATION LINK"
                onClick={() => copyToClipboard(`${SIGN_UP_ROUTES.registration}/${user.slug}`)}
              />
            )}
            {Boolean(permissions?.length > 0) && hasAccessTo(CREATE_CUSTOMER, permissions) && (
              <ButtonAhref buttonStyle="big" text="NEW PATIENT" onClick={handleOpenModal} />
            )}
            {Boolean(permissions?.length > 0) && !isMedspaProvider && hasAccessTo(CREATE_CUSTOMER, permissions) && (
              <ButtonAhref
                buttonStyle="big"
                text="BULK UPLOAD"
                href={ADMIN_NEW_BULK_UPLOAD_PATH}
                styles={{ marginLeft: '10px' }}
              />
            )}
          </Box>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          {isSpaAdmin ? (
            <Box data-cy="practitionerIdSelect" style={{ marginRight: '80px', width: '150px' }}>
              <Select id="practitionerId" labelWidth={40} value={queryParams.practitionerId} fullWidth>
                <MenuItem
                  value={undefined}
                  onClick={() => setQueryParams({ ...queryParams, practitionerId: undefined })}
                >
                  Show All
                </MenuItem>
                {(assignedPractitioners || []).map(
                  ({ id, firstName, lastName }: IMedspaAdminListEntry): React.ReactElement => (
                    <MenuItem
                      key={id}
                      value={id}
                      onClick={() => setQueryParams({ ...queryParams, practitionerId: id })}
                    >
                      {firstName} {lastName}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
          ) : (
            <Box data-cy="stateSelect" style={{ marginRight: '80px', width: '150px' }}>
              <Select id="state" labelWidth={40} value={queryParams.status} fullWidth>
                {Object.entries(PATIENT_STATUS_OPTIONS).map(([key, value]) => (
                  <MenuItem key={key} value={key} onClick={() => setQueryParams({ ...queryParams, status: key })}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          <Box data-cy="hasRequestedPhotosCheckbox" style={{ marginRight: '80px' }}>
            {!isSpaAdmin && (
              <>
                <CheckboxCustom
                  className={classes.checkbox}
                  checked={queryParams.hasRequestedPhotos}
                  onChange={() => {
                    setQueryParams({ ...queryParams, hasRequestedPhotos: !queryParams.hasRequestedPhotos });
                  }}
                  value="byPhotoRequest"
                />
                With photo request
              </>
            )}
          </Box>
          <Box>
            <InputSearch
              placeholder="Search..."
              onChangeValue={onInputChange}
              value={searchQuery}
              isLoading={isLoading}
            />
          </Box>
        </Box>
        <Box>
          <div className={classes.infiScrollContainer}>
            <TablePaginator
              isLoading={isLoading}
              isFetching={isFetching}
              isError={isError}
              data={patients}
              columns={userType === USER_TYPES.PRACTITIONER ? PRACTITIONER_PATIENT_COLUMNS : ALL_PATIENT_COLUMNS}
              onChangeSortBy={onChangeSortBy}
              total={patientsCount}
              fetchNextPage={fetchNextPage}
            />
          </div>
        </Box>
        <NewPatientModal openModal={isModalOpen} onClose={handleCloseModal} />
      </Box>
    </Page>
  );
};

export default PatientsPage;
