import React, { useCallback } from 'react';
import {
  CircularProgress,
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import IInputSearch from '../../interfaces/IInputSearch';

const InputSearch = (props: IInputSearch) => {
  const { name, width, labelWidth, showLabel, placeholder, onChangeValue, isLoading, error, value } = props;

  const useStyles = useCallback(
    makeStyles(() => ({
      search: {},
      input: {
        height: '2.5rem',
        fontSize: '0.875rem',
        '& input::placeholder': {
          color: '#6F6F6F',
          opacity: 1 /* Firefox */,
        },
        '& input::-ms-input-placeholder': {
          /* Edge 12 -18 */ color: '#6F6F6F',
        },
      },
      formControl: {
        width: `${!width && '100% !important'}`,
        minWidth: `${width}px` || '',
        borderRadius: '0.375rem',
        backgroundColor: '#FFFFFF',
      },
    })),
    [width]
  );

  const classes = useStyles();

  return (
    <FormControl
      size="small"
      // variant="outlined"
      className={classes.formControl}
    >
      {showLabel && (
        <InputLabel htmlFor="search" color="secondary" className={classes.search}>
          {placeholder}
        </InputLabel>
      )}
      <OutlinedInput
        data-cy="search"
        className={classes.input}
        id="search"
        name={name}
        type="text"
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton data-cy="searchBtn" style={{ padding: 0 }}>
              {isLoading ? (
                <CircularProgress style={{ color: 'black' }} size={20} />
              ) : (
                <Search style={{ color: 'black' }} />
              )}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={labelWidth || undefined}
      />
    </FormControl>
  );
};

export default InputSearch;
