/* eslint-disable react/no-danger */
import React from 'react';
import { Modal } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { usePhotoConsent } from 'src/hooks/queries/useConsents';
import { useStyles } from '../consents/concentModal.styles';

export const PhotoConsentAgreementModal = ({ open, onClose, practitioner }: any) => {
  const classes = useStyles();
  const { businessEntity, medicalGroup } = practitioner;
  const { data: consent } = usePhotoConsent();

  const consentInfo = consent?.content
    ?.replace('<%= business_entity %>', businessEntity)
    .replace('<%= medical_group %>', medicalGroup ? ` and ${medicalGroup}` : '');

  return (
    <Modal open={open} onClose={onClose} className={classes.photoConsentAgreementModal}>
      <div style={{ backgroundColor: '#e7eeed', height: '100%', overflowY: 'scroll' }}>
        <div>
          <div>
            <CloseIcon className={classes.closeIcon} onClick={onClose} />
            <div className={classes.consentTitle}> Photo consent </div>
          </div>
          <hr style={{ border: '0.5px solid lightgray', marginTop: '25px', marginBottom: '20px' }} />
          <div className={classes.consentContent} dangerouslySetInnerHTML={{ __html: consentInfo }} />
          <hr style={{ border: '0.5px solid lightgray', marginTop: '40px', marginBottom: '20px' }} />
        </div>
      </div>
    </Modal>
  );
};
