/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, FC, MouseEvent } from 'react';
import { withStyles, TableCell, TableRow, Button, TextField, FormControl } from '@material-ui/core';
import { Edit as EditIcon, Loyalty, Undo as UndoIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useStyles } from './LineItemRow.styles';
import IServices from '../../../interfaces/IServices';
import { calculateDiscount, formatNumber, warnLineItemBelowPriceFloor } from '../../../utils/checkout.utils';
import { getServiceLabel } from '../../../utils/newServiceVisit.util';
import { CustomPopover } from './Popover';
import { IVariantItem } from '../../../interfaces/IVariants';
import { hasAccessTo } from '../../../utils/auth.utils';
import { ReactComponent as Alert } from '../../../assets/images/alert.svg';
import { IDiscount } from '../../../interfaces/checkout.interfaces';
import { ILineItemDiscount } from '../../../interfaces/IServiceVisits';
import { useUpdateCheckoutLineItemMutation } from '../../../hooks/serviceVisit/useLineItems';
import { READ_FLOOR_PRICE } from '../../../constants/actions.constants';

const CustomTableCell = withStyles(() => ({
  root: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    letterSpacing: '-0.5px',
    color: '#393B3F',
    padding: '10px',
    borderBottom: 'none',
  },
}))(TableCell);

interface Props {
  lineItemId?: number;
  serviceVisitId?: number;
  quantity: number;
  credit: number;
  service: IServices | IVariantItem | any;
  priceOverride?: number;
  unitPrice?: number;
  unitPriceOverride?: number | null;
  appliedDiscount: boolean;
  showFutureColumn: boolean;
  openDiscountsModal: (name: string, id: number, quantity: number, units: number, isVariantItem?: boolean) => void;
  isVariantItem?: boolean;
  discounts: Array<IDiscount | ILineItemDiscount>;
  handleRefetch: () => void;
  resetTenderAndCredit?: () => void;
  showEditUnitPrice?: boolean;
  disableDiscounts?: boolean;
}

export const LineItemRow: FC<Props> = (props) => {
  const {
    lineItemId,
    serviceVisitId,
    quantity,
    credit,
    service,
    appliedDiscount,
    showFutureColumn,
    handleRefetch,
    resetTenderAndCredit,
    openDiscountsModal,
    isVariantItem,
    priceOverride,
    unitPrice,
    unitPriceOverride,
    discounts = [],
    showEditUnitPrice,
    disableDiscounts,
  } = props;
  const newServiceVisit = useSelector(({ newServiceVisit: sv }: any) => sv);
  const allDiscounts = newServiceVisit?.discounts || [];
  const styles = useStyles();
  const { name, price, unitLabel, assetLabel, allowPartialSale, id, defaultPrice } = service || {};
  const displayedUnitPrice = unitPrice?.toString() || priceOverride?.toString() || price?.toString();
  const [newUnitPriceOverride, setNewUnitPriceOverride] = useState<number | undefined>(
    unitPriceOverride || +displayedUnitPrice
  );
  const [showEditUnitPriceOverride, setShowEditUnitPriceOverride] = useState<boolean>(false);
  const [quantityAnchorEl, setQuantityAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [futureAnchorEl, setFutureAnchorEl] = useState<HTMLSpanElement | null>(null);
  const quantityOpen = Boolean(quantityAnchorEl);
  const futureOpen = Boolean(futureAnchorEl);
  const quantityPopoverId = quantityOpen ? 'simple-popover' : undefined;
  const futurePopoverId = futureOpen ? 'simple-popover' : undefined;
  const { permissions } = useSelector(({ auth }: any) => auth);
  const updateCheckoutLineItemMutation = useUpdateCheckoutLineItemMutation();

  const label: string = useMemo(
    () => getServiceLabel(allowPartialSale || false, unitLabel || '', assetLabel || ''),
    [allowPartialSale, unitLabel, assetLabel]
  );

  const handleQuantityClick = (event: MouseEvent<HTMLSpanElement>): void => {
    setQuantityAnchorEl(event.currentTarget);
  };
  const handleFutureClick = (event: MouseEvent<HTMLSpanElement>): void => {
    setFutureAnchorEl(event.currentTarget);
  };

  const calculatedPrice = isVariantItem
    ? (+quantity / service.minAmount + +credit / service.minAmount) * price
    : (+quantity + +credit) * price;

  const calculatedDefaultPrice = isVariantItem
    ? (+quantity / service.minAmount + +credit / service.minAmount) * defaultPrice
    : (+quantity + +credit) * defaultPrice;

  const discountsTotal = discounts.reduce((total, discount: any) => {
    let newTotal = total;
    if (discount.lineItemPriceValue) {
      newTotal += discount.lineItemPriceValue;
    } else if (discount.discountType) {
      const { discountType, discountTypeValue } = discount as ILineItemDiscount;
      newTotal += calculateDiscount(discountTypeValue, discountType, priceOverride || calculatedPrice);
    } else {
      const { amount, type } = discount as IDiscount;
      newTotal += calculateDiscount(amount, type, priceOverride || calculatedPrice);
    }
    return newTotal;
  }, 0);

  const handleSaveUnitPriceOverride = async () => {
    if (typeof newUnitPriceOverride === 'number' && serviceVisitId && lineItemId) {
      await updateCheckoutLineItemMutation.mutate({
        serviceVisitId,
        serviceId: id,
        checkoutLineItemId: lineItemId,
        unitPriceOverride: +newUnitPriceOverride,
      });
      resetTenderAndCredit?.();
      await handleRefetch();
    } else {
      await resetToDefaultPrice();
    }
    setShowEditUnitPriceOverride(false);
  };

  const resetToDefaultPrice = async () => {
    if (serviceVisitId && lineItemId) {
      await updateCheckoutLineItemMutation.mutate({
        serviceVisitId,
        serviceId: id,
        checkoutLineItemId: lineItemId,
        unitPriceOverride: +displayedUnitPrice,
      });
      resetTenderAndCredit?.();
      await handleRefetch();
      setNewUnitPriceOverride(+displayedUnitPrice);
    }
  };

  const totalWithDiscount = (priceOverride || calculatedPrice) - discountsTotal;

  const noCommission =
    discounts?.[0]?.noCommission || allDiscounts.find((dis: IDiscount) => dis.id === discounts[0]?.id)?.noCommission;

  return (
    <TableRow key={name} style={{ fontFamily: 'Messina Sans Regular' }}>
      <CustomTableCell align="center">
        <span role="button" onClick={handleQuantityClick}>
          {isVariantItem ? quantity / service.minAmount : quantity}
        </span>
        <CustomPopover
          id={quantityPopoverId}
          open={quantityOpen}
          anchorEl={quantityAnchorEl}
          label={label}
          onClose={() => {
            setQuantityAnchorEl(null);
          }}
        />
      </CustomTableCell>
      {showFutureColumn && (
        <CustomTableCell align="center">
          <span role="button" onClick={handleFutureClick}>
            {isVariantItem ? credit / service.minAmount : credit}
          </span>
          <CustomPopover
            id={futurePopoverId}
            open={futureOpen}
            anchorEl={futureAnchorEl}
            label={label}
            onClose={() => {
              setFutureAnchorEl(null);
            }}
          />
        </CustomTableCell>
      )}
      <CustomTableCell>{name}</CustomTableCell>
      <CustomTableCell data-cy="checkoutLineItemUnitPrice" align="center">
        {showEditUnitPrice ? (
          <div className={styles.unitPriceContainer}>
            {showEditUnitPriceOverride ? (
              <FormControl size="small" variant="outlined">
                <TextField
                  name="unitPriceOverride"
                  variant="outlined"
                  size="small"
                  style={{ width: '120px' }}
                  type="number"
                  value={newUnitPriceOverride}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue && +newValue > 0) {
                      setNewUnitPriceOverride(Number((+newValue).toFixed(2)));
                    } else if (newValue === '0') {
                      setNewUnitPriceOverride(0);
                    } else {
                      setNewUnitPriceOverride(undefined);
                    }
                  }}
                  error={newUnitPriceOverride === undefined || newUnitPriceOverride < 0}
                  helperText={
                    newUnitPriceOverride === undefined || newUnitPriceOverride < 0
                      ? 'Must be equal to or greater than 0'
                      : ''
                  }
                />
              </FormControl>
            ) : (
              <>
                ${' '}
                {formatNumber(
                  unitPriceOverride !== undefined && unitPriceOverride !== null
                    ? unitPriceOverride
                    : +displayedUnitPrice
                )}
              </>
            )}
            {showEditUnitPriceOverride ? (
              <button
                type="button"
                onClick={handleSaveUnitPriceOverride}
                className={styles.saveButton}
                disabled={newUnitPriceOverride === undefined || newUnitPriceOverride < 0}
              >
                Save
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className={styles.editButtonContainer}
                  onClick={() => setShowEditUnitPriceOverride(true)}
                >
                  <EditIcon data-cy="pricingEditIcon" fontSize="small" className={styles.actionButton} />
                  Edit
                </button>
                {newUnitPriceOverride !== +displayedUnitPrice && (
                  <button onClick={resetToDefaultPrice} className={styles.undoButtonContainer} type="button">
                    <UndoIcon data-cy="pricingResetIcon" fontSize="small" className={styles.undoButton} />
                    Reset
                  </button>
                )}
              </>
            )}
          </div>
        ) : (
          <>$ {formatNumber(unitPriceOverride || +displayedUnitPrice)}</>
        )}
      </CustomTableCell>
      <CustomTableCell>
        {/* eslint-disable no-nested-ternary */}
        {appliedDiscount ? (
          'Applied'
        ) : credit + quantity > 0 && !disableDiscounts ? (
          <Button
            style={{ backgroundColor: '#E7EEED' }}
            onClick={() => {
              openDiscountsModal(name, +id, unitPrice || price, +quantity + +credit, isVariantItem);
            }}
          >
            <Loyalty />
          </Button>
        ) : null}
      </CustomTableCell>
      <CustomTableCell align="right">
        $ {formatNumber(priceOverride || calculatedPrice)}
        {hasAccessTo(READ_FLOOR_PRICE, permissions) &&
          warnLineItemBelowPriceFloor({
            totalDefaultPrice: calculatedDefaultPrice,
            totalWithDiscount,
            service,
            noCommission,
          }) && <Alert className={styles.warning} />}
      </CustomTableCell>
    </TableRow>
  );
};
/* eslint-enable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
