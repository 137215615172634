import React, { useEffect } from 'react';

// Components
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

// Hooks
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { useAcuityAccount, useUpdateAccountMutation } from '../../../hooks/acuity/useAcuityAccount';
import { useStyles } from './styles';
import { accountDetailDefaultValues, accountDetailSchema } from './schemas';
import { ACUITY_TIMEZONE_OPTIONS } from '../../../constants/acuity/acuity.constants';

const useQuery = () => new URLSearchParams(useLocation().search);

interface Props {
  locationId?: number;
}

const AccountDetails = ({ locationId }: Props) => {
  const query = useQuery();
  const practitionerId = query.get('practitionerId') || undefined;
  const {
    user: { userGroupId },
  } = useSelector(({ auth }: RootState) => auth);
  const belongsToGroup = !!userGroupId;

  const classes = useStyles();
  const { register, control, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(accountDetailSchema(belongsToGroup)),
    defaultValues: accountDetailDefaultValues,
  });
  const watchTimezone = watch('timezone');
  const { isFetching, data: account } = useAcuityAccount({ practitionerId, locationId });

  const updateAccountMutation = useUpdateAccountMutation();
  const loading = updateAccountMutation.isLoading || isFetching;

  useEffect(() => {
    if (account.id) {
      setValue('name', account.businessName);
      setValue('email', account.email);
      setValue('location', account.location);
      setValue('timezone', account.timezone);
    }
  }, [account]);

  const onSubmit = (data: any) => {
    updateAccountMutation.mutate({ ...data, practitionerId, locationId: account.locationId });
  };

  return (
    <>
      <Grid item>
        <h2>Account Details</h2>
        <Typography>
          Welcome to the `&quot;`Account Settings`&quot;` section. This area empowers you to personalize your calendar
          configuration and manage your account details. Take control of your scheduling preferences, notifications, and
          important information in just a few clicks.
        </Typography>
      </Grid>
      <Box paddingX={2} component="form" onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} className={classes.formItem}>
            <TextField
              data-cy="businessNameInput"
              fullWidth
              name="name"
              error={!!errors.name}
              helperText={errors.name?.message || ''}
              disabled={loading || belongsToGroup}
              inputRef={register}
              label={belongsToGroup ? 'MedSpa Name' : 'Business name'}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={0} md={6} lg={8} />
          <Grid item xs={12} md={6} lg={4} className={classes.formItem}>
            <TextField
              data-cy="emailInput"
              fullWidth
              name="email"
              error={!!errors.email}
              helperText={errors.email?.message || ''}
              disabled={loading}
              inputRef={register}
              label="Email"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.formItemLegend}>
            Indicate the email in which you will want to receive appointment notifications and Patient replies on booked
            appointments.
          </Grid>
          <Grid item xs={0} md={0} lg={2} />
          <Grid item xs={12} md={6} lg={4} className={classes.formItem}>
            <FormControl variant="filled" className={classes.selectForm} error={!!errors.timezone}>
              <InputLabel shrink htmlFor="timezoneId" className={classes.inputLabel}>
                Time Zone
              </InputLabel>
              <Controller
                name="timezone"
                control={control}
                defaultValue=""
                render={() => (
                  <Select
                    variant="outlined"
                    name="timezone"
                    disabled={loading}
                    labelId="timezoneId"
                    value={String(ACUITY_TIMEZONE_OPTIONS.findIndex((val) => val === watchTimezone))}
                    onChange={(e) =>
                      setValue('timezone', ACUITY_TIMEZONE_OPTIONS[parseInt(e?.target?.value || '0', 10)])
                    }
                    error={!!errors?.timezone}
                  >
                    <MenuItem data-cy="none" value={-1} disabled>
                      None
                    </MenuItem>
                    {ACUITY_TIMEZONE_OPTIONS.map((value, index) => (
                      <MenuItem data-cy="selectedTimezone" value={index} key={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {!!errors?.timezone?.message && (
                <Box color="#f44336" fontSize="86%" marginLeft="15px">
                  {errors?.timezone?.message || ''}
                </Box>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={8} className={classes.formItemLegend}>
            Choose which time zone you want your appointments to appear. Keep in mind: Changes to your time zone will be
            applied to upcoming and scheduled appointments.
          </Grid>
          <Grid item xs={12} md={6} lg={4} className={classes.formItem}>
            <TextField
              data-cy="locationInput"
              fullWidth
              name="location"
              error={!!errors.location}
              helperText={errors.location?.message || ''}
              disabled={loading}
              inputRef={register}
              label="Suite address"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={0} md={6} lg={8} />
          <Grid item xs={12} md={6} lg={6} className={classes.formItem}>
            <Button data-cy="submitBtn" disabled={loading} variant="contained" color="primary" type="submit">
              Save Changes
              {loading && <CircularProgress size={25} />}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AccountDetails;
