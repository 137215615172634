import {
  IPractitionerSMSTemplate,
  IPractitionerSMSTemplateFetch,
  IUpdateSMSTemplate,
} from '../interfaces/IPractitionerSMSTemplate';
import Api from './Api';

const PractitionerSMSTemplates = {
  async getTemplates(params: { practitionerId?: number } = {}): Promise<IPractitionerSMSTemplate[] | null> {
    const { success, data }: IPractitionerSMSTemplateFetch = await Api.get(
      `/practitioner_sms_templates/details`,
      params
    );
    return success ? data : null;
  },

  async updateTemplate(params: IUpdateSMSTemplate): Promise<void> {
    await Api.post(`/practitioner_sms_templates/update`, params);
  },
};

export { PractitionerSMSTemplates, PractitionerSMSTemplates as default };
