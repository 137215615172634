import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { EHRCheckbox, EHRTextField } from 'src/components/ui/v1';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  boxGroup: {
    display: 'block',
  },
  boxGroupItem: {
    margin: '0.5rem',
  },
  boxGroupItemInput: {
    margin: '1rem',
  },
});

interface FormField {
  cancellationFee: string | number | undefined;
  depositFee: string | number | undefined;
}
interface FeeTypesFormProps {
  onChange: (data: FormField) => void;
  data: FormField;
}

const FeeTypesForm: React.FC<FeeTypesFormProps> = ({ data, onChange }) => {
  const classes = useStyles();

  const [noFee, setNoFee] = useState(false);
  const [cancellationFee, setCancellationFee] = useState(false);
  const [cancellationFeeValue, setCancellationFeeValue] = useState(0);
  const [depositFee, setDepositFee] = useState(false);
  const [depositFeeValue, setDepositFeeValue] = useState(0);

  useEffect(() => {
    setNoFee(!+data?.cancellationFee && !+data?.depositFee);
    setCancellationFee(!!(+data?.cancellationFee || 0));
    setCancellationFeeValue(+data?.cancellationFee);
    setDepositFee(!!(+data?.depositFee || 0));
    setDepositFeeValue(+data?.depositFee);
  }, [data.depositFee, data.cancellationFee]);

  /* Update noFee Checkbox if any value changes */
  useEffect(() => {
    if (noFee && (depositFee || cancellationFee)) {
      setNoFee(false);
    } else if (!noFee && !depositFee && !cancellationFee) {
      setNoFee(true);
    }
  }, [depositFee, cancellationFee]);

  useEffect(() => {
    // onChange callback
    onChange({
      depositFee: depositFeeValue || 0,
      cancellationFee: cancellationFeeValue || 0,
    });
  }, [depositFeeValue, cancellationFeeValue]);

  const onChangeNoFeeValue = () => {
    const newFee = !noFee;
    setNoFee(newFee);

    if (newFee) {
      setCancellationFee(false);
      setCancellationFeeValue(0);
      setDepositFee(false);
      setDepositFeeValue(0);
    }
  };

  return (
    <Box className={classes.boxGroup}>
      <Box className={classes.boxGroupItem}>
        <EHRCheckbox dataCy="no-fee" label="No Fee" checked={noFee} onChange={onChangeNoFeeValue} />
      </Box>
      <Box className={classes.boxGroupItem}>
        <EHRCheckbox
          dataCy="cancellation-fee"
          label="Cancellation Fee"
          checked={cancellationFee}
          onChange={() => {
            setCancellationFee(!cancellationFee);
            setCancellationFeeValue(0);
          }}
        />
      </Box>
      {cancellationFee && (
        <Box className={classes.boxGroupItemInput}>
          <EHRTextField
            dataCy="cancellationFeeValue"
            label="Cancellation Fee Amount"
            value={cancellationFeeValue}
            type="number"
            onChange={(ev) => setCancellationFeeValue(+ev.target.value)}
          />
        </Box>
      )}
      <Box className={classes.boxGroupItem}>
        <EHRCheckbox
          dataCy="deposit-fee"
          label="Deposit Fee"
          checked={depositFee}
          onChange={() => {
            setDepositFee(!depositFee);
            setDepositFeeValue(0);
          }}
        />
      </Box>
      {depositFee && (
        <Box className={classes.boxGroupItemInput}>
          <EHRTextField
            dataCy="depositFeeValue"
            label="Deposit Fee Amount"
            value={depositFeeValue}
            type="number"
            onChange={(ev) => setDepositFeeValue(+ev.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default FeeTypesForm;
