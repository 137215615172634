import React from 'react';
import { Box, Chip, Paper, TableCell, TableContainer, TableHead, Table, TableBody, TableRow } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { IReconciliationReportItem, IProductsTable } from '../../../interfaces/reconciliation.interfaces';
import { showQuantityUnits, getCostTotal } from '../../../utils/inventory.utils';
import { formatCurrency } from '../../../utils/formatNumber';
import { useStyles } from './ProductsTable.styles';

const TotalCost: React.FC<{ body?: IReconciliationReportItem[] }> = ({
  body,
}: {
  body?: IReconciliationReportItem[];
}): React.ReactElement => {
  const classes = useStyles();

  const totalCost = formatCurrency(getCostTotal(body || []));

  return (
    <Paper variant="outlined" className={classes.totalCost}>
      <Chip color="primary" variant="outlined" label={`Total Cost: ${totalCost}`} />
    </Paper>
  );
};

export const ProductsTable = ({ header, body, fullheight, iconCallback = () => { } }: IProductsTable) => {
  const classes = useStyles();

  return (
    <Box padding="10px">
      <TotalCost body={body} />
      <TableContainer className={classes.tableContainer} data-fullheight={fullheight}>
        <Table stickyHeader style={{ border: '1px solid lightgray' }}>
          <TableHead>
            <TableRow className={classes.textCenter}>
              {header.map((name: string) => (
                <TableCell key={name}>{name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {body.map((row: any) => (
              <TableRow key={row.serviceId} className={classes.textCenter}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{showQuantityUnits(row)}</TableCell>
                <TableCell>{showQuantityUnits({ ...row, quantity: row.scanned, units: row.scannedUnits })}</TableCell>
                <TableCell>
                  {showQuantityUnits({
                    ...row,
                    quantity: row.markedAsEmpty,
                    units: row.markedAsEmptyUnits,
                    showRedText: true,
                  })}
                </TableCell>
                <TableCell>
                  {showQuantityUnits({
                    ...row,
                    quantity: row.expired,
                    units: row.expiredUnits,
                    showRedText: true,
                  })}
                </TableCell>
                <TableCell>
                  {showQuantityUnits({
                    ...row,
                    quantity: row.quantity - row.scanned,
                    units: row.units - row.scannedUnits,
                    showRedText: true,
                  })}
                </TableCell>
                <TableCell>
                  <Chip label={formatCurrency(row.cost < 0 ? 0 : row.cost)} />
                </TableCell>
                <TableCell>
                  <Menu
                    style={{
                      border: '1px solid #E4E7EB',
                      borderRadius: '2px',
                      fontSize: '36px',
                      color: row.scanned ? 'black' : '#E4E7EB',
                    }}
                    onClick={() => {
                      iconCallback(row);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
