/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import TableFeedback from './TableFeedback';
import { TabFrame, TabFrameProps, TabDataObject, TabOuterProps } from '../TabFrame';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { READ_FEEDBACK } from '../../../../constants/actions.constants';

const FeedbackTab: React.FC<TabOuterProps> = ({ state }): ReactElement => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const tabs: TabDataObject = {
    charts: {
      label: 'Physician Chart Review',
      order: 0,
      target: <TableFeedback />,
    },
  };

  const tabProps: TabFrameProps = {
    path: '/practitioner/feedback',
    tabs,
    state,
  };

  if (!hasAccessTo(READ_FEEDBACK, permissions)) {
    return <></>;
  }

  return <TabFrame {...tabProps} />;
};

export { FeedbackTab, FeedbackTab as default };
