import { useMutation } from 'react-query';
import Patients from '../../services/Patients';
import { BulkMedicalProfilesUpload, BulkSkinProfilesUpload } from '../../services/MedicalProfile';
import { IPatientData } from '../../pages/BulkUpload/PatientDataTable';
import { BULK_UPLOAD_TYPES } from '../../constants/bulkUpload.constants';

interface ICreateProgressNote {
  dataType: string | undefined;
  data: IPatientData[];
  practitionerId?: number;
}

export const useBulkUpload = () =>
  useMutation(({ dataType, data, practitionerId }: ICreateProgressNote) => {
    switch (dataType) {
      case BULK_UPLOAD_TYPES.PATIENT_PROFILES:
        return Patients.bulkPatientProfilesUpload({ dataType, patientProfiles: data, practitionerId });
      case BULK_UPLOAD_TYPES.MEDICAL_PROFILES:
        return BulkMedicalProfilesUpload({ dataType, medicalProfiles: data });
      default:
        return BulkSkinProfilesUpload({ dataType, skinProfiles: data });
    }
  });
