import { ICustomerPhotos } from '../interfaces/ICustomerPhotos';
import compile from '../utils/toastMessagesCompiler';
import { dispatch } from '../rematch';
import Api from './Api';

const BASE_PATH = '/v3/photos';
const ERROR_GETTING_PHOTOS = compile('generic.error_message', {
  action: 'getting',
  element: 'photos',
});

const getCustomerPhotos = async (customerId: number): Promise<ICustomerPhotos[]> => {
  const { photos, error } = await Api.get(`${BASE_PATH}/${customerId}`);
  if (error) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: ERROR_GETTING_PHOTOS,
        type: 'error',
        duration: 2000,
      },
    });
  }
  return photos;
};

export default {
  getCustomerPhotos,
};
