import { useQuery } from 'react-query';
import Patients from '../../services/Patients';
import { INCOMING_MEDICAL_HISTORY_ACK, MEDICAL_HISTORY_ACK } from '../../constants/reactQuery.keys';

export function useCustomIncomingPatientHistoryAcknowledgements(id: string) {
  return useQuery([INCOMING_MEDICAL_HISTORY_ACK, id], () => Patients.incomingMedicalHistoryAcknowledgement(id), {
    refetchOnWindowFocus: false,
  });
}

export function useCustomPatientHistoryAcknowledgements(id: string) {
  const {
    data: dataMHA,
    isLoading: isLoadingMHA,
    isFetching: isFechingMHA,
    isError: isErrorMHA,
    refetch,
  } = useQuery([MEDICAL_HISTORY_ACK, id], () => Patients.medicalHistoryAcknowledgement(id));

  return {
    dataMHA,
    isFechingMHA,
    isErrorMHA,
    isLoadingMHA,
    refetch,
  };
}
