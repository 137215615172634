import { createModel } from '@rematch/core';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import { toSnakeCase } from '../utils/global';
import { IAsset } from '../interfaces/reconciliation.interfaces';
import { IMetaPagination } from '../interfaces/global.interfaces';
import compile from '../utils/toastMessagesCompiler';

interface IAssetModel {
  assets: IAsset[];
  meta: IMetaPagination;
  isLoading: boolean;
}

export const assets = createModel<RootModel>()({
  state: {
    assets: [],
    meta: { currentPage: 1, totalCount: 0, totalPages: 0 },
    isLoading: false,
  } as IAssetModel,
  reducers: {
    setAssets(state, payload: { meta: IMetaPagination; assets: IAsset[] }) {
      return { ...state, ...payload };
    },
    setLoading(state, payload: boolean) {
      return { ...state, isLoading: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchAssets(payload: any = {}, state) {
      try {
        dispatch.assets.setLoading(true);
        const {
          data: { assets: fetchedAssets, meta },
        } = await axiosInstance.get('/assets', { params: toSnakeCase(payload) });
        const newAssets = meta.currentPage === 1 ? fetchedAssets : [...state.assets.assets, ...fetchedAssets];
        dispatch.assets.setAssets({
          assets: newAssets,
          meta,
        });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'annotations',
            }),
            type: 'error',
          },
        });
      } finally {
        dispatch.assets.setLoading(false);
      }
    },
  }),
});
