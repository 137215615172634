import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Grid, MenuItem, Box, CircularProgress } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { EHRTextField, EHRSelect, EHRButton } from 'src/components/ui/v1';
import { useStates } from 'src/hooks/queries/useStates';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import {
  BankAccountParams,
  useBankAccount,
  useCreateBankAccount,
} from 'src/hooks/queries/medspaAdmins/useMedspaBankAccount';

export type BankAccountType = 'Checking' | 'Savings';

export interface BankAccountFormData {
  name: string;
  type: BankAccountType | null;
  billingAddress: {
    address: string;
    city: string;
    state: string;
  };
  routingNumber: number | null;
  accountNumber: number | null;
}

interface BankAccountProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus) => void;
  progressPercentage?: number;
  disabled?: boolean;
}

const defaultValues: BankAccountFormData = {
  name: '',
  type: null,
  billingAddress: {
    address: '',
    city: '',
    state: '',
  },
  routingNumber: null,
  accountNumber: null,
};

const BankAccountSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Name can not contain numbers.'),
  type: yup.string().required('Type is required').nullable(),
  routingNumber: yup
    .number()
    .typeError('Routing number must be a number')
    .positive('Routing number must be positive')
    .integer('Routing number must be an integer')
    .min(1000, 'Routing number must be at least 4 digits')
    .nullable()
    .required('Routing number is required'),
  accountNumber: yup
    .number()
    .typeError('Account number must be a number')
    .positive('Account number must be positive')
    .integer('Account number must be an integer')
    .min(1000, 'Account number must be at least 4 digits')
    .nullable()
    .required('Account number is required'),
  billingAddress: yup.object().shape({
    address: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
  }),
});

const BankAccount = ({ stepId, onStepUpdate, progressPercentage, disabled = false }: BankAccountProps) => {
  const userGroupId = useSelector(({ auth }: RootState) => auth.userGroupId) as number;
  const { data: states = [], isFetching: isFetchingStates } = useStates();

  const {
    data: bankAccount,
    isFetching: isFetchingBankAccount,
    isSuccess: isSuccessBankAccount,
  } = useBankAccount(userGroupId);

  const { mutateAsync: updateBankAccount } = useCreateBankAccount(userGroupId);
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();

  const { control, errors, handleSubmit, reset } = useForm<BankAccountFormData>({
    resolver: yupResolver(BankAccountSchema),
    defaultValues,
  });

  useEffect(() => {
    if (bankAccount && !isFetchingBankAccount) {
      const newDefaultValues: BankAccountFormData = {
        name: bankAccount?.name || '',
        type: bankAccount?.type,
        billingAddress: {
          address: bankAccount?.billingAddress?.address || '',
          city: bankAccount?.billingAddress?.city || '',
          state: bankAccount?.billingAddress?.state || '',
        },
        routingNumber: bankAccount.routingNumber || null,
        accountNumber: bankAccount.accountNumber || null,
      };
      reset(newDefaultValues);
    }
  }, [bankAccount, isFetchingBankAccount, reset]);

  const onSubmit = async (formData: BankAccountFormData) => {
    const params: BankAccountParams = {
      address: formData.billingAddress.address,
      city: formData.billingAddress.city,
      state: formData.billingAddress.state,
      routing: formData.routingNumber,
      account: formData.accountNumber,
      name: formData.name,
      accountType: formData.type as BankAccountType,
    };

    const isFirstUpdate = bankAccount === null;
    await updateBankAccount(params);

    if (isFirstUpdate) {
      updateProgress({
        stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
        percentage: progressPercentage as number,
      });
    }
  };

  useEffect(() => {
    if (isFetchingBankAccount) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isSuccessBankAccount && bankAccount) {
      onStepUpdate(stepId, STEP_COMPLETED);
    } else {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [isFetchingBankAccount, isSuccessBankAccount]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              render={({ onChange, value }) => (
                <EHRTextField
                  label="Name on Account (Must Match Exactly)"
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  value={value}
                  onChange={onChange}
                  dataCy=""
                  placeholder=""
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ onChange, value }) => (
                <EHRSelect
                  data-testid="bank-account-type"
                  dataCy="bank-account-type"
                  displayEmpty
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label="Bank Account Type"
                  error={!!errors?.type}
                  helperText={errors?.type?.message}
                  disabled={disabled}
                >
                  {['Checking', 'Savings'].map((st) => (
                    <MenuItem key={st} value={st}>
                      {st}
                    </MenuItem>
                  ))}
                </EHRSelect>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="billingAddress.address"
              render={({ onChange, value }) => (
                <EHRTextField
                  label="Address"
                  error={!!errors?.billingAddress?.address}
                  helperText={errors?.billingAddress?.address?.message}
                  value={value}
                  onChange={onChange}
                  dataCy=""
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="billingAddress.city"
              control={control}
              render={({ onChange, value }) => (
                <EHRTextField
                  data-testid="input-medspa-city"
                  dataCy="input-medspa-city"
                  onChange={onChange}
                  error={!!errors?.billingAddress?.city}
                  helperText={errors?.billingAddress?.city?.message}
                  value={value}
                  fullWidth
                  label="City"
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="billingAddress.state"
              control={control}
              render={({ onChange, value }) => (
                <EHRSelect
                  data-testid="select-medspa-state"
                  dataCy="select-medspa-state"
                  displayEmpty
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label="State"
                  error={!!errors?.billingAddress?.state}
                  helperText={errors?.billingAddress?.state?.message}
                  disabled={isFetchingStates || disabled}
                  startAdornment={
                    isFetchingStates ? (
                      <Box width="40px" textAlign="center">
                        <CircularProgress size={20} />
                      </Box>
                    ) : null
                  }
                >
                  {(states || []).map((st) => (
                    <MenuItem key={st.code} value={st.code}>
                      {st.name}
                    </MenuItem>
                  ))}
                </EHRSelect>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="routingNumber"
              render={({ onChange, value }) => (
                <EHRTextField
                  label="Routing Number"
                  error={!!errors?.routingNumber}
                  helperText={errors?.routingNumber?.message}
                  value={value}
                  onChange={onChange}
                  dataCy=""
                  placeholder=""
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="accountNumber"
              render={({ onChange, value }) => (
                <EHRTextField
                  label="Account Number"
                  error={!!errors?.accountNumber}
                  helperText={errors?.accountNumber?.message}
                  value={value}
                  onChange={onChange}
                  dataCy=""
                  placeholder=""
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <EHRButton type="submit" dataCy="" text="Submit" color="primary" disabled={disabled} fullWidth>
              Submit
            </EHRButton>
          </Grid>
        </>
      </Grid>
    </form>
  );
};

export default BankAccount;
