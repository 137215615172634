import React, { FC, useState } from 'react';
import { Box, FormControl } from '@material-ui/core';
import { EHRButton, EHRCheckbox } from 'src/components/ui/v1';
import { usePractitionersHeaderStyles } from './Practitioner.styles';
import { useProviderMedspaHeaderStyles } from './ProviderMedspa.styles';
import InputSearch from '../../common/InputSearch';
import { UpsertMedspaProviderModal } from '../../DashboardMedspaAdmin/Practitioners/UpsertMedspaProviderModal';

interface ProviderHeaderMedspaProps {
  inactiveChecked: boolean;
  onChangeInactiveCheckbox: () => void;
  onSearch: (event: React.ChangeEvent) => any;
  isLoading?: boolean;
}

const ProviderHeaderMedspa: FC<ProviderHeaderMedspaProps> = ({
  inactiveChecked,
  onChangeInactiveCheckbox,
  onSearch,
  isLoading = false,
}) => {
  const classesPractHeader = usePractitionersHeaderStyles();
  const classes = useProviderMedspaHeaderStyles();
  const [showNewProviderModal, setShowNewProviderModal] = useState(false);

  return (
    <Box display="flex" justifyContent="space-between" gridGap="1rem" flexWrap="wrap">
      <Box display="flex" flexGrow={1} gridGap="1rem" flexWrap="wrap">
        <Box className={classesPractHeader.practHeaderFormControlContainer}>
          <FormControl variant="outlined" className={classesPractHeader.practHeaderInputSearch}>
            <InputSearch placeholder="Search..." onChangeValue={onSearch} isLoading={isLoading} />
          </FormControl>
        </Box>

        <Box className={classesPractHeader.practHeaderFormControlContainer}>
          <FormControl variant="outlined" className={classesPractHeader.practHeaderShowInactive}>
            <EHRCheckbox
              dataCy="showInactive"
              label="Show Inactive providers"
              checked={inactiveChecked}
              onChange={onChangeInactiveCheckbox}
              value="order"
            />
          </FormControl>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem" flexWrap="wrap" className={classes.practHeaderActionsContainer}>
        <Box>
          <EHRButton
            dataCy="btn-new-provider"
            text="New Provider"
            color="primary"
            onClick={() => setShowNewProviderModal(true)}
          />
        </Box>
      </Box>

      <UpsertMedspaProviderModal onClose={() => setShowNewProviderModal(false)} open={showNewProviderModal} />
    </Box>
  );
};

export default ProviderHeaderMedspa;
