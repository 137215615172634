import { createModel } from '@rematch/core';
import { LoyaltyProgramsState } from '../types/LoyaltyPrograms';
import { RootModel } from './rootModel';
import { LOYALTY_PROGRAMS } from '../constants/loyaltyPrograms.constants';
import compile from '../utils/toastMessagesCompiler';

const loyaltyProgramsInitialState = {
  programs: [],
} as LoyaltyProgramsState;

export const loyaltyPrograms = createModel<RootModel>()({
  state: loyaltyProgramsInitialState,
  reducers: {
    setPrograms(state: LoyaltyProgramsState, payload: any) {
      return { ...state, programs: payload };
    },
    setResetPrograms(): LoyaltyProgramsState {
      return loyaltyProgramsInitialState;
    },
  },
  effects: (dispatch: any) => ({
    async fetchLoyaltyPrograms() {
      try {
        dispatch.loyaltyPrograms.setPrograms(LOYALTY_PROGRAMS);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            type: 'error',
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'loyalty programs',
            }),
          },
        });
      }
    },
  }),
});
