/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, FormControl, Select, Chip, FormHelperText, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { xor } from 'lodash';
import classnames from 'classnames';
import { Autocomplete } from '@material-ui/lab';

export const DEFAULT_CHIP_COLOR = '#e0e0e0';
export const MULTIPLE_SELECTOR_OPTION_COLORS = [
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#673AB7',
  '#3F51B5',
  '#4682B4',
  '#6495ED',
  '#6A5ACD',
  '#778899',
];

export const MULTIPLE_SELECTOR_OPTION_COLORS_RESOURCE: any = {
  '#03A9F4': { color: '#000000', background: '#03A9F4', border: '#03A9F4' },
  '#00BCD4': { color: '#ffffff', background: '#00BCD4', border: '#00BCD4' },
  '#009688': { color: '#ffffff', background: '#009688', border: '#009688' },
  '#673AB7': { color: '#ffffff', background: '#673AB7', border: '#673AB7' },
  '#3F51B5': { color: '#ffffff', background: '#3F51B5', border: '#3F51B5' },
  '#4682B4': { color: '#ffffff', background: '#4682B4', border: '#4682B4' },
  '#6495ED': { color: '#ffffff', background: '#6495ED', border: '#6495ED' },
  '#6A5ACD': { color: '#ffffff', background: '#6A5ACD', border: '#6A5ACD' },
  '#778899': { color: '#ffffff', background: '#778899', border: '#778899' },
  '#e0e0e0': { color: '#000000', background: '#e0e0e0', border: '#e0e0e0' }, // default
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'silver',
    '&:focus': {
      color: 'silver !important',
    },
  },
  selectedItem: {
    backgroundColor: '#12574d',
    color: 'white',
  },
  select: {
    overflow: 'hidden',
    height: '56px',
    paddingTop: '5px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

interface IOption {
  name: string;
  value: any;
}
interface Props {
  name: string;
  value: any;
  options: IOption[];
  control?: any;
  errors?: any;
  label?: string;
  onChange?: (value: any) => void;
  autocomplete?: boolean;
  disabled?: boolean;
  enableChipColors?: boolean;
  renderTags?: (tagValue: any, getTagProps: any) => any;
  disableClearable?: boolean;
}

const MultipleSelector = ({
  name,
  label,
  options,
  errors,
  control,
  onChange,
  value,
  autocomplete,
  disabled,
  enableChipColors,
  renderTags,
  disableClearable = false,
}: Props) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = React.useState<any>(value || []);

  const handleChange = (val: any) => {
    const newValues = xor(selectedOptions, [val]);
    setSelectedOptions(newValues);
    onChange?.(newValues);
  };
  const handleChangeAutocomplete = (values: any) => {
    setSelectedOptions(values);
    onChange?.(values);
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const defaultRenderTags = (tagValue: any, getTagProps: any) =>
    tagValue.map((option: any, index: number) => {
      const chipStyles: any = {};
      if (enableChipColors) {
        const hexadecimalKey =
          MULTIPLE_SELECTOR_OPTION_COLORS[selectedOptions.findIndex((selected: any) => selected === option.value)] ||
          DEFAULT_CHIP_COLOR;
        chipStyles.background = MULTIPLE_SELECTOR_OPTION_COLORS_RESOURCE[hexadecimalKey].background;
        chipStyles.color = MULTIPLE_SELECTOR_OPTION_COLORS_RESOURCE[hexadecimalKey].color;
      }

      return <Chip label={option.name} {...getTagProps({ index })} style={chipStyles} />;
    });

  if (autocomplete) {
    return (
      <Autocomplete
        data-testid="autocomplete-search"
        multiple
        disabled={disabled}
        id={`${name}-label-id`}
        options={options}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        value={options.filter((option) => selectedOptions.includes(option.value))}
        onChange={(ev: any, val) => handleChangeAutocomplete(val.map(({ value: v }) => v))}
        renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
        renderTags={renderTags || defaultRenderTags}
        disableClearable={disableClearable}
      />
    );
  }

  return (
    <FormControl size="medium" variant="outlined" fullWidth>
      <InputLabel htmlFor={`${name}-id`} color="secondary" className={classes.label} focused={false}>
        {label}
      </InputLabel>
      <Controller
        control={control}
        error={!!errors?.[name]}
        name={name}
        render={() => (
          <Select
            className={classes.select}
            label={label}
            labelId={`${name}-label-id`}
            id={`${name}-label-id`}
            name={name}
            multiple
            fullWidth
            disabled={disabled}
            value={options.filter((option) => selectedOptions.includes(option.value)).map((option) => option.name)}
            onChange={(ev: any) => handleChange(ev?.target?.value.splice(-1)[0])}
            renderValue={(selected: any) => (
              <div className={classes.chips}>
                {selected.map((val: any) => (
                  <Chip key={val} label={val} className={classes.chip} />
                ))}
              </div>
            )}
          >
            {options.map(({ name: labelName, value: val }) => (
              <MenuItem
                key={val}
                value={val}
                className={classnames({ [classes.selectedItem]: selectedOptions.includes(val) })}
              >
                {labelName}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors?.[name] && <FormHelperText error>{errors?.[name]?.message}</FormHelperText>}
    </FormControl>
  );
};

export default MultipleSelector;
