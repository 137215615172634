import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  dialog: {
    borderRadius: '6px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 24px 20px 20px'
  },
  title: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '24px',
    letterSpacing: '-0.05px',
    color: '#12574D',
    fontWeight: 100
  },
  dialogContent: {
    padding: 0,
    minWidth: '430px',
    display: 'flex',
    flexDirection: 'column'
  },
  stripe: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    padding: '9px 24px 9px 19px',
    width: '100%',
    marginBottom: '21px',
    fontFamily: 'Messina Sans Light',
    color: '#3A3B3F',
  },
  stripeTitle: {
    letterSpacing: '-0.07px',
    fontSize: '20px'
  },
  stripeTitleRight: {
    letterSpacing: '-0.05px',
    fontSize: '13px'
  },
  referralsAvailableSection: {
    width: '100%'
  },
  buttonSection: {
    paddingLeft: '20px',
    paddingBottom: '16px'
  },
  applyButton: {
    backgroundColor: '#E7EEED',
    width: '152px',
    height: '47px',
    '&:hover': {
      backgroundColor: '#E7EEED'
    }
  },
  removeButton: {
    backgroundColor: '#FBDADA',
    width: '152px',
    height: '47px',
    '&:hover': {
      backgroundColor: '#FBDADA'
    }
  },
  referralsAvailable: {
    fontFamily: 'Messina Sans Regular',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    paddingLeft: '76px',
    color: '#3A3B3F'
  },
  referralsAvailableAmount: {
    fontSize: '20px',
    letterSpacing: '-0.07px',
    fontWeight: 'bold',
    paddingRight: '5px'
  },
  referralsAvailableName: {
    fontSize: '15px',
    letterSpacing: '-0.05px'
  },
  redeemedSection: {
    width: '100%',
    paddingLeft: '76px'
  },
  redeemedNames: {
    letterSpacing: '-0.05px',
    color: '#3A3B3F',
    fontSize: '15px',
    fontFamily: 'Messina Sans Regular',
    paddingBottom: '5px'
  }
});
