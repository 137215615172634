import React from 'react';
import { Switch, useHistory, Route, useRouteMatch } from 'react-router-dom';
import ScanProducts from './ScanProducts';
import ProductsSummary from './ProductsSummary';
import FinalizeReconciliation from './FinalizeReconciliation';
import { NavigationBar } from '../../PatientProfile/NavigationBar';
import { PRACTITIONER_RECONCILIATION_PATH } from '../../../routes/practitionerRoutes';

const InventoryWizardRoutes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const navigate = () => {
    history.push(PRACTITIONER_RECONCILIATION_PATH);
  };

  return (
    <>
      <NavigationBar title="New Inventory Report" onBackButtonClick={navigate} />
      <Switch>
        <Route path={`${path}/reconciliation`} component={ScanProducts} />
        <Route path={`${path}/product-summary`} component={ProductsSummary} />
        <Route path={`${path}/finalize-reconciliation`} component={FinalizeReconciliation} />
      </Switch>
    </>
  );
};

export default InventoryWizardRoutes;
