import React from 'react';
import { Modal } from '@material-ui/core';
import { Button } from '../../../components/common/Button';
import { useStyles } from './CreditCardModal.styles';
import { dispatch } from '../../../rematch';

export const DeleteCreditCardModal = ({
  open,
  handleCloseCallback,
  creditCardId,
  patientId,
  onCardRemoved,
}: {
  open: boolean;
  handleCloseCallback: () => void;
  creditCardId: string;
  patientId: string;
  onCardRemoved: () => void;
}) => {
  const classes = useStyles();
  const handleDeleteCard = () => {
    dispatch({
      type: 'patient/deleteCustomerCreditCard',
      payload: { patientId, creditCardId, successCallback: onCardRemoved },
    });
    handleCloseCallback();
  };

  return (
    <Modal className={classes.modal} open={open} onClose={handleCloseCallback}>
      <div className={classes.modalContent}>
        <div className={classes.modalTitle}>Are you sure you want to delete this card?</div>
        <div>
          <Button className={classes.deleteButton} title="Confirm" onClick={handleDeleteCard} />
          <Button className={classes.cancelButton} onClick={handleCloseCallback} title="Cancel" />
        </div>
      </div>
    </Modal>
  );
};
