import React from 'react';
import clsx from 'classnames';
import { Link } from 'react-router-dom';
import { Box, LinearProgress } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// eslint-disable-next-line import/no-unresolved
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
// eslint-disable-next-line import/no-unresolved
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { AccessTime, ChevronRight } from '@material-ui/icons';
import { EHRTypography } from 'src/components/ui/v1';
import {
  PENDING_TAG_BACKGROUND_COLOR,
  PENDING_TAG_FONT_COLOR,
} from 'src/pages/Onboarding/AccountSettings/AccountSettings.styles';
import { PENDING_STEP_COLOR } from 'src/components/StepsTracker/StepsTracker.styles';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import {
  blackColor,
  disabledColor,
  grayColor,
  greenColor,
  usePendingStyles,
  useStepsListStyles,
} from '../index.styles';

type Props = {
  text: string;
  title: string;
  completed: boolean;
  disabled: boolean;
  pending: boolean;
  completedPercentage: number;
  isRequired?: boolean;
};

type ExtendedProps = Props & {
  redirect: string;
};

const getStepIcon = (disabled: boolean, completed: boolean, pending: boolean) => {
  if (disabled) {
    return <FiberManualRecordIcon style={{ color: grayColor }} />;
  }
  if (completed) {
    return <CheckCircleIcon style={{ color: greenColor }} />;
  }
  if (pending) {
    return (
      <AccessTime
        style={{
          backgroundColor: PENDING_TAG_BACKGROUND_COLOR,
          color: PENDING_TAG_FONT_COLOR,
          borderRadius: '100%',
          padding: '2px',
        }}
      />
    );
  }
  return <RadioButtonUncheckedOutlinedIcon style={{ color: blackColor }} />;
};

const StatusTag = ({ label, status }: { label: string; status: 'required' | 'pending' }) => {
  const classes = useStepsListStyles();
  const pendingClasses = usePendingStyles();

  const isPending = status === 'pending';
  return (
    <div className={isPending ? pendingClasses.pendingTag : classes.tag}>
      {isPending && (
        <Box
          style={{
            height: '16px',
            width: '16px',
            borderRadius: '100%',
            backgroundColor: PENDING_STEP_COLOR,
            color: PENDING_TAG_FONT_COLOR,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AccessTime style={{ transform: 'scale(0.8)' }} />
        </Box>
      )}
      <EHRTypography
        variant="body2"
        dataCy={`medspa-admin-onboarding-card-${isPending ? 'pending' : 'required'}-status`}
        style={{ fontSize: '0.75rem', paddingTop: '1px' }}
      >
        {label}
      </EHRTypography>
    </div>
  );
};

const StepContent = ({ text, title, completed, disabled, pending, completedPercentage, isRequired }: Props) => {
  const { pendingCommercialShippingAddress, pendingMedicalDirector } = useMedspaAdminOnboarding();
  const classes = useStepsListStyles();
  const pendingClasses = usePendingStyles();

  return (
    <>
      <div className={classes.innerContainer}>
        {getStepIcon(disabled, completed, pending)}

        <div className={clsx(classes.section, classes.textSection)}>
          <p className={classes.listItemText}>{text}</p>
          <p className={disabled ? classes.disabled : ''}>{title}</p>
        </div>
      </div>

      <div className={clsx(classes.section, classes.progressBarContainer, classes.root)}>
        <p className={classes.listItemText}>Progress</p>
        <LinearProgress variant="determinate" value={completedPercentage} />

        {pending && (
          <div className={pendingClasses.pendingTagsContainer}>
            {pendingMedicalDirector && <StatusTag label="Medical Director Pending" status="pending" />}
            {pendingCommercialShippingAddress && (
              <StatusTag label="Commercial Shipping Address Pending" status="pending" />
            )}
          </div>
        )}
      </div>
      <div className={classes.tagAndChevronContainer}>
        <div className={classes.tagContainer}>
          {!completed && isRequired && <StatusTag label="Required" status="required" />}
        </div>
        <div className={classes.chevronContainer}>
          {!completed && <ChevronRight style={{ color: `${disabled ? disabledColor : blackColor}` }} />}
        </div>
      </div>
    </>
  );
};

export const Step = ({
  text,
  title,
  completed,
  pending,
  redirect,
  disabled,
  completedPercentage,
  isRequired,
}: ExtendedProps) => {
  const classes = useStepsListStyles();
  const commonClassName = clsx(classes.container, {
    [classes.completed]: completed,
    [classes.disabledContainer]: disabled,
  });

  const commonProps = {
    text,
    title,
    completed,
    disabled,
    pending,
    completedPercentage,
    isRequired,
  };

  const content = <StepContent {...commonProps} />;

  return disabled ? (
    <div className={commonClassName}>{content}</div>
  ) : (
    <Link to={redirect} className={commonClassName}>
      {content}
    </Link>
  );
};
