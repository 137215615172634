import { useMutation, useQuery } from 'react-query';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { queryClient } from 'src/initializers/queryClient';
import { Appointment } from 'src/services/Appointment';
import { MedspaCalendar } from 'src/services/medspaAdmins/MedspaCalendar';
import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import compile from 'src/utils/toastMessagesCompiler';

/* Update block off hour configuration */
export const useGetMedspaAppointments = (medspaLocationId: number | undefined, date: string, viewType: string) =>
  useQuery(
    ['medspaCalendar', medspaLocationId, date.toString(), viewType],
    () => MedspaCalendar.getMedspaAppointments(medspaLocationId, date, viewType),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

export const useCreateMedspaAppointment = (successCallback?: () => void) =>
  useMutation((params: any) => Appointment.createMedspaAppointment(params), {
    onSuccess: (appointment) => {
      if (!appointment) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          element: 'Appointment',
          action: 'created',
        }),
        SNACKBAR_SUCCESS
      );

      queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf('medspaCalendar') > -1 });
      successCallback?.();
    },
  });

export const useRescheduleMedspaAppointment = (id: string | number | undefined, successCallback?: () => void) =>
  useMutation((params: any) => Appointment.rescheduleMedspaAppointment(id, params), {
    onSuccess: (success) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          element: 'Appointment',
          action: 'rescheduled',
        }),
        SNACKBAR_SUCCESS
      );

      queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf('medspaCalendar') > -1 });
      successCallback?.();
    },
  });

export const useCancelMedspaAppointment = (appointmentId: number, successCallback?: () => void) =>
  useMutation(() => Appointment.cancelMedspaAppointment(appointmentId), {
    onSuccess: (appointment) => {
      if (!appointment) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      showSnackbar(
        compile('generic.success_message', {
          element: 'Appointment',
          action: 'cancelled',
        }),
        SNACKBAR_SUCCESS
      );

      queryClient.invalidateQueries({ predicate: (query) => query.queryHash.indexOf('medspaCalendar') > -1 });
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });

export const useMedspaAppointmentFullContext = (appointmentId: number | null) =>
  useQuery(['medspaCalendar', appointmentId], () => Appointment.getMedspaAppointmentFullContext(appointmentId), {
    refetchOnWindowFocus: false,
    retry: false,
  });
