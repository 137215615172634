import { Switch, SwitchProps, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles({
  ehrSwitch: {
    '&.MuiSwitch-root': {
      height: '3.25rem',
      width: '4.5rem',
      '& .MuiSwitch-switchBase': {
        padding: '0.87rem',
        '& + .MuiSwitch-track': {
          backgroundColor: '#A6A6A6',
          opacity: 1,
        },
        '&.Mui-checked': {
          transform: 'translateX(1.25rem)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#12574d',
            opacity: 1,
          },
        },
        '& .MuiSwitch-thumb': {
          width: '1.5rem',
          height: '1.5rem',
          boxShadow: 'none',
        },
      },
      '& .MuiSwitch-track': {
        borderRadius: '100px',
      },
    },
  },
});

interface EHRSwitchProps extends SwitchProps {}

const EHRSwitch = (props: EHRSwitchProps) => {
  const { onChange, checked, className, color } = props;
  const classes = useStyles();

  return (
    <Switch
      {...props}
      onChange={onChange}
      checked={checked}
      color={color}
      className={classNames(classes.ehrSwitch, className)}
    />
  );
};

export { EHRSwitch };
