import React from 'react';
import { ReactComponent as Amex } from '../../../assets/images/amex.svg';
import { ReactComponent as Discover } from '../../../assets/images/discover.svg';
import { ReactComponent as MasterCard } from '../../../assets/images/mastercard.svg';
import { ReactComponent as Visa } from '../../../assets/images/visa.svg';
import { ReactComponent as CreditCard } from '../../../assets/images/creditcard.svg';

const cardBrandLogoMap = {
  VISA: <Visa width="100%" height={32} />,
  DISCOVER: <Discover width="100%" height={32} />,
  MASTERCARD: <MasterCard width="100%" height={32} />,
  AMERICAN_EXPRESS: <Amex width="100%" height={32} />,
};

const CreditCardIcon = ({
  cardBrand,
  noFormat = false,
}: {
  cardBrand?: 'VISA' | 'DISCOVER' | 'MASTERCARD' | 'AMERICAN_EXPRESS';
  noFormat?: boolean;
}) => (
  <div
    style={
      noFormat
        ? {
          display: 'flex',
          alignItems: 'center',
        }
        : {
          width: '40px',
          position: 'relative',
          top: '-5px',
          display: 'inline-block',
        }
    }
  >
    {cardBrand ? (
      cardBrandLogoMap[cardBrand] || <CreditCard width="100%" height={32} />
    ) : (
      <CreditCard width="100%" height={32} />
    )}
  </div>
);

export default CreditCardIcon;
