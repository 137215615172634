import { ILineItem, IServiceVisitAssetScanned } from '../../interfaces/IServiceVisits';
import IServices from '../../interfaces/IServices';
import { IAsset } from '../../interfaces/reconciliation.interfaces';

/* Get sum of units scanned from service visit assets */
export const getServiceUnitsScanned = (
  serviceVisitAssetForService: IServiceVisitAssetScanned[],
  allowPartialSale: boolean = false
) =>
  serviceVisitAssetForService.reduce(
    (total: number, { units = 0 }: IServiceVisitAssetScanned) => total + (allowPartialSale ? Number(units) : 1),
    0
  );

/* Get sum of service quantity to be scanned */
export const getServiceUnitsToScan = (service: IServices, lineItems: ILineItem[]) => {
  const serviceQuantity =
    lineItems.find(({ serviceId, variantItemId }) => serviceId === service.id && !variantItemId)?.currentUseQuantity ||
    0;

  const variantIds = service.variants?.flatMap(({ items }) => items.map(({ id }) => id)) || [];
  const variantsQuantity = lineItems
    .filter(
      ({ variantItemId, currentUseQuantity }) =>
        variantItemId && currentUseQuantity > 0 && variantIds.includes(variantItemId)
    )
    .reduce((total, { currentUseQuantity }) => total + currentUseQuantity, 0);

  return serviceQuantity + variantsQuantity;
};

/* Function that returns an array with the pending services to be scanned
 * Duplicated for allow partial sale and variant units if applies
 */
export const getPendingServices = (
  services: IServices[],
  lineItems: ILineItem[],
  serviceVisitAssets: IServiceVisitAssetScanned[]
) => {
  let pendingServices = services.filter(({ untracked }) => !untracked);

  // Duplicate items for non allow partial sale and variant items:
  pendingServices = pendingServices
    .map((service) => {
      const unitsToScan = getServiceUnitsToScan(service, lineItems);
      const sumOfScanned = getServiceUnitsScanned(
        serviceVisitAssets.filter(({ serviceId }) => serviceId === service.id),
        service.allowPartialSale
      );

      if (service.allowPartialSale && unitsToScan > sumOfScanned) {
        return service;
      }

      return Array(Math.max(unitsToScan - sumOfScanned, 0)).fill(service);
    })
    .flat();

  return pendingServices;
};

/* Method that calculates the max amount of units to scan
 * by asset current number of units or asset previously scanned for same
 * service if is allow partial sale.
 */
export const calculateUnitsToSuggest = (
  asset: IAsset,
  sumOfScannedUnits: number,
  maxNumberOfUnitsAllowedToScan: number
) => {
  if (sumOfScannedUnits === 0) {
    return asset.currentNumberOfUnits < maxNumberOfUnitsAllowedToScan
      ? asset.currentNumberOfUnits
      : maxNumberOfUnitsAllowedToScan;
  }

  const remainingUnitsToScan = maxNumberOfUnitsAllowedToScan - sumOfScannedUnits;

  if (asset.currentNumberOfUnits > remainingUnitsToScan) {
    return remainingUnitsToScan;
  }

  return asset.currentNumberOfUnits > 0 ? asset.currentNumberOfUnits : 1;
};
