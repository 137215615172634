import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AppBar, Typography, Box } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import GreenTabs from '../../../common/GreenTabs';
import GreenTab from '../../../common/GreenTab';
import TabItem from '../../../common/TabItem';
import tabIdentifier from '../../../../utils/tabIdentifier';
import ServiceTable from './ServiceTable';
import InputSearch from '../../InputSearch';
import IServices from '../../../../interfaces/IServices';
import Services from '../../../../services/Services';
import { SERVICES } from '../../../../constants/reactQuery.keys';
import { PRACTITIONER_PRICING_PATH } from '../../../../routes/practitionerRoutes';
import { PractitionerFeeTab } from './PractitionerFeeTab';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { CUSTOM_SERVICES } from '../../../../constants/actions.constants';

const useStyles = makeStyles(() => ({
  boxClass: {
    padding: '15px 20px 10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typographyClass: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '18px',
    fontWeight: 600,
    color: '#949494',
    paddingLeft: 5,
  },
}));

const tabsObj = new Map([
  ['prices', 0],
  ['fees', 1],
]);

const PractitionerPricingTab = () => {
  const { state } = useParams() as { state: string };
  const history = useHistory();
  const location = useLocation();
  const { permissions } = useSelector(({ auth }: any) => auth);
  const [value, setValue] = useState(tabsObj.get(state) || 0);
  const values = Array.from(tabsObj.keys());
  const [search, setSearch] = useState<string>('');
  const classes = useStyles();
  const customServicesAccess = hasAccessTo(CUSTOM_SERVICES, permissions);

  const { data: services, isLoading } = useQuery(SERVICES, () => Services.getServices(null), {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (customServicesAccess) {
      setValue(0);
      history.push(`${PRACTITIONER_PRICING_PATH}/fees`);
    } else {
      const urlTab = location.pathname.split('/').slice(-1)[0];
      setValue(tabsObj.get(urlTab) || 0);
    }
  }, [location.pathname]);

  const handleChange = (event: any, newValue: number) => {
    if (customServicesAccess) {
      history.push(`${PRACTITIONER_PRICING_PATH}/fees`);
      setValue(0);
    } else {
      history.push(`${PRACTITIONER_PRICING_PATH}/${values[newValue]}`);
      setValue(newValue);
    }
  };

  const onChange = (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <AppBar position="static">
        <GreenTabs value={value} onChange={handleChange} aria-label="simple tabs example" style={{ fontSize: '13px' }}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {!customServicesAccess && <GreenTab label="Pricing" {...tabIdentifier(0)} data-cy="pricingInternalTab" />}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <GreenTab label="Fees" {...tabIdentifier(1)} data-cy="feesInternalTab" />
        </GreenTabs>
      </AppBar>
      {!customServicesAccess && (
        <TabItem value={value} index={0}>
          <Box style={{ backgroundColor: 'white' }}>
            <Box className={classes.boxClass}>
              <Typography className={classes.typographyClass} data-cy="pricesTabTitle">
                Current prices
              </Typography>
              <InputSearch
                data-cy="pricesSearchInput"
                placeholder="Search by name"
                onChangeValue={onChange}
                value={search}
                isLoading={isLoading}
              />
            </Box>
            <ServiceTable
              isFetching={isLoading}
              data={
                services?.filter(({ name }: IServices) => name.toLowerCase().includes(search?.toLowerCase())) ||
                services
              }
            />
          </Box>
        </TabItem>
      )}
      <TabItem value={value} index={customServicesAccess ? 0 : 1}>
        <PractitionerFeeTab />
      </TabItem>
    </>
  );
};

export default PractitionerPricingTab;
