/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { useStyles } from './styles';
import AvailabilityOverrideModal from './AvailabilityOverrideModal';

interface Props {
  isLoading: boolean;
  currentDate: any;
  view: string;
  handleChangeView: (view: string) => void;
  handleNavigatorChange: (action: string) => void;
}

const yearFormat = 'YYYY';
const monthFormat = 'MMMM';
const backNav = 'back';
const todayNav = 'today';
const forwardNav = 'forward';
const dayView = 'Day';
const weekView = 'Week';
const monthView = 'Month';

const AvailabilityToolbar = ({ isLoading, currentDate, view, handleChangeView, handleNavigatorChange }: Props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div className={classes.toolbar}>
      <div className={classes.currentDate} data-cy="currentMonthYear">
        <b style={{ fontSize: '115%' }}>{moment(currentDate).format(monthFormat)}</b>
        {` ${moment(currentDate).format(yearFormat)}`}
      </div>

      <div className={classes.navigationButtons}>
        <div
          className={classes.navigationButton}
          onClick={() => handleNavigatorChange(backNav)}
          data-cy="calendarNavBack"
        >
          <KeyboardArrowLeftIcon />
        </div>
        <div
          className={classes.todayNavigation}
          onClick={() => handleNavigatorChange(todayNav)}
          data-cy="calendarNavToday"
        >
          Today
        </div>
        <div
          className={classes.navigationButton}
          onClick={() => handleNavigatorChange(forwardNav)}
          data-cy="calendarNavForward"
        >
          <KeyboardArrowRightIcon />
        </div>
      </div>

      <div style={{ margin: '8px 20px' }}>
        <Button
          disabled={isLoading}
          onClick={() => setOpenDialog(true)}
          style={{ color: 'white', width: 'auto', border: '1px solid #5F8E87' }}
        >
          Override hours
        </Button>
      </div>

      <div className={classes.viewsSelectors}>
        <div
          className={`${classes.viewSelector} ${view === dayView && classes.viewSelectorActive}`}
          onClick={() => handleChangeView(dayView)}
          data-cy="dayToggle"
        >
          Day
        </div>
        <div
          className={`${classes.viewSelector} ${view === weekView && classes.viewSelectorActive}`}
          onClick={() => handleChangeView(weekView)}
          data-cy="weekToggle"
        >
          Week
        </div>
        <div
          className={`${classes.viewSelector} ${view === monthView && classes.viewSelectorActive}`}
          onClick={() => handleChangeView(monthView)}
          data-cy="monthToggle"
        >
          Month
        </div>
      </div>
      <AvailabilityOverrideModal openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </div>
  );
};

export default AvailabilityToolbar;
