import React from 'react';
import algoliasearch from 'algoliasearch';
import { InstantSearch, connectAutoComplete } from 'react-instantsearch-dom';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID as string,
  process.env.REACT_APP_ALGOLIA_SECRET_KEY as string
);

interface AutocompleteProps {
  label: string;
  multiple: boolean;
  indexName: string;
  value: string[];
  onChange: (event: React.ChangeEvent<{}> | null, value: any) => void;
}

export const AlgoliaAutoComplete = ({ label, multiple, indexName, value, onChange }: AutocompleteProps) => {
  const addItem = (e: React.ChangeEvent<{}>, val: any, details: any, medicationName: string) => {
    const newValues = val.filter(({ name }: any) => !name.includes('Add '));

    if (details.option.create) {
      newValues.push({ name: medicationName.trim() });
    }

    onChange(e, newValues);
  };

  const AutoComplete = ({ hits, currentRefinement, refine }: any) => {
    const foundValue = value.find(
      ({ name }: any) => name.toLocaleLowerCase().trim() === currentRefinement.toLocaleLowerCase().trim()
    );
    if (!foundValue) {
      hits.push({ name: `Add "${currentRefinement}"`, create: true });
    }

    return (
      <MaterialAutocomplete
        style={{ width: '100%' }}
        multiple={multiple}
        filterSelectedOptions
        options={hits.map((hit: any) => hit)}
        getOptionLabel={(option: any) => option.name}
        getOptionSelected={(option: any, val: any) => option.name === val.name}
        onChange={(e, val, reason, details) => {
          addItem(e, val, details, currentRefinement);
        }}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            value={currentRefinement}
            onChange={(e) => {
              refine(e.currentTarget.value);
            }}
          />
        )}
      />
    );
  };

  const CustomAutoComplete = connectAutoComplete(AutoComplete);

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <CustomAutoComplete />
    </InstantSearch>
  );
};
