import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Grid, CardContent } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab/';
import { Title, Card } from '../../../common/card';
import { useStyles } from '../mainSection.styles';
import { LIMIT_INITIAL_CUSTOMER_NOTE } from '../../../../constants/general.constants';
import StickyCard from '../../../common/StickyCard';
import { ICustomerNote } from '../../../../interfaces/ICustomerNote';
import Message from '../../../common/Message/Message';

type Props = {
  isLoading: boolean;
  customerNotesCollection: ICustomerNote[];
};

const DoctorNotes: React.FC<Props> = ({ isLoading, customerNotesCollection }: Props) => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState<boolean>(false);

  const stickyNotesViewed = _.orderBy(customerNotesCollection, ['updatedAt'], ['desc']).filter(
    (sticky) => sticky.viewed
  );

  const viewMoreAction = () => {
    setShowMore(!showMore);
  };

  const numberOfCards = showMore ? stickyNotesViewed.length : LIMIT_INITIAL_CUSTOMER_NOTE;

  const getButtonText = () => {
    if (showMore) {
      return 'View less';
    }
    return `View more (${stickyNotesViewed.length - LIMIT_INITIAL_CUSTOMER_NOTE})`;
  };

  const getButtonIcon = () => {
    if (showMore) {
      return <ExpandLessIcon width={12} height={12} className={classes.imgArrow} />;
    }
    return <ExpandMoreIcon width={12} height={12} className={classes.imgArrow} />;
  };

  const doctorNotesCards = () =>
    stickyNotesViewed && stickyNotesViewed.length > 0 ? (
      stickyNotesViewed.slice(0, numberOfCards).map((sticky: ICustomerNote) => (
        <Grid item xs={6}>
          <StickyCard
            backgroundColorNote="#fff"
            colorText="#000"
            doctorNote
            title={`${sticky.createdBy ? sticky.createdBy : 'System note'}`}
          >
            {sticky.note}
            <div className={classes.dateCard}>{moment(sticky.createdAt).format('MM/DD/YYYY - hh:mm A')}</div>
          </StickyCard>
        </Grid>
      ))
    ) : (
      <Message text="There are no notes yet" />
    );

  return (
    <Card>
      <CardContent>
        <Title
          title="Doctor & Admin notes"
          style={{
            marginTop: '0',
            marginBottom: '20px',
          }}
        />
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Grid container spacing={3}>
              {doctorNotesCards()}
            </Grid>
            {stickyNotesViewed.length > LIMIT_INITIAL_CUSTOMER_NOTE && (
              <div className={classes.bottomBtn}>
                <button className={classes.btnMore} type="button" onClick={viewMoreAction}>
                  {getButtonText()}
                  {getButtonIcon()}
                </button>
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default DoctorNotes;
