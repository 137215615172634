import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  editSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  selectedSection: {
    display: 'flex'
  },
  selectSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  condition: {
    border: '2px solid transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px 40px 10px 40px',
    minHeight: '204px',
    // '&:hover': {
    //   border: '2px solid #12574d'
    // },
    '&.selected': {
      border: '2px solid #12574d',
      backgroundColor: '#e7eeed'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px'
  },
}));
