import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import classnames from 'classnames';

const useStyles = makeStyles(() => ({
  boxContainer: {
    height: '100%',
  },
  boxItem: {
    height: '100%',
    borderRadius: '3px',
    background: `#3D766E`, // rgb(139 139 139)
    color: '#ffff',
    border: 'solid 1px #3D766E !important',
    textTransform: 'capitalize',
  },
  boxItemTimeOff: {
    background: `rgb(139 139 139) !important`,
  },
}));

interface Props {
  data: any;
}

const formatHourLT = 'LT';
const AvailabilityOverrideBox = ({ data }: Props) => {
  const classes = useStyles({});

  return (
    <div className={classes.boxContainer}>
      <div
        className={classnames(classes.boxItem, { [classes.boxItemTimeOff]: data.title.includes('Time off') })}
        data-cy="appointmentCard"
      >
        {data.title} {moment(data.startDate).format(formatHourLT)} - {moment(data.endDate).format(formatHourLT)}
      </div>
    </div>
  );
};

export default AvailabilityOverrideBox;
