import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { PatientState } from 'src/rematch/patient.model';
import { useStyles } from '../mainSection.styles';
import { Button } from '../../../common/Button';
import { dispatch } from '../../../../rematch';
import compile from '../../../../utils/toastMessagesCompiler';
import ConfirmModal from '../../../common/ConfirmModal';

import { useCustomMedicalProfile } from '../../../../hooks/queries/useCustomMedicalProfile';
import { useMoveToReclear } from '../../../../hooks/mutations/useMoveToReclear';
import { PATIENT_INFO } from '../../../../constants/reactQuery.keys';
import { IPhoto } from '../../../../interfaces/global.interfaces';
import { GFE_AND_NEW_SERVICE_VISIT_MODAL_CONFIRMATION } from '../../../../constants/general.constants';
import { REQUEST_UPDATED_GFE_TITLE } from '../../../../constants/medicalProfile.constants';

type Props = {
  patientId: string;
  patient: PatientState;
  onCompleteCallback: () => void;
};

const NotesContainerHeader: React.FC<Props> = ({ patientId, patient, onCompleteCallback }: Props) => {
  const classes = useStyles();
  const [moveToReclearDisabled, setMoveToReclearDisabled] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const { data: medicalProfile } = useCustomMedicalProfile(patientId);
  const useMoveToReclearMutation = useMoveToReclear();
  const queryClient = useQueryClient();

  const { customerStatus } = patient?.basicInfo;

  const completeProfile = () => {
    dispatch({ type: 'patient/completeProfile', payload: { id: patientId } });
    onCompleteCallback();
  };

  useEffect(() => {
    if (patient && Array.isArray(patient.photos) && patient.photos.length > 0) {
      const lastPhotoUploaded: IPhoto = patient.photos.sort((a: IPhoto, b: IPhoto) => {
        const aCreatedAt: Date = new Date(a.createdAt);
        const bCreatedAt: Date = new Date(b.createdAt);
        if (aCreatedAt.toString() === 'Invalid Date' && bCreatedAt.toString() === 'Invalid Date') {
          return 0;
        }
        return bCreatedAt.getTime() - aCreatedAt.getTime();
      })[0];
      setMoveToReclearDisabled(moment().isSameOrAfter(moment(lastPhotoUploaded.createdAt).add(90, 'days')));
    }
  }, [patient, patient.photos]);

  const handleMoveToReclear = async () => {
    try {
      await useMoveToReclearMutation.mutateAsync({ customerId: patientId });
      queryClient.invalidateQueries([PATIENT_INFO, patientId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Patient status',
            action: 'updated to reclear',
          }),
        },
      });
      patient.id !== null && dispatch.patient.fetchBasicInfo({ patientId: patient.id });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'updating',
            element: 'the patient status to reclear',
          }),
          type: 'error',
        },
      });
    }
  };

  return (
    <>
      <div
        className={
          customerStatus === 'incomplete' || customerStatus === 'pending_reclear'
            ? classes.completeSectionVisible
            : classes.completeSectionHidden
        }
      >
        {customerStatus === 'incomplete' && (
          <Button
            disabled={
              !patient.contactInfo.zipCode ||
              (patient.photos !== null && !patient.photos.length) ||
              !patient.basicInfo.dateOfBirth
            }
            title="COMPLETE CUSTOMER PROFILE"
            style={{
              color: '#ffffff',
              backgroundColor: '#12574d',
            }}
            onClick={completeProfile}
          />
        )}
        {customerStatus === 'pending_reclear' && (
          <Button
            data-testid="request-updated-gfe-btn"
            disabled={moveToReclearDisabled || useMoveToReclearMutation.isLoading}
            title="Request Updated GFE"
            style={{
              color: '#ffffff',
              backgroundColor: '#12574d',
            }}
            onClick={() => setOpenDialog(true)}
          />
        )}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={classes.lastUpdateLabel}>
          <b>Last Updated:</b> {`${moment(medicalProfile?.updatedAt).format('dddd, MMMM D, YYYY hh:mm A')}`}
        </label>
      </div>
      <ConfirmModal
        title={REQUEST_UPDATED_GFE_TITLE}
        contentText={GFE_AND_NEW_SERVICE_VISIT_MODAL_CONFIRMATION}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onClickConfirm={handleMoveToReclear}
      />
    </>
  );
};

export default NotesContainerHeader;
