import { createModel } from '@rematch/core';
import { orderBy } from 'lodash';
import axiosInstance from '../utils/axios';
import { PurchaseOrderState } from '../types/PurchaseOrderState';
import { TypeSort } from '../utils/types';
import { RootModel } from './rootModel';

interface IGetPurchaseOrderEffect {
  purchaseOrderId: number;
  successCallback?: () => void;
}

export const purchaseOrders = createModel<RootModel>()({
  state: {
    orderByDate: 'asc',
    purchaseOrders: [],
    purchaseOrder: {},
    isLoading: false,
  } as PurchaseOrderState,
  reducers: {
    setPurchaseOrder(state: PurchaseOrderState, payload: any[]) {
      return { ...state, purchaseOrder: payload };
    },
    setPurchaseOrders(state: PurchaseOrderState, payload: any[]) {
      return { ...state, purchaseOrders: payload };
    },
    sortByDate(state: PurchaseOrderState, payload: TypeSort) {
      const purchaseOrdersSorted = orderBy(state.purchaseOrders, [(object: any) => new Date(object.date)], [payload]);
      return { ...state, purchaseOrdersSorted };
    },
    setSort(state: PurchaseOrderState, payload: TypeSort) {
      return { ...state, orderByDate: payload };
    },
    setFilterPurchaseOrders(state: PurchaseOrderState, payload: { value: string }) {
      const { value } = payload;
      if (value === '') {
        return { ...state };
      }
      const purchaseOrdersFiltered = state.purchaseOrders.filter((item: any) =>
        item.practitionerName.trim().toLowerCase().match(value.trim().toLowerCase())
      );
      return { ...state, purchaseOrdersFiltered };
    },
    setLoading(state: any, payload: boolean) {
      return { ...state, isLoading: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getPurchaseOrder({ purchaseOrderId, successCallback = () => {} }: IGetPurchaseOrderEffect) {
      const response = await axiosInstance.get(`/purchase-order/${purchaseOrderId}`);
      dispatch({ type: 'purchaseOrders/setPurchaseOrder', payload: response.data.purchaseOrder });
      successCallback();
    },
    async getPurchaseOrders() {
      try {
        dispatch.purchaseOrders.setLoading(true);
        const response = await axiosInstance.get('/purchase-orders');
        if (response.status !== 200) {
          throw new Error(response.data.message);
        }
        if (response.data.error) {
          dispatch({ 
            type: 'snackbar/enqueueSnackBar',
            payload: { 
              message: response.data.error, 
              type: 'error', 
            } 
          });
          return;
        }
        dispatch.purchaseOrders.setPurchaseOrders(response.data.purchaseOrders);
      } finally {
        dispatch.purchaseOrders.setLoading(false);
      }
    },
    async filterPurchaseOrders(payload: { value: string }) {
      dispatch.purchaseOrders.setFilterPurchaseOrders(payload);
    },
  }),
});
