import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import { filter, keys } from 'lodash';
import { dispatch } from '../rematch/index';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import Wrapper from '../components/common/Wrapper';
import ProductIdentifiers from '../components/common/ProductIdentifiers/ProductIdentifiers';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import { IAsset } from '../interfaces/reconciliation.interfaces';
import { isAssetAboutToExpire } from '../utils/inventory.utils';
import { PRACTITIONER_INVENTORY_PATH } from '../routes/practitionerRoutes';

const ProductsAboutToExpire = () => {
  const history = useHistory();
  const userType = useSelector(({ auth }: any) => auth.userType);
  const { isLoadingInventory, groupedProductList } = useSelector(({ inventoryDefault }: any) => ({
    ...inventoryDefault,
  }));
  const { userId } = useSelector(({ auth }: any) => auth);
  const { showMasqueradeBar } = useSelector(({ masquerade }: any) => masquerade);

  useEffect(() => {
    if (userId) {
      dispatch({
        type: 'inventoryDefault/getDashboardLists',
        payload: userId,
      });
    }
  }, [userId]);

  const navigateToBack = () => {
    history.push(PRACTITIONER_INVENTORY_PATH);
  };

  const renderProductIds = (name: string, assets: IAsset[]) => {
    const service = {
      name,
      assets: filter(assets, (asset: IAsset) => isAssetAboutToExpire(asset)),
    };
    return service.assets.length === 0 ? null : (
      <ProductIdentifiers
        editionDisabled
        service={service}
        aggregatedField="currentNumberOfUnits"
        hidePractitionerBar
        hideServiceBackButtom
        canMarkAsEmpty
      />
    );
  };

  const Products = (
    <Box component="main" height="calc(100vh - 4rem)">
      {userType === 'Practitioner' && (
        <NavigationBar title="Products about to Expire" onBackButtonClick={navigateToBack} />
      )}
      <Box display="flex" flexDirection="column" height="100%">
        <article style={{ padding: '12px', width: 'inherit', marginBottom: 'auto' }}>
          {isLoadingInventory ? (
            <MultipleSkeleton />
          ) : (
            <Grid container direction="row">
              {keys(groupedProductList).map((name: string) => (
                <React.Fragment key={name}>{renderProductIds(name, groupedProductList[name])}</React.Fragment>
              ))}
            </Grid>
          )}
        </article>
      </Box>
    </Box>
  );

  if (userType === 'Administrator' && !showMasqueradeBar) {
    return <Wrapper>{Products}</Wrapper>;
  }
  return Products;
};

export default ProductsAboutToExpire;
