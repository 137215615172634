import { IFlexPractitionerPhysician } from '../hooks/mutations/useSaveFlexPractitionerPhysician';
import Api from './Api';

const BASE_PATH = '/v3/manual_gfe_clearances';

export interface IExternalPhysician {
  createdAt: string;
  customerId: number;
  firstName: string;
  id: number;
  lastName: string;
  medicalMemberType: string;
  notes: string;
  practitionerId: number;
  professionalName: string;
}

export const FlexPractitionerPhysician = {
  async createFlexPractitionerPhysician(data: IFlexPractitionerPhysician): Promise<any> {
    const { success } = await Api.post(BASE_PATH, data);
    return success;
  },

  async getFlexPractitionerPhysician(patientId: string | number): Promise<IExternalPhysician> {
    const { success, data } = await Api.get(`${BASE_PATH}/${patientId}`);
    return success ? data : null;
  },
};
