import React from 'react';

import { Box, Fab, FormControl, LinearProgress } from '@material-ui/core';

import { Send } from '@material-ui/icons';
import AddPhoto from './AddPhoto';
import ProgressNoteTextInput from './ProgressNoteTextInput';
import { useStyles } from './AddProgressNote.styles';
import { IAddProgressNoteProps, IProgressNotephoto } from '../../types/ProgressNoteTypes';

const AddProgressNote = ({
  value,
  onChange,
  setPhotosList,
  tempPhotoList,
  setTempPhotoList,
  noteMutationInProgress,
}: IAddProgressNoteProps) => {
  const classes = useStyles();

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        {noteMutationInProgress ? (
          <Box style={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (
          <Box style={{ display: 'flex', width: '100%' }}>
            <AddPhoto setPhotosList={setPhotosList} setTempPhotoList={setTempPhotoList} />
            <Box className={classes.inputBoxContainer}>
              <Box className={classes.contentImages}>
                {tempPhotoList.map((image: IProgressNotephoto) => (
                  <img className={classes.contentImageItem} src={image.src} alt="progress note item" />
                ))}
              </Box>
              <Box className={classes.inputText}>
                <ProgressNoteTextInput value={value} onChange={onChange} />
              </Box>
            </Box>
            <Box className={classes.saveBoxContainer}>
              <Fab
                disabled={tempPhotoList.length === 0 && !value.trim()}
                size="large"
                color="primary"
                aria-label="add"
                type="submit"
              >
                <Send />
              </Fab>
            </Box>
          </Box>
        )}
      </FormControl>
    </div>
  );
};

export default AddProgressNote;
