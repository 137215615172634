import { UpdateInventoryBudgetParams } from 'src/interfaces/IInventoryOrder';
import Api from './Api';
import API_ROUTES from '../constants/apiRoutes.constants';

const InventoryBudget = {
  async getInventoryBudget(practitionerId?: number): Promise<number | null> {
    const { success, data }: { success: boolean; data: number | string } = await Api.get(API_ROUTES.INVENTORY_BUDGET, {
      practitionerId,
    });
    return success && typeof data !== 'string' ? data : null;
  },

  async updateInventoryBudget(params: UpdateInventoryBudgetParams): Promise<number | null> {
    const { success, data }: { success: boolean; data: number | string } = await Api.post(
      API_ROUTES.INVENTORY_BUDGET,
      params
    );
    return success && typeof data !== 'string' ? data : null;
  },
};

export { InventoryBudget, InventoryBudget as default };
