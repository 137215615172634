import { useMutation, useQueryClient } from 'react-query';
import Customers from '../../services/Customers';
import { PATIENT_INFO } from '../../constants/reactQuery.keys';

interface IUpdateBeautiBankSubscription {
  squareCardId?: string | undefined;
  autoRenew?: boolean;
  acknowledgedLastSquareError?: boolean;
}

export const useUpdateBeautiBankSubscription = (patientId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ squareCardId, autoRenew, acknowledgedLastSquareError }: IUpdateBeautiBankSubscription) =>
      Customers.updateCustomerBeautiBankSubscription({
        patientId,
        squareCardId,
        autoRenew,
        acknowledgedLastSquareError,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([PATIENT_INFO, patientId]),
    }
  );
};
