import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { logSentryMessageInfo } from 'src/services/Sentry';
import { logDatadogAction } from 'src/services/Datadog';
import { SQUARE_ERROR_MESSAGES, SQUARE_STATUS } from '../constants/checkout.constants';
import { getTransactionInfo } from '../utils/checkout.utils';
import { dispatch } from '../rematch';

const mainStyles = {
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  alignItems: 'center',
};

const SquareTransaction = () => {
  const [transaction, setTransaction] = useState<any>({});
  const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    setInProgress(true);
    const transactionInfo = getTransactionInfo(window.location.href);
    logSentryMessageInfo('TRANSACTION INFO', { data: transactionInfo });
    logDatadogAction('TRANSACTION INFO', { data: transactionInfo });
    setTransaction(transactionInfo);
    const { serviceVisitId } = JSON.parse(transactionInfo.state);

    if (transactionInfo.status === SQUARE_STATUS.ERROR) {
      dispatch({
        type: 'newServiceVisit/squareResponse',
        payload: {
          transactionInfo,
          serviceVisitId,
          finallyCallback: () => {
            setInProgress(false);
          },
        },
      });
    } else {
      setInProgress(false);
    }
  }, []);

  return (
    <div style={mainStyles}>
      {inProgress ? (
        <div>
          <CircularProgress color="primary" style={{ color: '#12574d' }} />
        </div>
      ) : (
        <div style={{ fontSize: '35px', textAlign: 'center' }}>
          {transaction.status === SQUARE_STATUS.ERROR ? (
            <h1>{`${SQUARE_ERROR_MESSAGES[transaction.error_code]}. \n Close this tab and try again.`}</h1>
          ) : (
            <h1>
              The transaction was <span style={{ color: '#12574d' }}>successful</span>. You may now close this tab
            </h1>
          )}
        </div>
      )}
    </div>
  );
};

export default SquareTransaction;
