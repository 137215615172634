export const SQUARE_PLAN_ID = 1;
export const BEAUTY_BANK_SUBSCRIPTION_STATUS = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  PAYMENT_OVERDUE: 'PAYMENT_OVERDUE',
};
export const BEAUTY_BANK = 'BeautiBank';
export const MANAGE_MEMBERSHIP = 'Manage Membership';
export const SIGN_AGREEMENT = 'Sign Agreement';
export const BEAUTY_BANK_MEMBER = 'BeautiBank Member';
export const BEAUTY_BANK_PRICE = 99;

export const BEAUTIBANK_ERROR_MESSAGE_MAP: Record<string, string> = {
  GENERIC_DECLINE: 'Your card was declined. Please check with your card issuer for more information.',
  PAN_FAILURE: 'The card number entered is invalid. Please check and enter the correct card number.',
  INVALID_CARD: 'The card details provided are invalid. Please check and enter the correct card information.',
  CVV_FAILURE: 'The CVV code on your card is invalid. Please check and enter the correct CVV.',
  INVALID_EXPIRATION_DATE: 'The expiration date on your card is invalid. Please check and enter the correct date.',
  INVALID_EXPIRATION_YEAR: 'The expiration year on your card is invalid. Please check and enter the correct year.',
  INVALID_EXPIRATION: 'The expiration date on your card is invalid. Please check and enter the correct date.',
  CARD_EXPIRED: 'Your card has expired. Please use a different card or update your card details.',
  INSUFFICIENT_FUNDS:
    'Your card has insufficient funds to complete this transaction. Please use a different ' +
    'card or contact your bank.',
  CARD_DECLINED: 'Your card was declined. Please check with your card issuer for more information.',
};
