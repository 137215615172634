import React from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useCheckoutTransactionDetails } from '../../../hooks/queries/useCheckoutTransactions';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import formatDate, { DATETIME_FORMAT } from '../../../utils/formatDate';
import { formatNumber } from '../../../utils/checkout.utils';
import { Pill } from '../../common/Pill';
import { copyToClipboard } from '../../../utils/clipboard.utils';

const CustomHeader = ({ children, width }: any) => (
  <TableCellHeader>
    <div style={{ whiteSpace: 'nowrap', width }}>{children}</div>
  </TableCellHeader>
);

export const TransactionDetails = (props: any) => {
  const { row } = props;
  const { data: checkoutTransactions } = useCheckoutTransactionDetails(row.checkoutId);

  return (
    <Box>
      <TableContainer>
        <Table style={{ width: 'unset' }}>
          <TableHead>
            <CustomHeader>ID</CustomHeader>
            <CustomHeader>
              <Box style={{ width: '120px' }}>Created at</Box>
            </CustomHeader>
            <CustomHeader>Square status</CustomHeader>
            <CustomHeader>Total paid</CustomHeader>
            <CustomHeader>Amount refunded</CustomHeader>
            <CustomHeader>Square order ID</CustomHeader>
            <CustomHeader>Square payment ID</CustomHeader>
          </TableHead>
          <TableBody>
            {checkoutTransactions.map((transaction: any) => (
              <TableRow>
                <TableCellCustom>{transaction.id}</TableCellCustom>
                <TableCellCustom>{formatDate(transaction.createdAt, DATETIME_FORMAT)}</TableCellCustom>
                <TableCellCustom>{transaction.transactionStatus}</TableCellCustom>
                <TableCellCustom style={{ textAlign: 'right' }}>
                  {transaction?.amountPaid ? (
                    <Pill
                      title={`$ ${formatNumber(transaction.amountPaid)}`}
                      color="green"
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    '-'
                  )}
                </TableCellCustom>
                <TableCellCustom style={{ textAlign: 'right' }}>
                  {transaction?.amountRefunded ? (
                    <Pill
                      title={`$ ${formatNumber(transaction.amountRefunded)}`}
                      color="red"
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    '-'
                  )}
                </TableCellCustom>
                <TableCellCustom style={{ textAlign: 'right' }}>
                  {transaction.squareOrderId?.length > 4 ? (
                    <Box onClick={() => copyToClipboard(transaction.squareOrderId)}>
                      <Pill title={transaction.squareOrderId} color="gray" style={{ cursor: 'pointer' }} />
                    </Box>
                  ) : (
                    transaction.squareOrderId
                  )}
                </TableCellCustom>
                <TableCellCustom style={{ textAlign: 'right' }}>
                  {transaction.squarePaymentId?.length > 4 ? (
                    <Box onClick={() => copyToClipboard(transaction.squareOrderId)}>
                      <Pill title={transaction.squareOrderId} color="gray" style={{ cursor: 'pointer' }} />
                    </Box>
                  ) : (
                    transaction.squarePaymentId
                  )}
                </TableCellCustom>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
