import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import InfiScroller from 'react-infi-scroller';
import { TableCellHeader } from '../../../common/TableStyles/TableCellHeader';
import SupplyReceiptTabRow from './SupplyReceiptTabRow';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
// eslint-disable-next-line max-len
import { usePractitionerSupplyReceiptsPagination } from '../../../../hooks/queries/usePractitionerSupplyReceiptsPagination';

const useStyles = makeStyles(() => ({
  infiScrollContainer: {
    overflow: 'auto',
    height: '84.7vh',
    width: '104%',
    marginTop: '10px',
  },
}));

const SupplyReceiptsTab: React.FC = () => {
  const classes = useStyles();
  const { userId } = useSelector(({ auth }: any) => auth);
  const refComponent = useRef(null);

  const {
    isLoading,
    data: { supplyReceipts },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePractitionerSupplyReceiptsPagination({
    practitionerId: userId,
  });

  if (isLoading) {
    return <MultipleSkeleton />;
  }

  return (
    <div>
      <Box component="p" fontSize="16px" color="#949494" marginBottom={5}>
        Supply Receipts
      </Box>
      <div ref={refComponent} className={classes.infiScrollContainer}>
        <InfiScroller hasMore={hasNextPage} scrollTarget={refComponent.current} onLoadMore={fetchNextPage}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHeader />
                  <TableCellHeader>Date</TableCellHeader>
                  <TableCellHeader>Status</TableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplyReceipts.map((row: any) => (
                  <SupplyReceiptTabRow
                    key={row.id}
                    id={row.id}
                    date={row.createdAt}
                    status={row.status}
                    products={row.products}
                    dataCollapse={row.productsList}
                  />
                ))}
              </TableBody>
            </Table>
            {isFetchingNextPage && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress size={25} />
              </div>
            )}
          </TableContainer>
        </InfiScroller>
      </div>
    </div>
  );
};

export default SupplyReceiptsTab;
