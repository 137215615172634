import React from 'react';
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import { makeStyles, styled } from '@material-ui/core';
import classNames from 'classnames';
import { IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';
import { groupingStyles } from './groupingStyles';
import { isWithinAWorkingHour } from './medspaCalendarUtils';
import { VIEW_NAME_WEEK } from '../indexV2';

// @ts-expect-error
const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(groupingStyles);

const useStyles = makeStyles({
  ehrWeekCell: {
    borderRight: '1px solid #e0e0e0 !important',
    height: '15rem',
  },
});
interface WeekViewTimeTableCellProps extends WeekView.TimeTableCellProps {
  workingHours: IWorkingHour[];
  view: string;
}
const WeekViewTimeTableCell = ({ groupingInfo, view, workingHours, ...restProps }: WeekViewTimeTableCellProps) => {
  const classes = useStyles();
  const groupId = groupingInfo?.[0].id;

  const isValidWorkingHour =
    [undefined, VIEW_NAME_WEEK].includes(view) ||
    isWithinAWorkingHour(workingHours, restProps.startDate, restProps.endDate);
  return (
    <StyledWeekViewTimeTableCell
      className={classNames(
        `group-${groupId}`,
        [undefined, VIEW_NAME_WEEK].includes(view) && classes.ehrWeekCell,
        !isValidWorkingHour && 'disabled-date'
      )}
      groupingInfo={groupingInfo}
      {...restProps}
    />
  );
};

export { WeekViewTimeTableCell };
