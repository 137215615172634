import { withStyles, Tabs } from '@material-ui/core';

const GreenTabs = withStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: '#f2f5f5',
    fontWeight: 700,
  },
  indicator: {
    backgroundColor: '#f16c00',
    color: theme.palette.primary.main,
  },
}))(Tabs);

export default GreenTabs;
