import React from 'react';
import { Modal } from '@material-ui/core';
import { Button } from '../../../components/common/Button';
import { useStyles } from './ConfirmChargeModal.styles';
import { ISquareCard } from '../../../interfaces/ISquareCard.interfaces';
import { ReactComponent as ChargeConfirm } from '../../../assets/images/charge-confirm.svg';

export const ConfirmChargeModal = ({
  open,
  handleCloseCallback,
  handleCharge,
  chargeAmount,
  card,
}: {
  card: ISquareCard | undefined;
  chargeAmount: string | null;
  open: boolean;
  handleCloseCallback: () => void;
  handleCharge: () => void;
}) => {
  const classes = useStyles();
  const handleConfirmCharge = () => {
    handleCharge();
    handleCloseCallback();
  };

  if (!card || !chargeAmount) {
    return null;
  }

  return (
    <Modal className={classes.modal} open={open} onClose={handleCloseCallback}>
      <div className={classes.modalContent}>
        <ChargeConfirm width="60px" />
        <div className={classes.modalTitle}>
          Confirm the card ending in {card.last4} will be charged <span className={classes.bold}>${chargeAmount}</span>.
        </div>
        <div>
          <Button className={classes.confirmButton} title="Confirm" onClick={handleConfirmCharge} />
          <Button className={classes.cancelButton} onClick={handleCloseCallback} title="Cancel" />
        </div>
      </div>
    </Modal>
  );
};
