import React, { ReactElement, useState } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableBody, TextField, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { USER_TYPES } from 'src/constants/general.constants';
import { useSelector } from 'react-redux';
import { useMedspaPractitioners } from 'src/hooks/queries/useMedspaAdmins';
import { RowItem } from './RowItemCampaigns';
import { IPractitionerSMSTemplate } from '../../../../interfaces/IPractitionerSMSTemplate';
import { usePractitionerSMSTemplates } from '../../../../hooks/queries/usePractitionerSMSTemplates';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import { TableCellHeader } from './marketing.styles';

const TableCampaigns: React.FC = (): ReactElement => {
  const { userType } = useSelector(({ auth }: any) => auth);
  const [practitionerSelected, setPractitionerSelected] = useState<any>(null);
  const isMedspaAdmin = userType === USER_TYPES.ADMINISTRATOR;
  const { data: assignedPractitioners } = useMedspaPractitioners(isMedspaAdmin);
  const showTable = isMedspaAdmin ? !!practitionerSelected?.id : userType === USER_TYPES.PRACTITIONER;

  const { data: templateData, isLoading } = usePractitionerSMSTemplates(
    { practitionerId: practitionerSelected?.id },
    showTable
  );

  return (
    <>
      {userType !== USER_TYPES.PRACTITIONER && (
        <Box padding="10px" width="40%" minWidth="300px">
          <Autocomplete
            onChange={(e, selectedOptions) => {
              setPractitionerSelected(selectedOptions);
            }}
            filterSelectedOptions
            options={assignedPractitioners || []}
            value={practitionerSelected}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            renderInput={(params) => (
              <TextField
                data-cy="practitionerSelector"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="Practitioner"
              />
            )}
          />
        </Box>
      )}

      {!showTable ? (
        <Box padding="14px">Select a provider.</Box>
      ) : (
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCellHeader data-cy="todoDateColumnTitle" width={200}>
                  Campaign
                </TableCellHeader>
                <TableCellHeader data-cy="todoPatientIdColumnTitle" width={550}>
                  Description
                </TableCellHeader>
                <TableCellHeader data-cy="todoPatientColumnTitle" width={120} style={{ paddingLeft: 30 }}>
                  Status
                </TableCellHeader>
                <TableCellHeader data-cy="todoActionColumnTitle" width={120}>
                  {' '}
                  Actions{' '}
                </TableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody data-cy="todoTableBody">
              {isLoading ? (
                <MultipleSkeleton />
              ) : (
                (templateData || []).map(
                  (row: IPractitionerSMSTemplate): ReactElement => (
                    <RowItem key={row.label} row={row} practitionerId={practitionerSelected?.id} />
                  )
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export { TableCampaigns, TableCampaigns as default };
