import { withStyles, Button, Switch, createStyles, Select, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  breadCrumbsFontSize: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '0.875rem !important',
  },
  isActive: {
    color: theme.palette.primary.main,
  },
  inactive: {
    color: '#989591',
  },
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Messina Sans Regular',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: '1px',
  },
  textField: {
    marginBottom: '10px',
    '& fieldset': {
      borderColor: '#979797',
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontFamily: 'Messina Sans Regular',
    },
  },
  buttonNewSupplyReceipt: {
    backgroundColor: '#e7eeed',
    height: '47px',
    paddingLeft: '16px',
    paddingRight: '16px',
    fontSize: '14px',
    fontFamily: 'Messina Sans SemiBold',
    letterSpacing: -0.03,
  },
  inputSearch: {
    fontFamily: 'Messina Sans Regular',
    height: '47px',
  },
  buttonDeleteItems: {
    backgroundColor: '#fbdada',
    paddingLeft: '30px',
    paddingRight: '30px',
    borderColor: '#fbdada',
  },
  buttonBacktems: {
    backgroundColor: '#f2f5f5',
    paddingLeft: '30px',
    paddingRight: '30px',
    borderColor: '#f2f5f5',
  },
  buttonPadding: {
    paddingRight: '5px',
  },
  formControl: {
    height: '46px',
    width: '100%',
  },
  formNurseDisabled: {
    height: '46px',
    width: '100%',
    color: '#323232 !important',
  },
  productBox: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const ButtonFooter = withStyles((theme) => ({
  root: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '14px',
    textTransform: 'uppercase',
    height: 'auto',
    width: '180px',
    [theme.breakpoints.up('md')]: {
      height: '47px',
    },
  },
}))(Button);

export const SwitchCustom = withStyles((theme) =>
  createStyles({
    root: {
      width: 49,
      height: 24,
      padding: 0,
      display: 'flex',
      margin: '0 16px',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      marginTop: '3px',
      '&$checked': {
        transform: 'translateX(32px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 0.7,
          backgroundColor: '#62bb58',
          borderColor: '#62bb58',
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 36 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

export const SelectCustom = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: 0,
      color: 'rgba(0, 0, 0, .54)',
      fontSize: '15px',
      fontFamily: 'Messina Sans Regular',
    },
    select: {
      color: '#000',
    },
  })
)(Select);
