import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  card: {
    margin: '15px',
    paddingBottom: '24px !important',
    padding: '24px 30px',
    background: '#F5F5F5',
    marginTop: '-68px',
    marginLeft: '10px',
    border: '5px solid transparent',
    borderRadius: '15px',
    marginBottom: '5px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  activePill: {
    cursor: 'pointer',
    background: '#12574D',
    color: 'white',
    margin: '15px 24px 15px 0',
    padding: '5px 10px',
    '&:focus': {
      background: '#12574D',
      color: 'white',
    },
  },
  inactivePill: {
    cursor: 'pointer',
    background: '#E7EEED',
    color: '#12574D',
    border: '1px solid #12574D',
    margin: '15px 24px 15px 0',
    padding: '5px 10px',
  },
  subComponent: {
    marginTop: '30px',
  },
}));
