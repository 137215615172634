import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Close as CloseIcon } from '@material-ui/icons';
import { DialogActions, DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { ILineItem } from '../../interfaces/IServiceVisits';
import { ICustomDiscount, IDiscount } from '../../interfaces/checkout.interfaces';
import { useStyles } from './DiscountsModal.styles';
import IServices from '../../interfaces/IServices';
import { DiscountItem } from '../../pages/Checkout/LineItems/DiscountItem';
import { DISCOUNT_TYPES, INDUSTRY_DISCOUNT_NAME } from '../../constants/checkout.constants';
import { IPatientBasicInfo } from '../../types/Patient';
import { Button } from '../common/Button';
import { CustomDiscount } from './CustomDiscount';
import IVariant, { IVariantItem } from '../../interfaces/IVariants';

const DiscountsModal = ({
  open,
  handleClose,
  lineItem,
  discounts,
  servicesIdMap,
  patient,
  variantServiceId,
  applyCallback,
}: {
  lineItem: ILineItem | undefined;
  open: boolean;
  handleClose: () => void;
  discounts: IDiscount[];
  servicesIdMap: Record<number, IServices>;
  variantServiceId: number | undefined;
  patient: IPatientBasicInfo;
  applyCallback: (data: any) => void;
}) => {
  if (!lineItem) {
    return null;
  }

  const classes = useStyles();
  const service = servicesIdMap[lineItem.serviceId];
  const { usedIndustryDiscount, isAdvocate } = patient;
  const [selectedDiscountId, setSelectedDiscountId] = useState<number | undefined>();
  const [customDiscountChecked, setCustomDiscountChecked] = useState<boolean>(false);
  const [customDiscount, setCustomDiscount] = useState<ICustomDiscount>();

  useEffect(() => {
    const discount = lineItem.checkoutLineItemDiscounts?.[0];
    if (discount) {
      const discountId = discount?.discountsId;
      const lineItemDiscountId = discount?.id;
      if (discountId) {
        setSelectedDiscountId(discountId);
      } else if (lineItemDiscountId) {
        setCustomDiscountChecked(true);
        setCustomDiscount({
          type: discount.discountType,
          amount: discount.discountTypeValue,
          reason: discount.description,
        });
      }
    }
  }, [lineItem]);

  const applyDiscount = (): void => {
    if (customDiscountChecked && customDiscount) {
      applyCallback({
        customData: {
          value: customDiscount.amount,
          description: customDiscount.reason,
          discount_type: customDiscount.type,
        },
      });
    } else {
      applyCallback({ discountId: selectedDiscountId });
    }
    setSelectedDiscountId(undefined);
    setCustomDiscountChecked(false);
    setCustomDiscount(undefined);
    handleClose();
  };

  const selectDiscount = ({ id }: IDiscount): void => {
    if (selectedDiscountId === id) {
      setSelectedDiscountId(undefined);
    } else {
      setSelectedDiscountId(id);
    }
  };

  const onClose = (): void => {
    setSelectedDiscountId(undefined);
    setCustomDiscountChecked(false);
    setCustomDiscount(undefined);
    handleClose();
  };

  const isDiscountDisabled = (discount: IDiscount): boolean => {
    const { id } = discount;

    if (customDiscountChecked) {
      return true;
    }

    if (usedIndustryDiscount && discount.name === INDUSTRY_DISCOUNT_NAME) {
      return true;
    }

    if (!isAdvocate && discount.name === INDUSTRY_DISCOUNT_NAME) {
      return true;
    }

    if (selectedDiscountId && selectedDiscountId !== id) {
      return true;
    }

    return false;
  };

  const isValidAmount = (type: string, amount: string | number): boolean => (
    amount === '' ||
      (amount > 0 && (type === DISCOUNT_TYPES.PERCENTAGE ? +amount <= 100 : +amount <= lineItem.totalWithoutDiscount))
  );

  const isCustomDiscountValid = (): boolean => {
    if (customDiscount) {
      const { type, amount } = customDiscount;
      const values = Object.values(customDiscount);

      if (values.length) {
        return !values.some((value: any) => !value) && isValidAmount(type, amount);
      }
      return false;
    }
    return true;
  };

  const disableApplyButton = (): boolean => (customDiscountChecked ? !isCustomDiscountValid() : false);

  let variantItem = null;
  if (lineItem.variantItemId && variantServiceId) {
    const variantService = servicesIdMap[variantServiceId];
    variantItem = variantService.variants
      ?.flatMap(({ items }: IVariant) => items)
      ?.find(({ id }: IVariantItem) => id === lineItem.variantItemId);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { borderRadius: '9px', maxWidth: '536px', maxHeight: '89%' } }}
    >
      <DialogTitle className={classes.modalHeaderContainer}>
        <div className={classes.modalHeader}>
          <span className={classes.headerTitle}>Apply Discount to{` ${service?.name || variantItem?.name}`}</span>
          <CloseIcon onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.listContainer}>
          {discounts
            .filter((discount) => discount.active) // check validation
            .map((discount: IDiscount) => (
              <DiscountItem
                key={`dis-${discount.id}`}
                label={discount.name}
                onChange={() => {
                  selectDiscount(discount);
                }}
                disabled={isDiscountDisabled(discount)}
                checked={selectedDiscountId === discount.id}
              />
            ))}
          <DiscountItem
            label="Custom Discount"
            onChange={() => {
              setCustomDiscountChecked((prevState) => !prevState);
            }}
            disabled={!!selectedDiscountId}
            checked={customDiscountChecked}
          />
          <div style={{ marginLeft: '65px' }}>
            <CustomDiscount
              discounts={discounts}
              lineItem={lineItem}
              disabled={!customDiscountChecked}
              setCustomDiscount={setCustomDiscount}
              isValidAmount={isValidAmount}
              initialState={customDiscount}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonSection}>
          <Button className={classes.cancelButton} title="Cancel" onClick={onClose} />
          <Button
            className={classes.applyButton}
            title="Apply"
            onClick={applyDiscount}
            disabled={disableApplyButton()}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountsModal;
