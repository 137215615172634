import { makeStyles, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Messina Sans Bold',
    letterSpacing: '-0.04px',
    color: '#393B3F',
    opacity: 0.4,
    fontSize: '18px',
    width: '75px',
    display: 'inline-block'
  },
  titleData: {
    letterSpacing: '0px',
    color: '#393B3F',
    opacity: 1,
    fontFamily: 'Messina Sans Regular',
    fontSize: '18px'
  },
  text: {
    color: '#393B3F',
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    textAlign: 'center'
  },
  form: {
    display: 'flex',
    justifyContent: 'center'
  },
  input: {
    border: '1px solid #393B3F1F',
    borderRadius: '4px',
    width: '70px',
    height: '36px',
    color: '#12574D',
    fontSize: '14px',
    textAlign: 'center',
    fontFamily: 'Messina Sans SemiBold',
    outline: 'none'
  },
  inputError: {
    borderColor: 'red'
  }
}));

export const RemoveButton = withStyles(() => ({
  root: {
    backgroundColor: '#FBDADA',
    color: '#393B3F',
    border: '1px solid #FBDADA',
    '&:hover': {
      backgroundColor: '#FBDADA',
      color: '#393B3F',
    }
  }
}))(Button);

export const CancelButton = withStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF',
    color: '##12574D',
    border: '1px solid #393B3F1F',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '##12574D',
    }
  }
}))(Button);

export const ConfirmButton = withStyles(() => ({
  root: {
    backgroundColor: '#12574D',
    color: '#FFFFFF',
    border: '1px solid #12574D',
    '&:hover': {
      backgroundColor: '#12574D',
      color: '#FFFFFF',
    }
  }
}))(Button);
