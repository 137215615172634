import React from 'react';
import { Checkbox, TableCell } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { MEDSPA_ADMIN_SERVICES_MANAGEMENT } from 'src/routes/medspaAdminRoutes';
import IServices from '../../../interfaces/IServices';
import { TableCellCustom, TableRowStriped, useStyles } from './index.styles';
import { IVariantItem } from '../../../interfaces/IVariants';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH } from '../../../routes/practitionerRoutes';

interface Props {
  service: IServices | IVariantItem;
  selectedService?: boolean;
  onChangeSelection?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  servicesManagementStepCompleted: boolean;
  renderLocation?: string;
  isMedspaAdmin?: boolean;
}
const ServiceRow = ({
  service,
  selectedService,
  onChangeSelection,
  servicesManagementStepCompleted,
  renderLocation,
  isMedspaAdmin = false,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const serviceModalParams = new URLSearchParams({
    serviceId: String(service.id),
  }).toString();

  return (
    <TableRowStriped
      className={classes.itemTab}
      hover
      key={service.id}
      onClick={() =>
        servicesManagementStepCompleted &&
        history.push(
          isMedspaAdmin
            ? `${MEDSPA_ADMIN_SERVICES_MANAGEMENT}?${serviceModalParams}`
            : `${PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management', renderLocation)}?${serviceModalParams}`
        )
      }
    >
      <TableCell>
        {!servicesManagementStepCompleted && (
          <Checkbox
            color="primary"
            checked={selectedService}
            onChange={onChangeSelection}
            style={{ marginLeft: '-0.7rem' }}
          />
        )}
        {service.name}
      </TableCell>
      <TableCell>${service.price?.toFixed(2)}</TableCell>
      <TableCell>{service.serviceGroupName}</TableCell>
      <TableCell>{service.supplierServiceName}</TableCell>
      {servicesManagementStepCompleted ? (
        <TableCellCustom active={service.showInEmr}>
          <div>{service.showInEmr ? 'Active' : 'Inactive'}</div>
        </TableCellCustom>
      ) : (
        <TableCellCustom backgroundColor="#ECEAE4">
          <div>Portrait</div>
        </TableCellCustom>
      )}
    </TableRowStriped>
  );
};

export default ServiceRow;
