import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    cursor: 'pointer',
  },
  documentIconContainer: {
    height: '100px',
    width: '100px',
    display: 'flex',
    flexDirection: 'row',
  },
  documentIcon: {
    width: '50px',
    height: '100%',
  },
  selectedDocumentContainer: {
    padding: '10px',
    background: '#EDFCF1',
    borderRadius: '8px',
    marginBottom: '10px',
    width: '100%',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '#55B76F 1px solid',
  },
  selectedDeleteButton: {
    marginLeft: '10px',
    borderRadius: '50%',
    padding: '8px',
    color: 'black',
  },
  selectedDocumentNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
});
