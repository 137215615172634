import React from 'react';
import moment from 'moment';
import { Pill } from '../../components/common/Pill';
import { ColumnType } from '../../types/TablePaginator';
import { formatNumber } from '../../utils/checkout.utils';

export const TRANSACTIONS_COLUMN: ColumnType[] = [
  { field: 'checkoutId', title: 'Checkout ID', sortable: true, sortField: 'checkout_transactions.checkout_id' },
  { field: 'serviceVisitId', title: 'Service visit ID', sortable: true, sortField: 'checkouts.service_visit_id' },
  { field: 'customerId', title: 'Patient MRN' },
  {
    field: 'createdAtInPstTimezone',
    title: 'Checkout date PST',
    sortable: true,
    sortField: 'checkouts.created_at_in_pst_timezone',
    render: (value: string) => {
      const date = moment(value.split(' -')[0]);
      return date.format('MM/DD/YYYY HH:mm');
    },
  },
  {
    field: 'transactionCreatedAt',
    title: 'Transaction date UTC',
    sortable: true,
    sortField: 'checkout_transactions.created_at',
    render: (value: string) => {
      const date = moment(value);
      return date.format('MM/DD/YYYY HH:mm');
    },
  },
  {
    field: 'practitioner',
    title: 'Practitioner',
    sortable: true,
    sortField: 'prac_identities.first_name',
    width: '100px',
  },
  { field: 'eventType', title: 'Event type' },
  { field: 'customerName', title: 'Customer', width: '100px' },
  { field: 'total', title: 'Total' },
  { field: 'discounts', title: 'Discounts' },
  { field: 'netSales', title: 'New Sales' },
  { field: 'otherTendersTotal', title: 'Other tenders total' },
  { field: 'squareTransactionAmount', title: 'Square transaction amount' },
  {
    field: 'amountPaid',
    title: 'Total paid',
    render: (value: string) =>
      value ? <Pill title={`$ ${formatNumber(parseFloat(value))}`} color="green" style={{ cursor: 'pointer' }} /> : '-',
  },
  { field: 'significantSquareTransactionId', title: 'Square transaction ID' },
  { field: 'note', title: 'Note', width: '250px' },
  {
    field: 'details',
    title: 'Open square',
    render: (value: string, row: any) =>
      row.processedInSquare ? (
        <a href={value} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
          <Pill title="Square" color="gray" style={{ cursor: 'pointer' }} />
        </a>
      ) : null,
  },
  {
    field: 'ehrUrl',
    title: 'Open EHR',
    render: (value: string) => (
      <a href={value} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
      </a>
    ),
  },
];

export const LINE_ITEM_COLUMNS: ColumnType[] = [
  { field: 'checkoutId', title: 'Checkout ID', sortable: true, sortField: 'checkout_transactions.checkout_id' },
  { field: 'serviceVisitId', title: 'Service visit ID', sortable: true, sortField: 'checkouts.service_visit_id' },
  { field: 'checkoutLineItemId', title: 'Checkout line item ID' },
  { field: 'customerId', title: 'Patient MRN' },
  { field: 'practitioner', title: 'Practitioner' },
  { field: 'serviceId', title: 'Service ID' },
  {
    field: 'serviceVisitCreatedAt',
    title: 'Service visit created at',
    sortable: true,
    sortField: 'service_visits.created_at',
    render: (value: string) => {
      const date = moment(value);
      return date.format('MM/DD/YYYY');
    },
  },
  { field: 'serviceName', title: 'Service name', width: '200px', sortable: true, sortField: 'services.name' },
  { field: 'quantity', title: 'Quantity' },
  { field: 'unit', title: 'Unit' },
  { field: 'totalWithoutDiscount', title: 'Total without discount' },
  { field: 'totalWithDiscount', title: 'Total with discount' },
  { field: 'numberOfRealAssetsInvolved', title: 'Number of real assets involved' },
  { field: 'sumOfAllCosts', title: 'Sum of all costs', render: (value: number | null) => value || '0' },
  {
    field: 'ehrUrl',
    title: 'Open EHR',
    render: (value: any) => (
      <a href={value} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
      </a>
    ),
  },
];

export const DISCOUNT_COLUMNS: ColumnType[] = [
  { field: 'checkoutId', title: 'Checkout ID', sortable: true, sortField: 'checkout_transactions.checkout_id' },
  { field: 'serviceVisitId', title: 'Service visit ID', sortable: true, sortField: 'checkouts.service_visit_id' },
  { field: 'checkoutLineItemId', title: 'Checkout line item ID' },
  { field: 'customerId', title: 'Patient MRN' },
  { field: 'practitioner', title: 'Practitioner' },
  { field: 'date', title: 'Date', sortable: true, sortField: 'checkouts.created_at', width: '70px' },
  { field: 'value', title: 'Value' },
  { field: 'discountType', title: 'Discount type' },
  { field: 'description', title: 'Descriptions' },
  { field: 'discountName', title: 'Discount name' },
  { field: 'discountId', title: 'Discount ID' },
  { field: 'servicePrice', title: 'Service price' },
  { field: 'amountDiscounted', title: 'Dollar amount discounted' },
  {
    field: 'ehrUrl',
    title: 'Open EHR',
    render: (value: any) => (
      <a href={value} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
      </a>
    ),
  },
];

export const TENDER_COLUMNS: ColumnType[] = [
  { field: 'checkoutId', title: 'Checkout ID', sortable: true, sortField: 'checkout_transactions.checkout_id' },
  { field: 'serviceVisitId', title: 'Service visit ID', sortable: true, sortField: 'checkouts.service_visit_id' },
  { field: 'checkoutTenderId', title: 'Checkout tender ID' },
  {
    field: 'createdAtInPstTimezone',
    title: 'Checkout date PST',
    sortable: true,
    sortField: 'checkouts.created_at_in_pst_timezone',
    render: (value: string) => {
      const date = moment(value.split(' -')[0]);
      return date.format('MM/DD/YYYY HH:mm');
    },
  },
  {
    field: 'transactionCreatedAt',
    title: 'Transaction date UTC',
    sortable: true,
    sortField: 'checkout_transactions.created_at',
    render: (value: string) => {
      const date = moment(value);
      return date.format('MM/DD/YYYY HH:mm');
    },
  },
  { field: 'customerId', title: 'Patient MRN' },
  { field: 'practitioner', title: 'Practitioner' },
  { field: 'voucher', title: 'voucher' },
  { field: 'value', title: 'Value' },
  { field: 'tenderType', title: 'Tender type' },
  {
    field: 'ehrUrl',
    title: 'Open EHR',
    render: (value: any) => (
      <a href={value} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
      </a>
    ),
  },
];

export const ASSETS_SOLD_COLUMNS: ColumnType[] = [
  { field: 'checkoutId', title: 'Checkout ID', sortable: true, sortField: 'checkout_transactions.checkout_id' },
  { field: 'serviceVisitId', title: 'Service visit ID', sortable: true, sortField: 'checkouts.service_visit_id' },
  { field: 'checkoutLineItemId', title: 'Checkout line item ID' },
  { field: 'serviceId', title: 'Service ID' },
  { field: 'serviceName', title: 'Service', sortable: true, sortField: 'services.name', width: '200px' },
  { field: 'quantityInLineItem', title: 'Quantity in line item' },
  { field: 'numberOfAssetsInvolved', title: 'Number of assets involved' },
  {
    field: 'costsOfAssetsInvolved',
    title: 'Cost of assets involved',
    width: '170px',
    render: (value: number | null) => value || '0',
  },
  {
    field: 'sumOfAllCosts',
    title: 'Sum of all costs',
    render: (value: number | null) => value || '0',
  },
  { field: 'salePrice', title: 'Sales price' },
  {
    field: 'margin',
    title: 'Margin',
    width: '170px',
    render: (value: number | null) => value || '0',
  },
  {
    field: 'internalSerialNumbers',
    title: 'Internal serial number',
  },
  { field: 'warehouseName', title: 'Warehouse' },
  { field: 'purchaseOrderIds', title: 'Purchase order IDs' },
  {
    field: 'ehrUrl',
    title: 'Open EHR',
    render: (value: any) => (
      <a href={value} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
      </a>
    ),
  },
];
