import React, { useState, useEffect, useRef, MouseEvent, ChangeEvent, ReactElement } from 'react';
import { snakeCase } from 'lodash';
import { TableRow, TableCell, Collapse, Box } from '@material-ui/core';
import { ButtonStatus } from '../../../common/TableStyles/ButtonStatus';
import { useStyle } from '../TodoListTabs/rowItem.styles';
import { templateStyle, RowClass } from './marketing.styles';
import { ISMSTemplateMacros, IUpdateSMSTemplate } from '../../../../interfaces/IPractitionerSMSTemplate';

export interface TemplateEditProps {
  body: string | undefined;
  macros: ISMSTemplateMacros | undefined;
  open: boolean;
  setOpen?: (a: boolean) => void;
  mutateTemplateData?: (d: IUpdateSMSTemplate) => void;
  practitionerId?: number;
}

const formatMacro = (macro: string): string => `+[${snakeCase(macro).toUpperCase()}]`;

export const TemplateEdit: React.FC<TemplateEditProps> = ({
  body,
  macros,
  open,
  setOpen,
  practitionerId,
  mutateTemplateData,
}: TemplateEditProps): ReactElement => {
  const [edits, setEdits] = useState<string>('');
  const [selected, setSelected] = useState<number>(0);

  const mainText = useRef<HTMLTextAreaElement | null>(null);

  const classes = useStyle();
  const subclasses = templateStyle();

  useEffect(() => setEdits(body || ''), []);
  useEffect(() => setSelected(body?.length || 0), []);

  const handleSave = (): void => {
    mutateTemplateData && mutateTemplateData({ body: edits, practitionerId });
    setOpen && setOpen(!open);
  };

  const setPoint = (target: HTMLTextAreaElement | undefined): void => {
    setSelected(target?.selectionEnd || 0);
  };

  const toggleSeparator = (): RowClass => ({
    background: '#f2f5f5',
    borderTop: open ? '3px solid #fff4' : '3px solid transparent',
  });

  const insertMacro = (macroText: string): void => {
    const formattedMacro = formatMacro(macroText);
    const target: HTMLTextAreaElement | null = mainText?.current;
    if (target) {
      setPoint(target);
      target.value = `${target.value.slice(0, selected)}${formattedMacro}${target.value.slice(selected)}`;
      target.focus();
      target.selectionEnd = selected + formattedMacro.length;
      setSelected(target.selectionEnd);
      setEdits(target.value);
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const newText: string = event?.currentTarget?.value;
    typeof newText === 'string' && setEdits(newText);
    event?.currentTarget && setPoint(event.currentTarget);
  };

  const handleTextClick = (event: MouseEvent<HTMLTextAreaElement>): void => {
    event?.currentTarget && setPoint(event.currentTarget);
  };

  const handleMacroClick = (event: MouseEvent<HTMLButtonElement>): void => {
    const macro = event.currentTarget?.getAttribute('data-mv');
    macro && insertMacro(macro);
  };

  const currentMacros = { ...(macros || {}) } as ISMSTemplateMacros;

  return (
    <TableRow style={toggleSeparator()}>
      <TableCell className={subclasses.slim} colSpan={3}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box className={classes.expandedText}>
            <textarea
              ref={mainText}
              className={subclasses.editor}
              defaultValue={edits}
              onChange={handleTextChange}
              onClick={handleTextClick}
            />
            <div className={subclasses.footer}>
              {Object.keys(currentMacros).map(
                (key: string): ReactElement => (
                  <ButtonStatus
                    data-cy="closed"
                    key={key}
                    data-mv={key}
                    className={subclasses.macrobutton}
                    onClick={handleMacroClick}
                  >
                    {' '}
                    {currentMacros[key]}{' '}
                  </ButtonStatus>
                )
              )}
            </div>
            <ButtonStatus data-cy="view" onClick={handleSave} variant="outlined" className={subclasses.actionButton}>
              {' '}
              SAVE{' '}
            </ButtonStatus>
          </Box>
        </Collapse>
      </TableCell>
      <TableCell className={subclasses.slimTop} colSpan={2} />
    </TableRow>
  );
};
