import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableContainer } from '@material-ui/core';
import { debounce } from 'lodash';
import classnames from 'classnames';
import AdminServiceRow from 'src/components/DashboardAdministrator/Services/ServiceRow';
import PractitionerServiceRow from 'src/components/DashboardPractitioner/Tabs/PriceList/ServiceRow';
import { RootState } from 'src/rematch';
import {
  USER_TYPES,
  FAKE,
  NAIVE,
  MISC_SERVICE_GROUP_NAME,
  CLERICAL_SERVICE_GROUP_NAME,
  AESTHETICIAN_SERVICERS_GROUP_NAME,
} from 'src/constants/general.constants';
import IServiceGroup from 'src/interfaces/IServiceGroup';
import Page from 'src/components/common/Page';
import InputSearch from '../../components/common/InputSearch';
import { ICustomerViewParams } from '../../interfaces/CustomerParams';
import ButtonAhref from '../../components/common/ButtonAhref';
import { useStyles } from './servicesListPage.styles';
import { hasAccessTo } from '../../utils/auth.utils';
import { CREATE_CUSTOMER } from '../../constants/actions.constants';
import { useServiceGroups, useServicesListCount, useServicesList } from '../../hooks/queries/services/useServices';
import IServices, { IServicesListParams } from '../../interfaces/IServices';
import { SERVICES_COLUMNS, PRACTITIONER_SERVICES_COLUMNS } from '../../constants/patients/columns.constants';
import { MultipleSkeleton, ShortMultipleSkeleton } from '../../components/common/LoadingSkeleton';
import TableHeader from '../../components/common/Table/TablePaginatorHeader';
import useSuppliers from '../../hooks/queries/useSuppliers';
import { useOnScreen } from '../../utils/global';

const INITIAL_DIRECTION = 'ASC';

const initialParams = {
  page: 1,
  search: '',
  createdAsLead: false,
  sortDirection: INITIAL_DIRECTION,
};

const NEW_SERVICE_URL = '/administrator/new-service';
const ORDER_REQUEST_URL = 'https://support.joinportrait.com/knowledge/kb-tickets/new';

const ServicesListPage = () => {
  const classes = useStyles();
  const { permissions, userType } = useSelector(({ auth }: RootState) => auth);

  const [searchQuery, setSearchQuery] = useState('');
  const [queryParams, setQueryParams] = useState<IServicesListParams>(initialParams);
  const { data: servicesCount = 0 } = useServicesListCount(queryParams);

  const { data: serviceGroups, isFetching: isFetchingServiceGroups } = useServiceGroups();

  const { data: suppliers, isFetching: isFetchingSuppliers } = useSuppliers();

  const refBottom = useRef();
  const isBottomVisible = useOnScreen(refBottom);
  const [isDataRendered, setIsDataRendered] = useState(false);

  const {
    isLoading,
    isFetching,
    data: { services },
    fetchNextPage,
  } = useServicesList(queryParams, servicesCount);

  const excludeServiceGroupIds = (serviceGroupName: string): boolean =>
    [MISC_SERVICE_GROUP_NAME, CLERICAL_SERVICE_GROUP_NAME, AESTHETICIAN_SERVICERS_GROUP_NAME].indexOf(
      serviceGroupName
    ) < 0;

  const practitionerView = userType === USER_TYPES.PRACTITIONER;
  const canCreateService =
    !practitionerView && Boolean(permissions?.length > 0) && hasAccessTo(CREATE_CUSTOMER, permissions);

  const filteredServices = practitionerView
    ? services?.filter(
      ({ serviceGroupName, name }: IServices) =>
        excludeServiceGroupIds(serviceGroupName) && name.indexOf(FAKE) < 0 && name.indexOf(NAIVE) < 0
    )
    : services;

  const filteredServiceGroups = practitionerView
    ? serviceGroups?.filter(({ name }: IServiceGroup) => excludeServiceGroupIds(name))
    : serviceGroups;

  const hasMore = services?.length < servicesCount;

  useEffect(() => {
    if (!isFetching && !isLoading && fetchNextPage && hasMore && isBottomVisible) {
      setIsDataRendered(false);
      fetchNextPage();
    }
  }, [isBottomVisible, isFetching, fetchNextPage, isLoading, hasMore]);

  useEffect(() => {
    setTimeout(() => {
      setIsDataRendered(true);
    }, 1500);
  }, [services.length]);

  const onChangeFilters = (event: React.ChangeEvent<any>): void => {
    const propertyName: string = event.target.name as string;
    setQueryParams((prevState) => ({
      ...prevState,
      [propertyName]: event.target.value,
      page: initialParams.page,
    }));
  };
  const debounceCall = (query: string, params: ICustomerViewParams) => {
    setQueryParams({
      ...params,
      search: query,
    });
  };

  const doSearch = useMemo(
    () => debounce((query: string, params: ICustomerViewParams) => debounceCall(query, params), 1000),
    []
  );

  const onInputChange = (event: any) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    doSearch(newSearchQuery, queryParams);
  };

  return (
    <Page title="Services">
      <Box padding={2} width="100%">
        <Box display="flex" justifyContent="flex-end">
          <Box style={{ marginLeft: '18px' }}>
            {canCreateService ? (
              <ButtonAhref buttonStyle="big" text="NEW SERVICE" href={NEW_SERVICE_URL} />
            ) : (
              <ButtonAhref buttonStyle="big" text="MAKE ORDER REQUEST" href={ORDER_REQUEST_URL} newWindow />
            )}
          </Box>
        </Box>
        <Box className={classes.container}>
          <FormControl size="medium" variant="outlined" className={classes.formDropdown}>
            <InputLabel id="service-group" className={classes.formInputSelect}>
              Service Group
            </InputLabel>
            <Select
              data-cy="service-group"
              labelId="service-group"
              name="serviceGroupId"
              className={classes.dropdown}
              value={queryParams.serviceGroupId}
              onChange={onChangeFilters}
              fullWidth
              label="Service Group"
              disabled={isFetchingServiceGroups}
            >
              <MenuItem value="">None</MenuItem>
              {filteredServiceGroups?.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="medium" variant="outlined" className={classes.formDropdown}>
            <InputLabel id="supplier" className={classes.formInputSelect}>
              Supplier
            </InputLabel>
            <Select
              data-cy="supplier"
              labelId="supplier"
              name="supplierId"
              className={classes.dropdown}
              value={queryParams.supplierId}
              onChange={onChangeFilters}
              fullWidth
              label="Supplier"
              disabled={isFetchingSuppliers}
            >
              <MenuItem value="">None</MenuItem>
              {suppliers?.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <InputSearch
              placeholder="Search..."
              onChangeValue={onInputChange}
              value={searchQuery}
              isLoading={isLoading}
            />
          </Box>
        </Box>
        <Box>
          <Box padding={2}>
            <div className={classnames(classes.tableGeneral, classes.infiScrollContainer)}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHeader columns={practitionerView ? PRACTITIONER_SERVICES_COLUMNS : SERVICES_COLUMNS} />
                  <TableBody>
                    {isLoading ? (
                      <MultipleSkeleton width="80%" />
                    ) : (
                      filteredServices?.map((row: IServices) =>
                        practitionerView ? (
                          <PractitionerServiceRow key={row.id} {...row} />
                        ) : (
                          <AdminServiceRow key={row.id} {...row} />
                        )
                      )
                    )}
                    <div
                      ref={refBottom}
                      className={classnames({ [classes.bottomRefHidden]: !isDataRendered || isFetching })}
                    />
                    {hasMore && !isLoading && (
                      <div className={classes.bottomRef}>
                        <ShortMultipleSkeleton length={2} />
                      </div>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default ServicesListPage;
