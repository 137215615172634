import { withStyles, makeStyles, TableCell } from '@material-ui/core';

const TableCellHeader = withStyles(() => ({
  root: {
    fontSize: '15px',
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  navbar: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '10px',
  },
  pager: {
    margin: 'auto 15px auto 0px',
    minWidth: '230px',
  },
  pagination: {
    margin: 'auto 15px auto 0px',
    minWidth: '230px',
  },
  pager2: {
    margin: 'auto 15px auto auto',
    minWidth: '250px',
  },
  pagerBottom: {
    margin: 'auto 15px auto auto',
    minWidth: '250px',
  },
  searchbox: {
    margin: 'auto 15px auto auto',
    minWidth: '145px',
  },
  search: {
    fontFamily: 'Messina Sans Regular !important',
    fontSize: '14px',
  },
  header: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '18px',
    fontWeight: 600,
    color: '#949494',
    paddingLeft: 5,
    margin: 'auto auto auto 15px',
  },
  topdownload: {
    margin: 'auto 0 ',
  },
  download: {
    margin: 'auto 0px auto auto',
  },
  download2: {
    margin: 'auto 0px auto 15px',
  },
  csvicon: {
    fontSize: '22px',
    overflow: 'visible',
  },
  theader: {
    cursor: 'pointer',
    borderRadius: '2px',
    borderTop: '1px dotted #fff0',
    borderLeft: '1px solid #eeeeee33',
    borderRight: '1px solid #eeeeee33',
    textAlign: 'center',
    padding: '16px 10px 5px 10px',
    fontSize: '13px',
    '&:first-of-type': {
      textAlign: 'left',
    },
    '&[data-shrink="true"]': {
      fontSize: '11px',
      minWidth: '96px',
    },
  },
  rowIcon: {
    marginTop: 4,
    marginBottom: 3,
    '&:first-of-type': {
      marginTop: 3,
      marginBottom: 0,
    },
  },
  centerSmall: {
    textAlign: 'center',
    fontSize: 11,
  },
  campaignName: {
    textAlign: 'center',
    fontSize: 11,
    maxWidth: 200,
    overflowX: 'scroll',
  },
  tsort: {
    cursor: 'pointer',
    borderRadius: '2px',
    borderTop: '1px dotted',
    borderLeft: '1px solid #eeeeee44',
    borderRight: '1px solid #eeeeee66',
    backgroundColor: '#eeeeee33',
    transitionDuration: '0.1s',
  },
  selectform: {
    minWidth: '200px',
    margin: 'auto auto auto auto',
  },
  statusform: {
    margin: 'auto 20px auto 20px',
  },
  providerform: {
    margin: 'auto auto auto 10px',
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'relative',
    left: '-12px',
    height: '60px',
    width: 'fit-content',
    margin: 'auto',
    '& svg': {
      marginTop: '0',
    },
    '&[data-left="true"]': {
      margin: 'auto 0',
    },
  },
  chevron: {
    display: 'block',
    marginBottom: '5px',
    color: '#ccc',
    '&[data-active="true"]': {
      color: '#000',
    },
    '&[data-sortable="false"]': {
      opacity: 0,
    },
  },
  leadStatusSelect: {
    margin: 'auto',
    marginTop: '-16px',
    fontSize: '14px',
    width: '125px',
    minWidth: '125px',
    maxWidth: '125px',
    '& > *': {
      fontSize: '13px',
      width: '125px',
      minWidth: '125px',
      maxWidth: '125px',
    },
    '& input': {
      fontSize: '13px',
      width: '125px',
      minWidth: '125px',
      maxWidth: '125px',
    },
  },
  leadStatusSelectFlex: {
    margin: 'auto 0 auto auto',
    fontSize: '12px',
    width: '125px',
    minWidth: '125px',
    maxWidth: '125px',
    marginTop: '-16px',
    textAlign: 'right',
    '& *': {
      fontSize: '12px',
      width: '125px',
      minWidth: '125px',
      alignItems: 'left',
      maxWidth: '125px',
    },
    '& > div > div': {
      marginBottom: '-4px',
    },
    '& svg': {
      top: '7px',
      right: '60px',
    },
  },
  focusDialog: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 25px 45px 25px',
    width: '90vw',
    maxWidth: '100%',
  },
  reportsMain: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 45px 0',
    width: '100%',
    overflow: 'hidden',
  },
  focusDialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
    borderBottom: '1px solid #0002',
    paddingBottom: '25px',
    paddingTop: '12px',
    paddingRight: '55px',
    '& > h4': {
      margin: 'auto 0 auto 15px',
    },
  },
  reportsHeader: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0',
    borderBottom: '1px solid #0002',
    padding: '25px 55px 25px 25px',
    backgroundColor: '#12574d',
    color: '#eee',
    '& > h4': {
      margin: 'auto 0 auto 15px',
    },
  },
  focusDialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  focusDialogBack: {
    margin: '30px 0 0 0',
    height: '55px',
  },
  focusDialogLine: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: '14px',
    margin: '0 auto 15px 0',
    '&[data-disabled="true"]': {
      color: '#999',
    },
    '& > p:nth-child(2)': {
      fontFamily: 'Messina Sans Regular',
      fontSize: '11px',
    },
    '& p': {
      margin: 'auto 0 auto auto',
    },
    '& p:first-child': {
      fontSize: '11px',
      margin: 'auto 10px 0 0',
    },
  },
  detailGroup: {
    padding: '15px',
    paddingTop: '22px',
    borderRadius: 7,
    border: '1px solid #ccc',
    '& textarea': {
      width: '100%',
      margin: 0,
      minHeight: 80,
      height: '100%',
      padding: 8,
      outline: 'none',
      border: 'none',
      resize: 'none',
    },
    '& button': {
      zIndex: 10,
      fontSize: 30,
      bottom: 0,
      right: 0,
      display: 'none',
      position: 'absolute',
      '&[data-editing="true"]': {
        display: 'block',
      },
    },
  },
  leadStatus: {
    minWidth: '110px',
    height: '35px',
    display: 'flex',
    backgroundColor: 'rgb(248,243,221)',
    color: 'rgb(131,100,31)',
    margin: 'auto 25px auto 25px',
    borderRadius: '4px',
    padding: '4px',
    '&[data-status="booked"]': {
      color: 'rgb(148,143,221)',
    },
    '&[data-status="converted"]': {
      color: 'rgb(74,99,127)',
    },
    '&[data-status="notInterested"]': {
      color: 'rgb(37,89,78)',
    },
    '&[data-align="right"]': {
      margin: 'auto 0 auto auto',
    },
  },
  rowStatus: {
    margin: '0',
  },
  leadStatusText: {
    margin: 'auto',
    fontSize: '14px',
  },
  focusMenu: {
    display: 'flex',
    flexDirection: 'column',
  },
  focusButton: {
    height: '55px',
    width: '75%',
    margin: '10px 0 10px auto',
  },
  sideBy: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '10px',
    '& > div': {
      width: '50%',
    },
  },
  comStat: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(75% - 8px)',
    padding: '10px',
    margin: '0 0 0 auto',
    '&:first-of-type': {
      marginTop: '25px',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: '100%',
    },
    '& > div > p': {
      margin: '0 0 5px auto',
      fontSize: '12px',
      width: '100%',
      textAlign: 'left',
      paddingLeft: '8px',
    },
  },
  talkIcon: {
    fontSize: '55px',
    margin: '10px 0 10px auto',
    color: '#555',
  },
  ccIcon: {
    color: 'dodgerblue',
    fontSize: '30px',
    margin: '5px 20px',
  },
  userIcon: {
    fontSize: '67px',
    color: '#aaa',
    marginRight: '15px',
  },
  noShadow: {
    boxShadow: 'none',
  },
  noShadowShort: {
    boxShadow: 'none',
    width: '100%',
    minWidth: 400,
    overflow: 'scroll',
    marginTop: 0,
    '&[data-obscured="true"]': {
      opacity: 0.3,
    },
  },
  centerRowMed: {
    '& *': {
      fontSize: 12,
    },
  },
  centerRowSmall: {
    '& *': {
      fontSize: 10,
    },
  },
  loadSkeleton: {
    minHeight: '400px',
  },
  tableHeader: {
    borderTop: '1px solid #0001',
  },
  centerRow: {
    textAlign: 'center',
  },
  leadTable: {
    marginTop: '15px',
  },
  cellLink: {
    color: '#12574d',
    textAlign: 'left',
    fontSize: 13,
  },
  phoneCell: {
    fontSize: '12px',
  },
  divider: {
    margin: '10px 0px',
    backgroundColor: '#00000000',
  },
  phonePill: {
    border: '1px solid #ccc',
    borderRadius: 12,
    padding: '3px 12px',
    '& a': {
      textDecoration: 'none',
    },
  },
  closeDialog: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  nameRow: {
    minWidth: 198,
  },
  channelSelect: {
    marginTop: '0 !important',
  },
  flexColumns: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 15,
  },
  adCampaignsTitle: {
    fontFamily: 'Messina Sans Light',
    margin: 'auto 0',
  },
  reportHeaderRow: {
    '& th': {
      fontSize: 11,
      maxWidth: 90,
      background: 'transparent',
    },
    '& th:first-child': {
      fontSize: 11,
      maxWidth: 'unset',
    },
  },
  reportRow: {
    '& td': {
      fontSize: 11,
      maxWidth: 90,
      fontFamily: 'Messina Sans Light',
    },
    '& td:first-child': {
      maxWidth: 'unset',
      fontFamily: 'Messina Sans SemiBold',
      position: 'sticky',
      top: 0,
      left: 0,
      minWidth: '200px',
    },
  },
  stickyTable: {
    overflow: 'scroll',
    '& tr:nth-child(odd)': {
      backgroundColor: '#f5f5f5',
    },
    '& tr:first-child': {
      backgroundColor: 'transparent',
    },
  },
  requestAccess: {
    width: 'fit-content',
    margin: '50px auto',
  },
  campaignButton: {
    margin: 'auto 5px auto 15px',
    padding: '8px 15px',
    display: 'block',
  },
  reportButton: {
    margin: 'auto auto auto 5px',
    padding: '8px 15px',
    display: 'block',
  },
  newCampaignButton: {
    display: 'block',
    margin: 'auto 0 auto auto',
    padding: '8px 15px',
    width: 256,
    color: '#eee',
    borderColor: '#eee',
  },
  saveCampaignButton: {
    display: 'block',
    margin: '25px auto auto auto',
    padding: '8px 15px',
    width: '100%',
    '&:last-of-type': {
      marginTop: 12,
    },
  },
  campaignInput: {
    width: '100%',
  },
  channelSelectLarge: {
    margin: 0,
    width: '100%',
    minHeight: 48,
  },
  accordion: {
    width: '100%',
    zIndex: 1,
  },
  accordionRow: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
    '& > div': {
      margin: 'auto',
      marginRight: 0,
    },
    '& > div:nth-child(1)': {
      marginRight: 'auto',
      marginLeft: 15,
      fontSize: '1rem',
    },
    '& > div:nth-child(2)': {
      marginRight: 0,
      marginLeft: 'auto',
      width: 200,
      textAlign: 'center',
    },
    '& > div:last-child': {
      marginRight: 15,
      marginLeft: 25,
    },
  },
  longChip: {
    width: '100%',
  },
  campaignDetails: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 8,
    borderTop: '1px solid #0001',
    paddingTop: 15,
    '& > div': {
      width: '20%',
      minWidth: 165,
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto 0 auto auto',
      paddingLeft: '8px',
      borderLeft: '1px solid #0003',
    },
    '& > div:first-child': {
      margin: 'auto auto auto 0',
    },
    '& > div:last-child': {
      margin: 'auto 25px auto auto',
    },
    '& > div > div': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& p': {
      fontSize: 11,
      margin: 'auto 0 auto auto',
      fontFamily: 'Messina Sans Regular',
    },
    '& p:first-child': {
      paddingRight: 5,
      margin: 'auto auto auto 0',
      fontFamily: 'Messina Sans SemiBold',
    },
  },
  fixedChip: {
    width: 75,
  },
  newCampaignTitle: {
    padding: '15px 25px 15px 25px',
    margin: 0,
    backgroundColor: '#12574d',
    color: '#eee',
    fontFamily: 'Messina Sans Light',
  },
  editInsert: {
    position: 'absolute',
    top: 15,
    right: 5,
    height: 'calc(100vh - 100px)',
    width: 425,
    zIndex: 10,
  },
  spendLimit: {
    fontSize: 10,
    fontFamily: 'Messina Sans Light',
  },
  campaignDialog: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 0 45px 0',
    width: '90vw',
    maxWidth: '100%',
    backgroundColor: '#f5f5f5',
    overflow: 'hidden',
  },
  campaignDialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    margin: '25px 0 15px 0',
    borderBottom: '1px solid #0002',
    padding: '25px 55px 25px 25px',
    backgroundColor: '#12574d',
    color: '#eee',
    '& > h4': {
      margin: 'auto 0 auto 15px',
    },
  },
  leadRowNotes: {
    maxWidth: 220,
    maxHeigh: 80,
    minWidth: 220,
    minHeigh: 80,
    overflowY: 'scroll',
    fontSize: 11,
    '& *': {
      fontSize: 11,
    },
  },
}));

export { TableCellHeader, useStyles, useStyles as default };
