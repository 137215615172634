import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import { Edit as EditIcon, Replay as ReplayIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { AlgoliaAutoComplete, searchClient } from './AlgoliaAutoComplete';
import { MedicationsModal } from './MedicationsModal';
import { Button } from '../../common/Button';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';

const useStyles = makeStyles({
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    color: '#000000',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  details: {
    whiteSpace: 'pre-line',
  },
});

interface Props {
  question: string;
  values: any[];
  indexName: string;
  objectKey: string;
  flagName?: string;
  flag?: boolean;
}

export const Multiselect = ({ question, values, indexName, objectKey, flagName, flag }: Props) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState<boolean | undefined>(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMedication, setModalMedication] = useState([]);

  useEffect(() => {
    setSelectedValues(values);
    setIsChecked(flag || !!values.length);
  }, []);

  const onToggleSwitch = () => {
    if (isChecked === false) {
      setSelectedValues([]);
    } else {
      setSelectedValues(values);
    }

    setIsChecked(!isChecked);
  };

  const onToggleEdit = () => {
    if (editMode) {
      setSelectedValues(values);
      setIsChecked(flag || !!values.length);
    }

    setEditMode(!editMode);
  };

  const callback = (vals: any) => {
    setEditMode(false);
    if (objectKey === 'medicationAllergies') {
      // eslint-disable-next-line no-param-reassign
      vals = vals.map((name: any) => ({ name }));
    }

    setSelectedValues(vals);
  };

  const openModal = async () => {
    try {
      const index = searchClient.initIndex(indexName);
      const medicationsIds = selectedValues
        .filter(({ algoliaId, objectID }: any) => algoliaId || objectID)
        .map(({ algoliaId, objectID }: any) => algoliaId || objectID);

      const response: any = await index.getObjects(medicationsIds);
      const allMedication = response.results.concat(
        selectedValues.filter(({ algoliaId, objectID }: any) => !algoliaId && !objectID)
      );

      setModalMedication(allMedication);
      setIsModalOpen(true);
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'fetching',
            element: 'medications',
          }),
          type: 'error',
        },
      });
    }
  };

  const onSave = () => {
    if (objectKey === 'medicationAllergies') {
      const value = selectedValues.map(({ name }: any) => name);
      dispatch({
        type: 'patient/updateMedicalProfileRequest',
        payload: { objectKey, flagName, flag: isChecked, value, callback },
      });
    } else if (!isChecked) {
      dispatch({
        type: 'patient/updateMedicalProfileRequest',
        payload: { objectKey, value: [], callback },
      });
    } else {
      openModal();
    }
  };

  const updateValues = (event: any, newValues: any) => {
    setSelectedValues(newValues);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const dispatchSave = (value: any[]) => {
    dispatch({ type: 'patient/updateMedicalProfileRequest', payload: { objectKey, value, callback } });
    setModalMedication([]);
    setIsModalOpen(false);
  };

  const medicationLabel = () => {
    if (objectKey === 'medicationAllergies') {
      return <span className={classes.label}>{selectedValues.map(({ name }: any) => name).join(', ')}</span>;
    }
    return selectedValues.map(({ name, vehicle, potency, frequency }: any) => (
      <span key={name} className={classes.details}>
        {[
          `${name}${vehicle || potency || frequency ? ': ' : ''}`,
          `${vehicle || ''}`,
          `${potency ? ',' : ''} ${potency || ''}`,
          `${potency || vehicle ? ',' : ''} ${frequency || ''}\n`,
        ].join('')}
      </span>
    ));
  };

  const isButtonDisabled = (): boolean =>
    isChecked ? !selectedValues.length : (flag ?? !!values.length) === isChecked;

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.field}>
          {question}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              label={isChecked ? 'Yes' : 'No'}
              control={<Switch checked={isChecked} onChange={onToggleSwitch} disabled={!editMode} />}
            />
            {!editMode ? <EditIcon onClick={onToggleEdit} /> : <ReplayIcon onClick={onToggleEdit} />}
          </div>
        </div>
        {!editMode && medicationLabel()}
      </Grid>
      <Grid item xs={10} md={10}>
        <Collapse in={editMode && isChecked} timeout="auto">
          <AlgoliaAutoComplete
            label="Search medication..."
            multiple
            indexName={indexName}
            onChange={updateValues}
            value={selectedValues}
          />
        </Collapse>
      </Grid>
      <Grid item xs={2} md={2}>
        <Collapse in={editMode} timeout="auto">
          <Button
            title="Save"
            style={{ minWidth: '100px', color: '#ffffff', backgroundColor: '#12574d', marginRight: '0' }}
            onClick={onSave}
            disabled={isButtonDisabled()}
          />
        </Collapse>
      </Grid>
      <MedicationsModal
        medicationInfo={modalMedication}
        isOpen={isModalOpen}
        closeModal={onModalClose}
        dispatchSave={dispatchSave}
        previousValues={values}
      />
    </>
  );
};
