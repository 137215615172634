import { useMutation, useQuery } from 'react-query';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import { Appointment } from '../../services/Appointment';
import { APPOINTMENT_BOOKING_SLOTS, APPOINTMENT_TYPES, CALENDAR_IDS } from '../../constants/reactQuery.keys';
import { relevantAppointmentTypes } from '../../utils/scheduler.utils';

/* List of all available slots per provider
 * Params: id ()
 * keepPreviousData false to avoid cache issues beetwen tabs
 */
export const useBookingSlot = (data: any) => {
  const response = useQuery([APPOINTMENT_BOOKING_SLOTS, JSON.stringify(data)], () => Appointment.bookingSlots(data), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: !!data.appointmentTypeId,
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'available slots' })),
  });

  return {
    ...response,
    data: response?.data || [],
  };
};

/* List of all appointmentTypes per provider
 * Params: practitionerId ()
 * keepPreviousData false to avoid cache issues beetwen tabs
 */
export const useAppointmentTypes = (practitionerId: string | null | number, hidePrivate: boolean = true) => {
  const response = useQuery([APPOINTMENT_TYPES], () => Appointment.appointmentTypes({ practitionerId }), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: !!practitionerId,
    retry: 0,
    onError: useReactQueryOnErrorHandler,
  });

  return {
    ...response,
    data: response?.data ? relevantAppointmentTypes(response.data, hidePrivate) : [],
  };
};

/* List of all CalendarIds per provider
 * Params: practitionerId ()
 * keepPreviousData false to avoid cache issues between tabs
 */
export const useCalendarIds = () => {
  const response = useQuery([CALENDAR_IDS], () => Appointment.calendarIDs({}), {
    refetchOnWindowFocus: true,
    keepPreviousData: false,
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'calendar IDs' })),
  });

  return {
    ...response,
    data: response?.data || [],
  };
};

/* List of all Location/Calendar
 * keepPreviousData false to avoid cache issues between tabs
 */
export const useLocations = () => {
  const response = useQuery([CALENDAR_IDS], () => Appointment.locations(), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'locations' })),
  });

  return {
    ...response,
    data: response?.data || [],
  };
};

/* List of all appointmentTypes per provider V4 from database
 * Params: practitionerId
 * keepPreviousData false to avoid cache issues between tabs
 */
export const useProviderAppointmentTypes = (practitionerId: string | null | number, calendarId?: number) => {
  const response = useQuery(
    [APPOINTMENT_TYPES, practitionerId, calendarId],
    () => Appointment.providerAppointmentTypes({ practitionerId, calendarId }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: !!practitionerId,
      retry: 0,
      onError: useReactQueryOnErrorHandler,
    }
  );

  return {
    ...response,
    data: response?.data || [],
  };
};

export const useRescheduleMutation = () =>
  useMutation((params: any) => Appointment.reschedule(params), {
    onSuccess: ({ success, message }: { success: boolean; message?: string }) => {
      if (!success) {
        showSnackbar(message || compile('generic.server_error'));
        return;
      }
      showSnackbar(compile('appointment_booking.success'), SNACKBAR_SUCCESS);
    },
    onError: useReactQueryOnErrorHandler,
  });
