export const REQUEST_STATUS_OPTIONS = {
  REQUESTED: 'New Photo Requested',
  APPROVED: 'Photo Approved',
  REJECTED: 'Photo Rejected',
  PHOTO_SUBMITTED: 'Photo Submitted',
} as const;

export type PhotoRequestTypes = typeof REQUEST_STATUS_OPTIONS[keyof typeof REQUEST_STATUS_OPTIONS];

export const photoRequestButtonNote =
  // eslint-disable-next-line max-len
  'Please note that you will not be able to submit a new photo request if one has been submitted within the last 24 hours.';
