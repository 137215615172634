import React, { ChangeEvent, useState } from 'react';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { CardContent, Grid, Badge } from '@material-ui/core/';
import { Delete as DeleteIcon } from '@material-ui/icons';
// eslint-disable-next-line import/no-unresolved
import Slider from 'react-slick';
import { RootState } from 'src/rematch';
import { CUSTOMER_PHOTOS } from '../../constants/reactQuery.keys';
import { useStyles } from './photos.styles';
import { Card, SmallTitle } from '../common/card';
import DeletePhotoDialog from './DeletePhotoDialog';
import { MIN_PATIENT_PHOTOS, PHYSICIAN_ROLE } from '../../constants/general.constants';
import { hasAccessTo } from '../../utils/auth.utils';
import { CREATE_PATIENT_PHOTO, CREATE_PHOTOS_REQUEST, UPDATE_PATIENT_PHOTO } from '../../constants/actions.constants';
import PhotoModal from '../Photo/PhotoModal';
import PhotoUpload from '../Photo/PhotoUpload';
import NewPhotoRequest from './NewPhotoRequest';
import Id from '../../types/Id';

interface Props {
  inputFileId: string;
  title: string;
  photos: any[];
  RightChildren?: React.ElementType;
  BottomChildren?: React.ElementType;
  isUploadingPhoto?: boolean;
  isLoading?: boolean;
  uploadPhotosCallback?: Function;
  deletePhotoCallback?: Function;
  cardStyles?: any;
  cardContentStyle?: any;
  addNewPhotoCallback?: Function;
  showUploadPhotoButton?: boolean;
  itemsPhotos?: number;
  showUnseenPhotosNotification?: boolean;
  onPhotoOpen?: (photoId: number) => void;
  showDeleteButton?: boolean;
  fromHistory?: boolean;
  patientId?: Id;
  patientProfile?: boolean;
  required?: boolean;
  showPhotoRequestButton?: boolean;
}

const Photos = ({
  inputFileId,
  title,
  photos = [],
  RightChildren,
  BottomChildren,
  isUploadingPhoto,
  isLoading,
  uploadPhotosCallback = () => {},
  deletePhotoCallback = () => {},
  cardStyles,
  cardContentStyle,
  addNewPhotoCallback = () => {},
  showUploadPhotoButton = true,
  itemsPhotos,
  showUnseenPhotosNotification = false,
  onPhotoOpen = () => {},
  showDeleteButton = false,
  fromHistory,
  patientId,
  patientProfile,
  required = false,
  showPhotoRequestButton = false,
}: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [image, setImage] = useState<any>({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { permissions, userType } = useSelector(({ auth }: RootState) => auth);
  // eslint-disable-next-line no-param-reassign
  showUploadPhotoButton = showUploadPhotoButton && hasAccessTo(CREATE_PATIENT_PHOTO, permissions);

  const uploadPhotos = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    uploadPhotosCallback(files);
    // TODO: remove invalidate for new service visit version only
    queryClient.invalidateQueries([CUSTOMER_PHOTOS, patientId]);
  };

  const openModal = (id: number, mediumUrl: string, largeUrl: string, linkToServiceVisit: string) => {
    setImage({ mediumUrl, largeUrl, id, linkToServiceVisit });
    setIsOpenModal(true);
    onPhotoOpen(id);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setImage({});
  };

  const addNewPhoto = () => {
    addNewPhotoCallback();
  };

  const settingsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: itemsPhotos,
    slidesToScroll: itemsPhotos,
  };

  const photosSlider = photos.map(
    ({ id, thumbnailUrl, createdAt, mediumUrl, largeUrl, seenByDoctor, canDelete, linkToServiceVisit }: any) => (
      <Grid key={thumbnailUrl} item>
        <div
          className={classes.photoContainer}
          onClick={() => openModal(id, mediumUrl, largeUrl, linkToServiceVisit)}
          aria-hidden="true"
        >
          <Badge color="error" badgeContent="new" invisible={!showUnseenPhotosNotification || seenByDoctor}>
            <img src={thumbnailUrl} alt="thumbnail" />
          </Badge>
          <div className={`date ${itemsPhotos && (itemsPhotos < photos.length ? 'margin-top-date' : '')} `}>
            {moment(createdAt).format('MM/DD/YYYY')}
          </div>
        </div>

        {canDelete && showDeleteButton && hasAccessTo(UPDATE_PATIENT_PHOTO, permissions) && (
          <DeleteIcon
            onClick={() => {
              setImage({ mediumUrl, largeUrl, id, thumbnailUrl });
              setShowDeleteDialog(true);
            }}
            style={{ color: '#ff5252' }}
            className={classes.actionButton}
          />
        )}
      </Grid>
    )
  );

  const renderChildren = () => {
    if (RightChildren && !fromHistory) {
      return <RightChildren />;
    }
    return null;
  };

  const photosSection = (
    <Grid container direction="row" spacing={1}>
      {showUploadPhotoButton && (
        <PhotoUpload
          inputFileId={inputFileId}
          isUploadingPhoto={isUploadingPhoto || isLoading}
          addNewPhoto={addNewPhoto}
          uploadPhotos={uploadPhotos}
          required={required}
        />
      )}
      {itemsPhotos && itemsPhotos < photosSlider.length ? (
        <div
          data-cy="slider"
          className={`${classes.photoSliders} ${showUploadPhotoButton ? classes.photoSlidersSmall : ''}`}
        >
          <Slider // eslint-disable-next-line react/jsx-props-no-spreading
            {...settingsSlider}
            className={`${itemsPhotos >= photosSlider.length ? 'no-padding-slider' : ''} `}
          >
            {photosSlider}
          </Slider>
        </div>
      ) : (
        <>{photosSlider}</>
      )}
    </Grid>
  );

  return (
    <>
      <Card style={cardStyles}>
        <CardContent style={{ ...cardContentStyle, padding: fromHistory ? '10px 0' : '' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SmallTitle title={title} style={{ marginTop: '0', marginBottom: '15px' }} />
            {renderChildren()}
          </div>
          {patientProfile && photos.length < MIN_PATIENT_PHOTOS ? (
            <span
              style={{
                margin: '34px 0',
                display: 'inline-block',
              }}
            >
              No photos added yet
            </span>
          ) : (
            photosSection
          )}
        </CardContent>
        {BottomChildren && <BottomChildren fromHistory={fromHistory} />}
        {showPhotoRequestButton && (userType === PHYSICIAN_ROLE || hasAccessTo(CREATE_PHOTOS_REQUEST, permissions)) ? (
          <section className={classes.buttonContainer}>
            <NewPhotoRequest patientId={patientId as Id} />
          </section>
        ) : null}
      </Card>

      <PhotoModal isOpenModal={isOpenModal} closeModal={closeModal} image={image} />

      <DeletePhotoDialog
        open={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        deletePhotoCallback={deletePhotoCallback}
        image={image}
        setImage={setImage}
        patientId={patientId}
      />
    </>
  );
};

Photos.defaultProps = {
  isLoading: true,
  isUploadingPhoto: false,
  cardContentStyle: {},
  cardStyles: {},
  showUploadPhotoButton: true,
  uploadPhotosCallback: () => null,
  addNewPhotoCallback: () => null,
  RightChildren: null,
  BottomChildren: null,
  itemsPhotos: 0,
};

export default Photos;
