import { withStyles, Typography, IconButton } from '@material-ui/core';

export const Title = withStyles((theme) => ({
  root: {
    fontSize: '15px',
    fontFamily: 'Messina Sans SemiBold',
    color: theme.palette.common.black,
    lineHeight: '29px',
    marginRight: '7px',
    letterSpacing: 0,
  },
}))(Typography);

export const LastEdited = withStyles((theme) => ({
  root: {
    fontSize: '13px',
    fontFamily: 'Messina Sans Regular',
    color: theme.palette.common.black,
    marginTop: '2px',
  },
}))(Typography);

export const Body = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontFamily: 'Messina Sans Regular',
    lineHeight: '20px',
    color: theme.palette.common.black,
  },
}))(Typography);

export const ButtonEditIcon = withStyles((theme) => ({
  root: {
    height: '47px',
    width: '47px',
    borderRadius: '3px',
    backgroundColor: '#e7eeed',
    color: theme.palette.common.black,
  },
}))(IconButton);

export const ButtonCancel = { color: '#000', marginRight: '12px' };
export const ButtonSaveNote = {
  backgroundColor: '#e7eeed',
  color: '#000',
  marginTop: '20px',
  '&:focus, &:visited': {
    backgroundColor: '#12574D',
    color: '#fff',
  },
};

export const TextAreaStyles = {
  width: '100%',
  height: '88px',
  borderRadius: '3px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: '#fff',
  padding: '13px 14px',
  fontFamily: 'Messina Sans Regular',
  fontSize: '14px',
};
