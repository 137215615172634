import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { IUserGroup } from 'src/interfaces/IUserGroup';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { IPhysician } from 'src/interfaces/IPhysician';
import MedspaDetails from './MedspaDetails';
import AddOnSection from './MedspaDetails/AddOnSection';
import MedicalDirectorSection from './MedspaDetails/MedicalDirectorSection';

const useStyles = makeStyles(() => ({
  medspaSettingsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
}));

interface MedspaAdminMedspaSettingsProps {
  userGroup: IUserGroup;
  medicalDirector: IPhysician;
  refetchUserGroup: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IUserGroup | null, unknown>>;
  onboardingCompleted?: boolean;
}

const MedspaAdminMedspaSettings = (props: MedspaAdminMedspaSettingsProps) => {
  const classes = useStyles();
  const { userGroup, refetchUserGroup, medicalDirector, onboardingCompleted = false } = props;

  return (
    <Box className={classes.medspaSettingsContainer}>
      <MedspaDetails userGroup={userGroup} onboardingCompleted={onboardingCompleted} />

      <MedicalDirectorSection
        medicalDirector={medicalDirector}
        userGroup={userGroup}
        onboardingCompleted={onboardingCompleted}
      />

      <AddOnSection userGroup={userGroup} refetchUserGroup={refetchUserGroup} />
      <Box height="1rem" />
    </Box>
  );
};

export default MedspaAdminMedspaSettings;
