import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import SelectAndChargeCard from '../../../components/PatientProfile/CreditCards/SelectAndChargeCard';
import CreditCardForm from '../../PatientCreditCards/components/CreditCardForm';
import { useStyles } from './SelectOrAddCardForMembership.styles';
import { ISquareCard } from '../../../interfaces/ISquareCard.interfaces';

export const SelectOrAddCardForMembership = ({
  selectedCardId,
  setSelectedCardId,
  onSave,
  loading,
  patientCards,
  squareLocationId,
  patientId,
  onSuccessCardAdd,
  buttonText,
  showChargeMessage = true,
}: {
  selectedCardId: string | undefined;
  setSelectedCardId: (cardId: string) => void;
  onSave: (data?: any) => void;
  loading: boolean;
  patientCards: ISquareCard[];
  squareLocationId: string;
  patientId: string;
  onSuccessCardAdd: (data: any) => void;
  buttonText: string;
  showChargeMessage?: boolean;
}) => {
  const classes = useStyles();
  const [seed, setSeed] = useState(1);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const StartMembershipTabs = () => (
    <div className={classes.tabsContainer}>
      <Button
        className={`${classes.mr10} ${tabIndex === 0 ? classes.activeTab : classes.inactiveTab}`}
        onClick={() => {
          if (!loading) {
            setTabIndex(0);
          }
        }}
      >
        Saved Payment Options
      </Button>
      <Button
        className={tabIndex === 1 ? classes.activeTab : classes.inactiveTab}
        onClick={() => {
          if (!loading) {
            setTabIndex(1);
          }
        }}
      >
        New Card
      </Button>
    </div>
  );

  const handleAddCardSuccess = async (cardId: string, callBack: () => void) => {
    await onSuccessCardAdd({ cardId });
    callBack();
    // @ts-ignore
    setSeed(crypto.randomUUID());
  };

  return (
    <>
      <StartMembershipTabs />
      {tabIndex === 0 ? (
        <SelectAndChargeCard
          selectedCardId={selectedCardId}
          onCardSelect={(e) => {
            setSelectedCardId(e.target.value);
          }}
          buttonText={buttonText}
          patientCards={patientCards}
          loading={loading}
          onCharge={onSave}
          showChargeMessage={showChargeMessage}
        />
      ) : (
        <div>
          <CreditCardForm
            key={seed}
            locationId={squareLocationId}
            patientId={patientId}
            onCardAdded={handleAddCardSuccess}
            buttonText={buttonText}
            loading={loading}
          />
        </div>
      )}
    </>
  );
};
