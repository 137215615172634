import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import moment from 'moment';
import { capitalize, snakeCase } from 'lodash';
import { useSelector } from 'react-redux';
import Page from 'src/components/common/Page';
import { useClearanceHistory, useClearanceHistoryStatistics } from '../../hooks/queries/useClearanceHistory';
import { MedicalProfileUpdated, NewMessages, PhotoRequested, UnseenPhotos } from './ProviderPatientsList';
import formatDate from '../../utils/formatDate';
import compile from '../../utils/toastMessagesCompiler';
import { PortraitTable } from '../../components/common/TableNew/Table';
import { useStyles } from './PhysicianPatientsList.styles';
import { SelectCustom } from '../../components/DashboardAdministrator/Inventory/supplyReceipt.styles';
import { usePractitionerForPhysicians } from '../../hooks/practitioner/usePractitioner';
import { ClearanceHistoryCustomer } from '../../interfaces/IClearanceHistory';
import ChartBar from '../../components/common/Chart/ChartBar';
import { longMonths, months as shortenMonths } from '../../constants/general.constants';
import { MultipleSkeleton } from '../../components/common/LoadingSkeleton';
import { PHYSICIAN_NEW_PATIENTS_PATH } from '../../routes/physicianRoutes';
import { usePhysicianNavigate } from '../../hooks/customer/usePhysicianNavigate';
import { dispatch } from '../../rematch';

const CLEARED_COLUMNS = [
  { id: 'patient_name', title: 'Name' },
  { id: 'practitioner_name', title: 'Provider' },
  { id: 'id', title: 'MRN' },
  { id: 'customer_views.next_appointment', title: 'Appt. Date', sort: true },
  { id: 'updates', title: 'Updates' },
  { id: 'customer_views.visits', title: 'Visits', sort: true },
  { id: 'request_type', title: 'Type' },
  { id: 'clearance_histories.created_at', title: 'Cleared in', sort: true },
];

const ClearedPatientRow = ({ patient }: { patient: ClearanceHistoryCustomer }) => {
  const classes = useStyles();
  const navigateToPatient = usePhysicianNavigate(patient.id);

  return (
    <TableRow onClick={navigateToPatient} className={classes.patientRow} key={patient.id}>
      <TableCell>{patient.customerName}</TableCell>
      <TableCell>{patient.practitionerName}</TableCell>
      <TableCell>{patient.customerId}</TableCell>
      <TableCell>{patient.nextAppointment}</TableCell>
      <TableCell>
        {patient.unreadMessagesCount > 0 && <NewMessages count={patient.unreadMessagesCount} />}
        {patient.unseenPhotosCount > 0 && <UnseenPhotos count={patient.unseenPhotosCount} />}
        {patient.needsDoctorReview && <MedicalProfileUpdated />}
        {patient.hasRequestedPhotos && <PhotoRequested />}
      </TableCell>
      <TableCell>{patient.visitsCount}</TableCell>
      <TableCell>{capitalize(patient.requestType)}</TableCell>
      <TableCell>{formatDate(patient.createdAt)}</TableCell>
    </TableRow>
  );
};

const initialFilters = {
  search: '',
  sortBy: '',
  sortDirection: '',
  practitionerId: '',
  startDate: '',
  endDate: '',
};

export const PhysicianGFEReport = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector(({ auth }: any) => auth);
  const [search, setSearch] = useState<string>('');
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [providerId, setProviderId] = useState<string | number>('');
  const [filters, setFilters] = useState<any>(initialFilters);
  const [timeFrame, setTimeFrame] = useState<string>('');
  const { results, isLoading, isError, fetchNextPage, hasNextPage, isFetching } = useClearanceHistory(filters);
  const { data: practitioners = [] } = usePractitionerForPhysicians({});
  const patients = results?.pages.map(({ clearances = [] }) => clearances).flat() || [];

  const { results: oldestRecords } = useClearanceHistory({
    sortBy: 'clearance_histories.created_at',
    sortDirection: 'asc',
  });

  const currentYear = new Date().getFullYear().toString();
  const currentMonth = (new Date().getMonth() + 1).toString();

  const [exportMonth, setExportMonth] = useState<string>(currentMonth);
  const [exportYear, setExportYear] = useState<string>(currentYear);
  const [exportProviderId, setExportProviderId] = useState<string | number>('');
  const [downloadingExport, setDownloadingExport] = useState<boolean>(false);

  const [year, setYear] = useState<string>(currentYear);
  const { data: yearData, isLoading: isLoadingYearData } = useClearanceHistoryStatistics(+year);

  const data = yearData ? Object.values(yearData) : Array(12).fill(0);
  const totalYear = data.reduce((partialSum, cleared) => partialSum + cleared, 0);
  const currentMonthTotal = yearData ? yearData[new Date().getMonth() + 1] : 0;

  const oldestRecord = (oldestRecords?.pages.map(({ clearances = [] }) => clearances).flat() || [])?.[0];

  useEffect(() => {
    if (yearData) {
      let latestMonthWithClearedGfes = 12;
      while (latestMonthWithClearedGfes > 0 && yearData[latestMonthWithClearedGfes] === 0) {
        latestMonthWithClearedGfes -= 1;
      }
      if (latestMonthWithClearedGfes) {
        setExportMonth(latestMonthWithClearedGfes.toString());
      }
    }
    setExportYear(year);
  }, [year, yearData]);

  const handleExportDownload = async () => {
    setDownloadingExport(true);
    const exportUrl =
      `${process.env.REACT_APP_API_RAILS_URL}/v3/clearance_histories/export_csv?month=` +
      `${exportMonth}&year=${exportYear}&practitioner_id=${exportProviderId}`;
    try {
      // @ts-ignore
      const response = await fetch(exportUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/csv',
          'access-token': localStorage.getItem('identity.token.access-token'),
          'token-type': localStorage.getItem('identity.token.token-type'),
          client: localStorage.getItem('identity.token.client'),
          uid: sessionStorage.getItem('identity.token.uid') || localStorage.getItem('identity.token.uid'),
          expiry: localStorage.getItem('identity.token.expiry'),
        },
      });

      const blob = await response.clone().blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      let exportProvider;
      if (exportProviderId) {
        exportProvider = practitioners.find((provider) => provider.id === exportProviderId);
      }
      a.href = url;
      a.download =
        `cleared_patients${exportProvider ? `_for_${snakeCase(exportProvider.fullName)}` : ''}` +
        `_in_${exportMonth}_${exportYear}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'generating',
            element: 'report',
          }),
          type: 'error',
        },
      });
    } finally {
      setDownloadingExport(false);
    }
  };

  const availableMonths = useMemo(() => {
    const currentDate = moment().startOf('month');
    if (oldestRecords) {
      const months = [];
      let oldestDate = moment(oldestRecord.createdAt).startOf('month');
      while (oldestDate <= currentDate) {
        months.push(oldestDate.format('MMM YYYY'));
        oldestDate = oldestDate.add(1, 'M');
      }
      return months.reverse();
    }
    return [currentDate.format('MMM YYYY')];
  }, [oldestRecord]);

  const availableYears = useMemo(() => {
    const currentDate = moment().startOf('year');
    if (oldestRecords) {
      const years = [];
      let oldestDate = moment(oldestRecord.createdAt).startOf('year');
      while (oldestDate <= currentDate) {
        years.push(oldestDate.format('YYYY'));
        oldestDate = oldestDate.add(1, 'y');
      }
      return years.reverse();
    }
    return [currentDate.format('YYYY')];
  }, [oldestRecord]);

  useEffect(() => {
    let startDate = '';
    let endDate = '';
    if (timeFrame) {
      startDate = moment(timeFrame, 'MMM YYYY').startOf('month').toISOString();
      endDate = moment(timeFrame, 'MMM YYYY').endOf('month').toISOString();
    }
    setFilters({ ...filters, ...{ search, practitionerId: providerId, startDate, endDate } });
  }, [search, providerId, timeFrame]);

  const doSortBy = (column: string, direction: string) => {
    setFilters({ ...filters, ...{ sortBy: column, sortDirection: direction } });
  };

  const ChartHeader = () => (
    <div className={classnames(classes.flexRow, classes.chartHeader)}>
      <div className={classes.flexRow}>
        <div className={classes.mr3}>
          <span className={classes.chartTotal}>{totalYear}</span>{' '}
          <span className={classes.chartSubText}>Patients Cleared in {year}</span>
        </div>
        {year === currentYear && (
          <>
            <span className={classes.chartTotal}>{currentMonthTotal}</span>{' '}
            <span className={classes.chartSubText}>Patients Cleared this month</span>
          </>
        )}
      </div>
      <div className={classes.flexRow}>
        <FormControl size="small" className={classes.formControl} variant="outlined">
          <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>Year</InputLabel>
          <SelectCustom
            data-cy="from"
            name="state"
            value={year}
            onChange={(e) => setYear(e.target.value as string)}
            label="Clear in"
          >
            {availableYears.map((yearOption: string) => (
              <MenuItem key={yearOption} value={yearOption}>
                {yearOption}
              </MenuItem>
            ))}
          </SelectCustom>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          className={classes.exportButton}
          onClick={() => setOpenExportModal(true)}
        >
          Export
        </Button>
      </div>
    </div>
  );

  if (!user && user.isAdvancedPractitioner) {
    history.replace(PHYSICIAN_NEW_PATIENTS_PATH);
  }

  return (
    <Page title="GFE Report">
      <div className={classes.physicianPage}>
        {isLoadingYearData ? (
          <MultipleSkeleton addPosition={false} />
        ) : (
          <Grid style={{ padding: '1rem' }}>
            <ChartBar
              header={<ChartHeader />}
              chartColor="#12574D"
              xAxis={shortenMonths}
              data={[
                {
                  name: 'GFE Cleared',
                  data,
                },
              ]}
              isLoading={isLoadingYearData}
              titleChart=""
              xLegend=""
              yLegend=""
              className={classes.gfeChart}
            />
          </Grid>
        )}
        <h3 className={classes.heading}>Cleared Patients</h3>
        <div className={`${classes.flexRow} ${classes.filterContainer}`}>
          <FormControl size="small" variant="outlined">
            <InputLabel htmlFor="search" color="secondary" className={classes.search}>
              Search
            </InputLabel>
            <OutlinedInput
              data-cy="providerSearchInput"
              id="search"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton style={{ padding: 0 }}>
                    {isFetching ? (
                      <CircularProgress style={{ color: 'black' }} size={20} />
                    ) : (
                      <SearchIcon style={{ color: 'black' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          <div className={classes.flexRow}>
            <FormControl size="small" variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="licenseSearch" color="secondary" className={classes.search}>
                Provider
              </InputLabel>
              <SelectCustom
                data-cy="from"
                name="provider"
                value={providerId}
                onChange={(e) => setProviderId(e.target.value as string)}
                label="Provider"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {practitioners.map((provider) => (
                  <MenuItem value={provider.id} key={provider.id}>
                    {provider.fullName}
                  </MenuItem>
                ))}
              </SelectCustom>
            </FormControl>
            <FormControl size="small" className={classes.formControl} variant="outlined">
              <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>Clear in</InputLabel>
              <SelectCustom
                data-cy="from"
                name="state"
                value={timeFrame}
                onChange={(e) => setTimeFrame(e.target.value as string)}
                label="Clear in"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {availableMonths.map((monthOption: string) => (
                  <MenuItem key={monthOption} value={monthOption}>
                    {monthOption}
                  </MenuItem>
                ))}
              </SelectCustom>
            </FormControl>
          </div>
        </div>
        <PortraitTable
          columns={CLEARED_COLUMNS}
          sortBy={filters.sortBy}
          sortDirection={filters.sortDirection}
          onSortChange={doSortBy}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          data={patients}
          rowComponent={(row) => <ClearedPatientRow patient={row} />}
          errorMessage={compile('generic.error_message', { action: 'fetching', element: 'cleared patients' })}
          infiScroll
          isLoading={isLoading}
          isFetching={isFetching}
          isError={isError}
          tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
        />
        <Modal open={openExportModal} onClose={() => setOpenExportModal(false)} className={classes.modal}>
          <Box className={classes.modalContent}>
            <Box className={classes.modalHeader}>
              <h2>Export</h2>
              <Box className={classes.closeButton} onClick={() => setOpenExportModal(false)}>
                <CloseIcon width={12} height={12} />
              </Box>
            </Box>
            <hr style={{ borderTop: '1px solid #CFCFCF' }} />
            <div className={classes.exportDateSelection}>
              <FormControl size="small" variant="outlined" className={classes.modalFormControl}>
                <InputLabel htmlFor="exportMonth" color="secondary" className={classes.search}>
                  Month
                </InputLabel>
                <SelectCustom
                  data-cy="from"
                  name="exportMonth"
                  value={exportMonth}
                  onChange={(e) => setExportMonth(e.target.value as string)}
                  label="Month"
                >
                  {longMonths.map((month, index) => (
                    <MenuItem value={index + 1} key={month}>
                      {month}
                    </MenuItem>
                  ))}
                </SelectCustom>
              </FormControl>
              <FormControl size="small" variant="outlined" className={classes.modalFormControl}>
                <InputLabel htmlFor="exportYear" color="secondary" className={classes.search}>
                  Year
                </InputLabel>
                <SelectCustom
                  data-cy="from"
                  name="exportYear"
                  value={exportYear}
                  onChange={(e) => setExportYear(e.target.value as string)}
                  label="Provider"
                >
                  {availableYears.map((yearOption) => (
                    <MenuItem value={yearOption} key={yearOption}>
                      {yearOption}
                    </MenuItem>
                  ))}
                </SelectCustom>
              </FormControl>
            </div>
            <div className={classes.modelProviderSelection}>
              <FormControl size="small" variant="outlined" className={classes.modalFormProviderControl}>
                <InputLabel htmlFor="exportProvider" color="secondary" className={classes.search}>
                  Provider (Optional)
                </InputLabel>
                <SelectCustom
                  fullWidth
                  data-cy="from"
                  name="exportProvider"
                  value={exportProviderId}
                  onChange={(e) => setExportProviderId(e.target.value as string)}
                  label="Select a Provider"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {practitioners.map((provider) => (
                    <MenuItem value={provider.id} key={provider.id}>
                      {provider.fullName}
                    </MenuItem>
                  ))}
                </SelectCustom>
              </FormControl>
            </div>
            <div className={classes.exportButtonContainer}>
              <button
                type="button"
                disabled={downloadingExport}
                className={classes.exportButtonDownload}
                onClick={handleExportDownload}
              >
                {downloadingExport ? (
                  <span className={classes.downloading}>
                    <span className={classes.mr1}>Generating Report</span>
                    <CircularProgress size={20} color="inherit" />
                  </span>
                ) : (
                  'Download GFE Report'
                )}
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </Page>
  );
};
