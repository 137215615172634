/* eslint-disable react/no-danger */

import React from 'react';
import { Box, Typography } from '@material-ui/core';

// eslint-disable-next-line import/no-unresolved
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { hasAccessTo } from 'src/utils/auth.utils';
import { CUSTOM_SERVICES } from 'src/constants/actions.constants';
import { useSelector } from 'react-redux';
import CustomCKEditor from 'src/components/common/Custom/CustomCKEditor';
import { ICareMessage } from '../../../interfaces/ICustomCareMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    mandatoryLabel: {
      color: theme.palette.text.disabled,
    },
    mandatoryDiv: {
      padding: '1px 12px',
      color: theme.palette.text.disabled,
    },
    ckEditor: {
      width: '100%',
      marginTop: '0px',
    },
  })
);

type Props = {
  readOnly: boolean | undefined;
  data: ICareMessage | undefined;
  isFromMasterLibrary?: boolean;
  onChange: any;
};
const MessageForm = ({ readOnly = false, data, isFromMasterLibrary, onChange }: Props) => {
  const classes = useStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const { introductoryMessage, mandatoryMessage, endingMessage, practitionerId } = data || {};

  return (
    <div className={classes.root}>
      <Box>
        <Typography>Your introductory message (Optional):</Typography>
        <CustomCKEditor
          height="150px"
          onChange={(value) => onChange({ introductoryMessage: value })}
          value={introductoryMessage || ''}
          disabled={readOnly}
          className={classes.ckEditor}
          placeholder={
            // eslint-disable-next-line max-len
            `I'm excited to treat you at your upcoming appointment.\n\nMy suite can be hard to find, it is next to the Starbucks.`
          }
        />
      </Box>

      {hasAccessTo(CUSTOM_SERVICES, permissions) && (!isFromMasterLibrary || !readOnly) ? (
        <Box>
          <Typography>Care service instruction:</Typography>
          <CustomCKEditor
            height="200px"
            onChange={(value) => onChange({ mandatoryMessage: value })}
            value={mandatoryMessage || ''}
            disabled={readOnly}
            className={classes.ckEditor}
            placeholder="Message Content."
          />
        </Box>
      ) : (
        <details open>
          <summary className={classes.mandatoryLabel}>
            {hasAccessTo(CUSTOM_SERVICES, permissions)
              ? 'Care service instruction'
              : 'Physician service group mandatory message will be inserted here'}
          </summary>
          <CustomCKEditor
            height={hasAccessTo(CUSTOM_SERVICES, permissions) ? '200px' : '100px'}
            onChange={(value) => onChange({ mandatoryMessage: value })}
            value={
              (practitionerId && hasAccessTo(CUSTOM_SERVICES, permissions)) || !practitionerId
                ? mandatoryMessage || ''
                : '<i>Service group mandatory message will be inserted here...</i>'
            }
            disabled={readOnly || !hasAccessTo(CUSTOM_SERVICES, permissions)}
            className={classes.ckEditor}
            placeholder="Message Content."
          />
        </details>
      )}

      <Box>
        <Typography>Your end message (Optional):</Typography>
        <CustomCKEditor
          height="150px"
          onChange={(value) => onChange({ endingMessage: value })}
          value={endingMessage || ''}
          disabled={readOnly}
          className={classes.ckEditor}
          placeholder={`Thank you,\nMaria RN`}
        />
      </Box>
    </div>
  );
};

export default MessageForm;
