import React from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useStyles, RemoveButton, CancelButton, ConfirmButton } from './futureCreditModal.styles';

interface Props {
  open: boolean;
  data: ModalData;
  showRemoveButton: boolean;
  handleClose: () => void;
  handleConfirm: (units: number) => void;
  removeCredit: () => void;
}

export interface ModalData {
  id: number;
  serviceName: string;
  units: number;
  serviceId?: number;
  variantItemId?: number;
  maxUnits: number;
  balance: number;
}

interface FormData {
  units: string;
}

export const FutureCreditModal = (props: Props) => {
  const { open, data, showRemoveButton, handleClose, handleConfirm, removeCredit } = props;
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm<FormData>();

  const onSubmit = (formData: FormData): void => {
    handleConfirm(+formData.units);
    handleClose();
  };

  const handleRemoveCredit = () => {
    removeCredit();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: '9px', width: '391px' } }}>
      <DialogTitle style={{ textAlign: 'right', paddingBottom: 0 }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <div style={{ marginLeft: '36px' }}>
            <div>
              <span className={classes.title}>Service: </span>
              <span className={classes.titleData}>{data.serviceName}</span>
            </div>
            <div>
              <span className={classes.title}>Credit: </span>
              <span className={classes.titleData}>{data.balance} Units</span>
            </div>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <p className={classes.text}>How much credit (units) do you want to apply to this service?</p>
          </div>
          <form className={classes.form}>
            <input
              className={classnames(classes.input, { [classes.inputError]: !!errors.units })}
              type="number"
              name="units"
              defaultValue={Math.min(data.maxUnits, data.balance)}
              ref={register({ required: true, min: data.serviceId ? 1 : data.maxUnits, max: data.maxUnits })}
            />
          </form>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-around', paddingBottom: '14px' }}>
        {showRemoveButton && <RemoveButton onClick={handleRemoveCredit}>Remove credit</RemoveButton>}
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <ConfirmButton onClick={handleSubmit(onSubmit)}>Confirm</ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
