/* eslint-disable react/jsx-props-no-spreading */
import Dialog from '@material-ui/core/Dialog';
import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { Close } from '@material-ui/icons';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import classes from './serviceModal.module.css';
import Support from '../../../services/Support';
import IServices from '../../../interfaces/IServices';
import { dispatch } from '../../../rematch';

interface ServicesModalsProps {
  isAccountCredits: boolean;
  isOpenModal: boolean;
  isEditing: boolean;
  onClose: () => void;
  service: FormData;
  setService: Dispatch<SetStateAction<FormData>>;
  services: IServices[];
}

export interface FormData {
  serviceId?: number;
  quantity: number;
}

const ServiceModal = ({
  isOpenModal,
  isEditing,
  isAccountCredits,
  onClose,
  service,
  setService,
  services,
}: ServicesModalsProps) => {
  const { patientId } = useParams<{ patientId: string }>();
  const { mutate: updateAccountCredits, isLoading: isUpdatingAccountCredits } = useMutation({
    mutationFn: () => Support.updateCustomerAccountCredits({ patientId: +patientId, accountCredits: service.quantity }),
    onSuccess: () => {
      dispatch.patient.fetchPatientData(+patientId);
      toast.success('Account credits updated.');
      onClose();
    },
    onError: () => {
      toast.error('Error updating account credits');
    },
  });

  const { mutate: createOrUpdateService, isLoading: isCreatingOrUpdatingService } = useMutation({
    mutationFn: () =>
      Support.createOrUpdateServiceCredit({
        patientId: +patientId,
        serviceId: service.serviceId,
        quantity: service.quantity,
      }),
    onSuccess: () => {
      dispatch.serviceCredits.fetchCredits({ customerId: +patientId });
      toast.success(`${isEditing ? 'Service Credit updated.' : 'Service Credit created.'}`);
      onClose();
    },
    onError: () => {
      toast.error(`${isEditing ? 'Error updating service credit.' : 'Error creating service credit'}`);
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isAccountCredits) {
      updateAccountCredits();
    } else if (service.serviceId) {
      createOrUpdateService();
    } else {
      toast.error('Select a type');
    }
  };

  const isLoading = isUpdatingAccountCredits || isCreatingOrUpdatingService;
  let buttonText = isEditing ? 'Save' : 'Add';
  buttonText = isLoading ? 'Loading...' : buttonText;

  return (
    <>
      <Dialog open={isOpenModal}>
        <div className={classes.container}>
          <div className={classes.modalHeader}>
            <h2 className={classes.h2}>{isEditing ? 'Editing Service Credit' : 'Add new Service Credit'}</h2>
            <button className={classes.closeModalButton} type="button" onClick={onClose}>
              <Close />
            </button>
          </div>
          <form className={classes.form} onSubmit={handleSubmit}>
            <label htmlFor="serviceName">
              Service
              <select
                disabled={isEditing}
                value={service.serviceId}
                className={classes.select}
                onChange={(e) => {
                  const { value } = e.target;
                  const serviceId = value !== 'undefined' ? +value : undefined;
                  setService({ ...service, serviceId });
                }}
              >
                <option value={undefined}>Select type</option>
                {services.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="quantity">
              {isAccountCredits ? 'Amount' : 'Quantity'}
              <input
                className={classes.input}
                type="number"
                value={(+service.quantity).toString()}
                name="quantity"
                onChange={(e) => {
                  setService({ ...service, quantity: +e.target.value });
                }}
              />
            </label>
            <input disabled={isLoading} className={classes.input} type="submit" value={buttonText} />
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default ServiceModal;
