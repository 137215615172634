import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: '#ffffff',
    alignSelf: 'center',
    padding: '4px',
    flex: 1,
    margin: 'auto',
  },
  textArea: {
    backgroundColor: '#fff',
    fontFamily: 'Messina Sans Regular',
    '&:focus': {
      border: '2px solid #12574d',
      outline: 'none !important',
    },
  },
}));
