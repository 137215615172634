import { template } from 'lodash';

import messages from '../constants/toast.messages.templates';

export const MAX_SNACKS_MESSAGES = 4;
export const DEFAULT_POSITION = { vertical: 'top', horizontal: 'center' };
export const DEFAULT_DURATION = 4000;

export default (templateKey: string, variables: any = {}) => {
  const templateString = messages[templateKey];
  try {
    const compiled = template(templateString);
    return compiled(variables);
  } catch (error) {
    return templateString;
  }
};
