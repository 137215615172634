import React from 'react';
import Page from 'src/components/common/Page';
import InsightsTabMedspa from './Tabs/InsightsTabMedspa';

const MedspaAdminMarketing = () => (
  <Page title="Marketing">
    <InsightsTabMedspa />
  </Page>
);

export default MedspaAdminMarketing;
