import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import MedspaAdminSettings from 'src/pages/MedspaAdminSettings';
import MedspaAdminEarnings from 'src/components/DashboardMedspaAdmin/Earnings';
import MedspaAdminInventory from 'src/components/DashboardMedspaAdmin/Inventory';
import MedspaAdminLocations from 'src/components/DashboardMedspaAdmin/Locations';
import MedspaAdminMarketing from 'src/components/DashboardMedspaAdmin/Marketing';
import MedspaAdminPatients from 'src/components/DashboardMedspaAdmin/Patients';
import MedspaAdminPractitioners from 'src/components/DashboardMedspaAdmin/Practitioners';
import MedspaAdminPricing from 'src/components/DashboardMedspaAdmin/Pricing';
import MedspaAdminToDoList from 'src/components/DashboardMedspaAdmin/TodoList';
import OrderingTab from 'src/components/DashboardPractitioner/Tabs/OrderingTab';
import Wrapper from 'src/components/common/Wrapper';
import { FEATURE_ENABLE_CHARTING } from 'src/constants/features.constants';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import { useMedspaRole } from 'src/hooks/queries/useMedspaAdmins';
import NewPrivateRoute, { NewPrivateRouteWithoutRolesProps } from 'src/utils/NewPrivateRoute';
import DocumentsManagement from 'src/components/DashboardMedspaAdmin/DocumentsManagement';
import CustomizeText from 'src/components/DashboardMedspaAdmin/DocumentsManagement/Components/CustomizeText';
// eslint-disable-next-line max-len
import { MedspaCalendarConfigurationV2 } from 'src/components/DashboardMedspaAdmin/Calendar/MedspaCalendarConfigurationV2';
// eslint-disable-next-line max-len
import CustomizeCareMessages from 'src/components/DashboardMedspaAdmin/DocumentsManagement/Components/CustomizeCareMessages';
import { CUSTOM_SERVICES, READ_CUSTOMER, USE_EHR } from 'src/constants/actions.constants';
import MedspaAdminServicesManagement from 'src/components/DashboardMedspaAdmin/Services';
import { MedspaAppointmentTypes } from '../components/DashboardMedspaAdmin/Calendar/MedspaAppointmentTypes';
import { MedspaAdminCalendarV2 } from '../components/DashboardMedspaAdmin/Calendar/indexV2';
import MedspaAdminOnboardingRoutes, { ONBOARDING_BASE_PATH } from './medspaOnboardingRoutes';

export const MEDSPA_ADMIN_BASE_PATH = '/medspa-admin';
export const MEDSPA_ADMIN_CALENDAR = `${MEDSPA_ADMIN_BASE_PATH}/calendar`;
export const MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG = `${MEDSPA_ADMIN_BASE_PATH}/appointment-types`;
export const MEDSPA_ADMIN_CALENDAR_CONFIGURATION = `${MEDSPA_ADMIN_BASE_PATH}/provider-configuration`;
export const MEDSPA_ADMIN_PROVIDERS = `${MEDSPA_ADMIN_BASE_PATH}/practitioners`;
export const MEDSPA_ADMIN_EARNINGS = `${MEDSPA_ADMIN_BASE_PATH}/earning`;
export const MEDSPA_ADMIN_PATIENTS = `${MEDSPA_ADMIN_BASE_PATH}/patients`;
export const MEDSPA_ADMIN_INVENTORY = `${MEDSPA_ADMIN_BASE_PATH}/inventory`;
export const MEDSPA_ADMIN_NEW_ORDER = `${MEDSPA_ADMIN_BASE_PATH}/order`;
export const MEDSPA_ADMIN_MARKETING = `${MEDSPA_ADMIN_BASE_PATH}/marketing`;
export const MEDSPA_ADMIN_PRICING = `${MEDSPA_ADMIN_BASE_PATH}/pricing`;
export const MEDSPA_ADMIN_TODO_LIST = `${MEDSPA_ADMIN_BASE_PATH}/todo-list`;
export const MEDSPA_ADMIN_PRACTITIONERS = `${MEDSPA_ADMIN_BASE_PATH}/practitioners`;
export const MEDSPA_ADMIN_LOCATIONS = `${MEDSPA_ADMIN_BASE_PATH}/locations`;
export const MEDSPA_ADMIN_SETTINGS = `${MEDSPA_ADMIN_BASE_PATH}/settings`;
export const MEDSPA_DOCUMENTS_MANAGEMENT = `${MEDSPA_ADMIN_BASE_PATH}/documents-management`;
export const MEDSPA_MESSAGING_CUSTOMIZE_PATH = `${MEDSPA_ADMIN_BASE_PATH}/messaging/customize`;
export const MEDSPA_CARE_MESSAGES_CUSTOMIZE_PATH = `${MEDSPA_ADMIN_BASE_PATH}/care-messages/customize`;
export const MEDSPA_ADMIN_SERVICES_MANAGEMENT = `${MEDSPA_ADMIN_BASE_PATH}/services-management`;
export const MEDSPA_ADMIN_SERVICES_CUSTOMIZE = `${MEDSPA_ADMIN_SERVICES_MANAGEMENT}/customize`;
export const MEDSPA_ADMIN_SERVICES_CUSTOMIZE_MESSAGING = `${MEDSPA_ADMIN_SERVICES_MANAGEMENT}/messaging/customize`;

export const MEDSPA_ADMIN_URLS = {
  Earnings: MEDSPA_ADMIN_EARNINGS,
  Patients: MEDSPA_ADMIN_PATIENTS,
  ServicesManagement: MEDSPA_ADMIN_SERVICES_MANAGEMENT,
  Inventory: MEDSPA_ADMIN_INVENTORY,
  TodoList: MEDSPA_ADMIN_TODO_LIST,
  Practitioners: MEDSPA_ADMIN_PRACTITIONERS,
  Calendar: MEDSPA_ADMIN_CALENDAR,
  Locations: MEDSPA_ADMIN_LOCATIONS,
  CalendarConfiguration: MEDSPA_ADMIN_CALENDAR_CONFIGURATION,
  AppointmentTypesConfig: MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG,
  Marketing: MEDSPA_ADMIN_MARKETING,
  Pricing: MEDSPA_ADMIN_PRICING,
} as const;

const MedspaAdminPrivateRoute = (props: NewPrivateRouteWithoutRolesProps) => (
  <NewPrivateRoute {...props} roles={MEDSPA_ADMIN_ROLE} />
);

const MedspaAdminRoutes: React.FC = () => {
  const scrollMainRef = useRef(null);
  const { featureList } = useSelector(({ auth }: any) => auth);
  const hasOptedInToDoList = featureList?.some((feature: any) => feature.key === FEATURE_ENABLE_CHARTING);
  const { data: medspaRoleName } = useMedspaRole();
  const isLegacy = medspaRoleName === PORTRAIT_LEGACY || medspaRoleName === PORTRAIT_LEGACY_PLUS;

  return (
    <Wrapper scrollMainRef={scrollMainRef}>
      <Switch>
        <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_CALENDAR} permission={USE_EHR} component={MedspaAdminCalendarV2} />
        <MedspaAdminPrivateRoute
          path={MEDSPA_ADMIN_CALENDAR_CONFIGURATION}
          permission={USE_EHR}
          component={MedspaCalendarConfigurationV2}
        />
        <MedspaAdminPrivateRoute
          path={MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG}
          permission={USE_EHR}
          component={MedspaAppointmentTypes}
        />
        <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_EARNINGS} permission={USE_EHR} component={MedspaAdminEarnings} />
        <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_PATIENTS} permission={USE_EHR} component={MedspaAdminPatients} />
        <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_INVENTORY} permission={USE_EHR} component={MedspaAdminInventory} />
        <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_NEW_ORDER} permission={USE_EHR} component={OrderingTab} />
        <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_MARKETING} permission={USE_EHR} component={MedspaAdminMarketing} />
        <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_PRICING} permission={USE_EHR} component={MedspaAdminPricing} />
        {hasOptedInToDoList && (
          <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_TODO_LIST} permission={USE_EHR} component={MedspaAdminToDoList} />
        )}
        {!isLegacy && (
          <MedspaAdminPrivateRoute
            path={MEDSPA_ADMIN_PRACTITIONERS}
            permission={USE_EHR}
            component={MedspaAdminPractitioners}
          />
        )}
        <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_LOCATIONS} permission={USE_EHR} component={MedspaAdminLocations} />
        <MedspaAdminPrivateRoute path={MEDSPA_ADMIN_SETTINGS} permission={USE_EHR} component={MedspaAdminSettings} />
        <MedspaAdminPrivateRoute
          path={MEDSPA_DOCUMENTS_MANAGEMENT}
          permission={USE_EHR}
          component={DocumentsManagement}
        />
        <MedspaAdminPrivateRoute
          path={[MEDSPA_MESSAGING_CUSTOMIZE_PATH, MEDSPA_ADMIN_SERVICES_CUSTOMIZE]}
          permission={USE_EHR}
          component={CustomizeText}
        />
        <MedspaAdminPrivateRoute
          path={[MEDSPA_CARE_MESSAGES_CUSTOMIZE_PATH, MEDSPA_ADMIN_SERVICES_CUSTOMIZE_MESSAGING]}
          permission={USE_EHR}
          component={CustomizeCareMessages}
        />
        <MedspaAdminPrivateRoute
          path={MEDSPA_ADMIN_SERVICES_MANAGEMENT}
          permission={CUSTOM_SERVICES}
          component={MedspaAdminServicesManagement}
        />
        <MedspaAdminPrivateRoute
          path={ONBOARDING_BASE_PATH}
          permission={USE_EHR}
          component={MedspaAdminOnboardingRoutes}
        />
        <MedspaAdminPrivateRoute path="/" permission={READ_CUSTOMER}>
          <Redirect to={MEDSPA_ADMIN_PATIENTS} />
        </MedspaAdminPrivateRoute>
      </Switch>
    </Wrapper>
  );
};

export default MedspaAdminRoutes;
