import React, { useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useStyles } from './ModalDeleteDraft.styles';

const ModalDeleteItems = ({ handleSaveChanges, open, handleClose }: any) => {
  const classes = useStyles();
  const [discardInProgress, setDiscardInProgress] = useState(false);

  const discard = () => {
    setDiscardInProgress(true);
    handleSaveChanges();
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <div className={classes.containerDialog}>
          <DialogTitle className={classes.title}>Are you sure you want to remove the item?</DialogTitle>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={handleClose} className={classes.buttonLeft}>
              NO
            </Button>
            <Button onClick={discard} className={classes.buttonRight} disabled={discardInProgress}>
              {discardInProgress ? <CircularProgress /> : `YES, REMOVE`}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
};

export default ModalDeleteItems;
