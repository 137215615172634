import { useMutation } from 'react-query';
import Patients from '../../services/Patients';

interface IMarkCustomerStatus {
  patientId: string;
  patientStatus: string;
}

export const useUpdateCustomerGfeStatus = () =>
  useMutation(({ patientId, patientStatus }: IMarkCustomerStatus) =>
    Patients.updateCustomerGfeStatus({ customerId: patientId, status: patientStatus })
  );
