import React from 'react';
import { Box, FormHelperText, Select, SelectProps, Tooltip, makeStyles } from '@material-ui/core';
import { KeyboardArrowDown, Info as InfoIcon } from '@material-ui/icons';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { BootstrapInput } from './base/BootstrapInput';
import { EHRMultipleSelectOnChange } from './EHRMultipleSelector';
import { EHRTypography } from './EHRTypography';

const useStyles = makeStyles({
  ehrSelect: {
    height: '2.5rem',
    borderRadius: '0.375rem',
    fontSize: '0.875rem',
    backgroundColor: '#FFFFFF',
    color: '#6F6F6F',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset',
      borderRadius: '0.375rem',
    },
    '& .MuiSelect-icon': {
      fill: '#B0AFAE',
    },
    '& .ehrSelectPlaceholder': {
      color: '#a2a2a2',
    },
    '&.Mui-error': {
      '& .MuiSelect-select': {
        borderColor: '#f44336',
      },
      '& .MuiSelect-icon': {
        fill: '#f44336',
      },
      '& .ehrSelectPlaceholder': {
        color: '#f44336',
        opacity: 0.4,
      },
    },
  },
  ehrSelectContainer: {
    width: '100%',
  },
  ehrSelectLabel: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1rem',
    color: '#706F6F',
  },
});

export type EHRSelectOnChange = (value: string | number) => void;

export type EHRSelectProps = Omit<SelectProps, 'onChange'> & {
  dataCy: string;
  helperText?: string;
  onChange: EHRSelectOnChange | EHRMultipleSelectOnChange;
  tooltip?: string;
};

const EHRSelectIcon = () => (
  <KeyboardArrowDown
    className="MuiSelect-icon"
    viewBox="6 6 12 12"
    style={{
      width: '0.9rem',
      height: '0.9rem',
      marginRight: '1rem',
      transform: 'translateY(50%)',
    }}
  />
);

const EHRSelect = React.memo(
  (props: EHRSelectProps) => {
    const classes = useStyles();
    const { dataCy, children, className, error, helperText, id, label, multiple, onChange, tooltip } = props;

    const handleOnChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const {
        target: { value },
      } = event;
      if (multiple) {
        // @ts-expect-error
        onChange?.(value);
      } else {
        // @ts-expect-error
        onChange?.(value as string);
      }
    };

    return (
      <Box className={classes.ehrSelectContainer}>
        {label && (
          <EHRTypography id={id} dataCy={`label-for-${id}`} variant="label" error={error}>
            <Box display="flex" gridGap="0.25rem" alignItems="center">
              {label}
              {tooltip && (
                <Tooltip title={tooltip} style={{ fontSize: '1rem' }}>
                  <InfoIcon />
                </Tooltip>
              )}
            </Box>
          </EHRTypography>
        )}
        <Select
          data-cy={dataCy}
          className={classNames(classes.ehrSelect, className)}
          IconComponent={EHRSelectIcon}
          error={error}
          input={<BootstrapInput error={error} />}
          id={id}
          {...props}
          onChange={handleOnChange}
        >
          {children}
        </Select>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </Box>
    );
  },
  (oldProps, newProps) => isEqual(oldProps, newProps)
);

export { EHRSelect };
