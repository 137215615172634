export const SERVICE_VISIT_VERSIONS = {
  VERSION_DEPRECATED: 1,
  VERSION_WITH_PROGRESS: 2,
  VERSION_JULY_2023: 3,
};

export const SERVICE_VISIT_STEPS = {
  services: 'step-services',
  serviceUnits: 'step-services-units',
  photoHistory: 'historical-photos',
  beforePhotos: 'before-photos',
  afterPhotos: 'after-photos',
  standingOrders: 'step-standing-orders',
  consents: 'step-signed-consent',
  photoConsent: 'photo-consent',
  scanner: 'step-scanner',
  checkout: 'step-checkout',
  medicalCharting: 'step-medical-charting',
};

export const PAYMENT_STATUS_UNPAID = 'unpaid';
export const PAYMENT_STATUS_PAID = 'paid';
export const BEFORE_PHOTO_TYPE = 'before';
export const AFTER_PHOTO_TYPE = 'after';

export const SERVICE_CREDITS_NAME = 'Service credits';

export const TRANSACTION_STATUS_PAID = 'ok';

export const SERVICE_GROUPS_WITH_OPTIONAL_AFTER_PHOTOS = [
  'B12',
  'Neurotoxins',
  'Aesthetician Services',
  'Kybella',
  'Hydroquinone',
  'Bimatoprost',
  'Upneeq',
  'Misc',
  'Retail',
  'Clerical',
  'Flex',
];

export const SERVICE_GROUPS_WITH_OPTIONAL_BEFORE_PHOTOS = ['Misc', 'Retail', 'B12', 'Aesthetician Services', 'Flex'];
export const SERVICE_VISIT_STANDING_ORDER_FIX_DATE = Date.parse('09/01/2023');
