import React, { useState } from 'react';
import { withStyles, TableCell, TableRow, IconButton, Collapse, Box, Typography, Grid } from '@material-ui/core';
import moment from 'moment';
import IconCross from '../common/IconCross';
import { useStyles } from './ProgressNoteItemRow.styles';
import ProgressNotes from './FullServiceHistoryOpen/ProgressNotes';
import { IProgressNoteItemRowProps } from '../../types/ProgressNoteTypes';

const TableCellData = withStyles(() => ({
  root: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    lineHeight: 1.67,
    letterSpacing: '-0.05px',
  },
}))(TableCell);

const TableRowStriped = withStyles(() => ({
  root: {
    fontFamily: 'Messina Sans Regular',
    height: '56px',
  },
}))(TableRow);

const ProgressNoteItemRow = ({ progressNote, striped }: IProgressNoteItemRowProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <TableRowStriped style={{ backgroundColor: striped % 2 ? '#f2f5f5' : '#fff' }}>
        <TableCell width={50} style={{ verticalAlign: 'top', paddingTop: '12px' }}>
          <IconButton
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
            style={{ padding: '7px' }}
          >
            <IconCross open={open} />
          </IconButton>
        </TableCell>
        <TableCell>
          <div className={classes.progressNoteDetails} style={{ fontWeight: open ? 'bold' : 400 }}>
            Progress note
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.progressNoteDetails} style={{ fontWeight: open ? 'bold' : 400 }}>
            {`${moment(progressNote.createdAt).format('MM/DD/YYYY')} - ${moment(progressNote.createdAt).fromNow()}`}
          </div>
        </TableCell>
        <TableCellData />
        <TableCellData />
        <TableCellData />
      </TableRowStriped>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, maxWidth: '450px' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box margin={1}>
                <Box>
                  <Grid container item xs={12} direction="row" justify="space-between" alignItems="center">
                    <Typography className={classes.progressNoteTitle}>Progress note details</Typography>
                  </Grid>
                </Box>
                <ProgressNotes progressNote={progressNote} />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProgressNoteItemRow;
