import { useMutation } from 'react-query';
import { FlexPractitionerPhysician } from '../../services/FlexPractitionerPhysician';

export interface IFlexPractitionerPhysician {
  practitionerId: string;
  customerId: string;
  firstName: string;
  lastName: string;
  professionalName: string;
  medicalMemberType: string;
  notes: string;
}

export const useSaveFlexPractitionerPhysician = () =>
  useMutation((data: IFlexPractitionerPhysician) => FlexPractitionerPhysician.createFlexPractitionerPhysician(data));
