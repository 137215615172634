import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(() => ({
  header: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 3fr 1fr',
    gap: '10px',
    placeItems: 'center',
    '& > *': {
      width: '100%',
      padding: '15px 3px',
    },
    '&.noBudget': {
      gridTemplateColumns: '4fr 1fr',
    },
  },
  productUtilization: {
    padding: 20,
  },
  mainFilters: {
    display: 'grid',
    gap: '10px',
    gridTemplateColumns: '1fr 2fr',
    '& > div:last-child': {
      paddingTop: 25,
    },
  },
  subheader: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 3fr 1fr',
    gap: '0.75rem',
    placeItems: 'center',
    '& > *': {
      width: '100%',
    },
    '&.noBudget': {
      gridTemplateColumns: '4fr 1fr',
    },
  },
  checkbox: {
    color: '#12574d',
  },
  budget: {
    border: '1px solid #E1E1E1',
    borderRadius: '10px',
    padding: '10px',
    display: 'flex',
    minHeight: '96px',
    backgroundColor: 'white',
  },
  bar: {
    border: '1px solid #E1E1E1',
    borderRadius: '10px',
    padding: '10px',
    minHeight: '96px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      width: '100%',
    },
  },
  totalInventoryValue: {
    display: 'flex',
    '&::before': {
      content: '" "',
      backgroundColor: '#1D584D',
      width: '20px',
      height: '20px',
      marginRight: '5px',
      borderRadius: '5px',
    },
  },
  availableCredit: {
    display: 'flex',
    '&::before': {
      content: '" "',
      backgroundColor: '#C4D5D2',
      width: '20px',
      height: '20px',
      marginRight: '5px',
      borderRadius: '5px',
    },
  },
  primaryButton: {
    width: '10px',
    color: '#ffffff',
    backgroundColor: '#12574d',
    height: '28px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#104e45',
    },
    padding: '5px 20px',
    margin: '0 auto',
    marginRight: '0',
  },
  greyoutText: {
    fontWeight: 400,
    fontSize: '14px',
    opacity: '48%',
    margin: 0,
  },
  totals: {
    border: '1px solid #E1E1E1',
    borderRadius: '10px',
    padding: '20px',
    minHeight: '96px',
    backgroundColor: 'white',
  },
  tableRow: {
    '& td': {
      fontFamily: 'Messina Sans Regular',
    },
    '& td:first-child': {
      fontFamily: 'Messina Sans SemiBold',
    },
  },
  editOrderingBudget: {
    minWidth: 350,
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      display: 'flex',
      margin: 'auto',
      padding: 5,
    },
  },
  dataWithLoader: { margin: 0, padding: 0 },
  budgetContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  fullWidth: { width: '100%' },
  fontMedium: { fontSize: '12px' },
  noMargin: { margin: 0 },
  circularProgress: { color: '#12574d', marginRight: '10px' },
  providerSelect: {
    margin: 'auto 0',
  },
}));
