import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { ShortMultipleSkeleton } from '../../../components/common/LoadingSkeleton';
import ServiceGroupList from '../../../components/NewServiceVisit/ServiceGroupList';
import { ServiceList } from '../../../components/NewServiceVisit/ServiceList';
import IServices from '../../../interfaces/IServices';
import { dispatch } from '../../../rematch';
import IServiceGroup from '../../../interfaces/IServiceGroup';
import { MISC_SERVICE_GROUP_ID, FLEX_SERVICE_GROUP_ID } from '../../../constants/general.constants';
import { RETAIL_SERVICE_GROUP_ID } from '../../../constants/retail.constants';
import IServiceRequest from '../../../interfaces/IServiceRequest';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CUSTOM_SERVICES, GFE_REQUEST } from '../../../constants/actions.constants';

const SelectProducts = ({
  selectedServices,
  setSelectedServices,
}: {
  selectedServices: number[];
  setSelectedServices: (services: number[]) => void;
}) => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const { serviceGroups, serviceRequests } = useSelector(({ patient }: any) => patient);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState<number>(1);
  const newServiceVisit = useSelector((state: any) => state.newServiceVisit);
  const { services = [] }: { services: IServices[] } = newServiceVisit;

  useEffect(() => {
    dispatch({
      type: 'newServiceVisit/fetchServices',
      payload: {},
    });
    dispatch.patient.fetchServiceGroups();
  }, []);

  useEffect(() => {
    const shownServiceGroupsId = serviceGroupsToRender.map(({ id }: IServiceGroup) => id);
    if (shownServiceGroupsId?.length > 0 && !shownServiceGroupsId.includes(selectedServiceGroup)) {
      setSelectedServiceGroup(shownServiceGroupsId[0]);
    }
  }, [serviceGroups]);

  const handleOnChangeServiceSelection = (serviceId: number): void => {
    const existingServices = selectedServices.find((id) => id === serviceId);
    if (existingServices) {
      setSelectedServices(selectedServices.filter((id) => id !== serviceId));
    } else {
      setSelectedServices([...selectedServices, serviceId]);
    }
  };

  const handleServiceGroupSelect = (serviceGroupId: number) => {
    setSelectedServiceGroup(serviceGroupId);
  };

  const servicesToRender = useMemo(
    () => services.filter(({ serviceGroupId, showInEmr }) => serviceGroupId === selectedServiceGroup && showInEmr),
    [services, selectedServiceGroup]
  );

  const shouldRenderToPatient = (isServiceGroupRequested: number) => {
    // show Misc, Retail, Flex services regardless if they are approved or not
    if (
      (hasAccessTo(CUSTOM_SERVICES, permissions) && !hasAccessTo(GFE_REQUEST, permissions)) ||
      isServiceGroupRequested === MISC_SERVICE_GROUP_ID ||
      isServiceGroupRequested === RETAIL_SERVICE_GROUP_ID ||
      isServiceGroupRequested === FLEX_SERVICE_GROUP_ID
    ) {
      return true;
    }
    const serviceRequest = serviceRequests.find(
      ({ serviceGroupId }: IServiceRequest) => serviceGroupId === isServiceGroupRequested
    );
    return !!serviceRequest?.approved;
  };

  const serviceGroupsToRender = useMemo(
    () =>
      serviceGroups.filter(
        ({ id }: IServiceGroup) =>
          shouldRenderToPatient(id) &&
          services.some(({ serviceGroupId, showInEmr }) => serviceGroupId === id && showInEmr)
      ),
    [serviceGroups, services]
  );

  if (services.length === 0 || serviceGroups.length === 0) {
    return (
      <Grid container spacing={2} style={{ padding: '12px 15px' }}>
        <Grid item xs={12} md={4} lg={4}>
          <ShortMultipleSkeleton length={5} />
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <ShortMultipleSkeleton length={5} />
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <ServiceGroupList
            selectedServices={selectedServices}
            serviceGroups={serviceGroupsToRender}
            services={services}
            onServiceGroupSelect={handleServiceGroupSelect}
            selectedServiceGroup={selectedServiceGroup}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <ServiceList
            services={servicesToRender}
            onChangeServiceSelection={handleOnChangeServiceSelection}
            selectedServices={selectedServices}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectProducts;
