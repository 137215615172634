import React from 'react';
import { InputBaseProps, makeStyles, FormHelperText, Tooltip, Box } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import classNames from 'classnames';
import { BootstrapInput } from './base/BootstrapInput';
import { EHRTypography } from './EHRTypography';

const useStyles = makeStyles({
  ehrTextField: {
    height: '2.5rem',
    width: '100%',
    '& .Mui-disabled': {
      color: 'black',
      background: '#EEEEEE',
    },
  },
  ehrTextFieldContainer: {
    width: '100%',
  },
});

export type EHRTextFieldProps = InputBaseProps & {
  dataCy: string;
  label?: string;
  helperText?: string;
  tooltip?: string;
  step?: number;
};

const EHRTextField = (props: EHRTextFieldProps) => {
  const styles = useStyles();
  const { step, className, label, id, error, helperText, tooltip, classes, readOnly, ...restProps } = props;

  return (
    <Box className={classNames(styles.ehrTextFieldContainer, classes?.formControl)}>
      {label && (
        <EHRTypography dataCy={`label-for-${id}`} id={id} variant="label" error={error}>
          <Box display="flex" gridGap="0.25rem" alignItems="center">
            {label}
            {tooltip && (
              <Tooltip title={tooltip} style={{ fontSize: '1rem' }}>
                <InfoIcon />
              </Tooltip>
            )}
          </Box>
        </EHRTypography>
      )}
      {readOnly ? (
        <EHRTypography dataCy={`readOnly-${id}`} variant="readonly">
          {restProps.value}
        </EHRTypography>
      ) : (
        <BootstrapInput
          {...restProps}
          step={step}
          id={id}
          className={classNames(styles.ehrTextField, classes?.input, className)}
          error={error}
        />
      )}
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Box>
  );
};

export { EHRTextField };
