import { createStyles, makeStyles, Select, TableCell, withStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tableRow: {
    height: '60px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'center',
  },
  checkbox: {
    color: '#12574d',
  },
  formControl: {
    height: '46px',
    width: '100%',
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100px',
    width: '100%',
    padding: '10px',
  },
  secondaryButton: {
    color: '#393B3F',
    border: 'solid 1px #FBDADA',
    backgroundColor: '#FBDADA',
    height: '36px',
    minWidth: '104px',
    margin: '5px',
  },
  applyButton: {
    color: '#ffffff !important',
    backgroundColor: '#12574d',
    '&:hover': {
      backgroundColor: '#104e45',
    },
    minWidth: '100px',
    height: '40px',
  },
}));

export const SelectCustom = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: 0,
      color: 'rgba(0, 0, 0, .54)',
      fontSize: '15px',
      fontFamily: 'Messina Sans Regular',
    },
    select: {
      color: '#000',
    },
  })
)(Select);

export const TableCellCustom = withStyles(() => ({
  root: {
    fontSize: '14px',
    fontFamily: 'Messina Sans Regular',
    color: '#393b3f',
    letterSpacing: '-0.05px',
    height: '36px',
    padding: '3px 16px',
  },
}))(TableCell);
