import Api from './Api';

const ProfileProgress = {
  async getProfileProgress(): Promise<{ stepsCompleted: string[] }> {
    const { stepsCompleted } = await Api.get('/v3/profile_progress');
    return { stepsCompleted };
  },

  async updateProfileProgress({ stepName }: { stepName: string }): Promise<{ stepsCompleted: string[] }> {
    const { error, stepsCompleted } = await Api.post('/v3/profile_progress', { stepName });
    if (error) {
      throw new Error(error);
    }
    return { stepsCompleted };
  },
};

export default ProfileProgress;
