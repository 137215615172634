import React, { useEffect, useState } from 'react';
import { Avatar, Box, Divider } from '@material-ui/core';
import { DEFAULT_AVATAR_IMAGE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import { useMutation } from 'react-query';
import { sendResetPasswordEmail } from 'src/services/Password';
import { toast } from 'react-toastify';
import { EHRButton } from 'src/components/ui/v1';
import { IUserGroup } from '../../../interfaces/IUserGroup';
import { useServicesCommissions } from '../../../hooks/queries/useCommissions';
import useStylesMedspaSettings from '../index.styles';
import { UserInfoRow } from '../common/UserInfoRow';

interface ProfileDetailsProps {
  user: any;
  userGroup: IUserGroup | undefined;
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({ user, userGroup }) => {
  const classesMedspaSettings = useStylesMedspaSettings();
  const [isLegacyRole, setIsLegacyRole] = useState(false);
  const [commission, setCommission] = useState('');
  const { data: commissions } = useServicesCommissions();

  const loggedUserLinkPractitionerAccount = user?.linkedAccounts[0];

  const { mutate: sendResetPasswordEmailMutation, isLoading: isSendingEmail } = useMutation({
    mutationFn: sendResetPasswordEmail,
    onError: () => {
      toast.error('An error was encounter sending the email, please try again later');
    },
    onSuccess: () => {
      toast.success('If there is an account with that email address, a reset link is on its way!');
    },
  });

  const handleSendEmailSubmit = ({ email }: { email: string }) => {
    const resetPasswordUrl = `${window.location.origin}/reset-password`;
    sendResetPasswordEmailMutation({ email, redirectUrl: resetPasswordUrl });
  };

  useEffect(() => {
    if (userGroup) {
      setIsLegacyRole(userGroup.roleName === PORTRAIT_LEGACY || userGroup.roleName === PORTRAIT_LEGACY_PLUS);
    }

    if (commissions) {
      setCommission(commissions?.find((c) => c.id === userGroup?.servicesCommission)?.shortDescription || '');
    }
  }, [userGroup, commissions]);

  return (
    <Box className={classesMedspaSettings.medspaSettingsContainer}>
      <UserInfoRow label="Full Name" userData={`${user?.firstName} ${user?.lastName}`} />
      <Divider />
      <UserInfoRow
        label="Photo"
        userData={`${user?.firstName} ${user?.lastName}`}
        description="This will be displayed on your admin and provider accounts"
        content={
          <Avatar
            alt={`${user?.firstName} ${user?.lastName}'s avatar`}
            src={user?.profileImageUrl || DEFAULT_AVATAR_IMAGE}
            style={{ width: 56, height: 56 }}
            data-cy="medspaAdminAvatarLetter"
          />
        }
      />
      <Divider />
      <UserInfoRow label="Email" userData={user?.email} />
      <Divider />
      <UserInfoRow label="Phone" userData={user?.phone} />
      <Divider />
      <UserInfoRow
        label="Password"
        userData="********"
        third={
          <EHRButton
            text="Reset Password"
            color="text"
            onClick={() => handleSendEmailSubmit({ email: user?.email })}
            dataCy="medspa-admin-account-setting-reset-password-btn"
            disabled={isSendingEmail}
          />
        }
      />

      {user?.linkedPractitionerId && (
        <>
          <Divider />
          <UserInfoRow
            label="Signature"
            userData={`${loggedUserLinkPractitionerAccount?.firstName} ${loggedUserLinkPractitionerAccount?.lastName}`}
            description="In order to sign the documents digitally we need to use your real signature."
            content={<img src={loggedUserLinkPractitionerAccount?.signatureUrl} alt="Signature preview" />}
          />

          <Divider />
          <UserInfoRow label="Licenses" userData={loggedUserLinkPractitionerAccount?.licenses} />
          <Divider />
          <UserInfoRow label="Position" userData={loggedUserLinkPractitionerAccount?.position} />
        </>
      )}

      {isLegacyRole && (
        <>
          <Divider />
          <UserInfoRow label="Commission" userData={commission} />
        </>
      )}
    </Box>
  );
};

export default ProfileDetails;
