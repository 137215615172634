import { useQuery, useMutation, useQueryClient } from 'react-query';
import { MEDSPA_APPOINTMENT_TYPES } from '../../../constants/reactQuery.keys';
import { Appointment } from '../../../services/Appointment';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../../utils/global';
import { IAppointmentType } from '../../../interfaces/acuity/acuity.interface';
import compile from '../../../utils/toastMessagesCompiler';
import { SNACKBAR_SUCCESS } from '../../../constants/general.constants';

export const useMedspaAppointmentTypes = (medspaId: number | null, filter = {}) => {
  const response = useQuery(
    [MEDSPA_APPOINTMENT_TYPES, medspaId, filter],
    () => Appointment.medspa.appointmentTypes(medspaId, filter),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: !!medspaId,
      retry: 0,
      onError: useReactQueryOnErrorHandler,
    }
  );

  return {
    ...response,
    data: response?.data ? response.data.appointmentTypes : [],
  };
};

export const useMedspaCreateAppointmentTypeMutation = (medspaId: string | number) => {
  const queryClient = useQueryClient();

  return useMutation((params: IAppointmentType) => Appointment.medspa.createAppointmentType(medspaId, params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([MEDSPA_APPOINTMENT_TYPES]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Appointment type',
          action: 'created',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useUpdateMedspaAppointmentTypeMutation = (medspaId: number | null) => {
  const queryClient = useQueryClient();

  // @ts-ignore
  return useMutation((params: IAppointmentType) => Appointment.medspa.updateAppointmentType(medspaId, params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([MEDSPA_APPOINTMENT_TYPES]);

      showSnackbar(
        compile('generic.success_message', {
          element: 'Appointment type',
          action: 'updated',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};
