import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  consent: {
    padding: '10px 20px',
    fontFamily: 'Messina Sans Regular',
    borderRadius: '4px',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.14,
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: '#12574d',
    display: 'block',
    margin: '5px',
    textDecoration: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase',
    width: 'max-content',
  },
  notSigned: {
    borderRadius: '4px',
    border: ' solid 2px #ff0000',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    margin: '5px',
  },
  notSignedAndNotRequired: {
    borderRadius: '4px',
    border: ' solid 2px #4b78f7',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    margin: '5px',
  },
  optional: {
    borderRadius: '4px',
    border: ' solid 2px gray',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    margin: '5px',
  },
  alertText: {
    width: '215px',
    height: '29px',
    fontFamily: 'Messina Sans Regular',
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.23',
    letterSpacing: 'normal',
    color: '#ff0000',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  gridContainer: {
    height: '650px',
  },
}));
