import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/rematch';
import { useCustomMedicalProfile } from 'src/hooks/queries/useCustomMedicalProfile';
import { MultipleSkeleton } from 'src/components/common/LoadingSkeleton';
import { useCustomPatientProfile } from 'src/hooks/queries/useCustomPatientProfile';
import { useStyles } from './skinProfileForm.styles';
import { SkinTone } from './SkinTone';
import { SkinConditions } from './SkinConditions';
import { SunscreenFrequency } from './SunscreenFrequency';
import { BinaryQuestion } from './BinaryQuestion';
import { GoalsNotes } from './GoalsNotes';

const SkinProfileForm = () => {
  const classes = useStyles();
  const { id: patientId } = useSelector(({ patient }: RootState) => patient);
  const { data: medicalProfile, isLoading } = useCustomMedicalProfile(String(patientId));
  const { data: patient, isLoading: isLoadingPatient } = useCustomPatientProfile(String(patientId));

  useEffect(() => {
    if (!medicalProfile) {
      return;
    }

    dispatch({ type: 'patient/updateMedicalProfile', payload: { medicalProfile } });
  }, [medicalProfile, isLoading]);

  if (isLoading || isLoadingPatient) {
    return <MultipleSkeleton />;
  }

  return (
    <div>
      <div className={classes.nameContainer}>
        <h4 className={classes.name}>{`${patient?.firstName} ${patient?.lastName}`}</h4>
      </div>
      <div className={classes.form}>
        {medicalProfile?.usingPeels && (
          <BinaryQuestion
            question="Are you currently using any peels?"
            value={medicalProfile.usingPeels}
            objectKey="usingPeels"
          />
        )}
        {medicalProfile?.usingRetinol && (
          <BinaryQuestion
            question="Are you currently using retinol/retin-a?"
            value={medicalProfile.usingRetinol}
            objectKey="usingRetinol"
          />
        )}
        <SkinTone />
        <SunscreenFrequency />
        <SkinConditions />
        <GoalsNotes />
      </div>
    </div>
  );
};

export default SkinProfileForm;
