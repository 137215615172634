import React, { FormEvent, Fragment } from 'react';
import { Divider, FormControl, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import moment from 'moment';
import {
  DeleteForever as DeleteForeverIcon,
  InsertDriveFile as InsertDriveFileIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Button } from '../Button';
import { useStyles } from '../../PatientProfile/MainSection/mainSection.styles';
import { Title } from '../card';
import { ICustomerNote } from '../../../interfaces/ICustomerNote';
import { IMHANote } from '../../../interfaces/IMedicalHANote';
import {
  PHYSICIAN_ROLE,
  LIMIT_INITIAL_CUSTOMER_NOTE,
  PRACTITIONER_ROLE,
  ADMIN_ROLE,
} from '../../../constants/general.constants';
import Message from '../Message/Message';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_ADMIN_NOTE, DESTROY_ADMIN_NOTE } from '../../../constants/actions.constants';

type Props = {
  title?: string;
  contentPropName: 'note' | 'content' | 'text';
  subtitle?: string;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  disabled: boolean;
  inputValue: string;
  onChangeValue: (val: string) => void;
  notesLength: number;
  onDelete: (id: number) => void;
  viewMoreState: boolean;
  viewMoreAction: () => void;
  data: ICustomerNote[] | IMHANote[] | any[];
  practitionerNote?: boolean;
  readOnly?: boolean;
};

export const PatientProfileNote = ({
  title = '',
  contentPropName,
  subtitle = '',
  onSubmit,
  disabled,
  inputValue,
  onChangeValue,
  notesLength,
  onDelete,
  viewMoreAction,
  viewMoreState,
  data,
  readOnly,
  practitionerNote,
}: Props) => {
  const classes = useStyles();
  const userType = useSelector(({ auth }: any) => auth.userType);
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  return (
    <div className={`${classes.containerForm} ${classes.noMarginTop}`}>
      <Title title={title} style={{ marginTop: '0', marginBottom: '20px' }} />
      {!readOnly && hasAccessTo(CREATE_ADMIN_NOTE, permissions) && (
        <form className={classes.noteSection} onSubmit={onSubmit}>
          {subtitle && <span className={classes.titleForm}>{subtitle}</span>}
          <FormControl className={classes.noteInput} variant="outlined">
            <InputLabel className={classes.noteLabel}>Add a note...</InputLabel>
            <OutlinedInput
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    title="Add a note"
                    style={{ color: '#000000', backgroundColor: '#e7eeed' }}
                    disabled={!inputValue || disabled}
                    onClick={onSubmit}
                  />
                </InputAdornment>
              }
              labelWidth={90}
              value={inputValue}
              onChange={(event) => {
                onChangeValue(event.target.value);
              }}
              className={classes.input}
              multiline
            />
          </FormControl>
        </form>
      )}
      <div style={{ marginTop: '30px' }}>
        {data && !!data.length ? (
          data.slice(0, notesLength).map((note: ICustomerNote | any) => (
            <Fragment key={note.id}>
              <div className={classes.note}>
                <div
                  style={{ display: 'flex', alignItems: 'flex-start', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                >
                  <InsertDriveFileIcon className={classes.addNoteIcon} />
                  <span>{note[contentPropName]}</span>
                </div>
                {!readOnly && hasAccessTo(DESTROY_ADMIN_NOTE, permissions) && (
                  <DeleteForeverIcon
                    className={classes.deleteIcon}
                    onClick={() => {
                      onDelete(note.id);
                    }}
                  />
                )}
              </div>
              <div className={classes.dateCard}>
                {(userType === ADMIN_ROLE || userType === PHYSICIAN_ROLE) && !practitionerNote && note.createdBy ? (
                  <> {note.createdBy} - </>
                ) : (
                  <> {note.physicianName} - </>
                )}
                {moment(note.createdAt).format('MM/DD/YYYY - hh:mm A')}
              </div>
              <Divider />
            </Fragment>
          ))
        ) : (
          <>{!practitionerNote || (userType !== PRACTITIONER_ROLE && <Message text="There are no notes yet" />)}</>
        )}
      </div>
      {!!data.length && data.length > LIMIT_INITIAL_CUSTOMER_NOTE && (
        <div className={classes.bottomBtn}>
          <button className={classes.btnMore} type="button" onClick={viewMoreAction}>
            {viewMoreState ? 'View less' : `View more (${data.length - LIMIT_INITIAL_CUSTOMER_NOTE})`}
            {viewMoreState ? (
              <ExpandLessIcon width={12} height={12} className={classes.imgArrow} />
            ) : (
              <ExpandMoreIcon width={12} height={12} className={classes.imgArrow} />
            )}
          </button>
        </div>
      )}
    </div>
  );
};
