import React, { useState, FormEvent } from 'react';
import _ from 'lodash';
import { useQueryClient, useMutation } from 'react-query';
import { CardContent } from '@material-ui/core';
import { LIMIT_INITIAL_CUSTOMER_NOTE } from '../../../../constants/general.constants';
import { dispatch } from '../../../../rematch';
import { PatientProfileNote } from '../../../common/PatientProfileNote';
import compile from '../../../../utils/toastMessagesCompiler';
import CustomerNotes from '../../../../services/CustomerNotes';
import { CUSTOMER_NOTES } from '../../../../constants/reactQuery.keys';
import { ICustomerNote } from '../../../../interfaces/ICustomerNote';
import { ShortMultipleSkeleton } from '../../../common/LoadingSkeleton';
import { Card } from '../../../common/card';

const SUCCESS_CREATED_NOTE = compile('generic.success_message', {
  action: 'added',
  element: 'Sticky note',
  duration: 3000,
});
const SUCCESS_REMOVING_NOTE = compile('generic.success_message', {
  action: 'removed',
  element: 'Sticky note',
});
const ERROR_CREATED_NOTE = compile('generic.error_message', {
  action: 'customizing',
  element: 'the sticky note',
});
const ERROR_REMOVING_NOTE = compile('generic.error_message', {
  action: 'removing',
  element: 'the sticky note',
});

const { createCustomerNote, removeCustomerNote } = CustomerNotes;

type Props = {
  patientId: string;
  customerNotesCollection: ICustomerNote[];
  isLoading: boolean;
  isAdmin?: boolean;
};

const StickyNotes: React.FC<Props> = ({ patientId, customerNotesCollection, isLoading, isAdmin }: Props) => {
  const queryClient = useQueryClient();
  const [stickyNote, setStickyNote] = useState<string>('');
  const [showMoreStickyNotes, setMoreStickyNotes] = useState<boolean>(false);

  const stickyNotesData = _.orderBy(customerNotesCollection, ['updatedAt'], ['desc']);

  const mutationCreate = useMutation(createCustomerNote);
  const mutationDelete = useMutation(removeCustomerNote);

  const clearStickyNote = () => {
    setStickyNote('');
  };

  const viewMoreStickyNotes = () => {
    setMoreStickyNotes(!showMoreStickyNotes);
  };

  const addStickyNote = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      customerId: +patientId,
      note: stickyNote,
    };
    if (stickyNote.trim() !== '') {
      try {
        await mutationCreate.mutateAsync(data);
        queryClient.invalidateQueries([CUSTOMER_NOTES, patientId]);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: SUCCESS_CREATED_NOTE,
            type: 'success',
            duration: 1000,
          },
        });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: ERROR_CREATED_NOTE,
            type: 'error',
            duration: 1000,
          },
        });
      } finally {
        clearStickyNote();
      }
    }
  };

  const deleteCustomerNote = async (noteId: number) => {
    try {
      await mutationDelete.mutateAsync({
        id: noteId,
      });
      queryClient.invalidateQueries([CUSTOMER_NOTES, patientId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_REMOVING_NOTE,
          type: 'success',
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_REMOVING_NOTE,
          type: 'error',
        },
      });
    }
  };

  if (isLoading) {
    return (
      <Card>
        <CardContent style={{ padding: '12px 15px' }}>
          <ShortMultipleSkeleton length={5} />
        </CardContent>
      </Card>
    );
  }

  const notesLengthStickyNotes = showMoreStickyNotes ? customerNotesCollection.length : LIMIT_INITIAL_CUSTOMER_NOTE;

  return (
    <PatientProfileNote
      title={isAdmin ? `Doctor & Admin notes` : ''}
      contentPropName="note"
      subtitle="Sticky notes"
      onSubmit={addStickyNote}
      disabled={mutationCreate.isLoading}
      inputValue={stickyNote}
      onChangeValue={setStickyNote}
      notesLength={notesLengthStickyNotes}
      onDelete={deleteCustomerNote}
      viewMoreState={showMoreStickyNotes}
      viewMoreAction={viewMoreStickyNotes}
      data={stickyNotesData}
    />
  );
};

export default StickyNotes;
