import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import {
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Divider,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  DialogContentText,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { sortBy } from 'lodash';

import { useCreateCareMessageMutation, useUpdateCareMessageMutation } from 'src/hooks/queries/useCareMessages';
import { useServiceGroups } from 'src/hooks/queries/services/useServices';
import { hasAccessTo } from 'src/utils/auth.utils';
import { CUSTOM_SERVICES } from 'src/constants/actions.constants';
import { useSelector } from 'react-redux';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { ShortMultipleSkeleton } from '../../components/common/LoadingSkeleton';

import { ICustomCareMessage, ICareMessage, IFollowUpMessage } from '../../interfaces/ICustomCareMessage';

import Form from './components/MessageForm';
import FollowUpForm from './components/FollowUpForm';
import useStyles from './CustomizeMessage.styles';
import CustomDialog from './components/CustomDialog';
import { PRACTITIONER_MESSAGING_INDEX_PATH } from '../../routes/practitionerRoutes';

const DEFAULT_CARE_MESSAGE = {
  endingMessage: '',
  introductoryMessage: '',
  mandatoryMessage: '',
};
const DEFAULT_FOLLOW_MESSAGE = {
  message: '',
  periodFrequency: 'days',
  periodNumber: 1,
};

const PRE_CARE_SECTION = 'PRE_CARE';
const POST_CARE_SECTION = 'POST_CARE';
const FOLLOWUP_SECTION = 'FOLLOWUP';

const SHOW_FOLLOW_UP = false;

const CustomizeMessage = () => {
  const history = useHistory();
  const { state } = useLocation() || {};
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const classes = useStyles();

  const { customCareMessages, readOnly, backUrl, searchUrl, form } = state;

  const [selectedServicesGroups, setSelectedServicesGroups] = useState<any>({});

  const [nameData, setNameData] = useState<string>('');
  const [preCareData, setPreCareData] = useState<ICareMessage>();
  const [postCareData, setPostCareData] = useState<ICareMessage>();
  const [followUpData, setFollowUpData] = useState<IFollowUpMessage>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = React.useState<string | false>(PRE_CARE_SECTION);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [servicesDialogOpen, setServicesDialogOpen] = useState(false);

  const { data: serviceGroups = [] } = useServiceGroups();

  useEffect(() => {
    if (customCareMessages) {
      setIsLoading(false);
      setNameData(customCareMessages.name || 'New care message');
      const preData = customCareMessages.preCare
        ? { ...customCareMessages.preCare, practitionerId: customCareMessages.practitionerId }
        : DEFAULT_CARE_MESSAGE;
      setPreCareData(preData);
      const postData = customCareMessages.postCare
        ? { ...customCareMessages.postCare, practitionerId: customCareMessages.practitionerId }
        : DEFAULT_CARE_MESSAGE;
      setPostCareData(postData);
      setFollowUpData(customCareMessages.followUp || DEFAULT_FOLLOW_MESSAGE);
      const selectedGroups: any = {};
      customCareMessages.serviceGroupApplied?.forEach((id: number) => {
        selectedGroups[id] = true;
      });
      setSelectedServicesGroups(selectedGroups);
    }
  }, [customCareMessages]);

  const successCallback = (formData?: any) => {
    setDialogOpen(false);
    if (backUrl) {
      history.push({
        pathname: backUrl,
        search: searchUrl,
        state: {
          form: { ...form, ...formData },
        },
      });
    } else {
      history.push(PRACTITIONER_MESSAGING_INDEX_PATH);
    }
  };
  const createMutation = useCreateCareMessageMutation(successCallback);
  const updateMutation = useUpdateCareMessageMutation(successCallback);

  const handleChangeTab = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeCheckboxes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedServicesGroups({ ...selectedServicesGroups, [event.target.name]: event.target.checked });
  };

  const goBack = () => {
    if (backUrl) {
      history.push({
        pathname: backUrl,
        search: searchUrl,
        state: {
          form,
        },
      });
    } else {
      history.push(PRACTITIONER_MESSAGING_INDEX_PATH);
    }
  };

  const saveMessagging = async () => {
    const copyTo = Object.entries(selectedServicesGroups)
      .filter(([, isSelected]) => isSelected)
      .map(([serviceId]) => +serviceId);

    const data: ICustomCareMessage = {
      ...customCareMessages,
      id: customCareMessages.id,
      name: nameData,
      preCare: preCareData,
      postCare: postCareData,
      followUp: followUpData,
      serviceGroupApplied: copyTo,
    };

    // updateMutation.mutate({ customCareMessage: data, copyTo });
    if (!customCareMessages.id) {
      createMutation.mutate(data);
    } else {
      updateMutation.mutate(data);
    }
  };

  const NavigationTitle = readOnly ? 'Preview' : 'Customize Message';

  const Title = readOnly ? (
    <div style={{ marginBottom: '1rem' }}>
      <Typography variant="h5" component="h2" color="textPrimary">
        Template used for: {customCareMessages.name}
      </Typography>
    </div>
  ) : (
    <div style={{ marginBottom: '1rem' }}>
      <Typography variant="h5" component="h2" color="textPrimary">
        Customize messages for: {customCareMessages.name}
        {!customCareMessages.isGlobal && (
          <Button
            size="small"
            variant="contained"
            color="default"
            style={{ marginLeft: '.5rem' }}
            onClick={() => setServicesDialogOpen(true)}
          >
            {hasAccessTo(CUSTOM_SERVICES, permissions)
              ? 'Apply same Care Messages to'
              : 'Apply the same Intro/Ending to'}
          </Button>
        )}
      </Typography>
      <Typography color="textSecondary">Write messages to your patients for before and after services</Typography>
    </div>
  );

  const nameForm = (
    <>
      <Typography variant="h5" className={classes.heading}>
        Care Message Name
      </Typography>
      <FormControl size="small" variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="name-id" color="secondary" className={classes.formControlLabel}>
          Name
        </InputLabel>
        <OutlinedInput
          id="name-id"
          type="text"
          fullWidth
          value={nameData}
          disabled={readOnly}
          onChange={(ev: any) => setNameData(ev?.target?.value)}
        />
      </FormControl>
    </>
  );

  const PreCareTab = (
    <Accordion expanded={expanded === PRE_CARE_SECTION} onChange={handleChangeTab(PRE_CARE_SECTION)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h5" className={classes.heading}>
          Pre care service messages{' '}
          <small className={classes.secondaryHeading}>(Will be sent approximately one week before the service)</small>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.root}>
        <Form
          isFromMasterLibrary={customCareMessages.isFromMasterLibrary}
          readOnly={readOnly}
          data={preCareData}
          onChange={(el: any) => setPreCareData({ ...preCareData, ...el })}
        />
      </AccordionDetails>
    </Accordion>
  );

  const PostCareTab = (
    <Accordion expanded={expanded === POST_CARE_SECTION} onChange={handleChangeTab(POST_CARE_SECTION)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
        <Typography variant="h5" className={classes.heading}>
          Post care service messages{' '}
          <small className={classes.secondaryHeading}>(Will be sent immediately upon completion of the service)</small>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.root}>
        <Form
          isFromMasterLibrary={customCareMessages.isFromMasterLibrary}
          readOnly={readOnly}
          data={postCareData}
          onChange={(el: any) => setPostCareData({ ...postCareData, ...el })}
        />
      </AccordionDetails>
    </Accordion>
  );

  const FollowUpTab = (
    <Accordion expanded={expanded === FOLLOWUP_SECTION} onChange={handleChangeTab(FOLLOWUP_SECTION)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
        <Typography className={classes.heading}>Follow up messages</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.root}>
        <FollowUpForm
          readOnly={readOnly}
          data={followUpData}
          onChange={(el: any) => setFollowUpData({ ...followUpData, ...el })}
        />
      </AccordionDetails>
    </Accordion>
  );

  const Buttons = () => {
    if (readOnly) {
      return null;
    }
    return (
      <div style={{ marginTop: '1rem', textAlign: 'right' }}>
        <Divider style={{ marginBottom: '1rem' }} />
        <Button color="default" size="large" onClick={goBack}>
          Cancel
        </Button>
        <Button color="primary" size="large" variant="contained" onClick={() => setDialogOpen(true)}>
          {updateMutation.isLoading ? <CircularProgress /> : 'Apply'}
        </Button>
      </div>
    );
  };

  /* SHOW ALL EXCEPT THE SERVICE GROUP OF CURRENT */
  const ServicesGroupCheckbox = (
    <Grid container spacing={2}>
      {sortBy(serviceGroups, ['name'])
        .filter(({ id }) => id !== customCareMessages.serviceGroupId)
        .map(({ name: serviceName, id: serviceId }) => (
          <Grid item xs={12} sm={6} md={4} key={`checkbox_group_${serviceId}`}>
            <FormControlLabel
              key={serviceId}
              label={serviceName}
              control={
                <Checkbox
                  color="primary"
                  checked={selectedServicesGroups[serviceId] === true}
                  onChange={handleChangeCheckboxes}
                  name={`${serviceId}`}
                />
              }
            />
          </Grid>
        ))}
    </Grid>
  );

  const selectedServiceGroupsNames = () => {
    const selectedServicesGroupsIds = Object.entries(selectedServicesGroups)
      .filter(([, isSelected]) => isSelected)
      .map(([serviceId]) => serviceId);

    const names = serviceGroups
      .filter((sg) => selectedServicesGroupsIds.includes(sg.id.toString()))
      .map((sg) => sg.name)
      .join(', ');
    const replacingLastComma = `, ${names}`.replace(/,(?=[^,]*$)/, ' & ');
    return names ? replacingLastComma : '';
  };
  return (
    <>
      <NavigationBar title={NavigationTitle} onBackButtonClick={goBack} />
      <article style={{ padding: '12px', width: 'inherit', backgroundColor: '#e3e3e3' }}>
        <Card variant="outlined">
          {isLoading ? (
            <CardContent style={{ padding: '12px 15px' }}>
              <ShortMultipleSkeleton length={4} />
            </CardContent>
          ) : (
            <CardContent style={{ padding: '12px 15px' }}>
              {Title}
              {nameForm}
              {PreCareTab}
              {PostCareTab}
              {SHOW_FOLLOW_UP && FollowUpTab}
              {Buttons()}
              <CustomDialog
                open={dialogOpen}
                title="Customize Message"
                content={
                  <DialogContentText>
                    <Typography>You are about to save your changes as a template to be used in:</Typography>
                    <Typography color="primary" variant="h6" component="h2">
                      {customCareMessages.name}
                      {!customCareMessages.isGlobal && <small>{selectedServiceGroupsNames()}</small>}
                    </Typography>
                    {customCareMessages.isGlobal && (
                      <small>This message will be used for all service groups messages which are not customized</small>
                    )}
                  </DialogContentText>
                }
                loading={updateMutation.isLoading}
                okText="Yes, save this template"
                onOk={saveMessagging}
                cancelText="Continue editing"
                onCancel={() => setDialogOpen(false)}
              />
              <CustomDialog
                open={servicesDialogOpen}
                title="Choose the service groups"
                content={ServicesGroupCheckbox}
                loading={updateMutation.isLoading}
                okText="Done!"
                onOk={() => setServicesDialogOpen(false)}
              />
            </CardContent>
          )}
        </Card>
      </article>
    </>
  );
};

export default CustomizeMessage;
