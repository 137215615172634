import { IMonthlyBlocker, IMonthlyBlockerApi } from 'src/interfaces/IMonthlyBlocker';
import Api from './Api';

const MonthlyBlockers = {
  async getBlockers(practitionerId: string | null | undefined): Promise<IMonthlyBlocker[]> {
    const params = !Number.isNaN(Number(practitionerId)) ? { practitionerId } : {};
    const { success, data }: IMonthlyBlockerApi = await Api.get('/monthly_blockers/all', params);
    return success ? data : [];
  },
};

export { MonthlyBlockers, MonthlyBlockers as default };
