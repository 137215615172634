import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'src/hooks/useQueryParams';
import { sendResetPasswordEmail, resetPassword, setPassword } from 'src/services/Password';
import { LOGIN_PAGE } from 'src/constants/routes.constants';
import { ReactComponent as PortraitLogo } from 'src/assets/images/portrait-logo.svg';
import { EHRButton, EHRTextField } from 'src/components/ui/v1';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    minWidth: '473px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '90vw',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    gap: '1rem',
  },
  logoContainer: {
    width: '75%',
  },
}));

interface SendEmailFormData {
  email: string;
  password: string;
  confirmPassword: string;
}

interface UpdatePasswordFormData {
  password: string;
  confirmPassword: string;
}

const UpdatePassword = ({ text, mode }: { text: string; mode: string }) => {
  const classes = useStyles();
  const { queryParams } = useQueryParams();

  const history = useHistory();

  const token = queryParams.get('token');

  const { mutate: sendResetPasswordEmailMutation, isLoading: isSendingEmail } = useMutation({
    mutationFn: sendResetPasswordEmail,
    onError: () => {
      toast.error('An error was encounter sending the email, please try again later');
    },
    onSuccess: () => {
      toast.success('If there is an account with that email address, a reset link is on its way!');
    },
  });

  const { mutate: updatePasswordMutation, isLoading: isUpdatingPassword } = useMutation({
    mutationFn: (data: { password: string; token: string }) => {
      if (mode === 'reset') {
        return resetPassword(data);
      }
      return setPassword(data);
    },
    onError: () => {
      toast.error('Your password reset link is invalid');
    },
    onSuccess: () => {
      toast.success('Password successfully changed!');
      history.push(LOGIN_PAGE);
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleSendEmailSubmit = ({ email }: SendEmailFormData) => {
    sendResetPasswordEmailMutation({ email, redirectUrl: window.location.href });
  };

  const handleUpdatePassword = ({ password, confirmPassword }: UpdatePasswordFormData) => {
    if (password === confirmPassword) {
      if (token) {
        updatePasswordMutation({ password, token });
      } else {
        toast.error('Missing token!');
      }
    } else {
      toast.warn("Passwords don't match");
    }
  };

  if (token) {
    return (
      <Container component="main" className={classes.container}>
        <div className={classes.paper}>
          <div className={classes.logoContainer}>
            <PortraitLogo />
          </div>
          <form className={classes.form} onSubmit={handleSubmit(handleUpdatePassword)} autoComplete="off">
            <EHRTextField
              dataCy="input-password"
              inputRef={register}
              error={!isEmpty(errors.password)}
              helperText={errors.password?.message || ' '}
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
            />
            <EHRTextField
              dataCy="input-confirmPassword"
              inputRef={register}
              error={!isEmpty(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message || ' '}
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
            />
          </form>
          <EHRButton
            dataCy="btn-submit"
            fullWidth
            color="primary"
            disabled={isUpdatingPassword}
            onClick={handleSubmit(handleUpdatePassword)}
            text={isUpdatingPassword ? 'Loading...' : text}
          />
        </div>
      </Container>
    );
  }

  return (
    <Container component="main" className={classes.container}>
      <div className={classes.paper}>
        <div className={classes.logoContainer}>
          <PortraitLogo />
        </div>
        <Typography variant="body2">Enter your email address to receive a reset link.</Typography>
        <form className={classes.form} onSubmit={handleSubmit(handleSendEmailSubmit)} autoComplete="off">
          <EHRTextField
            dataCy="input-email"
            inputRef={register}
            error={!isEmpty(errors.email)}
            helperText={errors.email?.message || ' '}
            required
            fullWidth
            name="email"
            label="Email"
            type="email"
          />
        </form>
        <EHRButton
          dataCy="btn-sendEmail"
          fullWidth
          color="primary"
          disabled={isSendingEmail}
          onClick={handleSubmit(handleSendEmailSubmit)}
          text={isSendingEmail ? 'Loading...' : 'Send Email'}
        />
      </div>
    </Container>
  );
};

export default UpdatePassword;
