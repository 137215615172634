import * as yup from 'yup';
import moment from 'moment';
import {
  ACUITY_NAME,
  ACUITY_ADDRESS,
  ACUITY_TIMEZONE,
  EMAIL,
  VALID_EMAIL,
  ACUITY_DURATION,
  ACUITY_APPOINTMENT_TYPE_DESCRIPTION,
  ACUITY_APPOINTMENT_TYPE_NAME,
  ACUITY_AVAILABILITY_END,
  ACUITY_AVAILABILITY_START,
} from '../../../constants/schemaForm.constants';

export const accountDetailSchema = (belongsToGroup?: boolean) =>
  yup.object().shape({
    email: yup.string().required(EMAIL).email(VALID_EMAIL),
    timezone: yup.string().required(ACUITY_TIMEZONE),
    location: yup.string().required(ACUITY_ADDRESS),
    name: belongsToGroup ? yup.string() : yup.string().required(ACUITY_NAME),
  });
export const accountDetailDefaultValues = {
  email: '',
  timezone: -1,
  location: '',
  name: '',
};

export const appointmentTypeSchema = yup.object().shape({
  name: yup.string().required(ACUITY_APPOINTMENT_TYPE_NAME),
  description: yup.string().required(ACUITY_APPOINTMENT_TYPE_DESCRIPTION),
  duration: yup.number().positive().integer().min(1).required(ACUITY_DURATION),
  enablePreCareMessage: yup.boolean(),
  enablePostCareMessage: yup.boolean(),
  postCareMessage: yup.string(),
  preCareMessage: yup.string(),
  serviceGroups: yup.array(),
  services: yup.array(),
  private: yup.boolean(),
});

export const medspaAppointmentTypeSchema = yup.object().shape({
  name: yup.string().required(ACUITY_APPOINTMENT_TYPE_NAME),
  description: yup.string().required(ACUITY_APPOINTMENT_TYPE_DESCRIPTION),
  medspaLocations: yup.array().required('Locations is a required field'),
  locations: yup.array().required('Providers is a required field'),
  duration: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .positive()
    .integer()
    .min(1)
    .required(ACUITY_DURATION),
  enablePreCareMessage: yup.boolean(),
  preCareMessage: yup.string(),
  serviceGroups: yup.array(),
  services: yup.array(),
  hidden: yup.boolean(),
});

export const medspaAppointmentTypeDefaultValues = {
  name: '',
  description: '',
  duration: 30,
  enablePreCareMessage: false,
  preCareMessage: '',
  serviceGroups: [],
  services: [],
  medspaLocations: [],
  locations: [],
  private: false,
};

export const appointmentTypeDefaultValues = {
  name: '',
  description: '',
  duration: 30,
  enablePreCareMessage: false,
  enablePostCareMessage: false,
  postCareMessage: '',
  preCareMessage: '',
  serviceGroups: [],
  services: [],
  private: false,
};
export const patientNotificationSchema = yup.object().shape({
  smsRemainders: yup.boolean(),
  emailRemainders: yup.boolean(),
});

export const patientNotificationDefaultValues = {
  smsRemainders: false,
  emailRemainders: false,
};

// eslint-disable-next-line no-useless-escape
export const INPUT_REGEX = /^((\d{2}|\d{1}):\d{2}(am|pm))\-((\d{2}|\d{1}):\d{2}(am|pm))$/i;
export const DEFAULT_ACUITY_TIME_RANGE = '9:00am-5:00pm';
export const availabilityOverrideSchema = yup.object().shape({
  start: yup.string().required(ACUITY_AVAILABILITY_START),
  end: yup.string().required(ACUITY_AVAILABILITY_END),
  hourStart: yup.string(),
  hourEnd: yup.string(),
});

export const availabilityOverrideDefaultValues = {
  start: moment().format('YYYY-MM-DD'),
  end: moment().format('YYYY-MM-DD'),
  hourStart: '07:00',
  hourEnd: '20:00',
};

export const blockOffHoursDefaultValues = {
  start: moment().format('YYYY-MM-DD'),
  hourStart: '07:00',
  hourEnd: '20:00',
};

export const blockOffHoursSchema = yup.object().shape({
  start: yup.string().required(ACUITY_AVAILABILITY_START),
  hourStart: yup.string(),
  hourEnd: yup.string(),
});
