import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import portraitLogo from '../../assets/profile.png';

const useStyles = makeStyles(() => ({
  image: {
    width: '30px',
    height: '30px',
    borderRadius: '20px',
  },
  imageWithTextContainer: {
    alignItems: 'center',
  },
  imageText: {
    marginLeft: '7px',
  },
}));

export const ProfileImage = ({ src }: { src?: string }) => {
  const classes = useStyles();
  return <img src={src || portraitLogo} alt="Logo" className={classes.image} />;
};

export const ProfileImageWithText = ({ src, text }: { src?: string; text: string }) => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.imageWithTextContainer}>
      <img src={src || portraitLogo} alt="Logo" className={classes.image} />
      <div className={classes.imageText}>{text}</div>
    </Box>
  );
};
