import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  headerTitle: {
    marginTop: '0',
    marginBottom: '0',
    marginRight: '10px',
    fontWeight: 'bold',
  },
  subtitle: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '10px',
    marginBottom: '0px',
  },
  card: {
    margin: '15px',
    paddingBottom: '24px !important',
    padding: '24px 30px',
    background: '#F5F5F5',
    borderRadius: '9px',
    marginBottom: '10px',
    border: 'none',
  },
  headerCard: {
    alignItems: 'center',
  },
}));
