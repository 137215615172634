import React from 'react';
import { Dialog, CircularProgress, withStyles } from '@material-ui/core';

const OrderLoading: React.FC<{
  open?: boolean;
  classes?: { [key: string]: string };
}> = ({ open = true, classes }) => (
  <Dialog open={open} fullScreen PaperProps={{ className: classes?.paper }} data-testid="orderLoading">
    <CircularProgress size={275} />
  </Dialog>
);

export default withStyles({
  paper: {
    display: 'flex',
    margin: 0,
    backgroundColor: '#0000',
    '& div': { margin: 'auto' },
    '& svg': { color: 'rgba(252,243,226,0.8)' },
    '& circle': { strokeWidth: 1 },
  },
})(OrderLoading);
