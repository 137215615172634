import moment from 'moment';
import { IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';

export const isBetweenInclusive = (baseDate: moment.Moment, date1: moment.Moment, date2: moment.Moment) =>
  baseDate.isSameOrAfter(date1) && baseDate.isSameOrBefore(date2);

export const isWithinAWorkingHour = (workingHours: IWorkingHour[], startDate: Date, endDate: Date) =>
  workingHours?.length > 0 &&
  workingHours.reduce((withinAWorkingHourRange, workingHour) => {
    const { startDate: workStart, endDate: workEnd } = workingHour;
    const momentWorkStart = moment(workStart);
    const momentWorkEnd = moment(workEnd);
    const momentStart = moment(startDate);
    const momentEnd = moment(endDate);
    return (
      withinAWorkingHourRange ||
      (isBetweenInclusive(momentStart, momentWorkStart, momentWorkEnd) &&
        isBetweenInclusive(momentEnd, momentWorkStart, momentWorkEnd))
    );
  }, false);

export const capDatetimeSeconds = (date: Date | undefined): Date => {
  if (!date) {
    return new Date('');
  }
  const cappedDate = new Date(date);
  cappedDate.setSeconds(0);
  cappedDate.setMilliseconds(0);
  return cappedDate;
};
