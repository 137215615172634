import moment from 'moment';
import { concat, round, sortBy } from 'lodash';
import React, { useState } from 'react';
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
} from '@material-ui/core';
import { Whatshot as FlameIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { IAdCampaign } from 'src/interfaces/IAdCampaign';
import { useAdCampaigns } from 'src/hooks/queries/useAdCampaigns';
import { useStyles, TableCellHeader } from './reportsMedspa.styles';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import {
  EMPTY_CELL,
  REPORT_DATE_FORMAT,
  LEAD_REPORTS_DATE_RANGES,
  ALL_CAMPAIGNS,
  DEFAULT_DATE_INTERVAL,
  SHOW_PERCENT,
  PROVIDER_COLUMN_NAME,
  REPORT_COLUMNS,
  ALL_CAMPAIGN_ROW,
} from '../../../../constants/clientLead.constants';
import { useClientLeadReports } from '../../../../hooks/queries/useClientLeads';
import { SelectList as ReportList } from '../../../common/SelectList';
import { ProviderList } from './TableLeadsMedspa';
import { ILeadReportRow, DateIncrement } from '../../../../interfaces/IClientLead';

const findValue = (report: ILeadReportRow[] | undefined, range: string, columnKey: string): string => {
  const entry = (report || []).filter(
    (row: ILeadReportRow): boolean => moment(row.stage).format(REPORT_DATE_FORMAT) === range
  );
  const cellValue = entry.length > 0 ? entry[0][columnKey] : EMPTY_CELL;
  const pct =
    entry.length > 0 && columnKey in SHOW_PERCENT
      ? ` (${round(((+cellValue || 0) / (+entry[0][SHOW_PERCENT[columnKey]] || 1)) * 100)}%)`
      : '';

  return `${!!cellValue || cellValue === 0 ? String(cellValue) : EMPTY_CELL}${pct}`;
};

const LeadReportsMedspa: React.FC<{ practitionerView?: boolean; isMedspaAdmin?: boolean }> = ({
  practitionerView,
  isMedspaAdmin,
}: {
  practitionerView?: boolean;
  isMedspaAdmin?: boolean;
}): React.ReactElement => {
  const [practitionerId, setPractitionerId] = useState<string | undefined>();
  const [dateRange, setDateRange] = useState<DateIncrement | undefined>();
  const [campaign, setCampaign] = useState<string | undefined>();
  const customSelectStyles = {
    select: {
      height: '40px',
      padding: '10px',
    },
  };
  const focusPractitionerId = practitionerView || !practitionerId ? undefined : +practitionerId || undefined;
  const disabled = !practitionerView && !practitionerId;

  const { data: campaignList } = useAdCampaigns({
    practitionerId: focusPractitionerId,
    disabled,
  });

  const { data: reportData } = useClientLeadReports({
    practitionerId: focusPractitionerId,
    dateIncrement: dateRange,
    adCampaignId: !campaign || campaign === ALL_CAMPAIGNS ? undefined : +campaign,
    disabled: disabled || !campaign,
  });

  const { report, columns, ranges } = reportData || {};

  const classes = useStyles();

  const handleSetPractitionerId = async (newVal: string | null): Promise<void> => {
    if (campaign && campaign !== ALL_CAMPAIGNS) {
      setCampaign(ALL_CAMPAIGNS);
    }

    if (newVal) {
      setPractitionerId(newVal);
    }
  };

  const formattedCampaignList = Object.fromEntries(
    concat(
      [ALL_CAMPAIGN_ROW],
      campaignList?.map((adCampaign: IAdCampaign): [string, string] => [adCampaign.id.toString(), adCampaign.name]) ||
        []
    )
  );

  return (
    <Box className={classes.reportsMain} data-testid="lead reports main">
      {!practitionerView && !isMedspaAdmin && (
        <Box className={classes.reportsHeader}>
          <FlameIcon className={classes.userIcon} />
          <Typography className={classes.adCampaignsTitle} variant="h3">
            Lead Reports
          </Typography>
        </Box>
      )}
      <Paper className={classes.flexColumns} elevation={0}>
        <Box display="flex" padding={2}>
          <Box padding={2}>
            <ReportList
              customStyles={customSelectStyles}
              title="Date Range"
              listItems={LEAD_REPORTS_DATE_RANGES}
              className={classes.selectform}
              selected={dateRange || DEFAULT_DATE_INTERVAL}
              setSelected={(newRange: string | null): void => {
                setDateRange((newRange as DateIncrement) || undefined);
              }}
              loadSelected={true /* eslint-disable-line react/jsx-boolean-value */}
              variant="outlined"
            />
          </Box>
          <Box padding={2}>
            {campaignList ? (
              <ReportList
                customStyles={customSelectStyles}
                title="Campaign"
                listItems={formattedCampaignList}
                className={classes.selectform}
                selected={campaign?.toString() || ALL_CAMPAIGNS}
                setSelected={(newCampaign: string | null): void => {
                  setCampaign(newCampaign || ALL_CAMPAIGNS);
                }}
                loadSelected={true /* eslint-disable-line react/jsx-boolean-value */}
                variant="outlined"
              />
            ) : (
              <Skeleton variant="rect" width="100%" height={48} />
            )}
          </Box>
          <Box padding={2}>
            {!practitionerView && (
              <ProviderList
                selected={`${practitionerId}`}
                className={classes.channelSelect}
                setSelected={handleSetPractitionerId}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.noShadowShort}>
          <TableContainer className={classes.stickyTable}>
            <Table stickyHeader={true /* eslint-disable-line react/jsx-boolean-value */}>
              <TableHead>
                <TableRow className={classes.reportHeaderRow}>
                  <TableCellHeader />
                  {ranges?.map(
                    (range: string): React.ReactElement => (
                      <TableCellHeader key={range} data-testid={`table header ${range}`}>
                        {range}
                      </TableCellHeader>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {ranges ? (
                  sortBy(Object.entries(columns || {}), ([columnKey]: [string, string]): number =>
                    REPORT_COLUMNS.indexOf(columnKey)
                  ).map(
                    ([columnKey, columnName]: [string, string]): React.ReactElement => (
                      <TableRow className={classes.reportRow} key={columnKey}>
                        {columnKey !== PROVIDER_COLUMN_NAME && (
                          <>
                            <TableCell data-testid={`table row ${columnKey}`}>{columnName}</TableCell>
                            {ranges?.map(
                              (range: string): React.ReactElement => (
                                <TableCell key={columnKey + range} data-testid={`table cell ${columnKey} ${range}`}>
                                  {findValue(report, range, columnKey)}
                                </TableCell>
                              )
                            )}
                          </>
                        )}
                      </TableRow>
                    )
                  )
                ) : (
                  <MultipleSkeleton />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export { findValue, LeadReportsMedspa, LeadReportsMedspa as default };
