import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, FormControl, Chip } from '@material-ui/core';
import classnames from 'classnames';
import { EHRSelect, EHRSelectProps } from './EHRSelect';

const useStyles = makeStyles({
  ehrMultipleSelectContainer: {
    width: '100%',
    '& .MuiSelect-selectMenu': {
      height: 0,
      minHeight: '2rem',
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '2rem',
    },
  },
  ehrMultipleSelectLabel: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: '1rem',
    color: '#706F6F',
  },
  ehrMultipleSelectSelectedItem: {
    backgroundColor: '#12574d',
    color: 'white',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25rem',
    height: '100%',
    alignItems: 'center',
    overflow: 'auto',
  },
  chip: {
    height: '1rem',
    fontSize: '0.65rem',
  },
});

interface IOption {
  name: string;
  value: string | number | undefined;
}

export type EHRMultipleSelectOnChange = (values: IOption['value'][]) => void;

export type EHRMultipleSelectorProps = Omit<EHRSelectProps, 'onChange'> & {
  options?: IOption[];
  onChange?: EHRMultipleSelectOnChange;
  value: any[];
};

const EHRMultipleSelect = (props: EHRMultipleSelectorProps) => {
  const classes = useStyles();
  const { options, onChange, value, id, placeholder } = props;
  const [selectedOptions, setSelectedOptions] = React.useState<IOption['value'][]>(value || []);

  const handleChange: EHRMultipleSelectOnChange = (val) => {
    let newValues: IOption['value'][] = [];
    if (val.indexOf('') < 0) {
      newValues = [...val];
    }
    setSelectedOptions(newValues);
    onChange?.(newValues);
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  return (
    <FormControl className={classes.ehrMultipleSelectContainer}>
      <EHRSelect
        {...props}
        id={id}
        multiple
        fullWidth
        displayEmpty={!!placeholder}
        onChange={handleChange}
        renderValue={(values) => {
          const selected = values as IOption['value'][];
          if (selected === null || selected === undefined || selected.length === 0) {
            return (
              <div className={classes.chips}>
                <span className="ehrSelectPlaceholder">{placeholder}</span>
              </div>
            );
          }

          return (
            <div className={classes.chips}>
              {selected.map((val) => (
                <Chip
                  key={val}
                  label={options?.find((option) => option.value === val)?.name}
                  className={classes.chip}
                />
              ))}
            </div>
          );
        }}
      >
        {placeholder && (
          <MenuItem key="" value="">
            {placeholder}
          </MenuItem>
        )}
        {options?.map(({ name: optionName, value: optionValue }: IOption) => (
          <MenuItem
            key={optionValue}
            value={optionValue}
            className={classnames({ [classes.ehrMultipleSelectSelectedItem]: selectedOptions.includes(optionValue) })}
          >
            {optionName}
          </MenuItem>
        ))}
      </EHRSelect>
    </FormControl>
  );
};

export { EHRMultipleSelect };
