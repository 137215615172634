import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  consent: {
    padding: '10px 0',
    borderRadius: '4px',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.14,
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: '#12574d',
    display: 'block',
    marginBottom: '8px',
    textDecoration: 'none',
    width: '230px',
    margin: 'auto',
  },
  date: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.62,
    letterSpacing: 'normal',
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'center',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));
