import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  appointmentSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 13px',
    height: '50px'
  },
  icon: {
    opacity: '0.5'
  },
  titleSection: {
    display: 'flex',
    alignItems: 'flex-end',
    '& span': {
      marginLeft: '11px'
    }
  },
  title: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#000000'
  }
}));
