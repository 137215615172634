import React from 'react';
import moment from 'moment';

import { TableRow } from '@material-ui/core';
import { Pill } from '../../common/Pill';
import { ITransaction } from '../../../interfaces/ITransaction.interfaces';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { useStyles } from './transactions.styles';

export interface TransactionsRowProps {
  transaction: ITransaction;
}

const TransactionsRow: React.FC<TransactionsRowProps> = ({ transaction }: { transaction: ITransaction }) => {
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCellCustom>{transaction.checkoutId}</TableCellCustom>
        <TableCellCustom width="35%">{transaction.serviceVisitId}</TableCellCustom>
        <TableCellCustom>{transaction.customerId}</TableCellCustom>
        <TableCellCustom>{moment(transaction.dateCreatedAt).format('MM/DD/YYYY')}</TableCellCustom>
        <TableCellCustom>{transaction.timeCreatedAt}</TableCellCustom>
        <TableCellCustom>{transaction.practitioner}</TableCellCustom>
        <TableCellCustom>{transaction.eventType}</TableCellCustom>
        <TableCellCustom style={{ textAlign: 'right' }}>{transaction.total}</TableCellCustom>
        <TableCellCustom style={{ textAlign: 'right' }}>{transaction.discounts}</TableCellCustom>
        <TableCellCustom style={{ textAlign: 'right' }}>{transaction.netSales}</TableCellCustom>
        <TableCellCustom style={{ textAlign: 'right' }}>{transaction.otherTendersTotal}</TableCellCustom>
        <TableCellCustom style={{ textAlign: 'right' }}>{transaction.squareTransactionAmount}</TableCellCustom>
        <TableCellCustom>{transaction.significantSquareTransactionId}</TableCellCustom>
        <TableCellCustom>{transaction.note}</TableCellCustom>
        <TableCellCustom>
          {transaction.processedInSquare && (
            <a href={transaction.details} target="_blank" rel="noreferrer" className={classes.link}>
              <Pill title="Square" color="gray" style={{ cursor: 'pointer' }} />
            </a>
          )}
        </TableCellCustom>
        <TableCellCustom>
          <a href={transaction.ehrUrl} target="_blank" rel="noreferrer" className={classes.link}>
            <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />
          </a>
        </TableCellCustom>
      </TableRow>
    </>
  );
};

export default TransactionsRow;
