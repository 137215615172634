import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardName: {
    display: 'flex',
    alignItems: 'center',
  },
  cardIcon: {
    position: 'relative',
    top: '8px',
    marginLeft: '6px',
  },
  selectCardsContainer: {
    marginTop: '20px',
    position: 'relative',
  },
  checkoutButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    width: '300px',
    padding: '12px',
    borderRadius: '5px',
    margin: '1rem 0',
    textAlign: 'center',
    textTransform: 'none',
    '&:disabled': {
      color: 'white',
      opacity: '50%',
    },
    '&:hover': {
      background: '#12574D',
    },
  },
  processing: {
    color: 'white',
    marginLeft: theme.spacing(2),
    position: 'relative',
    top: '4px',
  },
  acknowledgeConsent: {
    maxWidth: '550px',
  },
  buttonOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    zIndex: 99,
  },
}));
