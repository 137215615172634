import React from 'react';
import { withStyles, FormControlLabel, Checkbox } from '@material-ui/core';

const CustomFormControlLabel = withStyles(() => ({
  root: {
    padding: '10px',
  },
  label: {
    fontSize: '20px',
    letterSpacing: '-0.07px',
    color: '#393B3F',
    marginLeft: '15px',
  },
}))(FormControlLabel);

interface Props {
  label: string;
  onChange: () => void;
  disabled: boolean;
  checked: boolean;
}

export const DiscountItem = ({ label, onChange, disabled, checked }: Props) => (
  <CustomFormControlLabel
    label={label}
    control={<Checkbox onChange={onChange} color="primary" disabled={disabled} checked={checked} />}
  />
);
