import React from 'react';
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import { styled } from '@material-ui/core';
import classNames from 'classnames';
import { groupingStyles } from './groupingStyles';

// @ts-expect-error
const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(groupingStyles);

const WeekViewDayScaleCell = ({ groupingInfo, ...restProps }: WeekView.DayScaleCellProps) => {
  const groupId = groupingInfo?.[0].id;
  return (
    <StyledWeekViewDayScaleCell className={classNames(`group-${groupId}`)} groupingInfo={groupingInfo} {...restProps} />
  );
};

export { WeekViewDayScaleCell };
