import React from 'react';
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { ButtonStatus } from '../../common/TableStyles/ButtonStatus';
import ButtonAhref from '../../common/ButtonAhref';
import { SUPPLY_RECEIPT_STATUS } from '../../../constants/general.constants';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const handleSupplyReceiptsStatus = (status: string, href: string) => {
  switch (status) {
    case 'pending':
      return (
        <ButtonStatus href={href} style={{ backgroundColor: '#e7e7e7' }}>
          Pending Signature
        </ButtonStatus>
      );
    case 'draft':
      return (
        <ButtonStatus href={href} style={{ backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
          Saved Draft
        </ButtonStatus>
      );
    case 'closed':
      return <ButtonStatus style={{ background: '#d1f1bd', cursor: 'default' }}>Closed</ButtonStatus>;
    default:
      return '';
  }
};

function SupplyReceiptsRow(props: any) {
  const { nurseName, date, closedDate, status, id } = props;
  const classes = useRowStyles();

  return (
    <>
      <TableRow data-cy="row" className={classes.root} style={{ backgroundColor: 'white' }}>
        <TableCellCustom data-cy="nurseName" width="200">
          {nurseName}
        </TableCellCustom>
        <TableCellCustom>
          <Box data-cy="creationDate" color="#000" component="span">
            {date}
          </Box>
        </TableCellCustom>
        <TableCellCustom>
          {status === SUPPLY_RECEIPT_STATUS.CLOSED && (
            <Box data-cy="closedDate" color="#000" component="span">
              {closedDate}
            </Box>
          )}
        </TableCellCustom>
        <TableCell data-cy="status">
          {handleSupplyReceiptsStatus(status, `/administrator/new-supply-receipt-step-1/${id}`)}
        </TableCell>
        <TableCell>
          <ButtonAhref text="VIEW RECEIPT" href={`/administrator/new-supply-receipt-step-1/${id}`} />
        </TableCell>
      </TableRow>
    </>
  );
}

export default SupplyReceiptsRow;
