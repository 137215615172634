import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dialog: {
    borderRadius: '10px',
    padding: '20px',
  },
  title: {
    fontSize: '18px',
    color: '#12574D',
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
}));
export default useStyles;
