import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import InfiScroller from 'react-infi-scroller';
import { debounce } from 'lodash';
import { queryClient } from 'src/initializers/queryClient';
import {
  PORTRAIT_FLEX,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  PORTRAIT_LITE,
  PORTRAIT_LUXE,
  USER_TYPES,
} from 'src/constants/general.constants';
import { IUserGroupListParams } from 'src/interfaces/IUserGroup';
import { IRoles } from 'src/interfaces/IRoles.interfaces';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import IMedspa from 'src/interfaces/IMedspa.interface';
import { IMedspaAdminListEntry } from 'src/interfaces/IMedspaAdminList';
import { useUserGroupInfinity, useUserGroupInfinityCount } from 'src/hooks/queries/useUserGroups';
import { useServicesCommissions } from 'src/hooks/queries/useCommissions';
import { useCustomRoles } from 'src/hooks/queries/useCustomRoles';
import Page from 'src/components/common/Page';
import { GUTTER, HEIGHT_BREAKPOINT } from 'src/components/common/TableNew/Table';
import { MultipleSkeleton } from 'src/components/common/LoadingSkeleton';
import InputSearch from 'src/components/common/InputSearch';
import { EHRButton, EHRSelect } from 'src/components/ui/v1';
import { useQuery } from 'react-query';
import { USER_GROUPS_INFINITY } from 'src/constants/reactQuery.keys';
import { getFeaturesByRole } from 'src/services/Features';
import { hasAccessTo } from 'src/utils/auth.utils';
import { useSelector } from 'react-redux';
import { CREATE_PRACTITIONER } from 'src/constants/actions.constants';
import { REGISTRATION_LITE_MEDSPA_SIGN_UP_PAGE } from 'src/constants/apiRoutes.constants';
import InvitationModal from 'src/components/InvitationModal';
import { dispatch } from 'src/rematch';
import toastMessagesCompiler from 'src/utils/toastMessagesCompiler';
import { useCreateMedspaAdminInvitation } from 'src/hooks/mutations/medspaAdmins/useCreateInvitation';
import { NewMedSpaModal } from './NewMedSpaModal';
import { NewMedspaUserModal } from './NewMedspaUserModal';
import { EditMedspaModal } from './EditMedspaModal';
import { EditMedspaUserModal } from './EditMedspaUserModal';
import { MedspaInfoCard } from './MedspaInfoCard';
import { useStyles } from './index.styles';

const initialParams: IUserGroupListParams = {
  search: '',
  status: '',
  sort: 'created_at',
  sortDirection: 'desc',
  roleName: '',
  page: 1,
};

const PRODUCT_TYPE_OPTIONS = {
  '': 'All Product Types',
  [PORTRAIT_LITE]: PORTRAIT_LITE,
  [PORTRAIT_FLEX]: PORTRAIT_FLEX,
  [PORTRAIT_LUXE]: PORTRAIT_LUXE,
  [PORTRAIT_LEGACY]: PORTRAIT_LEGACY,
  [PORTRAIT_LEGACY_PLUS]: PORTRAIT_LEGACY_PLUS,
};

const Medspas = () => {
  const classes = useStyles();
  const refComponent = useRef(null);
  const { permissions } = useSelector(({ auth }: any) => auth);

  const [searchValue, setSearchValue] = useState<string>('');
  const [medspaRole, setMedspaRole] = useState<string>('');
  const [medspaStatus, setMedspaStatus] = useState<string>('');
  const [selectedMedspa, setSelectedMedspa] = useState<IMedspa>();
  const [invitationToken, setInvitationToken] = useState<string>();
  const [selectedEditUserType, setSelectedEditUserType] = useState<string>();
  const [selectedEditSubUser, setSelectedEditSubUser] = useState<IPractitioner>();
  const [newMedspaOpen, setNewMedspaOpen] = useState<boolean>(false);
  const [editMedspaOpen, setEditMedspaOpen] = useState<boolean>(false);
  const [newUserModalOpen, setNewUserModalOpen] = useState<boolean>(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState<boolean>(false);
  const [showInvitationModal, setShowInvitationModal] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<IUserGroupListParams>(initialParams);
  const [selectedEditUser, setSelectedEditUser] = useState<IPractitioner | IMedspaAdminListEntry>();

  const { data: userGroupCount = 0 } = useUserGroupInfinityCount(queryParams);

  const { mutateAsync: createInvitation } = useCreateMedspaAdminInvitation();

  const {
    data: { userGroupList = [] },
    isFetching,
    isLoading,
    fetchNextPage,
  } = useUserGroupInfinity(queryParams, userGroupCount);
  const { data: roles = [] as IRoles[] } = useCustomRoles({
    userType: USER_TYPES.PRACTITIONER,
  });
  const { data: commissions = [] } = useServicesCommissions();
  const { data: featuresByRole, isLoading: isLoadingFeaturesByRole } = useQuery(['FEATURES_BY_ROLE', 1], () =>
    getFeaturesByRole()
  );

  const debounceSearchCall = (query: string, params: IUserGroupListParams) => {
    const userGroupParams = { ...params, search: query };
    setQueryParams(userGroupParams);
  };

  const doSearch = useMemo(
    () => debounce((query: string, params: IUserGroupListParams) => debounceSearchCall(query, params), 1000),
    []
  );

  const hasNextPage = userGroupList.length < userGroupCount;
  const medspaFeatures = featuresByRole?.userGroup || [];
  const isLargeScreen = window.innerHeight > HEIGHT_BREAKPOINT;

  const invitationUrl = `${window.location.origin}${REGISTRATION_LITE_MEDSPA_SIGN_UP_PAGE}?code=${invitationToken}`;

  const getGutterValue = () => (isLargeScreen ? GUTTER.L : GUTTER.S);

  const onInputChange = (event: any) => {
    const newSearchQuery = event.target.value;
    setSearchValue(newSearchQuery);
    doSearch(newSearchQuery, queryParams);
  };

  const handleEditMedspa = (medspa: IMedspa) => {
    setSelectedMedspa(medspa);
    setEditMedspaOpen(true);
  };

  const handleEditUser = (user: IPractitioner | IMedspaAdminListEntry, userType: string, subUser?: IPractitioner) => {
    setSelectedEditUser(user);
    setSelectedEditUserType(userType);
    setSelectedEditSubUser(subUser);
    setEditUserModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setInvitationToken(undefined);
    setShowInvitationModal(false);
  };

  const generateInvitationLink = async (email: string) => {
    const { success, data } = await createInvitation(email);
    if (success) {
      setInvitationToken(data.token);
      setShowInvitationModal(true);
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: toastMessagesCompiler('generic.error_message', {
            action: 'creating',
            element: 'invitation link',
          }),
          type: 'error',
        },
      });
    }
  };

  useEffect(() => {
    setQueryParams({ ...queryParams, ...{ status: medspaStatus, roleName: medspaRole } });
  }, [medspaStatus, medspaRole]);

  useEffect(
    () => () => {
      queryClient.invalidateQueries(['FEATURES_BY_ROLE', 1]);
      queryClient.removeQueries({ predicate: (query) => query.queryHash.indexOf(USER_GROUPS_INFINITY) > -1 });
    },
    []
  );

  if (isLoadingFeaturesByRole) {
    return (
      <Page title="MedSpas">
        <MultipleSkeleton />
      </Page>
    );
  }

  return (
    <Page title="MedSpas">
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" flexWrap="wrap" gridGap="1rem" marginBottom="1.5rem">
          <Box display="flex" flexWrap="wrap" gridGap="1rem">
            <Box display="flex" flexWrap="wrap" gridGap="1rem" className={classes.medspaHeaderSearchContainer}>
              <InputSearch
                placeholder="Search..."
                onChangeValue={onInputChange}
                value={searchValue}
                isLoading={isLoading}
              />
            </Box>
            <Box data-cy="medspaProductTypeSelect" className={classes.medspaHeaderProductTypeContainer}>
              <EHRSelect
                dataCy="select-medspa-role"
                displayEmpty
                onChange={(value: string | number) => setMedspaRole(value as string)}
                value={medspaRole}
                fullWidth
              >
                {Object.entries(PRODUCT_TYPE_OPTIONS).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </EHRSelect>
            </Box>
            <Box data-cy="medspaStatusSelect" className={classes.medspaHeaderStatusContainer}>
              <EHRSelect
                displayEmpty
                dataCy="select-medspa-status"
                onChange={(value: string | number) => setMedspaStatus(value as string)}
                value={medspaStatus}
                fullWidth
              >
                <MenuItem value="">All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </EHRSelect>
            </Box>
          </Box>
          <Box display="flex" gridGap="1rem" flexWrap="wrap">
            {hasAccessTo(CREATE_PRACTITIONER, permissions) && (
              <EHRButton
                dataCy="create-invite-link"
                text="Create Invite Link"
                color="primary"
                onClick={() => setShowInvitationModal(true)}
              />
            )}

            <EHRButton dataCy="add-user" color="primary" onClick={() => setNewUserModalOpen(true)} text="Add User" />

            <EHRButton dataCy="add-medspa" color="primary" onClick={() => setNewMedspaOpen(true)} text="New MedSpa" />
          </Box>
        </Box>

        <div ref={refComponent} className={classes.infiScrollContainer}>
          <InfiScroller
            active={hasNextPage}
            hasMore={hasNextPage}
            scrollTarget={refComponent.current}
            onLoadMore={fetchNextPage}
            gutter={getGutterValue()}
            shouldLoadMore={(scrollTargetHeight: any, scrollYOffset: any, gutter: any, scrollHeight: any) => {
              const shouldLoadMore: Boolean = scrollTargetHeight + scrollYOffset + gutter >= scrollHeight;
              return shouldLoadMore;
            }}
          >
            {isLoading ? (
              <MultipleSkeleton />
            ) : (
              <>
                {userGroupList.map((userGroup) => (
                  <MedspaInfoCard
                    medspaFeatures={medspaFeatures}
                    medspa={userGroup}
                    handleEditMedspa={handleEditMedspa}
                    handleEditUser={handleEditUser}
                  />
                ))}
                {isFetching && <MultipleSkeleton addPosition={false} />}
              </>
            )}
          </InfiScroller>
        </div>
        <NewMedSpaModal
          open={newMedspaOpen}
          onClose={() => setNewMedspaOpen(false)}
          roles={roles}
          commissions={commissions}
        />
        <NewMedspaUserModal open={newUserModalOpen} onClose={() => setNewUserModalOpen(false)} />
        <EditMedspaModal
          medspa={selectedMedspa}
          open={editMedspaOpen}
          onClose={() => setEditMedspaOpen(false)}
          commissions={commissions}
        />
        <EditMedspaUserModal
          open={editUserModalOpen}
          onClose={() => setEditUserModalOpen(false)}
          user={selectedEditUser}
          userType={selectedEditUserType}
          operatingUser={selectedEditSubUser}
        />

        <InvitationModal
          open={showInvitationModal}
          onClose={handleCloseInviteModal}
          invitationToken={invitationToken}
          invitationUrl={invitationUrl}
          generateInvitationLink={generateInvitationLink}
          showEmailInput
        />
      </Box>
    </Page>
  );
};

export default Medspas;
