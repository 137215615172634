/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable curly */
import apiRoutesConstants from '../constants/apiRoutes.constants';
import { BandwidthConfig, SaveNetworkDataParams } from '../interfaces/bandwidth.interface';
import Api from './Api';

export const getBandwidthConfig = async (): Promise<BandwidthConfig> => Api.get(apiRoutesConstants.BANDWIDTH_CONFIG);

export const saveNetworkData = (data: SaveNetworkDataParams) =>
  Api.post(apiRoutesConstants.SAVE_NETWORK_DATA_URL, data);
