import React, { useEffect, useState } from 'react';
import { Box, CardContent, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Edit } from '@material-ui/icons';
import { ShortMultipleSkeleton } from 'src/components/common/LoadingSkeleton';
import { useTypographyStyles } from 'src/styles/typography.styles';
import { PATIENT_CURRENT_BALANCE } from 'src/constants/reactQuery.keys';
import Patients from 'src/services/Patients';
import { useQuery } from 'react-query';
import { Card, Title } from '../../common/card';
import { useStyles } from './ServiceCredits.styles';
import { IServiceCredit } from '../../../types/ServiceCredits';
import { RootState, dispatch } from '../../../rematch';
import ServicesModals, { FormData } from './ServicesModals';
import moduleClasses from './serviceModal.module.css';
import { useServices } from '../../../hooks/queries/services/useServices';
import { hasAccessTo } from '../../../utils/auth.utils';
import { IS_SUPPORT } from '../../../constants/actions.constants';

interface Props {
  permissions: string[];
  patientId: string;
}

const initialServiceData = {
  serviceId: undefined,
  quantity: 0,
};

const ServiceCredits = ({ permissions, patientId }: Props) => {
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();
  const { credits, isLoadingServiceCredits } = useSelector(({ serviceCredits }: RootState) => serviceCredits);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isAccountCredits, setIsAccountCredits] = useState(false);
  const [serviceData, setServiceData] = useState<FormData>(initialServiceData);
  const { data: services } = useServices(+patientId);
  const { data: currentBalance } = useQuery(
    [PATIENT_CURRENT_BALANCE, patientId],
    () => Patients.getCurrentBalance(patientId),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (patientId) {
      dispatch.serviceCredits.fetchCredits({ customerId: +patientId });
    }

    return () => {
      dispatch.serviceCredits.resetCredits();
    };
  }, [patientId]);

  const isSupport = hasAccessTo(IS_SUPPORT, permissions);

  const titleComponent = (
    <Box>
      <Title title="Credits" className={typographyClasses.patientProfileCardTitle} />
    </Box>
  );

  if (isLoadingServiceCredits) {
    return (
      <Card>
        <CardContent>
          {titleComponent}
          <ShortMultipleSkeleton length={3} />
        </CardContent>
      </Card>
    );
  }

  if (!isSupport && !isLoadingServiceCredits && credits.length === 0 && currentBalance === 0) {
    return (
      <Card>
        <CardContent>
          {titleComponent}
          <Typography variant="h6" className={classes.zeroCredits}>
            This patient has no credits
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        {titleComponent}
        <Box>
          <table className={classes.creditsTable}>
            <thead>
              <tr className={classes.creditsRow}>
                <th className={classes.creditsHeader}>Credit Type</th>
                <th className={classes.creditsHeader}>Balance</th>
                {isSupport && <th className={classes.creditsHeader}>Actions</th>}
              </tr>
            </thead>
            <tbody>
              <tr key={0} className={classes.creditsRow}>
                <td className={classnames(classes.creditsData)}>Account credit</td>
                <td className={classnames(classes.creditsData)}>${currentBalance}</td>
                {isSupport && currentBalance !== undefined && (
                  <td className={classnames(classes.creditsData)}>
                    <button
                      className={moduleClasses.closeModalButton}
                      onClick={() => {
                        setIsAccountCredits(true);
                        setIsEditing(true);
                        setServiceData({
                          serviceId: services.find((s) => s.name === 'Account Credit')?.id,
                          quantity: currentBalance,
                        });
                        setOpenServiceModal(true);
                      }}
                      type="button"
                    >
                      <Edit />
                    </button>
                  </td>
                )}
              </tr>
              {credits.map((credit: IServiceCredit) => (
                <tr key={credit.id} className={classes.creditsRow}>
                  <td className={classnames(classes.creditsData)}>{credit.serviceName}</td>
                  <td className={classnames(classes.creditsData)}>
                    {credit.balance} {credit.label}
                  </td>
                  {isSupport && (
                    <td className={classnames(classes.creditsData)}>
                      <button
                        className={moduleClasses.closeModalButton}
                        onClick={() => {
                          setIsAccountCredits(false);
                          setIsEditing(true);
                          setServiceData({ serviceId: credit.serviceId, quantity: credit.balance });
                          setOpenServiceModal(true);
                        }}
                        type="button"
                      >
                        <Edit />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>

        {isSupport && (
          <>
            <button
              className={moduleClasses.outsideAddButton}
              type="button"
              onClick={() => {
                setIsAccountCredits(false);
                setIsEditing(false);
                setServiceData(initialServiceData);
                setOpenServiceModal(true);
              }}
            >
              Add new Service Credit
            </button>
            <ServicesModals
              isOpenModal={openServiceModal}
              isEditing={isEditing}
              isAccountCredits={isAccountCredits}
              onClose={() => setOpenServiceModal(false)}
              service={serviceData}
              setService={setServiceData}
              services={services}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ServiceCredits;
