import React, { useState, FormEvent } from 'react';
import _ from 'lodash';
import { useQueryClient, useMutation } from 'react-query';
import { CardContent } from '@material-ui/core';
import { LIMIT_INITIAL_CUSTOMER_NOTE } from '../../../../constants/general.constants';
import { dispatch } from '../../../../rematch';
import { PatientProfileNote } from '../../../common/PatientProfileNote';
import { useCustomContactInfo } from '../../../../hooks/queries/useCustomContactInfo';
import compile from '../../../../utils/toastMessagesCompiler';
import Patients from '../../../../services/Patients';
import { MEDICAL_HISTORY_ACK } from '../../../../constants/reactQuery.keys';
import { IMHANote } from '../../../../interfaces/IMedicalHANote';
import { ShortMultipleSkeleton } from '../../../common/LoadingSkeleton';
import { Card } from '../../../common/card';

const SUCCESS_CREATED_MHANOTE = compile('generic.success_message', {
  action: 'added',
  element: 'Medical history acknowledgement',
});
const SUCCESS_REMOVING_MHANOTE = compile('generic.success_message', {
  action: 'removed',
  element: 'Medical history acknowledgement note',
});
const ERROR_CREATED_MHANOTE = compile('generic.error_message', {
  action: 'adding',
  element: 'the medical history acknowledgement note',
});
const ERROR_REMOVING_MHANOTE = compile('generic.error_message', {
  action: 'removing',
  element: 'the medical history acknowledgement note',
});

const { createMHANote, removeMHANote } = Patients;

type Props = {
  patientId: string;
  medicalHistoryAckCollection: IMHANote[];
  isLoading: boolean;
};

const MedicalHistoryAcknowledgements: React.FC<Props> = ({
  patientId,
  medicalHistoryAckCollection,
  isLoading,
}: Props) => {
  const queryClient = useQueryClient();

  const [medicalHistoryNote, setMedicalHistoryNote] = useState<string>('');
  const [showMoreMHANotes, setMoreMHANotes] = useState<boolean>(false);

  const { data: patientData } = useCustomContactInfo(patientId.toString());

  const medicalHAData = _.orderBy(medicalHistoryAckCollection, ['updatedAt'], ['desc']).filter(
    ({ physicianId }: any) => physicianId
  );

  const mutationMHACreate = useMutation(createMHANote);
  const mutationMHADelete = useMutation(removeMHANote);

  const clearMHANotes = () => {
    setMedicalHistoryNote('');
  };

  const viewMoreMHANotes = () => {
    setMoreMHANotes(!showMoreMHANotes);
  };

  const addMedicalHistoryAck = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      customerId: +patientId,
      physicianId: patientData?.physician.id || 0,
      text: medicalHistoryNote,
    };
    if (medicalHistoryNote.trim() !== '') {
      try {
        await mutationMHACreate.mutateAsync(data);
        queryClient.invalidateQueries([MEDICAL_HISTORY_ACK, patientId]);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: SUCCESS_CREATED_MHANOTE,
            type: 'success',
            duration: 1000,
          },
        });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: ERROR_CREATED_MHANOTE,
            type: 'error',
            duration: 1000,
          },
        });
      } finally {
        clearMHANotes();
      }
    }
  };

  const deleteMHANote = async (noteId: number) => {
    try {
      await mutationMHADelete.mutateAsync({
        id: noteId,
      });
      queryClient.invalidateQueries([MEDICAL_HISTORY_ACK, patientId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_REMOVING_MHANOTE,
          type: 'success',
          duration: 1000,
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_REMOVING_MHANOTE,
          type: 'error',
          duration: 1000,
        },
      });
    }
  };

  if (isLoading) {
    return (
      <Card>
        <CardContent style={{ padding: '12px 15px' }}>
          <ShortMultipleSkeleton length={5} />
        </CardContent>
      </Card>
    );
  }

  const notesLengthMHANotes = showMoreMHANotes ? medicalHistoryAckCollection.length : LIMIT_INITIAL_CUSTOMER_NOTE;

  return (
    <PatientProfileNote
      title="Doctor & Admin notes"
      contentPropName="text"
      subtitle="Medical history acknowledgment notes"
      onSubmit={addMedicalHistoryAck}
      disabled={mutationMHACreate.isLoading}
      inputValue={medicalHistoryNote}
      onChangeValue={setMedicalHistoryNote}
      notesLength={notesLengthMHANotes}
      onDelete={deleteMHANote}
      viewMoreState={showMoreMHANotes}
      viewMoreAction={viewMoreMHANotes}
      data={medicalHAData}
    />
  );
};

export default MedicalHistoryAcknowledgements;
