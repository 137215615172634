import { identity, pickBy } from 'lodash';
import { useQuery, useMutation, useQueryClient, UseQueryResult } from 'react-query';
import { AD_CAMPAIGNS } from 'src/constants/reactQuery.keys';
import { IAdCampaign, IAdCampaignLookup, IAdCampaignUpdate } from 'src/interfaces/IAdCampaign';
import AdCampaign from 'src/services/AdCampaign';

const useAdCampaigns = ({ disabled, ...apiParams }: IAdCampaignLookup): UseQueryResult<IAdCampaign[]> =>
  useQuery<IAdCampaign[]>({
    queryKey: [AD_CAMPAIGNS, apiParams.practitionerId, apiParams.page],
    queryFn: (): Promise<IAdCampaign[]> => AdCampaign.getAdCampaigns(pickBy(apiParams, identity)),
    enabled: !disabled,
  });

const useCreateAdCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (adCampaign: IAdCampaignUpdate): Promise<void> => {
      await AdCampaign.createAdCampaign(adCampaign);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [AD_CAMPAIGNS] });
    },
  });
};

export { useCreateAdCampaign, useAdCampaigns, useAdCampaigns as default };
