import React, { useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Checkbox, CircularProgress, FormControlLabel, Modal } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Button } from '../../common/Button';
import { useStyles } from './PhotosConsentModal.styles';

export const PhotosConsentModal = ({
  isOpen,
  onClose,
  onSign,
  deidentifiedConsent,
  onChangeConsent,
  signature,
  patientNameProp,
  handleOpenAgreementModal,
}: any) => {
  let signCanvas: any;
  const classes = useStyles();
  const [patientName, setPatientName] = useState(patientNameProp);
  const [isSigned, setIsSigned] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPatientName(patientNameProp);
  }, [patientNameProp]);

  const clearSignature = () => {
    setIsSigned(false);
    signCanvas.clear();
  };

  const signConsent = async () => {
    setLoading(true);
    const file = signCanvas.getTrimmedCanvas().toDataURL('image/png');
    await onSign(file, patientName, !!signature);
    setIsSigned(false);
    setLoading(false);
    handleClose();
  };

  const onBeginStroke = () => {
    setIsSigned(true);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div style={{ backgroundColor: '#e7eeed', height: '100%', overflowY: 'scroll' }}>
        <div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          <div className={classes.contentContainer}>
            <div className={classes.secondaryTitle}>Consent</div>
            <FormControlLabel
              label="Please use de-identified versions of my image only"
              control={
                <Checkbox
                  color="primary"
                  checked={deidentifiedConsent ?? false}
                  onChange={() => onChangeConsent('deidentifiedConsent')}
                />
              }
            />
            <Button
              className={classes.consentFormButton}
              title="PHOTO CONSENT AGREEMENT"
              onClick={handleOpenAgreementModal}
            />
            <div className={classes.secondaryTitle}>Signature of Patient or Guardian</div>
            <div data-cy="signatureCanvas" className={classes.consentSignature}>
              <div className={classes.signatureTitle}>Signature of Patient</div>
              <SignatureCanvas
                canvasProps={{ className: classes.consentSignatureCanvas }}
                ref={(ref) => {
                  signCanvas = ref;
                }}
                onBegin={onBeginStroke}
                clearOnResize={false}
              />
            </div>
            <div className={classes.secondaryTitle}>Patient Name</div>
            <div style={{ width: '646px', margin: '0 auto' }}>
              <input
                type="text"
                value={patientName}
                className={classes.patientName}
                onChange={(e) => setPatientName(e.currentTarget.value)}
              />
            </div>
            <div className={classes.consentButtonsContainer}>
              <Button className={classes.clearSignatureButton} title="CLEAR SIGNATURE" onClick={clearSignature} />
              <Button
                className={classes.doneSigningButton}
                title={
                  loading ? (
                    <>
                      PROCESSING <CircularProgress size={20} className={`${classes.colorWhite} ${classes.ml2}`} />
                    </>
                  ) : (
                    'DONE SIGNING'
                  )
                }
                onClick={signConsent}
                disabled={!isSigned || !patientName || loading}
                style={{ color: !isSigned ? 'white' : undefined }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
