import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { INVENTORY_NOTIFICATIONS } from '../../constants/reactQuery.keys';
import IInventoryNotifications from '../../interfaces/IInventoryNotification';
import { getInventoryNotifications } from '../../services/Inventory';

export function useInventoryNotifications(enabled?: boolean): IInventoryNotifications {
  const initialData = {
    hasProductsAtRisk: false,
    hasPendingReconciliationReports: false,
    hasPendingSupplyReceipts: false,
    hasLessThan8Hyaluronidase: false,
  };

  const { userId } = useSelector(({ auth }: any) => auth);

  const { data } = useQuery([INVENTORY_NOTIFICATIONS, userId], () => getInventoryNotifications(), {
    initialData,
    enabled,
  });

  return data || initialData;
}

export { INVENTORY_NOTIFICATIONS } from '../../constants/reactQuery.keys';
