import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  cardContainer: {
    width: '308px',
    height: '170px',
    border: '1px solid #B4B4B4',
    borderRadius: '8px',
    margin: '1rem 1rem 1rem 0',
    position: 'relative',
  },
  cardInfo: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardLogo: {
    width: '40px',
    position: 'relative',
    top: '-5px',
    display: 'inline-block',
  },
  textRight: {
    textAlign: 'right',
  },
  cardNumber: {
    margin: 0,
    fontSize: '18px',
  },
  cardActions: {
    position: 'absolute',
    height: '50px',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #B4B4B4',
  },
  cardButton: {
    color: '#12574D',
    background: 'none',
    fontSize: '12px',
  },
  cardDefault: {
    color: '#12574D',
    background: '#E7EEED',
    padding: '4px 10px',
    borderRadius: '8px',
  },
}));
