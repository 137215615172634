/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Chip, FormControlLabel, IconButton, Radio, TextField, Tooltip } from '@material-ui/core';
import { Help as HelpCenterIcon } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useStyles } from './ServiceVisitCheckoutContainer.styles';
import { formatNumber } from '../../utils/checkout.utils';
import {
  ALLE_WITH_CHERRY_TENDER,
  ASPIRE_TENDER,
  BRILLIANT_TENDER,
  CARE_CREDIT_TENDER,
  PATIENTFI_TENDER,
  SQUARE_STATUS,
  TENDER_TYPE_NAMES,
  XPERIENCE_TENDER,
} from '../../constants/checkout.constants';
import { ISquareCard } from '../../interfaces/ISquareCard.interfaces';
import CreditCardIcon from '../../components/PatientProfile/CreditCards/CreditCardIcon';
import { IPatientBasicInfo } from '../../types/Patient';
import { ServiceVisitCheckoutSummaryModal } from './ServiceVisitCheckoutSummaryModal';
import { ILineItem } from '../../interfaces/IServiceVisits';
import { ICheckout } from '../../interfaces/checkout.interfaces';
import { ReactComponent as SuccessIcon } from '../../assets/images/payment-success.svg';

export const TENDER_TYPE_TO_SHOW = [
  BRILLIANT_TENDER,
  ALLE_WITH_CHERRY_TENDER,
  CARE_CREDIT_TENDER,
  XPERIENCE_TENDER,
  ASPIRE_TENDER,
  PATIENTFI_TENDER,
];

export const CASH_CHARGE_TYPE = 'cash';
export const SQUARE_CHARGE_TYPE = 'square';
export const CARD_CHARGE_TYPE = 'card';

const PaymentOptions = ({
  chargeType,
  setChargeType,
  cashAmount,
  setCashAmount,
  total,
  cards,
  selectedCard,
  setSelectedCard,
  patientId,
}: {
  chargeType: string;
  setChargeType: (chargeType: string) => void;
  cashAmount: string | undefined;
  setCashAmount: (chargeType: string) => void;
  total: number;
  cards: ISquareCard[];
  selectedCard: ISquareCard | undefined;
  setSelectedCard: (card: any) => void;
  patientId: number;
}) => {
  const styles = useStyles();
  const history = useHistory();

  const onCardSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cardId = e.target.value;
    const userSelectedCard = cards.find((card) => card.id === cardId);
    setSelectedCard(userSelectedCard);
  };

  const navigateToAddCard = () => {
    history.push(`/patient/${patientId}/creditCards`);
  };

  return (
    <Box className={styles.paymentOptionContainer}>
      <h2>Payment Options</h2>
      <Box>
        <Chip
          key={CARD_CHARGE_TYPE}
          label="Card on File"
          className={chargeType === CARD_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
          onClick={() => {
            setChargeType(CARD_CHARGE_TYPE);
          }}
        />
        <Chip
          key={SQUARE_CHARGE_TYPE}
          label="Charge with Square"
          className={chargeType === SQUARE_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
          onClick={() => {
            setChargeType(SQUARE_CHARGE_TYPE);
          }}
        />
        <Chip
          key={CASH_CHARGE_TYPE}
          label="Cash"
          className={chargeType === CASH_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
          onClick={() => {
            setChargeType(CASH_CHARGE_TYPE);
          }}
        />
      </Box>
      {chargeType === CARD_CHARGE_TYPE &&
        (cards.length ? (
          <>
            {cards.map((card: ISquareCard) => (
              <div>
                <FormControlLabel
                  value={card.id}
                  key={card.id}
                  // @ts-ignore
                  control={<Radio color="primary" checked={card.id === selectedCard?.id} onClick={onCardSelect} />}
                  label={
                    <div className={styles.cardName}>
                      Card ending in {card.last4}
                      <span className={styles.cardIcon}>
                        <CreditCardIcon cardBrand={card.cardBrand} />
                      </span>
                    </div>
                  }
                />
              </div>
            ))}
          </>
        ) : (
          <div className={styles.noCardsContainer}>
            <div>No cards on file</div>
            <button type="button" className={styles.noCardButton} onClick={navigateToAddCard}>
              Add a card
            </button>
          </div>
        ))}
      {chargeType === SQUARE_CHARGE_TYPE && <p>You will be re-directed to Square page to finish the checkout.</p>}
      {chargeType === CASH_CHARGE_TYPE && (
        <>
          <p>Enter the amount you received from your customer.</p>
          <NumberFormat
            decimalScale={2}
            fixedDecimalScale
            autoComplete="off"
            placeholder="$"
            customInput={TextField}
            variant="outlined"
            label="$"
            value={cashAmount}
            onChange={(e) => {
              e.preventDefault();
              setCashAmount(e.target.value);
            }}
            helperText={
              cashAmount && +cashAmount < total
                ? `Warning: Cash amount is less than the total to pay ($${formatNumber(total)})`
                : undefined
            }
            InputProps={{
              className: cashAmount && +cashAmount < total ? styles.warningInput : undefined,
            }}
          />
        </>
      )}
    </Box>
  );
};

export const RemainingToPay = ({
  accountCreditUsed,
  checkoutTenders,
  referralValue,
  patient,
  loading,
  openSquarePOS,
  handlePayment,
  lineItems,
  checkout,
  updateCheckout,
  payZeroBalance,
  isPractitioner,
}: {
  updateCheckout: any;
  accountCreditUsed?: number;
  checkoutTenders: any;
  referralValue?: number;
  patient: IPatientBasicInfo;
  loading: boolean;
  openSquarePOS: () => void;
  handlePayment: (args: any) => void;
  lineItems: ILineItem[];
  checkout: ICheckout;
  payZeroBalance: () => void;
  isPractitioner: boolean;
}) => {
  const styles = useStyles();
  const {
    toPay,
    subtotal: subTotal,
    discounts,
    amountPaid,
    canPayCheckout,
    serviceCreditsValue,
    transactionStatus,
    chargeType: checkoutChargeType,
    checkoutSignatureUrl,
  } = checkout;
  const total = toPay - (amountPaid || 0);
  const [chargeType, setChargeType] = useState<string>('card');
  const [cashAmount, setCashAmount] = useState<string>();
  const [selectedCard, setSelectedCard] = useState<ISquareCard>();
  const cards = useMemo(() => (patient.creditCards || []).reverse(), [patient.creditCards]);
  const [openSummaryModal, setOpenSummaryModal] = useState<boolean>(false);

  useEffect(() => {
    setSelectedCard(patient.creditCards.find((card) => card.id === patient.squareDefaultCreditCardId));
  }, [patient.squareDefaultCreditCardId]);

  useEffect(() => {
    if (chargeType !== CASH_CHARGE_TYPE) {
      setCashAmount(undefined);
    }
  }, [chargeType]);

  const handleOpenSummary = () => {
    if (checkout.toPay === 0) {
      payZeroBalance();
    } else if (chargeType === SQUARE_CHARGE_TYPE) {
      openSquarePOS();
    } else {
      setOpenSummaryModal(true);
    }
  };

  const checkValid = () => {
    if (checkout.toPay === 0) {
      return true;
    }
    if (chargeType === CARD_CHARGE_TYPE) {
      return !!selectedCard;
    }

    return true;
  };

  return (
    <div className={styles.totalsContainer}>
      {transactionStatus === SQUARE_STATUS.OK ? (
        <Box className={styles.paidContainer}>
          <SuccessIcon />
          <h2>Paid ${formatNumber(toPay)}</h2>
        </Box>
      ) : (
        <>
          <PaymentOptions
            patientId={patient.id}
            chargeType={chargeType}
            setChargeType={setChargeType}
            cashAmount={cashAmount}
            setCashAmount={setCashAmount}
            total={total}
            cards={cards}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
          />
          <Box className={styles.paymentSummaryContainer}>
            <h2>Summary</h2>
            <Box className={styles.summaryItem}>
              <div>Sub total</div>
              <div>${formatNumber(subTotal)}</div>
            </Box>
            {!!discounts && (
              <Box className={styles.summaryItem}>
                <div>Discounts</div>
                <div>- ${formatNumber(discounts)}</div>
              </Box>
            )}
            {!!serviceCreditsValue && (
              <Box className={styles.summaryItem}>
                <div>Service credits</div>
                <div>- ${formatNumber(serviceCreditsValue)}</div>
              </Box>
            )}
            {checkoutTenders
              .filter(
                ({ tenderType, value }: { tenderType: string; value: number }) =>
                  value > 0 && TENDER_TYPE_TO_SHOW.includes(tenderType)
              )
              .map(({ tenderType, value }: { tenderType: string; value: number }) => (
                <Box className={styles.summaryItem}>
                  <div
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: TENDER_TYPE_NAMES[tenderType] }}
                  />
                  <div>- ${formatNumber(value)}</div>
                </Box>
              ))}
            {!!referralValue && (
              <Box className={styles.summaryItem}>
                <div>Referral credit</div>
                <div>- ${formatNumber(referralValue)}</div>
              </Box>
            )}
            {!!accountCreditUsed && (
              <Box className={styles.summaryItem}>
                <div>Account Credit</div>
                <div>- ${formatNumber(accountCreditUsed)}</div>
              </Box>
            )}
            {!!amountPaid && amountPaid < toPay && (
              <Box className={styles.summaryItem}>
                <div>Processed</div>
                <div>- ${formatNumber(amountPaid)}</div>
              </Box>
            )}
            <Box className={styles.summaryItem}>
              <h2>Total</h2>
              <h2>${formatNumber(total)}</h2>
            </Box>
            {!!cashAmount && (
              <>
                <hr />
                <br />
              </>
            )}
            {!!cashAmount && (
              <Box className={styles.summaryItem}>
                <div>Cash Received</div>
                <div>${formatNumber(+cashAmount)}</div>
              </Box>
            )}
            {!!cashAmount && +cashAmount > total && (
              <Box className={styles.summaryItem}>
                <div>Cashback</div>
                <div>${formatNumber(+cashAmount - total)}</div>
              </Box>
            )}
            <Box className={styles.goToSummaryContainer}>
              <button
                type="button"
                className={styles.goToSummaryButton}
                disabled={loading || !canPayCheckout || !checkValid() || !isPractitioner}
                onClick={handleOpenSummary}
              >
                {chargeType === 'square' || checkout.toPay === 0 ? 'CHECKOUT' : 'GO TO SUMMARY'}
              </button>
              {!canPayCheckout && (
                <Tooltip title="Please complete the inventory steps!">
                  <IconButton>
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </>
      )}
      <ServiceVisitCheckoutSummaryModal
        open={openSummaryModal && isPractitioner}
        onClose={() => setOpenSummaryModal(false)}
        total={total}
        subTotal={subTotal}
        accountCreditUsed={accountCreditUsed}
        discounts={discounts}
        checkoutTenders={checkoutTenders}
        referralValue={referralValue}
        serviceCreditsValue={serviceCreditsValue}
        cashAmount={cashAmount ? +cashAmount : 0}
        selectedCard={chargeType === CARD_CHARGE_TYPE ? selectedCard : undefined}
        handlePayment={handlePayment}
        lineItems={lineItems}
        chargeType={chargeType}
        checkoutChargeType={checkoutChargeType}
        transactionStatus={transactionStatus}
        checkoutSignatureUrl={checkoutSignatureUrl}
        updateCheckout={updateCheckout}
      />
    </div>
  );
};
