import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Help as HelpIcon } from '@material-ui/icons';
import { ClassNameMap } from 'src/types/Dom';
import classNames from 'classnames';

export const OrderingTooltip = ({
  classes,
  title,
  fixedIcon,
  placement = 'right',
}: ClassNameMap & { title: string; fixedIcon?: boolean; placement?: TooltipProps['placement'] }) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  return (
    <Tooltip open={tooltipOpen} title={title} classes={classes} placement={placement} arrow>
      <HelpIcon
        className={classNames(classes?.icon, fixedIcon ? classes?.fixedIcon : null)}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      />
    </Tooltip>
  );
};

export default withStyles({
  tooltip: {
    maxWidth: 180,
    fontSize: '0.85rem',
    padding: 15,
    backgroundColor: 'rgb(50,50,50)',
  },
  icon: {
    color: '#777',
    cursor: 'pointer',
    fontSize: '14px',
  },
  fixedIcon: {
    position: 'absolute',
    right: '30%',
  },
})(OrderingTooltip);
