import { withStyles, TableCell } from '@material-ui/core';

export const TableCellHeader = withStyles({
  root: {
    fontSize: '15px',
    letterSpacing: '-0.05px',
    fontFamily: 'Messina Sans Bold',
    color: '#393b3f',
    padding: '2px 16px',
  },
})(TableCell);
