import { ListItem, ListItemText, Chip, Badge } from '@material-ui/core';
import React, { SyntheticEvent } from 'react';

const OPEN = 'open';
const CLOSED = 'closed';

type PropsType = {
  title: any | string;
  subtitle: any | string;
  selected: boolean;
  unreadCount?: number;
  status: typeof OPEN | typeof CLOSED;
  onClick: (e: SyntheticEvent) => void;
};

const Thread = ({ title, subtitle, selected, status = OPEN, unreadCount = 0, onClick }: PropsType) => (
  <ListItem button selected={selected} onClick={onClick}>
    <ListItemText
      primary={
        <>
          <Badge color="error" badgeContent={unreadCount}>
            {title}
          </Badge>
          {status === OPEN ? (
            <Chip size="small" style={{ marginLeft: '1rem' }} color="primary" label="Open" />
          ) : (
            <Chip size="small" style={{ marginLeft: '1rem' }} color="default" label="Closed" />
          )}
        </>
      }
      secondary={subtitle}
    />
  </ListItem>
);

export default Thread;
