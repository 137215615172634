import React, { useState, useMemo } from 'react';
import { Button, Switch, Typography } from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import { useStyles } from './MembershipDetails.styles';
import ISquareSubscription from '../../../interfaces/ISquareSubscription';
import { BEAUTY_BANK_PRICE } from '../../../constants/beautyBank.constants';
import formatDate, { formatMonthWithZero } from '../../../utils/formatDate';
import { ISquareCard } from '../../../interfaces/ISquareCard.interfaces';
import { useUpdateBeautiBankSubscription } from '../../../hooks/mutations/useUpdateBeautiBankSubscription';
import { SelectOrAddCardForMembership } from './SelectOrAddCardForMembership';
import { usePractitionerInfo } from '../../../hooks/queries/usePractitioners';
import { ReactComponent as GreenCheck } from '../../../assets/images/green-check.svg';
import { ShortMultipleSkeleton } from '../../../components/common/LoadingSkeleton';
import CreditCardIcon from '../../../components/PatientProfile/CreditCards/CreditCardIcon';

const MembershipDetails = ({
  accountBalance,
  subscription,
  customerCards,
  patientId,
  practitionerId,
  refetch,
  metadataPdfUrl,
}: {
  patientId: string;
  practitionerId: number | undefined;
  accountBalance: number;
  subscription: ISquareSubscription;
  customerCards: ISquareCard[];
  refetch: () => void;
  metadataPdfUrl: string | undefined;
}) => {
  const classes = useStyles();
  const [updatingAutoRenew, setUpdatingAutoRenew] = useState<boolean>(false);
  const [updatingCard, setUpdatingCard] = useState<boolean>(false);
  const updateSubscriptionMutation = useUpdateBeautiBankSubscription(patientId);
  const { practitionerInfo, isLoading: practitionerLoading } = usePractitionerInfo(practitionerId || 0);
  const [selectedCardId, setSelectedCardId] = useState<string>(subscription.cardId);
  const patientCards = [...(customerCards || [])].reverse();
  const [showCardForm, setShowCardForm] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [showSaved, setShowSaved] = useState<boolean>(false);

  const { startDate, endDate, autoRenew, nextPaymentDate } = subscription;

  const card = useMemo(
    () => customerCards.find((creditCard) => creditCard.id === subscription.cardId),
    [customerCards, subscription]
  );

  const updateAutoRenew = async (e: any) => {
    setUpdatingAutoRenew(true);
    await updateSubscriptionMutation.mutateAsync({
      autoRenew: !e.target.checked,
    });
    await refetch();
    setUpdatingAutoRenew(false);
  };

  const handleUpdateCard = async ({ cardId }: { cardId?: string }) => {
    setHasError(false);
    try {
      setUpdatingCard(true);
      const success = await updateSubscriptionMutation.mutateAsync({
        squareCardId: cardId || selectedCardId,
      });
      if (success) {
        await refetch();
        setShowSaved(true);
        setTimeout(() => setShowSaved(false), 3000);
      } else {
        setHasError(true);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setUpdatingCard(false);
    }
  };

  if (practitionerLoading) {
    return <ShortMultipleSkeleton length={3} />;
  }

  return (
    <div className={classes.detailsContainer}>
      <Typography variant="h5">Manage Membership</Typography>
      <Typography className={classes.subtitle}>
        Next payment of ${BEAUTY_BANK_PRICE} will be on {formatDate(nextPaymentDate, 'll')}.
      </Typography>
      <div className={classes.detailBlock}>
        <div className={classes.detailWithHeader}>
          <span className={classes.detailHeader}>Start Date</span>
          <span>{formatDate(startDate, 'll')}</span>
        </div>
        <div className={classes.detailWithHeader}>
          <span className={classes.detailHeader}>End Date</span>
          {endDate && <span>{formatDate(endDate, 'll')}</span>}
        </div>
      </div>
      <hr />
      <div className={classes.detailBlock}>
        <div>Cancel Membership After End Date</div>
        <Switch disabled={updatingAutoRenew} checked={!autoRenew} onChange={updateAutoRenew} color="primary" />
      </div>
      <hr />
      <div className={classes.detailBlock}>
        <div>Account Credits (Bank Balance)</div>
        <div>${accountBalance}</div>
      </div>
      <hr />
      <div className={classes.detailBlock}>
        <div className={classes.detailWithHeader}>
          <span className={classes.detailHeader}>Payment Method On File</span>
          <span className={classes.detailsCard}>
            <span className={classes.cardIcon}>
              <CreditCardIcon cardBrand={card?.cardBrand} />
            </span>
            ending in {card?.last4} expiring {formatMonthWithZero(card?.expMonth)}/{card?.expYear}
          </span>
        </div>
        {!showCardForm && (
          <Button className={classes.changeCardButton} onClick={() => setShowCardForm(true)}>
            CHANGE
          </Button>
        )}
      </div>
      {showCardForm && (
        <div className={classes.cardForm}>
          <SelectOrAddCardForMembership
            showChargeMessage={false}
            selectedCardId={selectedCardId}
            setSelectedCardId={setSelectedCardId}
            onSave={handleUpdateCard}
            loading={updatingCard}
            patientCards={patientCards}
            squareLocationId={practitionerInfo?.squareLocationId}
            patientId={patientId}
            onSuccessCardAdd={handleUpdateCard}
            buttonText="SAVE"
          />
          {showSaved && (
            <div className={classes.savedMessage}>
              <GreenCheck className={classes.savedIcon} />
              Credit card information saved
            </div>
          )}
          {hasError && (
            <div className={classes.errorMessage}>
              <ErrorOutlineIcon className={classes.errorMsgIcon} /> Error processing payment. Please try again.
            </div>
          )}
          <hr />
        </div>
      )}
      <a href={metadataPdfUrl} target="_blank" rel="noreferrer">
        <Button className={classes.agreementButton} type="button">
          View Agreement
        </Button>
      </a>
    </div>
  );
};

export default MembershipDetails;
