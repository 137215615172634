import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  cardContent: {
    padding: '11px 20px 20px 20px !important'
  },
  smallTitle: {
    margin: '0'
  },
  unitsSection: {
    fontFamily: 'Messina Sans Regular',
    paddingTop: '20px'
  },
  serviceName: {
    marginBottom: '10px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'center'
  },
  inputsSection: {
    display: 'flex',
    margin: '0 8px'
  },
  serviceUnit: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textField: {
    '& fieldset': {
      borderColor: '#979797'
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontFamily: 'Messina Sans Regular',
    }
  }
}));
