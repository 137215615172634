import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import { Edit as EditIcon, Replay as ReplayIcon } from '@material-ui/icons';
import { useStyles } from './sunscreenFrequency.styles';
import { RootState, dispatch } from '../../../rematch';
import { Button } from '../../common/Button';
import { SUNSCREEN_OPTIONS } from '../../../constants/medicalProfile.constants';

export const SunscreenFrequency = () => {
  const classes = useStyles();
  const { sunscreenFrequency } = useSelector(({ patient }: RootState) => patient.medicalProfile);
  const [editMode, setEditMode] = useState(false);
  const [frequency, setFrequency] = useState<any>('');

  useEffect(() => {
    setFrequency(sunscreenFrequency);
  }, [sunscreenFrequency]);

  const toggleEdit = () => {
    if (editMode) {
      setFrequency(sunscreenFrequency);
    }

    setEditMode(!editMode);
  };

  const callback = () => {
    setEditMode(false);
  };

  const save = () => {
    dispatch({
      type: 'patient/updateMedicalProfileRequest',
      payload: { value: frequency, objectKey: 'sunscreenFrequency', callback },
    });
  };

  const isButtonDisabled = (): boolean => frequency === sunscreenFrequency;

  const onFrequencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFrequency(event.target.value);
  };

  return (
    <div>
      <div className={classes.editSection}>
        <div>How often do you use sunscreen?</div>
        {editMode ? <ReplayIcon onClick={toggleEdit} /> : <EditIcon onClick={toggleEdit} />}
      </div>
      {!editMode && (
        <div className={classes.label}>
          {SUNSCREEN_OPTIONS.find(({ value }) => sunscreenFrequency === value)?.label}
        </div>
      )}
      <Collapse in={editMode} timeout="auto" disableStrictModeCompat>
        <div className={classes.selectSection}>
          <FormControl variant="outlined" className={classes.dropdown}>
            <InputLabel>Sunscreen Frequency</InputLabel>
            <Select value={frequency} onChange={onFrequencyChange} label="Sunscreen Frequency">
              {SUNSCREEN_OPTIONS.map(({ value, label }: any) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            title="Save"
            style={{ minWidth: '200px', color: '#ffffff', backgroundColor: '#12574d', marginRight: '0' }}
            onClick={save}
            disabled={isButtonDisabled()}
          />
        </div>
      </Collapse>
    </div>
  );
};
