import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  question: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  edit: {
    display: 'flex',
    alignItems: 'center'
  },
  selectSection: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-end'
  }
}));
