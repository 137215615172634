/* eslint-disable @typescript-eslint/no-shadow */
import { createModel } from '@rematch/core';
import { uniq } from 'lodash';
import axios from '../utils/axios';
import { RootModel } from './rootModel';
import {
  IAsset,
  IFetchRemainingAssetsAction,
  IFetchReviewedReconciliationReportAction,
  IReconciliationProductItem,
  IReconciliationReportReducer,
  ISignReviewedReconciliationReportAction,
  ISubmitReconciliationReportAction,
  IReconciliationReport,
  IScanProductReducer,
} from '../interfaces/reconciliation.interfaces';
import { WASTE_STATUS } from '../constants/inventory.constants';
import { getTotal, getCostTotal, itemCost, getReimbursementTotal } from '../utils/inventory.utils';
import compile from '../utils/toastMessagesCompiler';

import ReconciliationReport from '../services/ReconciliationReport';

const inventoryPractitionerInitialState = {
  id: null,
  status: 'draft',
  productList: [],
  remainingAssets: [],
  scannedAssets: [],
  reconciliationReport: {
    reconciliationReportItems: [],
  },
  reconciliationReportProductList: [],
  isLoading: true,
};

export const inventoryPractitioner = createModel<RootModel>()({
  state: inventoryPractitionerInitialState,
  reducers: {
    getReconciliationReports(state: any, { remainingAssets, productList }: IReconciliationReportReducer) {
      return { ...state, remainingAssets, productList };
    },
    scanProduct(state: any, { serialNumber, serviceId, units = 1 }: IScanProductReducer) {
      const asset = state.remainingAssets.find((asset: IAsset) => asset.internalSerialNumber === serialNumber);
      if (!asset) {
        return state;
      }
      const { manuallyCountedUnits: previousUnitsCounted } = asset;

      asset.manuallyCountedUnits = units;

      const isAlreadyScanned = state.scannedAssets.some(
        (scannedSerialNumber: any) => scannedSerialNumber === serialNumber
      );

      const product = state.productList.find((product: IReconciliationProductItem) => product.serviceId === serviceId);

      const scannedAssets = [...state.scannedAssets];

      if (!isAlreadyScanned) {
        scannedAssets.push(serialNumber);
        product.scannedUnits += units;
        product.scanned += 1;
        product.cost -= itemCost(asset, units);
      } else {
        product.scannedUnits += units - previousUnitsCounted;
        product.cost -= itemCost(asset, units - previousUnitsCounted);
      }

      return { ...state, scannedAssets };
    },
    getReviewReconciliationReport(state: any, payload: IReconciliationReport) {
      const serviceIds = uniq(payload.reconciliationReportItems.map(({ serviceId }) => serviceId));
      const { reconciliationReportItems } = payload;
      const reconciliationReportProductList = serviceIds.map((id) => {
        const items = reconciliationReportItems.filter(({ serviceId }) => serviceId === id);
        const markedAsEmptyList = items.filter(({ markedAsEmpty }) => markedAsEmpty);
        const expiredList = items.filter(({ expired }) => expired > 0);
        const missingList = items.filter(
          ({ missing, markedAsEmpty, expired, disposed }: any) => missing && !markedAsEmpty && !expired && !disposed
        );

        return {
          id,
          product: items[0]?.product,
          expected: items.filter(({ expected }: any) => expected).length,
          expectedUnits: getTotal(items, 'expected'),
          counted: items.filter(({ counted }: any) => counted).length,
          countedUnits: getTotal(items, 'counted'),
          missing: missingList.length,
          missingUnits: getTotal(missingList, 'missing'),
          practitionerNote: items[0]?.practitionerNote,
          waived: getTotal(items, 'waived'),
          allowPartialSale: items[0]?.allowPartialSale,
          markedAsEmpty: markedAsEmptyList.length,
          markedAsEmptyUnits: getTotal(markedAsEmptyList, 'disposed'),
          expired: expiredList.length,
          expiredUnits: getTotal(expiredList, 'expired'),
          open: false,
          cost: getCostTotal([...missingList, ...expiredList, ...markedAsEmptyList]),
        };
      });

      return { ...state, reconciliationReport: payload, reconciliationReportProductList };
    },
    toggleNote(state: any, payload: number) {
      const item = state.reconciliationReportProductList.find(({ id }: any) => id === payload);
      item.open = !item.open;

      return { ...state };
    },
    updateLoading(state: any, payload: boolean) {
      return { ...state, isLoading: payload };
    },
    setDraftReport(state: any, payload: any) {
      return {
        ...inventoryPractitionerInitialState,
        ...payload,
      };
    },
  },
  effects: (dispatch: any) => ({
    async createDraft({ callback }) {
      dispatch.inventoryPractitioner.updateLoading(true);
      try {
        const reconciliationReport = await ReconciliationReport.createDraft();
        const { id, status } = reconciliationReport;
        // @ts-ignore
        dispatch.inventoryPractitioner.setDraftReport({ id, status });
        callback();
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            type: 'error',
            message: compile('generic.error_message', {
              action: 'creating',
              element: 'the draft',
            }),
          },
        });
      } finally {
        dispatch.inventoryPractitioner.updateLoading(false);
      }
    },
    async fetchDraftReport({ practitionerId, callback = () => {} }) {
      const draftReport = await ReconciliationReport.getDraft(practitionerId);
      const { id: reportID, status, reconciliationReportItems = [] } = draftReport;
      dispatch.inventoryPractitioner.setDraftReport({ id: reportID, status });

      await this.fetchRemainingAssets({ practitionerId } as IFetchRemainingAssetsAction);

      reconciliationReportItems
        .filter(({ counted }: any) => counted > 0)
        .forEach(({ internalSerialNumber, serviceId, counted }: any) => {
          dispatch.inventoryPractitioner.scanProduct({
            serialNumber: internalSerialNumber,
            serviceId,
            units: counted,
          });
        });
      callback();
    },
    async addItem({ serialNumber, serviceId, units = 1 }, { inventoryPractitioner }) {
      const asset = inventoryPractitioner.remainingAssets.find(
        (asset: IAsset) => asset.internalSerialNumber === serialNumber
      );

      const finalUnits = asset.allowPartialSale ? units : asset.currentNumberOfUnits;

      const reportId = inventoryPractitioner.id;

      const data = {
        assetId: asset.id,
        expected: asset.currentNumberOfUnits,
        counted: finalUnits,
        practitionerNote: '',
      };
      await ReconciliationReport.addItem(reportId, data);

      dispatch.inventoryPractitioner.scanProduct({ serialNumber, serviceId, units });
    },
    async fetchRemainingAssets({ practitionerId, successCallback = () => {} }: IFetchRemainingAssetsAction) {
      dispatch.inventoryPractitioner.updateLoading(true);

      try {
        const {
          data: { remaining },
        } = await axios.get(`/reconciliation-reports-by-practitioner/${practitionerId}/remaining-assets`);
        const serviceIds: number[] = uniq(remaining.map(({ serviceId }: IAsset) => serviceId));
        const productList: IReconciliationProductItem[] = serviceIds.map((id: number) => {
          const inStockAssets: IAsset[] = [];
          const wasteAssets: IAsset[] = [];

          const serviceAssets = remaining.filter(({ serviceId }: IAsset) => serviceId === id);

          serviceAssets.forEach((asset: IAsset): void => {
            // eslint-disable-next-line no-unused-expressions
            asset.status === WASTE_STATUS ? wasteAssets.push(asset) : inStockAssets.push(asset);
          });

          const markedAsEmptyList = wasteAssets.filter(({ markedAsEmpty }: IAsset) => markedAsEmpty);
          const expiredList = wasteAssets.filter(({ expiredUnits }: IAsset) => expiredUnits && expiredUnits > 0);

          return {
            serviceId: id,
            name: serviceAssets[0].name,
            quantity: inStockAssets.length,
            units: getTotal(inStockAssets, 'currentNumberOfUnits'),
            scanned: 0,
            scannedUnits: 0,
            markedAsEmpty: markedAsEmptyList.length,
            markedAsEmptyUnits: getTotal(markedAsEmptyList, 'markedAsEmptyUnits'),
            expired: expiredList.length,
            expiredUnits: getTotal(expiredList, 'expiredUnits'),
            allowPartialSale: serviceAssets[0].allowPartialSale,
            note: '',
            cost: getReimbursementTotal(serviceAssets),
          };
        });

        dispatch.inventoryPractitioner.getReconciliationReports({ remainingAssets: remaining, productList });
        successCallback();
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            type: 'error',
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'your reconciliation reports',
            }),
          },
        });
      } finally {
        dispatch.inventoryPractitioner.updateLoading(false);
      }
    },
    async submitReconciliationReport(data: ISubmitReconciliationReportAction) {
      const { reconciliationReport, filledEmergencyKit, signature, practitionerId, successCallback, finallyCallback } =
        data;

      try {
        const body = { reconciliationReportItems: reconciliationReport, filledEmergencyKit, signature };
        await ReconciliationReport.submitReport(practitionerId, body);
        successCallback();

        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Report',
              action: 'submitted',
            }),
          },
        });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'submitting',
              element: 'the report',
            }),
            type: 'error',
          },
        });
      } finally {
        finallyCallback();
      }
    },
    async fetchReviewedReconciliationReport(data: IFetchReviewedReconciliationReportAction) {
      const { reportId, successCallback = () => {} } = data;

      try {
        const response = await axios.get(`/reconciliation-reports/${reportId}`);
        const data = response.data.reconciliationReport;
        dispatch.inventoryPractitioner.getReviewReconciliationReport(data);
        successCallback();
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'fetching',
              element: 'the report',
            }),
            type: 'error',
          },
        });
      }
    },
    async signReviewedReconciliationReport(data: ISignReviewedReconciliationReportAction) {
      const { signature, reportId, successCallback } = data;

      try {
        await axios.post(`/reconciliation-reports/${reportId}/practitioner-update`, { signature });
        dispatch({ type: 'inventoryReconciliation/closeReport', payload: reportId });
        successCallback();

        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Report',
              action: 'signed',
            }),
          },
        });
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'signing',
              element: 'the report',
            }),
            type: 'error',
          },
        });
      }
    },
  }),
});
