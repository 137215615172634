import { useQuery } from 'react-query';
import { IFilters } from '../../components/DashboardAdministrator/PurchaseOrders/PurchaseOrders';
import { PURCHASE_ORDERS } from '../../constants/reactQuery.keys';
import PurchaseOrders from '../../services/PurchaseOrders';

export function useCustomPurchaseOrders(query: IFilters) {
  const { data, isLoading, isFetching, isError } = useQuery([PURCHASE_ORDERS, query], () =>
    PurchaseOrders.getPurchaseOrders(query)
  );

  return {
    purchaseOrders: data?.purchaseOrders || [],
    purchaseOrdersCount: data?.purchaseOrdersCount || 0,
    isLoading,
    isFetching,
    isError,
  };
}
