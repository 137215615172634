/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import EventIcon from '@material-ui/icons/Event';
import { Select, Box, BoxProps, FormControl, FormControlProps, MenuItem, SelectProps } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { range } from 'lodash';
import { useStyles } from './DateComponent.styles';

interface DateComponentProps {
  containerProps?: BoxProps;
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  isBack?: boolean;
  currentDate?: any;
  getDateValue: (value: Date | string) => void;
}

const DateComponent = (props: DateComponentProps) => {
  const classes = useStyles();
  const { containerProps, formControlProps, selectProps, currentDate, isBack, getDateValue } = props;
  const [daysCount, setDaysArray] = useState<any>([]);
  const [day, setDay] = useState<any>(currentDate && currentDate.getDate() >= 0 ? currentDate.getDate() : undefined);
  const [month, setMonth] = useState<any>(
    currentDate && currentDate.getMonth() >= 0 ? currentDate.getMonth() : undefined
  );
  const [year, setYear] = useState<any>((currentDate && currentDate.getFullYear()) || undefined);

  useEffect(() => {
    const daysInMonth = moment(new Date(year || moment().year(), month), 'YYYY-M').daysInMonth();
    setDaysArray(daysInMonth);
  }, [month, year]);

  useEffect(() => {
    if (month >= 0 && year) {
      const newDay = day === 'none' || day === undefined ? moment(new Date(year, month), 'YYYY-M').daysInMonth() : day;
      const date = new Date(year, month, newDay);
      getDateValue(date);
    }
  }, [day, year, month]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const listOfYears = (date: Moment, numbers: number, isBack: boolean | undefined) => {
    if (isBack) {
      return range(date.get('year') - numbers, date.get('year') + 1);
    }
    return range(date.get('year'), date.get('year') + numbers);
  };

  return (
    <Box className={classes.container} {...containerProps}>
      <FormControl variant="filled" className={classes.formControl} {...formControlProps}>
        <Select
          data-cy="month"
          labelId="month"
          name="month"
          variant="outlined"
          onChange={(event: any) => setMonth(event.target.value)}
          IconComponent={EventIcon}
          defaultValue={month >= 0 ? month : 'none'}
          {...selectProps}
        >
          <MenuItem data-cy="none" value="none" disabled>
            None
          </MenuItem>
          {range(1, 13, 1).map((value, index) => (
            <MenuItem data-cy="selectedMonth" value={index}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="filled" className={classes.formControl} {...formControlProps}>
        <Select
          data-cy="day"
          displayEmpty
          labelId="day"
          name="day"
          variant="outlined"
          onChange={(event: any) => setDay(event.target.value)}
          IconComponent={EventIcon}
          defaultValue={day >= 0 ? day : 'none'}
          {...selectProps}
        >
          <MenuItem data-cy="none" value="none">
            None
          </MenuItem>
          {range(1, daysCount + 1).map((item: number) => (
            <MenuItem data-cy="selectedDay" key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="filled" className={classes.formControl} {...formControlProps}>
        <Select
          data-cy="year"
          labelId="year"
          value={year}
          name="year"
          variant="outlined"
          onChange={(event: any) => setYear(event.target.value)}
          IconComponent={EventIcon}
          defaultValue={year || 'none'}
          {...selectProps}
        >
          <MenuItem data-cy="none" value="none" disabled>
            None
          </MenuItem>
          {listOfYears(moment(), 70, isBack).map((value) => (
            <MenuItem data-cy="selectedYear" value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

DateComponent.defaultProps = {
  containerProps: {
    display: 'flex',
  },
  isBack: true,
};

export default DateComponent;
