import { Box } from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import React from 'react';
import { Pill } from 'src/components/common/Pill';
import { ColumnType } from 'src/types/TablePaginator';

export const APPOINTMENT_TYPE_COLUMNS: ColumnType[] = [
  { field: 'name', title: 'Name' },
  { field: 'description', title: 'Appointment type description' },
  { field: 'duration', title: 'Duration' },
  {
    field: 'private',
    title: 'status',
    render: (value: string) => <Pill title={value ? 'Disabled' : 'Active'} color={value ? 'gray' : 'green'} />,
  },
  {
    field: 'id',
    title: 'Actions',
    render: (value: string, row: any) => {
      const styles = {
        fontSize: '20px',
        color: row.default ? 'gray' : '#12574d',
        margin: '0 5px',
        cursor: 'pointer',
      };
      return (
        <Box display="flex">
          <EditIcon viewBox="0 0 22 22" style={styles} onClick={row.onEdit} />
          {row.private ? (
            <VisibilityIcon
              data-cy="visibilityIcon"
              fontSize="small"
              style={{ ...styles, color: '#12574d' }}
              onClick={row.onChangeStatus}
            />
          ) : (
            <VisibilityOffIcon
              data-cy="visibilityIcon"
              fontSize="small"
              style={{ ...styles, color: '#12574d' }}
              onClick={row.onChangeStatus}
            />
          )}
          <DeleteIcon
            data-cy="editIcon"
            fontSize="small"
            style={{ ...styles, color: row.default ? 'gray' : '#c25a5a' }}
            onClick={row.onDelete}
          />
        </Box>
      );
    },
  },
];
