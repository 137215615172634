import Api from '../Api';
import {
  CreateOrUpdateServiceCreditParams,
  ForceCloseServiceVisitParams,
  UpdateCustomerAccountCreditsParams,
} from './support.interfaces';

const BASE_SUPPORT_URL = '/v3/support';

const Support = {
  updateCustomerAccountCredits: async ({ patientId, accountCredits }: UpdateCustomerAccountCreditsParams) =>
    Api.post(`${BASE_SUPPORT_URL}/account_credits`, { cid: patientId, cents: accountCredits * 100 }),
  createOrUpdateServiceCredit: async ({ patientId, serviceId, quantity }: CreateOrUpdateServiceCreditParams) =>
    Api.post(`${BASE_SUPPORT_URL}/service_credits`, { cid: patientId, sid: serviceId, quantity }),
  forceCloseOpenServiceVisit: async ({ patientId, serviceVisitId }: ForceCloseServiceVisitParams) =>
    Api.post(`${BASE_SUPPORT_URL}/force_close_open_service_visit`, { cid: patientId, svId: serviceVisitId }),
};

export default Support;
