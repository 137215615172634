import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  mainSection: {
    display: 'flex',
    paddingTop: '20px',
  },
  sectionTitle: {
    borderRight: '8px solid #E7EEED',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '42px',
    textAlign: 'right',
    fontSize: '18px',
    color: '#3A3B3F',
    fontFamily: 'Messina Sans Light',
  },
  contentSection: {
    width: '100%',
    paddingLeft: '60px',
    paddingRight: '10px',
  },
  service: {
    color: '#3A3B3F',
    fontSize: '15px',
  },
  balance: {
    fontSize: '16px',
    color: '#000000',
  },
  buttonApply: {
    backgroundColor: '#E7EEED',
    fontSize: '13px',
    color: '#393B3F',
    textTransform: 'capitalize',
    fontWeight: 300,
    width: '101px'
  },
}));
