import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { formatCurrency } from 'src/utils/formatNumber';
import { InventoryOrderStatus } from 'src/interfaces/IInventoryOrder';
import { OrderNotes } from './OrderApprovalRowAux';
import { modalStyles } from './OrderApprovalRow.styles';

type ModalProps = {
  dismiss: () => void;
  action: (event: React.MouseEvent) => Promise<void>;
  orderReferenceNumber: string;
  total: number;
};

export const OrderApprovalModal = ({ dismiss, action, orderReferenceNumber, total }: ModalProps) => {
  const classes = modalStyles();

  const back = () => dismiss();

  return (
    <Box className={classes.confirmDialog} data-testid="approval dialog">
      <Box className={classes.confirmContent}>
        <Typography variant="h5">Approve order #{orderReferenceNumber}</Typography>
        <Typography>Total: {formatCurrency(total)} </Typography>
      </Box>

      <Box className={classes.confirmButtons}>
        <Button data-testid="cancel approval" onClick={back} variant="outlined" className={classes.actionButton}>
          Cancel
        </Button>
        <Button data-testid="approve and charge" onClick={action} variant="outlined" className={classes.actionButton}>
          Approve and Charge
        </Button>
      </Box>
    </Box>
  );
};

type AdminNoteModalProps = {
  id: number;
  dismiss: () => void;
  action: (event: React.MouseEvent) => Promise<void>;
  adminNote: string;
  status: InventoryOrderStatus;
  setAdminNote: React.Dispatch<React.SetStateAction<string | null>>;
};

export const AdminNoteModal = ({ id, dismiss, action, adminNote, setAdminNote, status }: AdminNoteModalProps) => {
  const classes = modalStyles();

  const back = () => dismiss();

  return (
    <Box className={classes.confirmDialog} data-testid="admin note dialog">
      <Box className={classes.confirmContent}>
        <Typography variant="h5">Add an admin note:</Typography>
      </Box>

      <Box className={classes.confirmContent}>
        <p style={{ margin: 0, marginTop: '1rem', color: '#706F6F' }}>Admin Note</p>
        <OrderNotes id={id} status={status} adminNote={adminNote} setAdminNote={setAdminNote} />
      </Box>

      <Box className={classes.confirmButtons}>
        <Button data-testid="cancel" onClick={back} variant="outlined" className={classes.actionButton}>
          Cancel
        </Button>
        <Button data-testid="confirm" onClick={action} variant="outlined" className={`${classes.actionButton}`}>
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

type RejectModalProps = {
  id: number;
  dismiss: () => void;
  action: (event: React.MouseEvent) => Promise<void>;
  adminNote: string;
  status: InventoryOrderStatus;
  orderReferenceNumber: string;
  setAdminNote: React.Dispatch<React.SetStateAction<string | null>>;
};

export const OrderRejectModal = ({
  id,
  dismiss,
  action,
  adminNote,
  setAdminNote,
  status,
  orderReferenceNumber,
}: RejectModalProps) => {
  const classes = modalStyles();

  const back = () => dismiss();

  return (
    <Box className={classes.confirmDialog} data-testid="reject dialog">
      <Box className={classes.confirmContent}>
        <Typography variant="h5">Reject the order: #{orderReferenceNumber}</Typography>
      </Box>

      <Box className={classes.confirmContent}>
        <p style={{ margin: 0, marginTop: '1rem', color: '#706F6F' }}>Reason</p>
        <OrderNotes id={id} status={status} adminNote={adminNote} setAdminNote={setAdminNote} />
      </Box>

      <Box className={classes.confirmButtons}>
        <Button data-testid="cancel rejection" onClick={back} variant="outlined" className={classes.actionButton}>
          Cancel
        </Button>
        <Button
          data-testid="reject"
          onClick={action}
          variant="outlined"
          className={`${classes.actionButton} ${classes.rejectButton}`}
        >
          Reject
        </Button>
      </Box>
    </Box>
  );
};
