import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Grid, Tab, Tabs } from '@material-ui/core';
import { dispatch } from '../../rematch';
import { useStyles } from './services.styles';
import { ActiveStepWrapper } from './ActiveStepWrapper';
import IServices from '../../interfaces/IServices';
import compile from '../../utils/toastMessagesCompiler';
import IServiceAcknowledgment from '../../interfaces/IServiceAcknowledgment';
import DialogModal from '../common/DialogModal';

import { useServiceAcknowledgments } from '../../hooks/queries/useServiceAcknowledgments';
import { useAcceptedServiceAckActivityLog } from '../../hooks/mutations/useAcceptedServiceAcknowledgmentActivityLog';
import { ShortMultipleSkeleton } from '../common/LoadingSkeleton';
import useLog from '../../hooks/mutations/useLog';
import ServiceGroupList from './ServiceGroupList';
import { ServiceList } from './ServiceList';
import IServiceRequest from '../../interfaces/IServiceRequest';
import {
  MISC_SERVICE_GROUP_ID,
  NEUROTOXINS_SERVICE_GROUP_ID,
  FLEX_SERVICE_GROUP_ID,
} from '../../constants/general.constants';
import { RETAIL_SERVICE_GROUP_ID } from '../../constants/retail.constants';
import IServiceGroup from '../../interfaces/IServiceGroup';
import { SQUARE_STATUS } from '../../constants/checkout.constants';

export const Services = () => {
  const classes = useStyles();
  const { serviceGroups, serviceRequests, id: patientId, practitioner } = useSelector(({ patient }: any) => patient);

  const whitelistedServiceGroups = practitioner?.whitelistedServiceGroups || [];
  const newServiceVisit = useSelector((state: any) => state.newServiceVisit);

  const { skusSelected, practitionerCertificatesSelected, patientCertificatesSelected } = useSelector(
    (state: any) => state.patientPointCertificates
  );

  const {
    selectedServices,
    checkout: { transactionStatus },
    serviceVisit: { opened },
  } = newServiceVisit;

  const { services }: { services: IServices[]; serviceVisit: { opened: boolean } } = newServiceVisit;

  const { data: serviceAcknowledgments } = useServiceAcknowledgments();
  const [openedDialog, setOpenedDialog] = useState(false);
  const [preSelectedServiceId, setPreSelectedServiceId] = useState<number | null>(null);
  const [selectedServiceAcknowledgment, setSelectedServiceAcknowledgment] = useState<IServiceAcknowledgment | null>(
    null
  );
  const useAcceptedServiceAcknowledgmentActivityLogMutation = useAcceptedServiceAckActivityLog();

  const { mutateAsync: log } = useLog();

  const [selectedServiceGroup, setSelectedServiceGroup] = useState(0);

  useEffect(() => {
    if (serviceRequests.length === 0) {
      return;
    }

    const isNeurotoxinsAllowed = isServiceRequested(NEUROTOXINS_SERVICE_GROUP_ID);

    const firstServiceGroupAllowed = serviceRequests.find(({ approved }: IServiceRequest) => approved);

    setSelectedServiceGroup(
      isNeurotoxinsAllowed ? NEUROTOXINS_SERVICE_GROUP_ID : firstServiceGroupAllowed?.serviceGroupId
    );
  }, [serviceRequests]);

  function isServiceRequested(isServiceGroupRequested: number): boolean {
    // condition for showing Misc, Retail and Flex services regardless if they are approved or not
    if (
      isServiceGroupRequested === MISC_SERVICE_GROUP_ID ||
      isServiceGroupRequested === RETAIL_SERVICE_GROUP_ID ||
      isServiceGroupRequested === FLEX_SERVICE_GROUP_ID
    ) {
      return true;
    }
    const serviceRequest = serviceRequests.find(
      ({ serviceGroupId }: IServiceRequest) => serviceGroupId === isServiceGroupRequested
    );
    return serviceRequest?.approved || false;
  }

  const dispatchServiceSelection = (serviceId: number) => {
    const resetGaldermaForCheckout =
      Object.keys(skusSelected).length > 0 ||
      practitionerCertificatesSelected.length > 0 ||
      patientCertificatesSelected > 0;

    // Reset galderma integration changes applied for checkout
    if (resetGaldermaForCheckout) {
      dispatch.patientPointCertificates.setSkusSelected({});
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('galderma.reset_services'),
          type: 'info',
        },
      });
    }

    dispatch({
      type: 'newServiceVisit/saveProgressStep1',
      payload: {
        serviceId,
        patientId,
        serviceVisitId: newServiceVisit.serviceVisit.id,
        services,
      },
    });
  };

  const handleServiceAcknowledgmentAccepted = async () => {
    setOpenedDialog(false);
    if (preSelectedServiceId) {
      try {
        const success = await useAcceptedServiceAcknowledgmentActivityLogMutation.mutateAsync({
          patientId,
          serviceId: preSelectedServiceId,
          serviceVisitId: newServiceVisit.serviceVisit.id,
        });
        if (success) {
          dispatchServiceSelection(preSelectedServiceId);
        }
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('services.service_acknowledgment_accepted_error'),
            type: 'error',
          },
        });
      }
    }
  };

  const handleOnCloseDialog = () => {
    setOpenedDialog(false);
    setPreSelectedServiceId(null);
    setSelectedServiceAcknowledgment(null);
  };

  const isServiceSelected = (selectedServiceId: number): boolean => selectedServices.includes(selectedServiceId);

  const handleOnChangeServiceSelection = (serviceId: number): void => {
    const selectedService = services.find((service: IServices) => service.id === serviceId);
    if (selectedService) {
      if (selectedServiceGroup) {
        const serviceAcknowledgment = serviceAcknowledgments?.find(
          (acknowledgment: IServiceAcknowledgment) => acknowledgment.serviceGroupId === selectedServiceGroup
        );
        if (!isServiceSelected(serviceId) && serviceAcknowledgment) {
          setSelectedServiceAcknowledgment(serviceAcknowledgment);
          setPreSelectedServiceId(serviceId);
          setOpenedDialog(true);
          return;
        }
      }
    }
    dispatchServiceSelection(serviceId);
  };

  const updateSelectedStep = (): void => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 1 });
    log({
      feature: 'Service Visit',
      step: 1,
      logId: null,
      params: {
        serviceAcknowledgments,
        services,
        selectedServices,
        transactionStatus,
        newServiceVisit,
        patientId,
        serviceGroups,
        serviceRequests,
        whitelistedServiceGroups,
      },
    });
  };

  const handleServiceGroupSelect = (serviceGroupId: any) => {
    setSelectedServiceGroup(serviceGroupId);
  };

  const servicesToShowInEmr = services.filter(
    ({ serviceGroupId, showInEmr }) => serviceGroupId === selectedServiceGroup && showInEmr
  );

  const requiredServiceGroups = serviceGroups.filter(
    ({ id }: IServiceGroup) =>
      isServiceRequested(id) && services.some(({ serviceGroupId, showInEmr }) => serviceGroupId === id && showInEmr)
  );

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const checkDisabledService = (serviceGroupId: string, _serviceId: string) =>
    transactionStatus === SQUARE_STATUS.OK || !whitelistedServiceGroups?.includes(serviceGroupId) || !opened;

  return (
    <ActiveStepWrapper step={1} onClick={updateSelectedStep}>
      <Card style={{ marginBottom: '0', minHeight: '200px' }}>
        <Tabs value={0} classes={{ root: classes.tabs, indicator: classes.selectedTab }}>
          <Tab label="Services" className={classes.tab} />
        </Tabs>
        {services.length === 0 || serviceGroups.length === 0 ? (
          <Grid container spacing={2} style={{ padding: '12px 15px' }}>
            <Grid item xs={12} sm={4}>
              <ShortMultipleSkeleton length={5} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <ShortMultipleSkeleton length={5} />
            </Grid>
          </Grid>
        ) : (
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <ServiceGroupList
                  selectedServices={selectedServices}
                  serviceGroups={requiredServiceGroups}
                  services={services}
                  onServiceGroupSelect={handleServiceGroupSelect}
                  selectedServiceGroup={selectedServiceGroup}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <ServiceList
                  services={servicesToShowInEmr}
                  onChangeServiceSelection={handleOnChangeServiceSelection}
                  selectedServices={selectedServices}
                  checkDisableFunc={checkDisabledService}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {selectedServiceAcknowledgment ? (
          <DialogModal
            open={openedDialog}
            confirm={handleServiceAcknowledgmentAccepted}
            closeModal={handleOnCloseDialog}
          >
            {selectedServiceAcknowledgment.message}
          </DialogModal>
        ) : null}
      </Card>
    </ActiveStepWrapper>
  );
};
