import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { formatNumber } from 'src/utils/checkout.utils';
import { PORTRAIT_FLEX, PORTRAIT_LITE } from 'src/constants/general.constants';
import { useStyle } from './inventoryOrder.styles';
import { OrderContext } from './index';
import DeliveryAddress from './DeliveryAddress';
import DeliveryDate from './DeliveryDate';
import OrderPayment from './OrderPayment';
import OrderOverview from './OrderOverview';
import OrderDeliveryOptions from './OrderDeliveryOptions';

const OrderDelivery: React.FC = (): React.ReactElement => {
  const classes = useStyle();
  const { orderData } = useContext(OrderContext) || {};
  const isFlexOrLite = orderData?.roleName === PORTRAIT_FLEX || orderData?.roleName === PORTRAIT_LITE;

  return (
    <>
      <Box className={classes.orderDeliveryParentGrid}>
        <Box className={classes.orderDeliveryGrid}>
          <OrderOverview />
          {isFlexOrLite ? (
            <OrderDeliveryOptions />
          ) : (
            <Box>
              <Box className={classes.summaryContainer}>
                <Typography className={classes.orderOverviewHeading}>Delivery Details</Typography>
              </Box>
              <Typography className={classes.labelTitleMid}>Delivery Address</Typography>
              <DeliveryAddress location={orderData?.deliveryLocation} className={classes.shadowedLabel} />
              <Typography className={classes.labelTitleMid}>Preferred Delivery Date</Typography>
              <DeliveryDate className={classes.legacyDatePicker} ProgressParams={{ height: 32 }} />
              <Box height={10} margin={0} padding={0} />
              <Typography variant="caption" color="textSecondary" className={classes.dateCaption}>
                *Please note that someone must be able to receive the package <br />
                on the day of the delivery. Deliveries can arrive as early as 8:00am.
              </Typography>
            </Box>
          )}
        </Box>

        {isFlexOrLite ? (
          <OrderPayment />
        ) : (
          <Box className={classes.summaryParentContainer} marginLeft="20px">
            <Box className={classes.summaryContainer}>
              <Typography className={classes.orderOverviewHeading}>Summary</Typography>
            </Box>
            <Box className={classes.detailsContainer}>
              <Box className={classes.detailItem}>
                <Typography className={classes.labelContent}>Subtotal</Typography>
                <Typography>{`$${formatNumber(orderData?.subtotal ?? 0)}`}</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.viewMore} />
    </>
  );
};

export { OrderDelivery, OrderDelivery as default };
