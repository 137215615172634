import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '18px',
    fontFamily: 'Messina Sans SemiBold',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  formControl: {
    width: '100%',
  },
  formInput: {
    color: '#000000',
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
  },
  add: {
    width: '100%',
    height: '55px',
  },
  dropdown: {
    fontFamily: 'Messina Sans Regular',
    height: '46px',
  },
  textField: {
    width: '100%',
  },
}));
