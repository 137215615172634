import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { orderBy, toInteger } from 'lodash';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { dispatch } from '../../rematch';
import compile from '../../utils/toastMessagesCompiler';

import IConversationThread from '../../interfaces/IConversationThread';
import { PHYSICIAN_ROLE, PRACTITIONER_ROLE } from '../../constants/general.constants';
import { NOTIFICATIONS, PATIENT_MESSAGES } from '../../constants/reactQuery.keys';

import { createThread, markMessagesAsRead, closeThread, createMessage } from '../../services/PatientsMessaging';

import { usePatientMessages } from '../../hooks/queries/usePatientMessages';
import { useCustomContactInfo } from '../../hooks/queries/useCustomContactInfo';

import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import GreenTabs from '../../components/common/GreenTabs';
import GreenTab from '../../components/common/GreenTab';
import TabItem from '../../components/common/TabItem';
import ConversationsWrapper from './ConversationsWrapper';
import IAvatarProps from '../../interfaces/IAvatarProps';
import { ROUTES } from '../../constants/routes.constants';

type paramsType = {
  patientId: string;
};

const PHYSICIAN_PATIENT_TAB = 'PHYSICIAN_PATIENT_TAB';
const PROVIDER_PATIENT_TAB = 'PROVIDER_PATIENT_TAB';

const fullName = ({ firstName = '', lastName = '' }): string => `${firstName} ${lastName}`;

const Messaging = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const markAsReadMutation = useMutation(markMessagesAsRead);
  const createThreadMutation = useMutation(createThread);
  const closeThreadMutation = useMutation(closeThread);
  const createMessageMutation = useMutation(createMessage);

  const { patientId } = useParams<paramsType>();

  const { userType, userId, name } = useSelector(({ auth }: any) => auth);
  const CURRENT_USER_IS_A_DOCTOR = userType === PHYSICIAN_ROLE;
  const CURRENT_USER_IS_A_PROVIDER = userType === PRACTITIONER_ROLE;

  const { data: patientData } = useCustomContactInfo(patientId);
  const { data, isError, isLoading: loadingThreads } = usePatientMessages({ patientId });

  const [currentTab, setCurrentTab] = useState(CURRENT_USER_IS_A_DOCTOR ? PHYSICIAN_PATIENT_TAB : PROVIDER_PATIENT_TAB);

  const [patient, setPatient] = useState<IAvatarProps>({
    userName: '',
    avatarAlt: 'patient',
    secondaryText: 'patient',
  });

  const [physician, setPhysician] = useState<IAvatarProps>({
    userName: CURRENT_USER_IS_A_DOCTOR ? name : '',
    avatarAlt: 'physician',
    secondaryText: '',
  });

  const [practitioner, setPractitioner] = useState<IAvatarProps>({
    userName: CURRENT_USER_IS_A_PROVIDER ? name : '',
    avatarAlt: 'practitioner',
    secondaryText: '',
  });

  const [threadsWithPhysician, setThreadsWithPhysician] = useState<IConversationThread[]>([]);
  const [threadsWithPractitioner, setThreadsWithPractitioner] = useState<IConversationThread[]>([]);
  const [selectedThread, setSelectedThread] = useState<IConversationThread | undefined>();

  useEffect(() => {
    if (!patientData) {
      return;
    }

    setPatient({
      ...patient,
      userName: fullName(patientData),
    });

    if (CURRENT_USER_IS_A_DOCTOR) {
      return;
    }

    // if the current user isn't a doctor take the patient's physician info to show initially
    // otherwise we're showing the current doctor info
    const { physician: patientPhysician, practitioner: patientPractitioner } = patientData;
    setPhysician({
      userName: fullName(patientPhysician),
      avatarAlt: 'Physician',
      secondaryText: patientPhysician?.professionalName,
    });

    setPractitioner({
      userName: fullName(patientPractitioner),
      avatarAlt: 'Practitioner',
      secondaryText: patientPractitioner?.professionalName,
    });
  }, [patientData]);

  useEffect(() => {
    // for physicians show only own threads
    if (currentTab === PHYSICIAN_PATIENT_TAB) {
      if (userType === PHYSICIAN_ROLE) {
        setThreadsWithPhysician(
          orderBy(data, ({ updatedAt }) => new Date(updatedAt), ['desc']).filter(
            (thread) => thread.physicianId === userId
          )
        );
      } else {
        setThreadsWithPhysician(
          orderBy(data, ({ updatedAt }) => new Date(updatedAt), ['desc']).filter((thread) => thread.physicianId)
        );
      }
    } else if (userType === PRACTITIONER_ROLE) {
      setThreadsWithPractitioner(orderBy(data, ({ updatedAt }) => new Date(updatedAt), ['desc']));
      // .filter((thread) => thread.practitionerId === userId)
    } else {
      setThreadsWithPractitioner(
        orderBy(data, ({ updatedAt }) => new Date(updatedAt), ['desc']).filter((thread) => thread.practitionerId)
      );
    }
  }, [data, currentTab]);

  const navigateBack = () => {
    history.push(ROUTES.PATIENT_ID(patientId));
  };

  const READONLY_PHYSICIAN_CHATS = userType !== PHYSICIAN_ROLE;
  const READONLY_PRACTITIONER_CHATS = userType !== PRACTITIONER_ROLE;

  // when select a conversation search the physician of that conversation to show
  const handleSelectPhysicianThread = async (thread: IConversationThread) => {
    setSelectedThread(thread);
    const { physician: threadPhysician } = thread;

    if (threadPhysician) {
      setPhysician({
        userName: fullName(threadPhysician),
        avatarAlt: 'Physician',
        secondaryText: threadPhysician?.professionalName,
      });
    }

    if (userType !== PHYSICIAN_ROLE) {
      return;
    }

    if (thread.open) {
      await markAsReadMutation.mutateAsync({ patientId: thread.customerId });
      queryClient.invalidateQueries([PATIENT_MESSAGES, patientId]);
      queryClient.invalidateQueries([NOTIFICATIONS, patientId]);
    }
  };

  const handleSelectPractitionerThread = async (thread: IConversationThread) => {
    if (!patientData) {
      return;
    }

    setSelectedThread(thread);
    const { practitionerId: threadPractitioner } = thread;
    const { practitioner: patientPractitioner } = patientData;

    if (threadPractitioner) {
      setPractitioner({
        userName: fullName(patientPractitioner),
        avatarAlt: 'Practitioner',
        secondaryText: patientPractitioner?.professionalName,
      });
    }

    if (userType !== PRACTITIONER_ROLE) {
      return;
    }

    if (thread.open) {
      await markAsReadMutation.mutateAsync({ patientId: thread.customerId });
      queryClient.invalidateQueries([PATIENT_MESSAGES, patientId]);
      queryClient.invalidateQueries([NOTIFICATIONS, patientId]);
    }
  };

  const handleOpenNewThread = async () => {
    try {
      const thread = await createThreadMutation.mutateAsync({ patientId: toInteger(patientId) });
      const newThread = {
        ...thread,
        messages: [],
      };
      setSelectedThread(newThread);
      setThreadsWithPhysician([newThread, ...threadsWithPhysician]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'conversation',
            action: 'created',
          }),
          type: 'success',
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('create_thread_error'),
          type: 'warning',
          duration: 5000,
        },
      });
    }
  };

  const handleCloseThread = async () => {
    try {
      if (!selectedThread?.id || !patientId) {
        return;
      }

      await closeThreadMutation.mutateAsync({
        patientId: selectedThread.customerId,
        threadId: selectedThread.id,
      });

      setSelectedThread({ ...selectedThread, open: false });

      queryClient.invalidateQueries([PATIENT_MESSAGES, patientId]);

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'converstion',
            action: 'closed',
          }),
          type: 'success',
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error', {
            action: 'closing',
            element: 'the conversation',
          }),
          type: 'error',
        },
      });
    }
  };

  const handleSendMessage = async (messageText: string) => {
    try {
      const message = await createMessageMutation.mutateAsync({
        patientId,
        message: messageText,
      });
      if (selectedThread) {
        const newMessages = [...selectedThread.messages, message];

        setSelectedThread({ ...selectedThread, messages: newMessages });
      }
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'sending',
            element: 'the message',
          }),
          type: 'error',
        },
      });
    }
  };

  return (
    <main>
      <NavigationBar title="Messages" onBackButtonClick={navigateBack} />
      <GreenTabs value={currentTab} onChange={(e, newTab) => setCurrentTab(newTab)} aria-label="simple tabs example">
        {CURRENT_USER_IS_A_DOCTOR ? <GreenTab value={PHYSICIAN_PATIENT_TAB} label="Physician-Patient" /> : null}
        {CURRENT_USER_IS_A_PROVIDER ? <GreenTab value={PROVIDER_PATIENT_TAB} label="Provider-Patient" /> : null}
      </GreenTabs>
      <TabItem value={currentTab} index={PHYSICIAN_PATIENT_TAB}>
        <ConversationsWrapper
          readOnly={READONLY_PHYSICIAN_CHATS}
          hideUnreadMeessages={READONLY_PHYSICIAN_CHATS}
          user1={physician}
          user2={patient}
          isLoading={loadingThreads}
          isError={isError}
          threads={threadsWithPhysician}
          onSelectThread={handleSelectPhysicianThread}
          selectedThread={selectedThread}
          onCreateThread={handleOpenNewThread}
          onCloseThread={handleCloseThread}
          onSendMessage={handleSendMessage}
        />
      </TabItem>
      <TabItem value={currentTab} index={PROVIDER_PATIENT_TAB}>
        <ConversationsWrapper
          readOnly={READONLY_PRACTITIONER_CHATS}
          hideUnreadMeessages={READONLY_PRACTITIONER_CHATS}
          user1={practitioner}
          user2={patient}
          isLoading={loadingThreads}
          isError={isError}
          threads={threadsWithPractitioner}
          onSelectThread={handleSelectPractitionerThread}
          selectedThread={selectedThread}
          onCreateThread={handleOpenNewThread}
          onCloseThread={handleCloseThread}
          onSendMessage={handleSendMessage}
        />
      </TabItem>
    </main>
  );
};

export default Messaging;
