import React from 'react';
import {
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Box,
  DialogContent,
  DialogTitle,
  Dialog,
  withStyles,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { Close as CloseIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { MEDSPA_ADMIN_ROLE, SNACKBAR_INFO } from 'src/constants/general.constants';
import MedspaAdmins from 'src/services/MedspaAdmins';
import { showSnackbar } from 'src/utils/global';
import { useStyles } from './ModalPricingStyle.styles';
import IServices from '../../../../interfaces/IServices';
import Services from '../../../../services/Services';
import { formatNumber } from '../../../../utils/checkout.utils';
import formatDate from '../../../../utils/formatDate';
import IPrice from '../../../../interfaces/IPrice';
import { SERVICE_PRICING, VARIANT_ITEM_PRICING } from '../../../../constants/reactQuery.keys';
import Variants from '../../../../services/Variants';
import { IVariantItem } from '../../../../interfaces/IVariants';
import compile from '../../../../utils/toastMessagesCompiler';

const TableRowStriped = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      background: 'none',
      border: 'none',
    },
  },
}))(TableRow);

interface Props {
  open: boolean;
  setOpenModalHistory: () => void;
  data: IServices | IVariantItem;
}

const ModalPricingHistory = ({ open, setOpenModalHistory, data }: Props) => {
  const classes = useStyles();
  const { roleName } = useSelector(({ auth }: any) => auth);
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;

  const showErrorMessage = () => {
    showSnackbar(
      compile('generic.error_message', {
        action: 'fetching',
        element: 'pricing history',
      }),
      SNACKBAR_INFO
    );
  };

  const { data: variantServiceHistory, isLoading: isLoadingVariantServiceHistory } = useQuery(
    [VARIANT_ITEM_PRICING, data.id],
    () => Variants.getVariantItemPricings(data.id),
    {
      keepPreviousData: false,
      enabled: !isMedspaAdmin,
      refetchOnWindowFocus: false,
      onError: () => {
        showErrorMessage();
      },
    }
  );

  const { data: servicePricingHistory, isLoading: isLoadingServiceHistory } = useQuery(
    [SERVICE_PRICING, data.id],
    () => Services.getServicePricings(data.id),
    {
      keepPreviousData: false,
      enabled: !isMedspaAdmin,
      refetchOnWindowFocus: false,
      onError: () => {
        showErrorMessage();
      },
    }
  );

  const serviceHistory = data.isVariantItem ? variantServiceHistory : servicePricingHistory;

  const { data: medspaVariantServiceHistory, isLoading: isLoadingMedspaVariantPricingHistory } = useQuery(
    [VARIANT_ITEM_PRICING, data.id],
    () => MedspaAdmins.getVariantItemPricingHistory({ variantItemId: data.id.toString() }),
    {
      enabled: isMedspaAdmin,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onError: () => {
        showErrorMessage();
      },
    }
  );

  const { data: medspaServicePricingHistory, isLoading: isLoadingMedspaServicePricingHistory } = useQuery(
    [SERVICE_PRICING, data.id],
    () => MedspaAdmins.getServicePricingHistory({ serviceId: data.id.toString() }),
    {
      enabled: isMedspaAdmin,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onError: () => {
        showErrorMessage();
      },
    }
  );

  const medspaPricingHistory = data.isVariantItem ? medspaVariantServiceHistory : medspaServicePricingHistory;
  const history = isMedspaAdmin ? medspaPricingHistory : serviceHistory;

  if (
    (isMedspaAdmin &&
      (!medspaPricingHistory || isLoadingMedspaVariantPricingHistory || isLoadingMedspaServicePricingHistory)) ||
    (!isMedspaAdmin && (!serviceHistory || isLoadingVariantServiceHistory || isLoadingServiceHistory))
  ) {
    return null;
  }

  return (
    <Box>
      <Dialog open={open} onClose={setOpenModalHistory}>
        <div className={classes.containerDialog} data-cy="priceHistoryModal">
          <DialogTitle style={{ padding: '35px 27px 20px 39px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span data-cy="priceHistoryModalTitle" className={classes.headerTitle}>
                {data.name}
              </span>
              <CloseIcon onClick={setOpenModalHistory} data-cy="priceHistoryCloseModal" />
            </div>
            <div className={classes.headerSubTitle} data-cy="priceHistoryModalSubTitle">
              Historical prices
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={classes.listContainer}>
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableBody>
                    {history?.map((item: IPrice) => (
                      <TableRowStriped>
                        <TableCell className={classes.cellContainer}>${formatNumber(item.priceOverride)}</TableCell>
                        <TableCell className={classes.cellContainer}>{formatDate(item.updatedAt)}</TableCell>
                      </TableRowStriped>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </Box>
  );
};

export default ModalPricingHistory;
