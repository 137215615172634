/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-fragments */
import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { TableBody, TableHead } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import { TableCellCustom } from '../../../common/TableStyles/TableCellCustom';
import { TableCellHeader } from '../../../common/TableStyles/TableCellHeader';
import IconCross from '../../../common/IconCross';
import { ButtonStatus } from '../../../common/TableStyles/ButtonStatus';
import { dispatch } from '../../../../rematch';
import { getProductIdentifiersData, showQuantityUnits } from '../../../../utils/inventory.utils';
import {
  PRACTITIONER_COMPLETED_SUPPLY_RECEIPT,
  PRACTITIONER_CONFIRMATION_SUPPLY_RECEIPT,
} from '../../../../routes/practitionerRoutes';

interface Props {
  id: number;
  date: string;
  status: string;
  dataCollapse: object[];
  products: any[];
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  actionButton: {
    fontSize: '16px',
    fontFamily: 'Messina Sans SemiBold',
    width: '124px',
    height: '36px',
    borderColor: '#ccc',
    color: '#12574d',
  },
});

const getStatus = (status: string, history: any, id: number, classes: any) => {
  switch (status) {
    case 'pending':
      return (
        <Box display="flex" justifyContent="space-between">
          <ButtonStatus data-cy="pending" style={{ background: '#fac980', textTransform: 'capitalize' }}>
            Signature Pending
          </ButtonStatus>
          <ButtonStatus
            data-cy="sign"
            onClick={() => history.push(PRACTITIONER_CONFIRMATION_SUPPLY_RECEIPT(id), history.location.pathname)}
            variant="outlined"
            className={classes.actionButton}
          >
            SIGN
          </ButtonStatus>
        </Box>
      );
    case 'closed':
      return (
        <Box display="flex" justifyContent="space-between">
          <ButtonStatus data-cy="closed" style={{ background: '#d1f1bd', textTransform: 'capitalize' }}>
            Closed
          </ButtonStatus>
          <ButtonStatus
            data-cy="view"
            onClick={() => history.push(PRACTITIONER_COMPLETED_SUPPLY_RECEIPT(id), history.location.pathname)}
            variant="outlined"
            className={classes.actionButton}
          >
            VIEW
          </ButtonStatus>
        </Box>
      );
    default:
      break;
  }
};

function SupplyReceiptTabRow(props: Props) {
  const { id, date, status, dataCollapse, products } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const history = useHistory();

  const navigateProductIdentifiers = (assets: any): void => {
    dispatch({
      type: 'productIdentifiers/setProductIdentifiersData',
      payload: { ...getProductIdentifiersData(assets), aggregatedField: 'initialNumberOfUnits' },
    });

    history.push('/product-identifiers', history.location.pathname);
  };

  return (
    <React.Fragment>
      <TableRow data-cy="row" className={classes.root} style={{ backgroundColor: open ? '#e5efed' : 'white' }}>
        <TableCell width="50">
          <IconButton data-cy="expandIcon" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <IconCross open={open} />
          </IconButton>
        </TableCell>
        <TableCell width="200">
          <Box data-cy="creationDate" component="span" fontWeight="bold">
            {moment(date).format('MM/DD/YYYY')}
          </Box>
        </TableCell>
        <TableCell>{getStatus(status, history, id, classes)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table>
                <TableHead>
                  <TableRow style={{ height: '56px' }}>
                    <TableCellHeader>Product</TableCellHeader>
                    <TableCellHeader>Supplied</TableCellHeader>
                    <TableCellHeader>Product IDs</TableCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataCollapse.map((item: any) => (
                    <TableRow data-cy="expandedRow" key={item.serviceId} style={{ height: '56px' }}>
                      <TableCellCustom data-cy="productName">{item.productName}</TableCellCustom>
                      <TableCellCustom>
                        {showQuantityUnits({ ...item, quantity: item.assetsCount, units: item.units })}
                      </TableCellCustom>
                      <TableCellCustom>
                        <Box
                          style={{ display: 'inline-flex', border: '1px solid #e5efed' }}
                          onClick={() => {
                            navigateProductIdentifiers(item.assets);
                          }}
                        >
                          <MenuIcon data-cy="menu" />
                        </Box>
                      </TableCellCustom>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SupplyReceiptTabRow;
