import React from 'react';
import { Box } from '@material-ui/core';
import Page from 'src/components/common/Page';
import ReportsBody from './ReportsBody';

const Reports = () => (
  <Page title="Reports">
    <Box width="100%">
      <ReportsBody />
    </Box>
  </Page>
);

export default Reports;
