import React, { useEffect, useRef, useState } from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
import DragAndDropComponent from 'src/components/DragAndDrop';
import { FileDetails, StepStatus } from 'src/interfaces/onboarding.interface';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import { useCreatePhotoID } from 'src/hooks/queries/useAccountSettings';
import FilesList from 'src/components/DragAndDrop/FilesList';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';

interface PhotoIDProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus, value?: any) => void;
  photoId?: FileDetails;
  progressPercentage?: number;
  disabled?: boolean;
}

const PhotoID = ({ stepId, onStepUpdate, photoId, progressPercentage, disabled = false }: PhotoIDProps) => {
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();
  const prevState = useRef(photoId?.fileName);
  const [governmentId, setGovernmentId] = useState<FileDetails | null>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState<string>('');
  const {
    mutateAsync: createPhotoId,
    isLoading: isLoadingCreate,
    isError: createError,
    isSuccess,
  } = useCreatePhotoID();

  const handleFileInput = async (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        createPhotoId({ photoId: reader.result?.toString() || '' });
      };
      reader.onerror = () => {
        setFileErrorMessage('Error uploading file.');
      };
      reader.readAsDataURL(file);
    } else {
      setFileErrorMessage('');
    }
  };

  const handleRemove = () => {
    setGovernmentId(null);
    onStepUpdate(stepId, STEP_EMPTY);
  };

  useEffect(() => {
    if (isLoadingCreate) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (createError) {
      handleRemove();
      setFileErrorMessage('Photo ID update failed. Please try again.');
    }
  }, [createError, isLoadingCreate]);

  useEffect(() => {
    if (photoId?.fileName) {
      setGovernmentId(photoId);
      onStepUpdate(stepId, STEP_COMPLETED);
    }
  }, [photoId?.fileName, photoId?.url, isSuccess]);

  useEffect(() => {
    if (prevState.current === null && photoId?.fileName) {
      updateProgress({
        stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
        percentage: progressPercentage as number,
      });
    }

    prevState.current = photoId?.fileName;
  }, [photoId?.fileName]);

  if (isLoadingCreate) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={50} style={{ marginRight: '10px' }} />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {!governmentId?.fileName ? (
          <DragAndDropComponent
            disabled={disabled}
            acceptedFileType="image/png,image/jpg,image/jpeg,application/pdf"
            onFileChange={handleFileInput}
            error={!!fileErrorMessage}
            helperText={fileErrorMessage}
            hideRemoveFile
          />
        ) : (
          <FilesList
            files={[{ name: governmentId.fileName || '' }]}
            onRemoveDocument={handleRemove}
            hideRemoveFile={disabled}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PhotoID;
