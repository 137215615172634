import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SERVICE_VISIT_OPENED } from '../../constants/reactQuery.keys';
import Patients from '../../services/Patients';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import ServiceVisit from '../../services/ServiceVisit';
import { ROUTES } from '../../constants/routes.constants';
import IServiceVisit from '../../interfaces/IServiceVisits';

export function useOpenedServiceVisit({ patientId }: { patientId: string }) {
  return useQuery([SERVICE_VISIT_OPENED, patientId], () => Patients.getOpenedServiceVisit({ patientId }), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    initialData: { id: 0, version: 0 },
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'Service visit' })),
  });
}

export const useCreateNewServiceVisitVersionMutation = (patientId: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => ServiceVisit.createServiceVisitVersion({ customerId: patientId }), {
    onSuccess: ({ success, serviceVisit }: { success: boolean; serviceVisit: IServiceVisit }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }
      queryClient.invalidateQueries([SERVICE_VISIT_OPENED, patientId]);
      window.location.href = ROUTES.NEW_SERVICE_VISIT_VERSION(patientId, serviceVisit.id);
    },
    onError: useReactQueryOnErrorHandler,
  });
};
