import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { MEDSPA_STANDING_ORDERS } from 'src/constants/reactQuery.keys';
import { IMedspaStandingOrders, IMedspaStandingOrdersParams } from 'src/interfaces/IMedspaStandingOrders';
import MedspaStandingOrders from 'src/services/MedspaStandingOrders';

export const useMedspaStandingOrders = (
  userGroupId: number,
  enabled: boolean = true
): UseQueryResult<IMedspaStandingOrders[]> =>
  useQuery<IMedspaStandingOrders[]>({
    queryKey: [MEDSPA_STANDING_ORDERS],
    queryFn: (): Promise<IMedspaStandingOrders[]> => MedspaStandingOrders.getMedspaStandingOrders({ userGroupId }),
    enabled,
  });

export const useUpdateMedspaStandingOrders = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userGroupId, standingOrderId, content }: IMedspaStandingOrdersParams) =>
      MedspaStandingOrders.updateMedspaStandingOrders({ userGroupId, standingOrderId, content }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MEDSPA_STANDING_ORDERS]);
      },
    }
  );
};

export const useCreateMedspaStandingOrders = () => {
  const queryClient = useQueryClient();
  return useMutation(MedspaStandingOrders.createMedspaStandingOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries([MEDSPA_STANDING_ORDERS]);
    },
  });
};
