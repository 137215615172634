import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { WizardHeader } from '../WizardHeader';
import { WizardFooter } from '../WizardFooter';
import { InventoryProductsTable } from './InventoryProductsTable';
import { AdminNote } from './AdminNote';
import { PractitionerSignature } from './PractitionerSignature';
import { dispatch } from '../../../../rematch';
import { IReconciliationReportItem } from '../../../../interfaces/reconciliation.interfaces';
import { getProductIdentifiersData } from '../../../../utils/inventory.utils';
import { PRACTITIONER_RECONCILIATION_PATH } from '../../../../routes/practitionerRoutes';
import { COMPLETED_RECONCILIATION_HEADERS_WITH_DEDUCTIONS } from '../../../../constants/inventory.constants';

export const CompletedInventoryReport = () => {
  const history = useHistory();
  const { reconciliationReport, reconciliationReportProductList } = useSelector(
    ({ inventoryPractitioner }: any) => inventoryPractitioner
  );
  const { adminNote, status, id, closingSignature, reconciliationReportItems } = reconciliationReport;

  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>('');

  const onBack = () => {
    history.push(PRACTITIONER_RECONCILIATION_PATH);
  };

  const onSubmit = () => {
    dispatch({
      type: 'inventoryPractitioner/signReviewedReconciliationReport',
      payload: { signature, reportId: id, successCallback: onBack },
    });
  };

  const onEndStroke = (signatureObj: string) => {
    setIsSigned(true);
    setSignature(signatureObj);
  };

  const getIsDisabled = (): boolean => (status === 'pending_signature' ? !isSigned : true);

  const navigateToProductIdentifiers = (objectId: number) => {
    const items = reconciliationReportItems
      .filter(({ serviceId }: IReconciliationReportItem) => serviceId === objectId)
      .map((item: IReconciliationReportItem) => ({ ...item, currentNumberOfUnits: item.expected }));

    dispatch({
      type: 'productIdentifiers/setProductIdentifiersData',
      payload: { ...getProductIdentifiersData(items), aggregatedField: 'counted' },
    });

    history.push('/product-identifiers');
  };

  return (
    <div>
      <div style={{ height: 'calc(100vh - 140px)', overflow: 'auto' }}>
        <WizardHeader title="Review Completed Inventory Report" />
        <Divider style={{ marginBottom: '30px' }} />
        <InventoryProductsTable
          header={COMPLETED_RECONCILIATION_HEADERS_WITH_DEDUCTIONS}
          body={reconciliationReportProductList}
          iconCallback={navigateToProductIdentifiers}
          showDeductions
        />
        <AdminNote adminNote={adminNote} />
        <PractitionerSignature onEndStroke={onEndStroke} signature={closingSignature} status={status} />
      </div>
      <WizardFooter
        primaryButtonText="SUBMIT"
        secondaryButtonText="BACK"
        primaryCallback={onSubmit}
        secondaryCallback={onBack}
        disablePrimary={getIsDisabled()}
      />
    </div>
  );
};
