import React from 'react';

import { useHistory } from 'react-router-dom';
import Header from '../Header';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH } from '../../../routes/practitionerRoutes';
import Footer from '../Footer';
import CalendarConfiguration from '../../Configuration/Calendar/CalendarConfiguration';
import { useUpdateProfileProgress } from '../../../hooks/mutations/useUpdateProfileProgress';

const CalendarConfigurationTab = () => {
  const history = useHistory();
  const updateProfileProgressMutation = useUpdateProfileProgress();
  const handleSubmit = async () => {
    await updateProfileProgressMutation.mutateAsync({ stepName: 'calendar-configuration' });
    history.push(PRACTITIONER_ACCOUNT_SETTINGS_PATH('completed-onboarding'));
  };
  return (
    <>
      <Header currentStepName="Calendar Configuration" currentStep={4} />
      <CalendarConfiguration hideNavBar />
      <Footer
        saveTitle="Complete"
        isSaving={false}
        currentStep={4}
        handleSubmit={handleSubmit}
        backLink={PRACTITIONER_ACCOUNT_SETTINGS_PATH('patient-bulk-upload')}
      />
    </>
  );
};

export default CalendarConfigurationTab;
