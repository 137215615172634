import { useQuery } from 'react-query';
import { CUSTOMER_SELECTOR } from '../../../constants/reactQuery.keys';
import Customers from '../../../services/Customers';

/* Tiny and performant hook to get the customers list for selector form */
export function useCustomerSelector(params: { search?: string; practitionerId?: number | string }) {
  const response = useQuery([CUSTOMER_SELECTOR, JSON.stringify(params)], () =>
    Customers.getCustomerForSelector(params)
  );
  return {
    ...response,
    data: response.data || [],
    emailOptions:
      response?.data?.map(({ id, fullName, email }) => ({ value: email, name: `MRN: ${id} ${fullName} [${email}]` })) ||
      [],
  };
}
