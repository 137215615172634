/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useStyles } from './purchaseOrder.styles';
import { dispatch } from '../../../rematch';
import IServices from '../../../interfaces/IServices';
import ISuppliers from '../../../interfaces/ISuppliers';
import IWarehouses from '../../../interfaces/IWarehouses';
import compile from '../../../utils/toastMessagesCompiler';

const ProductSelectedInitialState = {
  serviceId: undefined,
  quantity: 1,
  units: 1,
  warehouseId: undefined,
  supplierId: undefined,
};

interface ProductSelectedStateInterface {
  serviceId: number | undefined;
  quantity: number;
  units: number;
  warehouseId: number | undefined | any;
  supplierId: number | undefined | any;
}

const schema = yup.object().shape({
  quantity: yup.number().positive().integer().min(1),
});

interface OrderTypeInfo {
  title: string;
  value: string;
}

type OrderTypeEnum = 'supplierPurchase' | 'samples' | 'supplierExchange' | 'nonTraditionalTransfer';

const PurchaseOrderTypes: Record<OrderTypeEnum, OrderTypeInfo> = {
  supplierPurchase: { title: 'Supplier purchase', value: 'supplier_purchase' },
  samples: { title: 'Samples', value: 'samples' },
  supplierExchange: { title: 'Supplier exchange', value: 'supplier_exchange' },
  nonTraditionalTransfer: { title: 'Non-traditional transfer', value: 'non_traditional_transfer' },
};

const mapState = (state: any) => ({
  isLoadingServices: state.loading.effects.newPurchaseOrder.getProductsBySupplierById,
  isLoadingSuppliers: state.loading.effects.newPurchaseOrder.getSuppliers,
  isLoadingWarehouses: state.loading.effects.newPurchaseOrder.getWarehouses,
  products: state.newPurchaseOrder.products,
  warehouses: state.newPurchaseOrder.warehouses,
  suppliers: state.newPurchaseOrder.suppliers,
  purchase: state.newPurchaseOrder.purchase,
  warehouseId: state.newPurchaseOrder.warehouseId,
  orderType: state.newPurchaseOrder.orderType,
  supplierId: state.newPurchaseOrder.supplierId,
  referenceNumber: state.newPurchaseOrder.referenceNumber,
  internalNote: state.newPurchaseOrder.internalNote,
  productsSelected: state.newPurchaseOrder.productsSelected,
});

const mapDispatch = ({
  newPurchaseOrder: { setSupplierId, setWarehouseId, setReferenceNumber, setProducts, setInternalNote, setOrderType },
}: any) => ({
  setSupplierId: (id: number) => setSupplierId(id),
  setWarehouseId: (id: number) => setWarehouseId(id),
  setReferenceNumber: (text: number) => setReferenceNumber(text),
  setInternalNote: (text: number) => setInternalNote(text),
  setOrderType: (text: number) => setOrderType(text),
  setProducts: (items: any) => setProducts(items),
});

const NewPurchaseOrderForm = (props: any) => {
  const {
    isLoadingServices,
    productsSelected,
    products,
    warehouses,
    suppliers,
    orderType,
    purchase,
    warehouseId,
    supplierId,
    referenceNumber,
    internalNote,
    setSupplierId,
    setWarehouseId,
    setOrderType,
    setReferenceNumber,
    setInternalNote,
  } = props;

  const { purchaseId }: any = useParams();
  const classes = useStyles();
  const [productSelected, setProductSelected] = useState<ProductSelectedStateInterface>(ProductSelectedInitialState);
  const { register, errors } = useForm({ resolver: yupResolver(schema) });

  const handleProductSelected = (event: React.ChangeEvent<HTMLInputElement | any>) => {
    event.persist();
    setProductSelected((product: any) => ({ ...product, [event.target.name]: event.target.value }));
  };

  useEffect(() => {
    if (supplierId) {
      dispatch.newPurchaseOrder.getProductsBySupplierById({ supplierId });
    }
  }, [supplierId]);

  useEffect(
    () => () => {
      setSupplierId(undefined);
      setWarehouseId(undefined);
    },
    []
  );

  const addProductToSelected = ({
    serviceId,
    quantity,
    units,
    warehouseId: warehouseIdSelected,
    supplierId: supplierIdSelected,
  }: ProductSelectedStateInterface) => {
    setProductSelected(ProductSelectedInitialState);
    dispatch.newPurchaseOrder.addProductToSelect({
      serviceId,
      quantity,
      units,
      warehouseId: warehouseIdSelected,
      supplierId: supplierIdSelected,
    });
  };

  const handleChangeService = (event: any) => {
    if (!event.target.value) {
      return;
    }

    const service = products.find(({ id }: any) => id === event.target.value);
    setProductSelected((product: any) => ({ ...product, serviceId: service.id, units: service.startingUnits }));
  };

  const handleProductSelect = (event: any) => {
    event.preventDefault();
    if (!orderType) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('purchase_order.not_purchase_order_type_selected'),
          type: 'error',
        },
      });
      return;
    }

    if (!productSelected.serviceId) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('purchase_order.not_service_selected'),
          type: 'error',
        },
      });
      return;
    }

    addProductToSelected({ ...productSelected, supplierId, warehouseId });
  };

  return (
    <Box paddingX={2} data-cy="purchaseOrderForm" component="form" onSubmit={handleProductSelect}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Box>
          <Typography className={classes.title}>New Purchase Order</Typography>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs>
          <FormControl size="medium" variant="outlined" className={classes.formControl} fullWidth>
            <InputLabel className={classes.formInput}>Select a warehouse</InputLabel>
            <Select
              data-cy="warehouse"
              value={warehouseId || ''}
              name="warehouseId"
              onChange={(event) => setWarehouseId(event.target.value)}
              fullWidth
              label="Select Warehouse"
            >
              <MenuItem value={undefined}>
                <em>None</em>
              </MenuItem>
              {warehouses.map(
                ({ id, providerLabeling }: Pick<IWarehouses, 'id' | 'providerLabeling'>, index: number) => (
                  <MenuItem data-cy={`werehouseListItem-${index}`} key={id} value={id}>
                    {providerLabeling}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          <TextField
            data-cy="referenceNumber"
            fullWidth
            error={errors.referenceNumber ? errors.referenceNumber.message : ''}
            helperText={errors.referenceNumber && errors.referenceNumber.message}
            name="referenceNumber"
            value={referenceNumber}
            onChange={(ev) => {
              setReferenceNumber(ev.target.value);
            }}
            label="Reference Number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs>
          <TextField
            data-cy="internalNote"
            fullWidth
            error={errors.internalNote ? errors.internalNote.message : ''}
            helperText={errors.internalNote && errors.internalNote.message}
            name="internalNote"
            value={internalNote}
            onChange={(ev) => {
              setInternalNote(ev.target.value);
            }}
            label="Internal Note"
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          {purchase && purchaseId && supplierId && !isEmpty(suppliers) && !isEmpty(productsSelected) ? (
            <TextField
              variant="outlined"
              disabled
              fullWidth
              defaultValue={suppliers.find((item: ISuppliers) => item.id === supplierId)?.name || 'q'}
            />
          ) : (
            <FormControl size="medium" variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.formInput}>Select a supplier</InputLabel>
              <Select
                data-cy="supplier"
                defaultValue={supplierId}
                name="supplierId"
                onChange={(event: any) => {
                  setSupplierId(event.target.value);
                  setProductSelected(ProductSelectedInitialState);
                }}
                fullWidth
                disabled={!!productsSelected.length}
                label="Select a supplier"
              >
                <MenuItem data-cy="none" value="">
                  <em>None</em>
                </MenuItem>
                {suppliers.map(({ id, name }: Pick<ISuppliers, 'id' | 'name'>) => (
                  <MenuItem data-cy="selectedSupplier" key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs>
          <FormControl size="medium" variant="outlined" className={classes.formControl} fullWidth>
            {isLoadingServices ? (
              <InputLabel className={classes.formInputLoading}>Loading services...</InputLabel>
            ) : (
              <InputLabel className={classes.formInput}>Select a product</InputLabel>
            )}
            <Select
              data-cy="serviceName"
              name="serviceId"
              value={productSelected}
              disabled={isLoadingServices}
              onChange={handleChangeService}
              fullWidth
              renderValue={() => {
                if (productSelected.serviceId) {
                  return <span>{products.find((item: any) => item.id === productSelected.serviceId).name}</span>;
                }
                return <InputLabel className={classes.formInputSelect}>Select a product</InputLabel>;
              }}
              label="Select a product"
            >
              <MenuItem data-cy="none" value="">
                <em>None</em>
              </MenuItem>
              {products
                .filter(({ untracked }: IServices) => !untracked)
                .map(({ id, name }: Pick<IServices, 'id' | 'name'>) => (
                  <MenuItem data-cy="selectedService" key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                data-cy="quantityInput"
                type="number"
                fullWidth
                error={errors.quantity ? errors.quantity.message : ''}
                helperText={errors.quantity && errors.quantity.message}
                name="quantity"
                ref={register}
                inputProps={{
                  min: 1,
                }}
                value={productSelected.quantity}
                onChange={handleProductSelected}
                className={classes.quantity}
                label="Quantity"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl data-cy="orderType" size="medium" variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.formInput}>Purchase order type</InputLabel>
            <Select
              value={orderType}
              name="orderType"
              onChange={(event: any) => setOrderType(event.target.value)}
              label="Purchase order type"
            >
              {(Object.keys(PurchaseOrderTypes) as Array<keyof typeof PurchaseOrderTypes>).map((key) => (
                <MenuItem data-cy="type" key={PurchaseOrderTypes[key].value} value={PurchaseOrderTypes[key].value}>
                  {PurchaseOrderTypes[key].title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            data-cy="submitBtn"
            type="submit"
            variant="contained"
            disableElevation
            color="primary"
            className={classes.add}
            size="large"
          >
            ADD
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default connect(mapState, mapDispatch)(NewPurchaseOrderForm);
