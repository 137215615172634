import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import FeeTypesForm from 'src/components/FeeTypes/FeeTypesForm';
import { useStyles } from './PractitionerFeeTab.styles';
import { Button } from '../../../common/Button';
import { DEFAULT_CANCELLATION_FEE, usePractitionerInfo } from '../../../../hooks/queries/usePractitioners';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import { Practitioners } from '../../../../services/Practitioners';
import { ReactComponent as GreenCheck } from '../../../../assets/images/green-check.svg';
import { NO_FEE_VALUE, CANCELLATION_FEE_VALUE, DEPOSIT_FEE_VALUE } from '../../../../constants/general.constants';

export const PractitionerFeeTab = () => {
  const classes = useStyles();
  const { mutate: updatePractitioner } = useMutation(Practitioners.editPractitioner);
  const [selectedFeeOption, setSelectedFeeOption] = useState<number>(NO_FEE_VALUE);
  const [feeAmount, setFeeAmount] = useState<string | number>();
  const { userId: practitionerId } = useSelector(({ auth }: any) => auth);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showSaved, setShowSaved] = useState<boolean>(false);
  const [cancellationFee, setCancellationFee] = useState(0);
  const [depositFee, setDepositFee] = useState(0);
  const { practitionerInfo, isLoading } = usePractitionerInfo(practitionerId);

  useEffect(() => {
    if (practitionerInfo?.id) {
      setFeeAmount(practitionerInfo.cancelationFee?.toString() || DEFAULT_CANCELLATION_FEE);
      if (practitionerInfo.requestCreditCard) {
        setSelectedFeeOption(CANCELLATION_FEE_VALUE);
      } else if (practitionerInfo.requestDeposit) {
        setSelectedFeeOption(DEPOSIT_FEE_VALUE);
      }
    }
  }, [practitionerInfo]);

  const validFeeAmount =
    selectedFeeOption === NO_FEE_VALUE ||
    (selectedFeeOption !== NO_FEE_VALUE && feeAmount && parseFloat(feeAmount as string) > 0);

  const handleUpdateFeePreference = async () => {
    setProcessing(true);
    await updatePractitioner({
      id: practitionerId,
      skipUpdateInfoParams: true,
      requestCreditCard: !!cancellationFee,
      requestDeposit: !!depositFee,
      cancelationFee: cancellationFee,
      depositFee,
    });
    setProcessing(false);
    setShowSaved(true);
    setTimeout(() => setShowSaved(false), 3000);
  };

  if (isLoading || !practitionerInfo.id) {
    return <MultipleSkeleton />;
  }

  return (
    <div className={classes.feesContainer}>
      <Typography variant="h5" className={classes.title}>
        Deposits and Fees
      </Typography>
      <p className={classes.feeBoldText}>
        Choose whether you want to charge clients a fee for canceling or rescheduling without at least 24 hours notice:
      </p>
      <div>
        <FeeTypesForm
          data={{
            cancellationFee: practitionerInfo.cancelationFee,
            depositFee: practitionerInfo.depositFee,
          }}
          onChange={(data) => {
            setDepositFee(+data.depositFee);
            setCancellationFee(+data.cancellationFee);
          }}
        />
      </div>
      <div>
        <Button
          title={processing ? 'Saving...' : 'Save'}
          onClick={handleUpdateFeePreference}
          className={classes.button}
          disabled={!validFeeAmount}
        />
      </div>
      {showSaved && (
        <div className={classes.savedMessage}>
          <GreenCheck className={classes.savedIcon} />
          Settings saved
        </div>
      )}
    </div>
  );
};
