import { Checkbox, withStyles } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    width: '536px',
    maxWidth: '536px',
    padding: '0 16px 36px 28px',
  },
  title: {
    display: 'block',
    color: '#12574D',
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '24px',
  },
  titleSmall: {
    fontSize: '18px',
  },
  subtitle: {
    color: '#393B3F',
    fontSize: '13px',
    fontFamily: 'Messina Sans Regular',
  },
  listItemTitle: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '14px',
  },
  listItemSubtitle: {
    color: '#969696',
    fontFamily: 'Messina Sans Regular',
    fontSize: '13px',
    lineHeight: 0,
  },
  convertCertificateButton: {
    backgroundColor: '#E7EEED',
    width: '151px',
    maxWidth: '151px',
    textAlign: 'left',
  },
  convertCertificationItem: {
    color: '#3A3B3F',
    background: '#FFFFFF',
    fontSize: '13px',
    fontFamily: 'Messina Sans Regular',
    marginBottom: '16px',
  },
  confirmationCodeText: {
    color: '#3A3B3F',
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
  },
  codeButton: {
    backgroundColor: '#E7EEED',
    color: '#393B3F',
    height: '47px',
    fontFamily: 'Messina Sans SemiBold',
    width: '100%',
  },
  locationButton: {
    backgroundColor: '#E7EEED',
    width: '191px',
    textAlign: 'left',
  },
  locationSelectItem: {
    color: '#3A3B3F',
    background: '#FFFFFF',
    fontSize: '15px',
    fontFamily: 'Messina Sans Regular',
    marginBottom: '16px',
    display: 'inline-block',
    textAlign: 'center',
    width: '100%'
  },
  locationLabel: {
    background: '#e7eeed',
    textAlign: 'center',
    padding: '8px 0px',
    width: '191px'
  }
});

export const CheckboxCustom = withStyles(() => ({
  root: {
    '&$checked': {
      color: '#000000',
    },
  },
  checked: {},
}))(Checkbox);
