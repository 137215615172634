import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button as MaterialButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { Button } from '../common/Button';
import { useStyles } from '../NewServiceVisit/footerButtons.styles';
import { ROUTES } from '../../constants/routes.constants';
import { useSubmitServiceVisitMutation } from '../../hooks/serviceVisit/useServiceVisit';

interface Props {
  opened: boolean;
  isLoading: boolean;
  isValid: boolean;
  scrollToInvalidStep: () => void;
}

export const Footer = ({ opened, isLoading, isValid, scrollToInvalidStep }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const { patientId, serviceVisitId }: any = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const sumbitMutation = useSubmitServiceVisitMutation(+serviceVisitId, +patientId);
  const backToPatientProfile = () => {
    setIsOpen(false);
    history.push(ROUTES.PATIENT_ID(patientId), { from: history.location.pathname });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const submitServiceVisit = async () => {
    // TODO: implemente service visit submission
    await sumbitMutation.mutateAsync();
    backToPatientProfile();
  };

  const validateServiceVisit = () => {
    if (isValid) {
      setIsOpen(true);
    } else {
      scrollToInvalidStep();
    }
  };

  return (
    <div className={classes.buttonsSection}>
      {opened && (
        <>
          <Button title="REVIEW LATER" className={classes.reviewLaterButton} onClick={backToPatientProfile} />
          <Button
            title="SUBMIT SERVICE VISIT"
            disabled={isLoading}
            onClick={validateServiceVisit}
            className={classes.submitButton}
          />
        </>
      )}
      <Dialog open={isOpen} onClose={handleClose}>
        <div className={classes.containerDialog}>
          <DialogTitle className={classes.title}>Submit service visit</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.content}>
              Are you sure you want to finalize and submit the service visit?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <MaterialButton onClick={handleClose} className={classes.buttonLeft}>
              NO
            </MaterialButton>
            <MaterialButton
              data-cy="submitVisitBtn"
              onClick={submitServiceVisit}
              className={classes.buttonRight}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : `YES, SUBMIT`}
            </MaterialButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
