import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  ul: {
    marginTop: '20px',
    '& .Mui-selected': {
      backgroundColor: '#1D584D',
      color: 'white !important',
    },
  },
  mainTable: {
    width: '100%',
    marginTop: 25,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
