import React from 'react';
import { Box } from '@material-ui/core';
import { EHRTypography } from 'src/components/ui/v1';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { IUserGroup } from '../../../interfaces/IUserGroup';
import useStylesMedspaSettings from '../index.styles';
import MedspaConfigurations from '../MedspaConfigurations';
import DepositsAndFees from '../DepositsAndFees';

interface AddOnSectionProps {
  userGroup: IUserGroup | undefined;
  refetchUserGroup: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IUserGroup | null, unknown>>;
}

const AddOnSection: React.FC<AddOnSectionProps> = ({ userGroup, refetchUserGroup }) => {
  const classesMedspaSettings = useStylesMedspaSettings();

  return (
    <>
      <EHRTypography variant="h6" dataCy="medspa-tab-title">
        Add-ons
      </EHRTypography>
      <Box className={classesMedspaSettings.medspaSettingsContainer}>
        <MedspaConfigurations userGroup={userGroup} />
        <DepositsAndFees userGroup={userGroup} refetchUserGroup={refetchUserGroup} />
      </Box>
    </>
  );
};

export default AddOnSection;
