import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { CardContent, CircularProgress, Tooltip } from '@material-ui/core';
import { DoneAll, Update } from '@material-ui/icons/';
import { useStyles } from './contactInfo.styles';
import { markAsSeen } from '../../../services/MedicalProfile';
import { Title, Details } from '../../common/card';
import { IconButton } from '../../common/IconButton';
import { FEMALE, PHYSICIAN_ROLE } from '../../../constants/general.constants';
import { hasAccessTo } from '../../../utils/auth.utils';
import { MEDICAL_PROFILE } from '../../../hooks/queries/useCustomMedicalProfile';
import { UPDATE_PATIENT_MEDICAL_PROFILE } from '../../../constants/actions.constants';

const MedicalProfile = ({
  knownAllergies,
  medicationAllergies,
  pregnant,
  intendingPregnant,
  breastFeeding,
  sex,
  currentOralMedication,
  historicalOralMedication,
  currentTopicalMedication,
  historicalTopicalMedication,
  medicalConditions,
  hasProcedures,
  hasConditions,
  patientId,
  needsDoctorReview,
  extraDetails,
  weightLoss,
}: any) => {
  const classes = useStyles();
  const history = useHistory();
  const userType = useSelector(({ auth }: any) => auth.userType);
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const queryClient = useQueryClient();
  const mutation = useMutation(markAsSeen);

  const isADoctor = userType === PHYSICIAN_ROLE;

  const details = [
    { label: 'Biological sex', value: sex === FEMALE ? 'Female' : 'Male' },
    { label: 'Known allergies', value: knownAllergies },
    { label: 'Medication allergies', value: medicationAllergies },
    { label: 'Interested in weight lost', value: weightLoss },
    { label: 'Pregnant', value: pregnant, hide: sex !== FEMALE },
    { label: 'Intending to become pregnant', value: intendingPregnant, hide: sex !== FEMALE || pregnant === 'Yes' },
    { label: 'Breast feeding', value: breastFeeding, hide: sex !== FEMALE },
    { label: 'Current oral medication', value: currentOralMedication },
    { label: 'Historical oral medication', value: historicalOralMedication },
    { label: 'Current topical medication', value: currentTopicalMedication },
    { label: 'Historical topical medication', value: historicalTopicalMedication },
    { label: 'Medical condition', value: medicalConditions },
    { label: 'Previous procedures', value: hasProcedures },
    { label: 'Previous conditions', value: hasConditions },
    { label: 'Extra details', value: extraDetails },
  ];

  const navigateMedicalProfileForm = () => {
    history.push(`/patient/${patientId}/medicalProfile`);
  };

  const markProfileAsSeen = async () => {
    await mutation.mutateAsync({
      patientId,
    });

    queryClient.invalidateQueries([MEDICAL_PROFILE, patientId]);
  };

  const TitleLabel =
    isADoctor && needsDoctorReview ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        Medical Profile
        <Tooltip title="this profile has changed since your last visit">
          <Update color="inherit" style={{ marginLeft: '.2rem' }} />
        </Tooltip>
      </div>
    ) : (
      'Medical Profile'
    );

  const MarkAsSeenButton = () =>
    mutation.isLoading ? (
      <CircularProgress />
    ) : (
      <IconButton
        size="small"
        onClick={markProfileAsSeen}
        title="Mark as seen"
        style={{ color: '#000000', backgroundColor: '#e7eeed' }}
        Icon={<DoneAll />}
      />
    );

  return (
    <CardContent className={classes.cardContent}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title title={TitleLabel} style={{ marginTop: '0', marginBottom: '15px' }} />
        <div>
          {isADoctor && needsDoctorReview && <MarkAsSeenButton />}
          {hasAccessTo(UPDATE_PATIENT_MEDICAL_PROFILE, permissions) && (
            <IconButton
              onClick={navigateMedicalProfileForm}
              title="View Details"
              style={{ color: '#000000', backgroundColor: '#e7eeed' }}
            />
          )}
        </div>
      </div>
      <div>
        <Details details={details} />
      </div>
    </CardContent>
  );
};

export default MedicalProfile;
