import _ from 'lodash';
import React, { useState } from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CallMade as LearnMoreIcon } from '@material-ui/icons';
import { useInventoryBudget } from 'src/hooks/queries/useInventoryBudget';
import { formatCurrency } from 'src/utils/formatNumber';
import { IDashboardItem } from 'src/interfaces/reconciliation.interfaces';
import { useSelector } from 'react-redux';
import { hasFeatureEnabled } from 'src/utils/auth.utils';
import { FEATURE_INVENTORY_BUDGET, FEATURE_INVENTORY_VALUE } from 'src/constants/features.constants';
import { RootState } from 'src/rematch';
import { dashboardListValue } from 'src/utils/inventory.utils';
import { useStyles } from './budgetPanel.styles';

interface BudgetPanelParams {
  hideHeader?: boolean;
  totalInventoryValue?: number;
  adminView?: boolean;
  practitionerId?: number;
}

interface BudgetPanelHeaderParams {
  hideHeader?: boolean;
  hideBudget?: boolean;
  credit?: number | null;
  value?: number | null;
}

const BudgetPanelHeader: React.FC<BudgetPanelHeaderParams> = ({
  hideHeader = false,
  hideBudget = false,
  credit,
  value,
}: BudgetPanelHeaderParams): React.ReactElement | null => {
  const classes = useStyles();

  return hideHeader ? null : (
    <Box className={classes.budgetDetails} data-testid="budget content">
      <Box data-testid="budget credit">{formatCurrency((hideBudget ? value : credit) ?? 0)}</Box>
      <Box className={classes.budgetTag}>{hideBudget ? 'Total Inventory Value' : 'Budget'}</Box>
      <Box>
        Learn More <LearnMoreIcon />
      </Box>
    </Box>
  );
};

const BudgetPanel = ({ hideHeader = false, totalInventoryValue, adminView, practitionerId }: BudgetPanelParams) => {
  const [valueTooltipOpen, setValueTooltipOpen] = useState<boolean>(false);
  const [budgetTooltipOpen, setBudgetTooltipOpen] = useState<boolean>(false);

  const { featureList } = useSelector(({ auth }: RootState) => auth);
  const { dashboardList } = useSelector(({ inventoryDefault }: RootState) => ({ ...inventoryDefault }));
  const hasBudgetEnabled = adminView || hasFeatureEnabled(FEATURE_INVENTORY_BUDGET, featureList);
  const hasValueEnabled = adminView || hasFeatureEnabled(FEATURE_INVENTORY_VALUE, featureList);

  const { data: credit, isLoading } = useInventoryBudget({
    disabled: !hasBudgetEnabled || (adminView && !practitionerId),
    practitionerId: (adminView && practitionerId) || undefined,
  });

  const value = totalInventoryValue ?? (hasValueEnabled ? dashboardListValue(dashboardList as IDashboardItem[]) : 0);

  const hasAvailableCredit = !!credit && credit > value;
  const availableCredit = hasAvailableCredit ? credit - value : 0;
  const progressFillPercent = `${hasAvailableCredit ? _.round((value / credit) * 100, 0) : 100}%`;

  const classes = useStyles();

  if (!hasValueEnabled) {
    return null;
  }

  return (
    <Box
      className={classes.inventoryBudget}
      data-testid="budget"
      data-minimal={hideHeader}
      data-budget={hasBudgetEnabled}
    >
      {isLoading ? (
        <Skeleton variant="rect" className={classes.loading} data-testid="budget loading" />
      ) : (
        <>
          <BudgetPanelHeader hideHeader={hideHeader} hideBudget={!hasBudgetEnabled} credit={credit} value={value} />
          {hasBudgetEnabled && (
            <>
              <Box className={classes.progressFill} data-testid="full budget content" data-minimal={hideHeader}>
                <Box>
                  <Box style={{ width: progressFillPercent }} />
                </Box>
              </Box>
              <Box className={classes.legend}>
                <Box
                  onMouseEnter={() => setValueTooltipOpen(true)}
                  onMouseLeave={() => setValueTooltipOpen(false)}
                  data-testid="launch value tooltip"
                >
                  <Box />
                  <Typography className={classes.legendKey} data-minimal={hideHeader}>
                    Total Inventory Value:{' '}
                    <Tooltip
                      open={valueTooltipOpen}
                      title="Total value of all the products you currently have in stock."
                      classes={{ tooltip: classes.tooltip }}
                      arrow
                    >
                      <span data-testid="budget value">{formatCurrency(_.round(value || 0, 2))}</span>
                    </Tooltip>
                  </Typography>
                </Box>
                <Box
                  onMouseEnter={() => setBudgetTooltipOpen(true)}
                  onMouseLeave={() => setBudgetTooltipOpen(false)}
                  data-testid="launch budget tooltip"
                >
                  <Box />
                  <Typography className={classes.legendKey} data-minimal={hideHeader}>
                    Available Credit:{' '}
                    <Tooltip
                      open={budgetTooltipOpen}
                      title="Total value of additional products you can purchase."
                      classes={{ tooltip: classes.tooltip }}
                      arrow
                    >
                      <span data-testid="budget available">{formatCurrency(_.round(availableCredit, 2))}</span>
                    </Tooltip>
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export { BudgetPanel, BudgetPanel as default };
