import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  serviceContainer: {
    marginBottom: '24px',
  },
  card: {
    margin: '15px',
    paddingBottom: '24px !important',
    padding: '24px 30px',
    background: '#F5F5F5',
    marginTop: '-68px',
    marginLeft: '10px',
    border: '5px solid transparent',
    borderRadius: '15px',
    marginBottom: '5px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  serviceName: {
    marginBottom: '20px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  inputsSection: {
    display: 'flex',
    margin: '0 8px',
  },
  textField: {
    '& fieldset': {
      borderColor: '#979797',
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontFamily: 'Messina Sans Regular',
    },
  },
  checkoutButton: {
    background: '#12574D',
    color: 'white',
    fontSize: '12px',
    padding: '1rem 2rem',
    borderRadius: '8px',
    margin: '1rem 0',
    width: '400px',
    textAlign: 'center',
    textTransform: 'none',
    '&:disabled': {
      color: 'white',
    },
  },
}));
