import { CardContent, CircularProgress, Table, TableBody, TableRow } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DeleteForever } from '@material-ui/icons';
import { useStyles } from './ProductsScanStep.styles';
import { dispatch } from '../../../rematch';
import { Button } from '../../../components/common/Button';
import Scanner from '../../../components/common/Scanner';
import { IAsset } from '../../../interfaces/reconciliation.interfaces';
import compile from '../../../utils/toastMessagesCompiler';

import { TableCellCustom } from '../../../components/NewServiceVisit/scanner/ServiceVisitScanner.styles';
import { getHumanReadableInternalSerialNumber } from '../../../utils/inventory.utils';
import IServices from '../../../interfaces/IServices';
import { RETAIL_SERVICE_GROUP_ID } from '../../../constants/retail.constants';
import { ManualForm } from '../../../components/NewServiceVisit/scanner/ManualForm';
import { formatNumber } from '../../../utils/checkout.utils';
import { hasAccessTo } from '../../../utils/auth.utils';
import { READ_INVENTORY } from '../../../constants/actions.constants';
import { ILineItem, IServiceVisitAssetScanned } from '../../../interfaces/IServiceVisits';
import { getServiceUnitsScanned, getServiceUnitsToScan } from '../../../utils/serviceVisit/scanner.utils';
import { showSnackbar } from '../../../utils/global';
import { SNACKBAR_WARNING } from '../../../constants/general.constants';

export const ProductsScanStep = ({
  patientId,
  selectedServices,
  lineItems,
}: {
  patientId: string;
  selectedServices: number[];
  lineItems: ILineItem[];
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { permissions } = useSelector(({ auth }: any) => auth);
  const scannerInputRef = useRef<HTMLInputElement>(null);
  const { isProgressQueueWorking } = useSelector((state: any) => state.newServiceVisit);
  const [processing, setProcessing] = useState<boolean>(false);
  const { services = [] }: { services: IServices[] } = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const [serviceVisitAssets, setServiceVisitAssets] = useState<IServiceVisitAssetScanned[]>([]);
  const [showInputs, setShowInputs] = useState<Record<string, boolean>>({});
  const [lastSerialNumberAdded, setLastSerialNumberAdded] = useState<string>('');
  const [subTotal, setSubTotal] = useState<number>(0);

  const createAndNavigateToCheckout = async () => {
    setProcessing(true);
    dispatch({
      type: 'patient/purchaseServicesAndProducts',
      payload: {
        patientId,
        lineItems,
        serviceVisitAssets,
        successCallback: (serviceVisit: IServices) => {
          setProcessing(false);
          history.push(`/patient/${patientId}/newServiceVisit/${serviceVisit.id}/checkout`);
        },
      },
    });
  };

  useEffect(() => {}, [lineItems, serviceVisitAssets]);
  const serviceToIdMap = useMemo((): Record<string, IServices> | undefined => {
    if (services.length) {
      return services.reduce((obj, service) => ({ ...obj, ...{ [service.id]: service } }), {});
    }
    return undefined;
  }, [services]);

  useEffect(() => {
    if (serviceToIdMap) {
      let currentTotal = 0;

      lineItems.forEach((lineItem) => {
        const service = serviceToIdMap[lineItem.serviceId];
        currentTotal += service.price * lineItem.quantity;
      });

      setSubTotal(currentTotal);
    }
  }, [lineItems, serviceToIdMap]);

  const servicesToScan = useMemo(() => {
    if (!hasAccessTo(READ_INVENTORY, permissions)) {
      return [];
    }
    const pendServices: any[] = [];
    if (serviceToIdMap && lineItems.length > 0) {
      lineItems.forEach((lineItem) => {
        const service = serviceToIdMap[lineItem.serviceId];

        if (
          lineItem.currentUseQuantity > 0 &&
          !service.untracked &&
          service.serviceGroupId === RETAIL_SERVICE_GROUP_ID
        ) {
          const serviceScannedTimes = serviceVisitAssets.filter((asset) => asset.serviceId === service.id).length || 0;

          const pending = lineItem.currentUseQuantity - serviceScannedTimes;

          if (pending) {
            pendServices.push({ ...service, ...{ pending } });
          }
        }
      });
    }
    return pendServices;
  }, [lineItems, serviceToIdMap, serviceVisitAssets, permissions]);

  const validUnitsSelected = lineItems.every((lineItem) => lineItem.quantity && lineItem.quantity > 0);

  const isServiceSelected = (serviceId: number) => services.some(({ id }) => id === serviceId);

  const isAssetAlreadySelected = (code: string) =>
    serviceVisitAssets.some((asset) => asset.internalSerialNumber === code);

  const handleAssetChecked = (asset: IAsset) => {
    if (!isServiceSelected(asset.serviceId)) {
      showSnackbar(compile('new_service_visit.asset_not_in_service_selected'));
      return;
    }

    const lineItem = lineItems.find(({ serviceId }) => serviceId === asset.serviceId);
    const service = services.find(({ id }) => id === asset.serviceId);
    const unitsAllowedToScan = service ? getServiceUnitsToScan(service, lineItems) : 0;

    if (isAssetAlreadySelected(asset.internalSerialNumber)) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('new_service_visit.product_already_added'),
          type: 'error',
        },
      });
      return;
    }

    if (unitsAllowedToScan === 0) {
      const warning = compile(
        lineItem?.futureUseQuantity ? 'new_service_visit.asset_for_future_use' : 'new_service_visit.no_units_selected'
      );
      showSnackbar(warning, SNACKBAR_WARNING);
      return;
    }

    const serviceVisitAssetForService = serviceVisitAssets.filter(({ serviceId }) => serviceId === asset.serviceId);
    const sumOfScannedUnits = getServiceUnitsScanned(serviceVisitAssetForService, asset.allowPartialSale);

    // Check if the amount of scanned products reach the limit
    if (sumOfScannedUnits >= unitsAllowedToScan) {
      showSnackbar(compile('new_service_visit.reached_asset_limit', { serviceName: asset.name }), SNACKBAR_WARNING);
      return;
    }

    setServiceVisitAssets((prev: any) => [
      ...prev,
      {
        assetId: asset.id,
        serviceId: asset.serviceId,
        units: 1,
        createdAt: asset.createdAt,
        updatedAt: asset.updatedAt,
        internalSerialNumber: asset.internalSerialNumber,
        name: asset.name,
        lot: asset.lot,
        expireAt: asset.expireAt,
      },
    ]);

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.product_added'),
      },
    });
  };

  const toggleInputs = (key: string) => {
    const newShowInputs = { ...showInputs };
    newShowInputs[key] = !newShowInputs[key];
    setShowInputs(newShowInputs);
  };

  const onAddProduct = (text: string) => {
    setLastSerialNumberAdded(text);
    setTimeout(() => setLastSerialNumberAdded(''), 800);
  };

  const renderInventoryToScan = () => {
    const itemsToScan: any[] = [];
    servicesToScan.forEach((service) => {
      for (let i = 0; i < service.pending; i++) {
        const id = `${service.id}-${i}`;
        itemsToScan.push(
          <ManualForm
            key={id}
            service={service}
            showInputs={showInputs[id]}
            units={
              service.allowPartialSale
                ? lineItems.find(({ serviceId }) => serviceId === service.id)?.currentUseQuantity
                : 1
            }
            toggleInputs={toggleInputs}
            id={id}
            saveCallback={(text: string) => onAddProduct(text)}
          />
        );
      }
    });
    return itemsToScan;
  };

  const handleUndo = (assetId: number) => {
    const newServiceVisitAssets = serviceVisitAssets.filter(({ assetId: id }: any) => id !== assetId);
    setServiceVisitAssets(newServiceVisitAssets);
  };

  const checkoutDisabled =
    !validUnitsSelected || servicesToScan?.length || !selectedServices.length || processing || isProgressQueueWorking;

  const ProductList = () => (
    <div className={classes.productListContainer}>
      {renderInventoryToScan()}
      <Table>
        <TableBody>
          {serviceVisitAssets.map((asset: any) => (
            <TableRow
              key={`${asset.serviceId}-${asset.assetId}`}
              className={
                asset.internalSerialNumber === lastSerialNumberAdded
                  ? classes.productScannedTransition
                  : classes.productScanned
              }
            >
              <TableCellCustom
                style={{ fontSize: '12px', border: 'none', fontWeight: 'bold', maxWidth: '150px' }}
                colSpan={2}
              >
                {services.find(({ id }) => id === asset.serviceId)?.name}
              </TableCellCustom>
              <TableCellCustom style={{ fontSize: '12px', border: 'none', minWidth: '67px' }}>
                {asset.requestedUnits && <>Qty: {asset.requestedUnits}</>}
              </TableCellCustom>
              <TableCellCustom style={{ fontSize: '12px', border: 'none' }}>
                Lot: {asset.lot?.toUpperCase()}
              </TableCellCustom>
              <TableCellCustom style={{ fontSize: '12px', border: 'none' }}>
                Exp: {moment(asset.expireAt).format('MM/DD/YYYY')}
              </TableCellCustom>
              <TableCellCustom style={{ fontSize: '12px', border: 'none' }}>
                {!!asset.internalSerialNumber && getHumanReadableInternalSerialNumber(asset.internalSerialNumber)}
              </TableCellCustom>
              <TableCellCustom style={{ fontSize: '12px', border: 'none', paddingTop: '8px' }} align="right">
                <DeleteForever onClick={() => handleUndo(asset.assetId)} className={classes.undoIcon} />
              </TableCellCustom>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );

  return (
    <CardContent className={classes.card}>
      <div className={classes.title}>Checkout</div>
      {hasAccessTo(READ_INVENTORY, permissions) && Boolean(servicesToScan.length) && (
        <Scanner successCallback={(asset) => handleAssetChecked(asset)} scannerInputRef={scannerInputRef} />
      )}
      <ProductList />
      <p>
        By processing this payment I confirm the patient has provided verbal or written consent to charge the credit
        card on file for the amount charged.
      </p>
      <Button
        disabled={checkoutDisabled}
        className={classes.checkoutButton}
        /* eslint-disable no-nested-ternary */
        // @ts-ignore
        title={
          processing ? (
            <>
              Processing
              <CircularProgress size={20} className={classes.processing} />
            </>
          ) : subTotal > 0 ? (
            `Check Out: $${formatNumber(subTotal)}`
          ) : (
            'Check Out'
          )
        }
        onClick={createAndNavigateToCheckout}
      />
    </CardContent>
  );
};
