import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '0',
    marginBottom: '9px',
  },
  creditsTable: {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    textAlign: 'left',
    width: '100%',
    color: '#3A3B3F',
    '& thead tr th:first-child': {
      paddingLeft: '20px',
      width: '80%',
    },
    '& tbody tr td:first-child': {
      paddingLeft: '20px',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#F2F5F5',
    },
    '& tbody tr:last-child': {
      borderBottom: '1px solid transparent',
    },
  },
  creditsRow: {
    height: '56px',
    borderBottom: '1px solid #E3E3E3',
  },
  creditsHeader: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
  },
  creditsData: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'Messina Sans Regular',
    fontSize: '14px',
  },
  zeroCredits: {
    textAlign: 'center',
    padding: '15px'
  }
}));
