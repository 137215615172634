import React from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { Grid, Card, CardContent, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Photos from './Photos';
import { dispatch } from '../../rematch';
import { ACTIVITY_LOGS } from '../../hooks/queries/useActivityLogs';
import { PATIENT_PHOTOS, CUSTOMER_PHOTOS } from '../../constants/reactQuery.keys';
import { useCustomerPhotos } from '../../hooks/queries/useCustomerPhotos';
import useWindowDimensions from '../../utils/useWindowDimensions';
import {
  ITEMS_PHOTOS_ANNOTATIONS_LIMIT_DESKTOP,
  ITEMS_PHOTOS_ANNOTATIONS_LIMIT_MOBILE,
  WIDTH_MAX_DESKTOP,
} from '../../constants/general.constants';

const useStyles = makeStyles(() => ({
  cardStyles: {
    marginBottom: '10px',
    boxShadow: 'none',
    borderRadius: '9px',
  },
  noPadding: {
    padding: '0 !important',
  },
}));

export const PhotoContainer = () => {
  const { isUploadingPhoto } = useSelector(({ patient }: any) => patient);
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { patientId }: any = useParams();
  const { width } = useWindowDimensions();
  const { isLoading, data: customerPhotos = [] } = useCustomerPhotos(patientId);
  const photosBefore = customerPhotos.filter((photo) => photo.photoType === 'before');
  const photosAfter = customerPhotos.filter((photo) => photo.photoType === 'after');
  const ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE =
    width > WIDTH_MAX_DESKTOP ? ITEMS_PHOTOS_ANNOTATIONS_LIMIT_DESKTOP : ITEMS_PHOTOS_ANNOTATIONS_LIMIT_MOBILE;

  const uploadPhoto = (files: File[]) => {
    // technical debt, find what triggers uploadPhotos from Photos.tsx and remove if (work around)
    // steps to reproduce, upload photo on patient profile, navigate to new service visit, navigate back to
    // patient profile through navigation header
    if (patientId) {
      const callback = () => {
        queryClient.invalidateQueries([ACTIVITY_LOGS, patientId]);
        queryClient.invalidateQueries([PATIENT_PHOTOS, patientId]);
        queryClient.invalidateQueries([CUSTOMER_PHOTOS, patientId]);
      };
      dispatch({ type: 'patient/uploadPhotosRequest', payload: { files, id: patientId, callback } });
    }
  };

  return (
    <Grid container justify="space-between">
      <Grid item xs={6} style={{ padding: '10px 10px 10px 0px', display: 'grid' }}>
        <Card className={classes.cardStyles}>
          <CardContent className={classes.noPadding}>
            <Photos
              cardStyles={{ marginBottom: '10px' }}
              inputFileId="patientPhoto"
              title="Before photos"
              photos={photosBefore}
              itemsPhotos={ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE}
              isUploadingPhoto={isUploadingPhoto}
              isLoading={isLoading}
              uploadPhotosCallback={uploadPhoto}
              showUploadPhotoButton={false}
              patientProfile
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} style={{ padding: '10px 0px 10px 10px', display: 'grid' }}>
        <Card className={classes.cardStyles}>
          <CardContent className={classes.noPadding}>
            <Photos
              cardStyles={{ marginBottom: '10px' }}
              inputFileId="patientPhoto"
              title="After photos"
              photos={photosAfter}
              itemsPhotos={ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE}
              isUploadingPhoto={isUploadingPhoto}
              isLoading={isLoading}
              uploadPhotosCallback={uploadPhoto}
              patientId={patientId}
              showUploadPhotoButton={false}
              patientProfile
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
