import React from 'react';
import { TextField } from '@material-ui/core';

import { useStyles, useNewStyles } from './index.styles';

type Props = {
  errors: any;
  register: any;
  setValue: any;
  selectedLookingToDoOption: string;
  disabledEmail?: boolean;
  handleSelect: (lookingToDo: string) => void;
};

const LAUNCH_NEW_PRACTICE = 'launch-new-practice';
const SCALE_EXISTING_PRACTICE = 'scale-existing-practice';

export const UserForm = ({
  errors,
  register,
  setValue,
  selectedLookingToDoOption,
  disabledEmail = false,
  handleSelect,
}: Props) => {
  const classes = useStyles();
  const newClasses = useNewStyles();

  return (
    <>
      <h2 className={classes.h2}>Get started</h2>

      <TextField
        className={classes.signupField}
        data-cy="firstNameInput"
        fullWidth
        name="firstName"
        error={!!errors.firstName}
        helperText={errors.firstName?.message || ''}
        onChange={(event) => setValue('firstName', event.target.value.replace(/[^a-zA-Z\s]/g, ''))}
        inputRef={register}
        label="First Name*"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        inputProps={{
          pattern: '[A-Za-z ]+',
          title: 'Only letters are allowed',
        }}
      />
      <TextField
        className={classes.signupField}
        data-cy="lastNameInput"
        fullWidth
        name="lastName"
        error={!!errors.lastName}
        helperText={errors.lastName?.message || ''}
        onChange={(event) => setValue('lastName', event.target.value.replace(/[^a-zA-Z\s]/g, ''))}
        inputRef={register}
        label="Last Name*"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        inputProps={{
          pattern: '[A-Za-z ]+',
          title: 'Only letters are allowed',
        }}
      />
      <TextField
        className={classes.signupField}
        data-cy="phoneInput"
        fullWidth
        name="phone"
        error={!!errors.phone}
        helperText={errors.phone?.message || ''}
        onChange={(event) => setValue('phone', event.target.value)}
        inputRef={register}
        label="Phone Number*"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        className={classes.signupField}
        data-cy="emailInput"
        fullWidth
        name="email"
        error={!!errors.email}
        helperText={
          errors.email?.message ? errors.email.message.charAt(0).toUpperCase() + errors.email.message.substring(1) : ''
        }
        onChange={(event) => setValue('email', event.target.value.toLowerCase())}
        inputRef={register}
        label="Email*"
        type="email"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        disabled={disabledEmail}
      />

      <TextField
        className={classes.signupField}
        data-cy="businessNameInput"
        fullWidth
        name="businessName"
        error={!!errors.businessName}
        helperText={errors.businessName?.message || ''}
        onChange={(event) => setValue('businessName', event.target.value)}
        inputRef={register}
        label="Business Name"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        placeholder="Optional"
      />

      <div className={newClasses.lookingForContainer}>
        <p>What are you looking to do? *</p>
        <div className={newClasses.lookingForOptionsContainer}>
          <div
            aria-hidden="true"
            className={`${newClasses.lookingForOption} ${
              selectedLookingToDoOption === SCALE_EXISTING_PRACTICE
                ? newClasses.selectedStyles
                : newClasses.unselectedStyles
            }`}
            onClick={() => handleSelect(SCALE_EXISTING_PRACTICE)}
          >
            Scale Existing Practice
          </div>
          <div
            aria-hidden="true"
            className={`${newClasses.lookingForOption} ${
              selectedLookingToDoOption === LAUNCH_NEW_PRACTICE
                ? newClasses.selectedStyles
                : newClasses.unselectedStyles
            }`}
            onClick={() => handleSelect(LAUNCH_NEW_PRACTICE)}
          >
            Launch New Practice
          </div>
        </div>
      </div>
    </>
  );
};
