import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  parentGrid: {
    padding: '16px',
    justifyContent: 'space-around',
  },
  links: {
    color: '#000000',
    textDecoration: 'none',
    underline: 'none',
  },
  lastLink: {
    color: '#000000',
    display: 'flex',
    justifyContent: 'flex-end',
    textDecoration: 'none',
  },
});

export default useStyles;
