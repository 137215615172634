import { makeStyles } from '@material-ui/core';

export const useRowStyles = makeStyles({
  root: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    '& > *': {
      border: '0 !important',
    },
  },
  email: {
    textTransform: 'lowercase',
  },
  menuIcon: {
    border: '1px solid #E4E7EB',
    borderRadius: '2px',
  },
});

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: '18px',
    fontFamily: 'Messina Sans SemiBold',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  formControl: {
    width: '100%',
  },
  formInput: {
    color: '#000000',
    fontFamily: 'Messina Sans Regular',
    fontSize: '15px',
  },
  add: {
    width: '100%',
    height: '55px',
  },
  medicalMembers: {
    alignSelf: 'end',
  },
  checkbox: {
    color: '#12574d',
  },
}));
