/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import classnames from 'classnames';
import { useNotClearedStyles, NotClearedButton } from './Appointments.styles';
import { UNKNOWN_STATUS, NOT_CLEARED_STATUS } from '../../../constants/scheduler.constants';
import { getPluralWord } from '../../../utils/inventory.utils';
import { getViewAppointments } from '../../../utils/scheduler.utils';
import { ROUTES } from '../../../constants/routes.constants';

interface Props {
  appointments: any[];
  view: string;
  currentDate: any;
  handleChangeView: (view: string) => void;
  setCurrentDate: (date: string) => void;
  handleNavigatorChange: (action: string) => void;
}

const AppointmentsNotClearedTab = ({
  appointments,
  view,
  currentDate,
  handleChangeView,
  setCurrentDate,
  handleNavigatorChange,
}: Props) => {
  const classes = useNotClearedStyles();
  const history = useHistory();
  const [opened, setOpened] = useState(false);

  const toggleNotClearedPopUp = () => {
    setOpened(!opened);
  };

  const goToPatient = (customerId: string) => {
    if (customerId) {
      history.push(ROUTES.PATIENT_ID(customerId), { from: history.location.pathname });
    }
  };

  const handleDatePicker = (newDate: any) => {
    const to = newDate.isSameOrAfter(currentDate) ? 'forward' : 'back';
    setCurrentDate(newDate.format('YYYY-MM-DD'));
    handleNavigatorChange(to);
  };

  const handleDayPicker = (newDate: any) => {
    handleChangeView('Day');
    setCurrentDate(newDate.format('YYYY-MM-DD'));
  };

  const customerList = getViewAppointments(appointments, currentDate, view, [UNKNOWN_STATUS, NOT_CLEARED_STATUS]);
  const notClearedCount = customerList.filter((appointment: any) => appointment.statusId === NOT_CLEARED_STATUS).length;
  const unknownCount = customerList.filter((appointment: any) => appointment.statusId === UNKNOWN_STATUS).length;

  return (
    <div>
      <div
        className={classes.opacityFont}
        style={{ display: opened ? 'initial' : 'none' }}
        onClick={toggleNotClearedPopUp}
      />
      <div
        className={classes.notClearedPopUp}
        data-cy="notClearedModal"
        style={{ display: opened ? 'initial' : 'none' }}
      >
        <div className={classes.header}>
          {customerList.length} {getPluralWord('Patient', customerList.length > 1)}
        </div>

        <div className={classes.buttonsSection}>
          {customerList.map(({ id, patient, customerId, statusId }: any) => (
            <NotClearedButton
              className={classnames({ [classes.unknownButton]: statusId === UNKNOWN_STATUS })}
              key={id}
              disabled={statusId === UNKNOWN_STATUS}
              onClick={() => goToPatient(customerId)}
              data-cy="notClearedPatientPill"
            >
              {patient}
            </NotClearedButton>
          ))}
        </div>

        <div className={classes.miniCalendarContainer}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              variant="static"
              openTo="date"
              value={currentDate}
              disableToolbar
              onChange={handleDayPicker}
              onMonthChange={handleDatePicker}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>

      {notClearedCount > 0 && (
        <div style={{ display: 'block' }}>
          <div
            className={classnames(classes.notClearedTab, { [classes.hasMoreTabs]: unknownCount })}
            onClick={toggleNotClearedPopUp}
            data-cy="notClearedButton"
          >
            {notClearedCount} Not Cleared
          </div>
        </div>
      )}
      {unknownCount > 0 && (
        <div style={{ display: 'block' }}>
          <div
            className={classnames(classes.notClearedTab, classes.unknownTab, { [classes.extraTab]: notClearedCount })}
            onClick={toggleNotClearedPopUp}
          >
            {unknownCount} Unknown
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentsNotClearedTab;
