import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '5px',
    padding: '17px 27px',
    backgroundColor: '#E7EEED',
    borderRadius: '6px',
  },
  subTotalLineItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    letterSpacing: '-0.06px',
    lineHeight: '25px',
  },
  amount: {
    width: '120px',
    textAlign: 'right',
  },
  totalLineItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: 'Messina Sans Bold',
    fontSize: '21px',
    letterSpacing: '-0.07px',
  },
}));

interface Props {
  subTotal: string;
  discounts: string;
  total: string;
}
interface ISubTotalLineItemProps {
  title: string | React.ReactNode;
  amount: string;
  negative?: boolean; // eslint-disable-line react/no-unused-prop-types
}

export const AmountsSection = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export const BoldLineItem = ({ title, amount }: ISubTotalLineItemProps) => {
  const classes = useStyles();

  return (
    <div className={classes.totalLineItem}>
      <div>{title}</div>
      <div className={classes.amount}>${amount}</div>
    </div>
  );
};

export const SubTotalLineItem = ({ title, amount, negative }: ISubTotalLineItemProps) => {
  const classes = useStyles();

  return (
    <div className={classes.subTotalLineItem}>
      <div
        style={{
          fontFamily: 'Arial',
          fontSize: '15px',
        }}
      >
        {title === 'All&#x0113;' ? <>All&#x0113;</> : title}
      </div>
      <div className={classes.amount}>
        {negative && '- '}${amount}
      </div>
    </div>
  );
};

export const DiscountsSubTotal = ({ subTotal, discounts, total }: Props) => (
  <div style={{ marginTop: '20px' }}>
    <AmountsSection>
      <SubTotalLineItem title="Subtotal" amount={subTotal} />
      {+discounts > 0 && <SubTotalLineItem title="Discounts" amount={discounts} negative />}
      <BoldLineItem title="Total" amount={total} />
    </AmountsSection>
  </div>
);
