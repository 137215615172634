import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Wrapper from '../components/common/Wrapper';
import HistorySearchInput from '../components/FullServiceHistory/HistorySearchInput';
import TableHistory from '../components/FullServiceHistory/TableHistory';
import { dispatch } from '../rematch';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import IServiceVisit from '../interfaces/IServiceVisits';
import { PRACTITIONER_ROLE, PHYSICIAN_ROLE } from '../constants/general.constants';
import { ROUTES } from '../constants/routes.constants';
import { IN_PERSON_VISIT_TYPE } from '../services/ServiceVisit';

const FullServiceHistoryPage = ({
  match: {
    params: { patientId },
  },
}: any) => {
  const history = useHistory();
  const { showMasqueradeBar } = useSelector(({ masquerade }: any) => masquerade);

  const {
    serviceVisits,
    effects: {
      patient: { fetchBasicInfo: isLoadingFetchBasicInfo },
      fullServiceHistory: { getFullServiceHistory: isLoadingGetFullServiceHistory },
    },
  } = useSelector(({ fullServiceHistory, loading }: any) => ({ ...fullServiceHistory, ...loading }));

  const { userType } = useSelector(({ auth }: any) => auth);

  useEffect(
    () => () => {
      dispatch.fullServiceHistory.setResetFullServiceVisits();
      dispatch({ type: 'newServiceVisit/cleanServiceVisitData' });
    },
    []
  );

  useEffect(() => {
    dispatch.fullServiceHistory.getFullServiceHistory({ patientId });
    dispatch.patient.fetchPatientData(+patientId);
  }, [patientId]);

  const navigate = () => {
    history.push(ROUTES.PATIENT_ID(patientId));
  };

  // Physicians only can see closed services visits
  const filteredServiceVisit =
    userType === PHYSICIAN_ROLE
      ? serviceVisits.filter(({ opened, visitType }: IServiceVisit) => !opened && visitType === IN_PERSON_VISIT_TYPE)
      : serviceVisits.filter(({ visitType }: IServiceVisit) => visitType === IN_PERSON_VISIT_TYPE);

  const showHeader = [PRACTITIONER_ROLE, PHYSICIAN_ROLE].includes(userType);

  const FullServiceHistory = (
    <div style={{ width: '100%' }}>
      {showHeader && <NavigationBar title="Full Service History" onBackButtonClick={navigate} />}
      {isLoadingGetFullServiceHistory && isLoadingFetchBasicInfo ? (
        <MultipleSkeleton />
      ) : (
        <article style={{ padding: '20px 0', width: 'inherit', backgroundColor: 'white' }}>
          <HistorySearchInput />
          <TableHistory data={filteredServiceVisit} />
        </article>
      )}
    </div>
  );

  if (userType === 'Administrator' && !showMasqueradeBar) {
    return <Wrapper>{FullServiceHistory}</Wrapper>;
  }

  return FullServiceHistory;
};

export default FullServiceHistoryPage;
