import React from 'react';
import { useStyles } from './index.styles';
import { ReactComponent as PortraitLogo } from '../../assets/images/portrait-logo.svg';
import { PASSWORD_STEP, SIGNUP_STEP } from './constants';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-small.svg';

export const PractitionerSignupSidebar = ({ step }: { step: number }) => {
  const styles = useStyles();

  const determineStepStyle = (currentStep: number) => {
    if (step > currentStep) {
      return `${styles.signupStep} ${styles.signupStepCompleted} ${styles.cleared}`;
    }
    if (step === currentStep) {
      return `${styles.signupStep} ${styles.signupStepCompleted}`;
    }
    return `${styles.signupStep} ${styles.signupStepIncomplete}`;
  };

  return (
    <div className={styles.sidebarContainer}>
      <PortraitLogo />
      <div className={styles.signupStepsContainer}>
        <div className={determineStepStyle(SIGNUP_STEP)}>
          <SuccessIcon /> Your details
        </div>
        <div className={determineStepStyle(PASSWORD_STEP)}>
          <SuccessIcon /> Choose a password
        </div>
      </div>
    </div>
  );
};
