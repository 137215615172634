import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from 'react-query';
import { dispatch } from 'src/rematch';
import compile from 'src/utils/toastMessagesCompiler';
import { ORDER_PRODUCTS } from 'src/constants/reactQuery.keys';
import { AxiosError } from 'axios';
import Products, {
  IOrderProductAdmin,
  ProductResponse,
  ProductCreateParams,
  ProductImportParams,
} from '../../services/Products';

type PageParams = {
  page?: number;
  limit?: number;
  disabled?: boolean;
};

export function useAllProducts(enabled = true) {
  const response = useQuery(['PRODUCTS'], () => Products.getAllProducts(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled,
  });

  return {
    ...response,
    data: response.data || [],
  };
}

const useProducts = ({ disabled = false, ...pagination }: PageParams = {}): UseQueryResult<ProductResponse> =>
  useQuery<ProductResponse>({
    enabled: !disabled,
    queryKey: [ORDER_PRODUCTS],
    queryFn: () => Products.getProducts(pagination),
    onError: () => {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'fetching',
            element: 'products',
          }),
          type: 'error',
        },
      });
    },
    refetchOnWindowFocus: false,
  });

const useInfiniteProducts = ({ disabled = false, limit = 15 }: PageParams = {}): UseInfiniteQueryResult<
ProductResponse<IOrderProductAdmin>
> =>
  useInfiniteQuery<ProductResponse<IOrderProductAdmin>>({
    enabled: !disabled,
    queryKey: [ORDER_PRODUCTS],
    queryFn: ({ pageParam = 0 }) =>
      Products.getProducts({
        limit,
        page: pageParam === 0 ? 1 : pageParam,
      }) as Promise<ProductResponse<IOrderProductAdmin>>,
    onError: () => {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'fetching',
            element: 'products',
          }),
          type: 'error',
        },
      });
    },
    staleTime: 60000,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    getNextPageParam: (lastPage: ProductResponse<IOrderProductAdmin>) => {
      if (lastPage?.meta?.currentPage < lastPage?.meta?.totalPages) {
        return lastPage.meta.currentPage + 1;
      }
      return false;
    },
  });

const useCreateProduct = (upsert: boolean = false): UseMutationResult<void, unknown, ProductCreateParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: ProductCreateParams): Promise<void> => {
      await Products.createProduct({ upsert, ...params });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [ORDER_PRODUCTS] });
    },
    onError: (error: AxiosError | unknown) => {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'creating',
            element: `product ${(error as AxiosError).response?.data?.errors ?? error}`,
          }),
          type: 'error',
        },
      });
    },
  });
};

const useCreateProducts = (upsert: boolean = false): UseMutationResult<void, unknown, ProductImportParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: ProductImportParams): Promise<void> => {
      await Products.createProducts({ upsert, ...params });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [ORDER_PRODUCTS] });
    },
    onError: (error: AxiosError | unknown) => {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'creating',
            element: `products ${(error as AxiosError).response?.data?.errors ?? error}`,
          }),
          type: 'error',
        },
      });
    },
  });
};

const useUpdateProduct = (id: number): UseMutationResult<void, unknown, Partial<IOrderProductAdmin>, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: Partial<IOrderProductAdmin>): Promise<void> => {
      await Products.updateProduct({ ...params, id });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [ORDER_PRODUCTS] });
    },
    onError: () => {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'updating',
            element: 'product',
          }),
          type: 'error',
        },
      });
    },
  });
};

const useDestroyProduct = (id: number): UseMutationResult<void, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      await Products.destroyProduct(id);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [ORDER_PRODUCTS] });
    },
    onError: () => {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'removing',
            element: 'product',
          }),
          type: 'error',
        },
      });
    },
  });
};

export {
  useCreateProduct,
  useCreateProducts,
  useDestroyProduct,
  useUpdateProduct,
  useInfiniteProducts,
  useProducts,
  useProducts as default,
};
