import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  APPLIED_SERVICE_VISIT_CREDITS,
  PATIENT_REFERRALS,
  SERVICE_VISIT,
  SERVICE_VISIT_CHECKOUT,
  SERVICE_VISIT_CREDITS,
} from '../../constants/reactQuery.keys';
import compile from '../../utils/toastMessagesCompiler';
import { useReactQueryOnErrorHandler } from '../../utils/global';
import Checkout from '../../services/Checkout';
import { ICheckout } from '../../interfaces/checkout.interfaces';
import ServiceVisit from '../../services/ServiceVisit';
import { TRANSACTION_STATUS_PAID } from '../../constants/serviceVisits/serviceVisit.constants';

/* Get service visit checkout object
 * Params: id* (service visit id)
 */
export function useServiceVisitCheckout(id: number) {
  const response = useQuery([SERVICE_VISIT_CHECKOUT, id], () => Checkout.getServiceVisitCheckout(id), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: true,
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'checkout' })),
  });

  return {
    ...response,
    data: response.data || ({} as ICheckout),
    canUpdateAmounts: response.data?.transactionStatus !== TRANSACTION_STATUS_PAID,
  };
}

export interface UpdateCheckoutData {
  accountCreditUsed?: number;
  note?: string;
  checkoutSignature?: string;
}

/* Update service visit checkout object
 * Params: id* (service visit id)
 */
export const useUpdateServiceVisitCheckout = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation((data: UpdateCheckoutData) => Checkout.updateServiceVisitCheckout(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICE_VISIT, id]);
      queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, id]);
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Mark service visit checkout as paid unless for prod
 * Params: id* (service visit id)
 */
export const usePayStagingMutation = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => ServiceVisit.payStagingServiceVisit(serviceVisitId), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
      queryClient.invalidateQueries([SERVICE_VISIT, serviceVisitId]);
      queryClient.invalidateQueries([SERVICE_VISIT_CREDITS, serviceVisitId]);
      queryClient.invalidateQueries([APPLIED_SERVICE_VISIT_CREDITS, serviceVisitId]);
      queryClient.invalidateQueries([PATIENT_REFERRALS]);
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Mark service visit checkout as paid if checkout to pay is zero
 * Params: id* (service visit id)
 */
export const usePayZeroBalanceMutation = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => ServiceVisit.payZeroBalanceServiceVisit(serviceVisitId), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
      queryClient.invalidateQueries([SERVICE_VISIT, serviceVisitId]);
      queryClient.invalidateQueries([SERVICE_VISIT_CREDITS, serviceVisitId]);
      queryClient.invalidateQueries([APPLIED_SERVICE_VISIT_CREDITS, serviceVisitId]);
      queryClient.invalidateQueries([PATIENT_REFERRALS]);
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Charge service visit checkout as with card on file
 * Params: id* (service visit id)
 */
export const usePayServiceVisitWithCardMutation = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      cardId,
      cashAmount,
      patientId,
      tipAmount = 0,
      isFeeCharge,
    }: {
      cardId?: string;
      cashAmount?: number;
      patientId: number;
      tipAmount: number;
      isFeeCharge?: boolean;
    }) =>
      ServiceVisit.payForServiceVisit({ id: serviceVisitId, cardId, cashAmount, patientId, tipAmount, isFeeCharge }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_VISIT, serviceVisitId]);
        queryClient.invalidateQueries([SERVICE_VISIT_CREDITS, serviceVisitId]);
        queryClient.invalidateQueries([APPLIED_SERVICE_VISIT_CREDITS, serviceVisitId]);
        queryClient.invalidateQueries([PATIENT_REFERRALS]);
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};

/* Refund a service visit useless for prod
 * Params: id* (service visit id)
 */
export const useRefundStagingMutation = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => ServiceVisit.refundStagingServiceVisit(serviceVisitId), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
      queryClient.invalidateQueries([SERVICE_VISIT, serviceVisitId]);
      queryClient.invalidateQueries([SERVICE_VISIT_CREDITS, serviceVisitId]);
      queryClient.invalidateQueries([APPLIED_SERVICE_VISIT_CREDITS, serviceVisitId]);
      queryClient.invalidateQueries([PATIENT_REFERRALS]);
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Refund with square stg/prod */
export const useRefundMutation = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => ServiceVisit.refundServiceVisit(serviceVisitId, params), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
      queryClient.invalidateQueries([SERVICE_VISIT, serviceVisitId]);
      queryClient.invalidateQueries([SERVICE_VISIT_CREDITS, serviceVisitId]);
      queryClient.invalidateQueries([APPLIED_SERVICE_VISIT_CREDITS, serviceVisitId]);
      queryClient.invalidateQueries([PATIENT_REFERRALS]);
    },
    onError: useReactQueryOnErrorHandler,
  });
};
