import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';

import { EHRButton, EHRTypography } from '../ui/v1';

export const linkWrapper = (text: string, pdfUrl?: string | null, style?: React.CSSProperties) => {
  if (pdfUrl) {
    return (
      <a href={pdfUrl} target="_blank" rel="noreferrer" style={style}>
        {text}
      </a>
    );
  }
  return text;
};

const useStyles = makeStyles({
  container: {
    marginTop: '32px',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: '1px lightgrey dashed',
    borderRadius: '6px',
    padding: '20px',
  },
  button: {
    width: '80px',
    height: '32px',
  },
  successBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: '1px solid #55B76F',
    borderRadius: '6px',
    padding: '20px',
    backgroundColor: '#EDFCF1',
  },
  textContainer: {
    display: 'flex',
    color: '#55B76F',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '10px',
    gap: '10px',
  },
});

interface SignDocumentButtonProps {
  title: string;
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
  pdf?: string | null;
}

const SignDocumentButton = ({ title, label, onClick, disabled = false, pdf }: SignDocumentButtonProps) => {
  const classes = useStyles();
  const buttonLabel = label || 'Please sign the following document';

  return (
    <Box>
      {!pdf && (
        <EHRTypography variant="body2" dataCy="sign-document-headline" style={{ color: 'grey' }}>
          {buttonLabel}
        </EHRTypography>
      )}
      <Box className={pdf ? classes.successBox : classes.box}>
        <EHRTypography variant="body2" dataCy="sign-document-title">
          {linkWrapper(title, pdf)}
        </EHRTypography>
        {pdf ? (
          <Box className={classes.textContainer}>
            <CheckCircleIcon />
            <EHRTypography variant="body2" dataCy="sign-document-status">
              Signed
            </EHRTypography>
          </Box>
        ) : (
          <EHRButton
            name="Sign"
            dataCy="sign-document-button"
            data-testid="sign-document-button"
            variant="contained"
            color="primary"
            className={classes.button}
            text="Sign"
            onClick={onClick}
            disabled={disabled}
          />
        )}
      </Box>
    </Box>
  );
};

export default SignDocumentButton;
