import { Box, CircularProgress, Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { EHRTypography } from 'src/components/ui/v1';
import { useBankAccount } from 'src/hooks/queries/medspaAdmins/useMedspaBankAccount';

import { RootState } from 'src/rematch';
import { formatAddress } from 'src/utils/onboarding.utils';
import { UserInfoRow } from './common/UserInfoRow';
import useStylesMedspaSettings from './index.styles';

const useStyles = makeStyles(() => ({
  paymentSettingsContainer: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  paymentSettingsToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paymentMethodsListContainer: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '0.625rem',
    border: '1px solid #DFDFDF',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    position: 'relative',
  },
  ccActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  ccDefault: {
    backgroundColor: 'rgba(18, 87, 77, 0.15)',
    padding: '0.125rem 0.5rem',
    color: 'rgba(18, 87, 77, 1)',
    borderRadius: '0.25rem',
  },
  ccNotDefault: {
    color: 'rgba(18, 87, 77, 1)',
    cursor: 'pointer',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.75rem',
    width: '100%',
    '& *': {
      margin: 0,
    },
  },
}));

interface MedspaBackAccountPaymentMethodsSettingsProps {}

const MedspaBackAccountPaymentMethodsSettings = (_props: MedspaBackAccountPaymentMethodsSettingsProps) => {
  const classes = useStyles();
  const classesMedspaSettings = useStylesMedspaSettings();

  const userGroupId = useSelector(({ auth }: RootState) => auth.userGroupId) as number;

  const { data: bankAccount, isLoading: isLoadingBankAccount } = useBankAccount(userGroupId);

  const address = formatAddress(
    bankAccount?.billingAddress?.address ?? '',
    bankAccount?.billingAddress?.city ?? '',
    bankAccount?.billingAddress?.state ?? ''
  );

  let BodyComponent = <CircularProgress />;

  if (!isLoadingBankAccount) {
    if (bankAccount) {
      BodyComponent = (
        <Box className={classesMedspaSettings.medspaSettingsContainer}>
          <UserInfoRow label="Name on Account" userData={bankAccount.name} />
          <Divider />
          <UserInfoRow label="Bank Account Type" userData={bankAccount.type ?? ''} />
          <Divider />
          <UserInfoRow label="Routing Number" userData={bankAccount.routingNumber?.toString()} />
          <Divider />
          <UserInfoRow label="Account Number" userData={bankAccount.accountNumber?.toString()} />
          <Divider />
          <UserInfoRow label="Bank Account Billing Address" userData={address} />
        </Box>
      );
    } else {
      BodyComponent = (
        <EHRTypography dataCy="no-bank-account-found" variant="description">
          No bank account found
        </EHRTypography>
      );
    }
  }

  return (
    <Box className={classes.paymentSettingsContainer}>
      <Box className={classes.paymentSettingsToolbar}>
        <Box>
          <EHRTypography dataCy="title-cards-on-file" variant="h6">
            Bank Account for Rewards
          </EHRTypography>
        </Box>
      </Box>

      {BodyComponent}
    </Box>
  );
};

export default MedspaBackAccountPaymentMethodsSettings;
