import React, { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Modal } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Button } from '../../common/Button';
import { useStyles } from './concentModal.styles';

export const ConsentModal = ({
  isOpen,
  handleCloseCallback,
  consentName,
  consentContent,
  patientNameProp,
  signConsentCallback,
  isSigning,
}: any) => {
  let signCanvas: any;
  const classes = useStyles();
  const [patientName, setPatientName] = useState('');
  const [isSigned, setIsSigned] = useState(false);

  useEffect(() => {
    setPatientName(patientNameProp);
  }, [patientNameProp]);

  const clearSignature = () => {
    setIsSigned(false);
    signCanvas.clear();
  };

  const signConsent = () => {
    const file = signCanvas.getTrimmedCanvas().toDataURL('image/png');
    signConsentCallback(file, patientName);
    setIsSigned(false);
  };

  const onBeginStroke = () => {
    setIsSigned(true);
  };

  return (
    <Modal open={isOpen} onClose={handleCloseCallback}>
      <div style={{ backgroundColor: '#e7eeed', height: '100%', overflowY: 'scroll' }}>
        <div>
          <div>
            <CloseIcon className={classes.closeIcon} onClick={handleCloseCallback} />
            <div className={classes.consentTitle}> {consentName} consent </div>
          </div>
          <hr style={{ border: '0.5px solid lightgray', marginTop: '25px', marginBottom: '20px' }} />
          <div
            className={classes.consentContent}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: consentContent }}
          />
          <hr style={{ border: '0.5px solid lightgray', marginTop: '40px', marginBottom: '20px' }} />
          <div className={classes.secondaryTitle}> Signature of Patient or Guardian </div>
          <div data-cy="signatureCanvas" className={classes.consentSignature}>
            <div className={classes.signatureTitle}>Signature of Patient</div>
            <SignatureCanvas
              canvasProps={{ className: classes.consentSignatureCanvas }}
              ref={(ref) => {
                signCanvas = ref;
              }}
              onBegin={onBeginStroke}
              clearOnResize={false}
            />
          </div>
          <div className={classes.secondaryTitle}> Patient Name </div>
          <div style={{ width: '646px', margin: '0 auto' }}>
            <input
              type="text"
              value={patientName}
              className={classes.patientName}
              onChange={(e) => setPatientName(e.currentTarget.value)}
            />
            <Button className={classes.clearSignatureButton} title="CLEAR SIGNATURE" onClick={clearSignature} />
            <Button
              dataCy="modalSignBtn"
              className={classes.doneSigningButton}
              title={isSigning ? 'SIGNING...' : 'DONE SIGNING'}
              onClick={signConsent}
              disabled={!isSigned || isSigning || !patientName}
              style={{ color: !isSigned || isSigning ? 'white' : undefined }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
