/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, ReactElement, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Tabs, Box, withStyles } from '@material-ui/core';
import _ from 'lodash';
import { TabCustom } from 'src/components/DashboardPractitioner/Tabs/TodoListTabs/tabGreen.styles';
import { useSelector } from 'react-redux';
import { USER_TYPES } from 'src/constants/general.constants';
import { useStyles } from 'src/components/DashboardPractitioner/Tabs/tabs.styles';
import { Practitioners } from '../../../../services/Practitioners';

export interface TabsDescriptor {
  label: string;
  path: string;
  order: number;
  target: ReactElement;
}

export interface TabDataObject {
  [k: string]: TabItem;
}

export interface TabFrameProps {
  tabs: TabDataObject;
  path: string;
  state?: string;
}

export interface TabOuterProps {
  state?: string;
}

export interface TabItem {
  label: string;
  order: number;
  target: React.ReactNode;
}

const TabBanner: ReactElement = withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex !important',
  },
  indicator: {
    backgroundColor: 'black',
    color: 'black',
  },
}))(Tabs);

const TabPanel: React.FC = (props: any): ReactElement => {
  const { children, value, index, noPadding, ...other } = props;

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {' '}
      {value === index && <Box p={noPadding ? 0 : 3}>{children}</Box>}
    </div>
  );
};

export const TabFrame = ({ tabs, path, state }: TabFrameProps) => {
  const [value, setValue] = useState<number | null>(0);
  const tabCount = Object.keys(tabs).length;
  const history = useHistory();
  const classes = useStyles();
  const { user, userType } = useSelector(({ auth }: any) => auth);
  const isMedspaProvider = userType === USER_TYPES.PRACTITIONER && !!user.userGroupId;
  const isMedspaProviderView = Practitioners.isMedspaProvider();

  const tabProps = (index?: string | number) => ({
    id: `simple-tab-${index || '0'}`,
    'aria-controls': `simple-tabpanel-${index || '0'}`,
    key: `bannertab${index || '0'}`,
  });

  useEffect(() => {
    if (tabs[state]) {
      setHistory(tabs[state]?.order);
    }
  }, []);

  const setHistory = (newValue: number) => {
    const values = Array.from(Object.keys(tabs));
    history.push(`${path}/${values[newValue]}`);
    setValue(newValue);
  };

  const handleChange = (event: ChangeEvent<HTMLElement>, newValue: number) => {
    event && setHistory(newValue);
  };

  return isMedspaProvider && isMedspaProviderView ? (
    <>{tabs?.earnings?.target}</>
  ) : (
    <>
      <AppBar style={{ boxShadow: 'none' }} color="white" position="static">
        <TabBanner value={value} onChange={tabCount > 1 ? handleChange : null} aria-label="simple tabs">
          {_.sortBy(Object.values(tabs), (tab: TabsDescriptor) => tab.order).map(
            (tab: TabsDescriptor): ReactElement => (
              <TabCustom
                {...tabProps(0)}
                disableRipple={tabCount < 2}
                label={
                  <div className={classes.inventoryTab} data-cy={`bannerTab${tab.order}`}>
                    {' '}
                    {tab.label}{' '}
                  </div>
                }
              />
            )
          )}
        </TabBanner>
      </AppBar>
      {Object.values(tabs).map(
        (tab: TabsDescriptor): ReactElement => (
          <TabPanel noPadding value={value} index={tab.order} key={`panel${tab.order}`}>
            {tab.target}
          </TabPanel>
        )
      )}
    </>
  );
};
