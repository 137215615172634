import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import { CUSTOMER_PHOTOS } from '../../constants/reactQuery.keys';
import { useStyles } from './photos.styles';
import Id from '../../types/Id';

const MaterialButton = withStyles(() => ({
  disabled: {
    opacity: '0.5',
  },
}))(Button);
interface Props {
  deletePhotoCallback?: Function;
  open: boolean;
  setShowDeleteDialog: Function;
  image: any;
  setImage: Function;
  patientId?: Id;
}

const DeletePhotoDialog = ({
  open = false,
  setShowDeleteDialog,
  deletePhotoCallback = () => {},
  image,
  setImage,
  patientId,
}: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [otherReason, setOtherReason] = useState<string>('');
  const [deletePhotoReasonOption1, setDeletePhotoReasonOption1] = useState<string>('');
  const [deletePhotoReasonOption2, setDeletePhotoReasonOption2] = useState<string>('');

  const deletePhoto = (photoId: number) => {
    const reason = `${deletePhotoReasonOption1} ${deletePhotoReasonOption2} ${otherReason}`;
    deletePhotoCallback(photoId, reason.trim());
    setShowDeleteDialog(false);
    setImage({});
    setOtherReason('');
    setDeletePhotoReasonOption1('');
    setDeletePhotoReasonOption2('');
    queryClient.invalidateQueries([CUSTOMER_PHOTOS, patientId]);
  };

  return (
    <Dialog open={open} onClose={() => setShowDeleteDialog(false)}>
      <div className={classes.containerDialog}>
        <DialogTitle className={classes.title}>
          <div style={{ color: '#F16C00' }}>WARNING</div>
          <div>Why is this photo being deleted?</div>
          {image.thumbnailUrl && <img style={{ margin: '1rem' }} src={image.thumbnailUrl} alt="thumbnail" />}

          <p>
            This photo is a part of the patient&apos;s medical record, a legal document. Removing information from a
            patient&apos;s medical record should only occur when clearly appropriate.
          </p>

          <div className={classes.checkboxSection}>
            <FormControlLabel
              label="Photo is not of this patient"
              control={
                <Checkbox
                  color="default"
                  classes={{ checked: classes.checkbox }}
                  value="Photo is not of this patient."
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDeletePhotoReasonOption2(e.target.value);
                    } else {
                      setDeletePhotoReasonOption2('');
                    }
                  }}
                />
              }
            />
            <FormControlLabel
              label="Duplicate or poor quality photo"
              control={
                <Checkbox
                  color="default"
                  classes={{ checked: classes.checkbox }}
                  value="Duplicate or poor quality photo."
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDeletePhotoReasonOption1(e.target.value);
                    } else {
                      setDeletePhotoReasonOption1('');
                    }
                  }}
                />
              }
            />
          </div>
          <TextField
            placeholder="Explain other reason why you are deleting this photo."
            type="text"
            size="small"
            name="otherReason"
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
            fullWidth
          />
        </DialogTitle>

        <DialogActions className={classes.dialogActions}>
          <MaterialButton onClick={() => setShowDeleteDialog(false)} className={classes.secondaryButton}>
            Cancel
          </MaterialButton>

          <MaterialButton
            className={classes.applyButton}
            onClick={() => deletePhoto(image.id)}
            disabled={!deletePhotoReasonOption1 && !deletePhotoReasonOption2 && !otherReason}
          >
            Delete
          </MaterialButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeletePhotoDialog;
