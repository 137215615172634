import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead as TotalRowHeder,
} from '@material-ui/core';
import InfiScroller from 'react-infi-scroller';
import { InfiniteData } from 'react-query';

import { useSelector } from 'react-redux';
import TableHeader from '../../../common/Table/TableHeader';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import TransactionsDashboardRow from './TransactionsDashboardTableRow';
import { ITransaction } from '../../../../interfaces/ITransaction.interfaces';
import { IResponse } from '../../../../services/PractitionersDashboard';
import SortDirection, { ASCENDING, DESCENDING } from '../../../../types/SortDirection';
import { TableRowStriped, useRowStyles } from './practitionerDashboard.styles';
import { TableCellCustom } from '../../../common/TableStyles/TableCellCustom';
import { formatCurrency } from '../../../../utils/formatNumber';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { READ_EARNING_DASHBOARD, READ_FLOOR_PRICE } from '../../../../constants/actions.constants';

// TYPES
type TableProps = {
  data: InfiniteData<IResponse>;
  errorMessage: string;
  isLoading: boolean;
  isFetching?: boolean;
  isError: boolean;
  hasNextPage?: boolean;
  totalServicesWithDiscount: number;
  fetchNextPage?: any;
  filterText: string;
};

type TableResultsProps = {
  data: ITransaction[];
  errorMessage: string;
  isError: boolean;
  hasPriceFloorEnabled: boolean;
};

type keysType = keyof ITransaction;

type TotalsType = {
  productsAmount: number;
  servicesAmount: number;
  tipsAmount: number;
  discountsAmount: number;
  totalServicesWithDiscount: number;
};

// INTERNAL COMPONENTS
const ShowMessage = ({ text }: { text: string }) => (
  <tr>
    <td colSpan={TABLE_COLUMNS.length}>
      <Box textAlign="center">
        <p>{text}</p>
      </Box>
    </td>
  </tr>
);

const TableResults = ({ data, isError, errorMessage, hasPriceFloorEnabled }: TableResultsProps) => {
  if (isError) {
    return <ShowMessage text={errorMessage} />;
  }

  if (!data) {
    return <></>;
  }

  return data.length > 0 ? (
    <>
      {data.map(
        ({
          checkoutId,
          createdAt,
          serviceVisitId,
          customerId,
          customerName,
          productsAmount,
          servicesAmount,
          amountBelowPriceFloor,
          discountNames,
          tipsAmount,
          ehrUrl,
          lineItemsBelowPriceFloor,
        }) => (
          <TransactionsDashboardRow
            key={`${serviceVisitId}_${checkoutId}`}
            date={createdAt || ''}
            customerId={customerId}
            customer={customerName}
            productAmount={productsAmount}
            serviceAmount={servicesAmount}
            tipAmount={tipsAmount}
            amountBelowPriceFloor={amountBelowPriceFloor}
            promotion={discountNames}
            hasPriceFloorEnabled={hasPriceFloorEnabled}
            openEHR={ehrUrl || '#'}
            lineItemsBelowPriceFloor={lineItemsBelowPriceFloor}
          />
        )
      )}
    </>
  ) : (
    <ShowMessage text="No data to show" />
  );
};

const ResultsRow = ({ totals }: { totals: TotalsType }) => {
  const classes = useRowStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const { productsAmount, servicesAmount, tipsAmount } = totals;
  return (
    <TableRowStriped>
      <TableCellCustom align="center" className={classes.bold}>
        TOTALS
      </TableCellCustom>
      <TableCellCustom colSpan={2} />
      <TableCellCustom align="right" className={classes.bold}>
        {formatCurrency(servicesAmount)}
      </TableCellCustom>
      <TableCellCustom align="right" className={classes.bold}>
        {formatCurrency(productsAmount)}
      </TableCellCustom>
      <TableCellCustom align="right" className={classes.bold}>
        {formatCurrency(tipsAmount)}
      </TableCellCustom>
      {/* Hide Discounts/Promotions Column for flex users */}
      {hasAccessTo(READ_EARNING_DASHBOARD, permissions) && <TableCellCustom />}
      <TableCellCustom />
    </TableRowStriped>
  );
};

// CONSTS
const TABLE_COLUMNS = [
  { id: 'createdAt', title: 'Date', sort: true },
  { id: 'customerId', title: 'MRN', sort: true },
  { id: 'customerName', title: 'Patient' },
  { id: 'servicesAmount', title: 'Service Amount', sort: true },
  { id: 'productsAmount', title: 'Retail Products Amount', sort: true },
  { id: 'tipsAmount', title: 'Tip Amount', sort: true },
  { id: 'discountNames', title: 'Promotion / Discount' },
  { id: 'open_ehr', title: '' },
];

const FLEX_TABLE_COLUMNS = [
  { id: 'createdAt', title: 'Date', sort: true },
  { id: 'customerId', title: 'MRN', sort: true },
  { id: 'customerName', title: 'Patient' },
  { id: 'servicesAmount', title: 'Service Amount', sort: true },
  { id: 'productsAmount', title: 'Retail Products Amount', sort: true },
  { id: 'tipsAmount', title: 'Tip Amount', sort: true },
  { id: 'open_ehr', title: '' },
];

const NUMBER_COLUMNS = ['customerId', 'discounts', 'productsAmount', 'servicesAmount', 'tipsAmount', 'belowFloorPrice'];
const DATE_COLUMNS = ['createdAt'];

const compareNumbers = (a: number, b: number) => a - b;
const compareDates = (a: string, b: string) => new Date(a).valueOf() - new Date(b).valueOf();

const INITIAL_ORDER_COLUMN = 'createdAt';
const INITIAL_ORDER_DIRECTION = DESCENDING;

const INITIAL_TOTALS = {
  productsAmount: 0,
  servicesAmount: 0,
  tipsAmount: 0,
  futureCreditsApplied: 0,
  discountsAmount: 0,
  totalServicesWithDiscount: 0,
  accountCreditUsed: 0,
};

const TransactionsDashboardTable = ({
  isLoading,
  isFetching = false,
  isError,
  errorMessage,
  data,
  totalServicesWithDiscount,
  hasNextPage,
  fetchNextPage,
  filterText,
}: TableProps) => {
  const tableRef = useRef(null);
  const [sortBy, setSortBy] = useState<keysType>(INITIAL_ORDER_COLUMN as keysType);
  const [sortDirection, setSortDirection] = useState<SortDirection>(INITIAL_ORDER_DIRECTION);
  const [allTransactions, setAllTransactions] = useState<ITransaction[]>([]);
  const { permissions, isLoading: isLoadingPermissions } = useSelector(({ auth }: any) => auth);

  const [totals, setTotals] = useState<TotalsType>(INITIAL_TOTALS);

  const hasPriceFloorEnabled = hasAccessTo(READ_FLOOR_PRICE, permissions);

  useEffect(() => {
    if (data) {
      const initialTransactions = data?.pages
        .map(({ transactions }: { transactions: ITransaction[] }) => transactions)
        .flat();

      const filteredTransactions = filterText
        ? initialTransactions.filter((transaction) =>
          transaction.customerName.toLowerCase().includes(filterText.toLowerCase())
        )
        : initialTransactions;
      setAllTransactions(filteredTransactions);
      onChangeSortBy(sortBy, sortDirection);
    }
  }, [data, filterText]);

  useEffect(() => {
    if (allTransactions) {
      const totalsSums = allTransactions.reduce((acc: TotalsType, transaction: ITransaction) => {
        const {
          productsAmount,
          servicesAmount,
          tipsAmount,
          futureCreditsApplied,
          accountCreditUsed,
          discounts = 0,
        } = transaction;
        return {
          productsAmount: acc.productsAmount + productsAmount,
          servicesAmount: acc.servicesAmount + servicesAmount,
          tipsAmount: acc.tipsAmount + tipsAmount,
          futureCreditsApplied: acc.futureCreditsApplied + futureCreditsApplied,
          accountCreditUsed: acc.accountCreditUsed + accountCreditUsed,
          discountsAmount: acc.discountsAmount + discounts,
          totalServicesWithDiscount,
        };
      }, INITIAL_TOTALS);
      setTotals(totalsSums);
    }
  }, [allTransactions, totalServicesWithDiscount]);

  useEffect(() => {
    if (allTransactions.length) {
      allTransactions.sort((a, b) => {
        const { [sortBy]: aValue } = a;
        const { [sortBy]: bValue } = b;

        let orderResult = 0;
        if (NUMBER_COLUMNS.includes(sortBy)) {
          orderResult = compareNumbers(aValue, bValue);
        }
        if (DATE_COLUMNS.includes(sortBy)) {
          orderResult = compareDates(aValue, bValue);
        }
        return sortDirection === ASCENDING ? orderResult : orderResult * -1;
      });

      setAllTransactions([...allTransactions]);
    }
  }, [sortBy, sortDirection]);

  const onChangeSortBy = (column: string, direction: SortDirection) => {
    setSortBy(column as keysType);
    setSortDirection(direction);
  };

  return (
    <TableContainer style={{ maxHeight: '100vh', position: 'sticky' }} ref={tableRef}>
      <Table>
        <TableHeader
          columns={hasAccessTo(READ_EARNING_DASHBOARD, permissions) ? TABLE_COLUMNS : FLEX_TABLE_COLUMNS}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSortChange={onChangeSortBy}
        />
        <TotalRowHeder>
          <ResultsRow totals={isLoading || isLoadingPermissions ? INITIAL_TOTALS : totals} />
        </TotalRowHeder>
        <TableBody>
          <InfiScroller
            active={hasNextPage}
            scrollTarget={tableRef?.current}
            hasMore={hasNextPage}
            onLoadMore={() => {
              fetchNextPage();
            }}
          >
            {isLoading || isLoadingPermissions ? (
              <TableRow>
                <TableCell
                  colSpan={
                    hasAccessTo(READ_EARNING_DASHBOARD, permissions) ? TABLE_COLUMNS.length : FLEX_TABLE_COLUMNS.length
                  }
                >
                  <MultipleSkeleton addPosition={false} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                <TableResults
                  data={allTransactions}
                  isError={isError}
                  errorMessage={errorMessage}
                  hasPriceFloorEnabled={hasPriceFloorEnabled}
                />
                {isFetching && <MultipleSkeleton />}
              </>
            )}
          </InfiScroller>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionsDashboardTable;
